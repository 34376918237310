import paredAnalytics from 'pared/analytics/utils/ParedAnalytics'

export const track = {
  async rankByChanged(rankBy: string) {
    await paredAnalytics.track('Rankings - Rank By Changed', { rankBy })
  },

  async groupByChanged(groupBy: string) {
    await paredAnalytics.track('Rankings - Group By Changed', { groupBy })
  },

  async periodChanged(periodKey: string) {
    await paredAnalytics.track('Rankings - Period Changed', { periodKey })
  },
}
