import { PopoverOrigin } from '@material-ui/core'
import MaterialMenu from '@material-ui/core/Menu'
import * as d3 from 'd3-hierarchy'
import { memo, useMemo } from 'react'

import MenuItem, { IValueType } from './MenuItem'

interface IPropsType<Value extends IValueType> {
  dataSource: Value[]
  anchorEl: HTMLElement | null
  handleMenuClose: () => void
  onSelect: (value: Value['value'][]) => Promise<void> | void
  onKeyDown?: (event: any) => void
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  customizedMuiClasses?: any
}

const Menu = <Value extends IValueType>({
  dataSource,
  anchorEl,
  handleMenuClose,
  onSelect,
  onKeyDown = () => {},
  anchorOrigin,
  transformOrigin,
  customizedMuiClasses,
}: IPropsType<Value>) => {
  const option = useMemo(() => d3.stratify<Value>()(dataSource), [dataSource])

  return (
    <MaterialMenu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      onKeyDown={onKeyDown}
      variant="menu"
      getContentAnchorEl={null}
      anchorOrigin={anchorOrigin || { vertical: 'top', horizontal: 'left' }}
      transformOrigin={
        transformOrigin || { vertical: 'top', horizontal: 'left' }
      }
      className={customizedMuiClasses?.Menu}
    >
      {option?.children?.map((subOption) => (
        <MenuItem<Value>
          key={subOption.id}
          onSelect={onSelect}
          onClose={handleMenuClose}
          option={subOption}
          customizedMuiClasses={customizedMuiClasses}
        />
      ))}
    </MaterialMenu>
  )
}

export default memo(Menu) as typeof Menu
