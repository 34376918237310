interface IBrandLogos {
  dark: any
  darkDimension: {
    height: number
    width: number
  }
  white: any
}

// TODO: make it scalable. It's for hiding some missing data features
export const EDDIE_MERLOTS_BRAND_CODE = 'eddiemerlots'

export const NUM_BRAND: number =
  parseInt(process.env.REACT_APP_NUM_BRAND || '1', 10) || 1

export const BRAND_CODES: string[] = []
export const BRAND_ID: { [brandCode: string]: number } = {}
export const BRAND_LOCATION_GROUP_ID: { [brandCode: string]: number } = {}
export const BRAND_NAME: { [brandCode: string]: string } = {}
export const BRAND_LOGOS: { [brandCode: string]: IBrandLogos } = {}
export const BRAND_DEFAULT_CALENDAR_START_DATE: {
  [brandCode: string]: string
} = {}

for (let bi = 1; bi <= NUM_BRAND; ++bi) {
  const brandCodeFromConfig = process.env[`REACT_APP_BRAND_CODE_${bi}`]
  const brandNameFromConfig = process.env[`REACT_APP_BRAND_NAME_${bi}`]
  const brandIdFromConfig = parseInt(
    process.env[`REACT_APP_BRAND_ID_${bi}`] || '',
    10,
  )
  const brandLocationGroupIdFromConfig = parseInt(
    process.env[`REACT_APP_BRAND_LOCATION_GROUP_ID_${bi}`] || '',
    10,
  )
  const brandDefaultCalendarStartDateFromConfig =
    process.env[`REACT_APP_DEFAULT_CALENDAR_START_DATE_${bi}`] ||
    process.env.REACT_APP_DEFAULT_CALENDAR_START_DATE

  if (
    brandCodeFromConfig &&
    brandNameFromConfig &&
    brandIdFromConfig &&
    brandLocationGroupIdFromConfig
  ) {
    BRAND_CODES.push(brandCodeFromConfig)
    BRAND_NAME[brandCodeFromConfig] = brandNameFromConfig
    BRAND_ID[brandCodeFromConfig] = brandIdFromConfig
    BRAND_LOCATION_GROUP_ID[brandCodeFromConfig] =
      brandLocationGroupIdFromConfig

    BRAND_LOGOS[brandCodeFromConfig] = {
      dark: require(`pared/images/brands/${brandCodeFromConfig}/logo.png`)
        .default,
      darkDimension: {
        height: 40,
        width: 75,
      },
      white:
        require(`pared/images/brands/${brandCodeFromConfig}/logo-white.png`)
          .default,
    }

    const img = new Image()
    img.onload = function () {
      var height = img.height
      var width = img.width
      BRAND_LOGOS[brandCodeFromConfig].darkDimension = {
        height,
        width,
      }
    }
    img.src = BRAND_LOGOS[brandCodeFromConfig].dark

    if (brandDefaultCalendarStartDateFromConfig) {
      BRAND_DEFAULT_CALENDAR_START_DATE[brandCodeFromConfig] =
        brandDefaultCalendarStartDateFromConfig
    }
  } else {
    throw new Error(
      `Brand ${bi} has not been set correctly in the configuration file.`,
    )
  }
}

if (BRAND_CODES.length !== Object.keys(BRAND_NAME).length) {
  throw new Error('Found duplicated brand codes in the configuration file.')
}
