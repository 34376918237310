import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { getUser } from 'pared/utils/user'

import { useAdvancedFilter } from '../../advancedFilter'
import { IApiDataType } from '../types'

const queries = gql`
  query ListDirectorDevelopmentFeedbacks($iEmployeeId: Int!) {
    listDirectorDevelopmentFeedbacks(iEmployeeId: $iEmployeeId) {
      nodes {
        locationId
        locationCode
        locationName
        revieweeId
        revieweeFirstName
        revieweeLastName
        revieweeHiredAt
        revieweeStatus
        revieweeJobTitle
        revieweeRoleStartedAt
        reviewerFirstName
        reviewerLastName
        performace
        updateUuid
        reviewAt
        feedback
      }
    }
  }
`

const PERFORMANCE_ITEMS = [
  'BELOW_AVERAGE',
  'AVERAGE',
  'ABOVE_AVERAGE',
  'READY_TO_PROMOTE',
]

export const jscCorporateDevelopmentFeedbackConfigs = {
  name: 'feedback-name',
  'name.employee': 'string',
  timeInPosition: 'date-diff',
  tenure: 'date-diff',
  status: 'string',
  feedback: 'feedback',
} as const

export const useJscCorporateDevelopmentFeedbackData = () => {
  const user = getUser()
  const userId = user.employeeId ? user.employeeId : -1

  return useQuery(queries, {
    variables: {
      iEmployeeId: userId,
    },
    skip: !user,
  })
}

const useJscCorporateDevelopmentFeedback = () => {
  const advancedFilter = useAdvancedFilter('select')
  const { data, loading } = useJscCorporateDevelopmentFeedbackData()

  return {
    data: useMemo((): IApiDataType => {
      const reviews =
        !advancedFilter || advancedFilter?.value.feedback === 'ALL'
          ? data?.listDirectorDevelopmentFeedbacks.nodes
          : data?.listDirectorDevelopmentFeedbacks.nodes.filter(
              (employee: any) => {
                if (
                  PERFORMANCE_ITEMS.includes(advancedFilter?.value.feedback)
                ) {
                  return employee.performace === advancedFilter?.value.feedback
                } else {
                  return (
                    employee.revieweeJobTitle === advancedFilter?.value.feedback
                  )
                }
              },
            )

      let feedbacks: Record<string, unknown>[] = []

      reviews?.forEach((employee: any) => {
        const locationCode =
          employee.locationCode === 'UNKNOWN' ? '' : employee.locationCode
        const locationRole =
          employee.locationId === -1
            ? `${employee.revieweeJobTitle}`
            : `${employee.locationName} (${locationCode}) - ${employee.revieweeJobTitle}`
        const reviewAt = moment(employee.reviewAt).format('M/DD/YY')

        if (employee.revieweeStatus != 'INACTIVE') {
          feedbacks.push({
            name: {
              employee: `${employee.revieweeFirstName} ${employee.revieweeLastName}`,
              revieweeJobTitle: employee.revieweeJobTitle,
              storeCode: locationCode,
            },
            timeInPosition: employee.revieweeRoleStartedAt,
            tenure: employee.revieweeHiredAt,
            status: employee.revieweeStatus || 'New in Role',
            feedback: {
              employeeId: employee.revieweeId,
              employee: `${employee.revieweeFirstName} ${employee.revieweeLastName}`,
              reviewer: `${employee.reviewerFirstName} ${employee.reviewerLastName}`,
              locationId: employee.locationId,
              locationRole: locationRole,
              performance: employee.performace,
              feedback: employee.feedback,
              updateUuid: employee.updateUuid,
              reviewAt,
            },
          })
        }
      })

      return { source: feedbacks }
    }, [data, advancedFilter]),
    loading,
  }
}

export default useJscCorporateDevelopmentFeedback
