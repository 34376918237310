import _ from 'lodash'
import { useState } from 'react'

import { IDirector } from 'pared/data/getDirectors'
import { usdStringToNumber } from 'pared/utils/number'

import { IDetailGuestKpi } from '../index'
import Main from './Main'

export interface IOrderBy {
  columnName: string
  isAscending: boolean
}

interface IProps {
  itemizedKpis: IDetailGuestKpi[]
  selectedDirector: IDirector | null
}

function BreakdownTable({ itemizedKpis, selectedDirector }: IProps) {
  const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false)

  const onToggleExpansion = () => {
    setIsTableExpanded(!isTableExpanded)
  }

  const [orderBy, setOrderBy] = useState<IOrderBy>({
    columnName: 'ppa',
    isAscending: false,
  })

  let rankedItemizedKpis = []
  const columnName = _.get(orderBy, 'columnName', '')
  const ascending = orderBy.isAscending ? 'asc' : 'desc'

  const ascendingCoefficient = orderBy.isAscending ? 1 : -1

  switch (columnName) {
    case 'ppa':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return usdStringToNumber(_.get(k, 'ppa', '0')) * ascendingCoefficient
      })
      break
    case 'appetizerPpa':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          usdStringToNumber(_.get(k, 'appetizerPpa', '0')) *
          ascendingCoefficient
        )
      })
      break
    case 'lbwPpa':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return usdStringToNumber(_.get(k, 'lbwPpa', '0')) * ascendingCoefficient
      })
      break
    case 'dessertPpa':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          usdStringToNumber(_.get(k, 'dessertPpa', '0')) * ascendingCoefficient
        )
      })
      break
    case 'grossSalesPerLaborHour':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          usdStringToNumber(_.get(k, 'grossSalesPerLaborHour', '0')) *
          ascendingCoefficient
        )
      })
      break
    case 'grossSales':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          usdStringToNumber(_.get(k, 'grossSales', '0')) * ascendingCoefficient
        )
      })
      break
    case 'sosAvgTime':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          (parseFloat(_.get(k, 'sosAvgTime', '0')) || 0) * ascendingCoefficient
        )
      })
      break
    case 'productivityCoefficient':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          (parseFloat(_.get(k, 'productivityCoefficient', '0')) || 0) *
          ascendingCoefficient
        )
      })
      break
    default:
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return usdStringToNumber(_.get(k, 'ppa', '0')) * ascendingCoefficient
      })
      break
  }

  return (
    <Main
      itemizedKpis={rankedItemizedKpis}
      isTableExpanded={isTableExpanded}
      onToggleExpansion={onToggleExpansion}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      selectedDirector={selectedDirector}
    />
  )
}

export default BreakdownTable
