import { IConfigsType } from '../../types'

const originalCorporatePagesConfig: IConfigsType = {
  timoney_bk: {
    '/:brand/expo_ai': {},
    '/:brand/systemwide_overview': {},
    '/:brand/corporate_custom': {},
    '/:brand/rankings': {},
    '/:brand/flash': {},
    '/:brand/corporate_team': {},
    '/:brand/corporate_salesmanship': {},
    '/:brand/corporate_pmix': {},
    '/:brand/corporate_loss_prevention': {},
    '/:brand/corporate_inventory': {},
    '/:brand/corporate_guest': {},
    '/:brand/corporate_delivery': {},
  },
}

export default originalCorporatePagesConfig
