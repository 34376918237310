import deepmerge, { Options } from 'deepmerge'
import { useMemo } from 'react'

import useNewRoutes from 'pared/Routes/renderer/hooks/useRoutes'
import useUnAccessibleLinks from 'pared/Routes/renderer/hooks/useUnAccessibleLinks'
import { IRouteType as IBaseRouteType } from 'pared/Routes/renderer/types'
import { getBrandSettings } from 'pared/customer'
import { getUser } from 'pared/utils/user'

import { IApiKeyType as INotifactionApiType } from '../Notification'
import useBrands from './useBrands'

export type IRouteType = IBaseRouteType & {
  notification?: INotifactionApiType
}

const combineMerge = (
  target: IRouteType[],
  source: IRouteType[],
  options?: Options,
): IRouteType[] =>
  [...source, ...target].reduce((result, route) => {
    if ('link' in route) {
      const idx = result.findIndex((r) =>
        'link' in r ? r.link === route.link : false,
      )
      if (idx >= 0) {
        result[idx] = deepmerge(result[idx], route, options)
        return result
      }
    } else {
      const idx = result.findIndex((r) => r.title === route.title)
      if (idx >= 0) {
        result[idx] = deepmerge(result[idx], route, options)
        return result
      }
    }

    return [...result, route]
  }, [] as IRouteType[])

/*
TODO: need more cases to make sure if we could use custom routes and brandSetting.modules at the same time
*/
const useRoutes = (): IRouteType[] => {
  const { brand } = useBrands()
  const user = getUser()
  const brandSettings = getBrandSettings()
  const newRoutes = useNewRoutes()
  const unAccessibleLinks = useUnAccessibleLinks()

  return useMemo(() => {
    if (brand === 'lfr' && newRoutes.length !== 0) return newRoutes

    const oldRoutes = [
      ...(brandSettings.modules.corporate.length === 0 ||
      user.disableCorporatePages
        ? []
        : [
            {
              title: brandSettings.labels.business.corporate,
              children: [
                ...brandSettings.modules.corporate
                  .map((module) => ({
                    title: module.title,
                    link: module.url,
                    notification: module.notification,
                  }))
                  .filter((r) => !unAccessibleLinks.includes(r.link)),
              ],
            },
          ]),
      ...(brandSettings.modules.stores.length === 0
        ? []
        : [
            {
              title: brandSettings.labels.business.store,
              children: [
                ...brandSettings.modules.stores
                  .map((module) => ({
                    title: module.title,
                    link: module.url,
                    notification: module.notification,
                  }))
                  .filter((r) => !unAccessibleLinks.includes(r.link)),
              ],
            },
          ]),
    ]

    return deepmerge(oldRoutes, newRoutes, {
      arrayMerge: combineMerge,
    })
  }, [brand, brandSettings, newRoutes, unAccessibleLinks])
}

export default useRoutes
