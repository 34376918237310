import { IRouteType } from '../../types'
import irmgBkHome from './irmgBkHome'
import irmgBkPlaidPageConfig from './irmgBkPlaidPageConfig'
import originalCorporatePages from './originalCorporatePages'

export const irmgBkRoutes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Systemwide Overview',
        link: '/:brand/systemwide_overview',
        useOriginal: true,
      },
      {
        title: 'Plaid',
        link: '/:brand/plaid',
      },
    ],
  },
  {
    title: 'Store',
    children: [
      {
        title: 'Home',
        link: '/:brand/scorecard',
      },
    ],
  },
]

export default [irmgBkPlaidPageConfig, originalCorporatePages, irmgBkHome]
