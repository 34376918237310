import _ from 'lodash'
import { useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { getBrandFromPathname } from 'pared/utils/brand'

import Main from './Main'

interface IProps {
  navParams: INavParams
}

function ForgotPassword({ navParams }: IProps) {
  const pagePathname = _.get(navParams, 'pagePathname', '')

  const brand = getBrandFromPathname(pagePathname)

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isFirstPage, setIsFirstPage] = useState<boolean>(true)

  const onClickContinue = async (email: string) => {
    const formattedEmail = (email || '').trim().toLowerCase()

    if (!formattedEmail) {
      setErrorMessage('The email is required.')
      return false
    } else {
      try {
        await fetch(
          `${process.env.REACT_APP_BE_BASE_URL}/send_email?email=${formattedEmail}&brand=${brand}`,
        )
        return true
      } catch (error) {
        return false
      }
    }
  }

  const onSetErrorMessage = (message: string) => {
    setErrorMessage(message)
  }

  const onSetIsFirstPage = (shouldDisplayFirstPage: boolean) => {
    setIsFirstPage(shouldDisplayFirstPage)
  }

  return (
    <Main
      brand={brand}
      onClickContinue={onClickContinue}
      errorMessage={errorMessage}
      isFirstPage={isFirstPage}
      onSetIsFirstPage={onSetIsFirstPage}
      onSetErrorMessage={onSetErrorMessage}
    />
  )
}

export default ForgotPassword
