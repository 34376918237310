import { IConfigsType } from '../../types'

const bibibopHaccps: IConfigsType = {
  bibibop: {
    '/:brand/rational': {
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'last_week', 'this_week'],
      },
      haccps: {
        type: 'table',
        title: 'Rational',
        api: 'bbbHaccps',
        rowKey: 'id',
        pageSize: 20,
        defaultSortedField: 'startDate',
        columns: [
          {
            key: 'batchId',
            title: 'Batch',
            align: 'center',
            sortable: true,
          },
          {
            key: 'name',
            title: 'Cooking program',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'startDate',
            title: 'Date',
            align: 'center',
            sortable: true,
            format: 'YYYY-MM-DD hh:mm:ss A',
            timezone: 0,
          },
          {
            key: 'duration',
            title: 'Duration',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'openDoorCount',
            title: 'Door openings',
            align: 'center',
            sortable: true,
          },
          {
            key: 'deviceName',
            title: 'Device',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'deviceSerialNumber',
            title: 'Serial number',
            align: 'center',
            sortable: true,
          },
          {
            key: 'groupName',
            title: 'Group',
            align: 'center',
            highlight: true,
            sortable: true,
          },
        ],
      },
    },
  },
}

export default bibibopHaccps
