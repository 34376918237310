import { useState } from 'react'
import styled from 'styled-components'

import DateRangeNumberSelector from 'pared/components/DateRangeNumberSelector'
import {
  IItem,
  LAST_8_WEEKS,
  LAST_13_PERIODS,
  LAST_14_DAYS,
} from 'pared/components/DateRangeNumberSelector/hooks/useDateRange'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import DiscountTrendChart from './DiscountTrendChart'

interface IProps {
  isBreakdownByDirectors: boolean
  endDate?: string
  locationIds?: number[]
  locationGroupIds?: number[]
}

const SubHeader = styled.div`
  padding: 20px 0 0 0;
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    display: block;
    width: 100%;
    margin: 60px 0px 0px;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
`

const MobileCotainer = styled.div`
  padding: 20px 0;

  @media ${MOBILE_WIDTH} {
    margin: 20px 0px 0px;
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

export default function DiscountTrend({
  endDate,
  locationIds,
  locationGroupIds,
  isBreakdownByDirectors,
}: IProps) {
  const [discountDateRange, setDiscountDateRange] = useState<IItem['value']>(
    LAST_14_DAYS.value,
  )

  return (
    <>
      <SubHeader>
        <h1>DISCOUNTS TREND</h1>
        <DateRangeNumberSelector
          value={discountDateRange}
          onChange={setDiscountDateRange}
          dateRangeOptions={[LAST_14_DAYS, LAST_8_WEEKS, LAST_13_PERIODS]}
        />
      </SubHeader>

      <MobileCotainer>
        <DiscountTrendChart
          locationIds={locationIds}
          locationGroupIds={locationGroupIds}
          isBreakdownByDirectors={isBreakdownByDirectors}
          dateRange={discountDateRange}
          endDate={endDate || ''}
        />
      </MobileCotainer>
    </>
  )
}
