import Box from '@mui/material/Box'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

const Container = styled(Box)`
  display: flex;
  align-items: center;
  width: 250px;
  height: 40px;
`

const Text = styled.div`
  display: inline-block;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 200;
  flex: 1;
`

const BorderLinearProgress = styled(LinearProgress)`
  height: 25px;
  border-radius: 0px;
  flex: 3;
  &.${linearProgressClasses.colorPrimary} {
    background-color: ${COLORS.Smoke};
  }
  & .${linearProgressClasses.bar} {
    background-color: #07a4c7;
    border-radius: 0px;
  }
`

interface IPropsType {
  value: number
}

const ProgressBar = ({ value }: IPropsType) => {
  if (value || value === 0) {
    const fixedValue = value.toFixed(0)

    return (
      <Container>
        <BorderLinearProgress variant="determinate" value={value} />
        <Text>{fixedValue}%</Text>
      </Container>
    )
  }

  return null
}

export default ProgressBar
