import paredAnalytics from 'pared/analytics/utils/ParedAnalytics'

export const track = {
  async moreDetailsClicked(alertName: string, locationId: number) {
    await paredAnalytics.track('Alert - More Details Clicked', {
      alertName,
      locationId,
    })
  },

  async archivedAlertsToggled(alertsDisplayed: string) {
    await paredAnalytics.track('Alert - Archived Alerts Toggled', {
      alertsDisplayed,
    })
  },

  async alertUserNotifiedRead(alertUserNotifiedId: number) {
    await paredAnalytics.track('Alert - AlertUserNotified Read', {
      alertUserNotifiedId,
    })
  },

  async alertUserNotifiedUnRead(alertUserNotifiedId: number) {
    await paredAnalytics.track('Alert - AlertUserNotified Read', {
      alertUserNotifiedId,
    })
  },

  async clickOpsCompAlertSeeTeam(locationId: number) {
    await paredAnalytics.track('Alert - On Click Ops Comp Alert See Team', {
      locationId,
    })
  },
}
