import useListEmployeeDiscountData, {
  burgerworksListEmployeeDiscountConfigs,
} from './lossPrevention/useListEmployeeDiscountData'
import useListEmployeeLossPreventionData, {
  burgerworksListEmployeeLossPreventionConfigs,
} from './lossPrevention/useListEmployeeLossPreventionData'
import useLocationOpsCompsBreakdownData, {
  burgerworksLocationOpsCompsBreakdownDataConfigs,
} from './lossPrevention/useLocationOpsCompsBreakdownData'
import useBurgerworksLossPrevention, {
  burgerworksLossPreventionConfigs,
} from './lossPrevention/useLossPrevention'
import useBurgerworksFlash, { burgerworksFlashConfigs } from './useFlash'
import useBurgerworksSystemwideOverview, {
  burgerworksSystemwideOverviewConfigs,
} from './useSystemwideOverview'

export const configs = {
  burgerworksLossPrevention: burgerworksLossPreventionConfigs,
  burgerworksListEmployeeDiscount: burgerworksListEmployeeDiscountConfigs,
  burgerworksLocationOpsCompsBreakdownData:
    burgerworksLocationOpsCompsBreakdownDataConfigs,
  burgerworksListEmployeeLossPrevention:
    burgerworksListEmployeeLossPreventionConfigs,
  burgerworksSystemwideOverview: burgerworksSystemwideOverviewConfigs,
  burgerworksFlash: burgerworksFlashConfigs,
}

export const api = {
  burgerworksLossPrevention: useBurgerworksLossPrevention,
  burgerworksListEmployeeDiscount: useListEmployeeDiscountData,
  burgerworksLocationOpsCompsBreakdownData: useLocationOpsCompsBreakdownData,
  burgerworksListEmployeeLossPrevention: useListEmployeeLossPreventionData,
  burgerworksSystemwideOverview: useBurgerworksSystemwideOverview,
  burgerworksFlash: useBurgerworksFlash,
}
