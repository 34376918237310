import _ from 'lodash'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import useOnLoad from 'pared/hooks/useOnLoad'
import { getBrandFromPathname } from 'pared/utils/brand'
import { getBrand, getPathnameWithoutBrand } from 'pared/utils/brand'
import { getUser } from 'pared/utils/user'

import navParams from './navParams'

interface ISearchParams {
  storeId?: string | number
  dateRange?: string
}

export const URL_SEARCH_DATE_RANGE = 'date_range'
export const URL_SEARCH_STORE = 'store'
export const URL_SEARCH_REDIRECT = 'redirect'

let history: any = null
let location: any = null
export function NavigatorInitializingComponent() {
  history = useHistory()
  location = useLocation()
  useOnLoad()

  return <React.Fragment />
}

function getUrlPathWithSearchParams(
  urlPath: string,
  isStoreLevel?: boolean | null,
  newSearchParams?: ISearchParams | null,
  newBrand?: string,
  clearHash?: boolean,
) {
  let brand
  if (newBrand) {
    brand = newBrand
  } else {
    const pathname = _.get(location, 'pathname', '')
    brand = getBrandFromPathname(pathname)
  }

  const urlPathParts = urlPath.split('?')
  const cleanedUrlPath = urlPathParts[0] || urlPath
  const urlWithBrand = `/${brand}${cleanedUrlPath}`
  const urlHash = _.get(location, 'hash', '')

  // if newSearchParams === null, then remove all search parameters
  if (newSearchParams !== null) {
    const mergedNavParams = {
      ...navParams,
      ...newSearchParams,
    }
    const params: string[] = []

    if (mergedNavParams.storeId) {
      if (isStoreLevel !== false) {
        params.push(`${URL_SEARCH_STORE}=${mergedNavParams.storeId}`)
      }
    } else {
      if (isStoreLevel === true && mergedNavParams.userDefaultStoreId) {
        params.push(`${URL_SEARCH_STORE}=${mergedNavParams.userDefaultStoreId}`)
      }
    }
    if (mergedNavParams.dateRange) {
      params.push(`${URL_SEARCH_DATE_RANGE}=${mergedNavParams.dateRange}`)
    }
    if (params.length > 0) {
      return clearHash
        ? `${urlWithBrand}?${params.join('&')}`
        : `${urlWithBrand}?${params.join('&')}${urlHash}`
    }
  }

  return urlWithBrand
}

const navigator = {
  go(
    urlPath: string,
    newSearchParams?: ISearchParams | null,
    newBrand?: string,
    clearHash?: boolean,
  ) {
    if (history) {
      history.push(
        getUrlPathWithSearchParams(
          urlPath,
          null,
          newSearchParams,
          newBrand,
          clearHash,
        ),
      )
    }
  },

  goUrl(url: string) {
    window.location.replace(url)
  },

  refresh() {
    history.go(0)
  },

  back() {
    if (history?.goBack) {
      history.goBack()
    }
  },
  forward() {
    if (history?.goForward) {
      history.goForward()
    }
  },

  goToDefaultLandingPage() {
    const user = getUser()
    if (user && user.defaultLocationId && user.isStoreLevelUser) {
      const brand = getBrand()

      switch (brand) {
        // TODO: make this more elegant
        case 'rmg':
        case 'rmg_nso':
        case 'sullivans':
        case 'sullivans_nso':
        case 'eddie_merlots':
          navigator.go(getPathnameWithoutBrand(navigator.scorecard()), {
            storeId: user.defaultLocationId,
          })
          break
        default:
          navigator.go(getPathnameWithoutBrand(navigator.storeDetail()), {
            storeId: user.defaultLocationId,
          })
      }
    } else {
      navigator.go(getPathnameWithoutBrand(navigator.systemwideOverview()))
    }
  },

  // also need to modify the file `./webRoutes.tsx` if there is any route changes

  // ------ user level ------
  signIn(brand: string) {
    return `/${brand}/sign_in`
  },
  signInMagicLink(brand: string) {
    return `/${brand}/sign_in_magic_link`
  },
  signUp(brand: string) {
    return `/${brand}/sign_up`
  },
  forgotPassword(brand: string) {
    return `/${brand}/forgot_password`
  },
  changePassword(brand: string) {
    return `/${brand}/change_password`
  },

  expoAi(brand: string) {
    return `/${brand}/expo_ai`
  },

  myDashboard() {
    return getUrlPathWithSearchParams('/my_dashboard')
  },

  // ------ corporate level ------
  systemwideOverview(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/systemwide_overview',
      false,
      newSearchParams,
      brand,
    )
  },

  flash(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams('/flash', false, newSearchParams, brand)
  },

  rankings(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/rankings',
      false,
      newSearchParams,
      brand,
    )
  },

  corporateYields(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_yields',
      false,
      newSearchParams,
      brand,
    )
  },

  corporateProduct(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_product',
      false,
      newSearchParams,
      brand,
    )
  },

  corporateTeam(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_team',
      false,
      newSearchParams,
      brand,
    )
  },

  corporateSalesmanship(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_salesmanship',
      false,
      newSearchParams,
      brand,
    )
  },

  corporatePmix(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_pmix',
      false,
      newSearchParams,
      brand,
    )
  },

  corporateLossPrevention(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_loss_prevention',
      false,
      newSearchParams,
      brand,
    )
  },

  corporateSalesExaminer(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_sales_examiner',
      false,
      newSearchParams,
      brand,
    )
  },

  corporateGuest(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_guest',
      false,
      newSearchParams,
      brand,
    )
  },

  corporateDelivery(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_delivery',
      false,
      newSearchParams,
      brand,
    )
  },

  corporateInventory(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_inventory',
      false,
      newSearchParams,
      brand,
    )
  },

  corporatePurchases(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_purchases',
      false,
      newSearchParams,
      brand,
    )
  },

  corporateExpandedPL(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_expanded_pl',
      false,
      newSearchParams,
      brand,
    )
  },

  corporateCreditCardReconciliation(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_credit_card_reconciliation',
      false,
      newSearchParams,
      brand,
    )
  },

  userManagement(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/user_management',
      false,
      newSearchParams,
      brand,
    )
  },

  corporateCustom(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_custom',
      false,
      newSearchParams,
      brand,
    )
  },

  corporateCalls(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_calls',
      false,
      newSearchParams,
      brand,
    )
  },

  corporateMarketAnalysis(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      '/corporate_market_analysis',
      false,
      newSearchParams,
      brand,
    )
  },

  // ------ store level ------
  storeDetail(storeId?: string | number, brand?: string) {
    const newSearchParams: ISearchParams = {}
    if (storeId) {
      newSearchParams.storeId = storeId
    }
    return getUrlPathWithSearchParams(
      '/store_detail',
      true,
      newSearchParams,
      brand,
    )
  },

  scorecard(storeId?: string | number, brand?: string) {
    const newSearchParams: ISearchParams = {}
    if (storeId) {
      newSearchParams.storeId = storeId
    }

    return getUrlPathWithSearchParams(
      '/scorecard',
      true,
      newSearchParams,
      brand,
    )
  },

  product(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams('/product', false, newSearchParams, brand)
  },

  team(storeId?: string | number, brand?: string) {
    const newSearchParams: ISearchParams = {}
    if (storeId) {
      newSearchParams.storeId = storeId
    }
    return getUrlPathWithSearchParams('/team', true, newSearchParams, brand)
  },

  salesmanship(storeId?: string | number, brand?: string) {
    const newSearchParams: ISearchParams = {}
    if (storeId) {
      newSearchParams.storeId = storeId
    }
    return getUrlPathWithSearchParams(
      '/salesmanship',
      true,
      newSearchParams,
      brand,
    )
  },

  pmix(storeId?: string | number, brand?: string) {
    const newSearchParams: ISearchParams = {}
    if (storeId) {
      newSearchParams.storeId = storeId
    }
    return getUrlPathWithSearchParams('/pmix', true, newSearchParams, brand)
  },

  buns(storeId?: string | number, brand?: string) {
    const newSearchParams: ISearchParams = {}
    if (storeId) {
      newSearchParams.storeId = storeId
    }
    return getUrlPathWithSearchParams('/buns', true, newSearchParams, brand)
  },

  lossPrevention(storeId?: string | number, brand?: string) {
    const newSearchParams: ISearchParams = {}
    if (storeId) {
      newSearchParams.storeId = storeId
    }
    return getUrlPathWithSearchParams(
      '/loss_prevention',
      true,
      newSearchParams,
      brand,
    )
  },

  inventory(storeId?: string | number, brand?: string) {
    const newSearchParams: ISearchParams = {}
    if (storeId) {
      newSearchParams.storeId = storeId
    }
    return getUrlPathWithSearchParams(
      '/inventory',
      true,
      newSearchParams,
      brand,
    )
  },

  purchases(storeId?: string | number, brand?: string) {
    const newSearchParams: ISearchParams = {}
    if (storeId) {
      newSearchParams.storeId = storeId
    }
    return getUrlPathWithSearchParams(
      '/purchases',
      true,
      newSearchParams,
      brand,
    )
  },

  delivery(storeId?: string | number, brand?: string) {
    const newSearchParams: ISearchParams = {}
    if (storeId) {
      newSearchParams.storeId = storeId
    }
    return getUrlPathWithSearchParams('/delivery', true, newSearchParams, brand)
  },

  guest(storeId?: string | number, brand?: string) {
    const newSearchParams: ISearchParams = {}
    if (storeId) {
      newSearchParams.storeId = storeId
    }
    return getUrlPathWithSearchParams('/guest', true, newSearchParams, brand)
  },

  calls(storeId?: string | number, brand?: string) {
    const newSearchParams: ISearchParams = {}
    if (storeId) {
      newSearchParams.storeId = storeId
    }
    return getUrlPathWithSearchParams('/calls', true, newSearchParams, brand)
  },

  orderingAnalysis(storeId?: string | number, brand?: string) {
    const newSearchParams: ISearchParams = {}
    if (storeId) {
      newSearchParams.storeId = storeId
    }
    return getUrlPathWithSearchParams(
      '/ordering_analysis',
      true,
      newSearchParams,
      brand,
    )
  },

  // ------ others ------
  employeeProfile(employeeId: string | number, brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams(
      `/employee_profile/${employeeId}`,
      false,
      newSearchParams,
      brand,
    )
  },

  unauthorized(storeId?: string | number, brand?: string) {
    const newSearchParams: ISearchParams = {}
    if (storeId) {
      newSearchParams.storeId = storeId
    }

    return getUrlPathWithSearchParams(
      '/unauthorized',
      true,
      newSearchParams,
      brand,
    )
  },

  reports(brand?: string) {
    const newSearchParams: ISearchParams = {}
    return getUrlPathWithSearchParams('/reports', false, newSearchParams, brand)
  },
}

export default navigator
