import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

interface IDataType {
  trendLocationGroupMetricValues: {
    nodes: {
      startDate: string
      endDate: string
      metricData: Record<
        typeof METRIC_CODES[number],
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
    }[]
  }
}

const query = gql`
  query trendLocationGroupMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationGroupIds: [Int!]!
    $iMetricCodes: [String!]!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "business_month"
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        startDate
        endDate
        metricData
      }
    }
  }
`

export const bbbTrendPeriodMetricConfigs = {
  period: 'string',
  totalFoodAndPaperPercent: 'percent',
  totalLaborPercent: 'percent',
  totalControllableExpensesWithBankChargesAndThirdPartyFeesPercent: 'percent',
  ebidtaWithBonusPercent: 'percent',
  restaurantLevelProfitPercent: 'percent',
} as const

const METRIC_CODES = [
  'total_sales',
  'total_food_and_paper',
  'total_labor',
  'total_controllable_expenses_with_bank_charges_and_third_party_fees',
  'ebidta_with_bonus',
  'restaurant_level_profit',
] as const

const useBbbTrendPeriodMetric = () => {
  const { variables } = useVariables()
  const trendPeriods = variables.date?.getCalendar('period')?.calendar[0] || []
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: trendPeriods[0]?.dateRange.startDateStr,
      iEndDate: trendPeriods.slice(-1)[0]?.dateRange.endDateStr,
      // Fixed Comp location group
      iLocationGroupIds: [30],
      iMetricCodes: METRIC_CODES,
    },
    skip: !variables.date,
  })

  return {
    data: useMemo((): IApiDataType => {
      const metricData = data?.trendLocationGroupMetricValues.nodes

      if (!metricData) return null

      return metricData
        .map((data) => {
          const period = trendPeriods.find(
            (t) =>
              t.dateRange.startDateStr === data.startDate &&
              t.dateRange.endDateStr === data.endDate,
          )

          return {
            period: period?.id,
            totalFoodAndPaperPercent:
              (data.metricData.total_food_and_paper.value * 100) /
              data.metricData.total_sales.value,
            totalLaborPercent:
              (data.metricData.total_labor.value * 100) /
              data.metricData.total_sales.value,
            totalControllableExpensesWithBankChargesAndThirdPartyFeesPercent:
              (data.metricData
                .total_controllable_expenses_with_bank_charges_and_third_party_fees
                .value *
                100) /
              data.metricData.total_sales.value,
            ebidtaWithBonusPercent:
              (data.metricData.ebidta_with_bonus.value * 100) /
              data.metricData.total_sales.value,
            restaurantLevelProfitPercent:
              (data.metricData.restaurant_level_profit.value * 100) /
              data.metricData.total_sales.value,
          }
        })
        .filter((d) => d.period)
    }, [data, trendPeriods]),
    loading,
  }
}

export default useBbbTrendPeriodMetric
