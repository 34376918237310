import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { track } from 'pared/analytics/storeList'
import { IColumnsType } from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'

import { IDataSourceType } from '../types'
import { getTotalPrice, plus } from '../utils'

const Span = styled.span<{ danger: boolean }>`
  color: ${({ danger }) => (danger ? 'red' : COLORS.Chalkboard)};
`

const LinkButton = styled(Link)`
  color: ${COLORS.Link};
`

const useColumns = (
  isBreakdownByDirectors: boolean,
): IColumnsType<IDataSourceType>[] => {
  const brandSettings = getBrandSettings()
  const businessLabels = brandSettings.labels.business
  const financialLabels = brandSettings.labels.financial

  return useMemo(() => {
    return [
      ...((isBreakdownByDirectors
        ? []
        : [
            {
              key: 'storeCode',
              title: 'Store',
              minWidth: '150px',
              render: (_, values, index) => {
                if (index === -1) return 'TOTAL'
                if (!('storeId' in values)) return null

                return (
                  <LinkButton
                    to={navigator.storeDetail(values.storeId)}
                    onClick={() => track.locationLinkClicked(values.storeId)}
                  >
                    {values.storeCode} - {values.storeName}
                  </LinkButton>
                )
              },
              sorter: true,
            },
          ]) as IColumnsType<IDataSourceType>[]),
      {
        key: 'directorName',
        title: businessLabels['director'],
        minWidth: '150px',
        render: (
          value: IDataSourceType['directorName'],
          { directorId },
          index,
        ) => {
          if (index === -1) return !isBreakdownByDirectors ? '' : 'TOTAL'

          return businessLabels.isDirectorClickable ? (
            <LinkButton
              to={navigator.employeeProfile(directorId)}
              onClick={() => track.directorProfileLinkClicked(directorId)}
            >
              {value}
            </LinkButton>
          ) : (
            value
          )
        },
        sorter: true,
      },
      {
        key: 'currentSales',
        title: 'Current Sales',
        minWidth: '150px',
        align: 'center',
        render: (value) => {
          const { result, isNegative } = getTotalPrice(value)

          return <Span danger={isNegative}>{result}</Span>
        },
        sorter: true,
      },
      {
        key: 'yoySales',
        title: 'Previous Year',
        minWidth: '150px',
        align: 'center',
        render: (value) => {
          const { result, isNegative } = getTotalPrice(value)

          return <Span danger={isNegative}>{result}</Span>
        },
        sorter: true,
      },
      {
        key: 'salesGrow',
        title: '% vs LY',
        minWidth: '150px',
        align: 'center',
        render: (value: IDataSourceType['salesGrow']) => (
          <Span danger={value < 0}>
            {value === 0 ? '0%' : `${(100 * value).toFixed(2)}%`}
          </Span>
        ),
        sorter: true,
      },
      {
        key: 'budget',
        title: `% vs ${financialLabels.projected}`,
        minWidth: '150px',
        align: 'center',
        render: (value: IDataSourceType['budget']) => {
          if (!value) return '-'

          return (
            <Span danger={value < 0}>
              {value === 0 ? '0%' : `${(100 * value).toFixed(2)}%`}
            </Span>
          )
        },
        sorter: true,
      },
    ]
  }, [isBreakdownByDirectors])
}

export default useColumns
