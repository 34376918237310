import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import styled from 'styled-components'

import { COMP_TYPES_DISPLAY_NAME, EE_COMPS_METRICS } from 'pared/constants'
import COLORS from 'pared/constants/colors'
import { toPercentageString, toUsdString } from 'pared/utils/number'

import { IOpsComp } from './index'

interface IProps {
  opsComps: IOpsComp[]
  rankedOpsComps: IOpsComp[]
  isOpsCompsExpanded: boolean
  setIsOpsCompsExpanded: any
  isLoading: boolean
  selectedMetrics: string
  setSelectedMetrics: any
}

interface IRankTableTh {
  textAlign?: string
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
}

interface IRankText {
  italic?: boolean
}

const WebSmallMain = ({
  opsComps,
  rankedOpsComps,
  isOpsCompsExpanded,
  setIsOpsCompsExpanded,
  isLoading,
  selectedMetrics,
  setSelectedMetrics,
}: IProps) => {
  if (isLoading) {
    return (
      <MainContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </MainContainer>
    )
  }

  const onSelectMetrics = async (event: any) => {
    const value = _.get(event, 'target.value', '')
    setSelectedMetrics(value)
  }

  const onToggleExpandOpsComps = () => {
    setIsOpsCompsExpanded(!isOpsCompsExpanded)
  }

  const formatCompTypes = (compTypes: any) => {
    const compTypeArr = _.compact(
      _.map(compTypes, (count, compTypeKey) => {
        if (!_.isNull(count) && count !== 0) {
          const compType = _.get(COMP_TYPES_DISPLAY_NAME, [compTypeKey], '')
          return `${compType} (${count})`
        }
      }),
    )
    return compTypeArr.join(', ')
  }

  let selectedMetricsTh
  let selectedMetricsTd

  const opsCompsRows = _.map(rankedOpsComps, (o) => {
    const rank = _.findIndex(rankedOpsComps, o) + 1
    const firstName = _.startCase(_.get(o, 'firstName', ''))
    const lastName = _.startCase(_.get(o, 'lastName', ''))
    const name = `${firstName} ${lastName}`
    const role = _.startCase(_.get(o, 'role', ''))
    const totalOpsComps = _.get(o, 'totalOpsComps', 0)
    const totalGross = _.get(o, 'totalGross', 0)
    const percentOpsCompOfGross = _.get(o, 'percentOpsCompOfGross', 0)
    const compTypes = _.get(o, 'compTypes', [])

    switch (selectedMetrics) {
      case EE_COMPS_METRICS.EE_OPS_COMPS: {
        selectedMetricsTd = (
          <RankTableTd>
            {toUsdString(Math.abs(totalOpsComps) / 100.0, 2)}
          </RankTableTd>
        )
        break
      }
      case EE_COMPS_METRICS.EE_GROSS_SALES: {
        selectedMetricsTd = (
          <RankTableTd>{toUsdString(totalGross / 100.0, 2)}</RankTableTd>
        )
        break
      }
      case EE_COMPS_METRICS.OPS_COMP_RATE: {
        selectedMetricsTd = (
          <RankTableTd>
            {toPercentageString(percentOpsCompOfGross / 100.0, 1)}
          </RankTableTd>
        )
        break
      }
      default: {
        selectedMetricsTd = (
          <RankTableTd>
            {toUsdString(Math.abs(totalOpsComps) / 100.0, 2)}
          </RankTableTd>
        )
      }
    }

    return (
      <tr>
        <RankNumberTd bold textAlign={'left'}>
          {rank}
        </RankNumberTd>
        <RankTableTd textAlign={'left'}>
          <RankText>{name}</RankText>
          <RankText italic>{role}</RankText>
          <RankText italic>{formatCompTypes(compTypes)}</RankText>
        </RankTableTd>
        {selectedMetricsTd}
      </tr>
    )
  })

  let toggledStaffSalesRows = opsCompsRows

  if (!isOpsCompsExpanded) {
    toggledStaffSalesRows = _.slice(opsCompsRows, 0, 4)
  }

  const opsCompsToggleText = isOpsCompsExpanded ? 'Collapse all' : 'Show All'

  const expandSpan =
    _.size(opsCompsRows) >= 5 ? (
      <ExpandAllSpan onClick={onToggleExpandOpsComps}>
        {opsCompsToggleText}
      </ExpandAllSpan>
    ) : null

  switch (selectedMetrics) {
    case EE_COMPS_METRICS.EE_OPS_COMPS: {
      selectedMetricsTh = <RankTableTh>EE Ops Comps</RankTableTh>
      break
    }
    case EE_COMPS_METRICS.EE_GROSS_SALES: {
      selectedMetricsTh = <RankTableTh>EE Gross Sales</RankTableTh>
      break
    }
    case EE_COMPS_METRICS.OPS_COMP_RATE: {
      selectedMetricsTh = <RankTableTh>Ops Comp Rate</RankTableTh>
      break
    }
    default: {
      selectedMetricsTh = <RankTableTh>EE Ops Comps</RankTableTh>
    }
  }

  return (
    <>
      <OpsCompsContainer>OPS COMPS BREAKDOWN</OpsCompsContainer>
      <CompsFormControl
        variant="outlined"
        margin="dense"
        style={{ width: '90vw' }}
      >
        <SalesmanSelect
          displayEmpty
          value={selectedMetrics}
          onChange={onSelectMetrics}
        >
          <MenuItem key="eeOpsComps" value="eeOpsComps">
            EE Ops Comps
          </MenuItem>
          <MenuItem key="eeGrossSales" value="eeGrossSales">
            EE Gross Sales
          </MenuItem>
          <MenuItem key="opsCompRate" value="opsCompRate">
            Ops Comp Rate
          </MenuItem>
        </SalesmanSelect>
      </CompsFormControl>
      <CompsTableContainer>
        <RankTable>
          <thead>
            <tr>
              <RankTableTh textAlign={'left'}>Rank</RankTableTh>
              <RankTableTh textAlign={'left'}>Name</RankTableTh>
              {selectedMetricsTh}
            </tr>
          </thead>
          <tbody>{toggledStaffSalesRows}</tbody>
        </RankTable>
      </CompsTableContainer>
      {expandSpan}
    </>
  )
}

const MainContainer = styled.div``

const CompsFormControl = styled(FormControl)`
  background-color: white;
`

const SalesmanSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const RankText = styled.div<IRankText>`
  margin-top: 5px;
  font-size: ${(props) => (props.italic ? '12px' : '14px')};
  font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const CompsTableContainer = styled.div`
  margin-top: 30px;
  width: 90vw;
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  float: left;
`

const OpsCompsContainer = styled(PageHeader)`
  margin-bottom: 25px;
  font-size: 16px;
`

const RankTableTh = styled.th<IRankTableTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankTableTd = styled.td<IRankTableTd>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankNumberTd = styled(RankTableTd)`
  padding-left: 12px;
`

const RankTable = styled.table`
  width: 90vw;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }
`

const ExpandAllSpan = styled.div`
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;
  float: right;
`

export default WebSmallMain
