import { gql } from '@apollo/client'

export interface IDiscountCampaignVariableTypes {
  iStartDate: string
  iEndDate: string
  iFilter: any
}

export interface IDiscountCampaignNodeType {
  itemType: string
  totalItemCounts: number
  checkAvg: number
  ppa: number
}

export const LIST_LOCATION_GROUP_DISCOUNT_CAMPAIGN = gql`
  query ListLocationGroupDiscountCampaign(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupDiscountCampaign(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        itemType
        totalItemCounts
        totalItemDiscounts
        checkAvg
        ppa
      }
    }
  }
`
