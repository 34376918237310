import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useGroupFilter } from '../../../groupFilter'

export type TrendNode = {
  locationGroupId: number
  locationGroupName: string
  startDate: string
  endDate: string
  itemCode: string
  itemName: string
  categoryName: string
  quantitySold?: number | null
  itemSales?: number | null
  percentOfSales?: number | null
  businessWeek: number
  businessWeekOfMonth: number
  businessMonth: number
  businessQuarter: number
  businessYear: number
}

type IDataType = {
  trendLocationGroupItemMetrics: {
    nodes: TrendNode[]
  }
}

const query = gql`
  query trendLocationGroupItemMetrics(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    trendLocationGroupItemMetrics(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
      iGroupBy: "business_week"
    ) {
      nodes {
        locationGroupId
        locationGroupName
        startDate
        endDate
        itemCode
        itemName
        categoryName
        quantitySold
        itemSales
        percentOfSales
        businessWeek
        businessWeekOfMonth
        businessMonth
        businessQuarter
        businessYear
      }
    }
  }
`

interface BuildItemMetricTrendsHookOptions {
  metrics: string[]
  startDate: string
  endDate: string
}

function buildItemMetricTrendsHook<T>(handler: (nodes: TrendNode[]) => T): (
  options: BuildItemMetricTrendsHookOptions,
) => {
  data: T | null
  loading: boolean
} {
  const useItemMetricTrends = (
    options: BuildItemMetricTrendsHookOptions,
  ): { data: T | null; loading: boolean } => {
    const { groupFilter } = useGroupFilter()
    const iFilter = {
      location_group_ids: groupFilter?.ids,
      metrics: options.metrics,
    }

    const { data, loading } = useQuery<IDataType>(query, {
      variables: {
        iStartDate: options.startDate,
        iEndDate: options.endDate,
        iFilter,
      },
      skip: !groupFilter,
    })

    const processedData = useMemo<T | null>(() => {
      if (!data) return null

      const nodes = data.trendLocationGroupItemMetrics.nodes

      return handler(nodes)
    }, [data, handler])

    return {
      data: processedData,
      loading,
    }
  }

  return useItemMetricTrends
}

export default buildItemMetricTrendsHook
