import { gql } from '@apollo/client'

export const LOCATION_GROUP_GUEST_KPIS = gql`
  query LocationGroupGuestKpis(
    $iEndDate: Date!
    $iStartDate: Date!
    $iLocationGroupId: Int!
  ) {
    locationGroupGuestKpisV2(
      iEndDate: $iEndDate
      iStartDate: $iStartDate
      iLocationGroupId: $iLocationGroupId
    ) {
      nodes {
        ratingScore
        ratingCount
        reviewSource
        simpleAverageRatingScore
      }
    }
  }
`

export const LOCATION_GROUP_GUEST_KPIS_WITH_TIME_INTERVAL = gql`
  query LocationGroupGuestKpisWithTimeInterval(
    $iEndDate: Date!
    $iGroupBy: String!
    $iLocationGroupId: Int!
  ) {
    locationGroupGuestKpisWithTimeInterval(
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iLocationGroupId: $iLocationGroupId
    ) {
      nodes {
        businessYear
        businessQuarter
        businessMonth
        businessWeekOfMonth
        ratings
      }
    }
  }
`

export const TREND_LOCATION_GROUP_OPS_COMPS = gql`
  query TrendLocationGroupOpsComps(
    $iLocationGroupId: Int!
    $iEndDate: Date!
    $iGroupBy: String!
  ) {
    trendLocationGroupOpsComps(
      iLocationGroupId: $iLocationGroupId
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) {
      nodes {
        locationGroupId
        startDate
        endDate
        businessYear
        businessQuarter
        businessMonth
        businessWeekOfMonth
        opsComps
        grossSales
        opsCompsPercent
      }
    }
  }
`

export const LIST_LOCATION_GUEST_KPIS = gql`
  query ListLocationGuestKpis(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGuestKpis: listLocationGuestKpisV2(
      iEndDate: $iEndDate
      iStartDate: $iStartDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        locationInfo {
          code
          name
        }
        opsCompsPercent
        ratings
        ratingCounts
      }
    }
  }
`

export const LIST_LOCATION_GROUP_GUEST_KPIS = gql`
  query listLocationGroupGuestKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupGuestKpis: listLocationGroupGuestKpisV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        employeeInfo {
          id
          firstName: preferredName
          lastName: familyName
        }
        opsCompsPercent
        ratings
        ratingCounts
        locationGroupId
        locationGroupName
      }
    }
  }
`

export const LIST_LOCATION_GROUP_SMG_KPIS = (brand: string) => gql`
  query ListLocationGroupSmgKpis(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupSmgKpis: listLocationGroupSmgKpis(
      iEndDate: $iEndDate
      iStartDate: $iStartDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        totalSurveys
        ${
          brand === 'wingitnorth'
            ? 'dissatisfactionPercent inaccuratePercent'
            : ''
        }
        ${
          ['mwb', 'burgerworks'].includes(brand)
            ? 'totalOutstandingSurveys overallSatisfaction orderNotOnTime tasteOfFood friendlinessOfEmployees'
            : ''
        }
      }
    }
  }
`

export const LIST_LOCATION_SMG_KPIS = (brand: string) => gql`
  query ListLocationSmgKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationSmgKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        totalSurveys
        ${
          brand === 'wingitnorth'
            ? 'dissatisfactionPercent inaccuratePercent'
            : ''
        }
        ${
          ['mwb', 'burgerworks'].includes(brand)
            ? 'totalOutstandingSurveys overallSatisfaction orderNotOnTime tasteOfFood friendlinessOfEmployees'
            : ''
        }
      }
    }
  }
`

export const LIST_LOCATION_GUEST_OPENTABLE_KPIS = gql`
  query ListLocationGuestOpentableKpis(
    $iFilter: JSON!
    $iEndDate: Date!
    $iStartDate: Date!
  ) {
    listLocationGuestOpentableKpis(
      iFilter: $iFilter
      iEndDate: $iEndDate
      iStartDate: $iStartDate
    ) {
      nodes {
        locationId
        locationInfo {
          id
          code
          name
        }
        ratingScore
        ratingCount
        ratingScoreChange
        seatedCovers
        seatedCoversChange
        phoneCovers
        phoneCoversChange
        walkinCovers
        walkinCoversChange
        yourNetworkCovers
        yourNetworkCoversChange
        opentableCovers
        opentableCoversChange
        noShowRate
        noShowRateChange
        ratingRank
      }
    }
  }
`

export const LIST_LOCATION_GROUP_GUEST_OPENTABLE_KPIS = gql`
  query listLocationGroupGuestOpentableKpis(
    $iFilter: JSON!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationGroupGuestOpentableKpis(
      iFilter: $iFilter
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        employeeInfo {
          id
          firstName: preferredName
          lastName: familyName
        }
        ratingScore
        ratingCount
        ratingScoreChange
        seatedCovers
        seatedCoversChange
        phoneCovers
        phoneCoversChange
        walkinCovers
        walkinCoversChange
        yourNetworkCovers
        yourNetworkCoversChange
        opentableCovers
        opentableCoversChange
        noShowRate
        noShowRateChange
      }
    }
  }
`
