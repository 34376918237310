import { useMemo } from 'react'

import { acePopConfig } from 'pared/customer/lossPrevention/ace_pop'
import { bibibopConfig } from 'pared/customer/lossPrevention/bibibop'
import { ghaiPopConfig } from 'pared/customer/lossPrevention/ghai_pop'
import { irmgBkConfig } from 'pared/customer/lossPrevention/irmg_bk'
import { irmgPopConfig } from 'pared/customer/lossPrevention/irmg_pop'
import { jitbConfig } from 'pared/customer/lossPrevention/jitb'
import { jscBkConfig } from 'pared/customer/lossPrevention/jsc_bk'
import { mbnBkConfig } from 'pared/customer/lossPrevention/mbn_bk'
import { mwbConfig } from 'pared/customer/lossPrevention/mwb'
import { supremeBkConfig } from 'pared/customer/lossPrevention/supreme_bk'
import { supremePopConfig } from 'pared/customer/lossPrevention/supreme_pop'
import { timoneyBkConfig } from 'pared/customer/lossPrevention/timoney_bk'
import { vikingBkConfig } from 'pared/customer/lossPrevention/viking_bk'
import { wingitnorthConfig } from 'pared/customer/lossPrevention/wingitnorth'
import { getBrand } from 'pared/utils/brand'

interface IDiscountColumnType {
  key: string
  title: string
  colSpan?: number
  isCountVisible?: boolean
  isAmountVisible?: boolean
  isCompsPercentVisible?: boolean
  children?: IDiscountColumnType[]
}

interface IDiscountTableConfigType {
  isVisible: boolean
  isTotalCountVisible?: boolean
  isTotalAmountVisible?: boolean
  isCompsPercentVisible?: boolean
  discountColumns: IDiscountColumnType[]
}

export interface ICashControlColumnType {
  key: string
  title: string
  valueType?: 'count' | 'price' | 'percent'
  width?: string
  children?: ICashControlColumnType[]
}

interface ICashControlTableConfigType {
  isVisible: boolean
  cashControlColumns: ICashControlColumnType[]
}

interface ITrendChartConfigType {
  isVisible: boolean
  yLeftAxisLabel: string
  yLeftAxisValueType: string
  yLeftAxisKpiDefs: {
    key: string
    name: string
    kpiKey: string
    valueType: string
    color: string
    hidden?: boolean
  }[]
}

export interface IBasicKpiType {
  category: 'basic'
  key:
    | 'cashOverOrShort'
    | 'compsTotalCount'
    | 'compsTotalAmount'
    | 'compsPercent'
    | 'inaccurateCashierShiftPercent'
    | 'oloOverOrShort'
  title: string
}

export interface ILossPreventionItemsType {
  category: 'custom'
  type: string
  title: string
  key: 'amount' | 'count' | 'checkPercent'
}

export type IKpiSettingType = IBasicKpiType | ILossPreventionItemsType

export interface ILossPreventionConfigType {
  kpiSettings: IKpiSettingType[]
  discountTable: IDiscountTableConfigType
  discountTrendChart: ITrendChartConfigType
  cashControlTable: ICashControlTableConfigType
  cashControlTrendChart: ITrendChartConfigType
}

const defaultConfig: ILossPreventionConfigType = {
  kpiSettings: [
    { category: 'basic', key: 'compsTotalCount', title: 'Total Discounts' },
    { category: 'basic', key: 'cashOverOrShort', title: 'Cash +/-' },
    {
      category: 'custom',
      type: 'Over Ring',
      title: '% Checks with Over Ring',
      key: 'checkPercent',
    },
    {
      category: 'custom',
      type: 'Deal after Total',
      title: '% Checks with Deletes',
      key: 'checkPercent',
    },
    {
      category: 'basic',
      key: 'inaccurateCashierShiftPercent',
      title: '% Shifts with Inaccurate Cash',
    },
  ],

  discountTable: {
    isVisible: true,
    discountColumns: [],
  },

  discountTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '',
    yLeftAxisValueType: '',
    yLeftAxisKpiDefs: [],
  },

  cashControlTable: {
    isVisible: true,
    cashControlColumns: [
      {
        key: 'cashOverOrShort',
        title: 'Cash +/-',
        width: '150px',
        children: [
          {
            key: 'cashOverOrShort.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'cashOverOrShort.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Over Ring',
        title: 'Over Ring',
        width: '150px',
        children: [
          {
            key: 'Over Ring.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'Over Ring.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Deal after Total',
        title: 'Deletes',
        width: '150px',
        children: [
          {
            key: 'Deal after Total.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'Deal after Total.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'cashOverOrShort.checkPercent',
        title: '% Shifts with Inaccurate Cash',
        valueType: 'percent',
        width: '150px',
      },
      {
        key: 'Over Ring.checkPercent',
        title: '% Checks w/ Over Ring',
        valueType: 'percent',
        width: '150px',
      },
      {
        key: 'Deal after Total.checkPercent',
        title: '% Checks w/ Deletes',
        valueType: 'percent',
        width: '150px',
      },
    ],
  },

  cashControlTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '',
    yLeftAxisValueType: '',
    yLeftAxisKpiDefs: [],
  },
}

const useLossPreventionConfig = (): ILossPreventionConfigType => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'irmg_bk':
      case 'demo_bk':
        return irmgBkConfig
      case 'supreme_pop':
        return supremePopConfig
      case 'demo_pop':
      case 'irmg_pop':
        return irmgPopConfig
      case 'ghai_pop':
        return ghaiPopConfig
      case 'jsc_bk':
      case 'jsc_bk_nso':
        return jscBkConfig
      case 'viking_bk':
      case 'sandbox_bk':
        return vikingBkConfig
      case 'supreme_bk':
        return supremeBkConfig
      case 'timoney_bk':
        return timoneyBkConfig
      case 'mbn_bk':
        return mbnBkConfig
      case 'jitb':
        return jitbConfig
      case 'wingitnorth':
        return wingitnorthConfig
      case 'bibibop':
        return bibibopConfig
      case 'mwb':
      case 'burgerworks':
        return mwbConfig
      case 'ace_pop':
        return acePopConfig
      default:
        return defaultConfig
    }
  }, [brand])
}
export default useLossPreventionConfig
