import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { track } from 'pared/analytics/delivery'
import { DELIVERY_CATEGORY } from 'pared/constants'
import { getBrand } from 'pared/utils/brand'
import { toPercentageString } from 'pared/utils/number'

import { LIST_LOCATION_DELIVERY_STAFF_KPI } from '../gql'
import useDeliveryConfig from '../hooks/useDeliveryConfig'
import Main from './Main'

export interface IOrderBy {
  columnName: string
  isAscending: boolean
}

export interface IStaffKpi {
  employeeName: string
  roleName: string
  totalOrderCount: number
  inaccurateOrderPercent: string
  cancelledOrderPercent: string
  delayedOrderPercent: string
  avgCustomerReviewScore: string
  totalReviewCount: number
}

interface IProps {
  locationId: number
  startDate: string
  endDate: string
}

function StaffTable({ locationId, startDate, endDate }: IProps) {
  const deliveryConfig = useDeliveryConfig(getBrand())
  let defaultSelectOption = DELIVERY_CATEGORY.INACCURATE_ORDER_PERCENT

  if (deliveryConfig['staffAndDayTrendsFilter']) {
    defaultSelectOption = deliveryConfig['staffAndDayTrendsFilter'].default
  }

  const [staffDeliveryCategory, setStaffDeliveryCategory] =
    useState<string>(defaultSelectOption)

  const onSelectStaffDeliveryCategory = (event: any) => {
    setStaffDeliveryCategory(event.target.value)
    track.staffCategoryChanged(event.target.value)
  }

  const [
    listLocationDeliveryStaffKpi,
    {
      loading: listLocationDeliveryStaffKpiLoading,
      error: listLocationDeliveryStaffKpiError,
      data: listLocationDeliveryStaffKpiData,
    },
  ] = useLazyQuery(LIST_LOCATION_DELIVERY_STAFF_KPI)

  useEffect(() => {
    async function fetchData() {
      listLocationDeliveryStaffKpi({
        variables: {
          iLocationId: locationId,
          iStartDate: startDate,
          iEndDate: endDate,
        },
      })
    }

    fetchData()
  }, [locationId, startDate, endDate])

  let staffKpis: IStaffKpi[] = []

  if (
    listLocationDeliveryStaffKpiData &&
    listLocationDeliveryStaffKpiData.listLocationDeliveryStaffKpi &&
    Array.isArray(
      listLocationDeliveryStaffKpiData.listLocationDeliveryStaffKpi.nodes,
    )
  ) {
    const rawStaffKpis = _.get(
      listLocationDeliveryStaffKpiData,
      'listLocationDeliveryStaffKpi.nodes',
      [],
    )

    staffKpis = _.map(rawStaffKpis, (k) => {
      const employeeFirstName = _.startCase(
        _.get(k, 'employeeInfo.firstName', ''),
      )
      const employeeLastName = _.startCase(
        _.get(k, 'employeeInfo.lastName', ''),
      )
      const roleName = _.get(k, 'employeeRoleName', '')
      const avgCustomerReviewScore = _.get(k, 'avgCustomerReviewScore', 0)

      let displayRoleName = roleName
      if (!deliveryConfig['staffTable']?.hideToGo) {
        if (_.isEmpty(roleName)) {
          displayRoleName = 'ToGo'
        } else if (roleName !== 'ToGo' && roleName !== 'Shift Supervisor') {
          displayRoleName = roleName + '/ToGo'
        }
      }

      if (deliveryConfig['staffTable']?.roleNameOverride) {
        displayRoleName = deliveryConfig['staffTable'].roleNameOverride
      }

      return {
        employeeName: `${employeeFirstName} ${employeeLastName}`,
        roleName: displayRoleName,
        totalOrderCount: _.get(k, 'totalOrderCount', 0),
        inaccurateOrderPercent: toPercentageString(
          _.get(k, 'inaccurateOrderPercent', 0) / 100,
          1,
        ),
        cancelledOrderPercent: toPercentageString(
          _.get(k, 'cancelledOrderPercent', 0) / 100,
          1,
        ),
        delayedOrderPercent: toPercentageString(
          _.get(k, 'delayedOrderPercent', 0) / 100,
          1,
        ),
        avgCustomerReviewScore: avgCustomerReviewScore
          ? avgCustomerReviewScore.toFixed(1)
          : '-',
        totalReviewCount: _.get(k, 'totalReviewCount', 0),
        averageTripTime: (_.get(k, 'averageTripTime', 0) / 60).toFixed(1),
      }
    })
  }

  useEffect(() => {
    setOrderBy({
      columnName: staffDeliveryCategory,
      isAscending: orderBy.isAscending,
    })
  }, [staffDeliveryCategory])

  let defaultSortOrder = 'desc'
  const defaultCategoryConfig = deliveryConfig[
    'staffAndDayTrendsFilter'
  ].options.find(
    ({ variableName }: { variableName: string }) =>
      variableName === staffDeliveryCategory,
  )
  if (defaultCategoryConfig) {
    defaultSortOrder = defaultCategoryConfig.defaultSortOrder || 'desc'
  }

  const [orderBy, setOrderBy] = useState<IOrderBy>({
    columnName: staffDeliveryCategory,
    isAscending: defaultSortOrder === 'asc',
  })

  const rankedStaffKpis = _.sortBy(staffKpis, (i) => {
    const columnName = _.get(orderBy, 'columnName', '')
    const isAscending = orderBy.isAscending ? 1 : -1

    switch (columnName) {
      case staffDeliveryCategory:
        return parseFloat(_.get(i, [columnName])) * isAscending
      case 'totalOrderCount':
        return _.get(i, 'totalOrderCount') * isAscending
      default:
        return _.get(i, [columnName]) * isAscending
    }
  })

  return (
    <Main
      staffKpis={rankedStaffKpis}
      staffDeliveryCategory={staffDeliveryCategory}
      onSelectStaffDeliveryCategory={onSelectStaffDeliveryCategory}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
    />
  )
}

export default StaffTable
