import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import StackedBarChart from 'pared/charts/StackedBarChart'
import DateRangeNumberSelector from 'pared/components/DateRangeNumberSelector'
import useDateRange, {
  IItem,
  LAST_3_PERIODS,
  LAST_4_WEEKS,
  LAST_7_DAYS,
  LAST_13_PERIODS,
} from 'pared/components/DateRangeNumberSelector/hooks/useDateRange'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'
import { DATE_FORMAT, IDateRange } from 'pared/data/getDateRanges'
import { getDateRangeLabelV2 } from 'pared/utils/date'
import { toUsdStr } from 'pared/utils/number'

import { TREND_LOCATION_REV_CENTER_BREAKDOWN } from '../gql'

interface IProps {
  navParams: INavParams
  selectedDateRange: IDateRange | null
  trendSalesTrendStackedBarItems?: string[]
}

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

function SalesTrendStackedBarChart({
  navParams,
  selectedDateRange,
  trendSalesTrendStackedBarItems,
}: IProps) {
  const isLegacyBrand = getBrandSettings().isLegacy

  const storeId = navParams.storeId

  const locationId = parseInt(storeId || '0', 10) || 0

  const [dateRange, setDateRange] = useState<IItem['value']>(LAST_7_DAYS.value)

  const { dateRangeNumber, dateRangeGroupBy } = useDateRange(dateRange)

  const [
    trendLocationRevCenterBreakdown,
    {
      loading: trendLocationRevCenterBreakdownLoading,
      error: trendLocationRevCenterBreakdownError,
      data: trendLocationRevCenterBreakdownData,
    },
  ] = useLazyQuery(TREND_LOCATION_REV_CENTER_BREAKDOWN)

  useEffect(() => {
    const periodEndDate = _.get(selectedDateRange, 'endDateStr', '')

    const newEndDate =
      moment(periodEndDate, DATE_FORMAT) > moment()
        ? moment().format(DATE_FORMAT)
        : periodEndDate

    trendLocationRevCenterBreakdown({
      variables: {
        iLocationId: locationId,
        iEndDate: newEndDate,
        iGroupBy: dateRangeGroupBy,
        iDateRangeNumber: dateRangeNumber,
      },
    })
  }, [locationId, selectedDateRange, dateRange])

  let rawRevCenterBreakdownPeriodically = []

  if (
    trendLocationRevCenterBreakdownData &&
    trendLocationRevCenterBreakdownData.trendLocationRevenueCenterBreakdown &&
    Array.isArray(
      trendLocationRevCenterBreakdownData.trendLocationRevenueCenterBreakdown
        .nodes,
    ) &&
    trendLocationRevCenterBreakdownData.trendLocationRevenueCenterBreakdown
      .nodes.length > 0
  ) {
    rawRevCenterBreakdownPeriodically =
      trendLocationRevCenterBreakdownData.trendLocationRevenueCenterBreakdown
        .nodes
  }

  const groupedRevCenterData = _.groupBy(
    rawRevCenterBreakdownPeriodically,
    'revenueCenter',
  )

  const dates = new Set()
  Object.values(groupedRevCenterData).forEach((revCenterData: any) => {
    revCenterData.forEach((data: any) => {
      const date = _.get(data, 'startDate', '')
      dates.add(date)
    })
  })

  const groupedByStartDates = Array.from(dates).sort()
  let dineInDataPoints: number[] = []
  let barDataPoints: number[] = []

  let startEndDateData: any[] = []

  const groupedRevCenterDataPoints = _.compact(
    _.map(groupedRevCenterData, (data, revenueCenter) => {
      let dataPoints: number[] = []
      _.each(groupedByStartDates, (date) => {
        const dataPoint = _.filter(data, { startDate: date })

        if (_.isEmpty(dataPoint)) {
          dataPoints.push(0)
        } else {
          const revCenterNetSales =
            parseFloat(_.get(_.first(dataPoint), 'revCenterNetSales') || 0) /
            100.0

          dataPoints.push(revCenterNetSales)
        }
      })

      const groupByStartEndDates = _.map(data, (d) => {
        const groupByStartDate = _.get(d, 'startDate')
        const groupByEndDate = _.get(d, 'endDate')

        return {
          groupByStartDate,
          groupByEndDate,
          businessWeek: d.businessWeek,
          businessMonth: d.businessMonth,
          businessQuarter: d.businessQuarter,
          businessYear: d.businessYear,
        }
      })

      // some rev center may not have data in a time group
      // so, get the longest time groups for startEndDateData
      if (groupByStartEndDates.length > startEndDateData.length) {
        startEndDateData = groupByStartEndDates
      }

      if (isLegacyBrand && revenueCenter === 'Dine In') {
        dineInDataPoints = dataPoints
      } else if (isLegacyBrand && revenueCenter === 'Bar') {
        barDataPoints = dataPoints
      } else {
        return {
          revenueCenter,
          dataPoints,
          groupByStartEndDates,
        }
      }
    }),
  )

  if (isLegacyBrand) {
    let inUnitDataPoints: number[] = []

    _.each(dineInDataPoints, (d, index) => {
      const dineInDataPoint = d
      const barDataPoint = _.get(barDataPoints, [index]) || 0
      const inUnitDataPoint = dineInDataPoint + barDataPoint
      inUnitDataPoints.push(inUnitDataPoint)
    })

    groupedRevCenterDataPoints.push({
      dataPoints: inUnitDataPoints,
      revenueCenter: 'In Unit',
    })
  }

  let totalSalesDataPoints: number[] = []

  if (isLegacyBrand) {
    totalSalesDataPoints = _.clone(
      _.get(_.first(groupedRevCenterDataPoints), 'dataPoints', []),
    )
  }

  _.each(groupedRevCenterDataPoints, (d, index) => {
    if (!isLegacyBrand || index !== 0) {
      const dataPoints = _.get(d, 'dataPoints', [])

      _.each(dataPoints, (dataPoint, dataPointIndex) => {
        const originalDataPoint =
          _.get(totalSalesDataPoints, [dataPointIndex]) || 0
        const currentDataPoint = dataPoint
        const total = originalDataPoint + currentDataPoint
        totalSalesDataPoints[dataPointIndex] = total
      })
    }
  })

  const rawYAxisDataArr = _.sortBy(groupedRevCenterDataPoints, (d) => {
    const dp = _.first(_.get(d, 'dataPoints', [])) || 0

    return dp * -1 // descending order
  }).filter((data) => {
    if (trendSalesTrendStackedBarItems)
      return trendSalesTrendStackedBarItems.find(
        (item) => item === data.revenueCenter,
      )

    return true
  })

  const yAxisDataArr = _.map(rawYAxisDataArr, (dataObj, index) => {
    const revenueCenter = _.get(dataObj, 'revenueCenter', '')
    const dataPoints = _.get(dataObj, 'dataPoints', [])

    const color = COLORS.STACKED_BAR_COLOR_HUE[index]

    return {
      type: 'bar',
      label: revenueCenter,
      data: dataPoints,
      tooltipLabel: revenueCenter,
      borderColor: color,
      backgroundColor: color,
    }
  })

  const xAxisData = _.map(startEndDateData, (k) => {
    return getDateRangeLabelV2(dateRangeGroupBy, k)
  })

  const summary = new Array(startEndDateData.length).fill(0)
  for (let i = 0; i < yAxisDataArr.length; i++) {
    const revCenter = yAxisDataArr[i]
    for (let j = 0; j < startEndDateData.length; j++) {
      summary[j] += revCenter.data[j]
    }
  }

  const chartOptions = {
    title: '',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: 'Sales ($)',
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let datasetLabel = ''
        let separator = ''
        let yValue = ''
        if (tooltipItemContext.dataset && tooltipItemContext.dataset.label) {
          datasetLabel = tooltipItemContext.dataset.label
          separator = ': '
        }
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          yValue = tooltipItemContext.parsed.y
            ? toUsdStr(tooltipItemContext.parsed.y)
            : '-'
        }
        return [datasetLabel, separator, yValue].join('')
      }
      return ''
    },
    tooltipTitleCallback: (tooltipItemContext: any) => {
      if (Array.isArray(tooltipItemContext) && tooltipItemContext.length > 0) {
        const dataIndex = tooltipItemContext[0].dataIndex
        const label = tooltipItemContext[0].label
        const total = toUsdStr(summary[dataIndex])
        return `${label}; Total: ${total}`
      }

      return ''
    },
  }

  return (
    <>
      <Container>
        <SectionHeader>SALES TREND</SectionHeader>
        <DateRangeNumberSelector
          value={dateRange}
          onChange={setDateRange}
          dateRangeOptions={[
            LAST_7_DAYS,
            LAST_4_WEEKS,
            LAST_3_PERIODS,
            LAST_13_PERIODS,
          ]}
        />
      </Container>
      <StackedBarChart
        xAxisData={xAxisData}
        yAxisDataArr={yAxisDataArr}
        options={chartOptions}
      />
    </>
  )
}

const Container = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 30px;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    width: 100%;
  }
`

const SectionHeader = styled.div`
  font-family: Lexend-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
`

export default SalesTrendStackedBarChart
