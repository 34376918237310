import { useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import { INullableType } from 'pared/types'
import { getBrand } from 'pared/utils/brand'

import {
  GET_MARKETING_ANALYSIS_ITEMS,
  IGetDiscountItemTypesType,
  IGetDiscountItemTypesVariableTypes,
} from '../gql'

export const ALL = 'All'

interface IReturnType {
  selectedDiscounts: string[]
  discountsChanged: (value: string[]) => void
  discountItems: string[]
}

const useDiscountItems = (
  selectedLocation: number,
  locationGroupIds?: number[],
): IReturnType => {
  const brand = getBrand()

  const iFilter =
    selectedLocation === -1
      ? {
          location_group_ids: [
            ...(locationGroupIds || [BRAND_LOCATION_GROUP_ID[brand]]),
          ],
        }
      : { location_ids: [selectedLocation] }

  const dateRange = useGetDateRange()
  const variables: INullableType<IGetDiscountItemTypesVariableTypes> = {
    iFilter,
    iStartDate: dateRange?.startDateStr,
    iEndDate: dateRange?.endDateStr,
  }
  const { data } = useQuery<
    IGetDiscountItemTypesType,
    INullableType<IGetDiscountItemTypesVariableTypes>
  >(GET_MARKETING_ANALYSIS_ITEMS, {
    variables,
    skip: !variables?.iFilter || !variables?.iStartDate || !variables?.iEndDate,
  })

  const discountItemData = data?.getMarketingAnalysisItems?.nodes
  const [selectedDiscounts, setSelectedDiscounts] = useState<string[]>([])

  const discountItems = useMemo(() => {
    return discountItemData?.map((item) => item.itemType) || []
  }, [selectedLocation, discountItemData])

  return {
    selectedDiscounts,
    discountsChanged: setSelectedDiscounts,
    discountItems,
  }
}

export default useDiscountItems
