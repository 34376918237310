import { useQuery } from '@apollo/client'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { INullableType } from 'pared/types'
import { getBrand } from 'pared/utils/brand'

import {
  GET_STORE_LIST,
  IGetStoreListByBrandType,
  IGetStoreListByBrandVariableTypes,
} from '../gql'

const useStoreList = () => {
  const brand = getBrand()

  const { data: storeList, loading: storeListLoading } = useQuery<
    IGetStoreListByBrandType,
    INullableType<IGetStoreListByBrandVariableTypes>
  >(GET_STORE_LIST, {
    variables: {
      iFilter: {
        location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]],
      },
    },
  })

  return { storeList, storeListLoading }
}

export default useStoreList
