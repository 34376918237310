import TextField from '@mui/material/TextField'
import styled from 'styled-components'

import { CustomizedReportRender } from 'pared/components/CustomizedReport'
import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import Select from 'pared/components/basicUi/select'
import COLORS from 'pared/constants/colors'

import useCategoryFilter, {
  ICategoryFilterType,
} from './hooks/useCategoryFilter'
import useConfig from './hooks/useConfig'
import useFetchPurchaseReportData from './hooks/useFetchPurchaseReportData'
import useItemFilter from './hooks/useItemFilter'

const percentageToColor = (percentage: number, maxHue = 120, minHue = 0) => {
  const hue = percentage * (maxHue - minHue) + minHue
  return `hsl(${hue}, 80%, 80%)`
}

interface IProps {
  locationId: number
  locationName: string
  startDate: string
  endDate: string
  presetItemFilter?: string
  isHeaderVisible?: boolean
  isSummaryVisible?: boolean
  isTableVisible?: boolean
}
const PurchasesTable = ({
  locationId,
  locationName,
  startDate,
  endDate,
  presetItemFilter = '',
  isHeaderVisible = true,
  isSummaryVisible = true,
  isTableVisible = true,
}: IProps) => {
  const config = useConfig()
  const { reportDefinition, isLoading, reportResult, topItems } =
    useFetchPurchaseReportData(locationId, startDate, endDate)
  const {
    categoryFilter,
    setCategoryFilter,
    categoryFilters,
    categoryFilteredCustomizedReportResult,
  } = useCategoryFilter(reportResult)

  const { itemFilter, setItemFilter, itemFilteredCustomizedReportResult } =
    useItemFilter(categoryFilteredCustomizedReportResult, presetItemFilter)

  let finalReportResult = itemFilteredCustomizedReportResult
  let tableData = [...(finalReportResult?.tableData || [])]
  tableData.sort((a, b) => {
    return b.difference - a.difference
  })

  const counts = tableData.reduce(
    (acc, { difference }) => {
      if (difference > 0) {
        acc.positive += 1
      } else {
        acc.negative += 1
      }

      return acc
    },
    {
      positive: 0,
      negative: 0,
    },
  )

  tableData = tableData.map((row, index) => ({
    ...row,
    difference: {
      value: row.difference,
      color: percentageToColor(
        row.difference > 0
          ? (counts.positive - index) / counts.positive
          : (tableData.length - index) / counts.negative,
        row.difference > 0 ? 0 : 80,
        row.difference > 0 ? 60 : 120,
      ),
    },
  }))

  finalReportResult = {
    ...finalReportResult,
    tableData,
  }

  let summaryMessage: string | React.ReactElement = ''
  const percentileRank =
    config.mainKpi === 'COGS%'
      ? tableData[0]?.cogsPercentPercentileRank
      : tableData[0]?.plvPercentPercentileRank
  if (percentileRank <= 33) {
    summaryMessage = `${locationName} is underperforming and has higher ${config.mainKpi} than most of your group.`
  } else if (percentileRank <= 66) {
    summaryMessage = `${locationName} is around average ${config.mainKpi} in your group.`
  } else {
    summaryMessage = `Good Job!  ${locationName} has one of the best ${config.mainKpi} in your group.`
  }

  let areasOfImprovementMessages: string[] = []

  if (topItems.length > 0) {
    areasOfImprovementMessages.push(
      `${topItems
        .map(({ item }, index) =>
          index === topItems.length - 1 && index > 0 ? `and ${item}` : item,
        )
        .join(', ')}
      are being purchased in higher quantities than restaurants with similar sales.
      `,
      `These items represent a $${Math.round(
        topItems.reduce((sum, { difference }) => sum + difference, 0),
      )} opportunity.`,
    )
  } else {
    areasOfImprovementMessages.push(
      `${locationName} is purchasing items as expected for its sales. Keep up the good work!`,
    )
  }

  const contents: React.ReactNode[] = []

  if (isHeaderVisible) {
    contents.push(
      <Header>
        <div>
          <h1>Purchases</h1>
        </div>
      </Header>,
    )
  }

  if (isSummaryVisible && !config.isSummaryHidden) {
    if (contents.length > 0) {
      contents.push(<VerticalSpacer />)
    }
    contents.push(
      <SummaryContainer>
        <ConclusionDiv>
          {isLoading ? 'Loading...' : summaryMessage}
        </ConclusionDiv>
        <InsightDiv>
          <InsightTitle>EXPO INSIGHTS</InsightTitle>
          {(isLoading ? ['Loading...'] : areasOfImprovementMessages).map(
            (message) => (
              <Text>{message}</Text>
            ),
          )}
        </InsightDiv>
      </SummaryContainer>,
    )
  }

  let finalReportDefinition = reportDefinition
  if (reportDefinition) {
    finalReportDefinition = {
      ...reportDefinition,
      columns: reportDefinition.columns
        ?.map((col) => {
          const { customHeaders } = config
          if (customHeaders && col.key in customHeaders) {
            return {
              ...col,
              header: customHeaders[col.key],
            }
          }

          return col
        })
        ?.filter(({ key }) => !(!config.isVendorVisible && key === 'vendor')),
    }
  }

  if (isTableVisible) {
    if (contents.length > 0) {
      contents.push(<VerticalSpacer />)
    }
    contents.push(
      <Container>
        <FilterContainer>
          <span>Category</span>
          <MenuItemSelect<ICategoryFilterType>
            value={categoryFilter}
            onChange={setCategoryFilter}
            dataSource={categoryFilters}
          />

          <span>Item</span>
          <StyledTextField
            value={itemFilter}
            placeholder="Start typing item name..."
            onChange={(e) => setItemFilter(e.target.value)}
          />
        </FilterContainer>
        <CustomizedReportRender
          key={`${startDate} - ${endDate}`}
          reportDefinition={finalReportDefinition}
          reportResult={finalReportResult}
          isLoading={isLoading}
          rowKey={({ item }) => item}
          csvFileName="getexpo-store-purchases"
        />
      </Container>,
    )
  }

  return <>{contents}</>
}

const Header = styled.div`
  display: flex;
  margin-top: 40px;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 0 0 0;
    }
  }
`

const Container = styled.div`
  padding: 0;
  @media ${MOBILE_WIDTH} {
    padding: 30px 0;
  }
`

const MenuItemSelect = styled(Select)`
  width: 300px;
  background-color: white;
  margin: 0 30px 0 15px;

  @media ${MOBILE_WIDTH} {
    width: 100%;
    margin: 10px 0 20px 0;
  }
` as typeof Select

const FilterContainer = styled.div`
  width: 1300px;
  padding: 25px 0;

  span {
    font-family: Lexend-SemiBold;
    font-size: 14px;
    font-weight: 700;
  }

  @media ${MOBILE_WIDTH} {
    text-align: left;
    width: 100%;
    padding: 5px 0 25px 0;

    span {
      display: block;
      padding: 0;
    }
  }
`

const StyledTextField = styled(TextField)`
  width: 300px;
  background-color: white;
  margin: 0 30px 0 15px;
  font-family: Lexend-Regular;

  @media ${MOBILE_WIDTH} {
    width: 100%;
    margin: 10px 0 20px 0;
  }
`

const Text = styled.div`
  font-family: Lexend-Regular;
  font-size: 15px;
  flex: 5;
`

const VerticalSpacer = styled.div`
  height: 40px;
`

const SummaryContainer = styled.div`
  width: 1000px;

  @media ${MOBILE_WIDTH} {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

const ConclusionDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: ${COLORS.Salt};
  padding: 30px;

  border-radius: 10px 10px 0 0;
  background: #0071e3;

  @media ${MOBILE_WIDTH} {
    flex: 1;
  }
`

const InsightDiv = styled.div`
  padding: 30px;
  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};

  border-radius: 0 0 10px 10px;
  border: 1px solid #81abfd;

  @media ${MOBILE_WIDTH} {
    flex: 1;
  }
`

const InsightTitle = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 0 0 15px 0;
`

export default PurchasesTable
