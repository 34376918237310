import { useEffect, useState } from 'react'

import useMergedBody from './useMergedBody'

export interface ISortedFieldOptionType<Data> {
  key: keyof Data | null
  direction: 'ascend' | 'descend'
}

export interface ISortedFieldReturnType<Data> {
  sortedField: ISortedFieldOptionType<Data>
  setSortedField: (options: ISortedFieldOptionType<Data>) => void
}

const useSortedField = <Data>(
  columnsInfo: any,
  defaultSortedField: keyof Data | null,
  defaultSortingDirection: 'ascend' | 'descend' = 'descend',
): ISortedFieldReturnType<Data> => {
  const [sortedField, setSortedField] = useState<ISortedFieldOptionType<Data>>({
    key: defaultSortedField,
    direction: defaultSortingDirection,
  })

  const mergedBody = useMergedBody<Record<string, unknown>>(columnsInfo)

  useEffect(() => {
    const sortedKey = sortedField.key

    if (
      sortedKey &&
      sortedKey !== defaultSortedField &&
      !mergedBody.find(({ key }) => key === sortedKey)?.sortable
    )
      setSortedField({
        key: defaultSortedField,
        direction: 'descend',
      })
  }, [defaultSortedField, mergedBody, sortedField, setSortedField])

  return { sortedField, setSortedField }
}

export default useSortedField
