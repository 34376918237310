import COLORS from 'pared/constants/colors'
import { ILossPreventionConfigType } from 'pared/pages/CorporateLossPrevention/hooks/useLossPreventionConfig'

export const ghaiPopConfig: ILossPreventionConfigType = {
  kpiSettings: [
    { category: 'basic', key: 'compsTotalAmount', title: 'Total Discounts' },
    {
      category: 'basic',
      key: 'compsTotalCount',
      title: 'Total Discount Counts',
    },
    { category: 'basic', key: 'cashOverOrShort', title: 'Cash +/-' },
    { category: 'custom', type: 'Voids', title: 'Voids', key: 'amount' },
    {
      category: 'custom',
      type: 'Voids',
      title: '% Checks with Voids',
      key: 'checkPercent',
    },
    {
      category: 'custom',
      type: 'Cash Refund',
      title: 'Cash Refund',
      key: 'amount',
    },
    {
      category: 'custom',
      type: 'Non-Cash Refund',
      title: 'Non-Cash Refund',
      key: 'amount',
    },
  ],

  discountTable: {
    isVisible: true,
    discountColumns: [
      {
        key: 'Discounts - Military',
        title: 'Military',
      },
      {
        key: 'Discounts - Senior Citizens',
        title: 'Senior Citizens',
      },
      {
        key: 'Discounts - Police',
        title: 'Police',
      },
      {
        key: 'Discounts - Manager',
        title: 'Manager',
      },
      {
        key: 'Discounts - Employee',
        title: 'Employee',
      },
      {
        key: 'Discounts - $ Off Promo',
        title: '$ Off Promo',
      },
      {
        key: 'Guest Recovery',
        title: 'Guest Recovery',
      },
    ],
  },

  discountTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Discount',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [
      {
        key: 'Discounts - Military',
        name: 'Military',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Plum,
        hidden: true,
      },
      {
        key: 'Discounts - Senior Citizens',
        name: 'Senior Citizens',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.DarkOrchid,
        hidden: true,
      },
      {
        key: 'Discounts - Police',
        name: 'Police',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Mango,
        hidden: true,
      },
      {
        key: 'Discounts - Manager',
        name: 'Manager',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Basil,
        hidden: true,
      },
      {
        key: 'Discounts - Employee',
        name: 'Employee',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Pomodoro,
        hidden: true,
      },
      {
        key: 'Discounts - $ Off Promo',
        name: '$ Off Promo',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Acai,
        hidden: true,
      },
      {
        key: 'Guest Recovery',
        name: 'Guest Recovery',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Steel,
        hidden: true,
      },
    ],
  },

  cashControlTable: {
    isVisible: true,
    cashControlColumns: [
      {
        key: 'cashOverOrShort',
        title: 'Cash +/-',
        width: '150px',
        children: [
          {
            key: 'cashOverOrShort.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Voids',
        title: 'Voids',
        width: '150px',
        children: [
          {
            key: 'Voids.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'Voids.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Cash Refund',
        title: 'Cash Refund',
        width: '150px',
        children: [
          {
            key: 'Cash Refund.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'Cash Refund.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Non-Cash Refund',
        title: 'Non-Cash Refund',
        width: '150px',
        children: [
          {
            key: 'Non-Cash Refund.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'Non-Cash Refund.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Voids.checkPercent',
        title: '% Checks with Voids',
        valueType: 'percent',
        width: '150px',
      },
    ],
  },

  cashControlTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Amount',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [
      {
        key: 'cashOverOrShort',
        name: 'Cash +/- $',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Pomodoro,
      },
      {
        key: 'Voids',
        name: 'Voids $',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Plum,
        hidden: true,
      },
      {
        key: 'Cash Refund',
        name: 'Cash Refund $',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Mango,
        hidden: true,
      },
      {
        key: 'Non-Cash Refund',
        name: 'Non-Cash Refund $',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Basil,
        hidden: true,
      },
    ],
  },
}
