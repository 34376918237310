import * as d3 from 'd3-scale'
import styled from 'styled-components'

interface IProps {
  number: number
  min: number
  max: number
  showTitle?: boolean
  children?: string | number
}

interface ICircleProps {
  position: number
}

interface ITickProps {
  position: number
}

const getColorFromPosition = d3
  .scaleLinear<string>()
  .domain([0, 0.5, 1])
  .range(['hsl(0, 100%, 50%)', 'hsl(0, 0%, 50%)', 'hsl(0, 0%, 0%)'])

const getPosition = (num: number, min: number, max: number) =>
  d3.scaleLinear().domain([min, max]).range([0, 1])(num)

const NumberScale = ({
  number,
  min,
  max,
  children = number,
  showTitle = false,
}: IProps) => (
  <Wrapper>
    {!showTitle ? null : (
      <Title>
        <span>{min}</span>

        <span>{max}</span>
      </Title>
    )}

    <Container>
      <Line />
      <Tick position={0} />
      <Tick position={0.5} />
      <Tick position={1} />
      <Circle position={getPosition(number, min, max)}>{children}</Circle>
    </Container>
  </Wrapper>
)

export default NumberScale

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

// FIXME: This would fail with other numbers and should be rewritten with SVG.
const Title = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    width: 30px;
  }
`

const Container = styled.div`
  height: 30px;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: auto;
`

const Line = styled.div`
  height: 1px;
  width: 180px;
  background-color: black;
`

const Tick = styled.div<ITickProps>`
  height: 10px;
  width: 1px;
  background-color: black;
  position: absolute;
  left: calc(15px + ${(props) => props.position} * 180px);
`

const Circle = styled.div<ICircleProps>`
  border-radius: 50%;
  background-color: ${(props) => getColorFromPosition(props.position)};
  color: white;
  width: 40px;
  height: 40px;
  position: absolute;
  left: calc(${(props) => props.position} * 180px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`
