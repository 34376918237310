import { IRouteType } from '../../types'
import storeHome from './storeHome'
import wingitnorthDailyFlash from './wingitnorthDailyFlash'
import wingitnorthSystemwideOverview from './wingitnorthSystemwideOverview'

export const routes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Systemwide Overview',
        link: '/:brand/systemwide_overview',
        default: true,
      },
      {
        title: 'Daily Flash',
        link: '/:brand/daily_flash',
        default: true,
      },
    ],
  },
  {
    title: 'Store',
    children: [
      {
        title: 'Home',
        link: '/:brand/scorecard',
      },
    ],
  },
]

export default [storeHome, wingitnorthDailyFlash, wingitnorthSystemwideOverview]
