import { useQuery } from '@apollo/client'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import Chart from 'pared/charts'
import Select from 'pared/components/basicUi/select'
import Spin from 'pared/components/basicUi/spin'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import {
  GROUP_BY_PERIOD,
  GROUP_BY_QUARTER,
  GROUP_BY_YEAR,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_YEAR,
} from 'pared/data/getDateRanges'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import useGetDirectors from 'pared/hooks/useGetDirectors'

import ChartContainer from './ChartContainer'
import SubHeader from './SubHeader'
import {
  IGetProductHistoricalPerformanceListMenuItemsNodesType,
  IGetProductHistoricalPerformanceType,
  IGetProductHistoricalPerformanceVariablesType,
  getProductHistoricalPerformance,
} from './gqls/historicalPerformance'
import useHistoricalPerformanceConfig from './hooks/useHistoricalPerformanceConfig'

interface IPropsType
  extends Pick<ReturnType<typeof useGetDirectors>, 'locationGroupIds'> {
  dateRange: ReturnType<typeof useGetDateRange>
}

const MenuItemSelect = styled(Select)`
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
` as typeof Select

const HistoricalPerformance = ({ dateRange, locationGroupIds }: IPropsType) => {
  const [item, setItem] = useState<string[]>([])
  const prevItemRef = useRef(item)
  const { data, previousData, loading } = useQuery<
    IGetProductHistoricalPerformanceType,
    IGetProductHistoricalPerformanceVariablesType
  >(getProductHistoricalPerformance, {
    variables: {
      iStartDate: dateRange?.startDateStr || '',
      iEndDate: dateRange?.endDateStr || '',
      iGroupBy:
        {
          [TYPE_YEAR]: GROUP_BY_YEAR,
          [TYPE_QUARTER]: GROUP_BY_QUARTER,
          [TYPE_PERIOD]: GROUP_BY_PERIOD,
        }[dateRange?.type || TYPE_PERIOD] || GROUP_BY_PERIOD,
      iFilter: {
        location_group_ids: locationGroupIds,
      },
      iItemCategory: {
        item: item?.[1] || null,
      },
      notSelected: !item?.[1],
    },
    skip: !dateRange,
  })
  const listMenuItemCosting =
    (data || previousData)?.listMenuItemCosting || null
  const listMenuItems = (data || previousData)?.listMenuItems || null
  const historicalPerformanceConfig = useHistoricalPerformanceConfig(
    (!data ? prevItemRef.current : item).join(' / '),
    listMenuItemCosting?.nodes || [],
    dateRange?.type || TYPE_PERIOD,
  )
  const dataSource = useMemo(
    () => [
      { id: 'root', parentId: null, value: 'root', text: 'root' },
      ...(listMenuItems?.nodes || []),
    ],
    [listMenuItems],
  )

  useEffect(() => {
    if (data) prevItemRef.current = item
  }, [data, item])

  if (!listMenuItems)
    // TODO: should have loading page
    return <div>loading</div>

  return (
    <>
      <SubHeader>
        <h1>Historical Performance</h1>

        <div>
          <h3>Menu Item:</h3>

          <MenuItemSelect<IGetProductHistoricalPerformanceListMenuItemsNodesType>
            value={item}
            onChange={setItem}
            dataSource={dataSource}
          />
        </div>
      </SubHeader>

      {!listMenuItemCosting ? (
        // TODO: should have loading page
        <div>loading</div>
      ) : (
        <ChartContainer>
          <Spin spinning={loading}>
            <Chart {...historicalPerformanceConfig} width={1100} height={650} />
          </Spin>
        </ChartContainer>
      )}
    </>
  )
}

export default React.memo(
  HistoricalPerformance,
) as unknown as typeof HistoricalPerformance
