import { gql, useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'

export interface IDataType {
  discountType?: {
    options: {
      id: string
      parentId: string
      displayName: string
    }[]
    value: string[][]
  }
}

interface IMetricDefsDataType {
  uListRequestedLocationMetrics: {
    nodes: { metricCode: string; metricName: string }[]
  }
}

const query = gql`
  query listMetricDefs($iFilter: JSON!) {
    uListRequestedLocationMetrics(iFilter: $iFilter) {
      nodes {
        metricCode
        metricName
      }
    }
  }
`

const useDiscountType = (): IDataType => {
  const discountTypeHook = useState([['all']])
  const { data, loading } = useQuery<IMetricDefsDataType>(query, {
    variables: {
      iFilter: {
        metric_groups: ['Expo Module - Discount Comps'],
      },
    },
  })

  return {
    discountType: useMemo(() => {
      const nodes = data?.uListRequestedLocationMetrics?.nodes || []
      const options = [
        {
          id: 'all',
          parentId: 'root',
          displayName: 'All',
        },
        ...nodes?.map((n) => ({
          id: n.metricCode
            .replace(/^discount_comps_\s*/, '')
            .replace(/_([a-z])/g, (_, letter) => letter.toUpperCase()),
          parentId: 'root',
          displayName: n.metricName.replace(/^Discount Comps - \s*/, ''),
        })),
      ]

      return {
        value: discountTypeHook[0],
        options: options,
        onChange: discountTypeHook[1],
      }
    }, [discountTypeHook, data, loading]),
  }
}

export default useDiscountType
