import useArbListLocationGroupPrimantiTattle, {
  arbListLocationGroupPrimantiTattleConfigs,
} from './useArbListLocationGroupPrimantiTattle'
import useArbListLocationPrimantiTattle, {
  arbListLocationPrimantiTattleConfigs,
} from './useArbListLocationPrimantiTattle'

export const arbPrimantiTattleConfigs = {
  ...arbListLocationPrimantiTattleConfigs,
  ...arbListLocationGroupPrimantiTattleConfigs,
}

const useArbPrimantiTattle = () => {
  const arbListLocationPrimantiTattle = useArbListLocationPrimantiTattle()
  const arbListLocationGroupPrimantiTattle =
    useArbListLocationGroupPrimantiTattle()

  return (arbListLocationPrimantiTattle?.data?.source || []).length === 0
    ? arbListLocationGroupPrimantiTattle
    : arbListLocationPrimantiTattle
}

export default useArbPrimantiTattle
