import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import LocationDeliverySummary from 'pared/pages/Delivery/Summary'
import SalesPerLaborHourChart from 'pared/pages/v2/Salesmanship/SalesPerLaborHour'

import Summary from '../Summary'
import Table from '../Table'
import Trend from '../Trend'
import { FROM_EXPO } from '../constants'
import { IMessage } from '../type'
import formatDate from './formatDate'
import handleCannedQuestionAnswer from './responseHandler/handleCannedQuestionAnswer'
import handleDeliveryKpiRankings from './responseHandler/handleDeliveryKpiRankings'
import handleDeliveryKpiTrends from './responseHandler/handleDeliveryKpiTrends'
import handleDeliveryStoreEmployeeKpis from './responseHandler/handleDeliveryStoreEmployeeKpis'
import handleErrors from './responseHandler/handleErrors'
import handleForecastedResults from './responseHandler/handleForecastedResults'
import handleFssKpiRankings from './responseHandler/handleFssKpiRankings'
import handleFssKpiValues from './responseHandler/handleFssKpiValues'
import handleGuestKpiRankings from './responseHandler/handleGuestKpiRankings'
import handleGuestKpiTrends from './responseHandler/handleGuestKpiTrends'
import handleGuestSuggestions from './responseHandler/handleGuestSuggestions'
import handleInventoryKpiValues from './responseHandler/handleInventoryKpiValues'
import handleInventoryLocationSuggestions from './responseHandler/handleInventoryLocationSuggestions'
import handleKpiAnalyzerAnswers from './responseHandler/handleKpiAnalyzerAnswers'
import handleLossPreventionKpiTrends from './responseHandler/handleLossPreventionKpiTrends'
import handleLossPreventionKpiValues from './responseHandler/handleLossPreventionKpiValues'
import handleLossPreventionStoreEmployeeKpis from './responseHandler/handleLossPreventionStoreEmployeeKpis'
import handlePmixKpiValues from './responseHandler/handlePmixKpiValues'
import handlePurchaseTrends from './responseHandler/handlePurchaseTrends'
import handleSalesmanshipEmployeeKpis from './responseHandler/handleSalesmanshipEmployeeKpis'
import handleSalesmanshipKpiTrends from './responseHandler/handleSalesmanshipKpiTrends'
import handleSalesmanshipKpiValues from './responseHandler/handleSalesmanshipKpiValues'

interface IProps {
  brandLocationGroupId: number
  handleCannedQuestionClicked: (questionText: string) => void
  requestProcessingUuid?: string
}

const TextOnlyMessage = styled.div`
  max-width: 800px;
`

function generateAnswerMessagesFromExpoAnswer(
  expoAnswer: any,
  {
    brandLocationGroupId,
    handleCannedQuestionClicked,
    requestProcessingUuid,
  }: IProps,
) {
  const generatedAnswerMessages: IMessage[] = []
  let answerMessage: IMessage | null = null

  if (expoAnswer?.nativeGptAnswer) {
    generatedAnswerMessages.push({
      id: uuidv4(),
      from: FROM_EXPO,
      content: <TextOnlyMessage>{expoAnswer?.nativeGptAnswer}</TextOnlyMessage>,
    })
  }

  answerMessage = handleErrors(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleKpiAnalyzerAnswers(
    expoAnswer,
    handleCannedQuestionClicked,
    requestProcessingUuid,
  )
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  if (
    expoAnswer?.financialKpiValues ||
    expoAnswer?.deliveryKpiValues ||
    expoAnswer?.guestKpiValues ||
    expoAnswer?.storeRanks ||
    expoAnswer?.directorRanks ||
    expoAnswer?.regionRanks ||
    expoAnswer?.itemOrCategoryRanks ||
    expoAnswer?.employeeRanks
  ) {
    generatedAnswerMessages.push({
      id: uuidv4(),
      from: FROM_EXPO,
      content: (
        <Table
          data={
            expoAnswer?.financialKpiValues ||
            expoAnswer?.deliveryKpiValues ||
            expoAnswer?.guestKpiValues ||
            expoAnswer?.storeRanks ||
            expoAnswer?.directorRanks ||
            expoAnswer?.regionRanks ||
            expoAnswer?.itemOrCategoryRanks ||
            expoAnswer?.employeeRanks
          }
        />
      ),
    })
  }

  if (expoAnswer?.financialKpiTrends) {
    generatedAnswerMessages.push({
      id: uuidv4(),
      from: FROM_EXPO,
      content: <Trend data={expoAnswer?.financialKpiTrends} />,
    })
  }

  if (expoAnswer?.salesPerLaborHourSuggestions) {
    const suggestions = expoAnswer?.salesPerLaborHourSuggestions
    generatedAnswerMessages.push({
      id: uuidv4(),
      from: FROM_EXPO,
      content: suggestions.error ? (
        suggestions.error
      ) : (
        <div>
          <div>
            {suggestions.locationName} ({formatDate(suggestions.startDate)} ~{' '}
            {formatDate(suggestions.endDate)})
          </div>
          <div style={{ padding: '20px 0 0 0' }}>
            <SalesPerLaborHourChart
              locationName={suggestions.locationName}
              locationId={suggestions.locationId}
              startDate={suggestions.startDate}
              endDate={suggestions.endDate}
            />
          </div>
        </div>
      ),
    })
  }

  if (expoAnswer?.locationDeliverySuggestions) {
    const suggestions = expoAnswer?.locationDeliverySuggestions
    generatedAnswerMessages.push({
      id: uuidv4(),
      from: FROM_EXPO,
      content: suggestions.error ? (
        suggestions.error
      ) : (
        <div>
          <div>
            {suggestions.locationName} ({formatDate(suggestions.startDate)} ~{' '}
            {formatDate(suggestions.endDate)})
          </div>
          <div style={{ padding: '20px 0 0 0' }}>
            <LocationDeliverySummary
              locationName={suggestions.locationName}
              locationId={suggestions.locationId}
              startDateStr={suggestions.startDate}
              endDateStr={suggestions.endDate}
            />
          </div>
        </div>
      ),
    })
  }

  if (
    expoAnswer?.locationGroupLaborSummary ||
    expoAnswer?.locationGroupSalesmanshipSummary ||
    expoAnswer?.locationGroupLossPreventionSummary ||
    expoAnswer?.locationGroupInventorySummary ||
    expoAnswer?.locationGroupDeliverySummary ||
    expoAnswer?.locationGroupTeamSummary
  ) {
    const summary =
      expoAnswer?.locationGroupLaborSummary ||
      expoAnswer?.locationGroupSalesmanshipSummary ||
      expoAnswer?.locationGroupLossPreventionSummary ||
      expoAnswer?.locationGroupInventorySummary ||
      expoAnswer?.locationGroupDeliverySummary ||
      expoAnswer?.locationGroupTeamSummary

    generatedAnswerMessages.push({
      id: uuidv4(),
      from: FROM_EXPO,
      content: (
        <div>
          <div>
            {summary.locationGroupName} ({formatDate(summary.startDate)} ~{' '}
            {formatDate(summary.endDate)})
          </div>
          <div style={{ padding: '20px 0 0 0' }}>
            <Summary
              summaryType={summary.summaryType}
              locationGroupId={summary.locationGroupId}
              brandLocationGroupId={brandLocationGroupId}
              startDate={summary.startDate}
              endDate={summary.endDate}
            />
          </div>
        </div>
      ),
    })
  }

  answerMessage = handleLossPreventionKpiValues(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleLossPreventionKpiTrends(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleLossPreventionStoreEmployeeKpis(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleInventoryKpiValues(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleInventoryLocationSuggestions(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handlePurchaseTrends(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleDeliveryKpiTrends(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleDeliveryKpiRankings(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleDeliveryStoreEmployeeKpis(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleSalesmanshipKpiValues(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleCannedQuestionAnswer(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleSalesmanshipKpiTrends(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleSalesmanshipEmployeeKpis(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleGuestKpiTrends(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleGuestKpiRankings(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handlePmixKpiValues(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleFssKpiValues(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleFssKpiRankings(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleGuestSuggestions(expoAnswer, brandLocationGroupId)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  answerMessage = handleForecastedResults(expoAnswer)
  if (answerMessage) {
    generatedAnswerMessages.push(answerMessage)
  }

  // if (expoAnswer?.formattedGptAnswer) {
  //   generatedAnswerMessages.push({
  //     id: uuidv4(),
  //     from: FROM_EXPO,
  //     content: (
  //       <TextOnlyMessage>
  //         <div
  //           dangerouslySetInnerHTML={{
  //             __html: expoAnswer?.formattedGptAnswer,
  //           }}
  //         />
  //       </TextOnlyMessage>
  //     ),
  //   })
  // }

  return generatedAnswerMessages
}

export default generateAnswerMessagesFromExpoAnswer
