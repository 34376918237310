export interface INavParams {
  storeId?: string
  dateRange?: string
  employeeId?: string
  pagePathname?: string
  pageUrl?: string
  disabledSearches?: {
    [searchKey: string]: string
  }
  isPageFullWidth?: boolean
  section?: string
  isMobileView?: boolean
  navTabType?: string
  userDefaultStoreId?: number | string
  isStoreLevelUser?: boolean
  token?: string
  canManageUsers?: boolean
  canViewUserEventLogs?: boolean
  redirect?: string
}

// a global variable
// only allow to be mutated in: src/pared/layouts/Overall
const navParams: INavParams = {}

export function getNavParamsCopy() {
  const copy = { ...navParams }
  if (navParams.disabledSearches) {
    copy.disabledSearches = { ...navParams.disabledSearches }
  }
  return copy
}

export default navParams
