import React from 'react'

import Chart from 'pared/charts'
import { RAW_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA } from 'pared/constants/raw_data/productivityCoefficient'

import useConfig from './hooks/useConfig'

interface IPropsType {
  selectedLocation: {
    id: keyof typeof RAW_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA
  }
}

const ProductivityCoefficientChart = ({ selectedLocation }: IPropsType) => {
  const config = useConfig(selectedLocation.id)

  return <Chart {...config} width={1100} height={350} />
}

export default React.memo(ProductivityCoefficientChart)
