import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { track } from 'pared/analytics/storeList'
import { IColumnsType } from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import { getLocationCode, getLocationName } from 'pared/utils/location'
import { toPercentString } from 'pared/utils/number'

import useLossPreventionConfig from '../../hooks/useLossPreventionConfig'
import { getPriceOrDash } from '../../utils'
import useDiscountTypeItems, { ALL } from './useDiscountTypeItems'
import { IDiscountType } from './useDiscounts'

interface IReturnType {
  discountsColumns: IColumnsType<IDiscountType>[]
  selectedDiscount: string
  discountChanged: (value: string) => void
  discountItems: string[]
}

const LinkButton = styled(Link)`
  color: ${COLORS.Link};
`

const useDiscountsColumns = (
  isBreakdownByDirectors: boolean,
  locationIds?: number[],
  locationGroupIds?: number[],
  locationGroupType?: string,
): IReturnType => {
  const brandSettings = getBrandSettings()
  const businessLabels = brandSettings.labels.business

  const config = useLossPreventionConfig()
  const { selectedDiscount, discountChanged, discountItems } =
    useDiscountTypeItems(isBreakdownByDirectors, locationIds, locationGroupIds)

  const discountsColumns = useMemo(() => {
    const discountColumns = config?.discountTable?.discountColumns || []
    const discountColumnDefs = discountColumns
      .filter((col: any) =>
        selectedDiscount === ALL ? col : col.key === selectedDiscount,
      )
      .map((col: any) => {
        const colKey = col?.key || ''
        const colTitle = col?.title || col?.key || ''
        const colSpan = col?.colSpan || ''
        return {
          key: colKey,
          title: colTitle,
          align: 'center',
          colSpan,
          children: col.children
            ? col.children.map(
                ({ key, title }: { key: string; title: string }) => ({
                  key,
                  title,
                  width: '75px',
                  align: 'center',
                  children: [
                    {
                      key: `["${key}"].0`,
                      title: '#',
                      width: '75px',
                      align: 'center',
                      render: (countDisplayString: string) => {
                        return countDisplayString ? countDisplayString : '-'
                      },
                      sorter: true,
                    },
                    {
                      key: `["${key}"].1`,
                      title: '$',
                      width: '75px',
                      align: 'center',
                      render: (_: any, data: any) => {
                        return getPriceOrDash(data[key] ? data[key][2] : '-')
                      },
                      sorter: true,
                    },
                  ],
                }),
              )
            : [
                ...(col.isCountVisible === false
                  ? []
                  : [
                      {
                        key: `["${colKey}"].0`,
                        title: '#',
                        width: '75px',
                        align: 'center',
                        render: (countDisplayString: string) => {
                          return countDisplayString ? countDisplayString : '-'
                        },
                        sorter: true,
                      },
                    ]),
                ...(col.isAmountVisible === false
                  ? []
                  : [
                      {
                        key: `["${colKey}"].1`,
                        title: '$',
                        width: '75px',
                        align: 'center',
                        render: (_: any, data: any) => {
                          return getPriceOrDash(
                            data[colKey] ? data[colKey][2] : '-',
                          )
                        },
                        sorter: true,
                      },
                    ]),
                ...(col.isCompsPercentVisible === false
                  ? []
                  : [
                      {
                        key: `["${colKey}"].1.value`,
                        title: '%',
                        width: '75px',
                        align: 'center',
                        render: (value: number) => toPercentString(100 * value),
                        sorter: true,
                      },
                    ]),
              ],
        }
      })

    return [
      ...((isBreakdownByDirectors
        ? []
        : [
            {
              key: 'store',
              title: 'Store',
              minWidth: '275px',
              render: (_, values, index) => {
                if (index === -1) return 'TOTAL'
                if (!('storeId' in values)) return null

                return (
                  <LinkButton
                    to={navigator.lossPrevention(values.storeId)}
                    onClick={() => track.locationLinkClicked(values.storeId)}
                  >
                    {getLocationCode(values.storeId, values.storeCode)} -{' '}
                    {getLocationName(values.storeId, values.storeName)}
                  </LinkButton>
                )
              },
              sorter: true,
            },
          ]) as IColumnsType<IDiscountType>[]),
      !locationGroupType
        ? {
            key: 'directorName',
            title: businessLabels['director'],
            minWidth: '150px',
            render: (
              value: IDiscountType['directorName'],
              { directorId },
              index,
            ) => {
              if (index === -1) return !isBreakdownByDirectors ? '' : 'TOTAL'

              return businessLabels.isDirectorClickable ? (
                <LinkButton
                  to={navigator.employeeProfile(directorId)}
                  onClick={() => track.directorProfileLinkClicked(directorId)}
                >
                  {value}
                </LinkButton>
              ) : (
                value || 'All'
              )
            },
            sorter: true,
          }
        : {
            key: 'locationGroupName',
            title: locationGroupType,
            minWidth: '150px',
            render: (value: string) => value,
            sorter: true,
          },
      ...discountColumnDefs,
      {
        key: 'totalDiscounts',
        title: 'Total Discounts',
        align: 'center',
        children: [
          ...(config?.discountTable?.isTotalCountVisible === false
            ? []
            : [
                {
                  key: 'totalDiscounts.0',
                  title: '#',
                  width: '75px',
                  align: 'center',
                  sorter: true,
                },
              ]),
          ...(config?.discountTable?.isTotalAmountVisible === false
            ? []
            : [
                {
                  key: 'totalDiscounts.1',
                  title: '$',
                  width: '75px',
                  align: 'center',
                  render: (_: any, data: any) => {
                    return getPriceOrDash(data.totalDiscounts[2])
                  },
                  sorter: true,
                },
              ]),
          ...(config?.discountTable?.isCompsPercentVisible === false
            ? []
            : [
                {
                  key: 'totalDiscounts.1.value',
                  title: '%',
                  width: '75px',
                  align: 'center',
                  render: (value: number) => toPercentString(100 * value),
                  sorter: true,
                },
              ]),
        ],
      },
      ...(config?.discountTable?.isGrossSalesVisible === false
        ? []
        : [
            {
              key: 'grossSales',
              title: 'Gross Sales',
              align: 'center',
              width: '150px',
              render: getPriceOrDash,
              sorter: true,
            },
          ]),
    ]
  }, [config, selectedDiscount, isBreakdownByDirectors, locationGroupType])

  return {
    discountsColumns,
    selectedDiscount,
    discountChanged,
    discountItems,
  }
}

export default useDiscountsColumns
