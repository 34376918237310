import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/constants/styles'

export default styled.div`
  margin: 83px 0px 0px;
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    display: block;
    width: 100%;
    margin: 60px 0px 0px;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
  }

  h3 {
    margin: 0px 10px 0px 0px;
    display: inline-block;
    font-family: Lexend-SemiBold;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
  }
`
