import { INavParams } from 'pared/Routes/navParams'

import useCorporateSalesmanshipConfig from './hooks/useCorporateSalesmanshipConfig'

interface IProps {
  navParams: INavParams
}

const CorporateSalesmanship = ({ navParams }: IProps) => {
  const CorporateSalesmanshipModule = useCorporateSalesmanshipConfig()

  if (CorporateSalesmanshipModule) {
    return <CorporateSalesmanshipModule navParams={navParams} />
  } else {
    return null
  }
}

export default CorporateSalesmanship
