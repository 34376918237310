import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import useStoreList from '../../hooks/useStoreList'
import { LIST_LOCATION_DISCOUNT_DETAILS } from '../gql'
import { ALL } from './useDiscountTypeItems'
import { IHeatMapCell, percentageToColor } from './useDiscounts'

const useLocationDiscounts = (
  selectedDiscount: string,
  locationIds?: number[],
  locationGroupIds?: number[],
  startDate?: string,
  endDate?: string,
) => {
  let iFilter: any = null
  if (locationIds && locationIds.length > 0) {
    iFilter = { location_ids: [...locationIds] }
  } else if (locationGroupIds && locationGroupIds.length > 0) {
    iFilter = { location_group_ids: [...locationGroupIds] }
  }

  const { storeList, storeListLoading } = useStoreList()
  const { data: discountsData, loading: discountsDataLoading } = useQuery(
    LIST_LOCATION_DISCOUNT_DETAILS,
    {
      variables: {
        iFilter,
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !startDate || !endDate || !iFilter,
    },
  )

  const discountLocationLoading = discountsDataLoading || storeListLoading

  let compsDetailType: string[] = []

  const discountLocationData = useMemo(() => {
    const rawData = discountsData?.listLocationDiscountDetails?.nodes || []

    const tableData = rawData
      .map((row: any) => {
        const storeId = row.locationInfo.id
        const storeCode = row.locationInfo.code
        const storeName = row.locationInfo.name

        const store = storeList?.listLocationDetails?.nodes.find(
          ({ id }: any) => storeId === id,
        )

        let grossSales: string = '-'
        let totalDiscounts: [string, IHeatMapCell, string] = [
          '-',
          { value: 0, color: 'transparent' },
          '-',
        ]

        if (row.totalGrossSales || row.totalGrossSales === 0) {
          grossSales = (row.totalGrossSales / 100.0).toFixed(0)
        }

        if (row.totalCompsCount || row.totalCompsCount === 0) {
          totalDiscounts[0] = row.totalCompsCount
        }

        if (row.totalComps || row.totalComps === 0) {
          totalDiscounts[2] = (row.totalComps / 100.0).toFixed(0)
          if (row.totalGrossSales)
            totalDiscounts[1].value = row.totalComps / row.totalGrossSales
        }

        const itemDetails: {
          [compsType: string]: [string, IHeatMapCell, string]
        } = {}

        row?.compsItemDetails?.forEach((itemData: any) => {
          let count = '-'
          let amount = '-'
          if (itemData?.count || itemData.count === 0) {
            count = itemData.count
          }
          if (itemData?.totalComps || itemData.totalComps === 0) {
            amount = (itemData.totalComps / 100.0).toFixed(0)
          }

          itemDetails[itemData.type] = [
            count,
            {
              value: row.totalGrossSales
                ? itemData.totalComps / row.totalGrossSales
                : 0,
              color: 'transparent',
            },
            amount,
          ]

          if (!compsDetailType.includes(itemData.type))
            compsDetailType.push(itemData.type)
        })

        return {
          ...itemDetails,
          storeId,
          storeCode,
          storeName,
          directorId: store?.directorEmployeeInfo?.id || 0,
          directorName: [
            store?.directorEmployeeInfo?.preferredName || '',
            store?.directorEmployeeInfo?.familyName || '',
          ]
            .filter(Boolean)
            .join(' '),
          totalDiscounts,
          grossSales,
        }
      })
      .filter((data) =>
        selectedDiscount === ALL ? true : data.hasOwnProperty(selectedDiscount),
      )

    if (tableData?.length > 1) {
      compsDetailType?.forEach((type: string) => {
        tableData
          .sort((a: any, b: any) => {
            if (a[type] === undefined) return 1
            if (b[type] === undefined) return -1
            return b[type][1].value - a[type][1].value
          })
          .map((value, index, array) => {
            if (value[type]) {
              value[type][1].color = percentageToColor(
                index /
                  (array.length -
                    array.filter((obj: any) => !obj.hasOwnProperty(type))
                      .length),
              )
              return value
            }
          })
      })
    }

    if (tableData?.length > 1) {
      tableData
        .sort((a: any, b: any) => {
          return b?.totalDiscounts[1].value - a?.totalDiscounts[1].value
        })
        .map((value, index, array) => {
          value.totalDiscounts[1].color = percentageToColor(
            index / array.length,
          )
          return value
        })
    }

    return tableData
  }, [discountsData, selectedDiscount, locationGroupIds, startDate, endDate])

  return { discountLocationData, discountLocationLoading }
}

export default useLocationDiscounts
