import Popover from '@material-ui/core/Popover'
import React from 'react'
import styled from 'styled-components'

import EmployeeFeedbackPopover from 'pared/components/managerReview/EmployeeFeedbackPopover'
import AddIcon from 'pared/images/Pared/add.svg'

interface IProps {
  employeeAnchorEl: any
  setEmployeeAnchorEl: any
  firstName: string
  lastName: string
  role: string
  storeCode: string
  storeName: string
  revieweeId: number
  locationId: number
  onRefresh: () => Promise<void>
}

const Main = ({
  employeeAnchorEl,
  setEmployeeAnchorEl,
  firstName,
  lastName,
  role,
  storeCode,
  storeName,
  revieweeId,
  locationId,
  onRefresh,
}: IProps) => {
  const handleClick = (event: any) => {
    setEmployeeAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setEmployeeAnchorEl(null)
  }

  const open = Boolean(employeeAnchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <AddIconContainer>
        <span onClick={handleClick}>
          <img src={AddIcon} alt="Add Feedback" />
        </span>
      </AddIconContainer>
      <Popover
        id={id}
        open={open}
        anchorEl={employeeAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <EmployeeFeedbackPopover
          firstName={firstName}
          lastName={lastName}
          role={role}
          storeCode={storeCode}
          storeName={storeName}
          revieweeId={revieweeId}
          locationId={locationId}
          closePopover={handleClose}
          onRefresh={onRefresh}
          currentFeedback={''}
          isUpdatingFeedback={false}
        />
      </Popover>
    </>
  )
}

const AddIconContainer = styled.div`
  cursor: pointer;
`

export default Main
