import React from 'react'
import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'
import Table from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'

import useColumns from './hooks/useColumns'
import useDataSource from './hooks/useDataSource'

interface IProps {
  isBreakdownByDirectors: boolean
  startDate: string
  endDate: string
  locationGroupId?: number
  locationGroupIds?: number[]
  breakdownType?: string | null
  locationIds?: number[]
}

const TitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0px 0px 20px 0px;
`

const rowStyle = `
  &:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }
`

const MissingItemTable = ({
  isBreakdownByDirectors,
  startDate,
  endDate,
  locationGroupId,
  locationGroupIds,
  breakdownType,
  locationIds,
}: IProps) => {
  const { dataSource, loading } = useDataSource({
    isBreakdownByDirectors,
    startDate,
    endDate,
    locationGroupId,
    locationGroupIds,
    breakdownType,
    locationIds,
  })

  const columns = useColumns()

  return (
    <Spin spinning={loading}>
      <TitleDiv>Top 3 Missing Items</TitleDiv>
      <Table
        dataSource={dataSource}
        columns={columns}
        rowKey={(values) => {
          return `${values.itemName} - ${values.totalCount}`
        }}
        rowStyle={rowStyle}
      />
    </Spin>
  )
}

export default React.memo(MissingItemTable)
