import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

interface ITeamConfig {
  isActiveSalariedCountVisible: boolean
  isInactiveEmployeeVisible: boolean
  avgHourlyRate: {
    isVisible: boolean
  }
  managerPar: {
    isVisible: boolean
  }
  laborSchedule: {
    isVisible: boolean
  }
  laborTrends: {
    isVisible: boolean
  }
  laborScheduleVsTarget: {
    isVisible: boolean
  }
  unfilledManagerialPositions: {
    isVisible: boolean
  }
  currentTeam: {
    isVisible: boolean
  }
  shouldHideFeedback?: boolean
  shouldHideTimeInPosition?: boolean
  hasLinkToViewEmployeeProfile: boolean
  summary: {
    isVisible: boolean
  }
  isTeamV2?: boolean
}

const useTeamConfig = (): ITeamConfig => {
  const brand = getBrand()

  return useMemo(() => {
    switch (brand) {
      case 'papajohns_demo':
        return {
          isActiveSalariedCountVisible: true,
          isInactiveEmployeeVisible: true,
          avgHourlyRate: {
            isVisible: true,
          },
          managerPar: {
            isVisible: true,
          },
          laborSchedule: {
            isVisible: true,
          },
          laborTrends: {
            isVisible: false,
          },
          laborScheduleVsTarget: {
            isVisible: false,
          },
          currentTeam: {
            isVisible: true,
          },
          unfilledManagerialPositions: {
            isVisible: true,
          },
          hasLinkToViewEmployeeProfile: false,
          summary: {
            isVisible: true,
          },
        }

      case 'irmg_bk':
      case 'sandbox_bk':
      case 'supreme_bk':
      case 'timoney_bk':
      case 'mbn_bk':
      case 'demo_bk':
        return {
          isActiveSalariedCountVisible: true,
          isInactiveEmployeeVisible: false,
          avgHourlyRate: {
            isVisible: false,
          },
          managerPar: {
            isVisible: false,
          },
          laborSchedule: {
            isVisible: false,
          },
          laborTrends: {
            isVisible: false,
          },
          laborScheduleVsTarget: {
            isVisible: false,
          },
          currentTeam: {
            isVisible: true,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          shouldHideFeedback: true,
          shouldHideTimeInPosition: true,
          hasLinkToViewEmployeeProfile: false,
          summary: {
            isVisible: true,
          },
        }

      case 'viking_bk':
        return {
          isActiveSalariedCountVisible: true,
          isInactiveEmployeeVisible: false,
          avgHourlyRate: {
            isVisible: false,
          },
          managerPar: {
            isVisible: false,
          },
          laborSchedule: {
            isVisible: false,
          },
          laborTrends: {
            isVisible: false,
          },
          laborScheduleVsTarget: {
            isVisible: false,
          },
          currentTeam: {
            isVisible: true,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          shouldHideFeedback: true,
          shouldHideTimeInPosition: true,
          hasLinkToViewEmployeeProfile: false,
          summary: {
            isVisible: false,
          },
        }

      case 'jsc_bk':
      case 'jsc_bk_nso':
        return {
          isActiveSalariedCountVisible: false,
          isInactiveEmployeeVisible: false,
          avgHourlyRate: {
            isVisible: false,
          },
          managerPar: {
            isVisible: false,
          },
          laborSchedule: {
            isVisible: false,
          },
          laborTrends: {
            isVisible: false,
          },
          laborScheduleVsTarget: {
            isVisible: false,
          },
          currentTeam: {
            isVisible: true,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          shouldHideFeedback: true,
          shouldHideTimeInPosition: true,
          hasLinkToViewEmployeeProfile: false,
          summary: {
            isVisible: false,
          },
          isTeamV2: true,
        }

      case 'ghai_pop':
        return {
          isActiveSalariedCountVisible: true,
          isInactiveEmployeeVisible: false,
          avgHourlyRate: {
            isVisible: false,
          },
          managerPar: {
            isVisible: false,
          },
          laborSchedule: {
            isVisible: false,
          },
          laborTrends: {
            isVisible: false,
          },
          laborScheduleVsTarget: {
            isVisible: false,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          currentTeam: {
            isVisible: true,
          },
          shouldHideFeedback: true,
          shouldHideTimeInPosition: true,
          hasLinkToViewEmployeeProfile: false,
          summary: {
            isVisible: true,
          },
          isTeamV2: true,
        }

      case 'irmg_pop':
      case 'supreme_pop':
      case 'ace_pop':
      case 'demo_pop':
        return {
          isActiveSalariedCountVisible: true,
          isInactiveEmployeeVisible: false,
          avgHourlyRate: {
            isVisible: false,
          },
          managerPar: {
            isVisible: false,
          },
          laborSchedule: {
            isVisible: false,
          },
          laborTrends: {
            isVisible: false,
          },
          laborScheduleVsTarget: {
            isVisible: false,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          currentTeam: {
            isVisible: true,
          },
          shouldHideFeedback: true,
          shouldHideTimeInPosition: true,
          hasLinkToViewEmployeeProfile: false,
          summary: {
            isVisible: true,
          },
        }

      case 'cava':
        return {
          isActiveSalariedCountVisible: true,
          isInactiveEmployeeVisible: true,
          avgHourlyRate: {
            isVisible: false,
          },
          managerPar: {
            isVisible: false,
          },
          laborSchedule: {
            isVisible: false,
          },
          laborTrends: {
            isVisible: true,
          },
          laborScheduleVsTarget: {
            isVisible: false,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          currentTeam: {
            isVisible: false,
          },
          hasLinkToViewEmployeeProfile: false,
          summary: {
            isVisible: true,
          },
        }

      case 'jitb':
        return {
          isActiveSalariedCountVisible: true,
          isInactiveEmployeeVisible: false,
          avgHourlyRate: {
            isVisible: false,
          },
          managerPar: {
            isVisible: false,
          },
          laborSchedule: {
            isVisible: false,
          },
          laborTrends: {
            isVisible: false,
          },
          laborScheduleVsTarget: {
            isVisible: false,
          },
          currentTeam: {
            isVisible: false,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          shouldHideFeedback: true,
          shouldHideTimeInPosition: true,
          hasLinkToViewEmployeeProfile: false,
          summary: {
            isVisible: true,
          },
        }

      // Local Favorite
      case 'lfr_ef':
      case 'lfr_tr':
      case 'lfr_sn':
      case 'lfr_lv':
      case 'lfr_vb':
      case 'lfr_tu':
      case 'lfr_ws':
      case 'lfr_jn':
      case 'lfr_mm':
      case 'lfr_ol':
      case 'lfr_dt':
      case 'lfr_uv':
      case 'lfr_bth':
        return {
          isActiveSalariedCountVisible: true,
          isInactiveEmployeeVisible: true,
          avgHourlyRate: {
            isVisible: false,
          },
          managerPar: {
            isVisible: true,
          },
          laborSchedule: {
            isVisible: false,
          },
          laborTrends: {
            isVisible: false,
          },
          laborScheduleVsTarget: {
            isVisible: false,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          currentTeam: {
            isVisible: false,
          },
          hasLinkToViewEmployeeProfile: false,
          summary: {
            isVisible: true,
          },
        }

      case 'bibibop':
        return {
          isActiveSalariedCountVisible: true,
          isInactiveEmployeeVisible: true,
          avgHourlyRate: {
            isVisible: false,
          },
          managerPar: {
            isVisible: true,
          },
          laborSchedule: {
            isVisible: false,
          },
          laborTrends: {
            isVisible: false,
          },
          laborScheduleVsTarget: {
            isVisible: false,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          currentTeam: {
            isVisible: true,
          },
          hasLinkToViewEmployeeProfile: true,
          summary: {
            isVisible: true,
          },
        }

      case 'rmg':
      case 'rmg_nso':
      case 'sullivans':
      case 'sullivans_nso':
      case 'eddiemerlots':
      case 'eddiemerlots_nso':
      case 'papajohns':
      default:
        return {
          isActiveSalariedCountVisible: true,
          isInactiveEmployeeVisible: true,
          avgHourlyRate: {
            isVisible: false,
          },
          managerPar: {
            isVisible: true,
          },
          laborSchedule: {
            isVisible: false,
          },
          laborTrends: {
            isVisible: false,
          },
          laborScheduleVsTarget: {
            isVisible: false,
          },
          unfilledManagerialPositions: {
            isVisible: true,
          },
          currentTeam: {
            isVisible: true,
          },
          hasLinkToViewEmployeeProfile: true,
          summary: {
            isVisible: true,
          },
        }
    }
  }, [brand])
}
export default useTeamConfig
