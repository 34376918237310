import { gql } from '@apollo/client'

export const QUERY_GET_LOCATION_ACCESS_GROUPS_FOR_BRAND = gql`
  query GetLocationAccessGroupsForBrand(
    $iStartDate: Date!
    $iEndDate: Date!
    $iBrandId: Int!
  ) {
    getLocationAccessGroupsForBrand: getLocationAccessGroupsForBrandV2(
      iBrandId: $iBrandId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        id
        name
        type
        startDate
        endDate
      }
    }
  }
`
