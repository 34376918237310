import _ from 'lodash'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'

interface IProps {
  xAxisData: string[]
  yAxisInaccurateData: number[]
  yAxisCancelledData: number[]
  yAxisDelayedData: number[]
  yAxisTotalOrderCountData: number[]
  yAxisAnyIssueData: number[]
  chartOptions: any
  yAxisDataArr: number[][]
}

const CHART_WIDTH = 800
const CHART_HEIGHT = 350

function WebSmallMain({ xAxisData, chartOptions, yAxisDataArr }: IProps) {
  return (
    <>
      <SectionTitleDiv>OPS COMPS TREND</SectionTitleDiv>
      <MixedChart
        xAxisData={xAxisData}
        yAxisDataArr={yAxisDataArr}
        options={chartOptions}
      />
    </>
  )
}

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 20px 0;
`

export default WebSmallMain
