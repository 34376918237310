import CustomizedReport from 'pared/components/CustomizedReport'

interface IProps {
  locationGroupId: number
  startDate: string
  endDate: string
}

const KpiBar = ({ locationGroupId, startDate, endDate }: IProps) => {
  const inputParams = {
    locationGroupId,
    startDate,
    endDate,
  }

  return (
    <>
      <CustomizedReport
        title="Real-Time KPIs"
        reportName="CORPORATE_CALLS_KPIS"
        inputParams={inputParams}
      />
    </>
  )
}

export default KpiBar
