import { IConfigsType } from '../../types'

const ghaiPopFlash: IConfigsType = {
  ghai_pop: {
    '/:brand/flash': {
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'year',
          'quarter',
          'period',
          'week',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'custom',
        ],
        defaultType: 'yesterday',
      },
      flash: {
        type: 'title',
        title: 'Systemwide Flash Report',
      },
      downloadFlashTable: {
        type: 'download-csv',
        title: 'DAILY FLASH',
        api: 'flash',
        fileName: 'getexpo-flash',
        message: 'Download Daily Flash as CSV',
        fields: [
          'groupInfo',
          {
            key: 'netSales',
            title: 'Net Sales',
          },
          {
            key: 'yoyNetSales',
            title: 'Net Sales LY',
          },
          {
            key: 'netSalesLy',
            title: 'Net Sales vs LY',
          },
          {
            key: 'netSalesLyPercent',
            title: '% vs LY',
          },
          {
            key: 'totalCheckCount',
            title: 'Check Count',
          },
          {
            key: 'yoyTotalCheckCount',
            title: 'Check Count LY',
          },
          {
            key: 'totalCheckCountLy',
            title: 'Check Count vs LY',
          },
          {
            key: 'yoyCheckCountGrowth',
            title: 'Check Count % vs LY',
          },
          {
            key: 'totalCheckAverage',
            title: 'Average Check',
          },
          {
            key: 'regularHours',
            title: 'Regular Hours',
          },
          {
            key: 'overtimeHours',
            title: 'Overtime Hours',
          },
        ],
      },
      dailyFlashTable: {
        type: 'table',
        api: 'flash',
        highlineEvenRows: true,
        defaultSortedField: 'groupInfo',
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        columns: [
          {
            key: 'netSales',
            title: 'Net Sales',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'yoyNetSales',
            title: 'Net Sales LY',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'netSalesLy',
            title: 'Net Sales vs LY',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'netSalesLyPercent',
            title: '% vs LY',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'totalCheckCount',
            title: 'Check Count',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'yoyTotalCheckCount',
            title: 'Check Count LY',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'totalCheckCountLy',
            title: 'Check Count vs LY',
            width: '170px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'yoyCheckCountGrowth',
            title: 'Check Count % vs LY',
            width: '170px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'totalCheckAverage',
            title: 'Average Check',
            width: '150px',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'regularHours',
            title: 'Regular Hours',
            width: '150px',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'overtimeHours',
            title: 'Overtime Hours',
            width: '150px',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
        ],
      },
    },
  },
}

export default ghaiPopFlash
