import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { track } from 'pared/analytics/alert'
import { ALERTS_DISPLAYED } from 'pared/constants'
import { getUser } from 'pared/utils/user'

import Main from './Main'
import { GET_ALERT_USER_NOTIFIEDS } from './gql'

const MyDashboard = () => {
  const [getAlerts, { loading, error: alertsError, data: alertsData }] =
    useLazyQuery(GET_ALERT_USER_NOTIFIEDS, {
      fetchPolicy: 'cache-and-network',
    })
  const [alertsDisplayed, setAlertsDisplayed] = useState<string>(
    ALERTS_DISPLAYED.UNARCHIVED_ALERTS,
  )

  const retrieveAlerts = () => {
    const user = getUser()

    getAlerts({
      variables: {
        userId: _.get(user, 'userId', 1),
      },
    })
  }

  useEffect(() => {
    async function fetchData() {
      retrieveAlerts()
    }

    fetchData()
  }, [getAlerts])

  const onToggleArchivedAlerts = () => {
    if (alertsDisplayed === ALERTS_DISPLAYED.UNARCHIVED_ALERTS) {
      setAlertsDisplayed(ALERTS_DISPLAYED.ARCHIVED_ALERTS)
    } else {
      setAlertsDisplayed(ALERTS_DISPLAYED.UNARCHIVED_ALERTS)
    }
    track.archivedAlertsToggled(alertsDisplayed)
  }

  const rawAlerts = _.get(alertsData, 'alertUserNotifieds.nodes', [])

  const alerts = _.map(rawAlerts, (rawAlert) => {
    const alert = _.get(rawAlert, 'alert', {})
    const alertUserNotifiedId = _.get(rawAlert, 'id', 0)
    const alertDefinitionLevel = _.get(
      alert,
      'alertSpecificationBySpecification.alertDefinition.level',
      '',
    )
    const alertUserNotifieds = _.get(alert, 'alertUserNotifieds.nodes', [])
    const readAts = _.compact(_.map(alertUserNotifieds, 'readAt'))
    const isRead = !_.isEmpty(readAts)

    const triggeredAt = _.get(alert, 'triggeredAt', '')
    const triggeredAtInDays = moment(moment(triggeredAt).format('YYYY-MM-DD'))
      .add(1, 'day')
      .format()
    const rawTriggeredAtFromNowInDays = moment().diff(
      moment(triggeredAtInDays),
      'days',
    )

    const triggeredAtFromNow =
      rawTriggeredAtFromNowInDays > 0
        ? moment(triggeredAtInDays).fromNow()
        : 'Today'
    const alertMessage = _.get(alert, 'metadata.message', '')
    const tableData = _.get(alert, 'metadata.data', {})
    const locationId = _.get(alert, 'metadata.location_id', 0)

    return {
      alertUserNotifiedId,
      isRead,
      alertName: _.get(
        alert,
        'alertSpecificationBySpecification.alertName',
        '',
      ),
      metadata: _.get(alert, 'metadata', {}),
      triggeredAtFromNow,
      rawTriggeredAtFromNowInDays,
      alertMessage,
      alertDefinitionLevel,
      tableData,
      locationId,
    }
  })

  const sortedAlerts = _.sortBy(alerts, [
    'rawTriggeredAtFromNowInDays',
    'locationId',
  ])

  return (
    <Main
      isLoading={loading}
      alerts={sortedAlerts}
      alertsDisplayed={alertsDisplayed}
      onToggleArchivedAlerts={onToggleArchivedAlerts}
      retrieveAlerts={retrieveAlerts}
    />
  )
}

export default MyDashboard
