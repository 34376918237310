import { useState } from 'react'

import { IReportResult } from 'pared/components/CustomizedReport'

export interface ICategoryFilterType {
  id: string
  parentId: 'root' | null
  value: string
  text: string
}

const useItemFilter = (
  customizedReportResult: IReportResult | undefined,
  presetItemFilter: string,
  variableName: string = 'item',
) => {
  const [itemFilter, setItemFilter] = useState(presetItemFilter)
  if (!customizedReportResult?.tableData || itemFilter === '') {
    return {
      itemFilter,
      setItemFilter,
      itemFilteredCustomizedReportResult: customizedReportResult,
    }
  }

  const itemFilterArray = itemFilter
    .split(';')
    .map((item) => {
      return item.trim().toLowerCase()
    })
    .filter((item) => {
      return !!item
    })

  const allItemNames = customizedReportResult.tableData.map((data) => {
    return data[variableName]
  })
  const bestMatchItemSet = new Set<string>()

  itemFilterArray.forEach((filter) => {
    for (const item of allItemNames) {
      if (item.trim().toLowerCase().indexOf(filter) >= 0) {
        bestMatchItemSet.add(item.toLowerCase())
      }
    }
  })

  const filteredTableData = customizedReportResult.tableData.filter((data) => {
    const lowerCaseItem = data[variableName].toLowerCase()
    let isMatched = false
    const filterCount = itemFilterArray.length

    for (let ii = 0; ii < filterCount; ++ii) {
      const filter = itemFilterArray[ii]

      let isValid = true
      try {
        new RegExp(filter)
      } catch (e) {
        isValid = false
      }

      if (
        isValid &&
        (bestMatchItemSet.has(lowerCaseItem) ||
          lowerCaseItem.search(filter) !== -1)
      ) {
        isMatched = true
        break
      }
    }

    return isMatched
  })

  return {
    itemFilter,
    setItemFilter,
    itemFilteredCustomizedReportResult: {
      ...customizedReportResult,
      tableData: filteredTableData as any,
    },
  }
}

export default useItemFilter
