import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBrand } from 'pared/utils/brand'
import {
  toFormattedInteger,
  toPercentString,
  toUsdStr,
} from 'pared/utils/number'

import {
  IListLocationGroupLossPreventionKpisType,
  LIST_LOCATION_GROUP_LOSS_PREVENTION_KPIS,
} from '../gql'
import useLossPreventionConfig, {
  IKpiSettingType,
} from './useLossPreventionConfig'

const useKpis = (
  locationGroupIds: number[],
  isBreakdownByDirectors: boolean,
  startDate?: string,
  endDate?: string,
) => {
  const brand = getBrand()
  const config = useLossPreventionConfig()

  const { data, error, loading } =
    useQuery<IListLocationGroupLossPreventionKpisType>(
      LIST_LOCATION_GROUP_LOSS_PREVENTION_KPIS,
      {
        variables: {
          iStartDate: startDate,
          iEndDate: endDate,
          iFilter: {
            location_group_ids: isBreakdownByDirectors
              ? [BRAND_LOCATION_GROUP_ID[brand]]
              : [...locationGroupIds],
          },
        },
        skip: !startDate || !endDate || !locationGroupIds,
      },
    )

  return useMemo(() => {
    const rawData = data?.listLocationGroupLossPreventionKpis?.nodes?.[0]

    if (!rawData) return []

    const kpis = config?.kpiSettings?.map((kpi: IKpiSettingType) => {
      if (kpi.category === 'basic') {
        const value = (() => {
          switch (kpi.key) {
            case 'cashOverOrShort':
              return toUsdStr(rawData?.cashOverOrShort / 100.0)
            case 'oloOverOrShort':
              return toUsdStr(rawData?.oloOverOrShort / 100.0)
            case 'compsTotalAmount':
              return toUsdStr(rawData?.compsTotalAmount / 100.0)
            case 'compsTotalCount':
              return toFormattedInteger(rawData?.compsTotalCount)
            case 'inaccurateCashierShiftPercent':
              return toPercentString(rawData?.inaccurateCashierShiftPercent)
            case 'compsPercent':
              return toPercentString(rawData?.compsPercent)
          }
        })()

        return {
          key: kpi.key,
          value,
          text: kpi.title,
        }
      } else if (kpi.category === 'custom') {
        const data = rawData?.lossPreventionItems?.find(
          (item) => item.type === kpi.type,
        )
        const value = (() => {
          switch (kpi.key) {
            case 'amount':
              return toUsdStr((data?.amount || 0) / 100.0 || '')
            case 'count':
              return toFormattedInteger(data?.count || '')
            case 'checkPercent':
              return toPercentString(data?.checkPercent || '')
          }
        })()

        return {
          key: kpi.type,
          value,
          text: kpi.title,
        }
      } else {
        return { key: '', value: '', text: '' }
      }
    })

    return kpis
  }, [config, data, locationGroupIds, startDate, endDate])
}

export default useKpis
