import React from 'react'
import styled from 'styled-components'

import StarLabel from './StarLabel'
import useColumns from './hooks/useColumns'
import { IDataSourceType } from './types'

interface IPropsType {
  dataSource: IDataSourceType[]
}

const Table = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  line-height: 18px;
`

const Tr = styled.tr`
  &:nth-child(3n + 1) {
    background: #f1efef;

    & > th,
    td {
      font-family: Lexend-SemiBold;
      font-weight: 700;
    }
  }
`

const Th = styled.th`
  width: 150px;
  height: 45px;
  text-align: center;
`

const Td = styled.td<{
  isForecast?: boolean
}>`
  width: 150px;
  height: 45px;
  text-align: center;
  font-family: Lexend-Regular;
  ${({ isForecast }) =>
    !isForecast
      ? `
        font-family: Lexend-Regular;
      `
      : `
        font-family: Lexend-SemiBold;
        font-weight: 700;
        color: #07A4C7;
      `}

  &:first-child {
    font-family: Lexend-SemiBold;
    font-weight: 700;
  }

  svg {
    margin: 0px 0px 0px 5px;
    vertical-align: text-bottom;
  }
`

const Labels = styled.div`
  margin: 35px 0px 0px;
  display: flex;
  align-items: center;
  font-family: Lexend-SemiBold;
  font-weight: 600;

  & > * {
    &:nth-child(2n) {
      margin: 0px 0px 0px 12px;
    }

    &:nth-child(2n + 1):not(:first-child) {
      margin: 0px 0px 0px 30px;
    }
  }
`

const Label = styled.div<{
  color: string
}>`
  display: inline-block;
  width: 20px;
  height: 20px;
  ${({ color }) => `background: ${color};`}
`

const WEEK_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

const LABELS = [
  {
    key: 'Forecast',
    color: '#07A4C7',
    Label,
  },
  {
    key: 'Actual',
    color: '#000000',
    Label,
  },
  {
    key: 'Holiday',
    color: '#FA0C0C',
    Label: StarLabel,
  },
]

const BunForecase = ({ dataSource }: IPropsType) => {
  const columns = useColumns(dataSource)

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th />
            {WEEK_DAYS.map((day) => (
              <Th key={day}>{day}</Th>
            ))}
            <th />
          </tr>
        </thead>

        <tbody>
          {columns.map(({ type, values }) => (
            <Tr key={type}>
              {values.map(({ key, value, isForecast, isHoilday }) => (
                <Td key={key} isForecast={isForecast}>
                  {value}

                  {!isHoilday ? null : <StarLabel />}
                </Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </Table>

      <Labels>
        {LABELS.map(({ key, color, Label }) => (
          <React.Fragment key={key}>
            <Label color={color} />

            <div>{key}</div>
          </React.Fragment>
        ))}
      </Labels>
    </>
  )
}

export default React.memo(BunForecase)
