import { gql } from '@apollo/client'

export interface ISalesExaminerDataNodeType {
  locationId: number
  currentSales: number
  yoySales: number
  salesGrow: number
  budget: number
}

export interface IListItemsType {
  listLocationSalesExaminerData: {
    nodes: ISalesExaminerDataNodeType[]
  }
}

export interface ISalesExaminerVariableTypes {
  iStartDate: string
  iEndDate: string
  iDayPart: string
  iRevenueCenter: string
  iFilter: any
  isBreakdownByDirectors: boolean
}

export const LIST_LOCATION_SALES_EXAMINER_DATA = gql`
  query ListLocationSalesExaminerData(
    $iDayPart: String!
    $iRevenueCenter: String!
    $iFilter: JSON!
    $iStartDate: Date!
    $iEndDate: Date!
    $isBreakdownByDirectors: Boolean!
  ) {
    listLocationSalesExaminerData(
      iDayPart: $iDayPart
      iEndDate: $iEndDate
      iFilter: $iFilter
      iRevenueCenter: $iRevenueCenter
      iStartDate: $iStartDate
    ) @skip(if: $isBreakdownByDirectors) {
      nodes {
        locationId
        currentSales
        yoySales
        salesGrow
        budget
      }
    }

    listLocationGroupSalesExaminerData(
      iDayPart: $iDayPart
      iRevenueCenter: $iRevenueCenter
      iFilter: $iFilter
      iEndDate: $iEndDate
      iStartDate: $iStartDate
    ) @include(if: $isBreakdownByDirectors) {
      nodes {
        locationGroupId
        currentSales
        yoySales
        salesGrow
        budget
      }
    }
  }
`

export const GET_STORE_LIST = gql`
  query GetStoreListByBrand(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationDetails: listLocationDetailsV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        id
        code
        name
        locationGroups
      }
    }
  }
`
