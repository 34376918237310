import _ from 'lodash'
import { useMemo } from 'react'

import {
  toFormattedInteger,
  toPercentString,
  toUsdString,
} from 'pared/utils/number'

import useLocationGroupSalesmanshipKpiData from './useLocationGroupSalesmanshipKpiData'
import useSalesmanshipConfig from './useSalesmanshipConfig'

export interface IKpiDisplayDatum {
  name: string
  value: number
  displayString: string
}

function useDisplayData(
  locationGroupId: number,
  startDate: string,
  endDate: string,
) {
  const salesmanshipConfig = useSalesmanshipConfig()

  const {
    isLoading: isSalesmanshipKpiDataLoading,
    data: salesmanshipKpiData,
    sosData,
  } = useLocationGroupSalesmanshipKpiData(
    [locationGroupId],
    null,
    startDate,
    endDate,
  )

  return useMemo(() => {
    const kpiDefs = salesmanshipConfig.kpiDefs
    let kpiDisplayData: IKpiDisplayDatum[] = []
    const rawSalesmanshipKpiData: any = salesmanshipKpiData?.[0] || {}
    const loadingString = 'loading'

    kpiDefs.forEach((def: any) => {
      let value = 0
      let displayString = '-'

      if (def.kpiType === 'sales') {
        if (isSalesmanshipKpiDataLoading) {
          displayString = loadingString
        }

        let salesTypeData: any = {}
        if (def.salesType === 'total') {
          salesTypeData = rawSalesmanshipKpiData?.totalSalesDetails
        } else {
          salesTypeData =
            rawSalesmanshipKpiData?.categorizedSalesDetails?.[def.salesType]
        }

        value = salesTypeData?.[def.kpiKey] || 0

        switch (def.valueType) {
          case 'count':
            displayString = toFormattedInteger(value)
            break
          case 'cent':
            displayString = toUsdString(value / 100.0, def.digitNum)
            break
          case 'percent':
            displayString = toPercentString(value, def.digitNum)
            break
          default:
          // do nothing
        }
      }

      kpiDisplayData.push({
        value,
        displayString,
        name: def.name,
      })
    })

    if (salesmanshipConfig?.speedOfService?.isVisible) {
      const sosDetails = sosData?.[0]?.sosDetails
      salesmanshipConfig.speedOfService.windows.forEach((windowInfo: any) => {
        kpiDisplayData.push({
          name: windowInfo.displayName,
          value: sosDetails?.[windowInfo?.type]?.avgSeconds || 0,
          displayString: sosDetails?.[windowInfo?.type]?.avgSeconds || '0',
        })
      })
    }

    return {
      kpiDisplayData,
      isLoading: isSalesmanshipKpiDataLoading,
    }
  }, [
    salesmanshipConfig,
    salesmanshipKpiData,
    sosData,
    isSalesmanshipKpiDataLoading,
    locationGroupId,
    startDate,
    endDate,
  ])
}

export default useDisplayData
