import leaflet from 'leaflet'
import { useEffect, useState } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

import Control from './Control'
import Point from './Point'
import useApi, { IApiKeyType } from './hooks/useApi'

export interface IPropsType<K extends IApiKeyType = IApiKeyType> {
  type: 'map'
  api: K
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const Container = styled(MapContainer)`
  width: 1100px;
  height: 552px;
`

const LegendContainer = styled.div`
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0px;
  gap: 10px;
  font-family: Lexend-Regular;

  & > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`

const LegendColor = styled.div<{ color: string }>`
  width: 15px;
  height: 10px;
  background-color: ${({ color }) => color};
  border-radius: 2px;
`

const defaultGeoInfo = {
  latitude: 37.0902,
  longitude: -95.7129,
  zoom: 5,
}

const Map = ({ api }: IPropsType<IApiKeyType>) => {
  const { data, loading } = useApi(api)
  const [{ latitude, longitude, zoom }, setGeoInfo] = useState(defaultGeoInfo)

  useEffect(() => {
    const latitudes = (data || []).reduce(
      (result, d) => [...result, ...d.points.map((p) => p.latitude)],
      [] as number[],
    )
    const minLatitude = Math.min(...latitudes)
    const maxLatitude = Math.max(...latitudes)
    const averageLatitude = (minLatitude + maxLatitude) / 2

    const longitudes = (data || []).reduce(
      (result, d) => [...result, ...d.points.map((p) => p.longitude)],
      [] as number[],
    )
    const minLongitude = Math.min(...longitudes)
    const maxLongitude = Math.max(...longitudes)
    const averageLongitude = (minLongitude + maxLongitude) / 2

    setGeoInfo({
      latitude:
        latitudes.length === 0 ? defaultGeoInfo.latitude : averageLatitude,
      longitude:
        longitudes.length === 0 ? defaultGeoInfo.longitude : averageLongitude,
      zoom: defaultGeoInfo.zoom,
    })
  }, [data])

  if (loading || !data) return null

  return (
    <>
      <Container center={[latitude, longitude]} zoom={zoom}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          minZoom={4}
        />

        <Control latitude={latitude} longitude={longitude} zoom={zoom} />

        {data.map(({ key, points }, index) => (
          <MarkerClusterGroup
            key={key}
            showCoverageOnHover={false}
            iconCreateFunction={(cluster) =>
              leaflet.divIcon({
                html: `<span>${cluster.getChildCount()}</span>`,
                className: `marker-cluster-custom marker-cluster-${
                  index % COLORS.COLOR_HUE.length
                }`,
                iconSize: leaflet.point(40, 40, true),
              })
            }
          >
            {points.map(({ key, ...point }) => (
              <Point {...point} key={key} />
            ))}
          </MarkerClusterGroup>
        ))}
      </Container>

      <LegendContainer>
        {data
          .filter(({ key }) => key !== 'Others')
          .map(({ key, name }, index) => {
            const color = COLORS.COLOR_HUE[index % COLORS.COLOR_HUE.length]

            return (
              <div key={key}>
                <LegendColor color={color} />
                <div>{name}</div>
              </div>
            )
          })}
      </LegendContainer>
    </>
  )
}

export default Map
