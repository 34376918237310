import { ApolloError, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { LIST_HOLIDAYS } from '../../gql'

type IHoliday = {
  holidayDate: string
  holidayName: string
}

const useHolidays = () => {
  const startDate = moment().subtract(3, 'weeks').format('YYYY-MM-DD')
  const endDate = moment().add(2, 'weeks').format('YYYY-MM-DD')

  const { data } = useQuery(LIST_HOLIDAYS, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
    },
  })

  return useMemo(() => {
    return (
      data?.listHolidays?.nodes?.map((holiday: IHoliday) =>
        moment(holiday['holidayDate'], 'YYYY-MM-DD', true),
      ) || []
    )
  }, [data])
}

export default useHolidays
