import { ApolloError } from '@apollo/client'
import { useCallback, useState } from 'react'
import React, { SetStateAction } from 'react'

import useDataSource from './useDataSource'
import usePlConfig, { IDisplaySequence } from './usePlConfig'
import { IReportFilterType } from './useReportFilter'

const useCollapseArrowClicked = (): {
  displayData: any[] | null
  isLoading: boolean
  error: ApolloError | null
  reportFilterValue: string
  locationFilter: string[]
  setLocationFilter: React.Dispatch<SetStateAction<string[]>>
  locationFilters: IReportFilterType[]
  reportFilter: string[]
  setReportFilter: React.Dispatch<SetStateAction<string[]>>
  reportFilters: IReportFilterType[]
  clickedHeaders: Set<string>
  onClick: (name: string) => void
} => {
  const plConfig = usePlConfig()

  const allSections: string[] = []
  const displaySequence: IDisplaySequence[] = plConfig?.displaySequence || []
  displaySequence.forEach((displayPart) => {
    if (displayPart.type === 'categorySection') {
      allSections.push(displayPart.name)
    }
  })

  const [clickedHeaders, setClickedHeaders] = useState<Set<string>>(
    new Set(allSections),
  )
  const onClick = useCallback(
    (name: string) => {
      const newClickedHeaders = new Set([...Array.from(clickedHeaders)])

      if (newClickedHeaders.has(name)) {
        newClickedHeaders.delete(name)
      } else {
        newClickedHeaders.add(name)
      }

      setClickedHeaders(newClickedHeaders)
    },
    [clickedHeaders],
  )

  const {
    data,
    isLoading,
    error,
    reportFilterValue,
    locationFilter,
    setLocationFilter,
    locationFilters,
    reportFilter,
    setReportFilter,
    reportFilters,
  } = useDataSource()

  const displayData: any = []
  let show = true
  data?.forEach((item) => {
    if (item.type === 'header') {
      show = clickedHeaders.has(item.name) ? false : true
      displayData.push(item)
    } else {
      if (
        item.type === 'subHeader' ||
        item.type === 'summary' ||
        item.type === 'spacer' ||
        show
      )
        displayData.push(item)
    }
  })

  return {
    displayData,
    isLoading,
    error,
    reportFilterValue,
    locationFilter,
    setLocationFilter,
    locationFilters,
    reportFilter,
    setReportFilter,
    reportFilters,
    clickedHeaders,
    onClick,
  }
}

export default useCollapseArrowClicked
