import React from 'react'
import styled from 'styled-components'

import CustomizedReport from 'pared/components/CustomizedReport'

const UserPageViewsTable = () => {
  return (
    <Container>
      <TitleDiv>Page Views</TitleDiv>
      <CustomizedReport
        reportName="USER_PAGE_VIEWS_TABLE"
        inputParams={{}}
        rowKey={({ page }) => page}
      />
    </Container>
  )
}

const Container = styled.div`
  margin: 0;
  padding: 0;
`

const TitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 40px;
`

export default React.memo(UserPageViewsTable)
