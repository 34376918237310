import stringSimilarity from 'string-similarity'

import { IOrderingGuideDataType } from './useDataSource'

const getOptions = ({
  reportResult,
  selectedVendor,
  presetCategoryOrItemFilter = '',
}: {
  reportResult: IOrderingGuideDataType[]
  selectedVendor: string
  presetCategoryOrItemFilter?: string
}) => {
  if (reportResult) {
    const filteredData =
      selectedVendor === 'All'
        ? reportResult
        : reportResult.filter((d) => d.vendor === selectedVendor)

    const uniqueCategories = filteredData
      .map((item) => item.itemType)
      .filter((value, index, self) => self.indexOf(value) === index)

    const purchaseOptions = [
      {
        name: 'All Items',
        type: 'All Items',
      },
      ...uniqueCategories.map((c) => ({
        name: c,
        type: 'Category',
      })),
      ...filteredData.map((row) => ({
        name: row.item,
        type: 'Item',
      })),
    ]

    let defaultPurchaseOption =
      purchaseOptions?.length > 0 ? purchaseOptions[0] : null

    let bestMatchOptionName = ''
    if (presetCategoryOrItemFilter && purchaseOptions?.length > 0) {
      const bestMatchResults = stringSimilarity.findBestMatch(
        presetCategoryOrItemFilter,
        purchaseOptions.map((option) => {
          return option.name
        }),
      )
      bestMatchOptionName = bestMatchResults?.bestMatch?.target
    }

    const purchaseOptionCount = purchaseOptions?.length || 0
    for (let io = 0; io < purchaseOptionCount; ++io) {
      const option = purchaseOptions[io]
      if (bestMatchOptionName) {
        if (option.name === bestMatchOptionName) {
          defaultPurchaseOption = option
          break
        }
      } else if (option.name === 'Food' && option.type === 'Category') {
        defaultPurchaseOption = option
        break
      }
    }

    return {
      purchaseOptions,
      defaultPurchaseOption,
    }
  }

  return {
    purchaseOptions: [],
    defaultPurchaseOption: null,
  }
}

export default getOptions
