import Popover from '@material-ui/core/Popover'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { ILocation } from 'pared/data/getLocations'
import PencilIcon from 'pared/images/Pared/pencil.svg'

import PositionPopover from '../PositionPopover'

interface IProps {
  positionAnchorEl: any
  setPositionAnchorEl: any
  allLocations: ILocation[] | null
  roleName: string
  timeVacant: string
  isIcon?: boolean
  onRefresh: () => Promise<void>
  positionId: number
  candidateName?: string
  comment?: string
  currentTrainingStoreId?: number
  currentFormattedPendingStartDate?: string
  hasEditPermission: boolean
}

const Main = ({
  positionAnchorEl,
  setPositionAnchorEl,
  allLocations,
  roleName,
  timeVacant,
  isIcon,
  onRefresh,
  positionId,
  candidateName,
  comment,
  currentTrainingStoreId,
  currentFormattedPendingStartDate,
  hasEditPermission,
}: IProps) => {
  const handleClick = (event: any) => {
    setPositionAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setPositionAnchorEl(null)
  }

  const open = Boolean(positionAnchorEl)
  const id = open ? 'simple-popover' : undefined

  const updatePositionContent = isIcon ? (
    <img src={PencilIcon} alt="Edit" />
  ) : (
    <>Update Position</>
  )

  return (
    <>
      {hasEditPermission ? (
        <UpdatePositionSpan onClick={handleClick}>
          {updatePositionContent}
        </UpdatePositionSpan>
      ) : (
        <NoPermissionToEditText>No permission to edit</NoPermissionToEditText>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={positionAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <PositionPopover
          allLocations={allLocations}
          roleName={roleName}
          timeVacant={timeVacant}
          onRefresh={onRefresh}
          closePopover={handleClose}
          positionId={positionId}
          candidateName={candidateName}
          currentComment={comment}
          currentTrainingStoreId={currentTrainingStoreId}
          currentFormattedPendingStartDate={currentFormattedPendingStartDate}
        />
      </Popover>
    </>
  )
}

const UpdatePositionSpan = styled.span`
  font-family: Lexend-Regular;
  color: #07a4c7;
  text-decoration: underline;
  float: left;
  cursor: pointer;
  text-align: center;
`

const NoPermissionToEditText = styled.div`
  margin-top: -6px;
  font-family: Lexend-Regular;
  color: ${COLORS.Steel};
`

export default Main
