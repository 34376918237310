import styled from 'styled-components'

export interface IPropsType {
  employee: string
  revieweeJobTitle: string
  storeCode: string
}

const StyledDiv = styled.div`
  padding: 5px 0px;
`

const EmployeeNameDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-weight: 700;
`

export const sorter = (a: IPropsType, b: IPropsType) =>
  b.employee.localeCompare(a.employee)

const FeedbackName = ({
  employee,
  revieweeJobTitle,
  storeCode,
}: IPropsType) => (
  <>
    <EmployeeNameDiv>{employee}</EmployeeNameDiv>
    <StyledDiv>{revieweeJobTitle}</StyledDiv>
    <div>{storeCode}</div>
  </>
)

export default FeedbackName
