import { useQuery } from '@apollo/client'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import COLORS from 'pared/constants/colors'
import { getBrand } from 'pared/utils/brand'
import { getLocationName } from 'pared/utils/location'

import {
  ISummaryType,
  ISummaryVariablesType,
  LOCATION_LOSS_PREVENTION_SUMMARY,
} from './gqls'

interface IPropsType {
  locationId: number
  startDate?: string
  endDate?: string
}

const PageStatus = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 1000px;

  @media ${MOBILE_WIDTH} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

const ConclusionDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: ${COLORS.Salt};
  padding: 30px;

  border-radius: 10px 10px 0 0;
  background: #0071e3;

  @media ${MOBILE_WIDTH} {
    flex: 1;
  }
`

const InsightDiv = styled.div`
  padding: 30px;
  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};

  border-radius: 0 0 10px 10px;
  border: 1px solid #81abfd;

  @media ${MOBILE_WIDTH} {
    flex: 1;
  }
`

const InsightTitle = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 0 0 15px 0;
`

const hideSummaryMessage = () => {
  switch (getBrand()) {
    case 'ghai_pop':
      return true

    default:
      return false
  }
}

const Summary = ({ locationId, startDate, endDate }: IPropsType) => {
  const { data, loading } = useQuery<ISummaryType, ISummaryVariablesType>(
    LOCATION_LOSS_PREVENTION_SUMMARY,
    {
      variables: {
        iLocationId: locationId,
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !startDate || !endDate,
    },
  )
  const summary = data?.locationLossPreventionSummary.nodes[0]
  const summaryMessage = useMemo(() => {
    if (!summary) return null

    const store = (
      <strong>{getLocationName(locationId, summary.locationInfo.name)}</strong>
    )
    const amount = <strong>{Math.abs(summary.amount) / 100}</strong>

    if (summary.rankPercent < 1 / 3)
      return (
        <>
          {store} is discounting {amount} more than expected in the time period
          selected
        </>
      )

    if (summary.rankPercent < 2 / 3)
      return (
        <>
          {store} is discounting {amount}{' '}
          <strong>{summary.amount > 0 ? 'more' : 'less'}</strong> than expected
          but is around average for the company for the time period selected.
        </>
      )

    return <>{store} is discounting as expected for the time period selected.</>
  }, [summary])

  const improvementMessage = useMemo(() => {
    if (!summary) return null

    const employeeDiscountInfos = summary.employeeDiscountInfos || []

    if (employeeDiscountInfos.length === 0)
      return 'No employees are discounting above the Company Average for this time period.'

    return (
      <>
        {employeeDiscountInfos.map(({ familyName, preferredName }, index) => (
          <React.Fragment key={index}>
            <strong>
              {preferredName} {familyName}
            </strong>

            {(() => {
              if (index === employeeDiscountInfos.length - 1) return null

              if (index === employeeDiscountInfos.length - 2) return ', and '

              return ', '
            })()}
          </React.Fragment>
        ))}{' '}
        are discounting more than company averages.
      </>
    )
  }, [summary])

  if (loading) return <PageStatus>Loading ...</PageStatus>

  if (!summary) return null
  // This looks weird when first loaded. Temporarily hide it before find better solution
  // return (
  //   <PageStatus>
  //     There is no data within the date range selected. Please select a
  //     different date range.
  //   </PageStatus>
  // )

  return (
    <SummaryContainer>
      <ConclusionDiv>{summaryMessage}</ConclusionDiv>
      {hideSummaryMessage() ? null : (
        <InsightDiv>
          <InsightTitle>EXPO INSIGHTS</InsightTitle>
          {improvementMessage}
        </InsightDiv>
      )}
    </SummaryContainer>
  )
}

export default Summary
