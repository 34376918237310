import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import {
  GROUP_BY_PERIOD,
  GROUP_BY_QUARTER,
  GROUP_BY_WEEK,
  GROUP_BY_YEAR,
  IDateRange,
  TYPE_QUARTER,
  TYPE_WEEK,
  TYPE_YEAR,
} from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'
import { getBrand } from 'pared/utils/brand'
import { getDateRangeLabelV2 } from 'pared/utils/date'
import { toPercentString, toUsdString } from 'pared/utils/number'

import { TREND_LOCATION_GROUP_SALESMANSHIP_KPIS } from '../gql'
import useSalesmanshipConfig from '../hooks/useSalesmanshipConfig'
import Main from './Main'

interface IProps {
  navParams: INavParams
  selectedDateRange: IDateRange | null
  selectedDirector: IDirector | null
}

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

function Chart({ navParams, selectedDateRange, selectedDirector }: IProps) {
  const config = useSalesmanshipConfig()
  const storeId = navParams.storeId
  const brand = getBrand()
  const locationId = parseInt(storeId || '0', 10) || 0

  const [listKpi, { loading: listKpiLoading, data: listKpiData }] =
    useLazyQuery(TREND_LOCATION_GROUP_SALESMANSHIP_KPIS)

  let groupBy = ''
  const selectedDateRangeType = _.get(selectedDateRange, 'type', 'period')
  if (selectedDateRangeType === TYPE_YEAR) {
    groupBy = GROUP_BY_YEAR
  } else if (selectedDateRangeType === TYPE_QUARTER) {
    groupBy = GROUP_BY_QUARTER
  } else if (selectedDateRangeType === TYPE_WEEK) {
    groupBy = GROUP_BY_WEEK
  } else {
    groupBy = GROUP_BY_PERIOD
  }

  useEffect(() => {
    const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
    let selectedLocationGroupId =
      _.get(selectedDirector, 'locationGroupId') || -1
    selectedLocationGroupId =
      selectedLocationGroupId < 0
        ? brandLocationGroupId
        : selectedLocationGroupId

    const endDate = _.get(selectedDateRange, 'endDateStr') || ''

    if (endDate) {
      listKpi({
        variables: {
          iLocationGroupId: selectedLocationGroupId,
          iEndDate: endDate,
          iGroupBy: groupBy,
          iUseLocationData: !!config.useLocationData,
        },
      })
    }
  }, [locationId, selectedDateRange, selectedDirector])

  let rawKpiMonthly = []

  if (
    listKpiData &&
    listKpiData.trendLocationGroupSalesmanshipKpis &&
    Array.isArray(listKpiData.trendLocationGroupSalesmanshipKpis.nodes)
  ) {
    rawKpiMonthly = _.get(
      listKpiData,
      'trendLocationGroupSalesmanshipKpis.nodes',
      [],
    )
  }

  const startEndDateData = _.map(rawKpiMonthly, (d) => {
    const groupByStartDate = _.get(d, 'startDate')
    const groupByEndDate = _.get(d, 'endDate')

    return {
      groupByStartDate,
      groupByEndDate,
      businessWeek: d.businessWeek,
      businessWeekOfMonth: d.businessWeekOfMonth,
      businessMonth: d.businessMonth,
      businessQuarter: d.businessQuarter,
      businessYear: d.businessYear,
    }
  })

  const xAxisData = _.map(startEndDateData, (k) => {
    return getDateRangeLabelV2(groupBy, k)
  })

  const yLeftAxis = config.salesmanshipChart.yLeftAxisKpiDefs
  const yAxisDataArr = []

  for (let i = 0; i < yLeftAxis.length; i++) {
    const colDef = yLeftAxis[i]
    const kpiDataArr: any = []
    rawKpiMonthly.forEach((rawData: any) => {
      let value = 0
      let displayValue = 0

      if (colDef.kpiType === 'sales') {
        let salesTypeData: any = {}
        if (colDef.salesType === 'total') {
          salesTypeData = rawData?.totalSalesDetails
        } else {
          salesTypeData = rawData?.categorizedSalesDetails?.[colDef.salesType]
        }

        value = salesTypeData?.[colDef.kpiKey] || 0

        switch (colDef.valueType) {
          case 'cent':
            displayValue = value / 100.0
            break
          case 'percent':
            displayValue = value
            break
          default:
          // do nothing
        }

        kpiDataArr.push(displayValue)
      }
    })

    yAxisDataArr.push({
      type: 'line',
      data: kpiDataArr,
      tooltipLabel: colDef.name,
      borderColor: colDef.color,
      backgroundColor: colDef.color,
      hidden: !!colDef.hidden,
    })
  }

  const yRightAxisDataArr: any = []
  rawKpiMonthly.forEach((rawData: any) => {
    let value = 0
    let displayValue = 0
    const colDef = config.salesmanshipChart.yRightAxisKpiDef

    if (colDef?.kpiType === 'sales') {
      let salesTypeData: any = {}
      if (colDef.salesType === 'total') {
        salesTypeData = rawData?.totalSalesDetails
      } else {
        salesTypeData = rawData?.categorizedSalesDetails?.[colDef.salesType]
      }

      value = salesTypeData?.[colDef.kpiKey] || 0

      switch (colDef.valueType) {
        case 'cent':
          displayValue = value / 100.0
          break
        default:
        // do nothing
      }

      yRightAxisDataArr.push(displayValue)
    }
  })

  if (yRightAxisDataArr.length > 0) {
    yAxisDataArr.push({
      type: 'bar',
      yAxisId: 'yRight',
      data: yRightAxisDataArr,
      tooltipLabel: config.salesmanshipChart.yRightAxisKpiDef?.name,
    })
  }

  const chartOptions = {
    title: '',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: config.salesmanshipChart.yLeftAxisLabel,
    yLeftTickCallback: (value: number) => {
      switch (config.salesmanshipChart.yLeftAxisValueType) {
        case 'cent':
          return toUsdString(value, 2)
        case 'percent':
          return toPercentString(value, 2, false)
        default:
          return toUsdString(value, 2)
      }
    },
    yRightAxisLabel: config.salesmanshipChart.yRightAxisLabel,
    yRightShowGridLines: false,
    yRightTickCallback: (value: number) => {
      switch (config.salesmanshipChart.yRightAxisValueType) {
        case 'cent':
          return toUsdString(value, 2)
        case 'percent':
          return toPercentString(value, 2, false)
        default:
          return toUsdString(value, 2)
      }
    },
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let datasetLabel = ''
        let separator = ''
        let yValue = ''
        if (tooltipItemContext.dataset && tooltipItemContext.dataset.label) {
          datasetLabel = tooltipItemContext.dataset.label
          separator = ': '
        }
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          let axisValueType = config.salesmanshipChart.yLeftAxisValueType
          if (tooltipItemContext.dataset.yAxisID === 'yRight') {
            axisValueType = config.salesmanshipChart.yRightAxisValueType
          }

          let displayString = ''
          const value = tooltipItemContext.parsed.y
          switch (axisValueType) {
            case 'cent':
              displayString = toUsdString(value, 2)
              break
            case 'percent':
              displayString = toPercentString(value, 2, false)
              break
            default:
              displayString = toUsdString(value, 2)
          }

          if (tooltipItemContext.datasetIndex === 0) {
            yValue += displayString
          } else {
            yValue = displayString
          }
        }
        return [datasetLabel, separator, yValue].join('')
      }
      return ''
    },
  }

  return (
    <Main
      isLoading={listKpiLoading}
      xAxisData={xAxisData}
      chartOptions={chartOptions}
      yAxisDataArr={yAxisDataArr}
    />
  )
}

export default Chart
