import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'

import { IReportResult } from 'pared/components/CustomizedReport'
import { DATE_DATABASE_FORMAT, DATE_DISPLAY_FORMAT } from 'pared/constants'
import { getBrand } from 'pared/utils/brand'

export interface IReportFilterType {
  id: string
  parentId: 'root' | null
  value: string
  text: string
}

export interface IWeekInfo {
  key: string
  weekIndex: number
  weekBeginDate: moment.Moment
  weekEndDate: moment.Moment
}

export const INVENTORY_REPORT_TYPE_PERIOD = 'Period'
export const INVENTORY_REPORT_TYPE_WEEKLY = 'Weekly'

const useReportFilter = (customizedReportResult?: IReportResult) => {
  const [reportFilter, setReportFilter] = useState<string[]>([])
  const brand = getBrand()

  const defaultMenuItemList = [
    {
      id: 'root',
      parentId: null,
      value: 'root',
      text: 'root',
    },
    {
      id: INVENTORY_REPORT_TYPE_PERIOD,
      parentId: 'root' as const,
      value: INVENTORY_REPORT_TYPE_PERIOD,
      text: INVENTORY_REPORT_TYPE_PERIOD,
    },
    {
      id: INVENTORY_REPORT_TYPE_WEEKLY,
      parentId: 'root' as const,
      value: INVENTORY_REPORT_TYPE_WEEKLY,
      text: INVENTORY_REPORT_TYPE_WEEKLY,
    },
  ]

  const filterData = useMemo(() => {
    let menuItemList: IReportFilterType[] = [
      {
        id: 'root',
        parentId: null,
        value: 'root',
        text: 'root',
      },
    ]

    switch (brand) {
      case 'bibibop':
      case 'mwb':
        menuItemList = defaultMenuItemList.filter(
          ({ id }) => ![INVENTORY_REPORT_TYPE_PERIOD].includes(id),
        )
        break
      default:
        menuItemList = defaultMenuItemList
        break
    }

    let allWeeks: IWeekInfo[] = []
    if (
      Array.isArray(
        customizedReportResult?.dynamicColumns?.varianceDollarWeekDetails,
      )
    ) {
      allWeeks =
        customizedReportResult?.dynamicColumns?.varianceDollarWeekDetails.map(
          (rawWeekDetails: any) => {
            const weekBeginDate = moment.utc(
              rawWeekDetails?.weekBeginDate,
              DATE_DATABASE_FORMAT,
              true,
            )
            const weekEndDate = moment.utc(
              rawWeekDetails?.weekEndDate,
              DATE_DATABASE_FORMAT,
              true,
            )
            return {
              key: `Daily in Week ${weekEndDate.format(DATE_DISPLAY_FORMAT)}`,
              weekIndex: rawWeekDetails?.weekIndex || 0,
              weekBeginDate: weekBeginDate.isValid() ? weekBeginDate : moment(),
              weekEndDate: weekEndDate.isValid() ? weekEndDate : moment(),
            }
          },
        )
    } else if (
      Array.isArray(
        customizedReportResult?.dynamicColumns?.varianceDollarDateDetails,
      )
    ) {
      menuItemList = [
        menuItemList[0],
        {
          id: 'Daily',
          parentId: 'root' as const,
          value: 'Daily',
          text: 'Daily',
        },
      ]
    } else if (
      Array.isArray(
        customizedReportResult?.dynamicColumns?.varianceDollarPeriodDetails,
      )
    ) {
      menuItemList.pop()
    }

    let shouldIncludeDaily = true
    switch (brand) {
      case 'jsc_bk':
      case 'jsc_bk_nso':
      case 'ghai_pop':
      case 'mwb':
      case 'burgerworks':
      case 'bibibop':
        shouldIncludeDaily = false
        break
      default:
        shouldIncludeDaily = true
    }

    if (shouldIncludeDaily) {
      allWeeks.forEach((weekInfo) => {
        menuItemList.push({
          id: weekInfo.key,
          parentId: 'root' as const,
          value: weekInfo.key,
          text: weekInfo.key,
        })
      })
    }

    return {
      menuItemList,
      allWeeks,
    }
  }, [customizedReportResult, brand])

  useEffect(() => {
    let defaultValue = INVENTORY_REPORT_TYPE_PERIOD
    switch (brand) {
      case 'jitb':
      case 'jsc_bk':
      case 'jsc_bk_nso':
      case 'bibibop':
      case 'mwb':
      case 'burgerworks':
        defaultValue = INVENTORY_REPORT_TYPE_WEEKLY
        break
      default:
        defaultValue = INVENTORY_REPORT_TYPE_PERIOD
    }
    if (filterData.menuItemList.length === 2) {
      setReportFilter([defaultValue])
    } else {
      setReportFilter([defaultValue])
    }
  }, [filterData, brand])

  return {
    reportFilter,
    setReportFilter,
    reportFilters: filterData.menuItemList,
    allWeeks: filterData.allWeeks,
  }
}

export default useReportFilter
