import FormControl from '@material-ui/core/FormControl'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import useGroupSelector from 'pared/components/GroupSelector/hooks/useGroupSelector'
import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import Select from 'pared/components/basicUi/select'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import {
  TYPE_CUSTOM,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import { getBrand } from 'pared/utils/brand'

import BreakdownByStore from './BreakdownByStore'
import KpiBar from './KpiBar'
import TrendChart from './TrendChart'

const CorporateCalls = ({ navParams }: { navParams: INavParams }) => {
  const brand = getBrand()
  const dateRange = useGetDateRange({ isCustomAllowed: true })
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  const { groupBy, setGroupBy, options, locationGroupId } = useGroupSelector({
    initialGroupBy: 'store',
    isBrandVisible: false,
  })

  const selectedLocationGroupId = locationGroupId
    ? parseInt(locationGroupId)
    : brandLocationGroupId

  const locationGroupType = groupBy.length === 2 ? groupBy[0] : null

  return (
    <>
      <Header>
        <PageLeftHeader>Systemwide Calls</PageLeftHeader>
        <Selectors>
          <RankingFormControl margin="dense">
            <Select
              value={groupBy}
              onChange={setGroupBy}
              dataSource={options}
              fullWidth
              displayEmpty
            />
          </RankingFormControl>
          <DateRangeSelector
            navParams={navParams}
            dateRangeOptions={[
              TYPE_WEEK,
              TYPE_PERIOD,
              TYPE_QUARTER,
              TYPE_YEAR,
              TYPE_LAST_WEEK,
              TYPE_THIS_WEEK,
              TYPE_YESTERDAY,
              TYPE_TRAILING_7_DAYS,
              TYPE_CUSTOM,
            ]}
          />
        </Selectors>
      </Header>
      <MobileSelectors>
        <RankingFormControl margin="dense">
          <Select
            value={groupBy}
            onChange={setGroupBy}
            dataSource={options}
            fullWidth
            displayEmpty
          />
        </RankingFormControl>
        <DateRangeSelector
          navParams={navParams}
          dateRangeOptions={[
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
            TYPE_YESTERDAY,
            TYPE_TRAILING_7_DAYS,
            TYPE_CUSTOM,
          ]}
        />
      </MobileSelectors>
      <MainContainer>
        <PageTitle>Systemwide Calls</PageTitle>
        {dateRange ? (
          <>
            <KpiBar
              locationGroupId={selectedLocationGroupId}
              startDate={dateRange.startDateStr}
              endDate={dateRange.endDateStr}
            />
            <VerticalSpacer />
            <TrendChart
              locationGroupId={selectedLocationGroupId}
              endDate={dateRange.endDateStr}
            />
            <VerticalSpacer />
            <BreakdownByStore
              locationGroupId={selectedLocationGroupId}
              locationGroupType={locationGroupType}
              startDate={dateRange.startDateStr}
              endDate={dateRange.endDateStr}
            />
          </>
        ) : null}
      </MainContainer>
    </>
  )
}

export default CorporateCalls

const MainContainer = styled.div`
  padding: 30px 25px;
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    @media ${MOBILE_WIDTH} {
      width: 100%;
    }
  }
`

const MobileSelectors = styled(Selectors)`
  display: none;
  @media ${MOBILE_WIDTH} {
    display: inherit;
  }
`
const RankingFormControl = styled(FormControl)`
  background-color: white;
`

const PageTitle = styled.div`
  display: none;
  font-family: Lexend-SemiBold;
  font-size: 18px;
  margin-bottom: 20px;
  @media ${MOBILE_WIDTH} {
    display: inherit;
  }
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
`

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;
  align-items: center;
  @media ${MOBILE_WIDTH} {
    display: none;
  }
`

const VerticalSpacer = styled.div`
  height: 50px;
`
