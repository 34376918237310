import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'
import { getBrandLocationGroupId } from 'pared/utils/brand'

import { useDateFilter } from '../../dateFilter'
import { IApiDataType } from '../types'

interface IQueryDataType {
  listLocationDetails: {
    nodes: {
      id: number
      code: string
      name: string
    }[]
  }
}

interface IQueryVariablesType {
  iFilter: {
    location_group_ids: [number]
  }
  iStartDate?: string
  iEndDate?: string
}

const query = gql`
  query FetchLocations($iFilter: JSON!, $iStartDate: Date!, $iEndDate: Date!) {
    listLocationDetails: listLocationDetailsV2(
      iFilter: $iFilter
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        id
        code
        name
      }
    }
  }
`

const useCompanyFilter = () => {
  const { brand } = useBrands()
  const locationGroupId = getBrandLocationGroupId()
  const { startDate, endDate } = useDateFilter()

  const { data, loading } = useQuery<IQueryDataType, IQueryVariablesType>(
    query,
    {
      variables: {
        iFilter: {
          location_group_ids: [locationGroupId],
        },
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !startDate || !endDate,
    },
  )

  return {
    data: useMemo((): IApiDataType => {
      if (!data) {
        return null
      }

      const values = [
        {
          id: 'Brand Breakdown',
          parentId: 'root',
          ids: [BRAND_LOCATION_GROUP_ID[brand]],
          list: [
            {
              id: BRAND_LOCATION_GROUP_ID[brand],
              name: 'Company',
              header: 'Company',
            },
          ],
          label: 'Company',
        },
        ...data.listLocationDetails.nodes.map((l) => {
          return {
            id: l.id.toString(),
            parentId: 'root',
            ids: [l.id],
            label: `${l.code} - ${l.name}`,
          }
        }),
      ]

      return {
        values,
        defaultValue: [values[0].id],
      }
    }, [data, brand, loading]),
    loading,
  }
}

export default useCompanyFilter
