import { useQuery } from '@apollo/client'
import React from 'react'
import styled from 'styled-components'

import Chart from 'pared/charts'
import { ILocationInfo } from 'pared/components/LocationInfo/hooks/useLocationInfo'
import Spin from 'pared/components/basicUi/spin'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import {
  GROUP_BY_DAY,
  GROUP_BY_PERIOD,
  GROUP_BY_QUARTER,
  GROUP_BY_WEEK,
  IDateRange,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_YEAR,
} from 'pared/data/getDateRanges'

import {
  IListLocationVsSystemwideAcrScoreType,
  IListLocationVsSystemwideAcrScoreVariablesType,
  listLocationVsSystemwideAcrScore,
} from './gql'
import useChartConfig from './hooks/useChartConfig'

interface IProps {
  selectedLocation: ILocationInfo
  selectedDateRange: IDateRange | null
}

const ChartContainer = styled.div`
  :not(:first-child) {
    margin: 40px 0px 0px;

    @media ${MOBILE_WIDTH} {
      margin: 20px 0px 0px;
    }
  }

  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const SubHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
`

const AcrChart = ({ selectedLocation, selectedDateRange }: IProps) => {
  let groupBy = GROUP_BY_DAY
  if (selectedDateRange?.type === TYPE_YEAR) {
    groupBy = GROUP_BY_QUARTER
  } else if (selectedDateRange?.type === TYPE_QUARTER) {
    groupBy = GROUP_BY_PERIOD
  } else if (selectedDateRange?.type === TYPE_PERIOD) {
    groupBy = GROUP_BY_WEEK
  }

  const { data, loading } = useQuery<
    IListLocationVsSystemwideAcrScoreType,
    IListLocationVsSystemwideAcrScoreVariablesType
  >(listLocationVsSystemwideAcrScore, {
    variables: {
      iLocationId: selectedLocation.id,
      iStartDate: selectedDateRange?.startDate?.format('YYYY-MM-DD'),
      iEndDate: selectedDateRange?.endDate?.format('YYYY-MM-DD'),
      iGroupBy: groupBy,
    },
  })

  const chartConfig = useChartConfig(
    data?.listLocationVsSystemwideAcrScore.nodes,
    selectedDateRange?.type,
    selectedLocation.name,
  )

  return (
    <>
      <SubHeader>ACR Score</SubHeader>

      <ChartContainer>
        <Spin spinning={loading}>
          <Chart {...chartConfig} width={1100} height={350} />
        </Spin>
      </ChartContainer>
    </>
  )
}

export default React.memo(AcrChart)
