import useCorpCtrlExpenses from './useCorpCtrlExpenses'
import useCorpEbitda from './useCorpEbitda'
import useCorpFnp from './useCorpFnp'
import useCorpLabor from './useCorpLabor'
import useCorpSales from './useCorpSales'
import useCtrlExpenses from './useCtrlExpenses'
import useExeSales from './useExeSales'
import useFnp from './useFoodAndPaper'
import useFoodCost from './useFoodCost'
import useLabor from './useLabor'
import useSales from './useSales'

export type IAiSummaryType =
  | 'bbbFoodCost'
  | 'bbbExeSales'
  | 'bbbSales'
  | 'bbbFnp'
  | 'bbbLabor'
  | 'bbbCtrlExpenses'
  | 'bbbCorpSales'
  | 'bbbCorpLabor'
  | 'bbbCorpEbitda'
  | 'bbbCorpFnp'
  | 'bbbCorpCtrlExpenses'

export const hooks = [
  ['bbbFoodCost', useFoodCost],
  ['bbbExeSales', useExeSales],
  ['bbbSales', useSales],
  ['bbbFnp', useFnp],
  ['bbbLabor', useLabor],
  ['bbbCtrlExpenses', useCtrlExpenses],
  ['bbbCorpSales', useCorpSales],
  ['bbbCorpLabor', useCorpLabor],
  ['bbbCorpEbitda', useCorpEbitda],
  ['bbbCorpFnp', useCorpFnp],
  ['bbbCorpCtrlExpenses', useCorpCtrlExpenses],
] as const
