import _ from 'lodash'

import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import { IOpsComp } from './index'

interface IProps {
  opsComps: IOpsComp[]
  rankedOpsComps: IOpsComp[]
  isOpsCompsExpanded: boolean
  setIsOpsCompsExpanded: any
  isLoading: boolean
  selectedMetrics: string
  setSelectedMetrics: any
}

const Main = ({
  opsComps,
  rankedOpsComps,
  isOpsCompsExpanded,
  setIsOpsCompsExpanded,
  isLoading,
  selectedMetrics,
  setSelectedMetrics,
}: IProps) => {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          isLoading={isLoading}
          opsComps={opsComps}
          isOpsCompsExpanded={isOpsCompsExpanded}
          setIsOpsCompsExpanded={setIsOpsCompsExpanded}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          isLoading={isLoading}
          rankedOpsComps={rankedOpsComps}
          opsComps={opsComps}
          isOpsCompsExpanded={isOpsCompsExpanded}
          setIsOpsCompsExpanded={setIsOpsCompsExpanded}
          selectedMetrics={selectedMetrics}
          setSelectedMetrics={setSelectedMetrics}
        />
      </SmallScreen>
    </>
  )
}

export default Main
