import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { BRAND_ID } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

import { useYtdEndDate } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const getQuery = (ids: number[]) => gql`
  query lfrListLocationGuest(
    $iBrandId: Int!
    $i30DaysAgo: Date!
    $i90DaysAgo: Date!
    $iEndDate: Date!
  ${ids.map(
    (id) => `
    $iFilter${id}: JSON!
  `,
  )}
  ) {
    brand(id: $iBrandId) {
      id
    }

${ids
  .map(
    (id) => `
    listLocationGuestKpis${id}30: listLocationGuestKpisV2(
      iStartDate: $i30DaysAgo
      iEndDate: $iEndDate
      iFilter: $iFilter${id}
    ) {
      nodes {
        locationId
        ratings30: ratings
        ratingScores30: ratingScores
        ratingCounts30: ratingCounts
      }
    }

    listLocationGuestKpis${id}90: listLocationGuestKpisV2(
      iStartDate: $i90DaysAgo
      iEndDate: $iEndDate
      iFilter: $iFilter${id}
    ) {
      nodes {
        locationId
        ratings90: ratings
        ratingScores90: ratingScores
        ratingCounts90: ratingCounts
      }
    }
  `,
  )
  .join('\n\n')}
  }
`

export const lfrListLocationGuestConfigs = {
  'ratings30.google': 'number',
  'ratings30.yelp': 'number',
  'ratings30.tripadvisor': 'number',
  dayAvg30: 'number',
  'ratings90.google': 'number',
  'ratings90.yelp': 'number',
  'ratings90.tripadvisor': 'number',
  dayAvg90: 'number',
} as const

const useLfrListLocationGuest = () => {
  const ytdEndData = useYtdEndDate()
  const days = useMemo(() => {
    if (!ytdEndData) return

    return {
      '30daysAgo': moment
        .utc(ytdEndData)
        .subtract(30, 'days')
        .format('YYYY-MM-DD'),
      '90daysAgo': moment
        .utc(ytdEndData)
        .subtract(90, 'days')
        .format('YYYY-MM-DD'),
      endDate: ytdEndData,
    }
  }, [ytdEndData])
  const { groupFilter } = useGroupFilter()
  const { brand } = useBrands()
  const { data, loading } = useQuery(getQuery(groupFilter?.ids || []), {
    variables: {
      ...groupFilter?.ids.reduce(
        (result, id) => ({
          ...result,
          [`iFilter${id}`]: { location_group_ids: [id] },
        }),
        {},
      ),
      iBrandId: BRAND_ID[brand],
      i30DaysAgo: days?.['30daysAgo'],
      i90DaysAgo: days?.['90daysAgo'],
      iEndDate: days?.endDate,
    },
    skip: !days || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      if (!groupFilter?.list || !data) return null

      const listLocationGuestKpis30 = groupFilter?.ids.reduce(
        (result, id) => [
          ...result,
          ...(data?.[`listLocationGuestKpis${id}30`].nodes || []),
        ],
        [] as {
          locationId: number
          ratingScores30: Record<string, number>
          ratingCounts30: Record<string, number>
        }[],
      )
      const listLocationGuestKpis90 = groupFilter?.ids.reduce(
        (result, id) => [
          ...result,
          ...(data?.[`listLocationGuestKpis${id}90`].nodes || []),
        ],
        [] as {
          locationId: number
          ratingScores90: Record<string, number>
          ratingCounts90: Record<string, number>
        }[],
      )

      return {
        source: groupFilter.list
          ?.filter((i) =>
            listLocationGuestKpis90?.find((l) => l.locationId === i.id),
          )
          .map((i) => {
            const guest30 = listLocationGuestKpis30?.find(
              (l) => l.locationId === i.id,
            )
            const guest90 = listLocationGuestKpis90?.find(
              (l) => l.locationId === i.id,
            )
            const total30 = ['yelp', 'google', 'tripadvisor'].reduce(
              (result, key) => ({
                score: result.score + (guest30?.ratingScores30[key] || 0),
                count: result.count + (guest30?.ratingCounts30[key] || 0),
              }),
              {
                score: 0,
                count: 0,
              },
            )
            const total90 = ['yelp', 'google', 'tripadvisor'].reduce(
              (result, key) => ({
                score: result.score + (guest90?.ratingScores90[key] || 0),
                count: result.count + (guest90?.ratingCounts90[key] || 0),
              }),
              {
                score: 0,
                count: 0,
              },
            )

            return {
              ...guest30,
              ...guest90,
              dayAvg30:
                total30.count === 0 ? '-' : total30.score / total30.count,
              dayAvg90:
                total90.count === 0 ? '-' : total90.score / total90.count,
              groupInfo: i,
            }
          }),
      }
    }, [groupFilter, data]),
    loading,
  }
}

export default useLfrListLocationGuest
