import { useQuery } from '@apollo/client'
import moment from 'moment'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import COLORS from 'pared/constants/colors'
import { getDateRangeLabel } from 'pared/utils/date'

import { TREND_LOCATION_CUSTOMIZED_REPORT } from './gql'

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350
const CHART_START_DATE = moment.utc('2022-05-15', 'YYYY-MM-DD', true)

interface IProps {
  trendChartParams: {
    iLocationReportName: string
    iLocationId: number
    iLocationGroupReportName: string
    iLocationGroupId: number
    iEndDate: string
    iGroupBy: string
  }
}

interface IData {
  trendLocationCustomizedReport: {
    nodes: {
      startDate: string
      endDate: string
      reportResult: {
        textData: {
          upsizePercent: string
        }
      }
    }[]
  }

  trendLocationGroupCustomizedReport: {
    nodes: {
      startDate: string
      endDate: string
      reportResult: {
        textData: {
          upsizePercent: string
        }
      }
    }[]
  }
}

interface IOrganizedData {
  dateRanges: string[]
  locationUpsizePercent: number[]
  locationGroupUpsizePercent: number[]
}

const UpsizeTrendChart = ({ trendChartParams }: IProps) => {
  const { loading, data }: { loading: boolean; data: IData | undefined } =
    useQuery(TREND_LOCATION_CUSTOMIZED_REPORT, {
      variables: trendChartParams,
      skip: !trendChartParams.iEndDate,
    })

  if (loading) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  if (!data) {
    return (
      <PageStatusDiv>
        There is no data within the date range selected. Please select a
        different date range.
      </PageStatusDiv>
    )
  }

  const locationData = data?.trendLocationCustomizedReport?.nodes || []
  const locationGroupData =
    data?.trendLocationGroupCustomizedReport?.nodes || []

  const processedDataMap: {
    [startDate: string]: {
      locationUpsizePercent: number
      locationGroupUpsizePercent: number
    }
  } = {}
  locationData.forEach((rawData: any) => {
    const startDate = rawData?.startDate
    const upsizePercent = rawData?.reportResult?.textData?.upsizePercent || ''
    const upsizePercentNum = parseFloat(upsizePercent) || 0.0
    const processedData = processedDataMap[startDate] || {}
    processedData.locationUpsizePercent = upsizePercentNum
    processedDataMap[startDate] = processedData
  })

  locationGroupData.forEach((rawData: any) => {
    const startDate = rawData?.startDate
    const upsizePercent = rawData?.reportResult?.textData?.upsizePercent || ''
    const upsizePercentNum = parseFloat(upsizePercent) || 0.0
    const processedData = processedDataMap[startDate] || {}
    processedData.locationGroupUpsizePercent = upsizePercentNum
    processedDataMap[startDate] = processedData
  })

  const organizedData = locationData.reduce(
    (acc, { startDate }) => {
      if (CHART_START_DATE.isBefore(startDate)) {
        acc.dateRanges.push(
          getDateRangeLabel(startDate, trendChartParams.iGroupBy),
        )
        acc.locationUpsizePercent.push(
          processedDataMap[startDate]?.locationUpsizePercent || 0.0,
        )
        acc.locationGroupUpsizePercent.push(
          processedDataMap[startDate]?.locationGroupUpsizePercent || 0.0,
        )
      }
      return acc
    },
    {
      dateRanges: [],
      locationUpsizePercent: [],
      locationGroupUpsizePercent: [],
    } as IOrganizedData,
  )

  const yAxisDataArr = [
    {
      type: 'line',
      data: organizedData.locationUpsizePercent,
      tooltipLabel: 'Store Upsizing %',
      borderColor: COLORS.Basil,
      backgroundColor: COLORS.Basil,
    },
    {
      type: 'line',
      data: organizedData.locationGroupUpsizePercent,
      tooltipLabel: 'Systemwide Upsizing %',
      borderColor: COLORS.Pomodoro,
      backgroundColor: COLORS.Pomodoro,
    },
  ]

  const chartOptions = {
    title: 'Upsizing',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: '% Upsized',
    yLeftTickCallback: (value: number) => {
      return `${value}%`
    },
  }

  return (
    <MixedChart
      xAxisData={organizedData.dateRanges}
      yAxisDataArr={yAxisDataArr}
      options={chartOptions}
    />
  )
}

export default UpsizeTrendChart

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`
