import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

type ICategory =
  | 'Sales'
  | 'Labor'
  | 'Food Cost'
  | 'Loss Prevention'
  | 'Guest'
  | 'Delivery'
  | 'Calls'
interface IConfig {
  categories: ICategory[]
  hasKeyItems?: boolean
}

const defaultConfig: IConfig = {
  categories: [
    'Sales',
    'Labor',
    'Food Cost',
    'Loss Prevention',
    'Guest',
    'Delivery',
  ],
}

export default (): IConfig => {
  const brand = getBrand()

  return useMemo(() => {
    switch (brand) {
      case 'wingitnorth':
        return {
          ...defaultConfig,
          categories: [
            'Sales',
            'Labor',
            'Food Cost',
            'Loss Prevention',
            'Guest',
            'Delivery',
            'Calls',
          ],
        }
      case 'ghai_pop':
        return {
          ...defaultConfig,
          categories: ['Sales', 'Labor', 'Food Cost', 'Guest', 'Delivery'],
        }
      case 'mwb':
        return {
          ...defaultConfig,
          categories: ['Sales', 'Labor', 'Food Cost', 'Guest', 'Delivery'],
          hasKeyItems: true,
        }
      case 'burgerworks':
        return {
          ...defaultConfig,
          categories: ['Sales', 'Labor', 'Food Cost', 'Guest', 'Delivery'],
        }
      default:
        return defaultConfig
    }
  }, [brand])
}
