import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { Skeleton } from '@mui/material'
import {
  RowPinningPosition,
  SortingFn,
  SortingFns,
  TableOptions,
  createColumnHelper,
} from '@tanstack/react-table'
import * as d3Interpolate from 'd3-interpolate'
import * as d3 from 'd3-scale'
import { useCallback, useMemo, useRef } from 'react'
import { InView } from 'react-intersection-observer'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import DownArrow from 'pared/images/basic/arrow/caret-down.svg'
import UpArrow from 'pared/images/basic/arrow/caret-up.svg'

import { useVariables } from '../../variables'
import Format, {
  IBasePropsType as IFormatBasePropsType,
  IPropsType as IFormatPropsType,
} from '../Format'
import { IApiType, LOADING } from '../types'
import { IApiKeyType, configs } from './useApi'
import { IDataType } from './useData'

declare module '@tanstack/table-core' {
  interface SortingFns {
    link: SortingFn<unknown>
  }
}

interface IBaseTableColumnsType {
  align?: 'left' | 'right' | 'center'
  hide?: `<%- ${string} %>`
  background?:
    | string
    | {
        range: number[]
        colors: string[]
        value: `<%- ${string} %>`
      }
  variableTest?: string
}

type IColumnType<
  V extends Record<string, Pick<IFormatPropsType, 'type'>['type']>,
> = {
  [K in keyof V]: Omit<
    Extract<
      IFormatPropsType,
      {
        type: V[K]
      }
    >,
    'value' | 'type'
  > & {
    key: K
    title?: string
    width?: string
    sortable?: boolean
    fixed?: true
  } & IBaseTableColumnsType &
    Omit<IFormatBasePropsType, 'value'>
}

type IColumnsType = {
  [K in keyof typeof configs]: IColumnType<typeof configs[K]>
}

export type IColumnsOptionsType<K extends IApiKeyType> = (
  | ({
      key: string
      title: string
      children: IColumnsOptionsType<K>
    } & IBaseTableColumnsType)
  | IColumnsType[K][keyof IColumnsType[K]]
)[]

interface ILoadVisibilityDataType {
  id: string
  field: string
  visible: boolean
}

const columnHelper = createColumnHelper<IDataType>()

const Header = styled.th<
  Pick<IBaseTableColumnsType, 'align'> & {
    width?: string
    isAction?: boolean
    background?: string
    paddingLeft?: number
  }
>`
  padding: 8px 10px 8px calc(10px + ${({ paddingLeft }) => paddingLeft || 0}rem);
  text-align: ${({ align }) => align || 'left'};
  ${({ width }) => (!width ? '' : `min-width: ${width};`)}
  font-family: Lexend-SemiBold;
  background: ${({ background }) => (!background ? 'inherit' : background)};
  ${({ isAction }) =>
    !isAction
      ? ''
      : `
        cursor: pointer;
        user-select: none;
        color: ${COLORS.Plum};
      `}
  border-bottom: 1px solid black;
  vertical-align: bottom;
`

const Cell = styled.td<
  Pick<IBaseTableColumnsType, 'align'> & {
    bold?: boolean
    fontSize?: number
    background?: string
    paddingLeft?: number
    isAction?: boolean
    fixed?: true
    isPinned: RowPinningPosition
  }
>`
  padding: 8px 10px 8px calc(10px + ${({ paddingLeft }) => paddingLeft || 0}rem);
  text-align: ${({ align }) => align || 'left'};
  font-family: ${({ bold }) => (bold ? 'Lexend-SemiBold' : 'Lexend-Regular')};
  font-size: ${({ fontSize }) => (!fontSize ? 'inherit' : `${fontSize}px`)};
  background: ${({ background }) => (!background ? 'inherit' : background)};
  ${({ isAction }) =>
    !isAction
      ? ''
      : `
        cursor: pointer;
        user-select: none;
      `}
  ${({ isPinned }) => {
    switch (isPinned) {
      case 'top':
        return `
        font-weight: 700;
        border-bottom: 1px solid black;
      `
      case 'bottom':
        return `
        font-weight: 700;
        border-top: 1px solid black;
      `
      default:
        return ''
    }
  }}
  ${({ fixed, background }) =>
    !fixed
      ? ''
      : `
    position: sticky;
    left: 0px;
    background: ${background || '#fafafa'};
    z-index: 1;

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: -1px;
      width: 30px;
      transform: translateX(100%);
      transition: box-shadow 0.3s;
      content: "";
      pointer-events: none;
      box-shadow: inset 10px 0 8px -8px rgba(5, 5, 5, 0.06);
    }
  `}

  & > svg:first-child {
    vertical-align: middle;
    margin-top: -0.1em;
  }
`

const ArrowImg = styled.img`
  width: 10px;
  ${({ alt }) =>
    alt === 'asc' ? 'margin: 0px 0px 2px 5px;' : 'margin: 0px 0px -1px 5px;'}
`

const getBackground = (
  background: IBaseTableColumnsType['background'],
  template: ReturnType<typeof useVariables>['template'],
  external: Record<string, unknown>,
) => {
  if (!background) return

  if (typeof background === 'string') return template(background, { external })

  const value = parseFloat(template(background.value, { external }))

  if (isNaN(value)) return

  return d3
    .scaleLinear(background.range, background.colors)
    .interpolate(d3Interpolate.interpolateHsl)(value)
}

const generateColumns = <K extends IApiKeyType>({
  config,
  columns,
  template,
  scrollContainer,
  loadVisibilityData,
  isPerentFarLeft = true,
  configKey,
  refetch,
}: {
  config: typeof configs[K]
  columns: IColumnsOptionsType<K>
  template: ReturnType<typeof useVariables>['template']
  scrollContainer: Element | null
  loadVisibilityData: (options: ILoadVisibilityDataType) => void
  isPerentFarLeft?: boolean
  configKey?: keyof typeof configs[K]
  refetch?: any
}): TableOptions<IDataType>['columns'] =>
  columns.reduce(
    (
      result,
      {
        key: originKey,
        hide: originHide,
        title: originTitle,
        background,
        ...column
      },
      columnIndex,
    ) => {
      const key: string | IColumnsOptionsType<K> =
        template(originKey as string) || ''
      const hide = !originHide ? false : template(originHide) === 'true'
      const isFarLeft: boolean = isPerentFarLeft && columnIndex === 0

      if (hide) return result

      if (key instanceof Array) {
        const newColumns = key.map((d) => ({
          ...d,
          ...column,
          background: d.background || background,
        }))

        return [
          ...result,
          ...generateColumns<K>({
            config,
            columns: newColumns,
            template,
            scrollContainer,
            loadVisibilityData,
            isPerentFarLeft: isFarLeft,
            configKey: originKey as keyof typeof configs[K],
            refetch,
          }),
        ]
      }

      const title = template(originTitle || ' ') as string
      const { align } = column

      if ('children' in column)
        return [
          ...result,
          columnHelper.group({
            id: key,
            header: ({ header }) => (
              <Header
                align={align}
                background={getBackground(background, template, {
                  rowKey: null,
                  columnKey: key,
                  value: null,
                  values: null,
                  depth: null,
                })}
                colSpan={header.colSpan}
              >
                {title}
              </Header>
            ),
            columns: generateColumns({
              config,
              columns: column.children.map((d) => ({
                ...d,
                background: d.background || background,
              })),
              template,
              scrollContainer,
              loadVisibilityData,
              isPerentFarLeft: isFarLeft,
              refetch,
            }),
          }),
        ]

      const { width, sortable, fixed, variableTest } = column
      const type = config[configKey || (key as keyof typeof config)]

      return [
        ...result,
        columnHelper.accessor(key, {
          header: ({ header, column, table }) => (
            <Header
              align={isFarLeft && table.getCanSomeRowsExpand() ? 'left' : align}
              paddingLeft={isFarLeft && table.getCanSomeRowsExpand() ? 1.5 : 0}
              width={width}
              isAction={sortable}
              onClick={column.getToggleSortingHandler()}
              background={getBackground(background, template, {
                rowKey: null,
                columnKey: key,
                value: null,
                values: null,
                depth: null,
              })}
              colSpan={header.colSpan}
            >
              {title}

              {(() => {
                const isSorted = column.getIsSorted()

                if (!isSorted) return null

                return (
                  <ArrowImg
                    src={isSorted === 'asc' ? UpArrow : DownArrow}
                    alt={isSorted}
                  />
                )
              })()}
            </Header>
          ),
          cell: ({ getValue, table, row }) => {
            const ExpandedIcon = row.getIsExpanded()
              ? ArrowDropDownIcon
              : ArrowRightIcon
            const expandable = isFarLeft && row.getCanExpand()
            const canSomeRowsExpand = table.getCanSomeRowsExpand()
            const value = getValue()
            const formatProps = {
              ...column,
              type,
              value,
              row: row.original,
              refetch,
            } as unknown as IFormatPropsType
            const data =
              variableTest &&
              template(variableTest, {
                showError: true,
                external: {
                  rowKey: row.id,
                  columnKey: key,
                  value,
                  values: row.original,
                  depth: row.depth,
                },
              })

            return (
              <Cell
                // rowIndex is the specific key to auto-show the row index by css
                // not need to have the data in the api hook except for summary
                // still need to set up the type into the api config
                className={key === 'rowIndex' && !value ? 'rowIndex' : ''}
                align={isFarLeft && canSomeRowsExpand ? 'left' : align}
                bold={canSomeRowsExpand && row.depth === 0}
                fontSize={(() => {
                  if (!canSomeRowsExpand) return

                  if (row.depth >= 3) return 14

                  return 18 - row.depth * 2
                })()}
                background={getBackground(background, template, {
                  rowKey: row.id,
                  columnKey: key,
                  value,
                  values: row.original,
                  depth: row.depth,
                })}
                isAction={expandable}
                paddingLeft={
                  isFarLeft && canSomeRowsExpand
                    ? row.depth * 2 + (expandable ? 0 : 1.5)
                    : 0
                }
                onClick={() => {
                  if (expandable) row.toggleExpanded()
                }}
                fixed={fixed}
                isPinned={row.getIsPinned()}
              >
                {!expandable ? null : <ExpandedIcon />}

                {data && (
                  <pre>
                    {typeof data === 'string'
                      ? data
                      : JSON.stringify(data, null, 2)}
                  </pre>
                )}

                {value === LOADING ? (
                  <InView
                    root={scrollContainer}
                    rootMargin="25%"
                    threshold={0}
                    onChange={(inView) => {
                      loadVisibilityData({
                        id: row.original.id as string,
                        field: key,
                        visible: inView,
                      })
                    }}
                    delay={500}
                    trackVisibility
                  >
                    <Skeleton animation="wave" />
                  </InView>
                ) : (
                  <Format {...formatProps} />
                )}
              </Cell>
            )
          },
          enableSorting: sortable || false,
          sortUndefined: 'last',
          sortingFn: ['link'].includes(type as unknown as string)
            ? (type as unknown as keyof SortingFns)
            : 'auto',
        }),
      ]
    },
    [] as TableOptions<IDataType>['columns'],
  )

const findScrollContainer = (element: Element | null) => {
  if (!element) return null

  let parent = element.parentElement

  while (parent) {
    const { overflow } = window.getComputedStyle(parent)

    if (overflow.split(' ').every((o) => o === 'auto' || o === 'scroll'))
      return parent

    parent = parent.parentElement
  }

  return document.documentElement
}

const useColumns = <K extends IApiKeyType>(
  config: typeof configs[K],
  columns: IColumnsOptionsType<K>,
  loadMore: IApiType['loadMore'],
  refetch: any,
) => {
  const { template } = useVariables()
  const cacheRef = useRef<Record<string, Record<string, boolean>>>({})
  const timeRef = useRef<number>()
  const tableRef = useRef(null)
  const loadVisibilityData = useCallback(
    ({ id, field, visible }: ILoadVisibilityDataType) => {
      if (!loadMore) return

      if (!cacheRef.current[id]) cacheRef.current[id] = {}

      if (visible) cacheRef.current[id][field] = visible
      else if (cacheRef.current[id][field]) delete cacheRef.current[id][field]

      clearTimeout(timeRef.current)
      timeRef.current = setTimeout(() => {
        const newFields = Object.keys(cacheRef.current)
          .map((key) => ({
            id: key,
            fields: Object.keys(cacheRef.current[key]),
          }))
          .filter(({ fields }) => fields.length !== 0)

        loadMore(newFields)
      }, 100)
    },
    [loadMore],
  )

  return {
    tableRef,
    columns: useMemo(
      () =>
        generateColumns({
          config,
          columns,
          template,
          scrollContainer: findScrollContainer(tableRef.current),
          loadVisibilityData,
          refetch,
        }),
      [config, columns, template, loadVisibilityData, refetch],
    ),
  }
}

export default useColumns
