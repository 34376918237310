import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import {
  GET_LOCATION_TOP_MISSING_ITEMS,
  ILocationTopMissingItemsType,
  ILocationTopMissingItemsVariablesType,
} from '../gqls'

export interface IDataSourceType {
  itemName: string
  totalCount: number
}

interface IProps {
  isBreakdownByDirectors: boolean
  startDate: string
  endDate: string
  locationGroupId?: number
  locationGroupIds?: number[]
  breakdownType?: string | null
  locationIds?: number[]
}

export default ({
  isBreakdownByDirectors,
  startDate,
  endDate,
  locationGroupId,
  locationGroupIds,
  breakdownType,
  locationIds,
}: IProps): {
  dataSource: IDataSourceType[]
  loading: boolean
} => {
  const filter = (() => {
    if (breakdownType) {
      return {
        location_group_types: [breakdownType],
      }
    } else if (isBreakdownByDirectors) {
      return {
        location_group_ids: locationGroupIds,
      }
    } else {
      if (locationGroupId) return { location_group_ids: [locationGroupId] }
      else return { location_ids: locationIds }
    }
  })()

  const { data, loading } = useQuery<
    ILocationTopMissingItemsType,
    ILocationTopMissingItemsVariablesType
  >(GET_LOCATION_TOP_MISSING_ITEMS, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: filter,
    },
  })

  const dataSource = useMemo(() => {
    if (!data?.getLocationTopMissingItems.nodes) return []

    return data.getLocationTopMissingItems.nodes
  }, [filter, data, loading])

  return {
    dataSource,
    loading,
  }
}
