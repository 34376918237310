import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

const defaultConfig = {
  productivityCoefficient: {
    isVisible: false,
  },
}

const pjDemoConfig = {
  productivityCoefficient: {
    isVisible: true,
  },
  staffSales: {
    roleNameOverride: 'Team Member',
  },
}

const config = {
  rmg: defaultConfig,
  sullivans: defaultConfig,
  eddiemerlots: defaultConfig,
  papajohns: defaultConfig,
  papajohns_demo: pjDemoConfig,
}

const useSalesmanshipConfig = (): any => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'rmg':
      case 'rmg_nso':
        return config.rmg
      case 'sullivans':
      case 'sullivans_nso':
        return config.sullivans
      case 'eddiemerlots':
      case 'eddiemerlots_nso':
        return config.eddiemerlots
      case 'papajohns':
        return config.papajohns
      case 'papajohns_demo':
        return config.papajohns_demo
      default:
        return defaultConfig
    }
  }, [brand])
}
export default useSalesmanshipConfig
