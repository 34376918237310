import styled from 'styled-components'

interface IProps {
  percentile: number | string
}

interface ICircleProps {
  percentile: number | string
}

interface ITickProps {
  position: number
}

const getColorFromPercentile = (percentile: number | string) => {
  if (typeof percentile === 'string') {
    percentile = parseInt(percentile)
  }

  let s = 0
  let l = 50

  if (percentile <= 50) {
    s = 100 - percentile * 2
  } else {
    l = 100 - percentile
  }

  return `hsl(0, ${s}%, ${l}%)`
}

const PercentileScale = ({ percentile }: IProps) => (
  <Container>
    <Line />
    <Tick position={0} />
    <Tick position={0.5} />
    <Tick position={1} />
    <Circle percentile={percentile}>{percentile}</Circle>
  </Container>
)

export default PercentileScale

const Container = styled.div`
  height: 30px;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: auto;
`

const Line = styled.div`
  height: 1px;
  width: 180px;
  background-color: black;
`

const Tick = styled.div<ITickProps>`
  height: 10px;
  width: 1px;
  background-color: black;
  position: absolute;
  left: calc(15px + ${(props) => props.position} * 180px);
`

const Circle = styled.div<ICircleProps>`
  border-radius: 50%;
  background-color: ${(props) => getColorFromPercentile(props.percentile)};
  color: white;
  width: 30px;
  height: 30px;
  position: absolute;
  left: calc(${(props) => props.percentile} / 100 * 180px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
`
