import paredAnalytics from 'pared/analytics/utils/ParedAnalytics'

export const track = {
  async giveFeedbackClicked() {
    await paredAnalytics.track('LeftNavigation - Give Feedback Clicked')
  },

  async changePasswordClicked() {
    await paredAnalytics.track('LeftNavigation - Change Password Clicked')
  },
}
