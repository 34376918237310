import axios from 'axios'
import moment from 'moment'
import { useMemo } from 'react'
import { useEffect, useState } from 'react'

import { getBrandSettings } from 'pared/customer'
import { getUser } from 'pared/utils/user'

import { IApiDataType, IColoredText } from '../types'

interface IPlaidLinkedBankAccount {
  financialInstitution: string
  bankAccountMask: string
  bankAccountName: string
  bankAccountOfficialName: string
  bankAccountPlaidId: string
  plaidAccessToken: string
  plaidAccessTokenLast10Chars: string
  balanceAvailable: number
  balanceCurrent: number
  balanceQueryDate: string
  latestTransactionDate: string
  latestTransactionDateColor: string
}

export const irmgBkPlaidConfigs = {
  bankAccountPlaidId: 'string',
  financialInstitution: 'string',
  bankAccountMask: 'string',
  bankAccountName: 'string',
  bankAccountOfficialName: 'string',
  plaidAccessToken: 'string',
  plaidAccessTokenLast10Chars: 'string',
  balanceAvailable: 'price',
  balanceCurrent: 'price',
  balanceQueryDate: 'string',
  coloredBalanceQueryDate: 'colored-text',
  latestTransactionDate: 'string',
  coloredLatestTransactionDate: 'colored-text',
} as const

const useIrmgBkPlaid = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [linkedBankAccounts, setLinkedBankAccounts] = useState<
    IPlaidLinkedBankAccount[]
  >([])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      setErrorMessage('')

      const { brand } = getBrandSettings()
      const user = getUser()
      let response: any = null

      try {
        response = await axios.post(
          `${process.env.REACT_APP_BE_BASE_URL}/plaid/linked_bank_accounts`,
          {
            userId: user.userId,
            userJwt: user.jwt,
            customerBrandCode: brand,
          },
          {
            timeout: 60000,
          },
        )

        setLinkedBankAccounts(
          (response?.data || []) as IPlaidLinkedBankAccount[],
        )
        setIsLoading(false)
      } catch (error: any) {
        console.error('Error:', error)
        setErrorMessage(
          error.message || 'Fail to get Plaid linked bank accounts',
        )
        setIsLoading(false)
      }
    }

    fetchData()
  }, [])

  return {
    data: useMemo((): IApiDataType => {
      const tableData: Record<string, unknown>[] = []
      linkedBankAccounts.forEach((account) => {
        const plaidAccessToken = (account.plaidAccessToken || '').trim()
        const plaidAccessTokenLast10Chars = plaidAccessToken
          ? `...${plaidAccessToken.substring(plaidAccessToken.length - 10)}`
          : ''

        if (account?.bankAccountPlaidId) {
          tableData.push({
            plaidAccessToken,
            plaidAccessTokenLast10Chars,
            bankAccountPlaidId: account.bankAccountPlaidId,
            financialInstitution: account.financialInstitution,
            bankAccountMask: account.bankAccountMask,
            bankAccountName: account.bankAccountName,
            bankAccountOfficialName: account.bankAccountOfficialName,
            balanceAvailable: account.balanceAvailable * 100.0,
            balanceCurrent: account.balanceCurrent * 100.0,
            balanceQueryDate: account.balanceQueryDate,
            coloredBalanceQueryDate: {
              text: account.balanceQueryDate,
              textColor: moment(account.balanceQueryDate, 'YYYY-MM-DD', true)
                .add(1, 'day')
                .isBefore(moment())
                ? 'danger'
                : '',
            },
            latestTransactionDate: account.latestTransactionDate,
            coloredLatestTransactionDate: {
              text: account.latestTransactionDate,
              textColor: account.latestTransactionDateColor,
            },
          })
        }
      })

      return { source: tableData }
    }, [linkedBankAccounts]),
    loading: isLoading,
  }
}

export default useIrmgBkPlaid
