import { IConfigsType } from '../../types'

const fwWingstopFlashProductMix: IConfigsType = {
  fw_wingstop: {
    '/:brand/product_mix': {
      variables: ['date', 'trend'],
      groupFilter: {
        api: 'fwWingstopCorporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: ['this_week', 'week'],
        defaultType: 'this_week',
      },
      reportTitle: {
        type: 'title',
        title: 'Product Mix by Week',
      },

      categoryCsvDownload: {
        type: 'download-csv',
        api: 'farwestFlashPmixCategoryData',
        fileName: 'getexpo-far-west-product-mix-category',
        message: 'Download as CSV',
        fields: [
          {
            key: 'categoryName',
            title: 'Category',
          },
          {
            key: 'totalSalesWeek4InPast',
            title: 'Total Sales (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'totalSalesWeek3InPast',
            title: 'Total Sales (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'totalSalesWeek2InPast',
            title: 'Total Sales (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'totalSalesWeek1InPast',
            title: 'Total Sales (<%- date?.getInfo(0)?.displayName %>)',
          },
          {
            key: 'salesPercentageWeek4InPast',
            title: '% of Sales (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'salesPercentageWeek3InPast',
            title: '% of Sales (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'salesPercentageWeek2InPast',
            title: '% of Sales (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'salesPercentageWeek1InPast',
            title: '% of Sales (<%- date?.getInfo(0)?.displayName %>)',
          },
          {
            key: 'quantitySoldWeek4InPast',
            title: 'Quantity (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'quantitySoldWeek3InPast',
            title: 'Quantity (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'quantitySoldWeek2InPast',
            title: 'Quantity (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'quantitySoldWeek1InPast',
            title: 'Quantity (<%- date?.getInfo(0)?.displayName %>)',
          },
        ],
      },
      categoryTable: {
        type: 'table',
        api: 'farwestFlashPmixCategoryData',
        pageSize: 30,
        heatmap: {
          direction: 'desc',
        },
        summaryToTop: true,
        columns: [
          {
            key: 'categoryName',
            title: 'Category',
            sortable: true,
          },
          {
            key: 'salesPercentage',
            title: '% of Sales',
            align: 'center',
            highlight: true,
            hasRightGutter: true,
            children: [
              {
                key: 'salesPercentageWeek4InPast',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'salesPercentageWeek3InPast',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'salesPercentageWeek2InPast',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'salesPercentageWeek1InPast',
                title: '<%- date?.getInfo(0)?.displayName %>',
                align: 'center',
                sortable: true,
                hasRightGutter: true,
              },
            ],
          },
          {
            key: 'quantitySold',
            title: 'Quantity',
            align: 'center',
            children: [
              {
                key: 'quantitySoldWeek4InPast',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'quantitySoldWeek3InPast',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'quantitySoldWeek2InPast',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'quantitySoldWeek1InPast',
                title: '<%- date?.getInfo(0)?.displayName %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },

      subcategoryCsvDownload: {
        type: 'download-csv',
        api: 'farwestFlashPmixSubcategoryData',
        fileName: 'getexpo-far-west-product-mix-subcategory',
        message: 'Download as CSV',
        fields: [
          {
            key: 'categoryName',
            title: 'Category',
          },
          {
            key: 'subcategoryName',
            title: 'Sub-Category',
          },
          {
            key: 'totalSalesWeek4InPast',
            title: 'Total Sales (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'totalSalesWeek3InPast',
            title: 'Total Sales (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'totalSalesWeek2InPast',
            title: 'Total Sales (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'totalSalesWeek1InPast',
            title: 'Total Sales (<%- date?.getInfo(0)?.displayName %>)',
          },
          {
            key: 'salesPercentageWeek4InPast',
            title: '% of Sales (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'salesPercentageWeek3InPast',
            title: '% of Sales (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'salesPercentageWeek2InPast',
            title: '% of Sales (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'salesPercentageWeek1InPast',
            title: '% of Sales (<%- date?.getInfo(0)?.displayName %>)',
          },
          {
            key: 'quantitySoldWeek4InPast',
            title: 'Quantity (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'quantitySoldWeek3InPast',
            title: 'Quantity (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'quantitySoldWeek2InPast',
            title: 'Quantity (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'quantitySoldWeek1InPast',
            title: 'Quantity (<%- date?.getInfo(0)?.displayName %>)',
          },
        ],
      },
      subcategoryTable: {
        type: 'table',
        api: 'farwestFlashPmixSubcategoryData',
        pageSize: 30,
        heatmap: {
          direction: 'desc',
        },
        summaryToTop: true,
        columns: [
          {
            key: 'categoryName',
            title: 'Category',
            sortable: true,
          },
          {
            key: 'subcategoryName',
            title: 'Sub-Category',
            sortable: true,
          },
          {
            key: 'salesPercentage',
            title: '% of Sales',
            align: 'center',
            highlight: true,
            hasRightGutter: true,
            children: [
              {
                key: 'salesPercentageWeek4InPast',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'salesPercentageWeek3InPast',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'salesPercentageWeek2InPast',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'salesPercentageWeek1InPast',
                title: '<%- date?.getInfo(0)?.displayName %>',
                align: 'center',
                sortable: true,
                hasRightGutter: true,
              },
            ],
          },
          {
            key: 'quantitySold',
            title: 'Quantity',
            align: 'center',
            children: [
              {
                key: 'quantitySoldWeek4InPast',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'quantitySoldWeek3InPast',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'quantitySoldWeek2InPast',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'quantitySoldWeek1InPast',
                title: '<%- date?.getInfo(0)?.displayName %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },

      itemCsvDownload: {
        type: 'download-csv',
        api: 'farwestFlashPmixItemData',
        fileName: 'getexpo-far-west-product-mix-item',
        message: 'Download as CSV',
        fields: [
          {
            key: 'categoryName',
            title: 'Category',
          },
          {
            key: 'subcategoryName',
            title: 'Sub-Category',
          },
          {
            key: 'itemName',
            title: 'Item Name',
          },
          {
            key: 'totalSalesWeek4InPast',
            title: 'Total Sales (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'totalSalesWeek3InPast',
            title: 'Total Sales (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'totalSalesWeek2InPast',
            title: 'Total Sales (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'totalSalesWeek1InPast',
            title: 'Total Sales (<%- date?.getInfo(0)?.displayName %>)',
          },
          {
            key: 'salesPercentageWeek4InPast',
            title: '% of Sales (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'salesPercentageWeek3InPast',
            title: '% of Sales (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'salesPercentageWeek2InPast',
            title: '% of Sales (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'salesPercentageWeek1InPast',
            title: '% of Sales (<%- date?.getInfo(0)?.displayName %>)',
          },
          {
            key: 'quantitySoldWeek4InPast',
            title: 'Quantity (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'quantitySoldWeek3InPast',
            title: 'Quantity (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'quantitySoldWeek2InPast',
            title: 'Quantity (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'quantitySoldWeek1InPast',
            title: 'Quantity (<%- date?.getInfo(0)?.displayName %>)',
          },
        ],
      },
      itemTable: {
        type: 'table',
        api: 'farwestFlashPmixItemData',
        pageSize: 30,
        heatmap: {
          direction: 'desc',
        },
        summaryToTop: true,
        columns: [
          {
            key: 'categoryName',
            title: 'Category',
            sortable: true,
          },
          {
            key: 'subcategoryName',
            title: 'Sub-Category',
            sortable: true,
          },
          {
            key: 'itemName',
            title: 'Item Name',
            sortable: true,
          },
          {
            key: 'salesPercentage',
            title: '% of Sales',
            align: 'center',
            highlight: true,
            hasRightGutter: true,
            children: [
              {
                key: 'salesPercentageWeek4InPast',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'salesPercentageWeek3InPast',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'salesPercentageWeek2InPast',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'salesPercentageWeek1InPast',
                title: '<%- date?.getInfo(0)?.displayName %>',
                align: 'center',
                sortable: true,
                hasRightGutter: true,
              },
            ],
          },
          {
            key: 'quantitySold',
            title: 'Quantity',
            align: 'center',
            children: [
              {
                key: 'quantitySoldWeek4InPast',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'quantitySoldWeek3InPast',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'quantitySoldWeek2InPast',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'quantitySoldWeek1InPast',
                title: '<%- date?.getInfo(0)?.displayName %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
    },
  },
}

export default fwWingstopFlashProductMix
