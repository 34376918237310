import { FormControl } from '@material-ui/core'
import { useState } from 'react'
import styled from 'styled-components'

import DateRangeNumberSelector from 'pared/components/DateRangeNumberSelector'
import {
  IItem,
  LAST_6_PERIODS,
  LAST_8_WEEKS,
  LAST_14_DAYS,
} from 'pared/components/DateRangeNumberSelector/hooks/useDateRange'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import CashControlTrendChart from './CashControlTrendChart'

interface IProps {
  isBreakdownByDirectors: boolean
  endDate?: string
  locationIds?: number[]
  locationGroupIds?: number[]
  shouldDisplayAll?: boolean
}

const SubHeader = styled.div`
  padding: 20px 0 0 0;
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    display: block;
    width: 100%;
    margin: 60px 0px 0px;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
`

const MobileCotainer = styled.div`
  padding: 20px 0;

  @media ${MOBILE_WIDTH} {
    margin: 20px 0px 0px;
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const StyledFormControl = styled(FormControl)`
  background-color: white;
  width: 180px;
`

export default function CashControlTrend({
  endDate,
  locationIds,
  locationGroupIds,
  isBreakdownByDirectors,
  shouldDisplayAll,
}: IProps) {
  const [cashDateRange, setCashDateRange] = useState<IItem['value']>(
    LAST_14_DAYS.value,
  )

  return (
    <>
      <SubHeader>
        <h1>CASH TREND</h1>
        <StyledFormControl variant="outlined" margin="dense">
          <DateRangeNumberSelector
            value={cashDateRange}
            onChange={setCashDateRange}
            dateRangeOptions={[LAST_14_DAYS, LAST_8_WEEKS, LAST_6_PERIODS]}
          />
        </StyledFormControl>
      </SubHeader>

      <MobileCotainer>
        <CashControlTrendChart
          locationIds={locationIds}
          locationGroupIds={locationGroupIds}
          isBreakdownByDirectors={isBreakdownByDirectors}
          endDate={endDate || ''}
          dateRange={cashDateRange}
          shouldDisplayAll={shouldDisplayAll}
        />
      </MobileCotainer>
    </>
  )
}
