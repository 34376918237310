import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const fwWingstopTimeClock: IConfigsType = {
  fw_wingstop: {
    '/:brand/corporate_time_clock': {
      groupFilter: {
        api: 'fwWingstopCorporateWithoutBreakdownFilter',
      },
      dateFilter: {
        types: ['week', 'this_week'],
        defaultType: 'this_week',
      },
      reportTitle: {
        type: 'title',
        title: 'Time Clock Report',
      },
      downloadtimeClockReport: {
        type: 'download-csv',
        api: 'fwWingstopTimeClock',
        fileName: 'getexpo-far-west-time-clock',
        message: 'Download as CSV',
        sortedBy: 'locationName',
        fields: [
          {
            key: 'locationName',
            title: 'Store',
          },
          {
            key: 'employeeNumber',
            title: 'Employee',
          },
          {
            key: 'hoursWorked',
            title: 'Hours Worked',
          },
          {
            key: 'scheduledHoursRemaining',
            title: 'Scheduled Hours Remaining',
          },
          {
            key: 'totalHoursOverThirtyFive',
            title: 'Total Hours - Over 35',
          },
          {
            key: 'totalHoursOverForty',
            title: 'Total Hours - Over 40',
          },
          {
            key: 'mealBreakViolations',
            title: 'Meal Break Violations',
          },
          {
            key: 'timeClockDetails',
            title: 'Meal Break Violations Detail',
          },
        ],
      },
      timeClockTable: {
        type: 'table-v2',
        api: 'fwWingstopTimeClock',
        columns: [
          {
            key: 'employeeNumber',
            title: 'Employee',
            align: 'left',
            width: '550px',
            sortable: true,
            background: `<%- depth === 1 ? "${COLORS.Smoke}" : "initial" %>`,
          },
          {
            key: 'hoursWorked',
            title: 'Hours Worked',
            align: 'center',
            decimal: 2,
            sortable: true,
            background: `<%- depth === 1 ? "${COLORS.Smoke}" : "initial" %>`,
          },
          {
            key: 'scheduledHoursRemaining',
            title: 'Scheduled Hours Remaining',
            align: 'center',
            decimal: 2,
            sortable: true,
            background: `<%- depth === 1 ? "${COLORS.Smoke}" : "initial" %>`,
          },
          {
            key: 'totalHoursOver',
            title: 'Total Hours Over',
            align: 'center',
            children: [
              {
                key: 'totalHoursOverThirtyFive',
                title: '35',
                align: 'center',
                decimal: 2,
                sortable: true,
                background: `<%- depth === 1 ? "${COLORS.Smoke}" : "initial" %>`,
              },
              {
                key: 'totalHoursOverForty',
                title: '40',
                align: 'center',
                decimal: 2,
                sortable: true,
                background: `<%- depth === 1 ? "${COLORS.Smoke}" : "initial" %>`,
              },
            ],
          },
          {
            key: 'mealBreakViolations',
            title: 'Meal Break Violations',
            align: 'center',
            sortable: true,
            background: `<%- depth === 1 ? "${COLORS.Smoke}" : "initial" %>`,
          },
          {
            key: 'timeClockDetails',
            title: 'Meal Break Violations Detail',
            align: 'left',
            background: `<%- depth === 1 ? "${COLORS.Smoke}" : "initial" %>`,
          },
        ],
      },
    },
  },
}

export default fwWingstopTimeClock
