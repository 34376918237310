import _ from 'lodash'
import { useMemo } from 'react'

import { IColumnsType } from '../types'
import { ISortedFieldOptionType } from './useSortedField'

const strToNum = (value: string) => {
  try {
    const result = parseFloat(value)

    return isNaN(result) ? null : result
  } catch (e) {
    return null
  }
}

const getNumberFromData = <Data>(value: Data): number => {
  if (typeof value === 'number') return value

  if (typeof value === 'string') return strToNum(value) || 0

  return 0
}

const getValueFromData = <Data>(data: { value: Data }): number => {
  return getNumberFromData(data.value)
}

export default <Data>(
  dataSource: Data[],
  current: number,
  pageSize: number,
  sortedField: ISortedFieldOptionType<Data>,
  mergedBody: IColumnsType<Data>[],
) =>
  useMemo(() => {
    const sortedDataSource = (() => {
      const sortedKey = sortedField.key

      if (!sortedKey) return dataSource

      const sorter = mergedBody.find(({ key }) => key === sortedKey)?.sorter

      if (!sorter) return dataSource

      return [...dataSource].sort((a, b) => {
        const aFixedPosition = _.get(a, 'fixedPosition')
        const bFixedPosition = _.get(b, 'fixedPosition')
        if (aFixedPosition && bFixedPosition) {
          return aFixedPosition - bFixedPosition
        }

        if (aFixedPosition > 0 || bFixedPosition < 0) {
          return -1
        }

        if (aFixedPosition < 0 || bFixedPosition > 0) {
          return 1
        }

        const aValue = _.get(a, sortedKey)
        const bValue = _.get(b, sortedKey)

        // Lock null or NaN values at the bottom of the list
        if (aValue === null || aValue === undefined || Number.isNaN(aValue)) {
          return 1
        }

        if (bValue === null || bValue === undefined || Number.isNaN(bValue)) {
          return -1
        }

        const value = (() => {
          if (typeof sorter === 'function') return sorter(aValue, bValue)

          if (typeof aValue === 'string' && typeof bValue === 'string') {
            const aNum = strToNum(aValue)
            const bNum = strToNum(bValue)

            return aNum !== null && bNum !== null
              ? aNum - bNum
              : aValue.localeCompare(bValue)
          }

          if (
            typeof aValue === 'object' &&
            'value' in aValue &&
            typeof bValue === 'object' &&
            'value' in bValue
          ) {
            return getValueFromData(aValue) - getValueFromData(bValue)
          }

          return getNumberFromData(aValue) - getNumberFromData(bValue)
        })()

        return value * (sortedField.direction === 'descend' ? -1 : 1)
      })
    })()

    return sortedDataSource.slice(current * pageSize, (current + 1) * pageSize)
  }, [dataSource, current, pageSize, sortedField, mergedBody])
