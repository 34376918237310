import React from 'react'
import styled from 'styled-components'

import Table, {
  IPropsType as ITablePropsType,
} from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import useDiscounts, { IDiscountsDataType } from './hooks/useDiscounts'
import useDiscountsColumns from './hooks/useDiscountsColumns'

interface IProps {
  locationId: number
  startDate?: string
  endDate?: string
}

const MobileCotainer = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 20px 0;

  @media ${MOBILE_WIDTH} {
    margin: 20px 0px 0px;
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const rowStyle = `
  td:first-child {
    font-family: Lexend-SemiBold;
    font-weight: 600px;
  }

  &:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }
`

const DiscountsTable = styled<
  React.ComponentType<ITablePropsType<IDiscountsDataType>>
>(Table)`
  width: 100%;
  max-width: 1200px;
`

export default function EmployeeDiscountTable({
  locationId,
  startDate,
  endDate,
}: IProps) {
  const discounts = useDiscounts({
    iStartDate: startDate,
    iEndDate: endDate,
    iFilter: {
      location_ids: [locationId],
    },
  })
  const discountsColumns = useDiscountsColumns()

  return (
    <MobileCotainer>
      <DiscountsTable
        dataSource={discounts}
        columns={discountsColumns}
        rowStyle={rowStyle}
        rowKey="rank"
      />
    </MobileCotainer>
  )
}
