import axios from 'axios'
import { useState } from 'react'
import { PlaidLink } from 'react-plaid-link'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { getUser } from 'pared/utils/user'

interface IProps {
  plaidLinkToken: string
  isLoading: boolean
  financialInstitution?: string
  bankAccountNickname?: string
  onLinkSuccess?: () => Promise<void>
}

function BankAccountLinkForm({
  plaidLinkToken,
  isLoading,
  financialInstitution,
  bankAccountNickname,
  onLinkSuccess,
}: IProps) {
  const [isLinking, setIsLinking] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const onPlaidLinkSuccess = async (publicToken: string) => {
    setIsLinking(true)
    setErrorMessage('')

    const user = getUser()

    try {
      await axios.post(
        `${process.env.REACT_APP_BE_BASE_URL}/plaid/access_token/create`,
        {
          userId: user.userId,
          userJwt: user.jwt,
          plaidPublicToken: publicToken,
        },
        {
          timeout: 60000,
        },
      )

      if (onLinkSuccess) {
        await onLinkSuccess()
      }

      setIsLinking(false)
    } catch (error: any) {
      console.error('Error:', error)
      setErrorMessage(error.message || 'Fail to link the bank account to Plaid')
      setIsLinking(false)
    }
  }

  return (
    <FormContainer>
      <FormRow>
        {isLinking ? (
          <StatusText>Linking ...</StatusText>
        ) : (
          <PlaidLink
            token={plaidLinkToken}
            onSuccess={onPlaidLinkSuccess}
            // onEvent={...}
            // onExit={...}
            style={{
              width: '100%',
              height: '60px',
              backgroundColor: COLORS.Link,
              cursor: 'pointer',
              color: COLORS.Salt,
              fontSize: '16px',
            }}
          >
            Link your bank account
          </PlaidLink>
        )}
      </FormRow>

      {errorMessage ? (
        <FormRow>
          <ErrorText>{errorMessage}</ErrorText>
        </FormRow>
      ) : null}
    </FormContainer>
  )
}

const FormContainer = styled.div`
  width: 350px;
`

const FormRow = styled.div`
  width: 100%;
`

const ErrorText = styled.span`
  color: ${COLORS.Pomodoro};
`

const StatusText = styled.span`
  color: ${COLORS.Steel};
  font-style: italic;
  font-weight: bold;
`

export default BankAccountLinkForm
