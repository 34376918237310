import styled from 'styled-components'

import useApi, { IApiKeyType } from './hooks/useApi'

interface IPropsType {
  api?: IApiKeyType
}

const Container = styled.div`
  position: relative;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  background-color: #f4cd00;
  border-radius: 50%;
  color: black;
`

const Text = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Notification = ({ api }: IPropsType) => {
  const { data } = useApi(api)

  if (!data) return null

  return (
    <Container>
      <Text>{data}</Text>
    </Container>
  )
}

export type { IApiKeyType }
export default Notification
