import paredAnalytics from 'pared/analytics/utils/ParedAnalytics'

export const track = {
  async managerProfileLinkClicked(managerId: number) {
    await paredAnalytics.track('Team - Manager Profile Link Changed', {
      managerId,
    })
  },

  async managerStatusEdited(managerId: number) {
    await paredAnalytics.track('Team - Manager Status Edited', { managerId })
  },

  async managerFeedbackAdded(managerId: number) {
    await paredAnalytics.track('Team - Manager Feedback Added', { managerId })
  },

  async managerFeedbackEdited(managerId: number) {
    await paredAnalytics.track('Team - Manager Feedback Edited', { managerId })
  },
}
