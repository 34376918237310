import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { toUsdStr } from 'pared/utils/number'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

interface IListLocationGfpReportsNodeType {
  kpis: string
  kpiDisplayIndex: number
  kpiValue: number
  kpiPercentage: number
  ttmKpiValue: number
  ttmKpiPercentage: number
}

const query = gql`
  query ListLocationGfpReports(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocation: Int!
  ) {
    listLocationGfpReports(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iLocation: $iLocation
    ) {
      nodes {
        kpis
        kpiDisplayIndex
        kpiValue
        kpiPercentage
        ttmKpiValue
        ttmKpiPercentage
      }
    }
  }
`

export const lfrListLocationGfpReportConfigs = {
  name: 'string',
  kpiValue: 'string',
  kpiPercentage: 'percent',
  ttmKpiValue: 'string',
  ttmKpiPercentage: 'percent',
} as const

const GFP_GROUP_MAPPING = {
  Covers: 0,
  PPA$: 0,
  'Sales - Food': 1,
  'Sales - Bar': 1,
  'Sales - Other': 1,
  'Total Sales': 1,
  'COGS - Food': 2,
  'COGS - Bar': 2,
  'COGS - Other': 2,
  'Total Labor': 2,
  'Prime Costs': 2,
  Controllables: 3,
  'R&M': 3,
  Utilities: 3,
  Occupancy: 3,
  Advertising: 3,
  Insurance: 3,
  'G&A Exp': 3,
  'Mgmt Fee': 3,
  EBITDA: 3,
}

const GFP_SUMARY_MAPPING = {
  0: '-',
  1: 'Total Sales',
  2: 'Prime Costs',
  3: 'EBITDA',
}

const useLfrListLocationGfpReport = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iLocation: groupFilter?.ids[0],
    },
    skip: !startDate || !endDate || !groupFilter || hasGroupBy,
  })

  return {
    data: useMemo((): IApiDataType => {
      const rawReports: IListLocationGfpReportsNodeType[] | undefined =
        data?.listLocationGfpReports.nodes

      const reports = rawReports?.map((r) => {
        return {
          id: r.kpis,
          name: r.kpis,
          groupId: (GFP_GROUP_MAPPING as any)[r.kpis],
          kpis: r.kpis,
          kpiValue:
            r.kpis === 'Covers'
              ? (Math.round(r.kpiValue) || '-').toLocaleString()
              : toUsdStr(r.kpiValue || '-'),
          kpiPercentage: r.kpiPercentage,
          ttmKpiValue:
            r.kpis === 'Covers'
              ? (Math.round(r.ttmKpiValue) || '-').toLocaleString()
              : toUsdStr(r.ttmKpiValue || '-'),
          ttmKpiPercentage: r.ttmKpiPercentage,
        }
      })

      return reports?.reduce((result, row) => {
        const groupId = row.groupId
        if (groupId === undefined) return result

        const index = result.findIndex((r: any) => r.id === groupId.toString())
        const groupName = (GFP_SUMARY_MAPPING as any)[groupId]

        if (index !== -1) {
          if (groupName !== row.kpis) {
            result[index].source.push(row)
          } else {
            result[index].summary = {
              name: row.kpis,
              kpiValue: row.kpiValue,
              kpiPercentage: row.kpiPercentage,
              ttmKpiValue: row.ttmKpiValue,
              ttmKpiPercentage: row.ttmKpiPercentage,
            }
          }
        } else {
          if (groupName === '-') {
            result.push({
              id: groupId.toString(),
              parentId: 'root',
              source: [row],
              summary: {
                name: ' ',
                kpiValue: null,
                kpiPercentage: null,
                ttmKpiValue: null,
                ttmKpiPercentage: null,
              },
            })
          } else if (groupName !== row.kpis) {
            result.push({
              id: groupId.toString(),
              parentId: 'root',
              source: [row],
              summary: {
                name: groupName,
              },
            })
          }
        }

        return result
      }, [] as any)
    }, [data]),
    loading: loading,
  }
}

export default useLfrListLocationGfpReport
