import { useMemo } from 'react'

import { toPercentString, toUsdString } from 'pared/utils/number'

import { useVariables } from '../../../variables'
import type { IApiDataType } from '../../types'
import useFetchGroupMetricValue from './useFetchGroupMetricValue'

const useBbbExeSales = (): IApiDataType => {
  const { variables } = useVariables()
  const { data, loading } = useFetchGroupMetricValue([
    'total_sales',
    'total_sales_budget',
    'check_count',
    'check_average',
  ])

  return {
    data: useMemo(() => {
      if (!data) return null

      const currentPeriod = variables.date?.getInfo(0)
      const sales = data.total_sales ?? 0
      const priorSales = data.prior_total_sales ?? 0
      const yoySales = data.yoy_total_sales ?? 0
      const salesBudget = data.total_sales_budget ?? 0

      const salesVar = sales - salesBudget

      // ADD TEXT HERE START

      const salesVarText =
        salesVar <= 0
          ? `Missing the ${toUsdString(salesBudget)} budget by ${toUsdString(
              -salesVar,
            )}`
          : `Beating the ${toUsdString(salesBudget)} budget by ${toUsdString(
              salesVar,
            )}`

      const salesVarPeriodChgText =
        priorSales === 0
          ? `No Sales data from Prior Period for comparison`
          : sales >= priorSales
          ? `Increasing ${toPercentString(
              ((sales - priorSales) / priorSales) * 100,
            )} from Prior Period of ${toUsdString(priorSales)}`
          : `Decreasing ${toPercentString(
              ((priorSales - sales) / priorSales) * 100,
            )} from Prior Period of ${toUsdString(priorSales)}`

      const salesYoyChgText =
        yoySales === 0
          ? `No Sales data from Prior Year for comparison`
          : sales >= yoySales
          ? `Increasing ${toPercentString(
              ((sales - yoySales) / yoySales) * 100,
            )} from Prior Year of ${toUsdString(yoySales)}`
          : `Decreasing ${toPercentString(
              ((yoySales - sales) / yoySales) * 100,
            )} from Prior Year of ${toUsdString(yoySales)}`

      // ADD TEXT HERE END

      return {
        title: `Sales`,
        total: salesVar * -1,
        summary: `Sales in ${currentPeriod?.displayName} was ${toUsdString(
          sales,
        )}`,
        detail: `
          <ul>
            <li>${salesVarText}</li>
            <li>${salesVarPeriodChgText}</li>
            <li>${salesYoyChgText}</li>
          </ul>`,
        hasDetails: true,
      }
    }, [variables, data]),
    loading,
  }
}

export default useBbbExeSales
