import { gql, useApolloClient, useMutation } from '@apollo/client'
import { useEffect } from 'react'

import { getUser } from 'pared/utils/user'

const tracking = gql`
  mutation tracking($iUserId: Int!, $iDetail: JSON!) {
    tracking(input: { iUserId: $iUserId, iEvent: "load", iDetail: $iDetail }) {
      boolean
    }
  }
`

const getVersion = gql`
  query getVersion {
    version @rest(type: "String", path: "/version")
  }
`

const useOnLoad = () => {
  const client = useApolloClient()
  const [mutation] = useMutation(tracking)
  const user = getUser()

  useEffect(() => {
    if (user.userId) {
      ;(async () => {
        mutation({
          variables: {
            iUserId: user.userId,
            iDetail: { page: window.location.pathname },
          },
        })

        const serverVersion = window.serverData?.version
        const {
          data: { version },
        } = await client.query({ query: getVersion })

        if (serverVersion && version && serverVersion !== version)
          // eslint-disable-next-line
          location.reload()
      })()
    }
  }, [window.location.pathname])
}

export default useOnLoad
