import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import moment from 'moment'
import { useState } from 'react'
import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'
import Table from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { DATE_FORMAT } from 'pared/data/getDateRanges'

import useSchedulingAnalysisColumns, {
  IValueType,
} from './hooks/useSchedulingAnalysisColumns'
import useSchedulingAnalysisData, {
  IDataKeyType,
} from './hooks/useSchedulingAnalysisData'

const options: IKpiType[] = [
  {
    key: 'unproductiveLabor',
    title: 'Unproductive Hours',
    valueType: 'count',
  },
  {
    key: 'savings',
    title: 'Savings',
    valueType: 'price',
  },
]

interface IKpiType {
  key: IDataKeyType
  title: string
  valueType: IValueType
}

interface IProps {
  locationId: number
}

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
`

const Header = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    gap: 10px;
  }
`

const StyledFormControl = styled(FormControl)`
  width: 280px;
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

const VerticalSpacer = styled.div`
  height: 20px;
`

const rowStyle = `
  td:first-child {
    font-family: Lexend-SemiBold;
    font-weight: 600px;
  }

  &:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }
`

const SchedulingAnalysisTable = ({ locationId }: IProps) => {
  const [kpi, setKpi] = useState<IDataKeyType>(options[0].key)

  const today = moment().format(DATE_FORMAT)
  const nextThirtyDays = moment().add(30, 'days').format(DATE_FORMAT)

  const { loading, schedulingAnalysisData } = useSchedulingAnalysisData(
    kpi,
    locationId,
    today,
    nextThirtyDays,
  )

  const schedulingAnalysisColumns = useSchedulingAnalysisColumns(
    options.find((o) => o.key === kpi)?.valueType || 'count',
    schedulingAnalysisData,
  )

  return (
    <>
      <Header id="scheduling-analysis">
        <SectionTitleDiv>Scheduling Analysis</SectionTitleDiv>
        <StyledFormControl variant="outlined" size="small">
          <Select
            value={kpi}
            onChange={(e: any) => {
              setKpi(e.target.value as IDataKeyType)
            }}
          >
            {options.map(({ key, title }) => {
              return (
                <MenuItem key={key} value={key}>
                  {title}
                </MenuItem>
              )
            })}
          </Select>
        </StyledFormControl>
      </Header>
      <VerticalSpacer />
      <Spin spinning={loading}>
        <Table
          dataSource={schedulingAnalysisData}
          columns={schedulingAnalysisColumns}
          rowStyle={rowStyle}
          csvFileName={`scheduling-analysis-${today}-${nextThirtyDays}`}
        />
      </Spin>
    </>
  )
}

export default SchedulingAnalysisTable
