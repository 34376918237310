import { gql } from '@apollo/client'

export const LIST_HOLIDAYS = gql`
  query listHolidays($iStartDate: Date!, $iEndDate: Date!) {
    listHolidays(iStartDate: $iStartDate, iEndDate: $iEndDate) {
      nodes {
        holidayName
        holidayDate
      }
    }
  }
`
