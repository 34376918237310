import { gql, useMutation } from '@apollo/client'
import Button from '@mui/material/Button'

const MUTATION_UPDATE_USER_DEACTIVATION_STATUS = gql`
  mutation UpdateUserDeactivationStatus($userId: Int!, $newValue: Boolean!) {
    updateUserDeactivationStatus(
      input: { iUserId: $userId, iNewValue: $newValue }
    ) {
      boolean
    }
  }
`

const DeactivateUserButton = ({
  userId,
  curValue,
  refetch,
}: {
  userId: number
  curValue: string
  refetch: any
}) => {
  const [updateUserDeactivationStatus, { loading }] = useMutation(
    MUTATION_UPDATE_USER_DEACTIVATION_STATUS,
  )
  const handleClick = async () => {
    const shouldProceed = window.confirm(
      `Are you sure you want to ${
        curValue === 'Yes' ? 'activate' : 'deactivate'
      } this user?`,
    )

    if (shouldProceed) {
      await updateUserDeactivationStatus({
        variables: {
          userId,
          newValue: curValue === 'Yes' ? false : true,
        },
      })

      refetch()
    }
  }

  return (
    <>
      <div>{curValue}</div>
      <Button disabled={loading} onClick={handleClick}>
        {curValue === 'Yes' ? 'Activate' : 'Deactivate'}
      </Button>
    </>
  )
}

export default DeactivateUserButton
