import { useMemo } from 'react'

import { IApiDataType, IExrayDataType } from '../types'
import { IAiSummaryType as IBbbAiSummaryType, hooks as bbbHooks } from './bbb'
import {
  IAiSummaryType as ICommonAiSummaryType,
  hooks as commonHooks,
} from './common'
import {
  IAiSummaryType as IGhaiPopAiSummaryType,
  hooks as ghaiPopHooks,
} from './ghaiPop'

export type IAiSummaryType =
  | ICommonAiSummaryType
  | IBbbAiSummaryType
  | IGhaiPopAiSummaryType

interface IDataType extends IExrayDataType {
  type: Extract<IAiSummaryType, string>
}

interface IAiSummaryDataType {
  data: IDataType[] | null
  loading: boolean
}

const hooks = [...commonHooks, ...bbbHooks, ...ghaiPopHooks] as [
  IDataType['type'],
  (options?: IAiSummaryType) => IApiDataType,
][]

const useApi = (types: IAiSummaryType[]): IAiSummaryDataType => {
  const aiSummaries: [IDataType['type'], IApiDataType][] = []

  for (const [type, hook] of hooks) {
    const option = types.find((t) => t === type)

    if (option) aiSummaries.push([type, hook(option)])
  }

  return useMemo(
    () =>
      aiSummaries.reduce(
        (result, [type, { data, loading }]) => ({
          data: !data
            ? result.data
            : [...(result.data || []), { ...data, type }],
          loading: result.loading || loading,
        }),
        {
          data: null,
          loading: false,
        } as IAiSummaryDataType,
      ),
    aiSummaries,
  )
}

export default useApi
