import TrendChart, { ITrendData } from './TrendChart'

interface IProps {
  data: {
    [kpiValueType: string]: {
      [kpiName: string]: ITrendData[]
    }
  }
}

function Trend({ data }: IProps) {
  const kpiValueTypes = Object.keys(data)
  const trendCharts: any[] = []

  kpiValueTypes.forEach((type: string) => {
    trendCharts.push(
      <TrendChart key={type} kpiValueType={type} data={data?.[type]} />,
    )
  })

  return <div>{trendCharts}</div>
}

export default Trend
