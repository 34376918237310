import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import { IPeriod } from 'pared/data/getPeriods'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'

interface IProps {
  selectedValue: string
  canDropdownOpen: boolean
  setCanDropdownOpen: any
  allPeriods: IPeriod[] | null
  onClickShifts: (event: any) => void
  onClickPeriod: (event: any) => void
  onClickSmallScreenFilterType: (event: any) => void
  onClickDateRange: (ranges: any) => void
  dateRangeStartDate: any
  dateRangeEndDate: any
  selectedPeriod: IPeriod | null
  filterType: string
  numDaysWorked: number
  isSmallScreenDateRangeDiaglogOpen: boolean
  setIsSmallScreenDateRangeDiaglogOpen: any
}

const Main = ({
  selectedValue,
  canDropdownOpen,
  setCanDropdownOpen,
  allPeriods,
  onClickShifts,
  onClickPeriod,
  onClickSmallScreenFilterType,
  onClickDateRange,
  dateRangeStartDate,
  dateRangeEndDate,
  selectedPeriod,
  filterType,
  numDaysWorked,
  isSmallScreenDateRangeDiaglogOpen,
  setIsSmallScreenDateRangeDiaglogOpen,
}: IProps) => {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          selectedValue={selectedValue}
          canDropdownOpen={canDropdownOpen}
          setCanDropdownOpen={setCanDropdownOpen}
          allPeriods={allPeriods}
          onClickShifts={onClickShifts}
          onClickPeriod={onClickPeriod}
          onClickDateRange={onClickDateRange}
          dateRangeStartDate={dateRangeStartDate}
          dateRangeEndDate={dateRangeEndDate}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          filterType={filterType}
          selectedValue={selectedValue}
          canDropdownOpen={canDropdownOpen}
          setCanDropdownOpen={setCanDropdownOpen}
          allPeriods={allPeriods}
          onClickShifts={onClickShifts}
          onClickPeriod={onClickPeriod}
          onClickSmallScreenFilterType={onClickSmallScreenFilterType}
          onClickDateRange={onClickDateRange}
          dateRangeStartDate={dateRangeStartDate}
          dateRangeEndDate={dateRangeEndDate}
          selectedPeriod={selectedPeriod}
          numDaysWorked={numDaysWorked}
          isSmallScreenDateRangeDiaglogOpen={isSmallScreenDateRangeDiaglogOpen}
          setIsSmallScreenDateRangeDiaglogOpen={
            setIsSmallScreenDateRangeDiaglogOpen
          }
        />
      </SmallScreen>
    </>
  )
}

export default Main
