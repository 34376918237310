import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { BRAND_ID } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

import { IApiDataType } from '../types'

interface IQueryDataType {
  listDirectors: {
    nodes: {
      locationGroupId: number
      firstName: string
      lastName: string
    }[]
  }
}

interface IQueryVariablesType {
  iFilter: {
    brand_ids: [number]
  }
}

const query = gql`
  query lfrBrandsGroupFilter($iFilter: JSON!) {
    listDirectors(iFilter: $iFilter) {
      nodes {
        locationGroupId
        firstName
        lastName
      }
    }
  }
`

const useLfrBrandsGroupFilter = () => {
  const { brand } = useBrands()
  const brandId = BRAND_ID[brand]
  const { data, loading } = useQuery<IQueryDataType, IQueryVariablesType>(
    query,
    {
      variables: {
        iFilter: {
          brand_ids: [brandId],
        },
      },
    },
  )

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null
      const values = [
        {
          id: brandId.toString(),
          parentId: 'root',
          ids: [brandId],
          label: 'All Brands',
          list: data.listDirectors.nodes.map((n) => ({
            id: n.locationGroupId,
            header: 'Brand',
            name: `${n.firstName} ${n.lastName}`,
          })),
        },
      ]

      return {
        values,
        defaultValue: [values[0].id],
      }
    }, [data, brandId]),
    loading,
  }
}

export default useLfrBrandsGroupFilter
