import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import DownArrow from 'pared/images/basic/arrow/caret-down.svg'
import UpArrow from 'pared/images/basic/arrow/caret-up.svg'

import useMergedHead from './hooks/useMergedHead'
import { ISortedFieldReturnType } from './hooks/useSortedField'
import { IColumnsType } from './types'

interface IPropsType<Data> extends ISortedFieldReturnType<Data> {
  headerStyle?: string
  columns: IColumnsType<Data>[]
}

interface IThPropsType extends Pick<IColumnsType<{}>, typeof TH_PROPS[number]> {
  sortable: boolean
}

const TH_PROPS = ['width', 'align', 'cellStyle'] as const

const Tr = styled.tr<Pick<IPropsType<{}>, 'headerStyle'>>`
  vertical-align: bottom;
  border-bottom: 1px solid black;
  ${({ headerStyle }) => headerStyle}
`

const Th = styled.th<IThPropsType>`
  padding: 8px 10px;
  ${({ width }) => (!width ? '' : `min-width: ${width};`)}
  font-family: Lexend-SemiBold;
  text-align: ${({ align }) => align || 'left'};
  ${({ sortable }) =>
    !sortable
      ? ''
      : `
    color: ${COLORS.Plum};
    cursor: pointer;
    user-select: none;
  `}
  ${({ cellStyle }) => cellStyle}
`

const ArrowImg = styled.img`
  width: 10px;
  ${({ alt }) =>
    alt === 'ascend' ? 'margin: 0px 0px 2px 5px;' : 'margin: 0px 0px -1px 5px;'}
`

const Head = <Data,>({
  headerStyle,
  columns,
  sortedField,
  setSortedField,
}: IPropsType<Data>) => {
  const head = useMergedHead<Data>(columns)

  return (
    <>
      {head.map((columns, index) => (
        <Tr key={index} headerStyle={headerStyle}>
          {columns.map(
            ({ key, title, children, sortable: sorter, ...props }) => {
              const sortable = Boolean(!children && sorter)

              return (
                <Th
                  {..._.pick(props, TH_PROPS)}
                  key={key}
                  rowSpan={children ? undefined : head.length - index}
                  colSpan={children?.length}
                  sortable={sortable}
                  onClick={() => {
                    if (sortable) {
                      if (key === sortedField.key)
                        setSortedField({
                          ...sortedField,
                          direction:
                            sortedField.direction === 'ascend'
                              ? 'descend'
                              : 'ascend',
                        })
                      else
                        setSortedField({
                          key: key as keyof Data,
                          direction: 'descend',
                        })
                    }
                  }}
                >
                  {title || key}

                  {!sortable || key !== sortedField.key ? null : (
                    <ArrowImg
                      src={
                        sortedField.direction === 'ascend' ? UpArrow : DownArrow
                      }
                      alt={sortedField.direction}
                    />
                  )}
                </Th>
              )
            },
          )}
        </Tr>
      ))}
    </>
  )
}

export default React.memo(Head) as unknown as typeof Head
