import { useApolloClient } from '@apollo/client'
import React, { useCallback, useContext, useRef } from 'react'

interface IPropsType {
  children: React.ReactNode
}

const ResetContext = React.createContext<() => void>(() => {
  throw new Error('not found provider')
})

export const useReset = () => {
  const reset = useContext(ResetContext)

  return reset
}

// TODO: should update cache manually
const ResetProvider = ({ children }: IPropsType) => {
  const client = useApolloClient()
  const timeoutRef = useRef<number>()
  const reset = useCallback(() => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(client.resetStore, 500)
  }, [client])

  return <ResetContext.Provider value={reset}>{children}</ResetContext.Provider>
}

export default ResetProvider
