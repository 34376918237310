import { gql } from '@apollo/client'

export const LIST_LOCATION_GROUP_TEAM_KPIS = (brand: string) => gql`
  query ListLocationGroupTeamKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $isTeamV2: Boolean!
  ) {
    listLocationGroupTeamKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $isTeamV2) {
      nodes {
        locationGroupId
        locationGroupName
        doEmployeeId
        doEmployeeInfo {
          id
          preferredName
          familyName
        }
        startDate
        endDate
        managerPar
        latestHourlyCount
        latestSalariedCount
        hourlyTurnoverCount
        salariedTurnoverCount
        hourlyTurnoverPercent
        salariedTurnoverPercent
        annualizedHourlyTurnoverPercent
        annualizedSalariedTurnoverPercent
      }
    }

    listLocationGroupTeamKpisV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $isTeamV2) {
      nodes {
        locationGroupId
        locationGroupName
        doEmployeeId
        doEmployeeInfo {
          id
          preferredName
          familyName
        }
        startDate
        endDate
        managerPar
        latestHourlyCount
        latestSalariedCount
        hourlyTurnoverCount
        salariedTurnoverCount
        hourlyTurnoverPercent
        salariedTurnoverPercent
        annualizedHourlyTurnoverPercent
        annualizedSalariedTurnoverPercent
      }
    }
  }
`

export const LIST_LOCATION_TEAM_KPIS = gql`
  query ListLocationTeamKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $isTeamV2: Boolean!
  ) {
    listLocationTeamKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $isTeamV2) {
      nodes {
        locationId
        locationInfo {
          id
          code
          name
        }
        startDate
        endDate
        managerPar
        latestHourlyCount
        latestSalariedCount
        hourlyTurnoverCount
        salariedTurnoverCount
        hourlyTurnoverPercent
        salariedTurnoverPercent
        annualizedHourlyTurnoverPercent
        annualizedSalariedTurnoverPercent
      }
    }

    listLocationTeamKpisV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $isTeamV2) {
      nodes {
        locationId
        locationInfo {
          id
          code
          name
        }
        startDate
        endDate
        managerPar
        latestHourlyCount
        latestSalariedCount
        hourlyTurnoverCount
        salariedTurnoverCount
        hourlyTurnoverPercent
        salariedTurnoverPercent
        annualizedHourlyTurnoverPercent
        annualizedSalariedTurnoverPercent
      }
    }
  }
`

export const LIST_LOCATION_UNFILLED_MANAGERIAL_POSITIONS = gql`
  query ListLocationUnfilledManagerialPositions($iFilter: JSON!) {
    listLocationUnfilledManagerialPositions(iFilter: $iFilter) {
      nodes {
        candidateName
        comment
        commentedAt
        locationInfo {
          name
          code
          id
        }
        doEmployeeInfo {
          id
          preferredName
          familyName
        }
        commentedByEmployeeId
        commentedByFirstName
        commentedByLastName
        filledPendingStartDate
        managerRoleCode
        managerRoleName
        positionId
        trainingStoreCode
        trainingStoreId
        trainingStoreName
        vacantSince
      }
    }
  }
`

export const TREND_LOCATION_GROUP_TURNOVERS = gql`
  query TrendLocationGroupTurnovers(
    $iLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $isTeamV2: Boolean!
  ) {
    trendLocationGroupTurnovers(
      iLocationGroupId: $iLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) @skip(if: $isTeamV2) {
      nodes {
        locationGroupId
        startDate
        endDate
        hourlyTurnoverCount
        salariedTurnoverCount
        annualizedHourlyTurnoverPercent
        annualizedSalariedTurnoverPercent
      }
    }

    trendLocationGroupTurnoversV2(
      iLocationGroupId: $iLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) @include(if: $isTeamV2) {
      nodes {
        locationGroupId
        startDate
        endDate
        hourlyTurnoverCount
        annualizedHourlyTurnoverPercent
        annualizedSalariedTurnoverPercent
      }
    }
  }
`

export const TREND_LOCATION_GROUP_TURNOVERS_THIRTY_DAY = gql`
  query TrendLocationGroupTurnoversThirtyDay(
    $iLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iDays: Int!
  ) {
    trendLocationGroupTurnoversThirtyDayV2(
      iLocationGroupId: $iLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iDays: $iDays
    ) {
      nodes {
        locationGroupId
        startDate
        endDate
        hireCountHourly
        terminationCountHourly
        turnoverPercentHourly
        hireCountSalaried
        terminationCountSalaried
        turnoverPercentSalaried
      }
    }
  }
`

export const LIST_LOCATION_MANAGER_REVIEW = gql`
  query ListLocationManagerReview($iPerformance: String!, $iFilter: JSON!) {
    listLocationManagerReview(iPerformance: $iPerformance, iFilter: $iFilter) {
      nodes {
        reviewedAt
        performance
        reviewId
        store {
          id
          code
          name
        }
        doInfo {
          id
          firstName: preferredName
          lastName: familyName
        }
        reviewee {
          id
          firstName: preferredName
          lastName: familyName
          role {
            code
            name
          }
        }
      }
    }
  }
`
