import React from 'react'

import Table from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'

import useCollapseArrowClicked from './hooks/useCollapseArrowClicked'
import useColumns from './hooks/useColumns'
import usePlConfig from './hooks/usePlConfig'

interface IPropsType
  extends Pick<
    ReturnType<typeof useCollapseArrowClicked>,
    'displayData' | 'clickedHeaders'
  > {
  columns: ReturnType<typeof useColumns>
}

const ExpandedPLTable = ({
  columns,
  displayData,
  clickedHeaders,
}: IPropsType) => {
  const plConfig = usePlConfig()

  return (
    <Table
      columns={columns}
      dataSource={displayData || []}
      rowStyle={(value) => {
        const type = value?.type

        return `
          td {
            font-family: ${type ? 'Lexend-SemiBold' : ''};
            background-color: ${
              type === 'summary'
                ? COLORS.Sky
                : type === 'subHeader'
                ? COLORS.Smoke
                : ''
            };
            ${
              type === 'header' &&
              value?.name !== plConfig?.displaySequence?.[0]?.name
                ? 'padding-top: 40px; '
                : ''
            }
            ${type === 'summary' ? 'padding: 20px; ' : ''}
            ${type === 'spacer' ? 'height: 30px; padding: 0; ' : ''}
          }

          td:nth-last-child(-n+4) {
            background-color: ${
              (type === 'subHeader' &&
                !clickedHeaders.has(value?.categorySection)) ||
              type === 'summary' ||
              type === 'spacer' ||
              value?.trendData
                ? ''
                : COLORS.Lavender
            };
          }
        `
      }}
      pagination={{ pageSize: (displayData?.length || 0) + 100 }}
    />
  )
}

export default React.memo(ExpandedPLTable)
