import { IConfigsType } from '../../types'

const primantiConfig: IConfigsType = {
  primanti: {
    '/:brand/systemwide_overview': {
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: ['week', 'period', 'quarter', 'year', 'last_week', 'this_week'],
      },
      systemwideOverview: {
        type: 'list',
        api: 'systemwideOverviewKpis',
        title: 'Systemwide Overview',
        width: '150px',
        fields: [
          {
            key: 'netSales',
            title: 'Total Sales',
          },
          {
            key: 'netSalesSssPercent',
            title: 'SSS %',
            decimal: 1,
          },
          {
            key: 'checkCount',
            title: 'Check Count',
          },
          {
            key: 'checkCountVsLyPercent',
            title: 'Check Count % vs LY',
            decimal: 1,
          },
          {
            key: 'checkAverage',
            title: 'Check Average',
            decimal: 2,
          },
          {
            key: 'hourlyLaborCostPercent',
            title: 'Hourly Labor Percent',
            decimal: 1,
          },
        ],
      },
      breakdownByStore: {
        type: 'table',
        api: 'systemwideOverview',
        title: 'Breakdown by Store',
        pageSize: 20,
        defaultSortedField: 'netSales',
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '200px',
          },
        },
        columns: [
          {
            key: 'Net Sales',
            title: 'Net Sales',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'netSales',
                title: '$',
                align: 'center',
                sortable: true,
              },
              {
                key: 'netSalesSssPercent',
                title: 'YOY%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
            ],
          },
          {
            key: 'Hourly Labor',
            title: 'Hourly Labor',
            align: 'center',
            children: [
              {
                key: 'hourlyLaborCostPercent',
                title: '%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
            ],
          },
        ],
      },
      map: {
        type: 'map',
        api: 'listGeoInfo',
      },
    },
  },
}

export default primantiConfig
