import { useMemo } from 'react'

import { IGroupInfoType } from '../../table/hooks/useGroupInfoColumns'
import useTimeClock, {
  fwWingstopTimeClockConfigs,
} from '../../tableV2/hooks/fwWingstop/useTimeClock'

const useFwWingstopTimeClock = () => {
  const fwWingstopTimeClock = useTimeClock()

  return useMemo(
    (): {
      data: { source: { groupInfo?: IGroupInfoType }[]; summary?: {} } | null
      loading: boolean
    } => ({
      data: fwWingstopTimeClock?.data
        ? {
            source: fwWingstopTimeClock?.data.filter(
              (d) => d.parentId != 'root',
            ),
          }
        : null,
      loading: fwWingstopTimeClock.loading,
    }),
    [fwWingstopTimeClock],
  )
}

export { fwWingstopTimeClockConfigs }

export default useFwWingstopTimeClock
