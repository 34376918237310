import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import React from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import Spin from 'pared/components/basicUi/spin'
import Table from 'pared/components/basicUi/table'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import { EDDIE_MERLOTS_BRAND_CODE } from 'pared/constants/brands'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import {
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_WEEK,
  TYPE_YEAR,
} from 'pared/data/getDateRanges'
import useGetDirectors from 'pared/hooks/useGetDirectors'
import DirectorFilterSelector from 'pared/pages/StoreList/DirectorFilterSelector'
import { getBrand } from 'pared/utils/brand'

import useColumns from './hooks/useColumns'
import useConfig from './hooks/useConfig'
import useDataSource from './hooks/useDataSource'
import useItems from './hooks/useItems'

interface IPropsType {
  navParams: INavParams
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 36px;
    font-weight: 700;
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      font-size: 18px;
      padding: 0px 20px;
    }
  }
`

const SubHeader = styled.div`
  margin: 83px 0px 20px;
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    display: block;
    width: 100%;
    margin: 15px 0px 0px;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;

    @media ${MOBILE_WIDTH} {
      font-size: 16px;
      font-style: normal;
      color: rgb(74, 74, 74);
      padding: 0px 20px;
    }
  }

  h3 {
    margin: 0px;
    display: inline-block;
    font-family: Lexend-SemiBold;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 25px;

    @media ${MOBILE_WIDTH} {
      margin: 10px 0px;
      display: block;
      gap: 15x;
    }
  }
`

const Selectors = styled.div`
  display: flex;
  gap: 10px;

  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const MobileCotainer = styled.div`
  @media ${MOBILE_WIDTH} {
    margin: 20px 10px 0px;
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const SalesComparisonFormControl = styled(FormControl)`
  background-color: white;
  width: 140px;
`

const SalesComparisonSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;

  @media ${MOBILE_WIDTH} {
    position: initial;
    height: auto;
    padding: 0px 25px;
  }
`

const SalesExaminer = ({ navParams }: IPropsType) => {
  const config = useConfig()
  const brand = getBrand()
  const { director, directors, directorChange, locationGroupIds } =
    useGetDirectors()

  const {
    selectedRevenueCenter,
    selectedDayPart,
    revenueCenterItems,
    dayPartItems,
    revenueCenterChanged,
    dayPartChanged,
  } = useItems(locationGroupIds)

  const isBreakdownByDirectors = director?.employeeId === -2

  const columns = useColumns(isBreakdownByDirectors)
  const { loading, dataSource } = useDataSource(
    isBreakdownByDirectors,
    directors,
    locationGroupIds,
    selectedRevenueCenter,
    selectedDayPart,
  )

  return (
    <>
      <Header>
        <div>
          <h1>Sales Examiner</h1>
          {brand === EDDIE_MERLOTS_BRAND_CODE ? (
            <SubtitleContainer>
              This page uses CrunchTime data, so some locations may not be
              included yet
            </SubtitleContainer>
          ) : null}
        </div>

        <Selectors>
          <DirectorFilterSelector
            selectedDirector={director}
            allDirectors={directors}
            onDirectorChange={directorChange}
          />

          <h3>When:</h3>

          <DateRangeSelector
            navParams={navParams}
            dateRangeOptions={config.dateRangeOptions}
            preSelectedDateRange={config.preSelectedDateRange}
          />
        </Selectors>
      </Header>

      <SubHeader>
        <h1>Sales Comparison</h1>

        <Selectors>
          <h3>Revenue Center:</h3>
          <SalesComparisonFormControl variant="outlined" margin="dense">
            <SalesComparisonSelect
              value={selectedRevenueCenter}
              onChange={(e) => {
                revenueCenterChanged(e.target.value as string)
              }}
              displayEmpty
            >
              {revenueCenterItems?.map((item: string) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                )
              })}
            </SalesComparisonSelect>
          </SalesComparisonFormControl>

          <h3>Day Part:</h3>
          <SalesComparisonFormControl variant="outlined" margin="dense">
            <SalesComparisonSelect
              value={selectedDayPart}
              onChange={(e) => {
                dayPartChanged(e.target.value as string)
              }}
              displayEmpty
            >
              {dayPartItems?.map((item: string) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                )
              })}
            </SalesComparisonSelect>
          </SalesComparisonFormControl>
        </Selectors>
      </SubHeader>
      <MobileCotainer>
        <Spin spinning={loading}>
          <Table
            dataSource={dataSource}
            columns={columns}
            rowKey={(values) =>
              'storeId' in values ? values.storeId : values.directorId
            }
            useSummary
          />
        </Spin>
      </MobileCotainer>
    </>
  )
}

export default React.memo(SalesExaminer)
