import { useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import MixedChart from 'pared/charts/MixedChart'
import DateRangeNumberSelector from 'pared/components/DateRangeNumberSelector'
import useDateRange, {
  IItem,
  LAST_3_PERIODS,
  LAST_4_WEEKS,
  LAST_13_PERIODS,
} from 'pared/components/DateRangeNumberSelector/hooks/useDateRange'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { DATE_FORMAT, IDateRange } from 'pared/data/getDateRanges'
import { getDateRangeLabel } from 'pared/utils/date'
import { toUsdString } from 'pared/utils/number'

import {
  ITrendLocationPerformanceType,
  ITrendLocationPerformanceVariablesType,
  TREND_LOCATION_PERFORMANCE,
} from './gql'

interface IProps {
  navParams: INavParams
  selectedDateRange: IDateRange | null
}

interface IOrganizedData {
  dateRanges: string[]
  foodAndPaperPercent: number[]
  laborPercent: number[]
  ebitdaAfterBonusPercent: number[]
  sales: number[]
}

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const Container = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 30px;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    width: 100%;
  }
`

const MobileContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const SectionHeader = styled.div`
  font-family: Lexend-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
`

const PerformanceTrendChart = ({ navParams, selectedDateRange }: IProps) => {
  const storeId = navParams.storeId
  const locationId = parseInt(storeId || '0', 10) || 0
  const [dateRange, setDateRange] = useState<IItem['value']>(LAST_4_WEEKS.value)
  const { dateRangeNumber, dateRangeGroupBy } = useDateRange(dateRange)
  const periodEndDate = _.get(selectedDateRange, 'endDateStr', '')
  const newEndDate =
    moment(periodEndDate, DATE_FORMAT) > moment()
      ? moment().format(DATE_FORMAT)
      : periodEndDate
  const { data, previousData, loading } = useQuery<
    ITrendLocationPerformanceType,
    ITrendLocationPerformanceVariablesType
  >(TREND_LOCATION_PERFORMANCE, {
    variables: {
      iLocationId: locationId,
      iEndDate: newEndDate,
      iGroupBy: dateRangeGroupBy,
      iDateRangeNumber: dateRangeNumber,
    },
  })

  const trendLocationKpiData =
    (data || previousData)?.trendLocationPerformance?.nodes || []

  const organizedData = useMemo(() => {
    return (
      trendLocationKpiData?.reduce(
        (result, row) => {
          return {
            dateRanges: [
              ...result.dateRanges,
              getDateRangeLabel(row.startDate, dateRangeGroupBy),
            ],
            foodAndPaperPercent: [
              ...result.foodAndPaperPercent,
              row.foodAndPaperPercent,
            ],
            laborPercent: [...result.laborPercent, row.laborPercent],
            ebitdaAfterBonusPercent: [
              ...result.ebitdaAfterBonusPercent,
              row.ebitdaAfterBonusPercent,
            ],
            sales: [...result.sales, row.sales / 100.0],
          }
        },
        {
          dateRanges: [],
          foodAndPaperPercent: [],
          laborPercent: [],
          ebitdaAfterBonusPercent: [],
          sales: [],
        } as IOrganizedData,
      ) || {
        dateRanges: [],
        foodAndPaperPercent: [],
        laborPercent: [],
        ebitdaAfterBonusPercent: [],
      }
    )
  }, [loading, trendLocationKpiData])

  if (loading) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  if (!data) {
    return (
      <PageStatusDiv>
        There is no data within the date range selected. Please select a
        different date range.
      </PageStatusDiv>
    )
  }

  const yAxisDataArr = [
    {
      type: 'line',
      data: organizedData?.foodAndPaperPercent,
      tooltipLabel: 'Food & Paper %',
      borderColor: COLORS.Basil,
      backgroundColor: COLORS.Basil,
    },
    {
      type: 'line',
      data: organizedData?.laborPercent,
      tooltipLabel: 'Labor %',
      borderColor: COLORS.Plum,
      backgroundColor: COLORS.Plum,
    },
    {
      type: 'line',
      data: organizedData?.ebitdaAfterBonusPercent,
      tooltipLabel: 'EBIDTA After Bonus %',
      borderColor: COLORS.Mango,
      backgroundColor: COLORS.Mango,
    },
    {
      type: 'bar',
      yAxisId: 'yRight',
      data: organizedData?.sales,
      tooltipLabel: 'Sales $',
      backgroundColor: COLORS.Smoke,
    },
  ]

  const chartOptions = {
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: '%',
    yLeftTickCallback: (value: number) => {
      return `${value}%`
    },
    yRightShowGridLines: false,
    yRightAxisLabel: '$ of Sales',
    yRightTickCallback: (value: number) => {
      return `${toUsdString(value)}`
    },
  }

  return (
    <MobileContainer>
      <Container>
        <SectionHeader>Performance Trends</SectionHeader>
        <DateRangeNumberSelector
          value={dateRange}
          onChange={setDateRange}
          dateRangeOptions={[LAST_4_WEEKS, LAST_3_PERIODS, LAST_13_PERIODS]}
        />
      </Container>
      <MixedChart
        xAxisData={organizedData.dateRanges}
        yAxisDataArr={yAxisDataArr}
        options={chartOptions}
      />
    </MobileContainer>
  )
}

export default PerformanceTrendChart
