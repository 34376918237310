import React from 'react'

import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import WebLargeMain from './WebLargeMain'
import { IEmployeeData } from './index'

interface IProps {
  shouldShowSensitiveData: boolean
  isLoading: boolean
  employeeData: IEmployeeData | null | undefined
  error: any
}

function Main({
  shouldShowSensitiveData,
  isLoading,
  employeeData,
  error,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          shouldShowSensitiveData={shouldShowSensitiveData}
          isLoading={isLoading}
          employeeData={employeeData}
          error={error}
        />
      </LargeScreen>
      <SmallScreen>
        The employee profile page is not supported on a mobile screen, it is
        viewable from a Desktop browser.
      </SmallScreen>
    </>
  )
}

export default Main
