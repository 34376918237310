import FormControl from '@material-ui/core/FormControl'
import MaterialSelect from '@material-ui/core/Select'
import * as d3 from 'd3-hierarchy'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { useVariables } from '../variables'
import MenuItem, { IValueType } from './MenuItem'
import useValidateItems, {
  IValidateItemsOptionsType,
} from './hooks/useValidateItems'

export interface IPropsType {
  type: 'select'
  value: string
  onChange: string
  options: string | IValueType[]
  label?: string
  renderValue?: `<%- ${string} %>`
  multiple?: true
}

const StyledFormControl = styled(FormControl)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 25px;
`

const Label = styled.div`
  display: inline-block;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const StyledSelect = styled(MaterialSelect)`
  font-family: Lexend-Regular;
  min-width: 150px;
`

const Select = ({
  label,
  multiple,
  renderValue = '<%- values[0].map(v => v.displayName).join(" / ") %>',
  ...props
}: IPropsType) => {
  const [open, setOpen] = useState(false)
  const { variables } = useVariables()
  const value = useMemo(
    (): IValidateItemsOptionsType['value'] => _.get(variables, props.value),
    [props, variables],
  )
  const { onChange, options } = useMemo(
    () => ({
      onChange: _.get(
        variables,
        props.onChange,
      ) as IValidateItemsOptionsType['onChange'],
      options: d3.stratify<IValueType>()([
        { id: 'root' } as IValueType,
        ...(typeof props.options === 'string'
          ? _.get(variables, props.options)
          : props.options),
      ]),
    }),
    [props, variables],
  )
  const { value: menuValue, onChange: menuOnChange } = useValidateItems({
    value,
    onChange,
    options,
    multiple,
  })

  return (
    <StyledFormControl variant="outlined" margin="dense">
      {!label ? null : <Label>{label}</Label>}

      <StyledSelect
        value={value.join('/')}
        renderValue={() =>
          _.template(renderValue)({
            values: menuValue,
          }).replace(/&amp;/g, '&')
        }
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        variant="outlined"
      >
        {options.children?.map((option) => (
          <MenuItem
            key={option.data.id}
            onChange={menuOnChange}
            onClose={() => setOpen(false)}
            option={option}
            selectedIds={value.filter((v) => v[0] === option.data.id)}
            multiple={multiple || false}
          />
        ))}
      </StyledSelect>
    </StyledFormControl>
  )
}

export default React.memo(Select) as unknown as typeof Select
