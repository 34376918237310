import { FormControl } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import React from 'react'
import styled from 'styled-components'

import { IItem } from './hooks/useDateRange'

interface IProps {
  value: string
  onChange: (value: IItem['value']) => void
  dateRangeOptions: IItem[]
}

const DateRangeSelect = styled(Select)`
  border-radius: 0;
`

const DateRangeNumberSelector = ({
  value,
  onChange,
  dateRangeOptions,
}: IProps) => {
  return (
    <StyledFormControl variant="outlined" size="small">
      <DateRangeSelect
        value={value}
        onChange={(e) => {
          onChange(e.target.value as IItem['value'])
        }}
      >
        {dateRangeOptions?.map((item) => {
          return (
            <MenuItem key={item?.title} value={item?.value}>
              {item?.title}
            </MenuItem>
          )
        })}
      </DateRangeSelect>
    </StyledFormControl>
  )
}

export default React.memo(DateRangeNumberSelector)

const StyledFormControl = styled(FormControl)`
  width: 180px;
`
