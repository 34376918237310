import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { DATE_DATABASE_FORMAT } from 'pared/constants'
import { toUsdString } from 'pared/utils/number'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

interface IMetricInfoType {
  unit: string
  value: number
}

interface IMetricDataType {
  [key: string]: IMetricInfoType
}

interface IListFarwestWeekTrendDataNodeType {
  locationGroupId: number
  startDate: string
  metricData: IMetricDataType
}

const query = gql`
  query ListFarwestWeekTrendData(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "business_week"
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        startDate
        metricData
        metricSummaryData
      }
    }
  }
`

export const farwestCogsTrackerCaseCountsConfigs = {
  farwestCogsTrackerCaseCountsCaseCountItem: 'string',
  farwestCogsTrackerCaseCountsWeek5InPast: 'string',
  farwestCogsTrackerCaseCountsWeek4InPast: 'string',
  farwestCogsTrackerCaseCountsWeek3InPast: 'string',
  farwestCogsTrackerCaseCountsWeek2InPast: 'string',
  farwestCogsTrackerCaseCountsWeek1InPast: 'string',
} as const

interface IKpiInfoType {
  key: string
  itemName: string
  type: 'count' | 'dollar'
  decimal?: number
}

const formatData = (
  metricData: IMetricInfoType,
  kpiInfo: Pick<IKpiInfoType, 'type' | 'decimal'>,
) => {
  if (!metricData) return '-'

  switch (kpiInfo.type) {
    case 'count':
      return Math.abs(metricData.value).toLocaleString('en-US', {
        minimumFractionDigits: kpiInfo.decimal || 0,
        maximumFractionDigits: kpiInfo.decimal || 0,
      })

    case 'dollar':
      return toUsdString(metricData.value / 100.0, kpiInfo.decimal)
  }
}

const KPIS_INFO: IKpiInfoType[] = [
  {
    key: 'bone_in_wings_case_cost',
    itemName: 'Bone-in wings - case cost',
    type: 'dollar',
    decimal: 2,
  },
  {
    key: 'bone_in_wings_cost_per_lb',
    itemName: 'Bone-in wings - Cost/lb',
    type: 'dollar',
    decimal: 2,
  },
  {
    key: 'bone_in_wings_count_per_case',
    itemName: 'Bone-in wings - Count per Case',
    type: 'count',
  },
  {
    key: 'bone_in_wings_cost_per_wing',
    itemName: 'Bone-in wings - Cost Per Wing',
    type: 'dollar',
    decimal: 2,
  },
  {
    key: 'boneless_wings_case_cost',
    itemName: 'Boneless wings - case cost',
    type: 'dollar',
    decimal: 2,
  },
  {
    key: 'boneless_wings_cost_per_lb',
    itemName: 'Boneless wings - Cost/lb',
    type: 'dollar',
    decimal: 2,
  },
  {
    key: 'boneless_wings_count_per_case',
    itemName: 'Boneless wings - Count per Case',
    type: 'count',
  },
  {
    key: 'boneless_wings_cost_per_wing',
    itemName: 'Boneless wings - Cost Per Wing',
    type: 'dollar',
    decimal: 2,
  },
  {
    key: 'chicken_tenders_case_cost',
    itemName: 'Chicken Tenders - case cost',
    type: 'dollar',
    decimal: 2,
  },
  {
    key: 'potatoes_case_cost',
    itemName: 'Potatoes - case cost',
    type: 'dollar',
    decimal: 2,
  },
  {
    key: 'frozen_fries_case_cost',
    itemName: 'Frozen Fries - case cost',
    type: 'dollar',
    decimal: 2,
  },
  {
    key: 'shortening_case_cost',
    itemName: 'Shortening - case cost',
    type: 'dollar',
    decimal: 2,
  },
]

const useFarwestCogsTrackerCaseCounts = () => {
  const { endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()

  const momentEndDate = moment.utc(endDate, DATE_DATABASE_FORMAT, true)
  const startDate = momentEndDate
    .clone()
    .subtract(34, 'days')
    .format(DATE_DATABASE_FORMAT)

  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: groupFilter?.ids,
        intersected_location_group_ids: groupFilter?.intersectedIds,
        metrics: KPIS_INFO.map((k) => k.key),
      },
      hasGroupBy,
    },
    skip: !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const customizedData: IListFarwestWeekTrendDataNodeType[] =
        data?.trendLocationGroupMetricValues?.nodes

      if (!customizedData) return null

      return {
        source: KPIS_INFO.map((kpi) => ({
          farwestCogsTrackerCaseCountsCaseCountItem: kpi.itemName,
          farwestCogsTrackerCaseCountsWeek5InPast: formatData(
            customizedData[0]?.metricData[kpi.key],
            kpi,
          ),
          farwestCogsTrackerCaseCountsWeek4InPast: formatData(
            customizedData[1]?.metricData[kpi.key],
            kpi,
          ),
          farwestCogsTrackerCaseCountsWeek3InPast: formatData(
            customizedData[2]?.metricData[kpi.key],
            kpi,
          ),
          farwestCogsTrackerCaseCountsWeek2InPast: formatData(
            customizedData[3]?.metricData[kpi.key],
            kpi,
          ),
          farwestCogsTrackerCaseCountsWeek1InPast: formatData(
            customizedData[4]?.metricData[kpi.key],
            kpi,
          ),
        })),
      }
    }, [groupFilter, data]),
    loading,
  }
}

export default useFarwestCogsTrackerCaseCounts
