import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { feature, page } from 'pared/analytics/user'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBusinessLabel } from 'pared/customer'
import getDateRanges, { IDateRange } from 'pared/data/getDateRanges'
import useDefaultLocation from 'pared/hooks/useDefaultLocation'
import { getBrand } from 'pared/utils/brand'
import { scrollToTop } from 'pared/utils/web'

import Main from './Main'
import {
  CHECK_IF_LOCATION_NSO,
  GET_LOCATION_EXRAY,
  GET_LOCATION_SCORECARD,
} from './gql'
import useConfig from './hooks/useConfig'

interface IProps {
  navParams: INavParams
}

export interface IScore {
  name: string
  value: number
  average: number
  rank: number
  percentileRank: number
  score: number
  totalScore: number
}

export interface IExrayCategory {
  category: string
  summary: string
  warningLevel: number
}

export interface IExray {
  [key: string]: IExrayCategory
}

const Scorecard = ({ navParams }: IProps) => {
  useDefaultLocation()
  const config = useConfig()
  const storeId = navParams.storeId || ''
  const storeIdNum = parseInt(storeId, 10) || 0
  const dateRange = navParams.dateRange || ''
  const brand = getBrand()
  let errorMessage = ''

  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange | null>(
    null,
  )

  const locationInfo = useLocationInfo(storeIdNum)
  const locationId = locationInfo?.id

  const [
    getScorecard,
    {
      loading: scorecardLoading,
      error: scorecardError,
      data: scorecardData,
      variables: scorecardVariables,
    },
  ] = useLazyQuery(GET_LOCATION_SCORECARD)

  const [
    getExray,
    { loading: exrayLoading, error: exrayError, data: exrayData },
  ] = useLazyQuery(GET_LOCATION_EXRAY)

  const [checkIfLocationNso, { data: locationNsoData }] = useLazyQuery(
    CHECK_IF_LOCATION_NSO,
  )

  useEffect(() => {
    async function fetchData() {
      const newAllDateRangeData = await getDateRanges()
      let newSelectedDateRange: IDateRange | null = null
      if (dateRange) {
        newSelectedDateRange = newAllDateRangeData.dateRangeMap[dateRange]
      }

      if (!newSelectedDateRange) {
        newSelectedDateRange = newAllDateRangeData.defaultPeriod
      }

      if (newSelectedDateRange) {
        setSelectedDateRange(newSelectedDateRange)
      }

      const startDate = _.get(newSelectedDateRange, 'startDateStr', '')
      const endDate = _.get(newSelectedDateRange, 'endDateStr', '')
      let rankingFromLocationGroupIds = BRAND_LOCATION_GROUP_ID[brand]
      const comparisonLocationGroup = locationInfo?.locationGroups?.find(
        ({ type }) => type === getBusinessLabel('accessGroupType'),
      )
      if (comparisonLocationGroup) {
        rankingFromLocationGroupIds = comparisonLocationGroup.id
      }

      if (locationId) {
        config.isExrayVisible &&
          getExray({
            variables: {
              iLocationId: locationId,
            },
          })

        getScorecard({
          variables: {
            iStartDate: startDate,
            iEndDate: endDate,
            iFilter: {
              location_ids: [locationId],
              ranking_from: {
                location_group_ids: [rankingFromLocationGroupIds],
              },
            },
          },
        })

        checkIfLocationNso({
          variables: {
            iLocationId: locationId,
          },
        })
      }
    }

    fetchData()
  }, [locationId, dateRange])

  useEffect(() => {
    if (storeIdNum && navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl, { locationId: storeIdNum })
      feature.used('Scorecard', { locationId: storeIdNum })
    }
  }, [storeIdNum, navParams.pageUrl])

  if (!storeIdNum) {
    errorMessage = 'Please select a store first to view this page ...'
  }

  let parsedScorecardData = []
  let gqlQuery = 'getLocationScorecard'

  if (
    scorecardData &&
    scorecardData[gqlQuery] &&
    Array.isArray(scorecardData[gqlQuery].nodes)
  ) {
    parsedScorecardData = _.get(scorecardData, `${gqlQuery}.nodes`, []).map(
      (data: IScore) => ({
        ...data,
        score:
          data.score || data.score === 0
            ? Math.round(data.score * 100) / 100
            : data.score,
        value:
          data.value || data.value === 0
            ? Math.round(data.value * 100) / 100
            : data.value,
        average:
          data.average || data.average === 0
            ? Math.round(data.average * 100) / 100
            : data.average,
        totalScore:
          data.totalScore || data.totalScore === 0
            ? Math.round(data.totalScore * 100) / 100
            : data.totalScore,
      }),
    )
  }

  let parsedExrayData = []
  gqlQuery = 'getLocationExray'
  if (
    exrayData &&
    exrayData[gqlQuery] &&
    Array.isArray(exrayData[gqlQuery].nodes)
  ) {
    parsedExrayData = _.get(exrayData, `${gqlQuery}.nodes`, []).reduce(
      (prev: IExray, cur: IExrayCategory) => ({
        ...prev,
        [cur.category]: cur,
      }),
      {},
    )
  }

  let isLocationNso = false
  if (
    locationNsoData &&
    locationNsoData['checkIfLocationNso'] &&
    Array.isArray(locationNsoData['checkIfLocationNso'].nodes)
  ) {
    isLocationNso = _.get(locationNsoData, 'checkIfLocationNso.nodes[0]', false)
  }

  return (
    <Main
      navParams={navParams}
      locationData={locationInfo}
      errorMessage={errorMessage}
      scorecardData={parsedScorecardData}
      exrayData={parsedExrayData}
      shouldShowScorecard={!isLocationNso}
      selectedDateRange={selectedDateRange}
      scorecardLoading={
        scorecardLoading ||
        scorecardVariables?.iFilter?.location_ids?.[0] !== locationId
      }
      exrayLoading={exrayLoading}
    />
  )
}

export default Scorecard
