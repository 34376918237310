import React from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import DailyDashboardTable from './DailyDashboardTable'

const Header = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0px 0px;

  @media ${MOBILE_WIDTH} {
    font-size: 18px;
    padding: 30px 25px 0px;
  }
`

const VerticalSpacer60px = styled.div`
  width: 100%;
  height: 60px;

  @media ${MOBILE_WIDTH} {
    height: 20px;
  }
`

export default React.memo(() => {
  return (
    <>
      <Header>Daily Dashboard</Header>
      <VerticalSpacer60px />
      <DailyDashboardTable />
    </>
  )
})
