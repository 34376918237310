import React, { useCallback, useContext, useMemo } from 'react'

import COLORS from 'pared/constants/colors'

export interface IPropsType<Data> {
  children: React.ReactElement
  actionName?: string
  value: any
  values: Data
}

type IActionContextType<Data> = Record<
  string,
  (value: any, values: Data) => void
>

const ActionContext = React.createContext<IActionContextType<unknown>>({})

export const ActionProvider = ActionContext.Provider

const Action = ({
  children,
  actionName,
  value,
  values,
}: IPropsType<unknown>) => {
  const action = useContext(ActionContext)
  const onClick = useCallback(() => {
    if (!actionName || !action[actionName]) return

    action[actionName](value, values)
  }, [action, actionName, value, values])
  const isClickable = useMemo(
    () => Boolean(actionName && action[actionName]),
    [action, actionName],
  )

  return React.cloneElement(children, {
    onClick,
    style: !isClickable
      ? null
      : {
          cursor: 'pointer',
          color: COLORS.Link,
        },
  })
}

export default Action
