import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { feature, page } from 'pared/analytics/user'
import useLocationInfo, {
  ILocationInfo,
} from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBusinessLabel } from 'pared/customer'
import getDateRanges, { GROUP_BY_PERIOD } from 'pared/data/getDateRanges'
import getLocations, { ILocation } from 'pared/data/getLocations'
import useDefaultLocation from 'pared/hooks/useDefaultLocation'
import { getBrand, getBrandDefaultCalendarStartDate } from 'pared/utils/brand'
import { getYearMonthDiff } from 'pared/utils/date'
import { toPercentString } from 'pared/utils/number'
import { hasPrivilegeToViewEmployeeFeedback } from 'pared/utils/user'
import { scrollToTop } from 'pared/utils/web'

import Main from './Main'
import { GET_LOCATION_TEAM_DATA, TREND_LOCATION_TEAM_KPIS } from './gql'
import useTeamConfig from './hooks/useTeamConfig'

interface IUnfilledManagerialPosition {
  positionId: number
  roleName: string
  timeVacant: string
  candidateName: string
  filledPendingStartDate: string
  trainingStoreName: string
  trainingStoreId: number
  comment: string
  commentedAt: string
  commentedByFirstName: string
  commentedByLastName: string
}

interface ITurnover {
  year: number
  month: number
  locationHourlyTerminatedCountInThisMonth: number
  locationHourlyMonthlyTurnoverRate: number
  locationHourlyAnnualizedTurnoverRate: number
  systemwideHourlyAnnualizedTurnoverRate: number
  systemwideSalariedAnnualizedTurnoverRate: number
}

export interface ILocationTeamData {
  location: ILocationInfo | null

  kpis: {
    month: string
    managerPar: string
    salariedTurnoverInCurrentMonth: string
    hourlyTurnoverInCurrentMonth: string
    annualizedSalariedTurnoverRate: string
    annualizedSalariedTurnoverRank: string
    annualizedHourlyTurnoverRate: string
    annualizedHourlyTurnoverRank: string
  }

  turnovers: ITurnover[]

  unfilledManagerialPositions: IUnfilledManagerialPosition[]
}

interface IProps {
  navParams: INavParams
}

function Team({ navParams }: IProps) {
  useDefaultLocation()
  const brandDefaultCalendarStartDate = getBrandDefaultCalendarStartDate()
  const teamConfig = useTeamConfig()
  const storeId = navParams.storeId || ''
  const storeIdNum = parseInt(storeId, 10) || 0
  const locationInfo = useLocationInfo(storeIdNum)
  const locationId = locationInfo?.id
  const year = moment().year()
  let errorMessage = ''
  const brand = getBrand()

  let comparisonLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  const comparisonGroupType = getBusinessLabel('accessGroupType')
  const comparisonLocationGroup = locationInfo?.locationGroups?.find(
    ({ type }) => type === comparisonGroupType,
  )
  if (comparisonLocationGroup) {
    comparisonLocationGroupId = comparisonLocationGroup.id
  }

  const [
    getLocationTeamData,
    {
      error: getLocationTeamDataError,
      loading: getLocationTeamDataLoading,
      data,
      variables: getLocationTeamDataVariables,
      refetch: refetchLocationUnfilledPositions,
    },
  ] = useLazyQuery(GET_LOCATION_TEAM_DATA)

  const [
    trendLocationTeamKpis,
    {
      error: trendLocationTeamKpisError,
      loading: trendLocationTeamKpisLoading,
      data: trendLocationTeamKpisData,
      variables: trendLocationTeamKpisVariables,
    },
  ] = useLazyQuery(TREND_LOCATION_TEAM_KPIS)

  const [isCommentPopoverOpen, setIsCommentPopoverOpen] =
    useState<boolean>(false)
  const [allLocations, setAllLocations] = useState<ILocation[] | null>([])

  useEffect(() => {
    async function fetchData() {
      const [allDateRangeData, locationData] = await Promise.all([
        getDateRanges(),
        getLocations(),
      ])

      const locations = locationData.locations

      if (Array.isArray(locations) && locations.length > 0) {
        setAllLocations(locations)
      }

      const latestPeriod = allDateRangeData.defaultPeriod

      if (locationId && latestPeriod) {
        const samePeriodLastYear =
          allDateRangeData.periodsByYear[latestPeriod.year - 1]?.[
            12 - latestPeriod.index
          ]

        const startDate = samePeriodLastYear
          ? samePeriodLastYear.startDateStr
          : latestPeriod.startDateStr
        const defaultStartDate = brandDefaultCalendarStartDate || '2019-01-01'
        const trendStartDate = moment
          .utc(startDate)
          .isBefore(moment.utc(defaultStartDate))
          ? defaultStartDate
          : startDate

        await Promise.all([
          getLocationTeamData({
            variables: {
              iLocationId: locationId,
              iStartDate: latestPeriod.startDateStr,
              iEndDate: latestPeriod.endDateStr,
              iFilter: {
                location_ids: [locationId],
                ranking_from: {
                  location_group_ids: [comparisonLocationGroupId],
                },
              },
              isTeamV2: teamConfig.isTeamV2 || false,
            },
          }),
          trendLocationTeamKpis({
            variables: {
              iLocationId: locationId,
              iLocationGroupId: comparisonLocationGroupId,
              iStartDate: trendStartDate,
              iEndDate: latestPeriod.endDateStr,
              iGroupBy: GROUP_BY_PERIOD,
              isTeamV2: teamConfig.isTeamV2 || false,
            },
          }),
        ])
      }
    }

    fetchData()
  }, [locationId, getLocationTeamData, year])

  useEffect(() => {
    if (storeIdNum && navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl, { locationId: storeIdNum })
      feature.used('Team', { locationId: storeIdNum })
    }
  }, [storeIdNum, navParams.pageUrl])

  const onRefreshTable = async () => {
    if (storeIdNum && refetchLocationUnfilledPositions) {
      await refetchLocationUnfilledPositions({
        variables: {
          locationId: storeIdNum,
        },
      })
    }
  }

  if (!storeIdNum) {
    errorMessage = 'Please select a store first to view this page ...'
  }

  const isLoading =
    getLocationTeamDataLoading ||
    getLocationTeamDataVariables?.iLocationId !== locationId
  const defaultValue = isLoading ? 'loading' : '-'

  let locationTeamData: ILocationTeamData = {
    location: locationInfo,
    kpis: {
      month: defaultValue,
      managerPar: defaultValue,
      salariedTurnoverInCurrentMonth: defaultValue,
      hourlyTurnoverInCurrentMonth: defaultValue,
      annualizedSalariedTurnoverRate: defaultValue,
      annualizedSalariedTurnoverRank: defaultValue,
      annualizedHourlyTurnoverRate: defaultValue,
      annualizedHourlyTurnoverRank: defaultValue,
    },
    turnovers: [],
    unfilledManagerialPositions: [],
  }

  if (data) {
    const now = moment()

    const listLocationTeamKpis = teamConfig.isTeamV2
      ? 'listLocationTeamKpisV2'
      : 'listLocationTeamKpis'
    if (
      data[listLocationTeamKpis] &&
      Array.isArray(data[listLocationTeamKpis].nodes) &&
      data[listLocationTeamKpis].nodes.length === 1
    ) {
      const rawData = data[listLocationTeamKpis].nodes[0]
      if (rawData && rawData.locationId === storeIdNum) {
        const periodMiddleDay = moment.utc(rawData.startDate).add(15, 'days')

        locationTeamData.kpis.month = `P${periodMiddleDay.month() + 1}`
        if (rawData.managerPar) {
          locationTeamData.kpis.managerPar = `${
            rawData.latestSalariedCount || 0
          } of ${rawData.managerPar}`
        }

        locationTeamData.kpis.salariedTurnoverInCurrentMonth = `${
          rawData.salariedTurnoverCount || 0
        }`
        if (teamConfig.isActiveSalariedCountVisible !== false) {
          locationTeamData.kpis.salariedTurnoverInCurrentMonth += ` of ${
            rawData.latestSalariedCount || 0
          }`
        }

        locationTeamData.kpis.hourlyTurnoverInCurrentMonth = `${
          rawData.hourlyTurnoverCount || 0
        } of ${rawData.latestHourlyCount || 0}`
        locationTeamData.kpis.annualizedSalariedTurnoverRate = toPercentString(
          rawData.annualizedSalariedTurnoverPercent,
          1,
        )
        locationTeamData.kpis.annualizedHourlyTurnoverRate = toPercentString(
          rawData.annualizedHourlyTurnoverPercent,
          1,
        )

        locationTeamData.kpis.annualizedSalariedTurnoverRank =
          rawData.annualizedSalariedTurnoverRank
            ? `${rawData.annualizedSalariedTurnoverRank} of ${rawData.totalLocationCount}`
            : defaultValue

        locationTeamData.kpis.annualizedHourlyTurnoverRank =
          rawData.annualizedHourlyTurnoverRank
            ? `${rawData.annualizedHourlyTurnoverRank} of ${rawData.totalLocationCount}`
            : defaultValue
      }
    }

    const unfilledManagerialPositions: IUnfilledManagerialPosition[] = []
    if (
      data.listLocationUnfilledManagerialPositions &&
      Array.isArray(data.listLocationUnfilledManagerialPositions.nodes)
    ) {
      data.listLocationUnfilledManagerialPositions.nodes.forEach(
        (rawData: any) => {
          if (rawData) {
            let timeVacant = '-'
            if (rawData.vacantSince) {
              const vacantSince = moment(
                rawData.vacantSince,
                'YYYY-MM-DD',
                true,
              )
              if (vacantSince && vacantSince.isValid()) {
                timeVacant = getYearMonthDiff(vacantSince, now)
              }
            } else {
              timeVacant = 'No data found'
            }
            unfilledManagerialPositions.push({
              timeVacant,
              positionId: rawData.positionId,
              roleName: rawData.managerRoleName,
              candidateName: rawData.candidateName,
              filledPendingStartDate: rawData.filledPendingStartDate,
              trainingStoreName: rawData.trainingStoreName,
              trainingStoreId: rawData.trainingStoreId,
              comment: rawData.comment,
              commentedAt: rawData.commentedAt,
              commentedByFirstName: rawData.commentedByFirstName,
              commentedByLastName: rawData.commentedByLastName,
            })
          }
        },
      )
      const sortedUnfilledManagerialPositions = _.orderBy(
        unfilledManagerialPositions,
        ['candidateName', 'timeVacant'],
        ['asc', 'desc'],
      )

      locationTeamData.unfilledManagerialPositions =
        sortedUnfilledManagerialPositions
    }
  }

  if (trendLocationTeamKpisData) {
    const turnoverDataMap: { [periodStartDateStr: string]: ITurnover } = {}

    const trendLocationTurnovers = teamConfig.isTeamV2
      ? 'trendLocationTurnoversV2'
      : 'trendLocationTurnovers'
    if (
      trendLocationTeamKpisData[trendLocationTurnovers] &&
      Array.isArray(trendLocationTeamKpisData[trendLocationTurnovers].nodes)
    ) {
      trendLocationTeamKpisData[trendLocationTurnovers].nodes.forEach(
        (rawData: any) => {
          if (rawData && rawData.locationId === storeIdNum) {
            const periodStartDateStr = rawData.startDate
            const periodMiddleDay = moment
              .utc(rawData.startDate)
              .add(15, 'days')
            const turnoverData = {
              year: periodMiddleDay.year(),
              month: periodMiddleDay.month() + 1,
              locationHourlyTerminatedCountInThisMonth:
                rawData.hourlyTurnoverCount,
              locationHourlyMonthlyTurnoverRate:
                (rawData.hourlyTurnoverPercent || 0) / 100.0,
              locationHourlyAnnualizedTurnoverRate:
                (rawData.annualizedHourlyTurnoverPercent || 0) / 100.0,
            }
            turnoverDataMap[periodStartDateStr] = {
              ...turnoverDataMap[periodStartDateStr],
              ...turnoverData,
            }
          }
        },
      )
    }

    const trendLocationGroupTurnovers = teamConfig.isTeamV2
      ? 'trendLocationGroupTurnoversV2'
      : 'trendLocationGroupTurnovers'
    if (
      trendLocationTeamKpisData[trendLocationGroupTurnovers] &&
      Array.isArray(
        trendLocationTeamKpisData[trendLocationGroupTurnovers].nodes,
      )
    ) {
      trendLocationTeamKpisData[trendLocationGroupTurnovers].nodes.forEach(
        (rawData: any) => {
          if (
            rawData &&
            rawData.locationGroupId === comparisonLocationGroupId
          ) {
            const periodStartDateStr = rawData.startDate
            const periodMiddleDay = moment
              .utc(rawData.startDate)
              .add(15, 'days')
            const turnoverData = {
              year: periodMiddleDay.year(),
              month: periodMiddleDay.month() + 1,
              systemwideHourlyAnnualizedTurnoverRate:
                (rawData.annualizedHourlyTurnoverPercent || 0) / 100.0,
              systemwideSalariedAnnualizedTurnoverRate:
                (rawData.annualizedSalariedTurnoverPercent || 0) / 100.0,
            }
            turnoverDataMap[periodStartDateStr] = {
              ...turnoverDataMap[periodStartDateStr],
              ...turnoverData,
            }
          }
        },
      )
    }

    locationTeamData.turnovers = Object.values(turnoverDataMap).sort(
      (first: ITurnover, second: ITurnover) => {
        if (first.year === second.year) {
          return first.month - second.month
        } else {
          return first.year - second.year
        }
      },
    )
  }

  if (getLocationTeamDataError || trendLocationTeamKpisError) {
    errorMessage =
      'Unexpected Error. Please try again or contact support@getexpo.com.'
    if (getLocationTeamDataError && getLocationTeamDataError.message) {
      errorMessage += ` (${getLocationTeamDataError.message})`
    }
    if (trendLocationTeamKpisError && trendLocationTeamKpisError.message) {
      errorMessage += ` (${trendLocationTeamKpisError.message})`
    }
  }

  return (
    <Main
      navParams={navParams}
      shouldShowFeedback={
        hasPrivilegeToViewEmployeeFeedback() && !teamConfig.shouldHideFeedback
      }
      locationTeamData={locationTeamData}
      errorMessage={errorMessage}
      isCommentPopoverOpen={isCommentPopoverOpen}
      setIsCommentPopoverOpen={setIsCommentPopoverOpen}
      allLocations={allLocations}
      onRefresh={onRefreshTable}
      isTurnoverChartLoading={
        trendLocationTeamKpisLoading ||
        trendLocationTeamKpisVariables?.iLocationId !== locationId
      }
    />
  )
}

export default Team
