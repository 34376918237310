import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { IOptionsType } from '../types'

const queries = gql`
  query locationMetricDefs($metricGroup: String!) {
    locationMetricDefs(condition: { metricGroup: $metricGroup }) {
      nodes {
        name
        code
      }
    }
  }
`

const useFarwestSssChart = () => {
  const { data, loading } = useQuery(queries, {
    variables: {
      metricGroup: 'Expo Module - Revenue Center Sales',
    },
  })

  return {
    data: useMemo(() => {
      const options: IOptionsType = [
        {
          type: 'group',
          label: 'SSS%',
        },
        {
          value: 'net_sales',
          label: 'SSS% - Total',
        },
      ]

      // Hide for now because the logic of comparable values is  incorrect
      /*
      data?.locationMetricDefs.nodes.forEach(
        (d: { name: string; code: string }) => {
          options.push({
            value: d.code,
            label: `SSS% - ${d.name.replace('Revenue Center Sales - ', '')}`,
          })
        },
      )
     */

      options.push({
        type: 'group',
        label: 'SST%',
      })

      options.push({
        value: 'check_count',
        label: 'SST% - Total',
      })

      options.push({
        type: 'group',
        label: 'SSC%',
      })

      options.push({
        value: 'check_average',
        label: 'SSC% - Total',
      })

      return options
    }, [data]),
    loading,
  }
}

export default useFarwestSssChart
