import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import Chart from 'pared/pages/CorporateDelivery/BreakdownTable'

import { FROM_EXPO } from '../../constants'
import { IMessage } from '../../type'

const VerticalSpacer = styled.div`
  width: 100%;
  height: 50px;
`

export default function handleDeliveryKpiRankings(responseData: any) {
  if (responseData?.deliveryKpiRankings) {
    const messageId = uuidv4()
    const results = responseData.deliveryKpiRankings
    const uiComponents: React.ReactNode[] = []

    if (results.delivery.locations) {
      // Rank stores
      const dlgData = results.delivery.locations
      uiComponents.push(
        <div>
          {uiComponents.length > 0 ? <VerticalSpacer /> : null}
          <div>{dlgData.locationNames.join(', ')}</div>
          <Chart
            isBreakdownByDirectors={false}
            startDate={dlgData?.startDate}
            endDate={dlgData?.endDate}
            locationIds={dlgData?.locationIds}
          />
        </div>,
      )
    } else if (results.delivery.locationGroups) {
      // Rank directors
      const dlgData = results.delivery.locationGroups
      uiComponents.push(
        <div>
          {uiComponents.length > 0 ? <VerticalSpacer /> : null}
          <div>{dlgData.locationGroupNames.join(', ')}</div>
          <Chart
            isBreakdownByDirectors={true}
            startDate={dlgData?.startDate}
            endDate={dlgData?.endDate}
            locationGroupId={dlgData?.locationGroupIds[0]}
            locationGroupIds={dlgData?.locationGroupIds}
          />
        </div>,
      )
    }

    if (uiComponents.length > 0) {
      const answerMessage: IMessage = {
        id: messageId,
        from: FROM_EXPO,
        content: <div>{uiComponents}</div>,
      }

      return answerMessage
    }
  }

  return null
}
