import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { INullableType } from 'pared/types'

import {
  IDetailsType,
  ILocationSchedulingByHourByDayType,
  ILocationSchedulingByHourByDayVariablesType,
  LOCATION_SCHEDULING_BY_HOUR_BY_DAY,
} from '../gqls'

export type IDataKeyType = 'savings' | 'unproductiveLabor'

export interface ISchedulingTableDataType {
  hour: number
  [dateStr: string]:
    | {
        color: string
        value: number
      }
    | number
}

interface ISchedulingDataType {
  hour: number
  [dateStr: string]: number
}

const percentageToColor = (percentage: number, maxHue = 120, minHue = 0) => {
  const hue = percentage * (maxHue - minHue) + minHue
  return `hsl(${hue}, 80%, 80%)`
}

export default (
  kpiKey: IDataKeyType,
  locationId: number,
  startDate: string,
  endDate: string,
) => {
  const { data, error, loading } = useQuery<
    ILocationSchedulingByHourByDayType,
    INullableType<ILocationSchedulingByHourByDayVariablesType>
  >(LOCATION_SCHEDULING_BY_HOUR_BY_DAY, {
    variables: {
      iFilter: { location_ids: [locationId] },
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip: !startDate || !endDate || !locationId,
  })

  const schedulingAnalysisData = useMemo((): ISchedulingTableDataType[] => {
    const rawData = data?.locationSchedulingByHourByDay?.nodes

    if (!rawData) return []

    const schedulingData = rawData.reduce(
      (result, row) => [
        ...result,
        {
          hour: row.hour,
          ...row.details?.reduce(
            (r, d: IDetailsType) => ({
              ...r,
              [d.date]: d?.[kpiKey] || 0,
            }),
            {},
          ),
        },
      ],
      [] as ISchedulingDataType[],
    )

    return (
      Object?.keys(schedulingData?.[0] || [])?.reduce((result, key) => {
        if (key === 'hour') {
          return schedulingData?.map((r) => ({ hour: r.hour }))
        } else {
          const sortedData = schedulingData
            .slice()
            ?.filter((d) => d[key] > 0)
            .sort((a, b) => b[key] - a[key])
          const rankMap = new Map()
          sortedData.forEach((value, index) => {
            rankMap.set(value, index + 1)
          })
          const rankedArray = schedulingData.map((value) => rankMap.get(value))

          return result?.map((r, index) => {
            const value = schedulingData[index][key]
            const v = {
              value,
              color: percentageToColor(
                value > 0 && rankedArray?.[index] && rankedArray.length > 0
                  ? rankedArray[index] / rankedArray.length
                  : 1,
              ),
            }

            return { ...r, [key]: v }
          })
        }
      }, [] as ISchedulingTableDataType[]) || []
    )
  }, [data, kpiKey, locationId, startDate, endDate])

  return { loading, schedulingAnalysisData }
}
