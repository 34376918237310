import { gql } from '@apollo/client'

export const TREND_LOCATION_SPEED_OF_SERVICE = gql`
  query TrendLocationSpeedOfService(
    $iLocationId: Int!
    $iLocationGroupId: Int!
    $iEndDate: Date!
    $iGroupBy: String!
  ) {
    trendLocationSpeedOfService(
      iLocationId: $iLocationId
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) {
      nodes {
        startDate
        endDate
        sosDetails
      }
    }

    trendLocationGroupSpeedOfServiceBypassRls(
      iLocationGroupId: $iLocationGroupId
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) {
      nodes {
        startDate
        endDate
        sosDetails
      }
    }
  }
`
