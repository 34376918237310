import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import {
  GROUP_BY_PERIOD,
  GROUP_BY_QUARTER,
  GROUP_BY_WEEK,
  GROUP_BY_YEAR,
  IDateRange,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'
import { toPercentageString, toUsdString } from 'pared/utils/number'

import { TREND_LOCATION_GROUP_OPS_COMPS } from '../gql'
import Main from './Main'

interface IProps {
  navParams: INavParams
  selectedDateRange: IDateRange | null
  selectedDirector: IDirector | null
}

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

function OpsCompsTrendChart({
  navParams,
  selectedDateRange,
  selectedDirector,
}: IProps) {
  const storeId = navParams.storeId
  const brand = getBrand()

  const locationId = parseInt(storeId || '0', 10) || 0

  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)

  const [
    listKpi,
    { loading: listKpiLoading, error: listKpiError, data: listKpiData },
  ] = useLazyQuery(TREND_LOCATION_GROUP_OPS_COMPS)

  useEffect(() => {
    const periodEndDate = _.get(selectedDateRange, 'endDateStr', '')

    let groupBy = ''
    if (selectedDateRange) {
      if (selectedDateRange.type === TYPE_YEAR) {
        groupBy = GROUP_BY_YEAR
      } else if (selectedDateRange.type === TYPE_QUARTER) {
        groupBy = GROUP_BY_QUARTER
      } else if (selectedDateRange.type === TYPE_WEEK) {
        groupBy = GROUP_BY_WEEK
      } else {
        groupBy = GROUP_BY_PERIOD
      }
    }

    const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
    let selectedDirectorLocationGroupId =
      _.get(selectedDirector, 'locationGroupId') || -1
    selectedDirectorLocationGroupId =
      selectedDirectorLocationGroupId < 0
        ? brandLocationGroupId
        : selectedDirectorLocationGroupId

    listKpi({
      variables: {
        iLocationGroupId: selectedDirectorLocationGroupId,
        iEndDate: periodEndDate,
        iGroupBy: groupBy,
      },
    })
  }, [locationId, selectedDateRange, selectedDirector])

  let rawKpiMonthly = []

  if (
    listKpiData &&
    listKpiData.trendLocationGroupOpsComps &&
    Array.isArray(listKpiData.trendLocationGroupOpsComps.nodes)
  ) {
    rawKpiMonthly = _.get(listKpiData, 'trendLocationGroupOpsComps.nodes', [])
  }

  const xAxisData = _.map(
    rawKpiMonthly,
    (k) =>
      ({
        [TYPE_YEAR]: `Y${k.businessYear}`,
        [TYPE_QUARTER]: `Q${k.businessQuarter} ${k.businessYear}`,
        [TYPE_PERIOD]: `P${k.businessMonth} ${k.businessYear}`,
        [TYPE_WEEK]: `P${k.businessMonth} W${k.businessWeekOfMonth} ${k.businessYear}`,
        [TYPE_THIS_WEEK]: `P${k.businessMonth} ${k.businessYear}`,
        [TYPE_LAST_WEEK]: `P${k.businessMonth} ${k.businessYear}`,
        [TYPE_YESTERDAY]: `P${k.businessMonth} ${k.businessYear}`,
      }[selectedDateRange?.type || TYPE_PERIOD]),
  )

  const yAxisOpsCompsSalesData = _.map(rawKpiMonthly, (k) => {
    const opsCompsSales = parseInt(_.get(k, 'opsComps') || 0) / 100.0

    return opsCompsSales
  })

  const yAxisGrossSalesData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'grossSales', 0) / 100
  })

  const yAxisOpsCompsPercentData = _.map(rawKpiMonthly, (k) => {
    const opsCompsPercent = _.get(k, 'opsCompsPercent', 0) / 100

    return opsCompsPercent
  })

  const yAxisDataArr = [
    {
      type: 'line',
      data: yAxisOpsCompsPercentData,
      tooltipLabel: 'Ops Comps Percent',
      borderColor: COLORS.Plum,
      backgroundColor: COLORS.Plum,
    },
    {
      type: 'bar',
      yAxisId: 'yRight',
      data: yAxisOpsCompsSalesData,
      tooltipLabel: 'Total Ops Comps',
    },
  ]

  const chartOptions = {
    title: '',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: 'Total Ops Comps Percent',
    yLeftTickCallback: (value: number) => {
      return toPercentageString(value, 2)
    },
    yRightAxisLabel: 'Total Ops Comps $',
    yRightShowGridLines: false,
    yRightTickCallback: (value: number) => {
      return toUsdString(value)
    },
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let datasetLabel = ''
        let separator = ''
        let yValue = ''
        if (tooltipItemContext.dataset && tooltipItemContext.dataset.label) {
          datasetLabel = tooltipItemContext.dataset.label
          separator = ': '
        }
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          if (tooltipItemContext.datasetIndex === 0) {
            yValue += toPercentageString(tooltipItemContext.parsed.y, 2)
          } else {
            const dataIndex = tooltipItemContext.dataIndex
            const grossSales = yAxisGrossSalesData[dataIndex]
            yValue += [
              toUsdString(tooltipItemContext.parsed.y, 2),
              `Gross Sales: ${toUsdString(grossSales, 2)}`,
            ].join(', ')
          }
        }
        return [datasetLabel, separator, yValue].join('')
      }
      return ''
    },
  }

  return (
    <Main
      isLoading={listKpiLoading}
      xAxisData={xAxisData}
      chartOptions={chartOptions}
      yAxisDataArr={yAxisDataArr}
    />
  )
}

export default OpsCompsTrendChart
