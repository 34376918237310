const formatPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const getPrice = (value: number) =>
  formatPrice.format(value).replace(/\..+/, '')

export const getPriceOrDash = (value: number | string) => {
  if (typeof value === 'number') {
    return getPrice(value)
  } else {
    const valueNum = parseFloat(value)
    if (isNaN(valueNum)) {
      return '-'
    } else {
      return getPrice(valueNum)
    }
  }
}
