import { makeStyles } from '@material-ui/core/styles'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { useMemo } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

import { getPercent, getTotalPrice } from '../utils'

interface IProps {
  dataSource: any[]
  reportFilterValue: string
  onClick: (name: string) => void
  clickedHeaders: Set<string>
}

function cellStyle(value: any) {
  if (typeof value === 'number' && value < 0) {
    return 'color: red;'
  }
}

const StyledSpan = styled.span<{ type: string }>`
  font-family: ${({ type }) => (type ? 'Lexend-SemiBold' : '')};
  font-size: ${({ type }) => (type === 'header' ? '18px' : '')};
  margin: ${({ type }) => {
    if (type === 'header' || type === 'summary') {
      return '0px 0px 0px 5px'
    } else if (type === 'subHeader') {
      return '0px 0px 0px 30px'
    } else {
      return '0px 0px 0px 45px'
    }
  }};
`

const FlexDiv = styled.div`
  display: flex;
`

const useStyles = makeStyles({
  rotateIcon: {
    transform: 'rotate(90deg)',
  },
})

const useColumns = ({
  dataSource,
  reportFilterValue,
  onClick,
  clickedHeaders,
}: IProps) => {
  const classes = useStyles()
  return useMemo(() => {
    return [
      {
        key: 'name',
        title: ' ',
        minWidth: '300px',
        render: (
          value: any,
          { type, name }: { type: string; name: string },
        ) => (
          <FlexDiv>
            {type === 'header' ? (
              <PlayArrowIcon
                className={clickedHeaders?.has(name) ? '' : classes.rotateIcon}
                key={name}
                onClick={() => onClick(name)}
              />
            ) : null}
            <StyledSpan type={type}>{value}</StyledSpan>
          </FlexDiv>
        ),
      },
      ...(dataSource[0]?.trendData
        ? [
            ...dataSource[0]?.trendData?.map((value: any, index: number) => {
              return {
                key: `trendData.${index}`,
                title: `${value?.intervalType} ${value?.intervalIndex}`,
                minWidth: '80px',
                align: 'center',
                children: ['dollar', 'percent'].map((key) => ({
                  key: `trendData.${index}.${key}`,
                  title: key === 'percent' ? '%' : '$',
                  align: 'center',
                  width: '80px',
                  render: (value: number) =>
                    key === 'percent'
                      ? getPercent(value)
                      : getTotalPrice(value),
                })),
              }
            }),
          ]
        : [
            {
              key: 'currentAmount',
              title: `${reportFilterValue?.charAt(0)}TD`,
              align: 'center',
              children: [
                {
                  cellStyle,
                  key: 'amount',
                  title: '$',
                  align: 'center',
                  width: '80px',
                  render: getTotalPrice,
                },
                {
                  cellStyle,
                  key: 'amountPercent',
                  title: '%',
                  align: 'center',
                  width: '80px',
                  render: getPercent,
                },
              ],
            },
            {
              key: 'lyAmount',
              title: `${reportFilterValue?.charAt(0)}TD LY`,
              align: 'center',
              children: [
                {
                  cellStyle,
                  key: 'amountLy',
                  title: '$',
                  align: 'center',
                  width: '80px',
                  render: getTotalPrice,
                },
                {
                  cellStyle,
                  key: 'amountLyPercent',
                  title: '%',
                  align: 'center',
                  width: '80px',
                  render: getPercent,
                },
              ],
            },
            {
              key: 'YoY Variance',
              align: 'center',
              children: [
                {
                  cellStyle,
                  key: 'yoyVariance',
                  title: '$',
                  align: 'center',
                  width: '80px',
                  render: getTotalPrice,
                },
                {
                  cellStyle,
                  key: 'yoyVariancePercent',
                  title: '%',
                  align: 'center',
                  width: '80px',
                  render: getPercent,
                },
              ],
            },
            {
              key: `${reportFilterValue?.charAt(0)}TD Budget`,
              align: 'center',
              cellStyle: `background-color: ${COLORS.Lavender}`,
              children: [
                {
                  key: 'budget',
                  title: '$',
                  align: 'center',
                  width: '80px',
                  render: getTotalPrice,
                  cellStyle: (value: any) => {
                    return `background-color: ${COLORS.Lavender}; ${cellStyle(
                      value,
                    )}`
                  },
                },
                {
                  key: 'budgetPercent',
                  title: '%',
                  align: 'center',
                  width: '80px',
                  render: getPercent,
                  cellStyle: (value: any) => {
                    return `background-color: ${COLORS.Lavender}; ${cellStyle(
                      value,
                    )}`
                  },
                },
              ],
            },
            {
              key: 'Variance from Budget',
              align: 'center',
              cellStyle: `background-color: ${COLORS.Lavender}`,
              children: [
                {
                  key: 'varianceBudget',
                  title: '$',
                  align: 'center',
                  width: '80px',
                  render: getTotalPrice,
                  cellStyle: (value: any) => {
                    return `background-color: ${COLORS.Lavender}; ${cellStyle(
                      value,
                    )}`
                  },
                },
                {
                  key: 'varianceBudgetPercent',
                  title: '%',
                  align: 'center',
                  width: '80px',
                  render: getPercent,
                  cellStyle: (value: any) => {
                    return `background-color: ${COLORS.Lavender}; ${cellStyle(
                      value,
                    )}`
                  },
                },
              ],
            },
          ]),
    ]
  }, [dataSource, onClick])
}

export default useColumns
