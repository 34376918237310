import DeleteIcon from '@mui/icons-material/Delete'
import { useState } from 'react'
import styled from 'styled-components'

import DeleteTrackerModal, {
  IPropsType as IDeleteTrackerModalType,
} from './deleteTrackerModal'

export interface IPropsType extends Omit<IDeleteTrackerModalType, 'onClose'> {}

const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;
`

const DeleteTrainingTracker = ({ ...props }: IPropsType) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <StyledDeleteIcon onClick={() => setOpen(true)} />
      {open && <DeleteTrackerModal {...props} onClose={() => setOpen(false)} />}
    </>
  )
}

export default DeleteTrainingTracker
