import { useMemo } from 'react'

import { useFetchCustomizedReportData } from 'pared/components/CustomizedReport'

const useCustomizedReportData = (inputParams: any) => {
  const reportName = inputParams.locationGroupTypes
    ? 'LIST_LOCATION_GROUP_PMIX_TABLE_V2'
    : 'LIST_LOCATION_PMIX_TABLE_V2'

  const { reportDefinition, reportResult, isLoading } =
    useFetchCustomizedReportData(reportName, {
      ...inputParams,
      locationIds:
        inputParams.locationIds?.length === 0 ? null : inputParams.locationIds,
    })

  return useMemo(() => {
    return {
      reportDefinition,
      isLoading,
      reportResult,
    }
  }, [inputParams, reportDefinition, reportResult, isLoading])
}

export default useCustomizedReportData
