import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { track } from 'pared/analytics/team'
import EmployeeFeedbackSpan from 'pared/components/managerReview/EmployeeFeedbackSpan'
import ManagerReview from 'pared/components/managerReview/ManagerReview'
import { DEFAULT_EMPLOYEE_STATUS } from 'pared/constants'
import COLORS from 'pared/constants/colors'
import { IPeriod } from 'pared/data/getPeriods'

import useTeamConfig from '../hooks/useTeamConfig'
import EmployeeStatusEditButton from './EmployeeStatusEditButton'
import { ILocation, ITeamMember } from './index'

interface IProps {
  userEmployeeId: number | null
  allPeriods: IPeriod[]
  location: ILocation | null
  teamMembers: ITeamMember[]
  onRefreshTable: () => Promise<void>
  shouldShowFeedback: boolean
}

function Main({
  userEmployeeId,
  allPeriods,
  location,
  teamMembers,
  onRefreshTable,
  shouldShowFeedback,
}: IProps) {
  const teamConfig = useTeamConfig()
  let feedbackTableContent = null

  if (!location) {
    feedbackTableContent = (
      <tr>
        <td colSpan={6}>Loading ...</td>
      </tr>
    )
  } else if (teamMembers.length > 0) {
    feedbackTableContent = teamMembers.map((member) => {
      const managerReviewDetails = member.managerReviews.map((review) => {
        return (
          <ManagerReview
            key={review.id}
            performanceName={review.performanceName}
            feedback={review.feedback}
            reviewer={review.reviewer}
            formattedReviewedAt={review.formattedReviewedAt}
            reviewedAt={review.reviewedAt}
            managerReviewId={review.id}
            allPeriods={allPeriods}
            firstName={member.firstName}
            lastName={member.lastName}
            role={member.roleName}
            storeCode={location.code}
            storeName={location.name}
            onRefresh={onRefreshTable}
          />
        )
      })

      const employeeFeedbackSpan = userEmployeeId ? (
        <EmployeeFeedbackSpan
          firstName={member.firstName}
          lastName={member.lastName}
          role={member.roleName}
          storeCode={location.code}
          storeName={location.name}
          revieweeId={member.employeeId}
          locationId={location.id}
          onRefresh={onRefreshTable}
        />
      ) : null

      const feedbackSectionBody = shouldShowFeedback ? (
        <>
          <td>
            <div>
              {member.status || DEFAULT_EMPLOYEE_STATUS.name}
              {userEmployeeId ? (
                <StatusEditSpan>
                  <EmployeeStatusEditButton
                    employeeId={member.employeeId}
                    employeeName={`${member.firstName} ${member.lastName}`}
                    locationId={member.locationId}
                    roleId={member.roleId}
                    editedByEmployeeId={userEmployeeId}
                    currentEmployeeStatus={member.status}
                    onRefresh={onRefreshTable}
                  />
                </StatusEditSpan>
              ) : null}
            </div>
            {member.statusRecordedBy ? (
              <StatusEditedByDiv>
                <div>
                  Edited by{' '}
                  <Link to={navigator.employeeProfile(member.statusRecordedBy)}>
                    {member.statusRecordedByName}
                  </Link>
                </div>
                <div>on {member.statusRecordedAt}</div>
              </StatusEditedByDiv>
            ) : null}
          </td>
          <RecentFeedbackTd>
            {managerReviewDetails.length > 0 ? managerReviewDetails : <>-</>}
          </RecentFeedbackTd>
          <BaseTd>{employeeFeedbackSpan}</BaseTd>
        </>
      ) : null

      const trackEventManagerProfileLinkClicked = () => {
        track.managerProfileLinkClicked(member.employeeId)
      }

      return (
        <tr key={member.employeeId}>
          <td>{member.roleName}</td>
          <td>
            {teamConfig.hasLinkToViewEmployeeProfile ? (
              <Link
                to={navigator.employeeProfile(member.employeeId)}
                onClick={trackEventManagerProfileLinkClicked}
              >
                {member.firstName} {member.lastName}
              </Link>
            ) : (
              `${member.firstName} ${member.lastName}`
            )}
          </td>
          <td>{member.employmentType}</td>
          <td>{member.tenure}</td>
          {teamConfig.shouldHideTimeInPosition ? null : (
            <td>{member.timeInPosition}</td>
          )}
          {feedbackSectionBody}
        </tr>
      )
    })
  } else {
    feedbackTableContent = (
      <tr>
        <td colSpan={6}>No team member found.</td>
      </tr>
    )
  }

  const feedbackSectionHeader = shouldShowFeedback ? (
    <>
      <th>Status</th>
      <th>Feedback</th>
    </>
  ) : null

  return (
    <FeedbackTable>
      <thead>
        <tr>
          <th>Role</th>
          <th>Name</th>
          <th>Employment Type</th>
          <th>Tenure</th>
          {teamConfig.shouldHideTimeInPosition ? null : (
            <th>Time in Position</th>
          )}
          {feedbackSectionHeader}
        </tr>
      </thead>
      <tbody>{feedbackTableContent}</tbody>
    </FeedbackTable>
  )
}

const FeedbackTable = styled.table`
  width: 100%;
  min-width: 950px;
  border-collapse: collapse;
  text-align: left;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  th {
    font-family: Lexend-SemiBold;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    padding: 10px 0;
  }

  td {
    font-family: Lexend-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 15px 0;
    vertical-align: top;
  }
`

const StatusEditSpan = styled.span`
  font-family: Lexend-Regular;
  color: #07a4c7;
  text-decoration: underline;
  margin: 0 0 0 15px;
`

const StatusEditedByDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  padding: 20px 0 15px 0;
`

const BaseTd = styled.td`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const RecentFeedbackTd = styled(BaseTd)`
  max-width: 280px;
`

export default Main
