import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import { toUsdStr } from 'pared/utils/number'

import { IApiDataType } from '../../types'

interface ILossPreventionSummaryNodeType {
  locationInfo: {
    code: string
    name: string
  }
  amount: number
  employeeDiscountInfos: {
    familyName: string
    preferredName: string
  }[]
  rankPercent: number
  annualOpportunityCost: number
}

interface ILossPreventionSummaryType {
  locationLossPreventionSummary: {
    nodes: ILossPreventionSummaryNodeType[]
  }
}

const query = gql`
  query LocationLossPreventionSummary(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationId: Int!
  ) {
    locationLossPreventionSummary(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iLocationId: $iLocationId
    ) {
      nodes {
        locationInfo {
          code
          name
        }
        employeeDiscountInfos
        annualOpportunityCost
      }
    }
  }
`

const useLossPrevention = (): IApiDataType => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const { data, loading } = useQuery<ILossPreventionSummaryType>(query, {
    variables: {
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip: !groupFilter,
  })

  return {
    data: useMemo(() => {
      if (!data) return null

      const lossPreventionData = data.locationLossPreventionSummary.nodes[0]
      const annualOpportunityCost =
        lossPreventionData.annualOpportunityCost > 0
          ? lossPreventionData.annualOpportunityCost / 100
          : 0
      const employee = lossPreventionData.employeeDiscountInfos?.filter(
        (e) => e.preferredName !== 'Unknown Name',
      )[0]
      const employeeName = employee
        ? `${employee.preferredName} ${employee.familyName}`
        : null

      return {
        title: 'Loss Prevention',
        total: annualOpportunityCost,
        detail: !employeeName
          ? 'No employees are discounting above the Company Average for this time period.'
          : `${employeeName} is discounting more than company averages.  Consider retraining or addressing issue with your team member. ${
              annualOpportunityCost
                ? `Annual opportunity cost for all employees: ${toUsdStr(
                    annualOpportunityCost,
                  )}`
                : ''
            }`,
      }
    }, [data]),
    loading,
  }
}

export default useLossPrevention
