import { useMemo, useState } from 'react'

export interface IDataType {
  itemByDaypartColumnFilter?: {
    options: any
    value: any
  }
}

const useItemByDaypartColumnFilter = (): IDataType => {
  const filterHook = useState([['percent']])

  return {
    itemByDaypartColumnFilter: useMemo(() => {
      const options = [
        {
          id: 'percent',
          parentId: 'root',
          displayName: 'Percent',
        },
        {
          id: 'quantity',
          parentId: 'root',
          displayName: 'Quantity',
        },
      ]

      return {
        value: filterHook[0],
        options: options,
        onChange: filterHook[1],
      }
    }, [filterHook]),
  }
}

export default useItemByDaypartColumnFilter
