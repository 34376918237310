import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { FROM_EXPO } from 'pared/pages/ExpoAi/constants'
import { IMessage } from 'pared/pages/ExpoAi/type'
import EmployeeCashHandlingTable from 'pared/pages/LossPrevention/EmployeeCashHandlingTable'
import EmployeeDiscountTable from 'pared/pages/LossPrevention/EmployeeDiscountTable'
import EmployeeVoidsTable from 'pared/pages/LossPrevention/EmployeeVoidsTable'

import formatDate from '../formatDate'

const VerticalSpacer = styled.div`
  width: 100%;
  height: 50px;
`

export default function handleLossPreventionStoreEmployeeKpis(
  responseData: any,
) {
  if (responseData?.lossPreventionStoreEmployeeKpis) {
    const messageId = uuidv4()
    const result = responseData.lossPreventionStoreEmployeeKpis

    let uiComponent: React.ReactNode = null

    if (result.kpiType === 'discount') {
      uiComponent = (
        <div>
          <div>
            {result.locationName} ({formatDate(result.startDate)} ~{' '}
            {formatDate(result.endDate)})
          </div>
          <EmployeeDiscountTable
            locationId={result.locationId}
            startDate={result.startDate}
            endDate={result.endDate}
          />
        </div>
      )
    } else if (result.kpiType === 'cash') {
      uiComponent = (
        <div>
          <div>
            {result.locationName} ({formatDate(result.startDate)} ~{' '}
            {formatDate(result.endDate)})
          </div>
          <EmployeeDiscountTable
            locationId={result.locationId}
            startDate={result.startDate}
            endDate={result.endDate}
          />
          <VerticalSpacer />
          <EmployeeCashHandlingTable
            locationId={result.locationId}
            startDate={result.startDate}
            endDate={result.endDate}
          />
        </div>
      )
    } else if (result.kpiType === 'voids') {
      uiComponent = (
        <div>
          <div>
            {result.locationName} ({formatDate(result.startDate)} ~{' '}
            {formatDate(result.endDate)})
          </div>
          <EmployeeVoidsTable
            locationId={result.locationId}
            startDate={result.startDate}
            endDate={result.endDate}
          />
        </div>
      )
    }

    if (uiComponent) {
      const answerMessage: IMessage = {
        id: messageId,
        from: FROM_EXPO,
        content: uiComponent,
      }

      return answerMessage
    }
  }

  return null
}
