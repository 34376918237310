import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import { useRef } from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import CustomizedReport from 'pared/components/CustomizedReport'
import {
  PERFORMANCE_OPTIONS,
  UNFILLED_MANAGERIAL_POSITION_OPTIONS,
} from 'pared/constants'
import COLORS from 'pared/constants/colors'
import { getBusinessLabel } from 'pared/customer'
import { IDateRange } from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'
import DirectorFilterSelector from 'pared/pages/StoreList/DirectorFilterSelector'
import { hasPrivilegeToViewEmployeeFeedback } from 'pared/utils/user'

import BreakdownTable from './BreakdownTable'
import Chart from './Chart'
import EmployeesTermChart from './EmployeesTermChart'
import ManagerPerformanceTable from './ManagerPerformanceTable'
import UnfilledManagerialPositionTable from './UnfilledManagerialPositionTable'
import AvgHourlyRatesChart from './avgHourlyRatesChart'
import useTeamConfig from './hooks/useTeamConfig'
import { IDetailTeamKpi, IUnfilledManagerialPosition } from './index'
import { IGlobalCurrentTeamKpis } from './index'

interface IProps {
  allDirectors: IDirector[]
  selectedDirector: IDirector
  navParams: INavParams
  onDirectorChange: (directorEmployeeId: number) => void
  selectedDateRange: IDateRange | null
  globalCurrentTeamKpis: IGlobalCurrentTeamKpis
  itemizedKpis: IDetailTeamKpi[]
  unfilledManagerialPositions: IUnfilledManagerialPosition[]
  unfilledManagerialPositionOption: string
  onSelectUnfilledManagerialPositionOption: (event: any) => void
  performanceOption: string
  onSelectPerformanceOption: (event: any) => void
  managerReviews: IManagerReview[]
  locationGroupIds: number[] | null
  locationGroupTypes: string[] | null
  selectedLocationGroupId: number
}

interface IKpiDiv {
  book?: boolean
}

function WebSmallMain({
  navParams,
  allDirectors,
  selectedDirector,
  onDirectorChange,
  selectedDateRange,
  globalCurrentTeamKpis,
  itemizedKpis,
  unfilledManagerialPositions,
  unfilledManagerialPositionOption,
  onSelectUnfilledManagerialPositionOption,
  performanceOption,
  onSelectPerformanceOption,
  managerReviews,
  locationGroupIds,
  locationGroupTypes,
  selectedLocationGroupId,
}: IProps) {
  const teamConfig = useTeamConfig()
  const managerReviewRef = useRef(null)

  const isTeamV2 = teamConfig.isTeamV2 || false
  const shouldShowSensitiveData = hasPrivilegeToViewEmployeeFeedback()
  const managerPerformancetable = shouldShowSensitiveData ? (
    <>
      <VerticalSpacer30px />
      <Title>Manager Performance</Title>
      <FilterText>Filter by</FilterText>
      <TableFormControl
        variant="outlined"
        margin="dense"
        style={{ width: '90vw' }}
      >
        <TableSelect
          value={performanceOption}
          onChange={onSelectPerformanceOption}
        >
          <MenuItem value={PERFORMANCE_OPTIONS.PROMOTE_READY}>
            Promote Ready
          </MenuItem>
          <MenuItem value={PERFORMANCE_OPTIONS.UPGRADE}>Upgrade</MenuItem>
          <MenuItem value={PERFORMANCE_OPTIONS.DEVELOPMENT_NEEDED}>
            Development Needed
          </MenuItem>
          <MenuItem value={PERFORMANCE_OPTIONS.SOLID_PERFORMER}>
            Solid Performer
          </MenuItem>
          <MenuItem value={PERFORMANCE_OPTIONS.HIPO}>HiPo</MenuItem>
          <MenuItem value={PERFORMANCE_OPTIONS.NO_FEEDBACK_IN_THE_PAST_90_DAYS}>
            No Feedback in the Past 90 Days
          </MenuItem>
        </TableSelect>
      </TableFormControl>
      <VerticalSpacer30px />
      <ScrollableTableContainer>
        <ManagerPerformanceTable
          managerReviews={managerReviews}
          managerReviewRef={managerReviewRef}
        />
      </ScrollableTableContainer>
      <VerticalSpacer30px />
    </>
  ) : null

  const managerParText = _.get(globalCurrentTeamKpis, 'managerParText', '')
  const salariedTurnoverText = _.get(
    globalCurrentTeamKpis,
    'salariedTurnoverText',
    '',
  )
  const hourlyTurnoverText = _.get(
    globalCurrentTeamKpis,
    'hourlyTurnoverText',
    '',
  )
  const annualizedHourlyTurnoverRate = _.get(
    globalCurrentTeamKpis,
    'annualizedHourlyTurnoverRate',
    '',
  )
  const annualizedSalariedTurnoverRate = _.get(
    globalCurrentTeamKpis,
    'annualizedSalariedTurnoverRate',
    '',
  )

  const breakdownType = locationGroupTypes?.[0] || null
  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)
  let breakdownTitle = `BREAKDOWN BY ${getBusinessLabel('store').toUpperCase()}`
  if (selectedDirctorEmployeeId === -2) {
    // breakdownByDirectors
    breakdownTitle = `BREAKDOWN BY ${getBusinessLabel(
      'director',
    ).toUpperCase()}`
  } else if (breakdownType) {
    breakdownTitle = `BREAKDOWN BY ${breakdownType}`.toUpperCase()
  }

  return (
    <>
      <SelectorContainer>
        <DirectorFilterSelector
          allDirectors={allDirectors}
          selectedDirector={selectedDirector}
          onDirectorChange={onDirectorChange}
          width={'87vw'}
        />
      </SelectorContainer>
      <MainContainer>
        <PageTitleDiv>Systemwide Team</PageTitleDiv>

        <VerticalSpacer30px />

        <FullScreenTable>
          <thead>
            <tr>
              <th>KPIs</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!teamConfig.managerPar.isVisible ? null : (
              <tr>
                <td>Manager Par</td>
                <BoldNumTd>{managerParText}</BoldNumTd>
              </tr>
            )}
            <tr>
              <td>{`Salaried Turnover in ${globalCurrentTeamKpis.month}`}</td>
              <BoldNumTd>{salariedTurnoverText}</BoldNumTd>
            </tr>
            <tr>
              <td>{`Hourly Turnover in ${globalCurrentTeamKpis.month}`}</td>
              <BoldNumTd>{hourlyTurnoverText}</BoldNumTd>
            </tr>
            <tr>
              <td>Annualized Hourly Turnover</td>
              <BoldNumTd>{annualizedHourlyTurnoverRate}</BoldNumTd>
            </tr>
            <tr>
              <td>Annualized Salaried Turnover</td>
              <BoldNumTd>{annualizedSalariedTurnoverRate}</BoldNumTd>
            </tr>
          </tbody>
        </FullScreenTable>

        <VerticalSpacer40px />

        <SectionTitleDiv>TURNOVER CHART</SectionTitleDiv>

        <ScrollableTableContainer>
          <ScrollableTable>
            <Chart
              navParams={navParams}
              selectedDateRange={selectedDateRange}
              selectedDirector={selectedDirector}
            />
          </ScrollableTable>
        </ScrollableTableContainer>

        <VerticalSpacer60px />

        {!teamConfig['avgHourlyRate']?.isVisible ? null : (
          <>
            <SectionTitleDiv>AVERAGE HOURLY RATES</SectionTitleDiv>

            <ScrollableTableContainer>
              <ScrollableTable>
                <AvgHourlyRatesChart
                  locationGroupId={selectedDirector?.locationGroupId || -1}
                />
              </ScrollableTable>
            </ScrollableTableContainer>

            <VerticalSpacer60px />
          </>
        )}

        {!teamConfig.roleAvgPayrateTable?.isVisible ? null : (
          <>
            <SectionTitleDiv>AVERAGE PAYRATE {breakdownTitle}</SectionTitleDiv>
            <CustomizedReport
              reportName={
                locationGroupTypes
                  ? 'LIST_LOCATION_GROUP_AVG_PAYRATE_TABLE'
                  : 'LIST_LOCATION_AVG_PAYRATE_TABLE'
              }
              inputParams={{
                locationGroupId: selectedLocationGroupId,
                locationGroupIds: locationGroupIds,
                locationGroupTypes: [breakdownType || ''],
              }}
            />
            <VerticalSpacer60px />
          </>
        )}

        <SectionTitleDiv>TURNOVER {breakdownTitle}</SectionTitleDiv>

        <ScrollableTableContainer>
          <ScrollableTable>
            <BreakdownTable
              itemizedKpis={itemizedKpis}
              selectedDirector={selectedDirector}
              breakdownType={breakdownType}
            />
          </ScrollableTable>
        </ScrollableTableContainer>

        <VerticalSpacer60px />
        {teamConfig.newHireTurnover?.isVisible && !isTeamV2 ? (
          <EmployeesTermChart locationGroupId={selectedLocationGroupId} />
        ) : null}

        {teamConfig.unfilledManagerialPositions.isVisible ? (
          <>
            <VerticalSpacer30px />
            <Title>Unfilled Managerial Position Summary</Title>
            <FilterText>Filter by</FilterText>
            <TableFormControl
              variant="outlined"
              margin="dense"
              style={{ width: '90vw' }}
            >
              <TableSelect
                value={unfilledManagerialPositionOption}
                onChange={onSelectUnfilledManagerialPositionOption}
              >
                <MenuItem
                  value={UNFILLED_MANAGERIAL_POSITION_OPTIONS.NO_CANDIDATES}
                >
                  No Candidates
                </MenuItem>
                <MenuItem
                  value={UNFILLED_MANAGERIAL_POSITION_OPTIONS.FILLED_POSITIONS}
                >
                  Pending Start
                </MenuItem>
                <MenuItem
                  value={UNFILLED_MANAGERIAL_POSITION_OPTIONS.ALL_POSITIONS}
                >
                  All Positions
                </MenuItem>
              </TableSelect>
            </TableFormControl>
            <VerticalSpacer30px />

            <ScrollableTableContainer>
              <UnfilledManagerialPositionTable
                selectedDirector={selectedDirector}
                unfilledManagerialPositions={unfilledManagerialPositions}
                unfilledManagerialPositionOption={
                  unfilledManagerialPositionOption
                }
              />
            </ScrollableTableContainer>
          </>
        ) : null}

        {teamConfig.managerPerformance.isVisible
          ? managerPerformancetable
          : null}

        <VerticalSpacer80px />
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  padding: 30px 25px 50px 25px;
`

const ScrollableTableContainer = styled.div`
  overflow-x: scroll;
  border: 1px solid;
  padding-left: 30px;
  width: 90vw;
`

const ScrollableTable = styled.div`
  width: 850px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
`

const PageTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 0;
`

const BoldNumTd = styled.td`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
`

const FullScreenTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};
  text-align: right;

  th {
    font-family: Lexend-SemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  td,
  th {
    vertical-align: middle;
    padding: 8px 0;
  }

  th:first-child {
    text-align: left;
  }

  td:first-child {
    text-align: left;
  }

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  thead::after {
    content: '';
    display: block;
    height: 10px;
    width: 100%;
    background: transparent;
  }
`

const FilterText = styled.div`
  margin-top: 17px;
  margin-right: 15px;
  float: left;
  font-family: 'Lexend-Regular';
  font-size: '14px';
  font-style: normal;
  font-weight: 400;
`

const TableFormControl = styled(FormControl)`
  background-color: white;
`

const TableSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const Title = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0 0;
  width: 90vw;
  float: left;
`

const FilterContainer = styled.div`
  float: right;
`

const ManagerPerformanceFilterContainer = styled(FilterContainer)`
  margin-top: 15px;
`

const VerticalSpacer30px = styled.div`
  width: 100%;
  height: 30px;
`

const VerticalSpacer80px = styled.div`
  height: 80px;
`

const VerticalSpacer40px = styled.div`
  width: 100%;
  height: 40px;
`

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 20px 0;
  display: flex;
`

const VerticalSpacer60px = styled.div`
  width: 100%;
  height: 60px;
`

const VerticalSpacer110px = styled.div`
  width: 100%;
  height: 110px;
`

const VerticalSpacer150px = styled.div`
  width: 100%;
  height: 150px;
`

const TeamKpiTable = styled.table`
  width: 700px;
  border-collapse: collapse;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  th {
    font-family: Lexend-SemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-align: left;
    padding: 5px 8px;
  }

  td {
    text-align: center;
    vertical-align: top;
  }
`

const KpiValueDiv = styled.div<IKpiDiv>`
  width: 100%;
  font-family: ${(props) =>
    props.book ? 'Lexend-Regular' : 'Lexend-SemiBold'};
  font-size: ${(props) => (props.book ? '18px' : '24px')};
  font-style: normal;
  font-weight: ${(props) => (props.book ? '400' : '700')};
  padding: 25px 0 5px 0;
`

const KpiNameDiv = styled.div`
  width: 100%;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const PageHeaderContainer = styled.div`
  padding: 0 0 50px 0;
  margin: 0 0 50px 0;
`

const LeftHeaderContainer = styled.div`
  float: left;
`

const PageHeaderLineContainer = styled.div`
  display: inline-block;
  width: 1200px;
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0 0;
  float: left;
`

const SelectorContainer = styled.div`
  padding: 10px 25px 10px 25px;
  background-color: rgb(196, 196, 196);
`

const DirectorFilterSelectorContainer = styled.div`
  float: left;
  margin-right: 267px;
`

const Subtitle = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};
  margin-bottom: 20px;
`

export default WebSmallMain
