import { INavParams } from 'pared/Routes/navParams'
import { ILocationInfo } from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { IDateRange } from 'pared/data/getDateRanges'

import WebLargeMain from './WebLargeMain'
import { IExray, IScore } from './index'

interface IProps {
  navParams: INavParams
  locationData: ILocationInfo | null
  errorMessage: string
  scorecardData: IScore[]
  exrayData: IExray
  selectedDateRange: IDateRange | null
  scorecardLoading: boolean
  shouldShowScorecard: boolean
  exrayLoading: boolean
}

const Main = ({
  navParams,
  locationData,
  errorMessage,
  scorecardData,
  exrayData,
  selectedDateRange,
  scorecardLoading,
  shouldShowScorecard,
  exrayLoading,
}: IProps) => {
  return (
    <WebLargeMain
      navParams={navParams}
      locationData={locationData}
      errorMessage={errorMessage}
      scorecardData={scorecardData}
      exrayData={exrayData}
      selectedDateRange={selectedDateRange}
      scorecardLoading={scorecardLoading}
      shouldShowScorecard={shouldShowScorecard}
      exrayLoading={exrayLoading}
    />
  )
}

export default Main
