import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

type Metric = {
  name: string
  value: string
  budget: string
}

const query = gql`
  query listLocationMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationIds: [Int!]!
    $iMetricCodes: [String!]!
  ) {
    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: { location_ids: $iLocationIds, metrics: $iMetricCodes }
    ) {
      nodes {
        metricData
      }
    }
  }
`

export const bbbPnLConfigs = {
  name: 'string',
  value: 'price',
  budget: 'price',
  variance: 'price',
  variance_pct: 'percent',
} as const

const pnlMetricsSales: Metric[] = [
  {
    name: 'Total Food Sales',
    value: 'total_food_sales',
    budget: 'total_food_sales_budget',
  },
  {
    name: 'Total Coupons and Discounts',
    value: 'total_coupons_and_discounts',
    budget: 'total_coupons_and_discounts_budget',
  },
  {
    name: 'Employee Discount',
    value: 'employee_discount',
    budget: 'employee_discount_budget',
  },
  { name: 'Coupons', value: 'coupons', budget: 'coupons_budget' },
]

const pnlMetricsFnp: Metric[] = [
  {
    name: 'Total Food and Paper',
    value: 'total_food_and_paper',
    budget: 'total_food_and_paper_budget',
  },
  { name: 'Food Meat', value: 'food_meat', budget: 'food_budget_meat' },
  { name: 'Food Dairy', value: 'food_dairy', budget: 'food_budget_dairy' },
  {
    name: 'Food Produce',
    value: 'food_produce',
    budget: 'food_budget_produce',
  },
  {
    name: 'Food Grocery',
    value: 'food_grocery',
    budget: 'food_budget_grocery',
  },
  {
    name: 'Food Beverages',
    value: 'food_beverages',
    budget: 'food_budget_beverages',
  },
  { name: 'Paper', value: 'paper', budget: 'paper_budget' },
  { name: 'Food Waste', value: 'food_waste', budget: 'food_waste_budget' },
]

const pnlMetricsLabor: Metric[] = [
  { name: 'Total Labor', value: 'total_labor', budget: 'total_labor_budget' },
  {
    name: 'Manager Salary Exempt',
    value: 'manager_salary_exempt',
    budget: 'manager_salary_exempt_budget',
  },
  { name: 'Crew Labor', value: 'crew_labor', budget: 'crew_labor_budget' },
]

const pnlMetricsCtrlExpenses: Metric[] = [
  {
    name: 'Direct Operating',
    value: 'direct_operating',
    budget: 'direct_operating_budget',
  },
  {
    name: 'Store Smallwares',
    value: 'store_smallwares',
    budget: 'store_smallwares_budget',
  },
  {
    name: 'Cleaning Supplies',
    value: 'cleaning_supplies',
    budget: 'cleaning_supplies_budget',
  },
  {
    name: 'Uniform Supplies',
    value: 'uniform_supplies',
    budget: 'uniform_supplies_budget',
  },
  {
    name: 'Linen Services',
    value: 'linen_services',
    budget: 'linen_services_budget',
  },
  {
    name: 'Pest Control',
    value: 'pest_control',
    budget: 'pest_control_budget',
  },
  { name: 'Grease Trap', value: 'grease_trap', budget: 'grease_trap_budget' },
  {
    name: 'Knife Services',
    value: 'knife_services',
    budget: 'knife_services_budget',
  },
  {
    name: 'Cleaning Services',
    value: 'cleaning_services',
    budget: 'cleaning_services_budget',
  },
  {
    name: 'Security Services',
    value: 'security_services',
    budget: 'security_services_budget',
  },
  {
    name: 'Administrative & General',
    value: 'administrative_and_general',
    budget: 'administrative_and_general_budget',
  },
  {
    name: 'Charitable Contributions',
    value: 'charitable_contributions',
    budget: 'charitable_contributions_budget',
  },
  {
    name: 'Postage & Mailing',
    value: 'postage_and_mailing',
    budget: 'postage_and_mailing_budget',
  },
  {
    name: 'Printing Miscellaneous',
    value: 'printing_miscellaneous',
    budget: 'printing_miscellaneous_budget',
  },
  {
    name: 'Printing Expenses-Menu',
    value: 'printing_expenses_menu',
    budget: 'printing_expenses_menu_budget',
  },
  {
    name: 'Music Services',
    value: 'music_services',
    budget: 'music_services_budget',
  },
  {
    name: 'Digital Advertising Expenses',
    value: 'digital_advertising_expenses',
    budget: 'digital_advertising_expenses_budget',
  },
  {
    name: 'Non-Digital Advertising Expens',
    value: 'nondigital_advertising_expenses',
    budget: 'nondigital_advertising_expenses_budget',
  },
  {
    name: 'Landscape Maint.',
    value: 'landscape_maint',
    budget: 'landscape_maint_budget',
  },
  { name: 'SWAG', value: 'swag', budget: 'swag_budget' },
  {
    name: 'Other Services',
    value: 'other_services',
    budget: 'other_services_budget',
  },
  {
    name: 'Wall Improvements',
    value: 'wall_improvements',
    budget: 'wall_improvements_budget',
  },
  { name: 'BibiShop', value: 'bibishop', budget: 'bibishop_budget' },
  {
    name: 'Store Supplies',
    value: 'store_supplies',
    budget: 'store_supplies_budget',
  },
  {
    name: 'Office Supplies',
    value: 'office_supplies',
    budget: 'office_supplies_budget',
  },
  {
    name: 'Printing & Advertising',
    value: 'printing_and_advertising',
    budget: 'printing_and_advertising_budget',
  },
  {
    name: 'Postage & Delivery',
    value: 'postage_and_delivery',
    budget: 'postage_and_delivery_budget',
  },
  {
    name: 'Delivery Services-eGift',
    value: 'delivery_servicesegift',
    budget: 'delivery_servicesegift_budget',
  },
  {
    name: 'Bank Charges & Fees',
    value: 'bank_charges_and_fees',
    budget: 'bank_charges_and_fees_budget',
  },
  {
    name: 'Bank Charges',
    value: 'bank_charges',
    budget: 'bank_charges_budget',
  },
  {
    name: 'Refunds & Chargebacks',
    value: 'refunds_and_chargebacks',
    budget: 'refunds_and_chargebacks_budget',
  },
  {
    name: 'Credit Card Fees',
    value: 'credit_card_fees',
    budget: 'credit_card_fees_budget',
  },
  {
    name: 'Gift Card Fee',
    value: 'gift_card_fee',
    budget: 'gift_card_fee_budget',
  },
  {
    name: 'Restaurant Service Fee',
    value: 'restaurant_service_fee',
    budget: 'restaurant_service_fee_budget',
  },
  {
    name: 'Third Party Fee',
    value: 'third_party_fee',
    budget: 'third_party_fee_budget',
  },
  {
    name: 'Cash Over/Short',
    value: 'cash_over_or_short',
    budget: 'cash_over_or_short_budget',
  },
  {
    name: 'Cr Card Over/Short',
    value: 'cr_card_over_or_short',
    budget: 'cr_card_over_or_short_budget',
  },
  {
    name: 'GC Over/Short',
    value: 'gc_over_or_short',
    budget: 'gc_over_or_short_budget',
  },
  {
    name: 'Licenses & Permits',
    value: 'licenses_permits',
    budget: 'licenses_permits_budget',
  },
  {
    name: 'Payroll Processing Fee',
    value: 'payroll_processing_fee',
    budget: 'payroll_processing_fee_budget',
  },
  {
    name: 'Business Liability Ins',
    value: 'business_liability_ins',
    budget: 'business_liability_ins_budget',
  },
  {
    name: 'Training & Seminars',
    value: 'training_and_seminars',
    budget: 'training_and_seminars_budget',
  },
  {
    name: 'Subscriptions & Dues',
    value: 'subscriptions_and_dues',
    budget: 'subscriptions_and_dues_budget',
  },
  { name: 'Meals', value: 'meals', budget: 'meals_budget' },
  { name: 'Land Travel', value: 'land_travel', budget: 'land_travel_budget' },
  { name: 'Air Travel', value: 'air_travel', budget: 'air_travel_budget' },
  { name: 'Lodging', value: 'lodging', budget: 'lodging_budget' },
  {
    name: 'Acct. & Tax Services',
    value: 'acct_and_tax_services',
    budget: 'acct_and_tax_services_budget',
  },
  {
    name: 'Legal Services',
    value: 'legal_services',
    budget: 'legal_services_budget',
  },
  {
    name: 'Consulting Services',
    value: 'consulting_services',
    budget: 'consulting_services_budget',
  },
  {
    name: 'Management Fees',
    value: 'management_fees',
    budget: 'management_fees_budget',
  },
  {
    name: 'Management Fee-Troupsburg NY',
    value: 'management_fee_troupsburg_ny',
    budget: 'management_fee_troupsburg_ny_budget',
  },
  {
    name: 'Management Fee-Rochester-41',
    value: 'management_fee_rochester_fourty_one',
    budget: 'management_fee_rochester_fourty_one_budget',
  },
  { name: 'SREC Fees', value: 'srec_fees', budget: 'srec_fees_budget' },
  {
    name: 'Miscellaneous',
    value: 'miscellaneous',
    budget: 'miscellaneous_budget',
  },
  { name: 'Leasing', value: 'leasing', budget: 'leasing_budget' },
  {
    name: 'Recruiting Fees',
    value: 'recruiting_fees',
    budget: 'recruiting_fees_budget',
  },
  { name: 'Donations', value: 'donations', budget: 'donations_budget' },
  {
    name: 'Contribution Materials',
    value: 'contribution_materials',
    budget: 'contribution_materials_budget',
  },
  {
    name: 'Relocation Expense',
    value: 'relocation_expense',
    budget: 'relocation_expense_budget',
  },
  {
    name: 'Research & Development',
    value: 'research_and_development',
    budget: 'research_and_development_budget',
  },
  { name: 'Utilities', value: 'utilities', budget: 'utilities_budget' },
  {
    name: 'Internet Service',
    value: 'internet_service',
    budget: 'internet_service_budget',
  },
  { name: 'Telephone', value: 'telephone', budget: 'telephone_budget' },
  { name: 'Electric', value: 'electric', budget: 'electric_budget' },
  { name: 'Gas', value: 'gas', budget: 'gas_budget' },
  {
    name: 'Water & Sewer',
    value: 'water_and_sewer',
    budget: 'water_and_sewer_budget',
  },
  {
    name: 'Trash Removal',
    value: 'trash_removal',
    budget: 'trash_removal_budget',
  },
  { name: 'Spinkler', value: 'spinkler', budget: 'spinkler_budget' },
  {
    name: 'Repairs & Maintenance',
    value: 'repairs_and_maintenance',
    budget: 'repairs_and_maintenance_budget',
  },
  {
    name: 'HVAC & Exhaust',
    value: 'hvac_exhaust',
    budget: 'hvac_exhaust_budget',
  },
  { name: 'Oven', value: 'oven', budget: 'oven_budget' },
  {
    name: 'Kitchen Appliances',
    value: 'kitchen_appliances',
    budget: 'kitchen_appliances_budget',
  },
  { name: 'Plumbing', value: 'plumbing', budget: 'plumbing_budget' },
  { name: 'Ice Machine', value: 'ice_machine', budget: 'ice_machine_budget' },
  {
    name: 'Refrigeration',
    value: 'refrigeration',
    budget: 'refrigeration_budget',
  },
  { name: 'Stove', value: 'stove', budget: 'stove_budget' },
  { name: 'Grill', value: 'grill', budget: 'grill_budget' },
  { name: 'Register', value: 'register', budget: 'register_budget' },
  { name: 'Menu Board', value: 'menu_board', budget: 'menu_board_budget' },
  {
    name: 'Other Repairs',
    value: 'other_repairs',
    budget: 'other_repairs_budget',
  },
  {
    name: 'Tools & Equip',
    value: 'tools_and_equip',
    budget: 'tools_and_equip_budget',
  },
  {
    name: 'Maintenance - Project',
    value: 'maintenance_project',
    budget: 'maintenance_project_budget',
  },
  {
    name: 'Maintenance - Property',
    value: 'maintenance_property',
    budget: 'maintenance_property_budget',
  },
  {
    name: 'Maintenance - Equipment',
    value: 'maintenance_equipment',
    budget: 'maintenance_equipment_budget',
  },
  {
    name: 'Store Marketing',
    value: 'store_marketing',
    budget: 'store_marketing_budget',
  },
  {
    name: 'Marketing Relations',
    value: 'marketing_relations',
    budget: 'marketing_relations_budget',
  },
  {
    name: 'Customer Relations',
    value: 'customer_relations',
    budget: 'customer_relations_budget',
  },
  {
    name: 'Local Store Marketing',
    value: 'local_store_marketing',
    budget: 'local_store_marketing_budget',
  },
  {
    name: 'Catering Development',
    value: 'catering_development',
    budget: 'catering_development_budget',
  },
  {
    name: 'Preopening Expense',
    value: 'preopening_expense',
    budget: 'preopening_expense_budget',
  },
  {
    name: 'Opening Food & Bev',
    value: 'opening_food_bev',
    budget: 'opening_food_bev_budget',
  },
  {
    name: 'Opening Paper',
    value: 'opening_paper',
    budget: 'opening_paper_budget',
  },
  {
    name: 'Opening Cleaning',
    value: 'opening_cleaning',
    budget: 'opening_cleaning_budget',
  },
  {
    name: 'Opening Mgr. Salary',
    value: 'opening_mgr_salary',
    budget: 'opening_mgr_salary_budget',
  },
  {
    name: 'Opening Crew',
    value: 'opening_crew',
    budget: 'opening_crew_budget',
  },
  {
    name: 'Opening F.I.C.A',
    value: 'opening_fica',
    budget: 'opening_fica_budget',
  },
  {
    name: 'Opening F.U.T.A',
    value: 'opening_futa',
    budget: 'opening_futa_budget',
  },
  {
    name: 'Opening S.U.T.A',
    value: 'opening_suta',
    budget: 'opening_suta_budget',
  },
  {
    name: 'Opening Bonus',
    value: 'opening_bonus',
    budget: 'opening_bonus_budget',
  },
  {
    name: 'Opening Emp Ins.',
    value: 'opening_emp_ins',
    budget: 'opening_emp_ins_budget',
  },
  {
    name: 'Opening Travel',
    value: 'opening_travel',
    budget: 'opening_travel_budget',
  },
  {
    name: 'Opening Lodging',
    value: 'opening_lodging',
    budget: 'opening_lodging_budget',
  },
  {
    name: 'Opening Store Mktg',
    value: 'opening_store_mktg',
    budget: 'opening_store_mktg_budget',
  },
  {
    name: 'Opening Misc',
    value: 'opening_misc',
    budget: 'opening_misc_budget',
  },
  {
    name: 'Opening Rent',
    value: 'opening_rent',
    budget: 'opening_rent_budget',
  },
  {
    name: 'Training Material',
    value: 'training_material',
    budget: 'training_material_budget',
  },
]

const createBbbPnlHook = (pnlMetrics: Metric[]) => {
  const useBbbPnl = () => {
    const { startDate, endDate } = useDateFilter()
    const { groupFilter } = useGroupFilter()

    const metrics = pnlMetrics.map((metric) => metric.value)
    const budgetMetrics = pnlMetrics.map((metric) => metric.budget)

    const { data, loading } = useQuery(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iLocationIds: groupFilter?.ids,
        iMetricCodes: [...metrics, ...budgetMetrics],
      },
      skip: !startDate || !endDate || !groupFilter?.ids,
    })

    return {
      data: useMemo((): IApiDataType => {
        const pnlVales = data?.listLocationMetricValues.nodes

        if (!pnlVales) return null

        let source = pnlMetrics.map((x) => {
          let variance =
            pnlVales[0]?.metricData[x.budget]['value'] -
            pnlVales[0]?.metricData[x.value]['value']

          let variance_pct =
            pnlVales[0]?.metricData[x.budget]?.value === 0 ||
            pnlVales[0]?.metricData[x.budget]?.value === null
              ? null
              : ((pnlVales[0]?.metricData[x.budget]?.value -
                  pnlVales[0]?.metricData[x.value]?.value) /
                  pnlVales[0]?.metricData[x.budget]?.value) *
                100

          if (pnlMetrics === pnlMetricsSales) {
            variance *= -1
            if (variance_pct !== null) {
              variance_pct *= -1
            }
          }

          return {
            ...x,
            name: x.name,
            value: pnlVales[0]?.metricData[x.value]['value'],
            budget: pnlVales[0]?.metricData[x.budget]['value'],
            variance,
            variance_pct,
          }
        })

        if (pnlMetrics === pnlMetricsCtrlExpenses) {
          source = source
            .filter((item) => item.variance < 0)
            .sort((a, b) => a.variance - b.variance)
        }

        return {
          source: source,
        }
      }, [data]),
      loading,
    }
  }
  return useBbbPnl
}

export const useBbbSales = createBbbPnlHook(pnlMetricsSales)
export const useBbbFnp = createBbbPnlHook(pnlMetricsFnp)
export const useBbbLabor = createBbbPnlHook(pnlMetricsLabor)
export const useBbbCtrlExpenses = createBbbPnlHook(pnlMetricsCtrlExpenses)
