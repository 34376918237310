import { gql, useQuery } from '@apollo/client'
import _, { isNil } from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { useVariables } from '../../../variables'
import { IApiType } from '../../types'

interface IDataType {
  listLocationMetricValues: {
    nodes: {
      locationId: number
      metricData: Record<
        'total_sales_budget',
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
    }[]
  }
}

const query = gql`
  query listLocationMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        metricData
      }
    }
  }
`

const useBbbMissBudget = (): IApiType => {
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { variables } = useVariables()
  const allStores = variables?.allStores
  const { startDate, endDate } = useDateFilter()
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter:
        hasGroupBy || allStores
          ? {
              location_group_ids:
                groupFilter?.ids || allStores?.locationGroupIds,
              metrics: ['total_sales_budget'],
            }
          : {
              location_ids: groupFilter?.ids,
              metrics: ['total_sales_budget'],
            },
    },
    skip: !(groupFilter || allStores) || !startDate || !endDate,
  })

  return {
    data: useMemo(() => {
      if (!data) return null

      const validateSalesBudget = data.listLocationMetricValues.nodes.reduce(
        (result, data) => ({
          ...result,
          [data.locationId]: !isNil(data.metricData.total_sales_budget?.value),
        }),
        {} as Record<number, boolean>,
      )
      const noDataLocations = (() => {
        if (groupFilter?.list)
          return groupFilter.list
            .filter((l) => !validateSalesBudget[l.id])
            .map((l) => l.name)

        if (allStores?.locations)
          return Object.keys(allStores.locations)
            .filter((key) => !validateSalesBudget[parseInt(key, 10)])
            .map((key) => allStores.locations[parseInt(key, 10)].displayName)

        if (
          !hasGroupBy &&
          groupFilter?.ids.some((id) => !validateSalesBudget[id])
        )
          return [groupFilter.label]

        return []
      })()

      if (noDataLocations.length === 0) return null

      return `${
        noDataLocations.length === 1
          ? 'The Store does'
          : 'The Following Stores do'
      } not have Sales Budgets for the time period selected and may affect analysis until budgets are updated${
        noDataLocations.length === 1
          ? '.'
          : `:
            <ul>
              ${noDataLocations.map((name) => `<li>${name}</li>`).join('')}
            </ul>
            `
      }`
    }, [data, groupFilter, hasGroupBy, allStores]),
    loading,
  }
}

export default useBbbMissBudget
