import _ from 'lodash'
import { useMemo } from 'react'

import { toPercentString } from 'pared/utils/number'

import useLocationEmployeeSalesData from '../../../hooks/useLocationEmployeeSalesData'
import { addRankToTableData, getEmployeeInfo } from '../../../util'
import { ILeaderboardData } from '../../LeaderboardTable'

function useLtoLeaderboardData(
  salesType: string,
  leaderboardType: string,
  locationId: number | string,
  startDate: string,
  endDate: string,
  dayPart: string,
) {
  const { isLoading, data, error } = useLocationEmployeeSalesData(
    locationId,
    startDate,
    endDate,
    dayPart,
  )

  return useMemo(() => {
    let leaderboardData: ILeaderboardData[] | null = null

    if (data) {
      switch (leaderboardType) {
        case 'checkPercent': {
          let storeAvg = 0

          leaderboardData = data.map((rawData: any) => {
            const salesTypeData = rawData?.categorizedSalesDetails?.[salesType]
            const value = salesTypeData?.checkPercent || 0
            if (salesTypeData?.checkPercentStoreAvg) {
              storeAvg = salesTypeData.checkPercentStoreAvg
            }

            return {
              ...getEmployeeInfo(rawData),
              value,
              displayString: toPercentString(value, 1),
            }
          })

          leaderboardData.push({
            employeeId: '',
            value: storeAvg,
            employeeName: 'Store Average',
            displayString: toPercentString(storeAvg, 1),
            isStoreAvg: true,
          })

          leaderboardData = _.orderBy(
            leaderboardData,
            ['value', 'employeeName'],
            ['desc', 'asc'],
          )
          leaderboardData = addRankToTableData(leaderboardData)

          break
        }

        default:
        // do nothing
      }
    }

    return {
      isLoading,
      leaderboardData,
      error,
    }
  }, [
    isLoading,
    data,
    error,
    salesType,
    leaderboardType,
    locationId,
    startDate,
    endDate,
    dayPart,
  ])
}

export default useLtoLeaderboardData
