import { gql } from '@apollo/client'

export interface ILocationCreditCardReconciliationVariableType {
  iStartDate: string | null
  iEndDate: string | null
  iFilter: {
    location_group_ids: number[] | null
  }
}

interface ILocationCreditCardReconciliationNodeType {
  startDate: string
  endDate: string
  locationId: number
  creditCardType: string
  totalSalesAmount: number
  totalPaymentAmount: number
  overUnderAmount: number
  overUnderPercent: number
}

export interface ILocationCreditCardReconciliationType {
  listLocationCreditCardReconciliation: {
    nodes: ILocationCreditCardReconciliationNodeType[] | null
  }
}

export const LIST_LOCATION_CREDIT_CARD_RECONCILIATION = gql`
  query ListLocationCreditCardReconciliation(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationCreditCardReconciliation(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        startDate
        endDate
        locationId
        creditCardType
        totalSalesAmount
        totalPaymentAmount
        overUnderAmount
        overUnderPercent
      }
    }
  }
`

export interface ILocationGroupCreditCardReconciliationVariableType {
  iStartDate: string | null
  iEndDate: string | null
  iFilter: {
    location_group_ids: number[] | null
    location_group_types: string[] | null
    brand_ids: number[]
  }
}

interface ILocationGroupCreditCardReconciliationNodeType {
  startDate: string
  endDate: string
  locationGroupId: number
  locationGroupName: string
  creditCardType: string
  totalSalesAmount: number
  totalPaymentAmount: number
  overUnderAmount: number
  overUnderPercent: number
}

export interface ILocationGroupCreditCardReconciliationType {
  listLocationGroupCreditCardReconciliation: {
    nodes: ILocationGroupCreditCardReconciliationNodeType[] | null
  }
}

export const LIST_LOCATION_GROUP_CREDIT_CARD_RECONCILIATION = gql`
  query ListLocationGroupCreditCardReconciliation(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupCreditCardReconciliation(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        startDate
        endDate
        locationGroupId
        locationGroupName
        creditCardType
        totalSalesAmount
        totalPaymentAmount
        overUnderAmount
        overUnderPercent
      }
    }
  }
`

export interface ISummaryLocationGroupCreditCardReconciliationVariableType {
  iStartDate: string | null
  iEndDate: string | null
  iFilter: {
    location_group_ids: number[] | null
    location_group_types: string[] | null
    brand_ids: number[]
  }
}

interface ISummaryLocationGroupCreditCardReconciliationNodeType {
  startDate: string
  endDate: string
  creditCardType: string
  totalSalesAmount: number
  totalPaymentAmount: number
  totalOverUnderAmount: number
  totalOverUnderPercent: number
}

export interface ISummaryLocationGroupCreditCardReconciliationType {
  summaryLocationGroupCreditCardReconciliation: {
    nodes: ISummaryLocationGroupCreditCardReconciliationNodeType[] | null
  }
}

export const SUMMARY_LOCATION_GROUP_CREDIT_CARD_RECONCILIATION = gql`
  query SummaryLocationGroupCreditCardReconciliation(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    summaryLocationGroupCreditCardReconciliation(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        startDate
        endDate
        creditCardType
        totalSalesAmount
        totalPaymentAmount
        totalOverUnderAmount
        totalOverUnderPercent
      }
    }
  }
`

export interface ILocationInfoVariableType {
  iFilter: {
    location_group_ids: number[]
  }
}

interface ILocationInfoNodeType {
  id: number
  code: string
  name: string
  brandId: number
  directorId: number
  directorEmployeeInfo: {
    id: string
    givenName: string
    familyName: string
    preferredName: string
  }
  gmEmployeeInfo: {
    id: number
    givenName: string
    familyName: string
    preferredName: string
  }
}

export interface ILocationInfType {
  listLocationDetails: {
    nodes: ILocationInfoNodeType[] | null
  }
}

export const GET_STORE_LIST = gql`
  query GetStoreListByBrand($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        brandId
        directorId
        directorEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        gmEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
      }
    }
  }
`
