import { useMutation } from '@apollo/client'
import MenuItem from '@material-ui/core/MenuItem'
import Modal from '@material-ui/core/Modal'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@mui/material/Button'
import { ChangeEvent, useState } from 'react'
import styled from 'styled-components'

import { InputWithLeftLabel } from 'pared/components/basicUi/InputWithLeftLabel'
import COLORS from 'pared/constants/colors'

import { INode } from '../../hooks/common/useLocationGroups'
import useLocations from '../../hooks/common/useLocations'
import {
  MUTATION_CREATE_LOCATION_GROUP_MAPPING,
  MUTATION_UPDATE_LOCATION_GROUP_MAPPING,
} from './gql'

const useStyles = makeStyles({
  text: {
    fontFamily: 'Lexend-Regular',
  },
})

const ChangeLocationButton = ({
  row,
  refetch,
}: {
  row: INode
  refetch: any
}) => {
  const {
    locationCode,
    locationName,
    locationGroupType,
    locationGroupName,
    locationGroupId,
    locationGroupMappingId,
  } = row

  let isRemoval = locationCode !== undefined
  let isAdd = locationGroupName !== undefined && locationCode === undefined

  let message = `Remove ${locationCode}: ${locationName} from ${locationGroupType}: ${locationGroupName}?`
  if (isAdd) {
    message = `Add a location to ${locationGroupType}: ${locationGroupName}`
  }

  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [locationId, setLocationId] = useState('')
  const [hasFormSubmitted, setHasFormSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { data: locations, refetch: refetchLocations } = useLocations()

  const [createLocationGroupMapping] = useMutation(
    MUTATION_CREATE_LOCATION_GROUP_MAPPING,
  )
  const [updateLocationGroupMapping] = useMutation(
    MUTATION_UPDATE_LOCATION_GROUP_MAPPING,
  )

  const handleLocationChange = (e: ChangeEvent<{}>) => {
    const { value } = e.target as HTMLSelectElement
    const location: any = locations?.find(({ id }) => value === id)
    const existingLocationGroup = location?.locationGroups?.[locationGroupType]
    if (existingLocationGroup) {
      setErrorMessage(
        `Location ${location.code} will be moved from ${existingLocationGroup.type}: ${existingLocationGroup.name}`,
      )
    } else {
      setErrorMessage('')
    }

    setLocationId(value)
  }

  const closeModal = () => {
    setOpen(false)
    setLocationId('')
    setErrorMessage('')
  }

  const onSubmit = async () => {
    if (isAdd && !locationId) {
      setErrorMessage('Location required')
      return
    }

    try {
      setHasFormSubmitted(true)
      if (isRemoval) {
        await updateLocationGroupMapping({
          variables: {
            locationGroupMappingId,
            locationGroupId,
          },
        })
      }

      if (isAdd) {
        await createLocationGroupMapping({
          variables: {
            locationId,
            locationGroupId,
            locationGroupType,
          },
        })
      }

      closeModal()
      refetch()
      refetchLocations()
    } catch (error) {
      setErrorMessage('Error: Please try again or email support@getexpo.com')
    } finally {
      setHasFormSubmitted(false)
    }
  }

  if (!locationName && !locationGroupName) {
    return null
  }

  return (
    <>
      <div>{locationName}</div>
      <Button onClick={() => setOpen(true)}>
        {isRemoval ? 'Remove' : isAdd ? 'Add' : ''}
      </Button>
      <Modal open={open} onClose={closeModal}>
        <Container>
          <Header>{message}</Header>
          {!isAdd ? null : (
            <>
              <InputWithLeftLabel label="Location">
                <Select
                  fullWidth
                  variant="outlined"
                  value={locationId}
                  displayEmpty
                  onChange={handleLocationChange}
                  className={`${classes.text}`}
                >
                  <MenuItem value="" disabled className={`${classes.text}`}>
                    Select Location
                  </MenuItem>
                  {locations?.map((location) => (
                    <MenuItem
                      key={location.id}
                      value={location.id}
                      className={`${classes.text}`}
                    >
                      {location.code} - {location.name}
                    </MenuItem>
                  ))}
                </Select>
              </InputWithLeftLabel>
            </>
          )}
          <Footer>
            {hasFormSubmitted ? (
              <div>Submitting ...</div>
            ) : (
              <ButtonContainer>
                <CancelButton onClick={closeModal}>Go Back</CancelButton>
                <SubmitButton onClick={onSubmit}>
                  {isAdd ? 'Submit' : 'Yes'}
                </SubmitButton>
              </ButtonContainer>
            )}
            <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
          </Footer>
        </Container>
      </Modal>
    </>
  )
}

export default ChangeLocationButton

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px 70px;
  max-height: 80vh;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: none;
  overflow: hidden scroll;
  border-radius: 10px;
`

const Header = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 16px;
  margin-bottom: 20px;
`

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
`

const SubmitButton = styled.div`
  background-color: ${COLORS.Expo};
  flex: 1;
  color: white;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  font-family: Lexend-Semibold;
  cursor: pointer;
  &:hover {
    opacity: 50%;
  }
`

const CancelButton = styled(SubmitButton)`
  background-color: #cfcece;
  color: black;
`

const Footer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`

const ErrorMessageContainer = styled.div`
  color: red;
`
