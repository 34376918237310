import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiDataType } from '../../types'

type IUnitType = 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT'

type IMetricDataType = Record<
  string,
  {
    unit: IUnitType
    value: number
  }
>

interface IDataType {
  listEmployeeMetricValues: {
    nodes: {
      metricData: Record<
        string,
        {
          unit: IUnitType
          value: number
        }
      >
      endDate: string
      metricLocationId: string
      employeeId: string
      employeeNumber: string
      employeeName: string
    }[]
  }
}

type IMetricType = string | { key: string; type: 'yoy' }

const query = gql`
  query ListListEmployeeMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasSummary: Boolean!
  ) {
    listEmployeeMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        endDate
        metricLocationId
        employeeLocationId
        employeeId
        employeeName
        employeeNumber
        employeeRole
        metricData
        metricSummaryData @include(if: $hasSummary)
      }
    }
  }
`

const format = (data: IMetricDataType) =>
  Object.entries(data).reduce((result, [key, value]) => {
    switch (value.unit) {
      case 'PERCENTAGE':
      case 'DOLLAR':
        return {
          ...result,
          [_.camelCase(key)]: value.value * 100,
        }
      default:
        return {
          ...result,
          [_.camelCase(key)]: value.value,
        }
    }
  }, {})

const buildLocationEmployeeMetricValuesHook = (
  metrics: { codes?: IMetricType[]; groups?: IMetricType[] },
  handler: (data: Record<string, unknown>) => Record<string, unknown> = (
    data,
  ) => data,
  hasSummary: boolean = false,
) => {
  const useMetricValues = () => {
    const { startDate, endDate } = useDateFilter()
    const { groupFilter } = useGroupFilter()
    const { data, loading } = useQuery<IDataType>(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: {
          location_ids: groupFilter?.ids,
          metrics:
            metrics?.codes
              ?.map((m) => (typeof m === 'string' ? m : null))
              ?.filter(Boolean) || [],
          metric_groups:
            metrics.groups
              ?.map((m) => (typeof m === 'string' ? m : null))
              ?.filter(Boolean) || [],
        },
        hasSummary,
      },
      skip:
        !startDate ||
        !endDate ||
        !groupFilter ||
        (!metrics?.codes?.some((m) => typeof m === 'string') &&
          !metrics?.groups?.some((m) => typeof m === 'string')),
    })

    return {
      data: useMemo((): IApiDataType => {
        if (!data) return null

        const employeeData = data?.listEmployeeMetricValues?.nodes.map((n) => ({
          id: n.employeeId.toString(),
          parentId: 'root',
          ...n,
          ...handler(
            format({
              ...n.metricData,
            }),
          ),
        }))

        return employeeData
      }, [data]),
      loading: loading,
    }
  }

  return useMetricValues
}

export default buildLocationEmployeeMetricValuesHook
