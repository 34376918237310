import { useEffect } from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import { feature, page } from 'pared/analytics/user'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import LocationInfo from 'pared/components/LocationInfo/index'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import LocationSelector from 'pared/components/nav/LocationSelector'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'
import {
  TYPE_CUSTOM,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import PmixTable from 'pared/pages/CorporatePmix/PmixTable'
import { scrollToTop } from 'pared/utils/web'

interface IProps {
  navParams: INavParams
}

const Pmix = ({ navParams }: IProps) => {
  const brandSettings = getBrandSettings()
  const storeId = navParams.storeId
  const storeIdNum = parseInt(storeId || '0') || 0
  const locationInfo = useLocationInfo(storeIdNum)

  const dateRange = useGetDateRange({ isCustomAllowed: true })
  const locationId = locationInfo?.id

  useEffect(() => {
    if (navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl)
      feature.used('Pmix')
    }
  }, [navParams.pageUrl])

  const storeTitle = `
    ${brandSettings.labels.business.store}:
    ${locationInfo?.displayName}
  `

  if (!locationId) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  return (
    <>
      <Header>
        <div>
          <h1>{storeTitle}</h1>
          <LocationInfo locationId={locationId} />
        </div>

        <Selectors>
          <LocationSelector navParams={navParams} />

          <DateRangeSelector
            navParams={navParams}
            dateRangeOptions={[
              TYPE_PERIOD,
              TYPE_QUARTER,
              TYPE_YEAR,
              TYPE_LAST_WEEK,
              TYPE_THIS_WEEK,
              TYPE_YESTERDAY,
              TYPE_TRAILING_7_DAYS,
              TYPE_CUSTOM,
            ]}
          />
        </Selectors>
      </Header>

      <MainContainer>
        {dateRange ? (
          <PmixTable
            startDate={dateRange.startDateStr}
            endDate={dateRange.endDateStr}
            locationIds={[locationId]}
            locationGroupIds={null}
            locationGroupTypes={null}
            shouldHideName
          />
        ) : null}
      </MainContainer>
    </>
  )
}

export default Pmix

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 0px 0px;
    }
  }

  & > div:first-child {
    @media ${MOBILE_WIDTH} {
      padding: 0px 25px;
    }
  }
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const MainContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px;
  }
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`
