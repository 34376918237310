import { gql, useMutation } from '@apollo/client'
import moment from 'moment'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useReset } from '../../../ResetProvider'

interface IParamsPropsType {
  id: string
}

const mutation = gql`
  mutation ApprovedTrainingTracker($listUuid: String!, $now: Datetime!) {
    updateTrainingTrackerByListUuid(
      input: {
        listUuid: $listUuid
        patch: { needApproved: true, updatedAt: $now }
      }
    ) {
      clientMutationId
    }
  }
`

const useNeedApprovalTrainingTracker = () => {
  const { id }: IParamsPropsType = useParams()
  const [updateNeedApproval] = useMutation(mutation)
  const reset = useReset()

  return useCallback(async () => {
    const now = moment().toISOString()

    await updateNeedApproval({
      variables: {
        listUuid: id,
        now: now,
      },
    })
    reset()
  }, [reset])
}

export default useNeedApprovalTrainingTracker
