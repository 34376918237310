import { ILossPreventionConfig } from '../useLossPreventionConfig'

export const wingitnorthConfig: ILossPreventionConfig = {
  summary: {
    isVisible: true,
  },
  kpiSettings: [
    { category: 'basic', key: 'totalComps', title: 'Total Discounts' },
    { category: 'basic', key: 'cashOverOrShort', title: 'Cash +/-' },
    { category: 'basic', key: 'oloOverOrShort', title: 'Olo +/-' },
    {
      category: 'custom',
      type: 'Over Ring',
      title: '% Checks with Voids',
      key: 'checkPercent',
    },
  ],
  cashHandlingTable: {
    display: false,
    columns: [],
  },
  voidsAndDeletesTable: {
    display: true,
    columns: [
      {
        key: 'Over Ring',
        title: 'Voids',
        children: [
          {
            key: 'Over Ring.count',
            title: '#',
            width: '150px',
            valueType: 'count',
            sorter: true,
          },
          {
            key: 'Over Ring.amount',
            title: '$',
            width: '150px',
            valueType: 'price',
            sorter: true,
          },
          {
            key: 'Over Ring.details',
            title: 'Details',
            width: '150px',
            valueType: 'detail',
          },
        ],
      },
    ],
  },
  discountByEmployeeTable: {
    display: true,
  },
}
