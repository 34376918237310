import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import styled from 'styled-components'

import {
  CustomizedReportRender,
  useFetchCustomizedReportData,
} from 'pared/components/CustomizedReport'

const ExistingUserTable = () => {
  const inputParams = {}
  const { reportDefinition, reportResult, isLoading, refetch } =
    useFetchCustomizedReportData('EXISTING_USER_TABLE', inputParams)
  const [query, setQuery] = useState('')

  let finalReportResult = reportResult
  if (reportResult) {
    const filteredTableData = reportResult.tableData.filter(
      ({ email }) => email.indexOf(query) >= 0,
    )
    finalReportResult = {
      ...reportResult,
      tableData: filteredTableData,
    }
  }

  const handleInputChange = (e: any) => {
    setQuery(e.target.value)
  }

  return (
    <Container>
      <TextField
        name="email"
        variant="outlined"
        value={query}
        onChange={handleInputChange}
        placeholder="Start typing email..."
      />
      <VerticalSpacer />
      <CustomizedReportRender
        reportDefinition={reportDefinition}
        reportResult={finalReportResult}
        isLoading={isLoading}
        rowKey={({ email }) => email}
        refetch={refetch}
      />
    </Container>
  )
}

const Container = styled.div`
  margin: 0;
  padding: 0;
`

const VerticalSpacer = styled.div`
  height: 20px;
`

export default React.memo(ExistingUserTable)
