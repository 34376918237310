import { useMemo } from 'react'

import useMockData from './useMockData'

const useSchedule = () => {
  const data = useMockData()

  return useMemo(() => {
    const newData = data.map((data) => {
      if (!data.targetCost) return data

      const { totalTime, totalScheduledCost } = data.labor.reduce(
        (result, { time, scheduledCost }) => ({
          totalTime: result.totalTime + time,
          totalScheduledCost: result.totalScheduledCost + scheduledCost,
        }),
        {
          totalTime: 0,
          totalScheduledCost: 0,
        },
      )

      return {
        ...data,
        totalTime,
        totalScheduledCost,
        overTarget: totalScheduledCost - data.targetCost,
      }
    })

    return [newData.slice(0, 7), newData.slice(7, 14), newData.slice(14, 21)]
  }, [data])
}

export default useSchedule
