import { useQuery } from '@apollo/client'

import { INullableType } from 'pared/types'

import {
  IDeliveryDayTrendType,
  IDeliveryDayTrendVariablesType,
  IDeliveryStaffKpiNodeType,
  IDeliveryStaffKpiType,
  IDeliveryStaffKpiVariablesType,
  IDeliverySummaryType,
  IDeliverySummaryVariablesType,
  getLocationDeliverySummary,
  listLocationDeliveryDayTrends,
  listLocationDeliveryStaffKpi,
} from '../gqls/delivery'

interface IDeliveryActionType {
  isBetterThanCompany: boolean
  issue: string
  worstDay: string
  name: string
}

interface IReturnType {
  loading: boolean
  action: IDeliveryActionType
}

const DAYS = {
  1: 'Sunday',
  2: 'Monday',
  3: 'Tuesday',
  4: 'Wednesday',
  5: 'Thursday',
  6: 'Friday',
  7: 'Saturday',
}

export default (
  startDate: string,
  endDate: string,
  locationId: number,
): IReturnType => {
  const deliveryAction = {
    isBetterThanCompany: false,
    issue: '',
    worstDay: '',
    name: ',',
  }
  const deliverySummaryVariables: INullableType<IDeliverySummaryVariablesType> =
    {
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
    }

  const { data: deliverySummaryData, loading: deliverySummaryLoading } =
    useQuery<IDeliverySummaryType>(getLocationDeliverySummary, {
      variables: deliverySummaryVariables,
    })

  const deliveryDayTerndVariables: INullableType<IDeliveryDayTrendVariablesType> =
    {
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
    }

  const { data: deliveryDayTerndData, loading: deliveryDayTerndLoading } =
    useQuery<IDeliveryDayTrendType>(listLocationDeliveryDayTrends, {
      variables: deliveryDayTerndVariables,
    })

  const deliveryStaffKpiVariables: INullableType<IDeliveryStaffKpiVariablesType> =
    {
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
    }

  const { data: deliveryStaffKpiData, loading: deliveryStaffKpiLoading } =
    useQuery<IDeliveryStaffKpiType>(listLocationDeliveryStaffKpi, {
      variables: deliveryStaffKpiVariables,
    })

  const loading =
    deliverySummaryLoading || deliveryDayTerndLoading || deliveryStaffKpiLoading

  const deliverySummary =
    deliverySummaryData?.getLocationVsSystemwideDeliveryKpi?.nodes[0]
  let accuracy = false
  let cancellations = false
  let lateness = false

  if (deliverySummary) {
    const {
      locationInaccurateOrderPercent,
      systemwideInaccurateOrderPercent,
      locationCancelledOrderPercent,
      systemwideCancelledOrderPercent,
      locationDelayedOrderPercent,
      systemwideDelayedOrderPercent,
    } = deliverySummary

    if (locationInaccurateOrderPercent > systemwideInaccurateOrderPercent) {
      accuracy = true
    }

    if (locationCancelledOrderPercent > systemwideCancelledOrderPercent) {
      cancellations = true
    }

    if (locationDelayedOrderPercent > systemwideDelayedOrderPercent) {
      lateness = true
    }
  }

  const dayTrendData =
    deliveryDayTerndData?.listLocationDeliveryDayTrends?.nodes || []

  const deliveryStaffData =
    deliveryStaffKpiData?.listLocationDeliveryStaffKpi?.nodes || []

  if (dayTrendData?.length > 0 && deliveryStaffData?.length > 0) {
    const fullDays = dayTrendData.filter(({ dayPart }) => dayPart === 'ALL')
    let worstAccuracyDay = null
    let worstLatenessDay = null
    let worstCancellationDay = null

    fullDays.reduce(
      (
        acc,
        {
          dayOfWeek,
          inaccurateOrderPercent,
          delayedOrderPercent,
          cancelledOrderPercent,
        },
      ) => {
        if (inaccurateOrderPercent > acc.inaccurateOrderPercent) {
          worstAccuracyDay = dayOfWeek
          acc.inaccurateOrderPercent = inaccurateOrderPercent
        }

        if (delayedOrderPercent > acc.delayedOrderPercent) {
          worstLatenessDay = dayOfWeek
          acc.delayedOrderPercent = delayedOrderPercent
        }

        if (cancelledOrderPercent > acc.cancelledOrderPercent) {
          worstCancellationDay = dayOfWeek
          acc.cancelledOrderPercent = cancelledOrderPercent
        }

        return acc
      },
      {
        inaccurateOrderPercent: 0,
        delayedOrderPercent: 0,
        cancelledOrderPercent: 0,
      },
    )

    let employees: IDeliveryStaffKpiNodeType[] = []

    if (accuracy) {
      if (worstAccuracyDay) {
        employees = [...deliveryStaffData].sort(
          (a, b) => b.inaccurateOrderPercent - a.inaccurateOrderPercent,
        )

        deliveryAction.issue = 'Accuracy'
        deliveryAction.worstDay = DAYS[worstAccuracyDay]
      }
    } else if (lateness) {
      if (worstLatenessDay) {
        employees = [...deliveryStaffData].sort(
          (a, b) => b.delayedOrderPercent - a.delayedOrderPercent,
        )

        deliveryAction.issue = 'Lateness'
        deliveryAction.worstDay = DAYS[worstLatenessDay]
      }
    } else if (cancellations) {
      if (worstCancellationDay) {
        employees = [...deliveryStaffData].sort(
          (a, b) => b.cancelledOrderPercent - a.cancelledOrderPercent,
        )

        deliveryAction.issue = 'Cancellations'
        deliveryAction.worstDay = DAYS[worstCancellationDay]
      }
    }

    if (employees.length > 0) {
      deliveryAction.name = `${employees[0].employeeInfo.firstName} ${employees[0].employeeInfo.lastName}`
    }

    if (!accuracy && !lateness && !cancellations) {
      deliveryAction.isBetterThanCompany = true

      if (worstAccuracyDay) {
        deliveryAction.issue = 'Accuracy'
        deliveryAction.worstDay = DAYS[worstAccuracyDay]
      } else if (worstLatenessDay) {
        deliveryAction.issue = 'Lateness'
        deliveryAction.worstDay = DAYS[worstLatenessDay]
      } else if (worstCancellationDay) {
        deliveryAction.issue = 'Cancellations'
        deliveryAction.worstDay = DAYS[worstCancellationDay]
      }
    }
  }

  return {
    loading,
    action: deliveryAction,
  }
}
