import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import navigator from 'pared/Routes/navigator'
import { track } from 'pared/analytics/storeSelector'
import getLocations, { ILocation, ILocationMap } from 'pared/data/getLocations'
import { getPathnameWithoutBrand } from 'pared/utils/brand'

import Main from './Main'

interface IProps {
  navParams: INavParams
  isEnabled?: boolean
}

function LocationSelector({ navParams, isEnabled }: IProps) {
  const [allLocations, setAllLocations] = useState<ILocation[] | null>(null)
  const [locationMap, setLocationMap] = useState<ILocationMap>({})

  useEffect(() => {
    async function fetchLocations() {
      const locationData = await getLocations()
      const locations = locationData.locations

      if (Array.isArray(locations) && locations.length > 0) {
        const newLocationMap = locationData.locationMap
        setAllLocations(locations)
        setLocationMap(newLocationMap)
      } else {
        setAllLocations([])
      }
    }

    fetchLocations()
  }, [])

  async function handleLocationChange(event: any, value: { id: number }) {
    const locationId = value?.id
    if (locationId) {
      if (navParams && navParams.pagePathname) {
        track.storeChanged(`${locationId}`)
        const newSearchParams = { storeId: locationId }
        const pathnameWithoutBrand = getPathnameWithoutBrand(
          navParams.pagePathname,
        )

        navigator.go(pathnameWithoutBrand, newSearchParams, undefined, true)
      }
    }
  }

  let locationId: string | null = null
  if (navParams && navParams.storeId && locationMap[navParams.storeId]) {
    locationId = navParams.storeId
  } else {
    if (allLocations && allLocations.length > 0) {
      locationId = allLocations[0].locationId
      const newSearchParams = { storeId: locationId }
      const pathnameWithoutBrand = getPathnameWithoutBrand(
        navParams.pagePathname,
      )

      navigator.go(pathnameWithoutBrand, newSearchParams)
    }
  }

  return (
    <Main
      allLocations={allLocations}
      selectedLocationId={locationId}
      onLocationChange={handleLocationChange}
      isEnabled={isEnabled !== false}
    />
  )
}

export default LocationSelector
