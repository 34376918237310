import Main from './Main'

interface IProps {
  kpi: string
  onSelectKpi: (event: any) => null
  shouldShowScorecard: boolean
}

function KpiSelect({ kpi, onSelectKpi, shouldShowScorecard }: IProps) {
  return (
    <Main
      kpi={kpi}
      onSelectKpi={onSelectKpi}
      shouldShowScorecard={shouldShowScorecard}
    />
  )
}

export default KpiSelect
