import Popover from '@material-ui/core/Popover'
import TextField from '@mui/material/TextField'
import moment from 'moment'
import 'rc-calendar/assets/index.css'
// @ts-ignore no type
import RangeCalendar from 'rc-calendar/lib/RangeCalendar'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

interface IPropsType {
  startDate: moment.Moment
  endDate?: moment.Moment
  value?: [moment.Moment, moment.Moment]
  onChange: (value: [moment.Moment, moment.Moment]) => void
}

const StyledTextField = styled(TextField)`
  margin: 15px 0px;
  width: 80%;
  cursor: pointer;

  > *,
  input {
    font-family: Lexend-Regular;
    cursor: pointer;
    text-align: center;
  }
`

const CustomDateRangePicker = ({
  startDate,
  endDate = moment.utc(),
  value,
  onChange,
}: IPropsType) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<null | HTMLDivElement>(
    null,
  )
  const onOpen = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      setPopoverAnchorEl(e.target as HTMLDivElement)
    },
    [setPopoverAnchorEl],
  )
  const onClose = useCallback(() => {
    setPopoverAnchorEl(null)
  }, [setPopoverAnchorEl])
  const onDateChange = useCallback(
    (dates: [moment.Moment, moment.Moment] | null) => {
      if (!dates) return

      onChange(dates)
      setPopoverAnchorEl(null)
    },
    [onChange, setPopoverAnchorEl],
  )
  const inputValue = useMemo(() => {
    if (!value?.[0] || !value?.[1]) return ''

    return value[0].isSame(value[1])
      ? value[0].format('YYYY-MM-DD')
      : `${value[0].format('YYYY-MM-DD')} - ${value[1].format('YYYY-MM-DD')}`
  }, [value])

  return (
    <>
      <StyledTextField
        value={inputValue}
        placeholder="Please select a date"
        InputProps={{
          readOnly: true,
        }}
        onClick={onOpen}
      />

      <Popover
        onClose={onClose}
        anchorEl={popoverAnchorEl}
        open={Boolean(popoverAnchorEl)}
      >
        {/* @ts-ignore no type */}
        <RangeCalendar
          selectedValue={value}
          defaultSelectedValue={value}
          onSelect={onDateChange}
          onClear={onClose}
          disabledDate={(date?: moment.Moment) =>
            date?.isBefore(startDate, 'days') || date?.isAfter(endDate, 'days')
          }
          showToday={false}
        />
      </Popover>
    </>
  )
}

export default React.memo(CustomDateRangePicker)
