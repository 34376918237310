import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

interface IListLocationFinancialKpisNodeType {
  locationId: number
  netSales: string
  yoyNetSales: string
  totalCheckCount: string
  yoyTotalCheckCount: string
  totalCheckAverage: string
}

const query = gql`
  query jscBkListLocationDailyFlashGroupByDistrictLeader(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        netSales
        yoyNetSales
        totalCheckCount
        yoyTotalCheckCount
        totalCheckAverage
      }
    }
  }
`

export const jscBkListLocationDailyFlashGroupByDistrictLeaderConfigs = {
  netSales: 'price',
  yoyNetSales: 'price',
  netSalesDiffVsLy: 'price',
  percentVsLY: 'percent',
  totalCheckCount: 'number',
  yoyTotalCheckCount: 'number',
  checkCountDiffVsLy: 'number',
  customerPercentVsLY: 'percent',
  totalCheckAverage: 'price',
} as const

const useJscBkListLocationDailyFlashGroupByDistrictLeader = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_group_ids: groupFilter?.ids },
    },
    skip: !startDate || !endDate || !groupFilter?.list?.[0]?.groupBy,
  })

  return {
    data: useMemo((): IApiDataType => {
      const listLocationFinancialKpis = data?.listLocationFinancialKpis
        .nodes as IListLocationFinancialKpisNodeType[] | undefined

      if (!listLocationFinancialKpis) return null

      const uniqueIdList = groupFilter?.list?.reduce((r, l) => {
        const id = l.groupBy?.id
        if (!id) return r

        return r.includes(id) ? r : [...r, id]
      }, [] as number[])

      return (
        uniqueIdList?.map((groupId) => {
          const groupData = listLocationFinancialKpis?.filter((n) => {
            const groupInfo = groupFilter?.list?.find(
              (l) => l.id === n.locationId,
            )

            return groupInfo?.groupBy?.id === groupId
          })

          const source = groupData.map(
            (n: IListLocationFinancialKpisNodeType) => {
              const groupInfo = groupFilter?.list?.find(
                (l) => l.id === n.locationId,
              )
              const yoyNetSales = parseInt(n?.yoyNetSales || '0', 10)
              const netSales = parseInt(n?.netSales || '0', 10)
              const totalCheckCount = parseInt(n?.totalCheckCount || '0', 10)
              const yoyTotalCheckCount = parseInt(
                n?.yoyTotalCheckCount || '0',
                10,
              )

              return {
                ...n,
                netSales,
                yoyNetSales,
                totalCheckCount,
                yoyTotalCheckCount,
                ...(yoyNetSales !== 0
                  ? { netSalesDiffVsLy: netSales - yoyNetSales }
                  : {}),
                ...(yoyNetSales !== 0
                  ? {
                      percentVsLY:
                        (100 * (netSales - yoyNetSales)) / yoyNetSales,
                    }
                  : {}),
                ...(yoyTotalCheckCount !== 0
                  ? { checkCountDiffVsLy: totalCheckCount - yoyTotalCheckCount }
                  : {}),
                ...(yoyTotalCheckCount !== 0
                  ? {
                      customerPercentVsLY:
                        ((totalCheckCount - yoyTotalCheckCount) * 100.0) /
                        yoyTotalCheckCount,
                    }
                  : {}),
                groupInfo,
              }
            },
          )
          const total = source?.reduce(
            (sum, row) => ({
              netSales: sum.netSales + (row?.netSales || 0),
              yoyNetSales: sum.yoyNetSales + (row?.yoyNetSales || 0),
              totalCheckCount:
                sum.totalCheckCount + (row?.totalCheckCount || 0),
              yoyTotalCheckCount:
                sum.yoyTotalCheckCount + (row?.yoyTotalCheckCount || 0),
            }),
            {
              netSales: 0,
              yoyNetSales: 0,
              totalCheckCount: 0,
              yoyTotalCheckCount: 0,
            },
          )

          const netSales = total ? total.netSales : 0
          const totalCheckCount = total ? total.totalCheckCount : 0
          const yoyNetSales = total ? total.yoyNetSales : 0
          const netSalesDiffVsLy = total
            ? total.netSales - total.yoyNetSales
            : 0
          const checkCountDiffVsLy = total
            ? total.totalCheckCount - total.yoyTotalCheckCount
            : 0
          const yoyTotalCheckCount = total ? total.yoyTotalCheckCount : 0

          return {
            id: groupId.toString(),
            parentId: 'root',
            source,
            summary: {
              ...total,
              groupInfo: { name: 'Total' },
              'groupInfo.groupBy': { name: ' ' },
              netSalesDiffVsLy: netSalesDiffVsLy,
              percentVsLY:
                yoyNetSales !== 0 ? (100 * netSalesDiffVsLy) / yoyNetSales : 0,
              checkCountDiffVsLy,
              customerPercentVsLY:
                yoyTotalCheckCount !== 0
                  ? (100 * checkCountDiffVsLy) / yoyTotalCheckCount
                  : 0,
              totalCheckAverage:
                totalCheckCount !== 0 ? netSales / totalCheckCount : 0,
            },
          }
        }) || []
      )
    }, [groupFilter, data]),
    loading,
  }
}

export default useJscBkListLocationDailyFlashGroupByDistrictLeader
