import { useCallback, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'

export const MAX_AMOUNT = 9

const usePagination = (pageSize: number, total: number) => {
  const [pagination, setPagination] = useState({ current: 0, pageSize })
  const onChange = useCallback(
    (newCurrent: number) => {
      if (!pageSize)
        setPagination({
          current: 0,
          pageSize: pagination.pageSize !== total ? total : MAX_AMOUNT,
        })
      else
        setPagination({
          current: newCurrent,
          pageSize,
        })
    },
    [pageSize, total, pagination],
  )
  const prevTotal = usePrevious(total)

  useEffect(() => {
    if (total !== 0) {
      // use for not pagination table
      if (pageSize === 0) {
        if (pagination.pageSize === 0)
          setPagination({
            current: 0,
            pageSize: MAX_AMOUNT > total ? total : MAX_AMOUNT,
          })

        if (prevTotal !== total && pagination.pageSize === prevTotal)
          setPagination({
            current: 0,
            pageSize: total,
          })
        // pagination table could update the pageSize
      } else if (pagination.pageSize !== pageSize)
        setPagination({
          current: 0,
          pageSize,
        })
    }
  }, [pagination, pageSize, total])

  return {
    ...pagination,
    onChange,
  }
}

export default usePagination
