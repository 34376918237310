import Chart from 'chart.js/auto'
import _ from 'lodash'

export interface IYAxisData {
  type: string
  tooltipLabel: string
  data: number[]
  yAxisId?: string
  color?: string
  borderColor?: string
  backgroundColor?: string
  hidden?: boolean
}

interface IChartOptions {
  title?: string
  xAxisLabel?: string
  xTickCallback?: (value: any, index: number, values: any[]) => string
  xShowGridLines?: boolean
  yLeftAxisLabel?: string
  yLeftTickCallback?: (value: any, index: number, values: any[]) => string
  yLeftShowGridLines?: boolean
  yRightAxisLabel?: string
  yRightTickCallback?: (value: any, index: number, values: any[]) => string
  yRightShowGridLines?: boolean
  tooltipTitleCallback?: (tooltipItemContext: any[]) => string
  tooltipLabelCallback?: (tooltipItemContext: any) => string
}

function drawStackedBarChart(
  canvasContext: any,
  xAxisData: any[],
  yAxisDataArr: IYAxisData[],
  options?: IChartOptions,
) {
  let mixedChart: Chart

  // read customized chart options
  let title: any = { display: false }
  let xAxisLabel = ''
  let xTicks: any = {}
  let xShowGridLines: boolean = true
  let yLeftAxisLabel = ''
  let yLeftTicks: any = {}
  let yLeftShowGridLines: boolean = true
  let yRightAxisLabel = ''
  let yRightTicks: any = {}
  let yRightShowGridLines: boolean = true
  let tooltip: any = {}

  if (options) {
    if (options.title) {
      title = {
        display: true,
        text: options.title,
      }
    }

    if (options.xAxisLabel) {
      xAxisLabel = options.xAxisLabel
    }
    if (options.xTickCallback) {
      xTicks.callback = options.xTickCallback
    }
    if (options.xShowGridLines === false) {
      xShowGridLines = false
    }

    if (options.yLeftAxisLabel) {
      yLeftAxisLabel = options.yLeftAxisLabel
    }
    if (options.yLeftTickCallback) {
      yLeftTicks.callback = options.yLeftTickCallback
    }
    if (options.yLeftShowGridLines === false) {
      yLeftShowGridLines = false
    }

    if (options.yRightAxisLabel) {
      yRightAxisLabel = options.yRightAxisLabel
    }
    if (options.yRightTickCallback) {
      yRightTicks.callback = options.yRightTickCallback
    }
    if (options.yRightShowGridLines === false) {
      yRightShowGridLines = false
    }

    if (options.tooltipTitleCallback || options.tooltipLabelCallback) {
      tooltip.callbacks = {}
      if (options.tooltipTitleCallback) {
        tooltip.callbacks.title = options.tooltipTitleCallback
      }
      if (options.tooltipLabelCallback) {
        tooltip.callbacks.label = options.tooltipLabelCallback
      }
    }
  }

  let hasYLeftAxis: boolean = false
  let hasYRightAxis: boolean = false
  const datasets = yAxisDataArr

  // finalize the chart config
  const chartConfig: any = {
    type: 'line',
    data: {
      datasets,
      labels: xAxisData,
    },
    options: {
      responsive: true,
      plugins: {
        title,
        tooltip,
        legend: {
          position: 'bottom',
          labels: {
            filter: (legendItem: any) => {
              if (_.get(legendItem, 'text', '') !== 'Hidden') {
                return true
              }
            },
          },
        },
      },
      animation: false,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: yLeftAxisLabel,
          },
        },
      },
      interaction: {
        mode: 'index',
        intersect: false,
      },
    },
  }

  if (hasYLeftAxis) {
    chartConfig.options.scales.yLeft = {
      display: true,
      title: {
        display: true,
        text: yLeftAxisLabel,
      },
      ticks: yLeftTicks,
      position: 'left',
      grid: {
        display: yLeftShowGridLines,
      },
    }
  }

  if (hasYRightAxis) {
    chartConfig.options.scales.yRight = {
      display: true,
      title: {
        display: true,
        text: yRightAxisLabel,
      },
      ticks: yRightTicks,
      position: 'right',
      grid: {
        display: yRightShowGridLines,
      },
    }
  }

  mixedChart = new Chart(canvasContext, chartConfig)

  return mixedChart
}

export default drawStackedBarChart
