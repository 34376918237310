import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import CustomizedReport from 'pared/components/CustomizedReport'
import LocationInfo from 'pared/components/LocationInfo'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import LocationSelector from 'pared/components/nav/LocationSelector'
import COLORS from 'pared/constants/colors'
import {
  IDateRange,
  TYPE_PERIOD,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'

import Chart from './Chart'
import DayTrends from './DayTrends'
import DisputeTable from './DisputeTable'
import MissingItemTable from './MissingItemTable'
import StaffTable from './StaffTable'
import Summary from './Summary'
import useDeliveryConfig from './hooks/useDeliveryConfig'
import { ILocationData, ILocationDeliveryKpi } from './index'

interface IProps {
  navParams: INavParams
  locationDeliveryKpiSummary: ILocationDeliveryKpi
  selectedDateRange: IDateRange
  locationId: number
  onSelectDayTrendsDayPart: (event: any) => null
  dayTrendsDayPart: string
  dayTrendsInfo: any[]
  onSelectDayTrendsCategory: (event: any) => void
  dayTrendsCategory: string
  selectedLocation: ILocationData
}

interface IKpiTd {
  danger?: boolean
  success?: boolean
  book?: boolean
  isBackgroundGrey?: boolean
}

function WebSmallMain({
  navParams,
  locationDeliveryKpiSummary,
  selectedDateRange,
  locationId,
  onSelectDayTrendsDayPart,
  dayTrendsDayPart,
  dayTrendsInfo,
  onSelectDayTrendsCategory,
  dayTrendsCategory,
  selectedLocation,
}: IProps) {
  const totalStoreCount = _.get(
    locationDeliveryKpiSummary,
    'totalStoreCount',
    0,
  )
  const brand = getBrand()
  const deliveryConfig = useDeliveryConfig(brand)

  const director = {
    name: selectedLocation.doName,
    id: selectedLocation.doEmployeeId,
  }
  const gm = {
    name: selectedLocation.gmName,
    id: selectedLocation.gmEmployeeId,
  }

  return (
    <>
      <SelectorContainer>
        <LocationSelector navParams={navParams} />
        <DateRangeSelector
          navParams={navParams}
          excludedDateRanges={[TYPE_YESTERDAY]}
          preSelectedDateRange={TYPE_PERIOD}
        />
      </SelectorContainer>
      <MainContainer>
        <PageTitleDiv>Delivery</PageTitleDiv>
        <SubtitleContainer>Updated Weekly</SubtitleContainer>
        <LocationInfo director={director} gm={gm} />

        <VerticalSpacer40px />
        {deliveryConfig.isSummaryHidden ? null : (
          <Summary
            startDateStr={selectedDateRange?.startDateStr}
            endDateStr={selectedDateRange?.endDateStr}
            locationId={locationId}
            locationName={selectedLocation?.name}
          />
        )}
        <VerticalSpacer40px />
        <FullScreenTable>
          <thead>
            <tr>
              <th>KPIs</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {deliveryConfig['realTimeKpisSmall'].map(
              ({
                variableName,
                displayName,
                displayName2,
              }: {
                variableName: string
                displayName: string
                displayName2: string
              }) => {
                const value = _.get(
                  locationDeliveryKpiSummary,
                  variableName,
                  '',
                )
                return (
                  <tr>
                    <td>{displayName2 || displayName}</td>
                    <BoldNumTd>{value}</BoldNumTd>
                  </tr>
                )
              },
            )}
            {deliveryConfig.oloWaitTime?.isVisible ? (
              <tr>
                <td>{deliveryConfig.oloWaitTime?.displayName}</td>
                <BoldNumTd>
                  <CustomizedReport
                    reportName="STORE_DELIVERY_OLO_WAIT_TIME"
                    inputParams={{
                      locationId,
                      startDate: selectedDateRange?.startDateStr || '',
                      endDate: selectedDateRange?.endDateStr || '',
                    }}
                  />
                </BoldNumTd>
              </tr>
            ) : null}
          </tbody>
        </FullScreenTable>

        <VerticalSpacer40px />

        <FullScreenTable>
          <thead>
            <tr>
              <th>
                Store Ranking
                <PtdKpiCategoryNameSubtitleDiv>{`(out of ${totalStoreCount} stores)`}</PtdKpiCategoryNameSubtitleDiv>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {deliveryConfig['rankings'].map(
              ({
                variableName,
                displayName,
              }: {
                variableName: string
                displayName: string
              }) => {
                const value = _.get(
                  locationDeliveryKpiSummary,
                  variableName,
                  '',
                )
                return (
                  <tr>
                    <td>{displayName}</td>
                    <BoldNumTd>{value}</BoldNumTd>
                  </tr>
                )
              },
            )}
          </tbody>
        </FullScreenTable>

        {deliveryConfig['storeVsSystemChart']?.isVisible &&
        selectedDateRange &&
        selectedLocation ? (
          <>
            <VerticalSpacer30px />
            <Chart
              startDate={selectedDateRange.startDateStr}
              endDate={selectedDateRange.endDateStr}
              dateRangeType={selectedDateRange.type}
              locationId={locationId}
              locationName={selectedLocation.name}
            />
          </>
        ) : null}

        {deliveryConfig['staffTable']?.isVisible && selectedDateRange ? (
          <>
            <VerticalSpacer80px />
            <StaffTable
              locationId={locationId}
              startDate={selectedDateRange.startDateStr}
              endDate={selectedDateRange.endDateStr}
            />
          </>
        ) : null}

        <VerticalSpacer40px />

        <TrendsOuterContainer>
          <TrendsContainer>Day Trends</TrendsContainer>
          <TrendsSelectorContainer>
            <FormControl
              variant="outlined"
              margin="dense"
              style={{ width: '90vw' }}
            >
              <FilterSelect
                displayEmpty
                value={dayTrendsCategory}
                onChange={onSelectDayTrendsCategory}
              >
                {deliveryConfig['staffAndDayTrendsFilter'].options.map(
                  ({
                    variableName,
                    displayName,
                  }: {
                    variableName: string
                    displayName: string
                  }) => {
                    return (
                      <MenuItem key={variableName} value={variableName}>
                        {displayName}
                      </MenuItem>
                    )
                  },
                )}
              </FilterSelect>
            </FormControl>
          </TrendsSelectorContainer>
          {deliveryConfig['dayTrendsDayPartDropdown']?.isVisible ? (
            <TrendsSelectorContainer>
              <DaypartFormControl
                variant="outlined"
                margin="dense"
                style={{ width: '90vw' }}
              >
                <OptionSelect
                  displayEmpty
                  value={dayTrendsDayPart}
                  onChange={onSelectDayTrendsDayPart}
                >
                  <MenuItem key="DINNER" value="DINNER">
                    Dinner Only
                  </MenuItem>
                  <MenuItem key="LUNCH" value="LUNCH">
                    Lunch Only
                  </MenuItem>
                  <MenuItem key="ALL" value="ALL">
                    All
                  </MenuItem>
                </OptionSelect>
              </DaypartFormControl>
            </TrendsSelectorContainer>
          ) : null}
        </TrendsOuterContainer>

        <DayTrends
          dayTrendsInfo={dayTrendsInfo}
          dayTrendsCategory={dayTrendsCategory}
          isOrdersHidden={deliveryConfig.isDayTrendOrdersHidden || false}
        />

        {selectedDateRange && deliveryConfig.isDisputeTableVisible ? (
          <>
            <VerticalSpacer80px />
            <DisputeTable
              locationId={locationId}
              startDate={selectedDateRange.startDateStr}
              endDate={selectedDateRange.endDateStr}
            />
            <VerticalSpacer80px />
            <MissingItemTable
              locationId={locationId}
              startDate={selectedDateRange.startDateStr}
              endDate={selectedDateRange.endDateStr}
            />
          </>
        ) : null}

        <VerticalSpacer150px />
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  width: 100%;
  color: ${COLORS.Chalkboard};
  padding: 30px 25px 50px 25px;
`

const TrendsOuterContainer = styled.div`
  display: inline-block;
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-style: normal;
  font-weight: 700;
  float: left;
`

const TrendsContainer = styled(PageHeader)`
  width: 90vw;
  margin-bottom: 10px;
  font-size: 16px;
`

const FilterContainer = styled.div`
  display: inline-block;
`

const DescriptionContainer = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  float: left;
  margin-top: 17px;
`

const FilterSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const DaypartFormControl = styled(FormControl)`
  background-color: white;
`

const OptionSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const TrendsSelectorContainer = styled.div`
  float: left;
`

const PageTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 0;
`

const BoldNumTd = styled.td`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
`

const SelectorContainer = styled.div`
  padding: 10px 25px 10px 25px;
  background-color: rgb(196, 196, 196);
`

const KpiTd = styled.th<IKpiTd>`
  color: ${(props) =>
    props.danger ? COLORS.Pomodoro : props.success ? COLORS.Expo : 'black'};
  font-family: ${(props) =>
    props.book ? 'Lexend-Regular' : 'Lexend-SemiBold'};
  font-size: ${(props) => (props.book ? '18px' : '24px')};
  font-weight: ${(props) => (props.book ? '400' : '700')};
`

const VerticalSpacer30px = styled.div`
  width: 100%;
  height: 30px;
`

const VerticalSpacer40px = styled.div`
  width: 100%;
  height: 40px;
`

const VerticalSpacer80px = styled.div`
  width: 100%;
  height: 80px;
`

const VerticalSpacer150px = styled.div`
  width: 100%;
  height: 150px;
`

const FullScreenTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};
  text-align: right;

  th {
    font-family: Lexend-SemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  td,
  th {
    vertical-align: middle;
    padding: 8px 0;
  }

  th:first-child {
    text-align: left;
  }

  td:first-child {
    text-align: left;
  }

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  thead::after {
    content: '';
    display: block;
    height: 10px;
    width: 100%;
    background: transparent;
  }
`

const PtdKpiCategoryNameSubtitleDiv = styled.div`
  width: 100%;
  padding: 0 0 5px 0;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;
`

export default WebSmallMain
