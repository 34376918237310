import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

interface IProps {
  employeeName: string
  availableStatusOptions: { name: string; code: string }[]
  newEmployeeStatus: string | null
  isDialogOpen: boolean
  onOpenDialog: () => void
  onCloseDialog: () => void
  onSelectNewStatus: (newEmployeeStatus: string) => void
  onSaveNewStatus: () => Promise<void>
  isSaving: boolean
}

function Main({
  employeeName,
  availableStatusOptions,
  newEmployeeStatus,
  isDialogOpen,
  onOpenDialog,
  onCloseDialog,
  onSelectNewStatus,
  onSaveNewStatus,
  isSaving,
}: IProps) {
  return (
    <>
      <EditButtonSpan onClick={onOpenDialog}>EDIT</EditButtonSpan>
      <Dialog open={isDialogOpen}>
        <DialogContent>
          <DialogTitle>Select a New Status</DialogTitle>
          <DialogSubTitle>for {employeeName}</DialogSubTitle>
          <SelectListDiv>
            <List>
              {availableStatusOptions.map(({ name, code }) => (
                <ListItem
                  button
                  key={code}
                  onClick={() => onSelectNewStatus(code)}
                  selected={newEmployeeStatus === code}
                  disabled={isSaving}
                >
                  <ListItemText>{name}</ListItemText>
                </ListItem>
              ))}
            </List>
          </SelectListDiv>

          <ButtonTable>
            <tbody>
              <tr>
                <SaveButtonTd>
                  <Button
                    onClick={onSaveNewStatus}
                    disabled={isSaving || !newEmployeeStatus}
                  >
                    <SaveButtonText>
                      {isSaving
                        ? 'SAVING ...'
                        : newEmployeeStatus
                        ? 'SAVE'
                        : ''}
                    </SaveButtonText>
                  </Button>
                </SaveButtonTd>
                <CancelButtonTd>
                  <Button onClick={onCloseDialog} disabled={isSaving}>
                    <CancelButtonText>
                      {isSaving ? '' : 'CANCEL'}
                    </CancelButtonText>
                  </Button>
                </CancelButtonTd>
              </tr>
            </tbody>
          </ButtonTable>
        </DialogContent>
      </Dialog>
    </>
  )
}

const EditButtonSpan = styled.span`
  cursor: pointer;
`

const DialogContent = styled.div`
  width: 500px;
`

const DialogTitle = styled.div`
  width: 100%;
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 40px 0 0 0;
  text-align: center;
`

const DialogSubTitle = styled.div`
  width: 100%;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 0 30px 0;
  text-align: center;
`

const SelectListDiv = styled.div`
  width: 100%;
  padding: 10px 80px;
`

const ListItemText = styled.span`
  font-family: Lexend-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  padding: 5px 0;
`

const ButtonTable = styled.table`
  width: 100%;
  td {
    padding: 20px 0 30px 0;
  }
`

const SaveButtonTd = styled.td`
  text-align: right;
  padding-right: 5px;
`

const CancelButtonTd = styled.td`
  text-align: left;
  padding-left: 5px;
`

const SaveButtonText = styled.div`
  width: 180px;
  padding: 6px 0;
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Expo};
`

const CancelButtonText = styled.div`
  width: 180px;
  padding: 6px 0;
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
`

export default Main
