import { useMemo } from 'react'

import { useVariables } from '../../../variables'
import { IApiType, LOADING } from '../../types'
import usePAndLLoadMore from './usePAndLLoadMore'

export const bbbPAndLConfigs = {
  pAndLName: 'string',
  pAndLSales: 'price',
  pAndLSalesPercent: 'percent',
  pAndLBudget: 'price',
  pAndLBudgetPercent: 'percent',
  pAndLCompare: 'price',
  pAndLComparePercent: 'percent',
  pAndLYoySales: 'price',
  pAndLYoySalesPercent: 'percent',
  pAndLYoyCompare: 'price',
  pAndLYoyComparePercent: 'percent',
  pAndLYtdSales: 'price',
  pAndLYtdSalesPercent: 'percent',
  pAndLYtdBudget: 'price',
  pAndLYtdBudgetPercent: 'percent',
  pAndLYtdCompare: 'price',
  pAndLYtdComparePercent: 'percent',
  pAndLYytdSales: 'price',
  pAndLYytdSalesPercent: 'percent',
  pAndLYytdCompare: 'price',
  pAndLYytdComparePercent: 'percent',
} as const

const calculateValue = (
  type: 'percent' | 'diff',
  a?: number | typeof LOADING,
  b?: number | typeof LOADING,
) => {
  if (a === LOADING || b === LOADING) return LOADING

  if (a === undefined || b === undefined) return

  switch (type) {
    case 'percent':
      if (b === 0) return 0

      return (a * 100) / b
    case 'diff':
      return a - b
  }
}

const useBbbPAndL = (): IApiType => {
  const { variables } = useVariables()
  const { data, loadMore } = usePAndLLoadMore()

  return {
    data: useMemo(() => {
      const getBudgetCode = variables.bbbPnl?.getBudgetCode
      const options = variables.bbbPnl?.metricCode.options

      if (!getBudgetCode || !options) return null

      const getValue = (id: string) =>
        data[id]?.isLoaded ? data[id]?.value : LOADING
      const totalSales = getValue('total_sales')
      const totalSalesBudget = getValue(getBudgetCode('total_sales'))
      const totalSalesCompare = calculateValue(
        'diff',
        totalSales,
        totalSalesBudget,
      )
      const yoyTotalSales = getValue('yoy_total_sales')
      const yoyTotalSalesCompare = calculateValue(
        'diff',
        totalSales,
        yoyTotalSales,
      )
      const ytdTotalSales = getValue('ytd_total_sales')
      const ytdTotalSalesBudget = getValue(
        `ytd_${getBudgetCode('total_sales')}`,
      )
      const ytdTotalSalesCompare = calculateValue(
        'diff',
        ytdTotalSales,
        ytdTotalSalesBudget,
      )
      const yytdTotalSales = getValue('yytd_total_sales')
      const yytdTotalSalesCompare = calculateValue(
        'diff',
        ytdTotalSales,
        yytdTotalSales,
      )

      return options.map((field) => {
        const dataId = field.dataId || field.id
        const budgetId = getBudgetCode(dataId)
        const pAndLSales = getValue(dataId)
        const pAndLBudget = getValue(budgetId)
        const pAndLCompare = calculateValue('diff', pAndLSales, pAndLBudget)
        const pAndLYoySales = getValue(`yoy_${dataId}`)
        const pAndLYoyCompare = calculateValue(
          'diff',
          pAndLSales,
          pAndLYoySales,
        )
        const pAndLYtdSales = getValue(`ytd_${dataId}`)
        const pAndLYtdBudget = getValue(`ytd_${budgetId}`)
        const pAndLYtdCompare = calculateValue(
          'diff',
          pAndLYtdSales,
          pAndLYtdBudget,
        )
        const pAndLYytdSales = getValue(`yytd_${dataId}`)
        const pAndLYytdCompare = calculateValue(
          'diff',
          pAndLYtdSales,
          pAndLYytdSales,
        )

        return {
          ...field,
          pAndLName: field.displayName,
          pAndLSales,
          pAndLSalesPercent: calculateValue('percent', pAndLSales, totalSales),
          pAndLBudget,
          pAndLBudgetPercent: calculateValue(
            'percent',
            pAndLBudget,
            totalSalesBudget,
          ),
          pAndLCompare,
          pAndLComparePercent: calculateValue(
            'percent',
            pAndLCompare,
            totalSalesCompare,
          ),
          pAndLYoySales,
          pAndLYoySalesPercent: calculateValue(
            'percent',
            pAndLYoySales,
            yoyTotalSales,
          ),
          pAndLYoyCompare,
          pAndLYoyComparePercent: calculateValue(
            'percent',
            pAndLYoyCompare,
            yoyTotalSalesCompare,
          ),
          pAndLYtdSales,
          pAndLYtdSalesPercent: calculateValue(
            'percent',
            pAndLYtdSales,
            ytdTotalSales,
          ),
          pAndLYtdBudget,
          pAndLYtdBudgetPercent: calculateValue(
            'percent',
            pAndLYtdBudget,
            ytdTotalSalesBudget,
          ),
          pAndLYtdCompare,
          pAndLYtdComparePercent: calculateValue(
            'percent',
            pAndLYtdCompare,
            ytdTotalSalesCompare,
          ),
          pAndLYytdSales,
          pAndLYytdSalesPercent: calculateValue(
            'percent',
            pAndLYytdSales,
            yytdTotalSales,
          ),
          pAndLYytdCompare,
          pAndLYytdComparePercent: calculateValue(
            'percent',
            pAndLYytdCompare,
            yytdTotalSalesCompare,
          ),
        }
      })
    }, [data, variables.bbbPnl]),
    loading: false,
    loadMore,
  }
}

export default useBbbPAndL
