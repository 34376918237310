import _ from 'lodash'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import navigator from 'pared/Routes/navigator'
import { track } from 'pared/analytics/exray'
import FeedbackLink from 'pared/components/FeedbackLink'
import { ILocationInfo } from 'pared/components/LocationInfo/hooks/useLocationInfo'
import LocationInfo from 'pared/components/LocationInfo/index'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import LocationSelector from 'pared/components/nav/LocationSelector'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'
import { getIsProductionBrand } from 'pared/customer/general'
import { IDateRange } from 'pared/data/getDateRanges'
import { findAndReplace } from 'pared/utils/string'

import HistoricalPerformance from './HistoricalPerformance'
import Scores from './Scores'
import useConfig from './hooks/useConfig'
import useExrayConfig from './hooks/useExrayConfig'
import { IExray, IScore } from './index'

interface IHealthCheckContentTd {
  warningLevel?: number
}

interface IProps {
  navParams: INavParams
  locationData: ILocationInfo | null
  errorMessage: string
  scorecardData: IScore[]
  exrayData: IExray
  selectedDateRange: IDateRange | null
  scorecardLoading: boolean
  shouldShowScorecard: boolean
  exrayLoading: boolean
}

const WebLargeMain = ({
  navParams,
  locationData,
  errorMessage,
  scorecardData,
  exrayData,
  selectedDateRange,
  scorecardLoading,
  shouldShowScorecard,
  exrayLoading,
}: IProps) => {
  const config = useConfig()
  const exrayConfig = useExrayConfig()

  if (errorMessage) {
    return (
      <PageStatusContainer>
        <PageStatusDiv>{errorMessage}</PageStatusDiv>
      </PageStatusContainer>
    )
  }

  if (!locationData?.id || !selectedDateRange) {
    return (
      <PageStatusContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </PageStatusContainer>
    )
  }

  const brandSettings = getBrandSettings()
  const businessLabels = brandSettings.labels.business
  const isQsr = brandSettings.isQsr

  const locationId = locationData.id
  const storeTitle = `${businessLabels.store}: ${locationData.displayName}`
  const storeName = locationData.name

  const isExrayMockUp = exrayConfig.isMockUp === true
  const healthChecks = exrayConfig.categories.map(
    ({ variableName, displayName, replacements, content, warningLevel }) => {
      const data = exrayData[variableName]
      return {
        title: displayName,
        content: isExrayMockUp
          ? content
          : findAndReplace(
              _.get(data, 'summary', 'No data available.'),
              replacements,
            ),
        warningLevel: isExrayMockUp
          ? warningLevel
          : _.get(data, 'warningLevel', 2),
      }
    },
  )

  if (isQsr) {
    healthChecks.push({
      title: 'Drive Thru',
      content: _.get(
        exrayData.speed_of_service,
        'summary',
        'No data available.',
      ),
      warningLevel: _.get(exrayData.speed_of_service, 'warningLevel', 2),
    })
  }

  const healthCheckRows = _.map(healthChecks, (c, index) => {
    const warningLevel = _.get(c, 'warningLevel', 1)
    const title = _.get(c, 'title', '')
    let target = navigator.storeDetail()
    switch (title) {
      case 'Salesmanship':
      case 'Drive Thru':
        target = navigator.salesmanship()
        break
      case 'Guest':
        target = navigator.guest()
        break
      case 'Delivery':
        target = navigator.delivery()
        break
      case 'Team':
        target = navigator.team()
        break
      case 'Inventory':
        target = navigator.inventory()
        break
      case 'Loss Prevention':
        target = navigator.lossPrevention()
        break
      default:
        target = navigator.storeDetail()
    }

    return (
      <tr key={index}>
        <HealthCheckTitleTd>{title}</HealthCheckTitleTd>
        <Link
          to={target}
          style={{ textDecoration: 'none' }}
          onClick={() => {
            track.categoryClicked(title)
          }}
        >
          <HealthCheckContentTd warningLevel={exrayLoading ? 2 : warningLevel}>
            {exrayLoading ? 'Loading...' : _.get(c, 'content', '')}{' '}
          </HealthCheckContentTd>
        </Link>
      </tr>
    )
  })

  const exraySection = (
    <>
      <ExrayContainer>
        <SubtitleContainer>
          <Subtitle>
            ex
            <ExrayTitleBlue>ray</ExrayTitleBlue>
          </Subtitle>
          {getIsProductionBrand(brandSettings.brand) ? (
            <FeedbackButton>
              <FeedbackLink color="rgb(0,0,238)" />
            </FeedbackButton>
          ) : null}
        </SubtitleContainer>
        <ExrayTable>{healthCheckRows}</ExrayTable>
      </ExrayContainer>
      <VerticalSpacer50px />
    </>
  )

  const scorecardSection = (
    <>
      <PageHeaderLineContainer>
        {config.isExrayVisible ? (
          <SelectorContainer>
            <DateRangeSelector navParams={navParams} />
          </SelectorContainer>
        ) : null}
        <PageLeftHeader>{config.scorecardTitle ?? 'Scorecard'}</PageLeftHeader>
      </PageHeaderLineContainer>
      <VerticalSpacer30px />
      {scorecardLoading ? (
        <LoadingContainer>
          <PageStatusDiv>Loading ...</PageStatusDiv>
        </LoadingContainer>
      ) : (
        <Scores
          data={scorecardData}
          dateRangeType={_.get(selectedDateRange, 'type', 'period')}
        />
      )}
    </>
  )

  return (
    <MainContainer>
      <SmallScreenStoreInfoContainer>
        <SmallScreenSelectorContainer>
          <LocationSelector navParams={navParams} />
          {config.isExrayVisible ? null : (
            <DateRangeSelector navParams={navParams} />
          )}
        </SmallScreenSelectorContainer>

        <SmallScreenStoreInfoTable>
          <tbody>
            <tr>
              <td>
                <SmallScreenStoreNameDiv>{storeTitle}</SmallScreenStoreNameDiv>
                <LocationInfo locationId={locationId} />
              </td>
            </tr>
          </tbody>
        </SmallScreenStoreInfoTable>
      </SmallScreenStoreInfoContainer>

      <LargeScreenStoreInfoTable>
        <tbody>
          <tr>
            <td>
              <PageHeaderLineContainer>
                <PageTopContainer>
                  <PageLeftHeader>{storeTitle}</PageLeftHeader>
                </PageTopContainer>
                <SelectorContainer>
                  <LocationSelectorContainer>
                    <LocationSelector navParams={navParams} />
                  </LocationSelectorContainer>
                  {config.isExrayVisible ? null : (
                    <DateRangeSelector navParams={navParams} />
                  )}
                </SelectorContainer>
              </PageHeaderLineContainer>
              <LocationInfo locationId={locationId} />
            </td>
          </tr>
        </tbody>
      </LargeScreenStoreInfoTable>

      <VerticalSpacer30px />
      {config.isExrayVisible ? exraySection : null}
      <BottomContainer>
        {shouldShowScorecard ? scorecardSection : null}
        <VerticalSpacer80px />
        <HistoricalPerformance
          navParams={navParams}
          selectedDateRange={selectedDateRange}
          storeName={storeName}
          shouldShowScorecard={shouldShowScorecard}
        />
      </BottomContainer>
    </MainContainer>
  )
}

export default WebLargeMain

const SmallScreenStoreInfoContainer = styled.div`
  display: none;
  @media ${MOBILE_WIDTH} {
    display: block;
  }
`

const SmallScreenSelectorContainer = styled.div`
  padding: 10px 25px 10px 25px;
  background-color: rgb(196, 196, 196);
`

const SmallScreenStoreInfoTable = styled.table`
  padding: 10px 25px;
  font-family: Lexend-Regular;
  color: ${COLORS.Chalkboard};
`

const SmallScreenStoreNameDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 10px 0;
`

const MainContainer = styled.div``

const LoadingContainer = styled.div`
  height: 400px;
  width: 100%;
`

const BottomContainer = styled.div`
  padding-left: 50px;
  padding-right: 50px;
`

const VerticalSpacer30px = styled.div`
  width: 100%;
  height: 30px;
`

const PageStatusContainer = styled.div`
  padding-left: 50px;
  padding-right: 50px;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const LargeScreenStoreInfoTable = styled.table`
  width: 100%;
  font-family: Lexend-Regular;
  color: ${COLORS.Chalkboard};
  padding-right: 50px;
  padding-left: 50px;

  @media ${MOBILE_WIDTH} {
    display: none;
  }
`

const PageHeaderLineContainer = styled.div`
  display: inline-block;
  width: 1250px;
`

const PageTopContainer = styled.div`
  float: left;
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0 0;
  float: left;
`

const LocationSelectorContainer = styled.div`
  float: left;
  margin-right: 7px;
`

const SelectorContainer = styled.div`
  float: right;
  display: inline-block;
`

const ExrayContainer = styled.div`
  background-color: ${COLORS.BackgroundBlue};
  padding-top: 25px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
`

const Subtitle = styled.text`
  font-family: Lexend-Regular;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 37.5px;
`

const ExrayTitleBlue = styled.text`
  color: ${COLORS.Expo};
`

const VerticalSpacer50px = styled.div`
  width: 100%;
  height: 50px;
`

const VerticalSpacer80px = styled.div`
  width: 100%;
  height: 80px;
`

const HealthCheckTitleTd = styled.div`
  display: table-cell;
  width: 270px;
  font-family: Lexend-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  vertical-align: text-top;
  padding-top: 10px;

  @media ${MOBILE_WIDTH} {
    display: block;
    padding-top: 15px;
  }
`

const HealthCheckContentTd = styled.td<IHealthCheckContentTd>`
  background-color: ${(props) =>
    props.warningLevel === 1
      ? COLORS.ExpoGray1
      : props.warningLevel === 2
      ? COLORS.ExpoGray2
      : props.warningLevel === 3
      ? COLORS.ExpoGray3
      : props.warningLevel === 4
      ? COLORS.ExpoRed2
      : COLORS.ExpoRed1};
  padding: 15px;
  color: ${(props) =>
    props.warningLevel === 2 || props.warningLevel === 4 ? 'black' : 'white'};
  font-family: Lexend-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  width: 1100px;
  &:hover {
    opacity: 0.8;
  }
`

const ExrayTable = styled.table`
  width: 1100px;
  font-family: Lexend-Regular;
  border-spacing: 0 10px;
  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }
  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }
  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

const SubtitleContainer = styled.div`
  display: flex;
  width: 1100px;
  justify-content: space-between;
`

const FeedbackButton = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`
