import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

import { CLICKABLE_BUTTON, LINK_BUTTON } from '../constants'
import getDisplayedString from '../getDisplayedString'
import useConfig from '../hooks/useConfig'

export interface ITableProps {
  data: any[][]
}

const TableContainer = styled.div`
  padding: 20px 0;
`

const TableWrapper = styled.table<{ columnCount?: number }>`
  border-collapse: collapse;
  width: 100%;
  ${(props) => {
    return `min-width: ${
      (!props.columnCount || props.columnCount < 3 ? 3 : props.columnCount) *
      200
    }px;`
  }}

  td {
    border-bottom: 1px solid #939393;
  }

  th,
  tr:last-child {
    border-bottom: 2px solid ${COLORS.Chalkboard};
  }
`

const TableHeader = styled.th`
  border-bottom: 1px solid ${COLORS.Chalkboard};
  padding: 0 20px 10px 20px;
  text-align: left;
  color: ${COLORS.Chalkboard};
  cursor: pointer;
  font-size: 14px;
  font-family: Lexend-Regular;
  font-style: normal;
  font-weight: 600;
`

const TableRow = styled.tr`
  :hover {
    background-color: #cfe5ff;
  }
`

const TableCell = styled.td`
  padding: 15px 20px;
  text-align: left;
  font-size: 14px;
  font-family: Lexend-Regular;
  font-style: normal;
  font-weight: 400;
`

const HorizontalCenteredRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CilckableTableCell = styled.td`
  padding: 15px 20px;
  text-align: left;
  font-size: 14px;
  font-family: Lexend-Regular;
  font-style: normal;
  font-weight: 400;
  color: #07a4c7;
  cursor: pointer;
`

const LinkButton = styled(Link)`
  color: #07a4c7;
`

const ShowMoreDiv = styled.div`
  padding: 10px 0 0 20px;
  text-align: left;
  font-size: 14px;
  font-style: italic;
  color: #07a4c7;
  cursor: pointer;
`

const DownloadLink = styled.div`
  padding: 15px 0 0 0;
  font-size: 12px;
  text-align: center;
`

const VerticalSpacer20px = styled.div`
  width: 100%;
  height: 20px;
`

const SortIcon = ({ sortDirection }: { sortDirection: 'asc' | 'desc' }) => (
  <span
    style={{
      fontSize: '10px',
      padding: '0 0 0 5px',
    }}
  >
    {sortDirection === 'asc' ? '▲' : '▼'}
  </span>
)

const TableHelper: React.FC<ITableProps> = ({ data }) => {
  const [sortColumn, setSortColumn] = useState<number | null>(null)
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
  const [storeDetailData, setStoreDetailData] = useState(null)
  const [expandTable, setExpandTable] = useState<boolean>(false)

  const config = useConfig()
  const directorDisplayString = config.chatBox.directorDisplayString

  const handleSort = (columnIndex: number) => {
    if (columnIndex === sortColumn) {
      // If already sorted by this column, reverse the sorting direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      // Sort by a new column in ascending order
      setSortColumn(columnIndex)
      setSortDirection('asc')
    }
  }

  if (!data || data.length === 0) {
    return <div>No data available.</div>
  }

  const tableHeaders = data[0]
  const tableRows = data.slice(1)

  const sortedTableRows = [...tableRows].sort((a, b) => {
    if (sortColumn === null) return 0 // If no column selected for sorting, maintain the original order

    let aValue = a[sortColumn]
    let bValue = b[sortColumn]
    if (aValue.type) {
      aValue = aValue.value
    }
    if (bValue.type) {
      bValue = bValue.value
    }

    if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1
    if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1
    return 0
  })

  const expandedSortedTableRows =
    !expandTable && sortedTableRows.length > 5
      ? sortedTableRows.slice(0, 5)
      : sortedTableRows

  return (
    <TableContainer>
      <TableWrapper columnCount={tableHeaders.length}>
        <thead>
          <tr>
            {tableHeaders.map((header, index) => (
              <TableHeader key={index} onClick={() => handleSort(index)}>
                <HorizontalCenteredRow>
                  {header === 'Director Name' ? directorDisplayString : header}
                  {sortColumn === index && (
                    <SortIcon sortDirection={sortDirection} />
                  )}
                </HorizontalCenteredRow>
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {expandedSortedTableRows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, cellIndex) => {
                switch (cell?.type) {
                  case CLICKABLE_BUTTON:
                    return (
                      <CilckableTableCell
                        key={cellIndex}
                        onClick={() => {
                          setStoreDetailData(cell.data)
                        }}
                      >
                        {getDisplayedString(cell.title)}
                      </CilckableTableCell>
                    )

                  case LINK_BUTTON:
                    return (
                      <TableCell key={cellIndex}>
                        <LinkButton to={cell.data}>{cell.title}</LinkButton>
                      </TableCell>
                    )

                  default:
                    return (
                      <TableCell key={cellIndex}>
                        {getDisplayedString(cell)}
                      </TableCell>
                    )
                }
              })}
            </TableRow>
          ))}
        </tbody>
      </TableWrapper>
      {sortedTableRows.length > 5 ? (
        <ShowMoreDiv
          onClick={() => {
            setExpandTable(!expandTable)
          }}
        >
          {expandTable ? 'Show Less' : '... Show More'}
        </ShowMoreDiv>
      ) : null}
      {/* <DownloadLink>
        <a href="#">Click to download data</a>
      </DownloadLink> */}
      {storeDetailData ? (
        <>
          <VerticalSpacer20px />
          <TableHelper data={storeDetailData} />
        </>
      ) : null}
    </TableContainer>
  )
}

export default TableHelper
