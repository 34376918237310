import useBurgerworksListLocationGroupLossPreventionKpis, {
  burgerworksListLocationGroupLossPreventionKpisConfigs,
} from './useListLocationGroupLossPreventionKpis'
import useBurgerworksListLocationLossPreventionKpis, {
  burgerworksListLocationLossPreventionKpisConfigs,
} from './useListLocationLossPreventionKpis'
import useBurgerworksListSystemwideOverviewKpis, {
  burgerworksListSystemwideOverviewKpisConfigs,
} from './useListSystemwideOverviewKpisKpis'

export const configs = {
  burgerworksListLocationGroupLossPreventionKpis:
    burgerworksListLocationGroupLossPreventionKpisConfigs,
  burgerworksListLocationLossPreventionKpis:
    burgerworksListLocationLossPreventionKpisConfigs,
  burgerworksListSystemwideOverviewKpis:
    burgerworksListSystemwideOverviewKpisConfigs,
}

export const api = {
  burgerworksListLocationGroupLossPreventionKpis:
    useBurgerworksListLocationGroupLossPreventionKpis,
  burgerworksListLocationLossPreventionKpis:
    useBurgerworksListLocationLossPreventionKpis,
  burgerworksListSystemwideOverviewKpis:
    useBurgerworksListSystemwideOverviewKpis,
}
