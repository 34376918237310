import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useAsync } from 'react-use'
import { store } from 'state-pool'

import { track } from 'pared/analytics/corporateDelivery'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import getDirectors, { IDirector } from 'pared/data/getDirectors'
import useConfig from 'pared/pages/StoreList/DirectorFilterSelector/hooks/useConfig'
import { getBrand } from 'pared/utils/brand'

// @ts-ignore FIXME: should fix type
const defaultDirector: IDirector = {
  employeeId: -1,
  firstName: 'All',
  lastName: 'Stores',
}

const useGetDirectors = () => {
  const config = useConfig()
  const brand = _.get(store, 'value.brand', '')
  const state = useAsync(getDirectors, [brand])
  const directors = useMemo(
    () => (state.loading || !state.value ? [] : state.value),
    [state],
  )
  const [director, setDirector] = useState<IDirector>(defaultDirector)
  const directorChange = useCallback(
    (directorEmployeeId: number) => {
      track.directorChanged(directorEmployeeId)

      if (directorEmployeeId <= -1) {
        setDirector({
          ...defaultDirector,
          employeeId: directorEmployeeId,
        })
      } else {
        const selectedDirector = directors?.find(
          ({ employeeId }) => employeeId === directorEmployeeId,
        )

        setDirector(selectedDirector || defaultDirector)
      }
    },
    [directors],
  )
  const locationGroupIds = useMemo(() => {
    const brand = getBrand()

    switch (director?.employeeId || -1) {
      case -1:
        return [BRAND_LOCATION_GROUP_ID[brand]]
      case -2:
        return directors.map(({ locationGroupId }) => locationGroupId)
      default:
        return [director?.locationGroupId || -1]
    }
  }, [director, directors])

  const locationGroupTypes = useMemo(() => {
    const locationGroupType = config.locationGroupTypes.find(
      ({ id }: { id: number }) => id === director.employeeId,
    )
    if (locationGroupType) {
      return [locationGroupType.name]
    }

    return null
  }, [director])

  return {
    directors,
    director,
    directorChange,
    locationGroupIds,
    locationGroupTypes,
  }
}

export default useGetDirectors
