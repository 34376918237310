import _ from 'lodash'
import React, { useState } from 'react'
import styled from 'styled-components'

import Print from 'pared/components/basicUi/Print'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import Table from './Table'

export interface ILeaderboardData {
  rank?: number
  value: number
  employeeId: number | string
  employeeName: string
  displayString: string
  isStoreAvg: boolean
}

interface IProps {
  leaderboardTitle: string
  dataColumnHeader: string
  isExpanded: boolean
  storeName: string
  leaderboardData: ILeaderboardData[] | null
}

const MainContainer = styled.div`
  margin: 0px;
  padding: 0px;
  width: 100%;
`

const TableTitleContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  gap: 15px;
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-weight: 700;
`

const Header = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-weight: 700;
`

const PrintTableHeader = styled.div`
  margin: 30px 0px 10px;
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-weight: 700;
`

const PrintContinaer = styled.div`
  max-width: 500px;
`

const ExpandAllSpan = styled.div`
  display: none;
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;

  @media ${MOBILE_WIDTH} {
    display: block;
  }
`

const LeaderboardTable = ({
  leaderboardTitle,
  dataColumnHeader,
  isExpanded: propsIsExpanded,
  storeName,
  leaderboardData,
}: IProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const visibleData =
    propsIsExpanded || isExpanded
      ? leaderboardData
      : _.slice(leaderboardData, 0, 5)

  return (
    <MainContainer>
      <TableTitleContainer>
        {leaderboardTitle}

        <Print>
          <Header>Store: {storeName}</Header>

          <PrintTableHeader>{leaderboardTitle}</PrintTableHeader>

          <PrintContinaer>
            <Table dataSource={leaderboardData} title={dataColumnHeader} />
          </PrintContinaer>
        </Print>
      </TableTitleContainer>

      <Table dataSource={visibleData} title={dataColumnHeader} />

      <ExpandAllSpan onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? 'Collapse All' : 'Expand All'}
      </ExpandAllSpan>
    </MainContainer>
  )
}

export default React.memo(LeaderboardTable)
