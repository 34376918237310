import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import useStoreList from '../../hooks/useStorelist'
import { LIST_LOCATION_MARKET_ANALYSIS_DETAILS } from '../gql'
import { ALL } from './useDiscountTypeItems'

const useLocationDiscounts = (
  selectedDiscount?: string[],
  locationIds?: number[],
  locationGroupIds?: number[],
  startDate?: string,
  endDate?: string,
) => {
  let iFilter: any = null
  if (locationIds && locationIds.length > 0) {
    iFilter = { location_ids: [...locationIds] }
  } else if (locationGroupIds && locationGroupIds.length > 0) {
    iFilter = { location_group_ids: [...locationGroupIds] }
  }

  const { storeList, storeListLoading } = useStoreList()
  const { data: discountsData, loading: discountsDataLoading } = useQuery(
    LIST_LOCATION_MARKET_ANALYSIS_DETAILS,
    {
      variables: {
        iFilter,
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !startDate || !endDate || !iFilter,
    },
  )

  const discountLocationLoading = discountsDataLoading || storeListLoading

  let compsDetailType: string[] = []

  const discountLocationData = useMemo(() => {
    const rawData =
      discountsData?.listLocationMarketAnalysisDetails?.nodes || []

    let totalDetails: {
      [compsType: string]: [number, number, number]
    } = {}

    totalDetails['totalDiscounts'] = [0, 0, 0]

    const tableData = rawData.map((row: any) => {
      const storeId = row.locationInfo.id
      const storeCode = row.locationInfo.code
      const storeName = row.locationInfo.name

      const store = storeList?.listLocationDetails?.nodes.find(
        ({ id }: any) => storeId === id,
      )

      let totalDiscounts: [string, string, string] = ['-', '-', '-']

      if (row.totalCompsCount || row.totalCompsCount === 0) {
        totalDiscounts[0] = row.totalCompsCount
      }

      if (row.totalComps || row.totalComps === 0) {
        totalDiscounts[2] = (row.totalComps / 100.0).toFixed(0)
        totalDiscounts[1] = (row.totalComps / 100.0).toFixed(0)
      }

      const itemDetails: {
        [compsType: string]: [string, string, string]
      } = {}

      row?.compsItemDetails?.forEach((itemData: any) => {
        let count = '-'
        let amount = '-'
        if (itemData?.count || itemData.count === 0) {
          count = itemData.count
        }
        if (itemData?.totalComps || itemData.totalComps === 0) {
          amount = (itemData.totalComps / 100.0).toFixed(0)
        }

        // filter logic
        if (!selectedDiscount || selectedDiscount[0] === ALL) {
          if (!totalDetails[itemData.type]) {
            totalDetails[itemData.type] = [
              (count = itemData.count || 0),
              itemData.totalComps / 100,
              itemData.totalComps / 100,
            ]
          } else {
            totalDetails[itemData.type][0] += itemData.count || 0
            totalDetails[itemData.type][1] += itemData.totalComps / 100 || 0
            totalDetails[itemData.type][2] += itemData.totalComps / 100 || 0
          }

          itemDetails[itemData.type] = [count, amount, amount]
        } else if (selectedDiscount[1] === ALL) {
          if (itemData.category === selectedDiscount[0]) {
            if (!totalDetails[itemData.type]) {
              totalDetails[itemData.type] = [
                (count = itemData.count || 0),
                itemData.totalComps / 100,
                itemData.totalComps / 100,
              ]
            } else {
              totalDetails[itemData.type][0] += itemData.count || 0
              totalDetails[itemData.type][1] += itemData.totalComps / 100 || 0
              totalDetails[itemData.type][2] += itemData.totalComps / 100 || 0
            }

            itemDetails[itemData.type] = [count, amount, amount]
          }
        } else {
          if (itemData.type === selectedDiscount[1]) {
            if (!totalDetails[itemData.type]) {
              totalDetails[itemData.type] = [
                (count = itemData.count || 0),
                itemData.totalComps / 100,
                itemData.totalComps / 100,
              ]
            } else {
              totalDetails[itemData.type][0] += itemData.count || 0
              totalDetails[itemData.type][1] += itemData.totalComps / 100 || 0
              totalDetails[itemData.type][2] += itemData.totalComps / 100 || 0
            }

            itemDetails[itemData.type] = [count, amount, amount]
          }
        }

        if (!compsDetailType.includes(itemData.type))
          compsDetailType.push(itemData.type)
      })

      itemDetails['total'] = [
        row.totalCompsCount,
        row.totalComps,
        row.totalComps,
      ]

      totalDetails['totalDiscounts'][0] += row.totalCompsCount || 0
      totalDetails['totalDiscounts'][1] += row.totalComps / 100 || 0
      totalDetails['totalDiscounts'][2] += row.totalComps / 100 || 0

      return {
        ...itemDetails,
        storeId,
        store: `${storeCode} - ${storeName}`,
        directorId: store?.directorEmployeeInfo?.id || 0,
        directorName: [
          store?.directorEmployeeInfo?.preferredName || '',
          store?.directorEmployeeInfo?.familyName || '',
        ]
          .filter(Boolean)
          .join(' '),
        totalDiscounts,
      }
    })

    if (tableData?.length > 1) {
      compsDetailType?.forEach((type: string) => {
        tableData.sort((a: any, b: any) => {
          if (a[type] === undefined) return 1
          if (b[type] === undefined) return -1
          return b[type][1] - a[type][1]
        })
      })
    }

    return [
      ...tableData,
      {
        ...totalDetails,
        storeId: 99999,
        store: 'Total',
        directorId: 0,
        directorName: 'All',
      },
    ]
  }, [discountsData, selectedDiscount, locationGroupIds, startDate, endDate])

  return { discountLocationData, discountLocationLoading }
}

export default useLocationDiscounts
