import React from 'react'

import CustomizedReportRender from './CustomizedReportRender'
import useFetchCustomizedReportData from './hooks/useFetchCustomizedReportData'
import { IInputParams, IReportDefinition, IReportResult } from './types'

interface IProps {
  reportName: string
  inputParams: IInputParams
  title?: string
  customizedLoadingStatus?: React.ReactNode
  breakdownTitle?: string
  reportDataModifier?: (
    reportDefinition?: IReportDefinition,
    reportResult?: IReportResult,
  ) => {
    modifiedReportDefinition?: IReportDefinition
    modifiedReportResult?: IReportResult
  }
  csvFileName?: string
  rowKey?: ((values: any) => string) | string
  selectedFilter?: { [key: string]: string }
}

const CustomizedReport = ({
  reportName,
  inputParams,
  title,
  customizedLoadingStatus,
  breakdownTitle,
  reportDataModifier,
  csvFileName,
  rowKey,
  selectedFilter,
}: IProps) => {
  const { reportDefinition, reportResult, isLoading } =
    useFetchCustomizedReportData(reportName, inputParams)

  let finalReportDefinition = reportDefinition
  let finalReportResult = reportResult
  if (reportDataModifier) {
    const modifiedData = reportDataModifier(reportDefinition, reportResult)
    if (modifiedData?.modifiedReportDefinition) {
      finalReportDefinition = modifiedData.modifiedReportDefinition
    }
    if (modifiedData?.modifiedReportResult) {
      finalReportResult = modifiedData.modifiedReportResult
    }
  }

  return (
    <CustomizedReportRender
      reportDefinition={finalReportDefinition}
      reportResult={finalReportResult}
      isLoading={isLoading}
      title={title}
      customizedLoadingStatus={customizedLoadingStatus}
      breakdownTitle={breakdownTitle}
      csvFileName={csvFileName}
      rowKey={rowKey}
      selectedFilter={selectedFilter}
    />
  )
}

export default React.memo(CustomizedReport)

export type { IInputParams, IReportDefinition, IReportResult }

export { useFetchCustomizedReportData, CustomizedReportRender }
