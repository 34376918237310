import { gql } from '@apollo/client'

export const TREND_LOCATION_SALESMANSHIP_KPIS = gql`
  query TrendLocationSalesmanshipKpis(
    $iLocationId: Int!
    $iEndDate: Date!
    $iGroupBy: String!
    $iDateRangeNumber: Int!
  ) {
    trendLocationSalesmanshipKpis: trendLocationSalesmanshipKpisV2E1(
      iLocationId: $iLocationId
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iDateRangeNumber: $iDateRangeNumber
    ) {
      nodes {
        locationId
        startDate
        endDate
        categorizedSalesDetails
        totalSalesDetails
        businessWeek
        businessMonth
        businessQuarter
        businessYear
      }
    }
  }
`
