import useBbbAllPeriodMetric, {
  bbbAllPeriodMetricConfigs,
} from './useAllPeriodMetric'
import useIntradaySales, { intradaySalesConfigs } from './useIntradaySales'
import {
  bbbFoodAndPaperConfigs,
  bbbProfitAfterControllablesConfigs,
  useBbbFoodAndPaper,
  useBbbProfitAfterControllables,
} from './useMetricValues'
import useBbbTrendPeriodMetric, {
  bbbTrendPeriodMetricConfigs,
} from './useTrendPeriodMetric'
import useBbbWeeklySalesConfigs, {
  bbbWeeklySalesConfigs,
} from './useWeeklySales'

export const configs = {
  bbbWeeklySales: bbbWeeklySalesConfigs,
  bbbIntradaySales: intradaySalesConfigs,
  bbbFoodAndPaper: bbbFoodAndPaperConfigs,
  bbbProfitAfterControllables: bbbProfitAfterControllablesConfigs,
  bbbTrendPeriodMetric: bbbTrendPeriodMetricConfigs,
  bbbAllPeriodMetric: bbbAllPeriodMetricConfigs,
}

export const api = {
  bbbWeeklySales: useBbbWeeklySalesConfigs,
  bbbIntradaySales: useIntradaySales,
  bbbFoodAndPaper: useBbbFoodAndPaper,
  bbbProfitAfterControllables: useBbbProfitAfterControllables,
  bbbTrendPeriodMetric: useBbbTrendPeriodMetric,
  bbbAllPeriodMetric: useBbbAllPeriodMetric,
}
