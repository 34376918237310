import { BubbleController, Element } from 'chart.js'

import { ICustomChart } from './plugins/labelsButton'

interface IDatasetType {
  label: string
}

type IMetaType = Element<
  {
    x: number
    y: number
  },
  {
    radius: number
    backgroundColor: string
  }
>

class BubbleWithLabels extends BubbleController {
  draw = () => {
    super.draw()

    // FIXME: chart.js couldn't customize the data type
    const chart = this.chart as unknown as ICustomChart

    // NOTE: labelsButton come from labelsButton plguin. Adding this one avoids the user not using this plugin.
    if (chart.labelsButton?.hideLabels()) return

    const dataset = this.getDataset().data as unknown as IDatasetType[]
    const data = this.getMeta().data as unknown as IMetaType[]
    const { ctx, chartArea } = chart

    ctx.save()
    data.forEach((d, index) => {
      const { x, y } = d.getProps(['x', 'y'])
      const { radius, backgroundColor } = d.options
      const { label } = dataset[index]
      const { width: textWidth } = ctx.measureText(label)

      if (
        x < chartArea.left - radius ||
        x > chartArea.right + radius ||
        y < chartArea.top - radius
      )
        return

      const textX = (() => {
        if (x - textWidth / 2 < chartArea.left + 3) return chartArea.left + 3

        if (x + textWidth / 2 > chartArea.right - 3)
          return chartArea.right - textWidth - 3

        return x - textWidth / 2
      })()
      const textY =
        y - radius - 10 < chartArea.top ? y + radius + 10 : y - radius - 10

      ctx.fillStyle = backgroundColor
      ctx.fillText(label, textX, textY)
    })
    ctx.restore()
  }
}

BubbleWithLabels.id = 'bubbleWithLabels'
BubbleWithLabels.defaults = BubbleWithLabels.defaults

export default BubbleWithLabels
