import { gql, useMutation } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'

import { useReset } from '../../../ResetProvider'

export interface IDeleteTrackerModalType {
  trackerUuid: string
}

const DELETE_TRAINING_TRACKER = gql`
  mutation DeleteEmployeeTrainingTracker($iListUuid: String!) {
    deleteEmployeeTrainingTracker(input: { iListUuid: $iListUuid }) {
      clientMutationId
    }
  }
`

const useDeleteTrackerModal = (
  data: IDeleteTrackerModalType,
  onClose: () => void,
) => {
  const { trackerUuid } = data
  const [deleteTracker] = useMutation(DELETE_TRAINING_TRACKER)
  const reset = useReset()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const submit = useCallback(async () => {
    setIsSubmitting(true)
    setIsLoading(true)

    await deleteTracker({
      variables: {
        iListUuid: trackerUuid,
      },
    })
    reset()
  }, [reset])

  useEffect(() => {
    if (isLoading) onClose()
  }, [data])

  return {
    submit,
    isLoading,
    isSubmitting,
  }
}

export default useDeleteTrackerModal
