import { getBrand } from 'pared/utils/brand'

import timoney_bk from '../timoney_bk'
import viking_bk from '../viking_bk'

const useConfig = () => {
  const brand = getBrand()

  switch (brand) {
    case 'timoney_bk':
      return timoney_bk
    case 'viking_bk':
      return viking_bk

    default:
      return () => {
        return <></>
      }
  }
}

export default useConfig
