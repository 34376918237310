import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'

import { track } from 'pared/analytics/user'
import { getUser } from 'pared/utils/user'

import Main from './Main'

const MUTATION_CHANGE_PASSWORD = gql`
  mutation ChangePassword($userId: Int!, $newPassword: String!) {
    updatePassword(input: { iUserId: $userId, iNewPassword: $newPassword }) {
      boolean
    }
  }
`

export interface IChangePasswordParams {
  newPassword: string
  repeatedPassword: string
}

function ChangePassword() {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [changePassword, { loading, error }] = useMutation(
    MUTATION_CHANGE_PASSWORD,
  )

  const onChangePassword = async (
    changePasswordParams: IChangePasswordParams,
  ) => {
    const inputtedNewPassword = changePasswordParams.newPassword || ''
    const trimmedNewPassword = inputtedNewPassword.trim()

    if (trimmedNewPassword.length < 8) {
      setErrorMessage(
        'Invalid password. The length of the password must be at least 8.',
      )
      return false
    }

    if (trimmedNewPassword.length !== inputtedNewPassword.length) {
      setErrorMessage('Invalid password. White spaces are not allowed.')
      return false
    }

    if (trimmedNewPassword !== changePasswordParams.repeatedPassword) {
      setErrorMessage('The confirmed password is different with the password.')
      return false
    }

    setErrorMessage('')

    const user = getUser()

    try {
      const response = await changePassword({
        variables: {
          userId: user.userId,
          newPassword: trimmedNewPassword,
        },
      })

      if (
        response &&
        response.data &&
        response.data.updatePassword &&
        response.data.updatePassword.boolean
      ) {
        track.passwordChanged()
      } else {
        passwordChangeErrorMessage =
          'Unexpected internal error, please try again later.'
      }
    } catch (error) {
      return false
    }

    return true
  }

  let passwordChangeErrorMessage: string = ''
  if (error && error.message) {
    passwordChangeErrorMessage =
      'Unexpected internal error, please try again later.'
  }

  return (
    <Main
      isLoading={loading}
      onChangePassword={onChangePassword}
      errorMessage={passwordChangeErrorMessage || errorMessage}
    />
  )
}

export default ChangePassword
