import _ from 'lodash'
import { useState } from 'react'

import { ILocation } from 'pared/data/getLocations'
import { getUser } from 'pared/utils/user'

import Main from './Main'

interface IProps {
  allLocations: ILocation[] | null
  roleName: string
  timeVacant: string
  isIcon?: boolean
  onRefresh: () => Promise<void>
  positionId: number
  candidateName?: string
  comment?: string
  currentTrainingStoreId?: number
  currentFormattedPendingStartDate?: string
}

const PositionSpan = ({
  allLocations,
  roleName,
  timeVacant,
  isIcon,
  onRefresh,
  positionId,
  candidateName,
  comment,
  currentTrainingStoreId,
  currentFormattedPendingStartDate,
}: IProps) => {
  const [positionAnchorEl, setPositionAnchorEl] = useState(null)

  const user = getUser()
  const userEmployeeId = _.get(user, 'employeeId') || null
  const hasEditPermission = !!userEmployeeId

  return (
    <Main
      positionAnchorEl={positionAnchorEl}
      setPositionAnchorEl={setPositionAnchorEl}
      allLocations={allLocations}
      roleName={roleName}
      timeVacant={timeVacant}
      isIcon={isIcon}
      onRefresh={onRefresh}
      positionId={positionId}
      candidateName={candidateName}
      comment={comment}
      currentTrainingStoreId={currentTrainingStoreId}
      currentFormattedPendingStartDate={currentFormattedPendingStartDate}
      hasEditPermission={hasEditPermission}
    />
  )
}

export default PositionSpan
