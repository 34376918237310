import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { getUser } from 'pared/utils/user'

import { useAdvancedFilter } from '../../advancedFilter'
import { IApiDataType } from '../types'

const queries = gql`
  query ListLocationDevelopmentPlan($iEmployeeId: Int!) {
    listDirectorDevelopmentPlan(iEmployeeId: $iEmployeeId) {
      nodes {
        planId
        employeeId
        jobTitle
        employeeFirstName
        employeeLastName
        actionItem
        dueDate
        isCompleted
        deletedAt
      }
    }
  }
`

export const jscCorporateDevelopmentPlanConfigs = {
  planId: 'string',
  role: 'string',
  name: 'string',
  actionItem: 'string',
  dueDate: 'date',
  isCompleted: 'toggle',
  delete: 'delete-development-plan',
} as const

export const useJscCorporateDevelopmentPlanData = () => {
  const user = getUser()
  const userId = user.employeeId ? user.employeeId : -1

  return useQuery(queries, {
    variables: {
      iEmployeeId: userId,
    },
    skip: !user,
  })
}

const useJscCorporateDevelopmentPlan = () => {
  const advancedFilter = useAdvancedFilter('select')
  const { data, loading } = useJscCorporateDevelopmentPlanData()

  return {
    data: useMemo((): IApiDataType => {
      let plans = data?.listDirectorDevelopmentPlan.nodes

      if (!plans) return null

      plans = plans.filter((plan: any) => !plan.deletedAt)

      if (advancedFilter?.value.plan === 'COMPLETED') {
        plans = data?.listDirectorDevelopmentPlan.nodes.filter(
          (plan: any) => plan.isCompleted,
        )
      } else if (advancedFilter?.value.plan === 'OUTSTANDING') {
        plans = data?.listDirectorDevelopmentPlan.nodes.filter(
          (plan: any) => !plan.isCompleted,
        )
      }

      if (advancedFilter?.value.role !== 'ALL')
        plans = plans?.filter(
          (plan: any) => plan.jobTitle === advancedFilter?.value.role,
        )

      return {
        source: plans.map((item: any) => {
          const employeeName = `${item.employeeFirstName} ${item.employeeLastName}`

          return {
            planId: item.planId,
            role: item.jobTitle,
            name: employeeName,
            actionItem: item.actionItem,
            dueDate: item.dueDate,
            isCompleted: {
              checked: item.isCompleted,
            },
            delete: {
              planId: item.planId,
              name: employeeName,
              actionItem: item.actionItem,
            },
          }
        }),
      }
    }, [data, advancedFilter]),
    loading,
  }
}

export default useJscCorporateDevelopmentPlan
