import { INavParams } from 'pared/Routes/navParams'
import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import { IDateRange } from 'pared/data/getDateRanges'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import { ILocationData, ILocationDeliveryKpi } from './index'

interface IProps {
  navParams: INavParams
  locationDeliveryKpiSummary: ILocationDeliveryKpi
  selectedDateRange: IDateRange | null
  locationId: number
  onSelectDayTrendsDayPart: (event: any) => void
  dayTrendsDayPart: string | null
  dayTrendsInfo: any[]
  onSelectDayTrendsCategory: (event: any) => void
  dayTrendsCategory: string
  selectedLocation: ILocationData
}

function Main({
  navParams,
  locationDeliveryKpiSummary,
  selectedDateRange,
  locationId,
  onSelectDayTrendsDayPart,
  dayTrendsDayPart,
  dayTrendsInfo,
  onSelectDayTrendsCategory,
  dayTrendsCategory,
  selectedLocation,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          navParams={navParams}
          locationDeliveryKpiSummary={locationDeliveryKpiSummary}
          selectedDateRange={selectedDateRange}
          locationId={locationId}
          onSelectDayTrendsDayPart={onSelectDayTrendsDayPart}
          dayTrendsDayPart={dayTrendsDayPart}
          dayTrendsInfo={dayTrendsInfo}
          onSelectDayTrendsCategory={onSelectDayTrendsCategory}
          dayTrendsCategory={dayTrendsCategory}
          selectedLocation={selectedLocation}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          navParams={navParams}
          locationDeliveryKpiSummary={locationDeliveryKpiSummary}
          selectedDateRange={selectedDateRange}
          locationId={locationId}
          onSelectDayTrendsDayPart={onSelectDayTrendsDayPart}
          dayTrendsDayPart={dayTrendsDayPart}
          dayTrendsInfo={dayTrendsInfo}
          onSelectDayTrendsCategory={onSelectDayTrendsCategory}
          dayTrendsCategory={dayTrendsCategory}
          selectedLocation={selectedLocation}
        />
      </SmallScreen>
    </>
  )
}

export default Main
