import { gql } from '@apollo/client'

export const GET_LOCATION_DATA = gql`
  query GetLocationInfo($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        brandId
        directorId
        directorEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        gmEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
      }
    }
  }
`

export const GET_LOCATION_SCORECARD = gql`
  query GetLocationScorecard(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    getLocationScorecard(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        name
        value
        weight
        average
        score
        rank
        percentileRank
        totalScore
        locationCount
        overallRank
        overallPercentileRank
      }
    }
  }
`

export const GET_LOCATION_KPI_TREND = gql`
  query GetLocationKpiTrend(
    $iKpi: String!
    $iLocationId: Int!
    $iEndDate: Date!
    $iGroupBy: String!
  ) {
    getLocationKpiTrend(
      iKpi: $iKpi
      iLocationId: $iLocationId
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) {
      nodes {
        locationId
        startDate
        endDate
        name
        value
        average
        locationCount
        rank
        percentileRank
        groupingIndex
      }
    }
  }
`

export const GET_LOCATION_EXRAY = gql`
  query GetLocationExray($iLocationId: Int!) {
    getLocationExray(iLocationId: $iLocationId) {
      nodes {
        locationId
        startDate
        endDate
        category
        warningLevel
        summary
      }
    }
  }
`

export const CHECK_IF_LOCATION_NSO = gql`
  query CheckIfLocationNso($iLocationId: Int!) {
    checkIfLocationNso(iLocationId: $iLocationId) {
      nodes
    }
  }
`
