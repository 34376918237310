import { IConfigsType } from '../../types'

const acePeriodPurchase: IConfigsType = {
  ace_pop: {
    '/:brand/period_flash': {
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: ['period'],
        defaultType: 'period',
      },
      periodFlashTitle: {
        type: 'title',
        title: 'Period Flash Report',
      },
      downloadAllTables: {
        type: 'download-csv',
        api: 'acePopPeriodPurchase',
        fileName: 'getexpo-ace-pop-period-flash',
        fileNameFn: (date: string) => `getexpo-ace-pop-period-purchase-${date}`,
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'beginningInventory',
            title: 'Beginning Inventory',
          },
          {
            key: 'purchaseAmount',
            title: 'Purchases',
          },
          {
            key: 'endingInventory',
            title: 'Ending Inventory',
          },
          {
            key: 'netSales',
            title: 'Net Sales',
          },
          {
            key: 'foodAndPurchaseCostPercentage',
            title: 'F & P Cost',
          },
          {
            key: 'hourlyLaborCost',
            title: 'Labor',
          },
          {
            key: 'hourlyLaborCostPercentage',
            title: 'Labor %',
          },
        ],
      },
      periodFlashTable: {
        type: 'table',
        api: 'acePopPeriodPurchase',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        columns: [
          {
            key: 'beginningInventory',
            title: 'Beginning Inventory',
            sortable: true,
            align: 'center',
            highlight: true,
          },
          {
            key: 'purchaseAmount',
            title: 'Purchases',
            sortable: true,
            align: 'center',
          },
          {
            key: 'endingInventory',
            title: 'Ending Inventory',
            sortable: true,
            align: 'center',
            highlight: true,
          },
          {
            key: 'netSales',
            title: 'Net Sales',
            sortable: true,
            align: 'center',
          },
          {
            key: 'foodAndPurchaseCostPercentage',
            title: 'F & P Cost',
            sortable: true,
            align: 'center',
            highlight: true,
          },
          {
            key: 'hourlyLaborCost',
            title: 'Labor',
            sortable: true,
            align: 'center',
          },
          {
            key: 'hourlyLaborCostPercentage',
            title: 'Labor %',
            sortable: true,
            align: 'center',
            highlight: true,
          },
        ],
      },
    },
  },
}

export default acePeriodPurchase
