import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { track } from 'pared/analytics/salesmanship'
import { FILTER_TYPE, NUM_DAYS_WORKED } from 'pared/constants'
import { IPeriod } from 'pared/data/getPeriods'
import getPeriods from 'pared/data/getPeriods'

import Main from './Main'

interface IProps {
  filterType: string
  setFilterType: any
  numDaysWorked: number
  setNumDaysWorked: any
  selectedPeriod: IPeriod | null
  setSelectedPeriod: any
  dateRangeStartDate: any
  setDateRangeStartDate: any
  dateRangeEndDate: any
  setDateRangeEndDate: any
  selectedValue: string
  setSelectedValue: (selectedValue: string) => void
}

const DATE_DISPLAY_FORMAT = 'M/D/YY'

const TimeframeDropdown = ({
  filterType,
  setFilterType,
  numDaysWorked,
  setNumDaysWorked,
  selectedPeriod,
  setSelectedPeriod,
  dateRangeStartDate,
  setDateRangeStartDate,
  dateRangeEndDate,
  setDateRangeEndDate,
  selectedValue,
  setSelectedValue,
}: IProps) => {
  const [canDropdownOpen, setCanDropdownOpen] = useState<boolean>(true)
  const [isSelectingEndDate, setIsSelectingEndDate] = useState<boolean>(false)
  const [allPeriods, setAllPeriods] = useState<IPeriod[] | null>([])
  const [
    isSmallScreenDateRangeDiaglogOpen,
    setIsSmallScreenDateRangeDiaglogOpen,
  ] = useState<boolean>(false)

  useEffect(() => {
    async function fetchPeriods() {
      const periodData = await getPeriods()
      const periods = periodData.periods

      if (Array.isArray(periods) && periods.length > 0) {
        const last18Periods = _.slice(periods, 0, 18)
        setAllPeriods(last18Periods)
      } else {
        setAllPeriods(null)
      }
    }

    fetchPeriods()

    switch (filterType) {
      case FILTER_TYPE.SHIFTS: {
        setSelectedValue(`Last ${numDaysWorked} Shifts`)
        break
      }
      case FILTER_TYPE.PERIOD: {
        const period = _.get(selectedPeriod, 'period', 1)
        const periodStart = _.get(selectedPeriod, 'periodStart', '') || moment()
        const formattedPeriodStart = periodStart.format(DATE_DISPLAY_FORMAT)
        const periodEnd = _.get(selectedPeriod, 'periodEnd', '') || moment()
        const formattedPeriodEnd = periodEnd.format(DATE_DISPLAY_FORMAT)

        setSelectedValue(
          `P${period} (${formattedPeriodStart} to ${formattedPeriodEnd})`,
        )
        break
      }
      case FILTER_TYPE.DATE: {
        const formattedStartDate =
          moment(dateRangeStartDate).format(DATE_DISPLAY_FORMAT)
        const formattedEndDate =
          moment(dateRangeEndDate).format(DATE_DISPLAY_FORMAT)

        setSelectedValue(`${formattedStartDate} to ${formattedEndDate}`)
        break
      }
      default: {
      }
    }
  }, [numDaysWorked, filterType, selectedPeriod])

  useEffect(() => {
    async function fetchPeriods() {
      const periodData = await getPeriods()
      const periods = periodData.periods

      const latestPeriod = _.first(periods)
      if (_.isEmpty(selectedPeriod)) {
        setSelectedPeriod(latestPeriod)
      }
    }

    fetchPeriods()
  }, [])

  const onClickShifts = (event: any) => {
    const numDaysWorked = parseInt(
      _.get(event, 'currentTarget.dataset.value', 30),
    )
    setCanDropdownOpen(false)
    setNumDaysWorked(numDaysWorked)
    setFilterType(FILTER_TYPE.SHIFTS)
    track.timeFrameChanged(FILTER_TYPE.SHIFTS, numDaysWorked)
  }

  const onClickPeriod = (event: any) => {
    const selectedPeriod = _.first(
      _.filter(allPeriods, (p) => {
        const periodKey = _.get(p, 'periodKey', '')
        return _.includes(periodKey, _.get(event, 'target.value', ''))
      }),
    )
    setCanDropdownOpen(false)
    setFilterType(FILTER_TYPE.PERIOD)
    setSelectedPeriod(selectedPeriod)
    if (selectedPeriod) {
      track.timeFrameChanged(
        FILTER_TYPE.PERIOD,
        `${selectedPeriod.year} P${selectedPeriod.period} (${selectedPeriod.periodKey})`,
      )
    }
  }

  const onClickSmallScreenFilterType = (event: any) => {
    const value = _.get(event, 'target.value', '')
    if (value === FILTER_TYPE.DATE) {
      setIsSmallScreenDateRangeDiaglogOpen(true)
    } else {
      setFilterType(value)
    }
  }

  const onClickDateRange = (ranges: any) => {
    const newStartDate = _.get(ranges, 'selection.startDate', '')
    const newEndDate = _.get(ranges, 'selection.endDate', '')

    const formattedStartDate = moment(newStartDate).format(DATE_DISPLAY_FORMAT)
    const formattedEndDate = moment(newEndDate).format(DATE_DISPLAY_FORMAT)

    if (isSelectingEndDate) {
      const dateRangeStr = `${formattedStartDate} to ${formattedEndDate}`

      // assuming the first click the user is selecting the start date.
      // the second click the user is selecting the end date
      setFilterType(FILTER_TYPE.DATE)
      setSelectedValue(dateRangeStr)
      setDateRangeEndDate(newEndDate)
      setIsSelectingEndDate(false)
      track.timeFrameChanged(FILTER_TYPE.DATE, dateRangeStr)
    } else {
      setIsSelectingEndDate(true)
    }
    setDateRangeStartDate(newStartDate)
  }

  return (
    <Main
      selectedValue={selectedValue}
      canDropdownOpen={canDropdownOpen}
      setCanDropdownOpen={setCanDropdownOpen}
      filterType={filterType}
      allPeriods={allPeriods}
      onClickShifts={onClickShifts}
      onClickPeriod={onClickPeriod}
      onClickSmallScreenFilterType={onClickSmallScreenFilterType}
      onClickDateRange={onClickDateRange}
      dateRangeStartDate={dateRangeStartDate}
      dateRangeEndDate={dateRangeEndDate}
      selectedPeriod={selectedPeriod}
      numDaysWorked={numDaysWorked}
      isSmallScreenDateRangeDiaglogOpen={isSmallScreenDateRangeDiaglogOpen}
      setIsSmallScreenDateRangeDiaglogOpen={
        setIsSmallScreenDateRangeDiaglogOpen
      }
    />
  )
}

export default TimeframeDropdown
