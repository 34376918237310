import {
  api as tableApi,
  configs as tableConfigs,
} from '../../tempCustomizedTable/hooks/useApi'

export type IApiKeyType = keyof typeof api

export const configs = {
  ...tableConfigs,
}

const api = {
  ...tableApi,
}

const useApi = (apiName: IApiKeyType) => api[apiName]()

export default useApi
