import { IReportDefinition } from 'pared/components/CustomizedReport'

const getFilteredCustomReportDefinition = (
  customizedReportDefinition: IReportDefinition | undefined,
  { kpi, unit }: { kpi?: string; unit?: string },
) => {
  if (!customizedReportDefinition) {
    return customizedReportDefinition
  }

  const columns = customizedReportDefinition?.columns || []
  const filteredColumns = columns.filter((col) => {
    if (!col.filter) {
      return true
    }

    let isVisible = true
    if (col.filter.kpi) {
      if (Array.isArray(col.filter.kpi)) {
        if (!col.filter.kpi.includes(kpi)) {
          isVisible = false
        }
      } else {
        if (col.filter.kpi !== kpi) {
          isVisible = false
        }
      }
    }

    if (col.filter.unit && col.filter.unit !== unit) {
      isVisible = false
    }

    return isVisible
  })

  return {
    ...customizedReportDefinition,
    columns: filteredColumns,
  }
}

export default getFilteredCustomReportDefinition
