import { IYAxisData } from 'pared/charts/MixedChart/web/drawMixedChart'
import COLORS from 'pared/constants/colors'

import { IOrganizedData } from '..'

interface yAxisConfigType extends Omit<IYAxisData, 'data'> {
  dataKey: keyof IOrganizedData
}

interface ITrendChartConfigType {
  yAxisConfig: yAxisConfigType[]
}

export default (brandCode: string): ITrendChartConfigType => {
  switch (brandCode) {
    case 'sullivans': // Sullivan's
    case 'eddiemerlots': // Eddie Merlot's
    case 'sullivans_nso': // Sullivan's NSO
    case 'eddiemerlots_nso':
      return {
        yAxisConfig: [
          {
            type: 'bar',
            yAxisId: 'yRight',
            dataKey: 'inboundTotal',
            tooltipLabel: '# of Calls',
          },
          {
            type: 'line',
            dataKey: 'inboundAnsweredPercent',
            tooltipLabel: '% Answered',
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
          {
            type: 'line',
            dataKey: 'inboundMissedPercent',
            tooltipLabel: '% Missed',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
        ],
      }

    default:
      return {
        yAxisConfig: [
          {
            type: 'bar',
            yAxisId: 'yRight',
            dataKey: 'inboundTotal',
            tooltipLabel: '# of Calls',
          },
          {
            type: 'line',
            dataKey: 'inboundAnsweredPercent',
            tooltipLabel: '% Answered',
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
          {
            type: 'line',
            dataKey: 'inboundMissedAndAbandonedPercent',
            tooltipLabel: '% Missed + Abandoned',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
          },
          {
            type: 'line',
            dataKey: 'inboundMissedPercent',
            tooltipLabel: '% Missed',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
          {
            type: 'line',
            dataKey: 'inboundAbandonedPercent',
            tooltipLabel: '% Abandoned',
            borderColor: COLORS.Mango,
            backgroundColor: COLORS.Mango,
          },
        ],
      }
  }
}
