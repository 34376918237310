import _ from 'lodash'
import { useState } from 'react'

import { IGuestReview } from '../index'
import Main from './Main'

export interface IOrderBy {
  columnName: string
  isAscending: boolean
}

interface IProps {
  guestReviews: IGuestReview[]
  isLoading: boolean
}

function Reviews({ guestReviews, isLoading }: IProps) {
  const [isReviewsExpanded, setIsReviewsExpanded] = useState<boolean>(false)
  const [orderBy, setOrderBy] = useState<IOrderBy>({
    columnName: 'originalDate',
    isAscending: false,
  })

  let rankedGuestReviews = []
  const columnName = _.get(orderBy, 'columnName', '')
  const ascending = orderBy.isAscending ? 'asc' : 'desc'

  switch (columnName) {
    case 'ratingScore':
      rankedGuestReviews = _.orderBy(guestReviews, ['ratingScore'], [ascending])
      break
    case 'originalDate':
      rankedGuestReviews = _.orderBy(
        guestReviews,
        ['originalDate'],
        [ascending],
      )
      break
    default:
      rankedGuestReviews = _.orderBy(guestReviews, ['ratingScore'], [ascending])
      break
  }

  const onToggleExpandReviews = () => {
    setIsReviewsExpanded(!isReviewsExpanded)
  }

  return (
    <Main
      isReviewsExpanded={isReviewsExpanded}
      onToggleExpandReviews={onToggleExpandReviews}
      guestReviews={rankedGuestReviews}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      isLoading={isLoading}
    />
  )
}

export default Reviews
