import moment from 'moment'

import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import useConfig from 'pared/pages/Purchases/PurchasesTable/hooks/useConfig'
import { toUsdStr } from 'pared/utils/number'

import { IExrayDataType } from '../Exray/types'
import useCallsSummary from './useCallsSummary'
import useDeliverySummary from './useDeliverySummary'
import useGuestSummary from './useGuestSummary'
import useLaborSummary from './useLaborSummary'
import useLossPreventionSummary from './useLossPreventionSummary'
import useFetchPurchaseReportData from './usePurchasesReportData'
import useSalesSummary from './useSalesSummary'

const LOADING = 'Loading...'
const NO_DATA = 'No data available for the selected time period.'
const exrayTypes = [
  'Sales',
  'Guest',
  'Food Cost',
  'Delivery',
  'Labor',
  'Loss Prevention',
  'Calls',
] as const

export default (
  types: typeof exrayTypes[number][],
  locationId: number,
): {
  totalOpportunityCost: string
  data: IExrayDataType[]
} => {
  const startDate = moment().subtract(30, 'days')
  const endDate = moment().subtract(1, 'day')
  const startDateStr = startDate.format('YYYY-MM-DD')
  const endDateStr = endDate.format('YYYY-MM-DD')
  const locationName = useLocationInfo(locationId)?.name
  const foodCostConfig = useConfig()
  const { loading: salesLoading, action: salesAction } = useSalesSummary(
    startDateStr,
    endDateStr,
    locationId,
  )
  const { loading: lossPreventionLoading, action: lossPreventionAction } =
    useLossPreventionSummary(startDateStr, endDateStr, locationId)
  const { loading: laborLoading, action: laborAction } = useLaborSummary(
    startDateStr,
    endDateStr,
    locationId,
  )
  const {
    isLoading: purchaseLoading,
    topItemName,
    topItemOpportunity,
    totalAnnualOpportunityCost,
  } = useFetchPurchaseReportData(startDateStr, endDateStr, locationId)
  const { loading: guestLoading, action: guestAction } = useGuestSummary(
    startDateStr,
    endDateStr,
    locationId,
  )
  const { loading: deliveryLoading, action: deliveryAction } =
    useDeliverySummary(startDateStr, endDateStr, locationId)

  const { loading: callsLoading, action: callsAction } = useCallsSummary(
    startDateStr,
    endDateStr,
    locationId,
    locationName,
  )

  const isLoading =
    salesLoading ||
    lossPreventionLoading ||
    laborLoading ||
    purchaseLoading ||
    guestLoading ||
    deliveryLoading ||
    callsLoading
  const opportunityCosts = {
    ['Sales']: salesAction.annualOpportunityCost,
    ['Loss Prevention']: lossPreventionAction.annualOpportunityCost,
    ['Labor']: laborAction.totalAnnualOpportunityCost || '',
    ['Food Cost']: totalAnnualOpportunityCost,
    ['Calls']: callsAction.annualOpportunityCost,
  }

  const sorted = Object.keys(opportunityCosts).sort((typeA, typeB) => {
    return (
      opportunityCosts[typeB as keyof typeof opportunityCosts] -
      opportunityCosts[typeA as keyof typeof opportunityCosts]
    )
  })

  const totalOpportunityCost = toUsdStr(
    salesAction.annualOpportunityCost +
      lossPreventionAction.annualOpportunityCost +
      laborAction.totalAnnualOpportunityCost +
      totalAnnualOpportunityCost +
      callsAction.annualOpportunityCost,
  )
  const backgroundColors = ['#FE0505', '#FF5C5D', '#DAA8A8', '#474646']

  return {
    totalOpportunityCost: isLoading ? '-' : totalOpportunityCost,
    data: types?.map((type) => {
      let header
      const body = (() => {
        if (isLoading) {
          header = LOADING
          return
        }

        switch (type) {
          case 'Sales':
            header = toUsdStr(salesAction.annualOpportunityCost)
            if (salesAction.isBetterThanCompany)
              return `${locationName} performs better than company averages.`

            return `Your top opportunities to increase check averages are ${
              salesAction.menuCategory
            }.
              Consider running contests or training on suggestive selling.  Bringing these upsell categories up to system averages constitutes a ${toUsdStr(
                salesAction.annualOpportunityCost,
              )} annual opportunity cost which amounts to a ${
              salesAction.basisPoint
            } basis point improvement for ${locationName}.
              `

          case 'Loss Prevention':
            if (!lossPreventionAction.hasSummary) return NO_DATA

            header = toUsdStr(lossPreventionAction.annualOpportunityCost)
            return lossPreventionAction?.name === ''
              ? 'No employees are discounting above the Company Average for this time period.'
              : `${
                  lossPreventionAction?.name
                } is discounting more than company averages.  Consider retraining or addressing issue with your team member. ${
                  lossPreventionAction.annualOpportunityCost
                    ? `Annual opportunity cost for all employees: ${toUsdStr(
                        lossPreventionAction.annualOpportunityCost,
                      )}`
                    : ''
                }`

          case 'Labor':
            if (laborAction.opportunityHour === '0') return NO_DATA

            header = toUsdStr(laborAction.totalAnnualOpportunityCost)
            return laborAction.noOverstaffed
              ? `${locationName} has lower staffing than company averages.`
              : `${locationName}'s biggest opportunities are at ${
                  laborAction.opportunityHour
                } (${
                  laborAction.overstaffedHours
                } hours overstaffed).  Consider changing your scheduling during these hours. ${
                  laborAction.totalAnnualOpportunityCost
                    ? `Annual opportunity cost for all hours: ${toUsdStr(
                        laborAction.totalAnnualOpportunityCost,
                      )}`
                    : ''
                }`

          case 'Food Cost':
            header = toUsdStr(totalAnnualOpportunityCost)
            return topItemName.length === 0
              ? `${locationName} is purchasing items as expected for its sales. Keep up the good work!`
              : `${locationName}'s biggest opportunities to improve ${
                  foodCostConfig.mainKpi
                } is ${topItemName}.
                These are being purchased and used in higher quantities than restaurants with similar sales and represent a ${topItemOpportunity} opportunity.
                Put in measures to reduce ${topItemName} waste. Annual opportunity cost for all items: ${toUsdStr(
                  totalAnnualOpportunityCost,
                )}
                `
          case 'Guest':
            return guestAction.isBetterThanCompany
              ? 'Great job!  None of your OSAT responses had a problem!'
              : `${locationName}'s top problems are: ${guestAction.mostCommonProblems.join(
                  ', ',
                )}. ${guestAction?.mostCommonDays
                  .map((day: string) => `${day}s`)
                  .join(
                    ', ',
                  )} have the most negative reviews.  Address these issues with your team.
              `
          case 'Delivery':
            if (deliveryAction.isBetterThanCompany) {
              return `${locationName} performs better than company averages, but to keep improving: ${deliveryAction.worstDay} is the worst day for ${deliveryAction.issue}.`
            } else {
              return deliveryAction.issue === ''
                ? NO_DATA
                : `${deliveryAction.issue} is the top area to improve upon at ${locationName}. ${deliveryAction.worstDay} is the worst day. ${deliveryAction.name} is on shift when ${deliveryAction.issue} is worst.  Consider retraining your employees or repositioning them to reduce delivery issues.`
            }

          case 'Calls':
            header = toUsdStr(callsAction.annualOpportunityCost)
            return callsAction.summary
        }
      })()

      return {
        title: `${type}`,
        header,
        body,
        backgroundColor: isLoading
          ? 'black'
          : backgroundColors[sorted.findIndex((value) => value === type)],
      }
    }),
  }
}
