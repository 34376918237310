export default {
  options: {
    responsive: false,
    animation: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
    scales: {
      y: {
        title: {
          display: true,
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
  },
}
