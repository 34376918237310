import useListLocationGroupSystemwideOverview, {
  listLocationGroupSystemwideOverviewConfigs,
} from './useListLocationGroupSystemwideOverview'
import useListLocationSystemwideOverview, {
  listLocationSystemwideOverviewConfigs,
} from './useListLocationSystemwideOverview'

export const systemwideOverviewConfigs = {
  ...listLocationSystemwideOverviewConfigs,
  ...listLocationGroupSystemwideOverviewConfigs,
}

const useSystemwideOverview = () => {
  const listLocationSystemwideOverview = useListLocationSystemwideOverview()
  const listLocationGroupSystemwideOverview =
    useListLocationGroupSystemwideOverview()

  return {
    data:
      (listLocationSystemwideOverview?.data?.source || []).length === 0
        ? listLocationGroupSystemwideOverview.data
        : listLocationSystemwideOverview.data,
    loading:
      listLocationGroupSystemwideOverview.loading ||
      listLocationSystemwideOverview.loading,
  }
}

export default useSystemwideOverview
