import { v4 as uuidv4 } from 'uuid'

import Table from '../../Table'
import Trend from '../../Trend'
import { FROM_EXPO } from '../../constants'
import { IMessage } from '../../type'

export default function handleKpiAnalyzerAnswers(
  responseData: any,
  handleCannedQuestionClicked: (questionText: string) => void,
  requestProcessingUuid?: string,
) {
  if (
    responseData?.kpiValues ||
    responseData?.storeKpiRanking ||
    responseData?.directorKpiRanking
  ) {
    const answerMessage: IMessage = {
      id: requestProcessingUuid
        ? `${requestProcessingUuid}-kpiAnalyzerAnswers`
        : uuidv4(),
      from: FROM_EXPO,
      content: (
        <div>
          <Table
            data={
              responseData?.kpiValues ||
              responseData?.storeKpiRanking ||
              responseData?.directorKpiRanking
            }
          />
          {responseData?.formattedGptAnswer && (
            <div
              style={{ maxWidth: '800px' }}
              dangerouslySetInnerHTML={{
                __html: responseData?.formattedGptAnswer,
              }}
            />
          )}
          {responseData?.options && responseData.options.length > 0 && (
            <div style={{ maxWidth: '800px' }}>
              <ul>
                {responseData.options.map(
                  (optionText: string, index: number) => (
                    <li key={index}>
                      <a
                        href="#"
                        style={{ color: 'blue', textDecoration: 'underline' }}
                        onClick={(e) => {
                          e.preventDefault()
                          handleCannedQuestionClicked(optionText)
                        }}
                      >
                        {optionText}
                      </a>
                    </li>
                  ),
                )}
              </ul>
            </div>
          )}
        </div>
      ),
    }

    return answerMessage
  }

  if (responseData?.kpiTrends) {
    const answerMessage: IMessage = {
      id: requestProcessingUuid
        ? `${requestProcessingUuid}-kpiAnalyzerTrends`
        : uuidv4(),
      from: FROM_EXPO,
      content: <Trend data={responseData?.kpiTrends} />,
    }

    return answerMessage
  }

  return null
}
