import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'

const query = gql`
  query arbListLocationPrimantiTattleKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationPrimantiTattle(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        avgScore
        negativeReviews
        totalReviews
        details
      }
    }
  }
`

export const arbListLocationPrimantiTattleKpisConfigs = {
  avgScore: 'number',
  negativeReviews: 'number',
  totalReviews: 'number',
} as const

const useArbListLocationPrimantiTattleKpis = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_ids: groupFilter?.ids },
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const kpis = data?.listLocationPrimantiTattle.nodes[0] || {}

      if (Object.keys(kpis).length === 0) return null

      return kpis
    }, [data]),
    loading,
  }
}

export default useArbListLocationPrimantiTattleKpis
