import { useQuery } from '@apollo/client'

import { INullableType } from 'pared/types'

import {
  IGuestSummaryType,
  IGuestSummaryVariablesType,
  getLocationGuestSummary,
} from '../gqls/guest'

interface IGuestActionType {
  isBetterThanCompany: boolean
  mostCommonProblems: string[]
  mostCommonDays: string[]
}

interface IReturnType {
  loading: boolean
  action: IGuestActionType
}

export default (
  startDate: string,
  endDate: string,
  locationId: number,
): IReturnType => {
  const action = {
    isBetterThanCompany: false,
    mostCommonProblems: [] as string[],
    mostCommonDays: [] as string[],
  }
  const variables: INullableType<IGuestSummaryVariablesType> = {
    iLocationId: locationId,
    iStartDate: startDate,
    iEndDate: endDate,
  }

  const { data, loading } = useQuery<IGuestSummaryType>(
    getLocationGuestSummary,
    {
      variables,
    },
  )

  const dataSource = data?.locationGuestSummaryV2?.nodes[0]

  if (!dataSource) {
    action.isBetterThanCompany = true
  } else {
    if (dataSource.mostCommonProblem) {
      action.mostCommonProblems = [...dataSource.mostCommonProblem]
    }

    if (dataSource.mostCommonDay) {
      action.mostCommonDays = [...dataSource.mostCommonDay]
    }
  }

  return {
    loading,
    action,
  }
}
