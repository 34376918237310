import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

interface ICategory {
  variableName: string
  displayName: string
  replacements?: string[][]
  content?: string
  warningLevel?: number
}

interface IConfig {
  isMockUp?: boolean
  categories: ICategory[]
}

const defaultConfig = {
  categories: [
    {
      variableName: 'budget_variance',
      displayName: 'Sales Targets',
    },
    {
      variableName: 'financial_outliers',
      displayName: 'Financial Outliers',
    },
    {
      variableName: 'salesmanship',
      displayName: 'Salesmanship',
    },
    {
      variableName: 'guest',
      displayName: 'Guest',
    },
    {
      variableName: 'delivery',
      displayName: 'Delivery',
    },
    {
      variableName: 'team',
      displayName: 'Team',
    },
  ],
}

const eddieMerlotsConfig = {
  categories: [
    {
      variableName: 'budget_variance',
      displayName: 'Sales Targets',
    },
    {
      variableName: 'financial_outliers',
      displayName: 'Financial Outliers',
    },
    {
      variableName: 'guest',
      displayName: 'Guest',
    },
    {
      variableName: 'delivery',
      displayName: 'Delivery',
    },
    {
      variableName: 'team',
      displayName: 'Team',
    },
  ],
}

const pjConfig = {
  categories: [
    {
      variableName: 'budget_variance',
      displayName: 'Sales Targets',
      replacements: [
        ['Food', 'Pizza'],
        ['LBW', 'Sides'],
      ],
    },
    {
      variableName: 'financial_outliers',
      displayName: 'Financial Outliers',
      replacements: [['RCP', 'Controllable Profit']],
    },
    {
      variableName: 'salesmanship',
      displayName: 'Salesmanship',
      replacements: [['PPA', 'Sales Per Labor Hour']],
    },
    {
      variableName: 'guest',
      displayName: 'Guest',
      replacements: [['MomentFeed score', 'Customer Rating']],
    },
    {
      variableName: 'delivery',
      displayName: 'Delivery',
    },
    {
      variableName: 'team',
      displayName: 'Team',
    },
  ],
}

const demoBkConfig = {
  isMockUp: true,
  categories: [
    {
      variableName: 'financial',
      displayName: 'Financials',
      content: 'Net Sales are up 8% this Period',
      warningLevel: 1,
    },
    {
      variableName: 'team',
      displayName: 'Team',
      content: 'Today is Josephine L 1 Year Work Anniversary',
      warningLevel: 2,
    },
    {
      variableName: 'inventory',
      displayName: 'Inventory',
      content: 'Menu Waste is 2% higher than systemwide averages',
      warningLevel: 4,
    },
    {
      variableName: 'loss_prevention',
      displayName: 'Loss Prevention',
      content:
        'Discounts are 7% higher than systemwide averages. Tony F discounts are high: 9% of Sales.',
      warningLevel: 5,
    },
    {
      variableName: 'salesmanship',
      displayName: 'Salesmanship',
      content:
        'Check Averages are 2% higher YoY. Dessert Attachments are Ranked #1 against all stores.',
      warningLevel: 1,
    },
    {
      variableName: 'delivery',
      displayName: 'Delivery',
      content: 'Driver Wait Times are 3.8 minutes higher this month',
      warningLevel: 4,
    },
  ],
}

const config = {
  rmg: defaultConfig,
  sullivans: defaultConfig,
  eddiemerlots: eddieMerlotsConfig,
  papajohns: pjConfig,
}

const useExrayConfig = (): IConfig => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'rmg':
      case 'rmg_nso':
        return config.rmg
      case 'sullivans':
      case 'sullivans_nso':
        return config.sullivans
      case 'eddiemerlots':
      case 'eddiemerlots_nso':
        return config.eddiemerlots
      case 'papajohns':
      case 'papajohns_demo':
        return config.papajohns
      case 'demo_bk':
        return demoBkConfig
      default:
        return defaultConfig
    }
  }, [brand])
}

export default useExrayConfig
