import { gql } from '@apollo/client'

export const GET_ALERT_USER_NOTIFIEDS = gql`
  query AlertUserNotifieds($userId: Int) {
    alertUserNotifieds(condition: { userId: $userId }) {
      nodes {
        id
        user {
          id
          firstName
          lastName
          employeeId
        }
        alert {
          id
          metadata
          triggeredAt
          alertSpecificationBySpecification {
            id
            alertName
            alertDescription
            alertDefinition
          }
          alertUserNotifieds {
            nodes {
              readAt
            }
          }
        }
        group {
          id
          groupName
          groupDescription
        }
      }
    }
  }
`
