import React from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import { SummaryComponentProps } from '../type'
import useSummaryContent from './hooks/useSummaryContent'

interface IProps {
  selectedLocationGroupId: number
  startDate: string
  endDate: string
  summaryData: SummaryComponentProps['data']
}

const SummaryContainer = styled.div`
  width: 100%;

  @media ${MOBILE_WIDTH} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

const ConclusionDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: ${COLORS.Salt};
  padding: 30px;

  border-radius: 10px 10px 0 0;
  background: #0071e3;

  @media ${MOBILE_WIDTH} {
    flex: 1;
  }
`

const ConclusionOnlyContainer = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: ${COLORS.Salt};
  padding: 30px;

  border-radius: 10px;
  border: 1px solid #0071e3;
  background: #0071e3;

  @media ${MOBILE_WIDTH} {
    display: flex;
  }
`

const InsightDiv = styled.div`
  padding: 30px;
  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};

  border-radius: 0 0 10px 10px;
  border: 1px solid #81abfd;

  @media ${MOBILE_WIDTH} {
    flex: 1;
  }
`

const InsightTitle = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 0 0 15px 0;
`

const MessageBlock = ({
  selectedLocationGroupId,
  startDate,
  endDate,
  summaryData,
}: IProps) => {
  const { conclusion, insight } = useSummaryContent({
    selectedLocationGroupId,
    startDate,
    endDate,
    summaryData,
  })

  if (conclusion) {
    if (insight) {
      return (
        <SummaryContainer>
          <ConclusionDiv>{conclusion}</ConclusionDiv>
          <InsightDiv>
            <InsightTitle>EXPO INSIGHTS</InsightTitle>
            {insight}
          </InsightDiv>
        </SummaryContainer>
      )
    } else {
      return <ConclusionOnlyContainer>{conclusion}</ConclusionOnlyContainer>
    }
  }

  return null
}

export default React.memo(MessageBlock)
