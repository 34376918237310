import { gql } from '@apollo/client'

export const MUTATION_UPDATE_LOCATION_GROUP_MAPPING = gql`
  mutation ($locationGroupMappingId: Int!, $locationGroupId: Int!) {
    updateLocationGroupMappingV2(
      input: {
        iLocationGroupMappingId: $locationGroupMappingId
        iLocationGroupId: $locationGroupId
      }
    ) {
      clientMutationId
    }
  }
`

export const MUTATION_CREATE_LOCATION_GROUP_MAPPING = gql`
  mutation (
    $locationId: Int!
    $locationGroupId: Int!
    $locationGroupType: String!
  ) {
    createLocationGroupMappingV2(
      input: {
        iLocationId: $locationId
        iLocationGroupId: $locationGroupId
        iLocationGroupType: $locationGroupType
      }
    ) {
      clientMutationId
    }
  }
`

export const MUTATION_CREATE_LOCATION_GROUP = gql`
  mutation CreateLocationGroup(
    $type: String!
    $name: String!
    $brandId: Int!
    $locationId: Int!
  ) {
    createLocationGroupV2(
      input: {
        iType: $type
        iName: $name
        iBrandId: $brandId
        iLocationId: $locationId
      }
    ) {
      clientMutationId
    }
  }
`

export const MUTATION_UPDATE_LOCATION_GROUP = gql`
  mutation ($locationGroupId: Int!) {
    updateLocationGroupV2(input: { iLocationGroupId: $locationGroupId }) {
      clientMutationId
    }
  }
`

export const MUTATION_EDIT_LOCATION = gql`
  mutation ($locationId: Int!, $formValues: JSON!) {
    editLocation(
      input: { iLocationId: $locationId, iFormValues: $formValues }
    ) {
      clientMutationId
    }
  }
`
