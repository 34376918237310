import { corporateGroupTableConfigs } from '../../../variables'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const burgerworksSystemwideOverviewConfigs = {
  ...corporateGroupTableConfigs,
  netSales: 'price',
  laborTotalCostPercent: 'percent',
  averageWeeklySales: 'price',
} as const

const useBurgerworksSystemwideOverview = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: ['net_sales', 'labor_total_cost_percent'],
  },
})

export default useBurgerworksSystemwideOverview
