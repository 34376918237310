import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import styled from 'styled-components'

import { NUM_DAYS_WORKED } from 'pared/constants'
import { IPeriod } from 'pared/data/getPeriods'
import DownArrow from 'pared/images/basic/arrow/triangle-arrow-down.svg'
import { defaultStaticRanges } from 'pared/pages/v1/Salesmanship/TimeframeDropdown/defaultRanges.js'

interface IProps {
  selectedValue: string
  canDropdownOpen: boolean
  setCanDropdownOpen: any
  allPeriods: IPeriod[] | null
  onClickShifts: (event: any) => void
  onClickPeriod: (event: any) => void
  onClickDateRange: (ranges: any) => void
  dateRangeStartDate: any
  dateRangeEndDate: any
}

const DATE_DISPLAY_FORMAT = 'M/D/YY'

const useStyles = makeStyles(() => ({
  menuItem: {
    '&:hover': {
      backgroundColor: '#07A4C7 !important',
    },
  },
}))

const WebLargeMain = ({
  selectedValue,
  canDropdownOpen,
  setCanDropdownOpen,
  allPeriods,
  onClickShifts,
  onClickPeriod,
  onClickDateRange,
  dateRangeStartDate,
  dateRangeEndDate,
}: IProps) => {
  const classes = useStyles()

  const onMouseOverSelectionValue = () => {
    setCanDropdownOpen(true)
  }

  const selectionRange = {
    startDate: dateRangeStartDate,
    endDate: dateRangeEndDate,
    key: 'selection',
  }

  const periodRows = _.map(allPeriods, (p) => {
    const periodKey = _.get(p, 'periodKey', '')
    const period = _.get(p, 'period', 1)
    const periodStart = p.periodStart.format(DATE_DISPLAY_FORMAT)
    const periodEnd = p.periodEnd.format(DATE_DISPLAY_FORMAT)

    return (
      <FilterMenuItem
        onClick={onClickPeriod}
        key={periodKey}
        value={periodKey}
        className={classes.menuItem}
      >
        P{period} ({periodStart} to {periodEnd})
      </FilterMenuItem>
    )
  })

  let date = new Date()
  date.setDate(date.getDate() - 1)

  const dropdown = canDropdownOpen ? (
    <ul>
      <li>
        <TimeframeAnchor>Shifts</TimeframeAnchor>
        <ul>
          <li>
            <FilterMenuItem
              onClick={onClickShifts}
              className={classes.menuItem}
              data-value={10}
            >
              {NUM_DAYS_WORKED.LAST_10_SHIFTS}
            </FilterMenuItem>
          </li>
          <li>
            <FilterMenuItem
              onClick={onClickShifts}
              className={classes.menuItem}
              data-value={30}
            >
              {NUM_DAYS_WORKED.LAST_30_SHIFTS}
            </FilterMenuItem>
          </li>
        </ul>
      </li>
      <li>
        <TimeframeAnchor>Period</TimeframeAnchor>
        <ul>{periodRows}</ul>
      </li>
      <li>
        <TimeframeAnchor>Date</TimeframeAnchor>
        <ul>
          <li>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={onClickDateRange}
              maxDate={date}
              staticRanges={defaultStaticRanges}
              inputRanges={[]}
              editableDateInputs
              dateDisplayFormat={'M-dd-yyyy'}
            />
          </li>
        </ul>
      </li>
    </ul>
  ) : null

  return (
    <TimeframeSelectContainer>
      <ul className="main-navigation">
        <li>
          <TimeframeAnchor onMouseOver={onMouseOverSelectionValue}>
            <SelectionContainer>
              <SelectionDescription>{selectedValue}</SelectionDescription>
              <ArrowContainer>
                <img src={DownArrow} alt="Down Arrow" />
              </ArrowContainer>
            </SelectionContainer>
          </TimeframeAnchor>
          {dropdown}
        </li>
      </ul>
    </TimeframeSelectContainer>
  )
}

const SelectionContainer = styled.div`
  display: inline-block;
`

const FilterMenuItem = styled(MenuItem)`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding-top: 10px;
  height: 40px;
`

const SelectionDescription = styled.div`
  width: 180px;
  float: left;
`

const ArrowContainer = styled.div`
  float: left;
`

const TimeframeSelectContainer = styled.div`
  width: 220px;
  display: inline-block;
  float: left;
  margin-top: -2px;
  margin-left: 20px;

  ul {
    list-style: none;
    padding: 0;
    background: white;
    width: 220px;
    border: 1px solid #c4c4c4;
  }

  ul li {
    display: block;
    position: relative;
    float: left;
    width: 220px;
  }

  li ul {
    display: none;
  }

  ul li a {
    display: block;
    padding: 10px;
    text-decoration: none;
    white-space: nowrap;
    color: black;
  }

  ul li a:hover {
    background: #47a2c3;
  }

  li:hover > ul {
    display: block;
    position: absolute;
  }

  li:hover li {
    float: none;
  }

  li:hover a {
    background: white;
  }

  li:hover li a:hover {
    background: #47a2c3;
  }

  .main-navigation li ul li {
    border-top: 0;
  }

  ul ul ul {
    left: 100%;
    top: 0;
  }

  ul:before,
  ul:after {
    content: ' '; /* 1 */
    display: table; /* 2 */
  }

  ul:after {
    clear: both;
  }
`

const TimeframeAnchor = styled.a`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding-top: 5px;
  padding-down: 10px;
  height: 40px;
  cursor: pointer;
`

export default WebLargeMain
