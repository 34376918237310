import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import {
  GROUP_BY_PERIOD,
  GROUP_BY_QUARTER,
  GROUP_BY_YEAR,
  IDateRange,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_YEAR,
} from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'
import { toPercentageString, toUsdString } from 'pared/utils/number'

import { TREND_LOCATION_GROUP_SALESMANSHIP_KPIS } from '../gql'
import Main from './Main'

interface IProps {
  navParams: INavParams
  selectedDateRange: IDateRange | null
  selectedDirector: IDirector | null
}

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

function Chart({ navParams, selectedDateRange, selectedDirector }: IProps) {
  const storeId = navParams.storeId
  const brand = getBrand()

  const brandSettings = getBrandSettings()
  const salesmanshipLabels = brandSettings.labels.salesmanship

  const locationId = parseInt(storeId || '0', 10) || 0

  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)

  const [
    listKpi,
    { loading: listKpiLoading, error: listKpiError, data: listKpiData },
  ] = useLazyQuery(TREND_LOCATION_GROUP_SALESMANSHIP_KPIS)

  useEffect(() => {
    const selectedDateRangeType = _.get(selectedDateRange, 'type', 'period')

    const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
    let selectedLocationGroupId =
      _.get(selectedDirector, 'locationGroupId') || -1
    selectedLocationGroupId =
      selectedLocationGroupId < 0
        ? brandLocationGroupId
        : selectedLocationGroupId

    let groupBy = ''
    if (selectedDateRangeType === TYPE_YEAR) {
      groupBy = GROUP_BY_YEAR
    } else if (selectedDateRangeType === TYPE_QUARTER) {
      groupBy = GROUP_BY_QUARTER
    } else {
      groupBy = GROUP_BY_PERIOD
    }

    listKpi({
      variables: {
        iLocationGroupId: selectedLocationGroupId,
        iEndDate: _.get(selectedDateRange, 'endDateStr', ''),
        iGroupBy: groupBy,
      },
    })
  }, [locationId, selectedDateRange, selectedDirector])

  let rawKpiMonthly = []

  if (
    listKpiData &&
    listKpiData.trendLocationGroupSalesmanshipKpis &&
    Array.isArray(listKpiData.trendLocationGroupSalesmanshipKpis.nodes)
  ) {
    rawKpiMonthly = _.get(
      listKpiData,
      'trendLocationGroupSalesmanshipKpis.nodes',
      [],
    )
  }

  const selectedYear = _.get(selectedDateRange, 'year')
  const selectedDateRangeType = _.get(selectedDateRange, 'type')
  const selectedDateRangeTypeIndex = _.get(selectedDateRange, 'index') || 0
  let trendIndexOffset = -rawKpiMonthly.length + 1

  const xAxisData: any[] = []

  if (selectedYear && selectedDateRangeType) {
    _.forEach(rawKpiMonthly, () => {
      if (selectedDateRangeType === TYPE_YEAR) {
        const year = selectedYear + trendIndexOffset
        xAxisData.push(`Y${year}`)
      } else if (selectedDateRangeType === TYPE_QUARTER) {
        let year = selectedYear
        let quarter = selectedDateRangeTypeIndex + trendIndexOffset
        if (quarter <= 0) {
          quarter += 4
          year -= 1
        }
        xAxisData.push(`Q${quarter} ${year}`)
      } else if (selectedDateRangeType === TYPE_PERIOD) {
        let year = selectedYear
        let period = selectedDateRangeTypeIndex + trendIndexOffset
        if (period <= 0) {
          period += 12
          year -= 1
        }
        xAxisData.push(`P${period} ${year}`)
      }

      trendIndexOffset += 1
    })
  }

  const yAxisCheckAvgData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'checkAverage', 0) / 100.0
  })

  const yAxisPpaData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'ppa', 0) / 100.0
  })

  const yAxisAppetizerPpaData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'appetizerPpa', 0) / 100.0
  })

  const yAxisLbwPpaData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'lbwPpa', 0) / 100.0
  })

  const yAxisDessertPpaData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'dessertPpa', 0) / 100.0
  })

  const yAxisSalesPerLaborHourData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'grossSalesPerLaborHour', 0) / 100.0
  })

  const yAxisDataArr = [
    {
      type: 'line',
      data: yAxisCheckAvgData,
      tooltipLabel: salesmanshipLabels.checkAvg,
      borderColor: COLORS.DarkOrchid,
      backgroundColor: COLORS.DarkOrchid,
    },
    {
      type: 'line',
      data: yAxisPpaData,
      tooltipLabel: salesmanshipLabels.totalPpa,
      borderColor: COLORS.Plum,
      backgroundColor: COLORS.Plum,
    },
    {
      type: 'line',
      data: yAxisAppetizerPpaData,
      tooltipLabel: salesmanshipLabels.appetizerPpa,
      borderColor: COLORS.Pomodoro,
      backgroundColor: COLORS.Pomodoro,
      hidden: true,
    },
    {
      type: 'line',
      data: yAxisLbwPpaData,
      tooltipLabel: salesmanshipLabels.lbwPpa,
      borderColor: COLORS.Mango,
      backgroundColor: COLORS.Mango,
      hidden: true,
    },
    {
      type: 'line',
      data: yAxisDessertPpaData,
      tooltipLabel: salesmanshipLabels.dessertPpa,
      borderColor: COLORS.Basil,
      backgroundColor: COLORS.Basil,
      hidden: true,
    },
    {
      type: 'bar',
      yAxisId: 'yRight',
      data: yAxisSalesPerLaborHourData,
      tooltipLabel: salesmanshipLabels.salesPerLaborHour,
    },
  ]

  const chartOptions = {
    title: '',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: '$',
    yLeftTickCallback: (value: number) => {
      return toUsdString(value, 2)
    },
    yRightAxisLabel: `${salesmanshipLabels.salesPerLaborHour} ($/hr)`,
    yRightShowGridLines: false,
    yRightTickCallback: (value: number) => {
      return toUsdString(value)
    },
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let datasetLabel = ''
        let separator = ''
        let yValue = ''
        if (tooltipItemContext.dataset && tooltipItemContext.dataset.label) {
          datasetLabel = tooltipItemContext.dataset.label
          separator = ': '
        }
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          if (tooltipItemContext.datasetIndex === 0) {
            yValue += toUsdString(tooltipItemContext.parsed.y, 2)
          } else {
            yValue = toUsdString(tooltipItemContext.parsed.y, 2)
          }
        }
        return [datasetLabel, separator, yValue].join('')
      }
      return ''
    },
  }

  return (
    <Main
      isLoading={listKpiLoading}
      xAxisData={xAxisData}
      chartOptions={chartOptions}
      yAxisDataArr={yAxisDataArr}
    />
  )
}

export default Chart
