import { IRouteType } from '../../types'
import primantiGuest from './primantiGuest'
import primantiSystemwideOverview from './primantiSystemwideOverview'

export const routes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Systemwide Overview',
        link: '/:brand/systemwide_overview',
        default: true,
      },
      {
        title: 'Guest',
        link: '/:brand/corporate_guest',
      },
    ],
  },
  {
    title: 'Store',
    children: [
      {
        title: 'Guest',
        link: '/:brand/guest',
      },
    ],
  },
]

export default [primantiSystemwideOverview, primantiGuest]
