import { v4 as uuidv4 } from 'uuid'

import { FROM_EXPO } from 'pared/pages/ExpoAi/constants'
import { IMessage } from 'pared/pages/ExpoAi/type'

export default function handleErrors(responseData: any) {
  if (responseData?.error && responseData?.error?.code) {
    const messageId = uuidv4()
    let errorMessage =
      'Sorry, some unexpected errors happened, please try it again later'

    if (responseData.error.code === 'NO_PERMISSION_FOR_ENTITY_DATA') {
      errorMessage = 'Sorry, you may not have the permission for the data'
    }

    const answerMessage: IMessage = {
      id: messageId,
      from: FROM_EXPO,
      content: <div>{errorMessage}</div>,
      isFeedbackVisible: false,
    }

    return answerMessage
  }

  return null
}
