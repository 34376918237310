export const wingitnorthKpiDefs = {
  checkAvg: {
    key: 'checkAvg',
    name: 'Check Avg',
    kpiType: 'sales',
    salesType: 'total',
    kpiKey: 'checkAvg',
    valueType: 'cent',
    digitNum: 2,
  },
  totalPpa: {
    key: 'totalPpa',
    name: 'Total PPA',
    kpiType: 'sales',
    salesType: 'total',
    kpiKey: 'ppa',
    valueType: 'cent',
    digitNum: 2,
  },
  salesPerHour: {
    key: 'salesPerHour',
    name: 'Sales per Labor Hour',
    kpiType: 'sales',
    salesType: 'total',
    kpiKey: 'salesPerHour',
    valueType: 'cent',
    digitNum: 2,
  },
  cornAttachmentRate: {
    key: 'cornAttachmentRate',
    name: 'Corn Attachment Rate',
    kpiType: 'sales',
    salesType: 'CORN',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  waterAttachmentRate: {
    key: 'waterAttachmentRate',
    name: 'Bottled Water Attachment Rate',
    kpiType: 'sales',
    salesType: 'WATER',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  saucesAttachmentRate: {
    key: 'saucesAttachmentRate',
    name: 'Sauces Attachment Rate',
    kpiType: 'sales',
    salesType: 'SIDE SAUCES / DIPS',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  sticksAttachmentRate: {
    key: 'sticksAttachmentRate',
    name: 'Veggie Sticks Attachment Rate',
    kpiType: 'sales',
    salesType: 'STICKS',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  bonelessAttachmentRate: {
    key: 'bonelessAttachmentRate',
    name: 'Boneless Attachment Rate',
    kpiType: 'sales',
    salesType: 'BONELESS',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  classicAttachmentRate: {
    key: 'classicAttachmentRate',
    name: 'Classic Attachment Rate',
    kpiType: 'sales',
    salesType: 'CLASSIC',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  tendersAttachmentRate: {
    key: 'tendersAttachmentRate',
    name: 'Tenders Attachment Rate',
    kpiType: 'sales',
    salesType: 'TENDERS',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  splitAttachmentRate: {
    key: 'splitAttachmentRate',
    name: 'Split Attachment Rate',
    kpiType: 'sales',
    salesType: 'SPLIT',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  sandwichAttachmentRate: {
    key: 'sandwichAttachmentRate',
    name: 'Sandwich Attachment Rate',
    kpiType: 'sales',
    salesType: 'SANDWICH',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
}
