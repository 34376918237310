import IconButton from '@material-ui/core/IconButton'
import Modal from '@material-ui/core/Modal'
import CancelIcon from '@material-ui/icons/Cancel'
import styled from 'styled-components'

import { DAYPARTS, FILTER_TYPE } from 'pared/constants'
import CompsTable from 'pared/pages/v1/Salesmanship/CompsTable'

interface IProps {
  dateRangeStartDate: string
  dateRangeEndDate: string
  locationId: number
  isOpsCompModalVisible: boolean
  setIsOpsCompModalVisible: any
}

const Main = ({
  dateRangeStartDate,
  dateRangeEndDate,
  locationId,
  isOpsCompModalVisible,
  setIsOpsCompModalVisible,
}: IProps) => {
  const handleClose = () => {
    setIsOpsCompModalVisible(false)
  }

  return (
    <Modal
      open={isOpsCompModalVisible}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Container>
        <ModalTitleContainer>
          <CancelIconButton onClick={handleClose}>
            <CancelIcon />
          </CancelIconButton>
        </ModalTitleContainer>
        <CompsTable
          navParams={{
            storeId: locationId.toString(),
          }}
          selectedPeriod={null}
          dayPart={DAYPARTS.ALL}
          filterType={FILTER_TYPE.DATE}
          dateRangeStartDate={dateRangeStartDate}
          dateRangeEndDate={dateRangeEndDate}
        />
        <DisclaimerText>
          * Chart above only displays Ops Comps for Dine-In and Bar Revenue
          Centers
        </DisclaimerText>
      </Container>
    </Modal>
  )
}

const CancelIconButton = styled(IconButton)`
  float: right;
  margin-top: -15px;
`

const DisclaimerText = styled.div`
  margin-top: 10px;
  font-family: Lexend-Regular;
  font-style: normal;
  font-weight: 400;
`

const ModalTitleContainer = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
`

const Container = styled.div`
  width: 82%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0 20px 30px 30px;
`

export default Main
