import Main from './Main'

interface IProps {
  tableData: object
}

const HourlyLaborTable = ({ tableData }: IProps) => {
  return <Main tableData={tableData} />
}

export default HourlyLaborTable
