import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const burgerworksSystemwideOverviewConfig: IConfigsType = {
  burgerworks: {
    '/:brand/systemwide_overview': {
      variables: ['corporateGroup'],
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      systemwideOverview: {
        type: 'list',
        api: 'burgerworksListSystemwideOverviewKpis',
        title: 'Systemwide Overview',
        width: '120px',
        fields: [
          {
            key: 'netSales',
            title: 'Net Sales',
          },
          {
            key: 'netSalesSssPercent',
            title: 'SSS %',
          },
          {
            key: 'checkCount',
            title: 'Check Count',
          },
          {
            key: 'checkAverage',
            title: 'Check Average',
            decimal: 2,
          },
          {
            key: 'laborTotalCostPercent',
            title: 'Variable Labor %',
            width: '125px',
          },
          {
            key: 'averageWeeklySales',
            title: 'Average Weekly Sales',
            width: '125px',
          },
        ],
      },
      breakdownByStore: {
        type: 'table-v2',
        title: 'Breakdown by Store',
        api: 'burgerworksSystemwideOverview',
        sorting: [
          {
            id: '<%- corporateGroup?.tableColumns[0].key %>',
            desc: true,
          },
        ],
        columns: [
          {
            key: '<%- JSON(corporateGroup?.tableColumns) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            sortable: true,
          },
          {
            key: 'sales',
            title: 'Net Sales',
            align: 'center',
            children: [
              {
                key: 'netSales',
                title: '$',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Weekly Sales',
            title: 'Weekly Sales',
            align: 'center',
            children: [
              {
                key: 'averageWeeklySales',
                title: '$',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'variableLabor',
            title: 'Variable Labor',
            align: 'center',
            children: [
              {
                key: 'laborTotalCostPercent',
                title: '%',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
      revenueCenterAndDayPart: {
        type: 'grid',
        children: {
          revenueCenter: {
            type: 'table-v2',
            api: 'locationGroupRevenueCenter',
            title: 'Revenue Center',
            columns: [
              {
                key: 'name',
                title: 'Revenue Center',
                sortable: true,
              },
              {
                key: 'sales',
                title: 'Net Sales',
                unit: 'dollar',
                sortable: true,
              },
              {
                key: 'percentSales',
                title: '% Net Sales',
                sortable: true,
              },
              {
                key: 'checkCount',
                title: 'Check Count',
                sortable: true,
              },
              {
                key: 'checkAverage',
                title: 'Check Average',
                unit: 'dollar',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          dayPart: {
            type: 'table-v2',
            api: 'locationGroupDayPart',
            title: 'Day Part',
            columns: [
              {
                key: 'name',
                title: 'Day Part',
                sortable: true,
              },
              {
                key: 'sales',
                title: 'Sales',
                unit: 'dollar',
                sortable: true,
              },
              {
                key: 'percentSales',
                title: '% Net Sales',
                sortable: true,
              },
              {
                key: 'checkCount',
                title: 'Check Count',
                sortable: true,
              },
              {
                key: 'checkAverage',
                title: 'Check Average',
                unit: 'dollar',
                decimal: 2,
                sortable: true,
              },
            ],
          },
        },
      },
      averageWeeklySales: {
        type: 'line-chart',
        title: 'Average Weekly Sales',
        api: 'burgerworksWeeklySales',
        width: 1100,
        height: 350,
        label: 'date',
        datasets: [
          {
            key: 'netSalesWeeklyAverage',
            label: 'Net Sales',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
          },
        ],
        scaleTypes: {
          y: {
            type: 'price',
          },
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              title: {
                text: '$',
              },
            },
          },
        },
      },
      map: {
        type: 'map',
        api: 'listGeoInfo',
      },
    },
  },
}

export default burgerworksSystemwideOverviewConfig
