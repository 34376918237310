import _ from 'lodash'
import { useMemo } from 'react'

import { useFetchCustomizedReportData } from 'pared/components/CustomizedReport'
import { DATE_DATABASE_FORMAT } from 'pared/constants'
import { IDateRange } from 'pared/data/getDateRanges'

import {
  INVENTORY_CUSTOMIZED_REPORT_NAME_DAILY,
  INVENTORY_CUSTOMIZED_REPORT_NAME_PERIOD,
} from '../constants'
import useInventoryConfig from './useInventoryConfig'
import { INVENTORY_REPORT_TYPE_PERIOD, IWeekInfo } from './useReportFilter'

const useFetchSelectedInventoryReportData = (
  locationId?: number,
  reportFilterValue?: string,
  selectedWeek?: IWeekInfo,
  dateRange?: IDateRange | null,
) => {
  const config = useInventoryConfig()
  let customizedReportName = ''
  const queryVersionSuffix = config.queryVersion
    ? `_V${config.queryVersion}`
    : ''
  let startDate = ''
  let endDate = ''

  if (selectedWeek) {
    customizedReportName = INVENTORY_CUSTOMIZED_REPORT_NAME_DAILY
    startDate = selectedWeek?.weekBeginDate?.format(DATE_DATABASE_FORMAT) || ''
    endDate = selectedWeek?.weekEndDate?.format(DATE_DATABASE_FORMAT) || ''
  } else if (reportFilterValue === INVENTORY_REPORT_TYPE_PERIOD) {
    customizedReportName = `${INVENTORY_CUSTOMIZED_REPORT_NAME_PERIOD}${queryVersionSuffix}`
    startDate = dateRange?.startDateStr || ''
    endDate = dateRange?.endDateStr || ''
  }

  const { reportDefinition, reportResult, isLoading } =
    useFetchCustomizedReportData(customizedReportName, {
      locationId,
      startDate,
      endDate,
    })

  return useMemo(() => {
    if (selectedWeek || reportFilterValue === INVENTORY_REPORT_TYPE_PERIOD) {
      return {
        customizedReportName,
        reportDefinition,
        reportResult,
        isLoading,
      }
    }

    return null
  }, [
    locationId,
    reportFilterValue,
    selectedWeek,
    customizedReportName,
    reportDefinition,
    reportResult,
    isLoading,
  ])
}

export default useFetchSelectedInventoryReportData
