import { useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import { INullableType } from 'pared/types'
import { getBrand } from 'pared/utils/brand'

import useLossPreventionConfig from '../../hooks/useLossPreventionConfig'
import {
  GET_DISCOUNT_ITEM_TYPES,
  IGetDiscountItemTypesType,
  IGetDiscountItemTypesVariableTypes,
} from '../gql'

export const ALL = 'All'

interface IReturnType {
  selectedDiscount: string
  discountChanged: (value: string) => void
  discountItems: string[]
}

const useDiscountTypeItems = (
  isBreakdownByDirectors: boolean,
  locationIds?: number[],
  locationGroupIds?: number[],
): IReturnType => {
  const brand = getBrand()

  let iFilter: any = null
  if (locationIds && locationIds.length > 0) {
    iFilter = { location_ids: [...locationIds] }
  } else if (isBreakdownByDirectors) {
    iFilter = { location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]] }
  } else if (locationGroupIds && locationGroupIds.length > 0) {
    iFilter = { location_group_ids: [...locationGroupIds] }
  }

  const config = useLossPreventionConfig()
  const dateRange = useGetDateRange()
  const variables: INullableType<IGetDiscountItemTypesVariableTypes> = {
    iFilter,
    iStartDate: dateRange?.startDateStr,
    iEndDate: dateRange?.endDateStr,
  }
  const { data } = useQuery<
    IGetDiscountItemTypesType,
    INullableType<IGetDiscountItemTypesVariableTypes>
  >(GET_DISCOUNT_ITEM_TYPES, {
    variables,
    skip: !variables?.iFilter || !variables?.iStartDate || !variables?.iEndDate,
  })

  const discountItemData = data?.getDiscountItemTypes?.nodes
  const [selectedDiscount, setSelectedDiscount] = useState<string>(ALL)

  const discountItems = useMemo(() => {
    const discountColumns = config?.discountTable?.discountColumns || []
    const discountTypes = discountColumns
      ?.map((col: any) => {
        if (discountItemData?.map((item) => item.itemType)?.includes(col.key))
          return col
      })
      .filter((col: any) => col !== undefined)

    return [{ key: ALL, title: ALL }, ...(discountTypes || [])]
  }, [discountItemData])

  return {
    selectedDiscount,
    discountChanged: setSelectedDiscount,
    discountItems,
  }
}

export default useDiscountTypeItems
