import _ from 'lodash'
import React, { useState } from 'react'

import { IPeriod } from 'pared/data/getPeriods'
import { checkDateInCurrentPeriod } from 'pared/utils/date'
import { getUser } from 'pared/utils/user'

import Main from './Main'

interface IProps {
  performanceName: string
  feedback: string
  reviewer: {
    firstName: string
    lastName: string
  }
  formattedReviewedAt: string
  reviewedAt: string
  allPeriods: IPeriod[]
  firstName: string
  lastName: string
  role: string
  storeCode: string
  storeName: string
  managerReviewId: number
  onRefresh: () => Promise<void>
}

function ManagerReview({
  performanceName,
  feedback,
  reviewer,
  formattedReviewedAt,
  reviewedAt,
  allPeriods,
  managerReviewId,
  firstName,
  lastName,
  role,
  storeCode,
  storeName,
  onRefresh,
}: IProps) {
  const [employeeAnchorEl, setEmployeeAnchorEl] = useState(null)

  let isDateInCurrentPeriod = false
  if (Array.isArray(allPeriods) && allPeriods.length > 0) {
    const currentPeriod = allPeriods[0]
    isDateInCurrentPeriod = checkDateInCurrentPeriod(
      reviewedAt,
      _.get(currentPeriod, 'periodStartDate', ''),
      _.get(currentPeriod, 'periodEndDate', ''),
    )
  }

  const user = getUser()
  const userEmployeeId = _.get(user, 'employeeId', 0)

  return (
    <Main
      employeeAnchorEl={employeeAnchorEl}
      setEmployeeAnchorEl={setEmployeeAnchorEl}
      performanceName={performanceName}
      feedback={feedback}
      reviewer={reviewer}
      formattedReviewedAt={formattedReviewedAt}
      isDateInCurrentPeriod={isDateInCurrentPeriod}
      managerReviewId={managerReviewId}
      firstName={firstName}
      lastName={lastName}
      role={role}
      storeCode={storeCode}
      storeName={storeName}
      onRefresh={onRefresh}
      userEmployeeId={userEmployeeId}
    />
  )
}

export default ManagerReview
