import React from 'react'

import useChartCanvas, { IConfigType } from './hooks/useChartCanvas'

export interface IPropsType extends IConfigType {
  width?: number
  height?: number
}

const Chart = ({ width = 800, height = 450, ...props }: IPropsType) => {
  const chartCanvas = useChartCanvas(props)

  return <canvas ref={chartCanvas} width={width} height={height} />
}

export default React.memo(Chart) as unknown as typeof Chart
