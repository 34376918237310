import useLfrListLocationBusinessReview, {
  lfrListLocationBusinessReviewConfigs,
} from './useLfrListLocationBusinessReview'
import useLfrListLocationGroupBusinessReview, {
  lfrListLocationGroupBusinessReviewConfigs,
} from './useLfrListLocationGroupBusinessReview'

export const lfrBusinessReviewConfigs = {
  ...lfrListLocationBusinessReviewConfigs,
  ...lfrListLocationGroupBusinessReviewConfigs,
}

const useLfrBusinessReview = () => {
  const lfrListLocationBusinessReview = useLfrListLocationBusinessReview()
  const lfrListLocationGroupBusinessReview =
    useLfrListLocationGroupBusinessReview()

  return (lfrListLocationBusinessReview?.data?.source || []).length === 0
    ? lfrListLocationGroupBusinessReview
    : lfrListLocationBusinessReview
}

export default useLfrBusinessReview
