import { useMemo } from 'react'

import COLORS from 'pared/constants/colors'
import {
  RAW_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA,
  RAW_PRODUCTIVITY_COEFFICIENT_DATA,
} from 'pared/constants/raw_data/productivityCoefficient'

const useConfig = (
  locationId: keyof typeof RAW_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA,
) =>
  useMemo(
    () => ({
      type: 'line' as const,
      data: {
        labels: RAW_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA[locationId].map(
          ({ period, week }) => `P${period} - week${week}`,
        ),
        datasets: [
          {
            label: 'Systemwide Productivity Coefficient',
            data: RAW_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA[locationId].map(
              ({ value }) => value,
            ),
            borderColor: COLORS.Mango,
            backgroundColor: COLORS.Mango,
          },
          {
            label: 'Store Productivity Coefficient',
            data: RAW_PRODUCTIVITY_COEFFICIENT_DATA.map(({ value }) => value),
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
          {
            type: 'bar' as const,
            yAxisID: 'yRight',
            label: 'Transactions Count',
            data: RAW_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA[locationId].map(
              ({ transactionsCount }) => transactionsCount,
            ),
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Store Level Productivity Coefficient',
          },
          tooltip: {
            mode: 'index' as const,
          },
        },
        scales: {
          y: {
            title: {
              text: 'Productivity Coefficient Percent',
            },
          },
          yRight: {
            position: 'right' as const,
            title: {
              display: true,
              text: 'Transaction Count',
            },
            grid: {
              display: false,
            },
          },
        },
      },
    }),
    [locationId],
  )

export default useConfig
