import CloseIcon from '@material-ui/icons/Close'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

interface IPropsType {
  date: string
  labor: {
    role: string
    time: number
    scheduledCost: number
  }[]
  targetCost: number
  totalTime: number
  totalScheduledCost: number
  overTarget: number
  onClose: () => void
}

const Root = styled.div`
  position: absolute;
  top: -100px;
  left: -60px;
  padding: 30px;
  width: 350px;
  font-family: Lexend-Regular;
  background: white;
  border: 1px solid black;
`

const Title = styled.div`
  margin: 0px 0px 30px;
  font-size: 20px;
  font-weight: 600;
`

const CloseWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  cursor: pointer;
`

const Item = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  font-size: 16px;

  & > div {
    width: 10%;

    &:first-child {
      flex-grow: 1;
    }

    &:not(:first-child) {
      white-space: nowrap;
    }
  }

  &:not(:last-child) {
    margin: 0px 0px 4px;
  }
`

const Border = styled.div`
  margin: 20px 0px;
  border-top: 1px solid black;
`

const SummaryItem = styled(Item)`
  font-weight: 600;

  &:last-child {
    margin: 20px 0px 0px;
  }
`

const Popup = ({
  date,
  labor,
  targetCost,
  totalTime,
  totalScheduledCost,
  overTarget,
  onClose,
}: IPropsType) => (
  <Root>
    <Title>{moment(date).format('MMMM D')}</Title>

    <CloseWrapper onClick={onClose}>
      <CloseIcon />
    </CloseWrapper>

    {labor.map(({ role, time, scheduledCost }) => (
      <Item key={role}>
        <div>{role}</div>

        <div>{time} hr</div>

        <div>${scheduledCost}</div>
      </Item>
    ))}

    <Border />

    <SummaryItem>
      <div>Total Scheduled:</div>

      <div>{totalTime} hr</div>

      <div>${totalScheduledCost}</div>
    </SummaryItem>

    <SummaryItem>
      <div>Labor Target:</div>

      <div>${targetCost}</div>
    </SummaryItem>

    <SummaryItem>
      <div>Over Target:</div>

      <div>${overTarget}</div>
    </SummaryItem>
  </Root>
)

export default React.memo(Popup)
