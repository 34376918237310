import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import { useRef } from 'react'
import styled from 'styled-components'

import { RANKINGS_ROW_TYPE, SYSTEMWIDE_AVG_TEXT } from 'pared/constants'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'

import { getSaleTypeDisplayNames } from '../constants'
import { IStaffSale } from './index'

interface IProps {
  isLoading: boolean
  salesType: string
  setSalesType: any
  staffSales: IStaffSale[]
  orderStaffSales: (
    rawStaffsales: IStaffSale[],
    totalSalesOrPpa: string,
  ) => IStaffSale[]
  isStaffSalesExpanded: boolean
  setIsStaffSalesExpanded: any
  staffSalesRowData: any
}

interface IRankTablTh {
  textAlign?: string
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
  isAvg?: boolean
}

const WebSmallMain = ({
  isLoading,
  salesType,
  setSalesType,
  isStaffSalesExpanded,
  setIsStaffSalesExpanded,
  staffSalesRowData,
}: IProps) => {
  const staffSalesRef = useRef(null)

  if (isLoading) {
    return (
      <LoadingContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </LoadingContainer>
    )
  }

  const brandSettings = getBrandSettings()
  const isQsr = brandSettings.isQsr
  const isLegacy = brandSettings.isLegacy
  const salesmanshipLabels = brandSettings.labels.salesmanship
  const saleTypeDisplayNames = getSaleTypeDisplayNames()

  const handleStaffSalesChange = async (event: any) => {
    const value = _.get(event, 'target.value', '')
    setSalesType(value)
  }

  const onToggleExpandStaffSales = () => {
    setIsStaffSalesExpanded(!isStaffSalesExpanded)
    staffSalesRef.current.scrollIntoView()
  }

  const salesOrPpaHeaderText = _.get(saleTypeDisplayNames, [salesType], '')

  const staffSalesRows = _.map(staffSalesRowData, (s) => {
    const rank = _.get(s, 'rank', '')
    const name = _.get(s, 'name', '')
    const daysWorked = _.get(s, 'daysWorked', '')
    const rowType = _.get(s, 'rowType', '')

    let salesOrPpa = ''
    if (salesType === 'speed_of_service') {
      salesOrPpa = _.get(s, 'sosAvgTime', '')
    } else {
      salesOrPpa = _.get(s, 'salesOrPpa', '')
    }

    return rowType === RANKINGS_ROW_TYPE.AVG ? (
      <AvgTr>
        <RankNumberTd textAlign={'left'} bold></RankNumberTd>
        <RankTableTd textAlign={'left'} isAvg>
          {SYSTEMWIDE_AVG_TEXT}
        </RankTableTd>
        <RankTableTd isAvg>{salesOrPpa}</RankTableTd>
        <RankTableTd isAvg>{daysWorked}</RankTableTd>
      </AvgTr>
    ) : (
      <tr>
        <RankNumberTd textAlign={'left'} bold>
          {rank}
        </RankNumberTd>
        <RankTableTd textAlign={'left'}>{name}</RankTableTd>
        <RankTableTd>{salesOrPpa}</RankTableTd>
        <RankTableTd>{daysWorked}</RankTableTd>
      </tr>
    )
  })

  let toggledStaffSalesRows = staffSalesRows

  if (!isStaffSalesExpanded) {
    toggledStaffSalesRows = _.slice(staffSalesRows, 0, 4)
  }

  const staffSalesToggleText = isStaffSalesExpanded
    ? 'Collapse all'
    : 'Show All'

  return (
    <>
      <a ref={staffSalesRef} />
      <StaffOuterContainer>
        <StaffContainer>
          {salesmanshipLabels.staffSales.toUpperCase()}
        </StaffContainer>

        {isQsr || !isLegacy ? (
          <VerticalSpacer10px />
        ) : (
          <SubtitleContainer>Main Dining and Bar Sales Only</SubtitleContainer>
        )}

        <FilterContainer>
          <SelectorContainer>
            <FormControl
              variant="outlined"
              margin="dense"
              style={{ width: '90vw' }}
            >
              <SalesmanSelect
                displayEmpty
                value={salesType}
                onChange={handleStaffSalesChange}
              >
                <MenuItem key="gross_sales" value="gross_sales">
                  {salesmanshipLabels.totalSales}
                </MenuItem>
                <MenuItem key="app_sales" value="app_sales">
                  {salesmanshipLabels.appetizerSales}
                </MenuItem>
                <MenuItem key="lbw_sales" value="lbw_sales">
                  {salesmanshipLabels.lbwSales}
                </MenuItem>
                <MenuItem key="dessert_sales" value="dessert_sales">
                  {salesmanshipLabels.dessertSales}
                </MenuItem>
                <MenuItem key="total_ppa" value="total_ppa">
                  {salesmanshipLabels.totalPpa}
                </MenuItem>
                <MenuItem key="app_ppa" value="app_ppa">
                  {salesmanshipLabels.appetizerPpa}
                </MenuItem>
                <MenuItem key="lbw_ppa" value="lbw_ppa">
                  {salesmanshipLabels.lbwPpa}
                </MenuItem>
                <MenuItem key="dessert_ppa" value="dessert_ppa">
                  {salesmanshipLabels.dessertPpa}
                </MenuItem>
                {isQsr ? (
                  <MenuItem key="speed_of_service" value="speed_of_service">
                    {salesmanshipLabels.speedOfService}
                  </MenuItem>
                ) : null}
              </SalesmanSelect>
            </FormControl>
          </SelectorContainer>
        </FilterContainer>
      </StaffOuterContainer>
      <StaffTableContainer>
        <RankTable>
          <thead>
            <tr>
              <RankTableTh textAlign={'left'}>Rank</RankTableTh>
              <RankTableTh textAlign={'left'}>Name</RankTableTh>
              <RankTableTh>{salesOrPpaHeaderText}</RankTableTh>
              <RankTableTh>Shifts</RankTableTh>
            </tr>
          </thead>
          <tbody>{toggledStaffSalesRows}</tbody>
        </RankTable>
      </StaffTableContainer>
      <ExpandAllSpan onClick={onToggleExpandStaffSales}>
        {staffSalesToggleText}
      </ExpandAllSpan>
    </>
  )
}

const LoadingContainer = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  height: 400px;
  width: 100%;
`

const AvgTr = styled.tr`
  background-color: ${COLORS.Sky} !important;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const StaffOuterContainer = styled.div`
  display: inline-block;
`

const StaffDescriptionTd = styled.td`
  width: 470px;
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  float: left;
`

const StaffContainer = styled(PageHeader)`
  font-size: 16px;
  float: initial;
`

const FilterContainer = styled.div`
  display: inline-block;
`

const SelectorContainer = styled.div`
  float: left;
`

const SalesmanSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const StaffTableContainer = styled.div`
  margin-top: 30px;
`

const RankTable = styled.table`
  width: 90vw;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`

const RankTableTh = styled.th<IRankTablTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const UpArrowImg = styled.img`
  width: 10px;
  margin: 0 0 2px 5px;
`

const DownArrowImg = styled.img`
  width: 10px;
  margin: 0 0 -1px 5px;
`

const RankTableTd = styled.td<IRankTableTd>`
  color: ${(props) => (props.isAvg ? 'white' : 'black')};
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankNumberTd = styled(RankTableTd)`
  padding-left: 12px;
`

const ExpandAllSpan = styled.div`
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;
  float: right;
`

const SubtitleContainer = styled.div`
  margin-bottom: 10px;
  font-family: Lexend-Regular;
  font-style: italic;
`

const VerticalSpacer10px = styled.div`
  padding: 10px 50px 10px 50px;
  width: 1200px;
`

export default WebSmallMain
