import { gql } from '@apollo/client'

export const TREND_LOCATION_CUSTOMIZED_REPORT = gql`
  query TrendLocationCustomizedReport(
    $iLocationReportName: String!
    $iLocationId: Int!
    $iLocationGroupReportName: String!
    $iLocationGroupId: Int!
    $iEndDate: Date!
    $iGroupBy: String!
  ) {
    trendLocationCustomizedReport(
      iReportName: $iLocationReportName
      iLocationId: $iLocationId
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) {
      nodes {
        startDate
        endDate
        reportResult
      }
    }

    trendLocationGroupCustomizedReport: trendLocationGroupCustomizedReportBypassRls(
      iReportName: $iLocationGroupReportName
      iLocationGroupId: $iLocationGroupId
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) {
      nodes {
        startDate
        endDate
        reportResult
      }
    }
  }
`
