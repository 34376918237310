import { ILossPreventionConfig } from '../useLossPreventionConfig'

export const jscBkConfig: ILossPreventionConfig = {
  summary: {
    isVisible: true,
  },
  kpiSettings: [
    { category: 'basic', key: 'totalComps', title: 'Total Discounts' },
    { category: 'basic', key: 'cashOverOrShort', title: 'Cash +/-' },
    {
      category: 'custom',
      type: 'Deal after Total',
      title: 'Voids',
      key: 'amount',
    },
  ],
  cashHandlingTable: {
    display: false,
    columns: [],
  },
  voidsAndDeletesTable: {
    display: false,
    columns: [],
  },
  discountByEmployeeTable: {
    display: true,
  },
  opsCompsBreakdownTable: {
    display: true,
  },
}
