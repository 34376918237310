import _ from 'lodash'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import COLORS from 'pared/constants/colors'
import { toPercentageString } from 'pared/utils/number'

interface IProps {
  xAxisData: string[]
  yAxisLocationData: number[]
  yAxisSystemwideData: number[]
  locationName: string
}

const DEVICE_WIDTH = window.innerWidth
const CHART_HEIGHT = 350

const HIDDEN_LABEL = 'Hidden'

function WebSmallMain({
  xAxisData,
  yAxisLocationData,
  yAxisSystemwideData,
  locationName,
}: IProps) {
  const chartOptions = {
    title: 'Store Level Performance against System',
    width: DEVICE_WIDTH - 50,
    height: CHART_HEIGHT,
    yLeftAxisLabel: 'Correct Order Percent',
    yLeftTickCallback: (value: number) => {
      return toPercentageString(value, 1)
    },
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        const label = _.get(tooltipItemContext, 'dataset.label', '')
        if (label === HIDDEN_LABEL) {
          return ''
        }

        let datasetLabel = ''
        let separator = ''
        let yValue = ''
        if (tooltipItemContext.dataset && tooltipItemContext.dataset.label) {
          datasetLabel = tooltipItemContext.dataset.label
          separator = ': '
        }
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          yValue = toPercentageString(tooltipItemContext.parsed.y, 1)
        }
        return [datasetLabel, separator, yValue].join('')
      }
      return ''
    },
  }

  return (
    <ScrollableTableContainer>
      <MixedChart
        xAxisData={xAxisData}
        yAxisDataArr={[
          {
            type: 'line',
            data: yAxisLocationData,
            tooltipLabel: `${locationName} Correct Order Percent`,
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
          {
            type: 'line',
            data: yAxisSystemwideData,
            tooltipLabel: 'Systemwide Correct Order Percent',
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
        ]}
        options={chartOptions}
      />
    </ScrollableTableContainer>
  )
}

export default WebSmallMain

const ScrollableTableContainer = styled.div`
  overflow-x: scroll;
  border: 1px solid;
  padding-left: 30px;
  width: 90vw;
  padding-top: 20px;
  padding-bottom: 20px;
`
