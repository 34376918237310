import axios from 'axios'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import { getUser } from 'pared/utils/user'

import BankAccountLinkForm from './BankAccountLinkForm'

// import ListLinkedBankAccounts from './ListLinkedBankAccounts'

function Plaid() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [plaidLinkToken, setPlaidLinkToken] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    const fetchData = async () => {
      let response: any = null

      setIsLoading(true)
      setErrorMessage('')

      const { brand } = getBrandSettings()
      const user = getUser()

      try {
        response = await axios.post(
          `${process.env.REACT_APP_BE_BASE_URL}/plaid/link_token/create`,
          {
            customerBrandCode: brand,
            userId: user.userId,
            userJwt: user.jwt,
          },
          {
            timeout: 60000,
          },
        )
        setIsLoading(false)
      } catch (error: any) {
        console.error('Error:', error)
        setErrorMessage(error.message || 'Fail to get a link token from Plaid')
        setIsLoading(false)
      }

      const linkToken = response?.data?.plaidLinkToken || ''
      if (linkToken) {
        setErrorMessage('')
        setPlaidLinkToken(linkToken)
      } else {
        setPlaidLinkToken('')
      }
    }

    fetchData()
  }, [])

  return (
    <MainContainer>
      {plaidLinkToken ? (
        <BankAccountLinkForm
          plaidLinkToken={plaidLinkToken}
          isLoading={isLoading}
        />
      ) : null}
    </MainContainer>
  )
}

const MainContainer = styled.div`
  padding: 0 20px;
`

const NewBankAccountLink = styled.div`
  color: ${COLORS.Link};
  cursor: pointer;
`

export default Plaid
