import { CORPORATE_LEVEL_NAV_TABS, STORE_LEVEL_NAV_TABS } from 'pared/constants'
import Buns from 'pared/pages/Buns'
import Calls from 'pared/pages/Calls'
import ChangePassword from 'pared/pages/ChangePassword'
import CorporateCalls from 'pared/pages/CorporateCalls'
import CorporateCreditCardReconciliation from 'pared/pages/CorporateCreditCardReconciliation'
import CorporateCustom from 'pared/pages/CorporateCustom'
import CorporateDelivery from 'pared/pages/CorporateDelivery'
import CorporateExpandedPL from 'pared/pages/CorporateExpandedPL'
import CorporateGuest from 'pared/pages/CorporateGuest'
import CorporateInventory from 'pared/pages/CorporateInventory'
import CorporateLossPrevention from 'pared/pages/CorporateLossPrevention'
import CorporateMarketAnalysis from 'pared/pages/CorporateMarketAnalysis'
import CorporatePmix from 'pared/pages/CorporatePmix'
import CorporateProduct from 'pared/pages/CorporateProduct'
import CorporatePurchases from 'pared/pages/CorporatePurchases'
import CorporateSalesmanship from 'pared/pages/CorporateSalesmanship'
import CorporateTeam from 'pared/pages/CorporateTeam'
import CorporateYields from 'pared/pages/CorporateYields'
import Delivery from 'pared/pages/Delivery'
import EmployeeProfile from 'pared/pages/EmployeeProfile'
import ExpoAi from 'pared/pages/ExpoAi'
import Flash from 'pared/pages/Flash'
import ForgotPassword from 'pared/pages/ForgotPassword'
import Guest from 'pared/pages/Guest'
import Inventory from 'pared/pages/Inventory'
import LossPrevention from 'pared/pages/LossPrevention'
import MyDashboard from 'pared/pages/MyDashboard'
import OrderingAnalysis from 'pared/pages/OrderingAnalysis'
import Pmix from 'pared/pages/Pmix'
import Product from 'pared/pages/Product'
import Purchases from 'pared/pages/Purchases'
import Rankings from 'pared/pages/Rankings'
import Reports from 'pared/pages/Reports'
import SalesExaminer from 'pared/pages/SalesExaminer'
import Salesmanship from 'pared/pages/Salesmanship'
import Scorecard from 'pared/pages/Scorecard'
import SignIn from 'pared/pages/SignIn'
import SignUp from 'pared/pages/SignUp'
import StoreDetail from 'pared/pages/StoreDetail'
import StoreList from 'pared/pages/StoreList'
import Unauthorized from 'pared/pages/Unauthorized'
import UserManagement from 'pared/pages/UserManagement'
import Team from 'pared/pages/v1/Team'

import navigator from './navigator'

export interface IRoute {
  key: string
  path: string
  component: React.ElementType
  exact: boolean
  strict: boolean
  isPageFullWidth?: boolean
  navTabType?: string
  accessPage?: string
}

// also need to modify the file `./navigator.tsx` if there is any route changes
export const generalRoutes: IRoute[] = [
  {
    key: 'sign_in_magic_link',
    path: navigator.signInMagicLink(':brand'),
    component: SignIn,
    exact: true,
    strict: false,
  },
]

export const signInAndSignUpRoutes: IRoute[] = [
  {
    key: 'sign_in',
    path: navigator.signIn(':brand'),
    component: SignIn,
    exact: true,
    strict: false,
  },
  {
    key: 'sign_up',
    path: navigator.signUp(':brand'),
    component: SignUp,
    exact: true,
    strict: false,
  },
  {
    key: 'forgot_password',
    path: navigator.forgotPassword(':brand'),
    component: ForgotPassword,
    exact: true,
    strict: false,
  },
]

export const signInRequiredRoutes: IRoute[] = [
  {
    key: 'expo_ai',
    path: navigator.expoAi(':brand'),
    component: ExpoAi,
    exact: true,
    strict: false,
    isPageFullWidth: true,
  },
  {
    key: 'change_password',
    path: navigator.changePassword(':brand'),
    component: ChangePassword,
    exact: true,
    strict: false,
  },
  {
    key: 'systemwide_overview',
    path: navigator.systemwideOverview(':brand'),
    component: StoreList,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'flash',
    path: navigator.flash(':brand'),
    component: Flash,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'store_detail',
    path: navigator.storeDetail(undefined, ':brand'),
    component: StoreDetail,
    exact: true,
    strict: false,
    navTabType: STORE_LEVEL_NAV_TABS,
  },
  {
    key: 'employee_profile',
    path: navigator.employeeProfile(':employeeId', ':brand'),
    component: EmployeeProfile,
    exact: true,
    strict: false,
  },
  {
    key: 'team',
    path: navigator.team(undefined, ':brand'),
    component: Team,
    exact: true,
    strict: false,
    navTabType: STORE_LEVEL_NAV_TABS,
  },
  {
    key: 'my_dashboard',
    path: navigator.myDashboard(),
    component: MyDashboard,
    exact: true,
    strict: false,
  },
  {
    key: 'salesmanship',
    path: navigator.salesmanship(undefined, ':brand'),
    component: Salesmanship,
    exact: true,
    strict: false,
    isPageFullWidth: true,
    navTabType: STORE_LEVEL_NAV_TABS,
  },
  {
    key: 'product',
    path: navigator.product(':brand'),
    component: Product,
    exact: true,
    strict: false,
    navTabType: STORE_LEVEL_NAV_TABS,
  },
  {
    key: 'pmix',
    path: navigator.pmix(undefined, ':brand'),
    component: Pmix,
    exact: true,
    strict: false,
    navTabType: STORE_LEVEL_NAV_TABS,
  },
  {
    key: 'buns',
    path: navigator.buns(undefined, ':brand'),
    component: Buns,
    exact: true,
    strict: false,
    navTabType: STORE_LEVEL_NAV_TABS,
  },
  {
    key: 'loss_prevention',
    path: navigator.lossPrevention(undefined, ':brand'),
    component: LossPrevention,
    exact: true,
    strict: false,
    navTabType: STORE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_inventory',
    path: navigator.corporateInventory(':brand'),
    component: CorporateInventory,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'inventory',
    path: navigator.inventory(undefined, ':brand'),
    component: Inventory,
    exact: true,
    strict: false,
    navTabType: STORE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_purchases',
    path: navigator.corporatePurchases(':brand'),
    component: CorporatePurchases,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'purchases',
    path: navigator.purchases(undefined, ':brand'),
    component: Purchases,
    exact: true,
    strict: false,
    navTabType: STORE_LEVEL_NAV_TABS,
  },
  {
    key: 'rankings',
    path: navigator.rankings(':brand'),
    component: Rankings,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_yields',
    path: navigator.corporateYields(':brand'),
    component: CorporateYields,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_product',
    path: navigator.corporateProduct(':brand'),
    component: CorporateProduct,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_team',
    path: navigator.corporateTeam(':brand'),
    component: CorporateTeam,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_salesmanship',
    path: navigator.corporateSalesmanship(':brand'),
    component: CorporateSalesmanship,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_pmix',
    path: navigator.corporatePmix(':brand'),
    component: CorporatePmix,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_loss_prevention',
    path: navigator.corporateLossPrevention(':brand'),
    component: CorporateLossPrevention,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_sales_examiner',
    path: navigator.corporateSalesExaminer(':brand'),
    component: SalesExaminer,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_expanded_pl',
    path: navigator.corporateExpandedPL(':brand'),
    component: CorporateExpandedPL,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_guest',
    path: navigator.corporateGuest(':brand'),
    component: CorporateGuest,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_delivery',
    path: navigator.corporateDelivery(':brand'),
    component: CorporateDelivery,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_calls',
    path: navigator.corporateCalls(':brand'),
    component: CorporateCalls,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_credit_card_reconciliation',
    path: navigator.corporateCreditCardReconciliation(':brand'),
    component: CorporateCreditCardReconciliation,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
    accessPage: 'credit-card-reconciliation-c7a214a1',
  },
  {
    key: 'delivery',
    path: navigator.delivery(undefined, ':brand'),
    component: Delivery,
    exact: true,
    strict: false,
    navTabType: STORE_LEVEL_NAV_TABS,
  },
  {
    key: 'guest',
    path: navigator.guest(undefined, ':brand'),
    component: Guest,
    exact: true,
    strict: false,
    navTabType: STORE_LEVEL_NAV_TABS,
  },
  {
    key: 'calls',
    path: navigator.calls(undefined, ':brand'),
    component: Calls,
    exact: true,
    strict: false,
    navTabType: STORE_LEVEL_NAV_TABS,
  },
  {
    key: 'scorecard',
    path: navigator.scorecard(undefined, ':brand'),
    component: Scorecard,
    exact: true,
    strict: false,
    isPageFullWidth: true,
    navTabType: STORE_LEVEL_NAV_TABS,
  },
  {
    key: 'user_management',
    path: navigator.userManagement(':brand'),
    component: UserManagement,
    exact: true,
    strict: false,
    isPageFullWidth: true,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'unauthorized',
    path: navigator.unauthorized(undefined, ':brand'),
    component: Unauthorized,
    exact: true,
    strict: false,
  },
  {
    key: 'corporate_custom',
    path: navigator.corporateCustom(':brand'),
    component: CorporateCustom,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'reports',
    path: navigator.reports(':brand'),
    component: Reports,
    exact: true,
    strict: false,
  },
  {
    key: 'ordering_analysis',
    path: navigator.orderingAnalysis(undefined, ':brand'),
    component: OrderingAnalysis,
    exact: true,
    strict: false,
    navTabType: STORE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_market_analysis',
    path: navigator.corporateMarketAnalysis(':brand'),
    component: CorporateMarketAnalysis,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
]

export const drgSalesManagerRoutes = [
  {
    key: 'systemwide_overview',
    path: navigator.systemwideOverview(':brand'),
    component: StoreList,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'corporate_sales_examiner',
    path: navigator.corporateSalesExaminer(':brand'),
    component: SalesExaminer,
    exact: true,
    strict: false,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'user_management',
    path: navigator.userManagement(':brand'),
    component: UserManagement,
    exact: true,
    strict: false,
    isPageFullWidth: true,
    navTabType: CORPORATE_LEVEL_NAV_TABS,
  },
  {
    key: 'change_password',
    path: navigator.changePassword(':brand'),
    component: ChangePassword,
    exact: true,
    strict: false,
  },
  {
    key: 'unauthorized',
    path: navigator.unauthorized(undefined, ':brand'),
    component: Unauthorized,
    exact: true,
    strict: false,
  },
]
