import styled from 'styled-components'

interface IPropsType {
  assignedTracker: string
}

const InstructionText = styled.div`
  text-align: right;
  padding: 10px 0 10px 0;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
`

const InstructionParagraph = styled.div`
  padding: 20px 0 10px 0;
  font-weight: 200;
`

const TrackerInstructionText = ({ assignedTracker }: IPropsType) => {
  let boldInstruction = (
    <div>
      <strong>Items in bold-italic</strong> must be completed by the two-week
      check-in.
    </div>
  )
  let trainingInstruction = <div>* indicates a training reference document</div>

  switch (assignedTracker) {
    case 'Team Leader Milestone Tracker':
      return (
        <InstructionParagraph>
          Team Leaders provide Guests service their way by consistently
          utilizing the behaviors listed below. Team Leaders serve as an example
          for others and a delight to our Guests. Team Leaders support
          restaurant leadership by ensuring crew team productivity and QSC
          efficiency standards are met in assigned areas of the restaurant.
          General responsibilities include assistance with training,
          positioning, and directing the crew team, completing operations
          checklists, setting stock levels, resolving bottlenecks, and
          delegating work tasks assigned by a leader. Additional position
          responsibilities may vary as they are based on each area of
          accountability. Restaurant Leaders may assign additional
          administrative accountabilities.
        </InstructionParagraph>
      )

    case 'Restaurant Leader Milestone Tracker':
    case 'Assistant Restaurant Leader Milestone Tracker':
      return <InstructionText>{trainingInstruction}</InstructionText>

    case 'Breakfast Leader Milestone Tracker':
    case 'Shift Leader Milestone Tracker':
      return (
        <InstructionText>
          {boldInstruction}
          {trainingInstruction}
        </InstructionText>
      )

    case 'Crew Training Milestone Tracker':
    default:
      return null
  }
}

export default TrackerInstructionText
