import {
  calculatePercent,
  calculateVariance,
  calculateVariancePercent,
  getFloat,
} from '../utils'
import { IPlConfig } from './usePlConfig'

interface IPlData {
  type: string
  name?: string
  categorySection?: string
  amount?: number
  budget?: number
  amountLy?: number
  yoyVariance?: number
  yoyVariancePercent?: number
  varianceBudget?: number
  varianceBudgetPercent?: number
  amountPercent?: number
  amountLyPercent?: number
  budgetPercent?: number
  percentageBase?: string
  isGoodIfLessThanBudget?: boolean
  isGoodIfLessThanLastYear?: boolean
}

function processPlDetailData(rawPlData: any[], plConfig: IPlConfig) {
  const sectionPlData: { [section: string]: IPlData[] } = {}

  const displaySequenceInfoMap: {
    [name: string]: {
      percentageBase: string
      isGoodIfLessThanBudget: boolean
      isGoodIfLessThanLastYear: boolean
    }
  } = {}
  plConfig.displaySequence.forEach((sequence) => {
    displaySequenceInfoMap[sequence.name] = {
      percentageBase: sequence.percentageBase,
      isGoodIfLessThanBudget: sequence.isGoodIfLessThanBudget,
      isGoodIfLessThanLastYear: sequence.isGoodIfLessThanLastYear,
    }
  })

  const categorySectionMap: {
    [categorySectionName: string]: {
      amount: number
      yoyAmount: number
      budget: number
    }
  } = {}

  rawPlData.forEach((rawData: any) => {
    const categorySection = rawData.plCategorySection
    const categoryName = rawData.plCategoryName
    const itemName = rawData.plItemName

    const amount = getFloat(rawData.amount) || 0
    const yoyAmount = getFloat(rawData.yoyAmount) || 0
    const budget = getFloat(rawData.budget) || 0

    if (categorySection && !categoryName && !itemName) {
      categorySectionMap[categorySection] = {
        amount,
        yoyAmount,
        budget,
      }
    }

    let dataType = ''
    if (rawData.type === 'plCategorySection') {
      dataType = 'header'
    } else if (rawData.type === 'plCategory') {
      dataType = 'subHeader'
    }

    const isGoodIfLessThanBudget =
      displaySequenceInfoMap[categorySection]?.isGoodIfLessThanBudget || false
    const isGoodIfLessThanLastYear =
      displaySequenceInfoMap[categorySection]?.isGoodIfLessThanLastYear || false

    const budgetVarianceSign = isGoodIfLessThanBudget ? -1.0 : 1.0
    const yoyVarianceSign = isGoodIfLessThanLastYear ? -1.0 : 1.0

    const sectionData = sectionPlData[categorySection] || []

    sectionData.push({
      isGoodIfLessThanBudget,
      isGoodIfLessThanLastYear,
      amount,
      budget,
      type: dataType,
      name: itemName || categoryName || categorySection,
      categorySection: categorySection || '',
      amountLy: yoyAmount,
      yoyVariance: calculateVariance(amount, yoyAmount) * yoyVarianceSign,
      yoyVariancePercent:
        calculateVariancePercent(amount, yoyAmount) * yoyVarianceSign,
      varianceBudget: calculateVariance(amount, budget) * budgetVarianceSign,
      varianceBudgetPercent:
        calculateVariancePercent(amount, budget) * budgetVarianceSign,
      percentageBase: displaySequenceInfoMap[categorySection]?.percentageBase,
    })

    sectionPlData[categorySection] = sectionData
  })

  // calculate summaries
  const summaryPlData: { [summaryName: string]: IPlData } = {}
  plConfig.summaries.forEach((summary) => {
    let summaryAmount = 0
    let summaryYoyAmount = 0
    let summaryBudget = 0

    if (summary.positiveCategorySections) {
      summary.positiveCategorySections.forEach((section) => {
        summaryAmount += categorySectionMap[section]?.amount || 0
        summaryYoyAmount += categorySectionMap[section]?.yoyAmount || 0
        summaryBudget += categorySectionMap[section]?.budget || 0
      })
    }

    if (summary.negativeCategorySections) {
      summary.negativeCategorySections.forEach((section) => {
        summaryAmount -= categorySectionMap[section]?.amount || 0
        summaryYoyAmount -= categorySectionMap[section]?.yoyAmount || 0
        summaryBudget -= categorySectionMap[section]?.budget || 0
      })
    }

    const isGoodIfLessThanBudget =
      displaySequenceInfoMap[summary.name]?.isGoodIfLessThanBudget || false
    const isGoodIfLessThanLastYear =
      displaySequenceInfoMap[summary.name]?.isGoodIfLessThanLastYear || false

    const budgetVarianceSign = isGoodIfLessThanBudget ? -1.0 : 1.0
    const yoyVarianceSign = isGoodIfLessThanLastYear ? -1.0 : 1.0

    summaryPlData[summary.name] = {
      isGoodIfLessThanBudget,
      isGoodIfLessThanLastYear,
      amount: summaryAmount,
      budget: summaryBudget,
      type: 'summary',
      name: summary.name,
      categorySection: '',
      amountLy: summaryYoyAmount,
      yoyVariance:
        calculateVariance(summaryAmount, summaryYoyAmount) * yoyVarianceSign,
      yoyVariancePercent:
        calculateVariancePercent(summaryAmount, summaryYoyAmount) *
        yoyVarianceSign,
      varianceBudget:
        calculateVariance(summaryAmount, summaryBudget) * budgetVarianceSign,
      varianceBudgetPercent:
        calculateVariancePercent(summaryAmount, summaryBudget) *
        budgetVarianceSign,
      percentageBase: displaySequenceInfoMap[summary.name]?.percentageBase,
    }
  })

  // put category sections in correct order
  let sortedPlData: IPlData[] = []
  plConfig.displaySequence.forEach((sequence) => {
    if (sequence.type === 'categorySection') {
      sortedPlData = [...sortedPlData, ...(sectionPlData[sequence.name] || [])]
    } else if (sequence.type === 'summary') {
      if (summaryPlData[sequence.name]) {
        sortedPlData.push({
          type: 'spacer',
        })
        sortedPlData.push(summaryPlData[sequence.name])
      }
    }
  })

  sortedPlData = sortedPlData.map((plData) => {
    const percentageBase = summaryPlData[plData?.percentageBase || '']
    const isGoodIfLessThanBudget = plData?.isGoodIfLessThanBudget || false
    const isGoodIfLessThanLastYear = plData?.isGoodIfLessThanLastYear || false

    const processPlData = {
      ...plData,
      amountPercent:
        plData?.amount === undefined
          ? undefined
          : calculatePercent(plData?.amount || 0, percentageBase?.amount || 0),
      amountLyPercent:
        plData?.amountLy === undefined
          ? undefined
          : calculatePercent(
              plData?.amountLy || 0,
              percentageBase?.amountLy || 0,
            ),
      budgetPercent:
        plData?.budget === undefined
          ? undefined
          : calculatePercent(plData?.budget || 0, percentageBase?.budget || 0),
    }

    if (plConfig.budgetPercentageVarianceType === 'DRG') {
      if (
        (processPlData.amountPercent || processPlData.amountPercent === 0) &&
        (processPlData.budgetPercent || processPlData.budgetPercent === 0)
      ) {
        if (isGoodIfLessThanBudget) {
          processPlData.varianceBudgetPercent =
            processPlData.budgetPercent - processPlData.amountPercent
        } else {
          processPlData.varianceBudgetPercent =
            processPlData.amountPercent - processPlData.budgetPercent
        }
      }
    }

    if (plConfig.yoyPercentageVarianceType === 'DRG') {
      if (
        (processPlData.amountPercent || processPlData.amountPercent === 0) &&
        (processPlData.amountLyPercent || processPlData.amountLyPercent === 0)
      ) {
        if (isGoodIfLessThanLastYear) {
          processPlData.yoyVariancePercent =
            processPlData.amountLyPercent - processPlData.amountPercent
        } else {
          processPlData.yoyVariancePercent =
            processPlData.amountPercent - processPlData.amountLyPercent
        }
      }
    }

    return processPlData
  })

  return sortedPlData
}

export default processPlDetailData
