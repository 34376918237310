import { makeStyles } from '@material-ui/core/styles'
import StarIcon from '@material-ui/icons/Star'
import React from 'react'

const useStyles = makeStyles(() => ({
  label: {
    color: '#FA0C0C',
    width: '15px',
    height: '15px',
  },
}))

const StartLabel = () => {
  const classes = useStyles()

  return <StarIcon className={classes.label} />
}

export default React.memo(StartLabel)
