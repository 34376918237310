import { gql } from '@apollo/client'

export const GET_LOCATION_OPS_COMPS_BREAKDOWN_BY_DAYS_WORKED = gql`
  query getLocationOpsCompsBreakdownByService(
    $locationId: Int!
    $lastNShifts: Int!
    $service: String!
  ) {
    getLocationOpsCompsBreakdownByService(
      iLastNShifts: $lastNShifts
      iLocationId: $locationId
      iService: $service
    ) {
      nodes {
        ctrMenuAdjustComps
        dinnerCertificateComps
        employeeId
        firstName
        foodQualityComps
        lastName
        locationId
        mgrOverrideComps
        percentOpsCompOfGross
        qualityComps
        serviceCompComps
        serviceIssuesComps
        timeComps
        totalChecks
        totalChecksWithComps
        totalGross
        totalGuests
        totalOpsComps
        walkOutComps
        role
      }
    }
  }
`

export const GET_LOCATION_OPS_COMPS_BREAKDOWN_BY_DATE = gql`
  query GetLocationOpsCompsBreakdownByService(
    $locationId: Int!
    $startRange: Date!
    $endRange: Date!
    $service: String!
  ) {
    getLocationOpsCompsBreakdownByService(
      iService: $service
      iEndRange: $endRange
      iLocationId: $locationId
      iStartRange: $startRange
    ) {
      nodes {
        ctrMenuAdjustComps
        dinnerCertificateComps
        employeeId
        firstName
        foodQualityComps
        lastName
        locationId
        mgrOverrideComps
        percentOpsCompOfGross
        qualityComps
        serviceCompComps
        serviceIssuesComps
        timeComps
        totalChecks
        totalChecksWithComps
        totalGross
        totalGuests
        totalOpsComps
        walkOutComps
        role
      }
    }
  }
`

export const GET_LOCATION_OPS_COMPS_BREAKDOWN_BY_DAYS_WORKED_ALL = gql`
  query getLocationOpsCompsBreakdownByService(
    $locationId: Int!
    $lastNShifts: Int!
  ) {
    getLocationOpsCompsBreakdownByService(
      iLastNShifts: $lastNShifts
      iLocationId: $locationId
    ) {
      nodes {
        ctrMenuAdjustComps
        dinnerCertificateComps
        employeeId
        firstName
        foodQualityComps
        lastName
        locationId
        mgrOverrideComps
        percentOpsCompOfGross
        qualityComps
        serviceCompComps
        serviceIssuesComps
        timeComps
        totalChecks
        totalChecksWithComps
        totalGross
        totalGuests
        totalOpsComps
        walkOutComps
        role
      }
    }
  }
`

export const GET_LOCATION_OPS_COMPS_BREAKDOWN_BY_DATE_ALL = gql`
  query GetLocationOpsCompsBreakdownByService(
    $locationId: Int!
    $startRange: Date!
    $endRange: Date!
  ) {
    getLocationOpsCompsBreakdownByService(
      iEndRange: $endRange
      iLocationId: $locationId
      iStartRange: $startRange
    ) {
      nodes {
        ctrMenuAdjustComps
        dinnerCertificateComps
        employeeId
        firstName
        foodQualityComps
        lastName
        locationId
        mgrOverrideComps
        percentOpsCompOfGross
        qualityComps
        serviceCompComps
        serviceIssuesComps
        timeComps
        totalChecks
        totalChecksWithComps
        totalGross
        totalGuests
        totalOpsComps
        walkOutComps
        role
      }
    }
  }
`
