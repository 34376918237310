import { useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'

import { GROUP_BY_PERIOD } from 'pared/data/getDateRanges'
import useGetDateRange from 'pared/hooks/useGetDateRange'

import {
  GET_LOCATION_KPI_TREND,
  IGetLocationKpiTrend,
  IGetLocationKpiTrendNode,
  IGetLocationKpiTrendVariables,
} from '../gqls/useGetLocationKpiTrend'
import useMockData from './useMockData'

const formatPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const OPTIONS = [
  { id: 'root', parentId: null, value: 'root', text: 'root' },
  {
    id: 'amount',
    parentId: 'root',
    value: 'amount',
    text: 'Controllable Labor Target Variance ($)',
  },
  {
    id: 'percent',
    parentId: 'root',
    value: 'percent',
    text: 'Controllable Labor Target Variance (%)',
  },
  // {
  //   id: 'earned-hours',
  //   parentId: 'root',
  //   value: 'earned-hours',
  //   text: 'Actual minus Earned Hours',
  // },
  // {
  //   id: 'all',
  //   parentId: 'earned-hours',
  //   value: 'all',
  //   text: 'All',
  // },
  // {
  //   id: 'cashier',
  //   parentId: 'earned-hours',
  //   value: 'cashier',
  //   text: 'Cashier',
  // },
  // {
  //   id: 'prep',
  //   parentId: 'earned-hours',
  //   value: 'prep',
  //   text: 'Prep',
  // },
  // {
  //   id: 'customer-service',
  //   parentId: 'earned-hours',
  //   value: 'customer-service',
  //   text: 'Customer Service',
  // },
  // {
  //   id: 'grill',
  //   parentId: 'earned-hours',
  //   value: 'grill',
  //   text: 'Grill',
  // },
  // {
  //   id: 'digital-ordering',
  //   parentId: 'earned-hours',
  //   value: 'digital-ordering',
  //   text: 'Digital Ordering',
  // },
]

const useGetLocationKpiTrend = (locationId: number) => {
  const dateRange = useGetDateRange()
  const [option, setOption] = useState([OPTIONS[1].value])
  const { data: locationKpiTrend, loading } = useQuery<
    IGetLocationKpiTrend,
    IGetLocationKpiTrendVariables
  >(GET_LOCATION_KPI_TREND, {
    variables: {
      iLocationId: locationId,
      iGroupBy: GROUP_BY_PERIOD,
      iEndDate: dateRange?.endDateStr || '',
    },
    skip: !dateRange || option[0] === 'earned-hours',
  })
  const mockData = useMockData(option[1])
  const nodes = locationKpiTrend?.getLocationKpiTrend.nodes || mockData || []

  const config = useMemo(() => {
    const type = option.join('-')

    const getValue = (
      node: IGetLocationKpiTrendNode | NonNullable<typeof mockData>[number],
    ) => {
      if (type === 'amount' && 'intermediateValues' in node)
        return node.intermediateValues.hourly_labor_budget_variance_amount || 0

      return node.value || 0
    }

    const getIntermediateValue = (
      node: IGetLocationKpiTrendNode,
      intermediateValue: 'target' | 'actual',
    ) => {
      if (type === 'amount')
        return (
          node.intermediateValues[
            intermediateValue === 'target'
              ? 'hourly_labor_cost_budget'
              : 'hourly_labor_cost'
          ] || 0
        )

      return (
        node.intermediateValues[
          intermediateValue === 'target'
            ? 'hourly_labor_percent_budget'
            : 'hourly_labor_percent'
        ] || 0
      )
    }

    const format = (value: number) => {
      switch (type) {
        case 'amount':
          return formatPrice.format(value / 100)

        case 'percent':
          return `${value.toFixed(2)}%`

        default:
          return value
      }
    }

    return {
      type: 'bar' as const,
      data: {
        labels: nodes.map(
          ({ groupingIndex }) =>
            `P${groupingIndex % 100} ${Math.floor(groupingIndex / 100)}`,
        ),
        datasets: [
          {
            data: nodes.map(getValue),
            backgroundColor: nodes.map((node) =>
              getValue(node) >= 0
                ? 'hsla(0, 99%, 69%, 0.5)'
                : 'hsla(130, 35%, 58%, 0.5)',
            ),
          },
        ],
      },
      options: {
        plugins: {
          tooltip: {
            mode: 'index' as const,
            callbacks: {
              label: ({ dataIndex }: { dataIndex: number }) => {
                const node = nodes[dataIndex]
                if ('intermediateValues' in node) {
                  const actual = format(getIntermediateValue(node, 'actual'))
                  const target = format(getIntermediateValue(node, 'target'))
                  const variance = format(getValue(node))
                  return `Actual: ${actual}; Target: ${target}; Variance: ${variance}`
                }

                return format(getValue(node))
              },
            },
          },
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            ticks: {
              callback: format,
            },
          },
        },
      },
    }
  }, [nodes, option])

  return {
    config,
    option,
    options: OPTIONS,
    onChange: setOption,
    isLoading: loading,
  }
}

export default useGetLocationKpiTrend
