import React from 'react'
import styled from 'styled-components'

export interface IPropsType {
  children: React.ReactElement
}

export interface IConfigsType {
  type: 'grid'
}

const Container = styled.div`
  display: flex;
  gap: 25px;
`

const Grid = ({ children }: IPropsType) => <Container>{children}</Container>

export default Grid
