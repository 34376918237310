import { useMemo } from 'react'

import { getBrandSettings } from 'pared/customer'
import { toPercentString, toUsdString } from 'pared/utils/number'

import useConfig from './useConfig'
import { IDataSourceType } from './useDataSource'

interface ICsvDataType {
  [key: string]: string | number
}

const useCsvData = (
  isBreakdownByDirectors: boolean,
  groupByType: string,
  csvDataSource: IDataSourceType[],
): ICsvDataType[] => {
  const brandSettings = getBrandSettings()
  const businessLabels = brandSettings.labels.business
  const creditCardTypeconfig = useConfig()

  return useMemo(() => {
    return csvDataSource?.map((d) => {
      const store = (() => {
        if (d.rowType != 'header') return ''

        const groupInfo = d.groupInfo
        if (!groupInfo) return ''

        const storeCode =
          'locationCode' in groupInfo ? groupInfo.locationCode : 'Unkown'
        const storeName =
          'locationName' in groupInfo ? groupInfo.locationName : 'Unkown'
        return `${storeCode} - ${storeName}`
      })()

      const groupBy = (() => {
        if (d.rowType != 'header') return ''

        const groupInfo = d.groupInfo
        if (!groupInfo) return 'Summary'

        if (!('locationGroupName' in groupInfo)) return ''

        return groupInfo.locationGroupName
      })()

      const director = (() => {
        if (d.rowType != 'header') return null

        const groupInfo = d.groupInfo
        if (!groupInfo) return 'Summary'

        if (!('directorName' in groupInfo)) return ''

        return groupInfo.directorName
      })()

      return {
        ...(isBreakdownByDirectors
          ? {}
          : {
              Store: store,
            }),
        ...(groupByType !== 'store'
          ? {
              [groupByType]: groupBy,
            }
          : {
              [businessLabels['director']]: director,
            }),
        'Credit Card Type':
          creditCardTypeconfig.find((config) => config.key === d.creditCardType)
            ?.title || d.creditCardType,
        R365: d.salesAmount ? toUsdString(d.salesAmount / 100.0, 2) : '0',
        Fiserv: d.paymentAmount ? toUsdString(d.paymentAmount / 100.0, 2) : '0',
        'Over/Under $': d.overUnderAmount
          ? toUsdString(d.overUnderAmount / 100.0, 2)
          : '0',
        'Over/Under %': d.overUnderPercent
          ? toPercentString(d.overUnderPercent, 3)
          : '0',
      }
    })
  }, [isBreakdownByDirectors, groupByType, csvDataSource])
}

export default useCsvData
