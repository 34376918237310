import { useMemo } from 'react'

import { IColumnsType } from 'pared/components/basicUi/table'
import { getBrand } from 'pared/utils/brand'

import { getPrice } from '../utils'
import { IDiscountsDataType } from './useDiscounts'
import useConfig from './useLossPreventionConfig'

export default (): IColumnsType<IDiscountsDataType>[] => {
  const config = useConfig(getBrand())
  return useMemo(
    () => [
      {
        key: 'rank',
        title: 'Rank',
        align: 'center',
      },
      {
        key: 'employeeInfo',
        title: 'Name',
        render: ({
          preferredName,
          familyName,
        }: IDiscountsDataType['employeeInfo']) =>
          `${preferredName} ${familyName}`,
      },
      {
        key: 'roleInfo.name',
        title: 'Position',
        sorter: true,
      },
      {
        key: 'opsComps',
        title: 'Total Discounts',
        align: 'center',
        render: getPrice,
        sorter: true,
      },
      {
        key: 'totalSales',
        title: 'Gross Sales',
        align: 'center',
        render: getPrice,
        sorter: true,
      },
      {
        key: 'opsCompsPercent',
        title: 'Discount %',
        align: 'center',
        render: (value: IDiscountsDataType['opsCompsPercent']) =>
          !value ? '' : `${value.toFixed(1)}%`,
        cellStyle: (
          value: IDiscountsDataType['opsCompsPercent'],
          data?: IDiscountsDataType,
        ) => `
          background: ${data?.opsCompsPercentColor};
        `,
        sorter: true,
      },
      ...(config.discountTable?.isDetailsColHidden
        ? []
        : [
            {
              key: 'opsCompsDetails',
              title: 'Discount Types',
              render: (value: IDiscountsDataType['opsCompsDetails']) =>
                value
                  ?.map(({ type, count }) => {
                    if (count || count === 0) {
                      return `${type} (${count})`
                    } else {
                      return type
                    }
                  })
                  .join(', '),
            },
          ]),
    ],
    [],
  )
}
