import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/Help'
import debounce from 'lodash/debounce'
import React, {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import { InputWithLeftLabel } from 'pared/components/basicUi/InputWithLeftLabel'
import ExpoSelect from 'pared/components/basicUi/select'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBusinessLabel } from 'pared/customer'
import Unauthorized from 'pared/pages/Unauthorized'
import { decodedUserJwtToken } from 'pared/reactiveVariables/user'
import { getBrand, getBrandId } from 'pared/utils/brand'

import ExistingUserTable from './ExistingUserTable'
import PageViewsTable from './PageViews'
import UserCreatedMessage from './UserCreatedMessage'
import UserEventLogTable from './UserEventLog'
import {
  MUTATION_REGISTER_USER,
  QUERY_GET_ACCESSIBLE_BRANDS,
  QUERY_GET_EMPLOYEE_BY_ID,
  QUERY_GET_LOCATIONS_FOR_BRAND,
  QUERY_GET_LOCATION_ACCESS_GROUPS_FOR_BRAND,
  QUERY_GET_ROLES_FOR_BRAND,
} from './gql'
import useLocationAccessGroupOptions from './hooks/useLocationAccessGroupsOptions'

interface IBrand {
  id: number
  name: string
}

interface IRole {
  id: number
  name: string
}

interface ILocation {
  id: number
  name: string
  code: string
  brandId?: string
}

interface ILocationAccessGroup {
  id: number
  name: string
  type: string
}

const initialFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  locationCode: '',
  employeeId: '',
}

const useStyles = makeStyles({
  text: {
    fontFamily: 'Lexend-Regular',
  },
})

const UserManagement = ({ navParams }: { navParams: INavParams }) => {
  const classes = useStyles()
  const [roles, setRoles] = useState<Array<IRole>>([])
  const [locations, setLocations] = useState<Array<ILocation>>([])
  const [locationAccessGroups, setLocationAccessGroups] = useState<
    Array<ILocationAccessGroup>
  >([])
  const locationAccessGroupOptions =
    useLocationAccessGroupOptions(locationAccessGroups)
  const [formValues, setFormValues] = useState(initialFormValues)
  const [employeeId, setEmployeeId] = useState('')
  const [employeeIdError, setEmployeeIdError] = useState('')

  const [brandId, setBrandId] = useState('')
  const [roleId, setRoleId] = useState('')
  const [locationId, setLocationId] = useState('')
  const [locationCode, setLocationCode] = useState('')
  const [locationName, setLocationName] = useState('')
  const [locationCodeError, setLocationCodeError] = useState(false)
  const [locationAccessGroupId, setLocationAccessGroupId] = useState([''])

  const [registerUser] = useMutation(MUTATION_REGISTER_USER)
  const [errorMessage, setErrorMessage] = useState('')
  const [hasError, setHasError] = useState(false)
  const [hasFormSubmitted, setHasFormSubmitted] = useState(false)
  const [hasUserCreated, setHasUserCreated] = useState(false)
  const [shouldSendEmail, setShouldSendEmail] = useState(false)
  const [tab, setTab] = useState('manage')

  // This query executes when the component mounts
  const { data: fetchedBrands } = useQuery(QUERY_GET_ACCESSIBLE_BRANDS)
  const [getEmployee] = useLazyQuery(QUERY_GET_EMPLOYEE_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (Array.isArray(data?.getEmployeeById?.nodes)) {
        const employee = data.getEmployeeById.nodes[0]
        if (employee) {
          setFormValues({
            ...formValues,
            firstName: employee.givenName || '',
            lastName: employee.familyName || '',
            email: employee.workEmail || '',
            employeeId: employee.id,
          })

          const selectedLocation = locations.find(
            ({ id }) => id === parseInt(employee.locationId),
          )

          if (selectedLocation) {
            setLocationId(employee.locationId)
            setLocationCode(selectedLocation.code)
            setLocationCodeError(false)
            setLocationName(selectedLocation.name)
            setBrandId(employee.brandId)
          }
        } else {
          setEmployeeIdError('No employee found')
          setLocationId('')
          setLocationCode('')
          setLocationName('')
          setFormValues({
            ...formValues,
            employeeId: '',
          })
        }
      } else {
        setEmployeeIdError('No employee found')
        setLocationId('')
        setLocationCode('')
        setLocationName('')
        setFormValues({
          ...formValues,
          employeeId: '',
        })
      }
    },
  })

  const [getRolesForBrand] = useLazyQuery(QUERY_GET_ROLES_FOR_BRAND, {
    onCompleted: (data) => {
      const fetchedRoles: IRole[] = data?.getRolesForBrand?.nodes || []
      setRoles(fetchedRoles)
      const storeUser = fetchedRoles.find(({ name }) => name === 'Store User')
      if (storeUser) {
        setRoleId(`${storeUser.id}`)
      }
    },
  })

  const [getLocationsForBrand] = useLazyQuery(QUERY_GET_LOCATIONS_FOR_BRAND, {
    onCompleted: (data) => {
      setLocations(data?.getLocationsForBrand?.nodes || [])
    },
  })

  const [getLocationAccessGroupsForBrand] = useLazyQuery(
    QUERY_GET_LOCATION_ACCESS_GROUPS_FOR_BRAND,
    {
      onCompleted: (data) => {
        setLocationAccessGroups(
          data?.getLocationAccessGroupsForBrand?.nodes || [],
        )
      },
    },
  )

  useEffect(() => {
    const currentBrandId = getBrandId()
    const defaultBrandId =
      fetchedBrands?.getAccessibleBrandsForUser?.nodes?.find(
        ({ id }: { id: number }) => id === currentBrandId,
      )?.id

    if (defaultBrandId) {
      setBrandId(defaultBrandId)
    }
  }, [fetchedBrands])

  useEffect(() => {
    if (brandId) {
      getRolesForBrand({
        variables: {
          brandId,
        },
      })

      getLocationsForBrand({
        variables: {
          brandId,
        },
      })

      getLocationAccessGroupsForBrand({
        variables: {
          brandId,
        },
      })
    }
  }, [brandId])

  const findMatchingLocation = useCallback(
    debounce((locationCode: string) => {
      if (locationCode === '') {
        setLocationCodeError(false)
        return
      }

      const matchingLocation = locations.find(
        ({ code }) => code === locationCode,
      )
      if (matchingLocation) {
        setLocationId(`${matchingLocation.id}`)
        setLocationCodeError(false)
      } else {
        setLocationCodeError(true)
      }
    }, 300),
    [locations],
  )

  const searchEmployee = (id: string) => {
    getEmployee({
      variables: {
        id: parseInt(id),
      },
    })
  }

  const debouncedSearchEmployee = useCallback(debounce(searchEmployee, 300), [])

  const handleInputChange = (e: SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleEmployeeIdChange = (e: SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement
    setEmployeeId(value)
    setEmployeeIdError('')
    if (value.length > 0) {
      debouncedSearchEmployee(value)
    }
  }

  const handleLocationCodeChange = (e: SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement
    setLocationCode(value)
    findMatchingLocation(value)
  }

  const handleBrandChange = (e: ChangeEvent<{}>) => {
    const { value } = e.target as HTMLSelectElement
    setBrandId(value)
    setRoleId('')
    setLocationId('')
    setLocationCode('')
    setLocationCodeError(false)
    setLocationAccessGroupId([''])
  }

  const handleRoleChange = (e: ChangeEvent<{}>) => {
    const { value } = e.target as HTMLSelectElement
    setRoleId(value)
  }

  const handleLocationChange = (e: ChangeEvent<{}>) => {
    const { value } = e.target as HTMLSelectElement
    const selectedLocation = locations.find(({ id }) => id === parseInt(value))
    if (selectedLocation) {
      setLocationCode(selectedLocation.code)
      setLocationCodeError(false)
    }

    setLocationId(value)
  }

  const handleSendEmailChange = () => {
    setShouldSendEmail((prevValue) => !prevValue)
  }

  const onSubmit = async () => {
    let hasError = false
    const requiredFields = ['firstName', 'lastName', 'email', 'password']
    const keys = Object.keys(formValues)
    keys.forEach((key) => {
      if (
        requiredFields.includes(key) &&
        !formValues[key as keyof typeof formValues]
      ) {
        hasError = true
      }
    })

    if (!brandId || !roleId) {
      hasError = true
    }

    const selectedLocation = locations.find(
      ({ id }) => id === parseInt(locationId),
    )
    if (selectedLocation) {
      setLocationCode(selectedLocation.code)
      setLocationCodeError(false)
    }

    if (hasError) {
      setHasError(true)
      setErrorMessage('Required fields are outlined in red')
      return
    }

    try {
      setHasFormSubmitted(true)
      setHasError(false)
      setErrorMessage('')

      const email = (formValues.email || '').trim().toLowerCase()

      await registerUser({
        variables: {
          email,
          firstName: (formValues.firstName || '').trim(),
          lastName: (formValues.lastName || '').trim(),

          // TODO: auto generate a more secure initial password after we have a fully functioned user management UI.
          password: (formValues.password || '').trim(),

          locationId: parseInt(locationId),
          roleId: parseInt(roleId),
          locationAccessGroupId: parseInt(
            locationAccessGroupId[locationAccessGroupId.length - 1],
          ),
          locationCode: locationCode.trim() || null,
          employeeId: employeeId ? parseInt(employeeId) : null,
        },
      })

      if (shouldSendEmail) {
        const brand = getBrand()
        await fetch(
          `${process.env.REACT_APP_BE_BASE_URL}/send_new_user_creation_email`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email,
              brand,
            }),
          },
        )
      }

      setErrorMessage('')
      setHasUserCreated(true)
      setHasFormSubmitted(false)
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.search(/duplicate key/g) >= 0) {
          setErrorMessage('This user already exists')
        } else {
          setErrorMessage(error.message)
        }
      }
      setHasFormSubmitted(false)
      return
    }
  }

  const clearForm = () => {
    setFormValues(initialFormValues)
    setRoleId('')
    setLocationId('')
    setLocationCode('')
    setLocationCodeError(false)
    setLocationAccessGroupId([''])
    setEmployeeId('')

    setHasError(false)
    setErrorMessage('')
    setHasUserCreated(false)
    setHasFormSubmitted(false)
  }

  const brands: IBrand[] =
    fetchedBrands?.getAccessibleBrandsForUser?.nodes || []
  const storeText = getBusinessLabel('store')

  if (
    decodedUserJwtToken() &&
    decodedUserJwtToken().can_manage_users === false
  ) {
    return <Unauthorized />
  }

  const userCreationForm = (
    <Container>
      <FlexContainer>
        <TitleDiv
          isClickable={tab === 'manage'}
          onClick={() => setTab('create')}
        >
          Create
        </TitleDiv>
        <TitleDiv>/</TitleDiv>
        <TitleDiv
          isClickable={tab === 'create'}
          onClick={() => setTab('manage')}
        >
          Manage
        </TitleDiv>
      </FlexContainer>
      {tab === 'create' ? (
        <>
          <Subtitle>User Information</Subtitle>
          <form>
            <FlexContainer>
              <InputWithLeftLabel label="Employee ID">
                {/* <Tooltip title="Optional: Find your employee by entering an ID from your HR system">
                  <IconButton style={{ position: 'absolute', marginLeft: '85px' }}>
                    <HelpIcon />
                  </IconButton>
                </Tooltip> */}
                <TextField
                  name="employeeId"
                  variant="outlined"
                  value={employeeId}
                  onChange={handleEmployeeIdChange}
                  error={!!employeeIdError}
                  helperText={employeeIdError}
                  fullWidth
                />
              </InputWithLeftLabel>
              {locationName && (
                <InputWithLeftLabel label="Store">
                  <span>{locationName}</span>
                </InputWithLeftLabel>
              )}
            </FlexContainer>
            <VerticalSpacer20px />
            <FlexContainer>
              <InputWithLeftLabel label="First Name*">
                <TextField
                  name="firstName"
                  variant="outlined"
                  value={formValues.firstName}
                  onChange={handleInputChange}
                  error={hasError && !formValues.firstName}
                  fullWidth
                />
              </InputWithLeftLabel>
              <InputWithLeftLabel label="Last Name*">
                <TextField
                  name="lastName"
                  variant="outlined"
                  value={formValues.lastName}
                  onChange={handleInputChange}
                  error={hasError && !formValues.lastName}
                  fullWidth
                />
              </InputWithLeftLabel>
            </FlexContainer>
            <VerticalSpacer20px />
            <InputWithLeftLabel label="Email*">
              <TextField
                name="email"
                variant="outlined"
                value={formValues.email}
                onChange={handleInputChange}
                error={hasError && !formValues.email}
                fullWidth
              />
            </InputWithLeftLabel>
            <VerticalSpacer20px />
            <InputWithLeftLabel label="Password*">
              <TextField
                name="password"
                type="password"
                variant="outlined"
                value={formValues.password}
                onChange={handleInputChange}
                error={hasError && !formValues.password}
                fullWidth
              />
            </InputWithLeftLabel>
            <VerticalSpacer />

            <Subtitle>User Access</Subtitle>
            <InputWithLeftLabel label="Assign Brand*">
              <Select
                fullWidth
                variant="outlined"
                value={brandId}
                displayEmpty
                onChange={handleBrandChange}
                error={hasError && !brandId}
                className={`${classes.text}`}
              >
                <MenuItem value="" disabled className={`${classes.text}`}>
                  Select Brand
                </MenuItem>
                {brands.map((brand: IBrand) => (
                  <MenuItem
                    key={brand.id}
                    value={brand.id}
                    className={`${classes.text}`}
                  >
                    {brand.name}
                  </MenuItem>
                ))}
              </Select>
            </InputWithLeftLabel>
            <VerticalSpacer20px />

            <InputWithLeftLabel label="User Role*">
              <Select
                fullWidth
                variant="outlined"
                value={roleId}
                displayEmpty
                onChange={handleRoleChange}
                error={hasError && !roleId}
                className={`${classes.text}`}
              >
                <MenuItem value="" disabled className={`${classes.text}`}>
                  Select Role
                </MenuItem>
                {roles.map((role) => (
                  <MenuItem
                    key={role.id}
                    value={role.id}
                    className={`${classes.text}`}
                  >
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </InputWithLeftLabel>
            <VerticalSpacer20px />

            <FlexContainer>
              <InputWithLeftLabel label={`Assign ${storeText}`}>
                <Select
                  fullWidth
                  variant="outlined"
                  value={locationId}
                  displayEmpty
                  onChange={handleLocationChange}
                  className={`${classes.text}`}
                >
                  <MenuItem value="" className={`${classes.text}`}>
                    {`Select ${storeText}`}
                  </MenuItem>
                  {locations.map((location) => (
                    <MenuItem
                      key={location.id}
                      value={location.id}
                      className={`${classes.text}`}
                    >
                      {location.name}
                    </MenuItem>
                  ))}
                </Select>
              </InputWithLeftLabel>
              <InputWithLeftLabel label={`${storeText} Code`}>
                <TextField
                  name="locationCode"
                  variant="outlined"
                  value={locationCode}
                  onChange={handleLocationCodeChange}
                  error={locationCodeError}
                  placeholder="Start typing..."
                  fullWidth
                  disabled={locations.length === 0}
                />
              </InputWithLeftLabel>
            </FlexContainer>
            <VerticalSpacer20px />

            <InputWithLeftLabel label="Assign Group">
              <ExpoSelect
                value={locationAccessGroupId}
                onChange={setLocationAccessGroupId}
                dataSource={locationAccessGroupOptions}
                fullWidth
                displayEmpty
              />
            </InputWithLeftLabel>
            <VerticalSpacer />
            <FormControlLabel
              control={
                <Checkbox
                  checked={shouldSendEmail}
                  onChange={handleSendEmailChange}
                />
              }
              label="Send user an email after account creation"
            />
            <VerticalSpacer20px />

            <Footer>
              {hasFormSubmitted ? (
                <div>Submitting ...</div>
              ) : (
                <ButtonContainer>
                  <ClearButton onClick={clearForm}>Cancel</ClearButton>
                  <SubmitButton onClick={onSubmit}>Save</SubmitButton>
                </ButtonContainer>
              )}
              <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
            </Footer>
          </form>
        </>
      ) : null}
      {tab === 'manage' ? <ExistingUserTable /> : null}
      {navParams.canViewUserEventLogs ? (
        <>
          <VerticalSpacer />
          <UserEventLogTable />
          <VerticalSpacer />
          <PageViewsTable />
        </>
      ) : null}
    </Container>
  )

  if (hasUserCreated) {
    return (
      <UserCreatedMessage
        firstName={(formValues.firstName || '').trim()}
        lastName={(formValues.lastName || '').trim()}
        email={(formValues.email || '').trim()}
        password={(formValues.password || '').trim()}
        onBackToUserCreationForm={clearForm}
        wasEmailSent={shouldSendEmail}
      />
    )
  } else {
    return userCreationForm
  }
}

export default React.memo(UserManagement)

const Container = styled.div`
  min-width: 1000px;
  padding: 0 40px;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  @media ${MOBILE_WIDTH} {
    min-width: 100%;
    padding: 30px 25px 50px;
  }
`

const TitleDiv = styled.div<{ isClickable?: boolean }>`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 40px;
  ${({ isClickable }) =>
    isClickable
      ? `
      color: ${COLORS.Link};
      cursor: pointer;
      text-decoration: underline;
    `
      : ''}
`

const Subtitle = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  margin-bottom: 20px;
`

const FlexContainer = styled.div`
  display: flex;
  gap: 30px;
  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    gap: 20px;
  }
`

const VerticalSpacer = styled.div`
  height: 50px;
`

const VerticalSpacer20px = styled.div`
  height: 20px;
`

const Footer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  @media ${MOBILE_WIDTH} {
    margin-left: 0;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`

const SubmitButton = styled.div`
  background-color: ${COLORS.Expo};
  color: white;
  width: 150px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  font-family: Lexend-Semibold;
  cursor: pointer;
  &:hover {
    opacity: 50%;
  }
`

const ClearButton = styled(SubmitButton)`
  background-color: #cfcece;
  color: black;
`

const ErrorMessageContainer = styled.div`
  color: red;
`
