import { gql } from '@apollo/client'

export const GET_LOCATION_INFO = gql`
  query GetLocationInfo($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        brandId
        directorId
        directorEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        gmEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
      }
    }
  }
`

export const GET_LOCATION_TEAM_DATA = gql`
  query GetLocationTeamData(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
    $isTeamV2: Boolean!
  ) {
    listLocationTeamKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $isTeamV2) {
      nodes {
        locationId
        startDate
        endDate
        managerPar
        latestHourlyCount
        latestSalariedCount
        hourlyTurnoverCount
        salariedTurnoverCount
        annualizedHourlyTurnoverPercent
        annualizedSalariedTurnoverPercent
        annualizedHourlyTurnoverRank
        annualizedSalariedTurnoverRank
        totalLocationCount
      }
    }

    listLocationTeamKpisV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $isTeamV2) {
      nodes {
        locationId
        startDate
        endDate
        managerPar
        latestHourlyCount
        latestSalariedCount
        hourlyTurnoverCount
        salariedTurnoverCount
        annualizedHourlyTurnoverPercent
        annualizedSalariedTurnoverPercent
        annualizedHourlyTurnoverRank
        annualizedSalariedTurnoverRank
        totalLocationCount
      }
    }

    listLocationUnfilledManagerialPositions(iFilter: $iFilter) {
      nodes {
        positionId
        locationId
        managerRoleCode
        managerRoleName
        vacantSince
        candidateName
        filledPendingStartDate
        trainingStoreId
        trainingStoreCode
        trainingStoreName
        comment
        commentedAt
        commentedByEmployeeId
        commentedByFirstName
        commentedByLastName
      }
    }
  }
`

export const TREND_LOCATION_TEAM_KPIS = gql`
  query TrendLocationTeamKpis(
    $iLocationId: Int!
    $iLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $isTeamV2: Boolean!
  ) {
    trendLocationTurnovers(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) @skip(if: $isTeamV2) {
      nodes {
        locationId
        startDate
        endDate
        hourlyTurnoverCount
        hourlyTurnoverPercent
        annualizedHourlyTurnoverPercent
      }
    }

    trendLocationTurnoversV2(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) @include(if: $isTeamV2) {
      nodes {
        locationId
        startDate
        endDate
        hourlyTurnoverCount
        hourlyTurnoverPercent
        annualizedHourlyTurnoverPercent
      }
    }

    trendLocationGroupTurnovers(
      iLocationGroupId: $iLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) @skip(if: $isTeamV2) {
      nodes {
        locationGroupId
        startDate
        endDate
        hourlyTurnoverCount
        annualizedHourlyTurnoverPercent
        annualizedSalariedTurnoverPercent
      }
    }

    trendLocationGroupTurnoversV2(
      iLocationGroupId: $iLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) @include(if: $isTeamV2) {
      nodes {
        locationGroupId
        startDate
        endDate
        hourlyTurnoverCount
        annualizedHourlyTurnoverPercent
        annualizedSalariedTurnoverPercent
      }
    }
  }
`

export interface ISummaryNodeType {
  locationInfo: {
    code: string
    name: string
    id: number
  }
  thirtyDayTurnoverRate: number
  sixtyDayTurnoverRate: number
  ninetyDayTurnoverRate: number
  annualizedHourlyTurnoverPercent: number
  corporateCurrentAnnualizedHourlyTurnoverPercent
}

export interface ISummaryType {
  corporateTeamSummary: {
    nodes: ISummaryNodeType[]
  }

  corporateTeamSummaryV2: {
    nodes: ISummaryNodeType[]
  }
}

export interface ISummaryVariablesType {
  iLocationGroupId: number
}

export const GET_CORPORATE_TEAM_SUMMARY = gql`
  query getCorporateTeamSummary($iLocationGroupId: Int!) {
    corporateTeamSummary(iLocationGroupId: $iLocationGroupId) {
      nodes {
        locationInfo
        thirtyDayTerminationCount
        thirtyDayHireCount
        thirtyDayTurnoverRate
        sixtyDayTerminationCount
        sixtyDayHireCount
        sixtyDayTurnoverRate
        ninetyDayTerminationCount
        ninetyDayHireCount
        ninetyDayTurnoverRate
        annualizedHourlyTurnoverPercent
        corporateThirtyDayTurnoverRate
        corporateSixtyDayTurnoverRate
        corporateNinetyDayTurnoverRate
        corporateCurrentAnnualizedHourlyTurnoverPercent
        corporateCurrentAnnualizedSalariedTurnoverPercent
        corporatePastAnnualizedHourlyTurnoverPercent
        corporatePastAnnualizedSalariedTurnoverPercent
      }
    }
  }
`
