import _ from 'lodash'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { track } from 'pared/analytics/storeList'
import {
  ChangeEmployeeButton,
  ChangeLocationButton,
  ChangePasswordButton,
  DeactivateUserButton,
  IUserManagementData,
  SendEmailButton,
} from 'pared/components/UserManagement'
import { IColumnsType } from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'
import { getLocationCode, getLocationName } from 'pared/utils/location'
import { toPercentString, toUsdString } from 'pared/utils/number'

import { IReportDefinition, IReportResult, ITableColumnDef } from '../types'

const LinkButton = styled(Link)`
  color: ${COLORS.Link};
`

const Span = styled.span<{ danger: boolean }>`
  color: ${({ danger }) => (danger ? 'red' : COLORS.Chalkboard)};
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StringSplitContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

function getColumnDataRender(columnDef: ITableColumnDef, refetch: any) {
  return (columnData: any, allData?: any) => {
    let displayItem: React.ReactNode | string = '-'
    let value = 0
    switch (columnDef.dataType) {
      case 'STRING': {
        displayItem = columnData
        break
      }

      case 'STRING_SPLIT': {
        if (columnData) {
          const [partOne, partTwo] = columnData.split(' - ')
          displayItem = (
            <StringSplitContainer>
              <div>{partOne}</div>
              <div>{partTwo}</div>
            </StringSplitContainer>
          )
        } else {
          displayItem = columnData
        }

        break
      }

      case 'INTEGER': {
        value = parseInt(columnData, 10)
        if (value || value === 0) {
          displayItem = value.toLocaleString('en-US')
        }
        break
      }
      case 'INTEGER_NO_COMMA': {
        value = parseInt(columnData, 10)
        if (value || value === 0) {
          displayItem = value
        }
        break
      }
      case 'CENT': {
        value = parseInt(columnData)
        if (Math.abs(value) > 10000) {
          displayItem = toUsdString(value / 100.0, 0)
        } else {
          displayItem = toUsdString(value / 100.0, 2)
        }
        break
      }
      case 'DOLLAR': {
        value = parseFloat(columnData)
        if (value === 0 || Math.abs(value) > 100) {
          displayItem = toUsdString(value, 0)
        } else {
          displayItem = toUsdString(value, 2)
        }
        break
      }
      case 'FLOAT': {
        value = parseFloat(columnData)
        if (value || value === 0) {
          if (Math.abs(value) > 100) {
            displayItem = Math.round(value).toLocaleString('en-US')
          } else {
            displayItem = value.toFixed(columnDef.decimalDigit || 0)
          }
        }
        break
      }
      case 'PERCENT': {
        value = parseFloat(columnData)
        if (value || value === 0) {
          if (Math.abs(value) >= 100) {
            displayItem = toPercentString(value, 0)
          } else {
            displayItem = toPercentString(value, columnDef.decimalDigit)
          }
        }
        break
      }
      case 'DATETIME': {
        if (columnData) {
          const localDateTime = new Date(columnData).toLocaleString()
          displayItem = localDateTime
        }

        break
      }
      case 'STORE': {
        if (columnData) {
          if (columnData.id) {
            const redirect: 'storeDetail' | 'salesmanship' =
              columnDef.redirect ?? 'storeDetail'
            displayItem = (
              <LinkButton
                to={navigator[redirect](columnData.id)}
                onClick={() => track.locationLinkClicked(columnData.id)}
              >
                {getLocationCode(columnData.id, columnData.code)}-
                {getLocationName(columnData.id, columnData.name)}
              </LinkButton>
            )
          } else {
            displayItem = columnData.name || '-'
          }
        }
        break
      }
      case 'DIRECTOR': {
        if (columnData) {
          if (columnData.id) {
            displayItem = (
              <LinkButton to={navigator.employeeProfile(columnData.id)}>
                {columnData.name}
              </LinkButton>
            )
          } else {
            displayItem = columnData.name || '-'
          }
        }
        break
      }
      case 'USER_MANAGEMENT_EMAIL': {
        displayItem = (
          <FlexContainer>
            <span>{columnData}</span>
            <SendEmailButton email={columnData} />
          </FlexContainer>
        )
        break
      }
      case 'USER_MANAGEMENT_ID': {
        displayItem = (
          <FlexContainer>
            <ChangePasswordButton userId={columnData} />
          </FlexContainer>
        )
        break
      }
      case 'USER_MANAGEMENT_LOCATION': {
        displayItem = (
          <FlexContainer>
            <ChangeLocationButton
              email={allData.email}
              locationName={columnData || '-'}
              refetch={refetch}
            />
          </FlexContainer>
        )
        break
      }
      case 'USER_MANAGEMENT_DEACTIVATE': {
        displayItem = (
          <FlexContainer>
            <DeactivateUserButton
              userId={allData.userId}
              curValue={columnData}
              refetch={refetch}
            />
          </FlexContainer>
        )
        break
      }
      case 'USER_MANAGEMENT_EMPLOYEE_ID': {
        displayItem = (
          <FlexContainer>
            <ChangeEmployeeButton
              allData={allData as IUserManagementData}
              refetch={refetch}
            />
          </FlexContainer>
        )
        break
      }
      case 'ARRAY': {
        const sortedArray = [...(columnData ?? [])].sort()
        displayItem = sortedArray.join(', ')
        break
      }
      default:
        displayItem = '-'
    }

    if (Number.isNaN(value)) {
      displayItem = '-'
    }

    if (
      columnDef.dataType !== 'CENT' &&
      columnDef.dataType !== 'PERCENT' &&
      columnDef.dataType !== 'DOLLAR'
    ) {
      if (value < 0 && typeof displayItem === 'string') {
        displayItem = `(${displayItem.replace('-', '')})`
      }
    }

    return (
      <Span
        danger={
          columnDef.danger === 'none'
            ? false
            : columnDef.danger === 'positive'
            ? value > 0
            : columnDef.danger === 'zero'
            ? value === 0
            : value < 0
        }
      >
        {displayItem}
      </Span>
    )
  }
}

// Same as getColumnDataRender but no React nodes
function getColumnDataCsvRender(columnDef: ITableColumnDef) {
  return (columnData: any) => {
    let displayItem: string = '-'
    let value = columnData?.value ?? columnData
    switch (columnDef.dataType) {
      case 'STRING': {
        displayItem = value
        break
      }
      case 'INTEGER': {
        value = parseInt(value, 10)
        if (value || value === 0) {
          displayItem = value.toLocaleString('en-US')
        }
        break
      }
      case 'INTEGER_NO_COMMA': {
        value = parseInt(value, 10)
        if (value || value === 0) {
          displayItem = value
        }
        break
      }
      case 'CENT': {
        value = parseInt(value)
        displayItem = toUsdString(value / 100.0, 2)
        break
      }
      case 'DOLLAR': {
        value = parseFloat(value)
        displayItem = toUsdString(value, 2)
        break
      }
      case 'FLOAT': {
        value = parseFloat(value)
        if (value || value === 0) {
          if (Math.abs(value) > 100) {
            displayItem = Math.round(value).toLocaleString('en-US')
          } else {
            displayItem = value.toFixed(columnDef.decimalDigit || 0)
          }
        }
        break
      }
      case 'PERCENT': {
        value = parseFloat(value)
        if (value || value === 0) {
          if (Math.abs(value) >= 100) {
            displayItem = toPercentString(value, 0)
          } else {
            displayItem = toPercentString(value, columnDef.decimalDigit)
          }
        }
        break
      }
      case 'DATETIME': {
        if (value) {
          const localDateTime = new Date(value).toLocaleString()
          displayItem = localDateTime
        }

        break
      }
      case 'STORE': {
        if (value) {
          if (value.id) {
            displayItem = `${getLocationCode(
              value.id,
              value.code,
            )}-${getLocationName(value.id, value.name)}`
          } else {
            displayItem = value.name || '-'
          }
        }
        break
      }
      case 'DIRECTOR': {
        if (value) {
          displayItem = value.name || '-'
        }
        break
      }
      default:
        displayItem = '-'
    }

    if (Number.isNaN(value)) {
      displayItem = '-'
    }

    if (
      columnDef.dataType !== 'CENT' &&
      columnDef.dataType !== 'PERCENT' &&
      columnDef.dataType !== 'DOLLAR'
    ) {
      if (value < 0 && typeof displayItem === 'string') {
        displayItem = `(${displayItem.replace('-', '')})`
      }
    }

    return displayItem
  }
}

function noChangeGetter(value: any) {
  return value
}

function getColumnSorter(columnDef: ITableColumnDef) {
  const hasSorter = columnDef.isSortable !== false
  if (hasSorter) {
    let sortableValueGetter = noChangeGetter

    switch (columnDef.dataType) {
      // advanced sorting
      case 'STORE':
        sortableValueGetter = (value: any) => {
          return _.get(value, 'code')
        }
        break
      case 'DATETIME':
        // Date ISO strings can be compared with < and >
        break
      // use default Table sorting
      default:
        return true
    }

    return (valueA: any, valueB: any) => {
      let sortableA = sortableValueGetter(valueA)
      let sortableB = sortableValueGetter(valueB)
      if (sortableA > sortableB) {
        return 1
      } else if (sortableA < sortableB) {
        return -1
      } else return 0
    }
  }
  return undefined
}

function getSummaryRender(
  summaryRow: any,
  columnDef: ITableColumnDef,
  refetch: any,
  isRaw?: boolean,
) {
  let displayItem: React.ReactNode = '-'
  if (summaryRow) {
    const summaryValue = _.get(summaryRow, columnDef.key)
    if (isRaw) {
      displayItem = <span>{summaryValue}</span>
    } else {
      displayItem = getColumnDataRender(columnDef, refetch)(summaryValue)
    }

    return () => {
      return displayItem
    }
  }

  return null
}

const useColumns = (
  reportDefinition: IReportDefinition | undefined,
  reportResult: IReportResult | undefined,
  refetch: any,
  breakdownTitle?: string,
): IColumnsType<any>[] =>
  useMemo(() => {
    if (
      reportDefinition &&
      reportDefinition.type === 'TABLE' &&
      Array.isArray(reportDefinition.columns) &&
      reportDefinition.columns.length > 0 &&
      reportResult &&
      Array.isArray(reportResult.tableData) &&
      reportResult.tableData.length > 0
    ) {
      let finalColDefs: ITableColumnDef[] = []
      reportDefinition.columns.forEach((colDef) => {
        if (colDef.dataType !== 'DYNAMIC_ARRAY') {
          finalColDefs.push(colDef)
        } else {
          if (
            reportResult.dynamicColumns &&
            Array.isArray(reportResult.dynamicColumns[colDef.key])
          ) {
            finalColDefs = [
              ...finalColDefs,
              ...reportResult.dynamicColumns[colDef.key],
            ]
          }
        }
      })

      let summaryRow: any = null
      if (reportDefinition.hasSummary) {
        summaryRow = reportResult.summary || {}
      }

      let extraRow: any = null
      if (reportDefinition.hasExtraRow) {
        extraRow = reportResult.extraRow || {}
      }

      const columns = finalColDefs.map((columnDef) => {
        let textAlign: 'left' | 'center' | 'right' = 'center'

        switch (columnDef.dataType) {
          case 'STRING':
          case 'STORE': {
            textAlign = 'left'
            break
          }
          default:
          // do nothing
        }

        if (columnDef.align) {
          textAlign = columnDef.align
        }

        const colDef: IColumnsType<any> = {
          key: columnDef.key,
          title:
            columnDef.header === 'BREAKDOWN_TITLE'
              ? breakdownTitle
              : columnDef.header,
          minWidth: columnDef.minWidth || '150px',
          align: textAlign,
          render: getColumnDataRender(columnDef, refetch),
          csvRender: getColumnDataCsvRender(columnDef),
          sorter: getColumnSorter(columnDef),
          summary: getSummaryRender(summaryRow, columnDef, refetch),
          extraRow: getSummaryRender(extraRow, columnDef, refetch, true),
          parent: columnDef.parent,
          heatmap: columnDef.heatmap,
          heatmapDirection: columnDef.heatmapDirection,
          heatmapKey: columnDef.heatmapKey,
          colorCode: columnDef.colorCode,
          backgroundColor: columnDef.backgroundColor,
          filter: columnDef.filter,
        }

        if (columnDef.width) {
          colDef.width = columnDef.width
        }

        return colDef
      })

      const groupedColumns = columns.reduce((acc, cur) => {
        if (cur.parent) {
          const parent = acc[acc.length - 1]
          if (!parent || parent.key !== cur.parent) {
            return [
              ...acc,
              {
                key: cur.parent,
                align: 'center',
                children: [cur],
              },
            ] as IColumnsType<any>[]
          }

          if (parent?.children) {
            parent.children.push(cur)
            return acc
          }
        }

        return [...acc, cur]
      }, [] as IColumnsType<any>[])

      return groupedColumns
    }

    if (
      reportDefinition &&
      reportDefinition.type === 'LIST' &&
      Array.isArray(reportDefinition.columns) &&
      reportDefinition.columns.length > 0 &&
      reportResult &&
      Array.isArray(reportResult.tableData) &&
      reportResult.tableData.length > 0
    ) {
      return reportDefinition.columns.map((columnDef) => ({
        ...columnDef,
        render: getColumnDataRender(columnDef, refetch),
      }))
    }

    return []
  }, [reportDefinition, reportResult])

export default useColumns
