import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect } from 'react'

import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import {
  GROUP_BY_PERIOD,
  GROUP_BY_QUARTER,
  GROUP_BY_WEEK,
  GROUP_BY_YEAR,
  IDateRange,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_TRAILING_90_DAYS,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'

import { LOCATION_GROUP_GUEST_KPIS_WITH_TIME_INTERVAL } from '../gql'
import Main from './Main'

interface IProps {
  endDate: string
  dateRangeType: IDateRange['type']
  locationGroupId: number
}

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

function RatingsChart({ endDate, dateRangeType, locationGroupId }: IProps) {
  const brand = getBrand()
  const guestConfig = getBrandSettings().guest

  const [
    listKpi,
    { loading: listKpiLoading, error: listKpiError, data: listKpiData },
  ] = useLazyQuery(LOCATION_GROUP_GUEST_KPIS_WITH_TIME_INTERVAL)

  useEffect(() => {
    let groupBy = ''
    if (dateRangeType === TYPE_YEAR) {
      groupBy = GROUP_BY_YEAR
    } else if (dateRangeType === TYPE_QUARTER) {
      groupBy = GROUP_BY_QUARTER
    } else if (dateRangeType === TYPE_WEEK) {
      groupBy = GROUP_BY_WEEK
    } else {
      groupBy = GROUP_BY_PERIOD
    }

    listKpi({
      variables: {
        iEndDate: endDate,
        iGroupBy: groupBy,
        iLocationGroupId: locationGroupId,
      },
    })
  }, [endDate, dateRangeType, locationGroupId])

  let rawKpiMonthly = []

  if (
    Array.isArray(listKpiData?.locationGroupGuestKpisWithTimeInterval?.nodes)
  ) {
    rawKpiMonthly = _.get(
      listKpiData,
      'locationGroupGuestKpisWithTimeInterval.nodes',
      [],
    )
  }

  const xAxisData = _.map(
    rawKpiMonthly,
    (k) =>
      ({
        [TYPE_YEAR]: `Y${k.businessYear}`,
        [TYPE_QUARTER]: `Q${k.businessQuarter} ${k.businessYear}`,
        [TYPE_PERIOD]: `P${k.businessMonth} ${k.businessYear}`,
        [TYPE_WEEK]: `P${k.businessMonth} W${k.businessWeekOfMonth} ${k.businessYear}`,
        [TYPE_THIS_WEEK]: `P${k.businessMonth} ${k.businessYear}`,
        [TYPE_LAST_WEEK]: `P${k.businessMonth} ${k.businessYear}`,
        [TYPE_YESTERDAY]: `P${k.businessMonth} ${k.businessYear}`,
        [TYPE_TRAILING_7_DAYS]: `P${k.businessMonth} ${k.businessYear}`,
        [TYPE_TRAILING_90_DAYS]: `P${k.businessMonth} ${k.businessYear}`,
      }[dateRangeType || TYPE_PERIOD] ??
      `P${k.businessMonth} ${k.businessYear}`),
  )

  const yAxisAcrData = _.map(rawKpiMonthly, (k) => {
    const rating = _.get(k, 'ratings.acr', 0)

    return rating ? rating.toFixed(guestConfig.decimal) : null
  })

  const yAxisMomentfeedData = _.map(rawKpiMonthly, (k) => {
    const rating = _.get(k, 'ratings.momentfeed', 0)

    return rating ? rating.toFixed(guestConfig.decimal) : null
  })

  const yAxisYelpData = _.map(rawKpiMonthly, (k) => {
    const rating = _.get(k, 'ratings.yelp', 0)

    return rating ? rating.toFixed(guestConfig.decimal) : null
  })

  const yAxisGoogleData = _.map(rawKpiMonthly, (k) => {
    const rating = _.get(k, 'ratings.google', 0)

    return rating ? rating.toFixed(guestConfig.decimal) : null
  })

  const yAxisFacebookData = _.map(rawKpiMonthly, (k) => {
    const rating = _.get(k, 'ratings.facebook', 0)

    return rating ? rating.toFixed(guestConfig.decimal) : null
  })

  const yAxisDeliveryData = _.map(rawKpiMonthly, (k) => {
    const rating = _.get(k, 'ratings.delivery', 0)

    return rating ? rating.toFixed(guestConfig.decimal) : null
  })

  const yAxisAllData = _.map(rawKpiMonthly, (k) => {
    const rating = _.get(k, 'ratings.all', 0)

    return rating ? rating.toFixed(guestConfig.decimal) : null
  })

  const yAxisYextData = _.map(rawKpiMonthly, (k) => {
    const rating = _.get(k, 'ratings.yext', 0)

    return rating ? rating.toFixed(guestConfig.decimal) : null
  })

  const yAxisOpentableData = _.map(rawKpiMonthly, (k) => {
    const rating = _.get(k, 'ratings.opentable', 0)

    return rating ? rating.toFixed(guestConfig.decimal) : null
  })

  let yAxisDataArr = []
  const shouldInitiallyHidden =
    !!guestConfig.momentfeed || !!guestConfig.allRating.isVisible

  yAxisDataArr = [
    !guestConfig.acr.isVisible
      ? null
      : {
          type: 'line',
          data: yAxisAcrData,
          tooltipLabel: 'ACR',
          borderColor: COLORS.Basil,
          backgroundColor: COLORS.Basil,
          hidden: shouldInitiallyHidden,
        },
    !guestConfig.momentfeed
      ? null
      : {
          type: 'line',
          data: yAxisMomentfeedData,
          tooltipLabel: 'Momentfeed',
          borderColor: COLORS.Basil,
          backgroundColor: COLORS.Basil,
          hidden: shouldInitiallyHidden,
        },
    !guestConfig.yext
      ? null
      : {
          type: 'line',
          data: yAxisYextData,
          tooltipLabel: 'Yext',
          borderColor: COLORS.DarkOrchid,
          backgroundColor: COLORS.DarkOrchid,
          hidden: shouldInitiallyHidden,
        },
    !guestConfig.yelp
      ? null
      : {
          type: 'line',
          data: yAxisYelpData,
          tooltipLabel: 'Yelp',
          borderColor: COLORS.Pomodoro,
          backgroundColor: COLORS.Pomodoro,
          hidden: shouldInitiallyHidden,
        },
    !guestConfig.google
      ? null
      : {
          type: 'line',
          data: yAxisGoogleData,
          tooltipLabel: 'Google',
          borderColor: COLORS.Mango,
          backgroundColor: COLORS.Mango,
          hidden: shouldInitiallyHidden,
        },
    !guestConfig.facebook
      ? null
      : {
          type: 'line',
          data: yAxisFacebookData,
          tooltipLabel: 'Facebook',
          borderColor: COLORS.Plum,
          backgroundColor: COLORS.Plum,
          hidden: shouldInitiallyHidden,
        },
    !guestConfig.delivery
      ? null
      : {
          type: 'line',
          data: yAxisDeliveryData,
          tooltipLabel: 'Delivery',
          borderColor: COLORS.Acai,
          backgroundColor: COLORS.Acai,
          hidden: shouldInitiallyHidden,
        },
    !guestConfig.allRating.isVisible
      ? null
      : {
          type: 'line',
          data: yAxisAllData,
          tooltipLabel: guestConfig.allRating.displayName,
          borderColor: COLORS.Plum,
          backgroundColor: COLORS.Plum,
        },
    !guestConfig.opentable?.isVisible
      ? null
      : {
          type: 'line',
          data: yAxisOpentableData,
          tooltipLabel: 'Opentable',
          borderColor: COLORS.DarkOrchid,
          backgroundColor: COLORS.DarkOrchid,
          hidden: shouldInitiallyHidden,
        },
  ].filter(Boolean)

  if (yAxisDataArr[0]) {
    // always unhide the first line
    yAxisDataArr[0].hidden = undefined
  }

  const chartOptions = {
    title: '',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: '',
    yLeftTickCallback: (value: number) => {
      return value
    },
    yRightAxisLabel: '# of Delivery Orders',
    yRightShowGridLines: false,
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let datasetLabel = ''
        let separator = ''
        let yValue = ''
        if (tooltipItemContext.dataset && tooltipItemContext.dataset.label) {
          datasetLabel = tooltipItemContext.dataset.label
          separator = ': '
        }
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          yValue = tooltipItemContext.parsed.y
        }
        return [datasetLabel, separator, yValue].join('')
      }
      return ''
    },
  }

  return (
    <Main
      isLoading={listKpiLoading}
      xAxisData={xAxisData}
      chartOptions={chartOptions}
      yAxisDataArr={yAxisDataArr}
    />
  )
}

export default RatingsChart
