import { useQuery } from '@apollo/client'

import parseSummary from 'pared/pages/Calls/Summary/parseSummary'
import { toUsdStr } from 'pared/utils/number'

import { listLocationInboundCalls } from '../gqls/calls'
import useConfig from './useConfig'

export default (
  startDate: string,
  endDate: string,
  locationId: number,
  locationName: string | undefined,
) => {
  const config = useConfig()
  const callsAction = {
    summary: '',
    annualOpportunityCost: 0,
  }

  const inputParams = {
    iFilter: {
      location_ids: [locationId],
    },
    iStartDate: startDate,
    iEndDate: endDate,
    skip: !config.categories.includes('Calls'),
  }

  const { data, loading } = useQuery(listLocationInboundCalls, {
    variables: inputParams,
  })

  if (locationName) {
    const summary = parseSummary(data, locationName)
    callsAction.summary = `
      ${summary.summaryMessage}
      ${
        summary.annualOpportunityCost
          ? `This projects to an annual opportunity cost of ${toUsdStr(
              summary.annualOpportunityCost,
            )}.`
          : ''
      }
      ${summary.areasOfImprovementMessages.join(' ')}
    `
    callsAction.annualOpportunityCost = summary.annualOpportunityCost
  }

  return {
    loading,
    action: callsAction,
  }
}
