import { addDays } from 'date-fns'

interface IRawData {
  date: Date
  text?: string
  background?: string
}

const DARK_RED = 'hsla(0, 100%, 72%, 1)'
const LIGHT_RED = 'hsla(0, 100%, 72%, 0.5)'
const GRAY = 'hsla(0, 0%, 56%, 1)'

const RAW_DATA = [
  {
    text: 'TODAY',
  },
  {
    text: 'Overstaffed',
    background: DARK_RED,
  },
  {
    text: 'Slightly Understaffed',
    background: LIGHT_RED,
  },
  {
    text: 'Slightly Understaffed',
    background: LIGHT_RED,
  },
  {
    background: GRAY,
  },
  {
    background: GRAY,
  },
  {
    background: GRAY,
  },
  {
    text: 'Understaffed',
    background: DARK_RED,
  },
  {
    background: GRAY,
  },
  {
    text: 'Understaffed',
    background: DARK_RED,
  },
  {
    text: 'Slightly Overstaffed',
    background: LIGHT_RED,
  },
  {
    background: GRAY,
  },
  {
    text: 'Slightly Overstaffed',
    background: LIGHT_RED,
  },
  {
    background: GRAY,
  },
  {
    text: 'Slightly Understaffed',
    background: LIGHT_RED,
  },
]

export const RAW_LABOR_SCHEDULE = () => {
  const today = new Date()
  const laborSchedule: IRawData[] = RAW_DATA.map((data, index) => ({
    ...data,
    date: addDays(today, index),
  }))

  return laborSchedule
}
