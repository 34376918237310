import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import styled from 'styled-components'

import InformationSpan from 'pared/components/InformationSpan'
import { DELIVERY_CATEGORY_DISPLAY_TEXT } from 'pared/constants'
import COLORS from 'pared/constants/colors'
import DownArrow from 'pared/images/basic/arrow/caret-down.svg'
import UpArrow from 'pared/images/basic/arrow/caret-up.svg'
import { getBrand } from 'pared/utils/brand'

import useDeliveryConfig from '../hooks/useDeliveryConfig'
import { IOrderBy, IStaffKpi } from './index'

interface IProps {
  staffKpis: IStaffKpi[]
  staffDeliveryCategory: string
  onSelectStaffDeliveryCategory: (event: any) => void
  orderBy: IOrderBy
  setOrderBy: any
}

interface IRankTablTh {
  textAlign?: string
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
}

function WebLargeMain({
  staffKpis,
  staffDeliveryCategory,
  onSelectStaffDeliveryCategory,
  orderBy,
  setOrderBy,
}: IProps) {
  const deliveryConfig = useDeliveryConfig(getBrand())

  if (_.isEmpty(staffKpis)) {
    return (
      <EmptyMainContainer>
        There is no data with this selection. Please try a different filter or a
        different store.
      </EmptyMainContainer>
    )
  }

  const handleOrdering = (event: any) => {
    const orderingColumnValue = _.get(event, 'currentTarget.dataset.value')
    setOrderBy({
      columnName: orderingColumnValue,
      isAscending: !orderBy.isAscending,
    })
  }

  let orderStatus = null
  if (orderBy.isAscending) {
    orderStatus = <UpArrowImg src={UpArrow} alt="ASC"></UpArrowImg>
  } else {
    orderStatus = <DownArrowImg src={DownArrow} alt="DESC"></DownArrowImg>
  }

  const staffKpiRows = _.map(staffKpis, (k, index) => {
    return (
      <tr key={index + 1}>
        <RankNumberTd textAlign={'left'} bold>
          {index + 1}
        </RankNumberTd>
        <RankTableTd textAlign={'left'}>
          {_.get(k, 'employeeName', '')}
        </RankTableTd>
        <RankTableTd>{_.get(k, 'roleName', '')}</RankTableTd>
        <RankTableTd>{_.get(k, [staffDeliveryCategory], '')}</RankTableTd>
        <RankTableTd>{_.get(k, 'totalOrderCount', 0)}</RankTableTd>
      </tr>
    )
  })

  return (
    <>
      <TrendsOuterContainer>
        <table>
          <tbody>
            <tr>
              <StaffDescriptionTd>
                <TrendsContainer>Staff at a Glance</TrendsContainer>
              </StaffDescriptionTd>
              <td>
                <FilterContainer>
                  <TrendsSelectorContainer>
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      style={{ width: 280 }}
                    >
                      <FilterSelect
                        displayEmpty
                        value={staffDeliveryCategory}
                        onChange={onSelectStaffDeliveryCategory}
                      >
                        {deliveryConfig['staffAndDayTrendsFilter'].options.map(
                          ({
                            variableName,
                            displayName,
                          }: {
                            variableName: string
                            displayName: string
                          }) => {
                            return (
                              <MenuItem key={variableName} value={variableName}>
                                {displayName}
                              </MenuItem>
                            )
                          },
                        )}
                      </FilterSelect>
                    </FormControl>
                  </TrendsSelectorContainer>
                </FilterContainer>
              </td>
            </tr>
          </tbody>
        </table>
      </TrendsOuterContainer>
      <StaffTableContainer>
        <RankTable>
          <thead>
            <tr>
              <RankTableTh textAlign={'left'}>Rank</RankTableTh>
              <RankTableTh textAlign={'left'}>Employee Name</RankTableTh>
              <RankTableTh>Position</RankTableTh>
              <RankTableSortableTh
                onClick={handleOrdering}
                data-value={staffDeliveryCategory}
              >
                <span>
                  {DELIVERY_CATEGORY_DISPLAY_TEXT[staffDeliveryCategory]}
                </span>
                {orderBy.columnName === staffDeliveryCategory
                  ? orderStatus
                  : null}
              </RankTableSortableTh>
              <RankTableSortableTh
                onClick={handleOrdering}
                data-value={'totalOrderCount'}
              >
                <InformationSpan
                  popoverDescription={
                    '# of orders indicates the total number of delivery orders that were processed during the employees’ shift including orders processed by other team members on those shifts'
                  }
                >
                  <SpanContainer># of Orders</SpanContainer>
                </InformationSpan>
                {orderBy.columnName === 'totalOrderCount' ? orderStatus : null}
              </RankTableSortableTh>
            </tr>
          </thead>
          <tbody>{staffKpiRows}</tbody>
        </RankTable>
      </StaffTableContainer>
    </>
  )
}

const EmptyMainContainer = styled.div`
  font-family: Lexend-Regular;
`

const SpanContainer = styled.div`
  font-family: Lexend-SemiBold;
  color: ${COLORS.Plum};
`

const UpArrowImg = styled.img`
  width: 10px;
  margin: 0 0 2px 5px;
`

const DownArrowImg = styled.img`
  width: 10px;
  margin: 0 0 -1px 5px;
`

const StaffTableContainer = styled.div`
  margin-top: 30px;
  width: 800px;
`

const RankTable = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`

const RankTableTh = styled.th<IRankTablTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankTableSortableTh = styled.th<IRankTablTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  color: ${COLORS.Plum};
  cursor: pointer;
`

const RankTableTd = styled.td<IRankTableTd>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankNumberTd = styled(RankTableTd)`
  padding-left: 12px;
`

const TrendsOuterContainer = styled.div`
  display: inline-block;
`

const StaffDescriptionTd = styled.td`
  width: 490px;
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  float: left;
`

const TrendsContainer = styled(PageHeader)`
  margin-bottom: 10px;
`

const FilterContainer = styled.div`
  display: inline-block;
`

const TrendsSelectorContainer = styled.div`
  float: left;
  margin-left: 25px;
`

const FilterSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

export default WebLargeMain
