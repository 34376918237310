import { useMutation, useQuery } from '@apollo/client'
import TextField from '@material-ui/core/TextField'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBrand } from 'pared/utils/brand'

import AcrTable from './AcrTable'
import {
  MUTATION_CREATE_EMAIL_SUBSCRIPTION,
  MUTATION_REMOVE_EMAIL_SUBSCRIPTION,
  QUERY_GET_EMAILS_FOR_REPORT,
} from './gql'

const Reports = () => {
  const [email, setEmail] = useState('')
  const [emails, setEmails] = useState<string[]>([])
  const { data } = useQuery(QUERY_GET_EMAILS_FOR_REPORT, {
    variables: {
      report: 'ACR',
    },
  })

  const [createEmailSubscription] = useMutation(
    MUTATION_CREATE_EMAIL_SUBSCRIPTION,
  )
  const [removeEmailSubscription] = useMutation(
    MUTATION_REMOVE_EMAIL_SUBSCRIPTION,
  )

  const brand = getBrand()
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]

  let Emails = null
  const fetchedEmails = data?.getEmailsForReport?.nodes
  useEffect(() => {
    if (fetchedEmails) {
      setEmails(fetchedEmails.map((email: string) => email))
    }
  }, [fetchedEmails])

  const addEmail = async () => {
    if (email) {
      await createEmailSubscription({
        variables: {
          report: 'ACR',
          email,
        },
      })

      setEmails((prevEmails) => [...prevEmails, email])
      setEmail('')
    }
  }

  const removeEmail = async (selectedEmail: string) => {
    if (selectedEmail) {
      await removeEmailSubscription({
        variables: {
          report: 'ACR',
          email: selectedEmail,
        },
      })
      setEmails((prevEmails) =>
        prevEmails.filter((email) => email !== selectedEmail),
      )
    }
  }

  const EmailComponent = ({ email }: { email: string }) => (
    <tr>
      <td>{email}</td>
      <td>
        <Button onClick={() => removeEmail(email)}>Remove</Button>
      </td>
    </tr>
  )

  const AddEmailButton = (
    <>
      <tr>
        <td>
          <TextField
            name="email"
            type="email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            size="small"
          />
        </td>
        <td>
          <Button onClick={addEmail}>Add</Button>
        </td>
      </tr>
    </>
  )

  if (emails && Array.isArray(emails)) {
    Emails = (
      <>
        <Header2>Email List</Header2>
        <table>
          <tbody>
            {emails.map((email) => (
              <EmailComponent key={email} email={email} />
            ))}
            {AddEmailButton}
          </tbody>
        </table>
      </>
    )
  }

  return (
    <>
      <Header1>Reports</Header1>
      <Header2>ACR</Header2>
      <AcrTable locationGroupId={brandLocationGroupId} />
      <VerticalSpacer />
      {Emails}
    </>
  )
}

export default Reports

const Header1 = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
`

const Header2 = styled(Header1)`
  font-size: 18px;
`

const VerticalSpacer = styled.div`
  height: 20px;
`
