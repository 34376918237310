import useStoreSubtitle from './useStoreSubtitle'

export type IApiKeyType = keyof typeof api

const api = {
  storeSubtitle: useStoreSubtitle,
}

const apiKeys = Object.keys(api) as (keyof typeof api)[]

const useApi = (apiName?: IApiKeyType | string[]) =>
  !Array.isArray(apiName) && apiKeys.includes(apiName as IApiKeyType)
    ? api[apiName as IApiKeyType]()
    : { data: apiName, loading: false }

export default useApi
