import OrgChart from 'pared/components/OrgChart'
import Spin from 'pared/components/basicUi/spin'

import useApi, { IApiKeyType } from './hooks/useApi'

export interface IPropsType<K extends IApiKeyType = IApiKeyType> {
  type: 'org-chart'
  api: K
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const OrganizationChart = ({ api }: IPropsType) => {
  const { data, loading } = useApi(api)

  if (loading || !data) return null

  return (
    <Spin spinning={loading}>
      <OrgChart data={data} />
    </Spin>
  )
}

export default OrganizationChart
