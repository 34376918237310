import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiDataType } from '../../types'

interface ILossPreventionSummaryNodeType {
  locationCode: string
  locationName: string
  amount: number
  employeeDiscountInfos: {
    employeeName: string
  }[]
  rankPercent: number
  annualOpportunityCost: number
}

interface ILossPreventionSummaryType {
  locationULossPreventionSummary: {
    nodes: ILossPreventionSummaryNodeType[]
  }
}

const query = gql`
  query LocationLossPreventionSummary(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $iMetricInfo: JSON!
  ) {
    locationULossPreventionSummary(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iMetricInfo: $iMetricInfo
    ) {
      nodes {
        locationCode
        locationName
        amount
        employeeDiscountInfos
        rankPercent
      }
    }
  }
`

const useLocationLossPreventionAiSummary = (): IApiDataType => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery<ILossPreventionSummaryType>(query, {
    variables: {
      iLocationId: groupFilter?.ids?.[0],
      iStartDate: startDate,
      iEndDate: endDate,
      iMetricInfo: {
        grossSalesMetric: 'gross_sales',
        discountCompsTotalMetric: 'discount_comps_total',
        discountCompsPercentTotalMetric: 'discount_comps_percent_total',
      },
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const summary = data?.locationULossPreventionSummary.nodes[0]

      const summaryMessage = (() => {
        if (!summary) return null

        const store = `<strong>${summary.locationName}</strong>`
        const amount = `<strong>${Math.abs(summary.amount) / 100}</strong>`

        if (summary.rankPercent < 1 / 3) {
          return `${store} is discounting ${amount} more than expected in the time period selected.`
        } else if (summary.rankPercent < 2 / 3) {
          return `${store} is discounting ${amount} <strong>${
            summary.amount > 0 ? 'more' : 'less'
          }</strong> than expected but is around average for the company for the time period selected.`
        } else {
          return `${store} is discounting as expected for the time period selected.`
        }
      })()
      const improvementMessage = (() => {
        if (!summary) return null

        const employeeDiscountInfos = summary.employeeDiscountInfos || []
        if (employeeDiscountInfos.length === 0)
          return 'No employees are discounting above the Company Average for this time period.'

        const names = employeeDiscountInfos.map(({ employeeName }, index) => {
          return `<strong>${employeeName}</strong>${(() => {
            if (index === employeeDiscountInfos.length - 1) return ''
            else if (index === employeeDiscountInfos.length - 2) return ', and '
            else return ', '
          })()}`
        })

        return `${names.join(' ')} are discounting more than company averages.`
      })()

      return {
        conclusion: summaryMessage,
        insight: improvementMessage,
      }
    }, [data]),
    loading,
  }
}

export default useLocationLossPreventionAiSummary
