import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

interface ISummaryConfig {
  guestScoreLabel: string
  hideComparison: boolean
  showDailyInventoryAnalysis?: boolean
}

const defaultConfig = {
  guestScoreLabel: 'ACR',
  hideComparison: false,
}

const useConfig = (): ISummaryConfig => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'wingitnorth':
        return {
          guestScoreLabel: 'SMG',
          hideComparison: true,
        }
      case 'ghai_pop':
        return {
          ...defaultConfig,
          showDailyInventoryAnalysis: true,
        }
      default:
        return defaultConfig
    }
  }, [brand])
}
export default useConfig
