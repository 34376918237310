import _ from 'lodash'
import { useMemo } from 'react'

import { IApiDataType } from '../../../types'
import buildLocationEmployeeMetricValuesHook from '../../utils/buildLocationEmployeeMetricValuesHook'
import { IDiscountDetailsType } from './types'

export const fwWingstopListEmployeeDiscountConfigs = {
  employeeName: 'string',
  employeeRole: 'string',
  grossSales: 'price',
  discountCompsTotal: 'price',
  discountCheckCountTotal: 'number',
  discountCompsPercentTotal: 'percent',
  discountTypes: 'string',
  discountTypeSummary: 'string',
  discountTotalPercentRank: 'number',
} as const

const useCommonEmployeeDiscountData = buildLocationEmployeeMetricValuesHook({
  codes: [
    'gross_sales',
    'discount_comps_total',
    'discount_comps_percent_total',
    'discount_check_count_total',
  ],
  groups: [
    'Expo Module - Discount Comps',
    'Expo Module - Discount Check Count',
  ],
})

const useListEmployeeDiscountData = () => {
  const { data: commonData, loading } = useCommonEmployeeDiscountData()

  return {
    data: useMemo((): IApiDataType => {
      if (!commonData) return null

      const allDiscounts: IDiscountDetailsType[] = []
      const discountNameSet = new Set<string>()

      commonData.forEach((data: any) => {
        Object.keys(data.metricData).forEach((metricCode: string) => {
          if (
            metricCode.indexOf('discount_check_count_') === 0 &&
            metricCode !== 'discount_check_count_total'
          ) {
            const discountName = (data.metricData[metricCode]?.name || '')
              .replace('Discount Check Count - ', '')
              .trim()
            if (discountName && !discountNameSet.has(discountName)) {
              discountNameSet.add(discountName)
              allDiscounts.push({
                discountName,
                checkCountKey: _.camelCase(metricCode),
                compsKey: _.camelCase(
                  metricCode.replace(
                    'discount_check_count_',
                    'discount_comps_',
                  ),
                ),
              })
            }
          }

          if (
            metricCode.indexOf('discount_comps_') === 0 &&
            metricCode !== 'discount_comps_total' &&
            metricCode !== 'discount_comps_percent_total'
          ) {
            const discountName = (data.metricData[metricCode]?.name || '')
              .replace('Discount Comps - ', '')
              .trim()
            if (discountName && !discountNameSet.has(discountName)) {
              discountNameSet.add(discountName)
              allDiscounts.push({
                discountName,
                checkCountKey: _.camelCase(
                  metricCode.replace(
                    'discount_comps_',
                    'discount_check_count_',
                  ),
                ),
                compsKey: _.camelCase(metricCode),
              })
            }
          }
        })
      })

      const sortedDiscountTotalPercent = commonData
        .map((e) => e['discountCompsPercentTotal'] as number)
        .sort((a, b) => a - b)

      const newData = commonData.map((n) => {
        const discountTypeSummary = allDiscounts.reduce((sum, d) => {
          const discountDetailCheckCount = n?.[d.checkCountKey]
          if (discountDetailCheckCount) {
            return [...sum, `${d.discountName} (${discountDetailCheckCount})`]
          }

          return sum
        }, [] as string[])
        const rank =
          sortedDiscountTotalPercent.findIndex(
            (sortedItem) => sortedItem === n['discountCompsPercentTotal'],
          ) + 1

        return {
          ...n,
          discountTypeSummary: discountTypeSummary?.join(', '),
          discountTotalPercentRank: rank,
          discountTotalPercentHeatmap:
            ((rank - 1) / (sortedDiscountTotalPercent.length - 1)) * 100,
        }
      })

      const discountDetails = newData.reduce((result, nd: any) => {
        const details = allDiscounts.reduce((sum, dt) => {
          const employeeIdStr = nd.id
          const discountTypes = dt.discountName
          const discountDetailComps = nd?.[dt.compsKey]
          const discountDetailCheckCount = nd?.[dt.checkCountKey]
          if (discountDetailComps || discountDetailCheckCount) {
            return [
              ...sum,
              {
                id: `${employeeIdStr}-${discountTypes}`,
                parentId: employeeIdStr,
                discountTypes,
                discountCheckCountTotal: discountDetailCheckCount,
                discountCompsTotal: discountDetailComps,
              },
            ]
          }

          return sum
        }, [] as Exclude<IApiDataType, null>)

        return [...result, ...details]
      }, [] as Exclude<IApiDataType, null>)

      return [...newData, ...discountDetails]
    }, [commonData, loading]),
    loading: loading,
  }
}

export default useListEmployeeDiscountData
