import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { generateEnterKeyPressedHandler } from 'pared/utils/keyboard'

import { ISignUpParams } from './index'

interface IProps {
  isLoading: boolean
  onSignUp: (signUpParams: ISignUpParams) => Promise<void>
  errorMessage: string
}

function WebLargeMain({ isLoading, onSignUp, errorMessage }: IProps) {
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordRepeated, setPasswordRepeated] = useState<string>('')

  const onFirstNameChange = (event: any) => {
    setFirstName(event.target.value)
  }

  const onLastNameChange = (event: any) => {
    setLastName(event.target.value)
  }

  const onEmailChange = (event: any) => {
    setEmail(event.target.value)
  }

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value)
  }

  const onPasswordRepeatedChange = (event: any) => {
    setPasswordRepeated(event.target.value)
  }

  const onClickSignUpButton = () => {
    onSignUp({ firstName, lastName, email, password, passwordRepeated })
  }

  const onEnterKeyPressed = generateEnterKeyPressedHandler(onClickSignUpButton)

  return (
    <OuterContainer>
      <InnerContainer>
        <SignUpForm>
          <TextField
            required
            label="First Name"
            size="medium"
            fullWidth={true}
            onChange={onFirstNameChange}
            onKeyPress={onEnterKeyPressed}
          />
          <br />
          <br />
          <TextField
            required
            label="Last Name"
            size="medium"
            fullWidth={true}
            onChange={onLastNameChange}
            onKeyPress={onEnterKeyPressed}
          />
          <br />
          <br />
          <TextField
            type="email"
            required
            label="Email"
            size="medium"
            fullWidth={true}
            onChange={onEmailChange}
            onKeyPress={onEnterKeyPressed}
          />
          <br />
          <br />
          <TextField
            required
            type="password"
            label="Password"
            size="medium"
            fullWidth={true}
            onChange={onPasswordChange}
            onKeyPress={onEnterKeyPressed}
          />
          <br />
          <br />
          <TextField
            required
            type="password"
            label="Confirm Password"
            size="medium"
            fullWidth={true}
            onChange={onPasswordRepeatedChange}
            onKeyPress={onEnterKeyPressed}
          />
          <br />
          <br />
          <br />
          <Button
            variant="contained"
            color="primary"
            size="medium"
            fullWidth={true}
            disabled={isLoading}
            onClick={onClickSignUpButton}
          >
            Sign Up
          </Button>
        </SignUpForm>
        {errorMessage ? (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        ) : null}
        <SignInLinkDiv>
          Already have an account?{' '}
          <Link to={navigator.signIn()}>Click here to sign in</Link>
        </SignInLinkDiv>
      </InnerContainer>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: table;
`

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`

const SignUpForm = styled.div`
  display: inline-block;
  width: 300px;
`

const ErrorMessageContainer = styled.div`
  color: red;
  padding: 20px 0 0 0;
`

const SignInLinkDiv = styled.div`
  padding: 50px 0 0 0;
`

export default WebLargeMain
