import useListLocationFlash, {
  listLocationFlashConfigs,
} from './useListLocationFlash'
import useListLocationGroupFlash, {
  listLocationGroupFlashConfigs,
} from './useListLocationGroupFlash'

export const flashConfigs = {
  ...listLocationFlashConfigs,
  ...listLocationGroupFlashConfigs,
}

const useFlash = () => {
  const listLocationFlash = useListLocationFlash()
  const listLocationGroupFlash = useListLocationGroupFlash()

  return (listLocationFlash?.data?.source || []).length === 0
    ? listLocationGroupFlash
    : listLocationFlash
}

export default useFlash
