import FormControl from '@material-ui/core/FormControl'
import React from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import useGroupSelector from 'pared/components/GroupSelector/hooks/useGroupSelector'
import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import ExpoSelect from 'pared/components/basicUi/select'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import {
  TYPE_CUSTOM,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import { getBrand } from 'pared/utils/brand'

import PmixTable from './PmixTable'

interface IPropsType {
  navParams: INavParams
}

const CorporatePmix = ({ navParams }: IPropsType) => {
  const brand = getBrand()
  const dateRange = useGetDateRange({ isCustomAllowed: true })
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  const { groupBy, setGroupBy, options, locationGroupId } = useGroupSelector({
    initialGroupBy: 'store',
    isBrandVisible: true,
  })

  const selectedLocationGroupId = locationGroupId
    ? parseInt(locationGroupId)
    : brandLocationGroupId

  const locationIds = groupBy.length === 3 ? [] : null
  const locationGroupIds = [selectedLocationGroupId]
  const locationGroupTypes =
    groupBy.length === 2 || groupBy[0] === 'Brand' ? [groupBy[0]] : null

  return (
    <>
      <Header>
        <h1>Product</h1>
        <Selectors>
          <StyledFormControl margin="dense">
            <ExpoSelect
              value={groupBy}
              onChange={setGroupBy}
              dataSource={options}
              fullWidth
              displayEmpty
            />
          </StyledFormControl>
          <DateRangeSelector
            navParams={navParams}
            dateRangeOptions={[
              TYPE_PERIOD,
              TYPE_QUARTER,
              TYPE_YEAR,
              TYPE_LAST_WEEK,
              TYPE_THIS_WEEK,
              TYPE_YESTERDAY,
              TYPE_TRAILING_7_DAYS,
              TYPE_CUSTOM,
            ]}
          />
        </Selectors>
      </Header>
      {dateRange ? (
        <PmixTable
          startDate={dateRange.startDateStr}
          endDate={dateRange.endDateStr}
          locationIds={locationIds}
          locationGroupIds={locationGroupIds}
          locationGroupTypes={locationGroupTypes}
        />
      ) : null}
    </>
  )
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 25px 0px;
    }
  }
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const StyledFormControl = styled(FormControl)`
  background-color: white;
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

export default React.memo(CorporatePmix)
