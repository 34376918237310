import { useMemo } from 'react'
import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrand } from 'pared/utils/brand'

import v1 from '../v1'
import v2 from '../v2'

const Wrapper = styled.div`
  padding: 0px 50px;

  @media ${MOBILE_WIDTH} {
    padding: 0px;
  }
`

// TODO: should remove this after v1 remove fullWidth setting
const fullWidthHOC =
  <P extends Object>(Component: React.ComponentType<P>) =>
  (props: P) =>
    (
      <Wrapper>
        <Component {...props} />
      </Wrapper>
    )

const useConfig = () => {
  const brand = getBrand()
  return useMemo(() => {
    if (!brand) {
      return null
    }

    switch (brand) {
      case 'irmg_bk':
      case 'demo_bk':
      case 'viking_bk':
      case 'supreme_bk':
      case 'supreme_pop':
      case 'timoney_bk':
      case 'mbn_bk':
      case 'jsc_bk':
      case 'wingitnorth':
      case 'ghai_pop':
      case 'mwb':
      case 'burgerworks':
      case 'bibibop':
      case 'ace_pop':
        return fullWidthHOC(v2)

      default:
        return v1
    }
  }, [brand])
}

export default useConfig
