import paredAnalytics from 'pared/analytics/utils/ParedAnalytics'

export const track = {
  async timeFrameChanged(
    newTimeFrameType: string,
    newTimeFrameDetails: string | number,
  ) {
    await paredAnalytics.track('Salesmanship - Time Frame Changed', {
      newTimeFrameType,
      newTimeFrameDetails,
    })
  },

  async daypartChanged(newDaypart: string) {
    await paredAnalytics.track('Salesmanship - Daypart Changed', { newDaypart })
  },

  async overallStaffSalesTypeChanged(newSalesType: string) {
    await paredAnalytics.track(
      'Salesmanship - Overall Staff Sales Type Changed',
      { newSalesType },
    )
  },
}
