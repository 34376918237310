import { INavParams } from 'pared/Routes/navParams'
import { ILocationInfo } from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import { IDateRange } from 'pared/data/getDateRanges'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import {
  IGuestKpi,
  IGuestOpentablKpi,
  IGuestReview,
  IOpsCompsByReason,
} from './index'

interface IProps {
  navParams: INavParams
  employeeDayPart: string | null
  onSelectEmployeeDayPart: (event: any) => void
  opsCompsByReasons: IOpsCompsByReason[]
  guestKpis: IGuestKpi[]
  selectedDateRange: IDateRange | null
  selectedLocation: ILocationInfo | null
  guestReviews: IGuestReview[]
  isGuestReviewsLoading: boolean
  guestOpentableKpis: IGuestOpentablKpi
}

function Main({
  navParams,
  employeeDayPart,
  onSelectEmployeeDayPart,
  opsCompsByReasons,
  guestKpis,
  selectedDateRange,
  selectedLocation,
  guestReviews,
  isGuestReviewsLoading,
  guestOpentableKpis,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          navParams={navParams}
          employeeDayPart={employeeDayPart}
          onSelectEmployeeDayPart={onSelectEmployeeDayPart}
          opsCompsByReasons={opsCompsByReasons}
          guestKpis={guestKpis}
          selectedDateRange={selectedDateRange}
          selectedLocation={selectedLocation}
          guestReviews={guestReviews}
          isGuestReviewsLoading={isGuestReviewsLoading}
          guestOpentableKpis={guestOpentableKpis}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          navParams={navParams}
          employeeDayPart={employeeDayPart}
          onSelectEmployeeDayPart={onSelectEmployeeDayPart}
          opsCompsByReasons={opsCompsByReasons}
          guestKpis={guestKpis}
          selectedDateRange={selectedDateRange}
          selectedLocation={selectedLocation}
          guestReviews={guestReviews}
          isGuestReviewsLoading={isGuestReviewsLoading}
          guestOpentableKpis={guestOpentableKpis}
        />
      </SmallScreen>
    </>
  )
}

export default Main
