import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { getUser } from 'pared/utils/user'

interface ISubordinateType {
  externalId: string
  supervisorExternalId?: string
}

const queries = gql`
  query ListDirectorTrainingTracker($iEmployeeId: Int!) {
    listDirectorTrainingTracker(iEmployeeId: $iEmployeeId) {
      nodes {
        userExternalId
        traineeId
        trainingTrackerUuid
        traineeFirstName
        traineeLastName
        traineeLocationCode
        traineeJobTitle
        trainerExternalId
        trainerSupervisorExternalId
        assignedTracker
        completedDate
        assignedDate
        approvedDate
        needApproved
        currentTraineeLeader {
          externalId
          supervisorExternalId
        }
      }
    }

    listOrgDirectors {
      nodes {
        externalId
        supervisorExternalId
      }
    }

    employee(id: $iEmployeeId) {
      id
      jobTitle
      locationId
    }
  }
`

const findSubordinate = (
  root: string,
  subordinate: ISubordinateType,
  data: ISubordinateType[],
): boolean => {
  if (
    root === 'internal test' ||
    subordinate.externalId === root ||
    subordinate.supervisorExternalId === root
  )
    return true

  const parent = data.find(
    (d) => d.externalId === subordinate.supervisorExternalId,
  )

  if (!parent || !parent.supervisorExternalId) {
    return false
  }

  return findSubordinate(root, parent, data)
}

const useCorporateDevelopment = () => {
  const user = getUser()
  const userId = user.employeeId ? user.employeeId : -1
  const { data } = useQuery(queries, {
    variables: {
      iEmployeeId: userId,
    },
    skip: !user,
  })

  return {
    data: useMemo((): number | null => {
      const userExternalId =
        data?.listDirectorTrainingTracker.nodes.length > 0
          ? data?.listDirectorTrainingTracker.nodes[0].userExternalId
          : null

      const subordinateList = userExternalId
        ? data?.listOrgDirectors.nodes.filter((subordinate: ISubordinateType) =>
            findSubordinate(
              userExternalId,
              subordinate,
              data?.listOrgDirectors.nodes,
            ),
          )
        : null

      const trackers = data?.listDirectorTrainingTracker.nodes.filter(
        (tracker: any) => {
          const trainer = tracker.currentTraineeLeader
            ? {
                externalId: tracker.currentTraineeLeader.externalId,
                supervisorExternalId:
                  tracker.currentTraineeLeader.supervisorExternalId,
              }
            : {
                externalId: tracker.trainerExternalId,
                supervisorExternalId: tracker.trainerSupervisorExternalId,
              }

          return (
            subordinateList.some(
              (subordinate: ISubordinateType) =>
                subordinate.externalId === trainer.externalId ||
                subordinate.externalId === trainer.supervisorExternalId,
            ) ||
            trainer.externalId === 'internal test' ||
            data?.employee.jobTitle === 'Restaurant Leader'
          )
        },
      )

      const filteredTrackers = trackers?.filter(
        (tracker: any) =>
          tracker.needApproved ||
          !tracker.assignedDate ||
          (tracker.assignedTracker === 'Crew Training' &&
            tracker.completedDate &&
            !tracker.approvedDate),
      )

      return filteredTrackers?.length > 0 ? filteredTrackers?.length : null
    }, [data]),
  }
}

export default useCorporateDevelopment
