import { Link } from 'react-router-dom'
import styled from 'styled-components'

export interface IPropsType {
  employee: string
  employeeUrl: string
  revieweeJobTitle: string
  storeCode: string
}

const StyledDiv = styled.div`
  padding: 5px 0px;
`

const EmployeeNameLink = styled(Link)`
  font-family: Lexend-SemiBold;
  font-weight: 700;
`

export const sorter = (a: IPropsType, b: IPropsType) =>
  b.employee.localeCompare(a.employee)

const TrackerName = ({
  employee,
  employeeUrl,
  revieweeJobTitle,
  storeCode,
}: IPropsType) => {
  return (
    <>
      <EmployeeNameLink to={employeeUrl}>{employee}</EmployeeNameLink>
      <StyledDiv>{revieweeJobTitle}</StyledDiv>
      <div>{storeCode}</div>
    </>
  )
}

export default TrackerName
