import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import LocationInfo from 'pared/components/LocationInfo'
import LocationSelector from 'pared/components/nav/LocationSelector'
import {
  FILTER_TYPE,
  PRINTABLE_TYPE,
  RANKINGS_ROW_TYPE,
  SALESMANSHIP_COLUMN_NAMES,
  STORE_AVG_TEXT,
} from 'pared/constants'
import { EDDIE_MERLOTS_BRAND_CODE } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import { IPeriod } from 'pared/data/getPeriods'
import { toUsdString } from 'pared/utils/number'

import PrintPdf from './Pdf/PrintPdf'
import PlusMinusTable from './PlusMinusTable'
import ProductivityCoefficientChart from './ProductivityCoefficientChart'
import StaffSalesTable from './StaffSalesTable'
import TimeframeDropdown from './TimeframeDropdown'
import useSalesmanshipConfig from './hooks/useSalesmanshipConfig'
import { ILocationData, ISalesmanship } from './index'

interface IProps {
  isLoading: boolean
  navParams: INavParams
  locationData: ILocationData | null
  errorMessage: string
  salesmanships: ISalesmanship[]
  dayPart: string
  setDayPart: any
  isLeaderboardsExpanded: boolean
  setIsLeaderboardsExpanded: any
  filterType: string
  setFilterType: any
  numDaysWorked: number
  setNumDaysWorked: any
  selectedPeriod: IPeriod | null
  setSelectedPeriod: any
  dateRangeStartDate: any
  setDateRangeStartDate: any
  dateRangeEndDate: any
  setDateRangeEndDate: any
  selectedValue: string
  setSelectedValue: (selectedValue: string) => void
}

interface IRankTableTh {
  textAlign?: string
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
  isAvg?: boolean
}

const DATE_FORMAT = 'MM/DD/yyyy'

const WebLargeMain = ({
  isLoading,
  navParams,
  locationData,
  errorMessage,
  salesmanships,
  dayPart,
  setDayPart,
  isLeaderboardsExpanded,
  setIsLeaderboardsExpanded,
  filterType,
  setFilterType,
  numDaysWorked,
  setNumDaysWorked,
  selectedPeriod,
  setSelectedPeriod,
  dateRangeStartDate,
  setDateRangeStartDate,
  dateRangeEndDate,
  setDateRangeEndDate,
  selectedValue,
  setSelectedValue,
}: IProps) => {
  const brandSettings = getBrandSettings()
  const isQsr = brandSettings.isQsr
  const isLegacy = brandSettings.isLegacy
  const { ppa: ppaLabel, ppaFull: ppaFullLabel } =
    brandSettings.labels.salesmanship
  const salesmanshipConfig = useSalesmanshipConfig()

  const handleLeaderboardsDaypartChange = async (event: any) => {
    const value = _.get(event, 'target.value', '')
    setDayPart(value)
  }

  const onToggleExpandLeaderboard = () => {
    setIsLeaderboardsExpanded(!isLeaderboardsExpanded)
  }

  if (isLoading) {
    return (
      <PageStatusContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </PageStatusContainer>
    )
  }

  if (errorMessage) {
    return (
      <PageStatusContainer>
        <PageStatusDiv>{errorMessage}</PageStatusDiv>
      </PageStatusContainer>
    )
  }

  if (!locationData || !locationData.location.id) {
    return (
      <PageStatusContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </PageStatusContainer>
    )
  }

  const rankedTotalSales = _.orderBy(
    salesmanships,
    ({ grossSales }) => grossSales || 0,
    'desc',
  )
  const rankedSalesPerHour = _.orderBy(
    salesmanships,
    ({ salesPerHour }) => salesPerHour || 0,
    'desc',
  )
  const rankedPpa = _.orderBy(
    salesmanships,
    ({ salesPpa }) => salesPpa || 0,
    'desc',
  )
  const rankedSosAvgTime = _.orderBy(salesmanships, 'sosAvgTime', 'asc')

  const getRankedRows = (rankedData: any, numberOfDecimals: number = 0) => {
    return _.map(rankedData, (d) => {
      const name = _.get(d, 'name', '')
      const rank = _.get(d, 'rank', 1)
      const dataPoint = _.get(d, 'dataPoint', 0)
      const rowType = _.get(d, 'rowType', '')

      return rowType === RANKINGS_ROW_TYPE.AVG ? (
        <AvgTr>
          <RankNumberTd bold textAlign={'left'}></RankNumberTd>
          <RankTableTd textAlign={'left'} isAvg>
            {STORE_AVG_TEXT}
          </RankTableTd>
          <RankTableTd textAlign={'left'} isAvg>
            {toUsdString(dataPoint, numberOfDecimals)}
          </RankTableTd>
        </AvgTr>
      ) : (
        <tr>
          <RankNumberTd bold textAlign={'left'}>
            {rank}
          </RankNumberTd>
          <RankTableTd textAlign={'left'}>{name}</RankTableTd>
          <RankTableTd textAlign={'left'}>
            {toUsdString(dataPoint, numberOfDecimals)}
          </RankTableTd>
        </tr>
      )
    })
  }

  const getSpeedRankedRows = (
    rankedData: any,
    numberOfDecimals: number = 0,
  ) => {
    return _.map(rankedData, (d) => {
      const name = _.get(d, 'name', '')
      const rank = _.get(d, 'rank', 1)
      const dataPoint = _.get(d, 'dataPoint', 0)
      const rowType = _.get(d, 'rowType', '')

      return rowType === RANKINGS_ROW_TYPE.AVG ? (
        <AvgTr>
          <RankNumberTd bold textAlign={'left'}></RankNumberTd>
          <RankTableTd textAlign={'left'} isAvg>
            {STORE_AVG_TEXT}
          </RankTableTd>
          <RankTableTd textAlign={'left'} isAvg>
            {dataPoint.toFixed(numberOfDecimals)}
          </RankTableTd>
        </AvgTr>
      ) : (
        <tr>
          <RankNumberTd bold textAlign={'left'}>
            {rank}
          </RankNumberTd>
          <RankTableTd textAlign={'left'}>{name}</RankTableTd>
          <RankTableTd textAlign={'left'}>
            {dataPoint.toFixed(numberOfDecimals)}
          </RankTableTd>
        </tr>
      )
    })
  }

  const normalizedTotalSales = _.map(rankedTotalSales, (s) => {
    const lastName = _.startCase(_.get(s, 'lastName', ''))
    const firstName = lastName
      ? _.startCase(_.get(s, 'firstName', ''))
      : _.get(s, 'firstName', '')
    const name = `${firstName} ${lastName}`
    const rank = _.get(s, 'grossSalesRank', 1)
    const grossSales = _.get(s, 'grossSales', 0)
    const rowType = _.get(s, 'rowType', '')

    return {
      name,
      rank,
      dataPoint: grossSales,
      rowType,
    }
  })

  const normalizedSalesPerHour = _.map(rankedSalesPerHour, (s) => {
    const lastName = _.startCase(_.get(s, 'lastName', ''))
    const firstName = lastName
      ? _.startCase(_.get(s, 'firstName', ''))
      : _.get(s, 'firstName', '')
    const name = `${firstName} ${lastName}`
    const salesPerHourRank = _.get(s, 'salesPerHourRank', 1)
    const salesPerHour = _.get(s, 'salesPerHour', 0)
    const rowType = _.get(s, 'rowType', '')

    return {
      name,
      rank: salesPerHourRank,
      dataPoint: salesPerHour,
      rowType,
    }
  })

  const normalizedPpa = _.map(rankedPpa, (s) => {
    const lastName = _.startCase(_.get(s, 'lastName', ''))
    const firstName = lastName
      ? _.startCase(_.get(s, 'firstName', ''))
      : _.get(s, 'firstName', '')
    const name = `${firstName} ${lastName}`
    const salesPpaRank = _.get(s, 'salesPpaRank', 1)
    const salesPpa = _.get(s, 'salesPpa', 0)
    const rowType = _.get(s, 'rowType', '')

    return {
      name,
      rank: salesPpaRank,
      dataPoint: salesPpa,
      rowType,
    }
  })

  const normalizedSpeed: any[] = []
  _.forEach(rankedSosAvgTime, (s, index) => {
    const lastName = _.startCase(_.get(s, 'lastName', ''))
    const firstName = lastName
      ? _.startCase(_.get(s, 'firstName', ''))
      : _.get(s, 'firstName', '')
    const name = `${firstName} ${lastName}`
    const sosAvgTimeRank = _.get(s, 'sosAvgTimeRank', 1)
    const sosAvgTime = _.get(s, 'sosAvgTime', 0)
    const rowType = _.get(s, 'rowType', '')

    if (sosAvgTime > 0) {
      normalizedSpeed.push({
        name,
        rank: sosAvgTimeRank,
        dataPoint: sosAvgTime,
        rowType,
      })
    }
  })

  const totalSalesRows = getRankedRows(normalizedTotalSales, 0)
  const salesPerHourRows = getRankedRows(normalizedSalesPerHour, 2)
  const ppaRows = getRankedRows(normalizedPpa, 2)
  const speedRows = getSpeedRankedRows(normalizedSpeed, 1)

  let toggledSalesRows = totalSalesRows
  let toggledSalesPerHourRows = salesPerHourRows
  let toggledPpaRows = ppaRows
  let toggledSpeedRows = speedRows

  if (!isLeaderboardsExpanded) {
    toggledSalesRows = _.slice(totalSalesRows, 0, 5)
    toggledSalesPerHourRows = _.slice(salesPerHourRows, 0, 5)
    toggledPpaRows = _.slice(ppaRows, 0, 5)
    toggledSpeedRows = _.slice(speedRows, 0, 5)
  }

  const leaderboardToggleText = isLeaderboardsExpanded
    ? 'Collapse all'
    : 'Expand All'

  const director = {
    name: locationData?.location?.doName,
    id: locationData?.location?.doEmployeeId,
  }
  const gm = {
    name: locationData?.location?.gmName,
    id: locationData?.location?.gmEmployeeId,
  }

  const storeTitle = `Store: ${_.get(locationData, 'location.displayName', '')}`

  let printableSubtitle = selectedValue
  if (filterType === FILTER_TYPE.SHIFTS) {
    printableSubtitle = `${selectedValue} as of ${moment().format(DATE_FORMAT)}`
  }

  return (
    <MainContainer>
      <StoreInfoTable>
        <tbody>
          <tr>
            <td>
              <PageHeaderLineContainer>
                <PageTopContainer>
                  <PageLeftHeader>{storeTitle}</PageLeftHeader>
                </PageTopContainer>
                <SelectorContainer>
                  <LocationSelectorContainer>
                    <LocationSelector navParams={navParams} />
                  </LocationSelectorContainer>
                </SelectorContainer>
              </PageHeaderLineContainer>
              <DoNameContainer>
                <LocationInfo director={director} gm={gm} />
              </DoNameContainer>
            </td>
          </tr>
        </tbody>
      </StoreInfoTable>
      {brandSettings.brand === EDDIE_MERLOTS_BRAND_CODE ? (
        <DisclaimerContainer>
          This page uses CrunchTime data, so some locations may not be included
          yet
        </DisclaimerContainer>
      ) : null}
      <VerticalSpacer20px />
      {isLegacy || isQsr ? <PlusMinusTable navParams={navParams} /> : null}
      {isLegacy || isQsr ? (
        <HeaderContainer>
          <TopFilterContainer>
            <TopFilterByContainer>Filter by</TopFilterByContainer>
            <TimeframeDropdown
              filterType={filterType}
              setFilterType={setFilterType}
              numDaysWorked={numDaysWorked}
              setNumDaysWorked={setNumDaysWorked}
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
              dateRangeStartDate={dateRangeStartDate}
              dateRangeEndDate={dateRangeEndDate}
              setDateRangeStartDate={setDateRangeStartDate}
              setDateRangeEndDate={setDateRangeEndDate}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
            />
            <TimeFilterSelectorContainer>
              <DaypartFormControl
                variant="outlined"
                margin="dense"
                style={{ width: 140 }}
              >
                <SalesmanSelect
                  displayEmpty
                  value={dayPart}
                  onChange={handleLeaderboardsDaypartChange}
                >
                  <MenuItem key="dinner" value="dinner">
                    Dinner Only
                  </MenuItem>
                  <MenuItem key="lunch" value="lunch">
                    Lunch Only
                  </MenuItem>
                  <MenuItem key="all" value="all">
                    All
                  </MenuItem>
                </SalesmanSelect>
              </DaypartFormControl>
            </TimeFilterSelectorContainer>
          </TopFilterContainer>
        </HeaderContainer>
      ) : null}
      <ContentContainer>
        {salesmanshipConfig['productivityCoefficient']?.isVisible ? (
          <>
            <ProductivityCoefficientChart
              selectedLocation={locationData.location}
            />
            <VerticalSpacer30px />
          </>
        ) : null}

        <LeaderboardsContainer>Leaderboards</LeaderboardsContainer>
        {isQsr || !isLegacy ? (
          <VerticalSpacer10px />
        ) : (
          <SubtitleContainer>Main Dining and Bar Sales Only</SubtitleContainer>
        )}
        <VerticalSpacer20px />
        <DetailTable>
          <DetailTbody>
            <tr>
              <DetailTd>
                <TableTitleContainer>
                  <TableDescription>TOTAL SALES</TableDescription>
                  <PrintPdfContainer>
                    <PrintPdf
                      rankedData={normalizedTotalSales}
                      storeTitle={storeTitle}
                      selectedValue={printableSubtitle}
                      tableTitle={'TOTAL SALES'}
                      dataPointColumnName={
                        SALESMANSHIP_COLUMN_NAMES.total_sales
                      }
                      printableType={PRINTABLE_TYPE.SALESMANSHIP_TABLE}
                      dayPart={dayPart}
                    />
                  </PrintPdfContainer>
                </TableTitleContainer>
                <RankTable>
                  <thead>
                    <tr>
                      <RankTableTh textAlign={'left'}>Rank</RankTableTh>
                      <RankTableTh textAlign={'left'}>Name</RankTableTh>
                      <RankTableTh textAlign={'left'}>Total Sales</RankTableTh>
                    </tr>
                  </thead>
                  <tbody>{toggledSalesRows}</tbody>
                </RankTable>
              </DetailTd>
              <DetailTd>
                <TableTitleContainer>
                  <TableDescription>SALES PER LABOR HOUR</TableDescription>
                  <PrintPdfContainer>
                    <PrintPdf
                      rankedData={normalizedSalesPerHour}
                      storeTitle={storeTitle}
                      selectedValue={selectedValue}
                      tableTitle={
                        SALESMANSHIP_COLUMN_NAMES.sales_per_labor_hour
                      }
                      dataPointColumnName={'Sales/Hour'}
                      printableType={PRINTABLE_TYPE.SALESMANSHIP_TABLE}
                      dayPart={dayPart}
                    />
                  </PrintPdfContainer>
                </TableTitleContainer>
                <RankTable>
                  <thead>
                    <tr>
                      <RankTableTh textAlign={'left'}>Rank</RankTableTh>
                      <RankTableTh textAlign={'left'}>Name</RankTableTh>
                      <RankTableTh textAlign={'left'}>Sales/Hour</RankTableTh>
                    </tr>
                  </thead>
                  <tbody>{toggledSalesPerHourRows}</tbody>
                </RankTable>
              </DetailTd>
              <DetailTd>
                <TableTitleContainer>
                  <TableDescription>
                    {ppaFullLabel.toUpperCase()}
                  </TableDescription>
                  <PrintPdfContainer>
                    <PrintPdf
                      rankedData={normalizedPpa}
                      storeTitle={storeTitle}
                      selectedValue={selectedValue}
                      tableTitle={SALESMANSHIP_COLUMN_NAMES.per_person_average}
                      dataPointColumnName={ppaLabel}
                      printableType={PRINTABLE_TYPE.SALESMANSHIP_TABLE}
                      dayPart={dayPart}
                    />
                  </PrintPdfContainer>
                </TableTitleContainer>
                <RankTable>
                  <thead>
                    <tr>
                      <RankTableTh textAlign={'left'}>Rank</RankTableTh>
                      <RankTableTh textAlign={'left'}>Name</RankTableTh>
                      <RankTableTh textAlign={'left'}>{ppaLabel}</RankTableTh>
                    </tr>
                  </thead>
                  <tbody>{toggledPpaRows}</tbody>
                </RankTable>
              </DetailTd>
              {isQsr ? (
                <DetailTd>
                  <TableTitleContainer>
                    <TableDescription>SPEED OF SERVICE</TableDescription>
                    <PrintPdfContainer>
                      <PrintPdf
                        rankedData={normalizedSpeed}
                        storeTitle={storeTitle}
                        selectedValue={selectedValue}
                        tableTitle={SALESMANSHIP_COLUMN_NAMES.speed_of_service}
                        dataPointColumnName={'Seconds'}
                        printableType={PRINTABLE_TYPE.SALESMANSHIP_TABLE}
                        dayPart={dayPart}
                      />
                    </PrintPdfContainer>
                  </TableTitleContainer>
                  <RankTable>
                    <thead>
                      <tr>
                        <RankTableTh textAlign={'left'}>Rank</RankTableTh>
                        <RankTableTh textAlign={'left'}>Name</RankTableTh>
                        <RankTableTh textAlign={'left'}>Seconds</RankTableTh>
                      </tr>
                    </thead>
                    <tbody>{toggledSpeedRows}</tbody>
                  </RankTable>
                </DetailTd>
              ) : null}
            </tr>
          </DetailTbody>
        </DetailTable>
        <ExpandAllSpan onClick={onToggleExpandLeaderboard}>
          {leaderboardToggleText}
        </ExpandAllSpan>
        <VerticalSpacer30px />
        <StaffSalesTable
          navParams={navParams}
          selectedPeriod={selectedPeriod}
          dayPart={dayPart}
          filterType={filterType}
          numDaysWorked={numDaysWorked}
          dateRangeStartDate={dateRangeStartDate}
          dateRangeEndDate={dateRangeEndDate}
          storeTitle={storeTitle}
          selectedValue={printableSubtitle}
        />
        <VerticalSpacer400px />
      </ContentContainer>
    </MainContainer>
  )
}

const MainContainer = styled.div``

const TableTitleContainer = styled.div`
  display: inline-block;
`

const AvgTr = styled.tr`
  background-color: ${COLORS.Sky} !important;
`

const PrintPdfContainer = styled.div`
  float: left;
  margin-left: 15px;
  margin-top: 4px;
`

const PageTopContainer = styled.div`
  float: left;
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;
`

const DisclaimerContainer = styled(SubtitleContainer)`
  margin: 10px 0 0 50px;
`

const DoNameContainer = styled.div`
  margin-top: 10px;
`

const PageHeaderLineContainer = styled.div`
  display: inline-block;
  width: 1250px;
`

const LocationSelectorContainer = styled.div`
  float: right;
  display: inline-block;
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0 0;
  float: left;
`

const PageStatusContainer = styled.div`
  padding-left: 50px;
  padding-right: 50px;
`

const DaypartFormControl = styled(FormControl)`
  background-color: white;
`

const SalesmanSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const TopFilterContainer = styled.div`
  height: 65px;
  width: 100%;
  background-color: #c4c4c4;
  padding-left: 15px;
  display: inline-block;
`

const TopFilterByContainer = styled.div`
  float: left;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 23px;
  padding-left: 40px;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const SelectorContainer = styled.div`
  float: right;
  display: inline-block;
  margin-left: 25px;
`

const TimeFilterSelectorContainer = styled.div`
  float: left;
  margin-left: 25px;
  margin-top: 5px;
`

const VerticalSpacer10px = styled.div`
  padding: 10px 50px 10px 50px;
  width: 1200px;
`

const VerticalSpacer20px = styled.div`
  padding: 20px 50px 20px 50px;
  width: 1200px;
`

const VerticalSpacer30px = styled.div`
  padding: 30px 50px 30px 50px;
  width: 1200px;
`

const VerticalSpacer400px = styled.div`
  padding: 400px 50px 30px 50px;
  width: 1200px;
`

const ExpandAllSpan = styled.div`
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;
`

const TableDescription = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 10px;
  float: left;
`

const HeaderContainer = styled.div`
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
`

const DetailTable = styled.table`
  width: 1300px;
`

const DetailTbody = styled.tbody`
  width: 100%;
`

const DetailTd = styled.td`
  padding: 0 20px 0 0;
`

const RankTableTh = styled.th<IRankTableTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankTableTd = styled.td<IRankTableTd>`
  color: ${(props) => (props.isAvg ? 'white' : 'black')};
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankNumberTd = styled(RankTableTd)`
  padding-left: 12px;
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  float: left;
`

const LeaderboardsContainer = styled(PageHeader)`
  width: 100%;
`

const StoreInfoTable = styled.table`
  width: 100%;
  font-family: Lexend-Regular;
  color: ${COLORS.Chalkboard};
  padding-left: 50px;
  padding-right: 50px;
`

const ContentContainer = styled.div`
  padding-left: 50px;
  padding-right: 50px;
`

const StoreNameDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 10px 0;
`

const RankTable = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`

export default WebLargeMain
