import moment from 'moment'
import styled from 'styled-components'

import useFetchNormalizationStatus from './hooks/useFetchNormalizationStatus'

interface IProps {
  normalizationTable: string
  brandId?: number
}

const NormalizationStatus = ({ normalizationTable, brandId }: IProps) => {
  const currentStatus = useFetchNormalizationStatus(normalizationTable, brandId)

  if (currentStatus.latestNormalizedDate) {
    return (
      <UpdatedText>
        Updated:{' '}
        {moment
          .utc(currentStatus.latestNormalizedDate, 'YYYY-MM-DD', true)
          .add(1, 'day')
          .format('M/D')}
      </UpdatedText>
    )
  }

  return null
}

const UpdatedText = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

export default NormalizationStatus
