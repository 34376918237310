import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { IOrgChartNode } from 'pared/components/OrgChart'

interface IOrgDirectorsNodeType {
  id: number
  givenName: string
  familyName: string
  preferredName: string
  externalId: string
  jobTitle: string
  supervisorExternalId: string
}

interface IOrgDirectorsInfoType {
  listOrgDirectors: {
    nodes: IOrgDirectorsNodeType[]
  }
}

const query = gql`
  query ListOrgDirectors {
    listOrgDirectors {
      nodes {
        id
        givenName
        familyName
        preferredName
        externalId
        jobTitle
        supervisorExternalId
      }
    }
  }
`

const getEmployeeOrgData = (
  root: IOrgDirectorsNodeType,
  data: IOrgDirectorsNodeType[],
): IOrgChartNode => {
  const name = `${root.givenName} ${root.familyName}`
  const children = data.filter(
    (d) => d.supervisorExternalId === root.externalId,
  )
  if (children.length === 0) {
    return { name, position: root.jobTitle, cssClass: '' }
  } else {
    return {
      name,
      position: root.jobTitle,
      cssClass: '',
      childs: children.map((c) => getEmployeeOrgData(c, data)),
    }
  }
}

const useCorporateOrgInfo = () => {
  const directorTitles = [
    'Owner',
    'President',
    'Regional Director',
    'District Leader',
  ]

  const { data, loading } = useQuery<IOrgDirectorsInfoType>(query)

  const orgDirectorInfo = data?.listOrgDirectors?.nodes

  return {
    data: useMemo(() => {
      if (!orgDirectorInfo) return []

      const orgDirectors = orgDirectorInfo.filter((employee) =>
        directorTitles.includes(employee.jobTitle),
      )

      const rootEmployee = orgDirectors.find((e) => !e.supervisorExternalId)
      if (!rootEmployee) return []

      return [getEmployeeOrgData(rootEmployee, orgDirectors)]
    }, [data]),
    loading,
  }
}

export default useCorporateOrgInfo
