import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import FullWidthTable from 'pared/components/FullWidthTable'
import COLORS from 'pared/constants/colors'

import {
  IDevelopmentalFeedback,
  IEmployeeData,
  IPositionDetails,
} from './index'

interface IProps {
  shouldShowSensitiveData: boolean
  isLoading: boolean
  employeeData: IEmployeeData | null | undefined
  error: any
}

function WebLargeMain({
  shouldShowSensitiveData,
  isLoading,
  employeeData,
  error,
}: IProps) {
  if (isLoading || employeeData === undefined) {
    return (
      <MainContainer>
        <PageHeader>Employee Profile</PageHeader>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </MainContainer>
    )
  }

  if (error) {
    return (
      <MainContainer>
        <PageHeader>Employee Profile</PageHeader>
        <PageStatusDiv>
          {`Unexpected Error. Please try again or contact support@getexpo.com. (${error?.message})`}
        </PageStatusDiv>
      </MainContainer>
    )
  }

  if (employeeData === null) {
    return (
      <MainContainer>
        <PageHeader>Employee Profile</PageHeader>
        <PageStatusDiv>The employee is not found.</PageStatusDiv>
      </MainContainer>
    )
  }

  let careerPathContent = null
  if (
    Array.isArray(employeeData.employeeHistories) &&
    employeeData.employeeHistories.length > 0
  ) {
    careerPathContent = employeeData.employeeHistories.map(
      (position: IPositionDetails) => {
        let positionStore: React.ReactNode = '-'
        if (position.store) {
          if (position.store.brandId) {
            positionStore = (
              <Link to={navigator.storeDetail(position.store.id)}>
                {position.store.code} - {position.store.name}
              </Link>
            )
          } else {
            positionStore = position.store.name || '-'
          }
        }

        return (
          <tr key={position.id}>
            <CareerPathTd>{position.startDate}</CareerPathTd>
            <CareerPathTd>{position.timeTillNextEvent}</CareerPathTd>
            <CareerPathTd>{position.role}</CareerPathTd>
            <CareerPathTd>{position.employmentType}</CareerPathTd>
            <CareerPathTd>{positionStore}</CareerPathTd>
            <CareerPathTd>{position.event}</CareerPathTd>
            <CareerPathTd>{position.eventDetails}</CareerPathTd>
          </tr>
        )
      },
    )
  } else {
    careerPathContent = (
      <tr>
        <CareerPathTd colSpan={6}>No career history found</CareerPathTd>
      </tr>
    )
  }

  let devFeedbackHistoryContent = null
  if (Array.isArray(employeeData.developmentalFeedbackHistory)) {
    devFeedbackHistoryContent = employeeData.developmentalFeedbackHistory.map(
      (feedback: IDevelopmentalFeedback) => {
        let store: React.ReactNode = '-'
        if (feedback.location) {
          store = (
            <Link to={navigator.storeDetail(feedback.location.id)}>
              {feedback.location.code} - {feedback.location.name}
            </Link>
          )
        }

        let reviewer: React.ReactNode = '-'
        if (feedback.reviewer) {
          reviewer = (
            <Link to={navigator.employeeProfile(feedback.reviewer.id)}>
              {feedback.reviewer.firstName} {feedback.reviewer.lastName}
            </Link>
          )
        }

        return (
          <tr key={feedback.id}>
            <ReviewTd>{feedback.time}</ReviewTd>
            <ReviewTd>{store}</ReviewTd>
            <ReviewTd>{feedback.performance}</ReviewTd>
            <ReviewTd>{feedback.feedback}</ReviewTd>
            <ReviewTd>{reviewer}</ReviewTd>
          </tr>
        )
      },
    )
  } else {
    devFeedbackHistoryContent = (
      <tr>
        <ReviewTd colSpan={5}>No feedback found</ReviewTd>
      </tr>
    )
  }

  let store: React.ReactNode = '-'
  if (employeeData.store) {
    if (
      employeeData.store.concept &&
      employeeData.store.concept.toLowerCase() !== 'office'
    ) {
      store = (
        <Link to={navigator.storeDetail(employeeData.store.id)}>
          {employeeData.store.code} - {employeeData.store.name}
        </Link>
      )
    } else {
      store = employeeData.store.name || '-'
    }
  }

  let manager: React.ReactNode = '-'
  if (employeeData.employeeManager) {
    manager = (
      <Link
        to={navigator.employeeProfile(employeeData.employeeManager.employeeId)}
      >
        {employeeData.employeeManager.firstName}{' '}
        {employeeData.employeeManager.lastName}
      </Link>
    )
  }

  return (
    <MainContainer>
      <PageHeader>Employee Profile</PageHeader>
      <FullWidthTable>
        <tbody>
          <tr>
            <EmployeeNameTd>
              {employeeData.firstName} {employeeData.lastName}
            </EmployeeNameTd>
            <PromotionPotentialTd>
              {employeeData.potentialForPromotion ? (
                <>
                  High Potential for {employeeData.potentialForPromotion}{' '}
                  Promotion
                </>
              ) : null}
            </PromotionPotentialTd>
          </tr>
        </tbody>
      </FullWidthTable>

      <FullWidthTable>
        <tbody>
          <tr>
            <FactFirstColTd>
              <FactItem>Store: {store}</FactItem>
              <FactItem>Role: {employeeData.role || '-'}</FactItem>
              <FactItem>
                Employment Type: {employeeData.employmentType || '-'}
              </FactItem>
              <FactItem>Status: {employeeData.status || '-'}</FactItem>
            </FactFirstColTd>
            <FactSecondColTd>
              <FactItem>Tenure: {employeeData.tenure || '-'}</FactItem>
              <FactItem>Start Date: {employeeData.startDate || '-'}</FactItem>
              {employeeData.endDate ? (
                <FactItem>End Date: {employeeData.endDate || '-'}</FactItem>
              ) : null}
            </FactSecondColTd>
          </tr>
        </tbody>
      </FullWidthTable>

      {shouldShowSensitiveData ? (
        <>
          <VerticalSpacer80px />
          <SectionHeader>Career Path</SectionHeader>
          <FullWidthTable>
            <thead>
              <tr>
                <CareerPathTh>Start Date</CareerPathTh>
                <CareerPathTh>Time Till Next Event</CareerPathTh>
                <CareerPathTh>Role</CareerPathTh>
                <CareerPathTh>Employment Type</CareerPathTh>
                <CareerPathTh>Store</CareerPathTh>
                <CareerPathTh>Event</CareerPathTh>
                <CareerPathTh>More Details</CareerPathTh>
              </tr>
            </thead>
            <tbody>{careerPathContent}</tbody>
          </FullWidthTable>

          <VerticalSpacer80px />
          <SectionHeader>Developmental Feedback</SectionHeader>
          <FullWidthTable>
            <thead>
              <tr>
                <ReviewTh>Time</ReviewTh>
                <ReviewTh>Store</ReviewTh>
                <ReviewTh>Performance</ReviewTh>
                <ReviewTh>Feedback</ReviewTh>
                <ReviewTh>Reviewed By</ReviewTh>
              </tr>
            </thead>
            <tbody>{devFeedbackHistoryContent}</tbody>
          </FullWidthTable>
        </>
      ) : null}

      <VerticalSpacer80px />
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 1200px;
`

const PageHeader = styled.div`
  font-family: Lexend-Regular;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
`

const PageStatusDiv = styled.div`
  padding: 30px 0 0 0;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const EmployeeNameTd = styled.td`
  vertical-align: top;
  font-family: Lexend-Regular;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
`

const PromotionPotentialTd = styled.td`
  vertical-align: top;
  font-family: Lexend-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-align: right;
  color: ${COLORS.Expo};
`

const FactFirstColTd = styled.td`
  width: 360px;
`

const FactSecondColTd = styled.td`
  width: 440px;
`

const FactItem = styled.div`
  font-family: Lexend-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 0;
`

const PhotoTd = styled.td`
  vertical-align: top;
  text-align: right;
`

const PhotoImg = styled.img`
  width: 200px;
  height: 200px;
`

const VerticalSpacer80px = styled.div`
  width: 100%;
  height: 80px;
`

const SectionHeader = styled.div`
  font-family: Lexend-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 0 0 20px 0;
`

const CareerPathTh = styled.th`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
`

const CareerPathTd = styled.th`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
`

const ReviewTh = styled.th`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: left;
`

const ReviewTd = styled.th`
  max-width: 400px;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
`

export default WebLargeMain
