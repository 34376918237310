import _ from 'lodash'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import { MONTH_ABBREVIATION_MAP } from 'pared/constants'
import COLORS from 'pared/constants/colors'
import { toPercentageString } from 'pared/utils/number'

interface IProps {
  xAxisData: string[]
  yAxisInaccurateData: number[]
  yAxisCancelledData: number[]
  yAxisDelayedData: number[]
  yAxisTotalOrderCountData: number[]
  yAxisAnyIssueData: number[]
}

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

function WebLargeMain({
  xAxisData,
  yAxisInaccurateData,
  yAxisCancelledData,
  yAxisDelayedData,
  yAxisTotalOrderCountData,
  yAxisAnyIssueData,
}: IProps) {
  const chartOptions = {
    title: '',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: '',
    yLeftTickCallback: (value: number) => {
      return toPercentageString(value, 1)
    },
    yRightAxisLabel: '# of Delivery Orders',
    yRightShowGridLines: false,
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let datasetLabel = ''
        let separator = ''
        let yValue = ''
        if (tooltipItemContext.dataset && tooltipItemContext.dataset.label) {
          datasetLabel = tooltipItemContext.dataset.label
          separator = ': '
        }
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          if (tooltipItemContext.datasetIndex === 4) {
            yValue = tooltipItemContext.parsed.y
          } else {
            yValue = toPercentageString(tooltipItemContext.parsed.y, 1)
          }
        }
        return [datasetLabel, separator, yValue].join('')
      }
      return ''
    },
  }

  return (
    <>
      <SectionTitleDiv>DELIVERY CHART</SectionTitleDiv>
      <MixedChart
        xAxisData={xAxisData}
        yAxisDataArr={[
          {
            type: 'line',
            data: yAxisAnyIssueData,
            tooltipLabel: 'Order w/ Any Issues',
            borderColor: COLORS.Basil,
            backgroundColor: COLORS.Basil,
          },
          {
            type: 'line',
            data: yAxisInaccurateData,
            tooltipLabel: 'Accuracy',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
          {
            type: 'line',
            data: yAxisCancelledData,
            tooltipLabel: 'Cancellations',
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
          {
            type: 'line',
            data: yAxisDelayedData,
            tooltipLabel: 'Lateness',
            borderColor: COLORS.Mango,
            backgroundColor: COLORS.Mango,
          },
          {
            type: 'bar',
            yAxisId: 'yRight',
            data: yAxisTotalOrderCountData,
            tooltipLabel: '# of Delivery Orders',
          },
        ]}
        options={chartOptions}
      />
    </>
  )
}

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 20px 0;
`

export default WebLargeMain
