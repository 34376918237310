import _ from 'lodash'
import { useEffect, useRef } from 'react'

import { Chart, ChartConfiguration, ChartType } from '../chart'
import * as configs from '../configs'

export interface IConfigType
  extends Omit<ChartConfiguration<ChartType>, 'type'> {
  type: keyof typeof configs
}

const useChartCanvas = ({ type, ...config }: IConfigType) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const chart = new Chart(
      canvasRef.current,
      _.merge({}, configs[type], config),
    )

    return () => {
      if (chart) chart.destroy()
    }
  }, [type, config])

  return canvasRef
}

export default useChartCanvas
