import useCorporateDevelopment from './useCorporateDevelopment'

export type IApiKeyType = keyof typeof api

const api = {
  corporateDevelopment: useCorporateDevelopment,
}

const apiKeys = Object.keys(api) as (keyof typeof api)[]

const useApi = (apiName?: IApiKeyType | string) =>
  apiName && apiKeys.includes(apiName as IApiKeyType)
    ? api[apiName as IApiKeyType]()
    : { data: apiName }

export default useApi
