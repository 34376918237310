import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

import {
  GET_DISPUTE_REASONS,
  IDisputeReasonType,
  IDisputeReasonVariablesType,
} from '../gqls'

export interface IDataSourceType {
  reason: string
  totalCount: number
  totalAmount: number
  rowIndex: number
}

interface IProps {
  isBreakdownByDirectors: boolean
  startDate: string
  endDate: string
  locationGroupId?: number
  locationGroupIds?: number[]
  breakdownType?: string | null
  locationIds?: number[]
}

export default ({
  isBreakdownByDirectors,
  startDate,
  endDate,
  locationGroupId,
  locationGroupIds,
  breakdownType,
  locationIds,
}: IProps): {
  dataSource: IDataSourceType[]
  loading: boolean
} => {
  const brand = getBrand()
  const filter = (() => {
    if (breakdownType) {
      return {
        location_group_types: [breakdownType],
      }
    } else if (isBreakdownByDirectors) {
      return {
        location_group_ids: locationGroupIds,
      }
    } else {
      if (locationGroupId) return { location_group_ids: [locationGroupId] }
      else return { location_ids: locationIds }
    }
  })()
  const { data, loading } = useQuery<
    IDisputeReasonType,
    IDisputeReasonVariablesType
  >(GET_DISPUTE_REASONS, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: filter,
    },
  })

  const dataSource = useMemo(() => {
    if (!data?.getDisputeReasons.nodes) return []

    return data.getDisputeReasons.nodes.map((row, idx) => ({
      ...row,
      rowIndex: idx,
    }))
  }, [data, loading])

  return {
    dataSource,
    loading,
  }
}
