import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/constants/styles'

import useApi, { IApiKeyType } from './hooks/useApi'

export interface IPropsType {
  api?: IApiKeyType | string[]
}

const SubTitleContainer = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;

  @media ${MOBILE_WIDTH} {
    font-size: 16px;
    & ~ & {
      margin-top: 10px;
    }
  }
`

const SubTitle = ({ api }: IPropsType) => {
  const { data, loading } = useApi(api)

  if (loading) return <SubTitleContainer>Loading ...</SubTitleContainer>

  if (!data) return null

  return (
    <SubTitleContainer>
      {Array.isArray(data) ? data?.map((r) => <div key={r}>{r}</div>) : data}
    </SubTitleContainer>
  )
}

export default SubTitle
