import { gql } from '@apollo/client'

export const QUERY_GET_EMAILS_FOR_REPORT = gql`
  query GetEmailsForReport($report: String!) {
    getEmailsForReport(iReport: $report) {
      nodes
    }
  }
`

export const MUTATION_CREATE_EMAIL_SUBSCRIPTION = gql`
  mutation CreateEmailSubscription($report: String!, $email: String!) {
    createEmailSubscription(
      input: { emailSubscription: { report: $report, email: $email } }
    ) {
      clientMutationId
    }
  }
`

export const MUTATION_REMOVE_EMAIL_SUBSCRIPTION = gql`
  mutation RemoveEmailSubscription($report: String!, $email: String!) {
    removeEmailSubscription(input: { iReport: $report, iEmail: $email }) {
      clientMutationId
    }
  }
`
