import _ from 'lodash'

export function getEmployeeInfo(data: any) {
  const employeeNameParts = []
  const firstName = _.get(data, 'employeeInfo.preferredName') || ''
  if (firstName) {
    employeeNameParts.push(firstName)
  }
  const lastName = _.get(data, 'employeeInfo.familyName') || ''
  if (lastName) {
    employeeNameParts.push(lastName)
  }

  return {
    employeeId: _.get(data, 'employeeInfo.id'),
    employeeName: employeeNameParts.join(' '),
    roleName: _.get(data, 'roleInfo.name'),
    daysWorked: _.get(data, 'daysWorked'),
    isStoreAvg: false,
  }
}

export function addRankToTableData(tableData: any[]) {
  let numData = 0
  let currentRank = 1
  let lastValue = undefined
  const tableDataWithRank = []
  const dataCount = tableData.length
  for (let di = 0; di < dataCount; ++di) {
    const data = tableData[di]
    if (data.isStoreAvg) {
      tableDataWithRank.push({ ...data })
    } else {
      ++numData
      if (data.value !== lastValue) {
        currentRank = numData
        lastValue = data.value
      }
      tableDataWithRank.push({
        ...data,
        rank: currentRank,
      })
    }
  }
  return tableDataWithRank
}

const formatPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const getPrice = (value: number) => formatPrice.format(value / 100)
