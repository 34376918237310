import { gql } from '@apollo/client'

export const TREND_SUMMED_CASH_CONTROL_DETAILS = gql`
  query TrendSummedCashControlDetails(
    $iFilter: JSON!
    $iEndDate: Date!
    $iGroupBy: String!
    $iDateRangeNumber: Int
  ) {
    trendSummedCashControlDetails(
      iFilter: $iFilter
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iDateRangeNumber: $iDateRangeNumber
    ) {
      nodes {
        startDate
        endDate
        cashControlDetails
      }
    }
  }
`
