import { gql } from '@apollo/client'

interface IListLocationInboundCallsNode {
  locationId: number
  inboundTotal: number
  inboundAnswered: number
  percentAnswered: number
  inboundMissedAndAbandoned: number
  percentMissedAndAbandoned: number
  inboundMissed: number
  percentMissed: number
  inboundAbandoned: number
  percentAbandoned: number
  missedOpportunity: string
  annualOpportunityCost: string
  brandInboundTotal: number
  brandInboundAnswered: number
  brandPercentAnswered: number
  brandInboundMissedAndAbandoned: number
  brandPercentMissedAndAbandoned: number
  brandInboundMissed: number
  brandPercentMissed: number
  brandInboundAbandoned: number
  brandPercentAbandoned: number
}

interface IListLocationEmployeeCallsNode {
  locationId: number
  employeeName: string
  employeeRole: string
  inboundTotal: number
  percentAnswered: number
  inboundMissedAndAbandoned: number
  percentMissedAndAbandoned: number
  inboundMissed: number
  percentMissed: number
  inboundAbandoned: number
  percentAbandoned: number
}

export interface IListLocationInboundCalls {
  listLocationInboundCalls: {
    nodes: IListLocationInboundCallsNode[]
  }
  listLocationEmployeeCalls: {
    nodes: IListLocationEmployeeCallsNode[]
  }
}

export const LIST_LOCATION_INBOUND_CALLS = gql`
  query ListLocationInboundCalls(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationInboundCalls(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        inboundTotal
        inboundAnswered
        percentAnswered
        inboundMissedAndAbandoned
        percentMissedAndAbandoned
        inboundMissed
        percentMissed
        inboundAbandoned
        percentAbandoned
        missedOpportunity
        annualOpportunityCost
        brandInboundTotal
        brandInboundAnswered
        brandPercentAnswered
        brandInboundMissedAndAbandoned
        brandPercentMissedAndAbandoned
        brandInboundMissed
        brandPercentMissed
        brandInboundAbandoned
        brandPercentAbandoned
      }
    }

    listLocationEmployeeCalls(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        employeeName
        employeeRole
        inboundTotal
        percentAnswered
        inboundMissedAndAbandoned
        percentMissedAndAbandoned
        inboundMissed
        percentMissed
        inboundAbandoned
        percentAbandoned
      }
    }
  }
`
