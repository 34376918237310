import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'

import Summary from 'pared/components/basicUi/Summary'

import {
  GET_LOCATION_VS_SYSTEMWIDE_DELIVERY_KPI,
  LIST_LOCATION_DELIVERY_DAY_TRENDS,
  LIST_LOCATION_DELIVERY_STAFF_KPI,
} from '../gql'
import useCustomizedSummary from './hooks/useCustomizedSummary'

interface IProps {
  locationId: number
  locationName?: string
  startDateStr?: string
  endDateStr?: string
}

const DAYS = {
  1: 'Sunday',
  2: 'Monday',
  3: 'Tuesday',
  4: 'Wednesday',
  5: 'Thursday',
  6: 'Friday',
  7: 'Saturday',
}

const DeliverySummary = ({
  startDateStr,
  endDateStr,
  locationId,
  locationName,
}: IProps) => {
  const [
    getLocationVsSystemwideDeliveryKpi,
    {
      loading: isLocationVsSystemwideDeliveryKpiLoading,
      data: locationVsSystemwideDeliveryKpiData,
    },
  ] = useLazyQuery(GET_LOCATION_VS_SYSTEMWIDE_DELIVERY_KPI)

  const [
    listLocationDeliveryDayTrends,
    {
      loading: isLocationDeliveryDayTrendsLoading,
      data: locationDeliveryDayTrendsData,
    },
  ] = useLazyQuery(LIST_LOCATION_DELIVERY_DAY_TRENDS)

  const [
    listLocationDeliveryStaffKpi,
    {
      loading: isLocationDeliveryStaffKpiLoading,
      data: locationDeliveryStaffKpiData,
    },
  ] = useLazyQuery(LIST_LOCATION_DELIVERY_STAFF_KPI)
  const { customiedAreasOfImprovementMessages } = useCustomizedSummary({
    iStartDate: startDateStr,
    iEndDate: endDateStr,
    iFilter: {
      location_ids: [locationId],
    },
  })

  useEffect(() => {
    const periodStartDate = startDateStr
    const periodEndDate = endDateStr

    if (!periodStartDate || !periodEndDate) return

    getLocationVsSystemwideDeliveryKpi({
      variables: {
        iLocationId: locationId,
        iStartDate: periodStartDate,
        iEndDate: periodEndDate,
      },
    })

    listLocationDeliveryDayTrends({
      variables: {
        iLocationId: locationId,
        iStartDate: periodStartDate,
        iEndDate: periodEndDate,
      },
    })

    listLocationDeliveryStaffKpi({
      variables: {
        iLocationId: locationId,
        iStartDate: periodStartDate,
        iEndDate: periodEndDate,
      },
    })
  }, [locationId, startDateStr, endDateStr])

  let summaryMessage: string | React.ReactElement = ''
  let areasOfImprovementMessages: (string | React.ReactElement)[] = []

  const data =
    locationVsSystemwideDeliveryKpiData?.getLocationVsSystemwideDeliveryKpi
      ?.nodes?.[0]
  let accuracy = false
  let cancellations = false
  let lateness = false

  if (data) {
    const {
      locationInaccurateOrderPercent,
      systemwideInaccurateOrderPercent,
      locationCancelledOrderPercent,
      systemwideCancelledOrderPercent,
      locationDelayedOrderPercent,
      systemwideDelayedOrderPercent,
    } = data

    if (locationInaccurateOrderPercent > systemwideInaccurateOrderPercent) {
      accuracy = true
    }

    if (locationCancelledOrderPercent > systemwideCancelledOrderPercent) {
      cancellations = true
    }

    if (locationDelayedOrderPercent > systemwideDelayedOrderPercent) {
      lateness = true
    }

    if (accuracy && cancellations && lateness) {
      summaryMessage = `${locationName} is performing worse than the company on Accuracy, Cancellations, and Lateness.`
    } else if (accuracy && cancellations) {
      summaryMessage = `${locationName} is performing worse than the company on Accuracy and Cancellations.`
    } else if (accuracy && lateness) {
      summaryMessage = `${locationName} is performing worse than the company on Accuracy and Lateness.`
    } else if (cancellations && lateness) {
      summaryMessage = `${locationName} is performing worse than the company on Cancellations and Lateness.`
    } else if (accuracy) {
      summaryMessage = `Not bad! ${locationName} should focus on Accuracy which is below company averages.`
    } else if (cancellations) {
      summaryMessage = `Not bad! ${locationName} should focus on Cancellations which is below company averages.`
    } else if (lateness) {
      summaryMessage = `Not bad! ${locationName} should focus on Lateness which is below company averages.`
    } else {
      summaryMessage = `Great job! ${locationName} is performing better than the company on Accuracy, Cancellation and Lateness issues for the time period selected!`
    }
  } else {
    summaryMessage = 'No data available for the selected time period.'
  }

  const dayTrendData: any[] =
    locationDeliveryDayTrendsData?.listLocationDeliveryDayTrends?.nodes
  const deliveryStaffData =
    locationDeliveryStaffKpiData?.listLocationDeliveryStaffKpi?.nodes
  if (dayTrendData?.length > 0 && deliveryStaffData?.length > 0) {
    const fullDays = dayTrendData.filter(({ dayPart }) => dayPart === 'ALL')
    let worstAccuracyDay = null
    let worstLatenessDay = null
    let worstCancellationDay = null

    fullDays.reduce(
      (
        acc,
        {
          dayOfWeek,
          inaccurateOrderPercent,
          delayedOrderPercent,
          cancelledOrderPercent,
        },
      ) => {
        if (inaccurateOrderPercent > acc.inaccurateOrderPercent) {
          worstAccuracyDay = dayOfWeek
          acc.inaccurateOrderPercent = inaccurateOrderPercent
        }

        if (delayedOrderPercent > acc.delayedOrderPercent) {
          worstLatenessDay = dayOfWeek
          acc.delayedOrderPercent = delayedOrderPercent
        }

        if (cancelledOrderPercent > acc.cancelledOrderPercent) {
          worstCancellationDay = dayOfWeek
          acc.cancelledOrderPercent = cancelledOrderPercent
        }

        return acc
      },
      {
        inaccurateOrderPercent: 0,
        delayedOrderPercent: 0,
        cancelledOrderPercent: 0,
      },
    )

    if (accuracy && worstAccuracyDay) {
      const employees = [...deliveryStaffData].sort(
        (a, b) => b.inaccurateOrderPercent - a.inaccurateOrderPercent,
      )
      const worstTwoEmployees = employees
        .slice(0, 2)
        .map(
          ({ employeeInfo: { firstName, lastName } }) =>
            `${firstName} ${lastName}`,
        )
      areasOfImprovementMessages.push(
        <>
          <p>
            <strong>Accuracy</strong>
          </p>
          <p>{DAYS[worstAccuracyDay]} is the worst day.</p>
          {worstTwoEmployees.length > 0 ? (
            <p>
              <strong>{worstTwoEmployees[0]}</strong>
              {worstTwoEmployees.length === 2 ? (
                <span>
                  {' '}
                  and <strong>{worstTwoEmployees[1]}</strong> are
                </span>
              ) : (
                <span> is</span>
              )}
              <span> on shift when Accuracy issues are highest.</span>
            </p>
          ) : null}
        </>,
      )
    }

    if (lateness && worstLatenessDay) {
      const employees = [...deliveryStaffData].sort(
        (a, b) => b.delayedOrderPercent - a.delayedOrderPercent,
      )
      const worstTwoEmployees = employees
        .slice(0, 2)
        .map(
          ({ employeeInfo: { firstName, lastName } }) =>
            `${firstName} ${lastName}`,
        )
      areasOfImprovementMessages.push(
        <>
          <p>
            <strong>Lateness</strong>
          </p>
          <p>{DAYS[worstLatenessDay]} is the worst day.</p>
          {worstTwoEmployees.length > 0 ? (
            <p>
              <strong>{worstTwoEmployees[0]}</strong>
              {worstTwoEmployees.length === 2 ? (
                <span>
                  {' '}
                  and <strong>{worstTwoEmployees[1]}</strong> are
                </span>
              ) : (
                <span> is</span>
              )}
              <span> on shift when Lateness issues are highest.</span>
            </p>
          ) : null}
        </>,
      )
    }

    if (cancellations && worstCancellationDay) {
      const employees = [...deliveryStaffData].sort(
        (a, b) => b.cancelledOrderPercent - a.cancelledOrderPercent,
      )
      const worstTwoEmployees = employees
        .slice(0, 2)
        .map(
          ({ employeeInfo: { firstName, lastName } }) =>
            `${firstName} ${lastName}`,
        )
      areasOfImprovementMessages.push(
        <>
          <p>
            <strong>Cancellations</strong>
          </p>
          <p>{DAYS[worstCancellationDay]} is the worst day.</p>
          {worstTwoEmployees.length > 0 ? (
            <p>
              <strong>{worstTwoEmployees[0]}</strong>
              {worstTwoEmployees.length === 2 ? (
                <span>
                  {' '}
                  and <strong>{worstTwoEmployees[1]}</strong> are
                </span>
              ) : (
                <span> is</span>
              )}
              <span> on shift when Cancellations are highest.</span>
            </p>
          ) : null}
        </>,
      )
    }

    if (!accuracy && !lateness && !cancellations) {
      const messages = []
      if (worstAccuracyDay) {
        messages.push(`${DAYS[worstAccuracyDay]} is the worst day for Accuracy`)
      }

      if (worstLatenessDay) {
        messages.push(`${DAYS[worstLatenessDay]} is the worst day for Lateness`)
      }

      if (worstCancellationDay) {
        messages.push(
          `${DAYS[worstCancellationDay]} is the worst day for Cancellations`,
        )
      }

      if (messages.length > 1) {
        messages[messages.length - 1] = `and ${messages[messages.length - 1]}`
      }

      areasOfImprovementMessages.push(
        `${locationName} performs better than company averages, but to keep improving: ${messages.join(
          ', ',
        )}.`,
      )
    }

    if (customiedAreasOfImprovementMessages) {
      areasOfImprovementMessages.push(...customiedAreasOfImprovementMessages)
    }
  } else if (customiedAreasOfImprovementMessages) {
    areasOfImprovementMessages.push(...customiedAreasOfImprovementMessages)
  } else {
    areasOfImprovementMessages.push(
      'No data available for the selected time period.',
    )
  }

  if (
    !locationName ||
    isLocationVsSystemwideDeliveryKpiLoading ||
    isLocationDeliveryDayTrendsLoading ||
    isLocationDeliveryStaffKpiLoading
  ) {
    summaryMessage = 'Loading...'
    areasOfImprovementMessages = ['Loading...']
  }

  return (
    <Summary
      summaryMessage={summaryMessage}
      areasOfImprovementMessages={areasOfImprovementMessages}
    />
  )
}

export default DeliverySummary
