import moment from 'moment'
import { useMemo } from 'react'

import useCustomizedCalendarData from './useCustomizedCalendarData'

interface IBunForecastType {
  year: number
  week: number
  values: number[]
}

const useBunForecast = (
  locationId: number,
  selectedBunType: string[],
): IBunForecastType[] => {
  const { calendarData } = useCustomizedCalendarData(locationId)

  return useMemo(() => {
    const bucketedData: any = {}
    let minDate = moment().add(100, 'years')
    let maxDate = moment().subtract(100, 'years')

    if (Array.isArray(calendarData)) {
      calendarData.forEach((rawData: any) => {
        const date = moment(rawData?.date, 'YYYY-MM-DD', true)
        if (rawData && date.isValid()) {
          if (date < minDate) {
            minDate = date
          }
          if (date > maxDate) {
            maxDate = date
          }

          const item: string = rawData.item
          const businessWeek: number = rawData.businessWeek
          const businessDayOfWeek: number = rawData.businessDayOfWeek
          const queryYear: number = rawData.queryYear
          const queryYearValue: number = rawData.queryYearValue
          const compareYear: number = rawData.compareYear
          const compareYearValue: number = rawData.compareYearValue

          const bucketedItemData = bucketedData[item] || {}
          const bucketedYearData = bucketedItemData[queryYear] || {}
          const bucketedWeekData = bucketedYearData[businessWeek] || []
          bucketedWeekData[businessDayOfWeek - 1] = {
            queryYear,
            queryYearValue,
            compareYear,
            compareYearValue,
          }
          bucketedYearData[businessWeek] = bucketedWeekData
          bucketedItemData[queryYear] = bucketedYearData
          bucketedData[item] = bucketedItemData
        }
      })
    }

    const bunData = bucketedData[selectedBunType[0]]
    const displayData: IBunForecastType[] = []

    let dateIterator = minDate
    while (dateIterator < maxDate) {
      const year = dateIterator.year()
      const week = dateIterator.week()

      let compareYear = year - 1
      const weekData = bunData?.[year]?.[week] || []
      const queryYearData: number[] = []
      const compareYearData: number[] = []
      let sumQueryWeek = 0
      let sumCompareWeek = 0
      for (let wdi = 0; wdi < 7; ++wdi) {
        const dayData = weekData[wdi]
        queryYearData[wdi] = dayData?.queryYearValue || 0
        compareYearData[wdi] = dayData?.compareYearValue || 0
        sumQueryWeek += queryYearData[wdi]
        sumCompareWeek += compareYearData[wdi]

        if (dayData?.compareYear) {
          compareYear = dayData.compareYear
        }
      }
      queryYearData.push(sumQueryWeek)
      compareYearData.push(sumCompareWeek)

      displayData.push({
        year,
        week,
        values: queryYearData,
      })

      displayData.push({
        week,
        year: compareYear,
        values: compareYearData,
      })

      dateIterator.add(1, 'week')
    }

    return displayData
  }, [locationId, selectedBunType, calendarData])
}

export default useBunForecast
