import { useMemo } from 'react'

const useMockData = (role?: string) =>
  useMemo(
    () =>
      !role
        ? undefined
        : [...new Array(13)].map((_, index) => ({
            value: parseFloat((Math.random() * 80 - 40).toFixed(1)),
            groupingIndex: index < 11 ? 202203 + index : 202301 + index - 11,
          })),
    [role],
  )

export default useMockData
