import React from 'react'
import styled from 'styled-components'

import CustomizedReport from 'pared/components/CustomizedReport'
import useGetDateRange from 'pared/hooks/useGetDateRange'

interface IProps {
  locationGroupId: number
}

const EntreesPerLaborHourTable = ({ locationGroupId }: IProps) => {
  let inputParams = null

  const dateRange = useGetDateRange()
  const startDate = dateRange?.startDateStr
  const endDate = dateRange?.endDateStr

  if (locationGroupId && startDate && endDate) {
    inputParams = {
      locationGroupId,
      startDate,
      endDate,
    }
  }

  return (
    <Container>
      <SubHeader>ENTREES PER LABOR HOUR</SubHeader>
      {inputParams ? (
        <CustomizedReport
          reportName="CAVA_CORPORATE_ENTREES_PER_LABOR_HOUR_TABLE"
          inputParams={inputParams}
        />
      ) : null}
    </Container>
  )
}

const Container = styled.div`
  margin: 0;
  padding: 0;
`

const SubHeader = styled.div`
  margin: 0px;
  padding: 0 0 30px 0;
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
`

export default React.memo(EntreesPerLaborHourTable)
