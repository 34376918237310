export const getNumber = (value: number | string) =>
  typeof value === 'string' ? parseInt(value, 10) : value

const formatPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const getPrice = (value: number | string) =>
  formatPrice.format(getNumber(value) / 100)

export const getTotalPrice = (value: number | string) =>
  formatPrice.format(Math.round(getNumber(value) / 100)).replace(/\..+/, '')
