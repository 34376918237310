import Popover from '@material-ui/core/Popover'
import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import EmployeeFeedbackPopover from 'pared/components/managerReview/EmployeeFeedbackPopover'

interface IProps {
  employeeAnchorEl: any
  setEmployeeAnchorEl: any
  performanceName: string
  feedback: string
  reviewer: {
    firstName: string
    lastName: string
  }
  formattedReviewedAt: string
  isDateInCurrentPeriod: boolean
  managerReviewId: number
  firstName: string
  lastName: string
  role: string
  storeCode: string
  storeName: string
  onRefresh: () => Promise<void>
  userEmployeeId: number
}

function Main({
  employeeAnchorEl,
  setEmployeeAnchorEl,
  performanceName,
  feedback,
  reviewer,
  formattedReviewedAt,
  isDateInCurrentPeriod,
  managerReviewId,
  firstName,
  lastName,
  role,
  storeCode,
  storeName,
  onRefresh,
  userEmployeeId,
}: IProps) {
  const handleClick = (event: any) => {
    setEmployeeAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setEmployeeAnchorEl(null)
  }

  const open = Boolean(employeeAnchorEl)
  const id = open ? 'simple-popover' : undefined

  const editFeedback =
    isDateInCurrentPeriod && userEmployeeId == _.get(reviewer, 'id', 0) ? (
      <EditFeedbackContainer onClick={handleClick}>
        EDIT FEEDBACK
      </EditFeedbackContainer>
    ) : null

  return (
    <Container>
      <Value>{performanceName}</Value>
      <Feedback>{feedback}</Feedback>
      <ReviewerContainer>
        <Reviewer>{`${reviewer.firstName} ${reviewer.lastName} (${formattedReviewedAt})`}</Reviewer>
        {editFeedback}
      </ReviewerContainer>
      <Popover
        id={id}
        open={open}
        anchorEl={employeeAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <EmployeeFeedbackPopover
          firstName={firstName}
          lastName={lastName}
          role={role}
          storeCode={storeCode}
          storeName={storeName}
          currentFeedback={feedback}
          isUpdatingFeedback={true}
          revieweeId={0}
          locationId={0}
          closePopover={handleClose}
          onRefresh={onRefresh}
          managerReviewId={managerReviewId}
        />
      </Popover>
    </Container>
  )
}

const Container = styled.div`
  padding-bottom: 15px;
`

const Value = styled.div`
  font-family: Lexend-Regular;
  font-size: 12px;
  font-weight: 700;
`

const Feedback = styled.div`
  font-family: Lexend-Regular;
  font-size: 12px;
  margin-top: 8px;
`

const Reviewer = styled.div`
  font-family: Lexend-Regular;
  font-size: 12px;
  font-style: italic;
  margin-top: 8px;
  float: left;
`

const ReviewerContainer = styled.div`
  margin-bottom: 30px;
`

const EditFeedbackContainer = styled.span`
  font-family: Lexend-Regular;
  color: #07a4c7;
  text-decoration: underline;
  margin-top: 7px;
  margin-left: 10px;
  float: left;
  cursor: pointer;
`

export default Main
