import { useReactiveVar } from '@apollo/client'
import { useMemo } from 'react'

import useBrands from 'pared/layouts/hooks/useBrands'
import { decodedUserJwtToken } from 'pared/reactiveVariables/user'

import configs from '../configs'

export default () => {
  const jwtToken = useReactiveVar(decodedUserJwtToken)
  const accessiblePages: string[] = jwtToken?.accessible_pages || []
  const { brand } = useBrands()

  return useMemo(
    () =>
      accessiblePages.reduce((result, accessiblePage) => {
        const key = [brand, accessiblePage].join('-')

        return {
          ...result,
          ...configs[key],
        }
      }, configs[brand] || {}),
    [accessiblePages, brand],
  )
}
