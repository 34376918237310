import { INavParams } from 'pared/Routes/navParams'
import { IValueType } from 'pared/components/basicUi/select/MenuItem'
import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import WebLargeScreenMain from './WebLargeScreenMain'
import WebSmallScreenMain from './WebSmallScreenMain'
import { IOrderBy } from './index'

interface IProps {
  navParams: INavParams
  isLoading: boolean
  errorMessage: string
  rankBy: string
  onSelectRankBy: (event: any) => void
  groupBy: string[]
  groupByType: string
  onSelectGroupBy: (event: any) => void
  groupByOptions: IValueType[]
  rankingsData: any
  getStoreDisplayNameById: (storeId: number) => string
  getDirectorNameByEmployeeId: (employeeId: number) => string
  getDirectorNameByLocationGroupId: (locationGroupId: number) => string
  isRankingsDataEmpty: boolean
  orderBy: IOrderBy
  setOrderBy: any
  startDate: string
  endDate: string
}

const Main = ({
  navParams,
  isLoading,
  errorMessage,
  rankBy,
  onSelectRankBy,
  groupBy,
  groupByType,
  onSelectGroupBy,
  groupByOptions,
  rankingsData,
  getStoreDisplayNameById,
  getDirectorNameByEmployeeId,
  getDirectorNameByLocationGroupId,
  isRankingsDataEmpty,
  orderBy,
  setOrderBy,
  startDate,
  endDate,
}: IProps) => {
  return (
    <>
      <LargeScreen>
        <WebLargeScreenMain
          navParams={navParams}
          isLoading={isLoading}
          errorMessage={errorMessage}
          rankBy={rankBy}
          onSelectRankBy={onSelectRankBy}
          groupBy={groupBy}
          groupByType={groupByType}
          onSelectGroupBy={onSelectGroupBy}
          groupByOptions={groupByOptions}
          rankingsData={rankingsData}
          getStoreDisplayNameById={getStoreDisplayNameById}
          getDirectorNameByEmployeeId={getDirectorNameByEmployeeId}
          getDirectorNameByLocationGroupId={getDirectorNameByLocationGroupId}
          isRankingsDataEmpty={isRankingsDataEmpty}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          startDate={startDate}
          endDate={endDate}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallScreenMain
          navParams={navParams}
          isLoading={isLoading}
          errorMessage={errorMessage}
          rankBy={rankBy}
          onSelectRankBy={onSelectRankBy}
          groupBy={groupBy}
          groupByType={groupByType}
          onSelectGroupBy={onSelectGroupBy}
          groupByOptions={groupByOptions}
          rankingsData={rankingsData}
          getStoreDisplayNameById={getStoreDisplayNameById}
          getDirectorNameByEmployeeId={getDirectorNameByEmployeeId}
          getDirectorNameByLocationGroupId={getDirectorNameByLocationGroupId}
          isRankingsDataEmpty={isRankingsDataEmpty}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          startDate={startDate}
          endDate={endDate}
        />
      </SmallScreen>
    </>
  )
}

export default Main
