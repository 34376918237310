import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import useBrands from 'pared/layouts/hooks/useBrands'
import { getUser } from 'pared/utils/user'

import { useAdvancedFilter } from '../../advancedFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const queries = gql`
  query ListLocationTrainingTrackerFeedbacks($iFilter: JSON!, $iUserId: Int!) {
    listLocationTrainingTrackerFeedbacks(iFilter: $iFilter) {
      nodes {
        locationId
        locationCode
        locationName
        revieweeId
        revieweeFirstName
        revieweeLastName
        revieweeJobTitle
        reviewerFirstName
        reviewerLastName
        performace
        updateUuid
        reviewAt
        feedback
        assignedTracker
        trackerUuid
        completionRate
        completedDate
        assignedDate
        approvedDate
        needApproved
        approverFirstName
        approverLastName
      }
    }

    employee(id: $iUserId) {
      id
      jobTitle
      locationId
    }
  }
`

const PERFORMANCE_ITEMS = [
  'BELOW_AVERAGE',
  'AVERAGE',
  'ABOVE_AVERAGE',
  'READY_TO_PROMOTE',
]

export const jscDevelopmentTrackerFeedbackConfigs = {
  name: 'tracker-name',
  'name.employee': 'string',
  trackerUuid: 'string',
  assignedTracker: 'tracker',
  completionRate: 'tracker-completion-rate',
  approved: 'toggle',
  feedback: 'feedback',
} as const

export const useJscDevelopmentTrackerFeedbackData = () => {
  const user = getUser()
  const userId = user.employeeId ? user.employeeId : -1
  const { groupFilter } = useGroupFilter()

  return useQuery(queries, {
    variables: {
      iFilter: { location_ids: groupFilter?.ids },
      iUserId: userId,
    },
    skip: !groupFilter || !user,
  })
}

const useJscDevelopmentTrackerFeedback = () => {
  const { brand } = useBrands()
  const advancedFilter = useAdvancedFilter('select')
  const { data, loading } = useJscDevelopmentTrackerFeedbackData()

  return {
    data: useMemo((): IApiDataType => {
      const reviews =
        !advancedFilter || advancedFilter?.value.feedback === 'ALL'
          ? data?.listLocationTrainingTrackerFeedbacks.nodes
          : data?.listLocationTrainingTrackerFeedbacks.nodes.filter(
              (employee: any) => {
                if (
                  PERFORMANCE_ITEMS.includes(advancedFilter?.value.feedback)
                ) {
                  return employee.performace === advancedFilter?.value.feedback
                } else {
                  return (
                    employee.revieweeJobTitle === advancedFilter?.value.feedback
                  )
                }
              },
            )

      let feedbacks: Record<string, unknown>[] = []

      reviews?.forEach((employee: any) => {
        const reviewAt = moment(employee.reviewAt).format('M/DD/YY')
        const employeeUrl = `/${brand}/training_tracker_profile/${employee.revieweeId}`
        const trackerUrl = `/${brand}/training_tracker_detail/${employee.trackerUuid}`
        const completionRate = employee.assignedDate
          ? employee.completionRate * 100
          : null

        const approveText = !employee.completedDate
          ? ' '
          : employee.needApproved ||
            (employee.assignedTracker === 'Crew Training' &&
              !employee.approvedDate)
          ? 'Waiting for Approval'
          : employee.approvedDate
          ? `Approved by
                ${employee.approverFirstName} ${employee.approverLastName}
                (${moment(employee.approvedDate).format('M/D/YY')})`
          : `Submit for Approval`

        if (
          !(
            data?.employee.jobTitle === 'Restaurant Leader' ||
            data?.employee.jobTitle === 'Restaurant Leader in Training'
          ) ||
          !(
            employee.revieweeJobTitle === 'Restaurant Leader' ||
            employee.revieweeJobTitle === 'Restaurant Leader in Training'
          ) ||
          employee.locationId === data?.employee.locationId
        ) {
          feedbacks.push({
            performance: employee.performace,
            revieweeJobTitle: employee.revieweeJobTitle,
            trackerUuid: employee.trackerUuid,
            name: {
              employee: `${employee.revieweeFirstName} ${employee.revieweeLastName}`,
              employeeUrl: employeeUrl,
              revieweeJobTitle: employee.revieweeJobTitle,
              storeCode: employee.locationCode,
            },
            assignedTracker: {
              url: trackerUrl,
              assignedTracker: employee.assignedTracker,
              assignedDate: employee.assignedDate,
              employeeId: employee.revieweeId,
            },
            completionRate: employee.completedDate
              ? `Completed ${moment(employee.completedDate).format('M/D/YY')}`
              : completionRate,
            approved: {
              checked: !(
                employee.completedDate &&
                !employee.needApproved &&
                !employee.approvedDate
              ),
              label: approveText,
              disabled:
                employee.needApproved ||
                (employee.assignedTracker === 'Crew Training' &&
                  !employee.approvedDate),
            },
            feedback: {
              employeeId: employee.revieweeId,
              employee: `${employee.revieweeFirstName} ${employee.revieweeLastName}`,
              reviewer: `${employee.reviewerFirstName} ${employee.reviewerLastName}`,
              locationId: employee.locationId,
              locationRole: `${employee.locationName} (${employee.locationCode}) - ${employee.revieweeJobTitle}`,
              performance: employee.performace,
              feedback: employee.feedback,
              updateUuid: employee.updateUuid,
              reviewAt,
            },
          })
        }
      })

      return { source: feedbacks }
    }, [data, advancedFilter]),
    loading,
  }
}

export default useJscDevelopmentTrackerFeedback
