import _ from 'lodash'
import styled from 'styled-components'

import { track } from 'pared/analytics/alert'
import COLORS from 'pared/constants/colors'
import BellIcon from 'pared/images/actionItems/bell.svg'
import CelebrationIcon from 'pared/images/actionItems/celebration.svg'
import CheckIcon from 'pared/images/actionItems/check.svg'
import CheckCompletedIcon from 'pared/images/actionItems/checkCompleted.svg'
import MailIcon from 'pared/images/actionItems/mail.svg'
import WarningIcon from 'pared/images/actionItems/warning.svg'
import HourlyLaborTable from 'pared/pages/MyDashboard/HourlyLaborTable'
import NetSalesTable from 'pared/pages/MyDashboard/NetSalesTable'
import OpsCompTable from 'pared/pages/MyDashboard/OpsCompTable'

interface IAlertMessageText {
  danger?: boolean
  success?: boolean
}

interface IAlert {
  isRead?: boolean
}

interface IProps {
  date: string
  type: string
  alertMessage: string
  alertDefinitionLevel: string
  alertName: string
  shouldShowInfoTable: boolean
  setShouldShowInfoTable: any
  isRead: boolean
  onSetAlertUserNotifiedRead: () => void
  onSetAlertUserNotifiedUnread: () => void
  tableData: object
  locationId: number
}

const Main = ({
  date,
  type,
  shouldShowInfoTable,
  setShouldShowInfoTable,
  alertMessage,
  alertDefinitionLevel,
  alertName,
  isRead,
  onSetAlertUserNotifiedRead,
  onSetAlertUserNotifiedUnread,
  tableData,
  locationId,
}: IProps) => {
  const onToggleMoreDetails = () => {
    setShouldShowInfoTable(!shouldShowInfoTable)
    track.moreDetailsClicked(alertName, locationId)
  }

  const isOpsComp = _.includes(alertName, 'OPS_COMP')
  const isNetSales = _.includes(alertName, 'SALES_BUDGET')
  const isHourlyLabor = _.includes(alertName, 'HOURLY_LABOR')

  let dataTable
  if (isOpsComp) {
    dataTable = <OpsCompTable tableData={tableData} locationId={locationId} />
  } else if (isNetSales) {
    dataTable = <NetSalesTable tableData={tableData} />
  } else if (isHourlyLabor) {
    dataTable = <HourlyLaborTable tableData={tableData} />
  }

  const infoTable = shouldShowInfoTable ? (
    <HalfWidthTableContainer>{dataTable}</HalfWidthTableContainer>
  ) : null

  const iconCompnent =
    alertDefinitionLevel === 'alert' ? (
      <IconContainer>
        <IconImg src={WarningIcon} alt="warning" />
      </IconContainer>
    ) : null

  const checkIcon = isRead ? (
    <img
      src={CheckCompletedIcon}
      alt="warning"
      onClick={onSetAlertUserNotifiedUnread}
    />
  ) : (
    <img
      src={CheckIcon}
      alt="warning"
      onClick={onSetAlertUserNotifiedRead}
      onMouseOver={(e) => (e.currentTarget.src = CheckCompletedIcon)}
      onMouseOut={(e) => (e.currentTarget.src = CheckIcon)}
    />
  )

  const moreDeatils =
    isOpsComp || isNetSales || isHourlyLabor ? (
      <LinkSpan onClick={onToggleMoreDetails}>more details</LinkSpan>
    ) : null

  // TODO @changey add this back when the function is avialable
  // const mailIcon = (
  //   <td>
  //     <a href={`mailto://`} target="_blank">
  //       <MailImg src={MailIcon} alt="feedback" onClick={onClickShare} />
  //     </a>
  //   </td>
  // )

  return (
    <MainContainer isRead={isRead}>
      <ActionItemsTable>
        <tbody>
          <tr>
            <ButtonTd>
              <ButtonsContainer>
                <ButtonContainer>{checkIcon}</ButtonContainer>
              </ButtonsContainer>
            </ButtonTd>
            <DateTd>
              <Text>{date}</Text>
            </DateTd>
            <IconTd>{iconCompnent}</IconTd>
            <AlertMessageTd
              onClick={
                isOpsComp || isNetSales || isHourlyLabor
                  ? onToggleMoreDetails
                  : () => {}
              }
            >
              <AlertMessageText danger={alertDefinitionLevel === 'alert'}>
                {alertMessage}
              </AlertMessageText>
            </AlertMessageTd>
            <MoreDetailsTd>{moreDeatils}</MoreDetailsTd>
          </tr>
        </tbody>
      </ActionItemsTable>
      {infoTable}
    </MainContainer>
  )
}

const MainContainer = styled.div<IAlert>`
  border-top: 1px solid ${COLORS.Smoke};
  opacity: ${(props) => (props.isRead ? 0.5 : 1)};
`

const ButtonContainer = styled.div`
  float: left;
  margin: 3px 7px 0 0;
  cursor: pointer;
`

const ActionItemsTable = styled.table`
  width: 100%;
`

const Text = styled.div`
  font-family: Lexend-Regular;
`

const AlertMessageText = styled.div<IAlertMessageText>`
  color: ${(props) =>
    props.danger ? COLORS.Pomodoro : props.success ? COLORS.Expo : 'black'};
`

const ButtonsContainer = styled.div`
  display: inline-block;
  margin: 4px 0 0 0;
`

const IconContainer = styled.div`
  margin: 5px 7px 0 0;
`

const IconImg = styled.img`
  width: 20px;
`

const MailImg = styled.img`
  margin-top: 5px;
`

const ButtonTd = styled.td`
  width: 100px;
`

const DateTd = styled.td`
  width: 100px;
`

const IconTd = styled.td`
  width: 30px;
`

const AlertMessageTd = styled.td`
  width: 70%;
  cursor: pointer;
`

const MoreDetailsTd = styled.td`
  width: 100px;
`

const LinkSpan = styled.span`
  font-family: Lexend-Regular;
  color: #00bfff;
  cursor: pointer;
`

const HalfWidthTableContainer = styled.div`
  width: 600px;
  margin: auto;
  padding: 0 0 40px 0;
`

export default Main
