import { useMemo } from 'react'

import { IColumnsType } from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'

import { getPriceOrDash } from '../../utils'
import { IDiscountType } from './useDiscounts'

interface IDiscountItemType {
  key: string
  value: number
}

//Optimize the BE function to only retrieve PROMO items.
const IGNORE_LIST = ['totalDiscounts', 'total', 'Total', 'directorName']

const useDiscountsColumns = (
  isBreakdownByDirectors: boolean,
  discounts: any,
  discountLoading: boolean,
  selectedDiscount: string[],
  locationGroupType?: string,
): any => {
  const brandSettings = getBrandSettings()
  const businessLabels = brandSettings.labels.business

  const discountsColumns = useMemo(() => {
    if (discountLoading || discounts.length === 0) return []

    const discountColumns: IDiscountItemType[] = discounts.reduce(
      (result: IDiscountItemType[], discountItems: any) => {
        for (const key in discountItems) {
          if (discountItems[key].length === 3) {
            const itemIndex = result.findIndex(
              (item: IDiscountItemType) => item.key === key,
            )
            if (itemIndex === -1) {
              result.push({ key, value: discountItems[key][0] })
            } else {
              result[itemIndex].value += discountItems[key][0]
            }
          }
        }
        return result
      },
      [] as IDiscountItemType[],
    )

    discountColumns.sort((a, b) => {
      return b.value - a.value
    })
    const discountColumnDefs = discountColumns
      .filter((col: any) => !IGNORE_LIST.find((i) => i === col.key))
      .map((col: any, index: number) => {
        const colKey = col.key || ''
        const colTitle = col.key || ''
        return {
          key: colKey,
          title: colTitle,
          align: 'center',
          cellStyle:
            index % 2 === 1
              ? ''
              : `
                background: ${COLORS.Ash};
              `,
          children: col.children
            ? col.children.map(
                ({ key, title }: { key: string; title: string }) => ({
                  key,
                  title,
                  width: '75px',
                  align: 'center',
                  children: [
                    {
                      key: `["${key}"].0`,
                      title: '#',
                      width: '75px',
                      align: 'center',
                      render: (countDisplayString: string) => {
                        return countDisplayString ? countDisplayString : '-'
                      },
                      sorter: true,
                    },
                    {
                      key: `["${key}"].1`,
                      title: '$',
                      width: '75px',
                      align: 'center',
                      render: (_: any, data: any) => {
                        return getPriceOrDash(data[key] ? data[key][2] : '-')
                      },
                      sorter: true,
                    },
                  ],
                }),
              )
            : [
                ...(col.isCountVisible === false
                  ? []
                  : [
                      {
                        key: `["${colKey}"].0`,
                        title: '#',
                        width: '75px',
                        align: 'center',
                        cellStyle:
                          index % 2 === 1
                            ? ''
                            : `
                              background: ${COLORS.Ash};
                            `,
                        render: (countDisplayString: string) => {
                          return countDisplayString ? countDisplayString : '-'
                        },
                        sorter: true,
                      },
                    ]),
                ...(col.isAmountVisible === false
                  ? []
                  : [
                      {
                        key: `["${colKey}"].1`,
                        title: '$',
                        width: '75px',
                        align: 'center',
                        cellStyle:
                          index % 2 === 1
                            ? ''
                            : `
                              background: ${COLORS.Ash};
                            `,
                        render: (_: any, data: any) => {
                          return getPriceOrDash(
                            data[colKey] ? data[colKey][2] : '-',
                          )
                        },
                        sorter: true,
                      },
                    ]),
              ],
        }
      })

    return [
      ...((isBreakdownByDirectors
        ? []
        : [
            {
              key: 'store',
              title: 'Store',
              minWidth: '275px',
              render: (value: IDiscountType['store'], values, index) => {
                if (!values || !('storeId' in values)) return null
                if (index === -1 || values.storeId === 99999) return 'TOTAL'

                return value
              },
              sorter: true,
            },
          ]) as IColumnsType<IDiscountType>[]),
      !locationGroupType
        ? {
            key: 'directorName',
            title: businessLabels['director'],
            minWidth: '150px',
            render: (value: IDiscountType['directorName'], index: any) => {
              if (index === -1) return !isBreakdownByDirectors ? '' : 'TOTAL'

              return value || 'ALL'
            },
            sorter: true,
          }
        : {
            key: 'locationGroupName',
            title: locationGroupType,
            minWidth: '150px',
            render: (value: string) => value,
            sorter: true,
          },
      ...discountColumnDefs,
      {
        key: 'totalDiscounts',
        title: 'All Discounts',
        align: 'center',
        children: [
          {
            key: 'totalDiscounts.0',
            title: '#',
            width: '75px',
            align: 'center',
            sorter: true,
          },
          {
            key: 'totalDiscounts.1',
            title: '$',
            width: '75px',
            align: 'center',
            render: (_: any, data: any) => {
              return getPriceOrDash(data.totalDiscounts[2])
            },
            sorter: true,
          },
        ],
      },
    ]
  }, [discounts, selectedDiscount, isBreakdownByDirectors, locationGroupType])

  return {
    discountsColumns,
  }
}

export default useDiscountsColumns
