import styled from 'styled-components'

export const MOBILE_WIDTH = '(max-width: 599px)'

export const MobileCotainer = styled.div`
  @media ${MOBILE_WIDTH} {
    margin: 20px 0px 0px;
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`
