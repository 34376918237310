import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { DATE_DATABASE_FORMAT } from 'pared/constants'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

type FarwestFlashSalesByOrderModeType =
  | 'avgOrderPrice'
  | 'orderPrice'
  | 'orderPercent'
  | 'deliveryPercent'
  | 'equivalentUnitCount'

interface IProps {
  modeType: FarwestFlashSalesByOrderModeType
}

const query = gql`
  query ListFarwestFlashSalesByOrderMode(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "business_week"
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        startDate
        metricData
        metricSummaryData
      }
    }
  }
`

export const farwestFlashSalesByOrderModeConfigs = {
  // Average Weekly Sales by Order Mode
  avgOrderPriceName: 'string',
  avgOrderPriceWeek1InPast: 'price',
  avgOrderPriceWeek2InPast: 'price',
  avgOrderPriceWeek3InPast: 'price',
  avgOrderPriceWeek4InPast: 'price',
  avgOrderPriceWeek5InPast: 'price',
  avgOrderPriceWeek6InPast: 'price',
  avgOrderPriceWeek7InPast: 'price',
  avgOrderPriceWeek8InPast: 'price',

  // Total Sales by Delivery Mode
  orderPriceName: 'string',
  orderPriceWeek1InPast: 'price',
  orderPriceWeek2InPast: 'price',
  orderPriceWeek3InPast: 'price',
  orderPriceWeek4InPast: 'price',
  orderPriceWeek5InPast: 'price',
  orderPriceWeek6InPast: 'price',
  orderPriceWeek7InPast: 'price',
  orderPriceWeek8InPast: 'price',

  // Total Sales Percent by Order Mode
  orderPercentName: 'string',
  orderPercentWeek1InPast: 'percent',
  orderPercentWeek2InPast: 'percent',
  orderPercentWeek3InPast: 'percent',
  orderPercentWeek4InPast: 'percent',
  orderPercentWeek5InPast: 'percent',
  orderPercentWeek6InPast: 'percent',
  orderPercentWeek7InPast: 'percent',
  orderPercentWeek8InPast: 'percent',

  // Equivalent Unit Count
  equivalentUnitCountName: 'string',
  equivalentUnitCountWeek1InPast: 'number',
  equivalentUnitCountWeek2InPast: 'number',
  equivalentUnitCountWeek3InPast: 'number',
  equivalentUnitCountWeek4InPast: 'number',
  equivalentUnitCountWeek5InPast: 'number',
  equivalentUnitCountWeek6InPast: 'number',
  equivalentUnitCountWeek7InPast: 'number',
  equivalentUnitCountWeek8InPast: 'number',
} as const

export const KPIS: {
  code: string
  name: string
  type: FarwestFlashSalesByOrderModeType
}[] = [
  // Average Weekly Sales by Order Mode
  {
    code: 'avg_olo_take_out',
    name: 'Online (Pick Up)',
    type: 'avgOrderPrice',
  },
  {
    code: 'avg_online_dispatch_sales',
    name: 'Online (Dispatch)',
    type: 'avgOrderPrice',
  },
  {
    code: 'avg_walk_in_dine_in',
    name: 'Dine In - Walk In',
    type: 'avgOrderPrice',
  },
  {
    code: 'avg_walk_in_to_go',
    name: 'Take Out - Walk In',
    type: 'avgOrderPrice',
  },
  {
    code: 'avg_call_in_to_go',
    name: 'Take Out - Call in/Call Center',
    type: 'avgOrderPrice',
  },
  {
    code: 'avg_doordash_sales',
    name: 'DoorDash',
    type: 'avgOrderPrice',
  },
  {
    code: 'avg_ubereat_sales',
    name: 'UberEats',
    type: 'avgOrderPrice',
  },
  {
    code: 'avg_grubhub_sales',
    name: 'Grubhub',
    type: 'avgOrderPrice',
  },
  // Total Sales by Order Mode
  {
    code: 'olo_take_out',
    name: 'Online (Pick Up)',
    type: 'orderPrice',
  },
  {
    code: 'online_dispatch_sales',
    name: 'Online (Dispatch)',
    type: 'orderPrice',
  },
  {
    code: 'walk_in_dine_in',
    name: 'Dine In - Walk In',
    type: 'orderPrice',
  },
  {
    code: 'walk_in_to_go',
    name: 'Take Out - Walk In',
    type: 'orderPrice',
  },
  {
    code: 'call_in_to_go',
    name: 'Take Out - Call in/Call Center',
    type: 'orderPrice',
  },
  {
    code: 'doordash_sales',
    name: 'DoorDash',
    type: 'orderPrice',
  },
  {
    code: 'ubereat_sales',
    name: 'Uber Eats',
    type: 'orderPrice',
  },
  {
    code: 'grubhub_sales',
    name: 'Grubhub',
    type: 'orderPrice',
  },
  // Total Sales Percent by Order Mode
  {
    code: 'olo_take_out_percentage',
    name: 'Online (Pick Up)',
    type: 'orderPercent',
  },
  {
    code: 'dispatch_sales_percentage',
    name: 'Online (Dispatch)',
    type: 'orderPercent',
  },
  {
    code: 'walk_in_dine_in_percentage',
    name: 'Dine In - Walk In',
    type: 'orderPercent',
  },
  {
    code: 'walk_in_to_go_percentage',
    name: 'Take Out - Walk In',
    type: 'orderPercent',
  },
  {
    code: 'call_in_to_go_percentage',
    name: 'Take Out - Call in/Call Center',
    type: 'orderPercent',
  },
  {
    code: 'doordash_sales_percentage',
    name: 'DoorDash',
    type: 'orderPercent',
  },
  {
    code: 'ubereat_sales_percentage',
    name: 'Uber Eats',
    type: 'orderPercent',
  },
  {
    code: 'grubhub_sales_percentage',
    name: 'Grubhub',
    type: 'orderPercent',
  },
  // Equivalent Unit Count
  {
    code: 'equivalent_unit_count',
    name: 'Equivalent Unit Count',
    type: 'equivalentUnitCount',
  },
]

const codeToType = (metricCode: string) =>
  KPIS.find((k) => k.code === metricCode)?.type || ''

const buildUseFarwestFlashSalesByOrderMode = ({ modeType }: IProps) => {
  const useFarwestFlashSalesByOrderMode = () => {
    const { endDate } = useDateFilter()
    const momentEndDate = moment.utc(endDate, DATE_DATABASE_FORMAT, true)
    const startDate = momentEndDate
      .clone()
      .subtract(55, 'days')
      .format(DATE_DATABASE_FORMAT)
    const { groupFilter } = useGroupFilter()
    const { data, loading } = useQuery(query, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: {
          location_group_ids: groupFilter?.ids,
          metrics: KPIS.map((k) => k.code),
        },
      },
      skip: !startDate || !endDate || !groupFilter,
    })

    return {
      data: useMemo((): IApiDataType => {
        const customizedData: any = data?.trendLocationGroupMetricValues?.nodes
        if (!customizedData) return null

        const dateToWeekMap: { [dateStr: string]: string } = {}

        for (let di = 0; di < 56; ++di) {
          const dateStr = momentEndDate
            .clone()
            .subtract(di, 'days')
            .format(DATE_DATABASE_FORMAT)
          dateToWeekMap[dateStr] = `Week${Math.floor(di / 7.0) + 1}InPast`
        }

        let source: any[] = KPIS.filter((k) => k.type === modeType).map(
          (k) => ({
            metricCode: k.code,
            [`${k.type}Name`]: k.name,
            type: k.type,
          }),
        )
        const summary: { [key: string]: number | null } = {}

        customizedData.forEach((weeklyData: any) => {
          const weekStr = dateToWeekMap[weeklyData.startDate]
          Object.keys(weeklyData.metricData).forEach((code) => {
            const currentSource = source.find((s) => s.metricCode === code)
            if (currentSource) {
              const newKey = `${codeToType(code)}${weekStr}`
              switch (weeklyData.metricData[code].unit) {
                case 'DOLLAR':
                  currentSource[newKey] =
                    weeklyData.metricData[code].value * 100
                  if (!summary[newKey]) {
                    summary[newKey] =
                      0 + weeklyData.metricSummaryData[code].value * 100
                  } else {
                    summary[newKey] +=
                      weeklyData.metricSummaryData[code].value * 100
                  }
                  break

                case 'COUNT':
                  currentSource[newKey] = weeklyData.metricData[code].value
                  if (!summary[newKey]) {
                    summary[newKey] =
                      0 + weeklyData.metricSummaryData[code].value
                  } else {
                    summary[newKey] += weeklyData.metricSummaryData[code].value
                  }
                  break

                case 'PERCENTAGE':
                  currentSource[newKey] =
                    weeklyData.metricData[code].value * 100
                  if (!summary[newKey]) {
                    summary[newKey] =
                      0 + weeklyData.metricSummaryData[code].value * 100
                  } else {
                    summary[newKey] +=
                      weeklyData.metricSummaryData[code].value * 100
                  }
                  break
              }
            }
          })
        })

        return {
          source,
          summary: {
            [`${modeType}Name`]: 'Total',
            ...summary,
          },
        }
      }, [data]),
      loading,
    }
  }

  return useFarwestFlashSalesByOrderMode
}

export const useFarwestAverageWeeklySalesbyOrderMode =
  buildUseFarwestFlashSalesByOrderMode({
    modeType: 'avgOrderPrice',
  })

export const useFarwestTotalSalesbyOrderMode =
  buildUseFarwestFlashSalesByOrderMode({
    modeType: 'orderPrice',
  })

export const useFarwestTotalSalesPercentbyOrderMode =
  buildUseFarwestFlashSalesByOrderMode({
    modeType: 'orderPercent',
  })

export const useFarwestEquivalentUnitCount =
  buildUseFarwestFlashSalesByOrderMode({
    modeType: 'equivalentUnitCount',
  })
