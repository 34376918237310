import { IConfigsType } from '../../types'

const fwWingstopLocationManagementConfig: IConfigsType = {
  'fw_wingstop-admin': {
    '/:brand/locations': {
      timeClockTable: {
        title: 'Manage Locations',
        type: 'table-v2',
        api: 'locations',
        columns: [
          {
            key: 'id',
            title: 'ID',
            sortable: true,
          },
          {
            key: 'code',
            title: 'Code',
            sortable: true,
          },
          {
            key: 'name',
            title: 'Name',
            sortable: true,
          },

          {
            key: 'openedAt',
            title: 'Open Date',
            sortable: true,
          },

          {
            key: 'locationGroups',
            // title: 'Groups',
          },
        ],
      },
    },
  },
}

export default fwWingstopLocationManagementConfig
