import _ from 'lodash'

import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import { IDirector } from 'pared/data/getDirectors'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import { ILocationGroupType } from './hooks/useConfig'

interface IProps {
  allDirectors: IDirector[]
  director: IDirector
  onDirectorChange: (event: any) => Promise<void>
  width?: string
  disabledDirectors: boolean
  locationGroupTypes: ILocationGroupType[]
  temporaryValue?: string
}

function Main({
  allDirectors,
  director,
  onDirectorChange,
  width,
  disabledDirectors,
  locationGroupTypes,
  temporaryValue,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          allDirectors={allDirectors}
          director={director}
          onDirectorChange={onDirectorChange}
          disabledDirectors={disabledDirectors}
          locationGroupTypes={locationGroupTypes}
          temporaryValue={temporaryValue}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          allDirectors={allDirectors}
          director={director}
          onDirectorChange={onDirectorChange}
          width={width}
          disabledDirectors={disabledDirectors}
          locationGroupTypes={locationGroupTypes}
          temporaryValue={temporaryValue}
        />
      </SmallScreen>
    </>
  )
}

export default Main
