import COLORS from 'pared/constants/colors'
import { ILossPreventionConfigType } from 'pared/pages/CorporateLossPrevention/hooks/useLossPreventionConfig'

export const mwbConfig: ILossPreventionConfigType = {
  kpiSettings: [
    { category: 'basic', key: 'compsTotalAmount', title: 'Total Discounts' },
    {
      category: 'basic',
      key: 'compsTotalCount',
      title: 'Total Discount Counts',
    },
    { category: 'custom', type: 'Deletes', title: 'Deletes', key: 'amount' },
    {
      category: 'custom',
      type: 'Voids',
      title: 'Voids',
      key: 'amount',
    },
    {
      category: 'custom',
      type: 'Overrings',
      title: 'Overrings',
      key: 'amount',
    },
    {
      category: 'custom',
      type: 'Refunds',
      title: 'Refunds',
      key: 'amount',
    },
  ],

  discountTable: {
    isVisible: true,
    discountColumns: [
      {
        key: 'Coupons',
        title: 'Coupons',
      },
      {
        key: 'Employee Meal',
        title: 'Employee Meal',
      },
      {
        key: 'Promo Meal',
        title: 'Promo Meal',
      },
      {
        key: 'Police Discount',
        title: 'Police Discount',
      },
      {
        key: 'Trainer Promo',
        title: 'Trainer Promo',
      },
      {
        key: 'Mobile Courtesy Meal',
        title: 'Mobile Courtesy Meal',
      },
    ],
  },

  discountTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Discount',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [],
  },

  cashControlTable: {
    isVisible: true,
    cashControlColumns: [
      {
        key: 'Deletes',
        title: 'Deletes',
        width: '150px',
        children: [
          {
            key: 'Deletes.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'Deletes.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Voids',
        title: 'Voids',
        width: '150px',
        children: [
          {
            key: 'Voids.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'Voids.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Overrings',
        title: 'Overrings',
        width: '150px',
        children: [
          {
            key: 'Overrings.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'Overrings.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Refunds',
        title: 'Refunds',
        width: '150px',
        children: [
          {
            key: 'Refunds.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'Refunds.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
    ],
  },

  cashControlTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Amount',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [
      {
        key: 'Deletes',
        name: 'Deletes $',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Plum,
        hidden: false,
      },
      {
        key: 'Voids',
        name: 'Voids $',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Pomodoro,
        hidden: false,
      },
      {
        key: 'Overrings',
        name: 'Overrings $',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.DarkOrchid,
        hidden: false,
      },
      {
        key: 'Refunds',
        name: 'Refund $',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Mango,
        hidden: false,
      },
    ],
  },
}
