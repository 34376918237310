import { useCallback, useMemo, useState } from 'react'

import { IDayPartNodeType } from '../gqls/itemList'

const All = 'All'

const useDayPart = (dayPartData: IDayPartNodeType[]) => {
  const [selectedDayPart, setSelectedDayPart] = useState(All)
  const dayPartChanged = useCallback(
    (dayPartItem: string) => {
      setSelectedDayPart(dayPartItem)
    },
    [dayPartData],
  )

  const dayPartItems = useMemo(() => {
    return [All, ...dayPartData.map((item) => item.dayPart)]
  }, [dayPartData])

  return { selectedDayPart, dayPartItems, dayPartChanged }
}

export default useDayPart
