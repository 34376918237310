import { Row } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

export interface IPropsType {
  link?: string
  label: string
}

const LinkButton = styled(Link)`
  color: ${COLORS.Link};
`

const Location = ({ link, label, ...props }: IPropsType) =>
  !link ? (
    <>{label}</>
  ) : (
    <LinkButton {...props} to={link}>
      {label}
    </LinkButton>
  )

export const sorter = (
  rowA: Row<IPropsType>,
  rowB: Row<IPropsType>,
  columnId: string,
): number => {
  const a = rowA.getValue(columnId) as IPropsType
  const b = rowB.getValue(columnId) as IPropsType

  return b.label.localeCompare(a.label)
}

export default Location
