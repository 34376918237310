import { IApiType } from '../types'
import { api as bbbApi } from './bbb'

export type IApiKeyType = keyof typeof api

const api = {
  ...bbbApi,
}

const apiKeys = Object.keys(api) as (keyof typeof api)[]

const isApi = (
  apiOrContent: IApiKeyType | string,
): apiOrContent is IApiKeyType => apiKeys.includes(apiOrContent as IApiKeyType)

const useApi = (apiOrContent: IApiKeyType | string): IApiType =>
  isApi(apiOrContent)
    ? api[apiOrContent]()
    : { data: apiOrContent, loading: false }

export default useApi
