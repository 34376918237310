import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_NORMALIZATION_STATUS } from '../gql'

interface INormalizationStatus {
  isNormalizingNewerDate: boolean
  latestNormalizedDate: string | null
  currentNormalizingDate: string | null
}

interface IQueryVariables {
  iNormalizationTable: string
  iBrandId: number | null
}

interface IQueryData {
  getNormalizationStatus: {
    nodes: {
      status: {
        isNormalizingNewerDate: boolean
        latestNormalizedDate: string | null
        currentNormalizingDate: string | null
      }
    }[]
  }
}

const useFetchNormalizationStatus = (
  normalizationTable: string,
  brandId?: number,
): INormalizationStatus => {
  const { data } = useQuery<IQueryData, IQueryVariables>(
    GET_NORMALIZATION_STATUS,
    {
      variables: {
        iNormalizationTable: normalizationTable,
        iBrandId: brandId || null,
      },
    },
  )

  const normalizationStatus = useMemo(() => {
    const rawData: any = data?.getNormalizationStatus?.nodes?.[0]?.status
    const currentStatus: INormalizationStatus = {
      isNormalizingNewerDate: rawData?.isNormalizingNewerDate || false,
      latestNormalizedDate: rawData?.latestNormalizedDate || null,
      currentNormalizingDate: rawData?.currentNormalizingDate || null,
    }
    return currentStatus
  }, [data, normalizationTable, brandId])

  return normalizationStatus
}

export default useFetchNormalizationStatus
