import Checkbox from '@material-ui/core/Checkbox'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'
import COLORS from 'pared/constants/colors'

import useToggle, { IToggleTypes } from './hooks/useToggle'

interface IColorType {
  unchecked?: string
  checked?: string
  disabled?: string
}

export interface IPropsType extends IToggleTypes {
  checked: boolean
  label?: string
  disabled?: boolean
  color?: IColorType
}

const StyledCheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: ${COLORS.Plum};
  }
`

const Text = styled.div<{
  checked: boolean
  disabled: boolean
  fontColor?: IColorType
}>`
  font-size: 14px;
  ${({ checked, disabled, fontColor }) => {
    if (disabled) return `color: ${fontColor?.disabled || COLORS.Steel};`

    return !checked
      ? `
    color: ${fontColor?.unchecked || COLORS.ExpoRed1};
    cursor: pointer;
    `
      : `color: ${fontColor?.checked || ''};`
  }}
`

const Toggle = ({ checked, label, disabled, color, ...props }: IPropsType) => {
  const [loading, setLoading] = useState(false)
  const toggle = useToggle(setLoading, props)

  useEffect(() => {
    setLoading(false)
  }, [checked, disabled])

  if (label) {
    return (
      <Spin spinning={loading}>
        <Text
          checked={checked}
          disabled={disabled || false}
          fontColor={color}
          onClick={async () => {
            if (!disabled) await toggle({ checked: !checked })
          }}
        >
          {label}
        </Text>
      </Spin>
    )
  }

  return (
    <Spin spinning={loading}>
      <StyledCheckbox
        disabled={loading || disabled}
        checked={checked}
        onChange={async ({ target: { checked } }) => {
          await toggle({ checked })
        }}
      />
    </Spin>
  )
}

export default Toggle
