import { gql } from '@apollo/client'

export interface ILossPreventionSummaryNodeType {
  locationInfo: {
    code: string
    name: string
  }
  amount: number
  employeeDiscountInfos: {
    familyName: string
    preferredName: string
  }[]
  rankPercent: number
  annualOpportunityCost: number
}

export interface ILossPreventionSummaryType {
  locationLossPreventionSummary: {
    nodes: ILossPreventionSummaryNodeType[]
  }
}

export interface ILossPreventionSummaryVariablesType {
  iLocationId: number
  iStartDate?: string
  iEndDate?: string
}

export const getLocationLossPreventionSummary = gql`
  query LocationLossPreventionSummary(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationId: Int!
  ) {
    locationLossPreventionSummary(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iLocationId: $iLocationId
    ) {
      nodes {
        locationInfo {
          code
          name
        }
        employeeDiscountInfos
        annualOpportunityCost
      }
    }
  }
`
