import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'

import FullWidthTable from 'pared/components/FullWidthTable'
import COLORS from 'pared/constants/colors'
import LookUpIcon from 'pared/images/actionItems/look-up.svg'
import { toPercentageString, toUsdString } from 'pared/utils/number'

import OpsCompModal from '../OpsCompModal'

export interface IOverviewTableTh {
  largerFont?: boolean
  textAlign?: string
  bold?: boolean
}

export interface IOpenPositionsTd {
  bold?: boolean
  danger?: boolean
  success?: boolean
  textAlign?: string
}

interface IProps {
  tableData: object
  onClickSeeTeam: () => void
  locationId: number
  isOpsCompModalVisible: boolean
  setIsOpsCompModalVisible: any
}

const DATE_FORMAT = 'YYYY-MM-DD'

const Main = ({
  tableData,
  locationId,
  isOpsCompModalVisible,
  setIsOpsCompModalVisible,
  onClickSeeTeam,
}: IProps) => {
  const compsItem = _.get(tableData, 'comps_item', {})
  const percentItemCompOfGross = _.get(
    tableData,
    'percent_item_comp_of_gross',
    {},
  )
  const alertWindowStart = _.get(tableData, 'alert_window_start', '')
  const alertWindowEnd = _.get(tableData, 'alert_window_end', '')
  const shouldDisplayOpsCompModal = !_.isEmpty(alertWindowStart)

  const dateRangeStartDate = moment.utc(alertWindowStart).format(DATE_FORMAT)
  const dateRangeEndDate = moment.utc(alertWindowEnd).format(DATE_FORMAT)

  const lookUpSpanTable = shouldDisplayOpsCompModal ? (
    <>
      <LookUpSpanTable>
        <tbody>
          <tr>
            <td>
              <LookUpIconContainer>
                <img src={LookUpIcon} alt="See how your team performed" />
              </LookUpIconContainer>
            </td>
            <td>
              <SeeTeamSpan onClick={onClickSeeTeam}>
                See how your team performed
              </SeeTeamSpan>
            </td>
          </tr>
        </tbody>
      </LookUpSpanTable>
      <OpsCompModal
        dateRangeStartDate={dateRangeStartDate}
        dateRangeEndDate={dateRangeEndDate}
        locationId={locationId}
        isOpsCompModalVisible={isOpsCompModalVisible}
        setIsOpsCompModalVisible={setIsOpsCompModalVisible}
      />
    </>
  ) : null

  return (
    <>
      <FullWidthTable>
        <thead>
          <tr>
            <OverviewTableTh></OverviewTableTh>
            <OverviewTableTh bold textAlign="right">
              Amount
            </OverviewTableTh>
            <OverviewTableTh bold textAlign="right">
              Comp %
            </OverviewTableTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <OverviewTableTd bold>Operational</OverviewTableTd>
            <OverviewTableTd textAlign="right">
              {toUsdString(_.get(compsItem, 'Operations', 0) / 100.0)}
            </OverviewTableTd>
            <OverviewTableTd textAlign="right">
              {toPercentageString(
                _.get(percentItemCompOfGross, 'Operations', 0) / 100.0,
                1,
              )}
            </OverviewTableTd>
          </tr>
          <tr>
            <OverviewTableTd bold>Marketing</OverviewTableTd>
            <OverviewTableTd textAlign="right">
              {toUsdString(_.get(compsItem, 'Marketing', 0) / 100.0)}
            </OverviewTableTd>
            <OverviewTableTd textAlign="right">
              {toPercentageString(
                _.get(percentItemCompOfGross, 'Marketing', 0) / 100.0,
                1,
              )}
            </OverviewTableTd>
          </tr>
          <tr>
            <OverviewTableTd bold>Employee Meals</OverviewTableTd>
            <OverviewTableTd textAlign="right">
              {toUsdString(_.get(compsItem, 'Employee Meals', 0) / 100.0)}
            </OverviewTableTd>
            <OverviewTableTd textAlign="right">
              {toPercentageString(
                _.get(percentItemCompOfGross, 'Employee Meals', 0) / 100.0,
                1,
              )}
            </OverviewTableTd>
          </tr>
          <tr>
            <OverviewTableTd bold>Promotional Menus</OverviewTableTd>
            <OverviewTableTd textAlign="right">
              {toUsdString(_.get(compsItem, 'Promo Menus', 0) / 100.0)}
            </OverviewTableTd>
            <OverviewTableTd textAlign="right">
              {toPercentageString(
                _.get(percentItemCompOfGross, 'Promo Menus', 0) / 100.0,
                1,
              )}
            </OverviewTableTd>
          </tr>
        </tbody>
      </FullWidthTable>
      {lookUpSpanTable}
    </>
  )
}

const OverviewTableTh = styled.th<IOverviewTableTh>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: ${(props) => (props.largerFont ? '20px' : '14px')};
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'right' ? 'right' : 'left')};
`

const OverviewTableTd = styled.td<IOpenPositionsTd>`
  font-family: Lexend-Regular;
  font-size: ${(props) => (props.bold ? '14px' : '18px')};
  font-style: normal;
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  text-align: ${(props) => (props.textAlign === 'right' ? 'right' : 'left')};
  color: ${(props) =>
    props.danger ? COLORS.Pomodoro : props.success ? COLORS.Expo : 'black'};
`

const LookUpSpanTable = styled.div`
  margin-left: 180px;
`

const LookUpIconContainer = styled.div`
  margin-top: 25px;
  margin-right: 5px;
`

const SeeTeamSpan = styled.div`
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;
`

export default Main
