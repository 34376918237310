import { useMemo } from 'react'

import { corporateGroupTableConfigs } from '../../../../variables'
import buildMetricValuesHook from '../../utils/buildMetricValuesHook'

const commonLossPreventionDataConfigs = {
  grossSales: 'price',
  discountCompsTotal: 'price',
  discountCompsEmployeeMeal: 'price',
  discountCompsMobileCourtesyMeal: 'price',
  discountCompsTrainerPromo: 'price',
  discountCompsCoupons: 'price',
  discountCompsPromoMeal: 'price',
  discountCompsPoliceDiscount: 'price',
  discountCompsPercentTotalHeatmap: 'number',
  discountCompsPercentEmployeeMealHeatmap: 'number',
  discountCompsPercentMobileCourtesyMealHeatmap: 'number',
  discountCompsPercentTrainerPromoHeatmap: 'number',
  discountCompsPercentCouponsHeatmap: 'number',
  discountCompsPercentPromoMealHeatmap: 'number',
  discountCompsPercentPoliceDiscountHeatmap: 'number',
  discountCheckCountTotal: 'number',
  discountCheckCountMobileCourtesyMeal: 'number',
  discountCheckCountCoupons: 'number',
  discountCheckCountTrainerPromo: 'number',
  discountCheckCountPromoMeal: 'number',
  discountCheckCountEmployeeMeal: 'number',
  discountCheckCountPoliceDiscount: 'number',
  discountCompsPercentTotal: 'percent',
  discountCompsPercentEmployeeMeal: 'percent',
  discountCompsPercentMobileCourtesyMeal: 'percent',
  discountCompsPercentTrainerPromo: 'percent',
  discountCompsPercentCoupons: 'percent',
  discountCompsPercentPromoMeal: 'percent',
  discountCompsPercentPoliceDiscount: 'percent',
  lossPreventionCheckCountRefunds: 'number',
  lossPreventionCheckCountVoids: 'number',
  lossPreventionCheckCountDeletes: 'number',
  lossPreventionCheckCountOverrings: 'number',
  lossPreventionCompsDeletes: 'price',
  lossPreventionCompsRefunds: 'price',
  lossPreventionCompsVoids: 'price',
  lossPreventionCompsOverrings: 'price',
} as const

export const burgerworksLossPreventionConfigs = {
  ...corporateGroupTableConfigs,
  ...commonLossPreventionDataConfigs,
} as const

const useCommonLossPreventionData = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: [
      'gross_sales',
      'discount_comps_total',
      'discount_check_count_total',
      'discount_comps_percent_total',
    ],
    groups: [
      'Expo Module - Discount Comps',
      'Expo Module - Discount Check Count',
      'Expo Module - Discount Comps Percent',
      'Expo Module - Loss Prevention Comps',
      'Expo Module - Loss Prevention Check Count',
    ],
  },
})

const useBurgerworksLossPrevention = () => {
  const { data: commonData, loading } = useCommonLossPreventionData()

  return {
    data: useMemo(() => {
      const keys = Object.keys(commonData?.[0] || {}) || []

      const keyInfo = keys
        .filter((k) => Object.keys(commonLossPreventionDataConfigs).includes(k))
        .reduce(
          (r, k) => {
            const kpiData = commonData?.map((cd) => cd[k] as number) || []
            const sortedData = [...kpiData].sort((a, b) => a - b)

            return {
              ...r,
              [k]: {
                sortedData,
              },
            }
          },
          {} as {
            [k: string]: {
              sortedData: number[]
            }
          },
        )

      const newData =
        commonData?.map((cd) => {
          return {
            ...cd,
            ...Object.keys(cd).reduce((r, c) => {
              if (Object.keys(commonLossPreventionDataConfigs).includes(c)) {
                const sorted = keyInfo[c].sortedData
                const rank =
                  sorted.findIndex((sortedItem) => sortedItem === cd[c]) + 1
                return {
                  ...r,
                  [`${c}Heatmap`]: ((rank - 1) / (sorted.length - 1)) * 100,
                }
              } else {
                return r
              }
            }, {}),
          }
        }) || []

      return newData
    }, [commonData, loading]),
    loading,
  }
}

export default useBurgerworksLossPrevention
