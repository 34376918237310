import { gql } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'

import { apolloClient } from 'pared/reactiveVariables/apollo'

export interface IPeriod {
  periodKey: string
  year: number
  period: number
  periodStart: moment.Moment
  periodEnd: moment.Moment
  periodStartDate: string
  periodEndDate: string
  numDays: number
}

export interface IPeriodMap {
  [periodKey: string]: IPeriod
}

const FETCH_PERIODS = gql`
  query FetchPeriods {
    listBusinessMonths(minYear: 2020, maxYear: ${moment()
      .add(1, 'years')
      .format('YYYY')}) {
      nodes {
        year: businessYear
        period: businessMonth
        periodStart: businessMonthBeginDate
        periodEnd: businessMonthEndDate
      }
    }
  }
`

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_COMPACT_FORMAT = 'YYYYMMDD'

let periods: IPeriod[] = []
let periodMap: IPeriodMap = {}
let lastDataFetchDate: string = ''

async function getPeriods() {
  if (
    periods.length > 0 &&
    lastDataFetchDate &&
    moment().format(DATE_FORMAT) === lastDataFetchDate
  ) {
    // use cached data
    return { periods, periodMap }
  } else {
    const currentApolloClient = apolloClient()

    if (currentApolloClient) {
      let response: any
      try {
        response = await currentApolloClient.query({ query: FETCH_PERIODS })
      } catch (error: any) {
        console.log(error)
      }
      const rawPeriodList = _.get(response, 'data.listBusinessMonths.nodes')
      const currentTime = moment()

      if (Array.isArray(rawPeriodList)) {
        periods = []
        periodMap = {}
        lastDataFetchDate = currentTime.format(DATE_FORMAT)

        // delay 1 day to show the new period
        const yesterday = moment().subtract(1, 'day')

        rawPeriodList.forEach((data: any) => {
          if (data.year && data.period) {
            const periodStart = moment(data.periodStart, DATE_FORMAT)
            const periodEnd = moment(data.periodEnd, DATE_FORMAT)
            if (
              periodStart.isValid() &&
              periodEnd.isValid() &&
              yesterday >= periodStart
            ) {
              const periodKey = `${periodStart.format(
                DATE_COMPACT_FORMAT,
              )}-${periodEnd.format(DATE_COMPACT_FORMAT)}`
              const period: IPeriod = {
                periodKey,
                periodStart,
                periodEnd,
                year: data.year,
                period: data.period,
                periodStartDate: periodStart.format(DATE_FORMAT),
                periodEndDate: periodEnd.format(DATE_FORMAT),
                numDays: periodEnd.diff(periodStart, 'days') + 1,
              }
              periods.push(period)
              periodMap[periodKey] = period
            }
          }
        })
        periods = _.sortBy(periods, ['year', 'period']).reverse()
      }
    }

    return { periods, periodMap }
  }
}

// this function is deprecated, please use `getDateRanges` instead.
export default getPeriods
