import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

interface IVariablesType {
  iStartDate: string
  iEndDate: string
  iFilter: {
    location_group_ids: number[]
    metrics: string[]
  }
  hasGroupBy: boolean
}

interface IMetricValueData {
  listLocationMetricValues: {
    nodes: {
      locationId: number
      metricData: Record<
        string,
        {
          name: string
          unit: string
          value: number
        }
      >
    }[]
  }
  listLocationGroupMetricValues: {
    nodes: {
      locationGroupId: number
      metricData: Record<
        string,
        {
          name: string
          unit: string
          value: number
        }
      >
    }[]
  }
}

export interface MetricResult {
  id: number
  metricCode: string
  value: number
  unit: string | null
  loading: boolean
  hasGroupBy?: boolean
}

const query = gql`
  query listLocationMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        metricData
      }
    }

    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        metricData
      }
    }
  }
`

export const useFetchGroupMetricValue = (
  variables: IVariablesType | null,
): { results: MetricResult[]; isLoading: boolean } => {
  const { data, loading } = useQuery<IMetricValueData, IVariablesType>(query, {
    variables: variables || undefined,
    skip: !variables,
  })

  const results = useMemo(() => {
    if (!data || !variables) return []

    const nodes = !variables.hasGroupBy
      ? data?.listLocationGroupMetricValues?.nodes
      : data?.listLocationMetricValues?.nodes

    return nodes.flatMap((node) =>
      variables.iFilter.metrics.map((metricCode) => {
        let id: number

        if (!variables.hasGroupBy) {
          id = (node as { locationGroupId: number }).locationGroupId
        } else {
          id = (node as { locationId: number }).locationId
        }

        let value = node.metricData?.[metricCode]?.value ?? 0
        const unit = node.metricData?.[metricCode]?.unit || null

        if (unit === 'CENT') {
          value *= 1
        } else if (unit === 'PERCENTAGE') {
          value *= 100
        }

        return {
          id,
          metricCode,
          value,
          unit,
          loading,
        }
      }),
    )
  }, [data, loading, variables])

  return { results, isLoading: loading }
}
