import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings, getBusinessLabel } from 'pared/customer'

const LocationInfoDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;

  @media ${MOBILE_WIDTH} {
    font-size: 16px;
    & ~ & {
      margin-top: 10px;
    }
  }
`

interface IDataProps {
  name?: string | null
  id?: number | null
}

interface IProps {
  director?: IDataProps
  gm?: IDataProps
}

const brandSettings = getBrandSettings()

const LocationInfo = ({ director, gm }: IProps) => (
  <>
    <LocationInfoDiv>
      {getBusinessLabel('director')}
      {': '}
      {brandSettings.labels.business.isDirectorClickable ? (
        <Link to={navigator.employeeProfile(director?.id || '')}>
          {director?.name || 'n/a'}
        </Link>
      ) : (
        director?.name || 'n/a'
      )}
    </LocationInfoDiv>
    {brandSettings.labels.business.showGm ? (
      <LocationInfoDiv>
        {'GM: '}
        {brandSettings.labels.business.isGmClickable ? (
          <Link to={navigator.employeeProfile(gm?.id || '')}>
            {gm?.name || '-'}
          </Link>
        ) : (
          gm?.name || 'n/a'
        )}
      </LocationInfoDiv>
    ) : null}
  </>
)

export default LocationInfo
