import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import { useState } from 'react'
import styled from 'styled-components'

import Help from 'pared/components/basicUi/Help'
import NumberScale from 'pared/components/basicUi/NumberScale'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'
import DownArrow from 'pared/images/basic/arrow/caret-down.svg'

import { IHandleOrderBy, IPlayerPlusMinusData } from './index'

const formatPlusMinus = (plusMinus: number) => {
  if (plusMinus > 0) {
    return `+${plusMinus}`
  }

  if (plusMinus < 0) {
    return `${plusMinus}`
  }

  return '0'
}

interface IProps {
  data: IPlayerPlusMinusData[]
  roles: Array<string>
  tcMinMax: { min: number; max: number }
  gcMinMax: { min: number; max: number }
  handleOrderBy: IHandleOrderBy
}

const QUESTIONS = [
  {
    question: 'What is Peak Impact?',
    answer:
      'Peak Impact answers the question: What is the Impact of each Employee to the entire Store during Peak times? You want your best performers on the floor during Peak meal periods so you can drive more sales and not keep guests waiting.',
  },
  {
    question: 'How should I read a score?',
    answer:
      'If Jane’s rating is +100 Guests, +25 Transactions, it means that when Jane is working, the entire store served 100 more Guests and 25 more Transactions than average.  If Joe’s score is -100 Guests, -25 Transactions, it means the entire store served 100 less Guests and 25 less transactions than average.',
  },
  {
    question: 'Why isn’t my entire staff on here?',
    answer:
      'Only employees who have completed at least 20 shifts in the past 60 days are eligible.',
  },
  {
    question: 'Is a negative score bad?',
    answer:
      'Not necessarily.   An employee might have a low or negative score because they are always put on Private Parties or are training new employees.   You might put these people on shifts where you need them, but aren’t great for more transactions or more guests.',
  },
  {
    question:
      'Are employees who work slower days, being fairly scored compared to employees who work busier days?',
    answer:
      'Yes.   Peak Plus-Minus is an apples-to-apples against the same Weekday and Meal Period.  So if someone is only works Monday Lunches, their score is calculated against other Monday Lunches.',
  },
  {
    question: 'Why are BOH and Support Staff included?',
    answer:
      'Guest Counts and Transactions are the responsibility of every team member, not just servers or bartenders.   Peak Plus-Minus lets you recognize the chefs who are faster, the hosts that do more than just seat guests, and the stewards who keep the restaurant humming.',
  },
  {
    question: 'How should I use Peak Plus-Minus?',
    answer:
      'Like in sports, one metric doesn’t tell the entire story.   So combine Peak Plus-Minus with other KPIs to ensure you’re training, scheduling and congratulating the right team members.',
  },
]

const WebLargeMain = ({
  data,
  roles,
  tcMinMax,
  gcMinMax,
  handleOrderBy,
}: IProps) => {
  const [selectedRole, setSelectedRole] = useState('all')
  const [isExpanded, setIsExpanded] = useState(false)

  const brandSettings = getBrandSettings()
  const isQsr = brandSettings.isQsr

  const expandText = isExpanded ? 'Collapse all' : 'Expand all'
  const handleRoleChange = (e: any) => {
    setSelectedRole(e.target.value)
  }

  const handleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const filteredData = data.filter(
    ({ roleName }) => selectedRole === 'all' || roleName === selectedRole,
  )
  const rows = filteredData
    .map((s) => {
      const name = _.get(s, 'name', '')
      const roleName = _.get(s, 'roleName', '')
      const tcPlusMinus = _.get(s, 'tcPlusMinus', null)
      const gcPlusMinus = _.get(s, 'gcPlusMinus', null)

      return (
        <tr>
          <td>{name}</td>
          <td>{_.startCase(roleName)}</td>
          {isQsr ? (
            <td>
              {tcPlusMinus != null ? (
                <NumberScale
                  number={tcPlusMinus}
                  min={tcMinMax.min}
                  max={tcMinMax.max}
                >
                  {formatPlusMinus(tcPlusMinus)}
                </NumberScale>
              ) : (
                '-'
              )}
            </td>
          ) : (
            <>
              <td>
                {gcPlusMinus != null ? (
                  <NumberScale
                    number={gcPlusMinus}
                    min={gcMinMax.min}
                    max={gcMinMax.max}
                  >
                    {formatPlusMinus(gcPlusMinus)}
                  </NumberScale>
                ) : (
                  '-'
                )}
              </td>
              <td>
                {tcPlusMinus != null ? (
                  <NumberScale
                    number={tcPlusMinus}
                    min={tcMinMax.min}
                    max={tcMinMax.max}
                  >
                    {formatPlusMinus(tcPlusMinus)}
                  </NumberScale>
                ) : (
                  '-'
                )}
              </td>
            </>
          )}
        </tr>
      )
    })
    .slice(0, isExpanded ? filteredData.length : 4)

  const filterOptions = roles.map((roleName) => (
    <MenuItem key={roleName} value={roleName}>
      {_.startCase(roleName)}
    </MenuItem>
  ))

  return (
    <Container>
      <Header>
        <div>
          <Title>
            Peak <TitleBlue>Impact</TitleBlue>
            <Help questions={QUESTIONS} />
          </Title>

          <SubtitleContainer>All Revenue Centers</SubtitleContainer>
        </div>

        <FlexContainer>
          <Subtitle>Filter By:</Subtitle>
          <Dropdown variant="outlined" margin="dense">
            <Select
              displayEmpty
              value={selectedRole}
              onChange={handleRoleChange}
            >
              <MenuItem key="all" value="all">
                All Roles
              </MenuItem>
              {filterOptions}
            </Select>
          </Dropdown>
        </FlexContainer>
      </Header>

      <MobileContainer>
        <RankTable>
          <thead>
            <tr>
              <th>Name</th>
              <th>Position</th>
              {isQsr ? (
                <TextCenterTh>+/- Transactions</TextCenterTh>
              ) : (
                <>
                  <ClickToOrderByTh onClick={handleOrderBy.orderByGcPlusMinus}>
                    +/- Guest Count
                    {handleOrderBy.isOrderedByGcPlusMinus() ? (
                      <DownArrowImg src={DownArrow} alt="DESC"></DownArrowImg>
                    ) : null}
                  </ClickToOrderByTh>
                  <ClickToOrderByTh onClick={handleOrderBy.orderByTcPlusMinus}>
                    +/- Transactions
                    {handleOrderBy.isOrderedByTcPlusMinus() ? (
                      <DownArrowImg src={DownArrow} alt="DESC"></DownArrowImg>
                    ) : null}
                  </ClickToOrderByTh>
                </>
              )}
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </RankTable>
        <ExpandAllSpan onClick={handleExpand}>{expandText}</ExpandAllSpan>
      </MobileContainer>
    </Container>
  )
}

export default WebLargeMain

const Title = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
`

const Subtitle = styled.div`
  font-family: Lexend-Regular;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const Header = styled(FlexContainer)`
  justify-content: space-between;
  margin: 0px 0px 14px;
  max-width: 700px;

  @media ${MOBILE_WIDTH} {
    margin: 0px 0px 20px;
    display: block;
  }
`

const RankTable = styled.table`
  width: 850px;
  margin-top: 20px;
  text-align: left;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    padding: 10px 0;
  }

  tbody td {
    padding: 10px 0;
  }
`

const Container = styled.div`
  background-color: ${COLORS.BackgroundBlue};
  padding-top: 30px;
  padding-bottom: 80px;
  padding-left: 50px;
  padding-right: 50px;

  @media ${MOBILE_WIDTH} {
    position: relative;
    left: -25px;
    width: calc(100% + 25px * 2);
  }
`

const MobileContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const Dropdown = styled(FormControl)`
  width: 220px;
  background-color: white;
`

const ExpandAllSpan = styled.div`
  margin-top: 20px;
  width: 100px;
  font-family: Lexend-Regular;
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;
`

const TitleBlue = styled.span`
  color: ${COLORS.Expo};
`

const TextCenterTh = styled.th`
  text-align: center;
`

const ClickToOrderByTh = styled.th`
  color: ${COLORS.Link};
  cursor: pointer;
  text-align: center;
`

const DownArrowImg = styled.img`
  width: 10px;
  margin: 0 0 -1px 10px;
`

const SubtitleContainer = styled.div`
  position: relative;
  top: 7px;
  height: 0px;
  font-family: Lexend-Regular;
  font-style: italic;

  @media ${MOBILE_WIDTH} {
    position: initial;
    height: auto;
    margin: 0px 0px 12px;
  }
`
