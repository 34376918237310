import { gql } from '@apollo/client'

import { IFoodListFragmentType, foodListFragment } from './foodList'
import {
  IPerformanceChartFragmentType,
  performanceChartFragment,
} from './performanceChart'

// FIXME: this should be generated by apollo
export interface IGetProductPerformanceMatrixTypelistLocationGroupMenuItemsNodesType
  extends IPerformanceChartFragmentType,
    IFoodListFragmentType {}

export interface IGetProductPerformanceMatrixTypelistLocationGroupMenuItemsType {
  nodes: IGetProductPerformanceMatrixTypelistLocationGroupMenuItemsNodesType[]
}

export interface IGetProductPerformanceMatrixListMenuItemsNodesType {
  id: string
  parentId: string | null
  value: string
  text: string
}

export interface IGetProductPerformanceMatrixListMenuItemsType {
  nodes: IGetProductPerformanceMatrixListMenuItemsNodesType[]
}

export interface IGetProductPerformanceMatrixType {
  listMenuItems: IGetProductPerformanceMatrixListMenuItemsType | null
  listLocationGroupMenuItems: IGetProductPerformanceMatrixTypelistLocationGroupMenuItemsType | null
}

export interface IGetProductPerformanceMatrixVariablesType {
  iStartDate: string
  iEndDate: string
  iFilter: any
  iItemCategory: any
}

export const getProductPerformanceMatrix = gql`
  query getProductPerformanceMatrix(
    $iStartDate: Date
    $iEndDate: Date
    $iFilter: JSON
    $iItemCategory: JSON
  ) {
    listMenuItems(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
      iSalesPercentage: 0.8
    ) {
      nodes {
        id
        parentId
        value: name
        text: name
      }
    }

    listLocationMenuItems(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
      iItemCategory: $iItemCategory
      iSalesPercentage: 0.8
    ) {
      nodes {
        ...performanceChartFragment
        ...foodListFragment
      }
    }
  }

  ${performanceChartFragment}
  ${foodListFragment}
`
