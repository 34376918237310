import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'

interface IProps {
  xAxisData: string[]
  yAxisInaccurateData: number[]
  yAxisCancelledData: number[]
  yAxisDelayedData: number[]
  yAxisTotalOrderCountData: number[]
  yAxisAnyIssueData: number[]
}

function Main({
  xAxisData,
  yAxisInaccurateData,
  yAxisCancelledData,
  yAxisDelayedData,
  yAxisTotalOrderCountData,
  yAxisAnyIssueData,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          xAxisData={xAxisData}
          yAxisInaccurateData={yAxisInaccurateData}
          yAxisCancelledData={yAxisCancelledData}
          yAxisDelayedData={yAxisDelayedData}
          yAxisTotalOrderCountData={yAxisTotalOrderCountData}
          yAxisAnyIssueData={yAxisAnyIssueData}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          xAxisData={xAxisData}
          yAxisInaccurateData={yAxisInaccurateData}
          yAxisCancelledData={yAxisCancelledData}
          yAxisDelayedData={yAxisDelayedData}
          yAxisTotalOrderCountData={yAxisTotalOrderCountData}
          yAxisAnyIssueData={yAxisAnyIssueData}
        />
      </SmallScreen>
    </>
  )
}

export default Main
