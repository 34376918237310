import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { getBrand } from 'pared/utils/brand'

import { TREND_LOCATION_MARKET_ANALYSIS } from '../gql'

interface ITrendItemType {
  totalCount: number[]
  totalAmount: number[]
}
interface ITrendDateType {
  [key: string]: ITrendItemType
}

const WEEKDAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

const useTrendDiscount = (
  selectedLocation: number,
  selectedDiscounts: string[],
  locationGroupIds?: number[],
  startDate?: string,
  endDate?: string,
) => {
  const brand = getBrand()

  const iFilter =
    selectedLocation === -1
      ? {
          location_group_ids: [
            ...(locationGroupIds || [BRAND_LOCATION_GROUP_ID[brand]]),
          ],
        }
      : { location_ids: [selectedLocation] }

  const { data: trendDiscountData, loading: trendDiscountsDataLoading } =
    useQuery(TREND_LOCATION_MARKET_ANALYSIS, {
      variables: {
        iFilter,
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !startDate || !endDate || !iFilter,
    })

  const trendChartData = useMemo(() => {
    const xAxisData = WEEKDAYS

    let trendData: ITrendDateType = {}
    let calculatedAllCounts = [0, 0, 0, 0, 0, 0, 0]

    const filterdTrendDiscountData =
      trendDiscountData?.trendLocationMarketAnalysis.nodes.filter(
        (discount: any) =>
          selectedDiscounts.find((item) => item === discount.compsName),
      )

    filterdTrendDiscountData?.forEach((item: any) => {
      if (!trendData[item.compsName]) {
        trendData[item.compsName] = {
          totalCount: [0, 0, 0, 0, 0, 0, 0],
          totalAmount: [0, 0, 0, 0, 0, 0, 0],
        }
      }

      trendData[item.compsName].totalCount[item.dayOfWeek - 1] = item.totalCount
      trendData[item.compsName].totalAmount[item.dayOfWeek - 1] =
        item.totalAmount / 100
      calculatedAllCounts[item.dayOfWeek - 1] += item.totalAmount / 100
    })

    let yAxisDataArr = []

    let index = 0
    for (const key in trendData) {
      yAxisDataArr.push({
        type: 'line',
        data: trendData?.[key].totalCount || null,
        tooltipLabel: key,
        borderColor:
          COLORS.MARKET_ANALYSIS_BAR_COLOR_HUE[
            index % COLORS.MARKET_ANALYSIS_BAR_COLOR_HUE.length
          ],
        backgroundColor:
          COLORS.MARKET_ANALYSIS_BAR_COLOR_HUE[
            index % COLORS.MARKET_ANALYSIS_BAR_COLOR_HUE.length
          ],
      })
      index++
    }

    yAxisDataArr.push({
      type: 'bar',
      yAxisId: 'yRight',
      data: calculatedAllCounts,
      tooltipLabel: 'Total $ Redeemed',
      backgroundColor: COLORS.Smoke,
    })

    return {
      xAxisData,
      yAxisDataArr,
    }
  }, [selectedDiscounts, trendDiscountData])

  const chartOptions = {
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: '# of Redemptions',
    yLeftTickCallback: (value: string) => {
      return value
    },
    yRightAxisLabel: '$ of Redemptions',
    yRightShowGridLines: false,
    yRightTickCallback: (value: string) => {
      return `$${value}`
    },
    yRightTickStepSize: 1,
    yRightBeginAtZero: true,
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let datasetLabel = ''
        let separator = ''
        let yValue = ''
        if (tooltipItemContext.dataset && tooltipItemContext.dataset.label) {
          datasetLabel = tooltipItemContext.dataset.label
          separator = ': '
        }
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          if (tooltipItemContext.dataset.type === 'bar') {
            yValue = `$${tooltipItemContext.parsed.y}`
          } else {
            yValue = tooltipItemContext.parsed.y
          }
        }
        return [datasetLabel, separator, yValue].join('')
      }
      return ''
    },
  }

  return {
    trendDiscountsDataLoading,
    trendChartData,
    chartOptions,
  }
}

export default useTrendDiscount
