import { SHOULD_RANDOMIZE_LOCATION_INFO } from 'pared/constants'

// IMPORTANT: need to match this list exactly with the one in the file: kit-core/ai/src/think/getEntities/randomizeLocationInfo.ts
const cityNames = [
  'Jacksonville',
  'Orlando',
  'Denver',
  'Nashville',
  'San Francisco',
  'Chicago',
  'Urbana',
  'Charlotte',
  'Detroit',
  'Baltimore',
  'Atlanta',
  'Richmond',
  'Los Angeles',
  'Dallas',
  'Memphis',
  'Louisville',
  'San Jose',
  'Portland',
  'Boston',
  'Oklahoma City',
  'Albuquerque',
  'Milwaukee',
  'Fresno',
  'Tucson',
  'Sacramento',
  'Mesa',
  'Kansas City',
  'Washington',
  'El Paso',
  'Omaha',
  'Raleigh',
  'Laredo',
  'Long Beach',
  'Miami',
  'Oakland',
  'Minneapolis',
  'Bakersfield',
  'Tulsa',
  'Aurora',
  'Tampa',
  'Wichita',
  'Arlington',
  'New Orleans',
  'Cleveland',
  'Anaheim',
  'Honolulu',
  'Henderson',
  'Stockton',
  'Riverside',
  'Lexington',
  'Corpus Christi',
  'Irvine',
  'Orlando',
  'Santa Ana',
  'Cincinnati',
  'Newark',
  'St. Paul',
  'Greensboro',
  'Pittsburgh',
  'Lincoln',
  'Plano',
  'Durham',
  'Chandler',
  'Anchorage',
  'Gilbert',
  'Austin',
  'Chula Vista',
  'Buffalo',
  'Reno',
  'Madison',
  'New York',
  'Jersey City',
  'Lubbock',
  'Toledo',
  'Boulder',
  'St. Petersburg',
  'Chesapeake',
  'Winston-Salem',
  'Glendale',
  'Irving',
  'Scottsdale',
  'Boise',
  'Garland',
  'Norfolk',
  'Spokane',
  'Frisco',
  'Columbus',
  'San Bernardino',
  'Cape Coral',
  'Huntsville',
  'Fremont',
  'Modesto',
  'Tacoma',
  'Baton Rouge',
  'Hialeah',
  'Santa Clarita',
  'Moreno Valley',
  'Fontana',
  'McKinney',
  'Rochester',
  'Fort Worth',
  'Indianapolis',
  'Seattle',
  'Las Vegas',
  'Fort Wayne',
]

// make FE location code easy to randomize to support safer sales demo
export function getLocationCode(
  locationId: number | string,
  locationCode: string,
): any {
  if (!SHOULD_RANDOMIZE_LOCATION_INFO || !locationId) {
    return locationCode
  }

  const locationIdNum =
    typeof locationId === 'string' ? parseInt(locationId, 10) || 0 : locationId

  let randomizedLocationCode = 0
  if (locationIdNum % 2 === 0) {
    randomizedLocationCode = locationIdNum + 40000
  } else {
    randomizedLocationCode = locationIdNum + 30000
  }

  return randomizedLocationCode.toString()
}

// make FE location name easy to randomize to support safer sales demo
export function getLocationName(
  locationId: number | string,
  locationName: string,
): any {
  if (!SHOULD_RANDOMIZE_LOCATION_INFO || !locationId) {
    return locationName
  }

  const locationIdNum =
    typeof locationId === 'string' ? parseInt(locationId, 10) || 0 : locationId

  const cityCount = cityNames.length
  if (locationIdNum > cityCount) {
    return `${locationId} ${cityNames[locationIdNum % cityCount]}`
  } else {
    return `${cityNames[locationIdNum % cityCount]}`
  }
}
