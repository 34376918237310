import _ from 'lodash'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import FullWidthTable from 'pared/components/FullWidthTable'
import { UNFILLED_MANAGERIAL_POSITION_OPTIONS } from 'pared/constants'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'

import { IUnfilledManagerialPosition } from '../index'

interface IProps {
  isTableExpanded: boolean
  onToggleTable: () => void
  unfilledManagerialPositions: IUnfilledManagerialPosition[]
  unfilledManagerialPositionOption: string
}

export interface IOverviewTableTh {
  largerFont?: boolean
  textAlign?: string
  bold?: boolean
}

export interface IOpenPositionsTd {
  bold?: boolean
  danger?: boolean
  success?: boolean
  textAlign?: string
}

function WebLargeMain({
  isTableExpanded,
  onToggleTable,
  unfilledManagerialPositions,
  unfilledManagerialPositionOption,
}: IProps) {
  const brandSettings = getBrandSettings()
  const businessLabels = brandSettings.labels.business

  let filteredPositions

  switch (unfilledManagerialPositionOption) {
    case UNFILLED_MANAGERIAL_POSITION_OPTIONS.NO_CANDIDATES:
      filteredPositions = _.filter(unfilledManagerialPositions, (p) => {
        return _.isEmpty(_.get(p, 'candidateName'))
      })
      break
    case UNFILLED_MANAGERIAL_POSITION_OPTIONS.FILLED_POSITIONS:
      filteredPositions = _.filter(unfilledManagerialPositions, (p) => {
        return !_.isEmpty(_.get(p, 'candidateName'))
      })
      break
    case UNFILLED_MANAGERIAL_POSITION_OPTIONS.ALL_POSITIONS:
      filteredPositions = unfilledManagerialPositions
      break
    default:
      filteredPositions = unfilledManagerialPositions
  }

  const toggleText = isTableExpanded ? 'Collapse all' : 'Show All'

  const unfilledManagerialPositionRows = _.map(filteredPositions, (p) => {
    const positionId = _.get(p, 'positionId', 0)
    const candidateName = _.get(p, 'candidateName', '')
    const displayedCandidateName = _.isEmpty(candidateName)
      ? '-'
      : candidateName

    const trainingStoreName = _.get(p, 'trainingStoreName', '')
    const displayedTrainingStoreName = _.isEmpty(trainingStoreName)
      ? '-'
      : trainingStoreName

    const filledPendingStartDate = _.get(p, 'filledPendingStartDate', '')
    const displayedFilledPendingStartDate = _.isEmpty(filledPendingStartDate)
      ? '-'
      : filledPendingStartDate

    return (
      <tr key={positionId}>
        <OverviewTableTd>
          <LightBlueLink to={navigator.team(_.get(p, 'storeId', 1))}>
            {_.get(p, 'storeName', '')}
          </LightBlueLink>
        </OverviewTableTd>
        <OverviewTableTd>
          {businessLabels.isDirectorClickable ? (
            <LightBlueLink to={navigator.employeeProfile(_.get(p, 'doId', 1))}>
              {_.get(p, 'doName', '')}
            </LightBlueLink>
          ) : (
            _.get(p, 'doName', '')
          )}
        </OverviewTableTd>
        <OverviewTableTd>{_.get(p, 'managerRoleName', '')}</OverviewTableTd>
        <OverviewTableTd>{_.get(p, 'vacantFromNow', '')}</OverviewTableTd>
        <OverviewTableTd>{displayedCandidateName}</OverviewTableTd>
        <OverviewTableTd>{displayedTrainingStoreName}</OverviewTableTd>
        <OverviewTableTd>{displayedFilledPendingStartDate}</OverviewTableTd>
      </tr>
    )
  })

  const expandText =
    _.size(unfilledManagerialPositionRows) > 3 ? (
      <ExpandAllSpan onClick={onToggleTable}>{toggleText}</ExpandAllSpan>
    ) : null

  let toggledRows = unfilledManagerialPositionRows

  if (!isTableExpanded) {
    toggledRows = _.slice(unfilledManagerialPositionRows, 0, 3)
  }

  const content = _.isEmpty(unfilledManagerialPositionRows) ? (
    <NoData>No Positions</NoData>
  ) : (
    <FullWidthTable>
      <thead>
        <tr>
          <OverviewTableTh bold>Store</OverviewTableTh>
          <OverviewTableTh bold>{businessLabels['director']}</OverviewTableTh>
          <OverviewTableTh bold>Role</OverviewTableTh>
          <OverviewTableTh bold>Time Vacant</OverviewTableTh>
          <OverviewTableTh bold>Candidate Name</OverviewTableTh>
          <OverviewTableTh bold>Training Store</OverviewTableTh>
          <OverviewTableTh bold>Filled Pending Start Date</OverviewTableTh>
        </tr>
      </thead>
      <tbody>{toggledRows}</tbody>
    </FullWidthTable>
  )

  return (
    <>
      {content}
      {expandText}
    </>
  )
}

const OverviewTableTh = styled.th<IOverviewTableTh>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: ${(props) => (props.largerFont ? '20px' : '14px')};
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'right' ? 'right' : 'left')};
`

const LightBlueLink = styled(Link)`
  color: ${COLORS.Link};
`

const OverviewTableTd = styled.td<IOpenPositionsTd>`
  font-family: Lexend-Regular;
  font-size: ${(props) => (props.bold ? '18px' : '14px')};
  font-style: normal;
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  text-align: ${(props) => (props.textAlign === 'right' ? 'right' : 'left')};
  color: ${(props) =>
    props.danger ? COLORS.Pomodoro : props.success ? COLORS.Expo : 'black'};
`

const NoData = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const ExpandAllSpan = styled.div`
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: ${COLORS.Link};
  text-decoration: underline;
  cursor: pointer;
  float: left;
`

export default WebLargeMain
