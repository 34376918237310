import { useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import {
  IListLocationEmployeeLossPreventionKpisNodeType,
  IListLocationEmployeeLossPreventionKpisType,
  ILossPreventionItemDetailsType,
  LIST_LOCATION_EMPLOYEE_LOSS_PREVENTION_KPIS,
} from '../gqls'

// "Over Ring" and "Voids" are the same thing
export interface IVoidsDetails {
  time: moment.Moment
  timeStr: string
  checkNumber: string
  amount: string
}

export interface ICashType {
  name: string
  position: string
  cashHandlingItems: {
    type: string
    amount: number
    count: number
    checkPercent: number
    details?: IVoidsDetails[]
  }[]
}

interface ICustomDataType {
  [key: string]: {
    count: number
    amount: number
    checkPercent: number
    details: IVoidsDetails[]
  }
}

export default (locationId: number, startDate?: string, endDate?: string) => {
  const { data, error, loading } =
    useQuery<IListLocationEmployeeLossPreventionKpisType>(
      LIST_LOCATION_EMPLOYEE_LOSS_PREVENTION_KPIS,
      {
        variables: {
          iLocationId: locationId,
          iStartDate: startDate,
          iEndDate: endDate,
        },
        skip: !startDate || !endDate || !locationId,
      },
    )

  return useMemo(() => {
    const rawData = data?.listLocationEmployeeLossPreventionKpis?.nodes || []

    const tableData = rawData?.map(
      (row: IListLocationEmployeeLossPreventionKpisNodeType) => {
        const name = [
          _.get(row, 'employeeInfo.preferredName') || '',
          _.get(row, 'employeeInfo.familyName') || '',
        ].join(' ')
        const position = _.get(row, 'roleInfo.name') || '-'
        const cashOverOrShort = {
          amount: row?.cashOverOrShort,
          checkPercent: row?.inaccurateCashierShiftPercent,
        }

        const customData = row?.lossPreventionItems?.reduce(
          (
            result: ICustomDataType,
            itemData: ILossPreventionItemDetailsType,
          ) => ({
            ...result,
            [itemData.type]: {
              count: itemData?.count,
              amount: itemData?.amount,
              checkPercent: itemData?.checkPercent,
              details: _.sortBy(
                _.flatten(itemData.details).map((rawDetails: any) => {
                  const time = moment.utc(
                    rawDetails['time'],
                    'YYYY-MM-DD HH:mm:ss',
                  )
                  return {
                    time,
                    timeStr: time.format('M/D'),
                    checkNumber: rawDetails['checkNumber'],
                    amount: (rawDetails['amount'] / 100.0).toFixed(2),
                  }
                }),
                ['time'],
              ),
            },
          }),
          {} as ICustomDataType,
        )

        return {
          name,
          position,
          cashOverOrShort,
          ...customData,
        }
      },
    )

    return tableData
  }, [data, locationId, startDate, endDate])
}
