import { useQuery } from '@apollo/client'
import { filter } from 'graphql-anywhere'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import Help from 'pared/components/basicUi/Help'
import Select from 'pared/components/basicUi/select'
import COLORS from 'pared/constants/colors'
import useGetDateRange from 'pared/hooks/useGetDateRange'

import FoodList from './FoodList'
import PerformanceChart from './PerformanceChart'
import SubHeader from './SubHeader'
import { foodListFragment } from './gqls/foodList'
import { performanceChartFragment } from './gqls/performanceChart'
import {
  IGetProductPerformanceMatrixListMenuItemsNodesType,
  IGetProductPerformanceMatrixType,
  IGetProductPerformanceMatrixVariablesType,
  getProductPerformanceMatrix,
} from './gqls/performanceMatrix'

interface IPropsType {
  locationId: number
  dateRange: ReturnType<typeof useGetDateRange>
}

const QUESTIONS = [
  {
    question: 'How do I use the chart?',
    answer:
      'To zoom in and out of the chart, pinch and spread your finger and thumb on the mousepad or scroll up and down on the external mousewheel. Click and drag to move around the chart. Bigger circles represent the most popular items.',
  },
  {
    question: 'Which Menu Items are included?',
    answer:
      'Product Performance shows all “core menu items” listed on your menu, as well as the Top 80% of all items sold.',
  },
  {
    question: 'What is Popularity?',
    answer:
      'The Popularity Number is the amount of times a menu item has been purchased out of 100 checks. For example, 6.5 would mean a menu item appears on 6.5% of checks.',
  },
  {
    question: 'What is Portion Contribution Margin?',
    answer:
      'Portion Contribution Margin is the Sales Price of an item minus the Recipe Cost. It is the amount of money you earn per item purchased.',
  },
  {
    question: 'What is Total Contribution Margin?',
    answer:
      'Total Contribution Margin is the Portion CM multiplied by the # of Times an item has been purchased during the period of time selected. This shows you how much total $ you made on that item over the selected period of time.',
  },
  {
    question: 'What is Share of Margin?',
    answer:
      'This is the Total Contribution Margin of the item divided by the Contribution Margin of All Items. For example if an Item has a Share of Margin of 10%, it would mean that item represents 10% of all $ profit for the menu.',
  },
]

const Title = styled.h1`
  margin: 0px;
  font-family: Lexend-SemiBold;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
`

const Content = styled.p`
  margin: 0px 0px 24px;
  font-size: 12px;
  font-family: Lexend-Regular;
  color: ${COLORS.Chalkboard};
`

const CategorySelect = styled(Select)`
  width: 236px;
` as typeof Select

const PerformanceMatrix = ({ dateRange, locationId }: IPropsType) => {
  const [category, setCategory] = useState('All categories')
  const { data, previousData, loading } = useQuery<
    IGetProductPerformanceMatrixType,
    IGetProductPerformanceMatrixVariablesType
  >(getProductPerformanceMatrix, {
    variables: {
      iStartDate: dateRange?.startDateStr || '',
      iEndDate: dateRange?.endDateStr || '',
      iFilter: {
        location_ids: [locationId],
      },
      iItemCategory: {
        category,
      },
    },
    skip: !dateRange,
  })
  const listLocationMenuItems =
    (data || previousData)?.listLocationMenuItems || null
  const listMenuItems = (data || previousData)?.listMenuItems || null
  const dataSource = useMemo(
    () => [
      { id: 'root', parentId: null, value: 'root', text: 'root' },
      {
        id: 'all',
        parentId: 'root',
        value: 'All categories',
        text: 'All categories',
      },
      ...(listMenuItems?.nodes?.filter(({ parentId }) => parentId === 'root') ||
        []),
    ],
    [listMenuItems],
  )

  if (!listLocationMenuItems || !listMenuItems)
    // TODO: should have loading page
    return <div>loading</div>

  return (
    <>
      <SubHeader>
        <h1>
          Performance Matrix
          <Help
            header={
              <>
                <Title>Welcome to Product Performance</Title>

                <Content>
                  The Product Page easily shows you which menu items are the
                  most profitable and most popular for your company so you can
                  optimize your menu, ingredients, and marketing strategies.
                </Content>
              </>
            }
            questions={QUESTIONS}
          />
        </h1>

        <div>
          <h3>Filter by:</h3>

          <CategorySelect<IGetProductPerformanceMatrixListMenuItemsNodesType>
            value={[category]}
            onChange={(value) => setCategory(value[0])}
            dataSource={dataSource}
          />
        </div>
      </SubHeader>

      <PerformanceChart
        listLocationMenuItemsRecords={filter(
          performanceChartFragment,
          listLocationMenuItems.nodes,
        )}
        loading={loading}
      />

      <FoodList
        listLocationMenuItemsRecords={filter(
          foodListFragment,
          listLocationMenuItems.nodes,
        )}
        loading={loading}
      />
    </>
  )
}

export default React.memo(
  PerformanceMatrix,
) as unknown as typeof PerformanceMatrix
