import _ from 'lodash'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import ExpoLogo from 'pared/images/Pared/small-expo-logo.png'

interface IProps {
  isSullivansUser: boolean
}

function OldUrlWarning({ isSullivansUser }: IProps) {
  const redirectUrl = isSullivansUser
    ? 'https://drg.getexpo.com/sullivans/'
    : 'https://drg.getexpo.com/rmg/'

  return (
    <MainContainer>
      <OuterContainer>
        <InnerContainer>
          <LogoDiv>
            <img src={ExpoLogo} alt="Expo Logo" />
          </LogoDiv>

          <WarningContainer>
            <WarningTextDiv>
              The URL{' '}
              <strong>
                <i>https://macgrill.pared.com</i>
              </strong>{' '}
              is no longer active.
            </WarningTextDiv>
            <br />
            <br />
            <WarningTextDiv>
              Please use the updated URL{' '}
              <i>
                <a href={redirectUrl}>https://drg.getexpo.com</a>
              </i>{' '}
              to access Expo.
            </WarningTextDiv>
            <br />
            <br />
            <WarningTextDiv>Remember to update your bookmarks.</WarningTextDiv>
          </WarningContainer>

          <VerticalSpacer />
        </InnerContainer>
      </OuterContainer>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  min-width: 1440px;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: ${COLORS.Salt};
`

const OuterContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: table;
`

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`

const LogoDiv = styled.div`
  margin: 0 0 50px 0;
`

const WarningContainer = styled.div`
  color: ${COLORS.Chalkboard};
  width: 800px;
  display: inline-block;
  text-align: center;
  padding: 40px 60px;
  border: 5px solid ${COLORS.Spritz};
  border-radius: 15px;
  font-size: 16px;
`

const WarningTextDiv = styled.div`
  display: inline-block;
`

const VerticalSpacer = styled.div`
  height: 250px;
`

export default OldUrlWarning
