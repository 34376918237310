import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { FROM_EXPO } from 'pared/pages/ExpoAi/constants'
import { IMessage } from 'pared/pages/ExpoAi/type'
import EmployeeCashHandlingTable from 'pared/pages/LossPrevention/EmployeeCashHandlingTable'
import EmployeeDiscountTable from 'pared/pages/LossPrevention/EmployeeDiscountTable'
import EmployeeVoidsTable from 'pared/pages/LossPrevention/EmployeeVoidsTable'
import StaffSalesTable from 'pared/pages/v2/Salesmanship/StaffSalesTable'

import formatDate from '../formatDate'

const VerticalSpacer = styled.div`
  width: 100%;
  height: 50px;
`

export default function handleSalesmanshipEmployeeKpis(responseData: any) {
  if (responseData?.salesmanshipEmployeeKpis) {
    const messageId = uuidv4()
    const result = responseData.salesmanshipEmployeeKpis

    const answerMessage: IMessage = {
      id: messageId,
      from: FROM_EXPO,
      content: (
        <div>
          <div>
            {result.locationName} ({formatDate(result.startDate)} ~{' '}
            {formatDate(result.endDate)})
          </div>
          <StaffSalesTable
            storeId={result.locationId}
            storeTitle={result.locationName}
            startDate={result.startDate}
            endDate={result.endDate}
            dayPart="ALL"
            preSelectedKpi={result.kpiType}
          />
        </div>
      ),
    }

    return answerMessage
  }

  return null
}
