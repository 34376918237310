import { useMemo } from 'react'

import { IApiDataType } from '../types'
import {
  useBbbCorporateFilter,
  useBbbPnlCorporateFilter,
} from './useBbbCorporateFilter'

const buildBbbCorporateWithoutBreakdownFilter = (
  useHook: typeof useBbbCorporateFilter | typeof useBbbPnlCorporateFilter,
) => {
  const useBbbCorporateWithoutBreakdownFilter = () => {
    const { data, loading } = useHook()

    return {
      data: useMemo((): IApiDataType => {
        if (!data) return null

        const { values, defaultValue } = data

        return {
          values: values.filter((v) => !/Breakdown By/.test(v.label)),
          defaultValue,
        }
      }, [data]),
      loading,
    }
  }
  return useBbbCorporateWithoutBreakdownFilter
}

export const useBbbCorporateWithoutBreakdownFilter =
  buildBbbCorporateWithoutBreakdownFilter(useBbbCorporateFilter)

export const useBbbPnlCorporateWithoutBreakdownFilter =
  buildBbbCorporateWithoutBreakdownFilter(useBbbPnlCorporateFilter)
