import moment from 'moment'
import { useMemo } from 'react'

import { useDateFilter } from '../DateFitlerProvider'

const useYtdEndDate = () => {
  const { endDate: endDateStr } = useDateFilter()

  return useMemo(() => {
    if (!endDateStr) return

    const now = moment().utc(true)
    const endDate = moment(endDateStr).utc(true)

    return (endDate.isSameOrAfter(now, 'days') ? now : endDate).format(
      'YYYY-MM-DD',
    )
  }, [endDateStr])
}

export default useYtdEndDate
