import React, { useState } from 'react'

import Main from './Main'

interface IProps {
  color?: string
}

const FeedbackLink = ({ color }: IProps) => {
  const [isFeedbackFormVisible, setIsFeedbackFormVisible] =
    useState<boolean>(false)

  return (
    <Main
      isFeedbackFormVisible={isFeedbackFormVisible}
      setIsFeedbackFormVisible={setIsFeedbackFormVisible}
      color={color}
    />
  )
}

export default FeedbackLink
