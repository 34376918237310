import { IColumnsType } from 'pared/components/basicUi/table'

import { getPrice } from '../../utils'
import { IDiscountCampaignType } from './useDiscountCampaign'

interface IReturnType {
  discountCampaignColumns: IColumnsType<IDiscountCampaignType>[]
}

const useDiscountCampaignColumns = (): IReturnType => {
  const discountCampaignColumns = [
    {
      key: 'itemType',
      title: 'Campaign',
      minWidth: '275px',
      render: (_, col) => {
        return col?.itemType
      },
      sorter: true,
    },
    {
      key: 'totalItemCounts',
      title: '# of Redemptions',
      minWidth: '150px',
      align: 'center',
      render: (_, values) => {
        return values?.totalItemCounts
      },
      sorter: true,
    },
    {
      key: 'checkAvg',
      title: 'Check Average',
      minWidth: '150px',
      align: 'center',
      render: (_, values) => {
        return `$${values?.checkAvg}`
      },
      sorter: true,
    },
    {
      key: 'ppa',
      title: 'PPA',
      minWidth: '150px',
      align: 'center',
      render: (_, values) => {
        return `$${values?.ppa}`
      },
      sorter: true,
    },
    {
      key: 'totalItemDiscounts',
      title: 'Total $ Discount',
      minWidth: '150px',
      align: 'center',
      render: (_, values) => {
        return getPrice(values?.totalItemDiscounts || 0)
      },
      sorter: true,
    },
  ] as IColumnsType<IDiscountCampaignType>[]

  return { discountCampaignColumns }
}

export default useDiscountCampaignColumns
