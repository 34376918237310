import _ from 'lodash'
import { forwardRef } from 'react'
import styled from 'styled-components'

import {
  DAYPART_DISPLAY_NAME,
  RANKINGS_ROW_TYPE,
  STORE_AVG_TEXT,
} from 'pared/constants'
import { BRAND_LOGOS, BRAND_NAME } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import ExpoLogo from 'pared/images/Expo/expo-logo-blue.png'
import { getBrand } from 'pared/utils/brand'
import { toUsdString } from 'pared/utils/number'

import { ISalesmanship } from '../index'

interface IRankTableTh {
  textAlign?: string
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
  isAvg?: boolean
}

interface IProps {
  rankedData: ISalesmanship[]
  storeTitle: string
  selectedValue: string
  tableTitle: string
  dataPointColumnName: string
  dayPart: string
  numberOfDecimals: number
}

export const SalesmanshipTable = forwardRef(
  (
    {
      rankedData,
      storeTitle,
      selectedValue,
      tableTitle,
      dataPointColumnName,
      dayPart,
      numberOfDecimals,
    }: IProps,
    ref,
  ) => {
    const brand = getBrand()

    const dayPartDisplayName = DAYPART_DISPLAY_NAME[dayPart]

    const getRankedRows = (rankedData: any) => {
      return _.map(rankedData, (d) => {
        const name = _.get(d, 'name', '')
        const rank = _.get(d, 'rank', 1)
        const dataPoint = _.get(d, 'dataPoint', 0)
        const rowType = _.get(d, 'rowType', '')

        return rowType === RANKINGS_ROW_TYPE.AVG ? (
          <AvgTr>
            <RankNumberTd bold textAlign={'left'}></RankNumberTd>
            <RankTableTd textAlign={'left'} isAvg>
              {STORE_AVG_TEXT}
            </RankTableTd>
            <RankTableTd textAlign={'left'} isAvg>
              {toUsdString(dataPoint, numberOfDecimals)}
            </RankTableTd>
          </AvgTr>
        ) : (
          <tr>
            <RankNumberTd bold textAlign={'left'}>
              {rank}
            </RankNumberTd>
            <RankTableTd textAlign={'left'}>{name}</RankTableTd>
            <RankTableTd textAlign={'left'}>
              {toUsdString(dataPoint, numberOfDecimals)}
            </RankTableTd>
          </tr>
        )
      })
    }

    const logo = (
      <LogoImg
        src={BRAND_LOGOS[brand].dark}
        alt={`${BRAND_NAME[brand]} Logo`}
      />
    )

    return (
      <div ref={ref} class="printContainer">
        <MainContainer>
          <table>
            <tbody>
              <tr>
                <TitleLeftTd>{logo}</TitleLeftTd>
                <td>
                  <PoweredBy>powered by</PoweredBy>
                  <div>
                    <ExpoLogoImg src={ExpoLogo} alt="Expo" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <PageLeftHeader>{storeTitle}</PageLeftHeader>
          <SubtitleContainer>{`${selectedValue} ${dayPartDisplayName}`}</SubtitleContainer>
          <BottomContainer>
            <TableDescription>{tableTitle}</TableDescription>
            <RankTable>
              <thead>
                <tr>
                  <RankTableTh textAlign={'left'}>Rank</RankTableTh>
                  <RankTableTh textAlign={'left'}>Name</RankTableTh>
                  <RankTableTh textAlign={'left'}>
                    {dataPointColumnName}
                  </RankTableTh>
                </tr>
              </thead>
              <tbody>{getRankedRows(rankedData)}</tbody>
            </RankTable>
          </BottomContainer>
        </MainContainer>
      </div>
    )
  },
)

const MainContainer = styled.div`
  padding-top: 20px;
  padding-left: 50px;
`

const AvgTr = styled.tr`
  background-color: ${COLORS.Sky} !important;
`

const LogoImg = styled.img`
  height: 50px;
  margin-bottom: 10px;
`

const SullivansLogoImg = styled.img`
  height: 100px;
`

const TitleLeftTd = styled.td`
  width: 590px;
`

const PoweredBy = styled.div`
  padding-bottom: 10px;
  font-family: Lexend-SemiBold;
  font-size: 12px;
  text-align: center;
`

const BottomContainer = styled.div`
  width: 500px;
`

const ExpoLogoImg = styled.img`
  width: 131px;
`

const TableDescription = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 10px;
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  padding: 10px 20px 0 0;
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  margin-top: 10px;
`

const RankTableTh = styled.th<IRankTableTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankTableTd = styled.td<IRankTableTd>`
  color: ${(props) => (props.isAvg ? 'white' : 'black')};
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankNumberTd = styled(RankTableTd)`
  padding-left: 12px;
`

const RankTable = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 8px;
    padding-bottom: 10px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`
