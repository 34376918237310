import { IRouteType } from '../../types'
import lfrBusinessReview from './lfrBusinessReview'
import lfrGfg from './lfrGfp'
import lfrGlobalAndBrandLayout from './lfrGlobalAndBrandLayout'
import lfrUserEvents from './lfrUserEvents'

export const routes: IRouteType[] = [
  {
    title: 'Brand Overview',
    link: '/:brand/global-and-brand-layout',
    default: true,
  },
  {
    title: 'Business Review',
    link: '/:brand/business-review',
    default: true,
  },
  {
    title: 'Global Financial Picture',
    collapsible: true,
    children: [
      {
        title: 'GFP Restaurants',
        link: '/:brand/gfp-restaurant',
      },
      {
        title: 'GFP Properties',
        link: '/:brand/property',
      },
      {
        title: 'GFP Upload Reports',
        link: '/:brand/upload-gfp-reports',
      },
    ],
  },
  {
    title: 'User Events',
    link: '/:brand/user-events',
  },
]

export default [
  lfrBusinessReview,
  lfrGlobalAndBrandLayout,
  lfrGfg,
  lfrUserEvents,
]
