import _ from 'lodash'
import { useEffect, useState } from 'react'

import useUnitFiltersConfig from './useUnitFiltersConfig'

const useUnitFilter = (tableName: string) => {
  const [unitFilter, setUnitFilter] = useState<string[]>([])
  const unitFiltersConfig = useUnitFiltersConfig()
  const { unitFilters, initialUnit } =
    tableName === 'inventory'
      ? unitFiltersConfig.inventory
      : unitFiltersConfig.purchases

  useEffect(() => {
    setUnitFilter([unitFilters[1].value])
  }, [unitFilters])

  return {
    unitFilter,
    setUnitFilter,
    unitFilters,
  }
}

export default useUnitFilter
