import _ from 'lodash'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import CustomizedReport from 'pared/components/CustomizedReport'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import COLORS from 'pared/constants/colors'
import { getBusinessLabel } from 'pared/customer'
import {
  IDateRange,
  TYPE_PERIOD,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'
import DirectorFilterSelector from 'pared/pages/StoreList/DirectorFilterSelector'
import { getBrand } from 'pared/utils/brand'

import BreakdownTable from './BreakdownTable'
import Chart from './Chart'
import DisputeReasonTable from './DisputeReasonTable'
import DisputeTable from './DisputeTable'
import MissingItemTable from './MissingItemTable'
import useDeliveryConfig from './hooks/useDeliveryConfig'
import { IGlobalDeliveryKpi } from './index'

interface IProps {
  allDirectors: IDirector[]
  selectedDirector: IDirector | null
  navParams: INavParams
  onDirectorChange: (directorEmployeeId: number) => Promise<void>
  selectedDateRange: IDateRange | null
  globalDeliveryKpiSummary: IGlobalDeliveryKpi
  selectedLocationGroupId: number
  breakdownType: string | null
}

interface IKpiTh {
  danger?: boolean
  success?: boolean
  book?: boolean
  isBackgroundGrey?: boolean
}

function WebLargeMain({
  navParams,
  allDirectors,
  selectedDirector,
  onDirectorChange,
  selectedDateRange,
  globalDeliveryKpiSummary,
  selectedLocationGroupId,
  breakdownType,
}: IProps) {
  const deliveryConfig = useDeliveryConfig(getBrand())

  const selectedDirectorEmployeeId = _.get(selectedDirector, 'employeeId', -1)
  let breakdownTitle = 'BREAKDOWN BY STORE'
  let isBreakdownByDirectors = false
  if (selectedDirectorEmployeeId === -2) {
    isBreakdownByDirectors = true
    breakdownTitle = `BREAKDOWN BY ${getBusinessLabel(
      'director',
    ).toUpperCase()}`
  } else if (breakdownType) {
    breakdownTitle = `BREAKDOWN BY ${breakdownType}`.toUpperCase()
  } else if (deliveryConfig.breakdownByStoreTitle) {
    breakdownTitle = deliveryConfig.breakdownByStoreTitle
  }

  return (
    <MainContainer>
      <PageHeaderContainer>
        <LeftHeaderContainer>
          <PageHeaderLineContainer>
            <PageLeftHeader>
              Systemwide Delivery
              <SubtitleContainer>Updated Weekly</SubtitleContainer>
            </PageLeftHeader>
            <SelectorContainer>
              <DirectorFilterSelectorContainer>
                {selectedDirector ? (
                  <DirectorFilterSelector
                    allDirectors={allDirectors}
                    selectedDirector={selectedDirector}
                    onDirectorChange={onDirectorChange}
                  />
                ) : null}
              </DirectorFilterSelectorContainer>
              <DateRangeSelector
                navParams={navParams}
                excludedDateRanges={[TYPE_YESTERDAY]}
                preSelectedDateRange={TYPE_PERIOD}
              />
            </SelectorContainer>
          </PageHeaderLineContainer>
        </LeftHeaderContainer>
      </PageHeaderContainer>

      <KpiContainer>
        <KpiLeftContainer>
          <KpiOverviewTable>
            <tbody>
              <tr>
                <PtdKpiCategoryTd>
                  <PtdKpiCategoryNameDiv>KPIs</PtdKpiCategoryNameDiv>
                </PtdKpiCategoryTd>
                <PtdOverviewSpacerTd></PtdOverviewSpacerTd>
              </tr>
              <tr>
                <td>
                  <PtdKpiTable>
                    <thead>
                      <tr>
                        {deliveryConfig['realTimeKpis'].map(
                          ({ variableName }: { variableName: string }) => {
                            const value = _.get(
                              globalDeliveryKpiSummary,
                              variableName,
                              '',
                            )
                            return (
                              <KpiTh book={value === 'loading'}>
                                <div>{value}</div>
                              </KpiTh>
                            )
                          },
                        )}
                        {deliveryConfig.oloWaitTime?.isVisible ? (
                          <KpiTh>
                            <CustomizedReport
                              reportName="CORPORATE_DELIVERY_OLO_WAIT_TIME"
                              inputParams={{
                                locationGroupId: selectedLocationGroupId,
                                startDate:
                                  selectedDateRange?.startDateStr || '',
                                endDate: selectedDateRange?.endDateStr || '',
                              }}
                            />
                          </KpiTh>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {deliveryConfig['realTimeKpis'].map(
                          ({ displayName }: { displayName: string }) => {
                            return <td>{displayName}</td>
                          },
                        )}
                        {deliveryConfig.oloWaitTime?.isVisible ? (
                          <td>{deliveryConfig.oloWaitTime?.displayName}</td>
                        ) : null}
                      </tr>
                    </tbody>
                  </PtdKpiTable>
                </td>
              </tr>
            </tbody>
          </KpiOverviewTable>
        </KpiLeftContainer>
        {Array.isArray(deliveryConfig['breakdownOfIssues']) ? (
          <BreakdownContainer>
            <tbody>
              <tr>
                <PtdKpiCategoryTd>
                  <PtdKpiCategoryBreakdownDiv>
                    Breakdown of Issues
                  </PtdKpiCategoryBreakdownDiv>
                </PtdKpiCategoryTd>
              </tr>
              <tr>
                <td>
                  <table>
                    {deliveryConfig['breakdownOfIssues'].map(
                      ({
                        variableName,
                        displayName,
                      }: {
                        variableName: string
                        displayName: string
                      }) => {
                        const value = _.get(
                          globalDeliveryKpiSummary,
                          variableName,
                          '',
                        )
                        return (
                          <tr>
                            <KpiTd book={value === 'loading'}>{value}</KpiTd>
                            <td>{displayName}</td>
                          </tr>
                        )
                      },
                    )}
                  </table>
                </td>
              </tr>
            </tbody>
          </BreakdownContainer>
        ) : null}
      </KpiContainer>

      <VerticalSpacer60px />

      {deliveryConfig['deliveryChart']?.isVisible && selectedDateRange ? (
        <>
          <Chart
            endDate={selectedDateRange.endDateStr}
            dateRangeType={selectedDateRange.type}
            locationGroupId={selectedLocationGroupId}
          />

          <VerticalSpacer60px />
        </>
      ) : null}

      <SectionTitleDiv>{breakdownTitle}</SectionTitleDiv>
      {selectedDateRange ? (
        <BreakdownTable
          isBreakdownByDirectors={isBreakdownByDirectors}
          startDate={selectedDateRange.startDateStr}
          endDate={selectedDateRange.endDateStr}
          locationGroupId={selectedLocationGroupId}
          locationGroupIds={allDirectors.map(
            (doData) => doData.locationGroupId,
          )}
          breakdownType={breakdownType}
        />
      ) : null}

      {selectedDateRange && deliveryConfig.isDisputeTableVisible ? (
        <>
          <VerticalSpacer60px />
          <DisputeTable
            isBreakdownByDirectors={isBreakdownByDirectors}
            startDate={selectedDateRange.startDateStr}
            endDate={selectedDateRange.endDateStr}
            locationGroupId={selectedLocationGroupId}
            locationGroupIds={allDirectors.map(
              (doData) => doData.locationGroupId,
            )}
            breakdownType={breakdownType}
          />
          <VerticalSpacer60px />
          <DisputeReasonTable
            isBreakdownByDirectors={isBreakdownByDirectors}
            startDate={selectedDateRange.startDateStr}
            endDate={selectedDateRange.endDateStr}
            locationGroupId={selectedLocationGroupId}
            locationGroupIds={allDirectors.map(
              (doData) => doData.locationGroupId,
            )}
            breakdownType={breakdownType}
          />
          <VerticalSpacer60px />
          <MissingItemTable
            isBreakdownByDirectors={isBreakdownByDirectors}
            startDate={selectedDateRange.startDateStr}
            endDate={selectedDateRange.endDateStr}
            locationGroupId={selectedLocationGroupId}
            locationGroupIds={allDirectors.map(
              (doData) => doData.locationGroupId,
            )}
            breakdownType={breakdownType}
          />
        </>
      ) : null}
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 1200px;
  padding: 0 0 50px 0;
`

const DirectorFilterSelectorContainer = styled.div`
  float: left;
  margin-right: 7px;
`

const KpiContainer = styled.div`
  display: inline-block;
`

const KpiOverviewTable = styled.table``

const BreakdownContainer = styled.div`
  float: left;
`

const PtdKpiCategoryTd = styled.td`
  vertical-align: top;
`

const PtdKpiCategoryNameDiv = styled.div`
  width: 100%;
  text-align: center;
  padding: 5px 15px 5px 15px;
  border-bottom: 1px solid ${COLORS.Chalkboard};
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

const PtdOverviewSpacerTd = styled.td`
  width: 40px;
`

const KpiTh = styled.th<IKpiTh>`
  color: ${(props) =>
    props.danger ? COLORS.Pomodoro : props.success ? COLORS.Expo : 'black'};
  font-family: ${(props) =>
    props.book ? 'Lexend-Regular' : 'Lexend-SemiBold'};
  font-size: ${(props) => (props.book ? '18px' : '24px')};
  font-weight: ${(props) => (props.book ? '400' : '700')};
  div {
    padding: 0 10px;
  }
`

const KpiTd = styled.td<IKpiTh>`
  color: ${(props) =>
    props.danger ? COLORS.Pomodoro : props.success ? COLORS.Expo : 'black'};
  font-family: ${(props) =>
    props.book ? 'Lexend-Regular' : 'Lexend-SemiBold'};
  font-size: ${(props) => (props.book ? '18px' : '24px')};
  font-weight: ${(props) => (props.book ? '400' : '700')};
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
`

const KpiLeftContainer = styled.div`
  width: 900px;
  float: left;
`

const PtdKpiCategoryBreakdownDiv = styled.div`
  width: 100%;
  text-align: center;
  padding: 7px 20px 5px 20px;
  border-bottom: 1px solid ${COLORS.Chalkboard};
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

const PtdKpiTable = styled.table`
  width: 100%;
  text-align: center;

  thead th {
    font-style: normal;
    min-width: 60px;
  }

  tbody td {
    font-family: Lexend-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    vertical-align: top;
    height: 60px;
    padding: 5px;
  }
`

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 20px 0;
`

const VerticalSpacer60px = styled.div`
  width: 100%;
  height: 60px;
`

const PageHeaderContainer = styled.div`
  padding: 0 0 50px 0;
  margin: 0 0 50px 0;
`

const LeftHeaderContainer = styled.div`
  float: left;
`

const PageHeaderLineContainer = styled.div`
  display: inline-block;
  width: 1200px;
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0 0;
  float: left;
`

const SelectorContainer = styled.div`
  float: right;
  display: inline-block;
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;
  font-size: 14px;
  font-weight: normal;
`

export default WebLargeMain
