import { useMutation } from '@apollo/client'
import { useState } from 'react'

import { track } from 'pared/analytics/alert'

import Main from './Main'
import {
  SET_ALERT_USER_NOTIFIED_READ,
  SET_ALERT_USER_NOTIFIED_UNREAD,
} from './gql'

interface IProps {
  date: string
  type: string
  alertMessage: string
  alertDefinitionLevel: string
  alertUserNotifiedId: number
  alertName: string
  isRead: boolean
  retrieveAlerts: () => void
  tableData: object
  locationId: number
}

const ActionItemRow = ({
  date,
  type,
  alertMessage,
  alertDefinitionLevel,
  alertName,
  isRead,
  alertUserNotifiedId,
  retrieveAlerts,
  tableData,
  locationId,
}: IProps) => {
  const [shouldShowInfoTable, setShouldShowInfoTable] = useState<boolean>(false)
  const [setAlertUserNotifiedRead, { loading, error }] = useMutation(
    SET_ALERT_USER_NOTIFIED_READ,
  )
  const [
    setAlertUserNotifiedUnread,
    {
      loading: setAlertUserNotifiedReadLoading,
      error: setAlertUserNotifiedReadError,
    },
  ] = useMutation(SET_ALERT_USER_NOTIFIED_UNREAD)

  const onSetAlertUserNotifiedRead = async () => {
    await setAlertUserNotifiedRead({
      variables: {
        alertUserNotifiedId,
      },
    })
    retrieveAlerts()
    track.alertUserNotifiedRead(alertUserNotifiedId)
  }

  const onSetAlertUserNotifiedUnread = async () => {
    await setAlertUserNotifiedUnread({
      variables: {
        alertUserNotifiedId,
      },
    })
    retrieveAlerts()
    track.alertUserNotifiedUnRead(alertUserNotifiedId)
  }

  return (
    <Main
      date={date}
      type={type}
      alertMessage={alertMessage}
      alertDefinitionLevel={alertDefinitionLevel}
      alertName={alertName}
      shouldShowInfoTable={shouldShowInfoTable}
      setShouldShowInfoTable={setShouldShowInfoTable}
      isRead={isRead}
      onSetAlertUserNotifiedRead={onSetAlertUserNotifiedRead}
      onSetAlertUserNotifiedUnread={onSetAlertUserNotifiedUnread}
      tableData={tableData}
      locationId={locationId}
    />
  )
}

export default ActionItemRow
