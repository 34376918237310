import { gql } from '@apollo/client'

// FIXME: this should be generated by apollo
export interface IGetProductHistoricalPerformanceListMenuItemCostingNodesType {
  businessYear: string
  businessQuarter: string
  businessMonth: string
  popularity: number
  portionContributionMargin: number
  price: number
}

export interface IGetProductHistoricalPerformanceListMenuItemCostingType {
  nodes: IGetProductHistoricalPerformanceListMenuItemCostingNodesType[]
}

export interface IGetProductHistoricalPerformanceListMenuItemsNodesType {
  id: string
  parentId: string | null
  value: string
  text: string
}

export interface IGetProductHistoricalPerformanceListMenuItemsType {
  nodes: IGetProductHistoricalPerformanceListMenuItemsNodesType[]
}

export interface IGetProductHistoricalPerformanceType {
  listMenuItems: IGetProductHistoricalPerformanceListMenuItemsType | null
  listMenuItemCosting: IGetProductHistoricalPerformanceListMenuItemCostingType | null
}

export interface IGetProductHistoricalPerformanceVariablesType {
  iGroupBy: string
  iStartDate: string
  iEndDate: string
  iFilter: any
  iItemCategory: any
  notSelected: boolean
}

export const getProductHistoricalPerformance = gql`
  query getProductHistoricalPerformance(
    $iGroupBy: String
    $iStartDate: Date
    $iEndDate: Date
    $iFilter: JSON
    $iItemCategory: JSON
    $notSelected: Boolean!
  ) {
    listMenuItems(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
      iSalesPercentage: 0.8
    ) {
      nodes {
        id
        parentId
        value: name
        text: name
      }
    }

    listMenuItemCosting(
      iGroupBy: $iGroupBy
      iEndDate: $iEndDate
      iFilter: $iFilter
      iItemCategory: $iItemCategory
      iSalesPercentage: 0.8
    ) @skip(if: $notSelected) {
      nodes {
        businessYear
        businessQuarter
        businessMonth
        popularity
        portionContributionMargin
        price
      }
    }
  }
`
