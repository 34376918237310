import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const jscConfig: IConfigsType = {
  jsc_bk: {
    '/:brand/corporate_development': {
      development: {
        type: 'title',
        title: 'Employee Development',
      },
      downladtrainingTrackerCsv: {
        type: 'download-csv',
        title: 'Training Tracker Approvals',
        badge: 'corporateDevelopment',
        advancedFilter: [
          {
            type: 'select',
            selectKey: 'trainingTracker',
            label: 'Filter by',
            options: [
              {
                label: 'Needs Approval',
                value: 'Needs Approval',
              },
              {
                label: 'Approved - Last 30 days',
                value: 'Approved - Last 30 days',
              },
              {
                label: 'Approved - Last 60 days',
                value: 'Approved - Last 60 days',
              },
              {
                label: 'Approved - Last 90 days',
                value: 'Approved - Last 90 days',
              },
              {
                label: 'Approved - ALL',
                value: 'Approved - ALL',
              },
            ],
          },
        ],
        api: 'jscCorporateTrainingTracker',
        fileName: 'training-tracker',
        message: 'Download All Tables as CSV',
        sortedBy: 'store',
        fields: [
          {
            key: 'store',
            title: 'Store',
          },
          {
            key: 'traineeName',
            title: 'Employee Name',
          },
          {
            key: 'traineeJobTitle',
            title: 'Current Position',
          },
          {
            key: 'assignedTracker',
            title: 'Assigned Tracker',
          },
          {
            key: 'completedDate',
            title: 'Completed',
          },
          {
            key: 'approvedDate',
            title: 'Approval Status',
          },
        ],
      },
      trainingTracker: {
        type: 'table',
        api: 'jscCorporateTrainingTracker',
        minWidth: '1000px',
        defaultSortedField: 'store',
        rowKey: 'trainingTrackerUuid',
        highlineEvenRows: true,
        pageSize: 20,
        columns: [
          {
            key: 'store',
            title: 'Store',
            sortable: true,
          },
          {
            key: 'traineeName',
            title: 'Employee Name',
            sortable: true,
          },
          {
            key: 'traineeJobTitle',
            title: 'Current Position',
            sortable: true,
          },
          {
            key: 'assignedTracker',
            title: 'Assigned Tracker',
            mutation: 'updateAssignedTracker',
            sortable: true,
          },
          {
            key: 'completedDate',
            title: 'Completed',
            sortable: true,
            align: 'center',
            format: 'M/D/YY',
          },
          {
            key: 'approvedDate',
            title: 'Approval Status',
            sortable: true,
            align: 'center',
          },
        ],
      },
      developmentalFeedback: {
        type: 'table',
        title: 'Developmental Feedback',
        api: 'jscCorporateDevelopmentFeedback',
        advancedFilter: [
          {
            type: 'select',
            selectKey: 'feedback',
            label: 'Filter by',
            api: 'jscCorporateDevelopmentFeedback',
          },
        ],
        defaultSortedField: 'name',
        pageSize: 20,
        highlineEvenRows: true,
        rowKey: 'name.employee',
        columns: [
          {
            key: 'name',
            title: 'Employee Name/Position',
            sortable: true,
          },
          {
            key: 'tenure',
            title: 'Tenure',
            sortable: true,
          },
          {
            key: 'timeInPosition',
            title: 'Time in Position',
            sortable: true,
          },
          {
            key: 'feedback',
            title: 'Feedback',
          },
        ],
      },
      downladdevelopmentPlanCsv: {
        type: 'download-csv',
        title: 'Development Plan',
        api: 'jscCorporateDevelopmentPlan',
        advancedFilter: [
          {
            type: 'select',
            selectKey: 'role',
            label: 'Filter by',
            api: 'jscCorporateDevelopmentPlan',
          },
          {
            type: 'select',
            selectKey: 'plan',
            label: 'Show',
            options: [
              {
                value: 'ALL',
                label: 'All (Last 90 days)',
              },
              {
                value: 'COMPLETED',
                label: 'Completed (Last 90 days)',
              },
              {
                value: 'OUTSTANDING',
                label: 'Outstanding',
              },
            ],
          },
        ],
        fileName: 'developmental-plan',
        message: 'Export to CSV',
        sortedBy: 'role',
        fields: [
          {
            key: 'role',
            title: 'Role',
          },
          {
            key: 'name',
            title: 'Name',
          },
          {
            key: 'actionItem',
            title: 'Action Item',
          },
          {
            key: 'dueDate',
            title: 'Due Date',
          },
          {
            key: 'isCompleted',
            title: 'Completed?',
          },
        ],
      },
      developmentPlan: {
        type: 'table',
        api: 'jscCorporateDevelopmentPlan',
        defaultSortedField: 'dueDate',
        minWidth: '1200px',
        rowKey: 'planId',
        pageSize: 20,
        columns: [
          {
            key: 'role',
            title: 'Role',
            sortable: true,
          },
          {
            key: 'name',
            title: 'Name',
            sortable: true,
          },
          {
            key: 'actionItem',
            title: 'Action Item',
          },
          {
            key: 'dueDate',
            title: 'Due Date',
            sortable: true,
          },
          {
            key: 'isCompleted',
            title: 'Completed?',
            mutation: 'updateDevelopmentPlan',
          },
          {
            key: 'delete',
            title: ' ',
          },
        ],
      },
      orgChart: {
        type: 'org-chart',
        title: 'Organizational Chart',
        api: 'jscCorporateOrgInfo',
      },
    },
    '/:brand/development': {
      groupFilter: {
        api: 'locationFilter',
        autoComplete: true,
        width: 250,
      },
      development: {
        type: 'title',
        title: 'Employee Development',
      },
      developmentalFeedback: {
        type: 'table',
        title: 'Training and Development',
        api: 'jscDevelopmentTrackerFeedback',
        advancedFilter: [
          {
            type: 'select',
            selectKey: 'feedback',
            label: 'Filter by',
            api: 'jscDevelopmentalFeedback',
          },
        ],
        defaultSortedField: 'name',
        pageSize: 20,
        rowKey: 'name.employee',
        bordered: true,
        columns: [
          {
            key: 'name',
            title: 'Employee Name/Position',
            sortable: true,
          },
          {
            key: 'Training Tracker',
            title: 'Training Tracker',
            align: 'center',
            children: [
              {
                key: 'assignedTracker',
                title: 'Assigned Tracker',
              },
              {
                key: 'completionRate',
                title: 'Completion Rate',
                align: 'center',
              },
              {
                key: 'approved',
                title: 'Approval Status',
                sortable: true,
                align: 'center',
                mutation: 'updateNeedApproved',
                color: {
                  unchecked: COLORS.Plum,
                  checked: COLORS.Basil,
                  disabled: COLORS.Mango,
                },
              },
            ],
          },
          {
            key: 'Developmental Feedback',
            title: 'Developmental Feedback',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'feedback',
                title: 'Feedback',
                align: 'center',
              },
            ],
          },
        ],
      },
      downladdevelopmentPlanCsv: {
        type: 'download-csv',
        title: 'Development Plan',
        api: 'jscDevelopmentPlan',
        advancedFilter: [
          {
            type: 'select',
            selectKey: 'role',
            label: 'Filter by',
            api: 'jscDevelopmentPlan',
          },
          {
            type: 'select',
            selectKey: 'plan',
            label: 'Show',
            options: [
              {
                value: 'ALL',
                label: 'All (Last 90 days)',
              },
              {
                value: 'COMPLETED',
                label: 'Completed (Last 90 days)',
              },
              {
                value: 'OUTSTANDING',
                label: 'Outstanding',
              },
            ],
          },
        ],
        fileName: 'developmental-plan',
        message: 'Export to CSV',
        sortedBy: 'role',
        fields: [
          {
            key: 'role',
            title: 'Role',
          },
          {
            key: 'name',
            title: 'Name',
          },
          {
            key: 'actionItem',
            title: 'Action Item',
          },
          {
            key: 'dueDate',
            title: 'Due Date',
          },
          {
            key: 'isCompleted',
            title: 'Completed?',
          },
        ],
      },
      developmentPlan: {
        type: 'table',
        api: 'jscDevelopmentPlan',
        defaultSortedField: 'dueDate',
        minWidth: '1200px',
        rowKey: 'planId',
        pageSize: 20,
        columns: [
          {
            key: 'role',
            title: 'Role',
            sortable: true,
          },
          {
            key: 'name',
            title: 'Name',
            sortable: true,
          },
          {
            key: 'actionItem',
            title: 'Action Item',
          },
          {
            key: 'dueDate',
            title: 'Due Date',
            sortable: true,
          },
          {
            key: 'isCompleted',
            title: 'Completed?',
            mutation: 'updateDevelopmentPlan',
          },
          {
            key: 'delete',
            title: ' ',
          },
        ],
      },
      orgChart: {
        type: 'org-chart',
        title: 'Organizational Chart',
        api: 'jscStoreOrgInfo',
      },
    },
  },
}

export default jscConfig
