import { gql } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'

import { apolloClient } from 'pared/reactiveVariables/apollo'

export interface IGroupedTimeInterval {
  groupId: number
  groupType: string
  groupNumber: string
  startDate: moment.Moment
  endDate: moment.Moment
  startDateStr: string
  endDateStr: string
}

export const LIST_GROUPED_TIME_INTERVALS = gql`
  query ListGroupedTimeIntervals(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
  ) {
    listGroupedTimeIntervals(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) {
      nodes {
        groupingIndex
        groupType
        startDate
        endDate
      }
    }
  }
`

const responseDateFormat = 'YYYY-MM-DD'

async function getGroupedTimeIntervals(
  startDateStr: string,
  endDateStr: string,
  groupBy: string,
) {
  const currentApolloClient = apolloClient()

  if (currentApolloClient) {
    let response: any
    try {
      response = await currentApolloClient.query({
        query: LIST_GROUPED_TIME_INTERVALS,
        variables: {
          iStartDate: startDateStr,
          iEndDate: endDateStr,
          iGroupBy: groupBy,
        },
      })
    } catch (error: any) {
      console.log(error)
    }

    const rawTimeIntervals: any[] =
      _.get(response, 'data.listGroupedTimeIntervals.nodes') || []

    const sortedTimeIntervals: IGroupedTimeInterval[] = rawTimeIntervals.map(
      (rawData: any, index: number) => {
        return {
          groupId: rawData.groupingIndex,
          groupType: rawData.groupType,
          groupNumber: index + 1,
          startDate: moment.utc(rawData.startDate, responseDateFormat, true),
          endDate: moment.utc(rawData.endDate, responseDateFormat, true),
          startDateStr: rawData.startDate,
          endDateStr: rawData.endDate,
        }
      },
    ) as any

    return sortedTimeIntervals
  }

  return [] as IGroupedTimeInterval[]
}

export default getGroupedTimeIntervals
