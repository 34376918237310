import { gql } from '@apollo/client'

export interface IGetLocationInfoNode {
  id: number
  code: string
  name: string
  brandId: number | null
  directorId: number | null
  directorEmployeeInfo: {
    id: number
    givenName: string
    familyName: string
    preferredName: string
  } | null
  gmEmployeeInfo: {
    id: number
    givenName: string
    familyName: string
    preferredName: string
  } | null
  locationGroups:
    | {
        type: string
        name: string
        id: number
      }[]
    | null
}

export interface IGetLocationInfo {
  listLocationDetails: {
    nodes: IGetLocationInfoNode[]
  }
}

export interface IGetLocationInfoVariables {
  iFilter: {
    location_ids: number[]
  }
}

export const GET_LOCATION_INFO = gql`
  query GetLocationInfo($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        brandId
        directorId
        directorEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        gmEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        locationGroups
      }
    }
  }
`
