import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { useVariables } from '../../../variables'
import { IApiType } from '../../types'

const query = gql`
  query listLocationGroupMadeFromScratch(
    $iStartDate: Date!
    $iEndDate: Date!
    $iQueryType: String!
    $iInterval: String!
    $iMinutesBetween: Int!
    $iSummary: Boolean!
    $iFilter: JSON!
  ) {
    listLocationGroupMadeFromScratch(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iQueryType: $iQueryType
      iInterval: $iInterval
      iMinutesBetween: $iMinutesBetween
      iSummary: $iSummary
      iFilter: $iFilter
    ) {
      nodes {
        daypart
        value
        total
        percentScratch
        backgroundColor
      }
    }
  }
`

export const madeFromScratchConfigs = {
  daypart: 'string',
  value: 'number',
  total: 'number',
  percentScratch: 'percent',
} as const

const useMadeFromScratch = (): IApiType => {
  const { variables } = useVariables()
  const length = variables?.items?.value?.[0].length
  const selectedItem = variables?.items?.value?.[0]?.[length - 1]
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const isCategory = `${selectedItem}`.indexOf('category') === 0
  const isSubcategory = `${selectedItem}`.indexOf('subcategory') === 0
  const iFilter = {
    // location_group_ids: groupFilter?.ids,
    metrics: ['quantity_sold'],
  }

  if (groupFilter?.id === 'Brand Breakdown') {
    iFilter.location_group_ids = groupFilter?.ids
  } else {
    iFilter.location_ids = groupFilter?.ids
  }

  if (isCategory || isSubcategory) {
    iFilter.item_category_ids = [parseInt(selectedItem.split('-')[1])]
  } else {
    iFilter.item_ids = [selectedItem]
  }

  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iQueryType: isCategory || isSubcategory ? 'CATEGORY' : 'ITEM',
      iInterval: variables?.interval?.value?.[0]?.[0] ?? 'daypart',
      iMinutesBetween: variables?.timeBetween?.value?.[0]?.[0] ?? 30,
      iSummary: false,
      iFilter,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const listLocationGroupMadeFromScratch = data
        ?.listLocationGroupMadeFromScratch.nodes as
        | {
            daypart: string
          }[]
        | undefined

      if (!listLocationGroupMadeFromScratch) return null

      return listLocationGroupMadeFromScratch.map((n) => {
        return {
          ...n,
          id: n.daypart,
          parentId: 'root',
        }
      })
    }, [groupFilter, data]),
    loading,
  }
}

export default useMadeFromScratch
