import React from 'react'

import Main from './Main'

interface IProps {
  popoverDescription: string
}

function InformationPopover({ popoverDescription }: IProps) {
  return <Main popoverDescription={popoverDescription} />
}

export default InformationPopover
