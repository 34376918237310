import * as d3 from 'd3-hierarchy'
import _ from 'lodash'
import { useMemo } from 'react'

import { IDataType as IGroupType } from '../../groupFilter'
import { ILinkPropsType, ITogglePropsType } from '../../table/Format'
import { IApiDataType } from '../../table/types'
import useApi, { IApiKeyType, configs } from './useApi'

export interface ICsvDataArgsType<K extends IApiKeyType> {
  api: K
  sortedBy?: keyof typeof configs[K] | 'groupInfo'
  fields: (
    | {
        key: keyof typeof configs[K]
        title: string
      }
    | 'groupInfo'
  )[]
}

type IGroupInfoType = NonNullable<IGroupType['list']>[number]

const mergeCsvData = <K extends IApiKeyType>(
  data: any,
  { api, sortedBy, fields }: ICsvDataArgsType<K>,
) => {
  if (!data) return []

  if (!data?.children) {
    const dataSource = data.data

    if (!dataSource) return []

    const headers = fields
      .map((field) => {
        if (field === 'groupInfo') {
          const source = dataSource.source
          if (source[0] && 'groupInfo' in source[0]) {
            const groupInfo = source[0].groupInfo as IGroupInfoType

            return [groupInfo.header, groupInfo.groupBy?.header].filter(Boolean)
          }

          return []
        }

        return field.title
      })
      .flat()

    const rows = (
      !sortedBy
        ? dataSource?.source
        : dataSource.source.sort((a, b) => {
            const aValue = a[sortedBy as keyof typeof a]
            const bValue = b[sortedBy as keyof typeof b]

            if (sortedBy === 'groupInfo')
              return (aValue as IGroupInfoType).name.localeCompare(
                (bValue as IGroupInfoType).name,
              )

            const config = configs[api][sortedBy]

            switch (config) {
              case 'string':
                return (aValue as string).localeCompare(bValue as string)
              default:
                return (aValue as number) - (bValue as number)
            }
          })
    ).map((d) =>
      fields
        .map((field) => {
          if (field === 'groupInfo') {
            if (d && 'groupInfo' in d) {
              const groupInfo = d.groupInfo as IGroupInfoType

              return [groupInfo.name, groupInfo.groupBy?.name].filter(Boolean)
            }

            return []
          }

          const value = _.get(d, field.key)
          const config = configs[api][field.key]

          if (value === null || value === undefined) return ''

          switch (config) {
            case 'price':
              return (parseFloat(value as string) / 100).toFixed(2)
            case 'percent':
              return parseFloat(value as string).toFixed(2)
            case 'link':
              return (value as ILinkPropsType).label
            case 'toggle':
              return (value as ITogglePropsType).label
            default:
              return value
          }
        })
        .flat(),
    )

    const summary = !dataSource?.summary
      ? []
      : fields
          .map((field) => {
            if (field === 'groupInfo') {
              if (!(dataSource.summary && 'groupInfo' in dataSource.summary)) {
                return []
              }

              const groupInfo = dataSource.summary.groupInfo as IGroupInfoType
              if (!('groupInfo.groupBy' in dataSource.summary)) {
                return [groupInfo.name].filter(Boolean)
              }

              return [
                groupInfo.name,
                dataSource.summary['groupInfo.groupBy']?.name,
              ].filter(Boolean)
            }

            const value = _.get(dataSource.summary, field.key)
            const config = configs[api][field.key]

            if (value === null || value === undefined) return ''

            switch (config) {
              case 'price':
                return (parseFloat(value as string) / 100).toFixed(2)
              case 'percent':
                return parseFloat(value as string).toFixed(2)
              case 'link':
                return (value as ILinkPropsType).label
              case 'toggle':
                return (value as ITogglePropsType).label
              default:
                return value
            }
          })
          .flat()

    return [headers, ...rows, summary]
  }

  return data.children?.reduce((result, child) => {
    return [...result, ...mergeCsvData(child, { api, sortedBy, fields })]
  }, [])
}

const useCsvData = <K extends IApiKeyType>(
  { api, sortedBy, fields }: ICsvDataArgsType<K>,
  data?: ReturnType<typeof useApi>['data'],
) => {
  const dataSource = useMemo(() => {
    if (!data) return null

    return d3.stratify<NonNullable<IApiDataType>[number]>()([
      {
        id: 'root' as const,
        parentId: null,
      },
      ...data,
    ])
  }, [data])

  const csvData = mergeCsvData(dataSource, { api, sortedBy, fields })

  return csvData
}

export default useCsvData
