import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'

import { IApiDataType } from '../../types'

const query = gql`
  query trendLocationIntradaySales($iDate: Date!, $iFilter: JSON!) {
    trendLocationIntradaySales(iDate: $iDate, iFilter: $iFilter) {
      nodes {
        hour
        netSales
        cumulativeSales
        netSalesBudget
        cumulativeLabor
        cumulativeLaborPercent
        burdenedLabor
        burdenedLaborPercent
        lastYearSalesPlusTenPercent
      }
    }
  }
`

export const intradaySalesConfigs = {
  hour: 'string',
  formattedHour: 'string',
  netSales: 'price',
  cumulativeSales: 'price',
  netSalesBudget: 'price',
  cumulativeLabor: 'price',
  cumulativeLaborPercent: 'percent',
  burdenedLabor: 'price',
  burdenedLaborPercent: 'percent',
  lastYearSalesPlusTenPercent: 'price',
} as const

const useIntradaySales = () => {
  const today = moment().format('YYYY-MM-DD')
  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const { data, loading } = useQuery(query, {
    variables: {
      iDate: today,
      iFilter: { location_ids: [locationId] },
    },
    skip: !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const trendLocationIntradaySales = data?.trendLocationIntradaySales.nodes
      if (!trendLocationIntradaySales) return null

      return trendLocationIntradaySales.map(
        (n: { hour: string; netSales: number }) => {
          const formattedHour = moment(n.hour).format('h:mmA')
          return {
            ...n,
            formattedHour: formattedHour,
          }
        },
      )
    }, [data]),
    loading,
  }
}

export default useIntradaySales
