import { useQuery } from '@apollo/client'

import useGetDateRange from 'pared/hooks/useGetDateRange'
import { INullableType } from 'pared/types'

import {
  IListItemsType,
  IListItemsVariableTypes,
  LIST_LOCATION_REVENUE_CENTER_AND_DAY_PART,
} from '../gqls/itemList'
import useDayPart from './useDayPart'
import useRevenueCenter from './useRevenueCenter'

const useItems = (locationGroupIds: number[]) => {
  const dateRange = useGetDateRange()
  const variables: INullableType<IListItemsVariableTypes> = {
    iStartDate: dateRange?.startDateStr,
    iEndDate: dateRange?.endDateStr,
    iFilter: {
      location_group_ids: locationGroupIds,
    },
  }
  const { data } = useQuery<
    IListItemsType,
    INullableType<IListItemsVariableTypes>
  >(LIST_LOCATION_REVENUE_CENTER_AND_DAY_PART, {
    variables,
    skip: !variables?.iFilter || !variables?.iStartDate || !variables?.iEndDate,
  })

  const { selectedDayPart, dayPartItems, dayPartChanged } = useDayPart(
    data?.listLocationDayPart.nodes || [],
  )
  const { selectedRevenueCenter, revenueCenterItems, revenueCenterChanged } =
    useRevenueCenter(data?.listLocationRevenueCenter.nodes || [])

  return {
    selectedRevenueCenter,
    selectedDayPart,
    revenueCenterItems,
    dayPartItems,
    revenueCenterChanged,
    dayPartChanged,
  }
}

export default useItems
