import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import { IReportResult } from 'pared/components/CustomizedReport'

import useConfig from './useConfig'

export interface ICategoryFilterType {
  id: string
  parentId: 'root' | null
  value: string
  text: string
}

const ALL_CATEGORIES = 'All Categories'
const KEY_ITEMS = 'Key Items'
const WEEKLY_CATEGORIES = 'Weekly Categories'
const PERIOD_CATEGORIES = 'Period Categories'

export function getCategoryFilteredReportResult(
  customizedReportResult: (IReportResult & { summaryMap?: any }) | undefined,
  selectedCategory: string,
) {
  let filteredCustomizedReportResult: any = undefined
  if (
    !selectedCategory ||
    selectedCategory === ALL_CATEGORIES ||
    !Array.isArray(customizedReportResult?.tableData)
  ) {
    const allCategorySummary = customizedReportResult?.summaryMap?.['ALL']
    filteredCustomizedReportResult = {
      ...customizedReportResult,
      summary: allCategorySummary,
    }
  } else if (selectedCategory === KEY_ITEMS) {
    filteredCustomizedReportResult = {
      ...customizedReportResult,
      tableData: (customizedReportResult?.tableData ?? []).filter(
        ({ isKeyItem }) => isKeyItem,
      ),
    }
  } else {
    const categorySummary =
      customizedReportResult?.summaryMap?.[selectedCategory]
    filteredCustomizedReportResult = {
      ...customizedReportResult,
      tableData: (customizedReportResult?.tableData ?? []).filter(
        ({ category }) => {
          // TODO: Break out to config. Currently hard-coded for ghai_pop
          if (selectedCategory === WEEKLY_CATEGORIES) {
            return [
              'COGS- Chicken',
              'COGS- Other Beverage',
              'COGS- Other Food',
              'COGS- Other Protein',
            ].includes(category)
          } else if (selectedCategory === PERIOD_CATEGORIES) {
            return [
              'COGS- Chicken',
              'COGS- Other Beverage',
              'COGS- Other Food',
              'COGS- Other Protein',
              'COGS- Beverage Syrups',
              'COGS- Paper',
            ].includes(category)
          } else {
            return category === selectedCategory
          }
        },
      ),
      summary: categorySummary,
    }
  }
  return filteredCustomizedReportResult
}

const useCategoryFilter = (customizedReportResult?: IReportResult) => {
  const config = useConfig()
  const [hasSetInitialDefault, setHasSetInitialDefault] =
    useState<boolean>(false)
  const [categoryFilter, setCategoryFilter] = useState<string[]>([])

  const categorySet = new Set<string>()
  const categoryFilters = useMemo(() => {
    const menuItemList: ICategoryFilterType[] = [
      {
        id: 'root',
        parentId: null,
        value: 'root',
        text: 'root',
      },
      ...(config.hasKeyItems
        ? [
            {
              id: KEY_ITEMS,
              parentId: 'root' as const,
              value: KEY_ITEMS,
              text: KEY_ITEMS,
            },
          ]
        : []),
      {
        id: ALL_CATEGORIES,
        parentId: 'root' as const,
        value: ALL_CATEGORIES,
        text: ALL_CATEGORIES,
      },
      ...(config.hasSummaryCategories
        ? [
            {
              id: WEEKLY_CATEGORIES,
              parentId: 'root' as const,
              value: WEEKLY_CATEGORIES,
              text: WEEKLY_CATEGORIES,
            },
            {
              id: PERIOD_CATEGORIES,
              parentId: 'root' as const,
              value: PERIOD_CATEGORIES,
              text: PERIOD_CATEGORIES,
            },
          ]
        : []),
    ]

    categorySet.clear()
    if (Array.isArray(customizedReportResult?.tableData)) {
      customizedReportResult?.tableData.forEach((rawData: any) => {
        if (rawData?.category) {
          categorySet.add(rawData.category)
        }
      })
    }

    Array.from(categorySet)
      .sort()
      .forEach((category) => {
        menuItemList.push({
          id: category,
          parentId: 'root' as const,
          value: category,
          text: category,
        })
      })

    return menuItemList
  }, [customizedReportResult])

  useEffect(() => {
    setHasSetInitialDefault(false)
  }, [customizedReportResult])

  useEffect(() => {
    if (!hasSetInitialDefault) {
      setCategoryFilter([categoryFilters[1].value])
      setHasSetInitialDefault(true)
    }
  }, [categoryFilters])

  const selectedCategory = _.get(categoryFilter, '[0]')

  const categoryFilteredCustomizedReportResult =
    getCategoryFilteredReportResult(customizedReportResult, selectedCategory)

  return {
    categoryFilter,
    setCategoryFilter,
    categoryFilters,
    categoryFilteredCustomizedReportResult,
  }
}

export default useCategoryFilter
