import { useQuery } from '@apollo/client'
import moment from 'moment'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import COLORS from 'pared/constants/colors'

import useSalesmanshipConfig from '../hooks/useSalesmanshipConfig'
import { getDateRangeLabel } from '../util'
import { TREND_LOCATION_GROUP_SPEED_OF_SERVICE } from './gql'

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350
const CHART_START_DATE = moment.utc('2022-05-15', 'YYYY-MM-DD', true)

interface IProps {
  trendChartParams: {
    iLocationGroupId: number
    iEndDate: string
    iGroupBy: string
  }
}

interface IData {
  trendLocationGroupSpeedOfService: {
    nodes: {
      startDate: string
      endDate: string
      sosDetails: {
        [windowType: string]: {
          name: string
          avgSeconds: number
        }
      }
    }[]
  }
}

interface IOrganizedData {
  dateRanges: string[]
  averageWindowTime: number[]
}

const SpeedOfServiceTrendChart = ({ trendChartParams }: IProps) => {
  const config = useSalesmanshipConfig()

  const { loading, data }: { loading: boolean; data: IData | undefined } =
    useQuery(TREND_LOCATION_GROUP_SPEED_OF_SERVICE, {
      variables: trendChartParams,
      skip: !trendChartParams.iEndDate,
    })

  if (loading) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  if (!data) {
    return (
      <PageStatusDiv>
        There is no data within the date range selected. Please select a
        different date range.
      </PageStatusDiv>
    )
  }

  const {
    trendLocationGroupSpeedOfService: { nodes },
  } = data

  let xAxisData: any[] = []
  const yAxisDataArr: any[] = []
  const yLeftAxis = config?.speedOfService?.sosChart?.yLeftAxisKpiDefs

  for (let i = 0; i < yLeftAxis.length; i++) {
    const lineDef = yLeftAxis[i]

    const organizedData = nodes.reduce(
      (acc, { startDate, sosDetails }) => {
        if (CHART_START_DATE.isBefore(startDate)) {
          const avgSeconds = sosDetails?.[lineDef.key]?.avgSeconds

          acc.dateRanges.push(
            getDateRangeLabel(startDate, trendChartParams.iGroupBy),
          )
          acc.averageWindowTime.push(avgSeconds)
        }
        return acc
      },
      {
        dateRanges: [],
        averageWindowTime: [],
      } as IOrganizedData,
    )

    xAxisData = organizedData.dateRanges

    yAxisDataArr.push({
      type: 'line',
      data: organizedData.averageWindowTime,
      tooltipLabel: lineDef.name,
      borderColor: lineDef.color,
      backgroundColor: lineDef.color,
      hidden: !!lineDef.hidden,
    })
  }

  const chartOptions = {
    title: '',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: config?.speedOfService?.sosChart?.yLeftAxisLabel,
    yLeftTickCallback: (value: number) => {
      return `${value}`
    },
  }

  return (
    <MixedChart
      xAxisData={xAxisData}
      yAxisDataArr={yAxisDataArr}
      options={chartOptions}
    />
  )
}

export default SpeedOfServiceTrendChart

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`
