import React from 'react'

import Table from '../../Table'
import { SummaryComponentProps } from '../type'
import useSummaryTableData from './hooks/useSummaryTableData'

const StoreTable = ({ data }: SummaryComponentProps) => {
  const summaryTableData = useSummaryTableData({ data })

  return <Table data={summaryTableData} />
}

export default React.memo(StoreTable)
