import { gql } from '@apollo/client'

interface ILocationDisputesNodeType {
  startDate: string
  endDate: string
  locationId: number
  totalDisputeCount: number
  totalDisputeAmount: number
  completedDisputeCount: number
  completedDisputeAmount: number
  pendingDisputeCount: number
  pendingDisputeAmount: number
  lostDisputeCount: number
  lostDisputeAmount: number
}

export interface ILocationDisputesType {
  listLocationDisputes: {
    nodes: ILocationDisputesNodeType[]
  }
  listLocationDeliveryKpi: {
    nodes:
      | {
          locationId: number
          orderWithAnyIssuePercent: number
        }[]
      | null
  }
}

export interface ILocationDisputesVariablesType {
  iStartDate: string
  iEndDate: string
  iFilter: any
}

export const LIST_LOCATION_DISPUTES = gql`
  query ListLocationDisputes(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
  ) {
    listLocationDisputes(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        startDate
        endDate
        locationId
        totalDisputeCount
        totalDisputeAmount
        completedDisputeCount
        completedDisputeAmount
        pendingDisputeCount
        pendingDisputeAmount
        lostDisputeCount
        lostDisputeAmount
      }
    }

    listLocationDeliveryKpi: listLocationDeliveryKpiV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        orderWithAnyIssuePercent
      }
    }
  }
`

interface ILocationGroupDisputesNodeType {
  startDate: string
  endDate: string
  locationGroupId: number
  locationGroupName: string
  totalDisputeCount: number
  totalDisputeAmount: number
  completedDisputeCount: number
  completedDisputeAmount: number
  pendingDisputeCount: number
  pendingDisputeAmount: number
  lostDisputeCount: number
  lostDisputeAmount: number
}

export interface ILocationGroupDisputesType {
  listLocationGroupDisputes: {
    nodes: ILocationGroupDisputesNodeType[]
  }
  listLocationGroupDeliveryKpi: {
    nodes:
      | {
          locationGroupId: number
          orderWithAnyIssuePercent: number
        }[]
      | null
  }
}

export interface ILocationGroupDisputesVariablesType {
  iStartDate: string
  iEndDate: string
  iFilter: any
}

export const LIST_LOCATION_GROUP_DISPUTES = gql`
  query ListLocationGroupDisputes(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupDisputes(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        startDate
        endDate
        locationGroupId
        locationGroupName
        totalDisputeCount
        totalDisputeAmount
        completedDisputeCount
        completedDisputeAmount
        pendingDisputeCount
        pendingDisputeAmount
        lostDisputeCount
        lostDisputeAmount
      }
    }

    listLocationGroupDeliveryKpi(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        orderWithAnyIssuePercent
      }
    }
  }
`

export interface ILocationInfoVariableType {
  iFilter: {
    location_group_ids: number[]
  }
}

interface ILocationInfoNodeType {
  id: number
  code: string
  name: string
  brandId: number
  directorId: number
  directorEmployeeInfo: {
    id: string
    givenName: string
    familyName: string
    preferredName: string
  }
  gmEmployeeInfo: {
    id: number
    givenName: string
    familyName: string
    preferredName: string
  }
}

export interface ILocationInfoType {
  listLocationDetails: {
    nodes: ILocationInfoNodeType[] | null
  }
}

export const GET_STORE_LIST = gql`
  query GetStoreListByBrand($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        brandId
        directorId
        directorEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        gmEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
      }
    }
  }
`
