import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { EE_COMPS_METRICS } from 'pared/constants'
import getDateRanges from 'pared/data/getDateRanges'

import Main from './Main'
import { LIST_LOCATION_EMPLOYEE_OPS_COMPS } from './gql'

interface ICompType {
  type: string
  count: number
}

export interface IOpsComp {
  employeeId: number
  firstName: string
  lastName: string
  totalOpsComps: number
  totalGross: number
  percentOpsCompOfGross: number
  compTypes: ICompType[]
}

interface IProps {
  navParams: INavParams
  dayPart: string
  filterType: string
  numDaysWorked?: number
}

const OpsCompsByEmployee = ({
  navParams,
  dayPart,
  filterType,
  numDaysWorked,
}: IProps) => {
  const storeId = navParams.storeId || ''
  const storeIdNum = parseInt(storeId, 10) || 0
  const dateRange = navParams.dateRange || ''

  const [listLocationEmployeeOpsComps, { loading, error, data }] = useLazyQuery(
    LIST_LOCATION_EMPLOYEE_OPS_COMPS,
  )

  const [isOpsCompsExpanded, setIsOpsCompsExpanded] = useState<boolean>(false)
  const [selectedMetrics, setSelectedMetrics] = useState<string>(
    EE_COMPS_METRICS.EE_OPS_COMPS,
  )

  useEffect(() => {
    async function fetchData() {
      const newAllDateRangeData = await getDateRanges()

      let newSelectedDateRange: IPeriod | null = null

      if (dateRange) {
        newSelectedDateRange = newAllDateRangeData.dateRangeMap[dateRange]
      }

      if (!newSelectedDateRange) {
        newSelectedDateRange = newAllDateRangeData.defaultPeriod
      }

      if (storeIdNum) {
        listLocationEmployeeOpsComps({
          variables: {
            iStartDate: _.get(newSelectedDateRange, 'startDateStr', ''),
            iEndDate: _.get(newSelectedDateRange, 'endDateStr', ''),
            iDayPart: dayPart,
            iFilter: {
              location_ids: [storeIdNum],
            },
          },
        })
      }
    }

    fetchData()
  }, [dayPart, dateRange, storeIdNum])

  let opsComps: IOpsComp[] = []

  if (
    data &&
    data.listLocationEmployeeOpsComps &&
    Array.isArray(data.listLocationEmployeeOpsComps.nodes)
  ) {
    opsComps = data.listLocationEmployeeOpsComps.nodes.map((rawData: any) => {
      let compTypes: ICompType[] = []

      if (rawData.opsCompsDetails && Array.isArray(rawData.opsCompsDetails)) {
        rawData.opsCompsDetails.forEach((detail: any) => {
          compTypes.push({
            type: detail.type,
            count: detail.count,
          })
        })
      }

      return {
        employeeId: _.get(rawData, 'employeeId') || 0,
        firstName: _.get(rawData, 'employeeInfo.preferredName') || '',
        lastName: _.get(rawData, 'employeeInfo.familyName') || '',
        totalOpsComps: _.get(rawData, 'opsComps') || 0,
        totalGross: _.get(rawData, 'totalSales') || '',
        role: _.get(rawData, 'roleInfo.name') || '',
        percentOpsCompOfGross: _.get(rawData, 'opsCompsPercent') || 0,
        compTypes,
      }
    })
  }

  let rankedOpsComps: IOpsComp[] = []

  switch (selectedMetrics) {
    case EE_COMPS_METRICS.EE_OPS_COMPS: {
      rankedOpsComps = _.sortBy(opsComps, (c) => {
        return _.get(c, 'totalOpsComps', 0) * -1
      })
      break
    }
    case EE_COMPS_METRICS.EE_GROSS_SALES: {
      rankedOpsComps = _.sortBy(opsComps, (c) => {
        return _.get(c, 'totalGross', 0) * -1
      })
      break
    }
    case EE_COMPS_METRICS.OPS_COMP_RATE: {
      rankedOpsComps = _.sortBy(opsComps, (c) => {
        return _.get(c, 'percentOpsCompOfGross', 0) * -1
      })
      break
    }
    default: {
      rankedOpsComps = _.sortBy(opsComps, (c) => {
        return _.get(c, 'totalOpsComps', 0) * -1
      })
      break
    }
  }

  return (
    <Main
      isLoading={loading}
      opsComps={opsComps}
      rankedOpsComps={rankedOpsComps}
      isOpsCompsExpanded={isOpsCompsExpanded}
      setIsOpsCompsExpanded={setIsOpsCompsExpanded}
      selectedMetrics={selectedMetrics}
      setSelectedMetrics={setSelectedMetrics}
    />
  )
}

export default OpsCompsByEmployee
