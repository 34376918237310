import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'

interface IProps {
  dayTrendsInfo: any[]
  dayTrendsCategory: string
  isOrdersHidden: boolean
}

function Main({ dayTrendsInfo, dayTrendsCategory, isOrdersHidden }: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          dayTrendsInfo={dayTrendsInfo}
          dayTrendsCategory={dayTrendsCategory}
          isOrdersHidden={isOrdersHidden}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          dayTrendsInfo={dayTrendsInfo}
          dayTrendsCategory={dayTrendsCategory}
          isOrdersHidden={isOrdersHidden}
        />
      </SmallScreen>
    </>
  )
}

export default Main
