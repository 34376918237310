import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { track } from 'pared/analytics/storeList'
import { IColumnsType } from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import { getBrand } from 'pared/utils/brand'

import { IDataSourceType } from '../types'
import { getCount, getPrice, getTotalPrice, plus } from '../utils'

const Span = styled.span<{ danger: boolean }>`
  color: ${({ danger }) => (danger ? 'red' : COLORS.Chalkboard)};
`

const LinkButton = styled(Link)`
  color: ${COLORS.Link};
`

const useColumns = (
  isBreakdownByDirectors: boolean,
  groupByType: string,
): IColumnsType<IDataSourceType>[] => {
  const brand = getBrand()
  const brandSettings = getBrandSettings()
  const businessLabels = brandSettings.labels.business

  return useMemo(
    () =>
      [
        ...((isBreakdownByDirectors
          ? []
          : [
              {
                key: 'storeCode',
                title: 'Store',
                minWidth: '150px',
                render: (_, values) => {
                  if (!('storeId' in values)) return null

                  if (values.storeId === -1) return 'TOTAL'

                  return (
                    <LinkButton
                      to={navigator.storeDetail(values.storeId)}
                      onClick={() => track.locationLinkClicked(values.storeId)}
                    >
                      {values.storeCode} - {values.storeName}
                    </LinkButton>
                  )
                },
                csvRender: (_, values) => {
                  if (!('storeId' in values)) return null
                  if (values.storeId === -1) return 'TOTAL'
                  return `${values.storeCode} - ${values.storeName}`
                },
                summary: () => 'TOTAL',
              },
            ]) as IColumnsType<IDataSourceType>[]),
        ...(groupByType !== 'store'
          ? [
              {
                key: 'locationGroupName',
                title: groupByType,
                minWidth: '150px',
                render: (value: string) => value,
                csvRender: (value: string) => value,
                sorter: true,
                summary: () => 'TOTAL',
              },
            ]
          : [
              {
                key: 'directorName',
                title: businessLabels['director'],
                minWidth: '150px',
                render: (
                  value: IDataSourceType['directorName'],
                  { directorId },
                ) =>
                  businessLabels.isDirectorClickable ? (
                    <LinkButton
                      to={navigator.employeeProfile(directorId)}
                      onClick={() =>
                        track.directorProfileLinkClicked(directorId)
                      }
                    >
                      {value}
                    </LinkButton>
                  ) : (
                    value
                  ),
                csvRender: (value: IDataSourceType['directorName']) => value,
                sorter: true,
                summary: !isBreakdownByDirectors ? null : () => 'TOTAL',
              },
            ]),
        {
          key: 'netSales',
          title: 'Net Sales',
          minWidth: '150px',
          align: 'center',
          render: getTotalPrice,
          csvRender: getTotalPrice,
          sorter: true,
          summary: (values) =>
            getTotalPrice(
              values.reduce(
                (result: string, { netSales }) => plus(result, netSales),
                '0',
              ),
            ),
        },
        {
          key: 'yoyNetSales',
          title: 'Net Sales LY',
          minWidth: '150px',
          align: 'center',
          render: getTotalPrice,
          csvRender: getTotalPrice,
          sorter: true,
          summary: (values) =>
            getTotalPrice(
              values.reduce(
                (result: string, { yoyNetSales }) => plus(result, yoyNetSales),
                '0',
              ),
            ),
        },
        {
          key: 'netSalesDiffVsLy',
          title: 'Net Sales vs LY',
          minWidth: '150px',
          render: (value: IDataSourceType['netSalesDiffVsLy']) =>
            value === 0 ? '-' : `${getTotalPrice(value)}`,
          csvRender: (value: IDataSourceType['netSalesDiffVsLy']) =>
            value === 0 ? '-' : `${getTotalPrice(value)}`,
          align: 'center',
          sorter: true,
          summary: (values) => {
            const summaryResult = values.reduce(
              (result, value) => ({
                netSales: plus(result.netSales, value.netSales),
                yoyNetSales: plus(
                  result.yoyNetSales || '0',
                  value.yoyNetSales || '0',
                ),
              }),
              { netSales: '0', yoyNetSales: '0' },
            )
            const netSales = parseInt(summaryResult.netSales, 10)
            const yoyNetSales = parseInt(summaryResult.yoyNetSales, 10)

            if (yoyNetSales === 0) return '-'

            return `${getTotalPrice(netSales - yoyNetSales)}`
          },
        },
        {
          key: 'percentVsLY',
          title: '% vs LY',
          minWidth: '150px',
          render: (value: IDataSourceType['percentVsLY']) =>
            value === 0 ? '-' : `${value.toFixed(2)}%`,
          csvRender: (value: IDataSourceType['percentVsLY']) =>
            value === 0 ? '-' : `${value.toFixed(2)}%`,
          align: 'center',
          sorter: true,
          summary: (values) => {
            const summaryResult = values.reduce(
              (result, value) => ({
                netSales: plus(result.netSales, value.netSales),
                yoyNetSales: plus(
                  result.yoyNetSales || '0',
                  value.yoyNetSales || '0',
                ),
              }),
              { netSales: '0', yoyNetSales: '0' },
            )
            const netSales = parseInt(summaryResult.netSales, 10)
            const yoyNetSales = parseInt(summaryResult.yoyNetSales, 10)

            if (yoyNetSales === 0) return '-'

            return `${((100 * (netSales - yoyNetSales)) / yoyNetSales).toFixed(
              2,
            )}%`
          },
        },
        {
          key: 'totalCheckCount',
          title: 'Customer #',
          minWidth: '150px',
          render: (value: IDataSourceType['totalCheckCount']) =>
            getCount(value),
          csvRender: (value: IDataSourceType['totalCheckCount']) =>
            getCount(value),
          align: 'center',
          sorter: true,
          summary: (values) =>
            getCount(
              values.reduce(
                (result: string, { totalCheckCount }) =>
                  plus(result, totalCheckCount),
                '0',
              ),
            ),
        },
        {
          key: 'yoyTotalCheckCount',
          title: 'Check Count LY',
          minWidth: '150px',
          render: (value: IDataSourceType['yoyTotalCheckCount']) =>
            getCount(value),
          csvRender: (value: IDataSourceType['yoyTotalCheckCount']) =>
            getCount(value),
          align: 'center',
          sorter: true,
          summary: (values) =>
            getCount(
              values.reduce(
                (result: string, { yoyTotalCheckCount }) =>
                  plus(result, yoyTotalCheckCount),
                '0',
              ),
            ),
        },
        {
          key: 'checkCountDiffVsLy',
          title: 'Check Count vs LY',
          minWidth: '170px',
          render: (value: IDataSourceType['checkCountDiffVsLy']) =>
            value === 0 ? '-' : `${getCount(value)}`,
          csvRender: (value: IDataSourceType['checkCountDiffVsLy']) =>
            value === 0 ? '-' : `${value}`,
          align: 'center',
          sorter: true,
          summary: (values) => {
            const summaryResult = values.reduce(
              (result, value) => ({
                totalCheckCount: plus(
                  result.totalCheckCount,
                  value.totalCheckCount,
                ),
                yoyTotalCheckCount: plus(
                  result.yoyTotalCheckCount || '0',
                  value.yoyTotalCheckCount || '0',
                ),
              }),
              { totalCheckCount: '0', yoyTotalCheckCount: '0' },
            )
            const totalCheckCount = parseInt(summaryResult.totalCheckCount, 10)
            const yoyTotalCheckCount = parseInt(
              summaryResult.yoyTotalCheckCount,
              10,
            )

            if (yoyTotalCheckCount === 0) return '-'

            return `${getCount(totalCheckCount - yoyTotalCheckCount)}`
          },
        },
        {
          key: 'customerPercentVsLY',
          title: 'Customer % vs LY',
          minWidth: '170px',
          render: (value: IDataSourceType['customerPercentVsLY']) =>
            value === 0 ? '-' : `${(100.0 * value).toFixed(2)}%`,
          csvRender: (value: IDataSourceType['customerPercentVsLY']) =>
            value === 0 ? '-' : `${(100.0 * value).toFixed(2)}%`,
          align: 'center',
          sorter: true,
          summary: (values) => {
            const summaryResult = values.reduce(
              (result, value) => ({
                totalCheckCount: plus(
                  result.totalCheckCount,
                  value.totalCheckCount,
                ),
                yoyTotalCheckCount: plus(
                  result.yoyTotalCheckCount || '0',
                  value.yoyTotalCheckCount || '0',
                ),
              }),
              { totalCheckCount: '0', yoyTotalCheckCount: '0' },
            )
            const totalCheckCount = parseInt(summaryResult.totalCheckCount, 10)
            const yoyTotalCheckCount = parseInt(
              summaryResult.yoyTotalCheckCount,
              10,
            )

            if (yoyTotalCheckCount === 0) return '-'

            return `${(
              (100.0 * (totalCheckCount - yoyTotalCheckCount)) /
              yoyTotalCheckCount
            ).toFixed(2)}%`
          },
        },
        {
          key: 'totalCheckAverage',
          title: 'Average Check',
          minWidth: '150px',
          align: 'center',
          render: getPrice,
          csvRender: getPrice,
          sorter: true,
          summary: (values) => {
            let totalNetSales = 0
            let totalCheckCount = 0
            values.forEach((rowData) => {
              totalNetSales += parseFloat(rowData['netSales']) || 0
              totalCheckCount += parseFloat(rowData['totalCheckCount']) || 0
            })
            if (totalCheckCount > 0) {
              return getPrice((totalNetSales * 1.0) / totalCheckCount)
            } else {
              return '-'
            }
          },
        },
        {
          key: 'credits',
          title: 'Credits',
          minWidth: '150px',
          align: 'center',
          sorter: true,
        },
        {
          key: 'totalComps',
          title: 'Comps',
          minWidth: '150px',
          align: 'center',
          render: getPrice,
          csvRender: getPrice,
          sorter: true,
          summary: (values) =>
            getTotalPrice(
              values.reduce(
                (result: string, { totalComps }) => plus(result, totalComps),
                '0',
              ),
            ),
        },
        {
          key: 'cashOverOrShort',
          title: 'Cash +/-',
          minWidth: '150px',
          align: 'center',
          render: getPrice,
          csvRender: getPrice,
          sorter: true,
          summary: (values) =>
            getTotalPrice(
              values.reduce(
                (result: string, { cashOverOrShort }) =>
                  plus(result, cashOverOrShort),
                '0',
              ),
            ),
        },
      ] as IColumnsType<IDataSourceType>[],
    [isBreakdownByDirectors, brand],
  )
}

export default useColumns
