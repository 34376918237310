import { gql } from '@apollo/client'
import _ from 'lodash'

import { BRAND_NAME } from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'
import { apolloClient } from 'pared/reactiveVariables/apollo'

export const USER_INFO_VERSION = '2022-09-06'

export interface IUser {
  version?: string
  userId?: number
  email?: string
  firstName?: string
  lastName?: string
  employeeId?: number
  employeeRoleCode?: string
  defaultLocationId?: number
  isStoreLevelUser?: boolean
  disableCorporatePages?: boolean
  canViewUserEventLogs?: boolean
  accessGroupType?: string
  isDrgSalesManager?: boolean
  isDeactivated?: boolean
}

const GET_USER_BY_EMAIL = gql`
  query getUserByEmail($userEmail: String!) {
    getUserByEmail(iUserEmail: $userEmail) {
      nodes {
        userId
        employeeId
        employeeRoleCode
        firstName
        lastName
        defaultLocationId
        isStoreLevelUser
        canViewUserEventLogs
        accessGroupType
        isDrgSalesManager
        isDeactivated
      }
    }
  }
`

async function getUserByEmail(email: string) {
  const currentApolloClient = apolloClient()
  let newUser: IUser = {}
  if (currentApolloClient) {
    let response: any
    try {
      response = await currentApolloClient.query({
        query: GET_USER_BY_EMAIL,
        variables: { userEmail: email },
      })
    } catch (error) {
      console.log(error)
    }

    const version = USER_INFO_VERSION
    const user = _.first(_.get(response, 'data.getUserByEmail.nodes', []))
    const userId = _.get(user, 'userId') || undefined
    const userFirstName = _.get(user, 'firstName') || ''
    const userLastName = _.get(user, 'lastName') || ''
    const userEmployeeId = _.get(user, 'employeeId') || undefined
    const userEmployeeRoleCode = _.get(user, 'employeeRoleCode') || ''
    const defaultLocationId = _.get(user, 'defaultLocationId') || undefined
    const isStoreLevelUser = _.get(user, 'isStoreLevelUser') || false
    const canViewUserEventLogs = _.get(user, 'canViewUserEventLogs') || false
    const accessGroupType = _.get(user, 'accessGroupType') || undefined
    const isDrgSalesManager = _.get(user, 'isDrgSalesManager') || false
    const isDeactivated = _.get(user, 'isDeactivated') || false
    const brand = window.location.pathname.split(/\//)[1]

    newUser = {
      version,
      userId,
      email,
      defaultLocationId,
      isStoreLevelUser,
      // FIXME: should get info from BE
      disableCorporatePages:
        isStoreLevelUser &&
        ['ghai_pop', 'wingitnorth', 'bibibop'].includes(brand),
      canViewUserEventLogs,
      firstName: userFirstName,
      lastName: userLastName,
      employeeId: userEmployeeId,
      employeeRoleCode: userEmployeeRoleCode,
      accessGroupType,
      isDrgSalesManager,
      isDeactivated,
    }
  }

  return newUser
}

export default getUserByEmail
