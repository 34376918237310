import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query ListEmployeeMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listEmployeeMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        endDate
        employeeId
        employeeName
        employeeRole
        metricData
        metricSummaryData
      }
    }
  }
`

export const farwestTimeClockConfigs = {
  date: 'date-string',
  employeeName: 'string',
  position: 'string',
  overtimeHours: 'number',
  overtimeWages: 'price',
} as const

const useFarwestTimeClock = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_ids: groupFilter?.ids,
        metrics: ['overtime_hours', 'overtime_wages'],
      },
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const customizedData: any = data?.listEmployeeMetricValues?.nodes

      if (!customizedData) return null

      const source = customizedData.map((employeeData: any) => {
        const kpisData: { [key: string]: number } = {
          date: endDate as any,
          employeeName: employeeData.employeeName,
          position: employeeData.employeeRole,
        }

        for (const key in employeeData.metricData) {
          if (employeeData.metricData.hasOwnProperty(key)) {
            const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
            switch (employeeData.metricData[key].unit) {
              case 'DOLLAR':
              case 'PERCENTAGE':
                kpisData[newKey] = employeeData.metricData[key].value * 100
                break

              default:
                kpisData[newKey] = employeeData.metricData[key].value
                break
            }
          }
        }

        return {
          ...kpisData,
        }
      })

      const [rawSummary] = customizedData.filter(
        (employeeData: any) => employeeData.metricSummaryData != null,
      )
      const summary: { [key: string]: number } = {
        date: endDate as any,
      }
      for (const key in rawSummary?.metricSummaryData) {
        if (rawSummary.metricSummaryData.hasOwnProperty(key)) {
          const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
          switch (rawSummary.metricSummaryData[key].unit) {
            case 'DOLLAR':
            case 'PERCENTAGE':
              summary[newKey] = rawSummary.metricSummaryData[key].value * 100
              break

            default:
              summary[newKey] = rawSummary.metricSummaryData[key].value
              break
          }
        }
      }

      return {
        summary,
        source: _.orderBy(
          source,
          ['overtimeHours', 'overtimeWages', 'employeeName'],
          ['desc', 'desc', 'asc'],
        ),
      }
    }, [groupFilter, data]),
    loading,
  }
}

export default useFarwestTimeClock
