import useTrendLocationGroupDiscount, {
  trendLocationGroupDiscountConfigs,
} from './useTrendLocationGroupDiscounts'
import useTrendLocationGroupFinancialKpis, {
  trendLocationGroupFinancialKpisConfigs,
} from './useTrendLocationGroupFinancialKpis'
import useTrendLocationGroupLossPreventions, {
  trendLocationGroupLossPreventionConfigs,
} from './useTrendLocationGroupLossPreventions'

export const configs = {
  trendLocationGroupFinancialKpis: trendLocationGroupFinancialKpisConfigs,
  trendLocationGroupDiscount: trendLocationGroupDiscountConfigs,
  trendLocationGroupLossPrevention: trendLocationGroupLossPreventionConfigs,
}

export const api = {
  trendLocationGroupFinancialKpis: useTrendLocationGroupFinancialKpis,
  trendLocationGroupDiscount: useTrendLocationGroupDiscount,
  trendLocationGroupLossPrevention: useTrendLocationGroupLossPreventions,
}
