import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Modal from '@material-ui/core/Modal'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import React, { useState } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

interface IPropsType {
  header?: React.ReactNode
  questions: {
    question: string
    answer: string
  }[]
}

const StyledHelpIcon = styled(HelpOutlineIcon)`
  margin: 0px 0px 0px 6px;
  vertical-align: middle;
  font-size: 18px;
  cursor: pointer;
`

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px 20px;
  width: 50%;
  min-width: 400px;
  max-height: 80vh;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: none;
  overflow: hidden scroll;

  .MuiAccordionSummary-content {
    font-weight: bold;
  }

  .MuiAccordionDetails-root {
    color: ${COLORS.Chalkboard};
  }
`

const Help = ({ header, questions }: IPropsType) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <StyledHelpIcon onClick={() => setOpen(true)} />

      <Modal open={open} onClose={() => setOpen(false)}>
        <Container>
          {header}

          {questions.map(({ question, answer }, index) => (
            <Accordion key={index}>
              <AccordionSummary
                id={question}
                aria-controls={answer}
                expandIcon={<ExpandMoreIcon />}
              >
                {question}
              </AccordionSummary>

              <AccordionDetails>{answer}</AccordionDetails>
            </Accordion>
          ))}
        </Container>
      </Modal>
    </>
  )
}

export default React.memo(Help) as unknown as typeof Help
