import _ from 'lodash'
import styled from 'styled-components'

import { ALERTS_DISPLAYED } from 'pared/constants'
import COLORS from 'pared/constants/colors'

import ActionItemRow from './ActionItemRow'
import InsightRow from './InsightRow'
import { IAlert } from './Main'

interface IProps {
  isLoading: boolean
  alerts: IAlert[]
  alertsDisplayed: string
  onToggleArchivedAlerts: () => void
  retrieveAlerts: () => void
}

const WebLargeMain = ({
  isLoading,
  alerts,
  alertsDisplayed,
  onToggleArchivedAlerts,
  retrieveAlerts,
}: IProps) => {
  if (isLoading) {
    return (
      <MainContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </MainContainer>
    )
  }

  let filteredAlerts: IAlert[] = []
  if (alertsDisplayed === ALERTS_DISPLAYED.UNARCHIVED_ALERTS) {
    filteredAlerts = _.filter(alerts, (a) => {
      return !_.get(a, 'isRead', false)
    })
  } else {
    filteredAlerts = _.filter(alerts, (a) => {
      return _.get(a, 'isRead', false)
    })
  }

  const emptyAlertsMessage = _.isEmpty(filteredAlerts) ? (
    <EmptyAlertsContainer>You have no alerts.</EmptyAlertsContainer>
  ) : null

  const actionItemRows = _.map(filteredAlerts, (a) => {
    const triggeredAtFromNow = _.get(a, 'triggeredAtFromNow', '')
    const alertMessage = _.get(a, 'alertMessage', '')
    const alertDefinitionLevel = _.get(a, 'alertDefinitionLevel', '')
    const alertUserNotifiedId = _.get(a, 'alertUserNotifiedId', 0)
    const alertName = _.get(a, 'alertName', '')
    const tableData = _.get(a, 'tableData', {})
    const isRead = _.get(a, 'isRead', false)
    const locationId = _.get(a, 'locationId', 1)

    return (
      <ActionItemRow
        date={triggeredAtFromNow}
        type={'warning'}
        alertMessage={alertMessage}
        alertDefinitionLevel={alertDefinitionLevel}
        alertUserNotifiedId={alertUserNotifiedId}
        alertName={alertName}
        isRead={isRead}
        retrieveAlerts={retrieveAlerts}
        tableData={tableData}
        locationId={locationId}
      />
    )
  })

  const archivedAlertText =
    alertsDisplayed === ALERTS_DISPLAYED.UNARCHIVED_ALERTS
      ? 'Archived Alerts'
      : 'Unarchived Alerts'

  const archivedAlerts = (
    <CompletedActionItemsSpan onClick={onToggleArchivedAlerts}>
      {archivedAlertText}
    </CompletedActionItemsSpan>
  )

  return (
    <MainContainer>
      <PageHeaderContainer>
        <PageHeader>Alerts</PageHeader>
      </PageHeaderContainer>
      {emptyAlertsMessage}
      <ActionItemsContainer>{actionItemRows}</ActionItemsContainer>
      {archivedAlerts}
      <VerticalSpacer50px />
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 1200px;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const EmptyAlertsContainer = styled.div`
  font-family: Lexend-Regular;
  margin-bottom: 20px;
`

const VerticalSpacer50px = styled.div`
  width: 100%;
  height: 50px;
`

const PageHeaderContainer = styled.div`
  padding: 0 0 50px 0;
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
`

const CompletedActionItemsSpan = styled.span`
  margin-top: 20px;
  float: right;
  font-family: Lexend-Regular;
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;
`

const ActionItemsContainer = styled.div`
  border-bottom: 1px solid ${COLORS.Smoke};
`

export default WebLargeMain
