export const cavaKpiDefs = {
  entreePremiumIncidents: {
    key: 'entreePremiumIncidents',
    name: 'Entree Premium Incidents',
    kpiType: 'sales',
    salesType: 'Entree Premium',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  curatedIncidents: {
    key: 'curatedIncidents',
    name: 'Curated Incidents',
    kpiType: 'sales',
    salesType: 'Curated',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  sideAttachmentRate: {
    key: 'sideAttachmentRate',
    name: 'Side Attachment Rate',
    kpiType: 'sales',
    salesType: 'Side',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  dessertAttachmentRate: {
    key: 'dessertAttachmentRate',
    name: 'Dessert Attachment Rate',
    kpiType: 'sales',
    salesType: 'Dessert',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  beverageAttachmentRate: {
    key: 'beverageAttachmentRate',
    name: 'Beverage Attachment Rate',
    kpiType: 'sales',
    salesType: 'Beverage',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
}
