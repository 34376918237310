import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import WebLargeMain from './WebLargeMain'
import { IChangePasswordParams } from './index'

interface IProps {
  isLoading: boolean
  onChangePassword: (
    changePasswordParams: IChangePasswordParams,
  ) => Promise<boolean>
  errorMessage: string
}

function Main({ isLoading, onChangePassword, errorMessage }: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          isLoading={isLoading}
          onChangePassword={onChangePassword}
          errorMessage={errorMessage}
        />
      </LargeScreen>
      <SmallScreen>
        <WebLargeMain
          isLoading={isLoading}
          onChangePassword={onChangePassword}
          errorMessage={errorMessage}
        />
      </SmallScreen>
    </>
  )
}

export default Main
