import { IOrgChartNode } from 'pared/components/OrgChart'

import useJscCorporateOrgInfo from './useJscCorporateOrgInfo'
import useJscStoreOrgInfo from './useJscStoreOrgInfo'

export type IApiKeyType = keyof typeof api

const api = {
  jscCorporateOrgInfo: useJscCorporateOrgInfo,
  jscStoreOrgInfo: useJscStoreOrgInfo,
}

const useApi = (
  apiName: IApiKeyType,
): { data: IOrgChartNode[] | null; loading: boolean } => api[apiName]()

export default useApi
