import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import { toPercentString } from 'pared/utils/number'

import { STORES_CUSTOMIZED } from '../gql'
import {
  IFormattedDoBreakdownInfo,
  IFormattedLocationGroupBreakdownInfo,
  IFormattedStoreInfo,
} from '../index'

export type IDataSourceType =
  | IFormattedDoBreakdownInfo
  | IFormattedLocationGroupBreakdownInfo
  | IFormattedStoreInfo

const Percent = styled.span<{ danger: boolean }>`
  color: ${({ danger }) => (danger ? 'red' : COLORS.Chalkboard)};
`

const buildExtendColumns = (columns: any[]) =>
  columns.map(({ key, dataType, children, ...c }): any => ({
    ...c,
    key,
    children: !children ? null : buildExtendColumns(children),
    render: (value: any) => {
      switch (dataType) {
        case 'PERCENT': {
          if (value || value === 0)
            return Math.abs(value) >= 100
              ? toPercentString(value, 0)
              : toPercentString(value, 1)

          return '-'
        }

        case 'DIFF_PERCENT': {
          if (value || value === 0)
            return (
              <Percent danger={value > 0}>
                {Math.abs(value) >= 100
                  ? toPercentString(value, 0)
                  : toPercentString(value, 1)}
              </Percent>
            )

          return '-'
        }

        case 'DIFF_PERCENT_NEGATIVE': {
          if (value || value === 0)
            return (
              <Percent danger={value < 0}>
                {Math.abs(value) >= 100
                  ? toPercentString(value, 0)
                  : toPercentString(value, 1)}
              </Percent>
            )

          return '-'
        }

        default:
          if ([null, undefined].includes(value)) return '-'

          return value
      }
    },
  }))

const useStoresDataSource = (
  data: IDataSourceType[],
  isBreakdownByDirectors: boolean,
  isBreakdownByLocationGroupType: boolean,
  locationGroupType: string | null,
  selectedLocationGroupIds: number[],
) => {
  const brandSettings = getBrandSettings()
  const dateRange = useGetDateRange()
  const { data: storesCustomized } = useQuery(STORES_CUSTOMIZED, {
    variables: {
      iCustomizedReportName:
        isBreakdownByDirectors || isBreakdownByLocationGroupType
          ? 'LIST_LOCATION_GROUP_STORES_CUSTOMIZED_EXTEND_TABLE'
          : 'LIST_LOCATION_STORES_CUSTOMIZED_EXTEND_TABLE',
      iInputParams: {
        ...(locationGroupType
          ? { locationGroupTypes: [locationGroupType] }
          : { locationGroupIds: selectedLocationGroupIds }),
        startDate: dateRange?.startDateStr,
        endDate: dateRange?.endDateStr,
      },
    },
    skip:
      !dateRange ||
      !brandSettings.overview.customize ||
      selectedLocationGroupIds.length === 0,
  })
  const customizedData = storesCustomized?.getCustomizedReport.nodes[0]
  const dataSource = useMemo(() => {
    return data.map((d) => {
      const extendData = customizedData?.reportResult.tableData?.find(
        ({ locationId, locationGroupId }: any) => {
          if ('storeId' in d) return d.storeId === locationId

          if ('locationGroupId' in d)
            return d.locationGroupId === locationGroupId

          return false
        },
      )

      return { ...d, ...extendData }
    })
  }, [data, customizedData])

  const extendColumns = useMemo(
    () => buildExtendColumns(customizedData?.reportDefinition.columns || []),
    [customizedData],
  )

  return {
    extendColumns,
    dataSource,
  }
}

export default useStoresDataSource
