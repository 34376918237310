import { gql } from '@apollo/client'

export const SET_ALERT_USER_NOTIFIED_READ = gql`
  mutation SetAlertUserNotifiedRead($alertUserNotifiedId: Int) {
    setAlertUserNotifiedRead(
      input: { alertUserNotifiedId: $alertUserNotifiedId }
    ) {
      clientMutationId
    }
  }
`

export const SET_ALERT_USER_NOTIFIED_UNREAD = gql`
  mutation SetAlertUserNotifiedUnread($alertUserNotifiedId: Int) {
    setAlertUserNotifiedUnread(
      input: { alertUserNotifiedId: $alertUserNotifiedId }
    ) {
      clientMutationId
    }
  }
`
