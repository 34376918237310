export function calculateVariance(num1: number, num2: number) {
  return num1 - num2
}

export function calculateVariancePercent(num1: number, num2: number) {
  if (num2 != 0) {
    return ((num1 - num2) * 100.0) / num2
  } else {
    return 0
  }
}

export function calculatePercent(num1: number, num2: number) {
  if (num2 != 0) {
    return (num1 * 100.0) / num2
  } else {
    return 0
  }
}

export const getFloat = (value: number | string) =>
  typeof value === 'string' ? parseFloat(value) || 0 : value

export const getNumber = (value: number | string) =>
  typeof value === 'string' ? parseInt(value, 10) : value

export function getPercent(value: number | string | undefined) {
  if (value === undefined) {
    return ''
  }
  const num = getFloat(value)
  if (num < 0) {
    return `(${Math.abs(num).toFixed(2)}%)`
  } else if (num > 0) {
    return `${num.toFixed(2)}%`
  }
  return '-'
}

const formatPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const getPrice = (value: number | string) =>
  formatPrice.format(getNumber(value) / 100)

export const getTotalPrice = (value: number | string | undefined) => {
  if (value === undefined) {
    return ''
  }
  if (!value) {
    return '-'
  }
  const dollarAmount = Math.round(getNumber(value) / 100)
  let formattedDollarAmount = formatPrice
    .format(Math.abs(dollarAmount))
    .replace(/\..+/, '')

  if (dollarAmount < 0) {
    formattedDollarAmount = `(${formattedDollarAmount})`
  }

  return formattedDollarAmount
}

export const numberPlus = (
  [aValue, ...a]: string[],
  [bValue, ...b]: string[],
  extra = 0,
): string[] => {
  if (!aValue && !bValue) {
    if (extra !== 0) return [extra.toString()]

    return []
  }

  const result =
    parseInt(aValue || '0', 10) + parseInt(bValue || '0', 10) + extra
  const value = result % 10

  return [value.toString(), ...numberPlus(a, b, (result - value) / 10)]
}

export const plus = (a: string, b: string) => {
  a = !a ? '0' : a
  b = !b ? '0' : b

  return numberPlus(a.split('').reverse(), b.split('').reverse())
    .reverse()
    .join('')
}
