import { useMemo } from 'react'

import { IApiDataType } from '../types'
import useCorporateGroupFilter from './useCorporateFilter'

const useFwWingstopCorporateFilter = () => {
  const { data, loading } = useCorporateGroupFilter()

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const { defaultValue, values } = data
      const coreOrNonCore = values.find(
        (v) => v.id === 'Core or Non-Core Breakdown',
      )

      return {
        defaultValue,
        values: values
          .map((v) => {
            if (
              v.parentId !== 'Market' ||
              /Breakdown By/.test(v.label) ||
              !coreOrNonCore
            )
              return v

            return [
              { id: `${v.id} root`, parentId: v.parentId, label: v.label },
              { ...v, parentId: `${v.id} root`, label: 'All Stores' },
              {
                ...coreOrNonCore,
                id: `${v.id} ${coreOrNonCore?.id}`,
                parentId: `${v.id} root`,
                intersectedIds: 'ids' in v ? v.ids : undefined,
              },
              {
                ...v,
                id: `${v.id} Core`,
                parentId: `${v.id} root`,
                label: 'Core',
                intersectedIds: [89],
              },
              {
                ...v,
                id: `${v.id} Non-Core`,
                parentId: `${v.id} root`,
                label: 'Non-Core',
                intersectedIds: [90],
              },
            ]
          })
          .flat(),
      }
    }, [data]),
    loading,
  }
}

export default useFwWingstopCorporateFilter
