import COLORS from 'pared/constants/colors'

export const wingstopKpiDefs = {
  totalChecks: {
    key: 'checkCount',
    name: 'Total Checks',
    kpiType: 'sales',
    salesType: 'total',
    kpiKey: 'checkCount',
    valueType: 'count',
  },
  totalSales: {
    key: 'totalSales',
    name: 'Total Sales',
    kpiType: 'sales',
    salesType: 'total',
    kpiKey: 'totalSales',
    valueType: 'cent',
    digitNum: 2,
  },
  friesCheckAvg: {
    key: 'friesCheckAvg',
    name: 'Fries Check Avg',
    kpiType: 'sales',
    salesType: 'fries',
    kpiKey: 'checkAvg',
    valueType: 'cent',
    digitNum: 2,
    color: COLORS.Pomodoro,
  },
  cornCheckAvg: {
    key: 'cornCheckAvg',
    name: 'Corn Check Avg',
    kpiType: 'sales',
    salesType: 'corn',
    kpiKey: 'checkAvg',
    valueType: 'cent',
    digitNum: 2,
    color: COLORS.Mango,
  },
  browniesCheckAvg: {
    key: 'browniesCheckAvg',
    name: 'Brownies Check Avg',
    kpiType: 'sales',
    salesType: 'brownies',
    kpiKey: 'checkAvg',
    valueType: 'cent',
    digitNum: 2,
    color: COLORS.Basil,
  },
  // For rankings
  totalSidesSales: {
    key: 'totalSidesSales',
    name: 'Total Sides Sales',
    salesType: 'SIDES',
    kpiKey: 'totalSales',
  },
  friesSales: {
    key: 'friesSales',
    name: 'Fries Sales',
    salesType: 'fries',
    kpiKey: 'totalSales',
  },
  cornSales: {
    key: 'cornSales',
    name: 'Corn Sales',
    salesType: 'corn',
    kpiKey: 'totalSales',
  },
  browniesSales: {
    key: 'brownieSales',
    name: 'Brownie Sales',
    salesType: 'brownies',
    kpiKey: 'totalSales',
  },
}
