import * as _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import DownArrow from 'pared/images/basic/arrow/caret-down.svg'
import UpArrow from 'pared/images/basic/arrow/caret-up.svg'

import { IOrderBy } from './index'

interface IPropsType {
  dataSource: {}[]
  fieldName: string
  orderBy: IOrderBy
  handleOrdering: (e: any) => void
}

interface IRankTablTh {
  textAlign?: string
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
  isAvg?: boolean
}

const RankTable = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`

const RankTableTh = styled.th<IRankTablTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankTableSortableTh = styled.th<IRankTablTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  color: ${COLORS.Plum};
  cursor: pointer;
`

const AvgTr = styled.tr`
  background-color: ${COLORS.Sky} !important;
`

const RankTableTd = styled.td<IRankTableTd>`
  color: ${(props) => (props.isAvg ? 'white' : 'black')};
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankNumberTd = styled(RankTableTd)`
  padding-left: 12px;
`

const UpArrowImg = styled.img`
  width: 10px;
  margin: 0 0 2px 5px;
`

const DownArrowImg = styled.img`
  width: 10px;
  margin: 0 0 -1px 5px;
`

const Table = ({
  dataSource,
  fieldName,
  orderBy,
  handleOrdering,
}: IPropsType) => {
  let orderStatus = null
  if (orderBy.isAscending) {
    orderStatus = <UpArrowImg src={UpArrow} alt="ASC"></UpArrowImg>
  } else {
    orderStatus = <DownArrowImg src={DownArrow} alt="DESC"></DownArrowImg>
  }

  return (
    <RankTable>
      <thead>
        <tr>
          <RankTableTh textAlign={'left'}>Rank</RankTableTh>
          <RankTableTh textAlign={'left'}>Name</RankTableTh>
          <RankTableSortableTh
            textAlign={'left'}
            onClick={handleOrdering}
            data-value={'roleName'}
          >
            <span>Position</span>
            {orderBy.columnName === 'roleName' ? orderStatus : null}
          </RankTableSortableTh>
          <RankTableSortableTh onClick={handleOrdering} data-value={'value'}>
            <span>{fieldName}</span>
            {orderBy.columnName === 'value' ? orderStatus : null}
          </RankTableSortableTh>
          <RankTableSortableTh
            onClick={handleOrdering}
            data-value={'daysWorked'}
          >
            <span>Shifts</span>
            {orderBy.columnName === 'daysWorked' ? orderStatus : null}
          </RankTableSortableTh>
        </tr>
      </thead>

      <tbody>
        {dataSource.map((d) => {
          const rank = _.get(d, 'rank') || ''
          const name = _.get(d, 'name') || ''
          const roleName = _.get(d, 'roleName') || ''
          const daysWorked = _.get(d, 'daysWorked') || ''
          const isStoreAvg = _.get(d, 'isStoreAvg')
          const displayString = _.get(d, 'displayString') || ''

          return isStoreAvg ? (
            <AvgTr>
              <RankNumberTd textAlign={'left'} bold></RankNumberTd>
              <RankTableTd textAlign={'left'} isAvg>
                {name}
              </RankTableTd>
              <RankTableTd textAlign={'left'} isAvg></RankTableTd>
              <RankTableTd isAvg>{displayString}</RankTableTd>
              <RankTableTd isAvg>{daysWorked}</RankTableTd>
            </AvgTr>
          ) : (
            <tr>
              <RankNumberTd textAlign={'left'} bold>
                {rank}
              </RankNumberTd>
              <RankTableTd textAlign={'left'}>{name}</RankTableTd>
              <RankTableTd textAlign={'left'}>{roleName}</RankTableTd>
              <RankTableTd>{displayString}</RankTableTd>
              <RankTableTd>{daysWorked}</RankTableTd>
            </tr>
          )
        })}
      </tbody>
    </RankTable>
  )
}

export default React.memo(Table)
