import { gql, useQuery } from '@apollo/client'
import * as _ from 'lodash'
import { useMemo } from 'react'

import { toHourStr, toPercentString } from 'pared/utils/number'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query ListAcePopWeeklyFlashKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        metricData
        metricSummaryData
      }
    }

    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        metricData
        metricSummaryData
      }
    }
  }
`

export const acePopWeeklyFlashConfigs = {
  netSales: 'price',
  yoyNetSales: 'price',
  yoyNetSalesPercentage: 'colored-text',
  foodCogsPercentage: 'percent',
  hourlyLaborCostPercentage: 'colored-text',
  totalComplaintCount: 'colored-text',
  transactions: 'number',
  acrScore: 'colored-text',
  otHours: 'colored-text',
  rev: 'number',
  trailing4WeeksAcrScore: 'colored-text',
  foodAmount: 'price',
  foodAmountPercentage: 'percent',
  paperAmount: 'price',
  paperAmountPercentage: 'colored-text',
  suppliesAmount: 'price',
  suppliesAmountPercentage: 'colored-text',
  drinksAmount: 'price',
  drinksAmountPercentage: 'colored-text',
  miscAmount: 'price',
  miscAmountPercentage: 'percent',
  totalPurchaseAmount: 'price',
  totalPurchaseAmountPercentage: 'percent',
  paperAmountPercentageTw: 'colored-text',
  lastWeekPaperAmount: 'price',
  lastWeekPaperAmountPercentage: 'percent',
  paperAmountLastWeekVariance: 'percent',
} as const

const KPIS = [
  'net_sales',
  'yoy_net_sales',
  'yoy_net_sales_percentage',
  'food_cogs_percentage',
  'hourly_labor_cost_percentage',
  'total_complaint_count',
  'transactions',
  'acr_score',
  'ot_hours',
  'rev',
  'trailing_4_weeks_acr_score',
  'food_amount',
  'food_amount_percentage',
  'paper_amount',
  'paper_amount_percentage',
  'supplies_amount',
  'supplies_amount_percentage',
  'drinks_amount',
  'drinks_amount_percentage',
  'misc_amount',
  'misc_amount_percentage',
  'total_purchase_amount',
  'total_purchase_amount_percentage',
  'last_week_paper_amount_percentage',
  'paper_amount_last_week_variance',
]

const useAcePopWeeklyFlash = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: hasGroupBy
          ? groupFilter?.ids
          : groupFilter?.list?.map((g) => g.id),
        metrics: KPIS,
      },
      hasGroupBy,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const customizedData: any =
        data?.[
          hasGroupBy
            ? 'listLocationMetricValues'
            : 'listLocationGroupMetricValues'
        ]?.nodes

      if (!customizedData) return null

      const source = customizedData.map((locationData: any) => {
        const groupInfo = groupFilter?.list?.find(
          (l) =>
            l.id ===
            (hasGroupBy
              ? locationData.locationId
              : locationData.locationGroupId),
        )

        const kpisData: {
          [key: string]:
            | number
            | null
            | {
                text: string
                textColor: string
                type?: string
                value?: number
              }
        } = {}

        for (const key in locationData.metricData) {
          if (locationData.metricData.hasOwnProperty(key)) {
            const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())

            let statue = ''
            switch (locationData.metricData[key].unit) {
              case 'DOLLAR':
              case 'PERCENTAGE':
                kpisData[newKey] = locationData.metricData[key].value
                  ? locationData.metricData[key].value * 100
                  : null
                break

              default:
                kpisData[newKey] = locationData.metricData[key].value
                break
            }

            switch (key) {
              case 'yoy_net_sales_percentage':
                kpisData[newKey] = {
                  text: locationData.metricData[key].value
                    ? toPercentString(locationData.metricData[key].value * 100)
                    : '-',
                  textColor:
                    locationData.metricData[key].value >= 0
                      ? 'green'
                      : 'danger',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'hourly_labor_cost_percentage':
                kpisData[newKey] = {
                  text: toPercentString(
                    locationData.metricData[key].value * 100 || '-',
                  ),
                  textColor:
                    locationData.metricData[key].value > 0.21
                      ? 'danger'
                      : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'total_complaint_count':
                kpisData[newKey] = {
                  text: !_.isNil(locationData.metricData[key].value)
                    ? locationData.metricData[key].value
                    : '-',
                  textColor:
                    locationData.metricData[key].value > 2 ? 'danger' : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'paper_amount_percentage':
                kpisData[newKey] = {
                  text: toPercentString(
                    locationData.metricData[key].value * 100 || '-',
                  ),
                  textColor:
                    locationData.metricData[key].value > 0.0225
                      ? 'danger'
                      : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                kpisData.paperAmountPercentageTw = {
                  text: toPercentString(
                    locationData.metricData[key].value * 100 || '-',
                  ),
                  textColor: 'black',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'supplies_amount_percentage':
                kpisData[newKey] = {
                  text: toPercentString(
                    locationData.metricData[key].value * 100 || '-',
                  ),
                  textColor:
                    locationData.metricData[key].value > 0.0075
                      ? 'danger'
                      : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'drinks_amount_percentage':
                kpisData[newKey] = {
                  text: toPercentString(
                    locationData.metricData[key].value * 100 || '-',
                  ),
                  textColor:
                    locationData.metricData[key].value > 0.02
                      ? 'danger'
                      : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'acr_score':
                statue = 'warning'
                if (locationData.metricData[key].value > 11.5) statue = 'danger'
                if (locationData.metricData[key].value <= 9) statue = 'green'

                kpisData[newKey] = {
                  text: !_.isNil(locationData.metricData[key].value)
                    ? locationData.metricData[key].value.toFixed(2)
                    : '-',
                  textColor: statue,
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'trailing_4_weeks_acr_score':
                statue = 'warning'
                if (locationData.metricData[key].value > 11.5) statue = 'danger'
                if (locationData.metricData[key].value <= 9) statue = 'green'

                kpisData[newKey] = {
                  text: !_.isNil(locationData.metricData[key].value)
                    ? locationData.metricData[key].value.toFixed(2)
                    : '-',
                  textColor: statue,
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'ot_hours':
                kpisData[newKey] = {
                  text: !_.isNil(locationData.metricData[key].value)
                    ? locationData.metricData[key].value.toFixed(2)
                    : '-',
                  textColor:
                    locationData.metricData[key].value > 10
                      ? 'danger'
                      : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              default:
                break
            }
          }
        }

        return {
          ...kpisData,
          groupInfo,
        }
      })

      const [rawSummary] = customizedData.filter(
        (locationData: any) => locationData.metricSummaryData != null,
      )
      const summary: {
        [key: string]:
          | number
          | {
              text: string
              textColor: string
              type?: string
              value?: number
            }
      } = {}

      for (const key in rawSummary?.metricSummaryData) {
        if (rawSummary.metricSummaryData.hasOwnProperty(key)) {
          const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
          switch (rawSummary.metricSummaryData[key].unit) {
            case 'DOLLAR':
            case 'PERCENTAGE':
              summary[newKey] = rawSummary.metricSummaryData[key].value * 100
              break

            default:
              summary[newKey] = rawSummary.metricSummaryData[key].value
              break
          }

          switch (key) {
            case 'yoy_net_sales_percentage':
              summary[newKey] = {
                text: rawSummary.metricData[key].value
                  ? toPercentString(rawSummary.metricData[key].value * 100)
                  : '-',
                textColor: 'black',
              }
              break

            case 'hourly_labor_cost_percentage':
              summary[newKey] = {
                text: toPercentString(
                  rawSummary.metricSummaryData[key].value * 100 || '-',
                ),
                textColor:
                  rawSummary.metricSummaryData[key].value > 0.21
                    ? 'danger'
                    : 'black',
              }
              break

            case 'total_complaint_count':
              summary[newKey] = {
                text: !_.isNil(rawSummary.metricSummaryData[key].value)
                  ? rawSummary.metricSummaryData[key].value
                  : '-',
                textColor: 'black',
              }
              break

            case 'paper_amount_percentage':
              summary[newKey] = {
                text: toPercentString(
                  rawSummary.metricSummaryData[key].value * 100 || '-',
                ),
                textColor:
                  rawSummary.metricSummaryData[key].value > 0.0225
                    ? 'danger'
                    : 'black',
              }
              summary.paperAmountPercentageTw = {
                text: toPercentString(
                  rawSummary.metricSummaryData[key].value * 100 || '-',
                ),
                textColor: 'black',
              }
              break

            case 'supplies_amount_percentage':
              summary[newKey] = {
                text: toPercentString(
                  rawSummary.metricSummaryData[key].value * 100 || '-',
                ),
                textColor:
                  rawSummary.metricSummaryData[key].value > 0.0075
                    ? 'danger'
                    : 'black',
                type: 'number',
                value: rawSummary.metricSummaryData[key].value,
              }
              break

            case 'drinks_amount_percentage':
              summary[newKey] = {
                text: toPercentString(
                  rawSummary.metricSummaryData[key].value * 100 || '-',
                ),
                textColor:
                  rawSummary.metricSummaryData[key].value > 0.02
                    ? 'danger'
                    : 'black',
                type: 'number',
                value: rawSummary.metricSummaryData[key].value,
              }
              break

            case 'acr_score':
              summary[newKey] = {
                text: rawSummary.metricSummaryData[key].value
                  ? rawSummary.metricSummaryData[key].value.toFixed(2)
                  : '-',
                textColor: 'black',
              }
              break

            case 'trailing_4_weeks_acr_score':
              summary[newKey] = {
                text: rawSummary.metricSummaryData[key].value
                  ? rawSummary.metricSummaryData[key].value.toFixed(2)
                  : '-',
                textColor: 'black',
              }
              break

            case 'ot_hours':
              summary[newKey] = {
                text: rawSummary.metricSummaryData[key].value,
                textColor: 'black',
              }
              break

            default:
              break
          }
        }
      }

      return {
        source,
        summary: {
          ...summary,
        },
      }
    }, [groupFilter, data]),
    loading,
  }
}

export default useAcePopWeeklyFlash
