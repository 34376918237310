import { useMemo } from 'react'

interface IGuestConfigType {
  summary?: {
    reviewSource: 'acr'
  }
  employeeTable: {
    isVisible: boolean
  }
  acr: {
    isVisible: boolean
    hideSurveyCount?: boolean
  }
  momentfeed: {
    isVisible: boolean
  }
  yext?: {
    isVisible: boolean
  }
  yelp: {
    isVisible: boolean
  }
  facebook: {
    isVisible: boolean
  }
  delivery: {
    isVisible: boolean
  }
  allRating: {
    isVisible: boolean
    displayName?: string
  }
  opsCompsBreakdown: {
    title: string
    subtitle: string
    isVisible: boolean
  }
  isUpdatedWeekly: boolean
  smg?: {
    isVisible: boolean
  }
  smgMwb?: {
    isVisible: boolean
  }
  osatDisplayString?: string
  opentable?: {
    isVisible: boolean
  }
  kpiDisplayName?: string
  google?: {
    isHidden: boolean
  }
  isChartHidden?: boolean
  isRankingHidden?: boolean
  smgMod?: {
    isVisible: boolean
  }
  decimal: number
}

const defaultConfig = {
  employeeTable: {
    isVisible: true,
  },
  acr: {
    isVisible: false,
  },
  momentfeed: {
    isVisible: false,
  },
  yelp: {
    isVisible: true,
  },
  facebook: {
    isVisible: false,
  },
  delivery: {
    isVisible: true,
  },
  allRating: {
    isVisible: false,
  },
  opsCompsBreakdown: {
    title: 'Ops Comps Breakdown',
    subtitle: 'By Reason',
    isVisible: true,
  },
  isUpdatedWeekly: true,
  decimal: 1,
}

const useGuestConfig = (brand: string): IGuestConfigType =>
  useMemo(() => {
    switch (brand) {
      case 'rmg': // Romano's Macaroni Grill
      case 'rmg_nso': // RMG NSO
      case 'sullivans': // Sullivan's
      case 'sullivans_nso': // Sullivan's NSO
      case 'eddiemerlots': // Eddie Merlot's
      case 'eddiemerlots_nso':
      case 'qsr_shack': // QSR Demo
        return {
          employeeTable: {
            isVisible: true,
          },
          acr: {
            isVisible: false,
          },
          momentfeed: {
            isVisible: true,
          },
          yelp: {
            isVisible: true,
          },
          facebook: {
            isVisible: true,
          },
          delivery: {
            isVisible: true,
          },
          allRating: {
            isVisible: false,
          },
          opentable: {
            isVisible: true,
          },
          opsCompsBreakdown: {
            title: 'Ops Comps Breakdown',
            subtitle: 'By Reason',
            isVisible: true,
          },
          isUpdatedWeekly: true,
          kpiDisplayName: 'Social',
          decimal: 1,
        }

      case 'papajohns':
        return {
          employeeTable: {
            isVisible: false,
          },
          acr: {
            isVisible: false,
          },
          momentfeed: {
            isVisible: false,
          },
          yelp: {
            isVisible: true,
          },
          facebook: {
            isVisible: false,
          },
          delivery: {
            isVisible: true,
          },
          allRating: {
            isVisible: false,
          },
          opsCompsBreakdown: {
            title: 'Coupon Breakdown',
            subtitle: 'By Type',
            isVisible: true,
          },
          isUpdatedWeekly: true,
          decimal: 1,
        }

      case 'irmg_bk':
      case 'supreme_bk':
      case 'timoney_bk':
      case 'mbn_bk':
        return {
          employeeTable: {
            isVisible: false,
          },
          acr: {
            isVisible: true,
          },
          momentfeed: {
            isVisible: false,
          },
          yelp: {
            isVisible: true,
          },
          facebook: {
            isVisible: false,
          },
          delivery: {
            isVisible: true,
          },
          allRating: {
            isVisible: false,
          },
          opsCompsBreakdown: {
            title: 'Ops Comps Breakdown',
            subtitle: 'By Reason',
            isVisible: false,
          },
          isUpdatedWeekly: true,
          summary: {
            reviewSource: 'acr',
          },
          decimal: 1,
        }

      case 'viking_bk':
      case 'sandbox_bk':
      case 'irmg_pop':
      case 'demo_pop':
        return {
          employeeTable: {
            isVisible: false,
          },
          acr: {
            isVisible: false,
          },
          momentfeed: {
            isVisible: false,
          },
          yelp: {
            isVisible: true,
          },
          facebook: {
            isVisible: false,
          },
          delivery: {
            isVisible: true,
          },
          allRating: {
            isVisible: false,
          },
          opsCompsBreakdown: {
            title: 'Ops Comps Breakdown',
            subtitle: 'By Reason',
            isVisible: false,
          },
          isUpdatedWeekly: true,
          decimal: 1,
        }
      case 'ghai_pop':
        return {
          employeeTable: {
            isVisible: false,
          },
          acr: {
            isVisible: true,
            hideSurveyCount: true,
          },
          momentfeed: {
            isVisible: false,
          },
          yelp: {
            isVisible: true,
          },
          facebook: {
            isVisible: false,
          },
          delivery: {
            isVisible: true,
          },
          allRating: {
            isVisible: false,
          },
          opsCompsBreakdown: {
            title: 'Ops Comps Breakdown',
            subtitle: 'By Reason',
            isVisible: false,
          },
          isUpdatedWeekly: false,
          summary: {
            reviewSource: 'acr',
          },
          decimal: 1,
        }

      case 'supreme_pop':
      case 'ace_pop':
        return {
          employeeTable: {
            isVisible: false,
          },
          acr: {
            isVisible: true,
            hideSurveyCount: true,
          },
          momentfeed: {
            isVisible: false,
          },
          yelp: {
            isVisible: true,
          },
          facebook: {
            isVisible: false,
          },
          delivery: {
            isVisible: false,
          },
          allRating: {
            isVisible: false,
          },
          opsCompsBreakdown: {
            title: 'Ops Comps Breakdown',
            subtitle: 'By Reason',
            isVisible: false,
          },
          isUpdatedWeekly: false,
          summary: {
            reviewSource: 'acr',
          },
          decimal: 1,
        }

      case 'jsc_bk':
      case 'jsc_bk_nso':
        return {
          employeeTable: {
            isVisible: false,
          },
          acr: {
            isVisible: true,
          },
          momentfeed: {
            isVisible: false,
          },
          yelp: {
            isVisible: true,
          },
          facebook: {
            isVisible: false,
          },
          delivery: {
            isVisible: true,
          },
          allRating: {
            isVisible: false,
          },
          opsCompsBreakdown: {
            title: 'Ops Comps Breakdown',
            subtitle: 'By Reason',
            isVisible: false,
          },
          isUpdatedWeekly: false,
          summary: {
            reviewSource: 'acr',
          },
          decimal: 1,
        }

      case 'demo_bk':
        return {
          summary: {
            reviewSource: 'acr',
          },
          employeeTable: {
            isVisible: false,
          },
          acr: {
            isVisible: true,
          },
          momentfeed: {
            isVisible: false,
          },
          yelp: {
            isVisible: true,
          },
          facebook: {
            isVisible: false,
          },
          delivery: {
            isVisible: true,
          },
          allRating: {
            isVisible: false,
          },
          opsCompsBreakdown: {
            title: 'Ops Comps Breakdown',
            subtitle: 'By Reason',
            isVisible: false,
          },
          isUpdatedWeekly: true,
          decimal: 1,
        }

      case 'cava':
        return {
          employeeTable: {
            isVisible: false,
          },
          acr: {
            isVisible: false,
          },
          momentfeed: {
            isVisible: false,
          },
          yelp: {
            isVisible: true,
          },
          facebook: {
            isVisible: false,
          },
          delivery: {
            isVisible: false,
          },
          allRating: {
            isVisible: true,
            displayName: 'Yext',
          },
          opsCompsBreakdown: {
            title: 'Ops Comps Breakdown',
            subtitle: 'By Reason',
            isVisible: false,
          },
          isUpdatedWeekly: false,
          decimal: 1,
        }

      case 'jitb':
        return {
          employeeTable: {
            isVisible: false,
          },
          acr: {
            isVisible: false,
          },
          momentfeed: {
            isVisible: false,
          },
          yelp: {
            isVisible: true,
          },
          facebook: {
            isVisible: false,
          },
          delivery: {
            isVisible: true,
          },
          allRating: {
            isVisible: false,
          },
          opsCompsBreakdown: {
            title: 'Ops Comps Breakdown',
            subtitle: 'By Reason',
            isVisible: false,
          },
          isUpdatedWeekly: true,
          decimal: 1,
        }

      case 'lfr_ef':
      case 'lfr_tr':
      case 'lfr_sn':
      case 'lfr_lv':
      case 'lfr_vb':
      case 'lfr_tu':
      case 'lfr_ws':
      case 'lfr_jn':
      case 'lfr_mm':
      case 'lfr_ol':
      case 'lfr_dt':
      case 'lfr_uv':
      case 'lfr_bth':
        return {
          employeeTable: {
            isVisible: false,
          },
          acr: {
            isVisible: false,
          },
          momentfeed: {
            isVisible: false,
          },
          yelp: {
            isVisible: true,
          },
          facebook: {
            isVisible: false,
          },
          delivery: {
            isVisible: true,
          },
          allRating: {
            isVisible: false,
          },
          opsCompsBreakdown: {
            title: 'Ops Comps Breakdown',
            subtitle: 'By Reason',
            isVisible: false,
          },
          isUpdatedWeekly: false,
          decimal: 1,
        }

      case 'wingitnorth':
        return {
          employeeTable: {
            isVisible: false,
          },
          acr: {
            isVisible: false,
          },
          momentfeed: {
            isVisible: false,
          },
          yelp: {
            isVisible: true,
          },
          facebook: {
            isVisible: false,
          },
          delivery: {
            isVisible: true,
          },
          allRating: {
            isVisible: false,
          },
          opsCompsBreakdown: {
            title: 'Ops Comps Breakdown',
            subtitle: 'By Reason',
            isVisible: false,
          },
          isUpdatedWeekly: false,
          summary: {
            reviewSource: 'acr',
          },
          smg: {
            isVisible: true,
          },
          osatDisplayString: 'SMG',
          smgMod: {
            isVisible: true,
          },
          decimal: 1,
        }

      case 'mwb':
      case 'burgerworks':
        return {
          ...defaultConfig,
          yelp: {
            isVisible: true,
          },
          employeeTable: {
            isVisible: false,
          },
          opsCompsBreakdown: {
            ...defaultConfig.opsCompsBreakdown,
            isVisible: false,
          },
          isUpdatedWeekly: false,
          smgMwb: {
            isVisible: true,
          },
          osatDisplayString: 'SMG',
          summary: {
            reviewSource: 'acr',
          },
        }

      case 'fw_wingstop':
        return {
          employeeTable: {
            isVisible: false,
          },
          acr: {
            isVisible: false,
          },
          momentfeed: {
            isVisible: false,
          },
          yelp: {
            isVisible: false,
          },
          facebook: {
            isVisible: false,
          },
          delivery: {
            isVisible: false,
          },
          allRating: {
            isVisible: false,
          },
          opsCompsBreakdown: {
            title: 'Ops Comps Breakdown',
            subtitle: 'By Reason',
            isVisible: false,
          },
          isUpdatedWeekly: false,
          smg: {
            isVisible: true,
          },
          osatDisplayString: 'SMG',
          google: {
            isHidden: true,
          },
          summary: {
            reviewSource: 'acr',
          },
          isChartHidden: true,
          isRankingHidden: true,
          decimal: 1,
        }

      case 'bibibop':
        return {
          ...defaultConfig,
          isUpdatedWeekly: false,
          yext: {
            isVisible: true,
          },
          google: {
            isHidden: true,
          },
          yelp: {
            isVisible: false,
          },
          opsCompsBreakdown: {
            title: '',
            subtitle: '',
            isVisible: false,
          },
          employeeTable: {
            isVisible: false,
          },
          decimal: 2,
        }
      default:
        return defaultConfig
    }
  }, [brand])

export default useGuestConfig
