import { gql, useMutation } from '@apollo/client'
import moment from 'moment'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { getUser } from 'pared/utils/user'

import { useReset } from '../../../ResetProvider'

interface IParamsPropsType {
  id: string
}

const mutation = gql`
  mutation ApprovedTrainingTracker(
    $listUuid: String!
    $userId: Int!
    $now: Datetime!
  ) {
    updateTrainingTrackerByListUuid(
      input: {
        listUuid: $listUuid
        patch: {
          approvedAt: $now
          approverId: $userId
          updatedAt: $now
          needApproved: false
        }
      }
    ) {
      clientMutationId
    }
  }
`

const useApprovedTrainingTracker = () => {
  const user = getUser()
  const userId = user.employeeId ? user.employeeId : -1
  const { id }: IParamsPropsType = useParams()
  const [updateTrackerApproved] = useMutation(mutation)
  const reset = useReset()

  return useCallback(async () => {
    const now = moment().toISOString()

    await updateTrackerApproved({
      variables: {
        listUuid: id,
        userId: userId,
        now: now,
      },
    })
    reset()
  }, [reset])
}

export default useApprovedTrainingTracker
