import { useMemo } from 'react'

const exrayTypes = [
  'Sales',
  'Food Cost',
  'Labor',
  'Loss Prevention',
  'Voids',
] as const

export type ExrayType = typeof exrayTypes[number]

interface IExpoExrayConfigType {
  summaryTypeSettings: {
    type: ExrayType
    fetchType: string
    title: string
  }[]
}

export default (brand: string): IExpoExrayConfigType => {
  return useMemo(() => {
    switch (brand) {
      case 'ghai_pop':
        return {
          summaryTypeSettings: [
            {
              type: 'Sales',
              fetchType: 'corporate_salesmanship',
              title: 'Sales',
            },
            {
              type: 'Food Cost',
              fetchType: 'corporate_cogs',
              title: 'Food Cost',
            },
            {
              type: 'Labor',
              fetchType: 'corporate_labor',
              title: 'Labor',
            },
            {
              type: 'Loss Prevention',
              fetchType: 'corporate_loss_prevention',
              title: 'Discounts',
            },
            {
              type: 'Voids',
              fetchType: 'corporate_voids_summary',
              title: 'Voids',
            },
          ],
        }

      default:
        return {
          summaryTypeSettings: [
            {
              type: 'Sales',
              fetchType: 'corporate_salesmanship',
              title: 'Sales',
            },
            {
              type: 'Food Cost',
              fetchType: 'corporate_cogs',
              title: 'Food Cost',
            },
            {
              type: 'Labor',
              fetchType: 'corporate_labor',
              title: 'Labor',
            },
            {
              type: 'Loss Prevention',
              fetchType: 'corporate_loss_prevention',
              title: 'Loss Prevention',
            },
          ],
        }
    }
  }, [brand])
}
