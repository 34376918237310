import React from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import CustomizedReport from 'pared/components/CustomizedReport'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import {
  GROUP_BY_PERIOD,
  GROUP_BY_QUARTER,
  GROUP_BY_YEAR,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_YEAR,
} from 'pared/data/getDateRanges'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import useGetDirectors from 'pared/hooks/useGetDirectors'
import DirectorFilterSelector from 'pared/pages/StoreList/DirectorFilterSelector'
import { getBrand } from 'pared/utils/brand'

import TrendChart from './TrendChart'

interface IPropsType {
  navParams: INavParams
}

const Header = styled.h1`
  margin: 0px;
  font-family: Lexend-SemiBold;
  font-size: 36px;
  font-weight: 700;
  line-height: 52px;

  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 0px;
  }
`

const Container = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px;
  }
`

const ListContainer = styled.div`
  max-width: 700px;
`

const TableContainer = styled.div`
  width: 90vw;
  overflow: scroll hidden;
`

export const SubHeader = styled.div`
  margin: 20px 0;
  display: flex;
  width: 1200px;
  justify-content: end;
  gap: 25px;

  @media ${MOBILE_WIDTH} {
    display: block;
    width: 100%;
  }
`

const MobileContainer = styled.div`
  margin: 100px 0;
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const CorporateYields = ({ navParams }: IPropsType) => {
  const dateRange = useGetDateRange()
  const locationGroupId = BRAND_LOCATION_GROUP_ID[getBrand()]
  const startDate = dateRange?.startDateStr
  const endDate = dateRange?.endDateStr
  const type = dateRange?.type

  const { director, directors, directorChange } = useGetDirectors()

  if (!startDate || !endDate || !type) {
    return null
  }

  let groupBy = GROUP_BY_PERIOD
  if (type === TYPE_YEAR) {
    groupBy = GROUP_BY_YEAR
  } else if (type === TYPE_QUARTER) {
    groupBy = GROUP_BY_QUARTER
  }

  const inputParams = {
    startDate,
    endDate,
    locationGroupId: director.locationGroupId || locationGroupId,
  }

  const trendChartParams = {
    iReportName: 'CORPORATE_YIELDS_KPIS',
    iLocationGroupId: director.locationGroupId || locationGroupId,
    iEndDate: endDate,
    iGroupBy: groupBy,
  }

  return (
    <>
      <Header>Yields</Header>

      <Container>
        <SubHeader>
          <DirectorFilterSelector
            selectedDirector={director}
            allDirectors={directors}
            onDirectorChange={directorChange}
            disabledDirectors
          />

          <DateRangeSelector
            navParams={navParams}
            dateRangeOptions={[TYPE_YEAR, TYPE_QUARTER, TYPE_PERIOD]}
          />
        </SubHeader>
        <ListContainer>
          <CustomizedReport
            reportName="CORPORATE_YIELDS_KPIS"
            inputParams={inputParams}
            title="Real-Time KPIs"
          />
        </ListContainer>
        <MobileContainer>
          <TrendChart trendChartParams={trendChartParams} />
        </MobileContainer>
        <TableContainer>
          <CustomizedReport
            reportName="CORPORATE_YIELDS_BREAKDOWN_BY_STORE"
            inputParams={inputParams}
          />
        </TableContainer>
      </Container>
    </>
  )
}

export default React.memo(CorporateYields)
