import { useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { DATE_FORMAT } from 'pared/data/getDateRanges'

import {
  GET_CORPORATE_DAILY_INVENTORY_ANALYSIS,
  ILocationGroupDailyInventoryAnalysisType,
} from '../gqls'
import useConfig from './useConfig'

const useDailyInventoryAnalysisText = (
  brand: string,
  locationGroupId: number,
) => {
  const startDate = moment().subtract(8, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'days').format(DATE_FORMAT)
  const config = useConfig()
  const { data, loading } = useQuery<ILocationGroupDailyInventoryAnalysisType>(
    GET_CORPORATE_DAILY_INVENTORY_ANALYSIS,
    {
      variables: {
        iLocationGroupId: locationGroupId,
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !config.showDailyInventoryAnalysis,
    },
  )

  return useMemo(() => {
    const dailyInventoryAnalysisData =
      data?.listLocationGroupDailyInventoryAnalysis?.nodes || null

    if (!dailyInventoryAnalysisData) return ''

    const hasNonCounted =
      dailyInventoryAnalysisData
        .filter((d) => d.hasNonCountedItems === true)
        ?.map((d) => ({
          id: d.locationId,
          name: d.locationName,
        })) || []

    if (hasNonCounted.length === 0) {
      return 'In the trailing 7 Days, all the restaurants have completed Daily Inventory consistently!'
    }

    const length = hasNonCounted.length
    const hasNonCountedStr = (() => {
      if (length === 0) {
        return null
      } else if (length === 1) {
        return `<a href="/${brand}/inventory?store=${hasNonCounted[0].id}#daily-inventory-analysis">${hasNonCounted[0].name}</a>`
      } else if (length === 2) {
        return hasNonCounted
          .map(
            (d) =>
              `<a href="/${brand}/inventory?store=${d.id}#daily-inventory-analysis">${d.name}</a>`,
          )
          .join(' and ')
      } else {
        const lastItem = hasNonCounted[length - 1]
        const otherItems = hasNonCounted
          .slice(0, length - 1)
          .map(
            (d) =>
              `<a href="/${brand}/inventory?store=${d.id}#daily-inventory-analysis">${d.name}</a>`,
          )
        return `${otherItems.join(
          ', ',
        )}, and ${`<a href="/${brand}/inventory?store=${lastItem.id}#daily-inventory-analysis">${lastItem.name}</a>`}`
      }
    })()

    return `In the trailing 7 Days, ${length} restaurants have not completed Daily Inventory consistently.  ${hasNonCountedStr}. <br/><br/>`
  }, [data, loading])
}

export default useDailyInventoryAnalysisText
