import { useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { IDirector } from 'pared/data/getDirectors'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import { INullableType } from 'pared/types'
import { getBrand } from 'pared/utils/brand'

import {
  GET_STORE_LIST,
  ISalesExaminerVariableTypes,
  LIST_LOCATION_SALES_EXAMINER_DATA,
} from '../gqls/getSalesExaminerData'

const useDataSource = (
  isBreakdownByDirectors: boolean,
  directors: IDirector[],
  locationGroupIds: number[],
  revenueCenter: string,
  dayPart: string,
) => {
  const brand = getBrand()
  const dateRange = useGetDateRange()
  const variables: INullableType<ISalesExaminerVariableTypes> = {
    iStartDate: dateRange?.startDateStr,
    iEndDate: dateRange?.endDateStr,
    iFilter: {
      location_group_ids: locationGroupIds,
    },
    iRevenueCenter: revenueCenter,
    iDayPart: dayPart,
    isBreakdownByDirectors,
  }

  const { data, loading: salesDataLoading } = useQuery(
    LIST_LOCATION_SALES_EXAMINER_DATA,
    {
      variables,
      skip:
        !variables?.iRevenueCenter ||
        !variables?.iDayPart ||
        !variables?.iFilter ||
        !variables?.iStartDate ||
        !variables?.iEndDate,
    },
  )

  const { data: storeList, loading: storeListLoading } = useQuery(
    GET_STORE_LIST,
    {
      variables: {
        iStartDate: dateRange?.startDateStr,
        iEndDate: dateRange?.endDateStr,
        iFilter: {
          location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]],
        },
      },
      skip: !dateRange?.startDateStr || !dateRange?.endDateStr,
    },
  )

  const loading = salesDataLoading || storeListLoading

  const listSalesExaminerData =
    data?.listLocationSalesExaminerData ||
    data?.listLocationGroupSalesExaminerData

  const dataSource = useMemo(() => {
    if (!listSalesExaminerData?.nodes) return []

    return listSalesExaminerData.nodes.map((node: any) => {
      const store = storeList?.listLocationDetails?.nodes.find(
        ({ id }: any) => node?.locationId === id,
      )
      const filteredDirectors = directors
        .filter((d) =>
          store?.locationGroups.some((lg: any) => lg.id === d.locationGroupId),
        )
        .sort((a, b) => {
          const locationGroupA = store?.locationGroups.find(
            (lg: any) => lg.id === a.locationGroupId,
          )
          const locationGroupB = store?.locationGroups.find(
            (lg: any) => lg.id === b.locationGroupId,
          )

          if (!locationGroupA || !locationGroupB) return 0

          return moment
            .utc(locationGroupA.end_date)
            .isSameOrBefore(moment.utc(locationGroupB.end_date))
            ? 1
            : -1
        })
      const director =
        directors.find(
          ({ locationGroupId }) => node.locationGroupId === locationGroupId,
        ) || filteredDirectors[0]

      if (store)
        return {
          ...node,
          storeId: store.id,
          storeCode: store.code,
          storeName: store.name,
          directorId: director?.locationGroupId || 0,
          directorName: [director?.firstName, director?.lastName]
            .filter(Boolean)
            .join(' '),
        }

      if (director)
        return {
          ...node,
          directorId: director.employeeId,
          directorName: [director.firstName, director.lastName]
            .filter(Boolean)
            .join(' '),
        }

      return node
    })
  }, [listSalesExaminerData, directors, storeList])
  return {
    loading,
    dataSource,
  }
}

export default useDataSource
