import { IDirector } from 'pared/data/getDirectors'

import useLocationCash from './useLocationCash'
import useLocationGroupCash from './useLocationGroupCash'

export interface ICashType {
  storeId: number
  storeCode: string
  storeName: string
  directorId: number
  directorName: string
  cashControlItems: {
    type: string
    amount: number
    count: number
    checkPercent: number
  }[]
}

const useCash = (
  directors: IDirector[],
  isBreakdownByDirectors: boolean,
  locationIds?: number[],
  locationGroupIds?: number[],
  startDate?: string,
  endDate?: string,
  locationGroupType?: string,
) => {
  const { cashLocationData, cashLocationLoading } = useLocationCash(
    locationIds,
    locationGroupIds,
    startDate,
    endDate,
  )

  const { cashLocationGroupData, cashLocationGroupLoading } =
    useLocationGroupCash(
      directors,
      locationGroupIds,
      startDate,
      endDate,
      locationGroupType,
    )

  return {
    cash: isBreakdownByDirectors ? cashLocationGroupData : cashLocationData,
    cashLoading: isBreakdownByDirectors
      ? cashLocationGroupLoading
      : cashLocationLoading,
  }
}

export default useCash
