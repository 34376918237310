import { useVariables } from '../../../variables'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const trendLocationGroupDiscountConfigs = {
  date: 'string',
  discountCompsTotal: 'price',
} as const

const useTrendLocationGroupDiscount = () => {
  const { variables } = useVariables()
  const dateRange = variables.lastTimeRange?.value[0][0] || ''
  const { data: commonData, loading } = buildMetricValuesHook(
    {
      codes: ['discount_comps_total'],
    },
    dateRange,
  )()

  return {
    data: commonData,
    loading,
  }
}

export default useTrendLocationGroupDiscount
