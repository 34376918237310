import ClearIcon from '@mui/icons-material/Clear'
import { Button, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import RenderHtmlFromString from 'pared/components/basicUi/RenderHtmlFromString'
import Spin from 'pared/components/basicUi/spin'
import COLORS from 'pared/constants/colors'

import { TrainingTrackerItemsType } from '../hooks/useTrainingTrackerItems'
import useTrainerInitials from './hooks/useTrainerInitials'

interface PropsType {
  inputItem: TrainingTrackerItemsType
  showCancel: boolean
}

const StyledClearIcon = styled(ClearIcon)`
  display: inline-block;
  font-size: 14px;
  margin-left: 14px;
`

const StyledButton = styled(Button)`
  padding: 10px 24px;
  border: 1px solid;
  border-color: ${COLORS.Steel};
`

const StyledeRow = styled.tr`
  border-bottom: 1pt solid ${COLORS.Smoke};
`

const FirstdTd = styled.td`
  text-align: left;
  padding: 8px;
`

const StyledTd = styled.td`
  text-align: center;
  padding: 8px;
`

const Text = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const VerticalSpacer5px = styled.div`
  height: 10px;
`

const StyledDate = styled.div`
  font-size: 12px;
`

const TextFieldContainer = styled.div`
  background-color: ${COLORS.Ash};
`

const TrainingTrackerItem = ({ inputItem, showCancel }: PropsType) => {
  const [loading, setLoading] = useState(false)
  const initials = useTrainerInitials(setLoading, {
    id: inputItem.itemId,
    traineeInitials: !!inputItem.traineeInitials,
    trainerId: inputItem.currentTraineeLeaderId,
    listUuid: inputItem.listUuid,
  })

  useEffect(() => {
    setLoading(false)
  }, [inputItem])

  let itemCol = null
  switch (inputItem.itemType) {
    case 'text_field':
      itemCol = (
        <>
          {inputItem.itemName}
          <VerticalSpacer5px />
          <TextFieldContainer>
            <TextField
              multiline
              rows={5}
              fullWidth
              value={inputItem.content}
              disabled
            />
          </TextFieldContainer>
        </>
      )
      break
    default:
      if (inputItem.displayText) {
        itemCol = <RenderHtmlFromString htmlStr={inputItem.displayText} />
      } else {
        itemCol = inputItem.itemName
      }
  }

  return (
    <StyledeRow>
      <StyledTd>{inputItem.completedAt}</StyledTd>
      {inputItem.itemType === 'no_initials' ? (
        <>
          <StyledTd></StyledTd>
          <StyledTd></StyledTd>
        </>
      ) : (
        <>
          <StyledTd>
            <div>
              {inputItem.traineeInitials}
              <StyledDate>{inputItem.traineeInitialsDate}</StyledDate>
            </div>
          </StyledTd>
          <StyledTd>
            <Spin spinning={loading}>
              {!!inputItem.trainerInitials && (
                <Text>
                  <div>
                    {inputItem.trainerInitials}
                    <StyledDate>{inputItem.trainerInitialsDate}</StyledDate>
                  </div>
                  {showCancel && (
                    <StyledClearIcon
                      onClick={async () => {
                        await initials({ checked: !inputItem.trainerInitials })
                      }}
                    />
                  )}
                </Text>
              )}
              {!inputItem.trainerInitials && (
                <StyledButton
                  variant="outlined"
                  disabled={loading}
                  onClick={async () => {
                    await initials({ checked: !inputItem.trainerInitials })
                  }}
                />
              )}
            </Spin>
          </StyledTd>
        </>
      )}
      <FirstdTd>{itemCol}</FirstdTd>
    </StyledeRow>
  )
}

export default TrainingTrackerItem
