import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'
import Table from 'pared/components/basicUi/table'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import useDiscountCampaign from './Campaign/hooks/useDiscountCampaign'
import useDiscountCampaignColumns from './Campaign/hooks/useDiscountCampaignColumns'

interface IProps {
  startDate?: string
  endDate?: string
  locationGroupIds: number[]
}

const Container = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const SubHeaderDiscount = styled.div`
  padding: 20px 0;
  display: flex;
  width: 670px;
  justify-content: space-between;
  align-items: center;

  @media ${MOBILE_WIDTH} {
    display: block;
    width: 100%;
    margin: 60px 0px 0px;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
`

const MobileCotainer = styled.div`
  @media ${MOBILE_WIDTH} {
    margin: 20px 0px 0px;
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

function CampaignTable({ startDate, endDate, locationGroupIds }: IProps) {
  const { discountCampaignData, discountCampaignLoading } = useDiscountCampaign(
    locationGroupIds,
    startDate,
    endDate,
  )

  const { discountCampaignColumns } = useDiscountCampaignColumns()

  return (
    <Container>
      <SubHeaderDiscount>
        <h1>Breakdown by Campaign</h1>
      </SubHeaderDiscount>

      <MobileCotainer>
        <Spin spinning={discountCampaignLoading}>
          <Table
            dataSource={discountCampaignData}
            columns={discountCampaignColumns}
            rowKey="itemType"
            csvFileName="expo-marketing-campaign"
          />
        </Spin>
      </MobileCotainer>
    </Container>
  )
}

export default CampaignTable
