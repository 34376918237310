const useConfig = (): { key: string; title: string }[] => {
  return [
    {
      key: 'TOTAL',
      title: 'All',
    },
    {
      key: 'AMEX',
      title: 'Amex',
    },
    {
      key: 'DISCOVER',
      title: 'Discover',
    },
    {
      key: 'MC',
      title: 'Master Card',
    },
    {
      key: 'VISA',
      title: 'Visa',
    },
    {
      key: 'OTHERS',
      title: 'Others',
    },
  ]
}

export default useConfig
