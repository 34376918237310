import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import { PERFORMANCE_ENUM } from 'pared/constants'

interface IProps {
  firstName: string
  lastName: string
  role: string
  storeCode: string
  storeName: string
  performanceValue: string
  setPerformanceValue: any
  shouldDisplayPerformanceValueError: boolean
  setShouldDisplayPerformanceValueError: any
  shouldDisplayFeedbackEmptyError: boolean
  setShouldDisplayFeedbackEmptyError: any
  feedback: string
  setFeedback: any
  onSubmitFeedback: () => Promise<void>
  onPerformanceReviewChange: (event: any) => void
  isSubmitting: boolean
}

const useStyles = makeStyles(() => ({
  menuItem: {
    '&:hover': {
      backgroundColor: '#07A4C7 !important',
    },
  },
}))

const Main = ({
  firstName,
  lastName,
  role,
  storeCode,
  storeName,
  performanceValue,
  shouldDisplayPerformanceValueError,
  shouldDisplayFeedbackEmptyError,
  setShouldDisplayFeedbackEmptyError,
  feedback,
  setFeedback,
  onSubmitFeedback,
  onPerformanceReviewChange,
  isSubmitting,
}: IProps) => {
  const classes = useStyles()

  const errorText = shouldDisplayPerformanceValueError ? (
    <FormHelperText>No Rating Selected</FormHelperText>
  ) : null

  const feedbackErrorText = shouldDisplayFeedbackEmptyError ? (
    <ErrorText>Please Provide Feedback</ErrorText>
  ) : null

  const onChangeFeedback = (event: any) => {
    if (feedback !== '') {
      setShouldDisplayFeedbackEmptyError(false)
    }
    setFeedback(event.target.value)
  }

  return (
    <PopoverContainer>
      <SectionHeader>Employee Development Feedback</SectionHeader>
      <EmployeeNameDiv>
        {firstName} {lastName}
      </EmployeeNameDiv>
      <TitleDiv>{`${storeName} (${storeCode}) - ${role}`}</TitleDiv>
      <DescriptionTitle>Performance Review</DescriptionTitle>
      <PerformanceFormControl error={shouldDisplayPerformanceValueError}>
        <Select
          value={performanceValue}
          onChange={onPerformanceReviewChange}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select Rating
          </MenuItem>
          {PERFORMANCE_ENUM.map(({ name, code }) => {
            return (
              <MenuItem value={code} className={classes.menuItem}>
                {name}
              </MenuItem>
            )
          })}
        </Select>
        {errorText}
      </PerformanceFormControl>
      <DescriptionTitle>Feedback</DescriptionTitle>
      <DetailsTextarea
        placeholder={`Please provide input on ${firstName}'s recent performance`}
        value={feedback}
        onInput={onChangeFeedback}
      />
      {feedbackErrorText}
      <ButtonContainer>
        <SubmitButton disabled={isSubmitting} onClick={onSubmitFeedback}>
          {isSubmitting ? 'Submitting ...' : 'Submit Feedback'}
        </SubmitButton>
      </ButtonContainer>
    </PopoverContainer>
  )
}

const ErrorText = styled(FormHelperText)`
  font-family: Lexend-Regular;
  color: red;
`

const PopoverContainer = styled.div`
  width: 622px;
  padding: 30px 20px 20px 20px;
  margin: 1px 0 0 0;
  border: 1px solid black;
`

const SectionHeader = styled.div`
  font-family: Lexend-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 10px 0;
`

const DescriptionTitle = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 10px 0;
`

const PerformanceFormControl = styled(FormControl)`
  margin: 0 0 10px 0;
`

const EmployeeNameDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding 10px 0 0 0;
`

const ButtonContainer = styled.div`
  margin: auto;
  width: 179px;
`

const SubmitButton = styled.button`
  background-color: #07a4c7;
  border: none;
  color: white;
  padding: 15px 15px 15px 15px;
  width: 179px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: Lexend-Regular;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
`

const TitleDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0 10px 0;
`

const DetailsTextarea = styled.textarea`
  width: 100%;
  height: 195px;
  resize: none;
  padding: 8px 10px 10px 8px;

  ::placeholder {
    font-style: italic;
  }
`

export default Main
