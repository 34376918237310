import { gql, useMutation } from '@apollo/client'
import React, { useState } from 'react'

import { signInUser } from 'pared/utils/user'

import Main from './Main'

const MUTATION_REGISTER_USER = gql`
  mutation RegisterUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    registerUser(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
      }
    ) {
      clientMutationId
    }
  }
`

const MUTATION_AUTHENTICATE_USER = gql`
  mutation AuthenticateUser($email: String!, $password: String!) {
    authenticate(input: { iEmail: $email, iPassword: $password }) {
      jwtToken
    }
  }
`

export interface ISignUpParams {
  firstName: string
  lastName: string
  email: string
  password: string
  passwordRepeated: string
}

function SignUp() {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [registerUser, { loading, error }] = useMutation(MUTATION_REGISTER_USER)
  const [authenticateUser] = useMutation(MUTATION_AUTHENTICATE_USER)

  const onSignUp = async (signUpParams: ISignUpParams) => {
    const firstName = (signUpParams.firstName || '').trim()
    const lastName = (signUpParams.lastName || '').trim()
    const email = (signUpParams.email || '').trim().toLowerCase()
    const inputtedPassword = signUpParams.password || ''
    const trimmedPassword = inputtedPassword.trim()

    if (!firstName) {
      setErrorMessage('The first name is required.')
      return false
    }

    if (!lastName) {
      setErrorMessage('The last name is required.')
      return false
    }

    if (!email) {
      setErrorMessage('The email is required.')
      return false
    }

    if (trimmedPassword.length < 8) {
      setErrorMessage(
        'Invalid password. The length of the password must be at least 8.',
      )
      return false
    }

    if (trimmedPassword.length !== inputtedPassword.length) {
      setErrorMessage('Invalid password. White spaces are not allowed.')
      return false
    }

    if (trimmedPassword !== signUpParams.passwordRepeated) {
      setErrorMessage('The confirmed password is different with the password.')
      return false
    }

    setErrorMessage('')
    try {
      await registerUser({
        variables: {
          firstName,
          lastName,
          email,
          password: trimmedPassword,
        },
      })
    } catch (error) {
      return false
    }

    try {
      const response = await authenticateUser({
        variables: {
          email,
          password: trimmedPassword,
        },
      })

      if (
        response &&
        response.data &&
        response.data.authenticate &&
        response.data.authenticate.jwtToken
      ) {
        await signInUser(response.data.authenticate.jwtToken, email)
        return true
      }
    } catch (error) {
      // this should not block the page redirection
      return true
    }

    return true
  }

  let signUpErrorMessage: string = ''
  if (error && error.message) {
    if ((error.message || '').search('unique constraint') >= 0) {
      signUpErrorMessage = 'This email has been registered.'
    } else {
      signUpErrorMessage = 'Unexpected internal error, please try again later.'
    }
  }

  return (
    <Main
      isLoading={loading}
      onSignUp={onSignUp}
      errorMessage={signUpErrorMessage || errorMessage}
    />
  )
}

export default SignUp
