import { FormControl } from '@material-ui/core'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { CustomizedReportRender } from 'pared/components/CustomizedReport'
import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import Select from 'pared/components/basicUi/select'

import ItemSelector from './ItemSelector'
import useConfig from './hooks/useConfig'
import useFetchPurchaseReportData from './hooks/useFetchPurchaseReportData'
import useUnitFilter from './hooks/useUnitFilter'
import {
  INVENTORY_UNIT_TYPE,
  IUnitFilterItemType,
} from './hooks/useUnitFiltersConfig'
import getFilteredCustomReportDefinition from './util/getFilteredCustomReportDefinition'
import getOptions from './util/getOptions'

const percentageToColor = (percentage: number, maxHue = 0, minHue = 120) => {
  const hue = percentage * (maxHue - minHue) + minHue
  return `hsl(${hue}, 80%, 80%)`
}

const PurchasesTable = ({
  locationGroupId,
  locationIds,
  startDate,
  endDate,
  presetCategoryOrItemFilter,
}: {
  locationGroupId?: number
  locationIds?: number[]
  startDate?: string
  endDate?: string
  presetCategoryOrItemFilter?: string
}) => {
  const config = useConfig()
  const { reportDefinition, reportResult, isLoading } =
    useFetchPurchaseReportData(locationGroupId, locationIds, startDate, endDate)
  const { purchaseVendorOptions } = getOptions({
    reportResult,
  })
  const [selectedVendor, setSelectedVendor] = useState<{
    name: string
    type: string
  } | null>(purchaseVendorOptions[0] ?? null)

  const [isChangingVendor, setIsChangingVendor] = useState(false)
  const { purchaseOptions, defaultPurchaseOption } = getOptions({
    reportResult,
    selectedVendor: selectedVendor?.name,
    presetCategoryOrItemFilter,
    config,
  })
  const [selectedItem, setSelectedItem] = useState<{
    name: string
    type: string
  } | null>(defaultPurchaseOption)
  const { unitFilter, setUnitFilter, unitFilters } = useUnitFilter()
  const selectedUnit = unitFilter[0]
  const filteredReportDefinition = getFilteredCustomReportDefinition(
    reportDefinition,
    { unit: selectedUnit },
    config,
  )

  useEffect(() => {
    if (
      purchaseVendorOptions.length > 0 &&
      !purchaseVendorOptions.find(({ name }) => name === selectedVendor?.name)
    ) {
      setSelectedVendor(purchaseVendorOptions[0])
      setIsChangingVendor(true)
    }
  }, [purchaseVendorOptions, locationGroupId])

  useEffect(() => {
    if (selectedVendor) {
      setSelectedItem(defaultPurchaseOption)
      setIsChangingVendor(false)
    }
  }, [selectedVendor])

  const handleVendorChange = (
    event: any,
    value: { name: string; type: string },
  ) => {
    if (value) {
      setSelectedVendor(value)
      setIsChangingVendor(true)
    }
  }

  const handleItemChange = (
    event: any,
    value: { name: string; type: string },
  ) => {
    if (value) {
      setSelectedItem(value)
    }
  }

  let finalReportResult = reportResult
  if (reportResult) {
    if (reportResult.dynamicColumns && selectedItem) {
      const filteredDynamicColumnsCost =
        reportResult.dynamicColumns.costItemDetails?.filter(
          ({ header, itemType }: { header: string; itemType?: string }) =>
            header.toLowerCase() === selectedItem.name.toLowerCase() ||
            itemType?.toLowerCase() === selectedItem.name.toLowerCase(),
        )

      const filteredDynamicColumnsBasisPoints =
        reportResult.dynamicColumns.basisPointsItemDetails?.filter(
          ({ header, itemType }: { header: string; itemType?: string }) =>
            header.toLowerCase() === selectedItem.name.toLowerCase() ||
            itemType?.toLowerCase() === selectedItem.name.toLowerCase(),
        )

      finalReportResult = {
        ...reportResult,
        dynamicColumns: {
          costItemDetails: filteredDynamicColumnsCost,
          basisPointsItemDetails: filteredDynamicColumnsBasisPoints,
        },
      }
    }

    if (selectedItem) {
      let tableData = [...(finalReportResult?.tableData || [])]
      tableData = tableData.filter(
        (row) => row.itemDetails[selectedItem.name] && row.netSales > 0,
      )

      if (selectedVendor) {
        tableData = tableData.filter(
          (row) =>
            row.itemDetails[selectedItem.name].vendor === selectedVendor.name,
        )
      }

      if (selectedUnit === INVENTORY_UNIT_TYPE.DOLLAR) {
        tableData.sort((a, b) => {
          return (
            b.itemDetails[selectedItem.name].cost / b.netSales -
            a.itemDetails[selectedItem.name].cost / a.netSales
          )
        })

        tableData = tableData.map((row, index) => ({
          ...row,
          itemDetails: {
            ...row.itemDetails,
            [selectedItem.name]: {
              ...row.itemDetails[selectedItem.name],
              cost: {
                value: row.itemDetails[selectedItem.name]?.cost,
                color: percentageToColor(
                  (tableData.length - index) / tableData.length,
                ),
              },
            },
          },
        }))
      }

      if (selectedUnit === INVENTORY_UNIT_TYPE.BASIS_POINT) {
        tableData.sort((a, b) => {
          return (
            b.itemDetails[selectedItem.name].basisPoints -
            a.itemDetails[selectedItem.name].basisPoints
          )
        })

        tableData = tableData.map((row, index) => ({
          ...row,
          itemDetails: {
            ...row.itemDetails,
            [selectedItem.name]: {
              ...row.itemDetails[selectedItem.name],
              basisPoints: {
                value: row.itemDetails[selectedItem.name]?.basisPoints,
                color: percentageToColor(
                  (tableData.length - index) / tableData.length,
                ),
              },
            },
          },
        }))
      }

      finalReportResult = {
        ...finalReportResult,
        tableData,
      }
    }
  }

  return (
    <Container>
      <FilterContainer>
        {config.includeVendor ? (
          <>
            <span>Vendor</span>
            <ItemSelector
              allItems={purchaseVendorOptions}
              selectedItem={selectedVendor}
              onItemChange={handleVendorChange}
              isEnabled
              isLoading={!reportResult}
            />
          </>
        ) : null}
        <span>Category or Item</span>
        <ItemSelector
          allItems={purchaseOptions}
          selectedItem={selectedItem}
          onItemChange={handleItemChange}
          isEnabled
          isGrouped
          isLoading={!reportResult || isChangingVendor}
        />

        {unitFilters.length <= 2 ? null : (
          <>
            <span>Comparison</span>
            <StyledFormControl size="small">
              <MenuItemSelect<IUnitFilterItemType>
                value={unitFilter}
                onChange={setUnitFilter}
                dataSource={unitFilters}
              />
            </StyledFormControl>
          </>
        )}
      </FilterContainer>
      <CustomizedReportRender
        key={`${startDate} - ${endDate}`}
        reportDefinition={filteredReportDefinition}
        reportResult={finalReportResult}
        isLoading={isLoading || !selectedItem}
        rowKey={({ locationInfo }) => locationInfo.id}
        csvFileName="getexpo-corporate-purchases"
      />
    </Container>
  )
}

export default PurchasesTable

const Container = styled.div`
  padding: 0;
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
  }
`

const FilterContainer = styled.div`
  width: 1300px;
  padding: 25px 0;
  display: flex;
  align-items: center;
  gap: 20px;
  span {
    font-family: Lexend-SemiBold;
    font-size: 14px;
    font-weight: 700;
  }

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 5px 0 25px 0;

    span {
      display: block;
      padding: 0;
    }
  }
`

const MenuItemSelect = styled(Select)`
  background-color: white;

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
` as typeof Select

const StyledFormControl = styled(FormControl)`
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`
