import { useMemo } from 'react'

import useBrands from '../../hooks/useBrands'

const useBrandConfig = () => {
  const { brand } = useBrands()

  return useMemo(() => {
    switch (brand) {
      // Local Favorite
      case 'lfr':
      case 'lfr_ef':
      case 'lfr_tr':
      case 'lfr_sn':
      case 'lfr_lv':
      case 'lfr_vb':
      case 'lfr_tu':
      case 'lfr_ws':
      case 'lfr_jn':
      case 'lfr_mm':
      case 'lfr_ol':
      case 'lfr_dt':
      case 'lfr_uv':
      case 'lfr_bth':
        return { logoHeight: 55 }

      case 'fw_wingstop':
        return { logoHeight: 80 }

      default:
        return { logoHeight: 40 }
    }
  }, [brand])
}

export default useBrandConfig
