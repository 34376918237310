export const findAndReplace = (input: string, replacements?: string[][]) => {
  if (!replacements) {
    return input
  }

  return replacements.reduce(
    (str, [searchValue, newValue]) => str.replace(searchValue, newValue),
    input,
  )
}
