import { track } from 'pared/analytics/storeList'
import { IDirector } from 'pared/data/getDirectors'

import Main from './Main'
import useConfig from './hooks/useConfig'

interface IProps {
  allDirectors: IDirector[]
  selectedDirector: IDirector
  onDirectorChange: (directorEmployeeId: number) => void
  width?: string
  disabledDirectors?: boolean
  temporaryValue?: string
}

function DirectorFilterSelector({
  allDirectors,
  selectedDirector,
  onDirectorChange,
  width,
  disabledDirectors = false,
  temporaryValue,
}: IProps) {
  const config = useConfig()
  const handleDirectorChange = async (event: any) => {
    const directorEmployeeId = event.target.value
    track.directorChanged(directorEmployeeId)
    onDirectorChange(directorEmployeeId)
  }

  return (
    <Main
      allDirectors={allDirectors}
      director={selectedDirector}
      onDirectorChange={handleDirectorChange}
      width={width}
      disabledDirectors={
        (!config.isDirectorBreakdownVisible && config.shouldHideGroups) ||
        disabledDirectors
      }
      locationGroupTypes={
        config.shouldHideGroups ? [] : config.locationGroupTypes
      }
      temporaryValue={temporaryValue}
    />
  )
}

export default DirectorFilterSelector
