import _ from 'lodash'
import { useMemo } from 'react'
import stringSimilarity from 'string-similarity'

import { getBrandSettings } from 'pared/customer'
import {
  toFormattedInteger,
  toPercentString,
  toUsdString,
} from 'pared/utils/number'

import useLocationEmployeeSalesData from '../../hooks/useLocationEmployeeSalesData'
import useLocationEmployeeUpsizeData from '../../hooks/useLocationEmployeeUpsizeData'
import useSalesmanshipConfig from '../../hooks/useSalesmanshipConfig'
import { addRankToTableData, getEmployeeInfo } from '../../util'
import useStaffSalesTableConfig from './useStaffSalesTableConfig'

export interface IStaffSalesTableData {
  rank: number
  employeeName: string
  roleName: string
  daysWorked: number
  value: number
  displayString: string
  isStoreAvg: boolean
}

function useStaffSalesTableData(
  selectedMenuKey: string,
  locationId: number | string,
  startDate: string,
  endDate: string,
  dayPart: string,
) {
  const tableConfig = useStaffSalesTableConfig()
  const salesmanshipConfig = useSalesmanshipConfig()

  const { isLoading: isEmployeeSalesDataLoading, data: employeeSalesData } =
    useLocationEmployeeSalesData(locationId, startDate, endDate, dayPart)

  const { isLoading: isEmployeeUpsizeDataLoading, data: employeeUpsizeData } =
    useLocationEmployeeUpsizeData(locationId, startDate, endDate, dayPart)

  return useMemo(() => {
    const brandSettings = getBrandSettings()
    let dataConfig = tableConfig.kpis[selectedMenuKey]
    let matchedMenuKey = selectedMenuKey

    // to support AI
    if (!dataConfig) {
      const kpis = Object.keys(tableConfig.kpis) || []
      const bestMatchResults = stringSimilarity.findBestMatch(
        selectedMenuKey,
        kpis,
      )
      const bestMatchItem = bestMatchResults?.bestMatch?.target
      if (bestMatchItem) {
        matchedMenuKey = bestMatchItem
        dataConfig = tableConfig.kpis[matchedMenuKey]
      }
    }

    let tableData: IStaffSalesTableData[] = []

    if (
      dataConfig &&
      dataConfig.tableType &&
      employeeSalesData &&
      employeeUpsizeData
    ) {
      let rawTableData: any[] = []
      const tableType = dataConfig.tableType
      let storeAvg = null
      const storeAvgKpi = `${dataConfig.kpiKey}StoreAvg`

      if (tableType === 'sales') {
        rawTableData = employeeSalesData.map((rawData: any) => {
          let salesTypeData: any = {}
          if (dataConfig.salesType === 'total') {
            salesTypeData = rawData?.totalSalesDetails
          } else {
            salesTypeData =
              rawData?.categorizedSalesDetails?.[dataConfig.salesType]
          }

          const value = salesTypeData?.[dataConfig.kpiKey] || 0
          if (salesTypeData?.[storeAvgKpi]) {
            storeAvg = salesTypeData?.[storeAvgKpi]
          }

          const employeeInfo = getEmployeeInfo(rawData)
          return {
            ...employeeInfo,
            roleName:
              salesmanshipConfig.staffSales?.roleNameOverride ||
              employeeInfo.roleName,
            value,
          }
        })
      } else if (tableType === 'upsize') {
        rawTableData = employeeUpsizeData.map((rawData: any) => {
          const upsizeTypeData = rawData?.upsizeDetails?.[dataConfig.upsizeType]
          const value = upsizeTypeData?.[dataConfig.kpiKey] || 0
          if (upsizeTypeData?.[storeAvgKpi]) {
            storeAvg = upsizeTypeData?.[storeAvgKpi]
          }
          return {
            ...getEmployeeInfo(rawData),
            value,
          }
        })
      }

      if (storeAvg !== null) {
        rawTableData.push({
          employeeId: '',
          value: storeAvg,
          employeeName: `${brandSettings.labels.business.store} Average`,
          isStoreAvg: true,
        })
      }

      rawTableData = rawTableData.map((rawData: any) => {
        let displayString = ''
        switch (dataConfig.valueType) {
          case 'count':
            displayString = toFormattedInteger(rawData.value)
            break
          case 'cent':
            displayString = toUsdString(
              rawData.value / 100.0,
              dataConfig.digitNum,
            )
            break
          case 'percent':
            displayString = toPercentString(rawData.value, dataConfig.digitNum)
            break
          default:
          // do nothing
        }

        return {
          ...rawData,
          displayString,
        }
      })

      tableData = addRankToTableData(
        _.orderBy(rawTableData, 'value', dataConfig.orderBy),
      )
    }

    return {
      tableData,
      matchedMenuKey,
      isLoading: isEmployeeSalesDataLoading || isEmployeeUpsizeDataLoading,
    }
  }, [
    selectedMenuKey,
    tableConfig,
    isEmployeeSalesDataLoading,
    isEmployeeUpsizeDataLoading,
    employeeSalesData,
    employeeUpsizeData,
    locationId,
    startDate,
    endDate,
    dayPart,
  ])
}

export default useStaffSalesTableData
