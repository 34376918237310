import { gql, useLazyQuery, useMutation } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { getBrand } from 'pared/utils/brand'

import Main from './Main'

const MUTATION_CREATE_FEEDBACK = gql`
  mutation CreateFeedback($userId: Int!, $note: String!) {
    createFeedback(input: { feedback: { userId: $userId, note: $note } }) {
      clientMutationId
    }
  }
`

const GET_ME = gql`
  query GetMe {
    getMe {
      nodes {
        firstName
        lastName
        userId
        email
      }
    }
  }
`

interface IProps {
  isFeedbackFormVisible: boolean
  setIsFeedbackFormVisible: any
}

interface IMe {
  firstName: string
  lastName: string
  userId: number
}

function FeedbackModal({
  isFeedbackFormVisible,
  setIsFeedbackFormVisible,
}: IProps) {
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState<boolean>(false)
  const [createFeedback, { loading, error }] = useMutation(
    MUTATION_CREATE_FEEDBACK,
  )
  const [getMe, { error: meDataError, data: meData }] = useLazyQuery(GET_ME)
  const [feedbackNote, setFeedbackNote] = useState<string>('')

  useEffect(() => {
    getMe()
  }, [getMe])

  const me = _.first(_.get(meData, 'getMe.nodes', [])) || {
    firstName: '',
    lastName: '',
    userId: 5,
  }

  const userFullName = `${_.get(me, 'firstName', '')} ${_.get(
    me,
    'lastName',
    '',
  )}`

  const onSubmitFeedback = async () => {
    await createFeedback({
      variables: {
        userId: _.get(me, 'userId') || 0,
        note: feedbackNote,
      },
    })

    const brand = getBrand()
    const requestBody = {
      brand,
      user: {
        id: _.get(me, 'userId') || 0,
        name: userFullName,
        email: _.get(me, 'email', ''),
      },
      note: feedbackNote,
    }

    await fetch(`${process.env.REACT_APP_BE_BASE_URL}/send_feedback_email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })

    setIsFeedbackSubmitted(true)
    setTimeout(() => {
      setIsFeedbackSubmitted(false)
      setIsFeedbackFormVisible(false)
    }, 1000)
  }

  return (
    <Main
      isFeedbackFormVisible={isFeedbackFormVisible}
      setIsFeedbackFormVisible={setIsFeedbackFormVisible}
      setFeedbackNote={setFeedbackNote}
      isFeedbackSubmitted={isFeedbackSubmitted}
      onSubmitFeedback={onSubmitFeedback}
      userFullName={userFullName}
    />
  )
}

export default FeedbackModal
