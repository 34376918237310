import { useMemo } from 'react'

import COLORS from 'pared/constants/colors'

const generateNthChild = (datas: number[]) => `
  ${datas.map((data) => `&:nth-child(${data})`).join(', ')} {
    background: ${COLORS.Porcelain};
  }
`

const useStyles = (reportDefinition: any) => {
  const columns = reportDefinition?.columns
  const isForEmail = reportDefinition?.isForEmail
  let hasColor = false
  let currentParent = ''
  let coloredColumns: number[] = []
  if (Array.isArray(columns)) {
    for (let i = 0; i < columns.length; i++) {
      const parent = columns[i].parent
      if (!parent) {
        continue
      }

      if (parent !== currentParent) {
        if (!hasColor) {
          coloredColumns.push(i)
        }

        hasColor = !hasColor
        currentParent = parent
        continue
      }

      if (parent === currentParent && hasColor) {
        coloredColumns.push(i)
        continue
      }
    }
  }

  return useMemo(() => {
    if (isForEmail) {
      return {
        tableStyle: `
            border: 1px solid black;
            th {
              border: 1px solid black;
            }

            td {
              border: 1px solid black;
            }

            background-color: white;
          `,
      }
    }

    return {
      headerStyle:
        coloredColumns.length === 0
          ? ''
          : `
        &:first-child th:nth-child('even') {
          background: ${COLORS.Porcelain}
        }

        &:nth-child(2) th {
          ${generateNthChild(coloredColumns)}
        }
      `,

      rowStyle:
        coloredColumns.length === 0
          ? `
        &:nth-child(even):not(.table-summary-row) {
          background-color: ${COLORS.Porcelain}
        }
      `
          : `
        td {
          font-size: 13px;
          white-space: nowrap;

          ${generateNthChild(coloredColumns.map((index) => index + 1))}
        }
      `,
    }
  }, [columns, isForEmail])
}

export default useStyles
