import React from 'react'
import styled from 'styled-components'

import InfoIcon from 'pared/images/Pared/info.svg'

interface IProps {
  popoverDescription: string
}

function Main({ popoverDescription }: IProps) {
  return (
    <>
      <PopoverTable>
        <tbody>
          <InformationTr>
            <InfoIconImgTd>
              <img src={InfoIcon} alt="Info" />
            </InfoIconImgTd>
            <td>
              <Description>{popoverDescription}</Description>
            </td>
          </InformationTr>
        </tbody>
      </PopoverTable>
    </>
  )
}

const PopoverTable = styled.table`
  width: 251px;
  padding: 0 20px 0 3px;
  margin: 1px 0 0 0;
  border: 1px solid black;
  font-style: italic;
  font-size: 12px;
`

const InformationTr = styled.tr`
  vertical-align: top;
`

const InfoIconImgTd = styled.td`
  width: 16px;
`

const Description = styled.div`
  display: inline;
`

export default Main
