import { useApolloClient } from '@apollo/client'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import Tooltip from 'pared/components/basicUi/Tooltip'
import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import Spin from 'pared/components/basicUi/spin'
import Table from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'

import useColumns from './hooks/useColumns'
import useDataSource from './hooks/useDataSource'

interface IProps {
  locationId: number
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }
  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;
    @media ${MOBILE_WIDTH} {
      padding: 30px 25px 0px;
    }
  }
`

const rowStyle = `
  td:first-child {
    font-family: Lexend-SemiBold;
    font-weight: 600px;
  }

  &:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }
`

const Container = styled.div`
  padding: 0 0 60px 0;
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px;
  }
`

const DailyInventoryAnalysis = ({ locationId }: IProps) => {
  const { columns, dataSource, loading } = useDataSource(locationId)
  const columnsInfo = useColumns(columns)

  const apolloClient = useApolloClient()

  const observableQueries = apolloClient.getObservableQueries()
  const isLoading = Array.from(observableQueries.values()).some((value) => {
    const currentResult = value.getCurrentResult()
    return currentResult.loading && currentResult.networkStatus != 2
  })

  const urlInfo = useLocation()
  useEffect(() => {
    const timeout = setTimeout(() => {
      const queryObject = new URLSearchParams(urlInfo.search)
      const storeIdNum = parseInt(queryObject.get('store') || '0')
      if (storeIdNum) {
        const hash = urlInfo.hash
        if (hash) {
          const targetElement = document.getElementById(hash.replace(/#/, ''))
          if (targetElement && !isLoading) {
            targetElement.scrollIntoView({ block: 'start' })
          }
        }
      }
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [urlInfo, isLoading])

  return (
    <>
      <Header id={'daily-inventory-analysis'}>
        <div>
          <h1>Daily Inventory Analysis</h1>
        </div>
        <Tooltip title={'ending_qty from cogs flash report'} />
      </Header>
      <Spin spinning={loading}>
        <Container>
          <Table
            dataSource={dataSource}
            columns={columnsInfo}
            rowKey={(values) => values.item}
            rowStyle={rowStyle}
            csvFileName={`daily-inventory-analysis`}
          />
        </Container>
      </Spin>
    </>
  )
}

export default DailyInventoryAnalysis
