import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { usePrevious } from 'react-use'

import { INavParams } from 'pared/Routes/navParams'
import { feature, page } from 'pared/analytics/user'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { BRAND_ID, BRAND_NAME } from 'pared/constants/brands'
import { getBrandSettings } from 'pared/customer'
import getDateRanges, { IDateRange } from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'
import getDirectors from 'pared/data/getDirectors'
import useDefaultDirector, {
  FIXED_DIRECTORS,
} from 'pared/hooks/useDefaultDirector'
import { getLocationCode, getLocationName } from 'pared/utils/location'
import {
  toFormattedInteger,
  toPercentString,
  toUsdString,
} from 'pared/utils/number'
import { scrollToTop } from 'pared/utils/web'

import useDirectorFilterSelectorConfig from './DirectorFilterSelector/hooks/useConfig'
import Main from './Main'
import {
  GET_STORE_LIST,
  LIST_LOCATION_FINANCIAL_KPIS,
  LIST_LOCATION_GROUP_FINANCIAL_KPIS,
} from './gql'
import useConfig from './hooks/useConfig'

interface IEmployee {
  id: number
  firstName: string
  lastName: string
}

interface IStoreInfo {
  id: number
  name: string
  code: string
  closed: boolean

  coordinates: {
    latitude: number
    longitude: number
  }

  storeDirector?: IEmployee
}

export interface ILocationCoordinate {
  locationId: number
  locationName: string
  locationCode: string
  coordinates: {
    latitude: number
    longitude: number
  }
  doEmployeeId: number
}

interface ILocationPerformanceByDo {
  storeId: number
  cogsPercent: string
  cogsPercentNumber: number
  cogsBudgetVariance: string
  cogsBudgetVarianceNumber: number
  periodCogsPercent: string
  periodCogsPercentNumber: number
  periodCogsBudgetVariance: string
  avtPercent: string
  avtPercentNumber: number
  avtPercentWeekly: string
  avtPercentWeeklyNumber: string
  avtPercentMonthly: string
  avtPercentMonthlyNumber: string
  plvPercent: string
  plvPercentNumber: number
  hourlyLaborPercent: string
  hourlyLaborPercentNumber: number
  rcpBudgetVariance: string
  rcpBudgetVarianceNumber: number
  coordinates: {
    latitude: number
    longitude: number
  }
  doEmployeeId: number
  locationCode: string
  locationName: string
  rcpPercentNumber: number
  rcpPercent: string
  netSalesSssPercentNumber: number
  netSalesSssPercent: string
  grossSalesSssPercentNumber: number
  grossSalesSssPercent: string
  director: IDirector
  hourlyLaborPerformanceAgainstBudgetPercentNumber: number
  hourlyLaborPerformanceAgainstBudgetPercent: string
  netSalesNumber: number
  netSales: string
  netSalesPerformanceAgainstBudgetPercentNumber: number
  netSalesPerformanceAgainstBudgetPercent: string
  grossSalesNumber: number
  grossSales: string
  grossSalesPerformanceAgainstBudgetPercentNumber: number
  grossSalesPerformanceAgainstBudgetPercent: string
}

export interface ISummaryPerformance {
  checkAverage: string
  checkCount: string
  checkCountVsLyPercent: string
  cogsPercent: string
  cogsBudgetVariance: string
  periodCogsPercent: string
  periodCogsBudgetVariance: string
  hourlyLaborPerformanceAgainstBudgetPercent: string
  hourlyLaborPercent: string
  netSales: string
  netSalesBudgetVariance: string
  grossSales: string
  grossSalesBudgetVariance: string
  rcpPercent: string
  netSalesSssPercent: string
  grossSalesSssPercent: string
  salesPerLaborHour: string
  avtPercent: string
  avtPercentWeekly: string
  avtPercentMonthly: string
  plvPercent: string
  sosAvgSeconds: string
}

export interface ILocationPerformanceByDoMap {
  [storeId: number]: ILocationPerformanceByDo
}

export interface IFormattedStoreInfo {
  storeId: number
  storeCode: string
  storeName: string
  directorId: number
  directorName: string
  ptdNetSalesSss: string
  ptdGrossSalesSss: string
  ptdRcp: string
  hourlyLaborPerformanceAgainstBudgetPercent: string
  netSalesPerformanceAgainstBudgetPercent: string
  grossSalesPerformanceAgainstBudgetPercent: string
  ptdCogsPercent: string
  netSales: string
  netSalesNumber: number
  grossSales: string
  grossSalesNumber: number
  rcpPercentNumber: number
  hourlyLaborPerformanceAgainstBudgetPercentNumber: number
  avtPercent: string
  avtPercentNumber: number
  avtPercentWeekly: string
  avtPercentWeeklyNumber: number
  avtPercentMonthly: string
  avtPercentMonthlyNumber: number
  plvPercent: string
  plvPercentNumber: number
  hourlyLaborPercent: string
  hourlyLaborPercentNumber: number
  rcp: string
  rcpNumber: number
  cogsPercentNumber: number
  cogsBudgetVarianceNumber: number
  cogsBudgetVariance: string
  periodCogsPercent: string
  periodCogsPercentNumber: number
  periodCogsBudgetVariance: string
  periodCogsBudgetVarianceNumber: number
  rcpBudgetVarianceNumber: number
  rcpBudgetVariance: string
  netSalesPerformanceAgainstBudgetPercentNumber: number
  grossSalesPerformanceAgainstBudgetPercentNumber: number
  netSalesSssPercentNumber: number
  grossSalesSssPercentNumber: number
  totalCheckCount: number
  yoyTotalCheckCount: number
  totalCheckCountVsLyCount: number
  totalCheckCountVsLyPercent: number
  sosAvgSeconds: number
}

export interface IFormattedBreakdownInfo {
  ptdNetSalesSss: string
  ptdGrossSalesSss: string
  ptdRcp: string
  hourlyLaborPerformanceAgainstBudgetPercent: string
  netSalesPerformanceAgainstBudgetPercent: string
  grossSalesPerformanceAgainstBudgetPercent: string
  ptdCogsPercent: string
  netSales: string
  netSalesNumber: number
  grossSales: string
  grossSalesNumber: number
  rcpPercentNumber: number
  hourlyLaborPerformanceAgainstBudgetPercentNumber: number
  cogsPercentNumber: number
  netSalesPerformanceAgainstBudgetPercentNumber: number
  grossSalesPerformanceAgainstBudgetPercentNumber: number
  netSalesSssPercentNumber: number
  grossSalesSssPercentNumber: number
  cogsBudgetVariance: string
  cogsBudgetVarianceNumber: number
  periodCogsPercent: string
  periodCogsPercentNumber: number
  periodCogsBudgetVariance: string
  periodCogsBudgetVarianceNumber: number
  rcpBudgetVariance: string
  rcpBudgetVarianceNumber: number
  avtPercent: string
  avtPercentNumber: number
  avtPercentWeekly: string
  avtPercentWeeklyNumber: number
  avtPercentMonthly: string
  avtPercentMonthlyNumber: number
  plvPercent: string
  plvPercentNumber: number
  hourlyLaborPercent: string
  hourlyLaborPercentNumber: number
  rcp: string
  rcpNumber: number
  totalCheckCount: number
  yoyTotalCheckCount: number
  totalCheckCountVsLyCount: number
  totalCheckCountVsLyPercent: number
  sosAvgSeconds: number
}

export interface IFormattedDoBreakdownInfo extends IFormattedBreakdownInfo {
  locationGroupId: number
  directorId: number
  directorName: string
}

export interface IFormattedLocationGroupBreakdownInfo
  extends IFormattedBreakdownInfo {
  locationGroupId: number
  locationGroupName: string
}

interface IProps {
  navParams: INavParams
}

function StoreList({ navParams }: IProps) {
  let errorMessage: string = ''
  const config = useConfig()
  const dateRange = navParams.dateRange || config.preSelectedDateRange || ''
  const brandSettings = getBrandSettings()
  const directorFilterSelectorConfig = useDirectorFilterSelectorConfig()

  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange | null>(
    null,
  )
  const [allDirectors, setAllDirectors] = useState<IDirector[] | null>([])
  const defaultDirector = useDefaultDirector()
  const [selectedDirector, setSelectedDirector] = useState<IDirector | null>(
    defaultDirector,
  )
  const prevBrand = usePrevious(brandSettings.brand)

  useEffect(() => {
    if (prevBrand && prevBrand === brandSettings.brand) return

    onDirectorChange(defaultDirector.employeeId)
  }, [brandSettings.brand])

  const [breakdownType, setBreakdownType] = useState<string | null>(null)
  const [locationsWithCoordinates, setLocationsWithCoordinates] = useState<
    ILocationCoordinate[]
  >([])

  const [selectedLocationGroup, setSelectedLocationGroup] = useState<{
    id: number
    name?: string
  } | null>(null)
  const brand = brandSettings.brand
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  const selectedDirectorEmployeeId = _.get(selectedDirector, 'employeeId') || -1
  let selectedDirectorLocationGroupId =
    _.get(selectedDirector, 'locationGroupId') || -1
  selectedDirectorLocationGroupId =
    selectedDirectorLocationGroupId < 0
      ? brandLocationGroupId
      : selectedDirectorLocationGroupId
  const isBreakdownByDirectors = selectedDirectorEmployeeId === -2
  const isBreakdownByLocationGroupType =
    breakdownType !== null && selectedLocationGroup === null
  const history = useHistory()

  const [
    getStoreListByBrand,
    { loading: storeDataLoading, error: storeDataError, data: storeData },
  ] = useLazyQuery(GET_STORE_LIST)

  const [
    getSummaryKpis,
    {
      loading: getSummaryKpisLoading,
      error: getSummaryKpisError,
      data: getSummaryKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_GROUP_FINANCIAL_KPIS(brand))

  const [
    listLocationFinancialKpis,
    {
      loading: listLocationFinancialKpisLoading,
      error: listLocationFinancialKpisError,
      data: listLocationFinancialKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_FINANCIAL_KPIS)

  const [
    listLocationGroupFinancialKpis,
    {
      loading: listLocationGroupFinancialKpisLoading,
      error: listLocationGroupFinancialKpisError,
      data: listLocationGroupFinancialKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_GROUP_FINANCIAL_KPIS(brand))

  useEffect(() => {
    async function fetchData() {
      const [newAllDateRangeData, directors] = await Promise.all([
        getDateRanges(),
        getDirectors(),
      ])

      if (Array.isArray(directors) && directors.length > 0) {
        setAllDirectors(directors)
      }

      const years = newAllDateRangeData.years

      if (Array.isArray(years) && years.length > 0) {
        let newSelectedDateRange: IDateRange | null = null
        if (dateRange) {
          newSelectedDateRange = newAllDateRangeData.dateRangeMap[dateRange]
        }

        if (!newSelectedDateRange) {
          newSelectedDateRange = newAllDateRangeData.defaultPeriod
        }

        if (newSelectedDateRange) {
          const brandId = BRAND_ID[brand]

          setSelectedDateRange(newSelectedDateRange)
          getStoreListByBrand({
            variables: {
              iFilter: {
                location_group_ids: [brandLocationGroupId],
                brand_ids: [brandId],
              },
            },
          })

          getSummaryKpis({
            variables: {
              iStartDate: newSelectedDateRange.startDateStr,
              iEndDate: newSelectedDateRange.endDateStr,
              iFilter: {
                location_group_ids: [
                  selectedLocationGroup?.id || brandLocationGroupId,
                ],
              },
            },
          })

          if (isBreakdownByDirectors) {
            listLocationGroupFinancialKpis({
              variables: {
                iStartDate: newSelectedDateRange.startDateStr,
                iEndDate: newSelectedDateRange.endDateStr,
                iFilter: {
                  location_group_ids: _.map(allDirectors, (doData) => {
                    return doData.locationGroupId
                  }),
                },
              },
            })
          } else if (isBreakdownByLocationGroupType) {
            listLocationGroupFinancialKpis({
              variables: {
                iStartDate: newSelectedDateRange.startDateStr,
                iEndDate: newSelectedDateRange.endDateStr,
                iFilter: {
                  location_group_types: [breakdownType],
                  brand_ids: [brandId],
                },
              },
            })
          } else {
            listLocationFinancialKpis({
              variables: {
                iStartDate: newSelectedDateRange.startDateStr,
                iEndDate: newSelectedDateRange.endDateStr,
                iFilter: {
                  location_group_ids: [
                    selectedLocationGroup?.id || brandLocationGroupId,
                  ],
                },
              },
            })
          }
        }
      }
    }

    fetchData()
  }, [
    dateRange,
    selectedDirector,
    allDirectors,
    breakdownType,
    selectedLocationGroup,
    brandSettings.brandId,
  ])

  useEffect(() => {
    if (navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl)
      feature.used('Dashboard')
    }
  }, [navParams.pageUrl])

  const isLoading = storeDataLoading || !selectedDateRange || !allDirectors

  const handleLocationGroupNameClick = (
    locationGroupId: number,
    locationGroupName: string,
    directorEmployeeId?: number,
  ) => {
    if (directorEmployeeId) {
      onDirectorChange(directorEmployeeId)
    } else {
      setSelectedLocationGroup({
        id: locationGroupId,
        name: locationGroupName,
      })
    }
  }

  const handleGoBack = () => {
    setSelectedLocationGroup(null)
  }

  let storeList: IStoreInfo[] = []
  let storeInfoMap: { [locationId: number]: IStoreInfo } = {}

  if (
    storeData &&
    storeData.listLocationDetails &&
    Array.isArray(storeData.listLocationDetails.nodes)
  ) {
    storeData.listLocationDetails.nodes.forEach((store: any) => {
      const selectedDirectorEmployeeId = _.get(
        selectedDirector,
        'employeeId',
        1,
      )
      const storeDoEmployeeId = _.get(store, 'directorEmployeeInfo.id')

      const coordinates = {
        latitude: 0,
        longitude: 0,
      }

      if (store.geo) {
        if (store.geo.latitude) {
          coordinates.latitude = parseFloat(store.geo.latitude) || 0
        }
        if (store.geo.longitude) {
          coordinates.longitude = parseFloat(store.geo.longitude) || 0
        }
      }

      let storeDirector: IEmployee | undefined = undefined
      if (store.directorEmployeeInfo) {
        storeDirector = {
          id: store.directorEmployeeInfo.id,
          firstName: store.directorEmployeeInfo.preferredName,
          lastName: store.directorEmployeeInfo.familyName,
        }
      }

      const storeInfo: IStoreInfo = {
        ...store,
        coordinates,
        storeDirector,
      }

      storeInfoMap[store.id] = storeInfo

      // selectedDirectorEmployeeId === -1 === all stores
      const isSelectedStore =
        selectedDirectorEmployeeId === -1 ||
        selectedDirectorEmployeeId === -2 ||
        storeDoEmployeeId === selectedDirectorEmployeeId

      if (isSelectedStore) {
        storeList.push(storeInfo)
      }
    })
  }

  if (_.isEmpty(locationsWithCoordinates)) {
    const extractedLocationWithCoordinates = _.map(
      storeList,
      (storeInfo: IStoreInfo) => {
        return {
          locationId: storeInfo.id,
          locationName: getLocationName(storeInfo.id, storeInfo.name),
          locationCode: getLocationCode(storeInfo.id, storeInfo.code),
          coordinates: {
            latitude: _.get(storeInfo, 'coordinates.latitude') || 0,
            longitude: _.get(storeInfo, 'coordinates.longitude') || 0,
          },
          doEmployeeId: _.get(storeInfo, 'storeDirector.id', 0),
        }
      },
    )
    if (extractedLocationWithCoordinates.length > 0) {
      setLocationsWithCoordinates(extractedLocationWithCoordinates)
    }
  }

  let summaryPerformance: ISummaryPerformance = {
    checkAverage: '-',
    checkCount: '-',
    checkCountVsLyPercent: '-',
    cogsPercent: '-',
    cogsBudgetVariance: '-',
    periodCogsPercent: '-',
    periodCogsBudgetVariance: '-',
    hourlyLaborPerformanceAgainstBudgetPercent: '-',
    hourlyLaborPercent: '-',
    netSales: '-',
    netSalesBudgetVariance: '-',
    grossSales: '-',
    grossSalesBudgetVariance: '-',
    rcpPercent: '-',
    netSalesSssPercent: '-',
    grossSalesSssPercent: '-',
    salesPerLaborHour: '-',
    avtPercent: '-',
    avtPercentWeekly: '-',
    avtPercentMonthly: '-',
    plvPercent: '-',
    sosAvgSeconds: '-',
  }

  if (
    getSummaryKpisData &&
    getSummaryKpisData.listLocationGroupFinancialKpis &&
    Array.isArray(getSummaryKpisData.listLocationGroupFinancialKpis.nodes) &&
    getSummaryKpisData.listLocationGroupFinancialKpis.nodes[0]
  ) {
    const rawSummaryKpis =
      getSummaryKpisData.listLocationGroupFinancialKpis.nodes[0]
    const checkCount = rawSummaryKpis[brandSettings.overview.checkCount.name]
    const checkCountLy = rawSummaryKpis.yoyTotalCheckCount
    const checkAverage =
      rawSummaryKpis[brandSettings.overview.checkAverage.name]
    const cogsPercentValue = rawSummaryKpis.cogsPercent
      ? toPercentString(rawSummaryKpis.cogsPercent, 1)
      : '-'
    const cogsBudgetVarianceValue = rawSummaryKpis.cogsBudgetVariance
      ? toPercentString(rawSummaryKpis.cogsBudgetVariance, 1)
      : '-'
    const periodCogsPercentValue = rawSummaryKpis.periodCogsPercent
      ? toPercentString(rawSummaryKpis.periodCogsPercent, 1)
      : '-'
    const periodCogsBudgetVarianceValue =
      rawSummaryKpis.periodCogsBudgetVariance
        ? toPercentString(rawSummaryKpis.periodCogsBudgetVariance, 1)
        : '-'

    summaryPerformance = {
      checkAverage: checkAverage ? toUsdString(checkAverage / 100.0, 2) : '-',
      checkCount:
        checkCount && parseInt(checkCount, 10)
          ? toFormattedInteger(checkCount)
          : '-',
      checkCountVsLyPercent:
        checkCount &&
        checkCountLy &&
        parseInt(checkCount, 10) &&
        parseInt(checkCountLy, 10)
          ? toPercentString(
              (100.0 * (checkCount - checkCountLy)) / checkCountLy,
            )
          : '-',
      cogsPercent: cogsPercentValue,
      cogsBudgetVariance: cogsBudgetVarianceValue,
      periodCogsPercent: periodCogsPercentValue,
      periodCogsBudgetVariance: periodCogsBudgetVarianceValue,
      hourlyLaborPerformanceAgainstBudgetPercent:
        rawSummaryKpis.hourlyLaborBudgetVariance
          ? toPercentString(rawSummaryKpis.hourlyLaborBudgetVariance, 1)
          : '-',
      hourlyLaborPercent: rawSummaryKpis.hourlyLaborCostPercent
        ? toPercentString(rawSummaryKpis.hourlyLaborCostPercent, 1)
        : '-',
      netSales: rawSummaryKpis.netSales
        ? toUsdString(parseFloat(rawSummaryKpis.netSales) / 100.0)
        : '-',
      netSalesBudgetVariance: ![null, undefined].includes(
        rawSummaryKpis.netSalesBudgetVariance,
      )
        ? toPercentString(rawSummaryKpis.netSalesBudgetVariance, 1)
        : '-',
      grossSales: rawSummaryKpis.grossSales
        ? toUsdString(parseFloat(rawSummaryKpis.grossSales) / 100.0)
        : '-',
      grossSalesBudgetVariance: ![null, undefined].includes(
        rawSummaryKpis.grossSalesBudgetVariance,
      )
        ? toPercentString(rawSummaryKpis.grossSalesBudgetVariance, 1)
        : '-',
      rcpPercent: rawSummaryKpis.rcpPercent
        ? toPercentString(rawSummaryKpis.rcpPercent, 1)
        : '-',
      netSalesSssPercent: rawSummaryKpis.netSalesSssPercent
        ? toPercentString(rawSummaryKpis.netSalesSssPercent, 1)
        : '-',
      grossSalesSssPercent: rawSummaryKpis.grossSalesSssPercent
        ? toPercentString(rawSummaryKpis.grossSalesSssPercent, 1)
        : '-',
      salesPerLaborHour: rawSummaryKpis.salesPerLaborHour
        ? toUsdString(rawSummaryKpis.salesPerLaborHour / 100.0, 2)
        : '-',
      avtPercent: rawSummaryKpis.avtPercent
        ? toPercentString(rawSummaryKpis.avtPercent, 1)
        : '-',
      avtPercentWeekly: rawSummaryKpis.avtPercentWeekly
        ? toPercentString(rawSummaryKpis.avtPercentWeekly, 1)
        : '-',
      avtPercentMonthly: rawSummaryKpis.avtPercentMonthly
        ? toPercentString(rawSummaryKpis.avtPercentMonthly, 1)
        : '-',
      plvPercent: rawSummaryKpis.plvPercent
        ? toPercentString(rawSummaryKpis.plvPercent, 1)
        : '-',
      sosAvgSeconds: rawSummaryKpis.sosAvgSeconds
        ? toFormattedInteger(rawSummaryKpis.sosAvgSeconds)
        : '-',
    }
  }

  let sortedDoBreakdownList: IFormattedDoBreakdownInfo[] | null = null
  let sortedLocationGroupBreakdownList:
    | IFormattedLocationGroupBreakdownInfo[]
    | null = null
  let sortedStoreList: IFormattedStoreInfo[] = []

  if (isBreakdownByDirectors || isBreakdownByLocationGroupType) {
    if (listLocationGroupFinancialKpisLoading) {
      const loading = {
        netSales: 'loading',
        netSalesNumber: 0,
        netSalesPerformanceAgainstBudgetPercent: 'loading',
        netSalesPerformanceAgainstBudgetPercentNumber: 0,
        grossSales: 'loading',
        grossSalesNumber: 0,
        grossSalesPerformanceAgainstBudgetPercent: 'loading',
        grossSalesPerformanceAgainstBudgetPercentNumber: 0,
        ptdNetSalesSss: 'loading',
        netSalesSssPercentNumber: 0,
        grossSalesSssPercentNumber: 0,
        hourlyLaborPercent: 'loading',
        hourlyLaborPercentNumber: 0,
        hourlyLaborPerformanceAgainstBudgetPercent: 'loading',
        hourlyLaborPerformanceAgainstBudgetPercentNumber: 0,
        ptdCogsPercent: 'loading',
        cogsPercentNumber: 0,
        cogsBudgetVariance: 'loading',
        cogsBudgetVarianceNumber: 0,
        periodCogsPercent: 'loading',
        periodCogsPercentNumber: 0,
        periodCogsBudgetVariance: 'loading',
        periodCogsBudgetVarianceNumber: 0,
        avtPercent: 'loading',
        avtPercentNumber: 0,
        avtPercentWeekly: 'loading',
        avtPercentWeeklyNumber: 0,
        avtPercentMonthly: 'loading',
        avtPercentMonthlyNumber: 0,
        plvPercent: 'loading',
        plvPercentNumber: 0,
        rcp: 'loading',
        rcpNumber: 0,
        ptdRcp: 'loading',
        rcpPercentNumber: 0,
        rcpBudgetVariance: 'loading',
        rcpBudgetVarianceNumber: 0,
        totalCheckCount: 0,
        yoyTotalCheckCount: 0,
        totalCheckCountVsLyCount: 0,
        totalCheckCountVsLyPercent: 0,
        sosAvgSeconds: 0,
      }

      if (isBreakdownByDirectors) {
        sortedDoBreakdownList = _.map(allDirectors, (doProfile: IDirector) => {
          return {
            ...loading,
            directorId: doProfile.employeeId,
            directorName: `${doProfile.firstName} ${doProfile.lastName}`,
          }
        })
      }
    }

    if (
      listLocationGroupFinancialKpisData &&
      listLocationGroupFinancialKpisData.listLocationGroupFinancialKpis &&
      Array.isArray(
        listLocationGroupFinancialKpisData.listLocationGroupFinancialKpis.nodes,
      )
    ) {
      sortedDoBreakdownList = []
      sortedLocationGroupBreakdownList = []
      listLocationGroupFinancialKpisData.listLocationGroupFinancialKpis.nodes.forEach(
        (rawDoData: any) => {
          const doProfile = _.find(allDirectors, (d) => {
            return d.locationGroupId === rawDoData.locationGroupId
          })

          const netSalesNumber = parseFloat(rawDoData.netSales)
          const netSalesPerformanceAgainstBudgetPercentNumber = parseFloat(
            rawDoData.netSalesBudgetVariance,
          )
          const grossSalesNumber = parseFloat(rawDoData.grossSales)
          const grossSalesPerformanceAgainstBudgetPercentNumber = parseFloat(
            rawDoData.grossSalesBudgetVariance,
          )
          const netSalesSssPercentNumber = rawDoData.netSalesSssPercent
          const grossSalesSssPercentNumber = rawDoData.grossSalesSssPercent
          const hourlyLaborPercentNumber = rawDoData.hourlyLaborCostPercent
          const hourlyLaborPerformanceAgainstBudgetPercentNumber =
            rawDoData.hourlyLaborBudgetVariance
          const cogsNumber = parseFloat(
            config.usePeriodCogs && rawDoData.periodCogs !== null
              ? rawDoData.periodCogs
              : rawDoData.cogs,
          )
          const cogsPercentNumber = rawDoData.cogsPercent
          const cogsBudgetVarianceNumber = rawDoData.cogsBudgetVariance
          const periodCogsPercentNumber = rawDoData.periodCogsPercentNumber
          const periodCogsBudgetVariance = rawDoData.periodCogsBudgetVariance
          const avtPercentNumber = rawDoData.avtPercent
          const avtPercentWeeklyNumber = rawDoData.avtPercentWeekly
          const avtPercentMonthlyNumber = rawDoData.avtPercentMonthly
          const plvPercentNumber = rawDoData.plvPercent
          const rcpNumber = parseFloat(rawDoData.rcp)
          const rcpPercentNumber = rawDoData.rcpPercent
          const rcpBudgetVarianceNumber = rawDoData.rcpBudgetVariance
          const totalCheckCount = parseInt(rawDoData.totalCheckCount) || 0
          const yoyTotalCheckCount = parseInt(rawDoData.yoyTotalCheckCount) || 0
          const totalCheckCountVsLyCount = totalCheckCount - yoyTotalCheckCount
          const totalCheckCountVsLyPercent =
            yoyTotalCheckCount > 0
              ? ((totalCheckCount - yoyTotalCheckCount) * 100.0) /
                yoyTotalCheckCount
              : '0'
          const sosAvgSeconds = parseInt(rawDoData.sosAvgSeconds) || 0

          const breakdown = {
            netSalesNumber,
            netSalesPerformanceAgainstBudgetPercentNumber,
            grossSalesNumber,
            grossSalesPerformanceAgainstBudgetPercentNumber,
            netSalesSssPercentNumber,
            grossSalesSssPercentNumber,
            hourlyLaborPercentNumber,
            hourlyLaborPerformanceAgainstBudgetPercentNumber,
            cogsPercentNumber,
            cogsBudgetVarianceNumber,
            avtPercentNumber,
            avtPercentWeeklyNumber,
            avtPercentMonthlyNumber,
            plvPercentNumber,
            rcpNumber,
            rcpPercentNumber,
            rcpBudgetVarianceNumber,
            totalCheckCount,
            yoyTotalCheckCount,
            totalCheckCountVsLyCount,
            totalCheckCountVsLyPercent,
            sosAvgSeconds,

            netSales: toUsdString(netSalesNumber / 100.0),
            netSalesPerformanceAgainstBudgetPercent: toPercentString(
              netSalesPerformanceAgainstBudgetPercentNumber,
              1,
            ),
            grossSales: toUsdString(grossSalesNumber / 100.0),
            grossSalesPerformanceAgainstBudgetPercent: toPercentString(
              grossSalesPerformanceAgainstBudgetPercentNumber,
              1,
            ),
            ptdNetSalesSss: toPercentString(netSalesSssPercentNumber, 1),
            ptdGrossSalesSss: toPercentString(grossSalesSssPercentNumber, 1),
            hourlyLaborPercent: toPercentString(hourlyLaborPercentNumber, 1),
            hourlyLaborPerformanceAgainstBudgetPercent: toPercentString(
              hourlyLaborPerformanceAgainstBudgetPercentNumber,
              1,
            ),
            ptdCogsPercent: cogsNumber
              ? toPercentString(cogsPercentNumber, 1)
              : '-',
            cogsBudgetVariance: toPercentString(cogsBudgetVarianceNumber, 1),
            periodCogsPercent: toPercentString(periodCogsPercentNumber, 1),
            periodCogsPercentNumber: periodCogsPercentNumber,
            periodCogsBudgetVariance: toPercentString(
              periodCogsBudgetVariance,
              1,
            ),
            periodCogsBudgetVarianceNumber: periodCogsBudgetVariance,
            avtPercent: toPercentString(avtPercentNumber, 1),
            avtPercentWeekly: toPercentString(avtPercentWeeklyNumber, 1),
            avtPercentMonthly: toPercentString(avtPercentMonthlyNumber, 1),
            plvPercent: toPercentString(plvPercentNumber, 1),
            rcp: rcpNumber ? toUsdString(rcpNumber / 100.0) : '-',
            ptdRcp: rcpNumber ? toPercentString(rcpPercentNumber, 1) : '-',
            rcpBudgetVariance: rcpNumber
              ? toPercentString(rcpBudgetVarianceNumber, 1)
              : '-',
          }

          if (doProfile && sortedDoBreakdownList) {
            sortedDoBreakdownList.push({
              ...breakdown,
              locationGroupId: doProfile.locationGroupId,
              directorId: doProfile.employeeId,
              directorName: `${doProfile.firstName} ${doProfile.lastName}`,
            })
          } else if (sortedLocationGroupBreakdownList) {
            sortedLocationGroupBreakdownList.push({
              ...breakdown,
              locationGroupId: rawDoData.locationGroupId,
              locationGroupName: rawDoData.locationGroupName,
            })
          }
        },
      )
    }

    sortedDoBreakdownList = _.sortBy(sortedDoBreakdownList, [
      'netSalesNumber',
      'grossSalesNumber',
      'directorName',
    ])

    sortedLocationGroupBreakdownList = _.sortBy(
      sortedLocationGroupBreakdownList,
      'netSalesNumber',
      'locationGroupName',
    )
  } else {
    if (listLocationFinancialKpisLoading) {
      sortedStoreList = _.map(storeList, (storeInfo: IStoreInfo) => {
        return {
          storeId: storeInfo.id,
          storeCode: getLocationCode(storeInfo.id, storeInfo.code),
          storeName: getLocationName(storeInfo.id, storeInfo.name),
          directorId: storeInfo.storeDirector ? storeInfo.storeDirector.id : 0,
          directorName: storeInfo.storeDirector
            ? `${storeInfo.storeDirector.firstName} ${storeInfo.storeDirector.lastName}`
            : '',
          netSales: 'loading',
          netSalesNumber: 0,
          netSalesPerformanceAgainstBudgetPercent: 'loading',
          netSalesPerformanceAgainstBudgetPercentNumber: 0,
          grossSales: 'loading',
          grossSalesNumber: 0,
          grossSalesPerformanceAgainstBudgetPercent: 'loading',
          grossSalesPerformanceAgainstBudgetPercentNumber: 0,
          ptdNetSalesSss: 'loading',
          netSalesSssPercentNumber: 0,
          grossSalesSssPercentNumber: 0,
          hourlyLaborPercent: 'loading',
          hourlyLaborPercentNumber: 0,
          hourlyLaborPerformanceAgainstBudgetPercent: 'loading',
          hourlyLaborPerformanceAgainstBudgetPercentNumber: 0,
          ptdCogsPercent: 'loading',
          cogsPercentNumber: 0,
          cogsBudgetVariance: 'loading',
          cogsBudgetVarianceNumber: 0,
          periodCogsPercent: 'loading',
          periodCogsPercentNumber: 0,
          periodCogsBudgetVariance: 'loading',
          periodCogsBudgetVarianceNumber: 0,
          avtPercent: 'loading',
          avtPercentNumber: 0,
          avtPercentWeekly: 'loading',
          avtPercentWeeklyNumber: 0,
          avtPercentMonthly: 'loading',
          avtPercentMonthlyNumber: 0,
          plvPercent: 'loading',
          plvPercentNumber: 0,
          rcp: 'loading',
          rcpNumber: 0,
          ptdRcp: 'loading',
          rcpPercentNumber: 0,
          rcpBudgetVariance: 'loading',
          rcpBudgetVarianceNumber: 0,
          totalCheckCount: 0,
          yoyTotalCheckCount: 0,
          totalCheckCountVsLyCount: 0,
          totalCheckCountVsLyPercent: 0,
          sosAvgSeconds: 0,
        }
      })
    }

    if (
      listLocationFinancialKpisData &&
      listLocationFinancialKpisData.listLocationFinancialKpis &&
      Array.isArray(
        listLocationFinancialKpisData.listLocationFinancialKpis.nodes,
      )
    ) {
      sortedStoreList = []
      listLocationFinancialKpisData.listLocationFinancialKpis.nodes.forEach(
        (rawLocationData: any) => {
          const storeInfo = storeInfoMap[rawLocationData.locationId]
          if (storeInfo) {
            const netSalesNumber = parseFloat(rawLocationData.netSales)
            const netSalesPerformanceAgainstBudgetPercentNumber = parseFloat(
              rawLocationData.netSalesBudgetVariance,
            )
            const grossSalesNumber = parseFloat(rawLocationData.grossSales)
            const grossSalesPerformanceAgainstBudgetPercentNumber = parseFloat(
              rawLocationData.grossSalesBudgetVariance,
            )
            const netSalesSssPercentNumber = rawLocationData.netSalesSssPercent
            const grossSalesSssPercentNumber =
              rawLocationData.grossSalesSssPercent
            const hourlyLaborPercentNumber =
              rawLocationData.hourlyLaborCostPercent
            const hourlyLaborPerformanceAgainstBudgetPercentNumber =
              rawLocationData.hourlyLaborBudgetVariance
            const cogsNumber = parseFloat(
              config.usePeriodCogs && rawLocationData.periodCogs !== null
                ? rawLocationData.periodCogs
                : rawLocationData.cogs,
            )
            const cogsPercentNumber = parseFloat(rawLocationData.cogsPercent)
            const cogsBudgetVarianceNumber = rawLocationData.cogsBudgetVariance
            const periodCogsPercentNumber = parseFloat(
              rawLocationData.periodCogsPercent,
            )
            const periodCogsBudgetVariance =
              rawLocationData.periodCogsBudgetVariance
            const avtPercentNumber = rawLocationData.avtPercent
            const avtPercentWeeklyNumber = rawLocationData.avtPercentWeekly
            const avtPercentMonthlyNumber = rawLocationData.avtPercentMonthly
            const plvPercentNumber = rawLocationData.plvPercent
            const rcpNumber = parseFloat(rawLocationData.rcp)
            const rcpPercentNumber = rawLocationData.rcpPercent
            const rcpBudgetVarianceNumber = rawLocationData.rcpBudgetVariance
            const totalCheckCount =
              parseInt(rawLocationData.totalCheckCount) || 0
            const yoyTotalCheckCount =
              parseInt(rawLocationData.yoyTotalCheckCount) || 0
            const totalCheckCountVsLyCount =
              totalCheckCount - yoyTotalCheckCount
            const totalCheckCountVsLyPercent =
              yoyTotalCheckCount > 0
                ? ((totalCheckCount - yoyTotalCheckCount) * 100.0) /
                  yoyTotalCheckCount
                : 0
            const sosAvgSeconds = rawLocationData.sosAvgSeconds

            sortedStoreList.push({
              storeId: storeInfo.id,
              storeCode: getLocationCode(storeInfo.id, storeInfo.code),
              storeName: getLocationName(storeInfo.id, storeInfo.name),
              directorId: storeInfo.storeDirector
                ? storeInfo.storeDirector.id
                : 0,
              directorName: storeInfo.storeDirector
                ? `${storeInfo.storeDirector.firstName} ${storeInfo.storeDirector.lastName}`
                : '',

              netSalesNumber,
              netSalesPerformanceAgainstBudgetPercentNumber,
              grossSalesNumber,
              grossSalesPerformanceAgainstBudgetPercentNumber,
              netSalesSssPercentNumber,
              grossSalesSssPercentNumber,
              hourlyLaborPercentNumber,
              hourlyLaborPerformanceAgainstBudgetPercentNumber,
              cogsPercentNumber,
              cogsBudgetVarianceNumber,
              avtPercentNumber,
              avtPercentWeeklyNumber,
              avtPercentMonthlyNumber,
              plvPercentNumber,
              rcpNumber,
              rcpPercentNumber,
              rcpBudgetVarianceNumber,
              totalCheckCount,
              yoyTotalCheckCount,
              totalCheckCountVsLyCount,
              totalCheckCountVsLyPercent,
              sosAvgSeconds,

              netSales: toUsdString(netSalesNumber / 100.0),
              netSalesPerformanceAgainstBudgetPercent: toPercentString(
                netSalesPerformanceAgainstBudgetPercentNumber,
                1,
              ),
              grossSales: toUsdString(grossSalesNumber / 100.0),
              grossSalesPerformanceAgainstBudgetPercent: toPercentString(
                grossSalesPerformanceAgainstBudgetPercentNumber,
                1,
              ),
              ptdNetSalesSss: netSalesSssPercentNumber
                ? toPercentString(netSalesSssPercentNumber, 1)
                : '-',
              ptdGrossSalesSss: grossSalesSssPercentNumber
                ? toPercentString(grossSalesSssPercentNumber, 1)
                : '-',
              hourlyLaborPercent: toPercentString(hourlyLaborPercentNumber, 1),
              hourlyLaborPerformanceAgainstBudgetPercent: toPercentString(
                hourlyLaborPerformanceAgainstBudgetPercentNumber,
                1,
              ),
              ptdCogsPercent: cogsNumber
                ? toPercentString(cogsPercentNumber, 1)
                : '-',
              cogsBudgetVariance: toPercentString(cogsBudgetVarianceNumber, 1),
              periodCogsPercent: toPercentString(periodCogsPercentNumber, 1),
              periodCogsPercentNumber: periodCogsPercentNumber,
              periodCogsBudgetVariance: toPercentString(
                periodCogsBudgetVariance,
                1,
              ),
              periodCogsBudgetVarianceNumber: periodCogsBudgetVariance,
              avtPercent: toPercentString(avtPercentNumber, 1),
              avtPercentWeekly: toPercentString(avtPercentWeeklyNumber, 1),
              avtPercentMonthly: toPercentString(avtPercentMonthlyNumber, 1),
              plvPercent: toPercentString(plvPercentNumber, 1),
              rcp: rcpNumber ? toUsdString(rcpNumber / 100.0) : '-',
              ptdRcp: rcpNumber ? toPercentString(rcpPercentNumber, 1) : '-',
              rcpBudgetVariance: rcpNumber
                ? toPercentString(rcpBudgetVarianceNumber, 1)
                : '-',
            })
          }
        },
      )
    }

    sortedStoreList = _.sortBy(sortedStoreList, ['netSalesNumber', 'storeName'])
  }

  const onDirectorChange = (directorEmployeeId: number) => {
    let processedDirectorEmployeeId = 0

    if (directorEmployeeId === -1) {
      // The all stores case

      setSelectedDirector(FIXED_DIRECTORS.all)
      setBreakdownType(null)
      setSelectedLocationGroup(null)
    } else if (directorEmployeeId === -2) {
      // Case: breakdown by directors
      setSelectedDirector(FIXED_DIRECTORS.breakown)
      setBreakdownType(null)
      setSelectedLocationGroup(null)
    } else if (directorEmployeeId < -2) {
      // Case: breakdown by location group
      const locationGroupType =
        directorFilterSelectorConfig.locationGroupTypes.find(
          ({ id }: { id: number }) => id === directorEmployeeId,
        )

      if (locationGroupType) {
        setSelectedDirector({
          ...FIXED_DIRECTORS.all,
          employeeId: locationGroupType.id,
        })

        setBreakdownType(locationGroupType.name)
        setSelectedLocationGroup(null)
      }
    } else {
      // Case: select a DO
      processedDirectorEmployeeId = directorEmployeeId
      const selectedDirector = _.first(
        _.filter(allDirectors, { employeeId: processedDirectorEmployeeId }),
      )
      if (selectedDirector) {
        if (
          config.useBrandLink &&
          selectedDirector.employeeId === selectedDirectorEmployeeId
        ) {
          const brandCode = Object.keys(BRAND_NAME).find(
            (code) => BRAND_NAME[code] === selectedDirector.firstName,
          )

          if (brandCode) {
            history.push(`/${brandCode}`)
            return
          }
        }

        setSelectedDirector(selectedDirector)
        setBreakdownType(null)
        setSelectedLocationGroup({
          id: selectedDirector.locationGroupId,
        })
      }
    }
  }

  if (allDirectors === null) {
    errorMessage =
      'Unexpected Error. Please try again or contact support@getexpo.com. (Cannot fetch directors.)'
  }

  if (
    storeDataError ||
    getSummaryKpisError ||
    listLocationFinancialKpisError ||
    listLocationGroupFinancialKpisError
  ) {
    errorMessage =
      'Unexpected Error. Please try again or contact support@getexpo.com.'
    if (storeDataError && storeDataError.message) {
      errorMessage += ` (${storeDataError.message})`
    }

    if (getSummaryKpisError && getSummaryKpisError.message) {
      errorMessage += ` (${getSummaryKpisError.message})`
    }
    if (
      listLocationFinancialKpisError &&
      listLocationFinancialKpisError.message
    ) {
      errorMessage += ` (${listLocationFinancialKpisError.message})`
    }
    if (
      listLocationGroupFinancialKpisError &&
      listLocationGroupFinancialKpisError.message
    ) {
      errorMessage += ` (${listLocationGroupFinancialKpisError.message})`
    }
  }

  return (
    <Main
      navParams={navParams}
      isLoading={isLoading}
      allDirectors={allDirectors || []}
      selectedDateRange={selectedDateRange}
      selectedDirector={selectedDirector}
      selectedLocationGroupIds={
        isBreakdownByDirectors
          ? (allDirectors || []).map((d) => d.locationGroupId)
          : [selectedLocationGroup?.id || brandLocationGroupId]
      }
      onDirectorChange={onDirectorChange}
      summaryPerformance={summaryPerformance}
      sortedStoreList={sortedStoreList}
      sortedDoBreakdownList={sortedDoBreakdownList}
      sortedLocationGroupBreakdownList={sortedLocationGroupBreakdownList}
      breakdownType={breakdownType}
      handleLocationGroupNameClick={handleLocationGroupNameClick}
      handleGoBack={handleGoBack}
      selectedLocationGroup={selectedLocationGroup}
      errorMessage={errorMessage}
      locationsWithCoordinates={locationsWithCoordinates}
    />
  )
}

export default StoreList
