import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter, useYoyDates } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query lfrListLocationGroupBusinessReview(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        grossSales
        grossSalesSssPercent
        grossSalesBudgetVariance
        hourlyLaborCostPercent
        hourlyLaborBudgetVariance
        cogs
        cogsPercent
        cogsBudgetVariance
        rcp
        rcpPercent
        rcpBudgetVariance
      }
    }

    listLocationGroupBusinessReview(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        compsPercent
        compsVariance
        revPsf
        covers
        coversTarget
        coversVariance
        ppa
        ppaVariance
        primeCostsPercent
        primeCostsVariance
      }
    }
  }
`

const yoyQuery = gql`
  query yoyLfrListLocationGroupBusinessReview(
    $iFilter: JSON!
    $iYoyStartDate: Date
    $iYoyEndDate: Date
  ) {
    yoyListLocationGroupBusinessReview: listLocationGroupBusinessReview(
      iStartDate: $iYoyStartDate
      iEndDate: $iYoyEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        covers
        ppa
        primeCostsPercent
      }
    }
  }
`

export const lfrListLocationGroupBusinessReviewConfigs = {
  grossSales: 'price',
  grossSalesSssPercent: 'percent',
  grossSalesBudgetVariance: 'percent',
  hourlyLaborCostPercent: 'percent',
  hourlyLaborBudgetVariance: 'percent',
  cogs: 'price',
  cogsPercent: 'percent',
  cogsBudgetVariance: 'percent',
  rcp: 'price',
  rcpPercent: 'percent',
  rcpBudgetVariance: 'percent',
  revPsf: 'price',
  compsPercent: 'percent',
  compsVariance: 'percent',
  covers: 'number',
  coversVariance: 'number',
  coversSss: 'number',
  ppa: 'price',
  ppaVariance: 'price',
  ppaSss: 'price',
  primeCostsPercent: 'percent',
  primeCostsVariance: 'percent',
  primeCostsPercentSss: 'percent',
} as const

const getSss = (a?: unknown, b?: unknown) => {
  if (typeof a === 'number' && typeof b === 'number') return a - b

  if (typeof a === 'string' && typeof b === 'string')
    return parseFloat(a) - parseFloat(b)

  return null
}

const useLfrListLocationGroupBusinessReview = () => {
  const { startDate, endDate } = useDateFilter()
  const { yoyStartDate, yoyEndDate } = useYoyDates(startDate, endDate)
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_group_ids: groupFilter?.list?.map((g) => g.id) },
    },
    skip: !startDate || !endDate || !groupFilter || hasGroupBy,
  })
  const { data: yoyData, loading: yoyLoading } = useQuery(yoyQuery, {
    variables: {
      iFilter: { location_group_ids: groupFilter?.list?.map((g) => g.id) },
      iYoyStartDate: yoyStartDate,
      iYoyEndDate: yoyEndDate,
    },
    skip: !yoyStartDate || !yoyEndDate || !groupFilter || hasGroupBy,
  })

  return {
    data: useMemo((): IApiDataType => {
      const listLocationGroupBusinessReview = (data
        ?.listLocationGroupBusinessReview.nodes || []) as {
        locationGroupId: number
        covers: string
        ppa: number
        primeCostsPercent: number
      }[]
      const yoyListLocationGroupBusinessReview = (yoyData
        ?.yoyListLocationGroupBusinessReview.nodes || []) as {
        locationGroupId: number
        covers: string
        ppa: number
        primeCostsPercent: number
      }[]
      const listLocationGroupFinancialKpis = data
        ?.listLocationGroupFinancialKpis.nodes as
        | {
            locationGroupId: number
          }[]
        | undefined

      if (!listLocationGroupFinancialKpis) return null

      return {
        source: listLocationGroupFinancialKpis.map((n) => {
          const customizedData = listLocationGroupBusinessReview.find(
            (c) => c.locationGroupId === n.locationGroupId,
          )
          const yoyCustomizedData = yoyListLocationGroupBusinessReview.find(
            (c) => c.locationGroupId === n.locationGroupId,
          )
          const groupInfo = groupFilter?.list?.find(
            (g) => g.id === n.locationGroupId,
          )

          return {
            ...n,
            ...customizedData,
            coversSss: getSss(
              customizedData?.covers,
              yoyCustomizedData?.covers,
            ),
            ppaSss: getSss(customizedData?.ppa, yoyCustomizedData?.ppa),
            primeCostsPercentSss: getSss(
              customizedData?.primeCostsPercent,
              yoyCustomizedData?.primeCostsPercent,
            ),
            groupInfo,
          }
        }),
      }
    }, [groupFilter, data, yoyData]),
    loading: loading || yoyLoading,
  }
}

export default useLfrListLocationGroupBusinessReview
