import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { IDirector } from 'pared/data/getDirectors'

import {
  IListLocationGroupCashControlDetailsNodeType,
  IListLocationGroupCashControlDetailsType,
  LIST_LOCATION_GROUP_CASH_CONTROL_DETAILS,
} from '../gql'

const useLocationGroupCash = (
  directors: IDirector[],
  locationGroupIds?: number[],
  startDate?: string,
  endDate?: string,
  locationGroupType?: string,
) => {
  let iFilter: any = null
  if (locationGroupType) {
    iFilter = { location_group_types: [locationGroupType] }
  } else if (locationGroupIds && locationGroupIds.length > 0) {
    iFilter = { location_group_ids: [...locationGroupIds] }
  }

  const { data: cashData, loading: cashLocationGroupLoading } =
    useQuery<IListLocationGroupCashControlDetailsType>(
      LIST_LOCATION_GROUP_CASH_CONTROL_DETAILS,
      {
        variables: {
          iFilter,
          iStartDate: startDate,
          iEndDate: endDate,
        },
        skip: !startDate || !endDate || !iFilter,
      },
    )

  const cashLocationGroupData =
    useMemo(() => {
      const rawData = cashData?.listLocationGroupCashControlDetails?.nodes || []

      const tableData = rawData?.map(
        (row: IListLocationGroupCashControlDetailsNodeType) => {
          const locationGroupId = row.locationGroupId
          const director = directors.find(
            (d: IDirector) => d.locationGroupId === locationGroupId,
          )

          const customData = row?.cashControlDetails

          return {
            locationGroupName: row.locationGroupName,
            directorId: director?.directorId || 0,
            directorName: [director?.firstName || '', director?.lastName || '']
              .filter(Boolean)
              .join(' '),
            ...customData,
          }
        },
      )

      return tableData
    }, [
      cashData,
      directors,
      locationGroupIds,
      startDate,
      endDate,
      locationGroupType,
    ]) || []

  return { cashLocationGroupData, cashLocationGroupLoading }
}

export default useLocationGroupCash
