import { useEffect } from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import { feature, page } from 'pared/analytics/user'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import LocationInfo from 'pared/components/LocationInfo/index'
import LocationSelector from 'pared/components/nav/LocationSelector'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'
import ExpoAiLogo from 'pared/images/Expo/expoai.png'
import { scrollToTop } from 'pared/utils/web'

import ExrayTable from './Exray'
import useConfig from './hooks/useConfig'
import useExraySummary from './hooks/useExraySummary'

interface IProps {
  navParams: INavParams
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 0px 0px;
    }
  }

  & > div:first-child {
    @media ${MOBILE_WIDTH} {
      padding: 0px 25px;
    }
  }
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};

  @media ${MOBILE_WIDTH} {
    padding: 10px;
  }
`

const SubtitleContainer = styled.div`
  margin-bottom: 30px;
`

const ExrayContainer = styled.div`
  margin-top: 20px;
  padding: 55px 50px 50px px;
  @media ${MOBILE_WIDTH} {
    padding: 20px;
  }
`

const Bold = styled.div`
  font-family: Lexend-Semibold;
  font-size: 16px;
  margin-bottom: 20px;
`

export default ({ navParams }: IProps) => {
  const brandSettings = getBrandSettings()
  const config = useConfig()
  const storeId = navParams.storeId
  const storeIdNum = parseInt(storeId || '0') || 0
  const locationInfo = useLocationInfo(storeIdNum)
  const locationId = locationInfo?.id

  useEffect(() => {
    if (storeIdNum && navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl, { locationId: storeIdNum })
      feature.used('Scorecard', { locationId: storeIdNum })
    }
  }, [storeIdNum, navParams.pageUrl])

  const storeTitle = `
    ${brandSettings.labels.business.store}:
    ${locationInfo?.displayName}
  `

  const { totalOpportunityCost, data } = useExraySummary(
    config.categories,
    storeIdNum,
  )

  return (
    <>
      <Header>
        <div>
          {locationId ? (
            <h1>{storeTitle}</h1>
          ) : (
            <PageStatusDiv>Loading ...</PageStatusDiv>
          )}
          {locationId ? <LocationInfo locationId={locationId} /> : null}
        </div>
        <Selectors>
          <LocationSelector navParams={navParams} />
        </Selectors>
      </Header>

      <>
        <ExrayContainer>
          <SubtitleContainer>
            <img height={40} src={ExpoAiLogo} alt="Expo AI" />
          </SubtitleContainer>
          <Bold>Total Opportunities: {totalOpportunityCost} Annually</Bold>
          <ExrayTable exrayData={data}></ExrayTable>
        </ExrayContainer>
      </>
    </>
  )
}
