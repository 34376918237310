import { gql, useMutation } from '@apollo/client'
import { useState } from 'react'

import { track } from 'pared/analytics/team'
import { EMPLOYEE_STATUS_ENUM } from 'pared/constants'

import Main from './Main'

const CREATE_EMPLOYEE_STATUS_RECORD = gql`
  mutation CreateEmployeeStatusRecord(
    $employeeId: Int!
    $locationId: Int!
    $roleId: Int!
    $status: EnumEmployeeStatusRecordStatus!
    $recordedBy: Int!
  ) {
    createEmployeeStatusRecord(
      input: {
        employeeStatusRecord: {
          employeeId: $employeeId
          locationId: $locationId
          roleId: $roleId
          status: $status
          recordedBy: $recordedBy
        }
      }
    ) {
      employeeStatusRecord {
        id
      }
    }
  }
`

interface IProps {
  employeeId: number
  employeeName: string
  locationId: number
  roleId: number | null
  editedByEmployeeId: number
  currentEmployeeStatus: string | null
  onRefresh: () => Promise<void>
}

function EmployeeStatusEditButton({
  employeeId,
  employeeName,
  locationId,
  roleId,
  editedByEmployeeId,
  currentEmployeeStatus,
  onRefresh,
}: IProps) {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [newEmployeeStatus, setNewEmployeeStatus] = useState<string | null>(
    null,
  )
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [createEmployeeStatusRecord] = useMutation(
    CREATE_EMPLOYEE_STATUS_RECORD,
  )

  const availableStatusOptions = EMPLOYEE_STATUS_ENUM.filter(
    ({ name, code }) => {
      return name !== currentEmployeeStatus
    },
  )

  const onOpenDialog = () => {
    setIsDialogOpen(true)
  }
  const onCloseDialog = () => {
    setIsDialogOpen(false)
  }
  const onSelectNewStatus = (newStatusCode: string) => {
    setNewEmployeeStatus(newStatusCode)
  }
  const onSaveNewStatus = async () => {
    setIsSaving(true)
    try {
      await createEmployeeStatusRecord({
        variables: {
          employeeId,
          locationId,
          roleId,
          status: newEmployeeStatus,
          recordedBy: editedByEmployeeId,
        },
      })
      track.managerStatusEdited(employeeId)
      await onRefresh()
    } catch (error) {
      console.log(error)
      // mute the error
    }
    setIsDialogOpen(false)
    setIsSaving(false)
  }

  return (
    <Main
      employeeName={employeeName}
      availableStatusOptions={availableStatusOptions}
      newEmployeeStatus={newEmployeeStatus}
      isDialogOpen={isDialogOpen}
      onOpenDialog={onOpenDialog}
      onCloseDialog={onCloseDialog}
      onSelectNewStatus={onSelectNewStatus}
      onSaveNewStatus={onSaveNewStatus}
      isSaving={isSaving}
    />
  )
}

export default EmployeeStatusEditButton
