import WebLargeScreenMain from './WebLargeScreenMain'

interface IProps {
  onClickContinue: (email: string) => Promise<boolean>
  errorMessage: string
  brand: string
  isFirstPage: boolean
  onSetIsFirstPage: (shouldDisplayFirstPage: boolean) => void
  onSetErrorMessage: (message: string) => void
}

function Main({
  brand,
  onClickContinue,
  errorMessage,
  isFirstPage,
  onSetIsFirstPage,
  onSetErrorMessage,
}: IProps) {
  return (
    <WebLargeScreenMain
      onClickContinue={onClickContinue}
      errorMessage={errorMessage}
      brand={brand}
      isFirstPage={isFirstPage}
      onSetIsFirstPage={onSetIsFirstPage}
      onSetErrorMessage={onSetErrorMessage}
    />
  )
}

export default Main
