import { gql } from '@apollo/client'

export interface ISummaryNodeType {
  locationInfo: {
    code: string
    name: string
  }
  amount: number
  employeeDiscountInfos: {
    familyName: string
    preferredName: string
  }[]
  rankPercent: number
}

export interface ISummaryType {
  locationLossPreventionSummary: {
    nodes: ISummaryNodeType[]
  }
}

export interface ISummaryVariablesType {
  iLocationId: number
  iStartDate?: string
  iEndDate?: string
}

export const LOCATION_LOSS_PREVENTION_SUMMARY = gql`
  query LocationLossPreventionSummary(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationId: Int!
  ) {
    locationLossPreventionSummary(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iLocationId: $iLocationId
    ) {
      nodes {
        locationInfo {
          code
          name
        }
        amount
        employeeDiscountInfos
        rankPercent
      }
    }
  }
`

export interface ILossPreventionItemsType {
  type: string
  count: number
  amount: number
  checkPercent: number
}

interface IListLocationLossPreventionKpisNodeType {
  locationInfo: {
    id: number
    code: string
    name: string
  }
  totalComps: number
  compsPercent: number
  cashOverOrShort: number
  oloOverOrShort: number
  inaccurateCashierShiftCount: number
  inaccurateCashierShiftPercent: number
  lossPreventionItems: ILossPreventionItemsType[]
}

export interface IListLocationLossPreventionKpisType {
  listLocationLossPreventionKpis: {
    nodes: IListLocationLossPreventionKpisNodeType[]
  }
}

export const LIST_LOCATION_LOSS_PREVENTION_KPIS = gql`
  query ListLocationLossPreventionKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationLossPreventionKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationInfo {
          id
          code
          name
        }
        totalComps
        compsPercent
        cashOverOrShort
        oloOverOrShort
        inaccurateCashierShiftCount
        inaccurateCashierShiftPercent
        lossPreventionItems
      }
    }
  }
`

export interface IOpsCompsNodeType {
  opsCompsReason: string
  quantity: string
  totalCompsAmount: number
}

export interface IOpsCompsType {
  listLocationOpsCompsBreakdownByReason: {
    nodes: IOpsCompsNodeType[]
  }
}

export interface IOpsCompsVariablesType {
  iLocationId: number
  iStartDate: string
  iEndDate: string
}

export const OPS_COMPS = gql`
  query opsComps($iLocationId: Int!, $iStartDate: Date!, $iEndDate: Date!) {
    listLocationOpsCompsBreakdownByReason(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        opsCompsReason
        quantity
        totalCompsAmount
      }
    }
  }
`

export interface IDiscountsNodeType {
  employeeInfo: {
    id: number
    preferredName: string
    familyName: string
  }
  roleInfo: {
    id: number
    name: string
  }
  totalSales: number
  opsComps: number
  opsCompsPercent?: number
  opsCompsDetails?: {
    type: string
    count: number
    total_comps: number
  }[]
}

export interface IDiscountsType {
  listLocationEmployeeOpsComps: {
    nodes: IDiscountsNodeType[]
  }
}

export interface IDiscountsVariablesType {
  iStartDate: string
  iEndDate: string
  iFilter: any
}

export const DISCOUNTS = gql`
  query discounts($iStartDate: Date!, $iEndDate: Date!, $iFilter: JSON!) {
    listLocationEmployeeOpsComps(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iDayPart: "all"
      iFilter: $iFilter
    ) {
      nodes {
        employeeInfo {
          id
          preferredName
          familyName
        }
        roleInfo {
          id
          name
        }
        totalSales
        opsComps
        opsCompsPercent
        opsCompsDetails
      }
    }
  }
`

export interface ILossPreventionItemDetailsType
  extends ILossPreventionItemsType {
  details: {
    time: string
    checkNumber: string
    amount: number
  }[]
}

export interface IListLocationEmployeeLossPreventionKpisNodeType {
  employeeInfo: {
    id: number
    preferredName: string
    familyName: string
  }
  roleInfo: {
    id: number
    name: string
  }
  cashOverOrShort: number
  inaccurateCashierShiftPercent: number
  lossPreventionItems: ILossPreventionItemDetailsType[]
}

export interface IListLocationEmployeeLossPreventionKpisType {
  listLocationEmployeeLossPreventionKpis: {
    nodes: IListLocationEmployeeLossPreventionKpisNodeType[]
  }
}

export const LIST_LOCATION_EMPLOYEE_LOSS_PREVENTION_KPIS = gql`
  query ListLocationEmployeeLossPreventionKpis(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationEmployeeLossPreventionKpis(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        employeeInfo {
          id
          preferredName
          familyName
        }
        roleInfo {
          id
          name
        }
        cashOverOrShort
        inaccurateCashierShiftPercent
        lossPreventionItems
      }
    }
  }
`
