import Button from '@mui/material/Button'
import { useState } from 'react'

import { getBrand } from 'pared/utils/brand'

const SendEmailButton = ({ email }: { email: string }) => {
  const brand = getBrand()
  const [isSendingEmail, setIsSendingEmail] = useState(false)
  const [hasSentEmail, setHasSentEmail] = useState(false)

  const sendEmail = async () => {
    const shouldSendEmail = window.confirm(
      `Send email containing login details to ${email}?`,
    )
    if (shouldSendEmail) {
      setIsSendingEmail(true)
      await fetch(
        `${process.env.REACT_APP_BE_BASE_URL}/send_new_user_creation_email`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            brand,
          }),
        },
      )
      setIsSendingEmail(false)
      setHasSentEmail(true)
    }

    return false
  }

  const handleClick = async () => {
    await sendEmail()
  }

  return (
    <Button disabled={isSendingEmail || hasSentEmail} onClick={handleClick}>
      Send Email
    </Button>
  )
}

export default SendEmailButton
