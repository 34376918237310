import _ from 'lodash'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import COLORS from 'pared/constants/colors'

interface IProps {
  xAxisData: string[]
  chartOptions: any
  yAxisDataArr: any[]
  isLoading: boolean
}

function Main({ xAxisData, chartOptions, yAxisDataArr, isLoading }: IProps) {
  if (isLoading) {
    return (
      <LoadingContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </LoadingContainer>
    )
  }

  return (
    <MixedChart
      xAxisData={xAxisData}
      yAxisDataArr={yAxisDataArr}
      options={chartOptions}
    />
  )
}

const LoadingContainer = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  height: 400px;
  width: 100%;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

export default Main
