import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { getBrand } from 'pared/utils/brand'
import { getLocationCode, getLocationName } from 'pared/utils/location'
import {
  toDisplayedWaitTime,
  toPercentageString,
  toUsdString,
  usdStringToNumber,
} from 'pared/utils/number'

import {
  LIST_LOCATION_DELIVERY_KPI,
  LIST_LOCATION_GROUP_DELIVERY_KPI,
} from '../gql'
import useDeliveryConfig from '../hooks/useDeliveryConfig'
import { IDetailDeliveryKpi } from '../index'
import Main from './Main'

export interface IOrderBy {
  columnName: string
  isAscending: boolean
}

interface IProps {
  isBreakdownByDirectors: boolean
  startDate: string
  endDate: string
  locationGroupId?: number
  locationGroupIds?: number[]
  breakdownType?: string | null
  locationIds?: number[]
}

function BreakdownTable({
  isBreakdownByDirectors,
  startDate,
  endDate,
  locationGroupId,
  locationGroupIds,
  breakdownType,
  locationIds,
}: IProps) {
  const deliveryConfig = useDeliveryConfig(getBrand())
  const [
    listLocationDeliveryKpi,
    {
      loading: listLocationDeliveryKpiLoading,
      error: listLocationDeliveryKpiError,
      data: listLocationDeliveryKpiData,
    },
  ] = useLazyQuery(
    LIST_LOCATION_DELIVERY_KPI(deliveryConfig.customizedbreakdownByStore),
  )

  const [
    listDoDeliveryKpi,
    {
      loading: listDoDeliveryKpiLoading,
      error: listDoDeliveryKpiError,
      data: listDoDeliveryKpiData,
    },
  ] = useLazyQuery(
    LIST_LOCATION_GROUP_DELIVERY_KPI(deliveryConfig.customizedbreakdownByStore),
  )

  useEffect(() => {
    async function fetchData() {
      if (breakdownType) {
        listDoDeliveryKpi({
          variables: {
            iStartDate: startDate,
            iEndDate: endDate,
            iFilter: {
              location_group_types: [breakdownType],
            },
          },
        })
      } else if (isBreakdownByDirectors) {
        listDoDeliveryKpi({
          variables: {
            iStartDate: startDate,
            iEndDate: endDate,
            iFilter: {
              location_group_ids: locationGroupIds,
            },
          },
        })
      } else {
        const iFilter: any = {}
        if (locationGroupId) {
          iFilter['location_group_ids'] = [locationGroupId]
        } else if (locationIds && locationIds.length > 0) {
          iFilter['location_ids'] = locationIds
        }

        listLocationDeliveryKpi({
          variables: {
            iFilter,
            iStartDate: startDate,
            iEndDate: endDate,
          },
        })
      }
    }

    fetchData()
  }, [
    isBreakdownByDirectors,
    locationGroupId,
    locationGroupIds,
    startDate,
    endDate,
    breakdownType,
  ])

  let itemizedKpis: IDetailDeliveryKpi[] = []

  if (!isBreakdownByDirectors && !breakdownType) {
    if (
      listLocationDeliveryKpiData &&
      listLocationDeliveryKpiData.listLocationDeliveryKpi &&
      Array.isArray(listLocationDeliveryKpiData.listLocationDeliveryKpi.nodes)
    ) {
      const rawLocationDeliveryKpis = _.get(
        listLocationDeliveryKpiData,
        'listLocationDeliveryKpi.nodes',
        [],
      )
      const customizedData = (
        listLocationDeliveryKpiData?.customizedListLocationDelivery?.nodes || []
      ).map((d) =>
        Object.keys(d).reduce((result, key) => {
          switch (key) {
            case 'sumSubtotal':
              return { ...result, [key]: toUsdString(d[key] / 100) }
            default:
              return { ...result, [key]: d[key] }
          }
        }, {}),
      )

      itemizedKpis = _.map(rawLocationDeliveryKpis, (k) => {
        const avgCustomerReviewScore = _.get(k, 'avgCustomerReviewScore', 0)
        const customizedD = customizedData.find(
          (d) => d.locationId === k.locationId,
        )

        const locationId = _.get(k, 'locationId', 1)
        const locationCode = getLocationCode(
          locationId,
          _.get(k, 'locationInfo.code', ''),
        )
        const locationName = getLocationName(
          locationId,
          _.get(k, 'locationInfo.name', ''),
        )

        const averageTripTime = _.get(k, 'averageTripTime', 0)

        return {
          locationId,
          totalOrderCount: _.get(k, 'totalOrderCount', 0) || 0,
          avgDasherWaitTime: toDisplayedWaitTime(
            _.get(k, 'avgDasherWaitTime', 0),
          ),
          avgDasherWaitTimeNumber: _.get(k, 'avgDasherWaitTime', 0),
          sumSubtotal: toUsdString(_.get(k, 'sumSubtotal', 0) / 100),
          sumErrorCharges: toUsdString(_.get(k, 'sumErrorCharges', 0) / 100),
          orderWithAnyIssuePercent: toPercentageString(
            _.get(k, 'orderWithAnyIssuePercent', 0) / 100,
            1,
          ),
          avgCustomerReviewScore: avgCustomerReviewScore
            ? avgCustomerReviewScore.toFixed(1)
            : '-',
          cancelledOrderPercent: toPercentageString(
            _.get(k, 'cancelledOrderPercent', 0) / 100,
            1,
          ),
          delayedOrderPercent: toPercentageString(
            _.get(k, 'delayedOrderPercent', 0) / 100,
            1,
          ),
          inaccurateOrderPercent: toPercentageString(
            _.get(k, 'inaccurateOrderPercent', 0) / 100,
            1,
          ),
          formattedName: `${locationCode} - ${locationName}`,
          averageTripTime:
            averageTripTime === null ? null : (averageTripTime / 60).toFixed(1),
          ...customizedD,
        }
      })
    }
  } else {
    // breakdownByDirectors
    if (
      listDoDeliveryKpiData &&
      listDoDeliveryKpiData.listLocationGroupDeliveryKpi &&
      Array.isArray(listDoDeliveryKpiData.listLocationGroupDeliveryKpi.nodes)
    ) {
      const rawDoDeliveryKpis = _.get(
        listDoDeliveryKpiData,
        'listLocationGroupDeliveryKpi.nodes',
        [],
      )
      const customizedData = (
        listDoDeliveryKpiData?.customizedListLocationGroupDelivery?.nodes || []
      ).map((d) =>
        Object.keys(d).reduce((result, key) => {
          switch (key) {
            case 'sumSubtotal':
              return { ...result, [key]: toUsdString(d[key] / 100) }
            default:
              return { ...result, [key]: d[key] }
          }
        }, {}),
      )

      itemizedKpis = _.map(rawDoDeliveryKpis, (k) => {
        const avgCustomerReviewScore = _.get(k, 'avgCustomerReviewScore', 0)
        const customizedD = customizedData.find(
          (d) => d.locationGroupId === k.locationGroupId,
        )

        const doFirstName = _.get(k, 'doEmployeeInfo.firstName', '')
        const doLastName = _.get(k, 'doEmployeeInfo.lastName', '')
        const doEmployeeId = _.get(k, 'doEmployeeId', 1)
        const locationGroupName = _.get(k, 'locationGroupName', '')

        const base = {
          totalOrderCount: _.get(k, 'totalOrderCount', 0),
          avgDasherWaitTime: toDisplayedWaitTime(
            _.get(k, 'avgDasherWaitTime', 0),
          ),
          avgDasherWaitTimeNumber: _.get(k, 'avgDasherWaitTime', 0),
          sumSubtotal: toUsdString(_.get(k, 'sumSubtotal', 0) / 100),
          sumErrorCharges: toUsdString(_.get(k, 'sumErrorCharges', 0) / 100),
          orderWithAnyIssuePercent: toPercentageString(
            _.get(k, 'orderWithAnyIssuePercent', 0) / 100,
            1,
          ),
          avgCustomerReviewScore: avgCustomerReviewScore
            ? avgCustomerReviewScore.toFixed(1)
            : '-',
          cancelledOrderPercent: toPercentageString(
            _.get(k, 'cancelledOrderPercent', 0) / 100,
            1,
          ),
          delayedOrderPercent: toPercentageString(
            _.get(k, 'delayedOrderPercent', 0) / 100,
            1,
          ),
          inaccurateOrderPercent: toPercentageString(
            _.get(k, 'inaccurateOrderPercent', 0) / 100,
            1,
          ),
          formattedName: `${doFirstName} ${doLastName}`,
          averageTripTime: (_.get(k, 'averageTripTime') / 60).toFixed(1),
        }

        if (isBreakdownByDirectors && doEmployeeId) {
          return {
            ...base,
            employeeId: doEmployeeId,
            formattedName: `${doFirstName} ${doLastName}`,
            ...customizedD,
          }
        }

        return {
          ...base,
          formattedName: locationGroupName,
          ...customizedD,
        }
      })
    }
  }

  const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false)

  const onToggleExpansion = () => {
    setIsTableExpanded(!isTableExpanded)
  }

  const [orderBy, setOrderBy] = useState<IOrderBy>({
    columnName: 'totalOrderCount',
    isAscending: false,
  })

  let rankedItemizedKpis = []
  const columnName = _.get(orderBy, 'columnName', '')
  const ascending = orderBy.isAscending ? 'asc' : 'desc'

  const ascendingCoefficient = orderBy.isAscending ? 1 : -1

  switch (columnName) {
    case 'totalOrderCount':
      rankedItemizedKpis = _.orderBy(
        itemizedKpis,
        ['totalOrderCount'],
        [ascending],
      )
      break
    case 'sumSubtotal':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          usdStringToNumber(_.get(k, 'sumSubtotal', '0')) * ascendingCoefficient
        )
      })
      break
    case 'averageTripTime':
      rankedItemizedKpis = _.orderBy(
        itemizedKpis,
        [
          (k) => {
            return orderBy.isAscending
              ? _.get(k, 'averageTripTime', 0)
              : _.get(k, 'averageTripTime', 0) || 0
          },
        ],
        [ascending],
      )
      break
    case 'sumErrorCharges':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          usdStringToNumber(_.get(k, 'sumErrorCharges', '0')) *
          ascendingCoefficient
        )
      })
      break
    case 'avgDasherWaitTime':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return _.get(k, 'avgDasherWaitTimeNumber', 0) * ascendingCoefficient
      })
      break
    case 'orderWithAnyIssuePercent':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'orderWithAnyIssuePercent', '0')) *
          ascendingCoefficient
        )
      })
      break
    case 'avgCustomerReviewScore':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'avgCustomerReviewScore', '0')) *
          ascendingCoefficient
        )
      })
      break
    default:
      rankedItemizedKpis = _.orderBy(
        itemizedKpis,
        ['totalOrderCount'],
        [ascending],
      )
      break
  }

  return (
    <Main
      itemizedKpis={rankedItemizedKpis}
      isTableExpanded={isTableExpanded}
      onToggleExpansion={onToggleExpansion}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      isBreakdownByDirectors={isBreakdownByDirectors}
      breakdownType={breakdownType}
    />
  )
}

export default BreakdownTable
