import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { getUser } from 'pared/utils/user'

interface IParamsPropsType {
  id: string
}

export interface TrainingTrackerItemsType {
  itemId: number
  itemName: string
  itemOrder: number
  completedAt: string
  traineeInitials: string
  trainerInitials: string
  traineeInitialsDate: string
  trainerInitialsDate: string
  content?: string
  itemType?: string
  itemSecondaryType?: string
  displayText?: string
  currentTraineeLeaderId: number
  listUuid: string
}

export interface TrainingTrackerlistType {
  itemType: string
  items: TrainingTrackerItemsType[]
}

export interface SignedShiftLeaderAssessmentType {
  trainerName: string
  show: boolean
  signedAt: string
}

export interface TrainingTrackerType {
  traineeName: string
  traineeId: string
  trainerName: string
  assignedTracker: string
  assignedAt: string
  showSubmit: boolean
  showApprove: boolean
  showCancel: boolean
  needApprove: boolean
  signedShiftLeaderAssessment: SignedShiftLeaderAssessmentType | null
  list: TrainingTrackerlistType[]
}

const queries = gql`
  query ListTrainingTrackerItems($iListUuid: String!, $iUserId: Int!) {
    listTrainingTrackerFormDetails(iListUuid: $iListUuid) {
      edges {
        node {
          id
          item {
            name
            type
            secondaryType
            order
            itemType
            displayText
          }
          completedAt
          traineeInitials
          trainerInitials
          traineeInitialsDate
          trainerInitialsDate
          content
          trainerId {
            id
            familyName
            preferredName
          }
        }
      }
    }

    getTrainingTrackerStatus(iListUuid: $iListUuid) {
      nodes {
        trainee {
          id
          familyName
          preferredName
        }
        trainer {
          id
          familyName
          preferredName
        }
        assignedTracker
        assignedAt
        needApproved
        approvedAt
        signedShiftLeaderAssessment
        currentTraineeLeader {
          id
          familyName
          preferredName
        }
      }
    }

    getUserDevelopmentTier(iUserId: $iUserId) {
      nodes
    }
  }
`

const useTrainingTrackerItems = () => {
  const user = getUser()
  const userId = user.employeeId ? user.employeeId : -1
  const { id }: IParamsPropsType = useParams()
  const { data, loading } = useQuery(queries, {
    variables: {
      iListUuid: id,
      iUserId: userId,
    },
    skip: !id || !user,
  })

  return {
    data: useMemo((): TrainingTrackerType | null => {
      const trainingTracker = data?.getTrainingTrackerStatus.nodes
      const traineeName = `${
        trainingTracker?.[0].trainee.preferredName || ''
      } ${trainingTracker?.[0].trainee.familyName}`
      const traineeId = trainingTracker?.[0].trainee.id
      const trainerName = trainingTracker?.[0].currentTraineeLeader
        ? `${trainingTracker?.[0].currentTraineeLeader.preferredName} ${trainingTracker?.[0].currentTraineeLeader.familyName}`
        : `${trainingTracker?.[0].trainer.preferredName} ${trainingTracker?.[0].trainer.familyName}`
      const trainerAbbr = `${trainingTracker?.[0].trainer.preferredName[0]}.${trainingTracker?.[0].trainer.familyName[0]}`
      const assignedAt = trainingTracker?.[0].assignedAt
        ? moment(trainingTracker?.[0].assignedAt).format('M/D/YY')
        : ''
      const signedAt = trainingTracker?.[0].signedShiftLeaderAssessment
        ? moment(trainingTracker?.[0].signedShiftLeaderAssessment).format(
            'M/D/YY',
          )
        : ''

      const items = data?.listTrainingTrackerFormDetails.edges

      const sortedItems = [...(items ?? [])].sort((itemA, itemB) => {
        return itemA.node.item.order - itemB.node.item.order
      })

      const trainingTrackerLists = sortedItems?.reduce(
        (list: any, item: any) => {
          const trainerListAbbr = item.node.trainerId
            ? `${item.node.trainerId.preferredName[0]}.${item.node.trainerId.familyName[0]}`
            : trainerAbbr
          const traineeInitials =
            trainingTracker && item.node.traineeInitials
              ? `${trainingTracker[0].trainee.preferredName[0]}.${trainingTracker[0].trainee.familyName[0]}.`
              : ''
          const trainerInitials =
            trainingTracker && item.node.trainerInitials ? trainerListAbbr : ''
          const completedAt = item.node.completedAt
            ? moment(item.node.completedAt).format('M/D/YY')
            : ''

          const traineeInitialsDate = item.node.traineeInitialsDate
            ? moment(item.node.traineeInitialsDate).format('M/D/YY')
            : ''

          const trainerInitialsDate = item.node.trainerInitialsDate
            ? moment(item.node.trainerInitialsDate).format('M/D/YY')
            : ''

          const itemData: TrainingTrackerItemsType = {
            itemId: parseInt(item.node.id),
            itemName: item.node.item.name,
            itemOrder: parseInt(item.node.item.order),
            completedAt: completedAt,
            traineeInitials: traineeInitials,
            trainerInitials: trainerInitials,
            traineeInitialsDate,
            trainerInitialsDate,
            content: item.node.content,
            itemType: item.node.item.itemType,
            itemSecondaryType: item.node.item.secondaryType,
            displayText: item.node.item.displayText,
            currentTraineeLeaderId: trainingTracker?.[0].currentTraineeLeader
              ? trainingTracker?.[0].currentTraineeLeader.id
              : trainingTracker?.[0].trainer.id,
            listUuid: id,
          }

          const itemIndex = list.findIndex(
            (e: TrainingTrackerlistType) => e.itemType === item.node.item.type,
          )
          if (itemIndex === -1) {
            list.push({
              itemType: item.node.item.type,
              items: [itemData],
            })
          } else {
            list[itemIndex].items.push(itemData)
          }

          return list
        },
        [] as TrainingTrackerlistType[],
      )

      const isCompleteTracker =
        !items?.some(
          (item: any) =>
            item.node.item.itemType !== 'no_initials' &&
            item.node.completedAt === null,
        ) && !trainingTracker?.[0].approvedAt

      return {
        traineeName,
        traineeId,
        trainerName,
        assignedTracker: `${trainingTracker?.[0].assignedTracker} Milestone Tracker`,
        assignedAt: assignedAt,
        showSubmit:
          isCompleteTracker &&
          trainingTracker?.[0].assignedTracker !== 'Crew Training',
        showApprove:
          (trainingTracker?.[0].needApproved &&
            data?.getUserDevelopmentTier.nodes[0] < 3) ||
          (isCompleteTracker &&
            !trainingTracker?.[0].approvedAt &&
            trainingTracker?.[0].assignedTracker === 'Crew Training'),
        showCancel:
          !trainingTracker?.[0].needApproved &&
          !trainingTracker?.[0].approvedAt,
        needApprove: trainingTracker?.[0].needApproved,
        signedShiftLeaderAssessment:
          trainingTracker?.[0].assignedTracker === 'Shift Leader'
            ? {
                trainerName: trainerAbbr,
                show: userId === trainingTracker?.[0].trainer.id,
                signedAt: signedAt,
              }
            : null,
        list: trainingTrackerLists,
      }
    }, [data]),
    loading,
  }
}

export default useTrainingTrackerItems
