import { useMediaQuery } from 'react-responsive'

interface IProps {
  children: any
}

export const LargeScreen = ({ children }: IProps) => {
  const isLargeScreen = useMediaQuery({ minWidth: 600 })
  return isLargeScreen ? children : null
}

export const SmallScreen = ({ children }: IProps) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 599 })
  return isSmallScreen ? children : null
}
