import { IRouteType } from '../../types'
import jscBkFlash from './jscBkFlash'
import jscDevelopment from './jscDevelopment'
import jscTrainingTracker from './jscTrainingTracker'

export const routes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Development',
        link: '/:brand/corporate_development',
      },
      {
        title: 'Flash',
        link: '/:brand/flash',
      },
    ],
  },
  {
    title: 'Store',
    children: [
      {
        title: 'Development',
        link: '/:brand/development',
      },
    ],
  },
  {
    title: 'Training Tracker',
    link: '/:brand/training_tracker_profile/:id',
    hideFromNavMenu: true,
  },
  {
    title: 'Training Tracker',
    link: '/:brand/training_tracker_detail/:id',
    hideFromNavMenu: true,
  },
]

export default [jscDevelopment, jscTrainingTracker, jscBkFlash]
