import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { IOptionsType } from '../types'

const queries = gql`
  query locationMetricDefs($metricGroup: String!) {
    locationMetricDefs(condition: { metricGroup: $metricGroup }) {
      nodes {
        name
        code
      }
    }
  }
`

const useFarwestRevenueCenter = () => {
  const { data, loading } = useQuery(queries, {
    variables: {
      metricGroup: 'Expo Module - Revenue Center Sales',
    },
  })

  return {
    data: useMemo(() => {
      const options: IOptionsType = [
        {
          value: 'net_sales',
          label: 'Total',
        },
      ]

      data?.locationMetricDefs.nodes.forEach(
        (d: { name: string; code: string }) => {
          options.push({
            value: d.code,
            label: d.name.replace('Revenue Center Sales - ', ''),
          })
        },
      )

      return options
    }, [data]),
    loading,
  }
}

export default useFarwestRevenueCenter
