import _ from 'lodash'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import COLORS from 'pared/constants/colors'
import { getBrandSettings, getBusinessLabel } from 'pared/customer'

interface IProps {
  xAxisData: string[]
  yAxisLocationData: number[]
  yAxisSystemwideData: number[]
  locationName: string
  isLoading: boolean
}

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

function WebLargeMain({
  xAxisData,
  yAxisLocationData,
  yAxisSystemwideData,
  locationName,
  isLoading,
}: IProps) {
  const brandSettings = getBrandSettings()
  const globalGuestConfig = brandSettings.guest
  const ratingType = globalGuestConfig.momentfeed ? 'Momentfeed' : 'Rating'

  const comparisonGroupType =
    getBusinessLabel('accessGroupType') || 'Systemwide'

  const storeLabel = getBusinessLabel('store')
  const chartOptions = {
    title: `${storeLabel} Level ${ratingType} Stars`,
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: `${ratingType} Stars`,
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let datasetLabel = ''
        let separator = ''
        let yValue = ''
        if (tooltipItemContext.dataset && tooltipItemContext.dataset.label) {
          datasetLabel = tooltipItemContext.dataset.label
          separator = ': '
        }
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          yValue = tooltipItemContext.parsed.y
            ? tooltipItemContext.parsed.y.toFixed(globalGuestConfig.decimal)
            : '-'
        }
        return [datasetLabel, separator, yValue].join('')
      }
      return ''
    },
  }

  return (
    <>
      <ChartTitle>Guest Ratings</ChartTitle>
      {isLoading ? (
        <LoadingContainer>Loading...</LoadingContainer>
      ) : (
        <MixedChart
          xAxisData={xAxisData}
          yAxisDataArr={[
            {
              type: 'line',
              data: yAxisLocationData,
              tooltipLabel: `${locationName} Average ${ratingType} Stars`,
              borderColor: COLORS.Pomodoro,
              backgroundColor: COLORS.Pomodoro,
            },
            {
              type: 'line',
              data: yAxisSystemwideData,
              tooltipLabel: `${comparisonGroupType} Average ${ratingType} Stars`,
              borderColor: COLORS.Plum,
              backgroundColor: COLORS.Plum,
            },
          ]}
          options={chartOptions}
        />
      )}
    </>
  )
}

const ChartTitle = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 30px;
`

const LoadingContainer = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

export default WebLargeMain
