import { gql } from '@apollo/client'

export const TREND_LOCATION_GROUP_SALESMANSHIP_KPIS = gql`
  query TrendLocationGroupSalesmanshipKpis(
    $iLocationGroupId: Int!
    $iEndDate: Date!
    $iGroupBy: String!
    $iUseLocationData: Boolean!
  ) {
    trendLocationGroupSalesmanshipKpis: trendLocationGroupSalesmanshipKpisV2E1(
      iLocationGroupId: $iLocationGroupId
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iUseLocationData: $iUseLocationData
    ) {
      nodes {
        locationGroupId
        startDate
        endDate
        categorizedSalesDetails
        totalSalesDetails
        businessWeek
        businessWeekOfMonth
        businessMonth
        businessQuarter
        businessYear
      }
    }
  }
`

export const LIST_LOCATION_SALESMANSHIP_KPIS = gql`
  query ListLocationSalesmanshipKpis(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
  ) {
    listLocationSalesmanshipKpis: listLocationSalesmanshipKpisV2E1(
      iEndDate: $iEndDate
      iStartDate: $iStartDate
      iFilter: $iFilter
    ) {
      nodes {
        locationInfo {
          id
          code
          name
        }
        categorizedSalesDetails
        totalSalesDetails
      }
    }

    listLocationSpeedOfService(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        sosDetails
      }
    }
  }
`

export const LIST_LOCATION_GROUP_SALESMANSHIP_KPIS = (brand: string) => gql`
  query ListLocationGroupSalesmanshipKpis(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupSalesmanshipKpis: listLocationGroupSalesmanshipKpisV2E1(
      iEndDate: $iEndDate
      iStartDate: $iStartDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        locationGroupName
        categorizedSalesDetails
        totalSalesDetails
      }
    }

    listLocationGroupSpeedOfService(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        sosDetails
      }
    }
  }
`
