import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

const QUERY_PMIX_DATA = gql`
  query ListItemMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iQueryType: String!
    $iFilter: JSON!
  ) {
    listItemMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iQueryType: $iQueryType
      iFilter: $iFilter
    ) {
      nodes {
        itemId
        itemCode
        itemName
        displayParentCategoryCode
        displayParentCategoryName
        metricData
        metricSummaryData
      }
    }
  }
`

const useUnifiedData = (options: any) => {
  const { data, loading } = useQuery(QUERY_PMIX_DATA, options)

  return {
    data: useMemo(() => {
      const unifiedEndpointData = data?.listItemMetricValues?.nodes

      if (!unifiedEndpointData) return null

      //Hide `Other` Category
      const source = unifiedEndpointData.reduce(
        (sourceDate: any, rowData: any) => {
          if (rowData.itemCode.includes('Other')) return sourceDate

          const kpisData: { [key: string]: number } = {}

          for (const key in rowData.metricData) {
            if (rowData.metricData.hasOwnProperty(key)) {
              const newKey = key.replace(/_(.)/g, (_, char) =>
                char.toUpperCase(),
              )
              switch (rowData.metricData[key].unit) {
                case 'DOLLAR':
                case 'PERCENTAGE':
                  kpisData[newKey] = rowData.metricData[key].value * 100
                  break

                default:
                  kpisData[newKey] = rowData.metricData[key].value
                  break
              }
            }
          }

          // we won't show the data if only has location_total_sales
          const metrics = Object.keys(rowData.metricData)
          if (
            metrics.includes('quantity_sold') ||
            metrics.includes('total_sales')
          ) {
            sourceDate.push({
              ...kpisData,
              itemName: rowData.itemName,
              itemCode: rowData.itemCode,
              displayParentCategoryName: rowData.displayParentCategoryName,
              displayParentCategoryCode: rowData.displayParentCategoryCode,
            })
          }

          return sourceDate
        },
        [],
      )

      //Other category summary
      const otherCatogeryData = unifiedEndpointData.reduce(
        (res: any, rowData: any) => {
          if (rowData.itemCode.includes('Other')) {
            for (const key in rowData.metricData) {
              if (rowData.metricData.hasOwnProperty(key)) {
                const newKey = key.replace(/_(.)/g, (_, char) =>
                  char.toUpperCase(),
                )
                switch (rowData.metricData[key].unit) {
                  case 'DOLLAR':
                  case 'PERCENTAGE':
                    if (!res.hasOwnProperty(newKey)) {
                      res[newKey] = rowData.metricData[key].value * 100
                    } else {
                      res[newKey] =
                        res[newKey] + rowData.metricData[key].value * 100
                    }
                    break

                  default:
                    if (!res.hasOwnProperty(newKey)) {
                      res[newKey] = rowData.metricData[key].value
                    } else {
                      res[newKey] = res[newKey] + rowData.metricData[key].value
                    }
                    break
                }
              }
            }
          }

          return res
        },
        {} as { [key: string]: number },
      )

      const [rawSummary] = unifiedEndpointData.filter(
        (rowData: any) => rowData.metricSummaryData != null,
      )
      const summary: { [key: string]: number } = {}
      for (const key in rawSummary?.metricSummaryData) {
        if (rawSummary.metricSummaryData.hasOwnProperty(key)) {
          const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
          switch (rawSummary.metricSummaryData[key].unit) {
            case 'DOLLAR':
            case 'PERCENTAGE':
              summary[newKey] = rawSummary.metricSummaryData[key].value * 100
              break

            default:
              summary[newKey] = rawSummary.metricSummaryData[key].value
              break
          }
        }
      }

      //summary need to remove `other` category part
      const filteredSummary: { [key: string]: number } = {}

      for (const key in summary) {
        if (otherCatogeryData.hasOwnProperty(key)) {
          filteredSummary[key] = summary[key] - otherCatogeryData[key]
        } else {
          filteredSummary[key] = summary[key]
        }
      }

      return {
        source,
        summary: {
          ...filteredSummary,
        },
      }
    }, [data]),
    loading,
  }
}

export default useUnifiedData
