import React from 'react'
import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'
import Table from 'pared/components/basicUi/table'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import useColumns from './hooks/useColumns'
import useDataSource from './hooks/useDataSource'

const rowStyle = `
  &:nth-child(even):not(:last-child) {
    background-color: #E5E5E5;
  }
`

const MobileCotainer = styled.div`
  @media ${MOBILE_WIDTH} {
    margin: 20px 10px 0px;
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const DailyDashboardTable = () => {
  const { dataSource, loading } = useDataSource()
  const columns = useColumns()

  return (
    <MobileCotainer>
      <Spin spinning={loading}>
        <Table
          dataSource={dataSource}
          columns={columns}
          rowKey={(values) => values.storeId}
          rowStyle={rowStyle}
          defaultSortedField="storeCode"
          defaultSortingDirection="ascend"
          csvFileName="DailyDashboard.csv"
        />
      </Spin>
    </MobileCotainer>
  )
}

export default React.memo(DailyDashboardTable)
