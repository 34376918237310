import _ from 'lodash'

import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import { IOrderBy, IStaffSale } from './index'

interface IProps {
  isLoading: boolean
  salesType: string
  setSalesType: any
  staffSales: IStaffSale[]
  orderStaffSales: (
    rawStaffsales: IStaffSale[],
    totalSalesOrPpa: string,
  ) => IStaffSale[]
  isStaffSalesExpanded: boolean
  setIsStaffSalesExpanded: any
  orderBy: IOrderBy
  setOrderBy: any
  salesFilterType: string
  isSalesTypePpa: boolean
  staffSalesRowData: any
  storeTitle: string
  selectedValue: string
  dayPart: string
}

const Main = ({
  isLoading,
  salesType,
  setSalesType,
  staffSales,
  orderStaffSales,
  isStaffSalesExpanded,
  setIsStaffSalesExpanded,
  orderBy,
  setOrderBy,
  salesFilterType,
  isSalesTypePpa,
  staffSalesRowData,
  storeTitle,
  selectedValue,
  dayPart,
}: IProps) => {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          isLoading={isLoading}
          salesType={salesType}
          setSalesType={setSalesType}
          staffSales={staffSales}
          orderStaffSales={orderStaffSales}
          isStaffSalesExpanded={isStaffSalesExpanded}
          setIsStaffSalesExpanded={setIsStaffSalesExpanded}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          salesFilterType={salesFilterType}
          staffSalesRowData={staffSalesRowData}
          storeTitle={storeTitle}
          selectedValue={selectedValue}
          dayPart={dayPart}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          isLoading={isLoading}
          salesType={salesType}
          setSalesType={setSalesType}
          staffSales={staffSales}
          orderStaffSales={orderStaffSales}
          isStaffSalesExpanded={isStaffSalesExpanded}
          setIsStaffSalesExpanded={setIsStaffSalesExpanded}
          salesFilterType={salesFilterType}
          staffSalesRowData={staffSalesRowData}
          dayPart={dayPart}
        />
      </SmallScreen>
    </>
  )
}

export default Main
