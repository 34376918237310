import paredAnalytics from 'pared/analytics/utils/ParedAnalytics'

export const track = {
  async timeFrameYearChanged(newYear: number) {
    await paredAnalytics.track('TimeFrameSelector - Time Frame Year Changed', {
      newYear,
    })
  },

  async timeFrameTypeChanged(newType: string) {
    await paredAnalytics.track('TimeFrameSelector - Time Frame Type Changed', {
      newType,
    })
  },

  async timeFrameRangeChanged(type: string, dateRangeKey: string) {
    await paredAnalytics.track('TimeFrameSelector - Time Frame Range Changed', {
      type,
      dateRangeKey,
    })
  },

  async resetToCurrentPeriodButtonClicked() {
    await paredAnalytics.track(
      'TimeFrameSelector - Reset To Current Period Button Clicked',
    )
  },

  async newTimeFrameConfirmed(
    year: number,
    type: string,
    dateRangeKey: string,
  ) {
    await paredAnalytics.track('TimeFrameSelector - New Time Frame Confirmed', {
      year,
      type,
      dateRangeKey,
    })
  },
}
