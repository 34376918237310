import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import CashControlTrend from 'pared/pages/CorporateLossPrevention/CashControlTrend'
import DiscountTrend from 'pared/pages/CorporateLossPrevention/DiscountTrend'

import { FROM_EXPO } from '../../constants'
import { IMessage } from '../../type'

const VerticalSpacer = styled.div`
  width: 100%;
  height: 50px;
`

export default function handleLossPreventionKpiTrends(responseData: any) {
  if (responseData?.lossPreventionKpiTrends) {
    const messageId = uuidv4()
    const results = responseData.lossPreventionKpiTrends

    const uiComponents: React.ReactNode[] = []

    if (results.discount) {
      if (results.discount.locations) {
        const dlData = results.discount.locations
        uiComponents.push(
          <div>
            <div>{dlData.locationNames.join(', ')}</div>
            <DiscountTrend
              key={`discount.locations.${messageId}`}
              endDate={dlData?.endDate}
              locationIds={dlData?.locationIds}
              isBreakdownByDirectors={dlData?.isBreakdownByDirectors}
            />
          </div>,
        )
      }

      if (results.discount.locationGroups) {
        const dlgData = results.discount.locationGroups
        uiComponents.push(
          <div>
            {uiComponents.length > 0 ? <VerticalSpacer /> : null}
            <div>{dlgData.locationGroupNames.join(', ')}</div>
            <DiscountTrend
              key={`discount.locationGroups.${messageId}`}
              endDate={dlgData?.endDate}
              locationGroupIds={dlgData?.locationGroupIds}
              isBreakdownByDirectors={dlgData?.isBreakdownByDirectors}
            />
          </div>,
        )
      }
    }

    if (results.cashControl) {
      if (results.cashControl.locations) {
        const cclData = results.cashControl.locations
        uiComponents.push(
          <div>
            {uiComponents.length > 0 ? <VerticalSpacer /> : null}
            <div>{cclData.locationNames.join(', ')}</div>
            <CashControlTrend
              key={`cashControl.locations.${messageId}`}
              endDate={cclData?.endDate}
              locationIds={cclData?.locationIds}
              isBreakdownByDirectors={cclData?.isBreakdownByDirectors}
              shouldDisplayAll={true}
            />
          </div>,
        )
      }

      if (results.cashControl.locationGroups) {
        const cclgData = results.cashControl.locationGroups
        uiComponents.push(
          <div>
            {uiComponents.length > 0 ? <VerticalSpacer /> : null}
            <div>{cclgData.locationGroupNames.join(', ')}</div>
            <CashControlTrend
              key={`cashControl.locationGroups.${messageId}`}
              endDate={cclgData?.endDate}
              locationGroupIds={cclgData?.locationGroupIds}
              isBreakdownByDirectors={cclgData?.isBreakdownByDirectors}
              shouldDisplayAll={true}
            />
          </div>,
        )
      }
    }

    if (uiComponents.length > 0) {
      const answerMessage: IMessage = {
        id: messageId,
        from: FROM_EXPO,
        content: <div>{uiComponents}</div>,
      }

      return answerMessage
    }
  }

  return null
}
