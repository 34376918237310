import React from 'react'
import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/constants/styles'

import MessageBlock from './MessageBlock'
import StoreTable from './StoreTable'
import useFetchSummaryData from './hooks/useFetchSummaryData'

const SummaryContainer = styled.div<{
  width: number | null
}>`
  width: ${({ width }) => width ?? 900}px;
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

const VerticalSpacer30px = styled.div`
  width: 100%;
  height: 30px;
`

interface IProps {
  summaryType: string
  locationGroupId: number
  brandLocationGroupId: number
  startDate: string
  endDate: string
  isForExray?: boolean
}

const Summary = ({
  summaryType,
  locationGroupId,
  brandLocationGroupId,
  startDate,
  endDate,
  isForExray,
}: IProps) => {
  const summaryQueryData: any = useFetchSummaryData(
    summaryType,
    locationGroupId,
    brandLocationGroupId,
    startDate,
    endDate,
  )

  if (summaryQueryData?.data) {
    return (
      <SummaryContainer width={isForExray ? 600 : null}>
        {isForExray ? null : (
          <>
            <MessageBlock
              selectedLocationGroupId={locationGroupId}
              startDate={startDate}
              endDate={endDate}
              summaryData={summaryQueryData}
            />
            <VerticalSpacer30px />
          </>
        )}
        <StoreTable data={summaryQueryData} />
      </SummaryContainer>
    )
  } else {
    return null
  }
}

export default React.memo(Summary)
