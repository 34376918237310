import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Modal from '@material-ui/core/Modal'
import CancelIcon from '@material-ui/icons/Cancel'
import React from 'react'
import styled from 'styled-components'

interface IProps {
  isFeedbackFormVisible: boolean
  setIsFeedbackFormVisible: any
  setFeedbackNote: any
  isFeedbackSubmitted: boolean
  onSubmitFeedback: any
  userFullName: string
}

function Main({
  isFeedbackFormVisible,
  setIsFeedbackFormVisible,
  setFeedbackNote,
  isFeedbackSubmitted,
  onSubmitFeedback,
  userFullName,
}: IProps) {
  const handleChange = (event: any) => {
    setFeedbackNote(event.target.value)
  }

  const handleClose = () => {
    setIsFeedbackFormVisible(false)
  }

  const screenshotsDescription =
    'If you have any screenshots please email them to '

  const feedbackContent = isFeedbackSubmitted ? (
    <ModalTitleContainer>
      <ModalTitle>Feedback submitted. Thank you!</ModalTitle>
      <CancelIconButton onClick={handleClose}>
        <CancelIcon />
      </CancelIconButton>
    </ModalTitleContainer>
  ) : (
    <>
      <ModalTitleContainer>
        <ModalTitle>Give Feedback</ModalTitle>
        <CancelIconButton onClick={handleClose}>
          <CancelIcon />
        </CancelIconButton>
      </ModalTitleContainer>
      <NameDescription>{`Your Name: ${userFullName}`}</NameDescription>
      <div>Details</div>
      <DetailsTextarea onChange={handleChange}></DetailsTextarea>
      <div>
        {screenshotsDescription}
        <a href="mailto:support@getexpo.com">support@getexpo.com</a>
      </div>
      <ButtonsContainer>
        <CancelButton color="primary" onClick={handleClose}>
          Cancel
        </CancelButton>
        <Button variant="contained" color="primary" onClick={onSubmitFeedback}>
          Submit
        </Button>
      </ButtonsContainer>
    </>
  )

  return (
    <Modal
      open={isFeedbackFormVisible}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <FeedbackModalContainer>{feedbackContent}</FeedbackModalContainer>
    </Modal>
  )
}

const FeedbackModalContainer = styled.div`
  width: 50%;
  min-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0 20px 20px 20px;
`

const ModalTitleContainer = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
`

const ModalTitle = styled.div`
  display: inline-block;
`

const NameDescription = styled.div`
  margin-bottom: 10px;
`

const CancelIconButton = styled(IconButton)`
  float: right;
  margin-top: -15px;
`

const DetailsTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  resize: none;
  margin-bottom: 10px;
`

const ButtonsContainer = styled.div`
  float: right;
`

const CancelButton = styled(Button)`
  margin-right: 15px;
`

export default Main
