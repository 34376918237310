import { IRouteType } from '../../types'
import lfrEfSystemwideOverview from './lfrEfSystemwideOverview'

export const routes: IRouteType[] = [
  {
    title: 'Brand',
    children: [
      {
        title: 'Systemwide Overview',
        link: '/:brand/systemwide_overview',
        default: true,
      },
    ],
  },
]

export default [lfrEfSystemwideOverview]
