import { ApolloError, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

import { LIST_LOCATION_GROUP_SALESMANSHIP_KPIS } from '../gql'
import useSalesmanshipConfig from './useSalesmanshipConfig'

const useLocationGroupSalesmanshipKpiData = (
  locationGroupIds: number[] | null,
  locationGroupTypes: string[] | null,
  startDate: string,
  endDate: string,
  isFetching: boolean = true,
): {
  isLoading: boolean
  data: any[] | null
  sosData: any[] | null
  error: ApolloError | null
} => {
  const salesmanshipConfig = useSalesmanshipConfig()
  const { data, error, loading } = useQuery(
    LIST_LOCATION_GROUP_SALESMANSHIP_KPIS(getBrand()),
    {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: {
          location_group_ids: locationGroupIds,
          location_group_types: locationGroupTypes,
          use_location_data: !!salesmanshipConfig.useLocationData,
        },
      },
      skip:
        !isFetching ||
        (!locationGroupIds && !locationGroupTypes) ||
        !startDate ||
        !endDate,
    },
  )

  return useMemo(() => {
    if (
      data &&
      data.listLocationGroupSalesmanshipKpis &&
      data.listLocationGroupSpeedOfService &&
      Array.isArray(data.listLocationGroupSalesmanshipKpis.nodes) &&
      Array.isArray(data.listLocationGroupSpeedOfService.nodes)
    ) {
      return {
        isLoading: loading,
        data: [...data.listLocationGroupSalesmanshipKpis.nodes],
        sosData: [...data.listLocationGroupSpeedOfService.nodes],
        error: null,
      }
    } else {
      return {
        isLoading: loading,
        data: null,
        sosData: null,
        error: error || null,
      }
    }
  }, [data, locationGroupIds, startDate, endDate])
}

export default useLocationGroupSalesmanshipKpiData
