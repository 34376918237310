import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { IApiDataType } from '../types'

const query = gql`
  query ListYartiProperties($iCustomizedReportInputParams: JSON!) {
    getCustomizedReport(
      iCustomizedReportName: "LIST_YARTI_PROPERTIES"
      iInputParams: $iCustomizedReportInputParams
    ) {
      nodes {
        reportResult
      }
    }
  }
`

export const lfrYardiConfigs = {
  ref: 'string',
  property: 'string',
  acquired: 'string',
  loanAmt: 'price',
  balance: 'price',
  availableToDraw: 'string',
  monthlyPayment: 'price',
  interest: 'percent',
  principal: 'price',
  pmtDue: 'string',
  rateTerms: 'string',
  rate: 'percent',
  interestRateFloor: 'string',
  terms: 'string',
  loanOriginationDate: 'string',
  ioUntil: 'string',
  semiPerm: 'string',
  maturity: 'string',
  collateral: 'string',
  lenderBank: 'string',
  contact: 'string',
  acct: 'string',
  stabilized: 'price',
  valuation: 'price',
  loanBal: 'price',
  equity: 'price',
  cashBal: 'price',
  equityCash: 'price',
  grossRents: 'price',
  opExp: 'price',
  noi: 'price',
  ds: 'price',
  cashFlow: 'price',
  dscr: 'number',
  debtYield: 'percent',
} as const

const useLfrYardi = () => {
  const { startDate, endDate } = useDateFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iCustomizedReportInputParams: {
        startDate,
        endDate,
      },
    },
    skip: !startDate || !endDate,
  })

  return {
    data: useMemo((): IApiDataType => {
      const customizedReportData =
        data?.getCustomizedReport.nodes[0].reportResult?.tableData || []

      const total = customizedReportData.reduce(
        (sum: any, row: any) => ({
          loanAmt: sum.loanAmt + (row?.loanAmt || 0),
          balance: sum.balance + (row?.balance || 0),
          monthlyPayment: sum.monthlyPayment + (row?.monthlyPayment || 0),
          principal: sum.principal + (row?.principal || 0),
          stabilized: sum.stabilized + (row?.stabilized || 0),
          valuation: sum.valuation + (row?.valuation || 0),
          loanBal: sum.loanBal + (row?.loanBal || 0),
          equity: sum.equity + (row?.equity || 0),
          cashBal: sum.cashBal + (row?.cashBal || 0),
          equityCash: sum.equityCash + (row?.equityCash || 0),
          grossRents: sum.grossRents + (row?.grossRents || 0),
          opExp: sum.opExp + (row?.opExp || 0),
          noi: sum.noi + (row?.noi || 0),
          ds: sum.ds + (row?.ds || 0),
          cashFlow: sum.cashFlow + (row?.cashFlow || 0),
        }),
        {
          loanAmt: 0,
          balance: 0,
          monthlyPayment: 0,
          principal: 0,
          stabilized: 0,
          valuation: 0,
          loanBal: 0,
          equity: 0,
          cashBal: 0,
          equityCash: 0,
          grossRents: 0,
          opExp: 0,
          noi: 0,
          ds: 0,
          cashFlow: 0,
        },
      )

      return {
        source: customizedReportData,
        summary: {
          ref: 'Sub-Total',
          acquired: '',
          availableToDraw: '',
          pmtDue: '',
          rateTerms: '',
          interestRateFloor: '',
          loanOriginationDate: '',
          ioUntil: '',
          maturity: '',
          collateral: '',
          lenderBank: '',
          contact: '',
          acct: '',
          ...total,
        },
      }
    }, [data]),
    loading: loading,
  }
}

export default useLfrYardi
