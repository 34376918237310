import { IConfigsType } from '../../types'

const fwWingstopGroupManagementConfig: IConfigsType = {
  'fw_wingstop-admin': {
    '/:brand/groups': {
      timeClockTable: {
        title: 'Manage Groups',
        type: 'table-v2',
        api: 'locationGroups',
        columns: [
          {
            key: 'locationGroupType',
            title: 'Group Type',
            align: 'left',
            sortable: true,
          },
          {
            key: 'locationGroupName',
            title: 'Group Name',
            align: 'left',
            width: '250px',
            sortable: true,
          },
          {
            key: 'locationCode',
            title: 'Location Code',
            align: 'left',
            sortable: true,
          },
          {
            key: 'locationName',
            title: 'Location Name',
            align: 'left',
            width: '250px',
            sortable: true,
          },
        ],
      },
    },
  },
}

export default fwWingstopGroupManagementConfig
