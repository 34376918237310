import { IRouteType } from '../../types'
import burgerworksFlash from './burgerworksFlash'
import burgerworksLossPreventionConfig from './burgerworksLossPrevention'
import burgerworksSystemwideOverview from './burgerworksSystemwideOverview'

export const routes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Systemwide Overview',
        link: '/:brand/systemwide_overview',
        default: true,
      },
      {
        title: 'Flash',
        link: '/:brand/flash',
        default: true,
      },
      {
        title: 'Loss Prevention',
        link: '/:brand/corporate_loss_prevention',
        default: true,
      },
    ],
  },
  {
    title: 'Store',
    children: [
      {
        title: 'Loss Prevention',
        link: '/:brand/loss_prevention',
      },
    ],
  },
]

export default [
  burgerworksSystemwideOverview,
  burgerworksLossPreventionConfig,
  burgerworksFlash,
]
