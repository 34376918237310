import { useMemo } from 'react'

interface ILocationAccessGroup {
  id: number
  name: string
  type: string
}

const useLocationAccessGroupOptions = (
  locationAccessGroups: ILocationAccessGroup[],
) => {
  const locationAccessGroupOptions = useMemo(() => {
    const options = [
      {
        id: 'root',
        parentId: null,
        value: 'root',
        text: 'root',
      },
      {
        id: '',
        parentId: 'root',
        value: '',
        text: 'Select Group',
      },
    ]

    let parentType = null
    for (let i = 0; i < locationAccessGroups.length; i++) {
      const group = locationAccessGroups[i]
      const type = group.type
      const id = `${group.id}`
      const name = group.name

      if (type === 'null') {
        options.push({
          id,
          parentId: 'root',
          value: id,
          text: name,
        })

        continue
      }

      if (parentType !== type) {
        parentType = type
        options.push({
          id: type,
          parentId: 'root',
          value: type,
          text: type,
        })
      }

      options.push({
        id,
        parentId: type,
        value: id,
        text: name,
      })
    }

    return options
  }, [locationAccessGroups])

  return locationAccessGroupOptions
}

export default useLocationAccessGroupOptions
