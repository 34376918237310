import { getIsQsr } from './general'

export interface ISalesmanshipLabels {
  grossSales: string
  totalSales: string
  appetizerSales: string
  lbwSales: string
  dessertSales: string
  ppa: string
  ppaFull: string
  totalPpa: string
  appetizerPpa: string
  lbwPpa: string
  dessertPpa: string
  speedOfService: string
  speedOfServiceAvg: string
  salesPerLaborHour: string
  checkAvg: string
  staffSales: string
}

export function getSalesmanshipLabels(brand: string): ISalesmanshipLabels {
  if (brand === 'papajohns' || brand === 'papajohns_demo') {
    return {
      totalSales: 'Total Sales',
      dessertSales: 'Breadsticks Sales',
      speedOfService: 'Speed of Service',
      speedOfServiceAvg: 'Speed of Service Avg',
      salesPerLaborHour: 'Sales Per Labor Hour',
      checkAvg: 'Check Average',
      grossSales: 'Gross Sales',
      appetizerSales: 'Sides Sales',
      lbwSales: 'Pizza Sales',
      ppa: 'Check Average',
      ppaFull: 'Check Average',
      totalPpa: 'Total Check Avg',
      appetizerPpa: 'Sides Check Avg',
      lbwPpa: 'Pizza Check Avg',
      dessertPpa: 'Breadsticks Check Avg',
      staffSales: 'Employees and Channels',
    }
  }

  const isQsr = getIsQsr(brand)
  const samePart = {
    totalSales: 'Total Sales',
    dessertSales: 'Dessert Sales',
    speedOfService: 'Speed of Service',
    speedOfServiceAvg: 'Speed of Service Avg',
    salesPerLaborHour: 'Sales Per Labor Hour',
    checkAvg: 'Check Average',
    staffSales: 'Overall Staff at a Glance',
  }
  let diffPart = {
    grossSales: 'Gross Sales (In Unit)',
    appetizerSales: 'Appetizer Sales',
    lbwSales: 'LBW Sales',
    ppa: 'PPA',
    ppaFull: 'Per Person Average',
    totalPpa: 'Total PPA',
    appetizerPpa: 'Appetizer PPA',
    lbwPpa: 'LBW PPA',
    dessertPpa: 'Dessert PPA',
  }
  if (isQsr) {
    diffPart = {
      grossSales: 'Gross Sales',
      appetizerSales: 'LTO Sales',
      lbwSales: 'Drink Sales',
      ppa: 'Check Average',
      ppaFull: 'Check Average',
      totalPpa: 'Total Check Avg',
      appetizerPpa: 'LTO Check Avg',
      lbwPpa: 'Drink Check Avg',
      dessertPpa: 'Dessert Check Avg',
    }
  }

  const salesmashipLabels = {
    ...samePart,
    ...diffPart,
  }

  switch (brand) {
    case 'jsc_bk':
    case 'jsc_bk_nso':
      return {
        ...salesmashipLabels,
        speedOfServiceAvg: 'Window Time (seconds)',
      }
    case 'timoney_bk':
      return {
        ...salesmashipLabels,
        speedOfServiceAvg: 'Average Pickup Window Time (seconds)',
      }
    default:
    // do nothing
  }

  return salesmashipLabels
}
