import useListEmployeeDiscountData, {
  fwWingstopListEmployeeDiscountConfigs,
} from './lossPrevention/useListEmployeeDiscountData'
import useListEmployeeLossPreventionData, {
  fwWingstopListEmployeeLossPreventionConfigs,
} from './lossPrevention/useListEmployeeLossPreventionData'
import useLocationOpsCompsBreakdownData, {
  fwWingstopLocationOpsCompsBreakdownDataConfigs,
} from './lossPrevention/useLocationOpsCompsBreakdownData'
import useFwWingstopLossPrevention, {
  fwWingstopLossPreventionConfigs,
} from './lossPrevention/useLossPrevention'
import useLastTwentyEightDaysTrendData, {
  fwWingstopLastTwentyEightDaysTrendDataConfigs,
} from './useLastTwentyEightDaysTrendData'
import useTimeClock, { fwWingstopTimeClockConfigs } from './useTimeClock'

export const configs = {
  fwWingstopTimeClock: fwWingstopTimeClockConfigs,
  fwWingstopLastTwentyEightDaysTrendData:
    fwWingstopLastTwentyEightDaysTrendDataConfigs,
  fwWingstopLossPrevention: fwWingstopLossPreventionConfigs,
  fwWingstopLocationOpsCompsBreakdownData:
    fwWingstopLocationOpsCompsBreakdownDataConfigs,
  fwWingstopListEmployeeLossPrevention:
    fwWingstopListEmployeeLossPreventionConfigs,
  fwWingstopListEmployeeDiscount: fwWingstopListEmployeeDiscountConfigs,
}

export const api = {
  fwWingstopTimeClock: useTimeClock,
  fwWingstopLastTwentyEightDaysTrendData: useLastTwentyEightDaysTrendData,
  fwWingstopLossPrevention: useFwWingstopLossPrevention,
  fwWingstopLocationOpsCompsBreakdownData: useLocationOpsCompsBreakdownData,
  fwWingstopListEmployeeLossPrevention: useListEmployeeLossPreventionData,
  fwWingstopListEmployeeDiscount: useListEmployeeDiscountData,
}
