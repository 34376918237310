import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import CustomizedReport from 'pared/components/CustomizedReport'
import LocationInfo from 'pared/components/LocationInfo'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import LocationSelector from 'pared/components/nav/LocationSelector'
import COLORS from 'pared/constants/colors'
import {
  IDateRange,
  TYPE_PERIOD,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'

import Chart from './Chart'
import DayTrends from './DayTrends'
import DisputeTable from './DisputeTable'
import MissingItemTable from './MissingItemTable'
import StaffTable from './StaffTable'
import Summary from './Summary'
import useDeliveryConfig from './hooks/useDeliveryConfig'
import { ILocationData, ILocationDeliveryKpi } from './index'

interface IProps {
  navParams: INavParams
  locationDeliveryKpiSummary: ILocationDeliveryKpi
  selectedDateRange: IDateRange | null
  locationId: number
  onSelectDayTrendsDayPart: (event: any) => null
  dayTrendsDayPart: string
  dayTrendsInfo: any[]
  onSelectDayTrendsCategory: (event: any) => void
  dayTrendsCategory: string
  selectedLocation: ILocationData
}

interface IKpiTh {
  danger?: boolean
  success?: boolean
  book?: boolean
  isBackgroundGrey?: boolean
}

function WebLargeMain({
  navParams,
  locationDeliveryKpiSummary,
  selectedDateRange,
  locationId,
  onSelectDayTrendsDayPart,
  dayTrendsDayPart,
  dayTrendsInfo,
  onSelectDayTrendsCategory,
  dayTrendsCategory,
  selectedLocation,
}: IProps) {
  const totalStoreCount = _.get(
    locationDeliveryKpiSummary,
    'totalStoreCount',
    0,
  )
  const brand = getBrand()
  const deliveryConfig = useDeliveryConfig(brand)

  const director = {
    name: selectedLocation.doName,
    id: selectedLocation.doEmployeeId,
  }
  const gm = {
    name: selectedLocation.gmName,
    id: selectedLocation.gmEmployeeId,
  }

  return (
    <MainContainer>
      <PageHeaderLineContainer>
        <PageTopContainer>
          <PageLeftHeader>Delivery</PageLeftHeader>
          <SubtitleContainer>Updated Weekly</SubtitleContainer>
        </PageTopContainer>
        <SelectorContainer>
          <LocationSelectorContainer>
            <LocationSelector navParams={navParams} />
          </LocationSelectorContainer>
          <DateRangeSelector
            navParams={navParams}
            excludedDateRanges={[TYPE_YESTERDAY]}
            preSelectedDateRange={TYPE_PERIOD}
          />
        </SelectorContainer>
      </PageHeaderLineContainer>
      <LocationInfo director={director} gm={gm} />
      <VerticalSpacer30px />
      {deliveryConfig.isSummaryHidden ? null : (
        <Summary
          startDateStr={selectedDateRange?.startDateStr}
          endDateStr={selectedDateRange?.endDateStr}
          locationId={locationId}
          locationName={selectedLocation?.name}
        />
      )}
      <VerticalSpacer30px />
      <KpiContainer>
        <KpiLeftContainer>
          <KpiOverviewTable>
            <tbody>
              <tr>
                <PtdKpiCategoryTd>
                  <PtdKpiCategoryNameDiv>KPIs</PtdKpiCategoryNameDiv>
                </PtdKpiCategoryTd>
                <PtdOverviewSpacerTd></PtdOverviewSpacerTd>
              </tr>
              <tr>
                <td>
                  <PtdKpiTable>
                    <thead>
                      <tr>
                        {deliveryConfig['realTimeKpis'].map(
                          ({ variableName }: { variableName: string }) => {
                            const value = _.get(
                              locationDeliveryKpiSummary,
                              variableName,
                              '',
                            )
                            return (
                              <KpiTh book={value === 'loading'}>{value}</KpiTh>
                            )
                          },
                        )}
                        {deliveryConfig.oloWaitTime?.isVisible ? (
                          <KpiTh>
                            <CustomizedReport
                              reportName="STORE_DELIVERY_OLO_WAIT_TIME"
                              inputParams={{
                                locationId,
                                startDate:
                                  selectedDateRange?.startDateStr || '',
                                endDate: selectedDateRange?.endDateStr || '',
                              }}
                            />
                          </KpiTh>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {deliveryConfig['realTimeKpis'].map(
                          ({ displayName }: { displayName: string }) => {
                            return <td>{displayName}</td>
                          },
                        )}
                        {deliveryConfig.oloWaitTime?.isVisible ? (
                          <td>{deliveryConfig.oloWaitTime?.displayName}</td>
                        ) : null}
                      </tr>
                    </tbody>
                  </PtdKpiTable>
                </td>
              </tr>
            </tbody>
          </KpiOverviewTable>

          <VerticalSpacer30px />

          <RankingOverviewTable>
            <tbody>
              <tr>
                <PtdKpiCategoryTd>
                  <PtdKpiCategoryStoreRankingDiv>
                    Store Ranking
                  </PtdKpiCategoryStoreRankingDiv>
                  <PtdKpiCategoryNameSubtitleDiv>{`(out of ${totalStoreCount} stores)`}</PtdKpiCategoryNameSubtitleDiv>
                </PtdKpiCategoryTd>
              </tr>
              <tr>
                <td>
                  <PtdKpiTable>
                    <thead>
                      <tr>
                        {deliveryConfig['rankings'].map(
                          ({ variableName }: { variableName: string }) => {
                            const value = _.get(
                              locationDeliveryKpiSummary,
                              variableName,
                              '',
                            )
                            return (
                              <KpiTh book={value === 'loading'}>{value}</KpiTh>
                            )
                          },
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {deliveryConfig['rankings'].map(
                          ({ displayName }: { displayName: string }) => {
                            return <td>{displayName}</td>
                          },
                        )}
                      </tr>
                    </tbody>
                  </PtdKpiTable>
                </td>
              </tr>
            </tbody>
          </RankingOverviewTable>
        </KpiLeftContainer>
        {deliveryConfig['breakdownOfIssues'] ? (
          <BreakdownContainer>
            <tbody>
              <tr>
                <PtdKpiCategoryTd>
                  <PtdKpiCategoryBreakdownDiv>
                    Breakdown of Issues
                  </PtdKpiCategoryBreakdownDiv>
                </PtdKpiCategoryTd>
              </tr>
              <tr>
                <td>
                  <table>
                    {deliveryConfig['breakdownOfIssues'].map(
                      ({
                        variableName,
                        displayName,
                      }: {
                        variableName: string
                        displayName: string
                      }) => {
                        const value = _.get(
                          locationDeliveryKpiSummary,
                          variableName,
                          '',
                        )
                        return (
                          <tr>
                            <KpiTd book={value === 'loading'}>{value}</KpiTd>
                            <td>{displayName}</td>
                          </tr>
                        )
                      },
                    )}
                  </table>
                </td>
              </tr>
            </tbody>
          </BreakdownContainer>
        ) : null}
      </KpiContainer>

      {deliveryConfig['storeVsSystemChart']?.isVisible &&
      selectedDateRange &&
      selectedLocation ? (
        <>
          <VerticalSpacer80px />
          <Chart
            startDate={selectedDateRange.startDateStr}
            endDate={selectedDateRange.endDateStr}
            dateRangeType={selectedDateRange.type}
            locationId={locationId}
            locationName={selectedLocation.name}
          />
        </>
      ) : null}

      {deliveryConfig['staffTable']?.isVisible && selectedDateRange ? (
        <>
          <VerticalSpacer80px />
          <StaffTable
            locationId={locationId}
            startDate={selectedDateRange.startDateStr}
            endDate={selectedDateRange.endDateStr}
          />
        </>
      ) : null}

      <VerticalSpacer80px />

      <TrendsOuterContainer>
        <table>
          <tbody>
            <tr>
              <DayTrendsDescriptionTd>
                <TrendsContainer>Day Trends</TrendsContainer>
              </DayTrendsDescriptionTd>
              <td>
                <FilterContainer>
                  <DescriptionContainer>Filter by</DescriptionContainer>
                  <TrendsSelectorContainer>
                    <FormControl
                      variant="outlined"
                      margin="dense"
                      style={{ width: 280 }}
                    >
                      <FilterSelect
                        displayEmpty
                        value={dayTrendsCategory}
                        onChange={onSelectDayTrendsCategory}
                      >
                        {deliveryConfig['staffAndDayTrendsFilter'].options.map(
                          ({
                            variableName,
                            displayName,
                          }: {
                            variableName: string
                            displayName: string
                          }) => {
                            return (
                              <MenuItem key={variableName} value={variableName}>
                                {displayName}
                              </MenuItem>
                            )
                          },
                        )}
                      </FilterSelect>
                    </FormControl>
                  </TrendsSelectorContainer>
                  {deliveryConfig['dayTrendsDayPartDropdown']?.isVisible ? (
                    <TrendsSelectorContainer>
                      <DaypartFormControl
                        variant="outlined"
                        margin="dense"
                        style={{ width: 140 }}
                      >
                        <OptionSelect
                          displayEmpty
                          value={dayTrendsDayPart}
                          onChange={onSelectDayTrendsDayPart}
                        >
                          <MenuItem key="DINNER" value="DINNER">
                            Dinner Only
                          </MenuItem>
                          <MenuItem key="LUNCH" value="LUNCH">
                            Lunch Only
                          </MenuItem>
                          <MenuItem key="ALL" value="ALL">
                            All
                          </MenuItem>
                        </OptionSelect>
                      </DaypartFormControl>
                    </TrendsSelectorContainer>
                  ) : null}
                </FilterContainer>
              </td>
            </tr>
          </tbody>
        </table>
      </TrendsOuterContainer>

      <DayTrends
        dayTrendsInfo={dayTrendsInfo}
        dayTrendsCategory={dayTrendsCategory}
        isOrdersHidden={deliveryConfig.isDayTrendOrdersHidden || false}
      />

      {selectedDateRange && deliveryConfig.isDisputeTableVisible ? (
        <>
          <VerticalSpacer80px />
          <DisputeTable
            locationId={locationId}
            startDate={selectedDateRange.startDateStr}
            endDate={selectedDateRange.endDateStr}
          />
          <VerticalSpacer80px />
          <MissingItemTable
            locationId={locationId}
            startDate={selectedDateRange.startDateStr}
            endDate={selectedDateRange.endDateStr}
          />
        </>
      ) : null}
    </MainContainer>
  )
}

const MainContainer = styled.div``

const BreakdownContainer = styled.div`
  float: left;
`

const KpiContainer = styled.div`
  display: inline-block;
`

const KpiLeftContainer = styled.div`
  width: 800px;
  float: left;
`

const PageTopContainer = styled.div`
  float: left;
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;
`

const TrendsOuterContainer = styled.div`
  display: inline-block;
`

const DayTrendsDescriptionTd = styled.td`
  width: 300px;
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  float: left;
`

const TrendsContainer = styled(PageHeader)`
  margin-bottom: 10px;
`

const FilterContainer = styled.div`
  display: inline-block;
`

const DescriptionContainer = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  float: left;
  margin-top: 17px;
`

const FilterSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const DaypartFormControl = styled(FormControl)`
  background-color: white;
`

const OptionSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const KpiTh = styled.th<IKpiTh>`
  color: ${(props) =>
    props.danger ? COLORS.Pomodoro : props.success ? COLORS.Expo : 'black'};
  font-family: ${(props) =>
    props.book ? 'Lexend-Regular' : 'Lexend-SemiBold'};
  font-size: ${(props) => (props.book ? '18px' : '24px')};
  font-weight: ${(props) => (props.book ? '400' : '700')};
`

const KpiTd = styled.td<IKpiTh>`
  color: ${(props) =>
    props.danger ? COLORS.Pomodoro : props.success ? COLORS.Expo : 'black'};
  font-family: ${(props) =>
    props.book ? 'Lexend-Regular' : 'Lexend-SemiBold'};
  font-size: ${(props) => (props.book ? '18px' : '24px')};
  font-weight: ${(props) => (props.book ? '400' : '700')};
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
`

const PageHeaderLineContainer = styled.div`
  display: inline-block;
  width: 1200px;
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  padding: 10px 20px 0 0;
`

const SelectorContainer = styled.div`
  float: right;
  display: inline-block;
`

const TrendsSelectorContainer = styled.div`
  float: left;
  margin-left: 25px;
`

const LocationSelectorContainer = styled.div`
  float: left;
  margin-right: 7px;
`

const VerticalSpacer30px = styled.div`
  width: 100%;
  height: 30px;
`

const VerticalSpacer80px = styled.div`
  width: 100%;
  height: 80px;
`

const KpiOverviewTable = styled.table`
  width: 100%;
`

const RankingOverviewTable = styled.table`
  width: 760px;
`

const PtdKpiCategoryTd = styled.td`
  vertical-align: top;
`

const PtdKpiCategoryNameDiv = styled.div`
  width: 100%;
  text-align: center;
  padding: 5px 15px 5px 15px;
  border-bottom: 1px solid ${COLORS.Chalkboard};
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

const PtdKpiCategoryBreakdownDiv = styled.div`
  width: 100%;
  text-align: center;
  padding: 7px 20px 5px 20px;
  border-bottom: 1px solid ${COLORS.Chalkboard};
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

const PtdKpiCategoryStoreRankingDiv = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 0 5px 0;
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

const PtdKpiCategoryNameSubtitleDiv = styled.div`
  width: 100%;
  text-align: center;
  padding: 0 0 5px 0;
  border-bottom: 1px solid ${COLORS.Chalkboard};
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const PtdKpiTable = styled.table`
  width: 100%;
  text-align: center;

  thead th {
    font-style: normal;
    min-width: 60px;
  }

  tbody td {
    font-family: Lexend-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    vertical-align: top;
    height: 60px;
  }
`

const PtdOverviewSpacerTd = styled.td`
  width: 40px;
`

export default WebLargeMain
