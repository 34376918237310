import { useQuery } from '@apollo/client'
import _ from 'lodash'

import COLORS from 'pared/constants/colors'
import { getBusinessLabel } from 'pared/customer'
import { toUsdStr } from 'pared/utils/number'

import Main from './Main'
import { GET_SALES_PER_LABOR_HOUR } from './gql'
import useConfig from './hooks/useConfig'

interface IProps {
  locationName: string
  locationId: number
  startDate: string
  endDate: string
}

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

const convertHourToString = (hour: number) => {
  if (hour === 0) {
    return '12am'
  }

  if (hour === 12) {
    return '12pm'
  }

  if (hour < 12) {
    return `${hour}am`
  }

  if (hour > 12) {
    return `${hour - 12}pm`
  }

  return ''
}

const prependToLastElement = (arr: string[], str: string) => {
  const clonedArr = [...arr]
  if (clonedArr.length > 1) {
    clonedArr[clonedArr.length - 1] = `${str}${clonedArr[clonedArr.length - 1]}`
  }

  return clonedArr
}

function SalesPerLaborHourChart({
  locationName,
  locationId,
  startDate,
  endDate,
}: IProps) {
  const config = useConfig()
  const { loading, data: response } = useQuery(GET_SALES_PER_LABOR_HOUR, {
    variables: {
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip: !locationId || !startDate || !endDate,
  })

  const storeLabel = `${getBusinessLabel('store').toLowerCase()}s`

  const data: {
    hour: number
    sales: number
    systemwideSales: number
    differenceSales: number
    laborHours: number
    systemwideLaborHours: number
    differenceLaborHours: number
    salesPerLaborHour: number
    systemwideSalesPerLaborHour: number
    differenceSalesPerLaborHour: number
    laborPercent: number
    systemwideLaborPercent: number
    totalSales: number
    totalSystemwideSales: number
    totalDifferenceSales: number
    totalLaborHours: number
    totalSystemwideLaborHours: number
    totalDifferenceLaborHours: number
    totalSalesPerLaborHour: number
    totalSystemwideSalesPerLaborHour: number
    totalDifferenceSalesPerLaborHour: number
  }[] = response?.getSalesPerLaborHour?.nodes || []

  const { totalDifferenceSalesPerLaborHour } = data[0] ?? {}
  let summaryMessage: string | React.ReactElement = ''
  if (totalDifferenceSalesPerLaborHour >= 0) {
    summaryMessage = `Good job! ${locationName} is efficient and performing above Sales Per Labor Hour average.`
  } else {
    summaryMessage = (
      <>
        {locationName} is less productive than average, with sales{' '}
        <strong>
          {toUsdStr((-1 * totalDifferenceSalesPerLaborHour) / 100.0)} Less Per
          Labor Hour
        </strong>{' '}
        than benchmark.
      </>
    )
  }

  let doubleRedFlags = []
  let salesRedFlags = []
  let laborRedFlags = []
  for (const { hour, differenceSales, differenceLaborHours } of data) {
    const hourStr = convertHourToString(hour)
    if (differenceSales < 0 && differenceLaborHours > 0) {
      doubleRedFlags.push(hourStr)
    }

    if (differenceSales < 0) {
      salesRedFlags.push(hourStr)
    }

    if (differenceLaborHours > 0) {
      laborRedFlags.push(hourStr)
    }
  }

  doubleRedFlags = prependToLastElement(doubleRedFlags, 'and ')
  salesRedFlags = prependToLastElement(salesRedFlags, 'and ')
  laborRedFlags = prependToLastElement(laborRedFlags, 'and ')

  let areasOfImprovementMessages = []
  if (doubleRedFlags.length > 0) {
    areasOfImprovementMessages.push(
      <>
        At {doubleRedFlags.join(', ')}, {locationName} is staffing{' '}
        <strong>higher Labor Hours yet with lower Sales</strong> than other{' '}
        {storeLabel}.
      </>,
    )
  } else if (laborRedFlags.length === 0 && salesRedFlags.length === 0) {
    areasOfImprovementMessages.push('None at this time.')
  } else {
    if (laborRedFlags.length > 0) {
      areasOfImprovementMessages.push(
        `Labor hours are higher than average at ${laborRedFlags.join(', ')}.`,
      )
    }

    if (salesRedFlags.length > 0) {
      areasOfImprovementMessages.push(
        `Sales are below average at ${salesRedFlags.join(', ')}.`,
      )
    }
  }

  const xAxisData = data.map(({ hour }) => convertHourToString(hour))

  const yAxisDataArr = [
    {
      type: 'line',
      tooltipLabel: `${locationName}`,
      backgroundColor: COLORS.Plum,
      borderColor: COLORS.Plum,
      data: data.map(({ salesPerLaborHour }) => salesPerLaborHour / 100.0 ?? 0),
    },
    {
      type: 'line',
      tooltipLabel: getBusinessLabel('accessGroupType') || 'Systemwide',
      backgroundColor: COLORS.Mango,
      borderColor: COLORS.Mango,
      data: data.map(
        ({ systemwideSalesPerLaborHour }) =>
          systemwideSalesPerLaborHour / 100.0 ?? 0,
      ),
    },
    ...(config.isLaborPercentVisible
      ? [
          {
            type: 'bar',
            yAxisId: 'yRight',
            data: data.map(({ laborPercent }) => laborPercent),
            tooltipLabel: `${locationName} Labor %`,
            backgroundColor: COLORS.PlumTransparent,
          },
          {
            type: 'bar',
            yAxisId: 'yRight',
            data: data.map(
              ({ systemwideLaborPercent }) => systemwideLaborPercent,
            ),
            tooltipLabel: `${
              getBusinessLabel('accessGroupType') || 'Systemwide'
            } Labor %`,
            backgroundColor: COLORS.MangoTransparent,
          },
        ]
      : []),
  ]

  const chartOptions = {
    title: '',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: 'Sales per Labor Hour',
    yLeftTickCallback: (value: number) => `$${value}`,
    yRightAxisLabel: 'Labor %',
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let yValue = ''
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          if (tooltipItemContext.datasetIndex === 0) {
            const dataIndex = tooltipItemContext.dataIndex
            yValue = [
              `Average Sales: ${toUsdStr(data[dataIndex].sales / 100.0)}`,
              `Average Labor Hours: ${
                data[dataIndex].laborHours?.toFixed(1) ?? '-'
              }`,
              `Sales per Labor Hour: ${toUsdStr(tooltipItemContext.parsed.y)}`,
            ].join('; ')
          } else if (tooltipItemContext.datasetIndex === 1) {
            const dataIndex = tooltipItemContext.dataIndex
            yValue = [
              `Average Sales: ${toUsdStr(
                data[dataIndex].systemwideSales / 100.0,
              )}`,
              `Average Labor Hours: ${
                data[dataIndex].systemwideLaborHours?.toFixed(1) ?? '-'
              }`,
              `Sales per Labor Hour: ${toUsdStr(tooltipItemContext.parsed.y)}`,
            ].join('; ')
          } else if (tooltipItemContext.datasetIndex > 1) {
            yValue = `Labor: ${tooltipItemContext.parsed.y.toFixed(1)}%`
          } else {
            yValue = tooltipItemContext.parsed.y
          }
        } else {
          yValue = tooltipItemContext.parsed.y
        }

        return yValue
      }

      return ''
    },
  }

  return (
    <Main
      isLoading={loading}
      xAxisData={xAxisData}
      chartOptions={chartOptions}
      yAxisDataArr={yAxisDataArr}
      summaryMessage={summaryMessage}
      areasOfImprovementMessages={areasOfImprovementMessages}
    />
  )
}

export default SalesPerLaborHourChart
