export const primantiKpiDefs = {
  appetizersAndSidesAttachmentRate: {
    key: 'appetizersAndSidesAttachmentRate',
    name: 'Appetizers & Sides Attachment Rate',
    kpiType: 'sales',
    salesType: 'appetizers & sides',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  beverageAttachmentRate: {
    key: 'beverageAttachmentRate',
    name: 'Beverage Attachment Rate',
    kpiType: 'sales',
    salesType: 'beverage',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  friesAttachmentRate: {
    key: 'friesAttachmentRate',
    name: 'Fries Attachment Rate',
    kpiType: 'sales',
    salesType: 'fries',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  lbwAttachmentRate: {
    key: 'lbwAttachmentRate',
    name: 'LBW Attachment Rate',
    kpiType: 'sales',
    salesType: 'lbw',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
}
