import _ from 'lodash'
import moment, { MomentInput } from 'moment'
import { useMemo } from 'react'

import { IReportResult } from 'pared/components/CustomizedReport'
import { IColumnsType } from 'pared/components/basicUi/table'
import { toPercentString, toUsdString } from 'pared/utils/number'

import useConfig from './useInventoryConfig'

interface IDollarRankTableData {
  item: string
  variance: number
}

interface IPercentRankTableData {
  item: string
  variancePercent: number
  avtPercent: number
}

export interface ITopRankedItems {
  topFiveDollarShort: IDollarRankTableData[]
  topFiveDollarOver: IDollarRankTableData[]
  topFivePercentShort: IPercentRankTableData[]
  topFivePercentOver: IPercentRankTableData[]
  topFiveDollarShortTableColumnTypes: IColumnsType<IDollarRankTableData>[]
  topFiveDollarOverTableColumnTypes: IColumnsType<IDollarRankTableData>[]
  topFivePercentShortTableColumnTypes: IColumnsType<IPercentRankTableData>[]
  topFivePercentOverTableColumnTypes: IColumnsType<IPercentRankTableData>[]
}

const useGetTopRankedTableData = (
  customizedReportResult?: IReportResult,
): ITopRankedItems => {
  const config = useConfig()
  const inventoryItemColumnType = {
    key: 'item',
    minWidth: '140px',
    title: 'Inventory Item',
    align: 'left' as 'left',
  }

  const dollarRankColumnType = {
    key: 'variance',
    minWidth: '140px',
    align: 'center' as 'center',
    render: (centValue: number) => {
      const dollarValue = centValue / 100.0
      if (Math.abs(dollarValue) > 100) {
        return toUsdString(dollarValue, 0)
      } else {
        return toUsdString(dollarValue, 2)
      }
    },
    cellStyle: (value: any) => {
      if (typeof value === 'number' && value < 0) {
        return 'color: red;'
      }
      return ''
    },
  }

  const percentRankKey: string = config.topFiveKpi ?? 'variancePercent'
  const percentRankColumnType = {
    key: percentRankKey,
    minWidth: '140px',
    align: 'center' as 'center',
    render: (value: number) => {
      if (Math.abs(value) >= 100) {
        return toPercentString(value, 0)
      } else {
        return toPercentString(value, 1)
      }
    },
    cellStyle: (value: any) => {
      if (typeof value === 'number' && value < 0) {
        return 'color: red;'
      }
      return ''
    },
  }

  return useMemo(() => {
    if (
      customizedReportResult?.tableData &&
      Array.isArray(customizedReportResult?.tableData)
    ) {
      let inventoryDates: Set<MomentInput> = new Set()
      let inventoryTypeKey = 'weekDetails'
      for (let i = 0; i < customizedReportResult.tableData.length; i++) {
        const weekDetails = customizedReportResult.tableData[0].weekDetails
        if (weekDetails) {
          for (const week in weekDetails) {
            inventoryDates.add(week)
          }
        }

        const periodDetails = customizedReportResult.tableData[0].periodDetails
        if (periodDetails) {
          inventoryTypeKey = 'periodDetails'
          for (const period in periodDetails) {
            inventoryDates.add(period)
          }
        }
      }

      let latestInventoryDate: MomentInput = null
      if (inventoryDates.size > 0) {
        latestInventoryDate = Array.from(inventoryDates)?.reduce(
          (prev: MomentInput, cur: MomentInput) => {
            if (moment(cur, 'YYYY-MM-DD').isAfter(moment(prev, 'YYYY-MM-DD'))) {
              return cur
            }

            return prev
          },
        )
      }

      if (config.shouldUseLatestWeek && latestInventoryDate) {
        dollarRankColumnType.key = `${inventoryTypeKey}['${latestInventoryDate}'].variance`
        percentRankColumnType.key = `${inventoryTypeKey}['${latestInventoryDate}'].${percentRankKey}`
      }

      const orderedByDollarShort = _.orderBy(
        customizedReportResult?.tableData,
        config.shouldUseLatestWeek
          ? [
              (o) => {
                if (
                  o[inventoryTypeKey][latestInventoryDate as string] ===
                  undefined
                ) {
                  return ''
                }

                return o[inventoryTypeKey][latestInventoryDate as string]
                  .variance
              },
              'item',
            ]
          : ['variance', 'item'],
      )
      const orderedByPercentShort = _.orderBy(
        customizedReportResult?.tableData,
        config.shouldUseLatestWeek
          ? [
              (o) => {
                if (
                  o[inventoryTypeKey][latestInventoryDate as string] ===
                  undefined
                ) {
                  return ''
                }

                return o[inventoryTypeKey][latestInventoryDate as string][
                  percentRankKey
                ]
              },
              'item',
            ]
          : [percentRankKey, 'item'],
      )

      const topFiveDollarShortTableColumnTypes: IColumnsType<IDollarRankTableData>[] =
        [
          inventoryItemColumnType,
          {
            ...dollarRankColumnType,
            title: '$ Short',
          },
        ]

      const topFiveDollarOverTableColumnTypes: IColumnsType<IDollarRankTableData>[] =
        [
          inventoryItemColumnType,
          {
            ...dollarRankColumnType,
            title: '$ Over',
          },
        ]

      const topFivePercentShortTableColumnTypes: IColumnsType<IPercentRankTableData>[] =
        [
          inventoryItemColumnType,
          {
            ...percentRankColumnType,
            title: '% Short',
          },
        ]

      const topFivePercentOverTableColumnTypes: IColumnsType<IPercentRankTableData>[] =
        [
          inventoryItemColumnType,
          {
            ...percentRankColumnType,
            title: '% Over',
          },
        ]

      return {
        topFiveDollarShortTableColumnTypes,
        topFiveDollarOverTableColumnTypes,
        topFivePercentShortTableColumnTypes,
        topFivePercentOverTableColumnTypes,
        topFiveDollarShort: _.slice(orderedByDollarShort, 0, 5),
        topFiveDollarOver: _.slice(orderedByDollarShort.reverse(), 0, 5),
        topFivePercentShort: _.slice(orderedByPercentShort, 0, 5),
        topFivePercentOver: _.slice(orderedByPercentShort.reverse(), 0, 5),
      }
    }

    const topFiveDollarShortTableColumnTypes: IColumnsType<IDollarRankTableData>[] =
      [
        inventoryItemColumnType,
        {
          ...dollarRankColumnType,
          title: '$ Short',
        },
      ]

    const topFiveDollarOverTableColumnTypes: IColumnsType<IDollarRankTableData>[] =
      [
        inventoryItemColumnType,
        {
          ...dollarRankColumnType,
          title: '$ Over',
        },
      ]

    const topFivePercentShortTableColumnTypes: IColumnsType<IPercentRankTableData>[] =
      [
        inventoryItemColumnType,
        {
          ...percentRankColumnType,
          title: '% Short',
        },
      ]

    const topFivePercentOverTableColumnTypes: IColumnsType<IPercentRankTableData>[] =
      [
        inventoryItemColumnType,
        {
          ...percentRankColumnType,
          title: '% Over',
        },
      ]

    return {
      topFiveDollarShortTableColumnTypes,
      topFiveDollarOverTableColumnTypes,
      topFivePercentShortTableColumnTypes,
      topFivePercentOverTableColumnTypes,
      topFiveDollarShort: [],
      topFiveDollarOver: [],
      topFivePercentShort: [],
      topFivePercentOver: [],
    }
  }, [customizedReportResult])
}

export default useGetTopRankedTableData
