import { gql } from '@apollo/client'

export const listLocationInboundCalls = gql`
  query ListLocationInboundCalls(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationInboundCalls(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        inboundTotal
        inboundAnswered
        percentAnswered
        inboundMissedAndAbandoned
        percentMissedAndAbandoned
        inboundMissed
        percentMissed
        inboundAbandoned
        percentAbandoned
        missedOpportunity
        annualOpportunityCost
        brandInboundTotal
        brandInboundAnswered
        brandPercentAnswered
        brandInboundMissedAndAbandoned
        brandPercentMissedAndAbandoned
        brandInboundMissed
        brandPercentMissed
        brandInboundAbandoned
        brandPercentAbandoned
      }
    }

    listLocationEmployeeCalls(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        employeeName
        employeeRole
        inboundTotal
        percentAnswered
        inboundMissedAndAbandoned
        percentMissedAndAbandoned
        inboundMissed
        percentMissed
        inboundAbandoned
        percentAbandoned
      }
    }
  }
`
