import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import { toPercentString, toUsdString } from 'pared/utils/number'

import { useVariables } from '../../../variables'
import type { IApiDataType } from '../../types'
import useFetchGroupMetricValue from './useFetchGroupMetricValue'

const calculateChange = (
  currentValue: number,
  previousValue: number,
): number => {
  return previousValue !== 0
    ? (100 * (currentValue - previousValue)) / previousValue
    : 0
}

const useBbbCorpSales = (): IApiDataType => {
  const { variables } = useVariables()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useFetchGroupMetricValue([
    'total_sales',
    'total_sales_budget',
    'check_count',
    'check_average',
  ])

  return {
    data: useMemo(() => {
      if (!data) return null

      const currentPeriod = variables.date?.getInfo(0)
      const sales = data.total_sales ?? 0
      const yoySales = data.yoy_total_sales ?? 0
      const salesBudget = data.total_sales_budget ?? 0
      const checkCount = data.check_count ?? 0
      const priorCheckCount = data.prior_check_count ?? 0
      const yoyCheckCount = data.yoy_check_count ?? 0
      const checkAvg = data.check_average ?? 0
      const priorCheckAvg = data.prior_check_average ?? 0
      const yoyCheckAvg = data.yoy_check_average ?? 0

      const salesVar = sales - salesBudget
      const salesYoyChg = calculateChange(sales, yoySales)
      const checkCountPeriodChg = calculateChange(checkCount, priorCheckCount)
      const checkCountYoyChg = calculateChange(checkCount, yoyCheckCount)
      const checkAvgPeriodChg = calculateChange(checkAvg, priorCheckAvg)
      const checkAvgYoyChg = calculateChange(checkAvg, yoyCheckAvg)
      // ADD TEXT HERE START

      const salesVarText =
        salesVar <= 0
          ? `Missing the ${toUsdString(salesBudget)} budget by ${toUsdString(
              -salesVar,
            )}`
          : `Beating the ${toUsdString(salesBudget)} budget by ${toUsdString(
              salesVar,
            )}`

      const salesYoyChgText =
        salesYoyChg >= 0
          ? `increased by ${toPercentString(salesYoyChg)}`
          : `decreased by ${toPercentString(-salesYoyChg)}`

      const checkCountPeriodChgText =
        checkCountPeriodChg >= 0
          ? `increased ${toPercentString(checkCountPeriodChg)} vs Prior Period`
          : `decreased ${toPercentString(-checkCountPeriodChg)} vs Prior Period`

      const checkCountYoyChgText =
        checkCountYoyChg >= 0
          ? `increased ${toPercentString(checkCountYoyChg)} vs PY`
          : `decreased ${toPercentString(-checkCountYoyChg)} vs PY`

      const checkAvgPeriodChgText =
        checkAvgPeriodChg >= 0
          ? `increased ${toPercentString(checkAvgPeriodChg)} vs Prior Period`
          : `decreased ${toPercentString(-checkAvgPeriodChg)} vs Prior Period`

      const checkAvgYoyChgText =
        checkAvgYoyChg >= 0
          ? `increased ${toPercentString(checkAvgYoyChg)} vs PY`
          : `decreased ${toPercentString(-checkAvgYoyChg)} vs PY`

      // ADD TEXT HERE END

      return {
        title: `Sales`,
        total: salesVar * -1,
        summary: `Sales in ${currentPeriod?.displayName} was ${toUsdString(
          sales,
        )}`,
        detail: `
          <ul>
            <li>${salesVarText}</li>
            ${
              groupFilter?.label !== 'Comp'
                ? ''
                : `<li>Comp Store Sales % ${salesYoyChgText}</li>`
            }
            <li>Traffic ${checkCountPeriodChgText} and ${checkCountYoyChgText}</li>
            <li>Check Avg ${checkAvgPeriodChgText} and ${checkAvgYoyChgText}</li>
          </ul>`,
        hasDetails: true,
      }
    }, [data, variables, groupFilter]),
    loading,
  }
}

export default useBbbCorpSales
