import { createSlice } from '@reduxjs/toolkit'
import { defineState } from 'redux-localstore'

import {
  IAsyncActionStatus,
  generateAsyncActionStatusGetter,
  generateAsyncActionThunk,
} from './util'

export interface IUserState {
  asyncActionStatus: {
    [asyncActionName: string]: IAsyncActionStatus
  }
  hasSignedIn: boolean
}

export const userSliceName = 'user'
const defaultState: IUserState = {
  asyncActionStatus: {},
  hasSignedIn: false,
}
const initialState = defineState(defaultState)(userSliceName)

const signInAsyncActionName = 'user/signIn'
const signInThunk = generateAsyncActionThunk(
  signInAsyncActionName,
  async ({ username, password }: { username: string; password: string }) => {
    if (username === 'pared' && password === 'pared') {
      return true
    } else {
      throw Error('Incorrect username or password!')
    }
  },
  {
    fulfilled: (state: IUserState) => {
      state.hasSignedIn = true
    },
  },
)
export const signIn = signInThunk.action
export const getSignInStatus = generateAsyncActionStatusGetter(
  userSliceName,
  signInAsyncActionName,
)

const userSlice = createSlice({
  initialState,
  name: userSliceName,
  reducers: {
    signOut(state) {
      state.hasSignedIn = false
    },
  },
  extraReducers: {
    ...signInThunk.reducers,
  },
})

export default userSlice.reducer

export const { signOut } = userSlice.actions

export function hasSignedIn(state: { user: IUserState }) {
  return state.user.hasSignedIn
}
