import { IConfigsType } from '../../types'

const aceWeeklyFlash: IConfigsType = {
  ace_pop: {
    '/:brand/weekly_flash': {
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: ['this_week', 'week'],
        defaultType: 'this_week',
      },
      weeklyFlashTitle: {
        type: 'title',
        title: 'Weekly Flash Report',
      },
      downloadKpisTables: {
        type: 'download-csv',
        api: 'acePopWeeklyFlash',
        fileName: 'getexpo-ace-pop-weekly-flash',
        fileNameFn: (date: string) => `getexpo-ace-pop-weekly-flash-${date}`,
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'netSales',
            title: 'Sales TY',
          },
          {
            key: 'yoyNetSales',
            title: 'Sales LY',
          },
          {
            key: 'yoyNetSalesPercentage',
            title: 'Comp %',
          },
          {
            key: 'foodCogsPercentage',
            title: 'Food',
          },
          {
            key: 'hourlyLaborCostPercentage',
            title: 'Labor %',
          },
          {
            key: 'totalComplaintCount',
            title: 'No of Complaints Weekly',
          },
          {
            key: 'transactions',
            title: 'Transactions',
          },
          {
            key: 'acrScore',
            title: 'Weekly ACR',
          },
          {
            key: 'trailing4WeeksAcrScore',
            title: 'ACR T4Wks',
          },
          {
            key: 'otHours',
            title: 'OT Hours',
          },
          {
            key: 'rev',
            title: 'REV',
          },
        ],
      },
      weeklyFlashTable: {
        type: 'table',
        api: 'acePopWeeklyFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        columns: [
          {
            key: 'netSales',
            title: 'Sales TY',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'yoyNetSales',
            title: 'Sales LY',
            align: 'center',
            sortable: true,
          },
          {
            key: 'yoyNetSalesPercentage',
            title: 'Comp %',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'foodCogsPercentage',
            title: 'Food',
            align: 'center',
            sortable: true,
          },
          {
            key: 'hourlyLaborCostPercentage',
            title: 'Labor %',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'totalComplaintCount',
            title: 'No of Complaints Weekly',
            align: 'center',
            sortable: true,
          },
          {
            key: 'transactions',
            title: 'Transactions',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'acrScore',
            title: 'Weekly ACR',
            align: 'center',
            sortable: true,
          },
          {
            key: 'trailing4WeeksAcrScore',
            title: 'ACR T4Wks',
            align: 'center',
            sortable: true,
          },
          {
            key: 'otHours',
            title: 'OT Hours',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'rev',
            title: 'REV',
            sortable: true,
            decimal: 1,
          },
        ],
      },
      weeklyPurchaseTitle: {
        type: 'title',
        title: 'Weekly Food Purchase Summary',
      },
      downloadPurchaseTables: {
        type: 'download-csv',
        api: 'acePopWeeklyFlash',
        fileName: 'getexpo-ace-pop-weekly-food-purchase-summary',
        fileNameFn: (date: string) =>
          `getexpo-ace-pop-weekly-food-purchase-summary-${date}`,
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'foodAmount',
            title: 'Food $',
          },
          {
            key: 'foodAmountPercentage',
            title: 'Food %',
          },
          {
            key: 'paperAmount',
            title: 'Paper $',
          },
          {
            key: 'paperAmountPercentage',
            title: 'Paper %',
          },
          {
            key: 'suppliesAmount',
            title: 'Supplies $',
          },
          {
            key: 'suppliesAmountPercentage',
            title: 'Supplies %',
          },
          {
            key: 'drinksAmount',
            title: 'Drinks $',
          },
          {
            key: 'drinksAmountPercentage',
            title: 'Drinks %',
          },
          {
            key: 'miscAmount',
            title: 'Misc $',
          },
          {
            key: 'miscAmountPercentage',
            title: 'Misc %',
          },
          {
            key: 'totalPurchaseAmount',
            title: 'Total $',
          },
          {
            key: 'totalPurchaseAmountPercentage',
            title: 'Total %',
          },
          {
            key: 'paperAmountPercentageTw',
            title: 'Paper TW',
          },
          {
            key: 'lastWeekPaperAmountPercentage',
            title: 'Paper LW',
          },
          {
            key: 'paperAmountLastWeekVariance',
            title: 'Paper Variance',
          },
        ],
      },
      weeklyPurchaseTable: {
        type: 'table',
        api: 'acePopWeeklyFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        columns: [
          {
            key: 'foodAmount',
            title: 'Food $',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'foodAmountPercentage',
            title: 'Food %',
            align: 'center',
            sortable: true,
          },
          {
            key: 'paperAmount',
            title: 'Paper $',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'paperAmountPercentage',
            title: 'Paper %',
            align: 'center',
            sortable: true,
          },
          {
            key: 'suppliesAmount',
            title: 'Supplies $',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'suppliesAmountPercentage',
            title: 'Supplies %',
            align: 'center',
            sortable: true,
          },
          {
            key: 'drinksAmount',
            title: 'Drinks $',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'drinksAmountPercentage',
            title: 'Drinks %',
            align: 'center',
            sortable: true,
          },
          {
            key: 'miscAmount',
            title: 'Misc $',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'miscAmountPercentage',
            title: 'Misc %',
            align: 'center',
            sortable: true,
          },
          {
            key: 'totalPurchaseAmount',
            title: 'Total $',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'totalPurchaseAmountPercentage',
            title: 'Total %',
            align: 'center',
            sortable: true,
          },
          {
            key: 'paperAmountPercentageTw',
            title: 'Paper TW',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'lastWeekPaperAmountPercentage',
            title: 'Paper LW',
            align: 'center',
            sortable: true,
            disableDangerColor: true,
          },
          {
            key: 'paperAmountLastWeekVariance',
            title: 'Paper Variance',
            align: 'center',
            highlight: true,
            sortable: true,
            disableDangerColor: true,
          },
        ],
      },
    },
  },
}

export default aceWeeklyFlash
