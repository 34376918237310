import React from 'react'

import useConfig from './hooks/useConfig'

const CorporateCustom = () => {
  const Component = useConfig()

  return <Component />
}

export default React.memo(CorporateCustom)
