import COLORS from 'pared/constants/colors'
import { ILossPreventionConfigType } from 'pared/pages/CorporateLossPrevention/hooks/useLossPreventionConfig'

export const irmgPopConfig: ILossPreventionConfigType = {
  kpiSettings: [
    { category: 'basic', key: 'compsTotalCount', title: 'Total Discounts' },
    { category: 'basic', key: 'cashOverOrShort', title: 'Cash +/-' },
    {
      category: 'custom',
      type: 'Over Ring',
      title: '% Checks with Over Ring',
      key: 'checkPercent',
    },
    {
      category: 'custom',
      type: 'Deal after Total',
      title: '% Checks with Deletes',
      key: 'checkPercent',
    },
    {
      category: 'basic',
      key: 'inaccurateCashierShiftPercent',
      title: '% Shifts with Inaccurate Cash',
    },
  ],

  discountTable: {
    isVisible: true,
    discountColumns: [
      {
        key: 'DISCOUNT - Item #1',
        title: 'Item #1',
      },
      {
        key: 'DISCOUNT - Crew Meal',
        title: 'Crew',
      },
      {
        key: 'DISCOUNT - Manager Meal',
        title: 'Manager Meal',
      },
      {
        key: 'DISCOUNT - Senior',
        title: 'Senior',
      },
      {
        key: 'DISCOUNT - Police',
        title: 'Police',
      },
    ],
  },

  discountTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Discount',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [
      {
        key: 'DISCOUNT - Item #1',
        name: 'Item #1',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Plum,
        hidden: true,
      },
      {
        key: 'DISCOUNT - Crew Meal',
        name: 'Crew Meal',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.DarkOrchid,
        hidden: true,
      },
      {
        key: 'DISCOUNT - Manager Meal',
        name: 'Manager Meal',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Mango,
        hidden: true,
      },
      {
        key: 'DISCOUNT - Senior',
        name: 'Senior',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Basil,
        hidden: true,
      },
      {
        key: 'DISCOUNT - Police',
        name: 'Police',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Pomodoro,
        hidden: true,
      },
    ],
  },

  cashControlTable: {
    isVisible: true,
    cashControlColumns: [
      {
        key: 'cashOverOrShort',
        title: 'Cash +/-',
        width: '150px',
        children: [
          {
            key: 'cashOverOrShort.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'cashOverOrShort.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Over Ring',
        title: 'Over Ring',
        width: '150px',
        children: [
          {
            key: 'Over Ring.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'Over Ring.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Deal after Total',
        title: 'Deletes',
        width: '150px',
        children: [
          {
            key: 'Deal after Total.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'Deal after Total.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'cashOverOrShort.checkPercent',
        title: '% Shifts with Inaccurate Cash',
        valueType: 'percent',
        width: '150px',
      },
      {
        key: 'Over Ring.checkPercent',
        title: '% Checks w/ Over Ring',
        valueType: 'percent',
        width: '150px',
      },
      {
        key: 'Deal after Total.checkPercent',
        title: '% Checks w/ Deletes',
        valueType: 'percent',
        width: '150px',
      },
    ],
  },

  cashControlTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Amount',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [
      {
        key: 'cashOverOrShort',
        name: 'Cash +/- $',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Pomodoro,
      },
      {
        key: 'Over Ring',
        name: 'Over Ring $',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Plum,
        hidden: true,
      },
      {
        key: 'Deal after Total',
        name: 'Deletes $',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Basil,
        hidden: true,
      },
    ],
  },
}
