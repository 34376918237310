import FormControl from '@material-ui/core/FormControl'
import React from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import Select from 'pared/components/basicUi/select'
import Spin from 'pared/components/basicUi/spin'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import { EDDIE_MERLOTS_BRAND_CODE } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { TYPE_PERIOD, TYPE_QUARTER, TYPE_YEAR } from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'

import ExpandedPLTable from './ExpandedPLTable'
import TableWrapper from './TableWrapper'
import useCollapseArrowClicked from './hooks/useCollapseArrowClicked'
import useColumns from './hooks/useColumns'
import usePlConfig from './hooks/usePlConfig'
import { IReportFilterType } from './hooks/useReportFilter'

interface IPropsType {
  navParams: INavParams
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 36px;
    font-weight: 700;
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      font-size: 18px;
      padding: 0px 20px;
    }
  }
`

const Selectors = styled.div`
  display: flex;
  gap: 10px;
  height: 100%;

  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    &:last-child {
      @media ${MOBILE_WIDTH} {
        margin: 5px 0px;
        height: 100%;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const SubHeader = styled.div`
  margin: 83px 0px 20px;
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    display: block;
    width: 100%;
    margin: 15px 0px 0px;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;

    @media ${MOBILE_WIDTH} {
      font-size: 16px;
      font-style: normal;
      color: rgb(74, 74, 74);
      padding: 0px 20px;
    }
  }

  h3 {
    margin: 0px;
    display: inline-block;
    font-family: Lexend-SemiBold;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 25px;

    @media ${MOBILE_WIDTH} {
      margin: 10px 0px;
      display: block;
      gap: 15x;
    }
  }
`

const StyledFormControl = styled(FormControl)`
  width: 280px;
`

const MobileCotainer = styled.div`
  @media ${MOBILE_WIDTH} {
    margin: 20px 10px 0px;
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const MenuItemSelect = styled(Select)`
  width: 260px;
  background-color: white;
  margin: 0 30px 0 15px;

  @media ${MOBILE_WIDTH} {
    width: 100%;
    margin: 0px;
    border-radius: 0px;
  }
` as typeof Select

const Spacer = styled.div`
  height: 160px;
  width: 100%;
  min-width: 1200px;
`

const PageStatus = styled.div`
  padding-top: 30px;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;

  @media ${MOBILE_WIDTH} {
    position: initial;
    height: auto;
    padding: 0px 25px 20px 25px;
  }
`

const ExpandedPL = ({ navParams }: IPropsType) => {
  const plConfig = usePlConfig()
  const brand = getBrand()

  const {
    displayData,
    isLoading,
    reportFilterValue,
    locationFilter,
    setLocationFilter,
    locationFilters,
    reportFilter,
    setReportFilter,
    reportFilters,
    clickedHeaders,
    onClick,
  } = useCollapseArrowClicked()

  const columns = useColumns({
    dataSource: displayData || [],
    reportFilterValue,
    onClick,
    clickedHeaders,
  })

  return (
    <>
      <Header>
        <div>
          <h1>Profit & Loss</h1>
          <SubtitleContainer>
            P&L Data Updated Every Wednesday
          </SubtitleContainer>
          {brand === EDDIE_MERLOTS_BRAND_CODE ? (
            <SubtitleContainer>
              This page uses CrunchTime data, so some locations may not be
              included yet
            </SubtitleContainer>
          ) : null}
        </div>

        <Selectors>
          <h3>Filter by:</h3>

          <DateRangeSelector
            navParams={navParams}
            dateRangeOptions={[TYPE_YEAR, TYPE_QUARTER, TYPE_PERIOD]}
          />
        </Selectors>
      </Header>

      <SubHeader>
        <h1>Expanded P&L</h1>

        <Selectors>
          <h3>Filter by:</h3>
          <StyledFormControl variant="outlined" margin="dense">
            <MenuItemSelect<IReportFilterType>
              value={locationFilter}
              onChange={setLocationFilter}
              dataSource={locationFilters}
            />
          </StyledFormControl>

          <h3>View by:</h3>
          <StyledFormControl variant="outlined" margin="dense">
            <MenuItemSelect<IReportFilterType>
              value={reportFilter}
              onChange={setReportFilter}
              dataSource={reportFilters}
            />
          </StyledFormControl>
        </Selectors>
      </SubHeader>

      <MobileCotainer>
        {isLoading || !displayData?.length ? (
          <Spin spinning={isLoading}>
            <Spacer />
          </Spin>
        ) : displayData?.length >= plConfig?.displaySequence?.length ? (
          <TableWrapper>
            <ExpandedPLTable
              columns={columns}
              displayData={displayData}
              clickedHeaders={clickedHeaders}
            />
          </TableWrapper>
        ) : (
          <PageStatus>There is no data for this time interval</PageStatus>
        )}
      </MobileCotainer>
    </>
  )
}

export default React.memo(ExpandedPL)
