import MuiBadge from '@material-ui/core/Badge'
import styled from 'styled-components'

import useApi, { IApiKeyType } from './hooks/useApi'

export interface IPropsType {
  api?: IApiKeyType
  children: React.ReactNode
}

const StyledBadge = styled(MuiBadge)`
  .MuiBadge-badge {
    transform: translate(25px, -3px);
    font-family: Lexend-SemiBold;
    background-color: #f4cd00;
  }
`

const Badge = ({ api, children }: IPropsType) => {
  const { data } = useApi(api)

  if (!data) return <div>{children}</div>

  return <StyledBadge badgeContent={data}>{children}</StyledBadge>
}

export default Badge
