import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import useBrands from 'pared/layouts/hooks/useBrands'

interface IParamsPropsType {
  id: string
}

const queries = gql`
  query CurrentTrainingTracker($listUuid: String!) {
    trainingTrackerByListUuid(listUuid: $listUuid) {
      assignedTracker
      traineeId
    }
  }
`

export const jscTrainingTrackerConfig = {
  content: 'string',
} as const

const useJscTrainingTracker = () => {
  const { brand } = useBrands()
  const { id }: IParamsPropsType = useParams()
  const { data, loading } = useQuery(queries, {
    variables: {
      listUuid: id,
    },
    skip: !id,
  })

  return {
    data: useMemo((): Record<string, unknown>[] | null => {
      const tracker = data?.trainingTrackerByListUuid.assignedTracker
        ? data.trainingTrackerByListUuid.assignedTracker
        : 'none'

      return [
        {
          content: `Currently Assigned:    ${tracker}`,
          modalText: 'change',
          modalData: {
            callbackUrl: `/${brand}/training_tracker_profile/
              ${data?.trainingTrackerByListUuid.traineeId}`,
            employeeId: data?.trainingTrackerByListUuid.traineeId,
          },
        },
      ]
    }, [data]),
    loading,
  }
}

export default useJscTrainingTracker
