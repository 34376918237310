import React from 'react'
import styled from 'styled-components'

import CustomizedReport from 'pared/components/CustomizedReport'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings, getBusinessLabel } from 'pared/customer'
import useGetDateRange from 'pared/hooks/useGetDateRange'

interface IProps {
  locationGroupId: number | undefined
  isBreakdownByDirectors: boolean
  breakdownType: string | null
}

const SmgTable = ({
  locationGroupId,
  isBreakdownByDirectors,
  breakdownType,
}: IProps) => {
  const dateRange = useGetDateRange()
  const { brandLocationGroupId } = getBrandSettings()

  if (!dateRange) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  const { startDateStr, endDateStr } = dateRange

  const inputParams = {
    startDate: startDateStr,
    endDate: endDateStr,
    locationGroupId: locationGroupId || brandLocationGroupId,
    locationGroupType: isBreakdownByDirectors
      ? getBusinessLabel('director')
      : breakdownType,
  }

  let reportName = 'LIST_LOCATION_SMG_TABLE'
  if (isBreakdownByDirectors || breakdownType) {
    reportName = 'LIST_LOCATION_GROUP_SMG_TABLE'
  }

  return (
    <Container>
      <CustomizedReport
        reportName={reportName}
        inputParams={inputParams}
        csvFileName={`getexpo-smg-${startDateStr}-${endDateStr}`}
      />
    </Container>
  )
}

const Container = styled.div`
  margin: 0;
  overflow: scroll hidden;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};

  @media ${MOBILE_WIDTH} {
    padding: 10px;
  }
`

export default React.memo(SmgTable)
