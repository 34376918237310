import useFarwestSales, { farwestSalesConfigs } from './useSales'
import useFarwestSss, { farwestSssConfigs } from './useSss'

export const configs = {
  farwestSales: farwestSalesConfigs,
  farwestSss: farwestSssConfigs,
}

export const api = {
  farwestSales: useFarwestSales,
  farwestSss: useFarwestSss,
}
