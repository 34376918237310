import { gql } from '@apollo/client'

export const GET_ENTREES_PER_LABOR_HOUR = gql`
  query GetEntreesPerLaborHour(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    getEntreesPerLaborHour(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        hour
        entrees
        systemwideEntrees
        differenceEntrees
        laborHours
        systemwideLaborHours
        differenceLaborHours
        entreesPerLaborHour
        systemwideEntreesPerLaborHour
        differenceEntreesPerLaborHour
        totalEntrees
        totalSystemwideEntrees
        totalDifferenceEntrees
        totalLaborHours
        totalSystemwideLaborHours
        totalDifferenceLaborHours
        totalEntreesPerLaborHour
        totalSystemwideEntreesPerLaborHour
        totalDifferenceEntreesPerLaborHour
      }
    }
  }
`
