import _ from 'lodash'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import { IYAxisData } from 'pared/charts/MixedChart/web/drawMixedChart'
import COLORS from 'pared/constants/colors'

interface IProps {
  xAxisData: string[]
  chartOptions: any
  yAxisDataArr: IYAxisData[]
  isLoading: boolean
}

function WebLargeMain({
  xAxisData,
  chartOptions,
  yAxisDataArr,
  isLoading,
}: IProps) {
  if (isLoading) {
    return (
      <LoadingContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </LoadingContainer>
    )
  }

  return (
    <MainContainer>
      <MixedChart
        xAxisData={xAxisData}
        yAxisDataArr={yAxisDataArr}
        options={chartOptions}
      />
    </MainContainer>
  )
}

const MainContainer = styled.div`
  margin: 30px 0;
`
const LoadingContainer = styled.div`
  padding-top: 30px;
  height: 400px;
  width: 100%;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

export default WebLargeMain
