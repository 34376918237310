import { useCallback, useMemo, useState } from 'react'

import { IRevenueCenterNodeType } from '../gqls/itemList'

const All = 'All'

const useRevenueCenter = (revenueCenterData: IRevenueCenterNodeType[]) => {
  const [selectedRevenueCenter, setSelectedRevenueCenter] = useState(All)
  const revenueCenterChanged = useCallback(
    (revenueCenterItem: string) => {
      setSelectedRevenueCenter(revenueCenterItem)
    },
    [revenueCenterData],
  )

  const revenueCenterItems = useMemo(() => {
    return [All, ...revenueCenterData.map((item) => item.revenueCenter)]
  }, [revenueCenterData])

  return { selectedRevenueCenter, revenueCenterItems, revenueCenterChanged }
}

export default useRevenueCenter
