import _ from 'lodash'

import {
  GROUP_BY_PERIOD,
  GROUP_BY_QUARTER,
  GROUP_BY_WEEK,
} from 'pared/data/getDateRanges'

import { calculatePercent, getFloat } from '../utils'
import { IPlConfig } from './usePlConfig'

interface IPlIntervalData {
  startDate: string
  intervalType: string
  intervalIndex: number
  dollar: number
  percent?: number
}

interface IPlTrendData {
  type: string
  name?: string
  trendData?: IPlIntervalData[]
  categorySection?: string
  categoryDisplayOrder?: number
  itemDisplayOrder?: number
  percentageBase?: string
}

interface ICategorySectionMap {
  [categorySectionName: string]: {
    amount: number
  }
}

function processPlDetailData(
  rawPlData: any[],
  plConfig: IPlConfig,
  groupBy: string,
) {
  const sectionPlData: { [section: string]: IPlTrendData[] } = {}

  const displaySequencePercentageBaseMap: { [name: string]: string } = {}
  plConfig.displaySequence.forEach((sequence) => {
    displaySequencePercentageBaseMap[sequence.name] = sequence.percentageBase
  })

  let intervalType = 'Period'
  switch (groupBy) {
    case GROUP_BY_WEEK:
      intervalType = 'Week'
      break
    case GROUP_BY_PERIOD:
      intervalType = 'Period'
      break
    case GROUP_BY_QUARTER:
      intervalType = 'Quarter'
      break
    default:
      intervalType = 'Period'
  }

  let intervalDate = ''
  let intervalDateList: string[] = []
  const dateCategorySectionMap: {
    [date: string]: ICategorySectionMap
  } = {}
  const processedPlTrendData: { [plKey: string]: any } = {}

  rawPlData.forEach((rawData: any) => {
    const date = rawData.date
    const categorySection = rawData.plCategorySection
    const categoryName = rawData.plCategoryName
    const itemName = rawData.plItemName

    if (date !== intervalDate) {
      intervalDate = date
      intervalDateList.push(date)
    }

    const amount = getFloat(rawData.amount) || 0
    const categoryDisplayOrder = getFloat(rawData.plCategoryDisplayOrder) || 0
    const itemDisplayOrder = getFloat(rawData.plItemDisplayOrder) || 0

    if (categorySection && !categoryName && !itemName) {
      const categorySectionMap: ICategorySectionMap =
        dateCategorySectionMap[intervalDate] || {}
      categorySectionMap[categorySection] = { amount }
      dateCategorySectionMap[intervalDate] = categorySectionMap
    }

    let dataType = ''
    if (rawData.type === 'plCategorySection') {
      dataType = 'header'
    } else if (rawData.type === 'plCategory') {
      dataType = 'subHeader'
    }

    const plKey = `${categorySection}-${rawData.plCategoryId}--${rawData.plItemId}`
    const plTrendData = processedPlTrendData[plKey]?.['trendData'] || {}
    plTrendData[intervalDate] = {
      startDate: intervalDate,
      dollar: amount,
    }
    processedPlTrendData[plKey] = {
      type: dataType,
      name: itemName || categoryName || categorySection,
      categorySection: categorySection || '',
      categoryDisplayOrder,
      itemDisplayOrder,
      trendData: plTrendData,
      percentageBase: displaySequencePercentageBaseMap[categorySection],
    }
  })

  // calculate summaries
  const summaryPlData: { [summaryName: string]: IPlTrendData } = {}
  plConfig.summaries.forEach((summary) => {
    const trendData = intervalDateList.map((date: string, index: number) => {
      let summaryAmount = 0

      const categorySectionMap: ICategorySectionMap =
        dateCategorySectionMap[date]

      if (summary.positiveCategorySections) {
        summary.positiveCategorySections.forEach((section) => {
          summaryAmount += categorySectionMap[section]?.amount || 0
        })
      }

      if (summary.negativeCategorySections) {
        summary.negativeCategorySections.forEach((section) => {
          summaryAmount -= categorySectionMap[section]?.amount || 0
        })
      }

      return {
        intervalType,
        startDate: date,
        intervalIndex: index + 1,
        dollar: summaryAmount,
      }
    })

    summaryPlData[summary.name] = {
      trendData,
      type: 'summary',
      name: summary.name,
      categorySection: '',
      percentageBase: displaySequencePercentageBaseMap[summary.name],
    }
  })

  Object.values(processedPlTrendData).forEach((pl: any) => {
    const categorySection = pl.categorySection
    const sectionData = sectionPlData[categorySection] || []
    const trendData = intervalDateList.map((date: string, index: number) => {
      const intervalAmount = pl.trendData?.[date]?.['dollar']
      return {
        intervalType,
        startDate: date,
        intervalIndex: index + 1,
        dollar: intervalAmount,
      }
    })
    sectionData.push({
      trendData,
      categorySection,
      type: pl.type,
      name: pl.name,
      categoryDisplayOrder: pl.categoryDisplayOrder,
      itemDisplayOrder: pl.itemDisplayOrder,
      percentageBase: pl.percentageBase,
    })

    sectionPlData[categorySection] = sectionData
  })

  // put category sections in correct order
  let sortedPlData: IPlTrendData[] = []
  plConfig.displaySequence.forEach((sequence) => {
    if (sequence.type === 'categorySection') {
      sortedPlData = [
        ...sortedPlData,
        ..._.orderBy(sectionPlData[sequence.name] || [], [
          'categoryDisplayOrder',
          'itemDisplayOrder',
        ]),
      ]
    } else if (sequence.type === 'summary') {
      if (summaryPlData[sequence.name]) {
        sortedPlData.push({ type: 'spacer' })
        sortedPlData.push(summaryPlData[sequence.name])
      }
    }
  })

  // calculate percentage
  sortedPlData = sortedPlData.map((plData) => {
    const percentageBase = summaryPlData[plData?.percentageBase || '']

    const trendData = plData?.trendData?.map((intervalData, index: number) => {
      const intervalPercentageBase =
        percentageBase?.trendData?.[index]?.dollar || 0
      return {
        ...intervalData,
        percent:
          intervalData.dollar === undefined
            ? undefined
            : calculatePercent(
                intervalData.dollar || 0,
                intervalPercentageBase,
              ),
      }
    })

    return {
      ...plData,
      trendData,
    }
  })

  return sortedPlData
}

export default processPlDetailData
