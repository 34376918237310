import { gql, useMutation } from '@apollo/client'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'

import { useReset } from '../../../ResetProvider'

export interface IDeletePlanModalType {
  planId: number
}

const DELETE_DEVELOPMENT_PLAN = gql`
  mutation DeleteDevelopmentPlan($id: Int!, $now: Datetime!) {
    updateDevelopmentPlan(input: { id: $id, patch: { deletedAt: $now } }) {
      clientMutationId
    }
  }
`

const useDeletePlanModal = (
  data: IDeletePlanModalType,
  onClose: () => void,
) => {
  const { planId } = data
  const [deletePlan] = useMutation(DELETE_DEVELOPMENT_PLAN)
  const reset = useReset()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const submit = useCallback(async () => {
    const now = moment().toISOString()
    setIsSubmitting(true)
    setIsLoading(true)

    await deletePlan({
      variables: {
        id: planId,
        now: now,
      },
    })
    reset()
  }, [reset])

  useEffect(() => {
    if (isLoading) onClose()
  }, [data])

  return {
    submit,
    isLoading,
    isSubmitting,
  }
}

export default useDeletePlanModal
