import React from 'react'

import Chart from 'pared/charts'

import useConfig from './hooks/useConfig'

const ProductivityCoefficientChart = () => {
  const config = useConfig()

  return <Chart {...config} width={1100} height={350} />
}

export default React.memo(ProductivityCoefficientChart)
