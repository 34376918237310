import { useMemo, useState } from 'react'

export interface IDataType {
  timeBetween?: {
    options: any
    value: any
  }
}

const useTimeBetween = (): IDataType => {
  const timeBetweenHook = useState([[30]])

  return {
    timeBetween: useMemo(() => {
      const options = [
        {
          id: 5,
          parentId: 'root',
          displayName: 5,
        },
        {
          id: 10,
          parentId: 'root',
          displayName: 10,
        },
        {
          id: 15,
          parentId: 'root',
          displayName: 15,
        },
        {
          id: 20,
          parentId: 'root',
          displayName: 20,
        },
        {
          id: 25,
          parentId: 'root',
          displayName: 25,
        },
        {
          id: 30,
          parentId: 'root',
          displayName: 30,
        },
      ]

      return {
        value: timeBetweenHook[0],
        options: options,
        onChange: timeBetweenHook[1],
      }
    }, [timeBetweenHook]),
  }
}

export default useTimeBetween
