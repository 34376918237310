export interface IOverviewConfig {
  customize: boolean
  netSales: {
    isVisible: boolean
  }
  grossSales: {
    isVisible: boolean
  }
  netSalesBudgetVariance: {
    isVisible: boolean
  }
  netSalesSssPercent: {
    isVisible: boolean
  }
  grossSalesSssPercent: {
    isVisible: boolean
  }
  checkCount: {
    name: 'checkCount' | 'totalCheckCount' | 'totalGuestCount'
    isVisible: boolean
    isVisibleOnBreakDownTable?: boolean
  }
  checkCountVsLyPercent: {
    isVisible: boolean
    isVisibleOnBreakDownTable?: boolean
  }
  checkCountVsLyCount: {
    isVisible: boolean
    isVisibleOnBreakDownTable?: boolean
  }
  checkAverage: {
    name: 'checkAverage' | 'totalCheckAverage' | 'totalGuestAverage'
    isVisible: boolean
  }
  hourlyLaborBudgetVariance: {
    isVisible: boolean
  }
  avt: {
    isVisible: boolean
  }
  avtMonthly: {
    isVisible: boolean
  }
  plv: {
    isVisible: boolean
  }
  rcp: {
    isVisible: boolean
  }
  cogs: {
    isVisible: boolean
  }
  cogsBudgetVariance: {
    isVisible: boolean
  }
  breakdownByStore: {
    isCustom: boolean
  }
  salesPerLaborHour: {
    isVisible: boolean
  }
  sosAvgSeconds: {
    isVisible: boolean
  }
}

export function getOverviewConfig(brand: string): IOverviewConfig {
  switch (brand) {
    case 'eddiemerlots':
    case 'eddiemerlots_nso':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: true,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'checkCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'checkAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: true,
        },
        cogsBudgetVariance: {
          isVisible: true,
        },
        avt: {
          isVisible: true,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: true,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    case 'sg': // SweetGreen
    case 'qsr_shack': // The QSR Shack
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: true,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'checkCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'checkAverage',
          isVisible: false,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: true,
        },
        cogsBudgetVariance: {
          isVisible: true,
        },
        avt: {
          isVisible: true,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: true,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    case 'demo_bk':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: false,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'totalCheckCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'totalCheckAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: false,
        },
        cogsBudgetVariance: {
          isVisible: false,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: true,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    case 'irmg_bk':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: false,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'totalCheckCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'totalCheckAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: false,
        },
        cogsBudgetVariance: {
          isVisible: false,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: true,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    case 'supreme_bk':
      return {
        customize: true,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: false,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'totalCheckCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'totalCheckAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: false,
        },
        cogsBudgetVariance: {
          isVisible: false,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: true,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    case 'timoney_bk':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: false,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'totalCheckCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'totalCheckAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: false,
        },
        cogsBudgetVariance: {
          isVisible: false,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: true,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    case 'sandbox_bk':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: true,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'totalCheckCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'totalCheckAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: true,
        },
        cogsBudgetVariance: {
          isVisible: true,
        },
        avt: {
          isVisible: true,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    case 'supreme_pop':
    case 'ace_pop':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: false,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'totalCheckCount',
          isVisible: true,
          isVisibleOnBreakDownTable: true,
        },
        checkCountVsLyPercent: {
          isVisible: true,
          isVisibleOnBreakDownTable: true,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'totalCheckAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: true,
        },
        cogsBudgetVariance: {
          isVisible: true,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: true,
        },
      }

    case 'viking_bk':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: true,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'totalCheckCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'totalCheckAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: false,
        },
        cogsBudgetVariance: {
          isVisible: false,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: true,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    case 'irmg_pop':
    case 'demo_pop':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: true,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'totalCheckCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'totalCheckAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: true,
        },
        cogsBudgetVariance: {
          isVisible: true,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    case 'ghai_pop':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: true,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'totalCheckCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'totalCheckAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: true,
        },
        cogsBudgetVariance: {
          isVisible: true,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    case 'jsc_bk':
    case 'jsc_bk_nso':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: true,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'totalCheckCount',
          isVisible: true,
          isVisibleOnBreakDownTable: true,
        },
        checkCountVsLyPercent: {
          isVisible: true,
          isVisibleOnBreakDownTable: true,
        },
        checkCountVsLyCount: {
          isVisible: false,
          isVisibleOnBreakDownTable: true,
        },
        checkAverage: {
          name: 'totalCheckAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: false,
        },
        cogsBudgetVariance: {
          isVisible: false,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: true,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }
    case 'wingitnorth':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: true,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'checkCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: true,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'checkAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: true,
        },
        cogsBudgetVariance: {
          isVisible: true,
        },
        avt: {
          isVisible: true,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }
    case 'wingstop':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: false,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'totalCheckCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'totalCheckAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: false,
        },
        cogs: {
          isVisible: true,
        },
        cogsBudgetVariance: {
          isVisible: false,
        },
        avt: {
          isVisible: true,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: true,
        },
        salesPerLaborHour: {
          isVisible: true,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    case 'cava':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: true,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'totalGuestCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'totalGuestAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: true,
        },
        cogsBudgetVariance: {
          isVisible: true,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    case 'jitb':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: false,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'totalCheckCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'totalCheckAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: true,
        },
        cogsBudgetVariance: {
          isVisible: true,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    // Local Favorite
    case 'lfr':
      return {
        customize: true,
        netSales: {
          isVisible: false,
        },
        grossSales: {
          isVisible: true,
        },
        netSalesBudgetVariance: {
          isVisible: false,
        },
        netSalesSssPercent: {
          isVisible: false,
        },
        grossSalesSssPercent: {
          isVisible: true,
        },
        checkCount: {
          name: 'checkCount',
          isVisible: false,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'checkAverage',
          isVisible: false,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: true,
        },
        cogsBudgetVariance: {
          isVisible: true,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }
    case 'lfr_ef':
    case 'lfr_tr':
    case 'lfr_sn':
    case 'lfr_lv':
    case 'lfr_vb':
    case 'lfr_tu':
    case 'lfr_ws':
    case 'lfr_jn':
    case 'lfr_mm':
    case 'lfr_ol':
    case 'lfr_dt':
    case 'lfr_uv':
    case 'lfr_bth':
      return {
        customize: true,
        netSales: {
          isVisible: false,
        },
        grossSales: {
          isVisible: true,
        },
        netSalesBudgetVariance: {
          isVisible: false,
        },
        netSalesSssPercent: {
          isVisible: false,
        },
        grossSalesSssPercent: {
          isVisible: true,
        },
        checkCount: {
          name: 'checkCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'checkAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: true,
        },
        cogsBudgetVariance: {
          isVisible: true,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    case 'primanti':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: false,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'checkCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: true,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'checkAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: false,
        },
        cogs: {
          isVisible: false,
        },
        cogsBudgetVariance: {
          isVisible: false,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    case 'mwb':
    case 'burgerworks':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: false,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'checkCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkAverage: {
          name: 'checkAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: false,
        },
        cogs: {
          isVisible: true,
        },
        cogsBudgetVariance: {
          isVisible: false,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: true,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    case 'fw_wingstop':
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: true,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'checkCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'checkAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: true,
        },
        cogsBudgetVariance: {
          isVisible: true,
        },
        avt: {
          isVisible: false,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: false,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }

    default:
      return {
        customize: false,
        netSales: {
          isVisible: true,
        },
        grossSales: {
          isVisible: false,
        },
        netSalesBudgetVariance: {
          isVisible: true,
        },
        netSalesSssPercent: {
          isVisible: true,
        },
        grossSalesSssPercent: {
          isVisible: false,
        },
        checkCount: {
          name: 'checkCount',
          isVisible: true,
        },
        checkCountVsLyPercent: {
          isVisible: false,
        },
        checkCountVsLyCount: {
          isVisible: false,
        },
        checkAverage: {
          name: 'checkAverage',
          isVisible: true,
        },
        hourlyLaborBudgetVariance: {
          isVisible: true,
        },
        cogs: {
          isVisible: true,
        },
        cogsBudgetVariance: {
          isVisible: true,
        },
        avt: {
          isVisible: true,
        },
        avtMonthly: {
          isVisible: false,
        },
        plv: {
          isVisible: false,
        },
        rcp: {
          isVisible: true,
        },
        breakdownByStore: {
          isCustom: false,
        },
        salesPerLaborHour: {
          isVisible: false,
        },
        sosAvgSeconds: {
          isVisible: false,
        },
      }
  }
}
