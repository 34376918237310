import { INavParams } from 'pared/Routes/navParams'

import useConfig from './hooks/useConfig'

interface IProps {
  navParams: INavParams
}

const ScoreCard = ({ navParams }: IProps) => {
  const Component = useConfig()

  if (Component) {
    return <Component navParams={navParams} />
  } else {
    return null
  }
}

export default ScoreCard
