import _ from 'lodash'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import COLORS from 'pared/constants/colors'
import { getBrandSettings, getBusinessLabel } from 'pared/customer'

interface IProps {
  xAxisData: string[]
  yAxisLocationData: number[]
  yAxisSystemwideData: number[]
  locationName: string
  isLoading: boolean
}

const DEVICE_WIDTH = window.innerWidth
const CHART_HEIGHT = 350

function WebSmallMain({
  xAxisData,
  yAxisLocationData,
  yAxisSystemwideData,
  locationName,
  isLoading,
}: IProps) {
  const brandSettings = getBrandSettings()
  const globalGuestConfig = brandSettings.guest
  const ratingType = globalGuestConfig.momentfeed ? 'Momentfeed' : 'Rating'

  const comparisonGroupType =
    getBusinessLabel('accessGroupType') || 'Systemwide'

  const storeLabel = getBusinessLabel('store')
  const chartOptions = {
    title: `${storeLabel} Level ${ratingType} Stars`,
    width: DEVICE_WIDTH - 50,
    height: CHART_HEIGHT,
    yLeftAxisLabel: `${ratingType} Stars`,
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let datasetLabel = ''
        let separator = ''
        let yValue = ''
        if (tooltipItemContext.dataset && tooltipItemContext.dataset.label) {
          datasetLabel = tooltipItemContext.dataset.label
          separator = ': '
        }
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          yValue = tooltipItemContext.parsed.y
            ? tooltipItemContext.parsed.y.toFixed(1)
            : '-'
        }
        return [datasetLabel, separator, yValue].join('')
      }
      return ''
    },
  }

  if (isLoading) {
    return <LoadingContainer>Loading...</LoadingContainer>
  }

  return (
    <ScrollableTableContainer>
      <MixedChart
        xAxisData={xAxisData}
        yAxisDataArr={[
          {
            type: 'line',
            data: yAxisLocationData,
            tooltipLabel: `${locationName} Average ${ratingType} Stars`,
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
          {
            type: 'line',
            data: yAxisSystemwideData,
            tooltipLabel: `${comparisonGroupType} Average ${ratingType} Stars`,
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
        ]}
        options={chartOptions}
      />
    </ScrollableTableContainer>
  )
}

const LoadingContainer = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const ScrollableTableContainer = styled.div`
  overflow-x: scroll;
  border: 1px solid;
  padding-left: 30px;
  width: 90vw;
  padding-top: 20px;
  padding-bottom: 20px;
`

export default WebSmallMain
