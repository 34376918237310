import { useApolloClient } from '@apollo/client'
import { useReactiveVar } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { usePrevious } from 'react-use'
import styled from 'styled-components'

import { CORPORATE_LEVEL_NAV_TABS } from 'pared/constants'
import { BRAND_ID } from 'pared/constants/brands'
import useRoutes, { IRouteType } from 'pared/layouts/hooks/useRoutes'
import { decodedUserJwtToken } from 'pared/reactiveVariables/user'
import { getBrandFromPathname } from 'pared/utils/brand'
import { getUser } from 'pared/utils/user'

import { INavParams } from './navParams'
import navigator from './navigator'
import Renderer, { useRenderInfo } from './renderer'
import {
  IRoute,
  drgSalesManagerRoutes,
  generalRoutes,
  signInAndSignUpRoutes,
  signInRequiredRoutes,
} from './webRoutes'

interface IProps {
  hasUserSignedIn: boolean
  navParams: INavParams
  accessibleBrands: number[]
}

const StyledDiv = styled.div<{ isPageFullWidth: boolean }>`
  padding-left: ${(props) => (props.isPageFullWidth ? '50px' : '0px')};
  padding-right: ${(props) => (props.isPageFullWidth ? '50px' : '0px')};
`

function Main({ hasUserSignedIn, navParams, accessibleBrands }: IProps) {
  const user = hasUserSignedIn ? getUser() : null
  let protectedRoutes: IRoute[] = []
  if (hasUserSignedIn) {
    if (user?.isDrgSalesManager) {
      protectedRoutes = drgSalesManagerRoutes
    } else {
      protectedRoutes = signInRequiredRoutes
    }
  } else {
    protectedRoutes = signInAndSignUpRoutes
  }

  const brand = getBrandFromPathname(navParams.pagePathname || '')
  const hasAccessToBrand = accessibleBrands.indexOf(BRAND_ID[brand]) >= 0
  const client = useApolloClient()
  const prevBrand = usePrevious(brand)
  const [isLoading, setIsLoading] = useState(false)
  const { rendererPath, defaultPath } = useRenderInfo()
  const jwtToken = useReactiveVar(decodedUserJwtToken)
  const accessiblePages: string[] = jwtToken?.accessible_pages || []

  protectedRoutes = protectedRoutes.filter(
    (p) =>
      !user?.disableCorporatePages || p.navTabType !== CORPORATE_LEVEL_NAV_TABS,
  )

  useEffect(() => {
    if (!prevBrand || prevBrand === brand) return

    setIsLoading(true)
    client.stop()
    client.resetStore()
    setTimeout(setIsLoading, 0, false)
  }, [brand])

  if (isLoading) return null

  return (
    <Switch>
      <Route path={`/${brand}`}>
        <Switch>
          {generalRoutes.map((route) => {
            const RouteComponent = route.component
            return (
              <Route
                key={route.key}
                path={route.path}
                exact={route.exact}
                strict={route.strict}
              >
                <RouteComponent navParams={navParams} />
              </Route>
            )
          })}

          <Route path={rendererPath}>
            {hasAccessToBrand ? (
              <StyledDiv isPageFullWidth={navParams?.isPageFullWidth || false}>
                <Renderer />
              </StyledDiv>
            ) : (
              <Redirect to={navigator.unauthorized()} />
            )}
          </Route>

          {protectedRoutes
            .filter((route) => {
              if (!route?.accessPage) return true

              return accessiblePages.includes(route.accessPage)
            })
            .map((route) => {
              const RouteComponent = route.component
              return (
                <Route
                  key={route.key}
                  path={route.path}
                  exact={route.exact}
                  strict={route.strict}
                >
                  {!hasUserSignedIn ||
                  hasAccessToBrand ||
                  route.key === 'unauthorized' ? (
                    <RouteComponent navParams={navParams} />
                  ) : (
                    <Redirect to={navigator.unauthorized()} />
                  )}
                </Route>
              )
            })}
          {hasUserSignedIn ? (
            (() => {
              if (defaultPath) return <Redirect to={defaultPath} />

              if (
                (user?.defaultLocationId && user?.isStoreLevelUser) ||
                (user &&
                  (navParams?.redirect === 'store_detail' ||
                    navParams?.redirect === 'scorecard'))
              ) {
                if (navParams?.redirect === 'scorecard') {
                  return (
                    <Redirect
                      to={navigator.scorecard(user.defaultLocationId, brand)}
                    />
                  )
                }

                switch (brand) {
                  // TODO: make this more elegant
                  case 'rmg':
                  case 'rmg_nso':
                  case 'sullivans':
                  case 'sullivans_nso':
                  case 'eddie_merlots':
                  case 'wingitnorth':
                  case 'ghai_pop':
                  case 'bibibop':
                    return (
                      <Redirect
                        to={navigator.scorecard(user.defaultLocationId, brand)}
                      />
                    )
                  default:
                    return (
                      <Redirect
                        to={navigator.storeDetail(
                          user.defaultLocationId,
                          brand,
                        )}
                      />
                    )
                }
              }

              return <Redirect to={navigator.systemwideOverview(brand)} />
            })()
          ) : (
            <Redirect to={navigator.signIn(brand)} />
          )}
        </Switch>
      </Route>
      <Route>
        {hasUserSignedIn ? (
          (() => {
            if (defaultPath) return <Redirect to={defaultPath} />

            if (user?.defaultLocationId && user?.isStoreLevelUser) {
              switch (brand) {
                // TODO: make this more elegant
                case 'rmg':
                case 'rmg_nso':
                case 'sullivans':
                case 'sullivans_nso':
                case 'eddie_merlots':
                  return (
                    <Redirect
                      to={navigator.scorecard(user.defaultLocationId, brand)}
                    />
                  )
                default:
                  return (
                    <Redirect
                      to={navigator.storeDetail(user.defaultLocationId, brand)}
                    />
                  )
              }
            }

            return <Redirect to={navigator.systemwideOverview(brand)} />
          })()
        ) : (
          <Redirect to={navigator.signIn(brand)} />
        )}
      </Route>
    </Switch>
  )
}

export default Main
