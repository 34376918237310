import useCorporateDevelopment from './useCorporateDevelopment'

export type IApiKeyType = keyof typeof api

const api = {
  corporateDevelopment: useCorporateDevelopment,
}

const useApi = (
  apiName?: IApiKeyType,
): {
  data: number | null
} => (apiName ? api[apiName]() : { data: null })

export default useApi
