import { IRouteType } from '../../types'
import ghaiPopFlash from './ghaiPopFlash'
import ghaiPopHome from './ghaiPopHome'

export const routes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Flash',
        link: '/:brand/flash',
      },
    ],
  },
  {
    title: 'Store',
    children: [
      {
        title: 'Home',
        link: '/:brand/scorecard',
      },
    ],
  },
]

export default [ghaiPopFlash, ghaiPopHome]
