import COLORS from 'pared/constants/colors'
import { ILossPreventionConfigType } from 'pared/pages/CorporateLossPrevention/hooks/useLossPreventionConfig'

export const wingitnorthConfig: ILossPreventionConfigType = {
  kpiSettings: [
    { category: 'basic', key: 'compsTotalAmount', title: 'Total Discounts' },
    { category: 'basic', key: 'cashOverOrShort', title: 'Cash +/-' },
    { category: 'basic', key: 'oloOverOrShort', title: 'Olo +/-' },
    {
      category: 'custom',
      type: 'Over Ring',
      title: '% Checks with Voids',
      key: 'checkPercent',
    },
  ],

  discountTable: {
    isVisible: true,
    isTotalCountVisible: true,
    isTotalAmountVisible: true,
    discountColumns: [
      {
        key: '100% Emp',
        title: '100% Emp',
      },
      {
        key: '100% Comp Remake',
        title: '100% Comp Remake',
      },
      {
        key: 'OLO Coupon',
        title: 'OLO Coupon',
      },
      {
        key: '10% Off',
        title: '10% Off',
      },
      {
        key: 'Free Fries',
        title: 'Free Fries',
      },
      {
        key: '33% Emp',
        title: '33% Emp',
      },
      {
        key: '25% OFF COMP',
        title: '25% OFF COMP',
      },
      {
        key: '30% Comp',
        title: '30% Comp',
      },
      {
        key: '50% Comp',
        title: '50% Comp',
      },
      {
        key: '100% Comp',
        title: '100% Comp',
      },
      {
        key: '100% Charity Comp',
        title: '100% Charity Comp',
      },
      {
        key: '5% Off',
        title: '5% Off',
      },
      {
        key: '15% Off',
        title: '15% Off',
      },
      {
        key: '5 Free BW  No Pur',
        title: '5 Free BW No Pur',
      },
      {
        key: '5 Free BW  W/Pur',
        title: '5 Free BW W/Pur',
      },
      {
        key: '5 Free CL  No Pur',
        title: '5 Free CL No Pur',
      },
      {
        key: '5 Free CL W/ Pur',
        title: '5 Free CL W/ Pur',
      },
      {
        key: 'Free 6pc Combo',
        title: 'Free 6pc Combo',
      },
      {
        key: 'Free Dip',
        title: 'Free Dip',
      },
      {
        key: 'Free Large Fry',
        title: 'Free Large Fry',
      },
      {
        key: '20% Off',
        title: '20% Off',
      },
      {
        key: '$5 Towards Any',
        title: '$5 Towards Any',
      },
      {
        key: '$10 Towards Any',
        title: '$10 Towards Any',
      },
      {
        key: 'Free Veggie Stix',
        title: 'Free Veggie Stix',
      },
      {
        key: 'IOU Redeem',
        title: 'IOU Redeem',
      },
      {
        key: 'Open Discount',
        title: 'Open Discount',
      },
    ],
  },

  discountTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Discount',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [],
  },

  cashControlTable: {
    isVisible: true,
    cashControlColumns: [
      {
        key: 'cashOverOrShort',
        title: 'Cash +/-',
        children: [
          {
            key: 'cashOverOrShort.amount',
            title: '$',
            valueType: 'price',
            width: '150px',
          },
        ],
      },
      {
        key: 'Over Ring',
        title: 'Voids',
        children: [
          {
            key: 'Over Ring.count',
            title: '#',
            valueType: 'count',
            width: '150px',
          },
          {
            key: 'Over Ring.amount',
            title: '$',
            valueType: 'price',
            width: '150px',
          },
        ],
      },
      {
        key: 'Over Ring.checkPercent',
        title: '% Checks w/ Voids',
        valueType: 'percent',
        width: '150px',
      },
    ],
  },

  cashControlTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Amount',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [
      {
        key: 'cashOverOrShort',
        name: 'Cash +/- $',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Pomodoro,
      },
      {
        key: 'Over Ring',
        name: 'Voids $',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Plum,
        hidden: true,
      },
    ],
  },
}
