import moment from 'moment'
import { useCallback } from 'react'

import { IDateRangeMap } from 'pared/data/getDateRanges'

import { IDateOptionNodeType } from './types'
import { buildQPWOptions } from './useDateOptions'

interface ICalendarType<T extends 'period' | 'week'> {
  ids: IDateOptionNodeType<T>['id'][]
  yearIds: IDateOptionNodeType<'year'>['id'][]
  calendar: IDateOptionNodeType<T>[][]
}

const cache = {} as Record<string, ICalendarType<'period' | 'week'>>

const useGetCalendar = (dateRangeMap: IDateRangeMap | null) =>
  useCallback(
    <T extends 'period' | 'week' = 'period' | 'week'>(
      type: T,
      // Use to determine the end date of the calendar
      dateRangeType: 'year' | 'period' | 'week' = type,
    ): ICalendarType<T> | null => {
      const cacheKey = `${type}-${dateRangeType}`

      if (!dateRangeMap) return null

      if (cache[cacheKey]) return cache[cacheKey] as ICalendarType<T>

      const allDate = Object.values(dateRangeMap)
        .filter((d) => d.type === type || d.type === dateRangeType)
        .sort((a, b) => b.key.localeCompare(a.key))
      const now = moment()
      const endTypeDate = allDate.find(
        (d) => d.type === dateRangeType && d.startDate.isSameOrBefore(now),
      )
      const data = allDate
        .filter(
          (d) =>
            d.type === type &&
            d.startDate.isSameOrBefore(endTypeDate?.endDate || now),
        )
        .map((d) => buildQPWOptions(type, d)) as IDateOptionNodeType<
        typeof type
      >[]
      const calendar = data.reduce((result, d) => {
        if (result.length === 0) return [[d]]

        const prevD = result.slice(-1)[0].slice(-1)[0]

        if (prevD.year === d.year)
          return [...result.slice(0, -1), [d, ...result.slice(-1)[0]]]

        return [...result, [d]]
      }, [] as IDateOptionNodeType<typeof type>[][])
      const output = {
        ids: calendar[0].map((d) => d.id),
        yearIds: calendar.map((d) => `${d[0].year}` as const),
        calendar,
      }

      cache[cacheKey] = output

      return output
    },
    [dateRangeMap],
  )

export default useGetCalendar
