import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'

import Summary from 'pared/components/basicUi/Summary'
import { getBrandLocationGroupId } from 'pared/utils/brand'

import { IListLocationInboundCalls, LIST_LOCATION_INBOUND_CALLS } from './gql'
import parseSummary from './parseSummary'

interface IProps {
  locationId: number
  locationName: string
  startDate: string
  endDate: string
}

const CallsSummary = ({
  locationId,
  locationName,
  startDate,
  endDate,
}: IProps) => {
  const [fetch, { loading, data: fetchedData }] =
    useLazyQuery<IListLocationInboundCalls>(LIST_LOCATION_INBOUND_CALLS)

  useEffect(() => {
    fetch({
      variables: {
        iFilter: {
          location_ids: [locationId],
          brand_location_group_ids: [getBrandLocationGroupId()],
        },
        iStartDate: startDate,
        iEndDate: endDate,
      },
    })
  }, [locationId, startDate, endDate])

  let summaryMessage: string | React.ReactElement = ''
  let areasOfImprovementMessages: (string | React.ReactElement)[] = []

  if (loading) {
    summaryMessage = 'Loading...'
    areasOfImprovementMessages = ['Loading...']
  }

  if (fetchedData) {
    const {
      summaryMessage: parsedSummaryMessage,
      areasOfImprovementMessages: parsedAreasOfImprovementMessage,
    } = parseSummary(fetchedData, locationName)

    if (parsedSummaryMessage) {
      summaryMessage = parsedSummaryMessage
    }

    if (parsedAreasOfImprovementMessage) {
      areasOfImprovementMessages = parsedAreasOfImprovementMessage
    }
  }

  return (
    <Summary
      summaryMessage={summaryMessage}
      areasOfImprovementMessages={areasOfImprovementMessages}
    />
  )
}

export default CallsSummary
