import _ from 'lodash'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import { IYAxisData } from 'pared/charts/MixedChart/web/drawMixedChart'
import COLORS from 'pared/constants/colors'

interface IProps {
  xAxisData: string[]
  chartOptions: any
  yAxisDataArr: IYAxisData[]
  isLoading: boolean
}

const CHART_WIDTH = 800

function WebSmallMain({
  xAxisData,
  chartOptions,
  yAxisDataArr,
  isLoading,
}: IProps) {
  if (isLoading) {
    return <LoadingContainer>Loading ...</LoadingContainer>
  }

  return (
    <ScrollableTableContainer>
      <ScrollableTable>
        <MixedChart
          xAxisData={xAxisData}
          yAxisDataArr={yAxisDataArr}
          options={chartOptions}
        />
      </ScrollableTable>
    </ScrollableTableContainer>
  )
}

export default WebSmallMain

const ScrollableTableContainer = styled.div`
  overflow-x: scroll;
  border: 1px solid;
  padding: 30px 0;
`

const ScrollableTable = styled.div`
  width: ${CHART_WIDTH};
`

const LoadingContainer = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
  margin-left: 25px;
`
