import _ from 'lodash'
import styled from 'styled-components'

import NumberScale from 'pared/components/basicUi/NumberScale'
import PercentileScale from 'pared/components/basicUi/PercentileScale'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBusinessLabel } from 'pared/customer'
import DownArrow from 'pared/images/basic/arrow/caret-down.svg'
import UpArrow from 'pared/images/basic/arrow/caret-up.svg'
import Correct from 'pared/images/basic/checks/correct.png'
import Failed from 'pared/images/basic/checks/failed.png'
import { toPercentageString } from 'pared/utils/number'

import { KPIS_BY_UNIT, getKpiDisplayNames } from '../constants'
import useConfig from '../hooks/useConfig'
import { IScore } from '../index'
import { IOrderBy } from './index'

interface IProps {
  scores: IScore[]
  orderBy: IOrderBy
  setOrderBy: any
  dateRangeType: string
}

interface IRankTableTh {
  textAlign?: string
}

interface IRankTableTh {
  textAlign?: string
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
}

function WebLargeMain({ scores, orderBy, setOrderBy, dateRangeType }: IProps) {
  const config = useConfig()
  const kpiDisplayNames = getKpiDisplayNames()
  const visiableKpis = new Set(config.scorecardCategories)

  const handleOrdering = (event: any) => {
    const orderingColumnValue = _.get(event, 'currentTarget.dataset.value')
    setOrderBy({
      columnName: orderingColumnValue,
      isAscending: !orderBy.isAscending,
    })
  }

  let orderStatus = null
  if (orderBy.isAscending) {
    orderStatus = <UpArrowImg src={UpArrow} alt="ASC"></UpArrowImg>
  } else {
    orderStatus = <DownArrowImg src={DownArrow} alt="DESC"></DownArrowImg>
  }

  const totalScore = _.get(scores, '[0].totalScore') ?? '-'
  const locationCount = _.get(scores, '[0].locationCount', '-')
  const categoryCount = scores?.length || '-'
  const maxScore =
    config.maxScore === 'categoryCount' ? categoryCount : locationCount

  const overallRank = _.get(scores, '[0].overallRank') ?? '-'
  const overallPercentileRank =
    _.get(scores, '[0].overallPercentileRank') ?? '-'

  const visiableKpiScores = _.filter(scores, (rawData: any) => {
    if (visiableKpis.has(rawData.name)) {
      return true
    }
  })

  const scoreRows = _.map(visiableKpiScores, (r, index) => {
    const kpi = _.get(r, 'name', '')
    const kpiDisplayName = kpiDisplayNames[kpi] || '-'
    const unit = KPIS_BY_UNIT[kpi] || ''
    const weight = _.get(r, 'weight', '')
    const weightDisplay = weight ? toPercentageString(weight, 0) : '-'
    const score = _.get(r, 'score', null) ?? '-'
    const value = _.get(r, 'value', '')
    const valueDisplay = value || value === 0 ? `${value}${unit}` : '-'
    const average = _.get(r, 'average', '')
    const averageDisplay = average || average === 0 ? `${average}${unit}` : '-'
    const rank = _.get(r, 'rank')
    const percentileRank = _.get(r, 'percentileRank')
    const scoreIcon =
      typeof config.isScoreFavorable === 'function' &&
      config.isScoreFavorable(score)
        ? Correct
        : Failed

    return (
      <tr key={index}>
        <RankTableTd textAlign="left">{kpiDisplayName}</RankTableTd>
        {!config.isWeightVisible ? null : (
          <RankTableTd>{weightDisplay}</RankTableTd>
        )}
        <RankTableTd>
          {config.isScorecardColored && score !== '-' ? (
            <img src={scoreIcon} height="20px" />
          ) : (
            score
          )}
        </RankTableTd>
        <RankTableTd>{valueDisplay}</RankTableTd>
        <RankTableTd>{averageDisplay}</RankTableTd>
        {config.isPercentileRankVisible ? (
          <RankTableTd>
            {percentileRank || percentileRank === 0 ? (
              <PercentileScale percentile={percentileRank} />
            ) : (
              '-'
            )}
          </RankTableTd>
        ) : null}
        {config.isRankVisible ? (
          <RankTableTd>
            {rank ? (
              <NumberScale number={rank} min={locationCount} max={1}>
                {rank}
              </NumberScale>
            ) : (
              '-'
            )}
          </RankTableTd>
        ) : null}
      </tr>
    )
  })

  return (
    <MobileContainer>
      <MainContainer>
        <RankTable>
          <thead>
            <tr>
              <RankTableTh></RankTableTh>
              {!config.isWeightVisible ? null : (
                <RankTableSortableTh
                  onClick={handleOrdering}
                  data-value={'weight'}
                >
                  <span>Weight</span>
                  {orderBy.columnName === 'weight' ? orderStatus : null}
                </RankTableSortableTh>
              )}
              <RankTableSortableTh
                onClick={handleOrdering}
                data-value={'score'}
              >
                <span>Score</span>
                {orderBy.columnName === 'score' ? orderStatus : null}
              </RankTableSortableTh>
              <RankTableTh>
                {dateRangeType === 'year'
                  ? 'YTD'
                  : dateRangeType === 'quarter'
                  ? 'QTD'
                  : 'PTD'}
              </RankTableTh>
              <RankTableTh>{`${
                getBusinessLabel('accessGroupType') || 'Systemwide'
              } Average`}</RankTableTh>
              {config.isPercentileRankVisible ? (
                <RankTableSortableTh
                  onClick={handleOrdering}
                  data-value={'percentileRank'}
                >
                  <span>Percentile</span>
                  {orderBy.columnName === 'percentileRank' ? orderStatus : null}
                </RankTableSortableTh>
              ) : null}
              {config.isRankVisible ? (
                <RankTableSortableTh
                  onClick={handleOrdering}
                  data-value={'rank'}
                >
                  <span>Rank</span>
                  {orderBy.columnName === 'rank' ? orderStatus : null}
                </RankTableSortableTh>
              ) : null}
            </tr>
          </thead>
          <tbody>{scoreRows}</tbody>
          <tfoot>
            <tr>
              <RankTableTd textAlign="left" bold>
                TOTAL SCORE
              </RankTableTd>
              <RankTableTd bold>{`${totalScore} / ${maxScore}`}</RankTableTd>
              {!config.isWeightVisible ? null : <td></td>}
              <td></td>
              <td></td>
              {config.isPercentileRankVisible ? (
                <td>
                  {overallPercentileRank >= 0 ? (
                    <PercentileScale percentile={overallPercentileRank} />
                  ) : (
                    '-'
                  )}
                </td>
              ) : null}
              {config.isRankVisible ? (
                <td>
                  {overallRank ? (
                    <NumberScale
                      number={overallRank}
                      min={locationCount}
                      max={1}
                    >
                      {overallRank}
                    </NumberScale>
                  ) : (
                    '-'
                  )}
                </td>
              ) : null}
            </tr>
            <tr>
              <RankTableTd textAlign="left" bold>
                STORE RANKING
              </RankTableTd>
              <RankTableTd bold>
                {`${overallRank} / ${locationCount}`}
              </RankTableTd>
            </tr>
          </tfoot>
        </RankTable>
      </MainContainer>
    </MobileContainer>
  )
}

export default WebLargeMain

const UpArrowImg = styled.img`
  width: 10px;
  margin: 0 0 2px 5px;
`

const DownArrowImg = styled.img`
  width: 10px;
  margin: 0 0 -1px 5px;
`

const RankTableTd = styled.td<IRankTableTd>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  width: 500px;
`

const MainContainer = styled.div`
  width: 1000px;
`

const MobileContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const RankTable = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tbody tr:last-child {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`

const RankTableTh = styled.th<IRankTableTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankTableSortableTh = styled.th<IRankTableTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  color: ${COLORS.Plum};
  cursor: pointer;
`
