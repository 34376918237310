import { IReportDefinition } from 'pared/components/CustomizedReport'
import { getBrandSettings } from 'pared/customer'

import { IConfig } from '../hooks/useConfig'

const getFilteredCustomReportDefinition = (
  customizedReportDefinition: IReportDefinition | undefined,
  { kpi, unit }: { kpi?: string; unit?: string },
  config: IConfig,
) => {
  if (!customizedReportDefinition) {
    return customizedReportDefinition
  }

  const brandSettings = getBrandSettings()

  const columns = customizedReportDefinition?.columns || []
  const filteredColumns = columns.filter((col) => {
    if (!col.filter) {
      return true
    }

    let isVisible = true

    if (col.filter.kpi && col.filter.kpi !== kpi) {
      isVisible = false
    }

    if (col.filter.unit && col.filter.unit !== unit) {
      isVisible = false
    }

    if (!brandSettings.overview.cogs.isVisible) {
      if (col.key === 'cogsPercent') {
        isVisible = false
      }
    }

    if (config.shouldHideCogs) {
      if (col.key === 'cogsBasisPoints' || col.key === 'cogsPercent') {
        isVisible = false
      }
    }

    return isVisible
  })

  return {
    ...customizedReportDefinition,
    columns: filteredColumns,
  }
}

export default getFilteredCustomReportDefinition
