import { useCallback, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useReactToPrint } from 'react-to-print'
import styled from 'styled-components'

import { PRINTABLE_TYPE, SALESMANSHIP_COLUMN_NAMES } from 'pared/constants'
import PrintIcon from 'pared/images/Pared/print-icon.svg'

import { SalesmanshipTable } from '../SalesmanshipTable'
import { StaffTable } from '../StaffTable'
import { IOrderBy, ISalesmanship } from '../index'

interface IProps {
  rankedData: ISalesmanship[]
  storeTitle: string
  selectedValue: string
  tableTitle?: string
  dataPointColumnName?: string
  printableType: string
  orderBy?: IOrderBy
  salesType?: string
  dayPart: string
}

const TotalSales = ({
  rankedData,
  storeTitle,
  selectedValue,
  tableTitle,
  dataPointColumnName,
  orderBy,
  printableType,
  dayPart,
  salesType,
}: IProps) => {
  const componentRef = useRef(null)
  const domRef = useRef<HTMLDivElement>(document.createElement('div'))

  const onBeforeGetContentResolve = useRef(null)

  const handleAfterPrint = useCallback(() => {}, [])

  const handleBeforePrint = useCallback(() => {}, [])

  const handleOnBeforeGetContent = useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve

      resolve()
    })
  }, [])

  const reactToPrintContent = useCallback(() => {
    return componentRef.current
  }, [componentRef.current])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'Expo',
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  })

  useEffect(() => {
    if (typeof onBeforeGetContentResolve.current === 'function') {
      onBeforeGetContentResolve.current()
    }
  }, [onBeforeGetContentResolve.current])

  useEffect(() => {
    const root = document.querySelector('body')

    if (!root) return

    root.appendChild(domRef.current)

    return () => {
      root.removeChild(domRef.current)
    }
  }, [])

  let table

  switch (printableType) {
    case PRINTABLE_TYPE.SALESMANSHIP_TABLE:
      let numberOfDecimals = 0
      if (
        dataPointColumnName ===
          SALESMANSHIP_COLUMN_NAMES.sales_per_labor_hour ||
        dataPointColumnName === SALESMANSHIP_COLUMN_NAMES.per_person_average
      ) {
        numberOfDecimals = 2
      }

      table = (
        <SalesmanshipTable
          ref={componentRef}
          rankedData={rankedData}
          storeTitle={storeTitle}
          selectedValue={selectedValue}
          tableTitle={tableTitle}
          dataPointColumnName={dataPointColumnName}
          dayPart={dayPart}
          numberOfDecimals={numberOfDecimals}
        />
      )
      break
    case PRINTABLE_TYPE.STAFF_TABLE:
      table = (
        <StaffTable
          ref={componentRef}
          rankedData={rankedData}
          storeTitle={storeTitle}
          selectedValue={selectedValue}
          tableTitle={tableTitle}
          dataPointColumnName={dataPointColumnName}
          orderBy={orderBy}
          salesType={salesType}
          dayPart={dayPart}
        />
      )
      break
    default:
      table = (
        <SalesmanshipTable
          ref={componentRef}
          rankedData={rankedData}
          storeTitle={storeTitle}
          selectedValue={selectedValue}
          tableTitle={tableTitle}
          dataPointColumnName={dataPointColumnName}
          dayPart={dayPart}
        />
      )
      break
  }

  return (
    <>
      <PrintImg src={PrintIcon} alt="Print" onClick={handlePrint} />

      {ReactDOM.createPortal(table, domRef.current)}
    </>
  )
}

const PrintImg = styled.img`
  cursor: pointer;
`

export default TotalSales
