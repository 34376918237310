import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { track } from 'pared/analytics/storeList'
import { IColumnsType } from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'
import { getBusinessLabel } from 'pared/customer'
import { IDirector } from 'pared/data/getDirectors'
import { getLocationCode, getLocationName } from 'pared/utils/location'

import useSalesmanshipConfig from '../../hooks/useSalesmanshipConfig'

const LinkButton = styled(Link)`
  color: ${COLORS.Link};
`

function columnSorter(dataA: any, dataB: any) {
  let sortableA = dataA?.value
  let sortableB = dataB?.value
  if (sortableA > sortableB) {
    return 1
  } else if (sortableA < sortableB) {
    return -1
  } else return 0
}

const useColumns = (
  isBreakdownByDirectors: boolean,
  locationGroupTypes: string[] | null,
  allDirectors: IDirector[],
): IColumnsType<any>[] => {
  const salesmanshipConfig = useSalesmanshipConfig()

  return useMemo(() => {
    const breakdownTableColumnDefs =
      salesmanshipConfig?.breakdownTableColumnDefs || []

    let allColumns: IColumnsType<any>[] = []

    if (Array.isArray(breakdownTableColumnDefs)) {
      const columns = breakdownTableColumnDefs.map((columnDef: any) => {
        const colDef: IColumnsType<any> = {
          key: columnDef.key,
          title: columnDef.name,
          minWidth: '150px',
          align: 'center',
          render: (columnData: any) => {
            return columnData?.displayString || '-'
          },
          sorter: columnSorter,
        }
        return colDef
      })

      const otherColumns: IColumnsType<any>[] = []
      if (isBreakdownByDirectors) {
        const locationGroupIdToDoProfileMap: {
          [locationGroupId: number]: IDirector
        } = {}
        allDirectors?.forEach((director) => {
          locationGroupIdToDoProfileMap[director.locationGroupId] = director
        })

        otherColumns.push({
          key: 'locationGroupId',
          title: getBusinessLabel('director'),
          minWidth: '150px',
          align: 'left',
          render: (columnData: any) => {
            const doProfile = locationGroupIdToDoProfileMap[columnData]
            return `${doProfile?.firstName} ${doProfile?.lastName}`
          },
          sorter: true,
        })
      } else if (locationGroupTypes) {
        otherColumns.push({
          key: 'locationGroupName',
          title: locationGroupTypes[0],
          minWidth: '150px',
          align: 'left',
          sorter: true,
        })
      } else {
        otherColumns.push({
          key: 'locationInfo',
          title: getBusinessLabel('store'),
          minWidth: '150px',
          align: 'left',
          render: (columnData: any) => {
            return (
              <LinkButton
                to={navigator.salesmanship(columnData.id)}
                onClick={() => track.locationLinkClicked(columnData.id)}
              >
                {getLocationCode(columnData.id, columnData.code)}-
                {getLocationName(columnData.id, columnData.name)}
              </LinkButton>
            )
          },
          sorter: columnSorter,
        })
      }

      allColumns = [...otherColumns, ...columns]
    }

    if (salesmanshipConfig?.speedOfService?.isVisible) {
      salesmanshipConfig.speedOfService.windows.forEach((windowInfo: any) => {
        allColumns.push({
          key: windowInfo?.type,
          title: windowInfo?.displayName,
          minWidth: '150px',
          align: 'center',
          render: (columnData: any) => {
            return columnData?.displayString || '-'
          },
          sorter: columnSorter,
        })
      })
    }

    return allColumns
  }, [isBreakdownByDirectors, locationGroupTypes, salesmanshipConfig])
}

export default useColumns
