import { gql } from '@apollo/client'

interface IDisputeReasonsNodeType {
  startDate: string
  endDate: string
  locationId: number
  reason: string
  totalCount: number
  totalAmount: number
}

export interface IDisputeReasonType {
  getDisputeReasons: {
    nodes: IDisputeReasonsNodeType[]
  }
}

export interface IDisputeReasonVariablesType {
  iStartDate: string
  iEndDate: string
  iFilter: {
    location_group_ids?: number[]
    location_group_types?: string[]
  }
}

export const GET_DISPUTE_REASONS = gql`
  query GetDisputeReasons(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
  ) {
    getDisputeReasons(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        reason
        totalCount
        totalOrderPercent
        totalAmount
      }
    }
  }
`
