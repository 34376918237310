import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { track } from 'pared/analytics/storeList'
import { IColumnsType } from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'

import { getCount, getPercent, getPrice } from '../../utils'
import { IDataSourceType } from './useDataSource'

const LinkButton = styled(Link)`
  color: ${COLORS.Link};
`

const useColumns = (
  isBreakdownByDirectors: boolean,
  groupByType?: string | null,
): IColumnsType<IDataSourceType>[] => {
  return useMemo(
    () =>
      [
        ...((isBreakdownByDirectors || groupByType
          ? []
          : [
              {
                key: 'storeCode',
                title: 'Store',
                minWidth: '150px',
                render: (_, values: IDataSourceType) => {
                  const groupInfo = values.groupInfo
                  if (!groupInfo) return null

                  const storeCode =
                    'locationCode' in groupInfo
                      ? groupInfo.locationCode
                      : 'Unkown'
                  const storeName =
                    'locationName' in groupInfo
                      ? groupInfo.locationName
                      : 'Unkown'
                  const name = `${storeCode} - ${storeName}`

                  return (
                    <LinkButton
                      to={navigator.storeDetail(values.groupInfo?.locationId)}
                      onClick={() =>
                        track.locationLinkClicked(values.groupInfo?.locationId)
                      }
                    >
                      {name}
                    </LinkButton>
                  )
                },
              },
            ]) as IColumnsType<IDataSourceType>[]),
        ...(groupByType
          ? [
              {
                key: 'groupName',
                title: groupByType,
                minWidth: '150px',
                render: (_, values: IDataSourceType) => {
                  const groupInfo = values.groupInfo

                  if (!groupInfo) return null

                  return 'locationGroupName' in groupInfo
                    ? groupInfo.locationGroupName
                    : null
                },
              },
            ]
          : [
              {
                key: 'directorName',
                title: 'District Manager',
                minWidth: '150px',
                render: (_, values: IDataSourceType) => {
                  const groupInfo = values.groupInfo
                  if (!groupInfo) return null

                  const directorName =
                    'directorName' in groupInfo
                      ? groupInfo.directorName
                      : groupInfo?.locationGroupName

                  return directorName
                },
                sorter: true,
              },
            ]),
        {
          key: 'orderWithAnyIssuePercent',
          title: '% Orders with Issues',
          minWidth: '150px',
          align: 'center',
          render: getPercent,
          sorter: true,
        },
        {
          key: 'totalDisputeCount',
          title: 'Total Disputes #',
          minWidth: '75px',
          align: 'center',
          render: getCount,
          sorter: true,
        },
        {
          key: 'totalDisputeAmount',
          title: 'Total Disputes $',
          minWidth: '75px',
          align: 'center',
          render: getPrice,
          sorter: true,
        },
        {
          key: 'completedDisputeCount',
          title: 'Completed Disputes #',
          minWidth: '75px',
          align: 'center',
          render: getCount,
          sorter: true,
        },
        {
          key: 'completedDisputeAmount',
          title: 'Completed Disputes $',
          minWidth: '75px',
          align: 'center',
          render: getPrice,
          sorter: true,
        },
        {
          key: 'pendingDisputeCount',
          title: 'Pending Disputes #',
          minWidth: '75px',
          align: 'center',
          render: getCount,
          sorter: true,
        },
        {
          key: 'pendingDisputeAmount',
          title: 'Pending Disputes $',
          minWidth: '75px',
          align: 'center',
          render: getPrice,
          sorter: true,
        },
        {
          key: 'lostDisputeCount',
          title: 'Lost Disputes #',
          minWidth: '75px',
          align: 'center',
          render: getCount,
          sorter: true,
        },
        {
          key: 'lostDisputeAmount',
          title: 'Lost Disputes $',
          minWidth: '75px',
          align: 'center',
          render: getPrice,
          sorter: true,
        },
      ] as IColumnsType<IDataSourceType>[],
    [isBreakdownByDirectors, groupByType],
  )
}

export default useColumns
