import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

interface ITeamConfig {
  managerPar: {
    isVisible: boolean
  }
  avgHourlyRate: {
    isVisible: boolean
  }
  unfilledManagerialPositions: {
    isVisible: boolean
  }
  managerPerformance: {
    isVisible: boolean
  }
  newHireTurnover: {
    isVisible: boolean
  }
  roleAvgPayrateTable?: {
    isVisible: boolean
  }
  isTeamV2?: boolean
}

const useTeamConfig = (): ITeamConfig => {
  const brand = getBrand()

  return useMemo(() => {
    switch (brand) {
      case 'papajohns_demo':
        return {
          managerPar: {
            isVisible: true,
          },
          avgHourlyRate: {
            isVisible: true,
          },
          unfilledManagerialPositions: {
            isVisible: true,
          },
          managerPerformance: {
            isVisible: true,
          },
          newHireTurnover: {
            isVisible: true,
          },
        }

      case 'irmg_bk':
      case 'viking_bk':
      case 'sandbox_bk':
      case 'supreme_bk':
      case 'timoney_bk':
      case 'mbn_bk':
      case 'demo_bk':
        return {
          managerPar: {
            isVisible: false,
          },
          avgHourlyRate: {
            isVisible: false,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          managerPerformance: {
            isVisible: false,
          },
          newHireTurnover: {
            isVisible: true,
          },
        }

      case 'jsc_bk':
      case 'jsc_bk_nso':
        return {
          managerPar: {
            isVisible: false,
          },
          avgHourlyRate: {
            isVisible: false,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          managerPerformance: {
            isVisible: false,
          },
          newHireTurnover: {
            isVisible: true,
          },
          isTeamV2: true,
        }

      case 'irmg_pop':
      case 'supreme_pop':
      case 'ace_pop':
      case 'ghai_pop':
      case 'demo_pop':
        return {
          managerPar: {
            isVisible: false,
          },
          avgHourlyRate: {
            isVisible: false,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          managerPerformance: {
            isVisible: false,
          },
          newHireTurnover: {
            isVisible: true,
          },
        }

      case 'cava':
        return {
          managerPar: {
            isVisible: false,
          },
          avgHourlyRate: {
            isVisible: false,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          managerPerformance: {
            isVisible: false,
          },
          newHireTurnover: {
            isVisible: true,
          },
        }

      case 'jitb':
        return {
          managerPar: {
            isVisible: false,
          },
          avgHourlyRate: {
            isVisible: false,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          managerPerformance: {
            isVisible: false,
          },
          newHireTurnover: {
            isVisible: false,
          },
        }

      // Local Favorite
      case 'lfr_ef':
      case 'lfr_tr':
      case 'lfr_sn':
      case 'lfr_lv':
      case 'lfr_vb':
      case 'lfr_tu':
      case 'lfr_ws':
      case 'lfr_jn':
      case 'lfr_mm':
      case 'lfr_ol':
      case 'lfr_dt':
      case 'lfr_uv':
      case 'lfr_bth':
        return {
          managerPar: {
            isVisible: true,
          },
          avgHourlyRate: {
            isVisible: false,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          managerPerformance: {
            isVisible: false,
          },
          newHireTurnover: {
            isVisible: true,
          },
        }

      case 'bibibop':
        return {
          managerPar: {
            isVisible: true,
          },
          avgHourlyRate: {
            isVisible: false,
          },
          unfilledManagerialPositions: {
            isVisible: false,
          },
          managerPerformance: {
            isVisible: false,
          },
          newHireTurnover: {
            isVisible: true,
          },
          roleAvgPayrateTable: {
            isVisible: true,
          },
        }

      case 'rmg':
      case 'rmg_nso':
      case 'sullivans':
      case 'sullivans_nso':
      case 'eddiemerlots':
      case 'eddiemerlots_nso':
      case 'papajohns':
      default:
        return {
          managerPar: {
            isVisible: true,
          },
          avgHourlyRate: {
            isVisible: false,
          },
          unfilledManagerialPositions: {
            isVisible: true,
          },
          managerPerformance: {
            isVisible: true,
          },
          newHireTurnover: {
            isVisible: true,
          },
        }
    }
  }, [brand])
}

export default useTeamConfig
