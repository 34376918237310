import React from 'react'
import styled from 'styled-components'

import Table from 'pared/components/basicUi/table'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import useConfig from './hooks/useConfig'
import useStoresColumns from './hooks/useStoresColumns'
import useStoresDataSource, {
  IDataSourceType,
} from './hooks/useStoresDataSource'

const defaultPageNum = 20

interface IPropsType {
  isBreakdownByDirectors: boolean
  isBreakdownByLocationGroupType: boolean
  selectedLocationGroupIds: number[]
  locationGroupType: string | null
  handleLocationGroupNameClick: (
    locationGroupId: number,
    locationGroupName: string,
  ) => void
  dataSource: IDataSourceType[]
  isAvtMonthlyVisible: boolean
  selectedDateRangeType: string
}

const MobileCotainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const mergeColumns = (columns: any[], extendColumns: any[]) =>
  extendColumns.reduce(
    (newColumns, column) => {
      const existingColumn = newColumns.find((n: any) => n.key === column.key)

      if (!existingColumn) return [...newColumns, column]

      const result = mergeColumns(
        existingColumn?.children || [],
        column?.children || [],
      )

      if (result.length !== 0) existingColumn.children = result

      return newColumns
    },
    [...columns],
  )

const Stores = ({
  isBreakdownByDirectors,
  isBreakdownByLocationGroupType,
  selectedLocationGroupIds,
  locationGroupType,
  handleLocationGroupNameClick,
  dataSource: data,
  isAvtMonthlyVisible,
  selectedDateRangeType,
}: IPropsType) => {
  const config = useConfig()
  const { dataSource, extendColumns } = useStoresDataSource(
    data,
    isBreakdownByDirectors,
    isBreakdownByLocationGroupType,
    locationGroupType,
    selectedLocationGroupIds,
  )
  const columns = useStoresColumns(
    isBreakdownByDirectors,
    isBreakdownByLocationGroupType,
    locationGroupType,
    handleLocationGroupNameClick,
    isAvtMonthlyVisible,
    selectedDateRangeType,
  )

  const pageSize = config?.disablePagination
    ? dataSource?.length || defaultPageNum
    : defaultPageNum

  return (
    <MobileCotainer>
      <Table
        dataSource={dataSource}
        columns={mergeColumns(columns, extendColumns)}
        csvFileName={config.breakdownByStoreCsvFileName}
        rowKey={(values) =>
          'storeId' in values
            ? values.storeId
            : 'directorId' in values
            ? values.directorId
            : values.locationGroupName
        }
        pagination={{ pageSize }}
        defaultSortedField="netSalesNumber"
      />
    </MobileCotainer>
  )
}

export default React.memo(Stores) as unknown as typeof Stores
