import _ from 'lodash'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import navigator from 'pared/Routes/navigator'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import { getBusinessLabel } from 'pared/customer'
import DownArrow from 'pared/images/basic/arrow/caret-down.svg'
import UpArrow from 'pared/images/basic/arrow/caret-up.svg'

import useSalesmanshipConfig from '../hooks/useSalesmanshipConfig'
import { IDetailGuestKpi } from '../index'
import { IOrderBy } from './index'

interface IProps {
  itemizedKpis: IDetailGuestKpi[]
  isTableExpanded: boolean
  onToggleExpansion: () => void
  orderBy: IOrderBy
  setOrderBy: any
  selectedDirector: IDirector | null
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
}

interface IStorePerformanceSortableTh {
  isBackgroundGrey?: boolean
}

interface IRankTablTh {
  textAlign?: string
}

function WebSmallMain({
  itemizedKpis,
  isTableExpanded,
  onToggleExpansion,
  orderBy,
  setOrderBy,
  selectedDirector,
}: IProps) {
  const brandSettings = getBrandSettings()
  const isQsr = brandSettings.isQsr
  const salesmanshipLabels = brandSettings.labels.salesmanship
  const salesmanshipConfig = useSalesmanshipConfig()

  const handleOrdering = (event: any) => {
    const orderingColumnValue = _.get(event, 'currentTarget.dataset.value')
    setOrderBy({
      columnName: orderingColumnValue,
      isAscending: !orderBy.isAscending,
    })
  }

  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)

  let nameLabel = 'Store'

  if (selectedDirctorEmployeeId === -2) {
    // breakdownByDirectors
    nameLabel = getBusinessLabel('director')
  } else {
    nameLabel = 'Store'
  }

  let orderStatus = null
  if (orderBy.isAscending) {
    orderStatus = <UpArrowImg src={UpArrow} alt="ASC"></UpArrowImg>
  } else {
    orderStatus = <DownArrowImg src={DownArrow} alt="DESC"></DownArrowImg>
  }

  const locationRows = _.map(itemizedKpis, (k) => {
    const locationId = _.get(k, 'locationId', 1)
    const doEmployeeId = _.get(k, 'doEmployeeId', 1)
    let linkAction

    if (selectedDirctorEmployeeId === -2) {
      // breakdownByDirectors
      linkAction = navigator.employeeProfile(doEmployeeId)
    } else {
      linkAction = navigator.salesmanship(locationId)
    }

    return (
      <tr key={uuidv4()}>
        <RankTableTd textAlign={'left'}>
          <LightBlueLink to={linkAction} onClick={() => {}}>
            {_.get(k, 'formattedName', '')}
          </LightBlueLink>
        </RankTableTd>
        <RankTableTd>{_.get(k, 'ppa', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'appetizerPpa', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'lbwPpa', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'dessertPpa', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'grossSalesPerLaborHour', '-')}</RankTableTd>
        {isQsr ? (
          <RankTableTd>{_.get(k, 'sosAvgTime', '-')}</RankTableTd>
        ) : (
          <RankTableTd>{_.get(k, 'grossSales', '-')}</RankTableTd>
        )}
        {!salesmanshipConfig['productivityCoefficient']?.isVisible ? null : (
          <RankTableTd>{_.get(k, 'productivityCoefficient', '-')}</RankTableTd>
        )}
      </tr>
    )
  })

  let toggledlocationRows = locationRows

  if (!isTableExpanded) {
    toggledlocationRows = _.slice(locationRows, 0, 5)
  }

  const toggleText = isTableExpanded ? 'Collapse all' : 'Expand All'

  const expandSpan =
    _.size(locationRows) > 5 ? (
      <ExpandAllSpan onClick={onToggleExpansion}>{toggleText}</ExpandAllSpan>
    ) : null

  return (
    <MainContainer>
      <RankTable>
        <thead>
          <BreakdownSecondHeaderTr>
            <RankTableTh>{nameLabel}</RankTableTh>
            <RankTableSortableTh onClick={handleOrdering} data-value={'ppa'}>
              <span>{salesmanshipLabels.totalPpa}</span>
              {orderBy.columnName === 'ppa' ? orderStatus : null}
            </RankTableSortableTh>
            <RankTableSortableTh
              onClick={handleOrdering}
              data-value={'appetizerPpa'}
            >
              <span>{salesmanshipLabels.appetizerPpa}</span>
              {orderBy.columnName === 'appetizerPpa' ? orderStatus : null}
            </RankTableSortableTh>
            <RankTableSortableTh onClick={handleOrdering} data-value={'lbwPpa'}>
              <span>{salesmanshipLabels.lbwPpa}</span>
              {orderBy.columnName === 'lbwPpa' ? orderStatus : null}
            </RankTableSortableTh>
            <RankTableSortableTh
              onClick={handleOrdering}
              data-value={'dessertPpa'}
            >
              <span>{salesmanshipLabels.dessertPpa}</span>
              {orderBy.columnName === 'dessertPpa' ? orderStatus : null}
            </RankTableSortableTh>
            <RankTableSortableTh
              onClick={handleOrdering}
              data-value={'grossSalesPerLaborHour'}
            >
              <span>{salesmanshipLabels.salesPerLaborHour}</span>
              {orderBy.columnName === 'grossSalesPerLaborHour'
                ? orderStatus
                : null}
            </RankTableSortableTh>
            {isQsr ? (
              <RankTableSortableTh
                onClick={handleOrdering}
                data-value={'sosAvgTime'}
              >
                <span>{salesmanshipLabels.speedOfServiceAvg}</span>
                {orderBy.columnName === 'sosAvgTime' ? orderStatus : null}
              </RankTableSortableTh>
            ) : (
              <RankTableSortableTh
                onClick={handleOrdering}
                data-value={'grossSales'}
              >
                <span>{salesmanshipLabels.grossSales}</span>
                {orderBy.columnName === 'grossSales' ? orderStatus : null}
              </RankTableSortableTh>
            )}
            {!salesmanshipConfig['productivityCoefficient']
              ?.isVisible ? null : (
              <RankTableSortableTh
                onClick={handleOrdering}
                data-value={'productivityCoefficient'}
              >
                <span>Productivity Coefficient</span>
                {orderBy.columnName === 'productivityCoefficient'
                  ? orderStatus
                  : null}
              </RankTableSortableTh>
            )}
          </BreakdownSecondHeaderTr>
        </thead>
        <tbody>{toggledlocationRows}</tbody>
      </RankTable>
      {expandSpan}
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 600px;
`

const UpArrowImg = styled.img`
  width: 10px;
  margin: 0 0 2px 5px;
`

const DownArrowImg = styled.img`
  width: 10px;
  margin: 0 0 -1px 5px;
`

const LightBlueLink = styled(Link)`
  color: ${COLORS.Link};
`

const BreakdownSecondHeaderTr = styled.tr`
  border-bottom: 1px solid ${COLORS.Chalkboard};
`

const RankTableSortableTh = styled.th<IRankTablTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  color: ${COLORS.Plum};
  cursor: pointer;
`

const RankTableTd = styled.td<IRankTableTd>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  padding-left: ${(props) => (props.textAlign === 'left' ? '10px' : '0')};
`

const RankTable = styled.table`
  width: 1100px;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`

const ExpandAllSpan = styled.div`
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: ${COLORS.Link};
  text-decoration: underline;
  cursor: pointer;
`

const RankTableTh = styled.th<IRankTablTh>`
  width: 200px;
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

export default WebSmallMain
