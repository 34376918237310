import { gql } from '@apollo/client'

export const TREND_LOCATION_GROUP_SALESMANSHIP_KPIS = gql`
  query trendLocationGroupSalesmanshipKpis(
    $iLocationGroupId: Int!
    $iEndDate: Date!
    $iGroupBy: String!
  ) {
    trendLocationGroupSalesmanshipKpis: trendLocationGroupSalesmanshipKpisV1E1(
      iLocationGroupId: $iLocationGroupId
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) {
      nodes {
        locationGroupId
        startDate
        endDate
        checkAverage
        appetizerPpa
        dessertPpa
        grossSalesPerLaborHour
        lbwPpa
        ppa
      }
    }
  }
`

export const LIST_LOCATION_SALESMANSHIP_KPIS = gql`
  query ListLocationSalesmanshipKpisV2(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
  ) {
    listLocationSalesmanshipKpisV2(
      iEndDate: $iEndDate
      iStartDate: $iStartDate
      iFilter: $iFilter
    ) {
      nodes {
        appetizerPpa
        dessertPpa
        grossSalesPerLaborHour
        lbwPpa
        ppa
        grossSales
        sosAvgTime
        locationInfo {
          id
          code
          name
        }
      }
    }
  }
`

export const LIST_LOCATION_GROUP_SALESMANSHIP_KPIS = gql`
  query ListLocationGroupSalesmanshipKpis(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupSalesmanshipKpis(
      iEndDate: $iEndDate
      iStartDate: $iStartDate
      iFilter: $iFilter
    ) {
      nodes {
        checkAverage
        appetizerPpa
        dessertPpa
        grossSalesPerLaborHour
        lbwPpa
        ppa
        grossSales
        sosAvgTime
        locationGroupId
      }
    }
  }
`
