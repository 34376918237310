import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import {
  DAYPARTS,
  EE_COMPS_METRICS,
  FILTER_TYPE,
  OPS_COMPS_DAYPART_KEY,
} from 'pared/constants'
import getPeriods, { IPeriod } from 'pared/data/getPeriods'

import Main from './Main'
import {
  GET_LOCATION_OPS_COMPS_BREAKDOWN_BY_DATE,
  GET_LOCATION_OPS_COMPS_BREAKDOWN_BY_DATE_ALL,
  GET_LOCATION_OPS_COMPS_BREAKDOWN_BY_DAYS_WORKED,
  GET_LOCATION_OPS_COMPS_BREAKDOWN_BY_DAYS_WORKED_ALL,
} from './gql'

export interface IOpsComp {
  employeeId: number
  firstName: string
  lastName: string
  totalOpsComps: number
  totalGross: number
  percentOpsCompOfGross: number
  compTypes: {
    foodQualityComps: number
    mgrOverrideComps: number
    qualityComps: number
    serviceCompComps: number
    serviceIssuesComps: number
    timeComps: number
    walkOutComps: number
  }
}

interface IProps {
  navParams: INavParams
  selectedPeriod: IPeriod | null
  dayPart: string
  filterType: string
  dateRangeStartDate: any
  dateRangeEndDate: any
  numDaysWorked?: number
}

const CompsTable = ({
  navParams,
  selectedPeriod,
  dayPart,
  filterType,
  dateRangeStartDate,
  dateRangeEndDate,
  numDaysWorked,
}: IProps) => {
  const storeId = navParams.storeId || ''
  const storeIdNum = parseInt(storeId, 10) || 0

  const [
    getOpsCompsByDaysWorked,
    {
      loading: opsCompsByDaysWorkedLoading,
      error: opsCompsByDaysWorkedError,
      data: opsCompsByDaysWorkedData,
    },
  ] = useLazyQuery(GET_LOCATION_OPS_COMPS_BREAKDOWN_BY_DAYS_WORKED)
  const [
    getOpsCompsByDate,
    {
      loading: opsCompsByDateLoading,
      error: opsCompsByDateError,
      data: opsCompsByDateData,
    },
  ] = useLazyQuery(GET_LOCATION_OPS_COMPS_BREAKDOWN_BY_DATE)
  const [
    getOpsCompsByDaysWorkedAll,
    {
      loading: opsCompsByDaysWorkedAllLoading,
      error: opsCompsByDaysWorkedAllError,
      data: opsCompsByDaysWorkedAllData,
    },
  ] = useLazyQuery(GET_LOCATION_OPS_COMPS_BREAKDOWN_BY_DAYS_WORKED_ALL)
  const [
    getOpsCompsByDateAll,
    {
      loading: opsCompsByDateAllLoading,
      error: opsCompsByDateAllError,
      data: opsCompsByDateAllData,
    },
  ] = useLazyQuery(GET_LOCATION_OPS_COMPS_BREAKDOWN_BY_DATE_ALL)
  const [isOpsCompsExpanded, setIsOpsCompsExpanded] = useState<boolean>(false)
  const [selectedMetrics, setSelectedMetrics] = useState<string>(
    EE_COMPS_METRICS.EE_OPS_COMPS,
  )

  useEffect(() => {
    async function fetchData() {
      const { periods } = await getPeriods()

      let newSelectedPeriod: IPeriod | null = selectedPeriod

      if (!newSelectedPeriod) {
        newSelectedPeriod = periods[0]
      }

      if (storeIdNum) {
        switch (filterType) {
          case FILTER_TYPE.SHIFTS: {
            if (dayPart === DAYPARTS.ALL) {
              getOpsCompsByDaysWorkedAll({
                variables: {
                  locationId: storeIdNum,
                  lastNShifts: numDaysWorked,
                },
              })
            } else {
              getOpsCompsByDaysWorked({
                variables: {
                  locationId: storeIdNum,
                  lastNShifts: numDaysWorked,
                  service: _.get(OPS_COMPS_DAYPART_KEY, [dayPart], 'DINNER'),
                },
              })
            }
            break
          }
          case FILTER_TYPE.PERIOD: {
            if (dayPart === DAYPARTS.ALL) {
              getOpsCompsByDateAll({
                variables: {
                  locationId: storeIdNum,
                  startRange: _.get(newSelectedPeriod, 'periodStart', ''),
                  endRange: _.get(newSelectedPeriod, 'periodEnd', ''),
                },
              })
            } else {
              getOpsCompsByDate({
                variables: {
                  locationId: storeIdNum,
                  startRange: _.get(newSelectedPeriod, 'periodStart', ''),
                  endRange: _.get(newSelectedPeriod, 'periodEnd', ''),
                  service: _.get(OPS_COMPS_DAYPART_KEY, [dayPart], 'DINNER'),
                },
              })
            }
            break
          }
          case FILTER_TYPE.DATE: {
            if (dayPart === DAYPARTS.ALL) {
              getOpsCompsByDateAll({
                variables: {
                  locationId: storeIdNum,
                  startRange: moment(dateRangeStartDate).format(),
                  endRange: moment(dateRangeEndDate).format(),
                },
              })
            } else {
              getOpsCompsByDate({
                variables: {
                  locationId: storeIdNum,
                  startRange: moment(dateRangeStartDate).format(),
                  endRange: moment(dateRangeEndDate).format(),
                  service: _.get(OPS_COMPS_DAYPART_KEY, [dayPart], 'DINNER'),
                },
              })
            }
            break
          }
          default: {
          }
        }
      }
    }

    fetchData()
  }, [
    dayPart,
    selectedPeriod,
    filterType,
    numDaysWorked,
    dateRangeEndDate,
    getOpsCompsByDaysWorked,
    getOpsCompsByDate,
    getOpsCompsByDaysWorkedAll,
    getOpsCompsByDateAll,
  ])

  let opsComps: IOpsComp[] = []

  const formatOpsComps = (opsCompsByDaysWorkedData: any) => {
    const rawOpsComps = _.get(
      opsCompsByDaysWorkedData,
      'getLocationOpsCompsBreakdownByService.nodes',
      [],
    )
    const formattedOpsComps = _.map(rawOpsComps, (o) => {
      const compTypes = {
        foodQualityComps: _.get(o, 'foodQualityComps', 0),
        mgrOverrideComps: _.get(o, 'mgrOverrideComps', 0),
        qualityComps: _.get(o, 'qualityComps', 0),
        serviceCompComps: _.get(o, 'serviceCompComps', 0),
        serviceIssuesComps: _.get(o, 'serviceIssuesComps', 0),
        timeComps: _.get(o, 'timeComps', 0),
        walkOutComps: _.get(o, 'walkOutComps', 0),
      }

      const percentOpsCompOfGross = _.get(o, 'percentOpsCompOfGross', 0)

      return {
        employeeId: _.get(o, 'employeeId', 0),
        firstName: _.get(o, 'firstName', ''),
        lastName: _.get(o, 'lastName', ''),
        totalOpsComps: _.get(o, 'totalOpsComps', 0),
        totalGross: _.get(o, 'totalGross', 0),
        role: _.get(o, 'role', ''),
        percentOpsCompOfGross: percentOpsCompOfGross
          ? percentOpsCompOfGross
          : 0,
        compTypes,
      }
    })

    return _.orderBy(formattedOpsComps, ['totalOpsComps'], ['asc'])
  }

  if (
    filterType === FILTER_TYPE.SHIFTS &&
    opsCompsByDaysWorkedAllData &&
    opsCompsByDaysWorkedAllData.getLocationOpsCompsBreakdownByService &&
    Array.isArray(
      opsCompsByDaysWorkedAllData.getLocationOpsCompsBreakdownByService.nodes,
    )
  ) {
    opsComps = formatOpsComps(opsCompsByDaysWorkedAllData)
  }

  if (
    filterType === FILTER_TYPE.SHIFTS &&
    opsCompsByDaysWorkedData &&
    opsCompsByDaysWorkedData.getLocationOpsCompsBreakdownByService &&
    Array.isArray(
      opsCompsByDaysWorkedData.getLocationOpsCompsBreakdownByService.nodes,
    )
  ) {
    opsComps = formatOpsComps(opsCompsByDaysWorkedData)
  }

  if (
    (filterType === FILTER_TYPE.PERIOD || filterType === FILTER_TYPE.DATE) &&
    opsCompsByDateData &&
    opsCompsByDateData.getLocationOpsCompsBreakdownByService &&
    Array.isArray(
      opsCompsByDateData.getLocationOpsCompsBreakdownByService.nodes,
    )
  ) {
    opsComps = formatOpsComps(opsCompsByDateData)
  }

  if (
    (filterType === FILTER_TYPE.PERIOD || filterType === FILTER_TYPE.DATE) &&
    opsCompsByDateAllData &&
    opsCompsByDateAllData.getLocationOpsCompsBreakdownByService &&
    Array.isArray(
      opsCompsByDateAllData.getLocationOpsCompsBreakdownByService.nodes,
    )
  ) {
    opsComps = formatOpsComps(opsCompsByDateAllData)
  }

  let rankedOpsComps: IOpsComp[] = []
  switch (selectedMetrics) {
    case EE_COMPS_METRICS.EE_OPS_COMPS: {
      rankedOpsComps = _.sortBy(opsComps, (c) => {
        return _.get(c, 'totalOpsComps', 0)
      })
      break
    }
    case EE_COMPS_METRICS.EE_GROSS_SALES: {
      rankedOpsComps = _.sortBy(opsComps, (c) => {
        return _.get(c, 'totalGross', 0) * -1
      })
      break
    }
    case EE_COMPS_METRICS.OPS_COMP_RATE: {
      rankedOpsComps = _.sortBy(opsComps, (c) => {
        return _.get(c, 'percentOpsCompOfGross', 0) * -1
      })
      break
    }
    default: {
      rankedOpsComps = _.sortBy(opsComps, (c) => {
        return Math.abs(_.get(c, 'totalOpsComps', 0))
      })
      break
    }
  }

  return (
    <Main
      isLoading={
        opsCompsByDaysWorkedLoading ||
        opsCompsByDateLoading ||
        opsCompsByDaysWorkedAllLoading ||
        opsCompsByDateAllLoading
      }
      opsComps={opsComps}
      rankedOpsComps={rankedOpsComps}
      isOpsCompsExpanded={isOpsCompsExpanded}
      setIsOpsCompsExpanded={setIsOpsCompsExpanded}
      selectedMetrics={selectedMetrics}
      setSelectedMetrics={setSelectedMetrics}
    />
  )
}

export default CompsTable
