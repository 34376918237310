import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import COLORS from 'pared/constants/colors'

import { IApiDataType } from '../types'

interface IProps {
  customizationType: string
}

interface IUploadReportStatusNodeType {
  data: {
    period: string
    report: string
    status: string
    message: string
  }
  datetime: string
}

const query = gql`
  query UploadReportStatus($iCustomizationType: String!) {
    customizedData(
      condition: { customizationType: $iCustomizationType }
      last: 30
    ) {
      nodes {
        data
        datetime
      }
    }
  }
`

export const uploadReportStatusConfigs = {
  time: 'date-string',
  report: 'string',
  status: 'colored-text',
  message: 'string',
} as const

const buildUseUploadReportStatus = ({ customizationType }: IProps) => {
  const useUploadReportStatus = () => {
    const { data, loading } = useQuery(query, {
      variables: {
        iCustomizationType: customizationType,
      },
    })

    return {
      data: useMemo((): IApiDataType => {
        return {
          source: data?.customizedData.nodes
            .map((log: IUploadReportStatusNodeType) => {
              return {
                time: moment(log?.datetime),
                report: `${log?.data.report} - ${log?.data.period}`,
                status: {
                  text: log?.data.status,
                  textColor:
                    log?.data.status === 'Error' ? 'danger' : COLORS.Plum,
                },
                message: log?.data.message,
              }
            })
            .sort((a: any, b: any) => {
              return moment(b.time).diff(a.time)
            }),
        }
      }, [data]),
      loading: loading,
    }
  }

  return useUploadReportStatus
}

export const useLfrUploadGfpReportStatus = buildUseUploadReportStatus({
  customizationType: 'GFP_LOGGER_INFO',
})
export const useFarwestUploadReportStatus = buildUseUploadReportStatus({
  customizationType: 'UPLOAD_REPORT_LOGGER_INFO',
})
