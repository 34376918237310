import React from 'react'

import Chart from 'pared/charts'
import { RAW_LOCATION_AVG_HOURLY_RATES } from 'pared/constants/raw_data/avgHourlyRates'

import useConfig from './hooks/useConfig'

interface IPropsType {
  selectedLocation: {
    id: keyof typeof RAW_LOCATION_AVG_HOURLY_RATES
  }
}

const AvgHourlyRatesChart = ({ selectedLocation }: IPropsType) => {
  const config = useConfig(selectedLocation.id)

  return <Chart {...config} width={1100} height={350} />
}

export default React.memo(AvgHourlyRatesChart)
