import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import React, { useMemo } from 'react'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import useGroupSelector from 'pared/components/GroupSelector/hooks/useGroupSelector'
import ExpoSelect from 'pared/components/basicUi/select'
import Spin from 'pared/components/basicUi/spin'
import Table from 'pared/components/basicUi/table'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'
import {
  TYPE_CUSTOM,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_WEEK,
  TYPE_YEAR,
} from 'pared/data/getDateRanges'
import DownloadIcon from 'pared/images/basic/download.png'

import useCollapseArrowClicked from './hooks/useCollapseArrowClicked'
import useColumnSorter from './hooks/useColumnSorter'
import useColumns from './hooks/useColumns'
import useCsvData from './hooks/useCsvData'
import useDataSource from './hooks/useDataSource'
import { SortDirectionType } from './type'

interface IPropsType {
  navParams: INavParams
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      font-size: 18px;
      padding: 0px 20px;
    }
  }
`

const Selectors = styled.div`
  display: flex;
  gap: 10px;
  height: 100%;

  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    &:last-child {
      @media ${MOBILE_WIDTH} {
        margin: 5px 0px;
        height: 100%;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const StyledFormControl = styled(FormControl)`
  background-color: white;
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

const MobileCotainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const rowStyle = `
  &:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }
`

const SorterSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const StyledCSVLink = styled(CSVLink)`
  font-family: Lexend-Regular;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 250px;
`

const CorporateCreditCardReconciliation = ({ navParams }: IPropsType) => {
  const { brand } = getBrandSettings()
  const { clickedHeaders, onClick } = useCollapseArrowClicked()
  const { groupBy, groupByType, setGroupBy, options, locationGroupId } =
    useGroupSelector({ initialGroupBy: 'store' })

  const locationGroupIds = useMemo(
    () => [parseInt(locationGroupId) || BRAND_LOCATION_GROUP_ID[brand]],
    [groupByType, locationGroupId],
  )

  const isBreakdownByDirectors = groupByType !== 'store'

  const columns = useColumns(
    isBreakdownByDirectors,
    groupByType,
    onClick,
    clickedHeaders,
  )

  const {
    sorterOptions,
    selectedColumn,
    setSelectedColumn,
    directionOptions,
    selectedDirection,
    setSelectedDirection,
  } = useColumnSorter()

  const { dataSource, csvDataSource, loading } = useDataSource(
    brand,
    isBreakdownByDirectors,
    locationGroupIds,
    groupByType,
    clickedHeaders,
    selectedColumn,
    selectedDirection,
  )

  const csvData = useCsvData(isBreakdownByDirectors, groupByType, csvDataSource)

  return (
    <>
      <Header>
        <div>
          <h1>Credit Card Reconciliation</h1>
        </div>

        <Selectors>
          <StyledFormControl margin="dense">
            <ExpoSelect
              value={groupBy}
              onChange={setGroupBy}
              dataSource={options}
              fullWidth
              displayEmpty
            />
          </StyledFormControl>

          <DateRangeSelector
            navParams={navParams}
            dateRangeOptions={[
              TYPE_YEAR,
              TYPE_QUARTER,
              TYPE_PERIOD,
              TYPE_WEEK,
              TYPE_LAST_WEEK,
              TYPE_THIS_WEEK,
              TYPE_CUSTOM,
            ]}
            preSelectedDateRange={TYPE_LAST_WEEK}
          />
        </Selectors>
      </Header>

      <MobileCotainer>
        <Selectors>
          <h3>Sort by:</h3>
          <StyledFormControl variant="outlined" margin="dense">
            <SorterSelect
              value={selectedColumn}
              onChange={(e) => {
                setSelectedColumn(e.target.value as string)
              }}
              displayEmpty
            >
              {sorterOptions?.map((column) => {
                return (
                  <MenuItem key={column.key} value={column.key}>
                    {column.title}
                  </MenuItem>
                )
              })}
            </SorterSelect>
          </StyledFormControl>

          <h3>Sort direction:</h3>
          <StyledFormControl variant="outlined" margin="dense">
            <SorterSelect
              value={selectedDirection}
              onChange={(e) => {
                setSelectedDirection(e.target.value as SortDirectionType)
              }}
              displayEmpty
            >
              {directionOptions?.map((op) => {
                return (
                  <MenuItem key={op.key} value={op.key}>
                    {op.title}
                  </MenuItem>
                )
              })}
            </SorterSelect>
          </StyledFormControl>
        </Selectors>

        <Spin spinning={loading}>
          <StyledCSVLink
            data={csvData}
            filename={`getexpo-credit-card-reconciliation.csv`}
          >
            <img src={DownloadIcon} height="30px" />
            <div>Download Table as CSV</div>
          </StyledCSVLink>
          <Table
            dataSource={dataSource}
            columns={columns}
            rowKey={(values) => {
              if (!values.groupInfo) return `summary-${values.creditCardType}`

              return 'locationId' in values.groupInfo
                ? `${values.groupInfo.locationId} - ${values.creditCardType}`
                : `${values.groupInfo.locationGroupName} - ${values.creditCardType}`
            }}
            rowStyle={rowStyle}
            pagination={{ pageSize: dataSource.length }}
          />
        </Spin>
      </MobileCotainer>
    </>
  )
}

export default React.memo(CorporateCreditCardReconciliation)
