import { gql, useMutation } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { INavParams } from 'pared/Routes/navParams'
import navigator from 'pared/Routes/navigator'
import { getBrandFromPathname } from 'pared/utils/brand'
import { getPathnameWithoutBrand } from 'pared/utils/brand'
import { getBrand } from 'pared/utils/brand'
import { signInUser } from 'pared/utils/user'

import Main from './Main'

const MUTATION_AUTHENTICATE_USER = gql`
  mutation AuthenticateUser($email: String!, $password: String!) {
    authenticate(input: { iEmail: $email, iPassword: $password }) {
      jwtToken
    }
  }
`

const MUTATION_AUTHENTICATE_USER_WITH_TOKEN = gql`
  mutation AuthenticateUserWithToken(
    $iEmail: String!
    $iAuthenticationToken: String!
  ) {
    authenticateWithToken(
      input: { iEmail: $iEmail, iAuthenticationToken: $iAuthenticationToken }
    ) {
      jwtToken
    }
  }
`

interface IProps {
  navParams: INavParams
}

function SignIn({ navParams }: IProps) {
  const pagePathname = _.get(navParams, 'pagePathname', '')

  const brand = getBrandFromPathname(pagePathname)

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [authenticateUser, { loading, error }] = useMutation(
    MUTATION_AUTHENTICATE_USER,
  )
  const [authenticateUserWithToken] = useMutation(
    MUTATION_AUTHENTICATE_USER_WITH_TOKEN,
  )
  const [hasSignedInWithToken, setHasSignedInWithToken] =
    useState<boolean>(false)

  useEffect(() => {
    async function signInWithTokenIfExists() {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const token = urlParams.get('token')
      const formattedEmail = (urlParams.get('email') || '').trim().toLowerCase()

      if (!_.isEmpty(token)) {
        try {
          const response = await authenticateUserWithToken({
            variables: {
              iEmail: formattedEmail,
              iAuthenticationToken: token,
            },
          })

          if (
            response &&
            response.data &&
            response.data.authenticateWithToken &&
            response.data.authenticateWithToken.jwtToken
          ) {
            await signInUser(
              response.data.authenticateWithToken.jwtToken,
              formattedEmail,
            )
            setHasSignedInWithToken(true)
            return true
          } else {
            setErrorMessage('Fail to log in with the authentication token.')
          }
        } catch (error: any) {
          setErrorMessage(
            error || 'Fail to log in with the authentication token.',
          )
        }
      }
    }

    signInWithTokenIfExists()
  }, [])

  const onSignIn = async (email: string, password: string) => {
    const formattedEmail = (email || '').trim().toLowerCase()

    if (!formattedEmail) {
      setErrorMessage('The email is required.')
      return false
    }

    if (!password) {
      setErrorMessage('The password is required.')
      return false
    }

    try {
      const response = await authenticateUser({
        variables: {
          password,
          email: formattedEmail,
        },
      })

      if (
        response &&
        response.data &&
        response.data.authenticate &&
        response.data.authenticate.jwtToken
      ) {
        await signInUser(response.data.authenticate.jwtToken, formattedEmail)
        return true
      } else {
        setErrorMessage('The email or the password may be incorrect.')
        return false
      }
    } catch (error: any) {
      setErrorMessage(error || 'The email or the password may be incorrect.')
      return false
    }
  }

  if (hasSignedInWithToken) {
    const brand = getBrand()
    return <Redirect to={navigator.changePassword(brand)} />
  } else {
    let signInErrorMessage: string = ''
    if (error && error.message) {
      signInErrorMessage = error.message
    }

    return (
      <Main
        isLoading={loading}
        onSignIn={onSignIn}
        errorMessage={signInErrorMessage || errorMessage}
        brand={brand}
      />
    )
  }
}

export default SignIn
