import L from 'leaflet'
import _ from 'lodash'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import styled from 'styled-components'

import { BRAND_LOGOS, BRAND_NAME } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { IDirector } from 'pared/data/getDirectors'
import { getBrand } from 'pared/utils/brand'

import ChangeView from './ChangeView'
import useBrandIconsConfig from './hooks/useBrandIconsConfig'
import { IMapView } from './index'

interface IColorBox {
  color: string
}

interface IProps {
  mapView: IMapView
  allDirectors: IDirector[]
  locationByDo: any
}

const generateIcon = (logo: typeof BRAND_LOGOS[number]) => {
  const { dark, darkDimension } = logo
  const iconWidth = 75
  const iconHight =
    (iconWidth * darkDimension.height * 1.0) / darkDimension.width

  return new L.Icon({
    iconUrl: dark,
    iconSize: new L.Point(iconWidth, iconHight),
    className: 'leaflet-div-icon',
  })
}

const Main = ({ mapView, locationByDo, allDirectors }: IProps) => {
  const brand = getBrand()
  const brandIcon = generateIcon(BRAND_LOGOS[brand])
  const locationByDoArray = _.values(locationByDo)
  const config = useBrandIconsConfig()

  const circleMarkers = _.map(locationByDoArray, (locations, index) => {
    const markers = _.map(locations, (l) => {
      const locationCode = _.get(l, 'locationCode', '')
      const locationName = _.get(l, 'locationName', '')
      const icon = (() => {
        if (!config.breakdownByBrand) return brandIcon

        const director = allDirectors.find(
          (d) => d.employeeId === l.doEmployeeId,
        )
        const brandCode = Object.keys(BRAND_NAME).find(
          (key) => BRAND_NAME[key] === director?.firstName,
        )

        if (!brandCode) return brandIcon

        return generateIcon(BRAND_LOGOS[brandCode])
      })()

      return (
        <Marker
          key={locationCode}
          position={[
            _.get(l, 'coordinates.latitude', 0),
            _.get(l, 'coordinates.longitude', 0),
          ]}
          icon={icon}
        >
          <Popup>{`${locationCode} - ${locationName}`}</Popup>
        </Marker>
      )
    })

    return (
      <MarkerClusterGroup
        key={index}
        // @ts-ignore
        showCoverageOnHover={false}
        iconCreateFunction={(cluster: any) => {
          return L.divIcon({
            html: `<span>${cluster.getChildCount()}</span>`,
            className: `marker-cluster-custom marker-cluster-${
              index % _.size(COLORS.COLOR_HUE)
            }`,
            iconSize: L.point(40, 40, true),
          })
        }}
      >
        {markers}
      </MarkerClusterGroup>
    )
  })

  const directorEmployeeIds = _.keysIn(locationByDo)

  const legendData = _.map(directorEmployeeIds, (id, index) => {
    const director = _.first(
      _.filter(allDirectors, (d) => {
        return _.get(d, 'employeeId', 22) === parseInt(id)
      }),
    )
    const firstName = _.get(director, 'firstName', '')
    const lastName = _.get(director, 'lastName', '')

    return {
      color: COLORS.COLOR_HUE[index % _.size(COLORS.COLOR_HUE)],
      directorName: `${firstName} ${lastName}`,
    }
  })

  const lengends = _.map(legendData, (l) => {
    const color = _.get(l, 'color', '#CB5A9E')
    const directorName = _.get(l, 'directorName', '')

    return (
      <IndividualLegend key={l.directorName}>
        <ColorBox color={color} />
        <LegendText>{directorName}</LegendText>
      </IndividualLegend>
    )
  })

  const latitude = _.get(mapView, 'latitude', 37.0902)
  const longitude = _.get(mapView, 'longitude', -95.7129)
  const zoom = _.get(mapView, 'zoom', 4)

  return (
    <div>
      <MapContainer
        style={{ height: '552px', width: '1100px' }}
        zoom={4}
        center={[latitude, longitude]}
      >
        <ChangeView center={[latitude, longitude]} zoom={zoom} />
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          minZoom={4}
        />
        {circleMarkers}
      </MapContainer>
      <LegendContainer>{lengends}</LegendContainer>
    </div>
  )
}

const LegendContainer = styled.div``

const IndividualLegend = styled.div`
  padding: 10px 10px 10px 0;
  display: inline-block;
`

const ColorBox = styled.div<IColorBox>`
  width: 15px;
  height: 10px;
  background-color: ${(props) => props.color};
  margin: 5px 5px 5px 5px;
  border-radius: 2px;
  float: left;
`

const LegendText = styled.div`
  font-family: Lexend-Regular;
  float: left;
`

export default Main
