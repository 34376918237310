import { getBrandSettings } from 'pared/customer'

export const KPIS_BY_UNIT: { [key: string]: string } = {
  score: '',
  net_sales_budget_variance: '%',
  ops_comps_percent: '%',
  avt_percent: '%',
  hourly_labor_budget_variance: '%',
  controllable_expenses_percent: '%',
  rcp_budget_variance: '%',
  momentfeed_score: '',
  ot_hours: ' hrs',
  yoy_in_unit_check_count_growth: '%',
  yoy_in_unit_avg_check_growth: '%',
  annualized_hourly_turnover_rate: '%',
  annualized_salaried_turnover_rate: '%',
  cogs_budget_variance: '%',
  yext_score: '',
  zenput: '%',
  cavayou: '%',
}

export function getKpiDisplayNames(): { [kpi: string]: string } {
  const brandSettings = getBrandSettings()
  const financialLabel = brandSettings.labels.financial

  return {
    score: 'Score',
    net_sales_budget_variance: `Net Sales ${financialLabel.variance}`,
    ops_comps_percent: 'Ops Comps Percent',
    avt_percent: 'AvT Percent',
    hourly_labor_budget_variance: `${financialLabel.hourlyLabor} ${financialLabel.variance}`,
    controllable_expenses_percent: 'Controllable Expenses Percent',
    rcp_budget_variance: `${financialLabel.rcp} ${financialLabel.variance}`,
    momentfeed_score: 'Moment Feed Score',
    ot_hours: 'OT Hours',
    yoy_in_unit_check_count_growth: `YoY In-Unit ${financialLabel.transaction} Growth`,
    yoy_in_unit_avg_check_growth: `YoY In-Unit ${financialLabel.transactionAvg} Growth`,
    annualized_hourly_turnover_rate: 'Hourly Turnover Annualized',
    annualized_salaried_turnover_rate: 'Manager Turnover Annualized',
    cogs_budget_variance: `${financialLabel.cogs} ${financialLabel.variance}`,
    yext_score: 'Yext Score',
    zenput: 'Zenput',
    cavayou: 'CavaYou',
  }
}

export const IS_KPI_BETTER_WHEN_HIGH: { [key: string]: boolean } = {
  score: true,
  net_sales_budget_variance: true,
  ops_comps_percent: false,
  avt_percent: false,
  hourly_labor_budget_variance: false,
  controllable_expenses_percent: false,
  rcp_budget_variance: true,
  momentfeed_score: true,
  ot_hours: false,
  yoy_in_unit_check_count_growth: true,
  yoy_in_unit_avg_check_growth: true,
  annualized_hourly_turnover_rate: false,
  annualized_salaried_turnover_rate: false,
  cogs_budget_variance: false,
  yext_score: true,
  zenput: true,
  cavayou: true,
}
