import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Modal from '@material-ui/core/Modal'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import AddIcon from '@mui/icons-material/AddCircleOutlineRounded'
import RemoveIcon from '@mui/icons-material/RemoveCircleOutlineRounded'
import moment from 'moment'
import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'

import useEditFeedback, { IEditFeedbackType } from './hooks/useEditFeedback'

export interface IPropsType extends IEditFeedbackType {
  employee: string
  locationRole: string
  onClose: () => void
}

const Container = styled.div`
  width: 50%;
  min-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px;
  outline: none;
  background-color: white;
`

const Title = styled.div`
  font-family: Lexend-SemiBold;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
`

const Employee = styled.div`
  font-family: Lexend-SemiBold;
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
`

const LocationRole = styled.div`
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17.5px;
`

const SubTitle = styled.div`
  margin-top: 15px;
  font-family: Lexend-SemiBold;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`

const ConfirmButton = styled.button<{ disabled: boolean }>`
  margin-top: 25px;
  background-color: #07a4c7;
  border: none;
  color: white;
  padding: 15px 15px 15px 15px;
  width: 250px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: Lexend-Regular;
  font-size: 14px;
  ${({ disabled }) =>
    disabled
      ? `
          cursor: none;
          pointer-events: none;
        `
      : `cursor: pointer;`};
`

const StyledFormControl = styled(FormControl)`
  min-width: 150px;
`

const FeedBackTextarea = styled.textarea<{ error: boolean }>`
  padding: 10px;
  width: 100%;
  height: 185px;
  resize: none;
  margin-top: 10px;
  border-radius: 0px;
  outline: none;
  ${({ error }) =>
    error
      ? `
        border-color: red;
        `
      : `border-color: inherit;`};
`

const Plan = styled.div`
  display: flex;
  margin-top: 5px;
  gap: 10px;
  align-items: center;

  &:first-child {
    margin-top: 10px;
  }

  .MuiSvgIcon-root {
    cursor: pointer;
  }
`

const StyledTextField = styled(TextField)`
  flex-grow: 1;

  .MuiOutlinedInput-root {
    border-radius: 0px;
  }
`

const PERFORMANCE_ITEMS = [
  'Below average',
  'Average',
  'Above average',
  'Ready to promote',
]

const EditModal = ({
  employee,
  locationRole,
  onClose,
  ...props
}: IPropsType) => {
  const { form, setForm, submit, isLoading, hasErrors } = useEditFeedback(
    props,
    onClose,
  )

  return (
    <Modal onClose={onClose} open>
      <Container>
        <Title>Employee Development Feedback</Title>
        <Employee>{employee}</Employee>
        <LocationRole>{locationRole}</LocationRole>

        <SubTitle>Performance Review</SubTitle>
        <StyledFormControl variant="standard">
          <InputLabel id="performace-review">Select Rating</InputLabel>
          <Select
            labelId="performace-review"
            label="Performance"
            value={form.performance}
            onChange={({ target: { value } }) =>
              setForm({ type: 'performance', value: value as string })
            }
            error={hasErrors && form.performanceErrors.length !== 0}
          >
            {PERFORMANCE_ITEMS.map((item) => (
              <MenuItem
                key={item}
                value={item.toUpperCase().replace(/ /g, '_')}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
          {!hasErrors || form.performanceErrors.length === 0 ? null : (
            <FormHelperText error>
              {form.performanceErrors.join(',')}
            </FormHelperText>
          )}
        </StyledFormControl>

        <SubTitle>Feedback</SubTitle>
        <FeedBackTextarea
          placeholder={`Please provide input on ${employee}'s recent performance`}
          value={form.feedback}
          onChange={({ target: { value } }) =>
            setForm({ type: 'feedback', value })
          }
          error={hasErrors && form.feedbackErrors.length !== 0}
        />
        {!hasErrors || form.feedbackErrors.length === 0 ? null : (
          <FormHelperText error>{form.feedbackErrors.join(',')}</FormHelperText>
        )}

        <SubTitle>Development Plan</SubTitle>
        <div>
          {form.plans.map(({ key, name, date, errors }, index) => (
            <React.Fragment key={key}>
              <Plan>
                <StyledTextField
                  value={name}
                  onChange={({ target: { value } }) =>
                    setForm({ type: 'plan-name', index, value })
                  }
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder="Add Action Item"
                  error={hasErrors && errors.length !== 0}
                />

                <DatePicker
                  selected={date}
                  onChange={(date) => {
                    if (date) setForm({ type: 'plan-date', index, value: date })
                  }}
                  minDate={moment().toDate()}
                  placeholderText={'Choose Due Date'}
                  customInput={
                    <StyledTextField
                      inputProps={{ readOnly: true }}
                      type="text"
                      variant="outlined"
                      size="small"
                      placeholder="Due Date"
                      error={hasErrors && errors.length !== 0}
                    />
                  }
                />

                {form.plans.length - 1 === index && form.plans.length < 4 && (
                  <AddIcon onClick={() => setForm({ type: 'plan-add' })} />
                )}

                {form.plans.length > 1 && (
                  <RemoveIcon
                    onClick={() => setForm({ type: 'plan-remove', index })}
                  />
                )}
              </Plan>

              {!hasErrors || errors.length === 0 ? null : (
                <FormHelperText error>{errors.join(',')}</FormHelperText>
              )}
            </React.Fragment>
          ))}
        </div>

        <ConfirmButton onClick={submit} disabled={isLoading}>
          <Spin spinning={isLoading}>Submit Feedback</Spin>
        </ConfirmButton>
      </Container>
    </Modal>
  )
}

export default EditModal
