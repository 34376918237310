import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import getDateRanges, {
  GROUP_BY_PERIOD,
  IDateRange,
} from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'
import { getBrand, getBrandDefaultCalendarStartDate } from 'pared/utils/brand'
import { toPercentageString } from 'pared/utils/number'

import {
  TREND_LOCATION_GROUP_TURNOVERS,
  TREND_LOCATION_GROUP_TURNOVERS_THIRTY_DAY,
} from '../gql'
import useTeamConfig from '../hooks/useTeamConfig'
import Main from './Main'
import { ITurnoverType } from './constants'

interface IProps {
  navParams: INavParams
  selectedDateRange: IDateRange | null
  selectedDirector: IDirector | null
}

interface IUnfilledManagerialPosition {
  positionId: number
  roleName: string
  timeVacant: string
  candidateName: string
  filledPendingStartDate: string
  trainingStoreName: string
  trainingStoreId: number
  comment: string
  commentedAt: string
  commentedByFirstName: string
  commentedByLastName: string
}

interface ITurnover {
  month: number
  locationHourlyTerminatedCountInThisMonth: number
  locationHourlyMonthlyTurnoverRate: number
  locationHourlyAnnualizedTurnoverRate: number
  systemwideHourlyAnnualizedTurnoverRate: number
  systemwideSalariedAnnualizedTurnoverRate: number
}

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

export interface ILocationTeamData {
  location: {
    id: number | null
    code: string
    name: string
    displayName: string
    doEmployeeId: number | null
    doName: string
    gmEmployeeId: number | null
    gmName: string
  }

  kpis: {
    month: string
    managerPar: string
    salariedTurnoverInCurrentMonth: string
    hourlyTurnoverInCurrentMonth: string
    annualizedHourlyTurnoverRate: string
    annualizedHourlyTurnoverRank: string
  }

  turnovers: ITurnover[]

  unfilledManagerialPositions: IUnfilledManagerialPosition[]
}

function Chart({ navParams, selectedDateRange, selectedDirector }: IProps) {
  const storeId = navParams.storeId
  const brand = getBrand()
  const brandDefaultCalendarStartDate = getBrandDefaultCalendarStartDate()
  const [type, setType] = useState<ITurnoverType>('annualized')

  const locationId = parseInt(storeId || '0', 10) || 0
  const isTeamV2 = useTeamConfig().isTeamV2 || false

  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)
  let selectedDirectorLocationGroupId =
    _.get(selectedDirector, 'locationGroupId') || -1
  selectedDirectorLocationGroupId =
    selectedDirectorLocationGroupId < 0
      ? brandLocationGroupId
      : selectedDirectorLocationGroupId

  const [
    trendLocationGroupTurnovers,
    {
      loading: trendLocationGroupTurnoversLoading,
      error: trendLocationGroupTurnoversError,
      data: trendLocationGroupTurnoversData,
    },
  ] = useLazyQuery(TREND_LOCATION_GROUP_TURNOVERS)

  const [
    trendLocationGroupTurnoversThirtyDay,
    {
      loading: trendLocationGroupTurnoversThirtyDayLoading,
      error: trendLocationGroupTurnoversThirtyDayError,
      data: trendLocationGroupTurnoversThirtyDayData,
    },
  ] = useLazyQuery(TREND_LOCATION_GROUP_TURNOVERS_THIRTY_DAY)

  useEffect(() => {
    async function fetchData() {
      if (selectedDateRange) {
        // if there is a valid selectedDateRange, then this should be fetched from the cache
        const allDateRangeData = await getDateRanges()

        const samePeriodLastYear =
          allDateRangeData.periodsByYear[selectedDateRange.year - 1]?.[
            12 - selectedDateRange.index
          ]
        const startDate = samePeriodLastYear
          ? samePeriodLastYear.startDateStr
          : selectedDateRange.startDateStr
        const defaultStartDate = brandDefaultCalendarStartDate || '2019-01-01'
        const trendStartDate = moment
          .utc(startDate)
          .isBefore(moment.utc(defaultStartDate))
          ? defaultStartDate
          : startDate

        if (selectedDirctorEmployeeId > 0) {
          // individual DO
          if (type === 'annualized') {
            trendLocationGroupTurnovers({
              variables: {
                iLocationGroupId: selectedDirectorLocationGroupId,
                iStartDate: trendStartDate,
                iEndDate: selectedDateRange.endDateStr,
                iGroupBy: GROUP_BY_PERIOD,
                isTeamV2: isTeamV2,
              },
            })
          } else {
            trendLocationGroupTurnoversThirtyDay({
              variables: {
                iLocationGroupId: selectedDirectorLocationGroupId,
                iStartDate: trendStartDate,
                iEndDate: selectedDateRange.endDateStr,
                iGroupBy: GROUP_BY_PERIOD,
                iDays: type,
              },
            })
          }
        } else {
          if (type === 'annualized') {
            trendLocationGroupTurnovers({
              variables: {
                iLocationGroupId: brandLocationGroupId,
                iStartDate: trendStartDate,
                iEndDate: selectedDateRange.endDateStr,
                iGroupBy: GROUP_BY_PERIOD,
                isTeamV2: isTeamV2,
              },
            })
          } else {
            trendLocationGroupTurnoversThirtyDay({
              variables: {
                iLocationGroupId: brandLocationGroupId,
                iStartDate: trendStartDate,
                iEndDate: selectedDateRange.endDateStr,
                iGroupBy: GROUP_BY_PERIOD,
                iDays: type,
              },
            })
          }
        }
      }
    }

    fetchData()
  }, [locationId, selectedDateRange, selectedDirector, type])

  let rawKpiMonthly = []

  const trendLocationGroupTurnoversVersion = isTeamV2
    ? 'trendLocationGroupTurnoversV2'
    : 'trendLocationGroupTurnovers'
  if (
    type === 'annualized' &&
    trendLocationGroupTurnoversData &&
    trendLocationGroupTurnoversData[trendLocationGroupTurnoversVersion] &&
    Array.isArray(
      trendLocationGroupTurnoversData[trendLocationGroupTurnoversVersion].nodes,
    )
  ) {
    rawKpiMonthly = _.get(
      trendLocationGroupTurnoversData,
      `${trendLocationGroupTurnoversVersion}.nodes`,
      [],
    )
  }

  if (
    type !== 'annualized' &&
    trendLocationGroupTurnoversThirtyDayData &&
    trendLocationGroupTurnoversThirtyDayData.trendLocationGroupTurnoversThirtyDayV2 &&
    Array.isArray(
      trendLocationGroupTurnoversThirtyDayData
        .trendLocationGroupTurnoversThirtyDayV2.nodes,
    )
  ) {
    rawKpiMonthly = _.get(
      trendLocationGroupTurnoversThirtyDayData,
      'trendLocationGroupTurnoversThirtyDayV2.nodes',
      [],
    )
  }

  const xAxisData = _.map(rawKpiMonthly, (k) => {
    const midPeriodDate = moment.utc(k.startDate).add(15, 'days')

    const month = midPeriodDate.month() + 1
    const year = midPeriodDate.year()

    return `P${month} ${year}`
  })

  const yAxisAnnualizedHourlyTurnoverRateData = _.map(rawKpiMonthly, (k) => {
    return (_.get(k, 'annualizedHourlyTurnoverPercent', 0) || 0) / 100.0
  })

  const yAxisAnnualizedSalariedTurnoverRateData = _.map(rawKpiMonthly, (k) => {
    return (_.get(k, 'annualizedSalariedTurnoverPercent', 0) || 0) / 100.0
  })

  const yAxisHourlyTerminatedCountData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'hourlyTurnoverCount', 0)
  })

  const yAxisSalariedTerminatedCountData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'salariedTurnoverCount', 0)
  })

  const yAxisThirtyDayTurnoverCountHourlyData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'terminationCountHourly', 0)
  })

  const yAxisThirtyDayTurnoverPercentHourlyData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'turnoverPercentHourly', 0)
  })

  const yAxisThirtyDayTurnoverCountSalariedData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'terminationCountSalaried', 0)
  })

  const yAxisThirtyDayTurnoverPercentSalariedData = _.map(
    rawKpiMonthly,
    (k) => {
      return _.get(k, 'turnoverPercentSalaried', 0)
    },
  )

  const yAxisThirtyDayTurnoverDates = _.map(rawKpiMonthly, (k) => {
    return {
      startDate: moment(k.startDate, 'YYYY-MM-DD')
        .subtract(type, 'days')
        .format('YYYY-MM-DD'),
      endDate: k.startDate,
    }
  })

  const yAxisDataArr =
    type === 'annualized'
      ? [
          {
            type: 'bar',
            yAxisId: 'yRight',
            data: yAxisHourlyTerminatedCountData,
            tooltipLabel: 'Hourly Turnover Count',
            backgroundColor: COLORS.PomodoroTransparent,
          },
          {
            type: 'line',
            data: yAxisAnnualizedHourlyTurnoverRateData,
            tooltipLabel: 'Annualized Hourly Turnover',
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
          {
            type: 'bar',
            yAxisId: 'yRight',
            data: yAxisSalariedTerminatedCountData,
            tooltipLabel: 'Salaried Turnover Count',
            backgroundColor: COLORS.PlumTransparent,
          },
          {
            type: 'line',
            data: yAxisAnnualizedSalariedTurnoverRateData,
            tooltipLabel: 'Annualized Salaried Turnover',
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
        ]
      : [
          {
            type: 'bar',
            yAxisId: 'yRight',
            data: yAxisThirtyDayTurnoverCountHourlyData,
            tooltipLabel: 'Hourly Turnover Count',
            backgroundColor: COLORS.PomodoroTransparent,
          },
          {
            type: 'line',
            data: yAxisThirtyDayTurnoverPercentHourlyData,
            tooltipLabel: `${type} Day Hourly Turnover`,
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
          {
            type: 'bar',
            yAxisId: 'yRight',
            data: yAxisThirtyDayTurnoverCountSalariedData,
            tooltipLabel: 'Salaried Turnover Count',
            backgroundColor: COLORS.PlumTransparent,
          },

          {
            type: 'line',
            data: yAxisThirtyDayTurnoverPercentSalariedData,
            tooltipLabel: `${type} Day Salaried Turnover`,
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
        ]

  const chartOptions = {
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: 'Turnover Rate',
    yLeftTickCallback: (value: number) => {
      return toPercentageString(value, 0)
    },
    yRightAxisLabel: 'Turnover Count',
    yRightShowGridLines: false,
    yRightTickCallback: (value: number) => {
      return value
    },
    yRightTickStepSize: 1,
    yRightBeginAtZero: true,
    tooltipTitleCallback: (tooltipItemContext: any[]) => {
      if (tooltipItemContext && tooltipItemContext[0]) {
        const context = tooltipItemContext[0]
        if (context.label) {
          return context.label
        }
      }
      return ''
    },
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let datasetLabel = ''
        let separator = ''
        let yValue = ''
        if (tooltipItemContext.dataset && tooltipItemContext.dataset.label) {
          datasetLabel = tooltipItemContext.dataset.label
          separator = ': '
        }
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          if (tooltipItemContext.dataset.type === 'bar') {
            if (type !== 'annualized') {
              const { startDate, endDate } =
                yAxisThirtyDayTurnoverDates[tooltipItemContext.dataIndex]
              datasetLabel += ` (${startDate} to ${endDate})`
            }
            yValue = tooltipItemContext.parsed.y
          } else {
            yValue = toPercentageString(tooltipItemContext.parsed.y, 1)
          }
        }
        return [datasetLabel, separator, yValue].join('')
      }
      return ''
    },
    legendClickHandler: (event: any, legendItem: any, legend: any) => {
      const index = legendItem.datasetIndex
      const legendItems = legend.legendItems
      const legendItemMap = legendItems.reduce(
        (acc: any, cur: any) => {
          if (cur.text.toLowerCase().indexOf('hourly') >= 0) {
            acc.hourly.push(cur.datasetIndex)
          }

          if (cur.text.toLowerCase().indexOf('salaried') >= 0) {
            acc.salaried.push(cur.datasetIndex)
          }

          return acc
        },
        { hourly: [], salaried: [] },
      )

      const ci = legend.chart
      if (ci.isDatasetVisible(index)) {
        if (legendItem.text.toLowerCase().indexOf('hourly') >= 0) {
          legendItemMap.hourly.forEach((index: number) => {
            ci.hide(index)
            legendItems[index].hidden = true
          })
        } else if (legendItem.text.toLowerCase().indexOf('salaried') >= 0) {
          legendItemMap.salaried.forEach((index: number) => {
            ci.hide(index)
            legendItems[index].hidden = true
          })
        }
      } else {
        if (legendItem.text.toLowerCase().indexOf('hourly') >= 0) {
          legendItemMap.hourly.forEach((index: number) => {
            ci.show(index)
            legendItems[index].hidden = false
          })
        } else if (legendItem.text.toLowerCase().indexOf('salaried') >= 0) {
          legendItemMap.salaried.forEach((index: number) => {
            ci.show(index)
            legendItems[index].hidden = false
          })
        }
      }
    },
  }

  return (
    <Main
      isLoading={
        trendLocationGroupTurnoversLoading ||
        trendLocationGroupTurnoversThirtyDayLoading
      }
      xAxisData={xAxisData}
      yAxisDataArr={yAxisDataArr}
      chartOptions={chartOptions}
      type={type}
      setType={setType}
    />
  )
}

export default Chart
