import { v4 as uuidv4 } from 'uuid'

import CorporatePurchasesTable from 'pared/pages/CorporatePurchases/CorporatePurchasesTable'
import { FROM_EXPO } from 'pared/pages/ExpoAi/constants'
import { IMessage } from 'pared/pages/ExpoAi/type'
import PurchasesTable from 'pared/pages/Purchases/PurchasesTable'

import formatDate from '../formatDate'

export default function handleInventoryKpiValues(responseData: any) {
  if (responseData?.inventoryKpiValues) {
    const messageId = uuidv4()
    const results: any = responseData.inventoryKpiValues

    const uiComponents: React.ReactNode[] = []

    if (results?.locationGroupIds?.length > 0) {
      const firstLocationGroupId = results?.locationGroupIds?.[0]
      const firstLocationGroupName = results?.locationGroupNames?.[0]
      const firstItem = results?.items?.[0] || ''

      uiComponents.push(
        <div>
          <div>
            {firstLocationGroupName} ({formatDate(results.startDate)} ~{' '}
            {formatDate(results.endDate)})
          </div>
          <CorporatePurchasesTable
            locationGroupId={firstLocationGroupId}
            startDate={results.startDate}
            endDate={results.endDate}
            presetCategoryOrItemFilter={firstItem}
          />
        </div>,
      )
    } else if (results?.locationIds?.length > 0) {
      if (results?.locationIds?.length === 1) {
        const locationId = results.locationIds[0]
        const locationName = results.locationNames?.[0]

        uiComponents.push(
          <div>
            <div>
              {locationName} ({formatDate(results.startDate)} ~{' '}
              {formatDate(results.endDate)})
            </div>
            <PurchasesTable
              locationId={locationId}
              locationName={locationName}
              startDate={results.startDate}
              endDate={results.endDate}
              presetItemFilter={
                results?.items?.length > 0 ? results.items.join('; ') : ''
              }
              isHeaderVisible={false}
              isSummaryVisible={false}
              isTableVisible={true}
            />
          </div>,
        )
      } else {
        const firstItem = results?.items?.[0] || ''

        uiComponents.push(
          <div>
            <div>
              {formatDate(results.startDate)} ~ {formatDate(results.endDate)}
            </div>
            <CorporatePurchasesTable
              locationIds={results.locationIds}
              startDate={results.startDate}
              endDate={results.endDate}
              presetCategoryOrItemFilter={firstItem}
            />
          </div>,
        )
      }
    }

    if (uiComponents.length > 0) {
      const answerMessage: IMessage = {
        id: messageId,
        from: FROM_EXPO,
        content: uiComponents,
      }

      return answerMessage
    }
  }

  return null
}
