import moment from 'moment'
import React, { useContext, useMemo } from 'react'

import useGetDateRange from 'pared/hooks/useGetDateRange'

interface IDateFilterContextType {
  startDate?: string
  endDate?: string
}

interface IPropsType {
  hasDateConfig: boolean
  children: React.ReactNode
}

const DateFilterContext = React.createContext<IDateFilterContextType>({})

export const useDateFilter = () => useContext(DateFilterContext)

const DateFilterProvider = ({ hasDateConfig, children }: IPropsType) => {
  const currentDate = useGetDateRange({ isCustomAllowed: true })
  const date = useMemo(
    () =>
      hasDateConfig
        ? {
            startDateStr: currentDate?.startDateStr,
            endDateStr: currentDate?.endDateStr,
          }
        : {
            startDateStr: moment().subtract(1, 'day').format('YYYY-MM-DD'),
            endDateStr: moment().subtract(1, 'day').format('YYYY-MM-DD'),
          },
    [hasDateConfig, currentDate],
  )

  return (
    <DateFilterContext.Provider
      value={{ startDate: date?.startDateStr, endDate: date?.endDateStr }}
    >
      {children}
    </DateFilterContext.Provider>
  )
}

export default DateFilterProvider
