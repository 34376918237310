import getElementPosition from 'fbjs/lib/getElementPosition'
import ResizeObserver from 'rc-resize-observer'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface IPropsType {
  children: React.ReactNode
}

const Container = styled.div<{
  containerHeight: number
  tableWidth: number
  headerHeight: number
}>`
  position: relative;
  height: ${({ containerHeight }) => containerHeight}px;

  & > div {
    &:first-child {
      width: ${({ tableWidth }) => tableWidth}px;
      height: ${({ headerHeight }) => headerHeight}px;
      overflow: hidden;
    }

    &:last-child {
      position: relative;
      width: ${({ tableWidth }) => tableWidth}px;
      height: ${({ containerHeight, headerHeight }) =>
        containerHeight - headerHeight}px;
      overflow: scroll;

      table {
        position: absolute;
        top: ${({ headerHeight }) => -1 * headerHeight}px;
      }
    }
  }
`

const TableWrapper = ({ children }: IPropsType) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const [size, setSize] = useState({
    containerHeight: 0,
    tableWidth: 0,
    headerHeight: 0,
  })
  const calculateSize = useCallback(() => {
    const y = containerRef?.current
      ? getElementPosition(containerRef.current).y
      : 0
    const containerHeight = window.innerHeight - y - 50

    setSize({
      containerHeight: containerHeight < 500 ? 500 : containerHeight,
      tableWidth: headerRef.current?.querySelector('table')?.offsetWidth || 0,
      headerHeight:
        headerRef.current?.querySelector('thead')?.offsetHeight || 0,
    })
  }, [])

  useEffect(() => {
    calculateSize()
  }, [calculateSize])

  return (
    <Container {...size} ref={containerRef}>
      <ResizeObserver onResize={calculateSize}>
        <div ref={headerRef}>{children}</div>
      </ResizeObserver>

      <div>{children}</div>
    </Container>
  )
}

export default React.memo(TableWrapper)
