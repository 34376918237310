import { useMemo } from 'react'

export interface IOptionsType {
  current: number
  pageSize: number
  total: number
}

const usePaginationPages = ({
  current,
  pageSize,
  total,
}: IOptionsType): (number | 'left' | 'right')[] =>
  useMemo(() => {
    const min = 0
    const max = Math.ceil(total / pageSize) - 1

    if (max - min < 7)
      return [].constructor
        .apply({}, new Array(max - min + 1))
        .map((_: unknown, index: number) => index + min)

    const pages = []

    if (current - min <= 3)
      pages.push(min + 1, min + 2, min + 3, min + 4, 'right')
    else if (max - current <= 3)
      pages.push('left', max - 4, max - 3, max - 2, max - 1)
    else pages.push('left', current - 1, current, current + 1, 'right')

    return [min, ...pages, max]
  }, [current, pageSize, total])

export default usePaginationPages
