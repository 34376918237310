import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { FROM_EXPO } from 'pared/pages/ExpoAi/constants'
import { IMessage } from 'pared/pages/ExpoAi/type'
import PurchasesTable from 'pared/pages/Purchases/PurchasesTable'

import formatDate from '../formatDate'

export default function handleInventoryLocationSuggestions(responseData: any) {
  if (responseData?.inventoryLocationSuggestions) {
    const messageId = uuidv4()
    const results: any = responseData.inventoryLocationSuggestions

    const answerMessage: IMessage = {
      id: messageId,
      from: FROM_EXPO,
      content: (
        <div>
          <LocationAndDateRange>
            {results?.locationName} ({formatDate(results?.startDate)} ~{' '}
            {formatDate(results?.endDate)})
          </LocationAndDateRange>
          <PurchasesTable
            locationId={results?.locationId}
            locationName={results?.locationName}
            startDate={results?.startDate}
            endDate={results?.endDate}
            isHeaderVisible={false}
            isSummaryVisible={true}
            isTableVisible={false}
          />
        </div>
      ),
    }

    return answerMessage
  }

  return null
}

const LocationAndDateRange = styled.div`
  padding: 0 0 20px 0;
`
