import moment from 'moment'
import { useMemo } from 'react'

import { IColumnsType } from 'pared/components/basicUi/table'

import { IDataType } from './useDataSource'

export default (columns: string[]): IColumnsType<IDataType>[] =>
  useMemo(
    () => [
      {
        key: 'item',
        title: 'Item',
        sorter: true,
      },
      ...((columns.map((column) => ({
        key: column,
        title: column,
        width: '110px',
        align: 'center',
        sorter: true,
        extraRow: () => moment(column).format('dddd'),
        render: (value) => `${value.value}`,
        cellStyle: (value) =>
          value.value === 0
            ? `
                background: hsl(0, 80%, 80%);
              `
            : '',
      })) as IColumnsType<IDataType>[]) || []),
    ],

    [columns],
  )
