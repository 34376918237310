import _ from 'lodash'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import FullWidthTable from 'pared/components/FullWidthTable'
import { PERFORMANCE_DISPLAY_TEXTS } from 'pared/constants'
import COLORS from 'pared/constants/colors'
import { getBusinessLabel } from 'pared/customer'

import { IManagerReview } from '../index'

interface IProps {
  isTableExpanded: boolean
  onToggleTable: () => void
  managerReviews: IManagerReview[]
  isListManagerReviewloading: boolean
}

export interface IOverviewTableTh {
  largerFont?: boolean
  textAlign?: string
  bold?: boolean
}

export interface IOpenPositionsTd {
  bold?: boolean
  danger?: boolean
  success?: boolean
  textAlign?: string
}

function WebSmallMain({
  isTableExpanded,
  onToggleTable,
  managerReviews,
  isListManagerReviewloading,
}: IProps) {
  const toggleText = isTableExpanded ? 'Collapse all' : 'Show All'

  const managerReviewRows = _.map(managerReviews, (r) => {
    const reviewId = _.get(r, 'reviewId', 0)
    const performancce = _.get(r, 'performance', '')

    return (
      <tr key={reviewId}>
        <OverviewTableTd>
          <LightBlueLink to={navigator.storeDetail(_.get(r, 'storeId', 1))}>
            {_.get(r, 'storeName', '')}
          </LightBlueLink>
        </OverviewTableTd>
        <OverviewTableTd>{_.get(r, 'doName', '')}</OverviewTableTd>
        <OverviewTableTd>{_.get(r, 'roleName', '')}</OverviewTableTd>
        <OverviewTableTd>{_.get(r, 'revieweeName', '')}</OverviewTableTd>
        <OverviewTableTd>
          {PERFORMANCE_DISPLAY_TEXTS[performancce]}
        </OverviewTableTd>
        <OverviewTableTd>{_.get(r, 'reviewedAt', '')}</OverviewTableTd>
      </tr>
    )
  })

  const expandText =
    _.size(managerReviewRows) > 3 ? (
      <ExpandAllSpan onClick={onToggleTable}>{toggleText}</ExpandAllSpan>
    ) : null

  let toggledRows = managerReviewRows

  if (!isTableExpanded) {
    toggledRows = _.slice(managerReviewRows, 0, 3)
  }

  if (isListManagerReviewloading) {
    return (
      <LoadingContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </LoadingContainer>
    )
  }

  const content = _.isEmpty(managerReviews) ? (
    <NoData>No Candidates. Please try changing the filter</NoData>
  ) : (
    <FullWidthTable>
      <thead>
        <tr>
          <OverviewTableTh bold>Store</OverviewTableTh>
          <OverviewTableTh bold>{getBusinessLabel('director')}</OverviewTableTh>
          <OverviewTableTh bold>Role</OverviewTableTh>
          <OverviewTableTh bold>Name</OverviewTableTh>
          <OverviewTableTh bold>Performance Review</OverviewTableTh>
          <OverviewTableTh bold>Most Recent Feedback Date</OverviewTableTh>
        </tr>
      </thead>
      <tbody>{toggledRows}</tbody>
    </FullWidthTable>
  )

  return (
    <MainContainer>
      {content}
      {expandText}
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 1000px;
  padding-top: 10px;
  padding-bottom: 50px;
`

const LightBlueLink = styled(Link)`
  color: ${COLORS.Link};
`

const LoadingContainer = styled.div`
  width: 1200px;
  padding: 0 0 50px 0;
`

const PageStatusDiv = styled.div`
  padding: 30px 0 0 0;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const OverviewTableTh = styled.th<IOverviewTableTh>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: ${(props) => (props.largerFont ? '20px' : '14px')};
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'right' ? 'right' : 'left')};
`

const OverviewTableTd = styled.td<IOpenPositionsTd>`
  font-family: Lexend-Regular;
  font-size: ${(props) => (props.bold ? '18px' : '14px')};
  font-style: normal;
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  text-align: ${(props) => (props.textAlign === 'right' ? 'right' : 'left')};
  color: ${(props) =>
    props.danger ? COLORS.Pomodoro : props.success ? COLORS.Expo : 'black'};
`

const NoData = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const ExpandAllSpan = styled.div`
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: ${COLORS.Link};
  text-decoration: underline;
  cursor: pointer;
  float: left;
`

export default WebSmallMain
