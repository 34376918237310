import { gql } from '@apollo/client'

export const TREND_LOCATION_GROUP_CUSTOMIZED_REPORT = gql`
  query TrendLocationGroupCustomizedReport(
    $iReportName: String!
    $iLocationGroupId: Int!
    $iEndDate: Date!
    $iGroupBy: String!
  ) {
    trendLocationGroupCustomizedReport: trendLocationGroupCustomizedReportV2(
      iReportName: $iReportName
      iLocationGroupId: $iLocationGroupId
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) {
      nodes {
        startDate
        endDate
        reportResult
        businessWeek
        businessWeekOfMonth
        businessMonth
        businessQuarter
        businessYear
      }
    }
  }
`
