import _ from 'lodash'
import moment from 'moment'

const formatPriceWithCent = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencySign: 'accounting',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const formatPriceRoundToDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencySign: 'accounting',
  maximumFractionDigits: 0,
})

export function getNumber(value: number | string) {
  return typeof value === 'string' ? parseFloat(value) : value
}

export function toPercentString(
  number: number | string,
  scale: number = 2,
  hasPlusSign: boolean = false,
) {
  let num: any = number
  if (typeof number === 'string') {
    num = parseFloat(number)
  }

  if (isNaN(num) || num === null) {
    return '-'
  }

  let sign = ''
  if (num < 0) {
    sign = '-'
  } else if (num > 0 && hasPlusSign) {
    sign = '+'
  }

  const absNum = Math.abs(num)

  if (sign === '-') {
    return `(${absNum.toFixed(scale)}%)`
  } else {
    return `${sign}${absNum.toFixed(scale)}%`
  }
}

export function toPercentageString(
  number: number | string,
  scale: number = 2,
  hasPlusSign: boolean = false,
) {
  let num: any = number
  if (typeof number === 'string') {
    num = parseFloat(number)
  }

  if (isNaN(num)) {
    return '-'
  }

  num = num * 100.0
  let sign = ''
  if (num < 0) {
    sign = '-'
  } else if (num > 0 && hasPlusSign) {
    sign = '+'
  }

  const absNum = Math.abs(num)

  if (sign === '-') {
    return `(${absNum.toFixed(scale)}%)`
  } else {
    return `${sign}${absNum.toFixed(scale)}%`
  }
}

export function toUsdStr(dollarAmount: number | string) {
  const dollarNum = getNumber(dollarAmount)
  if (isNaN(dollarNum)) {
    return '-'
  }
  if (Math.abs(dollarNum) < 100.0) {
    return formatPriceWithCent.format(dollarNum)
  } else {
    return formatPriceRoundToDollar.format(dollarNum)
  }
}

// deprecated
export function toUsdString(
  number: number | string,
  scale: number = 0,
  hasPlusSign: boolean = false,
  hasDollarSign: boolean = true,
) {
  let num: any = number
  if (typeof number === 'string') {
    num = parseFloat(number)
  }

  if (isNaN(num)) {
    return '-'
  }

  let sign = ''
  if (num < 0) {
    sign = '-'
  } else if (num > 0 && hasPlusSign) {
    sign = '+'
  }

  num = Math.abs(num)
  let decimalPortion = ''
  if (scale > 0) {
    num = num.toFixed(scale)
    decimalPortion = num.substring(num.length - scale)
    decimalPortion = `.${decimalPortion}`
    num = num.substring(0, num.length - scale - 1)
  } else {
    num = num.toFixed(0)
  }

  let usdString = decimalPortion
  let numPortion = num.substring(num.length - 3)
  num = num.substring(0, num.length - 3)
  while (num && numPortion) {
    usdString = `,${numPortion}${usdString}`
    numPortion = num.substring(num.length - 3)
    num = num.substring(0, num.length - 3)
  }

  if (sign === '-') {
    usdString = `(${hasDollarSign ? '$' : ''}${numPortion}${usdString})`
  } else {
    usdString = `${sign}${hasDollarSign ? '$' : ''}${numPortion}${usdString}`
  }

  return usdString
}

export function toFormattedInteger(integerString: string | number) {
  if (integerString === '-') return '-'

  let integerNum = integerString
  if (typeof integerString === 'string') {
    integerNum = parseInt(integerString, 10)
  }
  return (integerNum || 0).toLocaleString('en-US')
}

export function usdStringToNumber(usdString: string) {
  const strWithoutDollarSign = usdString.replace('$', '')
  const strWithoutComma = strWithoutDollarSign.replace(/,/gi, '')
  return parseFloat(strWithoutComma)
}

const ONE_MINUTE_IN_SECONDS = 60
const ONE_SECOND_IN_MILLISECONDS = 1000

export function toDisplayedWaitTime(waitTime: number) {
  const avgDasherWaitTimeDuration: any = moment.duration(waitTime, 'seconds')

  let displayedAvgDashWaitTime = ''
  if (waitTime < ONE_MINUTE_IN_SECONDS) {
    displayedAvgDashWaitTime = 'less than a minute'
  } else {
    displayedAvgDashWaitTime =
      Math.floor(
        avgDasherWaitTimeDuration /
          ONE_MINUTE_IN_SECONDS /
          ONE_SECOND_IN_MILLISECONDS,
      ) + ' min'
  }

  return displayedAvgDashWaitTime
}

export function toHourStr(hours: number) {
  const hourNum = getNumber(hours)
  if (isNaN(hourNum) || _.isNil(hourNum)) {
    return '-'
  }
  return hourNum.toString()
}
