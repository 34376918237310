import moment from 'moment'
import { useMemo } from 'react'

import { IColumnsType } from 'pared/components/basicUi/table'

import { getPrice } from '../../util'
import { ISchedulingTableDataType } from './useSchedulingAnalysisData'

export type IValueType = 'count' | 'price'

export default (
  valueType: IValueType,
  schedulingAnalysisData: ISchedulingTableDataType[],
): IColumnsType<ISchedulingTableDataType>[] => {
  const render = (() => {
    switch (valueType) {
      case 'count':
        return (value: number) => (value <= 0 ? '0' : value.toFixed(2))

      case 'price':
        return (value: number) => getPrice(value <= 0 ? 0 : value)
    }
  })()

  return useMemo(
    () => [
      {
        key: 'hour',
        title: 'Time',
        width: '150px',
        render: (value: number) => `${value.toString().padStart(2, '0')}:00`,
        sorter: true,
        csvRender: (value) => {
          return `${value.toString().padStart(2, '0')}:00`
        },
      },
      ...(schedulingAnalysisData?.[0]
        ? (Object.keys(schedulingAnalysisData[0])
            ?.filter((key) => key !== 'hour')
            ?.map((c) => ({
              key: c,
              title: c,
              align: 'center',
              width: '100px',
              render,
              cellStyle: (value) => `
                background: ${value?.color};
              `,
              sorter: true,
              extraRow: () => moment(c).format('dddd'),
              summary: (value) =>
                render(
                  value?.reduce((result, row) => {
                    const d = row[c] as { color: string; value: number }
                    return (result += d.value > 0 ? d.value : 0)
                  }, 0),
                ),
              csvRender: (value) => value.value,
            })) as IColumnsType<ISchedulingTableDataType>[])
        : []),
    ],
    [valueType, schedulingAnalysisData],
  )
}
