import { IConfigsType } from '../../types'

const lfrConfig: IConfigsType = {
  'lfr-user-events-2c1a822f': {
    '/:brand/user-events': {
      userEvents: {
        type: 'table',
        title: 'User Events',
        api: 'userEvents',
        rowKey: 'email',
        pageSize: 20,
        defaultSortedField: 'lastEventAt',
        columns: [
          {
            key: 'email',
            title: 'E-mail',
            sortable: true,
          },
          {
            key: 'lastEventAt',
            title: 'Last Event At',
            align: 'center',
            sortable: true,
            format: 'YYYY/MM/DD h:mm:ss A',
          },
        ],
      },
    },
  },
}

export default lfrConfig
