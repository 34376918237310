import { IConfigsType } from '../../types'

const fwWingstopFlashFoodCost: IConfigsType = {
  fw_wingstop: {
    '/:brand/food_cost': {
      variables: ['date'],
      groupFilter: {
        api: 'fwWingstopCorporateFilter',
      },
      dateFilter: {
        types: ['week', 'this_week'],
        defaultType: 'this_week',
      },
      reportTitle: {
        type: 'title',
        title: 'Food Cost by Store',
      },
      downloadReport: {
        type: 'download-csv',
        api: 'farwestFourWeekTrendData',
        fileName: 'getexpo-far-west-food-cost-by-store',
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'market',
            title: 'Market',
          },

          {
            key: 'actualFoodCostPercentageWeek4InPast',
            title: 'Actual Food Cost % (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'actualFoodCostPercentageWeek3InPast',
            title: 'Actual Food Cost % (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'actualFoodCostPercentageWeek2InPast',
            title: 'Actual Food Cost % (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'actualFoodCostPercentageWeek1InPast',
            title: 'Actual Food Cost % (<%- date?.getInfo(0)?.displayName %>)',
          },

          {
            key: 'theoryFoodCostPercentageWeek4InPast',
            title: 'Theo Food Cost % (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'theoryFoodCostPercentageWeek3InPast',
            title: 'Theo Food Cost % (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'theoryFoodCostPercentageWeek2InPast',
            title: 'Theo Food Cost % (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'theoryFoodCostPercentageWeek1InPast',
            title: 'Theo Food Cost % (<%- date?.getInfo(0)?.displayName %>)',
          },

          {
            key: 'varFoodCostPercentageWeek4InPast',
            title: 'Var% (<%- date?.getInfo(-3)?.displayName %>)',
          },
          {
            key: 'varFoodCostPercentageWeek3InPast',
            title: 'Var% (<%- date?.getInfo(-2)?.displayName %>)',
          },
          {
            key: 'varFoodCostPercentageWeek2InPast',
            title: 'Var% (<%- date?.getInfo(-1)?.displayName %>)',
          },
          {
            key: 'varFoodCostPercentageWeek1InPast',
            title: 'Var% (<%- date?.getInfo(0)?.displayName %>)',
          },
        ],
      },
      flashTable: {
        type: 'table',
        api: 'farwestFourWeekTrendData',
        pageSize: 30,
        heatmap: {
          direction: 'asc',
        },
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        summaryToTop: true,
        columns: [
          {
            key: 'market',
            title: 'Market',
            sortable: true,
          },
          {
            key: 'actualFoodCostPercentage',
            title: 'Actual Food Cost %',
            align: 'center',
            highlight: true,
            hasRightGutter: true,
            children: [
              {
                key: 'actualFoodCostPercentageWeek4InPast',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'actualFoodCostPercentageWeek3InPast',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'actualFoodCostPercentageWeek2InPast',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'actualFoodCostPercentageWeek1InPast',
                title: '<%- date?.getInfo(0)?.displayName %>',
                align: 'center',
                sortable: true,
                hasRightGutter: true,
              },
            ],
          },
          {
            key: 'theoryFoodCostPercentage',
            title: 'Theo Food Cost %',
            align: 'center',
            children: [
              {
                key: 'theoryFoodCostPercentageWeek4InPast',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'theoryFoodCostPercentageWeek3InPast',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'theoryFoodCostPercentageWeek2InPast',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: true,
              },
              {
                key: 'theoryFoodCostPercentageWeek1InPast',
                title: '<%- date?.getInfo(0)?.displayName %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'varFoodCostPercentage',
            title: 'Var%',
            align: 'center',
            highlight: true,
            hasLeftGutter: true,
            children: [
              {
                key: 'varFoodCostPercentageWeek4InPast',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: true,
                disableDangerColor: true,
                hasLeftGutter: true,
              },
              {
                key: 'varFoodCostPercentageWeek3InPast',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: true,
                disableDangerColor: true,
              },
              {
                key: 'varFoodCostPercentageWeek2InPast',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: true,
                disableDangerColor: true,
              },
              {
                key: 'varFoodCostPercentageWeek1InPast',
                title: '<%- date?.getInfo(0)?.displayName %>',
                align: 'center',
                sortable: true,
                disableDangerColor: true,
              },
            ],
          },
        ],
      },
    },
  },
}

export default fwWingstopFlashFoodCost
