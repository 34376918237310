import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

interface IProps {
  children: React.ReactNode
  gap?: string
}

function FullWidthTable(props: IProps) {
  return (
    <FullWidthTableWrapper gap={props.gap}>
      {props.children}
    </FullWidthTableWrapper>
  )
}

interface ITableWrapper {
  gap?: string
}

const FullWidthTableWrapper = styled.table<ITableWrapper>`
  width: 100%;
  border-collapse: collapse;

  font-family: Lexend-Regular;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};
  text-align: left;

  td,
  th {
    vertical-align: top;
    padding: ${(props) => props.gap ?? '10px 0'};
  }

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  thead::after {
    content: '';
    display: block;
    height: 10px;
    width: 100%;
    background: transparent;
  }
`

export default FullWidthTable
