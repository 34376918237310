import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import COLORS from 'pared/constants/colors'

interface IProps {
  summaryMessage: string | React.ReactElement
  areasOfImprovementMessages: (string | React.ReactElement)[]
}

const Summary = ({ summaryMessage, areasOfImprovementMessages }: IProps) => {
  if (areasOfImprovementMessages && areasOfImprovementMessages.length > 0) {
    return (
      <SummaryContainer>
        <ConclusionDiv>{summaryMessage}</ConclusionDiv>
        <InsightDiv>
          <InsightTitle>EXPO INSIGHTS</InsightTitle>
          {areasOfImprovementMessages.map((message) => (
            <Text key={uuidv4()}>{message}</Text>
          ))}
        </InsightDiv>
      </SummaryContainer>
    )
  } else {
    return (
      <SummaryContainer>
        <ConclusionOnlyContainer>{summaryMessage}</ConclusionOnlyContainer>
      </SummaryContainer>
    )
  }
}

export default Summary

const Text = styled.div`
  font-family: Lexend-Regular;
  font-size: 15px;
  flex: 5;
`

const SummaryContainer = styled.div`
  width: 100%;
  min-width: 800px;
  max-width: 1000px;

  @media ${MOBILE_WIDTH} {
    display: flex;
    min-width: auto;
    flex-direction: column;
  }
`

const ConclusionDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: ${COLORS.Salt};
  padding: 30px;

  border-radius: 10px 10px 0 0;
  background: #0071e3;

  @media ${MOBILE_WIDTH} {
    flex: 1;
  }
`

const ConclusionOnlyContainer = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: ${COLORS.Salt};
  padding: 30px;

  border-radius: 10px;
  border: 1px solid #0071e3;
  background: #0071e3;

  @media ${MOBILE_WIDTH} {
    display: flex;
  }
`

const InsightDiv = styled.div`
  padding: 30px;
  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};

  border-radius: 0 0 10px 10px;
  border: 1px solid #81abfd;

  @media ${MOBILE_WIDTH} {
    flex: 1;
  }
`

const InsightTitle = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 0 0 15px 0;
`
