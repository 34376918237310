import { makeStyles } from '@material-ui/core/styles'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { useMemo } from 'react'
import styled from 'styled-components'

import { IColumnsType } from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import { toPercentString, toUsdString } from 'pared/utils/number'

import useConfig from './useConfig'
import { IDataSourceType } from './useDataSource'

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`

const BoldText = styled.div`
  font-family: Lexend-SemiBold;
`

const useStyles = makeStyles({
  rotateIcon: {
    transform: 'rotate(90deg)',
  },
})

const useColumns = (
  isBreakdownByDirectors: boolean,
  groupByType: string,
  onClick: (name: string) => void,
  clickedHeaders: Set<string>,
): IColumnsType<IDataSourceType>[] => {
  const classes = useStyles()
  const brandSettings = getBrandSettings()
  const businessLabels = brandSettings.labels.business
  const creditCardTypeconfig = useConfig()

  return useMemo(
    () =>
      [
        ...((isBreakdownByDirectors
          ? []
          : [
              {
                key: 'storeCode',
                title: 'Store',
                minWidth: '150px',
                render: (_, values: IDataSourceType) => {
                  if (values.rowType != 'header') return null

                  const groupInfo = values.groupInfo
                  if (!groupInfo) return null

                  const storeCode =
                    'locationCode' in groupInfo
                      ? groupInfo.locationCode
                      : 'Unkown'
                  const storeName =
                    'locationName' in groupInfo
                      ? groupInfo.locationName
                      : 'Unkown'
                  const name = `${storeCode} - ${storeName}`

                  return (
                    <FlexDiv>
                      <PlayArrowIcon
                        className={
                          clickedHeaders?.has(name) ? classes.rotateIcon : ''
                        }
                        key={name}
                        onClick={() => onClick(name)}
                      />
                      <BoldText>{name}</BoldText>
                    </FlexDiv>
                  )
                },
              },
            ]) as IColumnsType<IDataSourceType>[]),
        ...(groupByType !== 'store'
          ? ([
              {
                key: 'groupName',
                title: groupByType,
                minWidth: '150px',
                render: (_, values: IDataSourceType) => {
                  if (values.rowType != 'header') return null

                  const groupInfo = values.groupInfo
                  if (!groupInfo) return <BoldText>Summary</BoldText>

                  return 'locationGroupName' in groupInfo ? (
                    <FlexDiv>
                      <PlayArrowIcon
                        className={
                          clickedHeaders?.has(groupInfo.locationGroupName)
                            ? classes.rotateIcon
                            : ''
                        }
                        key={groupInfo.locationGroupName}
                        onClick={() => onClick(groupInfo.locationGroupName)}
                      />
                      <BoldText>{groupInfo.locationGroupName}</BoldText>
                    </FlexDiv>
                  ) : null
                },
              },
            ] as IColumnsType<IDataSourceType>[])
          : ([
              {
                key: 'directorName',
                title: businessLabels['director'],
                minWidth: '150px',
                render: (_, values: IDataSourceType) => {
                  if (values.rowType != 'header') return null

                  const groupInfo = values.groupInfo
                  if (!groupInfo) return <BoldText>Summary</BoldText>

                  const directorName =
                    'directorName' in groupInfo
                      ? groupInfo.directorName
                      : 'Unknown'

                  return <BoldText>{directorName}</BoldText>
                },
              },
            ] as IColumnsType<IDataSourceType>[])),
        {
          key: 'creditCardType',
          title: 'Credit Card Type',
          minWidth: '150px',
          align: 'center',
          render: (
            value: IDataSourceType['creditCardType'],
            values: IDataSourceType,
          ) => {
            const content =
              creditCardTypeconfig.find((config) => config.key === value)
                ?.title || value
            return values.rowType == 'header' ? (
              <BoldText>{content}</BoldText>
            ) : (
              content
            )
          },
        },
        {
          key: 'salesAmount',
          title: 'R365',
          minWidth: '150px',
          align: 'center',
          render: (
            value: IDataSourceType['salesAmount'],
            values: IDataSourceType,
          ) => {
            if (!value) return '-'

            const amount = toUsdString(value / 100.0, 2)

            return values.rowType == 'header' ? (
              <BoldText>{amount}</BoldText>
            ) : (
              amount
            )
          },
        },
        {
          key: 'paymentAmount',
          title: 'Fiserv',
          minWidth: '150px',
          align: 'center',
          render: (
            value: IDataSourceType['paymentAmount'],
            values: IDataSourceType,
          ) => {
            if (!value) return '-'

            const amount = toUsdString(value / 100.0, 2)

            return values.rowType == 'header' ? (
              <BoldText>{amount}</BoldText>
            ) : (
              amount
            )
          },
        },
        {
          key: 'overUnderAmount',
          title: 'Over/Under $',
          minWidth: '150px',
          align: 'center',
          cellStyle: (value: IDataSourceType['overUnderAmount']) => {
            if (!value) return null

            const diff = Math.abs(value)
            let color = ''
            if (diff == 0) color = 'lightgreen'
            if (diff > 0 && diff < 10000) color = COLORS.PxBgGreen
            if (diff >= 10000 && diff < 15000) color = COLORS.Flan
            if (diff >= 15000) color = COLORS.Spritz

            return `background-color: ${color}`
          },
          render: (
            value: IDataSourceType['overUnderAmount'],
            values: IDataSourceType,
          ) => {
            const amount = toUsdString(value / 100.0, 2)

            return values.rowType == 'header' ? (
              <BoldText>{amount}</BoldText>
            ) : (
              amount
            )
          },
        },
        {
          key: 'overUnderPercent',
          title: 'Over/Under %',
          minWidth: '150px',
          align: 'center',
          cellStyle: (_, values: IDataSourceType) => {
            const overUnderAmount = values?.overUnderAmount
            if (!overUnderAmount) return null

            const diff = Math.abs(overUnderAmount)
            let color = ''
            if (diff == 0) color = 'lightgreen'
            if (diff > 0 && diff < 10000) color = COLORS.PxBgGreen
            if (diff >= 10000 && diff < 15000) color = COLORS.Flan
            if (diff >= 15000) color = COLORS.Spritz

            return `background-color: ${color}`
          },
          render: (
            value: IDataSourceType['overUnderPercent'],
            values: IDataSourceType,
          ) => {
            const amount = toPercentString(value, 3)

            return values.rowType == 'header' ? (
              <BoldText>{amount}</BoldText>
            ) : (
              amount
            )
          },
        },
      ] as IColumnsType<IDataSourceType>[],
    [isBreakdownByDirectors, groupByType, brandSettings, clickedHeaders],
  )
}

export default useColumns
