import { FormControl, MenuItem, Select } from '@material-ui/core'
import _ from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import DownArrow from 'pared/images/basic/arrow/caret-down.svg'
import UpArrow from 'pared/images/basic/arrow/caret-up.svg'
import { getBrand } from 'pared/utils/brand'

import useGuestConfig from '../hooks/useGuestConfig'
import { IGuestReview } from '../types'
import EmployeeNames from './EmployeeNames'
import Stars from './Stars'
import useModSelect, { ALL_TEXT } from './hooks/useModSelect'
import { IOrderBy } from './index'

interface IProps {
  isReviewsExpanded: boolean
  onToggleExpandReviews: () => void
  guestReviews: IGuestReview[]
  orderBy: IOrderBy
  setOrderBy: any
  isLoading: boolean
}

interface IRankTableTh {
  textAlign?: string
}

interface IRankTableTh {
  textAlign?: string
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
}

function WebLargeMain({
  isReviewsExpanded,
  onToggleExpandReviews,
  guestReviews,
  orderBy,
  setOrderBy,
  isLoading,
}: IProps) {
  const config = useGuestConfig(getBrand())
  const handleOrdering = (event: any) => {
    const orderingColumnValue = _.get(event, 'currentTarget.dataset.value')
    setOrderBy({
      columnName: orderingColumnValue,
      isAscending: !orderBy.isAscending,
    })
  }

  let orderStatus = null
  if (orderBy.isAscending) {
    orderStatus = <UpArrowImg src={UpArrow} alt="ASC"></UpArrowImg>
  } else {
    orderStatus = <DownArrowImg src={DownArrow} alt="DESC"></DownArrowImg>
  }

  const { nameList, nameSelected, setSelectedName } = useModSelect(guestReviews)

  const { toggledGuestReviewRows, expandSpan } = useMemo(() => {
    if (!nameList.includes(nameSelected)) setSelectedName(ALL_TEXT)

    const filteredGuestReviews =
      nameSelected === ALL_TEXT
        ? guestReviews
        : guestReviews.filter((g) =>
            g.employeesOnDuty?.find((e) => e.employeeName === nameSelected),
          )

    const filteredGuestReviewRow = _.map(filteredGuestReviews, (r, index) => {
      const reviewSource = _.get(r, 'reviewSource', '')
      const employeesOnDuty = r.employeesOnDuty?.map((e) => e.employeeName) || [
        '',
      ]

      return (
        <tr key={index}>
          <RankTableTd>
            {reviewSource === 'osat'
              ? config.osatDisplayString ?? 'OSAT'
              : _.startCase(reviewSource)}
          </RankTableTd>
          <RankTableMediumTd textAlign={'left'}>
            {_.get(r, 'reviewContent', '')}
          </RankTableMediumTd>
          <RankTableTd>
            <StarsContainer>
              <Stars numberOfStars={_.get(r, 'ratingScore', 1)} />
            </StarsContainer>
          </RankTableTd>
          <RankTableTd>{_.get(r, 'formattedDate', '')}</RankTableTd>
          {config.smgMod?.isVisible ? (
            <RankTableTd>
              <EmployeeNames names={employeesOnDuty} target={nameSelected} />
            </RankTableTd>
          ) : null}
        </tr>
      )
    })

    const toggledGuestReviewRows = !isReviewsExpanded
      ? _.slice(filteredGuestReviewRow, 0, 4)
      : filteredGuestReviewRow

    const toggleText = isReviewsExpanded
      ? 'View less reviews'
      : 'View more reviews'

    const expandSpan =
      _.size(filteredGuestReviewRow) >= 5 ? (
        <ExpandAllSpan onClick={onToggleExpandReviews}>
          {toggleText}
        </ExpandAllSpan>
      ) : null

    return {
      toggledGuestReviewRows,
      expandSpan,
    }
  }, [guestReviews, nameList, nameSelected, isReviewsExpanded])

  if (isLoading) {
    return <PageStatusDiv>Loading...</PageStatusDiv>
  }

  const extendColumnNum = config.smgMod?.isVisible ? 1 : 0

  return (
    <MainContainer extendColumnNum={extendColumnNum}>
      {!config.smgMod?.isVisible ? null : (
        <FilterDiv>
          <h4>Manager:</h4>
          <FilterFormControl variant="outlined" margin="dense">
            <MenuItemSelect
              value={nameSelected}
              onChange={(e) => {
                setSelectedName(e.target.value as string)
              }}
            >
              {nameList?.map((item: string) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                )
              })}
            </MenuItemSelect>
          </FilterFormControl>
        </FilterDiv>
      )}
      <RankTable>
        <thead>
          <tr>
            <RankTableTh>Source</RankTableTh>
            <RankTableTh textAlign={'left'}>Review</RankTableTh>
            <RankTableSortableTh
              onClick={handleOrdering}
              data-value={'ratingScore'}
            >
              <span>Rating</span>
              {orderBy.columnName === 'ratingScore' ? orderStatus : null}
            </RankTableSortableTh>
            <RankTableSortableTh
              onClick={handleOrdering}
              data-value={'originalDate'}
            >
              <span>Date</span>
              {orderBy.columnName === 'originalDate' ? orderStatus : null}
            </RankTableSortableTh>
            {config.smgMod?.isVisible ? (
              <RankTableTh>Manager On Duty</RankTableTh>
            ) : null}
          </tr>
        </thead>
        <tbody>{toggledGuestReviewRows}</tbody>
      </RankTable>
      {expandSpan}
    </MainContainer>
  )
}

const MainContainer = styled.div<{ extendColumnNum: number }>`
  width: ${({ extendColumnNum }) => `${900 + extendColumnNum * 100}px`};
  padding-right: 30px;
`

const UpArrowImg = styled.img`
  width: 10px;
  margin: 0 0 2px 5px;
`

const DownArrowImg = styled.img`
  width: 10px;
  margin: 0 0 -1px 5px;
`

const RankTableSortableTh = styled.th<IRankTableTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  color: ${COLORS.Plum};
  cursor: pointer;
`

const StarsContainer = styled.div`
  display: flex;
  justify-content: center;
`

const FilterFormControl = styled(FormControl)`
  width: 220px;
`

const MenuItemSelect = styled(Select)`
  width: 200px;
  border-radius: 0;
  background-color: white;
` as typeof Select

const FilterDiv = styled.div`
  display: flex;
  gap: 10px;

  & > * {
    &:not(:last-child) {
      margin-right: 0px;
    }
  }
`

const RankTableTh = styled.th<IRankTableTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankTableTd = styled.td<IRankTableTd>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankTableMediumTd = styled(RankTableTd)`
  width: 400px;
`

const ExpandAllSpan = styled.div`
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;
`

const RankTable = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

export default WebLargeMain
