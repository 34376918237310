import { IConfigsType } from '../../types'

const drgConfig: IConfigsType = {
  drg: {
    '/:brand/systemwide_overview': {
      groupFilter: {
        api: 'drgGroupFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
        ],
      },
      systemwideOverview: {
        type: 'list',
        api: 'systemwideOverviewKpis',
        title: 'Systemwide Overview',
        width: '120px',
        fields: [
          {
            key: 'netSales',
            title: 'Total Sales',
          },
          {
            key: 'netSalesSssPercent',
            title: 'SSS %',
            decimal: 1,
          },
          {
            key: 'netSalesBudgetVariance',
            title: 'Net Sales Budget Variance',
            decimal: 1,
          },
          {
            key: 'checkCount',
            title: 'Check Count',
          },
          {
            key: 'checkAverage',
            title: 'Check Average',
            decimal: 2,
          },
          {
            key: 'checkCountLy',
            title: 'YoY Check Count',
          },
          {
            key: 'checkCountVsLyPercent',
            title: 'Check Count % vs LY',
            decimal: 1,
          },
          {
            key: 'checkAverageLy',
            title: 'YoY Check Average',
            decimal: 2,
          },
          {
            key: 'checkAverageLyPercent',
            title: 'Check Average % vs LY',
            decimal: 1,
          },
          {
            key: 'hourlyLaborCostPercent',
            title: 'Hourly Labor Percent',
            decimal: 1,
          },
          {
            key: 'hourlyLaborBudgetVariance',
            title: 'Hourly Labor Budget Variance',
            decimal: 1,
            reverse: true,
          },
          {
            key: 'avtPercent',
            title: 'AvT',
            decimal: 1,
          },
          {
            key: 'cogsPercent',
            title: 'COGS',
            decimal: 1,
          },
          {
            key: 'cogsBudgetVariance',
            title: 'COGS Budget Variance',
            decimal: 1,
            reverse: true,
          },
          {
            key: 'rcpPercent',
            title: 'RCP',
            decimal: 1,
          },
        ],
      },
      breakdownByStore: {
        type: 'table',
        api: 'systemwideOverview',
        title: 'Breakdown by Store',
        pageSize: 20,
        defaultSortedField: 'netSales',
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '200px',
          },
        },
        columns: [
          {
            key: 'Sales',
            title: 'Net Sales',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'netSales',
                title: '$',
                align: 'center',
                sortable: true,
              },
              {
                key: 'netSalesBudgetVariance',
                title: 'Variance',
                align: 'center',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'netSalesSssPercent',
                title: 'SSS %',
                align: 'center',
                decimal: 1,
                sortable: true,
              },
            ],
          },
          {
            key: 'Checks',
            title: 'Checks',
            align: 'center',
            children: [
              {
                key: 'inUnitYoyCheckCount',
                title: 'YoY Check Count',
                align: 'center',
                sortable: true,
              },
              {
                key: 'inUnitYoyCheckCountGrowth',
                title: 'Check Count % vs LY',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Labor',
            title: 'Hourly Labor',
            align: 'center',
            children: [
              {
                key: 'hourlyLaborCostPercent',
                title: '%',
                align: 'center',
                decimal: 1,
                sortable: true,
              },
              {
                key: 'hourlyLaborBudgetVariance',
                title: 'Variance',
                align: 'center',
                decimal: 1,
                reverse: true,
                sortable: true,
              },
            ],
          },
          {
            key: 'COGS',
            title: 'COGS',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'cogsPercent',
                title: '%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'cogsBudgetVariance',
                title: 'Variance',
                align: 'center',
                reverse: true,
                sortable: true,
                decimal: 1,
              },
              {
                key: 'avtPercent',
                title: 'AvT',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
            ],
          },
          {
            key: 'RCP',
            title: 'RCP',
            align: 'center',
            children: [
              {
                key: 'rcp',
                title: '$',
                align: 'center',
                sortable: true,
              },
              {
                key: 'rcpPercent',
                title: '%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'rcpBudgetVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
            ],
          },
        ],
      },
      map: {
        type: 'map',
        api: 'listGeoInfo',
      },
    },
  },
}

export default drgConfig
