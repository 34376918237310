import navigator from 'pared/Routes/navigator'
import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import { getUrlBeforeSignOut, resetUrlBeforeSignOut } from 'pared/utils/user'

import WebLargeMain from './WebLargeMain'

interface IProps {
  isLoading: boolean
  onSignIn: (email: string, password: string) => Promise<boolean>
  errorMessage: string
  brand: string
}

function Main({ isLoading, onSignIn, errorMessage, brand }: IProps) {
  const handleSignIn = async (email: string, password: string) => {
    const isSuccess = await onSignIn(email, password)

    if (isSuccess) {
      const urlBeforeSignOut = getUrlBeforeSignOut()
      if (urlBeforeSignOut) {
        navigator.goUrl(urlBeforeSignOut)
      }
      resetUrlBeforeSignOut()
    }
  }

  return (
    <>
      <LargeScreen>
        <WebLargeMain
          isLoading={isLoading}
          onSignIn={handleSignIn}
          errorMessage={errorMessage}
          brand={brand}
        />
      </LargeScreen>
      <SmallScreen>
        <WebLargeMain
          isLoading={isLoading}
          onSignIn={handleSignIn}
          errorMessage={errorMessage}
          brand={brand}
        />
      </SmallScreen>
    </>
  )
}

export default Main
