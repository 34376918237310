import TextField from '@mui/material/TextField'
import { useState } from 'react'
import styled from 'styled-components'

import { CustomizedReportRender } from 'pared/components/CustomizedReport'
import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import ItemSelector from 'pared/pages/CorporatePurchases/CorporatePurchasesTable/ItemSelector'

import useCustomizedReportData from './hooks/useCustomizedReportData'
import useGetOptions from './hooks/useGetOptions'
import useItemFilter from './hooks/useItemFilter'

interface IProps {
  startDate: string
  endDate: string
  locationIds: number[] | null
  locationGroupIds: number[] | null
  locationGroupTypes: string[] | null
  presetItemFilter?: string
  shouldHideName?: boolean
}

const days = [
  {
    name: 'All',
    type: 'Day',
  },
  {
    name: 'Sunday',
    type: 'Day',
  },
  {
    name: 'Monday',
    type: 'Day',
  },
  {
    name: 'Tuesday',
    type: 'Day',
  },
  {
    name: 'Wednesday',
    type: 'Day',
  },
  {
    name: 'Thursday',
    type: 'Day',
  },
  {
    name: 'Friday',
    type: 'Day',
  },
  {
    name: 'Saturday',
    type: 'Day',
  },
]

const PmixTable = ({
  startDate,
  endDate,
  locationIds,
  locationGroupIds,
  locationGroupTypes,
  presetItemFilter,
  shouldHideName,
}: IProps) => {
  const { options, defaultOption } = useGetOptions()

  const [selectedItem, setSelectedItem] = useState<{
    name: string
    type: string
  } | null>(
    presetItemFilter ? { name: presetItemFilter, type: 'item' } : defaultOption,
  )

  const inputParams = {
    locationIds,
    locationGroupTypes,
    locationGroupIds: locationGroupTypes === null ? locationGroupIds : null,
    startDate,
    endDate,
    menuItemName: selectedItem?.name,
  }

  const { reportDefinition, reportResult, isLoading } =
    useCustomizedReportData(inputParams)

  let presetNameFilter = ''
  const {
    itemFilter: nameFilter,
    setItemFilter: setNameFilter,
    itemFilteredCustomizedReportResult: nameFilteredCustomizedReportResult,
  } = useItemFilter(
    reportResult,
    presetNameFilter,
    locationGroupTypes ? 'locationGroupName' : 'locationName',
  )

  const [selectedDay, setSelectedDay] = useState<{
    name: string
    type: string
  }>({ name: 'All', type: 'Day' })

  const handleDayChange = (
    event: any,
    value: { name: string; type: string },
  ) => {
    if (value) {
      setSelectedDay(value)
    }
  }

  let filteredReportResult = {
    ...reportResult,
    tableData: nameFilteredCustomizedReportResult?.tableData?.filter(
      ({ dayOfWeek }: { dayOfWeek: string }) => dayOfWeek === selectedDay.name,
    ),
  }

  let filteredReportDefinition = reportDefinition
  if (reportDefinition) {
    filteredReportDefinition = {
      ...reportDefinition,
      columns: reportDefinition?.columns?.filter(({ key }) => {
        return (
          key !== 'dayOfWeek' &&
          (!shouldHideName || (shouldHideName && key !== 'locationName'))
        )
      }),
    }
  }

  const handleItemChange = (
    event: any,
    value: { name: string; type: string },
  ) => {
    if (value) {
      setSelectedItem(value)
    }
  }

  return (
    <Container>
      <FilterContainer>
        {shouldHideName ? null : (
          <>
            <span>Name</span>
            <StyledTextField
              value={nameFilter}
              placeholder="Start typing name..."
              onChange={(e) => setNameFilter(e.target.value)}
            />
          </>
        )}
        <span>Item</span>
        <ItemSelector
          allItems={options}
          selectedItem={selectedItem}
          onItemChange={handleItemChange}
          isEnabled
          isLoading={isLoading}
          isLarge
        />
        <span>Day</span>
        <ItemSelector
          allItems={days}
          selectedItem={selectedDay}
          onItemChange={handleDayChange}
          isEnabled
          isGrouped
          isLoading={isLoading}
          isLarge
        />
      </FilterContainer>
      <CustomizedReportRender
        key={`${startDate} - ${endDate}`}
        reportDefinition={filteredReportDefinition}
        reportResult={filteredReportResult}
        isLoading={isLoading}
        rowKey={({ locationGroupName, locationName, menuItemName }) =>
          `${locationGroupName}-${locationName}-${menuItemName}`
        }
        csvFileName="getexpo-pmix"
      />
    </Container>
  )
}

export default PmixTable

const Container = styled.div`
  padding: 0;
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
  }
`

const FilterContainer = styled.div`
  width: 1300px;
  padding: 25px 0;
  display: flex;
  align-items: center;
  gap: 20px;
  span {
    font-family: Lexend-SemiBold;
    font-size: 14px;
    font-weight: 700;
  }

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 5px 0 25px 0;

    span {
      display: block;
      padding: 0;
    }
  }
`

const StyledTextField = styled(TextField)`
  width: 300px;
  background-color: white;
  margin: 0 5px;
  font-family: Lexend-Regular;

  @media ${MOBILE_WIDTH} {
    width: 100%;
    margin: 10px 0 20px 0;
  }
`
