import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import { IOrderBy, IStaffKpi } from './index'

interface IProps {
  staffKpis: IStaffKpi[]
  staffDeliveryCategory: string
  onSelectStaffDeliveryCategory: (event: any) => void
  orderBy: IOrderBy
  setOrderBy: any
}

function Main({
  staffKpis,
  staffDeliveryCategory,
  onSelectStaffDeliveryCategory,
  orderBy,
  setOrderBy,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          staffKpis={staffKpis}
          staffDeliveryCategory={staffDeliveryCategory}
          onSelectStaffDeliveryCategory={onSelectStaffDeliveryCategory}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          staffKpis={staffKpis}
          staffDeliveryCategory={staffDeliveryCategory}
          onSelectStaffDeliveryCategory={onSelectStaffDeliveryCategory}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
        />
      </SmallScreen>
    </>
  )
}

export default Main
