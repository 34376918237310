import { INavParams } from 'pared/Routes/navParams'
import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import { IDateRange } from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import {
  IFormattedDoBreakdownInfo,
  IFormattedLocationGroupBreakdownInfo,
  IFormattedStoreInfo,
  ILocationCoordinate,
  ISummaryPerformance,
} from './index'

interface IProps {
  navParams: INavParams
  isLoading: boolean
  allDirectors: IDirector[]
  selectedDateRange: IDateRange | null
  selectedDirector: IDirector | null
  selectedLocationGroupIds: number[]
  onDirectorChange: (directorEmployeeId: number) => void
  summaryPerformance: ISummaryPerformance
  sortedStoreList: IFormattedStoreInfo[]
  sortedDoBreakdownList: IFormattedDoBreakdownInfo[] | null
  sortedLocationGroupBreakdownList:
    | IFormattedLocationGroupBreakdownInfo[]
    | null
  breakdownType: string | null
  handleLocationGroupNameClick: (
    locationGroupId: number,
    locationGroupName: string,
  ) => void
  handleGoBack: () => void
  selectedLocationGroup: { id: number; name?: string } | null
  errorMessage: string
  locationsWithCoordinates: ILocationCoordinate[]
}

function Main({
  navParams,
  isLoading,
  allDirectors,
  selectedDateRange,
  selectedDirector,
  selectedLocationGroupIds,
  onDirectorChange,
  summaryPerformance,
  sortedStoreList,
  sortedDoBreakdownList,
  sortedLocationGroupBreakdownList,
  breakdownType,
  handleLocationGroupNameClick,
  handleGoBack,
  selectedLocationGroup,
  errorMessage,
  locationsWithCoordinates,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          navParams={navParams}
          isLoading={isLoading}
          allDirectors={allDirectors}
          selectedDateRange={selectedDateRange}
          selectedDirector={selectedDirector}
          selectedLocationGroupIds={selectedLocationGroupIds}
          onDirectorChange={onDirectorChange}
          summaryPerformance={summaryPerformance}
          sortedStoreList={sortedStoreList}
          sortedDoBreakdownList={sortedDoBreakdownList}
          sortedLocationGroupBreakdownList={sortedLocationGroupBreakdownList}
          breakdownType={breakdownType}
          handleLocationGroupNameClick={handleLocationGroupNameClick}
          handleGoBack={handleGoBack}
          selectedLocationGroup={selectedLocationGroup}
          errorMessage={errorMessage}
          locationsWithCoordinates={locationsWithCoordinates}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          navParams={navParams}
          isLoading={isLoading}
          allDirectors={allDirectors}
          selectedDateRange={selectedDateRange}
          selectedDirector={selectedDirector}
          selectedLocationGroupIds={selectedLocationGroupIds}
          onDirectorChange={onDirectorChange}
          summaryPerformance={summaryPerformance}
          sortedStoreList={sortedStoreList}
          sortedDoBreakdownList={sortedDoBreakdownList}
          sortedLocationGroupBreakdownList={sortedLocationGroupBreakdownList}
          breakdownType={breakdownType}
          handleLocationGroupNameClick={handleLocationGroupNameClick}
          handleGoBack={handleGoBack}
          selectedLocationGroup={selectedLocationGroup}
          errorMessage={errorMessage}
        />
      </SmallScreen>
    </>
  )
}

export default Main
