import { useMemo } from 'react'

import { IColumnsType } from 'pared/components/basicUi/table'

import { getTotalPrice } from '../utils'
import { IOpsCompsDataType } from './useOpsComps'

export default (): IColumnsType<IOpsCompsDataType>[] =>
  useMemo(
    () => [
      {
        key: 'rank',
        title: 'Rank',
        align: 'center',
      },
      {
        key: 'opsCompsReason',
        title: 'Discount',
        sorter: true,
      },
      {
        key: 'quantity',
        title: 'Quantity',
        align: 'center',
        sorter: true,
      },
      {
        key: 'totalCompsAmount',
        title: '$ Discount',
        align: 'center',
        render: getTotalPrice,
        sorter: true,
      },
    ],
    [],
  )
