import { INavParams } from 'pared/Routes/navParams'
import { ILocationInfo } from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import { IDateRange } from 'pared/data/getDateRanges'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import { IDaypartBreakdown, IRevCenterBreakdown, IStoreData } from './index'

interface IProps {
  navParams: INavParams
  isLoading: boolean
  storeData: IStoreData | null
  selectedDateRange: IDateRange | null
  errorMessage: string
  userEmployeeId: number
  selectedLocation: ILocationInfo | null
  revCenterBreakdown: IRevCenterBreakdown[]
  locationRevCenterBreakdownLoading: boolean
  daypartBreakdown: IDaypartBreakdown[]
  customizedTrendLocationFinancialKpis: {
    columns: {}[]
    total: {}
  }
  trendSalesTrendStackedBarItems?: string[]
}

function Main({
  navParams,
  isLoading,
  storeData,
  selectedDateRange,
  errorMessage,
  userEmployeeId,
  selectedLocation,
  revCenterBreakdown,
  locationRevCenterBreakdownLoading,
  daypartBreakdown,
  customizedTrendLocationFinancialKpis,
  trendSalesTrendStackedBarItems,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          navParams={navParams}
          isLoading={isLoading}
          selectedDateRange={selectedDateRange}
          storeData={storeData}
          errorMessage={errorMessage}
          userEmployeeId={userEmployeeId}
          selectedLocation={selectedLocation}
          revCenterBreakdown={revCenterBreakdown}
          locationRevCenterBreakdownLoading={locationRevCenterBreakdownLoading}
          daypartBreakdown={daypartBreakdown}
          customizedTrendLocationFinancialKpis={
            customizedTrendLocationFinancialKpis
          }
          trendSalesTrendStackedBarItems={trendSalesTrendStackedBarItems}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          navParams={navParams}
          isLoading={isLoading}
          selectedDateRange={selectedDateRange}
          storeData={storeData}
          errorMessage={errorMessage}
          userEmployeeId={userEmployeeId}
          selectedLocation={selectedLocation}
          revCenterBreakdown={revCenterBreakdown}
          locationRevCenterBreakdownLoading={locationRevCenterBreakdownLoading}
          daypartBreakdown={daypartBreakdown}
          customizedTrendLocationFinancialKpis={
            customizedTrendLocationFinancialKpis
          }
          trendSalesTrendStackedBarItems={trendSalesTrendStackedBarItems}
        />
      </SmallScreen>
    </>
  )
}

export default Main
