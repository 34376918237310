import { useState } from 'react'

import { track } from 'pared/analytics/storeList'
import { feature } from 'pared/analytics/user'

import { IUnfilledManagerialPosition } from '../index'
import Main from './Main'

interface IProps {
  unfilledManagerialPositions: IUnfilledManagerialPosition[]
  unfilledManagerialPositionOption: string
  unfilledManagerialPositionRef: any
}

function UnfilledManagerialPositionTable({
  unfilledManagerialPositions,
  unfilledManagerialPositionOption,
  unfilledManagerialPositionRef,
}: IProps) {
  const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false)

  const onToggleTable = () => {
    if (
      unfilledManagerialPositionRef &&
      unfilledManagerialPositionRef.current &&
      isTableExpanded
    ) {
      unfilledManagerialPositionRef.current.scrollIntoView()
    }
    setIsTableExpanded(!isTableExpanded)
    feature.used('Exec View Team - Unfilled Managerial Position')
    track.unfilledManagerialPositionsToggled()
  }

  return (
    <Main
      isTableExpanded={isTableExpanded}
      onToggleTable={onToggleTable}
      unfilledManagerialPositions={unfilledManagerialPositions}
      unfilledManagerialPositionOption={unfilledManagerialPositionOption}
    />
  )
}

export default UnfilledManagerialPositionTable
