import { ILossPreventionConfig } from '../useLossPreventionConfig'

export const sandBoxBkConfig: ILossPreventionConfig = {
  summary: {
    isVisible: true,
  },
  kpiSettings: [
    { category: 'basic', key: 'totalComps', title: 'Total Discounts' },
    { category: 'basic', key: 'cashOverOrShort', title: 'Cash +/-' },
    {
      category: 'custom',
      type: 'Over Ring',
      title: '% Checks with Voids',
      key: 'checkPercent',
    },
    {
      category: 'custom',
      type: 'Deal after Total',
      title: '% Checks with Deletes',
      key: 'checkPercent',
    },
    {
      category: 'basic',
      key: 'inaccurateCashierShiftPercent',
      title: '% Shifts with Inaccurate Cash',
    },
  ],
  cashHandlingTable: {
    display: false,
    columns: [],
  },
  voidsAndDeletesTable: {
    display: false,
    columns: [],
  },
  discountByEmployeeTable: {
    display: true,
  },
}
