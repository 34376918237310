import { useQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import useBrands from 'pared/layouts/hooks/useBrands'
import { toUsdStr } from 'pared/utils/number'

import { IApiDataType, IExrayDataType } from '../../types'

interface IDirectorInfo {
  id: number
  given_name: string
  family_name: string
  preferred_name: string
}

interface ILocationInfo {
  id: string
  code: string
  name: string
  director: IDirectorInfo
}

interface ICorporateSalesmanshipLocations extends ILocationInfo {
  rate: number
  expectRate: number
  checkCount: number
  opportunityCost: number
  annualOpportunityCost: number
}

interface ILocationSalesmanshipDataType
  extends ICorporateSalesmanshipLocations {
  menuCategory: string
}

interface ISalesSummaryNodeType {
  area: string
  locations: ICorporateSalesmanshipLocations[]
  annualOpportunityCost: number
  opportunityCost: number
  totalNetSales: number
  annualTotalNetSales: number
}

const query = gql`
  query GetSummary(
    $iLocationGroupId: Int!
    $iBrandLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    corporateSalesmanshipSummaryV2(
      iLocationGroupId: $iLocationGroupId
      iBrandLocationGroupId: $iBrandLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        area
        locations
        annualOpportunityCost
        opportunityCost
        totalNetSales
        annualTotalNetSales
      }
    }
  }
`

const getSalesSummaryData = (
  locationId: number,
  locationName: string,
  dataSource: ISalesSummaryNodeType[],
): IExrayDataType => {
  const salesAction = {
    isBetterThanCompany: false,
    menuCategory: '',
    annualOpportunityCost: 0,
    basisPoint: '0',
  }

  const locationData =
    dataSource?.reduce(
      (
        result: ILocationSalesmanshipDataType[],
        salesData: ISalesSummaryNodeType,
      ) => {
        const location = salesData?.locations?.filter(
          (l) => parseInt(l.id) === locationId,
        )?.[0]
        if (location)
          return [{ menuCategory: salesData.area, ...location }, ...result]
        else return [...result]
      },
      [] as ILocationSalesmanshipDataType[],
    ) || []

  if (locationData.length === 0) {
    salesAction.isBetterThanCompany = true
  } else {
    const cost = Math.max(
      ...locationData?.map(
        (l: ILocationSalesmanshipDataType) => l.annualOpportunityCost,
      ),
    )

    salesAction.annualOpportunityCost = cost / 100

    if (dataSource) {
      salesAction.basisPoint = (
        (10000.0 * cost) /
        dataSource?.[0]?.annualTotalNetSales
      ).toFixed(0)
    }

    const topOpportunity = locationData?.filter(
      (l: ILocationSalesmanshipDataType) => l.annualOpportunityCost === cost,
    )?.[0]

    salesAction.menuCategory = topOpportunity?.menuCategory
  }

  const detail = (() => {
    if (salesAction.isBetterThanCompany)
      return `${locationName} performs better than company averages.`

    return `Your top opportunities to increase check averages are ${
      salesAction.menuCategory
    }.
Consider running contests or training on suggestive selling.  Bringing these upsell categories up to system averages constitutes a ${toUsdStr(
      salesAction.annualOpportunityCost,
    )} annual opportunity cost which amounts to a ${
      salesAction.basisPoint
    } basis point improvement for ${locationName}.
`
  })()

  return {
    title: 'Sales',
    total: salesAction.annualOpportunityCost,
    detail,
  }
}

const useSalesSummary = (): IApiDataType => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'

  const { brand } = useBrands()
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  const { data, loading } = useQuery(query, {
    variables: {
      iLocationGroupId: brandLocationGroupId,
      iBrandLocationGroupId: brandLocationGroupId,
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip: !groupFilter,
  })

  return {
    data: useMemo(() => {
      if (!data) return null

      return getSalesSummaryData(
        locationId,
        locationName,
        data?.corporateSalesmanshipSummaryV2?.nodes,
      )
    }, [locationId, data, locationName]),
    loading,
  }
}

export default useSalesSummary
