import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { track } from 'pared/analytics/salesmanship'
import { feature, page } from 'pared/analytics/user'
import {
  DAYPARTS,
  FILTER_TYPE,
  NUM_DAYS_WORKED,
  RANKINGS_ROW_TYPE,
} from 'pared/constants'
import { IPeriod } from 'pared/data/getPeriods'
import useDefaultLocation from 'pared/hooks/useDefaultLocation'
import { scrollToTop } from 'pared/utils/web'

import Main from './Main'
import {
  GET_LOCATION_DATA,
  LIST_LOCATION_SALESMANS,
  LIST_LOCATION_SALESMANS_BY_DAYS_WORKED,
} from './gql'

export interface ILocationData {
  location: {
    id: number | null
    code: string
    name: string
    displayName: string
    doEmployeeId: number | null
    doName: string
    gmEmployeeId: number | null
    gmName: string
  }
}

export interface ISalesmanship {
  employeeId: number
  firstName: string
  lastName: string
  grossSales: number
  grossSalesRank: number
  salesPerHour: number
  salesPerHourRank: number
  salesPpa: number
  salesPpaRank: number
}

interface IProps {
  navParams: INavParams
}

const Salesmanship = ({ navParams }: IProps) => {
  useDefaultLocation()
  const storeId = navParams.storeId || ''
  const storeIdNum = parseInt(storeId, 10) || 0
  const dateRange = navParams.dateRange || ''
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const section = urlParams.get('section')

  let errorMessage = ''

  const [dayPart, setDayPart] = useState<string>(DAYPARTS.DINNER)
  const [isLeaderboardsExpanded, setIsLeaderboardsExpanded] =
    useState<boolean>(false)
  const [isTotalSalesExpanded, setIsTotalSalesExpanded] =
    useState<boolean>(false)
  const [isSalesPerHourExpanded, setIsSalesPerHourExpanded] =
    useState<boolean>(false)
  const [isPpaExpanded, setIsPpaExpanded] = useState<boolean>(false)
  const [filterType, setFilterType] = useState<string>(FILTER_TYPE.SHIFTS)
  const [numDaysWorked, setNumDaysWorked] = useState<number>(30)
  const [selectedPeriod, setSelectedPeriod] = useState<IPeriod | null>(null)
  const [dateRangeStartDate, setDateRangeStartDate] = useState(new Date())
  const [dateRangeEndDate, setDateRangeEndDate] = useState(new Date())
  const [selectedValue, setSelectedValue] = useState<string>(
    NUM_DAYS_WORKED.LAST_30_SHIFTS,
  )

  const [getLocationData, { error, data }] = useLazyQuery(GET_LOCATION_DATA)

  const [
    listLocationSalesmansByDaysWorked,
    {
      loading: salesmanshipByDaysWorkedLoading,
      error: salesmanshipByDaysWorkedError,
      data: salesmanshipByDaysWorkedData,
    },
  ] = useLazyQuery(LIST_LOCATION_SALESMANS_BY_DAYS_WORKED)

  const [
    listLocationSalesmans,
    {
      loading: salesmanshipByDateLoading,
      error: salesmanshipByDateError,
      data: salesmanshipByDateData,
    },
  ] = useLazyQuery(LIST_LOCATION_SALESMANS)

  useEffect(() => {
    async function fetchData() {
      if (storeIdNum) {
        getLocationData({
          variables: {
            iFilter: {
              location_ids: [storeIdNum],
            },
          },
        })
        switch (filterType) {
          case FILTER_TYPE.SHIFTS: {
            listLocationSalesmansByDaysWorked({
              variables: {
                locationId: storeIdNum,
                numDaysWorked,
                dayPart,
              },
            })
            break
          }
          case FILTER_TYPE.PERIOD: {
            listLocationSalesmans({
              variables: {
                locationId: storeIdNum,
                startDate: _.get(selectedPeriod, 'periodStartDate', ''),
                endDate: _.get(selectedPeriod, 'periodEndDate', ''),
                dayPart,
              },
            })
            break
          }
          case FILTER_TYPE.DATE: {
            listLocationSalesmans({
              variables: {
                locationId: storeIdNum,
                startDate: moment(dateRangeStartDate).format(),
                endDate: moment(dateRangeEndDate).format(),
                dayPart,
              },
            })
            break
          }
          default: {
            listLocationSalesmansByDaysWorked({
              variables: {
                locationId: storeIdNum,
                numDaysWorked,
                dayPart,
              },
            })
          }
        }
      }
    }

    fetchData()
  }, [
    storeIdNum,
    dateRange,
    dayPart,
    numDaysWorked,
    listLocationSalesmansByDaysWorked,
    filterType,
    selectedPeriod,
    listLocationSalesmans,
    dateRangeEndDate,
  ])

  useEffect(() => {
    if (section === 'compsTable') {
      window.scrollTo(0, document.body.scrollHeight)
    }
  })

  useEffect(() => {
    if (storeIdNum && navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl, { locationId: storeIdNum })
      feature.used('Salesmanship', { locationId: storeIdNum })
    }
  }, [storeIdNum, navParams.pageUrl])

  let locationData: ILocationData | null = null

  if (!storeIdNum) {
    errorMessage = 'Please select a store first to view this page ...'
  }

  let salesmanships: ISalesmanship[] = []

  if (data) {
    locationData = {
      location: {
        id: null,
        code: '',
        name: '',
        displayName: '-',
        doEmployeeId: null,
        doName: '-',
        gmEmployeeId: null,
        gmName: '',
      },
    }

    if (
      data &&
      data.listLocationDetails &&
      Array.isArray(data.listLocationDetails.nodes) &&
      data.listLocationDetails.nodes.length === 1 &&
      data.listLocationDetails.nodes[0].id === storeIdNum
    ) {
      const info = data.listLocationDetails.nodes[0]

      locationData.location = {
        id: info.id,
        code: info.code || '',
        name: info.name || '',
        displayName: [info.code, info.name].join(' - '),
        doEmployeeId: info.directorEmployeeInfo
          ? info.directorEmployeeInfo.id
          : null,
        doName: info.directorEmployeeInfo
          ? `${info.directorEmployeeInfo.preferredName} ${info.directorEmployeeInfo.familyName}`
          : '-',
        gmEmployeeId: info.gmEmployeeInfo ? info.gmEmployeeInfo.id : null,
        gmName: info.gmEmployeeInfo
          ? `${info.gmEmployeeInfo.preferredName} ${info.gmEmployeeInfo.familyName}`
          : '-',
      }
    }

    if (
      filterType === FILTER_TYPE.SHIFTS &&
      salesmanshipByDaysWorkedData &&
      salesmanshipByDaysWorkedData.listLocationSalesmansByDaysWorked &&
      Array.isArray(
        salesmanshipByDaysWorkedData.listLocationSalesmansByDaysWorked.nodes,
      )
    ) {
      const rawSalesmanshipByDaysWorkedAvgData = _.first(
        _.get(
          salesmanshipByDaysWorkedData,
          'listLocationSalesmansByDaysWorked.nodes',
          [],
        ),
      )

      const rawSalesmanships = _.get(
        salesmanshipByDaysWorkedData,
        'listLocationSalesmansByDaysWorked.nodes',
        [],
      )
      salesmanships = _.map(rawSalesmanships, (s) => {
        return {
          employeeId: _.get(s, 'employeeId', 0),
          firstName: _.get(s, 'firstName', ''),
          lastName: _.get(s, 'lastName', ''),
          grossSales: _.get(s, 'grossSales', 0),
          grossSalesRank: _.get(s, 'grossSalesRank', 0),
          salesPerHour: _.get(s, 'salesPerHour', 0),
          salesPerHourRank: _.get(s, 'salesPerHourRank', 0),
          salesPpa: _.get(s, 'salesPpa', 0),
          salesPpaRank: _.get(s, 'salesPpaRank', 0),
          sosAvgTime: _.get(s, 'sosAvgTime', 0),
          sosAvgTimeRank: _.get(s, 'sosAvgTimeRank', 0),
        }
      })
      salesmanships.push({
        rowType: RANKINGS_ROW_TYPE.AVG,
        grossSales: _.get(
          rawSalesmanshipByDaysWorkedAvgData,
          'avgGrossSales',
          0,
        ),
        salesPerHour: _.get(
          rawSalesmanshipByDaysWorkedAvgData,
          'avgSalesPerHour',
          0,
        ),
        salesPpa: _.get(rawSalesmanshipByDaysWorkedAvgData, 'avgSalesPpa', 0),
        sosAvgTime: _.get(
          rawSalesmanshipByDaysWorkedAvgData,
          'avgSosAvgTime',
          0,
        ),
      })
    }

    if (
      (filterType === FILTER_TYPE.PERIOD || filterType === FILTER_TYPE.DATE) &&
      salesmanshipByDateData &&
      salesmanshipByDateData.listLocationSalesmans &&
      Array.isArray(salesmanshipByDateData.listLocationSalesmans.nodes)
    ) {
      const rawSalesmanshipByDateAvgData = _.first(
        _.get(salesmanshipByDateData, 'listLocationSalesmans.nodes', []),
      )

      const rawSalesmanships = _.get(
        salesmanshipByDateData,
        'listLocationSalesmans.nodes',
        [],
      )
      salesmanships = _.map(rawSalesmanships, (s) => {
        return {
          employeeId: _.get(s, 'employeeId', 0),
          firstName: _.get(s, 'firstName', ''),
          lastName: _.get(s, 'lastName', ''),
          grossSales: _.get(s, 'grossSales', 0),
          grossSalesRank: _.get(s, 'grossSalesRank', 0),
          salesPerHour: _.get(s, 'salesPerHour', 0),
          salesPerHourRank: _.get(s, 'salesPerHourRank', 0),
          salesPpa: _.get(s, 'salesPpa', 0),
          salesPpaRank: _.get(s, 'salesPpaRank', 0),
          sosAvgTime: _.get(s, 'sosAvgTime', 0),
          sosAvgTimeRank: _.get(s, 'sosAvgTimeRank', 0),
        }
      })
      salesmanships.push({
        rowType: RANKINGS_ROW_TYPE.AVG,
        grossSales: _.get(rawSalesmanshipByDateAvgData, 'avgGrossSales', 0),
        salesPerHour: _.get(rawSalesmanshipByDateAvgData, 'avgSalesPerHour', 0),
        salesPpa: _.get(rawSalesmanshipByDateAvgData, 'avgSalesPpa', 0),
        sosAvgTime: _.get(rawSalesmanshipByDateAvgData, 'avgSosAvgTime', 0),
      })
    }
  }

  const handleSetDayPart = (newDayPart: string) => {
    track.daypartChanged(newDayPart)
    setDayPart(newDayPart)
  }

  return (
    <Main
      isLoading={salesmanshipByDaysWorkedLoading || salesmanshipByDateLoading}
      navParams={navParams}
      locationData={locationData}
      errorMessage={errorMessage}
      salesmanships={salesmanships}
      dayPart={dayPart}
      setDayPart={handleSetDayPart}
      isLeaderboardsExpanded={isLeaderboardsExpanded}
      setIsLeaderboardsExpanded={setIsLeaderboardsExpanded}
      filterType={filterType}
      setFilterType={setFilterType}
      numDaysWorked={numDaysWorked}
      setNumDaysWorked={setNumDaysWorked}
      selectedPeriod={selectedPeriod}
      setSelectedPeriod={setSelectedPeriod}
      dateRangeStartDate={dateRangeStartDate}
      dateRangeEndDate={dateRangeEndDate}
      setDateRangeStartDate={setDateRangeStartDate}
      setDateRangeEndDate={setDateRangeEndDate}
      isTotalSalesExpanded={isTotalSalesExpanded}
      setIsTotalSalesExpanded={setIsTotalSalesExpanded}
      isSalesPerHourExpanded={isSalesPerHourExpanded}
      setIsSalesPerHourExpanded={setIsSalesPerHourExpanded}
      isPpaExpanded={isPpaExpanded}
      setIsPpaExpanded={setIsPpaExpanded}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
    />
  )
}

export default Salesmanship
