import React, { useState } from 'react'

import Main from './Main'

interface IProps {
  firstName: string
  lastName: string
  role: string
  storeCode: string
  storeName: string
  revieweeId: number
  locationId: number
  onRefresh: () => Promise<void>
}

const EmployeeFeedbackSpan = ({
  firstName,
  lastName,
  role,
  storeCode,
  storeName,
  revieweeId,
  locationId,
  onRefresh,
}: IProps) => {
  const [employeeAnchorEl, setEmployeeAnchorEl] = useState(null)

  return (
    <Main
      employeeAnchorEl={employeeAnchorEl}
      setEmployeeAnchorEl={setEmployeeAnchorEl}
      firstName={firstName}
      lastName={lastName}
      role={role}
      storeCode={storeCode}
      storeName={storeName}
      revieweeId={revieweeId}
      locationId={locationId}
      onRefresh={onRefresh}
    />
  )
}

export default EmployeeFeedbackSpan
