export const irmgPopKpiDefs = {
  dessertRate: {
    key: 'dessertRate',
    name: 'Dessert Rate',
    kpiType: 'sales',
    salesType: 'Dessert',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  comboRate: {
    key: 'comboRate',
    name: 'Combo Rate',
    kpiType: 'sales',
    salesType: 'Combo',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  drinkRate: {
    key: 'drinkRate',
    name: 'Drink Rate',
    kpiType: 'sales',
    salesType: 'Drink',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  sideRate: {
    key: 'sideRate',
    name: 'Side Rate',
    kpiType: 'sales',
    salesType: 'Side',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  wingRate: {
    key: 'wingRate',
    name: 'Wing Rate',
    kpiType: 'sales',
    salesType: 'Wing',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
}
