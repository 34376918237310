import { INavParams } from 'pared/Routes/navParams'
import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import { IDateRange } from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import { IGlobalDeliveryKpi } from './index'

interface IProps {
  navParams: INavParams
  allDirectors: IDirector[]
  selectedDirector: IDirector | null
  onDirectorChange: (directorEmployeeId: number) => Promise<void>
  selectedDateRange: IDateRange | null
  globalDeliveryKpiSummary: IGlobalDeliveryKpi
  selectedLocationGroupId: number
  breakdownType: string | null
}

function Main({
  navParams,
  allDirectors,
  selectedDirector,
  onDirectorChange,
  selectedDateRange,
  globalDeliveryKpiSummary,
  selectedLocationGroupId,
  breakdownType,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          navParams={navParams}
          globalDeliveryKpiSummary={globalDeliveryKpiSummary}
          allDirectors={allDirectors}
          selectedDirector={selectedDirector}
          selectedDateRange={selectedDateRange}
          onDirectorChange={onDirectorChange}
          selectedLocationGroupId={selectedLocationGroupId}
          breakdownType={breakdownType}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          navParams={navParams}
          globalDeliveryKpiSummary={globalDeliveryKpiSummary}
          allDirectors={allDirectors}
          selectedDirector={selectedDirector}
          selectedDateRange={selectedDateRange}
          onDirectorChange={onDirectorChange}
          selectedLocationGroupId={selectedLocationGroupId}
          breakdownType={breakdownType}
        />
      </SmallScreen>
    </>
  )
}

export default Main
