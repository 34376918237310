import { useEffect, useMemo, useState } from 'react'

import useCustomizedCalendarData from './useCustomizedCalendarData'

export interface IBunFilterType {
  id: string
  parentId: 'root' | null
  value: string
  text: string
}

const useBunFilter = (locationId: number) => {
  const [bunFilter, setBunFilter] = useState<string[]>([])
  const { calendarData } = useCustomizedCalendarData(locationId)

  const bunFilters = useMemo(() => {
    let itemList: string[] = []
    if (Array.isArray(calendarData)) {
      let uniqueItems = new Set<string>()

      calendarData.forEach((rawData: any) => {
        const item: string = rawData.item
        if (item) {
          uniqueItems.add(item)
        }
      })
      itemList = Array.from(uniqueItems).sort()
    }

    const menuItemList: IBunFilterType[] = [
      {
        id: 'root',
        parentId: null,
        value: 'root',
        text: 'root',
      },
    ]

    itemList.forEach((item: string) => {
      menuItemList.push({
        id: item,
        parentId: 'root' as const,
        value: item,
        text: item,
      })
    })

    return menuItemList
  }, [locationId, calendarData])

  useEffect(() => {
    if (!bunFilter && bunFilters) setBunFilter([bunFilters[0].id])
  }, [bunFilter, bunFilters])

  return {
    bunFilter,
    setBunFilter,
    bunFilters,
  }
}

export default useBunFilter
