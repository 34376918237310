import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { track } from 'pared/analytics/storeList'
import COLORS from 'pared/constants/colors'

import { IDataType as IGroupType } from '../../groupFilter'
import { IColumnsType as ITableColumnsType } from '../types'

export interface IGroupInfoConfigsType {
  width?: string
  groupBy?: {
    width: string
  }
}

export type IGroupInfoType = NonNullable<IGroupType['list']>[number]

const LinkButton = styled(Link)`
  color: ${COLORS.Link};
`

const useGroupInfoColumns = (
  useSummary: boolean,
  configs: IGroupInfoConfigsType = {},
  groupInfo?: IGroupInfoType,
) =>
  useMemo(() => {
    if (!groupInfo) return []

    return [
      {
        key: 'groupInfo',
        title: groupInfo.header,
        sorter: (a: IGroupInfoType, b: IGroupInfoType) => {
          const aLocationCode = a.name.match(/\d+/)
          const bLocationCode = b.name.match(/\d+/)
          return aLocationCode && bLocationCode
            ? parseInt(bLocationCode[0]) - parseInt(aLocationCode[0])
            : b.name.localeCompare(a.name)
        },
        render: (value: IGroupInfoType) =>
          !value.link ? (
            value.name
          ) : (
            <LinkButton
              to={value.link}
              onClick={() => track.locationLinkClicked(value.id)}
            >
              {value.name}
            </LinkButton>
          ),
        summary: () => useSummary && 'TOTAL',
        ...configs,
      },
      ...(!groupInfo.groupBy
        ? []
        : [
            {
              key: 'groupInfo.groupBy',
              title: groupInfo.groupBy.header,
              sorter: (
                a: NonNullable<IGroupInfoType['groupBy']>,
                b: NonNullable<IGroupInfoType['groupBy']>,
              ) => b.name.localeCompare(a.name),
              render: (value: IGroupInfoType['groupBy']) =>
                !value?.link ? (
                  value?.name
                ) : (
                  <LinkButton
                    to={value.link}
                    onClick={() => {
                      if (value.id) track.directorProfileLinkClicked(value.id)
                    }}
                  >
                    {value.name}
                  </LinkButton>
                ),
              ...configs?.groupBy,
            },
          ]),
    ] as ITableColumnsType<Record<string, unknown>>[]
  }, [groupInfo])

export default useGroupInfoColumns
