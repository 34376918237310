import _ from 'lodash'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import COLORS from 'pared/constants/colors'
import { getBusinessLabel } from 'pared/customer'
import DownArrow from 'pared/images/basic/arrow/caret-down.svg'
import UpArrow from 'pared/images/basic/arrow/caret-up.svg'

import useTeamConfig from '../hooks/useTeamConfig'
import { IDetailTeamKpi } from '../index'
import { IOrderBy } from './index'

interface IProps {
  itemizedKpis: IDetailTeamKpi[]
  isTableExpanded: boolean
  onToggleExpansion: () => void
  orderBy: IOrderBy
  setOrderBy: any
  selectedDirector: IDirector | null
  breakdownType: string | null
}

interface IRankTableTh {
  textAlign?: string
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
}

interface IStorePerformanceSortableTh {
  isBackgroundGrey?: boolean
}

function WebSmallMain({
  itemizedKpis,
  isTableExpanded,
  onToggleExpansion,
  orderBy,
  setOrderBy,
  selectedDirector,
  breakdownType,
}: IProps) {
  const teamConfig = useTeamConfig()
  const handleOrdering = (event: any) => {
    const orderingColumnValue = _.get(event, 'currentTarget.dataset.value')
    setOrderBy({
      columnName: orderingColumnValue,
      isAscending: !orderBy.isAscending,
    })
  }

  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)
  const isBreakdownByLocationGroupType = breakdownType !== null

  let nameLabel = getBusinessLabel('store')

  if (selectedDirctorEmployeeId === -2) {
    // breakdownByDirectors
    nameLabel = getBusinessLabel('director')
  } else if (isBreakdownByLocationGroupType) {
    nameLabel = breakdownType
  }

  let orderStatus = null
  if (orderBy.isAscending) {
    orderStatus = <UpArrowImg src={UpArrow} alt="ASC"></UpArrowImg>
  } else {
    orderStatus = <DownArrowImg src={DownArrow} alt="DESC"></DownArrowImg>
  }

  const locationRows = _.map(itemizedKpis, (k) => {
    const locationId = _.get(k, 'locationId', 1)
    const doEmployeeId = _.get(k, 'doEmployeeId', 1)
    const formattedName = _.get(k, 'formattedName', '')
    const key = `${doEmployeeId}-${locationId}-${formattedName}`
    let linkAction

    if (selectedDirctorEmployeeId === -2) {
      // breakdownByDirectors
      linkAction = navigator.employeeProfile(doEmployeeId)
    } else {
      linkAction = navigator.team(locationId)
    }

    return (
      <tr key={key}>
        <RankTableTd textAlign={'left'}>
          {isBreakdownByLocationGroupType ? (
            <div>{formattedName}</div>
          ) : (
            <LightBlueLink to={linkAction} onClick={() => {}}>
              {formattedName}
            </LightBlueLink>
          )}
        </RankTableTd>
        {!teamConfig.managerPar.isVisible ? null : (
          <RankTableTd>{`${_.get(k, 'totalSalariedCount', '-')} of ${_.get(
            k,
            'managerPar',
            '-',
          )}`}</RankTableTd>
        )}
        <RankTableTd>
          {_.get(k, 'annualizedHourlyTurnoverRate', '-')}
        </RankTableTd>
        <RankTableTd>
          {_.get(k, 'annualizedSalariedTurnoverRate', '-')}
        </RankTableTd>
        <RankTableTd>{_.get(k, 'monthlyHourlyTurnoverRate', '-')}</RankTableTd>
        <RankTableTd>
          {_.get(k, 'hourlyTerminatedCountInThisMonth', '-')}
        </RankTableTd>
        <RankTableTd>
          {_.get(k, 'monthlySalariedTurnoverRate', '-')}
        </RankTableTd>
        <RankTableTd>
          {_.get(k, 'salariedTerminatedCountInThisMonth', '-')}
        </RankTableTd>
        {!teamConfig['avgHourlyRate']?.isVisible ? null : (
          <RankTableTd>{_.get(k, 'avgHourlyRate', '-')}</RankTableTd>
        )}
      </tr>
    )
  })

  let toggledlocationRows = locationRows

  if (!isTableExpanded) {
    toggledlocationRows = _.slice(locationRows, 0, 5)
  }

  const toggleText = isTableExpanded ? 'Collapse all' : 'Expand All'

  const expandSpan =
    _.size(locationRows) > 5 ? (
      <ExpandAllSpan onClick={onToggleExpansion}>{toggleText}</ExpandAllSpan>
    ) : null

  return (
    <MainContainer>
      <RankTable>
        <thead>
          <BreakdownHeaderTr>
            <></>
            <KpiHeaderSpacerTd colSpan={4}></KpiHeaderSpacerTd>
            <KpiHeaderSpacerTd colSpan={2}>
              <PtdLabelNameDiv>Hourly Turnover (PTD)</PtdLabelNameDiv>
            </KpiHeaderSpacerTd>
            <KpiHeaderSpacerTd colSpan={2}>
              <PtdLabelNameDiv>Managerial Turnover (PTD)</PtdLabelNameDiv>
            </KpiHeaderSpacerTd>
          </BreakdownHeaderTr>
          <BreakdownSecondHeaderTr>
            <RankTableTh>{nameLabel}</RankTableTh>
            {!teamConfig.managerPar.isVisible ? null : (
              <RankTableSortableTh
                onClick={handleOrdering}
                data-value={'managerPar'}
              >
                <span>Manager Par</span>
                {orderBy.columnName === 'managerPar' ? orderStatus : null}
              </RankTableSortableTh>
            )}
            <RankTableSortableTh
              onClick={handleOrdering}
              data-value={'annualizedHourlyTurnoverRate'}
            >
              <span>Annualized Hourly Turnover</span>
              {orderBy.columnName === 'annualizedHourlyTurnoverRate'
                ? orderStatus
                : null}
            </RankTableSortableTh>
            <RankTableSortableTh
              onClick={handleOrdering}
              data-value={'annualizedSalariedTurnoverRate'}
            >
              <span>Annualized Manager Turnover</span>
              {orderBy.columnName === 'annualizedSalariedTurnoverRate'
                ? orderStatus
                : null}
            </RankTableSortableTh>
            <RankTableSortableTh
              onClick={handleOrdering}
              data-value={'monthlyHourlyTurnoverRate'}
            >
              <span>%</span>
              {orderBy.columnName === 'monthlyHourlyTurnoverRate'
                ? orderStatus
                : null}
            </RankTableSortableTh>
            <RankTableSortableTh
              onClick={handleOrdering}
              data-value={'hourlyTerminatedCountInThisMonth'}
            >
              <span>Count</span>
              {orderBy.columnName === 'hourlyTerminatedCountInThisMonth'
                ? orderStatus
                : null}
            </RankTableSortableTh>
            <RankTableSortableTh
              onClick={handleOrdering}
              data-value={'monthlySalariedTurnoverRate'}
            >
              <span>%</span>
              {orderBy.columnName === 'monthlySalariedTurnoverRate'
                ? orderStatus
                : null}
            </RankTableSortableTh>
            <RankTableSortableTh
              onClick={handleOrdering}
              data-value={'salariedTerminatedCountInThisMonth'}
            >
              <span>Count</span>
              {orderBy.columnName === 'salariedTerminatedCountInThisMonth'
                ? orderStatus
                : null}
            </RankTableSortableTh>
            {!teamConfig['avgHourlyRate']?.isVisible ? null : (
              <RankTableSortableTh
                onClick={handleOrdering}
                data-value={'avgHourlyRate'}
              >
                <span>Avg Hourly Rate</span>
                {orderBy.columnName === 'avgHourlyRate' ? orderStatus : null}
              </RankTableSortableTh>
            )}
          </BreakdownSecondHeaderTr>
        </thead>
        <tbody>{toggledlocationRows}</tbody>
      </RankTable>
      {expandSpan}
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 600px;
`

const ExpandAllSpan = styled.div`
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: ${COLORS.Link};
  text-decoration: underline;
  cursor: pointer;
`

const RankTableSortableTh = styled.th<IRankTableTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  color: ${COLORS.Plum};
  cursor: pointer;
`

const RankTableTh = styled.th<IRankTableTh>`
  width: 200px;
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const UpArrowImg = styled.img`
  width: 10px;
  margin: 0 0 2px 5px;
`

const DownArrowImg = styled.img`
  width: 10px;
  margin: 0 0 -1px 5px;
`

const LightBlueLink = styled(Link)`
  color: ${COLORS.Link};
`

const BreakdownHeaderTr = styled.tr`
  border-bottom: 0;
`

const BreakdownSecondHeaderTr = styled.tr`
  border-bottom: 1px solid ${COLORS.Chalkboard};
`

const KpiHeaderSpacerTd = styled.td``

const PtdLabelNameDiv = styled.div`
  width: 100%;
  text-align: center;
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

const StorePerformanceSortableTh = styled.th<IStorePerformanceSortableTh>`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: ${COLORS.Plum};
  cursor: pointer;
  background-color: ${(props) =>
    props.isBackgroundGrey ? `${COLORS.Porcelain}` : 'white'};
`

const RankTableTd = styled.td<IRankTableTd>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankTable = styled.table`
  width: 900px;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`

export default WebSmallMain
