import { gql } from '@apollo/client'

export const GET_GLOBAL_DELIVERY_KPI = (customizedFields?: string[]) => gql`
  query GetGlobalDeliveryKpi(
    $iEndDate: Date!
    $iStartDate: Date!
    $iLocationGroupId: Int!
  ) {
    getGlobalDeliveryKpi(
      iEndDate: $iEndDate
      iStartDate: $iStartDate
      iLocationGroupId: $iLocationGroupId
    ) {
      nodes {
        avgCustomerReviewScore
        cancelledOrderPercent
        avgDasherWaitTime
        delayedOrderPercent
        inaccurateOrderPercent
        orderWithAnyIssuePercent
        sumErrorCharges
        totalOrderCount
        sumSubtotal
        averageTripTime
      }
    }

    ${
      (customizedFields || []).length <= 0
        ? ''
        : `
      customizedSummaryDelivery(
        iEndDate: $iEndDate
        iStartDate: $iStartDate
        iFilter: {
          location_group_ids: [$iLocationGroupId]
        }
      ) {
        nodes {
          ${customizedFields?.join('\n')}
        }
      }
    `
    }
  }
`

export const LIST_GLOBAL_DELIVERY_KPI = (customizedFields?: string[]) => gql`
  query ListGlobalDeliveryKpiV2(
    $iEndDate: Date!
    $iDateRangeType: String!
    $iLocationGroupId: Int!
  ) {
    listGlobalDeliveryKpiV2(
      iEndDate: $iEndDate
      iDateRangeType: $iDateRangeType
      iLocationGroupId: $iLocationGroupId
    ) {
      nodes {
        avgCustomerReviewScore
        cancelledOrderPercent
        avgDasherWaitTime
        delayedOrderPercent
        inaccurateOrderPercent
        orderWithAnyIssuePercent
        period
        quarter
        sumErrorCharges
        totalOrderCount
        sumSubtotal
        year
        week
      }
    }

    ${
      (customizedFields || []).length <= 0
        ? ''
        : `
      customizedDateGroupDelivery(
        iEndDate: $iEndDate
        iDateRangeType: $iDateRangeType
        iFilter: {
          location_group_ids: [$iLocationGroupId]
        }
      ) {
        nodes {
          period
          quarter
          year
          week
          ${customizedFields}
        }
      }
    `
    }
  }
`

export const LIST_LOCATION_DELIVERY_KPI = (customizedFields?: string[]) => gql`
  query ListLoctionDeliveryKpi(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationDeliveryKpi: listLocationDeliveryKpiV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        avgCustomerReviewScore
        cancelledOrderPercent
        avgDasherWaitTime
        inaccurateOrderPercent
        orderWithAnyIssuePercent
        sumErrorCharges
        totalOrderCount
        sumSubtotal
        locationId
        delayedOrderPercent
        locationInfo {
          code
          name
        }
        averageTripTime
      }
    }

    ${
      (customizedFields || []).length <= 0
        ? ''
        : `
      customizedListLocationDelivery(
        iEndDate: $iEndDate
        iStartDate: $iStartDate
        iFilter: $iFilter
      ) {
        nodes {
          locationId
          ${customizedFields?.join('\n')}
        }
      }
    `
    }
  }
`

export const LIST_LOCATION_GROUP_DELIVERY_KPI = (
  customizedFields?: string[],
) => gql`
  query ListLocationGroupDeliveryKpi(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupDeliveryKpi(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        locationGroupName
        avgCustomerReviewScore
        cancelledOrderPercent
        avgDasherWaitTime
        delayedOrderPercent
        inaccurateOrderPercent
        orderWithAnyIssuePercent
        sumErrorCharges
        totalOrderCount
        sumSubtotal
        doEmployeeId
        doEmployeeInfo {
          firstName: preferredName
          lastName: familyName
        }
        averageTripTime
      }
    }

    ${
      (customizedFields || []).length <= 0
        ? ''
        : `
      customizedListLocationGroupDelivery(
        iEndDate: $iEndDate
        iStartDate: $iStartDate
        iFilter: $iFilter
      ) {
        nodes {
          locationGroupId
          ${customizedFields?.join('\n')}
        }
      }
    `
    }
  }
`
