import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_PMIX_MENU_ITEM_NAMES } from '../gql'

const useGetOptions = () => {
  let options: { name: string; type: string }[] = []

  const { loading, data } = useQuery(GET_PMIX_MENU_ITEM_NAMES)
  if (data?.getPmixMenuItemNames?.nodes) {
    options = data.getPmixMenuItemNames.nodes.map((name: string) => ({
      name,
      type: 'item',
    }))
  }

  return useMemo(() => {
    return {
      options,
      defaultOption: { name: 'All Wraps', type: 'item' },
    }
  }, [options])
}

export default useGetOptions
