import { createAsyncThunk } from '@reduxjs/toolkit'

export interface IAsyncActionStatus {
  isLoading: boolean
  isSucceeded: boolean
  isFailed: boolean
  errorMessage: string
}

interface IAction {
  type: string
  payload: any
  error?: {
    message?: string
  }
}

interface IAsyncReducers {
  pending?: (state?: any, action?: IAction) => void
  fulfilled: (state?: any, action?: IAction) => void
  rejected?: (state?: any, action?: IAction) => void
}

export function generateAsyncActionThunk(
  actionName: string,
  asyncAction: (actionParameters: any) => any,
  asyncReducers: IAsyncReducers,
) {
  const asyncThunk = createAsyncThunk(actionName, asyncAction)

  const pendingStr: string = asyncThunk.pending as any
  const fulfilledStr: string = asyncThunk.fulfilled as any
  const rejectedStr: string = asyncThunk.rejected as any

  const defaultFn = () => {}
  const pendingReducer = asyncReducers.pending ?? defaultFn
  const fulfilledReducer = asyncReducers.fulfilled
  const rejectedReducer = asyncReducers.rejected ?? defaultFn

  return {
    action: asyncThunk,
    reducers: {
      [pendingStr]: (state: any, action: IAction) => {
        const status: IAsyncActionStatus = {
          isLoading: true,
          isSucceeded: false,
          isFailed: false,
          errorMessage: '',
        }
        state.asyncActionStatus = state.asyncActionStatus ?? {}
        state.asyncActionStatus[actionName] = status
        pendingReducer(state, action)
      },
      [fulfilledStr]: (state: any, action: IAction) => {
        const status: IAsyncActionStatus = {
          isLoading: false,
          isSucceeded: true,
          isFailed: false,
          errorMessage: '',
        }
        state.asyncActionStatus = state.asyncActionStatus ?? {}
        state.asyncActionStatus[actionName] = status
        fulfilledReducer(state, action)
      },
      [rejectedStr]: (state: any, action: IAction) => {
        let errorMessage = ''
        if (action.error) {
          errorMessage = action.error.message ?? ''
        }
        const status: IAsyncActionStatus = {
          errorMessage,
          isLoading: false,
          isSucceeded: false,
          isFailed: true,
        }
        state.asyncActionStatus = state.asyncActionStatus ?? {}
        state.asyncActionStatus[actionName] = status
        rejectedReducer(state, action)
      },
    },
  }
}

export function generateAsyncActionStatusGetter(
  sliceName: string,
  actionName: string,
): (state: any) => IAsyncActionStatus {
  return (state: any) => {
    if (state) {
      const sliceState = state[sliceName]
      if (
        sliceState &&
        sliceState.asyncActionStatus &&
        sliceState.asyncActionStatus[actionName]
      ) {
        return sliceState.asyncActionStatus[actionName]
      }
    }

    return {
      isLoading: false,
      isSucceeded: false,
      isFailed: false,
      errorMessage: '',
    }
  }
}
