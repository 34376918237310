import styled from 'styled-components'

import CustomizedReport from 'pared/components/CustomizedReport'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBusinessLabel } from 'pared/customer'

interface IProps {
  locationGroupId: number
  locationGroupType: string | null
  startDate: string
  endDate: string
}

const BreakdownByStore = ({
  locationGroupId,
  locationGroupType,
  startDate,
  endDate,
}: IProps) => {
  const inputParams = {
    locationGroupType,
    locationGroupId: locationGroupType === null ? locationGroupId : null,
    startDate,
    endDate,
  }

  const reportName = inputParams.locationGroupType
    ? 'LIST_LOCATION_GROUP_INBOUND_CALLS_TABLE'
    : 'LIST_LOCATION_INBOUND_CALLS_TABLE'

  return (
    <>
      <PageHeader>Breakdown By {getBusinessLabel('store')}</PageHeader>
      <VerticalSpacer />
      <CustomizedReport
        rowKey={({ locationInfo, locationGroupName }) =>
          locationInfo?.id ?? locationGroupName
        }
        reportName={reportName}
        inputParams={inputParams}
      />
    </>
  )
}

export default BreakdownByStore

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  color: ${COLORS.Chalkboard};
  @media ${MOBILE_WIDTH} {
    font-size: 18px;
  }
`

const VerticalSpacer = styled.div`
  height: 20px;
`
