import { gql, useMutation } from '@apollo/client'
import moment from 'moment'
import { useCallback } from 'react'

import { useReset } from '../../../ResetProvider'

export interface IToggleTypes {
  mutation: keyof typeof mutations
  values: Record<string, unknown>
}

const updateDevelopmentPlan = gql`
  mutation UpdateDevelopmentPlan($id: Int!, $isCompleted: Boolean!) {
    updateDevelopmentPlan(
      input: { id: $id, patch: { isCompleted: $isCompleted } }
    ) {
      developmentPlan {
        id
        isCompleted
      }
    }
  }
`

const updateAssignedTracker = gql`
  mutation UpdateTrainingTracker($listUuid: String!, $now: Datetime!) {
    updateTrainingTrackerByListUuid(
      input: {
        listUuid: $listUuid
        patch: { assignedAt: $now, updatedAt: $now }
      }
    ) {
      trainingTracker {
        id
        assignedAt
        updatedAt
      }
    }
  }
`

const updateNeedApproved = gql`
  mutation UpdateNeedApproved($listUuid: String!, $now: Datetime!) {
    updateTrainingTrackerByListUuid(
      input: {
        listUuid: $listUuid
        patch: { needApproved: true, updatedAt: $now }
      }
    ) {
      clientMutationId
    }
  }
`

const mutations = {
  updateDevelopmentPlan,
  updateAssignedTracker,
  updateNeedApproved,
} as const

const useToggle = (
  setLoading: any,
  { mutation: mutationKey, values }: IToggleTypes,
) => {
  const [mutation] = useMutation(mutations[mutationKey])
  const reset = useReset()

  return useCallback(
    async ({ checked }) => {
      const now = moment().toISOString()

      switch (mutationKey) {
        case 'updateDevelopmentPlan':
          setLoading(true)
          await mutation({
            variables: {
              id: values.planId,
              isCompleted: checked,
            },
          })
          reset()
          break

        case 'updateAssignedTracker':
          if (checked) {
            setLoading(true)
            await mutation({
              variables: {
                listUuid: values.trainingTrackerUuid,
                now: now,
              },
            })
            reset()
          }
          break

        case 'updateNeedApproved':
          if (checked) {
            setLoading(true)
            await mutation({
              variables: {
                listUuid: values.trackerUuid,
                now: now,
              },
            })
            reset()
          }
          break
      }
    },
    [mutationKey, mutation, reset],
  )
}

export default useToggle
