import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { track } from 'pared/analytics/storeList'
import { IColumnsType } from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import { getLocationCode, getLocationName } from 'pared/utils/location'

import useLossPreventionConfig, {
  ICashControlColumnType,
} from '../../hooks/useLossPreventionConfig'
import { getPriceOrDash } from '../../utils'
import { ICashType } from './useCash'

const LinkButton = styled(Link)`
  color: ${COLORS.Link};
`

const makeColumnSettings = (item: ICashControlColumnType): any => {
  if (item?.children) {
    return {
      key: item.key,
      title: item.title,
      width: item?.width || '75px',
      align: 'center',
      children: item.children.map((child) => makeColumnSettings(child)),
    }
  } else {
    const format = (() => {
      switch (item?.valueType) {
        case 'count':
          return (value: any) => value || '-'

        case 'percent':
          return (value: any) => `${value?.toFixed(2) || '-'}%`

        case 'price':
          return (value: any) =>
            getPriceOrDash(value ? (value / 100.0).toFixed(0) : '-')

        default:
          return undefined
      }
    })()
    return {
      key: item.key,
      title: item.title,
      width: item?.width || '75px',
      align: 'center',
      sorter: true,
      ...(format ? { render: format } : null),
    }
  }
}

const useCashColumns = (
  isBreakdownByDirectors: boolean,
  locationGroupType?: string,
): IColumnsType<ICashType>[] => {
  const brandSettings = getBrandSettings()
  const businessLabels = brandSettings.labels.business
  const config = useLossPreventionConfig()?.cashControlTable?.cashControlColumns

  return useMemo(() => {
    const columnTypes: IColumnsType<ICashType>[] = [
      ...((isBreakdownByDirectors
        ? []
        : [
            {
              key: 'storeCode',
              title: 'Store',
              minWidth: '130px',
              render: (_, values, index) => {
                if (index === -1) return 'TOTAL'
                if (!('storeId' in values)) return null

                return (
                  <LinkButton
                    to={navigator.lossPrevention(values.storeId)}
                    onClick={() => track.locationLinkClicked(values.storeId)}
                  >
                    {getLocationCode(values.storeId, values.storeCode)} -{' '}
                    {getLocationName(values.storeId, values.storeName)}
                  </LinkButton>
                )
              },
              sorter: true,
            },
          ]) as IColumnsType<ICashType>[]),
      !locationGroupType
        ? {
            key: 'directorName',
            title: businessLabels['director'],
            minWidth: '150px',
            render: (
              value: ICashType['directorName'],
              { directorId },
              index,
            ) => {
              if (index === -1) return !isBreakdownByDirectors ? '' : 'TOTAL'

              return businessLabels.isDirectorClickable ? (
                <LinkButton
                  to={navigator.employeeProfile(directorId)}
                  onClick={() => track.directorProfileLinkClicked(directorId)}
                >
                  {value}
                </LinkButton>
              ) : (
                value || 'All'
              )
            },
            sorter: true,
          }
        : {
            key: 'locationGroupName',
            title: locationGroupType,
            minWidth: '150px',
            render: (value: string) => value,
            sorter: true,
          },
    ]

    if (config) {
      const customColumns = config.map((item) => makeColumnSettings(item))
      columnTypes.push(...customColumns)
    }

    return columnTypes
  }, [config, isBreakdownByDirectors, locationGroupType])
}

export default useCashColumns
