import { useMemo } from 'react'

import { track } from 'pared/analytics/storeList'

export interface IDataType {
  track?: {
    locationClicked?: (value: unknown) => void
    groupByClicked?: (value: unknown) => void
  }
}

const hasValueId = (value: unknown): value is { id: number } =>
  Boolean(value && typeof value === 'object' && 'id' in value)

const useTrack = (): IDataType => ({
  track: useMemo(
    () => ({
      locationClicked: (value: unknown) => {
        if (hasValueId(value)) track.locationLinkClicked(value.id)
      },
      groupByClicked: (value: unknown) => {
        if (hasValueId(value)) track.locationLinkClicked(value.id)
      },
    }),
    [],
  ),
})

export default useTrack
