import _ from 'lodash'
import styled from 'styled-components'

import { COMP_TYPES_DISPLAY_NAME } from 'pared/constants'
import COLORS from 'pared/constants/colors'
import { toPercentageString, toUsdString } from 'pared/utils/number'

import { IOpsComp } from './index'

interface IProps {
  opsComps: IOpsComp[]
  isOpsCompsExpanded: boolean
  setIsOpsCompsExpanded: any
  isLoading: boolean
}

interface IRankTableTh {
  textAlign?: string
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
}

const WebLargeMain = ({
  opsComps,
  isOpsCompsExpanded,
  setIsOpsCompsExpanded,
  isLoading,
}: IProps) => {
  if (isLoading) {
    return (
      <MainContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </MainContainer>
    )
  }

  const onToggleExpandOpsComps = () => {
    setIsOpsCompsExpanded(!isOpsCompsExpanded)
  }

  const formatCompTypes = (compTypes: any) => {
    const compTypeArr = _.compact(
      _.map(compTypes, (count, compTypeKey) => {
        if (!_.isNull(count) && count !== 0) {
          const compType = _.get(COMP_TYPES_DISPLAY_NAME, [compTypeKey], '')
          return `${compType} (${count})`
        }
      }),
    )
    return compTypeArr.join(', ')
  }

  const opsCompsRows = _.map(opsComps, (o) => {
    const rank = _.findIndex(opsComps, o) + 1
    const firstName = _.startCase(_.get(o, 'firstName', ''))
    const lastName = _.startCase(_.get(o, 'lastName', ''))
    const name = `${firstName} ${lastName}`
    const role = _.startCase(_.get(o, 'role', ''))
    const totalOpsComps = _.get(o, 'totalOpsComps', 0)
    const totalGross = _.get(o, 'totalGross', 0)
    const percentOpsCompOfGross = _.get(o, 'percentOpsCompOfGross', 0)
    const compTypes = _.get(o, 'compTypes', [])

    return (
      <tr>
        <RankNumberTd bold textAlign={'left'}>
          {rank}
        </RankNumberTd>
        <RankTableTd textAlign={'left'}>{name}</RankTableTd>
        <RankTableTd textAlign={'left'}>{role}</RankTableTd>
        <RankTableTd>
          {toUsdString(Math.abs(totalOpsComps) / 100.0, 2)}
        </RankTableTd>
        <RankTableTd>{toUsdString(totalGross / 100.0, 2)}</RankTableTd>
        <RankTableTd>
          {toPercentageString(percentOpsCompOfGross / 100.0, 1)}
        </RankTableTd>
        <RankTableTd textAlign={'left'}>
          {formatCompTypes(compTypes)}
        </RankTableTd>
      </tr>
    )
  })

  let toggledStaffSalesRows = opsCompsRows

  if (!isOpsCompsExpanded) {
    toggledStaffSalesRows = _.slice(opsCompsRows, 0, 4)
  }

  const opsCompsToggleText = isOpsCompsExpanded ? 'Collapse all' : 'Expand All'

  const expandSpan =
    _.size(opsCompsRows) >= 5 ? (
      <ExpandAllSpan onClick={onToggleExpandOpsComps}>
        {opsCompsToggleText}
      </ExpandAllSpan>
    ) : null

  return (
    <>
      <OpsCompsContainer>Ops Comps Breakdown</OpsCompsContainer>
      <CompsTableContainer>
        <RankTable>
          <thead>
            <tr>
              <RankTableTh textAlign={'left'}>Rank</RankTableTh>
              <RankTableTh textAlign={'left'}>Name</RankTableTh>
              <RankTableTh textAlign={'left'}>Position</RankTableTh>
              <RankTableTh>EE Ops Comps</RankTableTh>
              <RankTableTh>EE Gross Sales</RankTableTh>
              <RankTableTh>Ops Comp Rate</RankTableTh>
              <RankTableTh textAlign={'left'}>Comp Types</RankTableTh>
            </tr>
          </thead>
          <tbody>{toggledStaffSalesRows}</tbody>
        </RankTable>
      </CompsTableContainer>
      {expandSpan}
    </>
  )
}

const MainContainer = styled.div`
  width: 1200px;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const CompsTableContainer = styled.div`
  margin-top: 30px;
  width: 1200px;
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  float: left;
`

const OpsCompsContainer = styled(PageHeader)`
  margin-bottom: 25px;
  width: 100%;
`

const RankTableTh = styled.th<IRankTableTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankTableTd = styled.td<IRankTableTd>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankNumberTd = styled(RankTableTd)`
  padding-left: 12px;
`

const RankTable = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }
`

const ExpandAllSpan = styled.div`
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;
`

export default WebLargeMain
