import { useMemo } from 'react'

export const LAST_7_DAYS = {
  title: 'Last 7 Days',
  value: 'last_7_days',
}

export const LAST_14_DAYS = {
  title: 'Last 14 Days',
  value: 'last_14_days',
}

export const LAST_4_WEEKS = {
  title: 'Last 4 Weeks',
  value: 'last_4_weeks',
}

export const LAST_8_WEEKS = {
  title: 'Last 8 Weeks',
  value: 'last_8_weeks',
}

export const LAST_13_PERIODS = {
  title: 'Last 13 Periods',
  value: 'last_13_periods',
}

export const LAST_6_PERIODS = {
  title: 'Last 6 Periods',
  value: 'last_6_periods',
}

export const LAST_3_PERIODS = {
  title: 'Last 3 Periods',
  value: 'last_3_periods',
}

export type IItem =
  | typeof LAST_7_DAYS
  | typeof LAST_14_DAYS
  | typeof LAST_4_WEEKS
  | typeof LAST_8_WEEKS
  | typeof LAST_13_PERIODS
  | typeof LAST_6_PERIODS
  | typeof LAST_3_PERIODS

const useDateRange = (value: IItem['value']) =>
  useMemo(() => {
    return {
      dateRangeNumber: parseInt(value.split(/_/)[1]),
      dateRangeGroupBy: value.replace(/\d+/, 'x'),
    }
  }, [value])

export default useDateRange
