import { gql } from '@apollo/client'

export const LIST_LOCATION_PL = gql`
  query ListLocationPl(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
  ) {
    listPl: listLocationPl(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
    ) {
      nodes {
        date
        locationId
        plCategoryId
        plCategoryName
        plCategorySection
        plCategoryDisplayOrder
        plItemId
        plItemName
        plItemDisplayOrder
        type
        amount
        yoyAmount
        budget
      }
    }
  }
`

export const LIST_LOCATION_GROUP_PL = gql`
  query ListLocationGroupPl(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
  ) {
    listPl: listLocationGroupPl(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
    ) {
      nodes {
        date
        locationGroupId
        plCategoryId
        plCategoryName
        plCategorySection
        plCategoryDisplayOrder
        plItemId
        plItemName
        plItemDisplayOrder
        type
        amount
        yoyAmount
        budget
      }
    }
  }
`
