import { gql } from '@apollo/client'
import _ from 'lodash'

import { BRAND_ID } from 'pared/constants/brands'
import { apolloClient } from 'pared/reactiveVariables/apollo'
import { getBrand } from 'pared/utils/brand'

export interface IDirector {
  employeeId: number
  firstName: string
  lastName: string
  directorId: number
  directorLevel: number
  brandId: number
  brandCode: string
  brandName: string
  locationGroupId: number
}

const FETCH_DIRECTORS = gql`
  query ListDirectors($iFilter: JSON!) {
    listDirectors(iFilter: $iFilter) {
      nodes {
        employeeId
        firstName
        lastName
        directorId
        directorLevel
        brandId
        brandCode
        brandName
        locationGroupId
      }
    }
  }
`

const directors: { [brand: string]: IDirector[] } = {}

async function getDirectors() {
  const brand: string = getBrand()
  const brandId = BRAND_ID[brand]

  if (directors[brand]?.length > 0) {
    // use cached data
    return directors[brand]
  } else {
    const currentApolloClient = apolloClient()

    if (currentApolloClient) {
      let response: any
      try {
        response = await currentApolloClient.query({
          query: FETCH_DIRECTORS,
          variables: {
            iFilter: {
              brand_ids: [brandId],
            },
          },
        })
      } catch (error) {
        if (
          (error as Error).message !==
          'Store reset while query was in flight (not completed in link chain)'
        )
          console.log(error)
      }
      directors[brand] = _.get(response, 'data.listDirectors.nodes') || []
    }

    return directors[brand]
  }
}

export default getDirectors
