import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import { IDirector } from 'pared/data/getDirectors'

import { IUnfilledManagerialPosition } from '../index'
import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'

interface IProps {
  isTableExpanded: boolean
  onToggleTable: () => void
  unfilledManagerialPositions: IUnfilledManagerialPosition[]
  unfilledManagerialPositionOption: string
  selectedDirector: IDirector
}

function Main({
  isTableExpanded,
  onToggleTable,
  unfilledManagerialPositions,
  unfilledManagerialPositionOption,
  selectedDirector,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          isTableExpanded={isTableExpanded}
          onToggleTable={onToggleTable}
          unfilledManagerialPositions={unfilledManagerialPositions}
          unfilledManagerialPositionOption={unfilledManagerialPositionOption}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          isTableExpanded={isTableExpanded}
          onToggleTable={onToggleTable}
          unfilledManagerialPositions={unfilledManagerialPositions}
          unfilledManagerialPositionOption={unfilledManagerialPositionOption}
        />
      </SmallScreen>
    </>
  )
}

export default Main
