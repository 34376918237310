import { useReactiveVar } from '@apollo/client'
import { useRouteMatch } from 'react-router-dom'

import { BRAND_CODES, BRAND_ID } from 'pared/constants/brands'
import { decodedUserJwtToken } from 'pared/reactiveVariables/user'

export default () => {
  const jwtToken = useReactiveVar(decodedUserJwtToken)
  const accessibleBrands: number[] = jwtToken?.accessible_brands || []
  const brands = BRAND_CODES.filter((code) =>
    accessibleBrands.includes(BRAND_ID[code]),
  )
  const match = useRouteMatch<{ brand: string }>('/:brand')

  return { brand: match?.params.brand || brands[0], brands }
}
