import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import useStoreList from '../../hooks/useStoreList'
import {
  IListLocationLossPreventionKpisNodeType,
  IListLocationLossPreventionKpisType,
  ILossPreventionItems,
  LIST_LOCATION_LOSS_PREVENTION_KPIS,
} from '../gql'

interface ICustomDataType {
  [key: string]: { count: number; amount: number; checkPercent: number }
}

const useLocationCash = (
  locationIds?: number[],
  locationGroupIds?: number[],
  startDate?: string,
  endDate?: string,
) => {
  let iFilter: any = null
  if (locationIds && locationIds.length > 0) {
    iFilter = { location_ids: [...locationIds] }
  } else if (locationGroupIds && locationGroupIds.length > 0) {
    iFilter = { location_group_ids: [...locationGroupIds] }
  }

  const { storeList, storeListLoading } = useStoreList()
  const { data: cashData, loading: cashDataLoading } =
    useQuery<IListLocationLossPreventionKpisType>(
      LIST_LOCATION_LOSS_PREVENTION_KPIS,
      {
        variables: {
          iFilter,
          iStartDate: startDate,
          iEndDate: endDate,
        },
        skip: !startDate || !endDate || !iFilter,
      },
    )

  const cashLocationLoading = cashDataLoading || storeListLoading

  const cashLocationData = useMemo(() => {
    const rawData = cashData?.listLocationLossPreventionKpis?.nodes || []

    const tableData =
      rawData?.map((row: IListLocationLossPreventionKpisNodeType) => {
        const storeId = row.locationInfo.id
        const storeCode = row.locationInfo.code
        const storeName = row.locationInfo.name

        const store = storeList?.listLocationDetails?.nodes.find(
          ({ id }) => storeId === id,
        )

        const cashOverOrShort = {
          count: row?.inaccurateCashierShiftCount,
          amount: row?.cashOverOrShort,
          checkPercent: row?.inaccurateCashierShiftPercent,
        }

        const customData = row?.lossPreventionItems?.reduce(
          (result: ICustomDataType, itemData: ILossPreventionItems) => ({
            ...result,
            [itemData.type]: {
              count: itemData?.count,
              amount: itemData?.amount,
              checkPercent: itemData?.checkPercent,
            },
          }),
          {} as ICustomDataType,
        )

        return {
          storeId,
          storeCode,
          storeName,
          directorId: store?.directorEmployeeInfo?.id || 0,
          directorName: [
            store?.directorEmployeeInfo?.preferredName || '',
            store?.directorEmployeeInfo?.familyName || '',
          ]
            .filter(Boolean)
            .join(' '),
          cashOverOrShort,
          ...customData,
        }
      }) || []

    return tableData
  }, [cashData, locationGroupIds, startDate, endDate])

  return { cashLocationData, cashLocationLoading }
}

export default useLocationCash
