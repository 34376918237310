import { IRouteType } from '../../types'
import aceDailyFlash from './aceDailyFlash'
import aceHome from './aceHome'
import acePeriodPurchase from './acePeriodPurchase'
import aceWeeklyFlash from './aceWeeklyFlash'

export const routes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Flash',
        collapsible: true,
        children: [
          {
            title: 'Daily Flash',
            link: '/:brand/daily_flash',
          },
          {
            title: 'Weekly Flash',
            link: '/:brand/weekly_flash',
          },
          {
            title: 'Period Flash',
            link: '/:brand/period_flash',
          },
        ],
      },
    ],
  },
  {
    title: 'Store',
    children: [
      {
        title: 'Home',
        link: '/:brand/scorecard',
      },
    ],
  },
]

export default [aceDailyFlash, aceWeeklyFlash, acePeriodPurchase, aceHome]
