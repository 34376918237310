import Main from './Main'

interface IProps {
  numberOfStars: number
}

function Stars({ numberOfStars }: IProps) {
  return <Main numberOfStars={numberOfStars} />
}

export default Stars
