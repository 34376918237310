import _ from 'lodash'
import { useMemo } from 'react'

import { useFetchCustomizedReportData } from 'pared/components/CustomizedReport'
import { IDateRange } from 'pared/data/getDateRanges'
import { getLocationCode, getLocationName } from 'pared/utils/location'

import { CORPORATE_INVENTORY_CUSTOMIZED_REPORT_NAME } from '../constants'
import useConfig from './useInventoryConfig'
import { INVENTORY_TIME_TYPE } from './useTimeFilter'

const useFetchSelectedInventoryReportData = (
  locationGroupId: number,
  timeFilterValue: string,
  dateRange: IDateRange | null,
) => {
  const config = useConfig()
  const queryVersionSuffix = config.queryVersion
    ? `_V${config.queryVersion}`
    : ''
  const customizedReportName = `${CORPORATE_INVENTORY_CUSTOMIZED_REPORT_NAME}${queryVersionSuffix}`

  let inventoryType = ''
  let startDate = dateRange?.startDateStr || ''
  let endDate = dateRange?.endDateStr || ''

  switch (timeFilterValue) {
    case INVENTORY_TIME_TYPE.PERIOD:
      inventoryType = 'INVENTORY_PERIOD'
      break
    case INVENTORY_TIME_TYPE.WEEKLY:
      inventoryType = 'INVENTORY_WEEKLY'
      break
    case INVENTORY_TIME_TYPE.DAILY:
      inventoryType = 'INVENTORY_DAILY'
      break
    default:
      inventoryType = ''
  }

  let inputParams = null
  if (startDate && endDate) {
    inputParams = {
      locationGroupId,
      startDate,
      endDate,
      otherParams: {
        inventoryType,
      },
    }
  }

  const { reportDefinition, reportResult, isLoading } =
    useFetchCustomizedReportData(customizedReportName, inputParams)

  return useMemo(() => {
    // make sure randomize the location info for demo
    const dynamicColumns = reportResult?.dynamicColumns || {}
    const formattedDynamicColumns = {} as any
    for (const key in dynamicColumns) {
      formattedDynamicColumns[key] = _.map(
        dynamicColumns[key],
        (detail: any) => {
          const locationId = detail?.locationId
          const locationCode = getLocationCode(locationId, '')
          const locationName = getLocationName(locationId, '')
          const header =
            locationId && locationCode && locationName
              ? [locationCode, locationName].join(' - ')
              : detail.header
          return {
            ...detail,
            header,
          }
        },
      )
    }

    const modifiedReportResult = reportResult
      ? {
          ...reportResult,
          dynamicColumns: formattedDynamicColumns,
        }
      : reportResult

    return {
      reportDefinition,
      isLoading,
      reportResult: modifiedReportResult,
    }
  }, [
    locationGroupId,
    timeFilterValue,
    reportDefinition,
    reportResult,
    isLoading,
  ])
}

export default useFetchSelectedInventoryReportData
