import { gql } from '@apollo/client'

export const MUTATION_REGISTER_USER = gql`
  mutation RegisterUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $roleId: Int!
    $locationId: Int
    $locationAccessGroupId: Int
    $locationCode: String
    $employeeId: Int
  ) {
    registerUserV2(
      input: {
        iFirstName: $firstName
        iLastName: $lastName
        iEmail: $email
        iPassword: $password
        iRoleId: $roleId
        iLocationId: $locationId
        iLocationAccessGroupId: $locationAccessGroupId
        iLocationCode: $locationCode
        iEmployeeId: $employeeId
      }
    ) {
      clientMutationId
    }
  }
`

export const QUERY_GET_ACCESSIBLE_BRANDS = gql`
  query GetAccessibleBrandsForUser {
    getAccessibleBrandsForUser {
      nodes {
        id
        name
      }
    }
  }
`

export const QUERY_GET_ROLES_FOR_BRAND = gql`
  query GetRolesForBrand($brandId: Int!) {
    getRolesForBrand(iBrandId: $brandId) {
      nodes {
        id
        name
      }
    }
  }
`

export const QUERY_GET_LOCATIONS_FOR_BRAND = gql`
  query GetLocationsForBrand($brandId: Int!) {
    getLocationsForBrand(iBrandId: $brandId) {
      nodes {
        id
        name
        code
      }
    }
  }
`

export const QUERY_GET_LOCATION_ACCESS_GROUPS_FOR_BRAND = gql`
  query GetLocationAccessGroupsForBrand($brandId: Int!) {
    getLocationAccessGroupsForBrand(iBrandId: $brandId) {
      nodes {
        id
        name
        type
      }
    }
  }
`

export const QUERY_GET_EMPLOYEE_BY_ID = gql`
  query GetEmployeeById($id: Int!) {
    getEmployeeById(iId: $id) {
      nodes {
        id
        externalId
        givenName
        familyName
        preferredName
        phone
        workEmail
        locationId
        locationName
        brandId
      }
    }
  }
`
