import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

export interface IConfig {
  includeVendor?: boolean
  shouldHideCogs?: boolean
  hasSummaryCategories?: boolean
}

const defaultConfig = {
  includeVendor: false,
  shouldHideCogs: false,
  hasSummaryCategories: false,
}

const useConfig = (): IConfig => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'viking_bk':
      case 'cava':
        return {
          includeVendor: true,
        }
      case 'jsc_bk':
      case 'jsc_bk_nso':
      case 'timoney_bk':
      case 'irmg_bk':
      case 'mwb':
      case 'burgerworks':
        return {
          shouldHideCogs: true,
        }
      case 'ghai_pop':
        return {
          hasSummaryCategories: true,
        }
      default:
        return defaultConfig
    }
  }, [brand])
}
export default useConfig
