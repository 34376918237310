import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const burgerworksWeeklySalesConfigs = {
  date: 'string',
  netSalesWeeklyAverage: 'price',
} as const

const useBurgerworksWeeklySales = buildMetricValuesHook(
  {
    codes: ['net_sales_weekly_average'],
  },
  'business_week',
)

export default useBurgerworksWeeklySales
