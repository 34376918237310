import { useQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useBrands from 'pared/layouts/hooks/useBrands'
import { getLocationCode, getLocationName } from 'pared/utils/location'

interface ILocationInfo {
  id: number
  displayName: string
  doName: string
  locationGroups:
    | {
        type: string
        name: string
        id: number
      }[]
    | null
}

interface IGetLocationInfoNode {
  id: number
  code: string
  name: string
  directorEmployeeInfo: {
    id: number
    givenName: string
    familyName: string
    preferredName: string
  } | null
  locationGroups:
    | {
        type: string
        name: string
        id: number
      }[]
    | null
}

interface IGetLocationInfo {
  listLocationDetails: {
    nodes: IGetLocationInfoNode[]
  }
}

interface IGetLocationInfoVariables {
  iFilter: {
    location_ids: number[]
  }
}

const GET_LOCATION_INFO = gql`
  query GetLocationInfo($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        directorEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        locationGroups
      }
    }
  }
`

const useStoreSubtitle = () => {
  const { brand } = useBrands()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery<
    IGetLocationInfo,
    IGetLocationInfoVariables
  >(GET_LOCATION_INFO, {
    variables: {
      iFilter: {
        location_ids: groupFilter?.ids || [],
      },
    },
    skip: !groupFilter,
  })

  const locationInfo = useMemo((): ILocationInfo | null => {
    const nodes = data?.listLocationDetails?.nodes || []

    if (nodes.length === 0) {
      return null
    }

    const location = nodes[0]
    const locationCode = getLocationCode(location.id, location.code || '')
    const locationName = getLocationName(location.id, location.name || '')

    return {
      id: location.id,
      displayName: [locationCode, locationName].join(' - '),
      doName: location.directorEmployeeInfo
        ? `${location.directorEmployeeInfo.preferredName} ${location.directorEmployeeInfo.familyName}`
        : '-',
      locationGroups: location.locationGroups,
    }
  }, [data])

  return {
    data: useMemo((): string[] | null => {
      if (!locationInfo) return null

      switch (brand) {
        case 'ghai_pop': {
          const locationGroup = locationInfo?.locationGroups?.find(
            (l) => l.type === 'Area Director',
          )

          return [
            `District Manager: ${locationInfo?.doName || '-'}`,
            `Area Director: ${locationGroup?.name || '-'}`,
          ]
        }

        case 'bibibop': {
          return [`District Manager: ${locationInfo?.doName || '-'}`]
        }

        default:
          return null
      }
    }, [locationInfo]),
    loading,
  }
}

export default useStoreSubtitle
