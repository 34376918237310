import _ from 'lodash'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import { EDDIE_MERLOTS_BRAND_CODE } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { getBrandSettings, getBusinessLabel } from 'pared/customer'
import { IDateRange } from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'
import DirectorFilterSelector from 'pared/pages/StoreList/DirectorFilterSelector'

import BreakdownTable from './BreakdownTable'
import Chart from './Chart'
import ProductivityCoefficientChart from './ProductivityCoefficientChart'
import useSalesmanshipConfig from './hooks/useSalesmanshipConfig'
import { IGlobalSalesmanshipKpi } from './index'

interface IProps {
  allDirectors: IDirector[]
  selectedDirector: IDirector | null
  navParams: INavParams
  onDirectorChange: (directorEmployeeId: number) => Promise<void>
  selectedDateRange: IDateRange | null
  globalSalesmanshipKpiSummary: IGlobalSalesmanshipKpi
  itemizedKpis: IDetailTeamKpi[]
  unfilledManagerialPositionOption: string
  onSelectUnfilledManagerialPositionOption: (event: any) => void
  performanceOption: string
  onSelectPerformanceOption: (event: any) => void
}

interface IKpiDiv {
  book?: boolean
}

function WebSmallMain({
  navParams,
  allDirectors,
  selectedDirector,
  onDirectorChange,
  selectedDateRange,
  itemizedKpis,
  globalSalesmanshipKpiSummary,
}: IProps) {
  const brandSettings = getBrandSettings()
  const { isQsr, isLegacy } = brandSettings
  const salesmanshipLabels = brandSettings.labels.salesmanship
  const salesmanshipConfig = useSalesmanshipConfig()

  const appetizerPpa = _.get(globalSalesmanshipKpiSummary, 'appetizerPpa', '')
  const checkAverage = _.get(globalSalesmanshipKpiSummary, 'checkAverage', '')
  const dessertPpa = _.get(globalSalesmanshipKpiSummary, 'dessertPpa', '')
  const grossSales = _.get(globalSalesmanshipKpiSummary, 'grossSales', '')
  const grossSalesPerLaborHour = _.get(
    globalSalesmanshipKpiSummary,
    'grossSalesPerLaborHour',
    '',
  )
  const lbwPpa = _.get(globalSalesmanshipKpiSummary, 'lbwPpa', '')
  const ppa = _.get(globalSalesmanshipKpiSummary, 'ppa', '')
  const sosAvgTime = _.get(globalSalesmanshipKpiSummary, 'sosAvgTime', '')

  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)
  let breakdownTitle = 'BREAKDOWN BY STORE'
  if (selectedDirctorEmployeeId === -2) {
    // breakdownByDirectors
    breakdownTitle = `BREAKDOWN BY ${getBusinessLabel(
      'director',
    ).toUpperCase()}`
  }

  return (
    <>
      <SelectorContainer>
        <DirectorFilterSelector
          allDirectors={allDirectors}
          selectedDirector={selectedDirector}
          onDirectorChange={onDirectorChange}
          width={'87vw'}
        />
        <DateRangeSelector navParams={navParams} />
      </SelectorContainer>
      <MainContainer>
        <PageTitleDiv>Systemwide Salesmanship</PageTitleDiv>
        {isQsr || !isLegacy ? null : (
          <SubtitleContainer>Main Dining and Bar Sales Only</SubtitleContainer>
        )}
        {brandSettings.brand === EDDIE_MERLOTS_BRAND_CODE ? (
          <SubtitleContainer>
            This page uses CrunchTime data, so some locations may not be
            included yet
          </SubtitleContainer>
        ) : null}
        <VerticalSpacer30px />

        <FullScreenTable>
          <thead>
            <tr>
              <th>KPIs</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isQsr || !isLegacy ? null : (
              <tr>
                <td>{salesmanshipLabels.checkAvg}</td>
                <BoldNumTd>{checkAverage}</BoldNumTd>
              </tr>
            )}
            <tr>
              <td>{salesmanshipLabels.totalPpa}</td>
              <BoldNumTd>{ppa}</BoldNumTd>
            </tr>
            <tr>
              <td>{salesmanshipLabels.appetizerPpa}</td>
              <BoldNumTd>{appetizerPpa}</BoldNumTd>
            </tr>
            <tr>
              <td>{salesmanshipLabels.lbwPpa}</td>
              <BoldNumTd>{lbwPpa}</BoldNumTd>
            </tr>
            <tr>
              <td>{salesmanshipLabels.dessertPpa}</td>
              <BoldNumTd>{dessertPpa}</BoldNumTd>
            </tr>
            <tr>
              <td>{salesmanshipLabels.salesPerLaborHour}</td>
              <BoldNumTd>{grossSalesPerLaborHour}</BoldNumTd>
            </tr>
            {isLegacy ? (
              <tr>
                <td>{salesmanshipLabels.grossSales}</td>
                <BoldNumTd>{grossSales}</BoldNumTd>
              </tr>
            ) : null}
            {isQsr ? (
              <tr>
                <td>{salesmanshipLabels.speedOfServiceAvg}</td>
                <BoldNumTd>{sosAvgTime}</BoldNumTd>
              </tr>
            ) : null}
          </tbody>
        </FullScreenTable>

        <VerticalSpacer40px />

        <SectionTitleDiv>SALESMANSHIP CHART</SectionTitleDiv>

        <ScrollableTableContainer>
          <ScrollableTable>
            <Chart
              navParams={navParams}
              selectedDateRange={selectedDateRange}
              selectedDirector={selectedDirector}
            />
          </ScrollableTable>
          {salesmanshipConfig['productivityCoefficient']?.isVisible ? (
            <ScrollableTable>
              <ProductivityCoefficientChart />
            </ScrollableTable>
          ) : null}
        </ScrollableTableContainer>

        <VerticalSpacer60px />

        <SectionTitleDiv>{breakdownTitle}</SectionTitleDiv>

        <ScrollableTableContainer>
          <ScrollableTable>
            <BreakdownTable
              itemizedKpis={itemizedKpis}
              selectedDirector={selectedDirector}
            />
          </ScrollableTable>
        </ScrollableTableContainer>

        <VerticalSpacer80px />
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  padding: 30px 25px 50px 25px;
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;
`

const ScrollableTableContainer = styled.div`
  overflow-x: scroll;
  border: 1px solid;
  padding-left: 30px;
  width: 90vw;
`

const ScrollableTable = styled.div`
  width: 850px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
`

const PageTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 0;
`

const BoldNumTd = styled.td`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
`

const FullScreenTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};
  text-align: right;

  th {
    font-family: Lexend-SemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  td,
  th {
    vertical-align: middle;
    padding: 8px 0;
  }

  th:first-child {
    text-align: left;
  }

  td:first-child {
    text-align: left;
  }

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  thead::after {
    content: '';
    display: block;
    height: 10px;
    width: 100%;
    background: transparent;
  }
`

const FilterContainer = styled.div`
  float: right;
`

const ManagerPerformanceFilterContainer = styled(FilterContainer)`
  margin-top: 15px;
`

const VerticalSpacer30px = styled.div`
  width: 100%;
  height: 30px;
`

const VerticalSpacer80px = styled.div`
  height: 80px;
`

const VerticalSpacer40px = styled.div`
  width: 100%;
  height: 40px;
`

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 20px 0;
`

const VerticalSpacer60px = styled.div`
  width: 100%;
  height: 60px;
`

const SelectorContainer = styled.div`
  padding: 10px 25px 10px 25px;
  background-color: rgb(196, 196, 196);
`

export default WebSmallMain
