import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import { useRef } from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import CustomizedReport from 'pared/components/CustomizedReport'
import {
  PERFORMANCE_OPTIONS,
  UNFILLED_MANAGERIAL_POSITION_OPTIONS,
} from 'pared/constants'
import COLORS from 'pared/constants/colors'
import { getBusinessLabel } from 'pared/customer'
import { IDateRange } from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'
import DirectorFilterSelector from 'pared/pages/StoreList/DirectorFilterSelector'
import { hasPrivilegeToViewEmployeeFeedback } from 'pared/utils/user'

import BreakdownTable from './BreakdownTable'
import Chart from './Chart'
import EmployeesTermChart from './EmployeesTermChart'
import ManagerPerformanceTable from './ManagerPerformanceTable'
import UnfilledManagerialPositionTable from './UnfilledManagerialPositionTable'
import AvgHourlyRatesChart from './avgHourlyRatesChart'
import useTeamConfig from './hooks/useTeamConfig'
import { IDetailTeamKpi, IUnfilledManagerialPosition } from './index'
import { IGlobalCurrentTeamKpis } from './index'

interface IProps {
  allDirectors: IDirector[]
  selectedDirector: IDirector
  navParams: INavParams
  onDirectorChange: (directorEmployeeId: number) => void
  selectedDateRange: IDateRange | null
  globalCurrentTeamKpis: IGlobalCurrentTeamKpis
  itemizedKpis: IDetailTeamKpi[]
  unfilledManagerialPositions: IUnfilledManagerialPosition[]
  unfilledManagerialPositionOption: string
  onSelectUnfilledManagerialPositionOption: (event: any) => void
  performanceOption: string
  onSelectPerformanceOption: (event: any) => void
  managerReviews: IManagerReview[]
  locationGroupIds: number[] | null
  locationGroupTypes: string[] | null
  selectedLocationGroupId: number
}

interface IKpiDiv {
  book?: boolean
}

function WebLargeMain({
  navParams,
  allDirectors,
  selectedDirector,
  onDirectorChange,
  selectedDateRange,
  globalCurrentTeamKpis,
  itemizedKpis,
  unfilledManagerialPositions,
  unfilledManagerialPositionOption,
  onSelectUnfilledManagerialPositionOption,
  performanceOption,
  onSelectPerformanceOption,
  managerReviews,
  locationGroupIds,
  locationGroupTypes,
  selectedLocationGroupId,
}: IProps) {
  const teamConfig = useTeamConfig()
  const unfilledManagerialPositionRef = useRef(null)
  const managerReviewRef = useRef(null)

  const isTeamV2 = teamConfig.isTeamV2 || false
  const shouldShowSensitiveData = hasPrivilegeToViewEmployeeFeedback()
  const managerPerformancetable = shouldShowSensitiveData ? (
    <>
      <ManagerPerformanceTopContainer>
        <MangerPerformanceLeftContainer>
          <ManagerPerformanceTitle>Manager Performance</ManagerPerformanceTitle>
          <Subtitle>
            Performance reviews of managers from the past rolling 90 days
          </Subtitle>
        </MangerPerformanceLeftContainer>
        <ManagerPerformanceFilterContainer>
          <FilterText>Filter by</FilterText>
          <TableFormControl
            variant="outlined"
            margin="dense"
            style={{ width: '300px' }}
          >
            <TableSelect
              value={performanceOption}
              onChange={onSelectPerformanceOption}
            >
              <MenuItem value={PERFORMANCE_OPTIONS.PROMOTE_READY}>
                Promote Ready
              </MenuItem>
              <MenuItem value={PERFORMANCE_OPTIONS.UPGRADE}>Upgrade</MenuItem>
              <MenuItem value={PERFORMANCE_OPTIONS.DEVELOPMENT_NEEDED}>
                Development Needed
              </MenuItem>
              <MenuItem value={PERFORMANCE_OPTIONS.SOLID_PERFORMER}>
                Solid Performer
              </MenuItem>
              <MenuItem value={PERFORMANCE_OPTIONS.HIPO}>HiPo</MenuItem>
              <MenuItem
                value={PERFORMANCE_OPTIONS.NO_FEEDBACK_IN_THE_PAST_90_DAYS}
              >
                No Feedback in the Past 90 Days
              </MenuItem>
            </TableSelect>
          </TableFormControl>
        </ManagerPerformanceFilterContainer>
      </ManagerPerformanceTopContainer>
      <VerticalSpacer30px />
      <ManagerPerformanceTable
        managerReviews={managerReviews}
        managerReviewRef={managerReviewRef}
      />
    </>
  ) : null

  const managerParText = _.get(globalCurrentTeamKpis, 'managerParText', '')
  const salariedTurnoverText = _.get(
    globalCurrentTeamKpis,
    'salariedTurnoverText',
    '',
  )
  const hourlyTurnoverText = _.get(
    globalCurrentTeamKpis,
    'hourlyTurnoverText',
    '',
  )
  const annualizedHourlyTurnoverRate = _.get(
    globalCurrentTeamKpis,
    'annualizedHourlyTurnoverRate',
    '',
  )
  const annualizedSalariedTurnoverRate = _.get(
    globalCurrentTeamKpis,
    'annualizedSalariedTurnoverRate',
    '',
  )

  const breakdownType = locationGroupTypes?.[0] || null
  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)
  let breakdownTitle = `BREAKDOWN BY ${getBusinessLabel('store').toUpperCase()}`
  if (selectedDirctorEmployeeId === -2) {
    // breakdownByDirectors
    breakdownTitle = `BREAKDOWN BY ${getBusinessLabel(
      'director',
    ).toUpperCase()}`
  } else if (breakdownType) {
    breakdownTitle = `BREAKDOWN BY ${breakdownType}`.toUpperCase()
  }

  return (
    <MainContainer>
      <PageHeaderContainer>
        <LeftHeaderContainer>
          <PageHeaderLineContainer>
            <PageLeftHeader>Systemwide Team</PageLeftHeader>
            <SelectorContainer>
              <DirectorFilterSelectorContainer>
                <DirectorFilterSelector
                  allDirectors={allDirectors}
                  selectedDirector={selectedDirector}
                  onDirectorChange={onDirectorChange}
                />
              </DirectorFilterSelectorContainer>
            </SelectorContainer>
          </PageHeaderLineContainer>
        </LeftHeaderContainer>
      </PageHeaderContainer>

      <TeamKpiTable>
        <thead>
          <tr>
            <th colSpan={5}>KPIs</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {!teamConfig.managerPar.isVisible ? null : (
              <td>
                <KpiValueDiv book={managerParText === 'loading'}>
                  {managerParText}
                </KpiValueDiv>
                <KpiNameDiv>Manager Par</KpiNameDiv>
              </td>
            )}
            <td>
              <KpiValueDiv book={salariedTurnoverText === 'loading'}>
                {salariedTurnoverText}
              </KpiValueDiv>
              <KpiNameDiv>
                <div>Salaried Turnover</div>
                <div>in {globalCurrentTeamKpis.month}</div>
              </KpiNameDiv>
            </td>
            <td>
              <KpiValueDiv book={hourlyTurnoverText === 'loading'}>
                {hourlyTurnoverText}
              </KpiValueDiv>
              <KpiNameDiv>
                <div>Hourly Turnover</div>
                <div>in {globalCurrentTeamKpis.month}</div>
              </KpiNameDiv>
            </td>
            <td>
              <KpiValueDiv book={annualizedHourlyTurnoverRate === 'loading'}>
                {annualizedHourlyTurnoverRate}
              </KpiValueDiv>
              <KpiNameDiv>
                <div>Annualized Hourly</div>
                <div>Turnover</div>
              </KpiNameDiv>
            </td>
            <td>
              <KpiValueDiv book={annualizedSalariedTurnoverRate === 'loading'}>
                {annualizedSalariedTurnoverRate}
              </KpiValueDiv>
              <KpiNameDiv>
                <div>Annualized Salaried</div>
                <div>Turnover</div>
              </KpiNameDiv>
            </td>
          </tr>
        </tbody>
      </TeamKpiTable>

      <VerticalSpacer60px />

      <Chart
        navParams={navParams}
        selectedDateRange={selectedDateRange}
        selectedDirector={selectedDirector}
      />

      <VerticalSpacer60px />

      {!teamConfig['avgHourlyRate']?.isVisible ? null : (
        <>
          <SectionTitleDiv>AVERAGE HOURLY RATES</SectionTitleDiv>

          <VerticalSpacer60px />

          <AvgHourlyRatesChart
            locationGroupId={selectedDirector?.locationGroupId || -1}
          />

          <VerticalSpacer60px />
        </>
      )}

      {!teamConfig.roleAvgPayrateTable?.isVisible ? null : (
        <>
          <SectionTitleDiv>AVERAGE PAYRATE {breakdownTitle}</SectionTitleDiv>
          <CustomizedReport
            reportName={
              locationGroupTypes
                ? 'LIST_LOCATION_GROUP_AVG_PAYRATE_TABLE'
                : 'LIST_LOCATION_AVG_PAYRATE_TABLE'
            }
            inputParams={{
              locationGroupId: selectedLocationGroupId,
              locationGroupIds: locationGroupIds,
              locationGroupTypes: [breakdownType || ''],
            }}
          />
          <VerticalSpacer60px />
        </>
      )}

      <SectionTitleDiv>TURNOVER {breakdownTitle}</SectionTitleDiv>
      <BreakdownTable
        itemizedKpis={itemizedKpis}
        selectedDirector={selectedDirector}
        breakdownType={breakdownType}
      />

      <VerticalSpacer60px />
      {teamConfig.newHireTurnover?.isVisible && !isTeamV2 ? (
        <EmployeesTermChart locationGroupId={selectedLocationGroupId} />
      ) : null}

      {teamConfig.unfilledManagerialPositions.isVisible ? (
        <>
          <VerticalSpacer60px />

          <a ref={unfilledManagerialPositionRef} />
          <a ref={managerReviewRef} />
          <TableTitleContainer>
            <SectionTitleDiv>UNFILLED MANAGERIAL POSITIONS</SectionTitleDiv>
            <FilterContainer>
              <FilterText>Filter by</FilterText>
              <TableFormControl
                variant="outlined"
                margin="dense"
                style={{ width: '200px' }}
              >
                <TableSelect
                  value={unfilledManagerialPositionOption}
                  onChange={onSelectUnfilledManagerialPositionOption}
                >
                  <MenuItem
                    value={UNFILLED_MANAGERIAL_POSITION_OPTIONS.NO_CANDIDATES}
                  >
                    No Candidates
                  </MenuItem>
                  <MenuItem
                    value={
                      UNFILLED_MANAGERIAL_POSITION_OPTIONS.FILLED_POSITIONS
                    }
                  >
                    Pending Start
                  </MenuItem>
                  <MenuItem
                    value={UNFILLED_MANAGERIAL_POSITION_OPTIONS.ALL_POSITIONS}
                  >
                    All Positions
                  </MenuItem>
                </TableSelect>
              </TableFormControl>
            </FilterContainer>
          </TableTitleContainer>

          <VerticalSpacer30px />
          <UnfilledManagerialPositionTable
            itemizedKpis={itemizedKpis}
            selectedDirector={selectedDirector}
            unfilledManagerialPositions={unfilledManagerialPositions}
            unfilledManagerialPositionOption={unfilledManagerialPositionOption}
          />
        </>
      ) : null}

      {teamConfig.managerPerformance.isVisible ? (
        <>
          <VerticalSpacer110px />
          {managerPerformancetable}
        </>
      ) : null}

      <VerticalSpacer150px />
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 1200px;
  padding: 0 0 50px 0;
`

const ManagerPerformanceTopContainer = styled.div`
  display: inline-block;
`

const TableTitleContainer = styled.div`
  height: 40px;
`

const FilterText = styled.div`
  margin-top: 17px;
  margin-right: 15px;
  float: left;
  font-family: 'Lexend-Regular';
  font-size: '14px';
  font-style: normal;
  font-weight: 400;
`

const TableFormControl = styled(FormControl)`
  background-color: white;
`

const TableSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const MangerPerformanceLeftContainer = styled.div`
  width: 800px;
  float: left;
`

const Title = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0 0;
  float: left;
`

const ManagerPerformanceTitle = styled(Title)`
  width: 800px;
`

const Subtitle = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};
`

const FilterContainer = styled.div`
  float: right;
`

const ManagerPerformanceFilterContainer = styled(FilterContainer)`
  margin-top: 15px;
`

const VerticalSpacer30px = styled.div`
  width: 100%;
  height: 30px;
`

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0 0;
  display: flex;
`

const VerticalSpacer60px = styled.div`
  width: 100%;
  height: 60px;
`

const VerticalSpacer110px = styled.div`
  width: 100%;
  height: 110px;
`

const VerticalSpacer150px = styled.div`
  width: 100%;
  height: 150px;
`

const TeamKpiTable = styled.table`
  width: 700px;
  border-collapse: collapse;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  th {
    font-family: Lexend-SemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-align: left;
    padding: 5px 8px;
  }

  td {
    text-align: center;
    vertical-align: top;
  }
`

const KpiValueDiv = styled.div<IKpiDiv>`
  width: 100%;
  font-family: ${(props) =>
    props.book ? 'Lexend-Regular' : 'Lexend-SemiBold'};
  font-size: ${(props) => (props.book ? '18px' : '24px')};
  font-style: normal;
  font-weight: ${(props) => (props.book ? '400' : '700')};
  padding: 25px 0 5px 0;
`

const KpiNameDiv = styled.div`
  width: 100%;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const PageHeaderContainer = styled.div`
  padding: 0 0 50px 0;
  margin: 0 0 50px 0;
`

const LeftHeaderContainer = styled.div`
  float: left;
`

const PageHeaderLineContainer = styled.div`
  display: inline-block;
  width: 1200px;
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0 0;
  float: left;
`

const SelectorContainer = styled.div`
  float: right;
  display: inline-block;
`

const DirectorFilterSelectorContainer = styled.div`
  float: left;
  margin-right: 267px;
`

export default WebLargeMain
