import _ from 'lodash'
import { useEffect, useState } from 'react'

import { IDateRange } from 'pared/data/getDateRanges'

import useConfig from '../hooks/useConfig'
import Main from './Main'

const persistentPageFilters = {
  kpi: 'net_sales_budget_variance',
}

interface IProps {
  navParams: any
  selectedDateRange: IDateRange
  storeName: string
  shouldShowScorecard: boolean
}

const HistoricalPerformance = ({
  navParams,
  selectedDateRange,
  storeName,
  shouldShowScorecard,
}: IProps) => {
  const config = useConfig()
  const [kpi, setKpi] = useState<string>(config.historicalPerformanceDefaultKpi)
  const onSelectKpi = (event: any) => {
    const value = _.get(event, 'target.value', '')
    setKpi(value)
    persistentPageFilters.kpi = value
  }

  useEffect(() => {
    if (persistentPageFilters.kpi === 'score' && !shouldShowScorecard) {
      persistentPageFilters.kpi = 'net_sales_budget_variance'
      setKpi('net_sales_budget_variance')
    }
  }, [shouldShowScorecard])

  return (
    <Main
      kpi={kpi}
      onSelectKpi={onSelectKpi}
      navParams={navParams}
      selectedDateRange={selectedDateRange}
      storeName={storeName}
      shouldShowScorecard={shouldShowScorecard}
    />
  )
}

export default HistoricalPerformance
