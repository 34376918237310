export const mwbKpiDefs = {
  checkAvg: {
    key: 'checkAvg',
    name: 'Check Avg',
    kpiType: 'sales',
    salesType: 'total',
    kpiKey: 'checkAvg',
    valueType: 'cent',
    digitNum: 2,
  },
  totalPpa: {
    key: 'totalPpa',
    name: 'Total PPA',
    kpiType: 'sales',
    salesType: 'total',
    kpiKey: 'ppa',
    valueType: 'cent',
    digitNum: 2,
  },
  salesPerHour: {
    key: 'salesPerHour',
    name: 'Sales per Labor Hour',
    kpiType: 'sales',
    salesType: 'total',
    kpiKey: 'salesPerHour',
    valueType: 'cent',
    digitNum: 2,
  },
  dessertsAttachmentRate: {
    key: 'dessertsAttachmentRate',
    name: 'Desserts Attachment Rate',
    kpiType: 'sales',
    salesType: 'Desserts',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  beveragesAttachmentRate: {
    key: 'beveragesAttachmentRate',
    name: 'Beverages Attachment Rate',
    kpiType: 'sales',
    salesType: 'Beverages',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  baconAttachmentRate: {
    key: 'baconAttachmentRate',
    name: 'Bacon Attachment Rate',
    kpiType: 'sales',
    salesType: 'Bacon',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  cheeseAttachmentRate: {
    key: 'cheeseAttachmentRate',
    name: 'Cheese Attachment Rate',
    kpiType: 'sales',
    salesType: 'Cheese',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  jalapenoAttachmentRate: {
    key: 'jalapenoAttachmentRate',
    name: 'Jalapeno Attachment Rate',
    kpiType: 'sales',
    salesType: 'Jalapeno',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
}
