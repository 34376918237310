import moment from 'moment'
import { useMemo } from 'react'

import useFetchCustomizedReportData from 'pared/components/CustomizedReport/hooks/useFetchCustomizedReportData'

interface IBunForecastType {
  year: number
  week: number
  values: number[]
}

// TODO: make it more generalized for other customers
const REPORT_NAME = 'IRMG_LOCATION_BUNS_USAGE_CALENDAR'

const useCustomizedCalendarData = (locationId: number) => {
  const todayDateStr = moment().format('YYYY-MM-DD')
  const { reportDefinition, reportResult, isLoading } =
    useFetchCustomizedReportData(REPORT_NAME, {
      locationId,
      todayDate: todayDateStr,
      queryDate: todayDateStr,
    })

  return useMemo(() => {
    return {
      reportDefinition,
      isLoading,
      calendarData: reportResult?.calendarData || [],
    }
  }, [locationId, reportDefinition, reportResult, isLoading])
}

export default useCustomizedCalendarData
