import IconButton from '@material-ui/core/IconButton'
import * as d3 from 'd3-hierarchy'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { track } from 'pared/analytics/dateRangeSelector'
import DialogCloseIcon from 'pared/images/basic/dialog/dialogClose.svg'

import { IDateDataType } from '../variables'
import Item from './Item'
import useDateChange from './hooks/useDateChange'

interface IPropsType
  extends Pick<
    IDateDataType,
    'value' | 'options' | 'setDate' | 'startDate' | 'endDate'
  > {
  onClose: () => void
}

const Root = styled.div`
  display: flex;
  position: relative;
  padding: 50px 0px 45px 0px;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  width: 100%;
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0px 0px 30px 0px;
  text-align: center;
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
`

const ResetToLatestPeriod = styled.span`
  margin-top: 5px;
  font-family: Lexend-Regular;
  color: #07a4c7;
  text-decoration: underline;
  cursor: pointer;
`

const ConfirmButton = styled.button`
  margin: 40px 2px 0px;
  padding: 15px 15px 15px 15px;
  background-color: #07a4c7;
  border: none;
  color: white;
  width: 250px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: Lexend-Regular;
  font-size: 14px;
  cursor: pointer;
`

const Select = ({
  onClose,
  value: currentValue,
  options,
  setDate,
  startDate,
  endDate,
}: IPropsType) => {
  const [value, setValue] = useState(currentValue)
  const onConfirm = useCallback(() => {
    setDate(value)
    onClose()
  }, [onClose, value, setDate])
  const lastPeriod = useMemo(
    () =>
      [...options].find(
        (o) => 'type' in o && o.type === 'period',
      ) as IDateDataType['value'],
    [options],
  )
  const onResetToLatestPeriod = useCallback(() => {
    if (!lastPeriod) return

    track.resetToCurrentPeriodButtonClicked()
    setValue(lastPeriod)
  }, [lastPeriod, setValue])
  const optionsTree: d3.HierarchyNode<IDateDataType['options'][number]> =
    useMemo(
      () =>
        d3.stratify<IDateDataType['options'][number]>()(
          options.map((o) =>
            'type' in value &&
            ['custom_date', 'custom_date_range'].includes(value.type) &&
            'type' in o &&
            ['custom_date', 'custom_date_range'].includes(o.type)
              ? value
              : o,
          ),
        ),
      [options, value],
    )
  const currentOptionsTree = useMemo(
    () => optionsTree.find((o) => _.isEqual(o.data, value)) || null,
    [optionsTree, value],
  )
  const dateChange = useDateChange(optionsTree, currentOptionsTree, setValue)

  return (
    <Root>
      <Title>Select a Time Frame</Title>

      <StyledIconButton aria-label="close" onClick={onClose}>
        <img src={DialogCloseIcon} alt="close" />
      </StyledIconButton>

      {currentOptionsTree && (
        <Item
          optionsTree={currentOptionsTree}
          onChange={dateChange}
          startDate={startDate}
          endDate={endDate}
        />
      )}

      {!lastPeriod ? null : (
        <ResetToLatestPeriod onClick={onResetToLatestPeriod}>
          Reset to Current Period
        </ResetToLatestPeriod>
      )}

      <ConfirmButton onClick={onConfirm}>CONFIRM</ConfirmButton>
    </Root>
  )
}

export default Select
