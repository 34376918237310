import { gql } from '@apollo/client'

export interface IRevenueCenterNodeType {
  id: number
  revenueCenter: string
}

export interface IDayPartNodeType {
  id: number
  dayPart: string
}

export interface IListItemsType {
  listLocationRevenueCenter: {
    nodes: IRevenueCenterNodeType[]
  }
  listLocationDayPart: {
    nodes: IDayPartNodeType[]
  }
}

export interface IListItemsVariableTypes {
  iStartDate: string
  iEndDate: string
  iFilter: any
}

export const LIST_LOCATION_REVENUE_CENTER_AND_DAY_PART = gql`
  query ListLocationRevenueCenterAndDayPart(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationRevenueCenter(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        id
        revenueCenter
      }
    }
    listLocationDayPart(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        id
        dayPart
      }
    }
  }
`
