import React from 'react'
import styled from 'styled-components'

import CustomizedReport from 'pared/components/CustomizedReport'
import { getBrandSettings } from 'pared/customer'
import useGetDateRange from 'pared/hooks/useGetDateRange'

interface IProps {
  locationGroupId: number | undefined
}

const ChickenInventoryTable = ({ locationGroupId }: IProps) => {
  const dateRange = useGetDateRange()
  const { brandLocationGroupId } = getBrandSettings()
  const inputParams = {
    startDate: dateRange?.startDateStr,
    endDate: dateRange?.endDateStr,
    locationGroupId: locationGroupId || brandLocationGroupId,
  }

  return (
    <Container>
      <SubHeader>Chicken Inventory</SubHeader>
      <CustomizedReport
        reportName="CORPORATE_FLASH_CHICKEN_INVENTORY"
        inputParams={inputParams}
      />
    </Container>
  )
}

const Container = styled.div`
  margin: 0;
  padding: 80px 0;
  overflow: scroll hidden;
`

const SubHeader = styled.div`
  margin: 0px;
  padding: 0 0 30px 0;
  font-family: Lexend-SemiBold;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
`

export default React.memo(ChickenInventoryTable)
