import { IApiDataType } from '../types'
import useFarwestPmixCategoryData, {
  farwestPmixCategoryDataConfigs,
} from './farwestPmix/useFarwestPmixCategoryData'
import useFarwestPmixItemData, {
  farwestPmixItemDataConfigs,
} from './farwestPmix/useFarwestPmixItemData'
import useFarwestPmixSubcategoryData, {
  farwestPmixSubcategoryDataConfigs,
} from './farwestPmix/useFarwestPmixSubcategoryData'
import useAcePopDailyFlash, {
  acePopDailyFlashConfigs,
} from './useAcePopDailyFlash'
import useAcePopDailyFlashAverage, {
  acePopDailyFlashAverageConfigs,
} from './useAcePopDailyFlashAverage'
import useAcePopPeriodPurchase, {
  acePopPeriodPurchaseConfigs,
} from './useAcePopPeriodPurchase'
import useAcePopWeeklyFlash, {
  acePopWeeklyFlashConfigs,
} from './useAcePopWeeklyFlash'
import useArbListLocationGroupPrimantiTattleCategoryRating, {
  arbListLocationGroupPrimantiTattleCategoryRatingConfigs,
} from './useArbListLocationGroupPrimantiTattleCategoryRating'
import useArbListLocationPrimantiTattleCategoryRating, {
  arbListLocationPrimantiTattleCategoryRatingConfigs,
} from './useArbListLocationPrimantiTattleCategoryRating'
import useArbPrimantiTattle, {
  arbPrimantiTattleConfigs,
} from './useArbPrimantiTattle'
import useBbbExpenses, { bbbExpensesConfigs } from './useBbbExpenses'
import useBbbFlash, { bbbFlashConfigs } from './useBbbFlash'
import {
  bbbGroupPnLConfigs,
  useBbbGroupCtrlExpenses,
  useBbbGroupFnp,
  useBbbGroupLabor,
  useBbbGroupSales,
} from './useBbbGroupPnL'
import useBbbHaccps, { bbbHaccpsConfigs } from './useBbbHaccps'
import {
  bbbPnLConfigs,
  useBbbCtrlExpenses,
  useBbbFnp,
  useBbbLabor,
  useBbbSales,
} from './useBbbPnL'
import useBbbSystemwideOverviewKpis, {
  bbbSystemwideOverviewKpisConfigs,
} from './useBbbSystemwideOverviewKpis'
import useBibibopListLocationKpis, {
  bibibopListLocationKpisConfigs,
} from './useBibibopListLocationKpis'
import useFarwestCogsTrackerCaseCounts, {
  farwestCogsTrackerCaseCountsConfigs,
} from './useFarwestCogsTrackerCaseCounts'
import useFarwestCustomizedFlash, {
  farwestCustomizedFlashConfigs,
} from './useFarwestCustomizedFlash'
import useFarwestFiveWeekItemTrendData, {
  farwestFiveWeekItemTrendDataConfigs,
} from './useFarwestFiveWeekItemTrendData'
import useFarwestFiveWeekProduceItemTrendData, {
  farwestFiveWeekProduceItemTrendDataConfigs,
} from './useFarwestFiveWeekProduceItemTrendData'
import {
  farwestFlashSalesByOrderModeConfigs,
  useFarwestAverageWeeklySalesbyOrderMode,
  useFarwestEquivalentUnitCount,
  useFarwestTotalSalesPercentbyOrderMode,
  useFarwestTotalSalesbyOrderMode,
} from './useFarwestFlashSalesByOrderMode'
import useFarwestFourWeekTrendData, {
  farwestFourWeekTrendDataConfigs,
} from './useFarwestFourWeekTrendData'
import useFarwestLastTwentyEightDaysTrendData, {
  farwestLastTwentyEightDaysTrendDataConfigs,
} from './useFarwestLastTwentyEightDaysTrendData'
import useFarwestOutliers, {
  farwestOutliersConfigs,
} from './useFarwestOutliers'
import useFarwestSalesCharts, {
  farwestSalesChartsConfigs,
} from './useFarwestSalesCharts'
import useFarwestSssCharts, {
  farwestSssChartsConfigs,
} from './useFarwestSssCharts'
import useFarwestTimeClock, {
  farwestTimeClockConfigs,
} from './useFarwestTimeClock'
import useFarwestTrendDailySplh, {
  farwestTrendDailySplhConfigs,
} from './useFarwestTrendDailySplh'
import useFlash, { flashConfigs } from './useFlash'
import useIrmgBkPlaid, { irmgBkPlaidConfigs } from './useIrmgBkPlaid'
import useJscBaseballCard, {
  jscBaseballCardConfigs,
} from './useJscBaseballCard'
import useJscCorporateDevelopmentFeedback, {
  jscCorporateDevelopmentFeedbackConfigs,
} from './useJscCorporateDevelopmentFeedback'
import useJscCorporateDevelopmentPlan, {
  jscCorporateDevelopmentPlanConfigs,
} from './useJscCorporateDevelopmentPlan'
import useJscCorporateTrainingTracker, {
  jscCorporateTrainingTrackerConfigs,
} from './useJscCorporateTrainingTracker'
import useJscDevelopmentPlan, {
  jscDevelopmentPlanConfigs,
} from './useJscDevelopmentPlan'
import useJscDevelopmentTrackerFeedback, {
  jscDevelopmentTrackerFeedbackConfigs,
} from './useJscDevelopmentTrackerFeedback'
import useLfrBusinessReview, {
  lfrBusinessReviewConfigs,
} from './useLfrBusinessReview'
import useLfrListLocationBusinessReview, {
  lfrListLocationBusinessReviewConfigs,
} from './useLfrListLocationBusinessReview'
import useLfrListLocationGroupBusinessReview, {
  lfrListLocationGroupBusinessReviewConfigs,
} from './useLfrListLocationGroupBusinessReview'
import useLfrListLocationGuest, {
  lfrListLocationGuestConfigs,
} from './useLfrListLocationGuest'
import useLfrYardi, { lfrYardiConfigs } from './useLfrYardi'
import useSystemwideOverview, {
  systemwideOverviewConfigs,
} from './useSystemwideOverview'
import {
  uploadReportStatusConfigs,
  useFarwestUploadReportStatus,
  useLfrUploadGfpReportStatus,
} from './useUploadReportStatus'
import useUserEvents, { userEventsConfigs } from './useUserEvents'
import useWingitnorthDailyFlash, {
  wingitnorthDailyFlashConfigs,
} from './useWingitnorthDailyFlash'

export type IApiKeyType = keyof typeof api

export const configs = {
  lfrListLocationGuest: lfrListLocationGuestConfigs,
  lfrListLocationGroupBusinessReview: lfrListLocationGroupBusinessReviewConfigs,
  lfrListLocationBusinessReview: lfrListLocationBusinessReviewConfigs,
  lfrBusinessReview: lfrBusinessReviewConfigs,
  jscDevelopmentPlan: jscDevelopmentPlanConfigs,
  jscCorporateDevelopmentFeedback: jscCorporateDevelopmentFeedbackConfigs,
  jscCorporateDevelopmentPlan: jscCorporateDevelopmentPlanConfigs,
  jscCorporateTrainingTracker: jscCorporateTrainingTrackerConfigs,
  jscBaseballCard: jscBaseballCardConfigs,
  irmgBkPlaid: irmgBkPlaidConfigs,
  arbListLocationGroupPrimantiTattleCategoryRating:
    arbListLocationGroupPrimantiTattleCategoryRatingConfigs,
  arbListLocationPrimantiTattleCategoryRating:
    arbListLocationPrimantiTattleCategoryRatingConfigs,
  arbPrimantiTattle: arbPrimantiTattleConfigs,
  systemwideOverview: systemwideOverviewConfigs,
  jscDevelopmentTrackerFeedback: jscDevelopmentTrackerFeedbackConfigs,
  flash: flashConfigs,
  wingitnorthDailyFlash: wingitnorthDailyFlashConfigs,
  lfrYardi: lfrYardiConfigs,
  bibibopListLocationKpis: bibibopListLocationKpisConfigs,
  lfrUploadGfpReportStatus: uploadReportStatusConfigs,
  farwestUploadReportStatus: uploadReportStatusConfigs,
  userEvents: userEventsConfigs,
  farwestCustomizedFlash: farwestCustomizedFlashConfigs,
  farwestFiveWeekItemTrendData: farwestFiveWeekItemTrendDataConfigs,
  farwestFourWeekTrendData: farwestFourWeekTrendDataConfigs,
  bbbSystemwideOverviewKpis: bbbSystemwideOverviewKpisConfigs,
  farwestOutliers: farwestOutliersConfigs,
  farwestFlashCogsTrackerCaseCounts: farwestCogsTrackerCaseCountsConfigs,
  farwestTrendDailySplh: farwestTrendDailySplhConfigs,
  farwestAverageWeeklySalesbyOrderMode: farwestFlashSalesByOrderModeConfigs,
  farwestTotalSalesbyOrderMode: farwestFlashSalesByOrderModeConfigs,
  farwestTotalSalesPercentbyOrderMode: farwestFlashSalesByOrderModeConfigs,
  farwestTotalSalesPercentbyDeliveryMode: farwestFlashSalesByOrderModeConfigs,
  farwestEquivalentUnitCount: farwestFlashSalesByOrderModeConfigs,
  farwestFlashPmixCategoryData: farwestPmixCategoryDataConfigs,
  farwestFlashPmixSubcategoryData: farwestPmixSubcategoryDataConfigs,
  farwestFlashPmixItemData: farwestPmixItemDataConfigs,
  farwestFlashTimeClock: farwestTimeClockConfigs,
  farwestLastTwentyEightDaysTrendData:
    farwestLastTwentyEightDaysTrendDataConfigs,
  bbbHaccps: bbbHaccpsConfigs,
  bbbExpenses: bbbExpensesConfigs,
  acePopDailyFlash: acePopDailyFlashConfigs,
  acePopPeriodPurchase: acePopPeriodPurchaseConfigs,
  acePopDailyFlashAverage: acePopDailyFlashAverageConfigs,
  acePopWeeklyFlash: acePopWeeklyFlashConfigs,
  bbbSales: bbbPnLConfigs,
  bbbFnp: bbbPnLConfigs,
  bbbLabor: bbbPnLConfigs,
  bbbCtrlExpenses: bbbPnLConfigs,
  bbbGroupSales: bbbGroupPnLConfigs,
  bbbGroupFnp: bbbGroupPnLConfigs,
  bbbGroupLabor: bbbGroupPnLConfigs,
  bbbGroupCtrlExpenses: bbbGroupPnLConfigs,
  bbbFlash: bbbFlashConfigs,
  farwestSalesCharts: farwestSalesChartsConfigs,
  farwestSssCharts: farwestSssChartsConfigs,
  farwestFiveWeekProduceItemTrendData:
    farwestFiveWeekProduceItemTrendDataConfigs,
}

export const api = {
  lfrListLocationGuest: useLfrListLocationGuest,
  lfrListLocationGroupBusinessReview: useLfrListLocationGroupBusinessReview,
  lfrListLocationBusinessReview: useLfrListLocationBusinessReview,
  lfrBusinessReview: useLfrBusinessReview,
  jscDevelopmentPlan: useJscDevelopmentPlan,
  jscCorporateDevelopmentFeedback: useJscCorporateDevelopmentFeedback,
  jscCorporateDevelopmentPlan: useJscCorporateDevelopmentPlan,
  jscCorporateTrainingTracker: useJscCorporateTrainingTracker,
  jscBaseballCard: useJscBaseballCard,
  irmgBkPlaid: useIrmgBkPlaid,
  arbListLocationGroupPrimantiTattleCategoryRating:
    useArbListLocationGroupPrimantiTattleCategoryRating,
  arbListLocationPrimantiTattleCategoryRating:
    useArbListLocationPrimantiTattleCategoryRating,
  arbPrimantiTattle: useArbPrimantiTattle,
  systemwideOverview: useSystemwideOverview,
  jscDevelopmentTrackerFeedback: useJscDevelopmentTrackerFeedback,
  flash: useFlash,
  wingitnorthDailyFlash: useWingitnorthDailyFlash,
  lfrYardi: useLfrYardi,
  bibibopListLocationKpis: useBibibopListLocationKpis,
  lfrUploadGfpReportStatus: useLfrUploadGfpReportStatus,
  farwestUploadReportStatus: useFarwestUploadReportStatus,
  userEvents: useUserEvents,
  farwestCustomizedFlash: useFarwestCustomizedFlash,
  farwestFiveWeekItemTrendData: useFarwestFiveWeekItemTrendData,
  farwestFourWeekTrendData: useFarwestFourWeekTrendData,
  bbbSystemwideOverviewKpis: useBbbSystemwideOverviewKpis,
  farwestOutliers: useFarwestOutliers,
  farwestFlashCogsTrackerCaseCounts: useFarwestCogsTrackerCaseCounts,
  farwestTrendDailySplh: useFarwestTrendDailySplh,
  farwestAverageWeeklySalesbyOrderMode: useFarwestAverageWeeklySalesbyOrderMode,
  farwestTotalSalesbyOrderMode: useFarwestTotalSalesbyOrderMode,
  farwestTotalSalesPercentbyOrderMode: useFarwestTotalSalesPercentbyOrderMode,
  farwestEquivalentUnitCount: useFarwestEquivalentUnitCount,
  farwestFlashPmixCategoryData: useFarwestPmixCategoryData,
  farwestFlashPmixSubcategoryData: useFarwestPmixSubcategoryData,
  farwestFlashPmixItemData: useFarwestPmixItemData,
  farwestFlashTimeClock: useFarwestTimeClock,
  farwestLastTwentyEightDaysTrendData: useFarwestLastTwentyEightDaysTrendData,
  bbbHaccps: useBbbHaccps,
  bbbExpenses: useBbbExpenses,
  acePopDailyFlash: useAcePopDailyFlash,
  acePopPeriodPurchase: useAcePopPeriodPurchase,
  acePopDailyFlashAverage: useAcePopDailyFlashAverage,
  acePopWeeklyFlash: useAcePopWeeklyFlash,
  bbbSales: useBbbSales,
  bbbFnp: useBbbFnp,
  bbbLabor: useBbbLabor,
  bbbCtrlExpenses: useBbbCtrlExpenses,
  bbbGroupSales: useBbbGroupSales,
  bbbGroupFnp: useBbbGroupFnp,
  bbbGroupLabor: useBbbGroupLabor,
  bbbGroupCtrlExpenses: useBbbGroupCtrlExpenses,
  bbbFlash: useBbbFlash,
  farwestSalesCharts: useFarwestSalesCharts,
  farwestSssCharts: useFarwestSssCharts,
  farwestFiveWeekProduceItemTrendData: useFarwestFiveWeekProduceItemTrendData,
}

const useApi = (
  apiName: IApiKeyType,
): {
  data: IApiDataType
  loading: boolean
} => api[apiName]()

export default useApi
