import { useMemo } from 'react'

import NumberScale from 'pared/components/basicUi/NumberScale'
import { IColumnsType } from 'pared/components/basicUi/table'

import { IFoodListFragmentType } from '../gqls/foodList'
import { getPrice, getTotalPrice } from '../utils'

const useColumns = (
  data: IFoodListFragmentType[],
): IColumnsType<IFoodListFragmentType>[] =>
  useMemo(() => {
    const popularities = data.map(({ popularity }) => popularity)
    const min = Math.floor(Math.min(...popularities))
    const max = Math.ceil(Math.max(...popularities))

    return [
      {
        key: 'itemName',
        title: 'Name',
        minWidth: '200px',
      },
      {
        key: 'category',
        title: 'Menu Category',
        minWidth: '200px',
      },
      {
        key: 'popularity',
        title: 'Popularity',
        minWidth: '200px',
        align: 'center',
        render: (
          value: IFoodListFragmentType['popularity'],
          _: IFoodListFragmentType,
          index,
        ) => (
          <NumberScale
            number={value}
            max={max}
            min={min}
            showTitle={index === 0}
          >
            {value.toFixed(1)}
          </NumberScale>
        ),
        sorter: true,
      },
      {
        key: 'profitablity',
        title: 'Profitability',
        align: 'center',
        children: [
          {
            key: 'portionContributionMargin',
            title: 'Portion Contribution Margin',
            width: '182px',
            align: 'center',
            render: getPrice,
            sorter: true,
          },
          {
            key: 'totalContributionMargin',
            title: 'Total Contribution Margin ($)',
            width: '182px',
            align: 'center',
            render: getTotalPrice,
            sorter: true,
          },
          {
            key: 'contributionMarginPercent',
            title: 'Share of Margin (%)',
            width: '182px',
            align: 'center',
            render: (
              value: IFoodListFragmentType['contributionMarginPercent'],
            ) => `${value.toFixed(2)}%`,
            sorter: true,
          },
        ],
      },
      {
        key: 'salesMix',
        title: 'Sales Mix',
        align: 'center',
        children: [
          {
            key: 'theoreticalCostPerUnit',
            title: 'Price',
            width: '182px',
            align: 'center',
            render: getPrice,
            sorter: true,
          },
          {
            key: 'itemSales',
            title: 'Total Sales',
            width: '182px',
            align: 'center',
            render: getTotalPrice,
            sorter: true,
          },
          {
            key: 'percentOfSales',
            title: '% of Sales',
            width: '182px',
            align: 'center',
            render: (
              value: IFoodListFragmentType['contributionMarginPercent'],
            ) => `${value.toFixed(2)}%`,
            sorter: true,
          },
        ],
      },
    ]
  }, [data])

export default useColumns
