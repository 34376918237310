export const getNumber = (value: number | string) =>
  typeof value === 'string' ? parseInt(value, 10) : value

const formatPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const getPrice = (value: number | string) => {
  if (!value) return '0'

  return formatPrice.format(getNumber(value) / 100)
}

export const getTotalPrice = (
  value: number | string,
): { result: string; isNegative: boolean } => {
  if (!value) return { result: '0', isNegative: false }

  return {
    result: formatPrice
      .format(Math.round(getNumber(value) / 100))
      .replace(/\..+/, ''),
    isNegative: value < 0,
  }
}

export const numberPlus = (
  [aValue, ...a]: string[],
  [bValue, ...b]: string[],
  extra = 0,
): string[] => {
  if (!aValue && !bValue) {
    if (extra !== 0) return [extra.toString()]

    return []
  }

  const result =
    parseInt(aValue || '0', 10) + parseInt(bValue || '0', 10) + extra
  const value = result % 10

  return [value.toString(), ...numberPlus(a, b, (result - value) / 10)]
}

export const plus = (a: string, b: string) => {
  a = !a ? '0' : a
  b = !b ? '0' : b

  return numberPlus(a.split('').reverse(), b.split('').reverse())
    .reverse()
    .join('')
}
