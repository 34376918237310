import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { track } from 'pared/analytics/salesmanship'
import { FILTER_TYPE, RANKINGS_ROW_TYPE, SALES_TYPE } from 'pared/constants'
import getPeriods, { IPeriod } from 'pared/data/getPeriods'
import { getBrand } from 'pared/utils/brand'
import { toUsdString } from 'pared/utils/number'

import Main from './Main'
import {
  LIST_LOCATION_SALESMANS_BY_SALES_TYPE,
  LIST_LOCATION_SALESMANS_BY_SALES_TYPE_AND_DAYS_WORKED,
} from './gql'

export interface IStaffSale {
  employeeId: number
  dayPart: string
  firstName: string
  lastName: string
  sales: number
  salesRank: number
  salesPpa: number
  salesPpaRank: number
  roleName: string
  daysWorked: number
}

export interface IOrderBy {
  columnName: string
  isAscending: boolean
}

interface IProps {
  navParams: INavParams
  dayPart: string
  selectedPeriod: IPeriod | null
  filterType: string
  numDaysWorked: number
  dateRangeStartDate: any
  dateRangeEndDate: any
  storeTitle: string
  selectedValue: string
}

const StaffSalesTable = ({
  navParams,
  dayPart,
  selectedPeriod,
  filterType,
  numDaysWorked,
  dateRangeStartDate,
  dateRangeEndDate,
  storeTitle,
  selectedValue,
}: IProps) => {
  const storeId = navParams.storeId || ''
  const storeIdNum = parseInt(storeId, 10) || 0

  const [salesType, setSalesType] = useState<string>(SALES_TYPE.GROSS_SALES)
  const [orderBy, setOrderBy] = useState<IOrderBy>({
    columnName: 'daysWorked',
    isAscending: false,
  })

  const [
    listLocationSalesmansBySalesType,
    {
      loading: staffSalesByDateLoading,
      error: staffSalesByDateError,
      data: staffSalesDataByDate,
    },
  ] = useLazyQuery(LIST_LOCATION_SALESMANS_BY_SALES_TYPE)
  const [
    listLocationSalesmansBySalesTypeAndDaysWorked,
    {
      loading: staffSalesByDaysWorkedLoading,
      error: staffSalesByDaysWorkedError,
      data: staffSalesByDaysWorkedData,
    },
  ] = useLazyQuery(LIST_LOCATION_SALESMANS_BY_SALES_TYPE_AND_DAYS_WORKED)
  const [isStaffSalesExpanded, setIsStaffSalesExpanded] =
    useState<boolean>(false)

  let searchSalesType = SALES_TYPE.GROSS_SALES

  switch (salesType) {
    case SALES_TYPE.GROSS_SALES:
    case SALES_TYPE.TOTAL_PPA: {
      searchSalesType = SALES_TYPE.GROSS_SALES
      break
    }
    case SALES_TYPE.APP_SALES:
    case SALES_TYPE.APP_PPA: {
      searchSalesType = SALES_TYPE.APP_SALES
      break
    }
    case SALES_TYPE.LBW_SALES:
    case SALES_TYPE.LBW_PPA: {
      searchSalesType = SALES_TYPE.LBW_SALES
      break
    }
    case SALES_TYPE.DESSERT_SALES:
    case SALES_TYPE.DESSERT_PPA: {
      searchSalesType = SALES_TYPE.DESSERT_SALES
      break
    }
    default: {
      searchSalesType = SALES_TYPE.GROSS_SALES
    }
  }

  useEffect(() => {
    async function fetchData() {
      const { periods } = await getPeriods()

      let newSelectedPeriod: IPeriod | null = selectedPeriod

      if (!newSelectedPeriod) {
        newSelectedPeriod = periods[0]
      }

      if (storeIdNum) {
        switch (filterType) {
          case FILTER_TYPE.SHIFTS: {
            listLocationSalesmansBySalesTypeAndDaysWorked({
              variables: {
                locationId: storeIdNum,
                startDate: _.get(newSelectedPeriod, 'periodStart', ''),
                endDate: _.get(newSelectedPeriod, 'periodEnd', ''),
                salesType: searchSalesType,
                numDaysWorked,
                dayPart,
              },
            })
            break
          }
          case FILTER_TYPE.PERIOD: {
            listLocationSalesmansBySalesType({
              variables: {
                locationId: storeIdNum,
                startDate: _.get(newSelectedPeriod, 'periodStart', ''),
                endDate: _.get(newSelectedPeriod, 'periodEnd', ''),
                salesType: searchSalesType,
                dayPart,
              },
            })
            break
          }
          case FILTER_TYPE.DATE: {
            listLocationSalesmansBySalesType({
              variables: {
                locationId: storeIdNum,
                startDate: moment(dateRangeStartDate).format(),
                endDate: moment(dateRangeEndDate).format(),
                salesType: searchSalesType,
                dayPart,
              },
            })
            break
          }
          default: {
          }
        }
      }
    }

    fetchData()
  }, [
    salesType,
    dayPart,
    selectedPeriod,
    filterType,
    numDaysWorked,
    dateRangeEndDate,
    listLocationSalesmansBySalesType,
    listLocationSalesmansBySalesTypeAndDaysWorked,
  ])

  const orderStaffSales = (
    rawStaffsales: IStaffSale[],
    totalSalesOrPpa: string,
  ) => {
    let rankedStaffSales = []
    const originalOrderColumnName = _.get(orderBy, 'columnName', '')
    let orderColumnName = _.get(orderBy, 'columnName', '')

    switch (totalSalesOrPpa) {
      case 'sales': {
        rankedStaffSales = _.orderBy(rawStaffsales, 'sales', 'desc')
        break
      }
      case 'salesPpa': {
        rankedStaffSales = _.orderBy(rawStaffsales, 'salesPpa', 'desc')
        if (originalOrderColumnName === 'sales') {
          orderColumnName = 'salesPpa'
        }
        break
      }
      case 'speedOfService': {
        if (originalOrderColumnName === 'sales') {
          const sortType = orderBy.isAscending ? 'asc' : 'desc'
          rankedStaffSales = _.orderBy(rawStaffsales, 'sosAvgTime', sortType)
          return rankedStaffSales
        } else {
          rankedStaffSales = [...rawStaffsales]
          break
        }
      }
      default: {
        rankedStaffSales = _.orderBy(rawStaffsales, 'salesRank')
      }
    }

    const ascending = orderBy.isAscending ? 1 : -1

    rankedStaffSales = _.sortBy(rankedStaffSales, (s) => {
      const rankedValue = _.get(s, [orderColumnName], 0)

      return rankedValue * ascending
    })

    return rankedStaffSales
  }

  const ppaSalesType = [
    SALES_TYPE.TOTAL_PPA,
    SALES_TYPE.APP_PPA,
    SALES_TYPE.LBW_PPA,
    SALES_TYPE.DESSERT_PPA,
  ]

  const isSalesTypePpa = _.includes(ppaSalesType, salesType)
  let salesFilterType = ''
  if (salesType === 'speed_of_service') {
    salesFilterType = 'speedOfService'
  } else {
    salesFilterType = isSalesTypePpa ? 'salesPpa' : 'sales'
  }

  let staffSales: IStaffSale[] = []

  if (
    filterType === FILTER_TYPE.SHIFTS &&
    staffSalesByDaysWorkedData &&
    staffSalesByDaysWorkedData.listLocationSalesmansBySalesTypeAndDaysWorked &&
    Array.isArray(
      staffSalesByDaysWorkedData.listLocationSalesmansBySalesTypeAndDaysWorked
        .nodes,
    )
  ) {
    const rawAvgData = _.first(
      _.get(
        staffSalesByDaysWorkedData,
        'listLocationSalesmansBySalesTypeAndDaysWorked.nodes',
        [],
      ),
    )

    const rawStaffSales = _.get(
      staffSalesByDaysWorkedData,
      'listLocationSalesmansBySalesTypeAndDaysWorked.nodes',
      [],
    )
    staffSales = _.map(rawStaffSales, (s) => {
      return {
        employeeId: _.get(s, 'employeeId', 0),
        dayPart: _.get(s, 'dayPart', 'all'),
        firstName: _.get(s, 'firstName', ''),
        lastName: _.get(s, 'lastName', ''),
        sales: _.get(s, 'sales', 0),
        salesRank: _.get(s, 'salesRank', 1),
        salesPpa: _.get(s, 'salesPpa', 0),
        salesPpaRank: _.get(s, 'salesPpaRank', 1),
        sosAvgTime: _.get(s, 'sosAvgTime', 0),
        sosAvgTimeRank: _.get(s, 'sosAvgTimeRank', 1),
        roleName: _.get(s, 'roleName', ''),
        daysWorked: _.get(s, 'daysWorked', 0),
      }
    })
    staffSales.push({
      rowType: RANKINGS_ROW_TYPE.AVG,
      sales: _.get(rawAvgData, 'avgSales', 0),
      salesPpa: _.get(rawAvgData, 'avgSalesPpa', 0),
      sosAvgTime: _.get(rawAvgData, 'avgSosAvgTime', 0),
    })
  }

  if (
    (filterType === FILTER_TYPE.PERIOD || filterType === FILTER_TYPE.DATE) &&
    staffSalesDataByDate &&
    staffSalesDataByDate.listLocationSalesmansBySalesType &&
    Array.isArray(staffSalesDataByDate.listLocationSalesmansBySalesType.nodes)
  ) {
    const rawAvgData = _.first(
      _.get(staffSalesDataByDate, 'listLocationSalesmansBySalesType.nodes', []),
    )

    const rawStaffSales = _.get(
      staffSalesDataByDate,
      'listLocationSalesmansBySalesType.nodes',
      [],
    )
    staffSales = _.map(rawStaffSales, (s) => {
      return {
        employeeId: _.get(s, 'employeeId', 0),
        dayPart: _.get(s, 'dayPart', 'all'),
        firstName: _.get(s, 'firstName', ''),
        lastName: _.get(s, 'lastName', ''),
        sales: _.get(s, 'sales', 0),
        salesRank: _.get(s, 'salesRank', 1),
        salesPpa: _.get(s, 'salesPpa', 0),
        salesPpaRank: _.get(s, 'salesPpaRank', 1),
        sosAvgTime: _.get(s, 'sosAvgTime', 0),
        sosAvgTimeRank: _.get(s, 'sosAvgTimeRank', 1),
        roleName: _.get(s, 'roleName', ''),
        daysWorked: _.get(s, 'daysWorked', 0),
      }
    })
    staffSales.push({
      rowType: RANKINGS_ROW_TYPE.AVG,
      sales: _.get(rawAvgData, 'avgSales', 0),
      salesPpa: _.get(rawAvgData, 'avgSalesPpa', 0),
      sosAvgTime: _.get(rawAvgData, 'avgSosAvgTime', 0),
    })
  }

  let rankedStaffSales = orderStaffSales(staffSales, salesFilterType)

  let rankNum = 1

  const staffSalesRowData = _.map(rankedStaffSales, (s) => {
    const rank = rankNum
    const lastName = _.startCase(_.get(s, 'lastName', ''))
    const firstName = lastName
      ? _.startCase(_.get(s, 'firstName', ''))
      : _.get(s, 'firstName', '')
    const name = `${firstName} ${lastName}`
    const sales = isSalesTypePpa
      ? _.get(s, 'salesPpa', 0)
      : _.get(s, 'sales', 0)
    const roleName = _.get(s, 'roleName', '')
    const daysWorked = _.get(s, 'daysWorked', '')
    const salesOrPpa = isSalesTypePpa
      ? toUsdString(sales, 2)
      : toUsdString(sales, 0)
    const sosAvgTime = (_.get(s, 'sosAvgTime') || 0).toFixed(1)
    const rowType = _.get(s, 'rowType', '')

    if (rowType !== RANKINGS_ROW_TYPE.AVG) {
      rankNum++
    }

    return {
      rank,
      name,
      roleName,
      daysWorked,
      salesOrPpa,
      sosAvgTime,
      rowType,
    }
  })

  const handleSetSalesType = (newSalesType: string) => {
    track.overallStaffSalesTypeChanged(newSalesType)
    setSalesType(newSalesType)
  }

  return (
    <Main
      isLoading={staffSalesByDateLoading || staffSalesByDaysWorkedLoading}
      salesType={salesType}
      setSalesType={handleSetSalesType}
      staffSales={staffSales}
      orderStaffSales={orderStaffSales}
      isStaffSalesExpanded={isStaffSalesExpanded}
      setIsStaffSalesExpanded={setIsStaffSalesExpanded}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      salesFilterType={salesFilterType}
      isSalesTypePpa={isSalesTypePpa}
      staffSalesRowData={staffSalesRowData}
      storeTitle={storeTitle}
      selectedValue={selectedValue}
      dayPart={dayPart}
    />
  )
}

export default StaffSalesTable
