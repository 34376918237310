import { useMemo } from 'react'

import { IGroupInfoType } from '../../table/hooks/useGroupInfoColumns'
import useMadeFromScratch, {
  madeFromScratchConfigs,
} from '../../tableV2/hooks/common/useMadeFromScratch'

const useMadeFromScratchCsv = () => {
  const madeFromScratch = useMadeFromScratch()

  return useMemo(
    (): {
      data: { source: { groupInfo?: IGroupInfoType }[]; summary?: {} } | null
      loading: boolean
    } => ({
      data: { source: madeFromScratch?.data ?? null },
      loading: madeFromScratch.loading,
    }),
    [madeFromScratch],
  )
}

export { madeFromScratchConfigs }

export default useMadeFromScratchCsv
