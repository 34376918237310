import { gql } from '@apollo/client'

export interface IDetailsType {
  date: string
  savings: number
  unproductiveLabor: number
  hourlyWage: number
  projectedHours: number
  scheduledHours: number
}

export interface ILocationSchedulingByHourByDayNodeType {
  hour: number
  details: IDetailsType[]
}

export interface ILocationSchedulingByHourByDayType {
  locationSchedulingByHourByDay: {
    nodes: ILocationSchedulingByHourByDayNodeType[]
  }
}

export interface ILocationSchedulingByHourByDayVariablesType {
  iStartDate: string
  iEndDate: string
  iFilter: {
    location_ids: number[]
  }
}

export const LOCATION_SCHEDULING_BY_HOUR_BY_DAY = gql`
  query LocationSchedulingByHourByDay(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    locationSchedulingByHourByDay(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        hour
        details
      }
    }
  }
`
