export interface IPropsType {
  type: 'image'
  src: string
  alt: string
  height?: string
}

const Image = ({ src, alt, height }: IPropsType) => (
  <img height={height} src={src} alt={alt} />
)

export default Image
