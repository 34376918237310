import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { getBrandId } from 'pared/utils/brand'

import { IApiType } from '../../types'

const QUERY_NAME = 'listLocationsByFilter'

const query = gql`
  query ($iFilter: JSON!) {
    ${QUERY_NAME}: ${QUERY_NAME}V2(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        locationHistoryId
        openedAt
        locationGroups
      }
    }
  }
`

interface ILocationGroup {
  id: string
  name: string
  type: string
}

export interface INode {
  id: string
  code: string
  name: string
  locationHistoryId: number
  openedAt: string
  locationGroups: {
    [locationGroupType: string]: ILocationGroup
  }
}

export interface IResponse {
  [QUERY_NAME]: {
    nodes: INode[]
  }
}

export interface IVariables {
  iFilter: {
    brand_ids: number[]
  }
}

export const listLocationsByFilterConfigs = {
  id: 'number',
  code: 'string',
  name: 'string',
  openedAt: 'string',
  locationGroups: 'edit-location-button',
} as const

const useListLocationsByFilter = (): IApiType => {
  const brandId = getBrandId()
  const { data, loading, refetch } = useQuery<IResponse, IVariables>(query, {
    variables: {
      iFilter: { brand_ids: [brandId] },
    },
  })

  return {
    data: useMemo(() => {
      const nodes = data?.[QUERY_NAME].nodes

      if (!nodes) return null

      const locations = nodes.map((n) => {
        return {
          ...n,
          parentId: 'root',
        }
      })
      return locations
    }, [data]),
    loading,
    refetch,
  }
}

export const locationsConfigs = {
  ...listLocationsByFilterConfigs,
}

const useLocations = () => {
  const listLocationsByFilter = useListLocationsByFilter()
  return listLocationsByFilter
}

export default useLocations
