import { useEffect, useRef } from 'react'

import { IOptions } from './index'
import drawStackedBarChart, { IYAxisData } from './web/drawStackedBarChart'

interface IProps {
  xAxisData: any[]
  yAxisDataArr: IYAxisData[]
  options: IOptions
}

function Main({ xAxisData, yAxisDataArr, options }: IProps) {
  const lineChartCanvasRef = useRef(null)

  useEffect(() => {
    let lineChart: any = null
    const lineChartCanvas: any = lineChartCanvasRef.current
    const lineChartCanvasContext = lineChartCanvas.getContext('2d')

    lineChart = drawStackedBarChart(
      lineChartCanvasContext,
      xAxisData,
      yAxisDataArr,
      options,
    )

    return () => {
      if (lineChart) {
        lineChart.destroy()
      }
    }
  }, [xAxisData, yAxisDataArr, options])

  return (
    <div>
      <canvas
        ref={lineChartCanvasRef}
        width={options.width || 800}
        height={options.height || 450}
      />
    </div>
  )
}

export default Main
