import { useMemo } from 'react'

import COLORS from 'pared/constants/colors'
import { RAW_PRODUCTIVITY_COEFFICIENT_DATA } from 'pared/constants/raw_data/productivityCoefficient'

const useConfig = () =>
  useMemo(
    () => ({
      type: 'line' as const,
      data: {
        labels: RAW_PRODUCTIVITY_COEFFICIENT_DATA.map(
          ({ period, week }) => `P${period} - week${week}`,
        ),
        datasets: [
          {
            label: 'Productivity Coefficient',
            data: RAW_PRODUCTIVITY_COEFFICIENT_DATA.map(({ value }) => value),
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
          {
            type: 'bar' as const,
            yAxisID: 'yRight',
            label: 'Transactions Count',
            data: RAW_PRODUCTIVITY_COEFFICIENT_DATA.map(
              ({ transactionsCount }) => transactionsCount,
            ),
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Store Level Productivity Coefficient',
          },
          tooltip: {
            mode: 'index' as const,
          },
        },
        scales: {
          y: {
            title: {
              text: 'Productivity Coefficient Percent',
            },
          },
          yRight: {
            position: 'right' as const,
            title: {
              display: true,
              text: 'Transaction Count',
            },
            grid: {
              display: false,
            },
          },
        },
      },
    }),
    [],
  )

export default useConfig
