import { ILossPreventionConfig } from '../useLossPreventionConfig'

export const defaultConfig: ILossPreventionConfig = {
  summary: {
    isVisible: false,
  },
  kpiSettings: [
    { category: 'basic', key: 'totalComps', title: 'Total Discounts' },
    { category: 'basic', key: 'cashOverOrShort', title: 'Cash +/-' },
    {
      category: 'custom',
      type: 'Over Ring',
      title: '% Checks with Over Ring',
      key: 'checkPercent',
    },
    {
      category: 'custom',
      type: 'Deal after Total',
      title: '% Checks with Deletes',
      key: 'checkPercent',
    },
    {
      category: 'basic',
      key: 'inaccurateCashierShiftPercent',
      title: '% Shifts with Inaccurate Cash',
    },
  ],
  cashHandlingTable: {
    display: true,
    columns: [
      {
        key: 'cashOverOrShort.amount',
        title: 'Cash +/-',
        valueType: 'price',
        width: '150px',
      },
      {
        key: 'Over Ring',
        title: 'Over Ring',
        children: [
          {
            key: 'Over Ring.count',
            title: '#',
            valueType: 'count',
            width: '75px',
            sorter: true,
          },
          {
            key: 'Over Ring.amount',
            title: '$',
            valueType: 'price',
            width: '75px',
            sorter: true,
          },
        ],
      },
      {
        key: 'Deal after Total',
        title: 'Deletes',
        children: [
          {
            key: 'Deal after Total.count',
            title: '#',
            valueType: 'count',
            width: '75px',
            sorter: true,
          },
          {
            key: 'Deal after Total.amount',
            title: '$',
            valueType: 'price',
            width: '75px',
            sorter: true,
          },
        ],
      },
      {
        key: 'cashOverOrShort.checkPercent',
        title: '% Shifts with Inaccurate Cash',
        valueType: 'percent',
        width: '150px',
        sorter: true,
      },
      {
        key: 'Over Ring.checkPercent',
        title: '% Checks with Over Ring',
        valueType: 'percent',
        width: '150px',
        sorter: true,
      },
      {
        key: 'Deal after Total.checkPercent',
        title: '% Checks with Deletes',
        valueType: 'percent',
        width: '150px',
        sorter: true,
      },
    ],
  },
  voidsAndDeletesTable: {
    display: false,
    columns: [],
  },
  discountByEmployeeTable: {
    display: true,
  },
  opsCompsBreakdownTable: {
    display: true,
  },
}
