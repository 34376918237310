import { IDiscountDetailsType } from './types'

export const DISCOUNT_TYPES: IDiscountDetailsType[] = [
  {
    discountName: '100% Disc. / Emp',
    checkCountKey: 'discountCheckCount100DiscEmp',
    compsKey: 'discountComps100DiscEmp',
  },
  {
    discountName: '100% Remake',
    checkCountKey: 'discountCheckCount100Remake',
    compsKey: 'discountComps100Remake',
  },
  {
    discountName: 'Other % Combined',
    checkCountKey: 'discountCheckCountOtherSCombined',
    compsKey: 'discountCompsOtherSCombined',
  },
  {
    discountName: 'All With Purch',
    checkCountKey: 'discountCheckCountAllWithPurch',
    compsKey: 'discountCompsAllWithPurch',
  },
  {
    discountName: 'All Non Purch Promos',
    checkCountKey: 'discountCheckCountAllNonPurchPromos',
    compsKey: 'discountCompsAllNonPurchPromos',
  },
  {
    discountName: 'Open Discounts',
    checkCountKey: 'discountCheckCountOpenDiscounts',
    compsKey: 'discountCompsOpenDiscounts',
  },
]
