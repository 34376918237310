import HelpIcon from '@mui/icons-material/HelpOutline'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import styled from 'styled-components'

interface IProps {
  title: string
  placement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined
  customStyle?: any
}

const ExpoTooltip = ({ title, placement, customStyle }: IProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleTooltipOpen = () => {
    setIsOpen(true)
  }

  const handleTooltipClose = () => {
    setIsOpen(false)
  }

  const Title = (
    <Typography
      fontSize="14px"
      style={{
        fontFamily: 'Lexend-Regular',
      }}
    >
      {title}
    </Typography>
  )

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        disableHoverListener
        open={isOpen}
        onClose={handleTooltipClose}
        title={Title}
        style={customStyle}
        placement={placement}
      >
        <IconButton size="small" aria-label="help" onClick={handleTooltipOpen}>
          <StyledHelpIcon />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  )
}

export default ExpoTooltip

const StyledHelpIcon = styled(HelpIcon)`
  font-size: 18px;
`
