import { useMemo } from 'react'

import { IColumnsType } from 'pared/components/basicUi/table'

import { IOrderingGuideDataType } from './useDataSource'

export default (
  dataSource: IOrderingGuideDataType[],
  nextDaysFilterIndex: number,
): IColumnsType<IOrderingGuideDataType>[] => {
  return useMemo(
    () => [
      {
        key: 'item',
        title: 'Item',
        align: 'center',
        width: '150px',
        sorter: true,
      },
      {
        key: 'currentInventory',
        title: `Current Inventory (${dataSource[0]?.endingCountDate})`,
        align: 'center',
        width: '150px',
        sorter: true,
      },
      {
        key: 'forecast',
        title: `Projected Need - Next ${nextDaysFilterIndex + 2} Days`,
        align: 'center',
        width: '150px',
        sorter: true,
        render: (value: number) => value.toFixed(2),
      },
      {
        key: 'suggestOrder',
        title: 'Suggested Order QTY',
        align: 'center',
        width: '150px',
        sorter: true,
        render: (value: number) => value.toFixed(2),
      },
      {
        key: 'orderUnit',
        title: 'Ordering Unit',
        align: 'center',
        width: '150px',
        sorter: true,
      },
      {
        key: 'endingCost',
        title: 'Ending Cost',
        align: 'center',
        width: '150px',
        sorter: true,
      },
      {
        key: 'cost',
        title: 'Cost',
        align: 'center',
        width: '150px',
        sorter: true,
      },
    ],
    [dataSource, nextDaysFilterIndex],
  )
}
