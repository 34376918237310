import React, { useEffect } from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import { feature, page } from 'pared/analytics/user'
import List from 'pared/components/basicUi/List'
import Table, {
  IPropsType as ITablePropsType,
} from 'pared/components/basicUi/table'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import useDateRangeConfig from 'pared/components/nav/DateRangeSelector/hooks/useConfig'
import LocationSelector from 'pared/components/nav/LocationSelector'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { TYPE_CUSTOM } from 'pared/data/getDateRanges'
import useDefaultLocation from 'pared/hooks/useDefaultLocation'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import { getBrand } from 'pared/utils/brand'
import { scrollToTop } from 'pared/utils/web'

import EmployeeCashHandlingTable from './EmployeeCashHandlingTable'
import EmployeeDiscountTable from './EmployeeDiscountTable'
import EmployeeVoidsTable from './EmployeeVoidsTable'
import Summary from './Summary'
import useKpis from './hooks/useKpis'
import useLossPreventionConfig from './hooks/useLossPreventionConfig'
import useOpsComps, { IOpsCompsDataType } from './hooks/useOpsComps'
import useOpsCompsColumns from './hooks/useOpsCompsColumns'

interface IPropsType {
  navParams: INavParams
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 25px 0px;
    }
  }
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const Container = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px;
  }
`

const SummaryCotinaer = styled.div`
  margin: 20px 0px 0px;
`

const StyledList = styled(List)`
  margin: 46px 0px 0px;
  width: 100%;
  max-width: 700px;
`

export const SubHeader = styled.h1`
  margin: 83px 0 0 0;
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
`

const MobileCotainer = styled.div`
  @media ${MOBILE_WIDTH} {
    margin: 20px 0px 0px;
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const rowStyle = `
  td:first-child {
    font-family: Lexend-SemiBold;
    font-weight: 600px;
  }

  &:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }
`

const OpsCompsTable = styled<
  React.ComponentType<ITablePropsType<IOpsCompsDataType>>
>(Table)`
  width: 100%;
  max-width: 600px;
`

const LossPrevention = ({ navParams }: IPropsType) => {
  useDefaultLocation()
  const brand = getBrand()
  const storeId = navParams.storeId
  const locationId = parseInt(storeId || '0', 10)
  const dateRange = useGetDateRange({ isCustomAllowed: true })
  const { dateRangeOptions } = useDateRangeConfig()
  if (!dateRangeOptions.includes(TYPE_CUSTOM)) {
    dateRangeOptions.push(TYPE_CUSTOM)
  }

  const startDate = dateRange?.startDateStr
  const endDate = dateRange?.endDateStr

  const pageKey = `${locationId}-${startDate}-${endDate}`

  const lossPreventionConfig = useLossPreventionConfig(brand)

  const kpis = useKpis(locationId, startDate, endDate)
  const opsComps = useOpsComps({
    iLocationId: locationId,
    iStartDate: startDate,
    iEndDate: endDate,
  })
  const opsCompsColumns = useOpsCompsColumns()

  useEffect(() => {
    if (navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl)
      feature.used('Corporate Guest')
    }
  }, [navParams.pageUrl])

  return (
    <>
      <Header>
        <div>
          <h1>Loss Prevention</h1>
        </div>

        <Selectors>
          <LocationSelector navParams={navParams} />

          <DateRangeSelector
            navParams={navParams}
            dateRangeOptions={dateRangeOptions}
          />
        </Selectors>
      </Header>

      <Container>
        {!lossPreventionConfig.summary.isVisible ? null : (
          <SummaryCotinaer>
            <Summary
              startDate={startDate}
              endDate={endDate}
              locationId={locationId}
            />
          </SummaryCotinaer>
        )}
        <StyledList title="KPIs" items={kpis} />

        {lossPreventionConfig.opsCompsBreakdownTable?.display ? (
          <>
            <SubHeader>Ops Comps Breakdown</SubHeader>

            <MobileCotainer>
              <OpsCompsTable
                key={`opsComps-${pageKey}`}
                dataSource={opsComps}
                columns={opsCompsColumns}
                rowStyle={rowStyle}
                rowKey="rank"
              />
            </MobileCotainer>
          </>
        ) : null}

        {lossPreventionConfig?.discountByEmployeeTable?.display ? (
          <>
            <SubHeader>Discounts by Employee</SubHeader>

            <EmployeeDiscountTable
              locationId={locationId}
              startDate={startDate}
              endDate={endDate}
            />
          </>
        ) : null}

        {lossPreventionConfig.voidsAndDeletesTable?.display ? (
          <>
            <SubHeader>Voids And Deletes by Employee</SubHeader>

            <EmployeeVoidsTable
              locationId={locationId}
              startDate={startDate}
              endDate={endDate}
            />
          </>
        ) : null}

        {!lossPreventionConfig.cashHandlingTable?.display ? null : (
          <>
            <SubHeader>Cash Handling by Employee</SubHeader>

            <EmployeeCashHandlingTable
              locationId={locationId}
              startDate={startDate}
              endDate={endDate}
            />
          </>
        )}
      </Container>
    </>
  )
}

export default React.memo(LossPrevention)
