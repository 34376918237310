import { gql, useMutation } from '@apollo/client'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import { getUser } from 'pared/utils/user'

import { useReset } from '../../../ResetProvider'

export interface IAssignedTrackerType {
  employeeId: number
  callbackUrl: string
}

interface IInputTrackerType {
  assigned_tracker: string
  employee_id: number
  user_id: number
  date_uuid: string
}

const CREATE_TRAINING_TRACKER = gql`
  mutation CreateEmployeeTrainingTracker($iInput: JSON!) {
    createEmployeeTrainingTracker(input: { iInput: $iInput }) {
      clientMutationId
    }
  }
`

const useAssignTracker = (data: IAssignedTrackerType, onClose: () => void) => {
  const history = useHistory()
  const { employeeId, callbackUrl } = data
  const [updateAssignTracker] = useMutation(CREATE_TRAINING_TRACKER)
  const reset = useReset()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [assignTrackerValue, setAssignTrackerValue] = useState('')

  const hasErrors = useMemo(() => {
    if (!assignTrackerValue || assignTrackerValue.length === 0) return true
    return false
  }, [isSubmitting, assignTrackerValue])

  const submit = useCallback(async () => {
    setIsSubmitting(true)
    setIsLoading(true)

    if (hasErrors) {
      setIsLoading(false)
      return
    }

    const inputUuid = uuidv4()
    const user = getUser()
    const userId = user.employeeId ? user.employeeId : -1

    const inputTracker: IInputTrackerType = {
      assigned_tracker: assignTrackerValue,
      employee_id: employeeId,
      user_id: userId,
      date_uuid: inputUuid,
    }

    await updateAssignTracker({
      variables: {
        iInput: inputTracker,
      },
    })
    reset()
    history.push(callbackUrl)
  }, [assignTrackerValue, reset, hasErrors])

  useEffect(() => {
    if (isLoading) onClose()
  }, [data])

  return {
    assignTrackerValue,
    setAssignTrackerValue,
    submit,
    isLoading,
    hasErrors: isSubmitting && hasErrors,
  }
}

export default useAssignTracker
