import { useMemo } from 'react'

import {
  TYPE_CUSTOM,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_WEEK,
  TYPE_YEAR,
} from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'

const useConfig = () => {
  const brand = getBrand()

  return useMemo(() => {
    switch (brand) {
      // Local Favorite
      case 'lfr_ef':
      case 'lfr_tr':
      case 'lfr_sn':
      case 'lfr_lv':
      case 'lfr_vb':
      case 'lfr_tu':
      case 'lfr_ws':
      case 'lfr_jn':
      case 'lfr_mm':
      case 'lfr_ol':
      case 'lfr_dt':
      case 'lfr_uv':
      case 'lfr_bth':
        return {
          preSelectedDateRange: TYPE_THIS_WEEK,
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_QUARTER,
            TYPE_PERIOD,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
            TYPE_CUSTOM,
          ],
        }

      default:
        return {
          preSelectedDateRange: TYPE_THIS_WEEK,
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_QUARTER,
            TYPE_PERIOD,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
          ],
        }
    }
  }, [brand])
}

export default useConfig
