import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import set from 'lodash/set'
import React from 'react'
import styled from 'styled-components'

import List from 'pared/components/basicUi/List'
import Table from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { toPercentString } from 'pared/utils/number'

import useColumns from './hooks/useColumns'
import useStyles from './hooks/useStyles'
import { IReportDefinition, IReportResult } from './types'

const MobileContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

interface IProps {
  reportDefinition?: IReportDefinition
  reportResult?: IReportResult
  title?: string
  isLoading?: boolean
  customizedLoadingStatus?: React.ReactNode
  breakdownTitle?: string
  rowKey?: ((values: any) => string) | string
  csvFileName?: string
  refetch?: any
  selectedFilter?: { [key: string]: string }
}

const PAGINATION_PAGE_SIZE = 20

const getColor = (count: number, rank: number) => {
  const percentileRank = ((count - rank) * 100) / count
  if (percentileRank >= 66) {
    return '#D8EAD3'
  }

  if (percentileRank >= 33) {
    return '#FCE5CD'
  }

  return '#F4CCCC'
}

const percentageToColor = (percentage: number, maxHue = 120, minHue = 0) => {
  const hue = percentage * (maxHue - minHue) + minHue
  return `hsl(${hue}, 80%, 80%)`
}

const CustomizedReportRender = ({
  reportDefinition,
  reportResult,
  title,
  isLoading,
  customizedLoadingStatus,
  breakdownTitle,
  rowKey,
  csvFileName,
  refetch,
  selectedFilter,
}: IProps) => {
  const unfilteredColumns = useColumns(
    reportDefinition,
    reportResult,
    refetch,
    breakdownTitle,
  )

  const columns = unfilteredColumns.filter(
    ({ filter }) =>
      !filter || !selectedFilter || isEqual(filter, selectedFilter),
  )
  const styles = useStyles(reportDefinition)
  const isForEmail = reportDefinition?.isForEmail

  if (isLoading) {
    if (customizedLoadingStatus) {
      return customizedLoadingStatus
    }
    return <PageStatusDiv>Loading...</PageStatusDiv>
  }

  if (reportDefinition && reportResult) {
    switch (reportDefinition.type) {
      case 'TEXT':
        if (reportDefinition.textKey && reportResult.textData) {
          return reportResult.textData[reportDefinition.textKey]
        }
        return '-'

      case 'PERCENT':
        if (reportDefinition.percentKey && reportResult.percentData) {
          return toPercentString(
            reportResult.percentData[reportDefinition.percentKey],
            1,
          )
        }
        return '-'

      case 'TABLE':
        let tableData = [...(reportResult?.tableData || [])]
        tableData = tableData.map((data, index) => ({
          ...data,
          order: index,
        }))
        for (const colDef of columns) {
          const { key, heatmap, heatmapKey, heatmapDirection, colorCode } =
            colDef
          if (heatmap) {
            tableData.sort((a, b) => {
              const keyToUse = heatmapKey ?? key
              return heatmapDirection === 'asc'
                ? get(a, keyToUse) - get(b, keyToUse)
                : get(b, keyToUse) - get(a, keyToUse)
            })

            tableData = tableData.map((row, index) => {
              const newRow = cloneDeep(row)
              const value = get(row, key)
              const color =
                heatmap === 'thirds'
                  ? getColor(tableData.length, index)
                  : percentageToColor(
                      (tableData.length - index) / tableData.length,
                    )
              set(newRow, key, {
                value,
                color,
              })

              return newRow
            })
          }

          if (colorCode) {
            tableData = tableData.map((row) => {
              const newRow = cloneDeep(row)
              const value = get(row, key)
              const color = colorCode.find((rule) => {
                if (value > rule.min && value <= rule.max) {
                  return true
                }
              })?.color

              set(newRow, key, {
                value,
                color,
              })

              return newRow
            })
          }

          tableData = tableData.map((row) => {
            const newRow = cloneDeep(row)
            let value = get(row, key)
            if (value && typeof value === 'object' && 'value' in value) {
              value = get(value, 'value')
            }

            const rowColor = get(row, 'rowColor')
            if (rowColor) {
              set(newRow, key, {
                value,
                color: rowColor,
              })
            }

            return newRow
          })

          tableData.sort((a, b) => {
            const aFixedPosition = a.fixedPosition
            const bFixedPosition = b.fixedPosition
            if (aFixedPosition && bFixedPosition) {
              return aFixedPosition - bFixedPosition
            }

            if (aFixedPosition > 0 || bFixedPosition < 0) {
              return -1
            }

            if (aFixedPosition < 0 || bFixedPosition > 0) {
              return 1
            }

            return a.order - b.order
          })
        }

        if (tableData.length === 0) {
          return (
            <PageStatusDiv>
              There is no data within the date range selected. Please select a
              different date range.
            </PageStatusDiv>
          )
        }

        return (
          <MobileContainer>
            <Table
              {...styles}
              dataSource={tableData}
              columns={columns}
              rowKey={
                rowKey ||
                ((values) =>
                  'storeId' in values ? values.storeId : values.directorId)
              }
              pagination={
                reportDefinition.pagination ?? {
                  pageSize: PAGINATION_PAGE_SIZE,
                }
              }
              shouldPaginationAboveSummary={true}
              csvFileName={csvFileName}
              isForEmail={isForEmail}
            />
          </MobileContainer>
        )

      case 'LIST':
        const [data] = reportResult.tableData || {}
        const items = columns.map(({ key, title: header, render }) => {
          return {
            key,
            value: <>{data[key] && render ? render(data[key]) : '-'}</>,
            text: <>{header}</>,
          }
        })

        return (
          <MobileContainer>
            <List title={title || ''} items={items} />
          </MobileContainer>
        )
      default:
      // do nothing
    }
  }

  return null
}

export default React.memo(CustomizedReportRender)
