import moment from 'moment'
import { useMemo } from 'react'

import { useDateFilter, useYtdEndDate } from '../../../dateFilter'
import useFirstDateOfYear from '../../../dateFilter/hooks/useFirstDateOfYear'
import useYoyDates from '../../../dateFilter/hooks/useYoyDates'
import calc from '../../../utils/calc'
import { corporateGroupTableConfigs, useVariables } from '../../../variables'
import { IApiDataType } from '../../types'
import {
  MetricResult,
  useFetchGroupMetricValue,
} from './useFetchGroupMetricValue'

export const bbbPnlRankingsConfigs = {
  '<%- JSON(corporateGroup?.tableColumns.slice(0, 1)) %>':
    corporateGroupTableConfigs['<%- JSON(corporateGroup?.tableColumns) %>'],
  rowIndex: 'string',
  pAndLSales: 'price',
  pAndLBudget: 'price',
  pAndLCompare: 'price',
  pAndLComparePercent: 'percent',
  pAndLYoyCompare: 'price',
  pAndLYoyComparePercent: 'percent',
  pAndLYtdSales: 'price',
  pAndLYtdBudget: 'price',
  pAndLYtdCompare: 'price',
  pAndLYtdComparePercent: 'percent',
  pAndLYytdCompare: 'price',
  pAndLYytdComparePercent: 'percent',
} as const

const ASCENDING_RANKING = [
  'total_cogs',
  'total_controllable_expenses_with_bank_charges_and_third_party_fees',
  'total_controllable_expenses_without_bank_charges_and_third_party_fees',
  'total_noncontrollable_expenses',
  'total_restaurant_operating_expenses',
]

const useBbbPnlRankings = () => {
  const dateFilter = useDateFilter()
  const { variables } = useVariables()
  const yoyDates = useYoyDates(dateFilter.startDate, dateFilter.endDate)
  const ytdStartDate = useFirstDateOfYear(
    dateFilter.endDate ? moment.utc(dateFilter.endDate) : moment(),
  )
  const ytdEndDate = useYtdEndDate()
  const yytdDates = useYoyDates(ytdStartDate, ytdEndDate)

  const { currentVariables, yoyVariables, ytdVariables, yytdVariables } =
    useMemo(() => {
      const metricCode =
        variables.bbbPnl?.metricCode.value
          .flat()
          .slice(-1)[0]
          ?.split(/\./)[0] || ''
      const rankBy = variables.bbbPnl?.rankBy.value.flat().slice(-1)[0] || ''
      const requiredMetricCodes =
        variables.bbbPnl?.getRequiredCodes(metricCode)[rankBy] || []
      const currentFields = requiredMetricCodes.filter(
        (f) => !/^(yoy|ytd|yytd)_/.test(f),
      )
      const yoyFields = requiredMetricCodes
        .filter((f) => /^yoy_/.test(f))
        .map((f) => f.replace(/^yoy_/, ''))
      const ytdFields = requiredMetricCodes
        .filter((f) => /^ytd_/.test(f))
        .map((f) => f.replace(/^ytd_/, ''))
      const yytdFields = requiredMetricCodes
        .filter((f) => /^yytd_/.test(f))
        .map((f) => f.replace(/^yytd_/, ''))
      const hasGroupBy = !variables.corporateGroup?.locationGroups

      return {
        currentVariables:
          (dateFilter.startDate &&
            dateFilter.endDate &&
            variables.corporateGroup?.locationGroupIds &&
            currentFields.length !== 0 && {
              iStartDate: dateFilter.startDate,
              iEndDate: dateFilter.endDate,
              iFilter: {
                location_group_ids: variables.corporateGroup.locationGroupIds,
                intersected_location_group_ids:
                  variables.corporateGroup.intersectedLocationGroupIds,
                metrics: currentFields,
              },
              hasGroupBy,
            }) ||
          null,
        yoyVariables:
          (yoyDates.yoyStartDate &&
            yoyDates.yoyEndDate &&
            variables.corporateGroup?.locationGroupIds &&
            yoyFields.length !== 0 && {
              iStartDate: yoyDates.yoyStartDate,
              iEndDate: yoyDates.yoyEndDate,
              iFilter: {
                location_group_ids: variables.corporateGroup.locationGroupIds,
                intersected_location_group_ids:
                  variables.corporateGroup.intersectedLocationGroupIds,
                metrics: yoyFields,
              },
              hasGroupBy,
            }) ||
          null,
        ytdVariables:
          (ytdStartDate &&
            ytdEndDate &&
            variables.corporateGroup?.locationGroupIds &&
            ytdFields.length !== 0 && {
              iStartDate: ytdStartDate,
              iEndDate: ytdEndDate,
              iFilter: {
                location_group_ids: variables.corporateGroup.locationGroupIds,
                intersected_location_group_ids:
                  variables.corporateGroup.intersectedLocationGroupIds,
                metrics: ytdFields,
              },
              hasGroupBy,
            }) ||
          null,
        yytdVariables:
          (yytdDates.yoyStartDate &&
            yytdDates.yoyEndDate &&
            variables.corporateGroup?.locationGroupIds &&
            yytdFields.length !== 0 && {
              iStartDate: yytdDates.yoyStartDate,
              iEndDate: yytdDates.yoyEndDate,
              iFilter: {
                location_group_ids: variables.corporateGroup.locationGroupIds,
                intersected_location_group_ids:
                  variables.corporateGroup.intersectedLocationGroupIds,
                metrics: yytdFields,
              },
              hasGroupBy,
            }) ||
          null,
      }
    }, [variables, dateFilter, yoyDates, ytdStartDate, ytdEndDate, yytdDates])

  const { results: currentMetrics, isLoading: metricLoading } =
    useFetchGroupMetricValue(currentVariables)
  const { results: ytdMetrics, isLoading: ytdLoading } =
    useFetchGroupMetricValue(ytdVariables)
  const { results: yoyMetrics, isLoading: yoyLoading } =
    useFetchGroupMetricValue(yoyVariables)
  const { results: yoyYtdMetrics, isLoading: yoyYtdLoading } =
    useFetchGroupMetricValue(yytdVariables)

  const getMetricValue = (
    locationMetrics: MetricResult[],
    metricCode: string,
  ): number | null => {
    return (
      locationMetrics.find((m) => m.metricCode === metricCode)?.value ?? null
    )
  }

  return {
    data: useMemo((): IApiDataType => {
      const metricCode =
        variables.bbbPnl?.metricCode.value
          .flat()
          .slice(-1)[0]
          ?.split(/\./)[0] || ''
      const rankBy = variables.bbbPnl?.rankBy.value.flat().slice(-1)[0]
      const budgetMetricCode = variables.bbbPnl?.getBudgetCode(metricCode) || ''
      const { locations, locationGroups } = variables.corporateGroup || {}
      const corporateDetails = locations || locationGroups

      if (!corporateDetails || !metricCode || !rankBy) return null

      const source = Object.keys(corporateDetails).map((key) => {
        const id = parseInt(key, 10)
        const locationCurrentMetrics = currentMetrics.filter(
          (metric) => metric.id === id,
        )
        const locationYtdMetrics = ytdMetrics.filter(
          (metric) => metric.id === id,
        )
        const locationYoyMetrics = yoyMetrics.filter(
          (metric) => metric.id === id,
        )
        const locationYoyYtdMetrics = yoyYtdMetrics.filter(
          (metric) => metric.id === id,
        )

        const value = getMetricValue(locationCurrentMetrics, metricCode)
        const budget = getMetricValue(locationCurrentMetrics, budgetMetricCode)

        const ytdValue = getMetricValue(locationYtdMetrics, metricCode)
        const ytdBudget = getMetricValue(locationYtdMetrics, budgetMetricCode)

        const yoyValue = getMetricValue(locationYoyMetrics, metricCode)

        const yoyYtdValue = getMetricValue(locationYoyYtdMetrics, metricCode)

        return {
          ...corporateDetails[id]?.tableRow,
          id: id.toString(),
          parentId: 'summary',
          pAndLSales: value,
          pAndLBudget: budget,
          pAndLCompare: calc(value, '-', budget),
          pAndLComparePercent: calc(value, 'variancePercentageOf', budget),
          pAndLYtdSales: ytdValue,
          pAndLYtdBudget: ytdBudget,
          pAndLYtdCompare: calc(ytdValue, '-', ytdBudget),
          pAndLYtdComparePercent: calc(
            ytdValue,
            'variancePercentageOf',
            ytdBudget,
          ),
          pAndLYoySales: yoyValue,
          pAndLYoyCompare: calc(value, '-', yoyValue),
          pAndLYoyComparePercent: calc(value, 'variancePercentageOf', yoyValue),
          pAndLYoyYtdSales: yoyYtdValue,
          pAndLYytdCompare: calc(ytdValue, '-', yoyYtdValue),
          pAndLYytdComparePercent: calc(
            ytdValue,
            'variancePercentageOf',
            yoyYtdValue,
          ),
        }
      })
      const ascedingRanking = variables.bbbPnl?.metricCode.value
        .flat()
        .some((code) => ASCENDING_RANKING.includes(code))
        ? -1
        : 1
      const sortedData = source.sort(
        (
          a: NonNullable<IApiDataType>[number],
          b: NonNullable<IApiDataType>[number],
        ) => {
          if (a[rankBy] === null && b[rankBy] === null) return 0
          if (a[rankBy] === null) return 1
          if (b[rankBy] === null) return -1
          return (
            ((b[rankBy] as number) - (a[rankBy] as number)) * ascedingRanking
          )
        },
      )
      const summary = sortedData.slice(1).reduce(
        (result, d) =>
          (
            [
              'pAndLSales',
              'pAndLBudget',
              'pAndLCompare',
              'pAndLYtdSales',
              'pAndLYtdBudget',
              'pAndLYtdCompare',
              'pAndLYoySales',
              'pAndLYoyCompare',
              'pAndLYoyYtdSales',
              'pAndLYytdCompare',
            ] as const
          ).reduce(
            (subResult, key) => ({
              ...subResult,
              [key]:
                d[key] === null ? result[key] : calc(result[key], '+', d[key]),
            }),
            result,
          ),
        {
          ...sortedData[0],
          id: 'summary',
          parentId: 'root',
          rowIndex: 'Total',
          location: null,
          groupBy: null,
          locationGroup: null,
          pAndLComparePercent: null as number | null,
          pAndLYtdComparePercent: null as number | null,
          pAndLYoyComparePercent: null as number | null,
          pAndLYytdComparePercent: null as number | null,
        },
      )

      summary.pAndLComparePercent = calc(
        summary.pAndLSales,
        'variancePercentageOf',
        summary.pAndLBudget,
      )
      summary.pAndLYtdComparePercent = calc(
        summary.pAndLYtdSales,
        'variancePercentageOf',
        summary.pAndLYtdBudget,
      )
      summary.pAndLYoyComparePercent = calc(
        summary.pAndLSales,
        'variancePercentageOf',
        summary.pAndLYoySales,
      )
      summary.pAndLYytdComparePercent = calc(
        summary.pAndLYtdSales,
        'variancePercentageOf',
        summary.pAndLYoyYtdSales,
      )

      return [summary, ...sortedData]
    }, [currentMetrics, ytdMetrics, yoyMetrics, yoyYtdMetrics, variables]),
    loading: metricLoading || ytdLoading || yoyLoading || yoyYtdLoading,
  }
}

export default useBbbPnlRankings
