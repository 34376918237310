import { gql } from '@apollo/client'

export const LIST_LOCATION_PEAK_TIME_PLAYER_PLUS_MINUS = gql`
  query ListLocationPeakTimePlayerPlusMinus(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $iMinNumShifts: Int!
    $iMaxNumShifts: Int!
  ) {
    listLocationPeakTimePlayerPlusMinus(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iMinNumShifts: $iMinNumShifts
      iMaxNumShifts: $iMaxNumShifts
    ) {
      nodes {
        locationId
        employeeInfo {
          id
          familyName
          preferredName
        }
        roleInfo {
          id
          code
          name
        }
        transactionCountPlusMinus
        guestCountPlusMinus
        dayCount
      }
    }
  }
`

export const MOCK_UP_QUERY_LIST_LOCATION_PEAK_TIME_PLAYER_PLUS_MINUS = gql`
  query ListLocationEmployeeSales(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationPeakTimePlayerPlusMinus: listLocationEmployeeSalesV2E1(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iDayPart: "ALL"
    ) {
      nodes {
        locationId
        employeeInfo {
          id
          preferredName
          familyName
        }
        roleInfo {
          id
          code
          name
        }
        totalSalesDetails
      }
    }
  }
`
