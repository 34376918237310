import { useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { DATE_FORMAT } from 'pared/data/getDateRanges'
import useGetDirectors from 'pared/hooks/useGetDirectors'

import {
  IDailyDashboardTableType,
  IDailyDashboardTableVariablesType,
  listLocationGroupDailyDashboardTable,
} from '../gql'

export interface IHourlyLabor {
  costs: number
  sales: number
}

export interface ISpeedOfService {
  count: number
  seconds: number
}

export interface IAcrScore {
  traffic: number
  b3sValue: number
}

export interface IDailyDashboardData {
  storeId: number
  storeCode: string
  storeName: string
  hourlyLabor: IHourlyLabor
  mtdHourlyLabor: IHourlyLabor
  driveThruSos: ISpeedOfService
  mtdAvgTotalSos: ISpeedOfService
  pickupTime: ISpeedOfService
  mtdPickupTime: ISpeedOfService
  mtdAcrScore: IAcrScore
  cashOverShort: number
  mtdCashOverShort: number
  revScore: number
}

const useDataSource = () => {
  const { locationGroupIds } = useGetDirectors()
  const today = moment().format(DATE_FORMAT)

  const { data, loading } = useQuery<
    IDailyDashboardTableType,
    IDailyDashboardTableVariablesType
  >(listLocationGroupDailyDashboardTable, {
    variables: {
      iEndDate: today,
      iFilter: {
        location_group_ids: locationGroupIds,
      },
    },
  })

  const dataSource = useMemo(() => {
    if (!data?.listLocationGroupDailyDashboardTable) return []

    return data?.listLocationGroupDailyDashboardTable.nodes?.map((row) => ({
      storeId: row.locationId,
      storeCode: row.locationInfo.code,
      storeName: row.locationInfo.name,
      hourlyLabor: {
        costs: row.yesterdayHourlyLaborCost,
        sales: row.yesterdaySales,
      },
      mtdHourlyLabor: {
        costs: row.mtdHourlyLaborCost,
        sales: row.mtdSales,
      },
      driveThruSos: {
        count: parseInt(row.yesterdayDriveThruTotalCount, 10),
        seconds: parseInt(row.yesterdayDriveThruTotalSeconds, 10),
      },
      mtdAvgTotalSos: {
        count: parseInt(row.mtdAverageTotalCount, 10),
        seconds: parseInt(row.mtdAverageTotalSeconds, 10),
      },
      pickupTime: {
        count: parseInt(row.yesterdayPickupTotalCount, 10),
        seconds: parseInt(row.yesterdayPickupTotalSeconds, 10),
      },
      mtdPickupTime: {
        count: parseInt(row.mtdPickupTotalCount, 10),
        seconds: parseInt(row.mtdPickupTotalSeconds, 10),
      },
      mtdAcrScore: {
        traffic: parseInt(row.mtdTotalTraffic, 10),
        b3sValue: parseInt(row.mtdTotalB3sValue, 10),
      },
      cashOverShort: row.yesterdayCashOverShort,
      mtdCashOverShort: row.mtdCashOverShort,
      revScore: parseFloat(row.revScore),
    }))
  }, [data, locationGroupIds, today])

  return {
    loading,
    dataSource,
  }
}

export default useDataSource
