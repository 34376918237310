import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'

import { getBrand } from 'pared/utils/brand'

export interface ITimeFilterType {
  id: string
  parentId: 'root' | null
  value: string
  text: string
}

export interface IWeekInfo {
  key: string
  weekIndex: number
  weekBeginDate: moment.Moment
  weekEndDate: moment.Moment
}

export const INVENTORY_TIME_TYPE = {
  PERIOD: 'period',
  WEEKLY: 'weekly',
  DAILY: 'daily',
}

const DEFAULT_MENU_ITEM_LIST = [
  {
    id: 'root',
    parentId: null,
    value: 'root',
    text: 'root',
  },
  {
    id: INVENTORY_TIME_TYPE.PERIOD,
    parentId: 'root',
    value: INVENTORY_TIME_TYPE.PERIOD,
    text: 'Period',
  },
  {
    id: INVENTORY_TIME_TYPE.WEEKLY,
    parentId: 'root',
    value: INVENTORY_TIME_TYPE.WEEKLY,
    text: 'Weekly',
  },
  {
    id: INVENTORY_TIME_TYPE.DAILY,
    parentId: 'root',
    value: INVENTORY_TIME_TYPE.DAILY,
    text: 'Daily',
  },
]

const useTimeFilter = () => {
  const [timeFilter, setTimeFilter] = useState<string[]>([])
  const brand = getBrand()
  const menuItemList = useMemo(() => {
    switch (brand) {
      case 'jsc_bk':
      case 'jsc_bk_nso':
      case 'ghai_pop':
      case 'burgerworks':
        return DEFAULT_MENU_ITEM_LIST.filter(
          ({ id }) => ![INVENTORY_TIME_TYPE.DAILY].includes(id),
        )

      case 'bibibop':
      case 'mwb':
        return DEFAULT_MENU_ITEM_LIST.filter(
          ({ id }) =>
            ![INVENTORY_TIME_TYPE.PERIOD, INVENTORY_TIME_TYPE.DAILY].includes(
              id,
            ),
        )

      default:
        return DEFAULT_MENU_ITEM_LIST
    }
  }, [brand])

  useEffect(() => {
    let defaultValue = INVENTORY_TIME_TYPE.WEEKLY
    switch (brand) {
      default:
        defaultValue = INVENTORY_TIME_TYPE.WEEKLY
    }
    setTimeFilter([defaultValue])
  }, [menuItemList, brand])

  return {
    timeFilter,
    setTimeFilter,
    timeFilters: menuItemList,
  }
}

export default useTimeFilter
