import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import moment from 'moment'
import { useState } from 'react'
import styled from 'styled-components'

import {
  CustomizedReportRender,
  useFetchCustomizedReportData,
} from 'pared/components/CustomizedReport'
import { ITableColumnDef } from 'pared/components/CustomizedReport/types'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandLocationGroupId } from 'pared/utils/brand'

import useConfig from './hooks/useConfig'

type IKpiType =
  | 'Sales'
  | 'Labor Hours'
  | 'Sales Difference'
  | 'Labor Hours Difference'
  | 'Sales Per Labor Hour'
  | 'Sales Per Labor Hour Difference'

interface IProps {
  locationId: number
  startDate: string
  endDate: string
}
const SalesByHourByDayChart = ({ locationId, startDate, endDate }: IProps) => {
  const options = useConfig()
  const [kpi, setKpi] = useState<IKpiType>('Sales')
  const yesterday = moment().subtract(1, 'day')
  const adjustedEndDate = moment(endDate).isAfter(yesterday)
    ? yesterday
    : moment(endDate)
  const numDays = adjustedEndDate.diff(moment(startDate), 'days') + 1

  const inputParams = {
    locationId,
    brandLocationGroupId: getBrandLocationGroupId(),
    startDate,
    endDate,
  }

  const { reportDefinition, reportResult, isLoading } =
    useFetchCustomizedReportData('SALES_BY_HOUR_BY_DAY_TABLE', inputParams)

  let finalReportDefinition = reportDefinition
  let finalReportResult = reportResult
  if (reportDefinition && reportResult) {
    const filteredReportDefinition = {
      ...reportDefinition,
      columns: (reportDefinition.columns ?? []).filter(
        ({ filter }) => !filter || filter?.kpi === kpi,
      ),
    }

    if (numDays > 7) {
      finalReportDefinition = {
        ...filteredReportDefinition,
        hasExtraRow: false,
      }
    } else {
      const extraRow = reportResult.extraRow ?? {}
      const dates = []
      const days: string[] = []
      for (const dow in extraRow) {
        if (extraRow[dow]) {
          days.push(dow)
          dates.push({ dow, date: extraRow[dow] })
        }
      }

      const sortedDates = dates.sort(({ date: dateA }, { date: dateB }) =>
        moment(dateA).isAfter(moment(dateB)) ? 1 : -1,
      )
      const columns = filteredReportDefinition.columns ?? []
      const timeColumn = columns.find(({ key }) => key === 'time')
      const dayColumns = columns.filter(({ key }) => days.includes(key))
      const sortedColumns = sortedDates
        .map(({ dow }) => dayColumns.find(({ key }) => key === dow))
        .filter((col): col is ITableColumnDef => !!col)

      finalReportDefinition = {
        ...filteredReportDefinition,
        columns: [...(timeColumn ? [timeColumn] : []), ...sortedColumns],
      }
    }

    const filteredTableData =
      reportResult.tableData?.filter((data) => {
        const regex = new RegExp(`${kpi.replaceAll(' ', '')}$`)
        const keys = Object.keys(data).filter((key) => regex.test(key))
        const sum = keys.reduce((acc, curKey) => acc + data[curKey], 0)
        return sum !== 0
      }) || []

    finalReportResult = {
      ...reportResult,
      tableData: filteredTableData,
    }
  }

  return (
    <>
      <Header>
        <SectionTitleDiv>
          Hour/Day Breakdown {numDays > 7 ? '(Daily Average)' : ''}
        </SectionTitleDiv>
        <StyledFormControl variant="outlined" size="small">
          <Select
            value={kpi}
            onChange={(e: any) => {
              const value: IKpiType = e.target.value
              setKpi(value)
            }}
          >
            {options.map(({ key, title }) => {
              return (
                <MenuItem key={key} value={key}>
                  {title}
                </MenuItem>
              )
            })}
          </Select>
        </StyledFormControl>
      </Header>
      <VerticalSpacer />
      <CustomizedReportRender
        reportDefinition={finalReportDefinition}
        reportResult={finalReportResult}
        isLoading={isLoading}
        rowKey={(values) => values.time}
        csvFileName={`sales-${moment(startDate).format(
          'YYYYMMDD',
        )}-${adjustedEndDate.format('YYYYMMDD')}`}
      />
    </>
  )
}

export default SalesByHourByDayChart

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
`

const Header = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    gap: 10px;
  }
`

const StyledFormControl = styled(FormControl)`
  width: 280px;
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

const VerticalSpacer = styled.div`
  height: 20px;
`
