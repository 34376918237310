import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@mui/material/Autocomplete'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import React from 'react'
import styled from 'styled-components'

import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import { ILocation } from 'pared/data/getLocations'

interface IProps {
  allLocations: ILocation[] | null
  selectedLocationId: string | null
  onLocationChange: (event: any) => Promise<void>
  isEnabled: boolean
}

const useStyles = makeStyles(() => ({
  text: {
    fontFamily: 'Lexend-Regular',
  },
}))

const CustomPaper = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Paper
      sx={{
        '& .MuiAutocomplete-listbox': {
          fontFamily: 'Lexend-Regular',
          fontSize: 16,
        },
      }}
    >
      {children}
    </Paper>
  )
}

function Main({
  allLocations,
  selectedLocationId,
  onLocationChange,
  isEnabled,
}: IProps) {
  const classes = useStyles()

  const options =
    allLocations?.map((location) => ({
      id: location.id,
      label: `${location.code} - ${location.name}`,
    })) || []

  let selector = null
  if (allLocations) {
    selector = (
      <Autocomplete
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            sx={{
              '& .MuiAutocomplete-input': {
                fontFamily: 'Lexend-Regular',
              },
            }}
          />
        )}
        defaultValue={options.find(({ id }) => `${id}` === selectedLocationId)}
        onChange={onLocationChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={!isEnabled}
        PaperComponent={CustomPaper}
      />
    )
  } else {
    selector = (
      <MainSelect
        displayEmpty
        value="loading"
        disabled
        className={classes.text}
      >
        <MenuItem value="loading" className={classes.text}>
          Loading ...
        </MenuItem>
      </MainSelect>
    )
  }

  return (
    <>
      <LargeScreen>
        <LargeScreenFormControl
          variant="outlined"
          margin="dense"
          style={{ width: 250 }}
        >
          {selector}
        </LargeScreenFormControl>
      </LargeScreen>
      <SmallScreen>
        <SmallScreenFormControl
          variant="outlined"
          margin="dense"
          style={{ width: '100%' }}
        >
          {selector}
        </SmallScreenFormControl>
      </SmallScreen>
    </>
  )
}

const LargeScreenFormControl = styled(FormControl)`
  background-color: white;
`

const SmallScreenFormControl = styled(FormControl)`
  background-color: white;
`

const MainSelect = styled(Select)`
  border-radius: 0;
`

export default Main
