import COLORS from 'pared/constants/colors'
import { ILossPreventionConfigType } from 'pared/pages/CorporateLossPrevention/hooks/useLossPreventionConfig'

export const jitbConfig: ILossPreventionConfigType = {
  kpiSettings: [
    { category: 'basic', key: 'compsTotalCount', title: 'Total Discounts' },
    { category: 'basic', key: 'cashOverOrShort', title: 'Cash +/-' },
    {
      category: 'custom',
      type: 'Deal after Total',
      title: '% Checks with Deletes',
      key: 'checkPercent',
    },
  ],

  cashControlTable: {
    isVisible: true,
    cashControlColumns: [
      {
        key: 'cashOverOrShort',
        title: 'Cash +/-',
        width: '150px',
        children: [
          {
            key: 'cashOverOrShort.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Deal after Total',
        title: 'Deletes',
        width: '150px',
        children: [
          {
            key: 'Deal after Total.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'Deal after Total.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Deal after Total.checkPercent',
        title: '% Checks w/ Deletes',
        valueType: 'percent',
        width: '150px',
      },
    ],
  },

  discountTable: {
    isVisible: true,
    discountColumns: [
      {
        key: 'Employee Discount',
        title: 'Employee Discount',
      },
      {
        key: 'Employee Meal',
        title: 'Employee Meal',
      },
      {
        key: 'Manager Meal',
        title: 'Manager Meal',
      },
      {
        key: 'Senior Discount',
        title: 'Senior Discount',
      },
      {
        key: 'Coupon',
        title: 'Coupon',
      },
      {
        key: 'Percent Discount',
        title: 'Percent Discount',
      },
    ],
  },

  discountTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Discount',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [
      {
        key: 'Employee Discount',
        name: 'Employee Discount',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Plum,
        hidden: true,
      },
      {
        key: 'Employee Meal',
        name: 'Employee Meal',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.DarkOrchid,
        hidden: true,
      },
      {
        key: 'Manager Meal',
        name: 'Manager Meal',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Mango,
        hidden: true,
      },
      {
        key: 'Senior Discount',
        name: 'Senior Discount',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Basil,
        hidden: true,
      },
      {
        key: 'Coupon',
        name: 'Coupon',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Pomodoro,
        hidden: true,
      },
      {
        key: 'Percent Discount',
        name: 'Percent Discount',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Acai,
        hidden: true,
      },
    ],
  },

  cashControlTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Amount',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [
      {
        key: 'cashOverOrShort',
        name: 'Cash +/- $',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Pomodoro,
      },
      {
        key: 'Deal after Total',
        name: 'Deletes $',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Basil,
        hidden: true,
      },
    ],
  },
}
