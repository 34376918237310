import { IRouteType } from '../../types'
import originalCorporatePages from './originalCorporatePages'
import storeHome from './storeHome'

export const routes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Expo AI',
        link: '/:brand/expo_ai',
        useOriginal: true,
      },
      {
        title: 'Systemwide Overview',
        link: '/:brand/systemwide_overview',
        useOriginal: true,
      },
      {
        title: 'Daily Dashboard',
        link: '/:brand/corporate_custom',
        useOriginal: true,
      },
      {
        title: 'Rankings',
        link: '/:brand/rankings',
        useOriginal: true,
      },
      {
        title: 'Flash',
        link: '/:brand/flash',
        useOriginal: true,
      },
      {
        title: 'Team',
        link: '/:brand/corporate_team',
        useOriginal: true,
      },
      {
        title: 'Salesmanship',
        link: '/:brand/corporate_salesmanship',
        useOriginal: true,
      },
      {
        title: 'Product',
        link: '/:brand/corporate_pmix',
        useOriginal: true,
      },
      {
        title: 'Loss Prevention',
        link: '/:brand/corporate_loss_prevention',
        useOriginal: true,
      },
      {
        title: 'Inventory',
        link: '/:brand/corporate_inventory',
        useOriginal: true,
      },
      {
        title: 'Guest',
        link: '/:brand/corporate_guest',
        useOriginal: true,
      },
      {
        title: 'Delivery',
        link: '/:brand/corporate_delivery',
        useOriginal: true,
      },
    ],
  },
  {
    title: 'Store',
    children: [
      {
        title: 'Home',
        link: '/:brand/scorecard',
      },
    ],
  },
]

export default [originalCorporatePages, storeHome]
