import Popper from '@material-ui/core/Popper'
import moment from 'moment'
import React, { useState } from 'react'
import styled from 'styled-components'

import Popup from './Popup'
import useSchedule from './hooks/useSchedule'

const WEEK_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

const THRESHOLD = 30

const Table = styled.table`
  width: 1155px;
  text-align: center;
  font-weight: 700;
  font-family: Lexend-Regular;
  border-collapse: collapse;
`

const Th = styled.th`
  padding: 15px 0px;
  width: 165px;
`

const Td = styled.td<{
  overTarget?: number
}>`
  position: relative;
  width: 165px;
  height: 165px;
  border: 1px solid #8f8f8f;
  ${({ overTarget }) => {
    if (overTarget === undefined) return ''

    if (overTarget > THRESHOLD)
      return `
        background: rgba(255, 111, 111, 0.5);
        cursor: pointer;
      `

    return 'background: #E1E1E1;'
  }}
`

const Day = styled.div`
  position: absolute;
  top: 14px;
  right: 20px;
`

const Schedule = () => {
  const schedule = useSchedule()
  const [popDate, setPopDate] = useState<null | string>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <Table>
      <thead>
        <tr>
          {WEEK_DAYS.map((day) => (
            <Th key={day}>{day.toUpperCase()}</Th>
          ))}
        </tr>
      </thead>

      <tbody>
        {schedule.map((data) => (
          <tr key={data[0].date}>
            {data.map(({ date, ...d }) => {
              const day = moment(date)
              const open = popDate === date
              const id = open ? 'simple-popper' : undefined
              const overTarget = 'overTarget' in d ? d.overTarget : undefined
              const shouldShowPop = (overTarget || 0) > THRESHOLD

              return (
                <React.Fragment key={date}>
                  <Td
                    aria-describedby={id}
                    overTarget={overTarget}
                    onClick={(e) => {
                      if (!shouldShowPop) return

                      setAnchorEl(e.currentTarget)
                      setPopDate(date)
                    }}
                  >
                    <Day>
                      {day.format('D') !== '1'
                        ? null
                        : `${day.format('MMM').toUpperCase()} `}

                      {day.format('D')}
                    </Day>

                    {!day.isSame(moment(), 'days') ? null : <div>TODAY</div>}

                    {!shouldShowPop ? null : `$${overTarget}`}
                  </Td>

                  {!('overTarget' in d) ? null : (
                    <Popper id={id} open={open} anchorEl={anchorEl}>
                      <Popup
                        {...d}
                        date={date}
                        onClose={() => {
                          setAnchorEl(null)
                          setPopDate(null)
                        }}
                      />
                    </Popper>
                  )}
                </React.Fragment>
              )
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default React.memo(Schedule)
