import AdvancedFilterProvider, {
  useAdvancedFilter,
} from './AdvancedFilterProvider'
import Select, { IPropsType as ISelectPropsType } from './Select'

export type IPropsType =
  | {
      type: 'select'
    } & ISelectPropsType

const AdvancedFilter = (props: IPropsType) => {
  switch (props.type) {
    case 'select':
      return <Select {...props} />

    default:
      return null
  }
}

export { AdvancedFilterProvider, useAdvancedFilter }
export default AdvancedFilter
