import { useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { DATE_FORMAT } from 'pared/data/getDateRanges'

import {
  IDailyInventoryAnalysisType,
  IDailyInventoryAnalysisVariablesType,
  LIST_LOCATION_DAILY_INVENTORY_ANALYSIS,
} from '../gqls'

export interface IDataType {
  item: string
  [key: string]: { value: string; counted: boolean } | string
}

export default (
  locationId: number,
): {
  columns: string[]
  dataSource: IDataType[]
  loading: boolean
} => {
  const yesterday = moment().subtract(1, 'days').format(DATE_FORMAT)
  const startDate = moment().subtract(8, 'days').format(DATE_FORMAT)

  const { data, loading } = useQuery<
    IDailyInventoryAnalysisType,
    IDailyInventoryAnalysisVariablesType
  >(LIST_LOCATION_DAILY_INVENTORY_ANALYSIS, {
    variables: {
      iStartDate: startDate,
      iEndDate: yesterday,
      iFilter: { location_ids: [locationId] },
    },
  })

  const tableDataSource = useMemo((): {
    columns: string[]
    dataSource: IDataType[]
  } => {
    const rawData = data?.listLocationDailyInventoryAnalysis?.nodes

    if (!rawData)
      return {
        columns: [],
        dataSource: [],
      }

    const columns = rawData.map((row) => row.date)
    const items = rawData[0]?.aggregatableData?.map((a) => a.item) || []

    return {
      columns,
      dataSource: items?.map((item) =>
        columns.reduce(
          (result, column) => {
            const i = rawData
              .find((d) => d.date === column)
              ?.aggregatableData?.find((aggData) => aggData.item === item)
            const ending_qty = i?.ending_qty || 0
            const counted = i?.counted || false
            return {
              ...result,
              [column]: {
                value: ending_qty,
                counted,
              },
            }
          },
          { item },
        ),
      ),
    }
  }, [data, loading])

  return {
    ...tableDataSource,
    loading,
  }
}
