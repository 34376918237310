import React from 'react'
import styled from 'styled-components'

import CustomizedReport from 'pared/components/CustomizedReport'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import { getBrandLocationGroupId } from 'pared/utils/brand'

interface IProps {
  locationGroupId: number | undefined
}

const ChickenCountTable = ({ locationGroupId }: IProps) => {
  const dateRange = useGetDateRange()
  const brandLocationGroupId = getBrandLocationGroupId()
  const inputParams = {
    startDate: dateRange?.startDateStr,
    endDate: dateRange?.endDateStr,
    locationGroupId: locationGroupId || brandLocationGroupId,
  }

  return (
    <Container>
      <Header>
        <SubHeader>Chicken Count</SubHeader>
      </Header>
      <VerticalSpacer />
      <CustomizedReport
        reportName="LIST_LOCATION_CHICKEN_COUNT_TABLE"
        inputParams={inputParams}
      />
    </Container>
  )
}

export default React.memo(ChickenCountTable)

const Container = styled.div`
  margin: 0;
  padding-top: 80px;
  overflow: scroll hidden;
`

const SubHeader = styled.div`
  margin: 0px;
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
`

const VerticalSpacer = styled.div`
  height: 20px;
`

const Header = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    gap: 10px;
    align-items: start;
  }
`
