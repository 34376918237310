import { IConfigsType } from '../../types'

const fwWingstopDailySalesKpisReport: IConfigsType = {
  'fw_wingstop-daily-kpi-report-b61d68b9': {
    '/:brand/daily_sales_kpis_report': {
      variables: ['date', 'corporateGroup'],
      groupFilter: {
        api: 'fwWingstopCorporateFilter',
        defaultValue: ['Market', 'Market Breakdown'],
      },
      dateFilter: {
        types: ['yesterday', 'custom'],
        defaultType: 'yesterday',
        isDailyCustom: true,
      },
      reportTitle: {
        type: 'title',
        title: 'Daily Sales KPIs Report',
      },
      downloadDailySssPercent: {
        type: 'download-csv',
        api: 'farwestLastTwentyEightDaysTrendData',
        fileName: 'getexpo-far-west-daily-sales-kpis',
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'market',
            title: 'Market',
            hide: '<%- _.isUndefined(corporateGroup?.locations) %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay27InPast',
            title:
              'SSS: <%- date?.getInfo(-27)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay26InPast',
            title:
              'SSS: <%- date?.getInfo(-26)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay25InPast',
            title:
              'SSS: <%- date?.getInfo(-25)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay24InPast',
            title:
              'SSS: <%- date?.getInfo(-24)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay23InPast',
            title:
              'SSS: <%- date?.getInfo(-23)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay22InPast',
            title:
              'SSS: <%- date?.getInfo(-22)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay21InPast',
            title:
              'SSS: <%- date?.getInfo(-21)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay20InPast',
            title:
              'SSS: <%- date?.getInfo(-20)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay19InPast',
            title:
              'SSS: <%- date?.getInfo(-19)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay18InPast',
            title:
              'SSS: <%- date?.getInfo(-18)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay17InPast',
            title:
              'SSS: <%- date?.getInfo(-17)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay16InPast',
            title:
              'SSS: <%- date?.getInfo(-16)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay15InPast',
            title:
              'SSS: <%- date?.getInfo(-15)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay14InPast',
            title:
              'SSS: <%- date?.getInfo(-14)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay13InPast',
            title:
              'SSS: <%- date?.getInfo(-13)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay12InPast',
            title:
              'SSS: <%- date?.getInfo(-12)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay11InPast',
            title:
              'SSS: <%- date?.getInfo(-11)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay10InPast',
            title:
              'SSS: <%- date?.getInfo(-10)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay9InPast',
            title:
              'SSS: <%- date?.getInfo(-9)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay8InPast',
            title:
              'SSS: <%- date?.getInfo(-8)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay7InPast',
            title:
              'SSS: <%- date?.getInfo(-7)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay6InPast',
            title:
              'SSS: <%- date?.getInfo(-6)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay5InPast',
            title:
              'SSS: <%- date?.getInfo(-5)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay4InPast',
            title:
              'SSS: <%- date?.getInfo(-4)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay3InPast',
            title:
              'SSS: <%- date?.getInfo(-3)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay2InPast',
            title:
              'SSS: <%- date?.getInfo(-2)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay1InPast',
            title:
              'SSS: <%- date?.getInfo(-1)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesPercentageDiffToLastYearDay0InPast',
            title:
              'SSS: <%- date?.getInfo(0)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay27InPast',
            title:
              'Sales: <%- date?.getInfo(-27)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay26InPast',
            title:
              'Sales: <%- date?.getInfo(-26)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay25InPast',
            title:
              'Sales: <%- date?.getInfo(-25)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay24InPast',
            title:
              'Sales: <%- date?.getInfo(-24)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay23InPast',
            title:
              'Sales: <%- date?.getInfo(-23)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay22InPast',
            title:
              'Sales: <%- date?.getInfo(-22)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay21InPast',
            title:
              'Sales: <%- date?.getInfo(-21)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay20InPast',
            title:
              'Sales: <%- date?.getInfo(-20)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay19InPast',
            title:
              'Sales: <%- date?.getInfo(-19)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay18InPast',
            title:
              'Sales: <%- date?.getInfo(-18)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay17InPast',
            title:
              'Sales: <%- date?.getInfo(-17)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay16InPast',
            title:
              'Sales: <%- date?.getInfo(-16)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay15InPast',
            title:
              'Sales: <%- date?.getInfo(-15)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay14InPast',
            title:
              'Sales: <%- date?.getInfo(-14)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay13InPast',
            title:
              'Sales: <%- date?.getInfo(-13)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay12InPast',
            title:
              'Sales: <%- date?.getInfo(-12)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay11InPast',
            title:
              'Sales: <%- date?.getInfo(-11)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay10InPast',
            title:
              'Sales: <%- date?.getInfo(-10)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay9InPast',
            title:
              'Sales: <%- date?.getInfo(-9)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay8InPast',
            title:
              'Sales: <%- date?.getInfo(-8)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay7InPast',
            title:
              'Sales: <%- date?.getInfo(-7)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay6InPast',
            title:
              'Sales: <%- date?.getInfo(-6)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay5InPast',
            title:
              'Sales: <%- date?.getInfo(-5)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay4InPast',
            title:
              'Sales: <%- date?.getInfo(-4)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay3InPast',
            title:
              'Sales: <%- date?.getInfo(-3)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay2InPast',
            title:
              'Sales: <%- date?.getInfo(-2)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay1InPast',
            title:
              'Sales: <%- date?.getInfo(-1)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'netSalesDay0InPast',
            title:
              'Sales: <%- date?.getInfo(0)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay27InPast',
            title:
              'Check Average: <%- date?.getInfo(-27)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay26InPast',
            title:
              'Check Average: <%- date?.getInfo(-26)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay25InPast',
            title:
              'Check Average: <%- date?.getInfo(-25)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay24InPast',
            title:
              'Check Average: <%- date?.getInfo(-24)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay23InPast',
            title:
              'Check Average: <%- date?.getInfo(-23)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay22InPast',
            title:
              'Check Average: <%- date?.getInfo(-22)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay21InPast',
            title:
              'Check Average: <%- date?.getInfo(-21)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay20InPast',
            title:
              'Check Average: <%- date?.getInfo(-20)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay19InPast',
            title:
              'Check Average: <%- date?.getInfo(-19)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay18InPast',
            title:
              'Check Average: <%- date?.getInfo(-18)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay17InPast',
            title:
              'Check Average: <%- date?.getInfo(-17)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay16InPast',
            title:
              'Check Average: <%- date?.getInfo(-16)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay15InPast',
            title:
              'Check Average: <%- date?.getInfo(-15)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay14InPast',
            title:
              'Check Average: <%- date?.getInfo(-14)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay13InPast',
            title:
              'Check Average: <%- date?.getInfo(-13)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay12InPast',
            title:
              'Check Average: <%- date?.getInfo(-12)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay11InPast',
            title:
              'Check Average: <%- date?.getInfo(-11)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay10InPast',
            title:
              'Check Average: <%- date?.getInfo(-10)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay9InPast',
            title:
              'Check Average: <%- date?.getInfo(-9)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay8InPast',
            title:
              'Check Average: <%- date?.getInfo(-8)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay7InPast',
            title:
              'Check Average: <%- date?.getInfo(-7)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay6InPast',
            title:
              'Check Average: <%- date?.getInfo(-6)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay5InPast',
            title:
              'Check Average: <%- date?.getInfo(-5)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay4InPast',
            title:
              'Check Average: <%- date?.getInfo(-4)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay3InPast',
            title:
              'Check Average: <%- date?.getInfo(-3)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay2InPast',
            title:
              'Check Average: <%- date?.getInfo(-2)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay1InPast',
            title:
              'Check Average: <%- date?.getInfo(-1)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkAverageDay0InPast',
            title:
              'Check Average: <%- date?.getInfo(0)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay27InPast',
            title:
              'Tickets: <%- date?.getInfo(-27)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay26InPast',
            title:
              'Tickets: <%- date?.getInfo(-26)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay25InPast',
            title:
              'Tickets: <%- date?.getInfo(-25)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay24InPast',
            title:
              'Tickets: <%- date?.getInfo(-24)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay23InPast',
            title:
              'Tickets: <%- date?.getInfo(-23)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay22InPast',
            title:
              'Tickets: <%- date?.getInfo(-22)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay21InPast',
            title:
              'Tickets: <%- date?.getInfo(-21)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay20InPast',
            title:
              'Tickets: <%- date?.getInfo(-20)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay19InPast',
            title:
              'Tickets: <%- date?.getInfo(-19)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay18InPast',
            title:
              'Tickets: <%- date?.getInfo(-18)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay17InPast',
            title:
              'Tickets: <%- date?.getInfo(-17)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay16InPast',
            title:
              'Tickets: <%- date?.getInfo(-16)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay15InPast',
            title:
              'Tickets: <%- date?.getInfo(-15)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay14InPast',
            title:
              'Tickets: <%- date?.getInfo(-14)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay13InPast',
            title:
              'Tickets: <%- date?.getInfo(-13)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay12InPast',
            title:
              'Tickets: <%- date?.getInfo(-12)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay11InPast',
            title:
              'Tickets: <%- date?.getInfo(-11)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay10InPast',
            title:
              'Tickets: <%- date?.getInfo(-10)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay9InPast',
            title:
              'Tickets: <%- date?.getInfo(-9)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay8InPast',
            title:
              'Tickets: <%- date?.getInfo(-8)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay7InPast',
            title:
              'Tickets: <%- date?.getInfo(-7)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay6InPast',
            title:
              'Tickets: <%- date?.getInfo(-6)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay5InPast',
            title:
              'Tickets: <%- date?.getInfo(-5)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay4InPast',
            title:
              'Tickets: <%- date?.getInfo(-4)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay3InPast',
            title:
              'Tickets: <%- date?.getInfo(-3)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay2InPast',
            title:
              'Tickets: <%- date?.getInfo(-2)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay1InPast',
            title:
              'Tickets: <%- date?.getInfo(-1)?.dateRange.startDate.format("YY/M/D") %>',
          },
          {
            key: 'checkCountDay0InPast',
            title:
              'Tickets: <%- date?.getInfo(0)?.dateRange.startDate.format("YY/M/D") %>',
          },
        ],
      },
      dailySssPercentTable: {
        type: 'table-v2',
        title: 'Daily SSS %',
        api: 'fwWingstopLastTwentyEightDaysTrendData',
        rowPinning: {
          top: ['summary'],
        },
        columns: [
          {
            key: 'market',
            title: 'Market',
            hide: '<%- _.isUndefined(corporateGroup?.locations) %>',
            sortable: true,
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns.slice(0, 1)) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            width: 'initial',
            sortable: true,
            fixed: true,
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns.slice(1, 2)) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            width: 'initial',
            sortable: true,
          },
          {
            key: 'Day27InPast',
            title: '<%- date?.getInfo(-27)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay27InPast',
                title:
                  '<%- date?.getInfo(-27)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day26InPast',
            title: '<%- date?.getInfo(-26)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay26InPast',
                title:
                  '<%- date?.getInfo(-26)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day25InPast',
            title: '<%- date?.getInfo(-25)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay25InPast',
                title:
                  '<%- date?.getInfo(-25)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day24InPast',
            title: '<%- date?.getInfo(-24)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay24InPast',
                title:
                  '<%- date?.getInfo(-24)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day23InPast',
            title: '<%- date?.getInfo(-23)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay23InPast',
                title:
                  '<%- date?.getInfo(-23)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day22InPast',
            title: '<%- date?.getInfo(-22)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay22InPast',
                title:
                  '<%- date?.getInfo(-22)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day21InPast',
            title: '<%- date?.getInfo(-21)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay21InPast',
                title:
                  '<%- date?.getInfo(-21)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day20InPast',
            title: '<%- date?.getInfo(-20)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay20InPast',
                title:
                  '<%- date?.getInfo(-20)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day19InPast',
            title: '<%- date?.getInfo(-19)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay19InPast',
                title:
                  '<%- date?.getInfo(-19)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day18InPast',
            title: '<%- date?.getInfo(-18)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay18InPast',
                title:
                  '<%- date?.getInfo(-18)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day17InPast',
            title: '<%- date?.getInfo(-17)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay17InPast',
                title:
                  '<%- date?.getInfo(-17)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day16InPast',
            title: '<%- date?.getInfo(-16)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay16InPast',
                title:
                  '<%- date?.getInfo(-16)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day15InPast',
            title: '<%- date?.getInfo(-15)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay15InPast',
                title:
                  '<%- date?.getInfo(-15)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day14InPast',
            title: '<%- date?.getInfo(-14)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay14InPast',
                title:
                  '<%- date?.getInfo(-14)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day13InPast',
            title: '<%- date?.getInfo(-13)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay13InPast',
                title:
                  '<%- date?.getInfo(-13)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day12InPast',
            title: '<%- date?.getInfo(-12)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay12InPast',
                title:
                  '<%- date?.getInfo(-12)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day11InPast',
            title: '<%- date?.getInfo(-11)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay11InPast',
                title:
                  '<%- date?.getInfo(-11)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day10InPast',
            title: '<%- date?.getInfo(-10)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay10InPast',
                title:
                  '<%- date?.getInfo(-10)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day9InPast',
            title: '<%- date?.getInfo(-9)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay9InPast',
                title:
                  '<%- date?.getInfo(-9)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day8InPast',
            title: '<%- date?.getInfo(-8)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay8InPast',
                title:
                  '<%- date?.getInfo(-8)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day7InPast',
            title: '<%- date?.getInfo(-7)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay7InPast',
                title:
                  '<%- date?.getInfo(-7)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day6InPast',
            title: '<%- date?.getInfo(-6)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay6InPast',
                title:
                  '<%- date?.getInfo(-6)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day5InPast',
            title: '<%- date?.getInfo(-5)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay5InPast',
                title:
                  '<%- date?.getInfo(-5)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day4InPast',
            title: '<%- date?.getInfo(-4)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay4InPast',
                title:
                  '<%- date?.getInfo(-4)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day3InPast',
            title: '<%- date?.getInfo(-3)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay3InPast',
                title:
                  '<%- date?.getInfo(-3)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day2InPast',
            title: '<%- date?.getInfo(-2)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay2InPast',
                title:
                  '<%- date?.getInfo(-2)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day1InPast',
            title: '<%- date?.getInfo(-1)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay1InPast',
                title:
                  '<%- date?.getInfo(-1)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day0InPast',
            title: '<%- date?.getInfo(0)?.displayName %>',
            children: [
              {
                key: 'netSalesPercentageDiffToLastYearDay0InPast',
                title:
                  '<%- date?.getInfo(0)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
      salesTable: {
        type: 'table-v2',
        title: 'Sales $',
        api: 'fwWingstopLastTwentyEightDaysTrendData',
        rowPinning: {
          top: ['summary'],
        },
        columns: [
          {
            key: 'market',
            title: 'Market',
            hide: '<%- _.isUndefined(corporateGroup?.locations) %>',
            sortable: true,
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns.slice(0, 1)) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            width: 'initial',
            sortable: true,
            fixed: true,
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns.slice(1, 2)) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            width: 'initial',
            sortable: true,
          },
          {
            key: 'Day27InPast',
            title: '<%- date?.getInfo(-27)?.displayName %>',
            children: [
              {
                key: 'netSalesDay27InPast',
                title:
                  '<%- date?.getInfo(-27)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day26InPast',
            title: '<%- date?.getInfo(-26)?.displayName %>',
            children: [
              {
                key: 'netSalesDay26InPast',
                title:
                  '<%- date?.getInfo(-26)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day25InPast',
            title: '<%- date?.getInfo(-25)?.displayName %>',
            children: [
              {
                key: 'netSalesDay25InPast',
                title:
                  '<%- date?.getInfo(-25)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day24InPast',
            title: '<%- date?.getInfo(-24)?.displayName %>',
            children: [
              {
                key: 'netSalesDay24InPast',
                title:
                  '<%- date?.getInfo(-24)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day23InPast',
            title: '<%- date?.getInfo(-23)?.displayName %>',
            children: [
              {
                key: 'netSalesDay23InPast',
                title:
                  '<%- date?.getInfo(-23)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day22InPast',
            title: '<%- date?.getInfo(-22)?.displayName %>',
            children: [
              {
                key: 'netSalesDay22InPast',
                title:
                  '<%- date?.getInfo(-22)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day21InPast',
            title: '<%- date?.getInfo(-21)?.displayName %>',
            children: [
              {
                key: 'netSalesDay21InPast',
                title:
                  '<%- date?.getInfo(-21)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day20InPast',
            title: '<%- date?.getInfo(-20)?.displayName %>',
            children: [
              {
                key: 'netSalesDay20InPast',
                title:
                  '<%- date?.getInfo(-20)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day19InPast',
            title: '<%- date?.getInfo(-19)?.displayName %>',
            children: [
              {
                key: 'netSalesDay19InPast',
                title:
                  '<%- date?.getInfo(-19)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day18InPast',
            title: '<%- date?.getInfo(-18)?.displayName %>',
            children: [
              {
                key: 'netSalesDay18InPast',
                title:
                  '<%- date?.getInfo(-18)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day17InPast',
            title: '<%- date?.getInfo(-17)?.displayName %>',
            children: [
              {
                key: 'netSalesDay17InPast',
                title:
                  '<%- date?.getInfo(-17)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day16InPast',
            title: '<%- date?.getInfo(-16)?.displayName %>',
            children: [
              {
                key: 'netSalesDay16InPast',
                title:
                  '<%- date?.getInfo(-16)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day15InPast',
            title: '<%- date?.getInfo(-15)?.displayName %>',
            children: [
              {
                key: 'netSalesDay15InPast',
                title:
                  '<%- date?.getInfo(-15)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day14InPast',
            title: '<%- date?.getInfo(-14)?.displayName %>',
            children: [
              {
                key: 'netSalesDay14InPast',
                title:
                  '<%- date?.getInfo(-14)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day13InPast',
            title: '<%- date?.getInfo(-13)?.displayName %>',
            children: [
              {
                key: 'netSalesDay13InPast',
                title:
                  '<%- date?.getInfo(-13)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day12InPast',
            title: '<%- date?.getInfo(-12)?.displayName %>',
            children: [
              {
                key: 'netSalesDay12InPast',
                title:
                  '<%- date?.getInfo(-12)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day11InPast',
            title: '<%- date?.getInfo(-11)?.displayName %>',
            children: [
              {
                key: 'netSalesDay11InPast',
                title:
                  '<%- date?.getInfo(-11)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day10InPast',
            title: '<%- date?.getInfo(-10)?.displayName %>',
            children: [
              {
                key: 'netSalesDay10InPast',
                title:
                  '<%- date?.getInfo(-10)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day9InPast',
            title: '<%- date?.getInfo(-9)?.displayName %>',
            children: [
              {
                key: 'netSalesDay9InPast',
                title:
                  '<%- date?.getInfo(-9)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day8InPast',
            title: '<%- date?.getInfo(-8)?.displayName %>',
            children: [
              {
                key: 'netSalesDay8InPast',
                title:
                  '<%- date?.getInfo(-8)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day7InPast',
            title: '<%- date?.getInfo(-7)?.displayName %>',
            children: [
              {
                key: 'netSalesDay7InPast',
                title:
                  '<%- date?.getInfo(-7)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day6InPast',
            title: '<%- date?.getInfo(-6)?.displayName %>',
            children: [
              {
                key: 'netSalesDay6InPast',
                title:
                  '<%- date?.getInfo(-6)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day5InPast',
            title: '<%- date?.getInfo(-5)?.displayName %>',
            children: [
              {
                key: 'netSalesDay5InPast',
                title:
                  '<%- date?.getInfo(-5)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day4InPast',
            title: '<%- date?.getInfo(-4)?.displayName %>',
            children: [
              {
                key: 'netSalesDay4InPast',
                title:
                  '<%- date?.getInfo(-4)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day3InPast',
            title: '<%- date?.getInfo(-3)?.displayName %>',
            children: [
              {
                key: 'netSalesDay3InPast',
                title:
                  '<%- date?.getInfo(-3)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day2InPast',
            title: '<%- date?.getInfo(-2)?.displayName %>',
            children: [
              {
                key: 'netSalesDay2InPast',
                title:
                  '<%- date?.getInfo(-2)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day1InPast',
            title: '<%- date?.getInfo(-1)?.displayName %>',
            children: [
              {
                key: 'netSalesDay1InPast',
                title:
                  '<%- date?.getInfo(-1)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day0InPast',
            title: '<%- date?.getInfo(0)?.displayName %>',
            children: [
              {
                key: 'netSalesDay0InPast',
                title:
                  '<%- date?.getInfo(0)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
      dailyCheckAverageTable: {
        type: 'table-v2',
        title: 'Daily Check Average $',
        api: 'fwWingstopLastTwentyEightDaysTrendData',
        rowPinning: {
          top: ['summary'],
        },
        columns: [
          {
            key: 'market',
            title: 'Market',
            hide: '<%- _.isUndefined(corporateGroup?.locations) %>',
            sortable: true,
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns.slice(0, 1)) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            width: 'initial',
            sortable: true,
            fixed: true,
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns.slice(1, 2)) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            width: 'initial',
            sortable: true,
          },
          {
            key: 'Day27InPast',
            title: '<%- date?.getInfo(-27)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay27InPast',
                title:
                  '<%- date?.getInfo(-27)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day26InPast',
            title: '<%- date?.getInfo(-26)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay26InPast',
                title:
                  '<%- date?.getInfo(-26)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day25InPast',
            title: '<%- date?.getInfo(-25)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay25InPast',
                title:
                  '<%- date?.getInfo(-25)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day24InPast',
            title: '<%- date?.getInfo(-24)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay24InPast',
                title:
                  '<%- date?.getInfo(-24)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day23InPast',
            title: '<%- date?.getInfo(-23)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay23InPast',
                title:
                  '<%- date?.getInfo(-23)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day22InPast',
            title: '<%- date?.getInfo(-22)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay22InPast',
                title:
                  '<%- date?.getInfo(-22)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day21InPast',
            title: '<%- date?.getInfo(-21)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay21InPast',
                title:
                  '<%- date?.getInfo(-21)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day20InPast',
            title: '<%- date?.getInfo(-20)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay20InPast',
                title:
                  '<%- date?.getInfo(-20)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day19InPast',
            title: '<%- date?.getInfo(-19)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay19InPast',
                title:
                  '<%- date?.getInfo(-19)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day18InPast',
            title: '<%- date?.getInfo(-18)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay18InPast',
                title:
                  '<%- date?.getInfo(-18)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day17InPast',
            title: '<%- date?.getInfo(-17)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay17InPast',
                title:
                  '<%- date?.getInfo(-17)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day16InPast',
            title: '<%- date?.getInfo(-16)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay16InPast',
                title:
                  '<%- date?.getInfo(-16)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day15InPast',
            title: '<%- date?.getInfo(-15)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay15InPast',
                title:
                  '<%- date?.getInfo(-15)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day14InPast',
            title: '<%- date?.getInfo(-14)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay14InPast',
                title:
                  '<%- date?.getInfo(-14)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day13InPast',
            title: '<%- date?.getInfo(-13)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay13InPast',
                title:
                  '<%- date?.getInfo(-13)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day12InPast',
            title: '<%- date?.getInfo(-12)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay12InPast',
                title:
                  '<%- date?.getInfo(-12)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day11InPast',
            title: '<%- date?.getInfo(-11)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay11InPast',
                title:
                  '<%- date?.getInfo(-11)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day10InPast',
            title: '<%- date?.getInfo(-10)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay10InPast',
                title:
                  '<%- date?.getInfo(-10)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day9InPast',
            title: '<%- date?.getInfo(-9)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay9InPast',
                title:
                  '<%- date?.getInfo(-9)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day8InPast',
            title: '<%- date?.getInfo(-8)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay8InPast',
                title:
                  '<%- date?.getInfo(-8)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day7InPast',
            title: '<%- date?.getInfo(-7)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay7InPast',
                title:
                  '<%- date?.getInfo(-7)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day6InPast',
            title: '<%- date?.getInfo(-6)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay6InPast',
                title:
                  '<%- date?.getInfo(-6)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day5InPast',
            title: '<%- date?.getInfo(-5)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay5InPast',
                title:
                  '<%- date?.getInfo(-5)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day4InPast',
            title: '<%- date?.getInfo(-4)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay4InPast',
                title:
                  '<%- date?.getInfo(-4)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day3InPast',
            title: '<%- date?.getInfo(-3)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay3InPast',
                title:
                  '<%- date?.getInfo(-3)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day2InPast',
            title: '<%- date?.getInfo(-2)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay2InPast',
                title:
                  '<%- date?.getInfo(-2)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day1InPast',
            title: '<%- date?.getInfo(-1)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay1InPast',
                title:
                  '<%- date?.getInfo(-1)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day0InPast',
            title: '<%- date?.getInfo(0)?.displayName %>',
            children: [
              {
                key: 'checkAverageDay0InPast',
                title:
                  '<%- date?.getInfo(0)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
      dailyTicketsTable: {
        type: 'table-v2',
        title: 'Daily Tickets #',
        api: 'fwWingstopLastTwentyEightDaysTrendData',
        rowPinning: {
          top: ['summary'],
        },
        columns: [
          {
            key: 'market',
            title: 'Market',
            hide: '<%- _.isUndefined(corporateGroup?.locations) %>',
            sortable: true,
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns.slice(0, 1)) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            width: 'initial',
            sortable: true,
            fixed: true,
          },
          {
            key: '<%- JSON(corporateGroup?.tableColumns.slice(1, 2)) %>',
            hide: '<%- _.isUndefined(corporateGroup) %>',
            width: 'initial',
            sortable: true,
          },
          {
            key: 'Day27InPast',
            title: '<%- date?.getInfo(-27)?.displayName %>',
            children: [
              {
                key: 'checkCountDay27InPast',
                title:
                  '<%- date?.getInfo(-27)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day26InPast',
            title: '<%- date?.getInfo(-26)?.displayName %>',
            children: [
              {
                key: 'checkCountDay26InPast',
                title:
                  '<%- date?.getInfo(-26)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day25InPast',
            title: '<%- date?.getInfo(-25)?.displayName %>',
            children: [
              {
                key: 'checkCountDay25InPast',
                title:
                  '<%- date?.getInfo(-25)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day24InPast',
            title: '<%- date?.getInfo(-24)?.displayName %>',
            children: [
              {
                key: 'checkCountDay24InPast',
                title:
                  '<%- date?.getInfo(-24)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day23InPast',
            title: '<%- date?.getInfo(-23)?.displayName %>',
            children: [
              {
                key: 'checkCountDay23InPast',
                title:
                  '<%- date?.getInfo(-23)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day22InPast',
            title: '<%- date?.getInfo(-22)?.displayName %>',
            children: [
              {
                key: 'checkCountDay22InPast',
                title:
                  '<%- date?.getInfo(-22)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day21InPast',
            title: '<%- date?.getInfo(-21)?.displayName %>',
            children: [
              {
                key: 'checkCountDay21InPast',
                title:
                  '<%- date?.getInfo(-21)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day20InPast',
            title: '<%- date?.getInfo(-20)?.displayName %>',
            children: [
              {
                key: 'checkCountDay20InPast',
                title:
                  '<%- date?.getInfo(-20)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day19InPast',
            title: '<%- date?.getInfo(-19)?.displayName %>',
            children: [
              {
                key: 'checkCountDay19InPast',
                title:
                  '<%- date?.getInfo(-19)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day18InPast',
            title: '<%- date?.getInfo(-18)?.displayName %>',
            children: [
              {
                key: 'checkCountDay18InPast',
                title:
                  '<%- date?.getInfo(-18)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day17InPast',
            title: '<%- date?.getInfo(-17)?.displayName %>',
            children: [
              {
                key: 'checkCountDay17InPast',
                title:
                  '<%- date?.getInfo(-17)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day16InPast',
            title: '<%- date?.getInfo(-16)?.displayName %>',
            children: [
              {
                key: 'checkCountDay16InPast',
                title:
                  '<%- date?.getInfo(-16)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day15InPast',
            title: '<%- date?.getInfo(-15)?.displayName %>',
            children: [
              {
                key: 'checkCountDay15InPast',
                title:
                  '<%- date?.getInfo(-15)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day14InPast',
            title: '<%- date?.getInfo(-14)?.displayName %>',
            children: [
              {
                key: 'checkCountDay14InPast',
                title:
                  '<%- date?.getInfo(-14)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day13InPast',
            title: '<%- date?.getInfo(-13)?.displayName %>',
            children: [
              {
                key: 'checkCountDay13InPast',
                title:
                  '<%- date?.getInfo(-13)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day12InPast',
            title: '<%- date?.getInfo(-12)?.displayName %>',
            children: [
              {
                key: 'checkCountDay12InPast',
                title:
                  '<%- date?.getInfo(-12)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day11InPast',
            title: '<%- date?.getInfo(-11)?.displayName %>',
            children: [
              {
                key: 'checkCountDay11InPast',
                title:
                  '<%- date?.getInfo(-11)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day10InPast',
            title: '<%- date?.getInfo(-10)?.displayName %>',
            children: [
              {
                key: 'checkCountDay10InPast',
                title:
                  '<%- date?.getInfo(-10)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day9InPast',
            title: '<%- date?.getInfo(-9)?.displayName %>',
            children: [
              {
                key: 'checkCountDay9InPast',
                title:
                  '<%- date?.getInfo(-9)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day8InPast',
            title: '<%- date?.getInfo(-8)?.displayName %>',
            children: [
              {
                key: 'checkCountDay8InPast',
                title:
                  '<%- date?.getInfo(-8)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day7InPast',
            title: '<%- date?.getInfo(-7)?.displayName %>',
            children: [
              {
                key: 'checkCountDay7InPast',
                title:
                  '<%- date?.getInfo(-7)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day6InPast',
            title: '<%- date?.getInfo(-6)?.displayName %>',
            children: [
              {
                key: 'checkCountDay6InPast',
                title:
                  '<%- date?.getInfo(-6)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day5InPast',
            title: '<%- date?.getInfo(-5)?.displayName %>',
            children: [
              {
                key: 'checkCountDay5InPast',
                title:
                  '<%- date?.getInfo(-5)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day4InPast',
            title: '<%- date?.getInfo(-4)?.displayName %>',
            children: [
              {
                key: 'checkCountDay4InPast',
                title:
                  '<%- date?.getInfo(-4)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day3InPast',
            title: '<%- date?.getInfo(-3)?.displayName %>',
            children: [
              {
                key: 'checkCountDay3InPast',
                title:
                  '<%- date?.getInfo(-3)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day2InPast',
            title: '<%- date?.getInfo(-2)?.displayName %>',
            children: [
              {
                key: 'checkCountDay2InPast',
                title:
                  '<%- date?.getInfo(-2)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day1InPast',
            title: '<%- date?.getInfo(-1)?.displayName %>',
            children: [
              {
                key: 'checkCountDay1InPast',
                title:
                  '<%- date?.getInfo(-1)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
          {
            key: 'Day0InPast',
            title: '<%- date?.getInfo(0)?.displayName %>',
            children: [
              {
                key: 'checkCountDay0InPast',
                title:
                  '<%- date?.getInfo(0)?.dateRange.startDate.format("ddd") %>',
                align: 'center',
                sortable: true,
              },
            ],
          },
        ],
      },
    },
  },
}

export default fwWingstopDailySalesKpisReport
