import { getBrandSettings } from 'pared/customer'

export function getSaleTypeDisplayNames(): { [saleType: string]: string } {
  const brandSettings = getBrandSettings()
  const salesmanshipLabels = brandSettings.labels.salesmanship

  return {
    gross_sales: salesmanshipLabels.totalSales,
    app_sales: salesmanshipLabels.appetizerSales,
    lbw_sales: salesmanshipLabels.lbwSales,
    dessert_sales: salesmanshipLabels.dessertSales,
    total_ppa: salesmanshipLabels.totalPpa,
    app_ppa: salesmanshipLabels.appetizerPpa,
    lbw_ppa: salesmanshipLabels.lbwPpa,
    dessert_ppa: salesmanshipLabels.dessertPpa,
    speed_of_service: salesmanshipLabels.speedOfService,
  }
}
