import { IConfigsType } from '../../types'

const fwUploadReportConfig: IConfigsType = {
  fw_wingstop: {
    '/:brand/upload-reports': {
      upload: {
        type: 'upload-reports',
        title: 'Upload Reports',
        uploadRoute: 'upload/labor_tracker_reports',
        reportTypes: ['Labor Tracker Roster'],
      },
      uploadStatus: {
        type: 'table',
        api: 'farwestUploadReportStatus',
        title: 'Upload Events',
        pageSize: 30,
        columns: [
          {
            key: 'time',
            format: 'M/D/YYYY, hh:mm A',
            timezone: -7,
            title: 'Event At (PST)',
          },
          {
            key: 'report',
            title: 'Report Name',
          },
          {
            key: 'status',
            title: 'Status',
          },
          {
            key: 'message',
            title: 'Notes',
          },
        ],
      },
    },
  },
}

export default fwUploadReportConfig
