import { useState } from 'react'
import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'
import COLORS from 'pared/constants/colors'

import AssignTrainingTrackerModal, {
  IPropsType as IAssignTrainingTrackerModalPropsType,
} from './assignTrainingTrackerModal'
import useApi, { IApiKeyType } from './hooks/useApi'

export interface IPropsType<K extends IApiKeyType = IApiKeyType> {
  type: 'tracker-subtitle'
  api: K
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const Text = styled.div`
  max-width: 500px;
  margin-top: 20px;
  overflow-wrap: break-word;
  font-size: 14px;
  font-weight: 600;
`

const AssignText = styled.div`
  display: inline-block;
  margin-left: 16px;
  font-size: 14px;
  color: ${COLORS.Plum};
  cursor: pointer;
  font-weight: 300;
`

const TrackerSubtitle = ({ api }: IPropsType) => {
  const { data, loading } = useApi(api)
  const [open, setOpen] = useState(false)

  if (loading || !data) return null

  const trackerData = data[0].modalData as IAssignTrainingTrackerModalPropsType

  return (
    <Spin spinning={loading}>
      <Text>
        {data[0].content}
        <AssignText onClick={() => setOpen(true)}>
          {data[0].modalText}
        </AssignText>
      </Text>
      {open && (
        <AssignTrainingTrackerModal
          {...trackerData}
          onClose={() => setOpen(false)}
        />
      )}
    </Spin>
  )
}

export default TrackerSubtitle
