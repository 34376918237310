import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query arbListLocationGroupPrimantiTattle(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupPrimantiTattle(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        avgScore
        negativeReviews
        totalReviews
        numOneStar
        numTwoStar
        numThreeStar
        numFourStar
        numFiveStar
      }
    }
  }
`

export const arbListLocationGroupPrimantiTattleConfigs = {
  avgScore: 'number',
  negativeReviews: 'number',
  totalReviews: 'number',
  numOneStar: 'number',
  numTwoStar: 'number',
  numThreeStar: 'number',
  numFourStar: 'number',
  numFiveStar: 'number',
} as const

const useArbListLocationGroupPrimantiTattle = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_group_ids: groupFilter?.list?.map((g) => g.id) },
    },
    skip: !startDate || !endDate || !groupFilter || hasGroupBy,
  })

  return {
    data: useMemo((): IApiDataType => {
      const listLocationGroupPrimantiTattle = data
        ?.listLocationGroupPrimantiTattle.nodes as
        | {
            locationGroupId: number
          }[]
        | undefined

      if (!listLocationGroupPrimantiTattle) return null

      return {
        source: listLocationGroupPrimantiTattle.map((n) => {
          const groupInfo = groupFilter?.list?.find(
            (g) => g.id === n.locationGroupId,
          )

          return {
            ...n,
            groupInfo,
          }
        }),
      }
    }, [data]),
    loading,
  }
}

export default useArbListLocationGroupPrimantiTattle
