import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

export interface IUnitFilterItemType {
  id: string
  parentId: 'root' | null
  value: string
  text: string
}

export enum INVENTORY_UNIT_TYPE {
  DOLLAR = 'Dollar',
  QUANTITY = 'Quantity',
}

interface IUnitFilterType {
  unitFilters: IUnitFilterItemType[]
  initialUnit: INVENTORY_UNIT_TYPE
}

interface IUnitFiltersConfigType {
  inventory: IUnitFilterType
  purchases: IUnitFilterType
}

const defaultMenuItemList: IUnitFilterItemType[] = [
  {
    id: 'root',
    parentId: null,
    value: 'root',
    text: 'root',
  },
  {
    id: INVENTORY_UNIT_TYPE.DOLLAR,
    parentId: 'root' as const,
    value: INVENTORY_UNIT_TYPE.DOLLAR,
    text: INVENTORY_UNIT_TYPE.DOLLAR,
  },
  {
    id: INVENTORY_UNIT_TYPE.QUANTITY,
    parentId: 'root' as const,
    value: INVENTORY_UNIT_TYPE.QUANTITY,
    text: INVENTORY_UNIT_TYPE.QUANTITY,
  },
]

const dollarOnlyMenuItemList: IUnitFilterItemType[] = [
  {
    id: 'root',
    parentId: null,
    value: 'root',
    text: 'root',
  },
  {
    id: INVENTORY_UNIT_TYPE.DOLLAR,
    parentId: 'root' as const,
    value: INVENTORY_UNIT_TYPE.DOLLAR,
    text: INVENTORY_UNIT_TYPE.DOLLAR,
  },
]

const useUnitFiltersConfig = (): IUnitFiltersConfigType => {
  const brand = getBrand()

  return useMemo(() => {
    switch (brand) {
      case 'jsc_bk':
      case 'jsc_bk_nso':
      case 'mwb':
        return {
          inventory: {
            unitFilters: dollarOnlyMenuItemList,
            initialUnit: INVENTORY_UNIT_TYPE.DOLLAR,
          },
          purchases: {
            unitFilters: defaultMenuItemList,
            initialUnit: INVENTORY_UNIT_TYPE.DOLLAR,
          },
        }
      default:
        return {
          inventory: {
            unitFilters: defaultMenuItemList,
            initialUnit: INVENTORY_UNIT_TYPE.DOLLAR,
          },
          purchases: {
            unitFilters: defaultMenuItemList,
            initialUnit: INVENTORY_UNIT_TYPE.DOLLAR,
          },
        }
    }
  }, [brand])
}

export default useUnitFiltersConfig
