import React from 'react'

import navigator from 'pared/Routes/navigator'
import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import WebLargeMain from './WebLargeMain'
import { ISignUpParams } from './index'

interface IProps {
  isLoading: boolean
  onSignUp: (signUpParams: ISignUpParams) => Promise<boolean>
  errorMessage: string
}

function Main({ isLoading, onSignUp, errorMessage }: IProps) {
  const handleSignUp = async (signUpParams: ISignUpParams) => {
    const isSuccess = await onSignUp(signUpParams)
    if (isSuccess) {
      navigator.go(navigator.systemwideOverview())
    }
  }

  return (
    <>
      <LargeScreen>
        <WebLargeMain
          isLoading={isLoading}
          onSignUp={handleSignUp}
          errorMessage={errorMessage}
        />
      </LargeScreen>
      <SmallScreen>
        <WebLargeMain
          isLoading={isLoading}
          onSignUp={handleSignUp}
          errorMessage={errorMessage}
        />
      </SmallScreen>
    </>
  )
}

export default Main
