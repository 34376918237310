import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

interface IHighlightSpan {
  code: string
}

const HighlightSpan = styled.span<IHighlightSpan>`
  color: ${(props) =>
    props.code === 'danger'
      ? COLORS.Pomodoro
      : props.code === 'success'
      ? COLORS.Basil
      : 'black'};
`

export default HighlightSpan
