import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { getBrandSettings } from 'pared/customer'

import Main from './Main'

interface IProps {
  navParams: INavParams
}

export interface IPlayerPlusMinusData {
  name: string
  roleName: string
  tcPlusMinus: number
  gcPlusMinus: number
}

export interface IHandleOrderBy {
  isOrderedByTcPlusMinus: () => boolean
  isOrderedByGcPlusMinus: () => boolean
  orderByTcPlusMinus: () => void
  orderByGcPlusMinus: () => void
}

const LIST_LOCATION_PEAK_TIME_PLAYER_PLUS_MINUS = gql`
  query ListLocationPeakTimePlayerPlusMinus(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $iMinNumShifts: Int!
    $iMaxNumShifts: Int!
  ) {
    listLocationPeakTimePlayerPlusMinus(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iMinNumShifts: $iMinNumShifts
      iMaxNumShifts: $iMaxNumShifts
    ) {
      nodes {
        locationId
        employeeInfo {
          id
          familyName
          preferredName
        }
        roleInfo {
          id
          code
          name
        }
        transactionCountPlusMinus
        guestCountPlusMinus
        dayCount
      }
    }
  }
`

const MIN_NUM_SHIFTS = 10
const MAX_NUM_SHIFTS = 30
const QUERY_DATE_FORMAT = 'YYYY-MM-DD'
const NUM_DAYS_BACK = 60

const ORDER_BY_TC_PLUS_MINUS = 1
const ORDER_BY_GC_PLUS_MINUS = 2

const PlusMinusTable = ({ navParams }: IProps) => {
  const [orderBy, setOrderBy] = useState<number>(ORDER_BY_GC_PLUS_MINUS)

  const brandSettings = getBrandSettings()
  const isQsr = brandSettings.isQsr

  const handleOrderBy: IHandleOrderBy = {
    isOrderedByTcPlusMinus: () => {
      return orderBy === ORDER_BY_TC_PLUS_MINUS
    },
    isOrderedByGcPlusMinus: () => {
      return orderBy === ORDER_BY_GC_PLUS_MINUS
    },
    orderByTcPlusMinus: () => {
      setOrderBy(ORDER_BY_TC_PLUS_MINUS)
    },
    orderByGcPlusMinus: () => {
      setOrderBy(ORDER_BY_GC_PLUS_MINUS)
    },
  }

  const locationId = parseInt(navParams.storeId || '')
  const yesterday = moment().subtract(1, 'day')

  const {
    loading,
    error,
    data: queryResponse,
  } = useQuery(LIST_LOCATION_PEAK_TIME_PLAYER_PLUS_MINUS, {
    variables: {
      iLocationId: locationId,
      iStartDate: yesterday
        .clone()
        .subtract(NUM_DAYS_BACK, 'days')
        .format(QUERY_DATE_FORMAT),
      iEndDate: yesterday.format(QUERY_DATE_FORMAT),
      iMinNumShifts: MIN_NUM_SHIFTS,
      iMaxNumShifts: MAX_NUM_SHIFTS,
    },
  })

  let data: IPlayerPlusMinusData[] = []
  if (
    queryResponse &&
    queryResponse.listLocationPeakTimePlayerPlusMinus &&
    Array.isArray(queryResponse.listLocationPeakTimePlayerPlusMinus.nodes)
  ) {
    data = queryResponse.listLocationPeakTimePlayerPlusMinus.nodes.map(
      (queryData: any) => {
        let employeeName = '-'
        if (queryData.employeeInfo && queryData.employeeInfo.id) {
          employeeName = [
            queryData.employeeInfo.preferredName,
            queryData.employeeInfo.familyName,
          ].join(' ')
        }

        let roleName = '-'
        if (
          queryData.roleInfo &&
          queryData.roleInfo.id &&
          queryData.roleInfo.code !== 'UNKNOWN'
        ) {
          roleName = queryData.roleInfo.name
        }

        return {
          roleName,
          name: employeeName,
          tcPlusMinus: queryData.transactionCountPlusMinus,
          gcPlusMinus: queryData.guestCountPlusMinus,
        }
      },
    )
  }

  const tcPlusMinusValues = data.map(({ tcPlusMinus }) => tcPlusMinus)
  const tcMinMax = {
    min: Math.min(...tcPlusMinusValues),
    max: Math.max(...tcPlusMinusValues),
  }

  const gcPlusMinusValues = data.map(({ gcPlusMinus }) => gcPlusMinus)
  const gcMinMax = {
    min: Math.min(...gcPlusMinusValues),
    max: Math.max(...gcPlusMinusValues),
  }

  const roles = Array.from(new Set(data.map(({ roleName }) => roleName))).sort()

  let orderedData = []
  if (isQsr || orderBy === ORDER_BY_TC_PLUS_MINUS) {
    orderedData = data.sort((a, b) => b.tcPlusMinus - a.tcPlusMinus)
  } else {
    orderedData = data.sort((a, b) => b.gcPlusMinus - a.gcPlusMinus)
  }

  return (
    <Main
      data={orderedData}
      tcMinMax={tcMinMax}
      gcMinMax={gcMinMax}
      roles={roles}
      handleOrderBy={handleOrderBy}
    />
  )
}

export default PlusMinusTable
