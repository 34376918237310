import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import { getBrandId } from 'pared/utils/brand'

import {
  GET_STORE_LIST,
  ILocationCreditCardReconciliationType,
  ILocationCreditCardReconciliationVariableType,
  ILocationGroupCreditCardReconciliationType,
  ILocationGroupCreditCardReconciliationVariableType,
  ILocationInfType,
  ILocationInfoVariableType,
  ISummaryLocationGroupCreditCardReconciliationType,
  ISummaryLocationGroupCreditCardReconciliationVariableType,
  LIST_LOCATION_CREDIT_CARD_RECONCILIATION,
  LIST_LOCATION_GROUP_CREDIT_CARD_RECONCILIATION,
  SUMMARY_LOCATION_GROUP_CREDIT_CARD_RECONCILIATION,
} from '../gql'
import { SortDirectionType } from '../type'
import { sorterConfig } from './useColumnSorter'

export interface IDataSourceType {
  rowType: 'header' | 'detail'
  groupInfo:
    | {
        locationId: number
        locationCode: string
        locationName: string
        directorId: string | number
        directorName: string
      }
    | {
        locationGroupId: number
        locationGroupName: string
      }
    | null
  creditCardType: string
  salesAmount: number
  paymentAmount: number
  overUnderAmount: number
  overUnderPercent: number
}

const useDataSource = (
  brand: string,
  isBreakdownByDirectors: boolean,
  locationGroupIds: number[],
  groupByType: string,
  clickedHeaders: Set<string>,
  selectedColumn: string,
  selectedDirection: SortDirectionType,
): {
  dataSource: IDataSourceType[]
  csvDataSource: IDataSourceType[]
  loading: boolean
} => {
  const brandId = getBrandId()
  const dateRange = useGetDateRange({ isCustomAllowed: true })

  const { data: storeList, loading: storeListLoading } = useQuery<
    ILocationInfType,
    ILocationInfoVariableType
  >(GET_STORE_LIST, {
    variables: {
      iFilter: {
        location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]],
      },
    },
  })

  const {
    data: locationCreditCardReconciliationData,
    loading: locationCreditCardReconciliationLoading,
  } = useQuery<
    ILocationCreditCardReconciliationType,
    ILocationCreditCardReconciliationVariableType
  >(LIST_LOCATION_CREDIT_CARD_RECONCILIATION, {
    variables: {
      iStartDate: dateRange?.startDateStr || null,
      iEndDate: dateRange?.endDateStr || null,
      iFilter: {
        location_group_ids: locationGroupIds,
      },
    },
    skip: !dateRange || isBreakdownByDirectors,
  })

  const {
    data: locationGroupCreditCardReconciliationData,
    loading: locationGroupCreditCardReconciliationLoading,
  } = useQuery<
    ILocationGroupCreditCardReconciliationType,
    ILocationGroupCreditCardReconciliationVariableType
  >(LIST_LOCATION_GROUP_CREDIT_CARD_RECONCILIATION, {
    variables: {
      iStartDate: dateRange?.startDateStr || null,
      iEndDate: dateRange?.endDateStr || null,
      iFilter: {
        location_group_ids: groupByType !== 'store' ? null : locationGroupIds,
        location_group_types: groupByType !== 'store' ? [groupByType] : null,
        brand_ids: [brandId],
      },
    },
    skip: !dateRange || !isBreakdownByDirectors,
  })

  const {
    data: summaryLocationGroupCreditCardReconciliationData,
    loading: summaryLocationGroupCreditCardReconciliationLoading,
  } = useQuery<
    ISummaryLocationGroupCreditCardReconciliationType,
    ISummaryLocationGroupCreditCardReconciliationVariableType
  >(SUMMARY_LOCATION_GROUP_CREDIT_CARD_RECONCILIATION, {
    variables: {
      iStartDate: dateRange?.startDateStr || null,
      iEndDate: dateRange?.endDateStr || null,
      iFilter: {
        location_group_ids: groupByType !== 'store' ? null : locationGroupIds,
        location_group_types: groupByType !== 'store' ? [groupByType] : null,
        brand_ids: [brandId],
      },
    },
    skip: !dateRange,
  })

  const loading =
    storeListLoading ||
    summaryLocationGroupCreditCardReconciliationLoading ||
    (!isBreakdownByDirectors
      ? locationCreditCardReconciliationLoading
      : locationGroupCreditCardReconciliationLoading)

  const { dataSource, csvDataSource } = useMemo(() => {
    if (
      !locationCreditCardReconciliationData?.listLocationCreditCardReconciliation &&
      !locationGroupCreditCardReconciliationData?.listLocationGroupCreditCardReconciliation &&
      !summaryLocationGroupCreditCardReconciliationData?.summaryLocationGroupCreditCardReconciliation
    )
      return { dataSource: [], csvDataSource: [] }

    const summaryData =
      summaryLocationGroupCreditCardReconciliationData?.summaryLocationGroupCreditCardReconciliation.nodes?.map(
        (row) => ({
          rowType:
            row.creditCardType === 'TOTAL'
              ? ('header' as const)
              : ('detail' as const),
          groupInfo: null,
          creditCardType: row.creditCardType,
          salesAmount: row.totalSalesAmount,
          paymentAmount: row.totalPaymentAmount,
          overUnderAmount: row.totalOverUnderAmount,
          overUnderPercent: row.totalOverUnderPercent,
        }),
      ) || []

    if (!isBreakdownByDirectors) {
      if (
        !locationCreditCardReconciliationData?.listLocationCreditCardReconciliation
      ) {
        return { dataSource: [], csvDataSource: [] }
      }

      const locationData =
        locationCreditCardReconciliationData.listLocationCreditCardReconciliation.nodes?.map(
          (row) => {
            const store = storeList?.listLocationDetails?.nodes?.find(
              ({ id }: any) => row?.locationId === id,
            )

            return {
              rowType:
                row.creditCardType === 'TOTAL'
                  ? ('header' as const)
                  : ('detail' as const),
              groupInfo: {
                locationId: store?.id || -1,
                locationCode: store?.code || 'Unkown',
                locationName: store?.name || 'Unkown',
                directorId: store?.directorEmployeeInfo?.id || 0,
                directorName: [
                  store?.directorEmployeeInfo?.preferredName || 'Unkown',
                  store?.directorEmployeeInfo?.familyName || 'Unkown',
                ]
                  .filter(Boolean)
                  .join(' '),
              },
              creditCardType: row.creditCardType,
              salesAmount: row.totalSalesAmount,
              paymentAmount: row.totalPaymentAmount,
              overUnderAmount: row.overUnderAmount,
              overUnderPercent: row.overUnderPercent,
            }
          },
        ) || []

      const sorterLocationHeaderData = sorterConfig
        .find(({ key }) => key == selectedColumn)
        ?.func(
          locationData?.filter((d) => d.rowType == 'header'),
          selectedDirection,
        )

      const sorterLocationData =
        sorterLocationHeaderData?.reduce(
          (result, sd) => [
            ...result,
            ...locationData?.filter((ld) => {
              if (!sd.groupInfo) return false

              return 'locationCode' in sd.groupInfo
                ? ld.groupInfo.locationCode == sd.groupInfo.locationCode
                : false
            }),
          ],
          [] as IDataSourceType[],
        ) || []

      return {
        csvDataSource: sorterLocationData.concat(summaryData),
        dataSource: sorterLocationData
          ?.filter((d) => {
            if (!d.groupInfo) return false
            if (!('locationCode' in d.groupInfo)) return false

            const name = `${d.groupInfo.locationCode} - ${d.groupInfo.locationName}`
            if (!clickedHeaders.has(name)) {
              return d.rowType == 'header'
            }

            return true
          })
          .concat(summaryData),
      }
    } else {
      if (
        !locationGroupCreditCardReconciliationData?.listLocationGroupCreditCardReconciliation
      ) {
        return { dataSource: [], csvDataSource: [] }
      }

      const locationGroupData =
        locationGroupCreditCardReconciliationData?.listLocationGroupCreditCardReconciliation.nodes?.map(
          (row) => {
            return {
              rowType:
                row.creditCardType === 'TOTAL'
                  ? ('header' as const)
                  : ('detail' as const),
              groupInfo: {
                locationGroupId: row.locationGroupId,
                locationGroupName: row.locationGroupName,
              },
              creditCardType: row.creditCardType,
              salesAmount: row.totalSalesAmount,
              paymentAmount: row.totalPaymentAmount,
              overUnderAmount: row.overUnderAmount,
              overUnderPercent: row.overUnderPercent,
            }
          },
        ) || []

      const sorterLocationGroupHeaderData = sorterConfig
        .find(({ key }) => key == selectedColumn)
        ?.func(
          locationGroupData?.filter((d) => d.rowType == 'header'),
          selectedDirection,
        )

      const sorterLocationGroupData =
        sorterLocationGroupHeaderData?.reduce(
          (result, sd) => [
            ...result,
            ...locationGroupData?.filter((lgd) => {
              if (!sd.groupInfo) return false

              return 'locationGroupName' in sd.groupInfo
                ? lgd.groupInfo.locationGroupName ==
                    sd.groupInfo.locationGroupName
                : false
            }),
          ],
          [] as IDataSourceType[],
        ) || []

      return {
        csvDataSource: sorterLocationGroupData.concat(summaryData),
        dataSource: sorterLocationGroupData
          ?.filter((d) => {
            if (!d.groupInfo) return false
            if (!('locationGroupName' in d.groupInfo)) return false

            if (!clickedHeaders.has(d.groupInfo.locationGroupName)) {
              return d.rowType == 'header'
            }

            return true
          })
          .concat(summaryData),
      }
    }
  }, [
    storeList,
    summaryLocationGroupCreditCardReconciliationData,
    locationCreditCardReconciliationData,
    locationGroupCreditCardReconciliationData,
    loading,
    locationGroupIds,
    groupByType,
    clickedHeaders,
    selectedColumn,
    selectedDirection,
  ])

  return {
    dataSource,
    csvDataSource,
    loading,
  }
}

export default useDataSource
