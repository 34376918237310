import useJscBaseballCard from './useJscBaseballCard'
import useLocationInfo from './useLocationInfo'

export type IApiKeyType = keyof typeof api

const api = {
  locationInfo: useLocationInfo,
  jscBaseballCard: useJscBaseballCard,
}

const apiKeys = Object.keys(api) as (keyof typeof api)[]

const useApi = (apiName?: IApiKeyType | string) =>
  apiName && apiKeys.includes(apiName as IApiKeyType)
    ? api[apiName as IApiKeyType]()
    : { data: apiName, loading: false }

export default useApi
