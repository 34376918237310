import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const burgerworksListLocationLossPreventionKpisConfigs = {
  discountCompsTotal: 'price',
  discountCheckCountTotal: 'number',
  lossPreventionCompsDeletes: 'price',
  lossPreventionCompsOverrings: 'price',
  lossPreventionCompsRefunds: 'price',
  lossPreventionCompsVoids: 'price',
} as const

const useBurgerworksListLocationLossPreventionKpis = buildMetricValuesHook({
  groupFilterType: 'store',
  metrics: {
    codes: ['discount_comps_total', 'discount_check_count_total'],
    groups: ['Expo Module - Loss Prevention Comps'],
  },
})

export default useBurgerworksListLocationLossPreventionKpis
