import Chart from 'chart.js/auto'
import _ from 'lodash'

export interface IYAxisData {
  type: string
  tooltipLabel: string
  data: number[]
  yAxisId?: string
  color?: string
  borderColor?: string
  backgroundColor?: string
  hidden?: boolean
  spanGaps?: boolean
}

interface IChartOptions {
  title?: string
  xAxisLabel?: string
  xTickCallback?: (value: any, index: number, values: any[]) => string
  xShowGridLines?: boolean
  yLeftAxisLabel?: string
  yLeftTickCallback?: (value: any, index: number, values: any[]) => string
  yLeftShowGridLines?: boolean
  yRightAxisLabel?: string
  yRightTickCallback?: (value: any, index: number, values: any[]) => string
  yRightTickStepSize?: number
  yRightShowGridLines?: boolean
  yRightBeginAtZero?: boolean
  tooltipTitleCallback?: (tooltipItemContext: any[]) => string
  tooltipLabelCallback?: (tooltipItemContext: any) => string
  legendClickHandler?: (e: any, legendItem: any, legend: any) => void
}

function drawMixedChart(
  canvasContext: any,
  xAxisData: any[],
  yAxisDataArr: IYAxisData[],
  options?: IChartOptions,
) {
  let mixedChart: Chart

  // read customized chart options
  let title: any = { display: false }
  let xAxisLabel = ''
  let xTicks: any = {}
  let xShowGridLines: boolean = true
  let yLeftAxisLabel = ''
  let yLeftTicks: any = {}
  let yLeftShowGridLines: boolean = true
  let yRightAxisLabel = ''
  let yRightTicks: any = {}
  let yRightShowGridLines: boolean = true
  let yRightBeginAtZero: boolean = false
  let tooltip: any = {}
  let legendClickHandler

  if (options) {
    if (options.title) {
      title = {
        display: true,
        text: options.title,
      }
    }

    if (options.xAxisLabel) {
      xAxisLabel = options.xAxisLabel
    }
    if (options.xTickCallback) {
      xTicks.callback = options.xTickCallback
    }
    if (options.xShowGridLines === false) {
      xShowGridLines = false
    }

    if (options.yLeftAxisLabel) {
      yLeftAxisLabel = options.yLeftAxisLabel
    }
    if (options.yLeftTickCallback) {
      yLeftTicks.callback = options.yLeftTickCallback
    }
    if (options.yLeftShowGridLines === false) {
      yLeftShowGridLines = false
    }

    if (options.yRightAxisLabel) {
      yRightAxisLabel = options.yRightAxisLabel
    }
    if (options.yRightTickCallback) {
      yRightTicks.callback = options.yRightTickCallback
    }
    if (options.yRightTickStepSize) {
      yRightTicks.stepSize = options.yRightTickStepSize
    }
    if (options.yRightShowGridLines === false) {
      yRightShowGridLines = false
    }
    if (options.yRightBeginAtZero === true) {
      yRightBeginAtZero = true
    }

    if (options.tooltipTitleCallback || options.tooltipLabelCallback) {
      tooltip.callbacks = {}
      if (options.tooltipTitleCallback) {
        tooltip.callbacks.title = options.tooltipTitleCallback
      }
      if (options.tooltipLabelCallback) {
        tooltip.callbacks.label = options.tooltipLabelCallback
      }
    }

    if (options.legendClickHandler) {
      legendClickHandler = options.legendClickHandler
    }
  }

  let hasYLeftAxis: boolean = false
  let hasYRightAxis: boolean = false
  const datasets = yAxisDataArr.map((yAxisData) => {
    const yAxisId = yAxisData.yAxisId ? yAxisData.yAxisId : 'yLeft'
    if (yAxisId === 'yLeft') {
      hasYLeftAxis = true
    } else {
      hasYRightAxis = true
    }

    return {
      type: yAxisData.type,
      label: yAxisData.tooltipLabel,
      yAxisID: yAxisId,
      data: yAxisData.data,
      fill: _.get(yAxisData, 'fill', false),
      borderColor: yAxisData.borderColor,
      backgroundColor: yAxisData.backgroundColor,
      color: yAxisData.color,
      hidden: yAxisData.hidden,
      spanGaps: yAxisData.spanGaps,
    }
  })

  // finalize the chart config
  const chartConfig: any = {
    type: 'line',
    data: {
      datasets,
      labels: xAxisData,
    },
    options: {
      responsive: false,
      plugins: {
        title,
        tooltip,
        legend: {
          onClick: legendClickHandler,
          position: 'bottom',
          labels: {
            filter: (legendItem: any) => {
              if (_.get(legendItem, 'text', '') !== 'Hidden') {
                return true
              }
            },
          },
        },
      },
      animation: false,
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: xAxisLabel,
          },
          ticks: xTicks,
          grid: {
            display: xShowGridLines,
          },
        },
      },
      interaction: {
        mode: 'index',
        intersect: false,
      },
    },
  }

  if (hasYLeftAxis) {
    chartConfig.options.scales.yLeft = {
      display: true,
      title: {
        display: true,
        text: yLeftAxisLabel,
      },
      ticks: yLeftTicks,
      position: 'left',
      grid: {
        display: yLeftShowGridLines,
      },
    }
  }

  if (hasYRightAxis) {
    chartConfig.options.scales.yRight = {
      display: true,
      title: {
        display: true,
        text: yRightAxisLabel,
      },
      ticks: yRightTicks,
      position: 'right',
      grid: {
        display: yRightShowGridLines,
      },
      beginAtZero: yRightBeginAtZero,
    }
  }

  mixedChart = new Chart(canvasContext, chartConfig)

  return mixedChart
}

export default drawMixedChart
