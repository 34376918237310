import { useMemo } from 'react'

import {
  toFormattedInteger,
  toPercentString,
  toUsdString,
} from 'pared/utils/number'

import useLocationGroupSalesmanshipKpiData from '../../hooks/useLocationGroupSalesmanshipKpiData'
import useLocationSalesmanshipKpiData from '../../hooks/useLocationSalesmanshipKpiData'
import useSalesmanshipConfig from '../../hooks/useSalesmanshipConfig'

export interface IKpiDisplayDatum {
  name: string
  value: number
  displayString: string
}

function useBreakdownTableData(
  locationGroupIds: number[] | null,
  locationGroupTypes: string[] | null,
  isBreakdownByDirectors: boolean,
  startDate: string,
  endDate: string,
) {
  const salesmanshipConfig = useSalesmanshipConfig()

  const {
    isLoading: isLocationGroupKpiDataLoading,
    data: locationGroupKpiData,
    sosData: locationGroupSosData,
  } = useLocationGroupSalesmanshipKpiData(
    locationGroupIds,
    locationGroupTypes,
    startDate,
    endDate,
    isBreakdownByDirectors || !!locationGroupTypes,
  )

  const {
    isLoading: isLocationKpiDataLoading,
    data: locationKpiData,
    sosData: locationSosData,
  } = useLocationSalesmanshipKpiData(
    locationGroupIds?.[0],
    startDate,
    endDate,
    !isBreakdownByDirectors,
  )

  return useMemo(() => {
    const breakdownTableColumnDefs =
      salesmanshipConfig?.breakdownTableColumnDefs || []

    const tableData: any[] = []

    const kpiData =
      isBreakdownByDirectors || !!locationGroupTypes
        ? locationGroupKpiData
        : locationKpiData

    let sosDataMap: { [id: number]: any } = {}
    if (salesmanshipConfig?.speedOfService?.isVisible) {
      const sosData =
        isBreakdownByDirectors || !!locationGroupTypes
          ? locationGroupSosData
          : locationSosData

      if (sosData) {
        sosData.forEach((sosDetails: any) => {
          const currentId = sosDetails.locationId || sosDetails.locationGroupId
          if (currentId) {
            sosDataMap[currentId] = sosDetails
          }
        })
      }
    }

    if (Array.isArray(kpiData)) {
      kpiData.forEach((rawData: any) => {
        const processedData: any = {}

        if (isBreakdownByDirectors) {
          processedData.locationGroupId = rawData?.locationGroupId
          processedData.rowKey = processedData.locationGroupId
        } else if (locationGroupTypes) {
          processedData.locationGroupId = rawData?.locationGroupId
          processedData.locationGroupName = rawData?.locationGroupName
          processedData.rowKey = processedData.locationGroupId
        } else {
          processedData.locationInfo = {
            ...rawData?.locationInfo,
            value: rawData?.locationInfo?.code,
          }
          processedData.rowKey = rawData?.locationInfo?.id
        }

        // TODO: extract this into a library
        breakdownTableColumnDefs.forEach((colDef: any) => {
          let value = 0
          let displayString = '-'

          if (colDef.kpiType === 'sales') {
            let salesTypeData: any = {}
            if (colDef.salesType === 'total') {
              salesTypeData = rawData?.totalSalesDetails
            } else {
              salesTypeData =
                rawData?.categorizedSalesDetails?.[colDef.salesType]
            }

            value = salesTypeData?.[colDef.kpiKey] || 0

            switch (colDef.valueType) {
              case 'count':
                displayString = toFormattedInteger(value)
                break
              case 'cent':
                displayString = toUsdString(value / 100.0, colDef.digitNum)
                break
              case 'percent':
                displayString = toPercentString(value, colDef.digitNum)
                break
              default:
              // do nothing
            }
          }

          processedData[colDef.key] = {
            value,
            displayString,
            name: colDef.name,
          }
        })

        if (salesmanshipConfig?.speedOfService?.isVisible) {
          const currentId =
            processedData.locationInfo?.id || processedData.locationGroupId
          const sosDetails = sosDataMap[currentId]?.sosDetails
          salesmanshipConfig.speedOfService.windows.forEach(
            (windowInfo: any) => {
              processedData[windowInfo.type] = {
                name: windowInfo.displayName,
                value: sosDetails?.[windowInfo?.type]?.avgSeconds,
                displayString: sosDetails?.[windowInfo?.type]?.avgSeconds,
              }
            },
          )
        }

        tableData.push(processedData)
      })
    }

    return {
      tableData,
      isLoading: isLocationGroupKpiDataLoading || isLocationKpiDataLoading,
    }
  }, [
    locationGroupIds,
    locationGroupTypes,
    isBreakdownByDirectors,
    startDate,
    endDate,
    salesmanshipConfig,
    locationGroupKpiData,
    isLocationGroupKpiDataLoading,
    locationKpiData,
    isLocationKpiDataLoading,
  ])
}

export default useBreakdownTableData
