import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import COLORS from 'pared/constants/colors'
import { getBusinessLabel } from 'pared/customer'
import {
  GROUP_BY_PERIOD,
  GROUP_BY_QUARTER,
  GROUP_BY_YEAR,
  IDateRange,
  TYPE_QUARTER,
  TYPE_YEAR,
} from 'pared/data/getDateRanges'

import {
  IS_KPI_BETTER_WHEN_HIGH,
  KPIS_BY_UNIT,
  getKpiDisplayNames,
} from '../../constants'
import { GET_LOCATION_KPI_TREND } from '../../gql'
import useConfig from '../../hooks/useConfig'
import Main from './Main'

interface IProps {
  navParams: INavParams
  selectedDateRange: IDateRange
  kpi: string
  storeName: string
}

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

function Chart({ navParams, selectedDateRange, kpi, storeName }: IProps) {
  const config = useConfig()
  const kpiDisplayNames = getKpiDisplayNames()
  const storeId = navParams.storeId
  const locationId = parseInt(storeId || '0', 10) || 0
  const [
    listKpi,
    { loading: listKpiLoading, error: listKpiError, data: listKpiData },
  ] = useLazyQuery(GET_LOCATION_KPI_TREND)

  useEffect(() => {
    let groupBy = ''
    if (selectedDateRange) {
      if (selectedDateRange.type === TYPE_YEAR) {
        groupBy = GROUP_BY_YEAR
      } else if (selectedDateRange.type === TYPE_QUARTER) {
        groupBy = GROUP_BY_QUARTER
      } else {
        groupBy = GROUP_BY_PERIOD
      }
    }

    const periodEndDate = _.get(selectedDateRange, 'endDateStr', '')

    const variables = {
      iKpi: kpi,
      iEndDate: periodEndDate,
      iGroupBy: groupBy,
      iLocationId: locationId,
    }

    listKpi({
      variables,
    })
  }, [locationId, selectedDateRange, kpi])

  let rawKpiMonthly = listKpiData
  let gqlQuery = 'getLocationKpiTrend'

  if (
    listKpiData &&
    listKpiData[gqlQuery] &&
    Array.isArray(listKpiData[gqlQuery].nodes)
  ) {
    rawKpiMonthly = _.get(listKpiData, `${gqlQuery}.nodes`, [])
  }

  const { type: dateRangeType } = selectedDateRange
  const xAxisData = _.map(rawKpiMonthly, (k) => {
    const groupingIndex = _.get(k, 'groupingIndex')
    let year, quarter, period
    if (dateRangeType === 'year') {
      year = groupingIndex
    } else if (dateRangeType === 'quarter') {
      year = Math.floor(groupingIndex / 100)
      quarter = groupingIndex % 100
    } else {
      year = Math.floor(groupingIndex / 100)
      period = groupingIndex % 100
    }

    return period
      ? `P${period} ${year}`
      : quarter
      ? `Q${quarter} ${year}`
      : `Y${year}`
  })

  const yAxisData = _.map(rawKpiMonthly, (k) => {
    const value = _.get(k, 'value', null)
    return value || value === 0 ? value.toFixed(2) : value
  })

  const yAxisSystemwideData = _.map(rawKpiMonthly, (k) => {
    const value = _.get(k, 'average', null)
    return value || value === 0 ? value.toFixed(2) : value
  })

  const yAxisDataArr = [
    {
      type: 'line',
      data: yAxisData,
      tooltipLabel: storeName,
      borderColor: COLORS.Plum,
      backgroundColor: COLORS.Plum,
      spanGaps: true,
      fill: {
        target: 1,
        above: IS_KPI_BETTER_WHEN_HIGH[kpi]
          ? 'hsla(130, 35%, 58%, 0.5)'
          : 'hsla(0, 99%, 69%, 0.5)',
        below: IS_KPI_BETTER_WHEN_HIGH[kpi]
          ? 'hsla(0, 99%, 69%, 0.5)'
          : 'hsla(130, 35%, 58%, 0.5)',
      },
    },
    {
      type: 'line',
      data: yAxisSystemwideData,
      spanGaps: true,
      tooltipLabel: `${
        getBusinessLabel('accessGroupType') || 'Systemwide'
      } Average`,
      borderColor: COLORS.Chalkboard,
      backgroundColor: COLORS.Chalkboard,
    },
  ]

  const chartOptions = {
    title: kpiDisplayNames[kpi],
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftTickCallback: (value: number) => {
      const unit = KPIS_BY_UNIT[kpi]
      return `${value}${unit}`
    },
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let datasetLabel = ''
        let separator = ''
        let yValue = ''
        if (tooltipItemContext.dataset && tooltipItemContext.dataset.label) {
          datasetLabel = tooltipItemContext.dataset.label
          separator = ': '
        }

        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          if (tooltipItemContext.datasetIndex === 0) {
            yValue += `${tooltipItemContext.parsed.y}${KPIS_BY_UNIT[kpi]}`
          } else {
            yValue = `${tooltipItemContext.parsed.y}${KPIS_BY_UNIT[kpi]}`
          }
        }

        return [datasetLabel, separator, yValue].join('')
      }

      return ''
    },
  }

  return (
    <Main
      isLoading={listKpiLoading}
      xAxisData={xAxisData}
      chartOptions={chartOptions}
      yAxisDataArr={yAxisDataArr}
    />
  )
}

export default Chart
