import * as d3 from 'd3-hierarchy'
import { useEffect, useMemo } from 'react'

import { IValueType } from '../MenuItem'

interface IValidateItemsOptionsType<Value extends IValueType> {
  value: Value['value'][]
  onChange: (value: Value['value'][]) => void
  option: d3.HierarchyNode<Value>
}

const findNode = <Value extends IValueType>({
  value,
  option,
}: Omit<IValidateItemsOptionsType<Value>, 'onChange'>): Value[] => {
  if (!option.children || value.length === 0) return []

  const subOption = option.children.find(
    ({ data: { value: childValue } }) => value[0] === childValue,
  )

  if (!subOption) return []

  return [
    subOption.data,
    ...findNode({
      value: value.slice(1),
      option: subOption,
    }),
  ]
}

const getFirstNodes = <Value extends IValueType>({
  data: { value },
  children,
}: d3.HierarchyNode<Value>): Value['value'][] => [
  value,
  ...(!children ? [] : getFirstNodes(children[0])),
]

const useValidateItems = <Value extends IValueType>({
  value,
  onChange,
  option,
}: IValidateItemsOptionsType<Value>) => {
  const node = useMemo(() => findNode({ value, option }), [value, option])

  useEffect(() => {
    if (option.children && node.length === 0)
      onChange(getFirstNodes(option.children[0]))
  }, [node, option])

  return node
}

export default useValidateItems
