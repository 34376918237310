import React from 'react'
import styled from 'styled-components'

import Print from 'pared/components/basicUi/Print'
import COLORS from 'pared/constants/colors'

import ShiftLeaderAssessment from './ShiftLeaderAssessment'
import TrackerInstructionText from './TrackerInstructionText'
import TrackerTitle, { ITrackerTitlePropsType } from './TrackerTitle'
import Buttons from './buttons'
import useTrainingTrackerItems, {
  TrainingTrackerlistType,
} from './hooks/useTrainingTrackerItems'
import {
  SignedShiftLeaderAssessmentType,
  TrainingTrackerType,
} from './hooks/useTrainingTrackerItems'
import SignedShiftLeaderAssessment from './signedShiftLeaderAssessment'
import TrainingTrackerItem from './trainingTrackerItem'

interface TableProps {
  trainingTrackerData: TrainingTrackerlistType[]
  showCancel: boolean
  signedShiftLeaderAssessment: SignedShiftLeaderAssessmentType | null
}

export interface IPropsType {
  type: 'tracker-form'
}

const PrintHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
`

const TableContainer = styled.div`
  margin-top: 20px;
`

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  font-weight: 300;
  font-size: 14px;
  line-height: 17.5px;

  tr:nth-child(odd) {
    background-color: #f1f1f1;
  }

  th,
  td {
    border: 1px solid #4a4a4a;
  }

  @media (max-width: 599px) {
    tr:nth-child(odd) {
      background-color: initial;
    }

    td,
    th,
    tr {
      border: 0px;
    }
  }
`

const TitleRow = styled.tr`
  background: linear-gradient(to right, #afa67c, #fde9c8, #afa67c);
  text-align: center;

  th {
    min-width: 120px;
    height: 40px;
    font-style: italic;
    font-weight: bold;
    vertical-align: middle;
  }
`

const LastTh = styled.th`
  text-align: center;
  font-size: 25px;
`

const FirstdTd = styled.td`
  text-align: left;
  padding: 8px;
`

const SubtitleRow = styled.tr`
  background: linear-gradient(to right, #afa67c, #fde9c8, #afa67c);
  font-weight: bold;
  font-size: 18px;
  font-style: italic;
`

const SecondaryType = styled.td`
  padding: 12px;
  font-weight: bold;
  font-style: italic;
  font-size: 18px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    > div {
      flex-grow: 1;
      border-top: 1px dotted #bbb;
    }
  }
`

const StyledeRow = styled.tr`
  border-bottom: 1pt solid ${COLORS.Smoke};
`

const StyledTd = styled.td`
  text-align: center;
  padding: 8px;
`

const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
`

const VerticalSpacer = styled.div`
  height: 60px;
`

const Table = ({
  trainingTrackerData,
  showCancel,
  signedShiftLeaderAssessment,
}: TableProps) => {
  return (
    <StyledTable>
      <thead>
        <TitleRow>
          <th>
            <div>DATE</div>
            <div>COMPLETE</div>
          </th>
          <th>
            <div>TRAINEE</div>
            <div>INITIALS</div>
          </th>
          <th>
            <div>TRAINER</div>
            <div>INITIALS</div>
          </th>
          <LastTh>ITEM</LastTh>
        </TitleRow>
      </thead>
      <tbody>
        {trainingTrackerData.map((l, idx) => {
          let itemSecondaryType: null | string = null

          return (
            <React.Fragment key={l.itemType || idx}>
              {!l.itemType ? null : (
                <SubtitleRow>
                  <td />
                  <td />
                  <td />
                  <FirstdTd>{l.itemType}</FirstdTd>
                </SubtitleRow>
              )}

              {l.items.map((item) => {
                const secondaryType =
                  itemSecondaryType === item.itemSecondaryType
                    ? null
                    : item.itemSecondaryType

                if (item.itemSecondaryType) {
                  itemSecondaryType = item.itemSecondaryType
                }

                return (
                  <React.Fragment key={item.itemId}>
                    {secondaryType && (
                      <tr>
                        <td />
                        <td />
                        <td />

                        <SecondaryType>
                          <div>
                            {secondaryType} <div />{' '}
                          </div>
                        </SecondaryType>
                      </tr>
                    )}
                    <TrainingTrackerItem
                      key={item.itemId}
                      inputItem={item}
                      showCancel={showCancel}
                    />
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          )
        })}
        {signedShiftLeaderAssessment && (
          <StyledeRow>
            <StyledTd />
            <StyledTd />
            <StyledTd>
              <SignedShiftLeaderAssessment {...signedShiftLeaderAssessment} />
            </StyledTd>
            <FirstdTd>Shift Leader Assessment Completed (see below)</FirstdTd>
          </StyledeRow>
        )}
      </tbody>
    </StyledTable>
  )
}

const PrintContent = ({ ...data }: TrainingTrackerType) => {
  const titleProps: ITrackerTitlePropsType = {
    traineeName: data.traineeName,
    traineeId: data.traineeId,
    trainerName: data.trainerName,
    assignedTracker: data.assignedTracker,
    assignedAt: data.assignedAt,
    hideAssign: false,
  }

  return (
    <PrintHeader>
      <Print hideHeader={true}>
        <TableContainer>
          <TrackerTitle {...titleProps} />
          <TrackerInstructionText assignedTracker={data.assignedTracker} />
          <Table
            trainingTrackerData={data.list}
            showCancel={data.showCancel}
            signedShiftLeaderAssessment={data.signedShiftLeaderAssessment}
          />
        </TableContainer>
        {!!data.signedShiftLeaderAssessment ? <ShiftLeaderAssessment /> : null}
        <Buttons
          showSubmit={data.showSubmit}
          showApprove={data.showApprove}
          needApprove={data.needApprove}
          isShiftLeaderTracker={!!data.signedShiftLeaderAssessment}
        />
        <VerticalSpacer />
        {data.assignedTracker === 'Crew Training Milestone Tracker' ? (
          <StyledImg
            src={
              require('pared/images/brands/jsc_bk/congratulations-footer.png')
                .default
            }
            alt="Congratulations Image"
          />
        ) : null}
        <StyledImg
          src={require('pared/images/brands/jsc_bk/general-footer.png').default}
          alt="Footer Image"
        />
      </Print>
    </PrintHeader>
  )
}

const TrackerFrom = () => {
  const { data, loading } = useTrainingTrackerItems()

  if (loading || !data) return null

  const titleProps: ITrackerTitlePropsType = {
    traineeName: data.traineeName,
    traineeId: data.traineeId,
    trainerName: data.trainerName,
    assignedTracker: data.assignedTracker,
    assignedAt: data.assignedAt,
    hideAssign: false,
  }

  return (
    <>
      <TrackerTitle {...titleProps} />
      <PrintContent {...data} />
      <TrackerInstructionText assignedTracker={data.assignedTracker} />
      <Table
        trainingTrackerData={data.list}
        showCancel={data.showCancel}
        signedShiftLeaderAssessment={data.signedShiftLeaderAssessment}
      />
      {!!data.signedShiftLeaderAssessment ? <ShiftLeaderAssessment /> : null}
      <Buttons
        showSubmit={data.showSubmit}
        showApprove={data.showApprove}
        needApprove={data.needApprove}
        isShiftLeaderTracker={!!data.signedShiftLeaderAssessment}
      />
      <VerticalSpacer />
      {data.assignedTracker === 'Crew Training Milestone Tracker' ? (
        <StyledImg
          src={
            require('pared/images/brands/jsc_bk/congratulations-footer.png')
              .default
          }
          alt="Congratulations Image"
        />
      ) : null}
      <StyledImg
        src={require('pared/images/brands/jsc_bk/general-footer.png').default}
        alt="Footer Image"
      />
    </>
  )
}

export default TrackerFrom
