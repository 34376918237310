import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBrand } from 'pared/utils/brand'

import {
  GET_STORE_LIST,
  ILocationDisputesType,
  ILocationDisputesVariablesType,
  ILocationGroupDisputesType,
  ILocationGroupDisputesVariablesType,
  ILocationInfoType,
  ILocationInfoVariableType,
  LIST_LOCATION_DISPUTES,
  LIST_LOCATION_GROUP_DISPUTES,
} from '../gqls'

export interface IDataSourceType {
  groupInfo:
    | {
        locationId: number
        locationCode: string
        locationName: string
        directorId: string | number
        directorName: string
      }
    | {
        locationGroupId: number
        locationGroupName: string
      }
    | null
  totalDisputeCount: number
  totalDisputeAmount: number
  completedDisputeCount: number
  completedDisputeAmount: number
  pendingDisputeCount: number
  pendingDisputeAmount: number
  lostDisputeCount: number
  lostDisputeAmount: number
}

interface IProps {
  isBreakdownByDirectors: boolean
  startDate: string
  endDate: string
  locationGroupId?: number
  locationGroupIds?: number[]
  breakdownType?: string | null
  locationIds?: number[]
}

export default ({
  isBreakdownByDirectors,
  startDate,
  endDate,
  locationGroupId,
  locationGroupIds,
  breakdownType,
  locationIds,
}: IProps): {
  dataSource: IDataSourceType[]
  loading: boolean
} => {
  const brand = getBrand()
  const filter = (() => {
    if (breakdownType) {
      return {
        location_group_types: [breakdownType],
      }
    } else if (isBreakdownByDirectors) {
      return {
        location_group_ids: locationGroupIds,
      }
    } else {
      if (locationGroupId) return { location_group_ids: [locationGroupId] }
      else return { location_ids: locationIds }
    }
  })()

  const { data: storeList, loading: storeListLoading } = useQuery<
    ILocationInfoType,
    ILocationInfoVariableType
  >(GET_STORE_LIST, {
    variables: {
      iFilter: {
        location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]],
      },
    },
  })

  const { data: locationDisputeData, loading: locationDisputeLoading } =
    useQuery<ILocationDisputesType, ILocationDisputesVariablesType>(
      LIST_LOCATION_DISPUTES,
      {
        variables: {
          iStartDate: startDate,
          iEndDate: endDate,
          iFilter: filter,
        },
        skip: isBreakdownByDirectors || breakdownType != null,
      },
    )

  const {
    data: locationGroupDisputeData,
    loading: locationGroupDisputeLoading,
  } = useQuery<ILocationGroupDisputesType, ILocationGroupDisputesVariablesType>(
    LIST_LOCATION_GROUP_DISPUTES,
    {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: filter,
      },
      skip: !isBreakdownByDirectors && !breakdownType,
    },
  )

  const data =
    locationDisputeData?.listLocationDisputes?.nodes ||
    locationGroupDisputeData?.listLocationGroupDisputes?.nodes
  const deliveryData =
    locationDisputeData?.listLocationDeliveryKpi?.nodes ||
    locationGroupDisputeData?.listLocationGroupDeliveryKpi?.nodes ||
    []

  const loading =
    (locationDisputeLoading && locationGroupDisputeLoading) || storeListLoading

  const dataSource = useMemo(() => {
    if (!data) return []

    if (breakdownType || isBreakdownByDirectors) {
      return data.map((row) => {
        const deliveryD = deliveryData?.find(
          (d) => d.locationGroupId === row.locationGroupId,
        )

        return {
          groupInfo: {
            locationGroupId: row?.locationGroupId,
            locationGroupName: row?.locationGroupName,
          },
          ...row,
          ...deliveryD,
        }
      })
    } else {
      return data.map((row) => {
        const locationInfo = storeList?.listLocationDetails.nodes?.find(
          (l) => l.id === row?.locationId,
        )
        const deliveryD = deliveryData?.find(
          (d) => d.locationId === row.locationId,
        )

        return {
          groupInfo: {
            locationId: locationInfo?.id,
            locationCode: locationInfo?.code,
            locationName: locationInfo?.name,
            directorId: locationInfo?.directorId,
            directorName: `${
              locationInfo?.directorEmployeeInfo?.givenName || 'Unknown'
            } ${locationInfo?.gmEmployeeInfo?.familyName || ''}`,
          },
          ...row,
          ...deliveryD,
        }
      })
    }
  }, [brand, filter, data, loading, storeList])

  return {
    dataSource,
    loading,
  }
}
