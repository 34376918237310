import { makeStyles } from '@material-ui/core/styles'
import SendIcon from '@mui/icons-material/Send'
import IconButton from '@mui/material/IconButton'
import { ChangeEvent, FormEvent, MouseEvent, useState } from 'react'
import styled from 'styled-components'

import Menu from 'pared/components/basicUi/Menu'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import { PAGE_PADDING_LEFT } from '../constants'
import useConfig from '../hooks/useConfig'
import useQuestionMenuDataSource from '../hooks/useQuestionMenuDataSource'

const TextInput = styled.input`
  flex: 1;
  padding: 0 0 0 20px;
  border: none;
  border-radius: 15px 0 0 15px;
  font-family: Lexend-Regular;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.Chalkboard};
`

const MainContainer = styled.div`
  width: 875px;
  padding: 30px 0 0 ${PAGE_PADDING_LEFT}px;

  @media ${MOBILE_WIDTH} {
    width: 100%;
    padding: 30px 20px 0 20px;
  }
`

const InputForm = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 65px;
  border-radius: 15px;
  border: 1px solid #0c83d9;
  background-color: ${COLORS.Salt};
`

const useStyles = makeStyles({
  Menu: {
    fontFamily: 'Lexend-Regular',

    '& .MuiPaper-root': {
      minWidth: '320px',
      borderRadius: 0,
      border: '1px solid #0C83D9',
    },
  },

  MenuItem: {
    fontFamily: 'Lexend-Regular',
    padding: '8px 25px',

    '&:hover': {
      backgroundColor: '#D6F0FF',
    },
  },
})

interface IProps {
  isLoading: boolean
  inputValue: string
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleFormSubmit: (
    eventOrQuestion: FormEvent<HTMLFormElement> | string,
  ) => Promise<void>
}

function ChatInput({
  isLoading,
  inputValue,
  handleInputChange,
  handleFormSubmit,
}: IProps) {
  const customizedMuiClasses = useStyles()
  const questionMenuDataSource = useQuestionMenuDataSource()
  const chatBoxConfig = useConfig()?.chatBox

  const [questionMenuAnchorEl, setQuestionMenuAnchorEl] =
    useState<null | HTMLElement>(null)
  const handleQuestionMenuOpen = (event: MouseEvent<any>) => {
    setQuestionMenuAnchorEl(event?.currentTarget || null)
  }
  const handleQuestionMenuClose = () => {
    if (questionMenuAnchorEl) {
      setQuestionMenuAnchorEl(null)
    }
  }

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleQuestionMenuClose()
    handleInputChange(event)
  }

  const handleQuestionSelect = async (selectedValues: (string | number)[]) => {
    const question = (selectedValues?.[selectedValues?.length - 1] ||
      '') as string
    await handleFormSubmit(question)
  }

  return (
    <MainContainer>
      <form onSubmit={handleFormSubmit}>
        <InputForm>
          <TextInput
            type="text"
            value={inputValue}
            onClick={handleQuestionMenuOpen}
            onChange={onInputChange}
            placeholder="What would you like to know?"
          />
          <IconButton
            disabled={isLoading}
            type="submit"
            aria-label="Send"
            style={{ padding: '0 15px' }}
          >
            <SendIcon fontSize="large" sx={{ color: '#41A5EE' }} />
          </IconButton>
        </InputForm>
      </form>

      {chatBoxConfig?.hasQuestionMenu && questionMenuAnchorEl ? (
        <Menu
          anchorEl={questionMenuAnchorEl}
          handleMenuClose={handleQuestionMenuClose}
          onSelect={handleQuestionSelect}
          onKeyDown={handleQuestionMenuClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          customizedMuiClasses={customizedMuiClasses}
          dataSource={questionMenuDataSource}
        />
      ) : null}
    </MainContainer>
  )
}

export default ChatInput
