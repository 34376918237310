import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

interface IParamsPropsType {
  id: string
}

const queries = gql`
  query GetEmployeeName($id: Int!) {
    employee(id: $id) {
      familyName
      preferredName
    }
  }
`

const useJscBaseballCard = () => {
  const { id }: IParamsPropsType = useParams()
  const { data, loading } = useQuery(queries, {
    variables: {
      id: parseInt(id),
    },
    skip: !id,
  })

  return {
    data: useMemo((): string | null => {
      return data
        ? `${data.employee.preferredName} ${data.employee.familyName}`
        : null
    }, [data]),
    loading,
  }
}

export default useJscBaseballCard
