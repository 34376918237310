import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
// import { useEffect, useState } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'

// import getLocations, { ILocation } from 'pared/data/getLocations'
import { PAGE_PADDING_LEFT } from '../constants'
import useConfig, { IChatBoxConfig } from '../hooks/useConfig'

interface IProps {
  onQuestionClicked: (questionText: string) => void
}

const QuestionTable = styled.table`
  padding: 0 0 20px ${PAGE_PADDING_LEFT}px;
  border-collapse: separate;
  border-spacing: 12px 12px;

  td {
    width: 275px;
    min-width: 275px;
    height: 100px;
    padding: 10px 20px;
    background-color: #d6f0ff;
    font-size: 14px;
    font-family: Lexend-Regular;
    font-style: normal;
    font-weight: 300;
    vertical-align: center;
    color: ${COLORS.Chalkboard};
    cursor: pointer;

    :hover {
      background-color: #c2e9ff;
    }
  }

  @media ${MOBILE_WIDTH} {
    padding: 0 10px 20px 10px;

    td {
      width: 30%;
      min-width: 0;
    }
  }
`

const HeaderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const HeaderIcon = styled.div`
  padding: 0;
  margin: 0;
  color: ${COLORS.Chalkboard};
`

const HeaderText = styled.div`
  color: ${COLORS.Chalkboard};
  font-size: 16px;
  font-family: Lexend-Regular;
  font-style: normal;
  font-weight: 300;
`

function getQuestions(
  chatBoxConfig: IChatBoxConfig,
  locationName: string = '',
): string[][] {
  let cogsOrPlv = 'COGS%'
  if (chatBoxConfig.hasPlv && !chatBoxConfig.hasCogs) {
    cogsOrPlv = 'PLV'
  }

  return [
    [
      'Show me Sales Trend for trailing 12 months',
      `Which ${chatBoxConfig.directorDisplayString} has the most sales this year?`,
      'How do we improve Labor?',
    ],
    [
      `What is the ${cogsOrPlv} trend?`,
      `Which Store has the best ${cogsOrPlv}?`,
      'How do we improve Delivery Sales?',
    ],
    [
      'What is Hourly Labor % this year?',
      'What Stores are hitting Hourly Labor targets?',
      'How do we improve Salesmanship?',
    ],
  ]
}

function CannedQuestions({ onQuestionClicked }: IProps) {
  // --------------------
  // For speeding up the rendering, comment this out because no location name needed in questions
  // const [randomLocation, setRandomLocation] = useState<ILocation | null>(null)

  // useEffect(() => {
  //   async function getData() {
  //     const { locations } = await getLocations()
  //     const randomPickedLocation =
  //       locations[Math.floor(Math.random() * locations.length)] || null
  //     setRandomLocation(randomPickedLocation)
  //   }

  //   getData()
  // }, [])

  // if (!randomLocation) {
  //   return null
  // }

  // const questions = getQuestions(randomLocation.name)
  // --------------------

  const config = useConfig()
  const questions = getQuestions(config.chatBox)

  return (
    <QuestionTable>
      <thead>
        <tr>
          <th>
            <HeaderContentContainer>
              <HeaderIcon>
                <TrendingUpIcon fontSize="large" />
              </HeaderIcon>
              <HeaderText>Trends</HeaderText>
            </HeaderContentContainer>
          </th>
          <th>
            <HeaderContentContainer>
              <HeaderIcon>
                <CompareArrowsIcon fontSize="large" />
              </HeaderIcon>
              <HeaderText>Comparisons</HeaderText>
            </HeaderContentContainer>
          </th>
          <th>
            <HeaderContentContainer>
              <HeaderIcon>
                <RocketLaunchIcon fontSize="large" />
              </HeaderIcon>
              <HeaderText>Actions</HeaderText>
            </HeaderContentContainer>
          </th>
        </tr>
      </thead>
      <tbody>
        {questions.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cellText, cellIndex) => (
              <td
                key={cellIndex}
                onClick={() => {
                  onQuestionClicked(cellText)
                }}
              >
                {cellText}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </QuestionTable>
  )
}

export default CannedQuestions
