import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { renderToString } from 'react-dom/server'
import styled, { ServerStyleSheet } from 'styled-components'

import SendEmailButton from 'pared/components/SendEmailButton'
import DownloadIcon from 'pared/images/basic/download.png'

import Head from './Head'
import Pagination from './Pagination'
import useDataSource from './hooks/useDataSource'
import useMergedBody from './hooks/useMergedBody'
import usePagination, { MAX_AMOUNT } from './hooks/usePagination'
import useSortedField from './hooks/useSortedField'
import useSummaryDataSource from './hooks/useSummaryDataSource'
import { IColumnsType as ColumnsType } from './types'

export type IColumnsType<Data> = ColumnsType<Data>

interface IPaginationType {
  pageSize: number
}

export interface IPropsType<Data> {
  className?: string
  dataSource: Data[]
  columns: IColumnsType<Data>[]
  headerStyle?: string
  rowKey?: keyof Data | ((values: Data) => string | number)
  rowStyle?: string | ((value: any) => string)
  defaultSortedField?: keyof Data | null
  defaultSortingDirection?: 'ascend' | 'descend'
  pagination?: false | IPaginationType
  shouldPaginationAboveSummary?: boolean
  csvFileName?: string
  useSummary?: boolean
  tableStyle?: string
  isForEmail?: boolean
}

const TD_PROPS = ['align', 'cellStyle', 'backgroundColor'] as const

const StyledTable = styled.table<Pick<IPropsType<{}>, 'tableStyle'>>`
  border-collapse: collapse;
  ${({ tableStyle }) => tableStyle}
`

const Tr = styled.tr<
  Pick<IPropsType<{}>, 'rowStyle'> & {
    summary?: 0 | 1
    extraRow?: 0 | 1
  }
>`
  ${({ summary }) =>
    !summary
      ? ''
      : `
    &:last-child {
      border-top: 1px solid black;
      font-weight: 700;
    }
  `}

  ${({ extraRow }) =>
    !extraRow
      ? ''
      : `
    &:first-child {
      border-bottom: 1px solid black;
      font-weight: 700;
    }
  `}

  ${({ rowStyle }) => rowStyle}
`

const AboveSummaryPaginationContainer = styled.td`
  padding: 0 0 15px 0;
`

const Td = styled.td<
  Pick<IColumnsType<any>, typeof TD_PROPS[number]> & {
    cellValue: any
    rawValue?: any
  }
>`
  padding: 8px 10px;
  text-align: ${({ align }) => align || 'left'};
  font-family: Lexend-Regular;
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}

  ${({ cellStyle, cellValue, rawValue }) => {
    if (typeof cellStyle === 'function') {
      return cellStyle(cellValue, rawValue) || ''
    }
    return cellStyle || ''
  }}
`

const ExpandButton = styled.button`
  width: 140px;
  height: 46px;
  color: #07a4c7;
  background: transparent;
  font-family: Lexend-Regular;
  text-align: left;
  text-decoration-line: underline;
  border: 0px;
  cursor: pointer;
  outline: none;
`

const StyledCSVLink = styled(CSVLink)`
  font-family: Lexend-Regular;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 250px;
`

const getHeaderRows = <D,>(columns: IColumnsType<D>[]): string[] => {
  const hasChildren = columns.some((column) => column.children)

  if (hasChildren && columns.some((column) => !column.children))
    return getHeaderRows(
      columns.map((column) =>
        column.children
          ? {
              ...column,
              children: column.children.map((subColumn) => ({
                ...subColumn,
                title: `${column.title || (column.key as string)} - ${
                  subColumn.title
                }`,
              })),
            }
          : {
              ...column,
              title: '',
              key: '',
              children: [column],
            },
      ),
    )

  if (hasChildren)
    return getHeaderRows(
      columns.reduce(
        (result, column) =>
          !column.children ? result : [...result, ...column.children],
        [] as IColumnsType<D>[],
      ),
    )

  return columns.reduce(
    (result, column) => [
      ...result,
      column.title || (column.key as string),
      ...(!column.children ? [] : [...column.children].slice(1).map(() => '')),
    ],
    [] as string[],
  )
}

const Table = <Data,>({
  className,
  dataSource: allDataSource,
  columns,
  headerStyle,
  rowKey = 'key' as keyof Data,
  rowStyle,
  defaultSortedField = null,
  defaultSortingDirection = 'descend',
  pagination = false,
  shouldPaginationAboveSummary = false,
  csvFileName,
  useSummary,
  tableStyle,
  isForEmail,
}: IPropsType<Data>) => {
  const [html, setHtml] = useState('')
  const originalDataSource = useMemo(() => [...allDataSource], [allDataSource])
  const summaryDataSource = useMemo(
    () => (useSummary ? originalDataSource.pop() : undefined),
    [useSummary, originalDataSource],
  )
  const total = originalDataSource.length
  const mergedBody = useMergedBody<Data>(columns)
  const { current, pageSize, onChange } = usePagination(
    !pagination ? 0 : pagination.pageSize,
    total,
  )
  const { sortedField, setSortedField } = useSortedField<Data>(
    defaultSortedField,
    mergedBody,
    defaultSortingDirection,
  )
  const dataSource = useDataSource<Data>(
    originalDataSource,
    current,
    pageSize,
    sortedField,
    mergedBody,
  )

  const extraRow = useSummaryDataSource<Data>(
    dataSource,
    mergedBody,
    'extraRow',
  )
  const summary = useSummaryDataSource<Data>(dataSource, mergedBody, 'summary')

  useEffect(() => {
    if (pagination) onChange(0)
  }, [originalDataSource])

  useEffect(() => {
    if (isForEmail) {
      const sheet = new ServerStyleSheet()
      const markup = renderToString(sheet.collectStyles(TableComponent))
      const styleTags = sheet.getStyleTags()
      const html = `
        <!DOCTYPE html>
        <html>
          <head>
              <meta charset="UTF-8">
              ${styleTags}
          </head>
          <body>
              <div>${markup}</div>
          </body>
        </html>
      `
      setHtml(html)
    }
  }, [current, pageSize])

  // Show all rows when downloading to CSV
  const dataSourceForCsv = useDataSource<Data>(
    originalDataSource,
    current,
    total,
    sortedField,
    mergedBody,
  )

  const headerRow = useMemo(() => getHeaderRows(columns), [columns])
  const dataRowsForCsv = dataSourceForCsv.map((data) => {
    return mergedBody.map(({ key, csvRender }) => {
      const cellValue = _.get(data, key)
      if (csvRender) {
        return csvRender(cellValue, data)
      }

      return cellValue
    })
  })

  const extraRowForCsv = extraRow.map(({ value }) => value?.props?.children)
  const summaryRow = summary.map(({ value }) => {
    if (value && typeof value === 'object') {
      if ('props' in value) {
        return value?.props?.children
      }

      return null
    }

    return value
  })

  const csvData = [
    headerRow,
    extraRowForCsv,
    ...dataRowsForCsv,
    summaryRow,
  ].filter((row) => row.length > 0)

  const TableComponent = (
    <StyledTable tableStyle={tableStyle} className={className}>
      <thead>
        <Head<Data>
          headerStyle={headerStyle}
          columns={columns}
          sortedField={sortedField}
          setSortedField={setSortedField}
        />
      </thead>

      <tbody>
        {extraRow.length === 0 ? null : (
          <Tr rowStyle={rowStyle} className="table-summary-row" extraRow={1}>
            {extraRow.map(({ key, value, ...props }) => (
              <Td {..._.pick(props, TD_PROPS)} key={key} cellValue={value}>
                {value}
              </Td>
            ))}
          </Tr>
        )}
        {dataSource.map((data, index) => (
          <Tr
            key={
              typeof rowKey === 'function'
                ? rowKey(data)
                : (_.get(data, rowKey) as unknown as React.Key)
            }
            rowStyle={
              typeof rowStyle === 'function' ? rowStyle(data) : rowStyle
            }
          >
            {mergedBody.map(({ key, render, ...props }) => {
              let cellValue = _.get(data, key)
              const color = cellValue?.color
              if (color) {
                cellValue = cellValue.value
              }

              return (
                <Td
                  {..._.pick(props, TD_PROPS)}
                  key={key}
                  cellValue={cellValue}
                  rawValue={data}
                  backgroundColor={color}
                >
                  {render?.(cellValue, data, index) || cellValue}
                </Td>
              )
            })}
          </Tr>
        ))}

        {!shouldPaginationAboveSummary ||
        total === 0 ||
        !pagination ||
        pagination.pageSize >= originalDataSource.length ? null : (
          <Tr>
            <AboveSummaryPaginationContainer>
              <Pagination
                current={current}
                pageSize={pageSize}
                total={total}
                onChange={onChange}
              />
            </AboveSummaryPaginationContainer>
          </Tr>
        )}
        {!summaryDataSource ? null : (
          <ExpandButton onClick={() => onChange(current)}>
            {total === pageSize ? 'Collapse all' : 'Expand all'}
          </ExpandButton>
        )}
        {summary.length === 0 && !summaryDataSource ? null : (
          <Tr rowStyle={rowStyle} className="table-summary-row" summary={1}>
            {summaryDataSource
              ? mergedBody.map(({ key, render, ...props }) => {
                  let cellValue = _.get(summaryDataSource, key)
                  const color = cellValue?.color
                  if (color) {
                    cellValue = cellValue.value
                  }

                  return (
                    <Td
                      {..._.pick(props, TD_PROPS)}
                      key={key}
                      cellValue={cellValue}
                      rawValue={summaryDataSource}
                      backgroundColor={color}
                    >
                      {render?.(cellValue, summaryDataSource, -1) || cellValue}
                    </Td>
                  )
                })
              : summary.map(({ key, value, ...props }) => (
                  <Td {..._.pick(props, TD_PROPS)} key={key} cellValue={value}>
                    {value}
                  </Td>
                ))}
          </Tr>
        )}
      </tbody>
    </StyledTable>
  )

  return (
    <>
      {csvFileName ? (
        <StyledCSVLink data={csvData} filename={`${csvFileName}.csv`}>
          <img src={DownloadIcon} height="30px" />
          <div>Download Table as CSV</div>
        </StyledCSVLink>
      ) : null}
      {TableComponent}
      {isForEmail ? <SendEmailButton html={html} /> : null}

      {total === 0 ||
      total <= MAX_AMOUNT ||
      pagination ||
      summaryDataSource ? null : (
        <ExpandButton onClick={() => onChange(current)} data-cy="expand">
          {total === pageSize ? 'Collapse all' : 'Expand all'}
        </ExpandButton>
      )}

      {shouldPaginationAboveSummary ||
      total === 0 ||
      !pagination ||
      pagination.pageSize >= originalDataSource.length ? null : (
        <Pagination
          current={current}
          pageSize={pageSize}
          total={total}
          onChange={onChange}
        />
      )}
    </>
  )
}

export default React.memo(Table) as unknown as typeof Table
