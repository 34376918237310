import { IScore } from '../index'
import WebLargeMain from './WebLargeMain'
import { IOrderBy } from './index'

interface IProps {
  scores: IScore[]
  orderBy: IOrderBy
  setOrderBy: any
  dateRangeType: string
}

function Main({ scores, orderBy, setOrderBy, dateRangeType }: IProps) {
  return (
    <WebLargeMain
      scores={scores}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      dateRangeType={dateRangeType}
    />
  )
}

export default Main
