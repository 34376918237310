import _ from 'lodash'
import { useMemo } from 'react'

import { useFetchCustomizedReportData } from 'pared/components/CustomizedReport'

const useFetchPurchaseReportData = (
  locationGroupId?: number,
  locationIds?: number[],
  startDate?: string,
  endDate?: string,
) => {
  const reportName = 'CORPORATE_PURCHASE_TABLE_V3'

  let filter = null
  if (locationGroupId) {
    filter = JSON.stringify({
      location_group_ids: [locationGroupId],
    })
  } else if (locationIds && locationIds.length > 0) {
    filter = JSON.stringify({
      location_ids: locationIds,
    })
  }

  const inputParams =
    startDate && endDate && filter
      ? {
          startDate,
          endDate,
          filter,
        }
      : null

  const { reportDefinition, reportResult, isLoading } =
    useFetchCustomizedReportData(reportName, inputParams)

  return useMemo(() => {
    return {
      reportDefinition,
      isLoading,
      reportResult,
    }
  }, [locationGroupId, reportDefinition, reportResult, isLoading])
}

export default useFetchPurchaseReportData
