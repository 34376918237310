import ExpoAiLogo from 'pared/images/Expo/expoai.png'

import { IConfigsType } from '../../types'

const storeHome: IConfigsType = {
  timoney_bk: {
    '/:brand/scorecard': {
      groupFilter: {
        api: 'locationFilter',
      },
      title: {
        type: 'title',
        title: 'locationInfo',
        subTitle: 'storeSubtitle',
      },
      image: {
        type: 'image',
        src: ExpoAiLogo,
        alt: 'Expo AI',
        height: '40px',
      },
      exray: {
        type: 'ai-summary',
        fields: [
          'sales',
          'foodCost',
          'labor',
          'lossPrevention',
          'guest',
          'delivery',
        ],
      },
      chatbox: {
        type: 'chatbox',
      },
    },
  },
}

export default storeHome
