import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import {
  BRAND_ID,
  BRAND_LOCATION_GROUP_ID,
  BRAND_NAME,
  NUM_BRAND,
} from 'pared/constants/brands'
import useBrands from 'pared/layouts/hooks/useBrands'

import { IApiDataType, IDataType, ISelectDataType } from '../types'

interface IQueryDataType {
  [key: `listDirectors${string}`]: {
    nodes: {
      locationGroupId: number
      firstName: string
      lastName: string
      brandId: number
    }[]
  }
  [key: `listLocationDetails${string}`]: {
    nodes: {
      id: number
      code: string
      name: string
      directorEmployeeInfo: {
        givenName: string
        familyName: string
      }
    }[]
  }
}

interface IQueryVariablesType {
  [key: `iFilter${string}`]: {
    brand_ids: [number]
    location_group_ids: [number]
  }
}

const getQuery = (ids: number[]) => gql`
  query lfrGroupFilter(
    $iBrandId: Int!
    ${ids.map(
      (id) => `
    $iFilter${id}: JSON!
    `,
    )}
  ) {
    brand(id: $iBrandId) {
      id
    }

    ${ids.map(
      (id) => `
    listDirectors${id}: listDirectors(iFilter: $iFilter${id}) {
      nodes {
        locationGroupId
        firstName
        lastName
      }
    }

    listLocationDetails${id}: listLocationDetails(iFilter: $iFilter${id}) {
      nodes {
        id
        code
        name
        directorEmployeeInfo {
          givenName
          familyName
        }
      }
    }
    `,
    )}
  }
`

const useLfrGroupFilter = () => {
  const { brand, brands } = useBrands()
  const filteredBrands = brands.filter((b) => b !== brand)
  const { data, loading } = useQuery<IQueryDataType, IQueryVariablesType>(
    getQuery(filteredBrands.map((b) => BRAND_ID[b])),
    {
      variables: filteredBrands.reduce(
        (result, b) => ({
          ...result,
          [`iFilter${BRAND_ID[b]}`]: {
            brand_ids: [BRAND_ID[b]],
            location_group_ids: [BRAND_LOCATION_GROUP_ID[b]],
          },
        }),
        {
          iBrandId: BRAND_ID[brand],
        } as IQueryVariablesType,
      ),
    },
  )

  return {
    data: useMemo((): IApiDataType => {
      if (!data || Object.keys(data).length === 1) return null

      const allLocations =
        brands.length !== NUM_BRAND
          ? null
          : {
              id: 'all-stores',
              parentId: 'root',
              ids: [BRAND_ID[brand]],
              label: 'All Stores',
              list: filteredBrands
                .map((brand) =>
                  data[`listLocationDetails${BRAND_ID[brand]}`].nodes.map(
                    (n) => ({
                      id: n.id,
                      name: `${n.code} - ${n.name}`,
                      header: 'Store',
                      groupBy: {
                        id: BRAND_ID[brand],
                        header: 'Brand',
                        name: BRAND_NAME[brand],
                      },
                    }),
                  ),
                )
                .flat(),
            }
      const allbrands = filteredBrands.map(
        (brand): ISelectDataType => ({
          id: BRAND_NAME[brand],
          parentId: 'brands',
          ids: [BRAND_LOCATION_GROUP_ID[brand]],
          label: BRAND_NAME[brand],
          list: data[`listLocationDetails${BRAND_ID[brand]}`].nodes.map(
            (n) => ({
              id: n.id,
              name: `${n.code} - ${n.name}`,
              header: 'Store',
              groupBy: {
                id: BRAND_ID[brand],
                header: 'Brand',
                name: BRAND_NAME[brand],
              },
            }),
          ),
        }),
      )
      const allDirectors = filteredBrands.reduce((result, brand) => {
        const listLocationDetails: IQueryDataType[`listLocationDetails${string}`]['nodes'] =
          data[`listLocationDetails${BRAND_ID[brand]}`].nodes || []

        return data[`listDirectors${BRAND_ID[brand]}`].nodes.reduce(
          (subResult, n) => {
            const item = {
              id: n.locationGroupId.toString(),
              parentId: 'directors',
              ids: [n.locationGroupId],
              label: `${n.firstName} ${n.lastName}`,
              list: listLocationDetails.map((l) => ({
                id: l.id,
                name: `${l.code} - ${l.name}`,
                header: 'Store',
                groupBy: {
                  id: BRAND_ID[brand],
                  header: 'Brand',
                  name: BRAND_NAME[brand],
                },
              })),
            }

            const existingItem = subResult.find((r) => r.label === item.label)

            if (existingItem) {
              existingItem.ids.push(...item.ids)

              if (existingItem.list) existingItem.list.push(...item.list)

              return subResult
            }

            return [...subResult, item]
          },
          result,
        )
      }, [] as IDataType[])

      const values = [
        ...(!allLocations ? [] : [allLocations]),
        {
          id: 'brands',
          parentId: 'root',
          label: 'Brands',
        },
        ...allbrands,
        {
          id: 'directors',
          parentId: 'root',
          label: 'Directors',
        },
        ...allDirectors,
      ]

      return {
        values,
        defaultValue: allLocations
          ? [allLocations.id]
          : ['brands', allbrands[0].id],
      }
    }, [data, filteredBrands, brands, brand]),
    loading,
  }
}

export default useLfrGroupFilter
