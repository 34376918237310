import COLORS from 'pared/constants/colors'

import { IDaypartBreakdown } from '../..'

const formatPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const getPrice = (value: number) => formatPrice.format(value / 100)

const backgroundColors = [
  COLORS.Plum,
  COLORS.Mango,
  COLORS.Basil,
  COLORS.DarkOrchid,
  COLORS.Acai,
  COLORS.Pomodoro,
]

export default (rawData: IDaypartBreakdown[]) => {
  const labels = rawData.map((d) => d.daypart)
  const dayPartCount = labels.length || 0
  const colors: string[] = []

  const currentColorCount = backgroundColors.length
  for (let i = 0; i < dayPartCount; ++i) {
    if (i < currentColorCount) {
      colors.push(backgroundColors[i])
    } else {
      colors.push(backgroundColors[i - currentColorCount])
    }
  }

  return {
    type: 'pie' as const,
    data: {
      labels,
      datasets: [
        {
          label: 'Net Sales',
          data: rawData.map((d) => d?.rawData?.daypartSales || 0),
          backgroundColor: colors,
          hoverOffset: 4,
        },
      ],
    },
    options: {
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem: any) => {
              const dataset = tooltipItem.dataset
              const total = dataset.data.reduce(
                (acc: number, value: number) => acc + value,
                0,
              ) as number
              const currentValue = dataset.data[tooltipItem.dataIndex] as number
              const percentage = ((currentValue / total) * 100).toFixed(2)
              return `${percentage}% - ${tooltipItem.label} ${getPrice(
                tooltipItem.raw,
              )}`
            },
          },
        },
      },
    },
  }
}
