import { ILossPreventionConfig } from '../useLossPreventionConfig'

export const demoBkConfig: ILossPreventionConfig = {
  summary: {
    isVisible: true,
  },
  kpiSettings: [
    { category: 'basic', key: 'totalComps', title: 'Total Discounts' },
    { category: 'basic', key: 'cashOverOrShort', title: 'Cash +/-' },
    {
      category: 'custom',
      type: 'Over Ring',
      title: '% Checks with Voids',
      key: 'checkPercent',
    },
    {
      category: 'custom',
      type: 'Deal after Total',
      title: '% Checks with Deletes',
      key: 'checkPercent',
    },
    {
      category: 'basic',
      key: 'inaccurateCashierShiftPercent',
      title: '% Shifts with Inaccurate Cash',
    },
  ],
  cashHandlingTable: {
    display: true,
    columns: [
      {
        key: 'cashOverOrShort.amount',
        title: 'Cash +/-',
        width: '150px',
        valueType: 'price',
      },
      {
        key: 'cashOverOrShort.checkPercent',
        title: '% Shifts with Inaccurate Cash',
        valueType: 'percent',
        width: '150px',
      },
    ],
  },
  voidsAndDeletesTable: {
    display: true,
    columns: [
      {
        key: 'Deal after Total.count',
        title: 'Deletes Count',
        width: '150px',
        valueType: 'count',
        sorter: true,
      },
      {
        key: 'Over Ring',
        title: 'Voids',
        children: [
          {
            key: 'Over Ring.count',
            title: '#',
            width: '75px',
            valueType: 'count',
            sorter: true,
          },
          {
            key: 'Over Ring.amount',
            title: '$',
            width: '75px',
            valueType: 'price',
            sorter: true,
          },
          {
            key: 'Over Ring.details',
            title: 'Details',
            width: '150px',
            valueType: 'detail',
            sorter: false,
          },
        ],
      },
    ],
  },
  discountByEmployeeTable: {
    display: true,
  },
}
