import Menu from '@material-ui/core/Menu'
import MaterialMenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import * as d3 from 'd3-hierarchy'
import React, { useCallback, useState } from 'react'

export interface IValueType {
  id: string
  parentId: string | null
  value: string | number
  text: React.ReactNode
}

interface IPropsType<Value extends IValueType> {
  forwardRef?: React.Ref<HTMLLIElement> | null
  prevValue?: Value['value'][]
  onChange: (value: Value['value'][]) => void
  onClose: () => void
  option: d3.HierarchyNode<Value>
}

const useStyles = makeStyles({
  text: {
    fontFamily: 'Lexend-Regular',
  },
})

const withForwardRef = <Value extends IValueType>(
  Compoment: typeof MenuItem,
): typeof MenuItem => {
  const MemoComponent = React.memo(Compoment) as unknown as typeof MenuItem

  return React.forwardRef<HTMLLIElement, IPropsType<Value>>((props, ref) => (
    <MemoComponent {...props} forwardRef={ref} />
  )) as unknown as typeof MenuItem
}

const MenuItem = <Value extends IValueType>({
  forwardRef,
  prevValue = [],
  onChange,
  onClose: prevOnClose,
  option: {
    data: { value, text },
    children,
  },
}: IPropsType<Value>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const classes = useStyles()
  const onClose = useCallback(() => {
    prevOnClose()
    setAnchorEl(null)
  }, [prevOnClose])
  const SubMenuItem = withForwardRef(MenuItem)

  return (
    <>
      <MaterialMenuItem
        ref={forwardRef}
        className={`${classes.text} ${children ? '' : 'is-leaf'}`}
        value={value}
        onClick={({ target }) => {
          if (!children) {
            onChange([...prevValue, value])
            onClose()
          }
          // @ts-ignore: FIXME types error
          else setAnchorEl(target)
        }}
      >
        {text}
      </MaterialMenuItem>

      {!children ? null : (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          variant="menu"
        >
          {children.map((option) => (
            <SubMenuItem
              key={option.id}
              prevValue={[...prevValue, value]}
              onChange={onChange}
              onClose={onClose}
              option={option}
            />
          ))}
        </Menu>
      )}
    </>
  )
}

export default withForwardRef(MenuItem)
