import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import useListLocationFlash, {
  listLocationFlashConfigs,
} from './useListLocationFlash'

interface MetricData {
  [key: string]: {
    name: string
    unit: string
    value: number
  }
}

interface LocationNode {
  locationId: number
  metricData: MetricData
}

interface LocationCustomizedReport {
  nodes: LocationNode[]
}

const query = gql`
  query listLocationGroupMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationGroupIds: [Int!]!
    $iMetricCodes: [String!]!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        locationGroupId
        metricData
      }
    }

    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        locationId
        metricData
      }
    }
  }
`

export const bbbListLocationFlashConfigs = listLocationFlashConfigs

const useBbbListLocationFlash = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()

  const metricsCodes = [
    'total_sales',
    'total_sales_budget',
    'total_coupons_and_discounts',
    'employee_discount',
    'voids',
    'cash_over_or_short',
    'labor_cost_percentage',
  ]

  const { data: originalData, loading: originalLoading } =
    useListLocationFlash()

  const { data: customizedData, loading: customizedLoading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iLocationGroupIds: groupFilter?.ids,
      iMetricCodes: metricsCodes,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  const transformData = (originalCustomizedData: LocationCustomizedReport) => {
    const transformedCustomizedData = originalCustomizedData.nodes.map(
      (node) => {
        const locationId = node.locationId
        const metricData = node.metricData

        return {
          locationId,
          netSales: metricData.total_sales?.value || null,
          netSalesBudget: metricData.total_sales_budget?.value || null,
          totalComps: metricData.total_coupons_and_discounts?.value || null,
          'Employee Discount.totalComps':
            metricData.employee_discount?.value || null,
          voids: metricData.voids?.value || null,
          cashOverOrShort: metricData.cash_over_or_short?.value || null,
          laborPercent: 100 * metricData.labor_cost_percentage?.value || null,
        }
      },
    )

    return transformedCustomizedData
  }

  return {
    data: useMemo(() => {
      if (!originalData) return null

      const locationCustomizedReport = customizedData?.listLocationMetricValues
      const locationGroupCustomizedReport =
        customizedData?.listLocationGroupMetricValues?.nodes[0]?.metricData

      const transformedLocationCustomizedReport = transformData(
        locationCustomizedReport,
      )

      const updatedSource = originalData.source.map((item) => {
        const matchedData = transformedLocationCustomizedReport.find(
          (data) => data.locationId === item.locationId,
        )
        return {
          ...item,
          netSales: matchedData?.netSales || item.netSales,
          netSalesBudget: matchedData?.netSalesBudget || item.netSalesBudget,
          totalComps: matchedData?.totalComps || item.totalComps,
          'Employee Discount.totalComps':
            matchedData?.['Employee Discount.totalComps'] ||
            item['Employee Discount.totalComps'],
          voids: matchedData?.voids || item.voids,
          cashOverOrShort: matchedData?.cashOverOrShort || item.cashOverOrShort,
          laborPercent: matchedData?.laborPercent || item.laborPercent,
        }
      })

      const updatedSummary = {
        ...originalData.summary,
        netSales:
          locationGroupCustomizedReport?.total_sales.value ||
          originalData.summary?.netSales,
        netSalesBudget:
          locationGroupCustomizedReport?.total_sales_budget.value ||
          originalData.summary?.netSalesBudget,
        totalComps:
          locationGroupCustomizedReport?.total_coupons_and_discounts.value ||
          originalData.summary?.totalComps,
        'Employee Discount.totalComps':
          locationGroupCustomizedReport?.employee_discount.value ||
          originalData.summary?.['Employee Discount.totalComps'],
        voids:
          locationGroupCustomizedReport?.voids?.value ||
          originalData.summary?.voids,
        cashOverOrShort:
          locationGroupCustomizedReport?.cash_over_or_short.value ||
          originalData.summary?.cashOverOrShort,
        laborPercent:
          100 * locationGroupCustomizedReport?.labor_cost_percentage.value ||
          originalData.summary?.laborPercent,
      }

      return {
        source: updatedSource,
        summary: updatedSummary,
      }
    }, [originalData, customizedData]),
    loading: originalLoading || customizedLoading,
  }
}

export default useBbbListLocationFlash
