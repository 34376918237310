import { gql } from '@apollo/client'

export const QUERY_GET_ACCESSIBLE_BRANDS = gql`
  query GetAccessibleBrandsForUser {
    getAccessibleBrandsForUser {
      nodes {
        id
        name
      }
    }
  }
`

export const QUERY_GET_LOCATIONS_FOR_BRAND = gql`
  query GetLocationsForBrand($brandId: Int!) {
    getLocationsForBrand(iBrandId: $brandId) {
      nodes {
        id
        name
        code
      }
    }
  }
`

export const MUTATION_UPDATE_USER_LOCATION = gql`
  mutation UpdateUserLocation($email: String!, $locationId: Int) {
    updateUserLocation(input: { iEmail: $email, iLocationId: $locationId }) {
      clientMutationId
    }
  }
`

export const QUERY_SEARCH_EMPLOYEES = gql`
  query SearchEmployees($iFilter: JSON!) {
    searchEmployeesV2(iFilter: $iFilter) {
      nodes {
        id
        givenName
        familyName
        jobTitle
        locationId
        locationCode
        locationName
      }
    }
  }
`

export const MUTATION_UPDATE_USER_EMPLOYEE_ID = gql`
  mutation UpdateUserEmployeeId($userId: Int!, $employeeId: Int!) {
    updateUserEmployeeId(
      input: { iUserId: $userId, iEmployeeId: $employeeId }
    ) {
      clientMutationId
    }
  }
`
