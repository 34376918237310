import { useState } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

import Link from '../Link'
import AssignModel, {
  IPropsType as IAssignedModalPropsType,
} from './AssignModal'

export interface IPropsType extends Omit<IAssignedModalPropsType, 'onClose'> {
  url: string
  assignedTracker?: string
  assignedDate?: string
}

const GrayText = styled.div`
  font-size: 14px;
  color: ${COLORS.Steel};
`

const AssignText = styled.div`
  font-size: 14px;
  color: ${COLORS.Plum};
  cursor: pointer;
`

const Tracker = ({
  url,
  assignedTracker,
  assignedDate,
  ...props
}: IPropsType) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {assignedTracker && assignedDate && (
        <Link url={url} label={assignedTracker} />
      )}
      {assignedTracker && !assignedDate && (
        <GrayText>RL Submitted for Approval</GrayText>
      )}
      {!assignedTracker && (
        <AssignText onClick={() => setOpen(true)}>Assign</AssignText>
      )}
      {open && <AssignModel {...props} onClose={() => setOpen(false)} />}
    </>
  )
}

export default Tracker
