import { IOpsCompsByReason } from '../index'
import Main from './Main'

interface IProps {
  opsCompsByReasons: IOpsCompsByReason[]
}

function OpsCompsByReason({ opsCompsByReasons }: IProps) {
  return <Main opsCompsByReasons={opsCompsByReasons} />
}

export default OpsCompsByReason
