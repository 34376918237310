import styled from 'styled-components'

import { track } from 'pared/analytics/leftNavigation'
import FeedbackModal from 'pared/components/FeedbackModal'

interface IProps {
  isFeedbackFormVisible: boolean
  setIsFeedbackFormVisible: any
  color?: string
}

const Main = ({
  isFeedbackFormVisible,
  setIsFeedbackFormVisible,
  color,
}: IProps) => {
  const onClickGiveFeedback = () => {
    setIsFeedbackFormVisible(true)
    track.giveFeedbackClicked()
  }

  const feedbackModal = isFeedbackFormVisible ? (
    <FeedbackModal
      isFeedbackFormVisible={isFeedbackFormVisible}
      setIsFeedbackFormVisible={setIsFeedbackFormVisible}
    />
  ) : null

  return (
    <FeedbackContainer>
      <FeedbackLinkAnchor onClick={onClickGiveFeedback} color={color}>
        Give Feedback
      </FeedbackLinkAnchor>
      {feedbackModal}
    </FeedbackContainer>
  )
}

const FeedbackContainer = styled.div`
  margin: 0;
`

const FeedbackLinkAnchor = styled.a`
  color: ${(props) => props.color || 'white'};
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  :link {
    text-decoration: none;
  }
  :hover {
    text-decoration: underline;
  }
`

export default Main
