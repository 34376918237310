import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { IApiDataType, ISelectDataType } from '../types'

interface IQueryDataType {
  getQfpEntities: {
    nodes: {
      id: number
      code: string
      type: string
      brandName: string
    }[]
  }
}

interface IQueryVariablesType {
  iType: string
}

const query = gql`
  query LfrGfpAllGroupFilter($iType: String!) {
    getQfpEntities(iType: $iType) {
      nodes {
        id
        code
        type
        brandName
      }
    }
  }
`

const useLfrGfpAllGroupFilter = () => {
  const { data, loading } = useQuery<IQueryDataType, IQueryVariablesType>(
    query,
    {
      variables: {
        iType: 'all',
      },
    },
  )

  return {
    data: useMemo((): IApiDataType => {
      if (!data) return null

      const brandIdMap = new Map()
      const brands = data.getQfpEntities.nodes.reduce((result, entity) => {
        if (result.find((e) => e.id === entity.brandName)) return result

        brandIdMap.set(entity.brandName, entity.id)
        result.push({
          id: entity.brandName,
          parentId: 'root',
          label: entity.brandName,
        })
        return result
      }, [] as ISelectDataType[])

      const locations = data.getQfpEntities.nodes.reduce((res, n) => {
        const brandId = brandIdMap.get(n.brandName)

        if (res.find((r) => r.id === brandId)) {
          return [
            ...res,
            {
              id: n.id.toString(),
              parentId: n.brandName,
              ids: [n.id],
              label: n.code.split(' - ')[1] || n.brandName,
            },
          ]
        } else {
          return [
            ...res,
            {
              id: brandId,
              parentId: n.brandName,
              ids: [brandId],
              label: `Brand - ${n.brandName}`,
              // add list.groupBy to make hasGroupBy = true
              list: [
                {
                  id: `Brand - ${n.brandName}`,
                  header: `Brand Name`,
                  name: n.brandName,
                  groupBy: {
                    id: `Brand - ${n.brandName}`,
                    header: `Brand Name`,
                    name: n.brandName,
                  },
                },
              ],
            },
          ]
        }
      }, [] as ISelectDataType[])

      return {
        values: [...brands, ...locations],
        defaultValue: [locations[0].id],
      }
    }, [data]),
    loading,
  }
}

export default useLfrGfpAllGroupFilter
