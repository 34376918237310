import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import { ILocationInfo } from 'pared/components/LocationInfo/hooks/useLocationInfo'
import LocationInfo from 'pared/components/LocationInfo/index'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import LocationSelector from 'pared/components/nav/LocationSelector'
import { FILTER_TYPE } from 'pared/constants'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import {
  IDateRange,
  TYPE_PERIOD,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'

import AcrChart from './AcrChart'
import Chart from './Chart'
import OpsCompsByEmployee from './OpsCompsByEmployee'
import OpsCompsByReason from './OpsCompsByReason'
import Reviews from './Reviews'
import SmgModTable from './SmgModTable'
import SummaryV3 from './SummaryV3'
import useGuestConfig from './hooks/useGuestConfig'
import {
  IGuestKpi,
  IGuestOpentablKpi,
  IGuestReview,
  IOpsCompsByReason,
} from './index'

interface IProps {
  navParams: INavParams
  employeeDayPart: string | null
  onSelectEmployeeDayPart: (event: any) => void
  opsCompsByReasons: IOpsCompsByReason[]
  guestKpis: IGuestKpi[]
  selectedDateRange: IDateRange | null
  selectedLocation: ILocationInfo | null
  guestReviews: IGuestReview[]
  isGuestReviewsLoading: boolean
  guestOpentableKpis: IGuestOpentablKpi
}

interface IKpiTh {
  danger?: boolean
  success?: boolean
  book?: boolean
  isBackgroundGrey?: boolean
}

function WebSmallMain({
  navParams,
  employeeDayPart,
  onSelectEmployeeDayPart,
  opsCompsByReasons,
  guestKpis,
  selectedDateRange,
  selectedLocation,
  guestReviews,
  isGuestReviewsLoading,
  guestOpentableKpis,
}: IProps) {
  const brandSettings = getBrandSettings()
  const guestConfig = useGuestConfig(brandSettings.brand)

  if (!selectedLocation) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  const momentfeed = _.first(
    _.filter(guestKpis, ['reviewSource', 'momentfeed']),
  )
  const acr = _.first(_.filter(guestKpis, ['reviewSource', 'acr']))
  const google = _.first(_.filter(guestKpis, ['reviewSource', 'google']))
  const facebook = _.first(_.filter(guestKpis, ['reviewSource', 'facebook']))
  const delivery = _.first(_.filter(guestKpis, ['reviewSource', 'delivery']))
  const yelp = _.first(_.filter(guestKpis, ['reviewSource', 'yelp']))
  const all = _.first(_.filter(guestKpis, ['reviewSource', 'all']))
  const yext = _.first(_.filter(guestKpis, ['reviewSource', 'yext']))
  const dissatisfaction = _.first(
    _.filter(guestKpis, ['reviewSource', 'dissatisfaction']),
  )
  const inaccurate = _.first(
    _.filter(guestKpis, ['reviewSource', 'inaccurate']),
  )

  const overallSatisfaction = _.first(
    _.filter(guestKpis, ['reviewSource', 'overallSatisfaction']),
  )
  const orderNotOnTime = _.first(
    _.filter(guestKpis, ['reviewSource', 'orderNotOnTime']),
  )
  const tasteOfFood = _.first(
    _.filter(guestKpis, ['reviewSource', 'tasteOfFood']),
  )
  const acrRatingScore = _.get(acr, 'ratingScore', '-')
  const momentfeedRatingScore = _.get(momentfeed, 'ratingScore', '-')
  const deliveryRatingScore = _.get(delivery, 'ratingScore', '-')
  const allRatingScore = _.get(all, 'ratingScore', '-')
  const yextRatingScore = _.get(yext, 'ratingScore', '-')
  const dissatisfactionRatingScore = _.get(dissatisfaction, 'ratingScore', '-')
  const inaccurateRatingScore = _.get(inaccurate, 'ratingScore', '-')
  const overallSatisfactionRatingScore = _.get(
    overallSatisfaction,
    'ratingScore',
    '-',
  )
  const orderNotOnTimeRatingScore = _.get(orderNotOnTime, 'ratingScore', '-')
  const tasteOfFoodRatingScore = _.get(tasteOfFood, 'ratingScore', '-')
  const acrRatingRank = _.get(acr, 'ratingRank', '-')
  const momentfeedRatingRank = _.get(momentfeed, 'ratingRank', '-')
  const deliveryRatingRank = _.get(delivery, 'ratingRank', '-')
  const allRatingRank = _.get(all, 'ratingRank', '-')
  const yextRatingRank = _.get(yext, 'ratingRank', '-')

  let acrDetail = acr ? `${acrRatingScore}` : '-'
  if (acr) {
    const acrRatingCount = _.get(acr, 'ratingCount', 0)
    const acrRatingCountText =
      acrRatingCount === 1 ? '1 survey' : `${acrRatingCount} surveys`

    acrDetail = `${acrRatingScore} (${acrRatingCountText})`
  }

  const googleRatingScore = _.get(google, 'ratingScore', '-')
  let googleDetail = '-'

  if (google) {
    const googleRatingCount = _.get(google, 'ratingCount', 0)
    const googleRatingCountText =
      googleRatingCount === 1 ? '1 rating' : `${googleRatingCount} ratings`

    googleDetail = `${googleRatingScore} (${googleRatingCountText})`
  }

  const facebookRatingScore = _.get(facebook, 'ratingScore', '-')
  let facebookDetail = '-'

  if (facebook) {
    const facebookRatingCount = _.get(facebook, 'ratingCount', 0)
    const facebookRatingCountText =
      facebookRatingCount === 1 ? '1 rating' : `${facebookRatingCount} ratings`

    facebookDetail = `${facebookRatingScore} (${facebookRatingCountText})`
  }

  const yelpRatingScore = _.get(yelp, 'ratingScore', '-')
  let yelpDetail = '-'

  if (yelp) {
    const yelpRatingCount = _.get(yelp, 'ratingCount', 0) || 0
    const yelpRatingCountText =
      yelpRatingCount === 1 ? '1 rating' : `${yelpRatingCount} ratings`

    yelpDetail =
      yelpRatingScore === '-'
        ? `${yelpRatingScore}`
        : `${yelpRatingScore} (${yelpRatingCountText})`
  }

  let allDetail = '-'
  if (all) {
    const allRatingCount = _.get(all, 'ratingCount', 0) || 0
    const allRatingCountText =
      allRatingCount === 1 ? '1 rating' : `${allRatingCount} ratings`

    allDetail =
      allRatingScore === '-'
        ? `${allRatingScore}`
        : `${allRatingScore} (${allRatingCountText})`
  }

  let yextDetail = '-'
  if (yext) {
    const yextRatingCount = _.get(yext, 'ratingCount', 0) || 0
    const yextRatingCountText =
      yextRatingCount === 1 ? '1 rating' : `${yextRatingCount} ratings`

    yextDetail =
      yextRatingScore === '-'
        ? `${yextRatingScore}`
        : `${yextRatingScore} (${yextRatingCountText})`
  }

  let deliveryDetail = '-'

  if (delivery?.ratingCount !== null) {
    const deliveryRatingCount = _.get(delivery, 'ratingCount', 0) || 0
    const deliveryRatingCountText =
      deliveryRatingCount === 1 ? '1 rating' : `${deliveryRatingCount} ratings`

    deliveryDetail =
      deliveryRatingScore === '-'
        ? `${deliveryRatingScore}`
        : `${deliveryRatingScore} (${deliveryRatingCountText})`
  }

  let momentfeedDetail = '-'

  if (momentfeed) {
    const momentfeedRatingCount = _.get(momentfeed, 'ratingCount', 0) || 0
    const momentfeedRatingCountText =
      momentfeedRatingCount === 1
        ? '1 rating'
        : `${momentfeedRatingCount} ratings`

    momentfeedDetail =
      momentfeedRatingScore === '-'
        ? `${momentfeedRatingScore}`
        : `${momentfeedRatingScore} (${momentfeedRatingCountText})`
  }

  let dissatisfactionDetail = '-'
  if (dissatisfaction) {
    const dissatisfactionRatingCount =
      _.get(dissatisfaction, 'ratingCount', 0) || 0
    const dissatisfactionRatingCountText =
      dissatisfactionRatingCount === 1
        ? '1 rating'
        : `${dissatisfactionRatingCount} ratings`

    dissatisfactionDetail =
      dissatisfactionRatingScore === '-'
        ? `${dissatisfactionRatingScore}`
        : `${dissatisfactionRatingScore} (${dissatisfactionRatingCountText})`
  }

  let inaccurateDetail = '-'
  if (inaccurate) {
    const inaccurateRatingCount = _.get(inaccurate, 'ratingCount', 0) || 0
    const inaccurateRatingCountText =
      inaccurateRatingCount === 1
        ? '1 rating'
        : `${inaccurateRatingCount} ratings`

    inaccurateDetail =
      inaccurateRatingScore === '-'
        ? `${inaccurateRatingScore}`
        : `${inaccurateRatingScore} (${inaccurateRatingCountText})`
  }

  let overallSatisfactionDetail = '-'
  if (overallSatisfaction) {
    const overallSatisfactionRatingCount =
      _.get(overallSatisfaction, 'ratingCount', 0) || 0
    const overallSatisfactionRatingCountText =
      overallSatisfactionRatingCount === 1
        ? '1 survey'
        : `${overallSatisfactionRatingCount} surveys`

    overallSatisfactionDetail =
      overallSatisfactionRatingScore === '-'
        ? `${overallSatisfactionRatingScore}`
        : `${overallSatisfactionRatingScore} (${overallSatisfactionRatingCountText})`
  }

  let orderNotOnTimeDetail = '-'
  if (orderNotOnTime) {
    const orderNotOnTimeRatingCount =
      _.get(orderNotOnTime, 'ratingCount', 0) || 0
    const orderNotOnTimeRatingCountText =
      orderNotOnTimeRatingCount === 1
        ? '1 survey'
        : `${orderNotOnTimeRatingCount} surveys`

    orderNotOnTimeDetail =
      orderNotOnTimeRatingScore === '-'
        ? `${orderNotOnTimeRatingScore}`
        : `${orderNotOnTimeRatingScore} (${orderNotOnTimeRatingCountText})`
  }

  let tasteOfFoodDetail = '-'
  if (tasteOfFood) {
    const tasteOfFoodRatingCount = _.get(tasteOfFood, 'ratingCount', 0) || 0
    const tasteOfFoodRatingCountText =
      tasteOfFoodRatingCount === 1
        ? '1 survey'
        : `${tasteOfFoodRatingCount} surveys`

    tasteOfFoodDetail =
      tasteOfFoodRatingScore === '-'
        ? `${tasteOfFoodRatingScore}`
        : `${tasteOfFoodRatingScore} (${tasteOfFoodRatingCountText})`
  }

  const opentableRatingScore = _.get(guestOpentableKpis, 'ratingScore', '-')
  const opentableRatingCount = _.get(guestOpentableKpis, 'ratingCount', '-')
  const opentableRatingScoreChange = _.get(
    guestOpentableKpis,
    'ratingScoreChange',
    '-',
  )
  const opentableSeatedCovers = _.get(guestOpentableKpis, 'seatedCovers', '-')
  const opentableSeatedCoversChange = _.get(
    guestOpentableKpis,
    'seatedCoversChange',
    '-',
  )
  const opentableOpentableCovers = _.get(
    guestOpentableKpis,
    'opentableCovers',
    '',
  )
  const opentableOpentableCoversChange = _.get(
    guestOpentableKpis,
    'opentableCoversChange',
    '',
  )
  const opentableYourNetworkCovers = _.get(
    guestOpentableKpis,
    'yourNetworkCovers',
    '',
  )
  const opentableYourNetworkCoversChange = _.get(
    guestOpentableKpis,
    'yourNetworkCoversChange',
    '',
  )
  const opentablePhoneCovers = _.get(guestOpentableKpis, 'phoneCovers', '')
  const opentablePhoneCoversChange = _.get(
    guestOpentableKpis,
    'phoneCoversChange',
    '',
  )
  const opentableWalkinCovers = _.get(guestOpentableKpis, 'walkinCovers', '')
  const opentableWalkinCoversChange = _.get(
    guestOpentableKpis,
    'walkinCoversChange',
    '',
  )
  const opentableNoShowRate = _.get(guestOpentableKpis, 'noShowRate', '')
  const opentableNoShowRateChange = _.get(
    guestOpentableKpis,
    'noShowRateChange',
    '',
  )

  const opentableDetail =
    opentableRatingScore === '-'
      ? `${opentableRatingScore}`
      : `${opentableRatingScore} (${opentableRatingCount})`

  const storeTitle = `
    ${brandSettings.labels.business.store}:
    ${selectedLocation.displayName}
  `

  const locationCount = Math.max(
    _.get(delivery, 'locationCount', 1),
    _.get(all, 'locationCount', 1),
    _.get(acr, 'locationCount', 1),
    _.get(yext, 'locationCount', 1),
  )

  return (
    <>
      <SelectorContainer>
        <LocationSelector navParams={navParams} />
        <DateRangeSelector
          navParams={navParams}
          excludedDateRanges={[TYPE_YESTERDAY]}
          preSelectedDateRange={TYPE_PERIOD}
        />
      </SelectorContainer>
      <MainContainer>
        <PageTitleDiv>{storeTitle}</PageTitleDiv>
        {guestConfig.isUpdatedWeekly ? (
          <SubtitleContainer>Updated Weekly</SubtitleContainer>
        ) : null}
        <LocationInfo locationId={selectedLocation.id} />

        {guestConfig.summary && selectedDateRange ? (
          <SummaryCotinaer>
            <SummaryV3
              startDate={selectedDateRange?.startDate?.format('YYYY-MM-DD')}
              endDate={selectedDateRange?.endDate?.format('YYYY-MM-DD')}
              locationId={selectedLocation.id}
            />
          </SummaryCotinaer>
        ) : null}

        <VerticalSpacer40px />

        <FullScreenTable>
          <thead>
            <tr>
              <th>{guestConfig.kpiDisplayName || 'KPIs'}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {guestConfig.smgMwb?.isVisible ? (
              <>
                <tr>
                  <td>SMG Overall Satisfaction Rating</td>
                  <BoldNumTd>{overallSatisfactionDetail}</BoldNumTd>
                </tr>
                <tr>
                  <td>SMG Time to Receive Order Rating</td>
                  <BoldNumTd>{orderNotOnTimeDetail}</BoldNumTd>
                </tr>
                <tr>
                  <td>SMG Taste of Food Rating</td>
                  <BoldNumTd>{tasteOfFoodDetail}</BoldNumTd>
                </tr>
              </>
            ) : null}
            {guestConfig.acr?.isVisible ? (
              <tr>
                <td>ACR Score</td>
                <BoldNumTd>{acrDetail}</BoldNumTd>
              </tr>
            ) : null}
            {guestConfig.momentfeed?.isVisible ? (
              <tr>
                <td>Momentfeed Rating</td>
                <BoldNumTd>{momentfeedDetail}</BoldNumTd>
              </tr>
            ) : null}
            {guestConfig.yext?.isVisible ? (
              <tr>
                <td>Yext Rating</td>
                <BoldNumTd>{yextDetail}</BoldNumTd>
              </tr>
            ) : null}
            {guestConfig.facebook?.isVisible ? (
              <tr>
                <td>Facebook Rating</td>
                <BoldNumTd>{facebookDetail}</BoldNumTd>
              </tr>
            ) : null}
            {guestConfig.google?.isHidden ? null : (
              <tr>
                <td>Google Rating</td>
                <BoldNumTd>{googleDetail}</BoldNumTd>
              </tr>
            )}
            {guestConfig.delivery?.isVisible ? (
              <tr>
                <td>Delivery Rating</td>
                <BoldNumTd>{deliveryDetail}</BoldNumTd>
              </tr>
            ) : null}
            {guestConfig.yelp?.isVisible ? (
              <tr>
                <td>Yelp Rating</td>
                <BoldNumTd>{yelpDetail}</BoldNumTd>
              </tr>
            ) : null}
            {guestConfig.allRating.isVisible ? (
              <tr>
                <td>{guestConfig.allRating.displayName} Rating</td>
                <BoldNumTd>{allDetail}</BoldNumTd>
              </tr>
            ) : null}
            {guestConfig.smg?.isVisible ? (
              <tr>
                <td>Dissatisfaction %</td>
                <BoldNumTd>{dissatisfactionDetail}</BoldNumTd>
              </tr>
            ) : null}
            {guestConfig.smg?.isVisible ? (
              <tr>
                <td>Inaccurate %</td>
                <BoldNumTd>{inaccurateDetail}</BoldNumTd>
              </tr>
            ) : null}
          </tbody>
        </FullScreenTable>

        {!guestConfig.acr.isVisible ? null : (
          <>
            <VerticalSpacer40px />

            <AcrChart
              selectedLocation={selectedLocation}
              selectedDateRange={selectedDateRange}
            />
          </>
        )}

        <VerticalSpacer40px />

        <FullScreenTable>
          <thead>
            <tr>
              <th>
                Rankings
                <PtdKpiCategoryNameSubtitleDiv>{`out of ${locationCount} stores)`}</PtdKpiCategoryNameSubtitleDiv>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {guestConfig.acr?.isVisible ? (
              <tr>
                <td>ACR Score</td>
                <BoldNumTd>{acrRatingRank}</BoldNumTd>
              </tr>
            ) : null}
            {guestConfig.momentfeed?.isVisible ? (
              <tr>
                <td>Momentfeed Rating</td>
                <BoldNumTd>{momentfeedRatingRank}</BoldNumTd>
              </tr>
            ) : null}
            {guestConfig.yext?.isVisible ? (
              <tr>
                <td>Yext Rating</td>
                <BoldNumTd>{yextRatingRank}</BoldNumTd>
              </tr>
            ) : null}
            {guestConfig.delivery?.isVisible ? (
              <tr>
                <td>Delivery Rating</td>
                <BoldNumTd>{deliveryRatingRank}</BoldNumTd>
              </tr>
            ) : null}
            {guestConfig.allRating.isVisible ? (
              <tr>
                <td>{guestConfig.allRating.displayName} Rating</td>
                <BoldNumTd>{allRatingRank}</BoldNumTd>
              </tr>
            ) : null}
          </tbody>
        </FullScreenTable>

        {!guestConfig.opentable?.isVisible ? null : (
          <>
            <VerticalSpacer40px />

            <FullScreenTable>
              <thead>
                <tr>
                  <th>Opentable</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Rating</td>
                  <BoldNumTd>{opentableDetail}</BoldNumTd>
                </tr>
                <tr>
                  <td>YoY Rating Change %</td>
                  <BoldNumTd>{opentableRatingScoreChange}</BoldNumTd>
                </tr>
                <tr>
                  <td>Seats Covers</td>
                  <BoldNumTd>{opentableSeatedCovers}</BoldNumTd>
                </tr>
                <tr>
                  <td>YoY Seats Covers Changes %</td>
                  <BoldNumTd>{opentableSeatedCoversChange}</BoldNumTd>
                </tr>
                <tr>
                  <td>Opentable Covers</td>
                  <BoldNumTd>{opentableOpentableCovers}</BoldNumTd>
                </tr>
                <tr>
                  <td>YoY Opentable Covers Change %</td>
                  <BoldNumTd>{opentableOpentableCoversChange}</BoldNumTd>
                </tr>
                <tr>
                  <td>Your Network Covers</td>
                  <BoldNumTd>{opentableYourNetworkCovers}</BoldNumTd>
                </tr>
                <tr>
                  <td>YoY Your Network Covers Change %</td>
                  <BoldNumTd>{opentableYourNetworkCoversChange}</BoldNumTd>
                </tr>
                <tr>
                  <td>Phone Covers</td>
                  <BoldNumTd>{opentablePhoneCovers}</BoldNumTd>
                </tr>
                <tr>
                  <td>YoY Phone Covers Change %</td>
                  <BoldNumTd>{opentablePhoneCoversChange}</BoldNumTd>
                </tr>
                <tr>
                  <td>Walk-in Covers</td>
                  <BoldNumTd>{opentableWalkinCovers}</BoldNumTd>
                </tr>
                <tr>
                  <td>YoY Walk-in Covers Change %</td>
                  <BoldNumTd>{opentableWalkinCoversChange}</BoldNumTd>
                </tr>
                <tr>
                  <td>No-Show Rate</td>
                  <BoldNumTd>{opentableNoShowRate}</BoldNumTd>
                </tr>
                <tr>
                  <td>YoY No-Show Rate Change %</td>
                  <BoldNumTd>{opentableNoShowRateChange}</BoldNumTd>
                </tr>
              </tbody>
            </FullScreenTable>
          </>
        )}

        <VerticalSpacer20px />

        {!guestConfig.smgMod?.isVisible ? null : (
          <SmgModTable
            locationId={selectedLocation.id}
            startDate={selectedDateRange?.startDateStr || null}
            endDate={selectedDateRange?.endDateStr || null}
          />
        )}

        <TableDescription>Guest Reviews</TableDescription>
        <ScrollableTableContainer>
          <Reviews
            guestReviews={guestReviews}
            isLoading={isGuestReviewsLoading}
          />
        </ScrollableTableContainer>

        <VerticalSpacer40px />

        <TableDescription>Guest Ratings</TableDescription>
        {selectedDateRange && selectedLocation ? (
          <Chart
            startDate={selectedDateRange.startDateStr}
            endDate={selectedDateRange.endDateStr}
            dateRangeType={selectedDateRange.type}
            locationId={selectedLocation.id}
            locationName={selectedLocation.name}
          />
        ) : null}

        {!guestConfig.opsCompsBreakdown.isVisible ? null : (
          <>
            <VerticalSpacer40px />

            <OpsCompsByReason opsCompsByReasons={opsCompsByReasons} />
          </>
        )}

        <VerticalSpacer40px />

        {guestConfig.momentfeed?.isVisible ? (
          <>
            <EmployeeOuterContainer>
              <EmployeeContainer>By Employee</EmployeeContainer>
              <FilterContainer>
                <EmployeeSelectorContainer>
                  <DaypartFormControl
                    variant="outlined"
                    margin="dense"
                    style={{ width: '90vw' }}
                  >
                    <OptionSelect
                      displayEmpty
                      value={employeeDayPart}
                      onChange={onSelectEmployeeDayPart}
                    >
                      <MenuItem key="dinner" value="dinner">
                        Dinner Only
                      </MenuItem>
                      <MenuItem key="lunch" value="lunch">
                        Lunch Only
                      </MenuItem>
                      <MenuItem key="all" value="all">
                        All
                      </MenuItem>
                    </OptionSelect>
                  </DaypartFormControl>
                </EmployeeSelectorContainer>
              </FilterContainer>
            </EmployeeOuterContainer>
            {employeeDayPart ? (
              <OpsCompsByEmployee
                navParams={navParams}
                dayPart={employeeDayPart}
                filterType={FILTER_TYPE.DATE}
              />
            ) : null}
          </>
        ) : null}
        <VerticalSpacer80px />
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  width: 100%;
  color: ${COLORS.Chalkboard};
  padding: 30px 25px 50px 25px;
`

const SummaryCotinaer = styled.div`
  margin: 20px 0px 0px;
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;
  padding: 0 0 15px 0;
`

const TableDescription = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;
`

const ScrollableTableContainer = styled.div`
  overflow-x: scroll;
  border: 1px solid;
  padding-left: 30px;
  width: 90vw;
  padding-top: 20px;
  padding-bottom: 20px;
`

const PtdKpiCategoryNameSubtitleDiv = styled.div`
  width: 100%;
  padding: 0 0 5px 0;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const BoldNumTd = styled.td`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
`

const PageTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 0;
`

const EmployeeOuterContainer = styled.div`
  display: inline-block;
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  float: left;
`

const EmployeeContainer = styled(PageHeader)`
  margin-bottom: 10px;
`

const FilterContainer = styled.div`
  display: inline-block;
`

const DaypartFormControl = styled(FormControl)`
  background-color: white;
`

const OptionSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const EmployeeSelectorContainer = styled.div`
  float: left;
`

const SelectorContainer = styled.div`
  padding: 10px 25px 10px 25px;
  background-color: rgb(196, 196, 196);
`

const LocationSelectorContainer = styled.div`
  float: left;
  margin-right: 7px;
`

const VerticalSpacer80px = styled.div`
  width: 100%;
  height: 80px;
`

const VerticalSpacer40px = styled.div`
  width: 100%;
  height: 40px;
`

const VerticalSpacer20px = styled.div`
  width: 100%;
  height: 20px;
`

const FullScreenTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};
  text-align: right;

  th {
    font-family: Lexend-SemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  td,
  th {
    vertical-align: middle;
    padding: 8px 0;
  }

  th:first-child {
    text-align: left;
  }

  td:first-child {
    text-align: left;
  }

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  thead::after {
    content: '';
    display: block;
    height: 10px;
    width: 100%;
    background: transparent;
  }
`

const PageStatusDiv = styled.div`
  padding: 30px 0 0 0;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

export default WebSmallMain
