import styled from 'styled-components'

const StyledImg = styled.img`
  margin-top: 20px;
  max-width: 100%;
  height: auto;
`

const SubTitleContainer = styled.div`
  position: relative;
  display: inline-block;
`
const SubTitle = styled.div`
  padding: 0 8px 0 100px;
  font-family: Lexend-SemiBold;
  font-weight: bold;
  font-size: 20px;
  line-height: 45px;
  text-align: right;
  font-style: italic;
  background: linear-gradient(to right, #afa67c, #fde9c8, #afa67c);
`

const Content = styled.div`
  font-weight: 400;
  font-size: 16px;
  font-family: Lexend-Regular;
  line-height: 20px;
  padding: 15px 0 15px 0;
`
const Text = styled.div`
  padding: 5px 0 5px 0;
  display: flex;
`

const ShiftLeaderAssessment = () => {
  return (
    <div>
      <StyledImg
        src={
          require('pared/images/brands/jsc_bk/shift-leader-assesment.png')
            .default
        }
        alt="Shift Leader Assesment"
      />
      <Content>
        <Text>
          Leadership is the ability of an individual to influence, motivate, and
          enable others to contribute toward the eectiveness and success of the
          organization. At this level, leadership should be evident throughout
          their individual shifts and their ability to meet daily
          responsibilities through the eorts of their teams.
        </Text>
        <Text>• Is vocal during their shift control in a positive manner</Text>
        <Text>• Looks for opportunities to reinforce behavior</Text>
        <Text>
          • Has the ability to accept and benefit from constructive feedback
        </Text>
        <Text>• Meets attendance standards</Text>
        <Text>• Demonstrates proper procedures</Text>
        <Text>• Demonstrates self control</Text>
        <Text>• Treats all employees fairly</Text>
      </Content>
      <SubTitleContainer>
        <SubTitle>COMMUNICATION</SubTitle>
      </SubTitleContainer>
      <Content>
        <Text>• Utilizes communication log and daily planner</Text>
        <Text>
          • Communicates relative information to other leaders regarding product
          shortages, call os, etc.
        </Text>
        <Text>
          • Communicates shift goals with the team at the beginning of each
          shift and provides updates throughout the shift
        </Text>
        <Text>• Leaves communication regarding new employee performance</Text>
      </Content>
      <SubTitleContainer>
        <SubTitle>TRAINING AND DEVELOPMENT</SubTitle>
      </SubTitleContainer>
      <Content>
        <Text>• Uses the four step training method</Text>
        <Text>• Uses training trackers used</Text>
        <Text>• Eectively instructs employees to use correct procedures</Text>
        <Text>• travel paths to monitor progress</Text>
        <Text>• Has completed BKU</Text>
        <Text>
          • Develops teamwork and productivity of employees and cross trains
        </Text>
      </Content>
      <SubTitleContainer>
        <SubTitle>TEAM BUILDING</SubTitle>
      </SubTitleContainer>
      <Content>
        <Text>• Shares knowledge</Text>
        <Text>• Reinforces good behavior and redirects negative behavior</Text>
        <Text>
          • Creates awareness of nightly goals and objectives to the team
        </Text>
        <Text>• Maintains focus on goals and objectives</Text>
        <Text>
          • Makes sure each team member understands their contributions to the
          goals or objectives
        </Text>
        <Text>• Demonstrates and enforces JSC’s philosophy of business</Text>
        <Text>• Greets and say goodbye to each team member</Text>
      </Content>
      <SubTitleContainer>
        <SubTitle>SHIFT PLANNING</SubTitle>
      </SubTitleContainer>
      <Content>
        <Text>
          • Has a shift plan set up (prep needed, cleaning, goals set, breaks
          planned, etc.)
        </Text>
        <Text>• Has the rroper setup of command station</Text>
        <Text>• Has properly setup positioning for best productivity</Text>
        <Text>• Monitors labor as needed and reacts accordingly</Text>
        <Text>
          • Ability to troubleshoot-remedies scheduling problems (call os, etc.)
        </Text>
        <Text>• Monitors products and reacts to anticipated shortages</Text>
      </Content>
      <SubTitleContainer>
        <SubTitle>TRAVEL PATH</SubTitle>
      </SubTitleContainer>
      <Content>
        <Text>• Enforces employee appearance</Text>
        <Text>• Does not tolerate cell phone usage</Text>
        <Text>• Follows up and coaches on service procedures</Text>
        <Text>• Follows up on all cleaning tasks</Text>
        <Text>
          • “Hot and fresh”, “friendly and fast”, “clean and safe” continually
          monitored
        </Text>
        <Text>
          • Food safety standards are continually monitored (critical checklist
          completed and watched throughout shift)
        </Text>
      </Content>
      <SubTitleContainer>
        <SubTitle>DIRECTING AND COORDINATING</SubTitle>
      </SubTitleContainer>
      <Content>
        <Text>• Demonstrates a sense of urgency and positive attitude</Text>
        <Text>• Directs team to maintain proper holding based on volume</Text>
        <Text>
          • Uses appropriate checklist (closing checklist, shift changeover
          checklist)
        </Text>
        <Text>• Directs team to keep stations clean</Text>
      </Content>
      <SubTitleContainer>
        <SubTitle>SITUATIONAL LEADERSHIP</SubTitle>
      </SubTitleContainer>
      <Content>
        <Text>
          • “what if” situations discussed (power or equipment failure, fire,
          weather/storm, police emergency
        </Text>
      </Content>
      <SubTitleContainer>
        <SubTitle>CHEF</SubTitle>
      </SubTitleContainer>
      <Content>
        <Text>
          • Can demonstrate how to adjust sales projections and adjust product
          mix
        </Text>
        <Text>
          • Understands and coaches the team on product timing, using the
          buttons, and what each light means
        </Text>
      </Content>
      <SubTitleContainer>
        <SubTitle>GUEST SERVICE</SubTitle>
      </SubTitleContainer>
      <Content>
        <Text>• Can demonstrate how to pull drive thru time reports</Text>
        <Text>• Can demonstrate how to handle a guest complaint</Text>
        <Text>• Interacts with guests</Text>
        <Text>• Table touches demonstrated</Text>
        <Text>
          • Ability to balance SOS standards between front counter and drive
          thru
        </Text>
        <Text>• Ensures all cashiers are smiling, being friendly</Text>
        <Text>
          • Ensures the greeting phrase, upselling, parting phrase (YOU RULE!
          WOULD YOU LIKE A CROWN?) and politeness is at standard and coaches at
          every opportunity
        </Text>
      </Content>
      <SubTitleContainer>
        <SubTitle>CLEANLINESS STANDARDS</SubTitle>
      </SubTitleContainer>
      <Content>
        <Text>• Coaches a “clean as you go” culture</Text>
        <Text>• Schedules and assigns cleaning tasks throughout the shift</Text>
        <Text>
          • Understands and and can demonstrate how to clean the broiler,
          fryers, shake machine, PHU’s
        </Text>
        <Text>
          • Understands the cleanliness expectations in the 4 key areas.
          Kitchen, dining area, restroom, and outside
        </Text>
        <Text>
          • When closing the restaurant or during a shift change over, is
          leaving the restaurant in a clean and acceptable condition
        </Text>
      </Content>
      <SubTitleContainer>
        <SubTitle>CASH MANAGEMENT</SubTitle>
      </SubTitleContainer>
      <Content>
        <Text>• Understands petty cash procedures</Text>
        <Text>
          • Understands deposit schedule (2 deposits per day and takes to the
          bank)
        </Text>
        <Text>• Understands cash shortage procedures</Text>
        <Text>• Understands how to handle short change complaints</Text>
        <Text>• Understands how to complete coin order</Text>
        <Text>• Understands how to enter deposits in R365</Text>
        <Text>
          • Can demonstrate how to log in and use the square in the event that
          credit card machines go down and how to follow the procedure
        </Text>
      </Content>
      <SubTitleContainer>
        <SubTitle>ADMINISTRATIVE</SubTitle>
      </SubTitleContainer>
      <Content>
        <Text>• Can demonstrate how to verify the DSS in R365</Text>
        <Text>
          • Can demonstrate how to count inventory and enter in R365 and
          spreadsheet
        </Text>
        <Text>
          • Can demonstrate how to complete the daily bun count, pull the actual
          v. ideal report, and order correct amount of buns
        </Text>
        <Text>• Can demonstrate how to complete TruOI temp logs</Text>
        <Text>
          • Can demonstrate how to pull up the previous 30 days of TruOI temp
          logs
        </Text>
      </Content>
    </div>
  )
}

export default ShiftLeaderAssessment
