import { useEffect } from 'react'
import { useMap } from 'react-leaflet'

interface IPropsType {
  latitude: number
  longitude: number
  zoom: number
}

const Control = ({ latitude, longitude, zoom }: IPropsType) => {
  const map = useMap()

  useEffect(() => {
    map.setView([latitude, longitude], zoom)
  }, [latitude, longitude, zoom])

  return null
}

export default Control
