import { gql } from '@apollo/client'

export interface ICreditCard {
  paymentMethod: string
  totalAmount: number
}

type ColumnName =
  | 'doorDashAmount'
  | 'grubHubAmount'
  | 'postMatesAmount'
  | 'uberEatsAmount'
  | 'netFoodSalesAmount'
  | 'netNonFoodSalesAmount'
  | 'salesTaxAmount'
  | 'paidInAmount'
  | 'paidOutsAmount'
  | 'giftCardSoldAmount'
  | 'giftCardReloadsAmount'
  | 'giftRedeemedAmount'
  | 'deliveryTipsAmount'
  | 'donationRoundUpAmount'
  | 'donationVfwAmount'
  | 'schoolVoucherAmount'
  | 'overShortAmount'

export interface IAccountingDataNodesType extends Record<ColumnName, number> {
  code: string
  deposit: number[]
  creditCard: ICreditCard[]
}

export interface IAccountingDataType {
  listLocationGroupAccountingData: {
    nodes: IAccountingDataNodesType[]
  }
}

export interface IAccountingDataVariablesType {
  iStartDate: string
  iEndDate: string
  iFilter: any
}

export const listLocationGroupAccountingData = gql`
  query ListLocationGroupAccountingData(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupAccountingData(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        code
        deposit
        creditCard
        doorDashAmount
        grubHubAmount
        postMatesAmount
        uberEatsAmount
        netFoodSalesAmount
        netNonFoodSalesAmount
        salesTaxAmount
        paidInAmount
        paidOutsAmount
        giftCardSoldAmount
        giftCardReloadsAmount
        giftRedeemedAmount
        deliveryTipsAmount
        donationRoundUpAmount
        donationVfwAmount
        schoolVoucherAmount
        overShortAmount
      }
    }
  }
`
