import styled from 'styled-components'

interface IProps {
  label: string
  children: React.ReactNode
}

export const InputWithLeftLabel = ({ label, children }: IProps) => (
  <Container>
    <Label>{label}</Label>
    {children}
  </Container>
)

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  justify-content: space-between;
  gap: 30px;
`

const Label = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  width: 150px;
`
