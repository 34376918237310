import WebLargeMain from './WebLargeMain'

export interface IAlert {
  alertUserNotifiedId: number
  isRead: boolean
  alertName: string
  metadata: {
    location_id: number
    value: number
  }
  triggeredAtFromNow: string
  alertMessage: string
  tableData: object
}

interface IProps {
  alerts: IAlert[]
  alertsDisplayed: string
  onToggleArchivedAlerts: () => void
  retrieveAlerts: () => void
  isLoading: boolean
}

const Main = ({
  isLoading,
  alerts,
  alertsDisplayed,
  onToggleArchivedAlerts,
  retrieveAlerts,
}: IProps) => {
  return (
    <WebLargeMain
      isLoading={isLoading}
      alerts={alerts}
      alertsDisplayed={alertsDisplayed}
      onToggleArchivedAlerts={onToggleArchivedAlerts}
      retrieveAlerts={retrieveAlerts}
    />
  )
}

export default Main
