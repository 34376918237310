import { gql } from '@apollo/client'

export interface ILaborSummaryNodeType {
  hour: number
  sales: number
  systemwideSales: number
  differenceSales: number
  laborHours: number
  systemwideLaborHours: number
  differenceLaborHours: number
  annualOpportunityCost: number
  salesPerLaborHour: number
  systemwideSalesPerLaborHour: number
  differenceSalesPerLaborHour: number
  totalSales: number
  totalSystemwideSales: number
  totalDifferenceSales: number
  totalLaborHours: number
  totalSystemwideLaborHours: number
  totalDifferenceLaborHours: number
  totalAnnualOpportunityCost: number
  totalSalesPerLaborHour: number
  totalSystemwideSalesPerLaborHour: number
  totalDifferenceSalesPerLaborHour: number
}

export interface ILaborSummaryType {
  getSalesPerLaborHour: {
    nodes: ILaborSummaryNodeType[]
  }
}

export interface ILaborSummaryVariablesType {
  iLocationId: number
  iStartDate?: string
  iEndDate?: string
}

export const getSalesPerLaborHour = gql`
  query GetSalesPerLaborHour(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    getSalesPerLaborHour(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        hour
        sales
        systemwideSales
        differenceSales
        laborHours
        systemwideLaborHours
        differenceLaborHours
        annualOpportunityCost
        salesPerLaborHour
        systemwideSalesPerLaborHour
        differenceSalesPerLaborHour
        totalSales
        totalSystemwideSales
        totalDifferenceSales
        totalLaborHours
        totalSystemwideLaborHours
        totalDifferenceLaborHours
        totalAnnualOpportunityCost
        totalSalesPerLaborHour
        totalSystemwideSalesPerLaborHour
        totalDifferenceSalesPerLaborHour
      }
    }
  }
`
