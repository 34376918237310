import _ from 'lodash'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { INavParams } from 'pared/Routes/navParams'
import CustomizedReport from 'pared/components/CustomizedReport'
import { ILocationInfo } from 'pared/components/LocationInfo/hooks/useLocationInfo'
import LocationInfo from 'pared/components/LocationInfo/index'
import NormalizationStatus from 'pared/components/NormalizationStatus'
import HighlightSpan from 'pared/components/basicUi/HighlightSpan'
import Tooltip from 'pared/components/basicUi/Tooltip'
import Spin from 'pared/components/basicUi/spin'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import LocationSelector from 'pared/components/nav/LocationSelector'
import { EDDIE_MERLOTS_BRAND_CODE } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings, getFinancialLabel } from 'pared/customer'
import {
  IDateRange,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import useBrands from 'pared/layouts/hooks/useBrands'
import { getDay } from 'pared/utils/date'

import CustomizedTd from './CustomizedTd'
import DayPartPieChart from './DayPartPieChart'
import PerformanceTrendChart from './PerformanceTrendChart'
import PlvPercentTrendChart from './PlvPercentTrendChart'
import SalesTrendStackedBarChart from './SalesTrendStackedBarChart'
import useConfig from './hooks/useConfig'
import { IDaypartBreakdown, IRevCenterBreakdown, IStoreData } from './index'

const DATE_DISPLAY_FORMAT = 'M/D/YY'

interface IProps {
  navParams: INavParams
  isLoading: boolean
  storeData: IStoreData | null
  selectedDateRange: IDateRange | null
  errorMessage: string
  userEmployeeId: number
  selectedLocation: ILocationInfo | null
  revCenterBreakdown: IRevCenterBreakdown[]
  locationRevCenterBreakdownLoading: boolean
  daypartBreakdown: IDaypartBreakdown[]
  customizedTrendLocationFinancialKpis: {
    columns: {}[]
    total: {}
  }
  trendSalesTrendStackedBarItems?: string[]
}

const isTextNegativeNumber = (text: string) =>
  text.includes('-') || text.includes('(')

function WebSmallMain({
  navParams,
  isLoading,
  storeData,
  selectedDateRange,
  errorMessage,
  selectedLocation,
  revCenterBreakdown,
  locationRevCenterBreakdownLoading,
  daypartBreakdown,
  customizedTrendLocationFinancialKpis,
  trendSalesTrendStackedBarItems,
}: IProps) {
  const config = useConfig()
  const { brand } = useBrands()

  if (errorMessage) {
    return (
      <MainContainer>
        <PageStatusDiv>{errorMessage}</PageStatusDiv>
      </MainContainer>
    )
  }

  if (!storeData || !selectedLocation || !selectedDateRange) {
    return (
      <MainContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </MainContainer>
    )
  }

  const brandSettings = getBrandSettings()
  const financialLabel = brandSettings.labels.financial
  const businessLabel = brandSettings.labels.business
  const isAvtMonthlyVisible =
    config.avtPercentMonthly &&
    ![TYPE_TRAILING_7_DAYS, TYPE_YESTERDAY].includes(selectedDateRange.type)

  const revCenterOrder = config.revCenterOrder
  const revCenterBreakdownRows = locationRevCenterBreakdownLoading ? (
    <PageStatusDiv>Loading ...</PageStatusDiv>
  ) : (
    revCenterBreakdown
      .sort((a, b) => {
        if (revCenterOrder) {
          return (
            revCenterOrder?.[a.revenueCenter] -
            revCenterOrder?.[b.revenueCenter]
          )
        } else {
          return 0
        }
      })
      .map((d) => {
        const rawRevCenterName = _.get(d, 'revenueCenter', '')
        const mappedRevCenterName =
          config.revenueCenterNameMap?.[rawRevCenterName]

        return (
          <tr key={uuidv4()}>
            <td>
              {mappedRevCenterName ? mappedRevCenterName : rawRevCenterName}
            </td>
            <td>{_.get(d, 'revCenterNetSales', '')}</td>
            <td>{_.get(d, 'revCenterNetSalesPercent', '')}</td>
            {config.isRevCenterGuestAverageVisible ? (
              <td>{_.get(d, 'revCenterGuestAverage', '')}</td>
            ) : null}
            {config.isRevCenterCheckCountVisible ? (
              <td>{_.get(d, 'revCenterCheckCount', '')}</td>
            ) : null}
            {config.isRevCenterCheckAverageVisible ? (
              <td>{_.get(d, 'revCenterCheckAverage', '')}</td>
            ) : null}
          </tr>
        )
      })
  )

  const dapartOrder = {
    Breakfast: 1,
    Lunch: 2,
    'Mid-Day': 3,
    Afternoon: 4,
    Dinner: 5,
    Evening: 6,
    'Late-Night': 7,
  }

  const daypartBreakdownRows = locationRevCenterBreakdownLoading ? (
    <PageStatusDiv>Loading ...</PageStatusDiv>
  ) : (
    daypartBreakdown
      .sort((a, b) => dapartOrder[a.daypart] - dapartOrder[b.daypart])
      .map((d) => {
        return (
          <tr key={_.get(d, 'daypart')}>
            <td>{_.get(d, 'daypart', '')}</td>
            <td>{_.get(d, 'daypartSales', '')}</td>
            <td>{_.get(d, 'daypartSalesPercent', '')}</td>
            {config.isDaypartCheckCountVisible ? (
              <td>{_.get(d, 'daypartCheckCount', '')}</td>
            ) : null}
            {config.isDaypartCheckAverageVisible ? (
              <td>{_.get(d, 'daypartCheckAverage', '')}</td>
            ) : null}
            {config.isDaypartHourlyLaborPercentVisible ? (
              <td>{_.get(d, 'daypartHourlyLaborPercent', '')}</td>
            ) : null}
          </tr>
        )
      })
  )

  let breakDownName = 'Week'
  let breakDownFieldName: 'businessWeek' | 'businessMonth' | 'businessQuarter' =
    'businessWeek'
  let breakDownSumName = 'PTD'
  if (selectedDateRange.type === TYPE_YEAR) {
    breakDownName = 'Quarter'
    breakDownFieldName = 'businessQuarter'
    breakDownSumName = 'YTD'
  } else if (selectedDateRange.type === TYPE_QUARTER) {
    breakDownName = 'Period'
    breakDownFieldName = 'businessMonth'
    breakDownSumName = 'QTD'
  }

  return (
    <>
      <SelectorContainer>
        <LocationSelector navParams={navParams} />
        <DateRangeSelector
          navParams={navParams}
          dateRangeOptions={config.dateRangeOptions}
        />
      </SelectorContainer>
      <MainContainer>
        <PageTitleDiv>Performance</PageTitleDiv>
        <StoreNameDiv>
          {businessLabel.store}: {selectedLocation.displayName}
        </StoreNameDiv>

        <LocationInfo locationId={selectedLocation.id} />

        <NormalizationStatusDiv>
          <NormalizationStatus
            normalizationTable="location_normalized_data_daily"
            brandId={brandSettings.brandId}
          />
        </NormalizationStatusDiv>

        <VerticalSpacer40px />

        <FullScreenTable>
          <tbody>
            {config.isNetSalesVisible ? (
              <tr>
                <td>Net Sales</td>
                <BoldNumTd>{storeData.netSales}</BoldNumTd>
              </tr>
            ) : null}
            {config.isGrossSalesVisible ? (
              <tr>
                <td>Gross Sales</td>
                <BoldNumTd>{storeData.grossSales}</BoldNumTd>
              </tr>
            ) : null}
            {config.isNetSalesBudgetVisible ? (
              <tr>
                <td>Sales Budget</td>
                <BoldNumTd>{storeData.netSalesBudget}</BoldNumTd>
              </tr>
            ) : null}
            {config.isNetSalesBudgetVarianceVisible ? (
              <tr>
                <td>Sales Variance</td>
                <BoldNumTd>{storeData.netSalesBudgetVariance}</BoldNumTd>
              </tr>
            ) : null}
            {config.isNetSalesVisible ? (
              <tr>
                <td>
                  {financialLabel.sss}
                  {config.tooltips?.sss ? (
                    <Tooltip title={config.tooltips.sss} placement="right" />
                  ) : null}
                </td>
                <BoldNumTd>{storeData.netSalesSssPercent}</BoldNumTd>
              </tr>
            ) : null}
            {config.isGrossSalesVisible ? (
              <tr>
                <td>
                  {financialLabel.sss}
                  {config.tooltips?.sss ? (
                    <Tooltip title={config.tooltips.sss} placement="right" />
                  ) : null}
                </td>
                <BoldNumTd>{storeData.grossSalesSssPercent}</BoldNumTd>
              </tr>
            ) : null}
            <tr>
              <td>
                {financialLabel.transaction}
                {config.tooltips?.transaction ? (
                  <Tooltip
                    title={config.tooltips.transaction}
                    placement="right"
                  />
                ) : null}
              </td>
              <BoldNumTd>{storeData.checkCount}</BoldNumTd>
            </tr>
            {!config.isCheckCountVsLyPercentVisible ? null : (
              <>
                <tr>
                  <td>{financialLabel.transactionLy}</td>
                  <BoldNumTd>{storeData.checkCountLy}</BoldNumTd>
                </tr>
                <tr>
                  <td>{financialLabel.transactionVsLyPercent}</td>
                  <BoldNumTd>{storeData.checkCountVsLyPercent}</BoldNumTd>
                </tr>
              </>
            )}
            <tr>
              <td>
                {financialLabel.transactionAvg}
                {config.tooltips?.transactionAvg ? (
                  <Tooltip
                    title={config.tooltips.transactionAvg}
                    placement="right"
                  />
                ) : null}
              </td>
              <BoldNumTd>{storeData.checkAvg}</BoldNumTd>
            </tr>
            {!config.isSosAvgSecondsVisible ? null : (
              <tr>
                <td>{financialLabel.sos}</td>
                <BoldNumTd>{storeData.sosAvgSeconds}</BoldNumTd>
              </tr>
            )}
            {config.avtPercent ? (
              <tr>
                <td>{financialLabel.avt}</td>
                <BoldNumTd danger={isTextNegativeNumber(storeData.avtPercent)}>
                  {storeData.avtPercent}
                </BoldNumTd>
              </tr>
            ) : null}
            {isAvtMonthlyVisible ? (
              <tr>
                <td>{financialLabel.avt}</td>
                <BoldNumTd
                  danger={isTextNegativeNumber(storeData.avtPercentMonthly)}
                >
                  {storeData.avtPercentMonthly}
                </BoldNumTd>
              </tr>
            ) : null}
            {config.isPlvVisible ? (
              <tr>
                <td>{financialLabel.plv}</td>
                <BoldNumTd danger={isTextNegativeNumber(storeData.plvPercent)}>
                  {storeData.plvPercent}
                </BoldNumTd>
              </tr>
            ) : null}
            {config.isPlvLastThirtyOneDaysVisible ? (
              <tr>
                <td>Last 30 Days PLV</td>
                <BoldNumTd>
                  <CustomizedReport
                    reportName="LIST_LOCATION_STORE_PERFORMANCE_CUSTOMIZED"
                    customizedLoadingStatus="-"
                    inputParams={{
                      locationId: selectedLocation.id,
                    }}
                  />
                </BoldNumTd>
              </tr>
            ) : null}
            {storeData.custom?.reportDefinition?.columns.map(
              ({ key, ...column }) => (
                <tr key={key}>
                  <td>{column.header}</td>
                  <CustomizedTd
                    {...column}
                    key={key}
                    value={storeData.custom.reportResult?.tableData?.[0]?.[key]}
                  />
                </tr>
              ),
            )}
          </tbody>
        </FullScreenTable>
        <FullScreenTable>
          <tbody>
            {!config.isCogsPercentVisible ? null : (
              <tr>
                <td>
                  {financialLabel.cogs} %
                  {config.tooltips?.cogsKpi &&
                  selectedDateRange.type === TYPE_PERIOD ? (
                    <Tooltip
                      title={config.tooltips.cogsKpi}
                      placement="bottom"
                    />
                  ) : null}
                </td>
                <BoldNumTd>
                  {config.usePeriodCogs &&
                  storeData.periodCogsPercentage !== '-'
                    ? storeData.periodCogsPercentage
                    : storeData.cogsPercentage}
                </BoldNumTd>
              </tr>
            )}
            {!config.rcp ? null : (
              <tr>
                <td>{financialLabel.rcp} %</td>
                <BoldNumTd>{storeData.rcpPercentage}</BoldNumTd>
              </tr>
            )}
          </tbody>
        </FullScreenTable>

        <VerticalSpacer40px />

        <WeeklyTableScrollableContainer>
          <WeeklyTableContainer>
            <WeeklyTable>
              <thead>
                <tr>
                  <th></th>
                  {!config.isNetSalesVisible ||
                  !config.isWeeklyNetSalesVisible ? null : (
                    <th>Net Sales</th>
                  )}
                  {!config.isGrossSalesVisible ? null : <th>Gross Sales</th>}
                  {!config.isCogsVisible ? null : (
                    <th>{financialLabel.cogs}</th>
                  )}
                  {!config.isCogsPercentVisible ? null : (
                    <th>{financialLabel.cogs} %</th>
                  )}
                  {!config.avtPercent ? null : <th>{financialLabel.avt}</th>}
                  {!isAvtMonthlyVisible ? null : <th>{financialLabel.avt}</th>}
                  {!config.isPlvVisible ? null : <th>{financialLabel.plv}</th>}
                  {!config.isHourlyLaborVisible ? null : (
                    <th>{financialLabel.hourlyLabor}</th>
                  )}
                  {!config.isHourlyLaborVisible ? null : (
                    <th>{financialLabel.hourlyLabor} %</th>
                  )}
                  {!config.rcp || /^lfr/.test(brand) ? null : (
                    <>
                      <th>{financialLabel.rcp}</th>
                      <th>{financialLabel.rcp} %</th>
                    </>
                  )}
                  {customizedTrendLocationFinancialKpis.columns.map(
                    ({ key, header }) => (
                      <th key={key}>{header}</th>
                    ),
                  )}
                  {!config.rcp || !/^lfr/.test(brand) ? null : (
                    <>
                      <th>{financialLabel.rcp}</th>
                      <th>{financialLabel.rcp} %</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {storeData.groupedData.map((data) => {
                  const weekStartDate =
                    data.groupByStartDate.format(DATE_DISPLAY_FORMAT)
                  return (
                    <tr key={weekStartDate}>
                      <WeeklyTableLeftMostTd>
                        <WeekIndexDiv>
                          {breakDownName} {data[breakDownFieldName]}
                        </WeekIndexDiv>
                        <WeekDateRangeDiv>
                          {weekStartDate} -{' '}
                          {data.groupByEndDate.format(DATE_DISPLAY_FORMAT)}
                        </WeekDateRangeDiv>
                      </WeeklyTableLeftMostTd>
                      {!config.isNetSalesVisible ||
                      !config.isWeeklyNetSalesVisible ? null : (
                        <td>{data.netSales}</td>
                      )}
                      {!config.isGrossSalesVisible ? null : (
                        <td>{data.grossSales}</td>
                      )}
                      {!config.isCogsVisible ? null : <td>{data.cogs}</td>}
                      {!config.isCogsPercentVisible ? null : (
                        <td>{data.cogsPercentage}</td>
                      )}
                      {!config.avtPercent ? null : (
                        <DangerTd
                          danger={isTextNegativeNumber(data.avtPercent)}
                        >
                          {data.avtPercent}
                        </DangerTd>
                      )}
                      {!isAvtMonthlyVisible ? null : [
                          TYPE_QUARTER,
                          TYPE_YEAR,
                        ].includes(selectedDateRange.type) ? (
                        <DangerTd
                          danger={isTextNegativeNumber(data.avtPercentMonthly)}
                        >
                          {data.avtPercentMonthly}
                        </DangerTd>
                      ) : (
                        <DangerTd
                          danger={isTextNegativeNumber(data.avtPercentWeekly)}
                        >
                          {data.avtPercentWeekly}
                        </DangerTd>
                      )}
                      {!config.isPlvVisible ? null : (
                        <DangerTd
                          danger={isTextNegativeNumber(data.plvPercent)}
                        >
                          {data.plvPercent}
                        </DangerTd>
                      )}
                      {!config.isHourlyLaborVisible ? null : (
                        <td>{data.hourlyLaborActual}</td>
                      )}
                      {!config.isHourlyLaborVisible ? null : (
                        <td>{data.hourlyLaborPercentage}</td>
                      )}
                      {!config.rcp || /^lfr/.test(brand) ? null : (
                        <>
                          <td>{data.rcp}</td>
                          <td>{data.rcpPercentage}</td>
                        </>
                      )}
                      {customizedTrendLocationFinancialKpis.columns.map(
                        ({ key, ...props }) => (
                          <CustomizedTd
                            {...props}
                            key={key}
                            value={data[key]}
                          />
                        ),
                      )}
                      {!config.rcp || !/^lfr/.test(brand) ? null : (
                        <>
                          <td>{data.rcp}</td>
                          <td>{data.rcpPercentage}</td>
                        </>
                      )}
                    </tr>
                  )
                })}
              </tbody>
              <tfoot>
                <tr>
                  <WeeklyTableLeftMostTd>
                    <BoldTextSpan>{breakDownSumName} Actual</BoldTextSpan>
                  </WeeklyTableLeftMostTd>
                  {!config.isNetSalesVisible ||
                  !config.isWeeklyNetSalesVisible ? null : (
                    <td>
                      <BoldTextSpan>{storeData.netSales}</BoldTextSpan>
                    </td>
                  )}
                  {!config.isGrossSalesVisible ? null : (
                    <td>
                      <BoldTextSpan>{storeData.grossSales}</BoldTextSpan>
                    </td>
                  )}
                  {!config.isCogsVisible ? null : (
                    <TdWithTooltip>
                      <BoldTextSpan>
                        {config.usePeriodCogs
                          ? storeData.periodCogs
                          : storeData.cogs}
                        {config.tooltips?.cogsTableSummary &&
                        selectedDateRange.type === TYPE_PERIOD ? (
                          <Tooltip
                            title={config.tooltips.cogsTableSummary}
                            placement="bottom"
                            customStyle={{
                              position: 'absolute',
                              top: '11px',
                            }}
                          />
                        ) : null}
                      </BoldTextSpan>
                    </TdWithTooltip>
                  )}
                  {!config.isCogsPercentVisible ? null : (
                    <td>
                      <BoldTextSpan>
                        {config.usePeriodCogs
                          ? storeData.periodCogsPercentage
                          : storeData.cogsPercentage}
                      </BoldTextSpan>
                    </td>
                  )}
                  {!config.avtPercent ? null : (
                    <td>
                      <BoldTextSpan
                        danger={isTextNegativeNumber(storeData.avtPercent)}
                      >
                        {storeData.avtPercent}
                      </BoldTextSpan>
                    </td>
                  )}
                  {!isAvtMonthlyVisible ? null : (
                    <td>
                      <BoldTextSpan
                        danger={isTextNegativeNumber(
                          storeData.avtPercentMonthly,
                        )}
                      >
                        {storeData.avtPercentMonthly}
                      </BoldTextSpan>
                    </td>
                  )}
                  {!config.isPlvVisible ? null : (
                    <td>
                      <BoldTextSpan
                        danger={isTextNegativeNumber(storeData.plvPercent)}
                      >
                        {storeData.plvPercent}
                      </BoldTextSpan>
                    </td>
                  )}
                  {!config.isHourlyLaborVisible ? null : (
                    <td>
                      <BoldTextSpan>{storeData.hourlyLaborActual}</BoldTextSpan>
                    </td>
                  )}
                  {!config.isHourlyLaborVisible ? null : (
                    <td>
                      <BoldTextSpan>{storeData.laborPercentage}</BoldTextSpan>
                    </td>
                  )}
                  {!config.rcp || /^lfr/.test(brand) ? null : (
                    <>
                      <td>
                        <BoldTextSpan>{storeData.rcp}</BoldTextSpan>
                      </td>
                      <td>
                        <BoldTextSpan>{storeData.rcpPercentage}</BoldTextSpan>
                      </td>
                    </>
                  )}
                  {customizedTrendLocationFinancialKpis.columns.map(
                    ({ key, ...props }) => (
                      <CustomizedTd
                        {...props}
                        key={key}
                        value={
                          customizedTrendLocationFinancialKpis.total[key]
                            ?.actual
                        }
                      />
                    ),
                  )}
                  {!config.rcp || !/^lfr/.test(brand) ? null : (
                    <>
                      <td>
                        <BoldTextSpan>{storeData.rcp}</BoldTextSpan>
                      </td>
                      <td>
                        <BoldTextSpan>{storeData.rcpPercentage}</BoldTextSpan>
                      </td>
                    </>
                  )}
                </tr>
                {!config.isPTDBudgetVisible ? null : (
                  <tr>
                    <WeeklyTableLeftMostTd>
                      <BoldTextSpan>
                        {breakDownSumName} {getFinancialLabel('projected')}
                      </BoldTextSpan>
                    </WeeklyTableLeftMostTd>
                    {!config.isNetSalesVisible ||
                    !config.isWeeklyNetSalesVisible ? null : (
                      <td>{storeData.netSalesBudgetToDate}</td>
                    )}
                    {!config.isGrossSalesVisible ? null : (
                      <td>{storeData.grossSalesBudgetToDate}</td>
                    )}
                    {!config.isCogsVisible ? null : (
                      <td>
                        {config.usePeriodCogsBudget
                          ? storeData.periodCogsBudget
                          : storeData.cogsBudgetToDate}
                      </td>
                    )}
                    {!config.isCogsPercentVisible ? null : (
                      <td>
                        {config.usePeriodCogsBudget
                          ? storeData.periodCogsBudgetPercentage
                          : storeData.cogsBudgetPercentage}
                      </td>
                    )}
                    {!config.avtPercent ? null : (
                      <td>{storeData.avtBudgetPercentage}</td>
                    )}
                    {!isAvtMonthlyVisible ? null : (
                      <td>{storeData.avtMonthlyBudgetPercentage}</td>
                    )}
                    {!config.isPlvVisible ? null : (
                      <td>{storeData.plvBudgetPercentage}</td>
                    )}
                    {!config.isHourlyLaborVisible ? null : (
                      <td>{storeData.hourlyLaborBudget}</td>
                    )}
                    {!config.isHourlyLaborVisible ? null : (
                      <td>{storeData.hourlyLaborBudgetPercentage}</td>
                    )}
                    {!config.rcp || /^lfr/.test(brand) ? null : (
                      <>
                        <td>{storeData.rcpBudgetToDate}</td>
                        <td>{storeData.rcpBudgetPercentage}</td>
                      </>
                    )}
                    {customizedTrendLocationFinancialKpis.columns.map(
                      ({ key, ...props }) => (
                        <CustomizedTd
                          {...props}
                          key={key}
                          value={
                            customizedTrendLocationFinancialKpis.total?.[key]
                              ?.target
                          }
                        />
                      ),
                    )}
                    {!config.rcp || !/^lfr/.test(brand) ? null : (
                      <>
                        <td>{storeData.rcpBudgetToDate}</td>
                        <td>{storeData.rcpBudgetPercentage}</td>
                      </>
                    )}
                  </tr>
                )}
                {!config.isDifferenceVisible ? null : (
                  <tr>
                    <WeeklyTableLeftMostTd>
                      <BoldTextSpan>
                        {config.budgetDifferenceLabel || 'Difference'}
                      </BoldTextSpan>
                    </WeeklyTableLeftMostTd>
                    {!config.isNetSalesVisible ||
                    !config.isWeeklyNetSalesVisible ? null : (
                      <td>
                        <HighlightSpan
                          code={
                            storeData.ptdActualBudgetDifference
                              .netSalesHighlightCode
                          }
                        >
                          {storeData.ptdActualBudgetDifference.netSales}
                        </HighlightSpan>
                      </td>
                    )}
                    {!config.isGrossSalesVisible ? null : (
                      <td>
                        <HighlightSpan
                          code={
                            storeData.ptdActualBudgetDifference
                              .grossSalesHighlightCode
                          }
                        >
                          {storeData.ptdActualBudgetDifference.grossSales}
                        </HighlightSpan>
                      </td>
                    )}
                    {!config.isCogsVisible ? null : (
                      <td>
                        {config.isAmountDifferenceVisible ? (
                          <HighlightSpan
                            code={
                              storeData.ptdActualBudgetDifference
                                .cogsHighlightCode
                            }
                          >
                            {storeData.ptdActualBudgetDifference.cogs}
                          </HighlightSpan>
                        ) : null}
                      </td>
                    )}
                    {!config.isCogsPercentVisible ? null : (
                      <td>
                        <HighlightSpan
                          code={
                            storeData.ptdActualBudgetDifference
                              .cogsPercentHighlightCode
                          }
                        >
                          {storeData.ptdActualBudgetDifference.cogsPercent}
                        </HighlightSpan>
                      </td>
                    )}
                    {config.avtPercent ? (
                      <td>
                        <HighlightSpan
                          code={
                            storeData.ptdActualBudgetDifference
                              .avtPercentHighlightCode
                          }
                        >
                          {storeData.ptdActualBudgetDifference.avtPercent}
                        </HighlightSpan>
                      </td>
                    ) : null}
                    {isAvtMonthlyVisible ? (
                      <td>
                        <HighlightSpan
                          code={
                            storeData.ptdActualBudgetDifference
                              .avtPercentMonthlyHighlightCode
                          }
                        >
                          {
                            storeData.ptdActualBudgetDifference
                              .avtPercentMonthly
                          }
                        </HighlightSpan>
                      </td>
                    ) : null}
                    {config.isPlvVisible ? (
                      <td>
                        <HighlightSpan
                          code={
                            storeData.ptdActualBudgetDifference
                              .plvPercentHighlightCode
                          }
                        >
                          {storeData.ptdActualBudgetDifference.plvPercent}
                        </HighlightSpan>
                      </td>
                    ) : null}
                    {!config.isHourlyLaborVisible ? null : (
                      <td>
                        {config.isAmountDifferenceVisible ? (
                          <HighlightSpan
                            code={
                              storeData.ptdActualBudgetDifference
                                .hourlyLaborHighlightCode
                            }
                          >
                            {storeData.ptdActualBudgetDifference.hourlyLabor}
                          </HighlightSpan>
                        ) : null}
                      </td>
                    )}
                    {!config.isHourlyLaborVisible ? null : (
                      <td>
                        <HighlightSpan
                          code={
                            storeData.ptdActualBudgetDifference
                              .hourlyLaborPercentHighlightCode
                          }
                        >
                          {
                            storeData.ptdActualBudgetDifference
                              .hourlyLaborPercent
                          }
                        </HighlightSpan>
                      </td>
                    )}
                    {!config.rcp || /^lfr/.test(brand) ? null : (
                      <>
                        <td>
                          <HighlightSpan
                            code={
                              storeData.ptdActualBudgetDifference
                                .rcpHighlightCode
                            }
                          >
                            {storeData.ptdActualBudgetDifference.rcp}
                          </HighlightSpan>
                        </td>
                        <td>
                          <HighlightSpan
                            code={
                              storeData.ptdActualBudgetDifference
                                .rcpPercentHighlightCode
                            }
                          >
                            {storeData.ptdActualBudgetDifference.rcpPercent}
                          </HighlightSpan>
                        </td>
                      </>
                    )}
                    {customizedTrendLocationFinancialKpis.columns.map(
                      ({ key, reverse, ...props }) => {
                        const data =
                          customizedTrendLocationFinancialKpis.total?.[key]
                        const code = (() => {
                          if (!data) return undefined

                          if (data?.target && data?.actual)
                            if (data.actual > data.target)
                              return reverse ? 'success' : 'danger'
                          if (data.actual < data.target)
                            return reverse ? 'danger' : 'success'

                          return undefined
                        })()

                        return (
                          <CustomizedTd
                            {...props}
                            key={key}
                            value={
                              _.isNil(data?.target) || _.isNil(data?.actual)
                                ? null
                                : data.actual - data.target
                            }
                            code={code}
                          />
                        )
                      },
                    )}
                    {!config.rcp || !/^lfr/.test(brand) ? null : (
                      <>
                        <td>
                          <HighlightSpan
                            code={
                              storeData.ptdActualBudgetDifference
                                .rcpHighlightCode
                            }
                          >
                            {storeData.ptdActualBudgetDifference.rcp}
                          </HighlightSpan>
                        </td>
                        <td>
                          <HighlightSpan
                            code={
                              storeData.ptdActualBudgetDifference
                                .rcpPercentHighlightCode
                            }
                          >
                            {storeData.ptdActualBudgetDifference.rcpPercent}
                          </HighlightSpan>
                        </td>
                      </>
                    )}
                  </tr>
                )}
                {!config.isPTDProjectionVisible ? null : (
                  <tr>
                    <WeeklyTableLeftMostTd>
                      <BoldTextSpan>
                        {breakDownSumName} {getFinancialLabel('projected')}
                      </BoldTextSpan>
                    </WeeklyTableLeftMostTd>
                    {!config.isNetSalesVisible ||
                    !config.isWeeklyNetSalesVisible ? null : (
                      <td>{storeData.netSalesProjectionToDate}</td>
                    )}
                  </tr>
                )}
                {!config.isProjectionDifferenceVisible ? null : (
                  <tr>
                    <WeeklyTableLeftMostTd>
                      <BoldTextSpan>
                        {config.projectionDifferenceLabel || 'Difference'}
                      </BoldTextSpan>
                    </WeeklyTableLeftMostTd>
                    {!config.isNetSalesVisible ||
                    !config.isWeeklyNetSalesVisible ? null : (
                      <td>
                        <HighlightSpan
                          code={
                            storeData.ptdActualProjectionDifference
                              .netSalesHighlightCode
                          }
                        >
                          {storeData.ptdActualProjectionDifference.netSales}
                        </HighlightSpan>
                      </td>
                    )}
                  </tr>
                )}
              </tfoot>
            </WeeklyTable>
          </WeeklyTableContainer>
        </WeeklyTableScrollableContainer>

        {![
          TYPE_LAST_WEEK,
          TYPE_THIS_WEEK,
          TYPE_TRAILING_7_DAYS,
          TYPE_YESTERDAY,
        ].includes(selectedDateRange.type) || config.hideWeeklytable ? null : (
          <>
            <VerticalSpacer40px />
            <WeeklyTableScrollableContainer>
              <WeeklyTableContainer>
                <WeeklyTable>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Net Sales</th>
                      <th>Net Sales Budget</th>
                      <th>Net Sales {financialLabel.variance}</th>
                      {!config.isHourlyLaborVisible ? null : (
                        <th>{financialLabel.hourlyLabor}</th>
                      )}
                      {!config.isHourlyLaborVisible ? null : (
                        <th>{financialLabel.hourlyLabor} %</th>
                      )}
                      {!config.isHourlyLaborVisible ? null : (
                        <th>
                          {financialLabel.hourlyLabor} {financialLabel.variance}
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {storeData.groupedDataDaily.map((data) => {
                      const weekStartDate =
                        data.groupByStartDate.format(DATE_DISPLAY_FORMAT)
                      return (
                        <tr key={weekStartDate}>
                          <WeeklyTableLeftMostTd>
                            <WeekIndexDiv>{getDay(weekStartDate)}</WeekIndexDiv>
                            <WeekDateRangeDiv>{weekStartDate}</WeekDateRangeDiv>
                          </WeeklyTableLeftMostTd>
                          <td>{data.netSales}</td>
                          <td>{data.netSalesBudget}</td>
                          <td>
                            <HighlightSpan
                              code={
                                data.netSalesBudgetVarianceAmountHighlightCode ||
                                'success'
                              }
                            >
                              {data.netSalesBudgetVarianceAmount}
                            </HighlightSpan>
                          </td>
                          {!config.isHourlyLaborVisible ? null : (
                            <td>{data.hourlyLaborActual}</td>
                          )}
                          {!config.isHourlyLaborVisible ? null : (
                            <td>{data.hourlyLaborPercentage}</td>
                          )}
                          {!config.isHourlyLaborVisible ? null : (
                            <td>
                              <HighlightSpan
                                code={
                                  data.hourlyLaborBudgetVarianceAmountHighlightCode ||
                                  'success'
                                }
                              >
                                {data.hourlyLaborBudgetVarianceAmount}
                              </HighlightSpan>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                </WeeklyTable>
              </WeeklyTableContainer>
            </WeeklyTableScrollableContainer>
          </>
        )}

        {!config.isPlvVisible ? null : (
          <>
            <VerticalSpacer40px />
            <PlvPercentTrendChart
              navParams={navParams}
              selectedDateRange={selectedDateRange}
            />
          </>
        )}

        {!config.isPerformanceTrendChartVisible ? null : (
          <>
            <VerticalSpacer40px />
            <PerformanceTrendChart
              navParams={navParams}
              selectedDateRange={selectedDateRange}
            />
          </>
        )}

        <VerticalSpacer40px />

        <FullScreenTable>
          <thead>
            <tr>
              <WideColumn>REVENUE CENTER</WideColumn>
              <th>
                <SecondaryColumnHeaderSpan>
                  {config.revenueCenterHeader}
                </SecondaryColumnHeaderSpan>
              </th>
              <th>
                <SecondaryColumnHeaderSpan>
                  % {config.revenueCenterHeader}
                </SecondaryColumnHeaderSpan>
              </th>
              {config.isRevCenterGuestAverageVisible ? (
                <th>
                  <SecondaryColumnHeaderSpan>PPA</SecondaryColumnHeaderSpan>
                </th>
              ) : null}
              {config.isRevCenterCheckCountVisible ? (
                <th>
                  <SecondaryColumnHeaderSpan>
                    {financialLabel.transaction}
                  </SecondaryColumnHeaderSpan>
                </th>
              ) : null}
              {config.isRevCenterCheckAverageVisible ? (
                <th>
                  <SecondaryColumnHeaderSpan>
                    {financialLabel.transactionAvg}
                  </SecondaryColumnHeaderSpan>
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>{revCenterBreakdownRows}</tbody>
        </FullScreenTable>

        <VerticalSpacer40px />

        <ScrollableTableContainer>
          <ScrollableTable>
            <SalesTrendStackedBarChart
              navParams={navParams}
              selectedDateRange={selectedDateRange}
              trendSalesTrendStackedBarItems={trendSalesTrendStackedBarItems}
            />
          </ScrollableTable>
        </ScrollableTableContainer>

        <VerticalSpacer40px />

        <MobileContainer>
          <FullScreenTable>
            <thead>
              <tr>
                <WideColumn>DAYPART</WideColumn>
                <th>
                  <SecondaryColumnHeaderSpan>
                    {config.daypartHeader}
                  </SecondaryColumnHeaderSpan>
                </th>
                <th>
                  <SecondaryColumnHeaderSpan>
                    % {config.daypartHeader}
                  </SecondaryColumnHeaderSpan>
                </th>
                {config.isDaypartCheckCountVisible ? (
                  <th>
                    <SecondaryColumnHeaderSpan>
                      {financialLabel.transaction}
                    </SecondaryColumnHeaderSpan>
                  </th>
                ) : null}
                {config.isDaypartCheckAverageVisible ? (
                  <th>
                    <SecondaryColumnHeaderSpan>
                      {financialLabel.transactionAvg}
                    </SecondaryColumnHeaderSpan>
                  </th>
                ) : null}
                {config.isDaypartHourlyLaborPercentVisible ? (
                  <th>
                    <SecondaryColumnHeaderSpan>
                      Labor %
                    </SecondaryColumnHeaderSpan>
                  </th>
                ) : null}
              </tr>
            </thead>
            <tbody>{daypartBreakdownRows}</tbody>
          </FullScreenTable>
          <DayPartPieChartContainer>
            <Spin spinning={locationRevCenterBreakdownLoading}>
              <DayPartPieChart data={daypartBreakdown} width={300} />
            </Spin>
          </DayPartPieChartContainer>
        </MobileContainer>

        <VerticalSpacer40px />

        <VerticalSpacer40px />

        {!config.isCompsVisible ? null : (
          <FullScreenTable>
            <thead>
              <tr>
                <th>{getFinancialLabel('comps').toUpperCase()}</th>
                {config.isCompsCountVisible ? (
                  <th>
                    <SecondaryColumnHeaderSpan>Qty</SecondaryColumnHeaderSpan>
                  </th>
                ) : null}
                <th>
                  <SecondaryColumnHeaderSpan>Amount</SecondaryColumnHeaderSpan>
                </th>
                {config.isCompsPercentVisible ? (
                  <th>
                    <SecondaryColumnHeaderSpan>
                      % Gross Sales
                    </SecondaryColumnHeaderSpan>
                  </th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {_.map(storeData.compsBreakdown, (compsDetails) => {
                return (
                  <tr>
                    <td>{compsDetails.compsName}</td>
                    {config.isCompsCountVisible ? (
                      <td>{compsDetails.compsCount}</td>
                    ) : null}
                    <td>{compsDetails.compsAmount}</td>
                    {config.isCompsPercentVisible ? (
                      <td>{compsDetails.compsPercentage}</td>
                    ) : null}
                  </tr>
                )
              })}
            </tbody>
          </FullScreenTable>
        )}

        {config.isLaborOverviewVisible === false ? null : (
          <>
            <VerticalSpacer40px />

            <FullScreenTable>
              <thead>
                <tr>
                  <th>LABOR OVERVIEW</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {!config.isHourlyLaborVisible ? null : (
                    <td>{financialLabel.hourlyLabor} %</td>
                  )}
                  {!config.isHourlyLaborVisible ? null : (
                    <td>{storeData.laborPercentage}</td>
                  )}
                </tr>
                {brandSettings.brand === EDDIE_MERLOTS_BRAND_CODE ? null : (
                  <tr>
                    <td>Overtime</td>
                    <td>{storeData.overtime}</td>
                  </tr>
                )}
              </tbody>
            </FullScreenTable>

            <VerticalSpacer40px />
          </>
        )}
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  width: 100%;
  color: ${COLORS.Chalkboard};
  padding: 30px 25px 50px 25px;
`

const SelectorContainer = styled.div`
  padding: 10px 25px 10px 25px;
  background-color: rgb(196, 196, 196);
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const PageTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 0;
`

const StoreNameDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 15px 0;
`

const NormalizationStatusDiv = styled.div`
  padding: 15px 0 0 1px;
`

const FullScreenTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};
  text-align: right;

  th {
    font-family: Lexend-SemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    min-width: 75px;
  }

  td,
  th {
    vertical-align: middle;
    padding: 8px 0;
  }

  th:first-child {
    text-align: left;
  }

  td:first-child {
    text-align: left;
  }

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  thead::after {
    content: '';
    display: block;
    height: 10px;
    width: 100%;
    background: transparent;
  }
`

const BoldNumTd = styled.td<{ danger?: boolean }>`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: ${({ danger }) => (danger ? COLORS.Pomodoro : COLORS.Chalkboard)};
`

const SecondaryColumnHeaderSpan = styled.span`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

const VerticalSpacer40px = styled.div`
  width: 100%;
  height: 40px;
`

const WeeklyTableScrollableContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  border: 1px solid;
`

const WeeklyTableContainer = styled.div`
  width: 900px;
  padding: 10px 10px 25px 10px;
`

const WeeklyTable = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    padding: 10px 0;
    min-width: 100px;
    font-family: Lexend-SemiBold;
  }

  tbody td {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 10px 0;
  }

  tfoot td {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 15px 0 0 0;
  }
`

const WeeklyTableLeftMostTd = styled.td`
  text-align: left;
`

const WeekIndexDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

const WeekDateRangeDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
`

const DayPartPieChartContainer = styled.div`
  text-align: center;
  padding-top: 5px;
`

const BoldTextSpan = styled.span<{ danger?: boolean }>`
  font-family: Lexend-SemiBold;
  font-weight: 700;
  color: ${({ danger }) => (danger ? COLORS.Pomodoro : COLORS.Chalkboard)};
`

const TdWithTooltip = styled.td`
  position: relative;
`

const ScrollableTableContainer = styled.div`
  overflow-x: scroll;
  border: 1px solid;
  padding-left: 30px;
  width: 90vw;
`

const ScrollableTable = styled.div`
  width: 850px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
`

const DangerTd = styled.td<{ danger: boolean }>`
  color: ${({ danger }) => (danger ? COLORS.Pomodoro : COLORS.Chalkboard)};
`

const WideColumn = styled.th`
  width: 150px;
`

const MobileContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

export default WebSmallMain
