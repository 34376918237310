import { useState } from 'react'

import {
  ISortDirectionType,
  ISorterConfigType,
  ISorterType,
  SortDirectionType,
} from '../type'
import { IDataSourceType } from './useDataSource'

export const sorterConfig: ISorterConfigType[] = [
  {
    key: 'salesAmount',
    title: 'R365',
    func: (data: IDataSourceType[], direction: SortDirectionType) =>
      direction == 'dec'
        ? data?.sort((a, b) => b['salesAmount'] - a['salesAmount'])
        : data?.sort((a, b) => a['salesAmount'] - b['salesAmount']),
  },
  {
    key: 'paymentAmount',
    title: 'Fiserv',
    func: (data: IDataSourceType[], direction: SortDirectionType) =>
      direction == 'dec'
        ? data?.sort((a, b) => b['paymentAmount'] - a['paymentAmount'])
        : data?.sort((a, b) => a['paymentAmount'] - b['paymentAmount']),
  },
  {
    key: 'overUnderAmount',
    title: 'Over/Under $',
    func: (data: IDataSourceType[], direction: SortDirectionType) =>
      direction == 'dec'
        ? data?.sort((a, b) => b['overUnderAmount'] - a['overUnderAmount'])
        : data?.sort((a, b) => a['overUnderAmount'] - b['overUnderAmount']),
  },
  {
    key: 'overUnderPercent',
    title: 'Over/Under %',
    func: (data: IDataSourceType[], direction: SortDirectionType) =>
      direction == 'dec'
        ? data?.sort((a, b) => b['overUnderPercent'] - a['overUnderPercent'])
        : data?.sort((a, b) => a['overUnderPercent'] - b['overUnderPercent']),
  },
]

const useColumnSorter = () => {
  const sorterOptions: ISorterType[] = sorterConfig.map(({ key, title }) => ({
    key,
    title,
  }))

  const defaultColumnKey = 'overUnderAmount'
  const [selectedColumn, setSelectedColumn] = useState(defaultColumnKey)

  const directionOptions: ISortDirectionType[] = [
    {
      key: 'dec',
      title: 'Descend',
    },
    {
      key: 'asc',
      title: 'Ascend',
    },
  ]
  const [selectedDirection, setSelectedDirection] = useState(
    directionOptions[0].key,
  )

  return {
    sorterOptions,
    selectedColumn,
    setSelectedColumn,
    directionOptions,
    selectedDirection,
    setSelectedDirection,
  }
}

export default useColumnSorter
