import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import MenuItem from '@material-ui/core/MenuItem'
import Modal from '@material-ui/core/Modal'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@mui/material/Button'
import { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components'

import { InputWithLeftLabel } from 'pared/components/basicUi/InputWithLeftLabel'
import { BRAND_ID } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { getBusinessLabel } from 'pared/customer'

import {
  MUTATION_UPDATE_USER_LOCATION,
  QUERY_GET_ACCESSIBLE_BRANDS,
  QUERY_GET_LOCATIONS_FOR_BRAND,
} from './gql'

const useStyles = makeStyles({
  text: {
    fontFamily: 'Lexend-Regular',
  },
})

interface IBrand {
  id: number
  name: string
}

interface ILocation {
  id: number
  name: string
  code: string
  brandId?: string
}

const ChangeLocationButton = ({
  email,
  locationName,
  refetch,
}: {
  email: string
  locationName: string
  refetch: any
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [brandId, setBrandId] = useState('')
  const [locations, setLocations] = useState<Array<ILocation>>([])
  const [locationId, setLocationId] = useState('')
  const [hasFormSubmitted, setHasFormSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { data: fetchedBrands } = useQuery(QUERY_GET_ACCESSIBLE_BRANDS)
  const [getLocationsForBrand] = useLazyQuery(QUERY_GET_LOCATIONS_FOR_BRAND, {
    onCompleted: (data) => {
      setLocations(data?.getLocationsForBrand?.nodes || [])
    },
  })

  const [updateUserLocation] = useMutation(MUTATION_UPDATE_USER_LOCATION)

  useEffect(() => {
    if (brandId) {
      getLocationsForBrand({
        variables: {
          brandId,
        },
      })
    }
  }, [brandId])

  const storeText = getBusinessLabel('store')
  const accessibleBrands: IBrand[] =
    fetchedBrands?.getAccessibleBrandsForUser?.nodes || []

  const brands = accessibleBrands.filter(({ id }) =>
    Object.values(BRAND_ID).includes(id),
  )
  const handleBrandChange = (e: ChangeEvent<{}>) => {
    const { value } = e.target as HTMLSelectElement
    setBrandId(value)
    setLocationId('')
  }

  const handleLocationChange = (e: ChangeEvent<{}>) => {
    const { value } = e.target as HTMLSelectElement
    setLocationId(value)
  }

  const onSubmit = async () => {
    if (!locationId) {
      setErrorMessage('Location required')
      return
    }

    try {
      setHasFormSubmitted(true)
      await updateUserLocation({
        variables: {
          email,
          locationId: parseInt(locationId),
        },
      })

      setErrorMessage('')
      setOpen(false)
      refetch()
    } catch (error) {
      setErrorMessage('Error: Please try again or email support@getexpo.com')
    } finally {
      setHasFormSubmitted(false)
    }
  }

  return (
    <>
      <div>{locationName}</div>
      <Button onClick={() => setOpen(true)}>Edit</Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Container>
          <Header>{`Edit ${storeText} for ${email}`}</Header>
          <InputWithLeftLabel label="Assign Brand">
            <Select
              fullWidth
              variant="outlined"
              value={brandId}
              displayEmpty
              onChange={handleBrandChange}
              className={`${classes.text}`}
            >
              <MenuItem value="" disabled className={`${classes.text}`}>
                Select Brand
              </MenuItem>
              {brands.map((brand: IBrand) => (
                <MenuItem
                  key={brand.id}
                  value={brand.id}
                  className={`${classes.text}`}
                >
                  {brand.name}
                </MenuItem>
              ))}
            </Select>
          </InputWithLeftLabel>
          <VerticalSpacer />
          <InputWithLeftLabel label={`Assign ${storeText}`}>
            <Select
              fullWidth
              variant="outlined"
              value={locationId}
              displayEmpty
              onChange={handleLocationChange}
              className={`${classes.text}`}
            >
              <MenuItem value="" disabled className={`${classes.text}`}>
                {`Select ${storeText}`}`
              </MenuItem>
              {locations.map((location) => (
                <MenuItem
                  key={location.id}
                  value={location.id}
                  className={`${classes.text}`}
                >
                  {location.name}
                </MenuItem>
              ))}
            </Select>
          </InputWithLeftLabel>
          <Footer>
            {hasFormSubmitted ? (
              <div>Submitting ...</div>
            ) : (
              <ButtonContainer>
                <CancelButton onClick={() => setOpen(false)}>
                  Cancel
                </CancelButton>
                <SubmitButton onClick={onSubmit}>Save</SubmitButton>
              </ButtonContainer>
            )}
            <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
          </Footer>
        </Container>
      </Modal>
    </>
  )
}

export default ChangeLocationButton

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px 20px;
  max-height: 80vh;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: none;
  overflow: hidden scroll;
`

const Header = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 16px;
  margin-bottom: 20px;
`

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
`

const SubmitButton = styled.div`
  background-color: ${COLORS.Expo};
  flex: 1;
  color: white;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  font-family: Lexend-Semibold;
  cursor: pointer;
  &:hover {
    opacity: 50%;
  }
`

const CancelButton = styled(SubmitButton)`
  background-color: #cfcece;
  color: black;
`

const VerticalSpacer = styled.div`
  height: 20px;
`

const Footer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`

const ErrorMessageContainer = styled.div`
  color: red;
`
