import { IConfigsType } from '../../types'

const fwWingstopOutliers: IConfigsType = {
  fw_wingstop: {
    '/:brand/outliers': {
      groupFilter: {
        api: 'fwWingstopCorporateFilter',
      },
      dateFilter: {
        types: ['week', 'this_week'],
        defaultType: 'this_week',
      },
      reportTitle: {
        type: 'title',
        title: 'Outliers',
      },
      downloadSalesReport: {
        type: 'download-csv',
        api: 'farwestOutliers',
        fileName: 'getexpo-far-west-outliers',
        fileNameFn: (date: string) => `getexpo-far-west-outliers-${date}`,
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'laborVarianceHours',
            title: 'Wkly Store Actual to Scheduled Labor Hours',
          },
          {
            key: 'varFoodCostPercentage',
            title: 'Cogs Variance to Theoretical',
          },
          {
            key: 'actualFoodCostPercentage',
            title: 'Cogs',
          },
          {
            key: 'otActualHours',
            title: 'Overtime',
          },
          {
            key: 'mealViolation',
            title: 'Meal Violation',
          },
          {
            key: 'cashOs',
            title: 'Cash Over Short',
          },
          {
            key: 'discountPercentage',
            title: 'Discount',
          },
          {
            key: 'voidPercentage',
            title: 'Void',
          },
          {
            key: 'netSalesPercentageDiffToLastYear',
            title: 'SSS%',
          },
          {
            key: 'netSalesPercentageDiffTo7DaysAgo',
            title: 'Week over Week Sales Change',
          },
          {
            key: 'checkAverage',
            title: 'Check Average',
          },
          {
            key: 'laborToMatrixVariance',
            title: 'Labor to Matrix Variance',
          },
        ],
      },
      table: {
        type: 'table',
        api: 'farwestOutliers',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        columns: [
          {
            key: 'laborVarianceHours',
            title: 'Wkly Store Actual to Scheduled Labor Hours',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'varFoodCostPercentage',
            title: 'Cogs Variance to Theoretical',
            align: 'center',
            sortable: true,
          },
          {
            key: 'actualFoodCostPercentage',
            title: 'Cogs',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'otActualHours',
            title: 'Overtime',
            align: 'center',
            sortable: true,
          },
          {
            key: 'mealViolation',
            title: 'Meal Violation',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'cashOs',
            title: 'Cash Over Short',
            align: 'center',
            sortable: true,
          },
          {
            key: 'discountPercentage',
            title: 'Discount',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'voidPercentage',
            title: 'Void',
            align: 'center',
            sortable: true,
          },
          {
            key: 'netSalesPercentageDiffToLastYear',
            title: 'SSS%',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'netSalesPercentageDiffTo7DaysAgo',
            title: 'Week over Week Sales Change',
            align: 'center',
            sortable: true,
          },
          {
            key: 'checkAverage',
            title: 'Check Average',
            highlight: true,
            align: 'center',
            sortable: true,
          },
          {
            key: 'laborToMatrixVariance',
            title: 'Labor to Matrix Variance',
            align: 'center',
            sortable: true,
          },
        ],
      },
    },
  },
}

export default fwWingstopOutliers
