import styled from 'styled-components'

import CustomizedReport from 'pared/components/CustomizedReport'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'

interface IProps {
  locationId: number
  startDate: string
  endDate: string
}

const EmployeeChart = ({ locationId, startDate, endDate }: IProps) => {
  const inputParams = {
    locationId,
    startDate,
    endDate,
  }

  return (
    <>
      <PageHeader>Breakdown by Manager on Duty</PageHeader>
      <VerticalSpacer />
      <CustomizedReport
        rowKey={({ employeeName }) => employeeName}
        reportName="GET_LOCATION_EMPLOYEE_CALLS_TABLE"
        inputParams={inputParams}
      />
    </>
  )
}

export default EmployeeChart

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  color: ${COLORS.Chalkboard};
  @media ${MOBILE_WIDTH} {
    font-size: 18px;
  }
`

const VerticalSpacer = styled.div`
  height: 20px;
`
