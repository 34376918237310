import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import useApi, { IApiKeyType } from './hooks/useApi'

export interface IPropsType<K extends IApiKeyType = IApiKeyType> {
  type: 'ai-summary-box'
  api: K
}

const MainContainer = styled.div`
  width: 100%;
  max-width: 1000px;

  @media ${MOBILE_WIDTH} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

const ConclusionDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: ${COLORS.Salt};
  padding: 30px;

  border-radius: 10px 10px 0 0;
  background: #0071e3;

  @media ${MOBILE_WIDTH} {
    flex: 1;
  }
`

const InsightDiv = styled.div`
  padding: 30px;
  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};

  border-radius: 0 0 10px 10px;
  border: 1px solid #81abfd;

  @media ${MOBILE_WIDTH} {
    flex: 1;
  }
`

const InsightTitle = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 0 0 15px 0;
`

const AiSummaryBox = ({ api }: IPropsType) => {
  const { data, loading } = useApi(api)

  return loading ? (
    <div>Loading ...</div>
  ) : (
    <MainContainer>
      {data?.conclusion ? (
        <ConclusionDiv>
          <div dangerouslySetInnerHTML={{ __html: data.conclusion || '' }} />
        </ConclusionDiv>
      ) : null}
      {data?.insight ? (
        <InsightDiv>
          <InsightTitle>EXPO INSIGHTS</InsightTitle>
          <div dangerouslySetInnerHTML={{ __html: data.insight || '' }} />
        </InsightDiv>
      ) : null}
    </MainContainer>
  )
}

export default AiSummaryBox
