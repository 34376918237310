import { gql } from '@apollo/client'

export interface IDeliverySummaryNodeType {
  locationId: number
  locationCorrectOrderPercent: number
  systemwideCorrectOrderPercent: number
  locationInaccurateOrderPercent: number
  systemwideInaccurateOrderPercent: number
  locationCancelledOrderPercent: number
  systemwideCancelledOrderPercent: number
  locationDelayedOrderPercent: number
  systemwideDelayedOrderPercent: number
}

export interface IDeliverySummaryType {
  getLocationVsSystemwideDeliveryKpi: {
    nodes: IDeliverySummaryNodeType[]
  }
}

export interface IDeliverySummaryVariablesType {
  iLocationId: number
  iStartDate?: string
  iEndDate?: string
}

export const getLocationDeliverySummary = gql`
  query GetLocationVsSystemwideDeliveryKpi(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    getLocationVsSystemwideDeliveryKpi(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        locationId
        locationCorrectOrderPercent
        systemwideCorrectOrderPercent
        locationInaccurateOrderPercent
        systemwideInaccurateOrderPercent
        locationCancelledOrderPercent
        systemwideCancelledOrderPercent
        locationDelayedOrderPercent
        systemwideDelayedOrderPercent
      }
    }
  }
`

export interface IDeliveryDayTrendNodeType {
  dayOfWeek: number
  dayPart: string
  totalOrderCount: number
  inaccurateOrderPercent: number
  cancelledOrderPercent: number
  delayedOrderPercent: number
  avgCustomerReviewScore: number
  reviewedOrderCount: number
  averageTripTime: number
}

export interface IDeliveryDayTrendType {
  listLocationDeliveryDayTrends: {
    nodes: IDeliveryDayTrendNodeType[]
  }
}

export interface IDeliveryDayTrendVariablesType {
  iLocationId: number
  iStartDate?: string
  iEndDate?: string
}

export const listLocationDeliveryDayTrends = gql`
  query ListLocationDeliveryDayTrends(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationDeliveryDayTrends(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        dayOfWeek
        dayPart
        totalOrderCount
        inaccurateOrderPercent
        cancelledOrderPercent
        delayedOrderPercent
        avgCustomerReviewScore
        reviewedOrderCount
        averageTripTime
      }
    }
  }
`

interface IEmployeeInfo {
  id: number
  firstName: string
  lastName: string
}

export interface IDeliveryStaffKpiNodeType {
  locationId: number
  employeeInfo: IEmployeeInfo
  employeeRoleName: string
  employeeRoleCode: string
  totalOrderCount: number
  inaccurateOrderPercent: number
  cancelledOrderPercent: number
  delayedOrderPercent: number
  avgCustomerReviewScore: number
  totalReviewCount: number
  averageTripTime: number
}

export interface IDeliveryStaffKpiType {
  listLocationDeliveryStaffKpi: {
    nodes: IDeliveryStaffKpiNodeType[]
  }
}

export interface IDeliveryStaffKpiVariablesType {
  iLocationId: number
  iStartDate?: string
  iEndDate?: string
}

export const listLocationDeliveryStaffKpi = gql`
  query ListLocationDeliveryStaffKpi(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationDeliveryStaffKpi(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        locationId
        employeeInfo {
          id
          firstName: preferredName
          lastName: familyName
        }
        employeeRoleName
        employeeRoleCode
        totalOrderCount
        inaccurateOrderPercent
        cancelledOrderPercent
        delayedOrderPercent
        avgCustomerReviewScore
        totalReviewCount
        averageTripTime
      }
    }
  }
`
