import React, { useContext, useEffect, useReducer } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'

interface IPropsType {
  children: React.ReactNode
}

type IStateType =
  | {
      type: 'init'
    }
  | {
      type: 'select'
      value: Record<string, string>
    }

type IActionType =
  | {
      type: 'select'
      key: string
      value: string
    }
  | {
      type: 'reset'
    }

type IAdvancedFilterContextType = [IStateType, (actoin: IActionType) => void]

export const AdvancedFilterContext =
  React.createContext<IAdvancedFilterContextType>([
    {
      type: 'init',
    },
    () => {
      throw new Error('not found provider')
    },
  ])

export const useAdvancedFilter = <
  T extends Exclude<IStateType['type'], 'init'>,
>(
  type: T,
) => {
  const [advancedState] = useContext(AdvancedFilterContext)

  if (advancedState.type !== type) return null

  return advancedState as Extract<IStateType, { type: T }>
}

const reducer = (state: IStateType, action: IActionType) => {
  switch (action.type) {
    case 'select':
      return {
        type: 'select' as const,
        value: {
          ...('value' in state ? state.value : {}),
          [action.key]: action.value,
        },
      }
    case 'reset':
      return { type: 'init' as const }
    default:
      return state
  }
}

const AdvancedFilterProvider = ({ children }: IPropsType) => {
  const filter = useReducer(reducer, { type: 'init' })
  const location = useLocation()
  const previous = usePrevious(location)

  useEffect(() => {
    const route = location.pathname
    const previousRoute = previous?.pathname
    if (!previousRoute || route != previousRoute) {
      filter[1]({ type: 'reset' })
    }
  }, [location, previous])

  return (
    <AdvancedFilterContext.Provider value={filter}>
      {children}
    </AdvancedFilterContext.Provider>
  )
}

export default AdvancedFilterProvider
