import { gql } from '@apollo/client';

export const GET_CUSTOMIZED_REPORT = gql`
  query GetCustomizedReport($iReportName: String!, $iInputParams: JSON!) {
    getCustomizedReport(
      iCustomizedReportName: $iReportName,
      iInputParams: $iInputParams
    ) {
      nodes {
        reportName
        reportDefinition
        reportResult
      }
    }
  }
`
