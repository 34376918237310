import { gql } from '@apollo/client'

export const LIST_LOCATION_FINANCIAL_KPIS = gql`
  query ListLocationFinancialKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        startDate
        endDate
        netSales
        netSalesBudgetVariance
        netSalesSssPercent
        grossSales
        grossSalesBudgetVariance
        grossSalesSssPercent
        checkCount
        checkAverage
        hourlyLaborCostPercent
        hourlyLaborBudgetVariance
        cogs
        cogsPercent
        cogsBudgetVariance
        periodCogs
        periodCogsPercent
        periodCogsBudgetVariance
        avtPercent
        avtPercentMonthly
        avtPercentWeekly
        plvPercent
        rcp
        rcpPercent
        rcpBudgetVariance
        totalCheckCount
        yoyTotalCheckCount
        totalCheckAverage
        sosAvgSeconds
      }
    }
  }
`

// FIXME: Including locationGroupName will cause an error if backend queries are not deployed
export const LIST_LOCATION_GROUP_FINANCIAL_KPIS = (brand: string) => gql`
  query ListLocationGroupFinancialKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        locationGroupName
        startDate
        endDate
        netSales
        netSalesBudgetVariance
        netSalesSssPercent
        grossSales
        grossSalesBudgetVariance
        grossSalesSssPercent
        checkCount
        checkAverage
        totalCheckCount
        totalCheckAverage
        yoyTotalCheckCount
        ${brand === 'cava' ? 'totalGuestCount totalGuestAverage' : ''}
        hourlyLaborCostPercent
        hourlyLaborBudgetVariance
        cogs
        cogsPercent
        cogsBudgetVariance
        periodCogs
        periodCogsPercent
        periodCogsBudgetVariance
        avtPercent
        avtPercentMonthly
        avtPercentWeekly
        plvPercent
        rcp
        rcpPercent
        rcpBudgetVariance
        salesPerLaborHour
        sosAvgSeconds
      }
    }
  }
`

export const GET_STORE_LIST = gql`
  query GetStoreListByBrand($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        geo
        brandId
        directorId
        directorEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        gmEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
      }
    }
  }
`

export const STORES_CUSTOMIZED = gql`
  query GetCustomizedReport(
    $iCustomizedReportName: String!
    $iInputParams: JSON!
  ) {
    getCustomizedReport(
      iCustomizedReportName: $iCustomizedReportName
      iInputParams: $iInputParams
    ) {
      nodes {
        reportName
        reportDefinition
        reportResult
      }
    }
  }
`
