import { gql } from '@apollo/client'

export const GET_LOCATION_DATA = gql`
  query GetLocationInfo($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        brandId
        directorId
        directorEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        gmEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
      }
    }
  }
`

export const LIST_LOCATION_SALESMANS_BY_DAYS_WORKED = gql`
  query ListLocationSalesmansByDaysWorked(
    $locationId: Int!
    $numDaysWorked: Int!
    $dayPart: String!
  ) {
    listLocationSalesmansByDaysWorked(
      iLocationId: $locationId
      iNumDaysWorked: $numDaysWorked
      iDayPart: $dayPart
    ) {
      nodes {
        locationId
        employeeId
        firstName
        lastName
        roleName
        dayPart
        daysWorked
        shiftHours
        grossSales
        grossSalesRank
        salesPerHour
        salesPerHourRank
        salesPpa
        salesPpaRank
        avgGrossSales
        avgSalesPerHour
        avgSalesPpa
        sosAvgTime
        sosAvgTimeRank
        avgSosAvgTime
      }
    }
  }
`

export const LIST_LOCATION_SALESMANS = gql`
  query ListLocationSalesmans(
    $locationId: Int!
    $startDate: Date!
    $endDate: Date!
    $dayPart: String!
  ) {
    listLocationSalesmans(
      iLocationId: $locationId
      iStartDate: $startDate
      iEndDate: $endDate
      iDayPart: $dayPart
    ) {
      nodes {
        locationId
        startDate
        endDate
        employeeId
        firstName
        lastName
        roleName
        dayPart
        daysWorked
        shiftHours
        grossSales
        grossSalesRank
        salesPerHour
        salesPerHourRank
        salesPpa
        salesPpaRank
        avgGrossSales
        avgSalesPerHour
        avgSalesPpa
        sosAvgTime
        sosAvgTimeRank
        avgSosAvgTime
      }
    }
  }
`
