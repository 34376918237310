import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const fwWingstopListLocationGroupLossPreventionKpisConfigs = {
  discountCompsTotal: 'price',
  lossPreventionCompsCashOverOrShort: 'price',
  lossPreventionCheckCountVoids: 'number',
  lossPreventionCheckCountPercentVoids: 'percent',
} as const

const useFwWingstopListLocationGroupLossPreventionKpis = buildMetricValuesHook({
  groupFilterType: 'corporate',
  metrics: {
    codes: [
      'discount_comps_total',
      'loss_prevention_comps_cash_over_or_short',
      'loss_prevention_check_count_voids',
      'loss_prevention_check_count_percent_voids',
    ],
  },
})

export default useFwWingstopListLocationGroupLossPreventionKpis
