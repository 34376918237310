import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

import {
  GET_CORPORATE_COGS_SUMMARY,
  GET_CORPORATE_DELIVERY_SUMMARY,
  GET_CORPORATE_GUEST_SUMMARY,
  GET_CORPORATE_LABOR_SUMMARY,
  GET_CORPORATE_LOSS_PREVENTION_SUMMARY,
  GET_CORPORATE_SALESMANSHIP_SUMMARY,
  GET_CORPORATE_TEAM_SUMMARY,
  GET_CORPORATE_VOIDS_SUMMARY,
} from '../gqls'

export default function useFetchSummaryData(
  summaryType: string,
  locationGroupId: number,
  brandLocationGroupId: number,
  startDate: string,
  endDate: string,
  skip?: boolean,
) {
  const brand = getBrand()
  let summaryGql = GET_CORPORATE_COGS_SUMMARY(brand)
  let gqlQueryName = 'corporateCogsSummary'

  switch (summaryType) {
    case 'corporate_cogs':
      summaryGql = GET_CORPORATE_COGS_SUMMARY(brand)
      gqlQueryName = 'corporateCogsSummary'
      break
    case 'corporate_delivery':
      summaryGql = GET_CORPORATE_DELIVERY_SUMMARY
      gqlQueryName = 'corporateDeliverySummary'
      break
    case 'corporate_labor':
      summaryGql = GET_CORPORATE_LABOR_SUMMARY
      gqlQueryName = 'corporateLaborSummary'
      break
    case 'corporate_loss_prevention':
      summaryGql = GET_CORPORATE_LOSS_PREVENTION_SUMMARY
      gqlQueryName = 'corporateLossPreventionSummary'
      break
    case 'corporate_salesmanship':
      summaryGql = GET_CORPORATE_SALESMANSHIP_SUMMARY
      gqlQueryName = 'corporateSalesmanshipSummary'
      break
    case 'corporate_team':
      summaryGql = GET_CORPORATE_TEAM_SUMMARY
      gqlQueryName = 'corporateTeamSummary'
      break
    case 'corporate_guest':
      summaryGql = GET_CORPORATE_GUEST_SUMMARY
      gqlQueryName = 'corporateGuestSummary'
      break
    case 'corporate_voids_summary':
      summaryGql = GET_CORPORATE_VOIDS_SUMMARY
      gqlQueryName = 'corporateVoidsSummary'
      break
    default:
    // do nothing
  }

  const { data, error, loading } = useQuery(summaryGql, {
    variables: {
      iLocationGroupId: locationGroupId,
      iBrandLocationGroupId: brandLocationGroupId,
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip:
      skip ||
      !startDate ||
      !endDate ||
      !locationGroupId ||
      !brandLocationGroupId ||
      !summaryType,
  })

  return useMemo(() => {
    const summaryData: any = data?.[gqlQueryName]?.nodes || null

    return {
      error,
      loading,
      type: summaryType,
      data: summaryData,
    }
  }, [data, error, loading, summaryType, locationGroupId, startDate, endDate])
}
