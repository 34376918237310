import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { getBrand } from 'pared/utils/brand'

import TrackerSubtitle, {
  IPropsType as ITrackerSubtitlePropsType,
} from '../trackerSubtitle'

export interface ITrackerTitlePropsType {
  traineeName: string
  traineeId: string
  trainerName: string
  assignedTracker: string
  assignedAt: string
  hideAssign: boolean
}

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  font-family: Lexend-SemiBold;
  line-height: 45px;
`

const AssignDate = styled.div`
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
`

const NameRow = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`

const TrainerName = styled.div`
  margin-left: 150px;
  display: inline-block;
`

const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
`

const TrackerTitle = ({
  traineeName,
  traineeId,
  trainerName,
  assignedTracker,
  assignedAt,
  hideAssign,
}: ITrackerTitlePropsType) => {
  const brand = getBrand()
  const employeeUrl = `/${brand}/training_tracker_profile/${traineeId}`

  return (
    <div>
      <StyledImg
        src={
          require(`pared/images/brands/jsc_bk/${assignedTracker
            .toLowerCase()
            .replaceAll(' ', '-')}-header.png`).default
        }
        alt="Header Image"
      />
      {!hideAssign && (
        <TrackerSubtitle
          {...({
            api: 'jscTrainingTracker',
          } as ITrackerSubtitlePropsType)}
        />
      )}
      <AssignDate>{`Assigned ${assignedAt}`}</AssignDate>
      <NameRow>
        <Link to={employeeUrl}>{`Trainee: ${traineeName}`}</Link>
        <TrainerName>{`Trainer: ${trainerName}`}</TrainerName>
      </NameRow>
    </div>
  )
}

export default TrackerTitle
