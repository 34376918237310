import { Chart, PieController } from 'chart.js'
import zoomPlugin from 'chartjs-plugin-zoom'

import BubbleWithLabels from './BubbleWithLabels'
import labelsButton from './plugins/labelsButton'

export * from 'chart.js'

Chart.register(BubbleWithLabels)
Chart.register(labelsButton)
Chart.register(zoomPlugin)
Chart.register(PieController)

export default Chart
