import styled from 'styled-components'

import Table from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import useCash from './hooks/useCash'
import useCashColumns from './hooks/useCashColumns'

interface IProps {
  locationId: number
  startDate?: string
  endDate?: string
}

const MobileCotainer = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 20px 0;

  @media ${MOBILE_WIDTH} {
    margin: 20px 0px 0px;
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const rowStyle = `
  td:first-child {
    font-family: Lexend-SemiBold;
    font-weight: 600px;
  }

  &:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }
`

export default function EmployeeCashHandlingTable({
  locationId,
  startDate,
  endDate,
}: IProps) {
  const cash = useCash(locationId, startDate, endDate)
  const cashColumns = useCashColumns('cashTable')

  return (
    <MobileCotainer>
      <Table dataSource={cash} columns={cashColumns} rowStyle={rowStyle} />
    </MobileCotainer>
  )
}
