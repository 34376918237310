import { IConfigsType } from '../../types'

const irmgBkPlaidPageConfig: IConfigsType = {
  'irmg_bk-plaid_page_b61d68b9': {
    '/:brand/plaid': {
      plaid: {
        type: 'title',
        title: 'Plaid',
      },
      bankAccountLinkForm: {
        type: 'irmg_cc-bank-account-link-form',
      },
      downloadPlaidLinkedBankAccountTable: {
        type: 'download-csv',
        api: 'irmgBkPlaid',
        fileName: 'irmg-plaid-linked-bank-accounts',
        message: 'Download Plaid linked bank accounts into a CSV file',
        fields: [
          {
            key: 'financialInstitution',
            title: 'Bank',
          },
          {
            key: 'bankAccountMask',
            title: 'Last 4 Digits',
          },
          {
            key: 'bankAccountName',
            title: 'Name',
          },
          {
            key: 'bankAccountOfficialName',
            title: 'Offical Name',
          },
          {
            key: 'plaidAccessToken',
            title: 'Plaid Access Token',
          },
          {
            key: 'balanceAvailable',
            title: 'Balance Available',
          },
          {
            key: 'balanceCurrent',
            title: 'Balance Current',
          },
          {
            key: 'balanceQueryDate',
            title: 'Balance Query Date',
          },
          {
            key: 'latestTransactionDate',
            title: 'Latest Transaction Date',
          },
        ],
      },
      plaidLinkedBankAccountTable: {
        type: 'table',
        api: 'irmgBkPlaid',
        rowKey: 'bankAccountPlaidId',
        columns: [
          {
            key: 'financialInstitution',
            title: 'Bank',
            sortable: true,
          },
          {
            key: 'bankAccountMask',
            title: 'Last 4 Digits',
            sortable: true,
          },
          {
            key: 'bankAccountName',
            title: 'Name',
            sortable: true,
          },
          {
            key: 'bankAccountOfficialName',
            title: 'Offical Name',
            sortable: true,
          },
          {
            key: 'plaidAccessTokenLast10Chars',
            title: 'Plaid Access Token',
            sortable: true,
          },
          {
            key: 'balanceAvailable',
            title: 'Balance Available',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'balanceCurrent',
            title: 'Balance Current',
            decimal: 2,
            sortable: true,
          },
          {
            key: 'coloredBalanceQueryDate',
            title: 'Balance Query Date',
            sortable: true,
          },
          {
            key: 'coloredLatestTransactionDate',
            title: 'Latest Transaction Date',
            sortable: true,
          },
        ],
      },
    },
  },
}

export default irmgBkPlaidPageConfig
