import { INavParams } from 'pared/Routes/navParams'
import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import { IDateRange } from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import { IDetailGuestKpi, IGlobalSalesmanshipKpi } from './index'

interface IProps {
  navParams: INavParams
  allDirectors: IDirector[]
  selectedDirector: IDirector | null
  onDirectorChange: (directorEmployeeId: number) => Promise<void>
  selectedDateRange: IDateRange | null
  groupBy: string
  globalSalesmanshipKpiSummary: IGlobalSalesmanshipKpi
  itemizedKpis: IDetailDeliveryKpi[]
  isBreakdownTableLoading: boolean
}

function Main({
  navParams,
  allDirectors,
  selectedDirector,
  onDirectorChange,
  selectedDateRange,
  groupBy,
  globalSalesmanshipKpiSummary,
  itemizedKpis,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          navParams={navParams}
          globalSalesmanshipKpiSummary={globalSalesmanshipKpiSummary}
          allDirectors={allDirectors}
          selectedDirector={selectedDirector}
          selectedDateRange={selectedDateRange}
          onDirectorChange={onDirectorChange}
          itemizedKpis={itemizedKpis}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          navParams={navParams}
          globalSalesmanshipKpiSummary={globalSalesmanshipKpiSummary}
          allDirectors={allDirectors}
          selectedDirector={selectedDirector}
          selectedDateRange={selectedDateRange}
          onDirectorChange={onDirectorChange}
          itemizedKpis={itemizedKpis}
        />
      </SmallScreen>
    </>
  )
}

export default Main
