import CssBaseline from '@material-ui/core/CssBaseline'
import 'fontsource-roboto'
import React from 'react'
import 'url-search-params-polyfill'

import ParedApp from 'pared/ParedApp'

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <ParedApp />
    </React.Fragment>
  )
}

export default App
