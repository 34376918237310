// FIXME: should add download-csv-v2
import { useMemo } from 'react'

import { useGroupFilter } from '../../groupFilter'
import useBbbFlash, { bbbFlashConfigs } from '../../tableV2/hooks/bbb/useFlash'

export const bbbFlashV2Configs = {
  ...bbbFlashConfigs,
}

const useBbbFlashV2 = () => {
  const { data, loading } = useBbbFlash()
  const { groupFilter } = useGroupFilter()

  return {
    data: useMemo((): {
      source: Record<string, unknown>[]
      summary: Record<string, unknown> | null
    } | null => {
      if (!data) return null

      return {
        source: data
          .filter((d) => d.parentId === 'summary')
          .map((d) => {
            const groupInfo = groupFilter?.list?.find(
              (l) => l.id.toString() === d.id,
            )

            return {
              ...d,
              groupInfo,
            }
          }),
        summary: data.filter((d) => d.parentId === 'root')?.[0] || null,
      }
    }, [data, groupFilter]),
    loading,
  }
}

export default useBbbFlashV2
