import { gql } from '@apollo/client'

export interface IListLocationVsSystemwideAcrScoreNodesType {
  locationId: number
  businessYear: string
  businessQuarter: string
  businessMonth: string
  businessWeek: string
  businessDate: string
  locationAcrScore: number
  systemwideAcrScore: number
}

export interface IListLocationVsSystemwideAcrScoreType {
  listLocationVsSystemwideAcrScore: IListLocationVsSystemwideAcrScoreDataType
}

export interface IListLocationVsSystemwideAcrScoreDataType {
  nodes: IListLocationVsSystemwideAcrScoreNodesType[]
}

export interface IListLocationVsSystemwideAcrScoreVariablesType {
  iLocationId: number
  iEndDate: string
  iStartDate: string
  iGroupBy: string
}

export const listLocationVsSystemwideAcrScore = gql`
  query ListLocationVsSystemwideAcrScore(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
  ) {
    listLocationVsSystemwideAcrScore(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) {
      nodes {
        locationId
        businessYear
        businessQuarter
        businessMonth
        businessWeek
        businessDate
        locationAcrScore
        systemwideAcrScore
      }
    }
  }
`
