import { gql } from '@apollo/client'

interface ITrendLocationPerformanceNodesType {
  startDate: string
  endDate: string
  locationId: number
  businessWeek: number
  businessMonth: number
  businessQuarter: number
  businessYear: number
  sales: number
  foodAndPaperPercent: number
  laborPercent: number
  ebitdaAfterBonusPercent: number
}

export interface ITrendLocationPerformanceType {
  trendLocationPerformance: {
    nodes: ITrendLocationPerformanceNodesType[]
  }
}

export interface ITrendLocationPerformanceVariablesType {
  iEndDate: string
  iLocationId: number
  iGroupBy: string
  iDateRangeNumber: number
}

export const TREND_LOCATION_PERFORMANCE = gql`
  query TrendLocationPerformance(
    $iLocationId: Int!
    $iEndDate: Date!
    $iGroupBy: String!
    $iDateRangeNumber: Int
  ) {
    trendLocationPerformance(
      iLocationId: $iLocationId
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iDateRangeNumber: $iDateRangeNumber
    ) {
      nodes {
        locationId
        startDate
        endDate
        businessWeek
        businessMonth
        businessQuarter
        businessYear
        sales
        foodAndPaperPercent
        laborPercent
        ebitdaAfterBonusPercent
      }
    }
  }
`
