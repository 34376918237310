import paredAnalytics from 'pared/analytics/utils/ParedAnalytics'

export const track = {
  async directorChanged(newDirector: string) {
    await paredAnalytics.track('StoreList - Director Changed', { newDirector })
  },

  async locationLinkClicked(locationId: number) {
    await paredAnalytics.track('StoreList - Location Link Changed', {
      locationId,
    })
  },

  async directorProfileLinkClicked(directorId: number) {
    await paredAnalytics.track('StoreList - Director Profile Link Changed', {
      directorId,
    })
  },

  async tableColumnSorted(columnName: string, orderType: string) {
    await paredAnalytics.track('StoreList - Table Column Sorted', {
      columnName,
      orderType,
    })
  },

  async unfilledManagerialPositionsToggled() {
    await paredAnalytics.track(
      'StoreList - Unfilled Managerial Positions Table Toggled',
    )
  },

  async managerPerformanceToggled() {
    await paredAnalytics.track('StoreList - Manager Performance Table Toggled')
  },
}
