import { useReactiveVar } from '@apollo/client'
import Drawer from '@material-ui/core/Drawer'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import Routes from 'pared/Routes'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { decodedUserJwtToken } from 'pared/reactiveVariables/user'

import NavigationList from './NavigationList'
import OldUrlWarning from './OldUrlWarning'
import TopMenu from './TopMenu'
import { leftDrawerWidthLarge } from './constant'
import useNavParams from './hooks/useNavParams'
import useUpdateStatePool from './hooks/useUpdateStatePool'

interface IContentContainer {
  isPageFullWidth?: boolean
}

const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
`

const Main = styled.div<{ open: boolean }>`
  width: ${({ open }) =>
    open ? `calc(100% - ${leftDrawerWidthLarge}px)` : '100%'};
  transform: translate(
    ${({ open }) => (open ? `${leftDrawerWidthLarge}px` : '0px')}
  );
  transition: all 225ms;
  overflow-x: scroll;

  @media ${MOBILE_WIDTH} {
    width: 100vw;
    margin-left: 0px;
  }
`

const ContentContainer = styled.div<IContentContainer>`
  display: inline-block;
  padding-top: 45px;
  padding-left: ${(props) => (props.isPageFullWidth ? '0px' : '50px')};
  padding-right: ${(props) => (props.isPageFullWidth ? '0px' : '50px')};
  padding-bottom: 50px;

  @media ${MOBILE_WIDTH} {
    display: block;
    min-width: 300px;
    padding: 0px 0px 25px 0px;
  }
`

const StyleDrawer = styled(Drawer)`
  .MuiDrawer-paperAnchorDockedLeft {
    border-right: 0px;
  }
`

const Shade = styled.div<{ open: boolean }>`
  @media ${MOBILE_WIDTH} {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    display: ${({ open }) => (open ? 'initial' : 'none')};
  }
`

const Layout = () => {
  // use the reactive variable `decodedUserJwtToken` to trigger page refreshing after the user sign in or sign out
  const jwtToken = useReactiveVar(decodedUserJwtToken)
  const hasUserSignedIn = !!jwtToken
  const accessibleBrands: number[] = jwtToken?.accessible_brands || []

  // force users to use getexpo.com URLs
  const url = (window.location.href || '').toLowerCase()
  const isOldUrl = url.indexOf('macgrill.pared.com') >= 0
  const navParams = useNavParams()
  const isPageFullWidth = navParams?.isPageFullWidth
  const matches = useMediaQuery(MOBILE_WIDTH)
  const [open, setOpen] = useState(true)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }
  const contentRef = useRef(null)

  useUpdateStatePool()

  useEffect(() => {
    setOpen(!matches)
  }, [matches])

  if (isOldUrl) {
    const isSullivansUser = url.indexOf('/sullivans') >= 0

    return <OldUrlWarning isSullivansUser={isSullivansUser} />
  }

  if (hasUserSignedIn) {
    return (
      <LayoutContainer>
        <StyleDrawer variant="persistent" anchor="left" open={open}>
          <NavigationList
            navParams={navParams}
            handleDrawerClose={handleDrawerClose}
          />
        </StyleDrawer>
        <Main open={open}>
          <TopMenu
            navParams={navParams}
            isOpen={open}
            handleDrawerOpen={handleDrawerOpen}
            contentRef={contentRef}
          />
          <ContentContainer ref={contentRef} isPageFullWidth={isPageFullWidth}>
            <Routes
              hasUserSignedIn={true}
              navParams={navParams}
              accessibleBrands={accessibleBrands}
            />
          </ContentContainer>
        </Main>
        <Shade open={open} onClick={handleDrawerClose} />
      </LayoutContainer>
    )
  }

  return (
    <Routes
      hasUserSignedIn={false}
      navParams={navParams}
      accessibleBrands={accessibleBrands}
    />
  )
}

export default React.memo(Layout)
