import moment from 'moment'
import { useMemo } from 'react'

import useBunConfig from '../../hooks/useBunConfig'
import { IDataSourceType } from '../types'
import useHolidays from './useHolidays'

interface IColumnsType {
  type: string | number
  values: {
    key: string | number
    value: string | number
    date?: moment.Moment
    isForecast?: boolean
    isHoilday?: boolean
  }[]
}

const getData = (start: moment.Moment, holidays: moment.Moment[]) => ({
  week: start.week(),
  days: [...new Array(7)].map((_, index) => {
    const date = start.clone().add(index, 'days')
    return {
      key: index,
      date,
      value: date.format('M/D'),
      isHoilday: holidays.some((holiday) => holiday.isSame(date, 'day')),
    }
  }),
  currentYear: start.year(),
  previousYear: start.clone().subtract(1, 'years').year(),
})

const isForecast = (date: moment.Moment) =>
  date.diff(moment().subtract(1, 'day').startOf('days')) > 0

function adjustCustomerWeekStart(
  queryDate: moment.Moment,
  weekStartDay: number,
) {
  return queryDate.clone().add(weekStartDay, 'days')
}

const generateColumns = (
  { week, days, currentYear, previousYear }: ReturnType<typeof getData>,
  dataSource: IDataSourceType[],
): IColumnsType[] => [
  {
    type: week,
    values: [
      {
        key: 'week',
        value: `Week ${week}`,
      },
      ...days,
      {
        key: 'total',
        value: 'Total',
      },
    ],
  },
  {
    type: `${currentYear}-${week}`,
    values: [
      {
        key: 'year',
        value: currentYear,
      },
      ...(
        dataSource.find((d) => d.year === currentYear && d.week === week)
          ?.values || ([] as number[])
      ).map((value, index) => ({
        key: index,
        value,
        isForecast: isForecast((days[index] || days[6]).date),
      })),
    ],
  },
  {
    type: `${previousYear}-${week}`,
    values: [
      {
        key: 'year',
        value: previousYear,
      },
      ...(
        dataSource.find((d) => d.year === previousYear && d.week === week)
          ?.values || ([] as number[])
      ).map((value, index) => ({
        key: index,
        value,
      })),
    ],
  },
]

const useColumns = (dataSource: IDataSourceType[]) => {
  const config = useBunConfig()
  const holidays = useHolidays()
  const weekStartDay = config?.weekStartDay || 0

  return useMemo(
    () => [
      ...generateColumns(
        getData(
          adjustCustomerWeekStart(
            moment().startOf('weeks').subtract(2, 'weeks'),
            weekStartDay,
          ),
          holidays,
        ),
        dataSource,
      ),
      ...generateColumns(
        getData(
          adjustCustomerWeekStart(
            moment().startOf('weeks').subtract(1, 'weeks'),
            weekStartDay,
          ),
          holidays,
        ),
        dataSource,
      ),
      ...generateColumns(
        getData(
          adjustCustomerWeekStart(moment().startOf('weeks'), weekStartDay),
          holidays,
        ),
        dataSource,
      ),
      ...generateColumns(
        getData(
          adjustCustomerWeekStart(
            moment().startOf('weeks').add(1, 'weeks'),
            weekStartDay,
          ),
          holidays,
        ),
        dataSource,
      ),
    ],
    [dataSource, holidays],
  )
}

export default useColumns
