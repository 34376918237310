import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import MixedChart from 'pared/charts/MixedChart'
import LocationInfo from 'pared/components/LocationInfo/index'
import List from 'pared/components/basicUi/List'
import Calendar from 'pared/components/basicUi/calendar'
import LocationSelector from 'pared/components/nav/LocationSelector'
import COLORS from 'pared/constants/colors'
import { RAW_LABOR_SCHEDULE } from 'pared/constants/raw_data/laborSchedule'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'
import { ILocation } from 'pared/data/getLocations'
import { toPercentageString } from 'pared/utils/number'

import PositionSpan from './PositionSpan'
import Summary from './Summary'
import TeamFeedbackTable from './TeamFeedbackTable'
import AvgHourlyRatesChart from './avgHourlyRatesChart'
import useTeamConfig from './hooks/useTeamConfig'
import { ILocationTeamData } from './index'
import LaborTrends from './laborTrends'
import Schedule from './schedule'

interface IProps {
  navParams: INavParams
  shouldShowFeedback: boolean
  locationTeamData: ILocationTeamData | null
  errorMessage: string
  isCommentPopoverOpen: boolean
  setIsCommentPopoverOpen: any
  allLocations: ILocation[] | null
  onRefresh: () => Promise<void>
  isTurnoverChartLoading: boolean
}

const CHART_WIDTH = 1200
const CHART_HEIGHT = 400

function WebLargeMain({
  navParams,
  shouldShowFeedback,
  locationTeamData,
  errorMessage,
  allLocations,
  onRefresh,
  isTurnoverChartLoading,
}: IProps) {
  const teamConfig = useTeamConfig()
  const brandSettings = getBrandSettings()
  const businessLabels = brandSettings.labels.business
  const comparisonDisplayName = businessLabels.accessGroupType ?? 'Systemwide'

  if (errorMessage) {
    return (
      <MainContainer>
        <PageStatusDiv>{errorMessage}</PageStatusDiv>
      </MainContainer>
    )
  }

  if (!locationTeamData?.location?.id) {
    return (
      <MainContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </MainContainer>
    )
  }

  const chartOptions = {
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: 'Annualized Turnover Rate',
    yLeftTickCallback: (value: number) => {
      return toPercentageString(value, 1)
    },
    yRightAxisLabel: 'Hourly Turnover Count',
    yRightShowGridLines: false,
    yRightBeginAtZero: true,
    yRightTickStepSize: 1,
    tooltipTitleCallback: (tooltipItemContext: any[]) => {
      if (tooltipItemContext && tooltipItemContext[0]) {
        const context = tooltipItemContext[0]
        if (context.label) {
          return context.label
        }
      }
      return ''
    },
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let datasetLabel = ''
        let separator = ''
        let yValue = ''
        if (tooltipItemContext.dataset && tooltipItemContext.dataset.label) {
          datasetLabel = tooltipItemContext.dataset.label
          separator = ': '
        }
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          if (tooltipItemContext.datasetIndex === 0) {
            yValue = tooltipItemContext.parsed.y
          } else if (tooltipItemContext.datasetIndex === 1) {
            const dataIndex = tooltipItemContext.dataIndex
            const turnoverData = locationTeamData.turnovers[dataIndex]
            if (turnoverData) {
              yValue += [
                `monthly ${toPercentageString(
                  turnoverData.locationHourlyMonthlyTurnoverRate,
                  1,
                )}`,
                `annualized ${toPercentageString(
                  tooltipItemContext.parsed.y,
                  1,
                )}`,
              ].join(', ')
            } else {
              yValue = toPercentageString(tooltipItemContext.parsed.y, 1)
            }
          } else {
            yValue = toPercentageString(tooltipItemContext.parsed.y, 1)
          }
        }
        return [datasetLabel, separator, yValue].join('')
      }
      return ''
    },
    legendClickHandler: (event: any, legendItem: any, legend: any) => {
      const index = legendItem.datasetIndex
      const legendItems = legend.legendItems
      const legendItemMap = legendItems.reduce(
        (acc: any, cur: any) => {
          if (cur.text.indexOf(comparisonDisplayName) < 0) {
            acc.location.push(cur.datasetIndex)
          }

          return acc
        },
        { location: [] },
      )

      const ci = legend.chart
      if (ci.isDatasetVisible(index)) {
        if (legendItem.text.indexOf(comparisonDisplayName) < 0) {
          legendItemMap.location.forEach((index: number) => {
            ci.hide(index)
            legendItems[index].hidden = true
          })
        } else {
          ci.hide(index)
          legendItem.hidden = true
        }
      } else {
        if (legendItem.text.indexOf(comparisonDisplayName) < 0) {
          legendItemMap.location.forEach((index: number) => {
            ci.show(index)
            legendItems[index].hidden = false
          })
        } else {
          ci.show(index)
          legendItem.hidden = false
        }
      }
    },
  }

  const xAxisData = locationTeamData.turnovers.map((data) => {
    return `P${data.month} ${data.year}`
  })

  const chart = (
    <MixedChart
      xAxisData={xAxisData}
      yAxisDataArr={[
        {
          type: 'bar',
          yAxisId: 'yRight',
          tooltipLabel: `${locationTeamData.location.name} Hourly Turnover Count`,
          data: locationTeamData.turnovers.map((data) => {
            return data.locationHourlyTerminatedCountInThisMonth
          }),
        },
        {
          type: 'line',
          data: locationTeamData.turnovers.map((data) => {
            return data.locationHourlyAnnualizedTurnoverRate
          }),
          tooltipLabel: `${locationTeamData.location.name} Annualized Hourly Turnover`,
          borderColor: COLORS.Pomodoro,
          backgroundColor: COLORS.Pomodoro,
        },
        {
          type: 'line',
          data: locationTeamData.turnovers.map((data) => {
            return data.systemwideHourlyAnnualizedTurnoverRate
          }),
          tooltipLabel: `${comparisonDisplayName} Annualized Hourly Turnover`,
          borderColor: COLORS.Plum,
          backgroundColor: COLORS.Plum,
        },
        {
          type: 'line',
          data: locationTeamData.turnovers.map((data) => {
            return data.systemwideSalariedAnnualizedTurnoverRate
          }),
          tooltipLabel: `${comparisonDisplayName} Annualized Salaried Turnover`,
          borderColor: COLORS.Mango,
          backgroundColor: COLORS.Mango,
        },
      ]}
      options={chartOptions}
    />
  )

  let vacancyTable = null
  if (locationTeamData.unfilledManagerialPositions.length > 0) {
    vacancyTable = (
      <UnfilledPositionsTable>
        <thead>
          <tr>
            <RoleTh>Role</RoleTh>
            <th>Time Vacant</th>
            <PendingStartDateTh>Filled Pending Start Date</PendingStartDateTh>
            <th>Name</th>
            <th>Training Store</th>
            <th>Comments</th>
            <th>Last Edited By</th>
          </tr>
        </thead>
        <tbody>
          {locationTeamData.unfilledManagerialPositions.map((data, index) => {
            const formattedFilledPendingStartDate = moment(
              _.get(data, 'filledPendingStartDate', ''),
            ).format('MM/DD/YYYY')
            const candidateName = _.get(data, 'candidateName', '')
            const trainingStoreName = _.get(data, 'trainingStoreName', '')
            const comment = _.get(data, 'comment', '')
            const commenterName = `${_.get(
              data,
              'commentedByFirstName',
              '',
            )} ${_.get(data, 'commentedByLastName', '')}`
            const positionId = _.get(data, 'positionId', 1)
            const currentTrainingStoreId = _.get(data, 'trainingStoreId', 0)

            const detailData = !_.isEmpty(candidateName) ? (
              <>
                <td>{formattedFilledPendingStartDate}</td>
                <td>{candidateName}</td>
                <td>{trainingStoreName}</td>
                <CommentTd>
                  <Feedback>{comment}</Feedback>
                </CommentTd>
                <td>
                  <Reviewer>{commenterName}</Reviewer>
                  <ReviewDate>
                    {moment(_.get(data, 'commentedAt', '')).format(
                      'MM/DD/YYYY',
                    )}
                  </ReviewDate>
                </td>
                <PositionSpanTd>
                  <PositionSpan
                    allLocations={allLocations}
                    roleName={data.roleName}
                    timeVacant={data.timeVacant}
                    isIcon
                    onRefresh={onRefresh}
                    positionId={positionId}
                    candidateName={candidateName}
                    comment={comment}
                    currentTrainingStoreId={currentTrainingStoreId}
                    currentFormattedPendingStartDate={
                      formattedFilledPendingStartDate
                    }
                  />
                </PositionSpanTd>
              </>
            ) : (
              <td colSpan={6}>
                <UpdatePosition>
                  <PositionSpan
                    allLocations={allLocations}
                    roleName={data.roleName}
                    timeVacant={data.timeVacant}
                    onRefresh={onRefresh}
                    positionId={positionId}
                  />
                </UpdatePosition>
              </td>
            )

            return (
              <tr key={`${data.roleName}-${index}`}>
                <RoleTd>{data.roleName}</RoleTd>
                <td>{data.timeVacant}</td>
                {detailData}
              </tr>
            )
          })}
        </tbody>
      </UnfilledPositionsTable>
    )
  } else {
    vacancyTable = (
      <SectionDescriptionDiv>
        Congratulations! All managerial positions are filled.
      </SectionDescriptionDiv>
    )
  }

  return (
    <>
      <Header>
        <div>
          <h1>
            {businessLabels.store}: {locationTeamData.location.displayName}
          </h1>
          <LocationInfo locationId={locationTeamData.location.id} />
        </div>

        <Selectors>
          <LocationSelector navParams={navParams} />
        </Selectors>
      </Header>

      <MainContainer>
        {teamConfig.summary?.isVisible ? (
          <>
            <VerticalSpacer30px />
            <Summary locationId={locationTeamData.location.id} />
          </>
        ) : null}
        <StyledList
          title="KPIs"
          items={[
            ...(!teamConfig.managerPar.isVisible
              ? []
              : [
                  {
                    key: 'managerPar',
                    value: locationTeamData.kpis.managerPar,
                    text: 'Manager par',
                  },
                ]),
            {
              key: 'salariedTurnoverInCurrentMonth',
              value: locationTeamData.kpis.salariedTurnoverInCurrentMonth,
              text: `Salaried Turnover in ${locationTeamData.kpis.month}`,
            },
            {
              key: 'hourlyTurnoverInCurrentMonth',
              value: locationTeamData.kpis.hourlyTurnoverInCurrentMonth,
              text: `Hourly Turnover in ${locationTeamData.kpis.month}`,
            },
            {
              key: 'annualizedSalariedTurnoverRate',
              value: locationTeamData.kpis.annualizedSalariedTurnoverRate,
              text: 'Annualized Salaried Turnover',
            },
            {
              key: 'annualizedSalariedTurnoverRank',
              value: locationTeamData.kpis.annualizedSalariedTurnoverRank,
              text: 'Annualized Salaried Turnover Rank',
            },
            {
              key: 'annualizedHourlyTurnoverRate',
              value: locationTeamData.kpis.annualizedHourlyTurnoverRate,
              text: 'Annualized Hourly Turnover',
            },
            {
              key: 'annualizedHourlyTurnoverRank',
              value: locationTeamData.kpis.annualizedHourlyTurnoverRank,
              text: 'Annualized Hourly Turnover Rank',
            },
          ]}
        />

        <VerticalSpacer100px />

        <SectionTitleDiv>TURNOVER CHART</SectionTitleDiv>

        <MobileContainer>
          {isTurnoverChartLoading ? (
            <PageStatusDiv>Loading ...</PageStatusDiv>
          ) : (
            <ChartContainer>{chart}</ChartContainer>
          )}
        </MobileContainer>

        <VerticalSpacer100px />

        {!teamConfig['avgHourlyRate']?.isVisible ? null : (
          <>
            <SectionTitleDiv>AVERAGE HOURLY RATES</SectionTitleDiv>

            <MobileContainer>
              <AvgHourlyRatesChart
                selectedLocation={locationTeamData.location}
              />
            </MobileContainer>

            <VerticalSpacer100px />
          </>
        )}

        {!teamConfig['laborSchedule']?.isVisible ? null : (
          <>
            <SectionTitleDiv>LABOR SCHEDULE</SectionTitleDiv>
            <VerticalSpacer60px />

            <MobileContainer>
              <Calendar dataSource={RAW_LABOR_SCHEDULE()} />
            </MobileContainer>

            <VerticalSpacer60px />
          </>
        )}

        {!teamConfig['laborScheduleVsTarget']?.isVisible ? null : (
          <>
            <SectionTitleDiv>SCHEDULE VS TARGET</SectionTitleDiv>
            <SubtitleContainer>
              Click on a day for more detail.
            </SubtitleContainer>
            <SubtitleContainer>
              THIS FEATURE IS A DEMO. NOT ACTUAL DATA.
            </SubtitleContainer>
            <VerticalSpacer60px />

            <MobileContainer>
              <Schedule />
            </MobileContainer>

            <VerticalSpacer60px />
          </>
        )}

        {!teamConfig['laborTrends']?.isVisible ? null : (
          <LaborTrends locationId={locationTeamData.location.id} />
        )}

        {!teamConfig['unfilledManagerialPositions']?.isVisible ? null : (
          <>
            <SectionTitleDiv>UNFILLED MANAGERIAL POSITIONS</SectionTitleDiv>

            <MobileContainer>{vacancyTable}</MobileContainer>

            <VerticalSpacer100px />
          </>
        )}

        {!teamConfig['currentTeam']?.isVisible ? null : (
          <>
            <SectionTitleDiv>CURRENT TEAM</SectionTitleDiv>

            <MobileContainer>
              <TeamFeedbackTable
                locationId={locationTeamData.location.id}
                shouldShowFeedback={shouldShowFeedback}
              />
            </MobileContainer>

            <VerticalSpacer60px />
          </>
        )}
      </MainContainer>
    </>
  )
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;
  }

  & > div:first-child {
    @media ${MOBILE_WIDTH} {
      padding: 30px 25px 0px;
    }
  }
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const StyledList = styled(List)`
  margin: 46px 0px 0px;
  width: 1000px;
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`
const MainContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px 25px;
  }
`

const PageHeaderLineContainer = styled.div`
  display: inline-block;
  width: 1200px;
`

const SelectorContainer = styled.div`
  float: right;
  display: inline-block;
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0 0;
  float: left;
`

const UpdatePosition = styled.div`
  font-family: Lexend-Regular;
  color: #07a4c7;
  margin-top: 7px;
  float: left;
  text-align: center;
`

const RoleTh = styled.th`
  padding-left: 10px;
`

const PendingStartDateTh = styled.th`
  width: 150px;
  padding-right: 50px;
`

const RoleTd = styled.td`
  padding-left: 10px;
`

const PositionSpanTd = styled.td`
  padding-right: 10px;
`

const CommentTd = styled.td`
  width: 350px;
`

const Feedback = styled.div`
  font-family: Lexend-Regular;
  font-size: 12px;
`

const ReviewDate = styled.div`
  font-family: Lexend-Regular;
  font-size: 12px;
  font-style: italic;
  margin-top: 8px;
`

const Reviewer = styled.div`
  font-family: Lexend-Regular;
  font-size: 12px;
  font-style: italic;
  margin-top: 8px;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const VerticalSpacer30px = styled.div`
  width: 100%;
  height: 30px;
`

const VerticalSpacer60px = styled.div`
  width: 100%;
  height: 60px;
`

const VerticalSpacer100px = styled.div`
  width: 100%;
  height: 100px;
`

const ChartContainer = styled.div`
  padding: 25px 0 10px 0;
`

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 20px 0;
`

const SectionDescriptionDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const UnfilledPositionsTable = styled.table`
  width: 90%;
  min-width: 950px;
  border-collapse: collapse;
  text-align: left;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  th {
    font-family: Lexend-SemiBold;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  td {
    font-family: Lexend-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }
`

const MobileContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;
`

export default WebLargeMain
