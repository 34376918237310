import { useState } from 'react'
import stringSimilarity from 'string-similarity'

import { IReportResult } from 'pared/components/CustomizedReport'

export interface ICategoryFilterType {
  id: string
  parentId: 'root' | null
  value: string
  text: string
}

const STRING_SIMILARITY_THRESHOLD = 0.35

const useItemFilter = (
  customizedReportResult: IReportResult,
  presetItemFilter: string,
) => {
  const [itemFilter, setItemFilter] = useState(presetItemFilter)
  if (!customizedReportResult.tableData || itemFilter === '') {
    return {
      itemFilter,
      setItemFilter,
      itemFilteredCustomizedReportResult: customizedReportResult,
    }
  }

  const itemFilterArray = itemFilter
    .split(';')
    .map((item) => {
      return item.trim().toLowerCase()
    })
    .filter((item) => {
      return !!item
    })

  const allItemNames = customizedReportResult.tableData.map(({ item }) => {
    return item
  })
  const bestMatchItemSet = new Set<string>()

  itemFilterArray.forEach((filter) => {
    const bestMatchResults = stringSimilarity.findBestMatch(
      filter,
      allItemNames,
    )
    const bestMatchItem = bestMatchResults?.bestMatch?.target
    if (bestMatchItem) {
      bestMatchItemSet.add(bestMatchItem.toLowerCase())
    }
  })

  const filteredTableData = customizedReportResult.tableData.filter(
    ({ item }) => {
      const lowerCaseItem = item.toLowerCase()
      let isMatched = false
      const filterCount = itemFilterArray.length

      for (let ii = 0; ii < filterCount; ++ii) {
        const filter = itemFilterArray[ii]

        if (
          bestMatchItemSet.has(lowerCaseItem) ||
          lowerCaseItem.search(filter) !== -1
        ) {
          isMatched = true
          break
        }
      }

      return isMatched
    },
  )

  return {
    itemFilter,
    setItemFilter,
    itemFilteredCustomizedReportResult: {
      ...customizedReportResult,
      tableData: filteredTableData as any,
    },
  }
}

export default useItemFilter
