import { Chart } from 'chart.js'
import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/constants/styles'

import getDisplayedString from '../getDisplayedString'

export interface ITrendData {
  dateRange: string
  kpiValue: {
    type: string
    value: string
  }
}

interface ITrendChartProps {
  kpiValueType: string
  data: { [column: string]: ITrendData[] }
}

const MobileWrapper = styled.div`
  @media ${MOBILE_WIDTH} {
    overflow: scroll hidden;
    padding: 20px 30px;
    border: 1px solid;
  }
`

const ChartContainer = styled.div`
  width: 900px;
  height: 400px;
  padding: 20px 0;
`

const TrendChart: React.FC<ITrendChartProps> = ({ kpiValueType, data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (data && chartRef.current) {
      const chartData = Object.entries(data).map(([column, trendData]) => ({
        label: column,
        data: trendData.map((item) => {
          return {
            dateRange: item?.dateRange,
            kpiValue: item?.kpiValue,
          }
        }),
        parsing: {
          xAxisKey: 'dateRange',
          yAxisKey: 'kpiValue.value',
        },
        borderColor: `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
          Math.random() * 256,
        )}, ${Math.floor(Math.random() * 256)}, 1)`,
        fill: false,
      }))

      new Chart(chartRef.current, {
        type: 'line',
        data: {
          labels: data[Object.keys(data)[0]].map((item) => item.dateRange),
          datasets: chartData,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: (value: any) => {
                  return getDisplayedString({
                    value,
                    type: kpiValueType,
                  })
                },
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (context: any) => {
                  return [
                    context?.dataset?.label || '',
                    getDisplayedString(context.raw?.kpiValue),
                  ].join(' - ')
                },
              },
            },
          },
        },
      })
    }
  }, [data])

  return (
    <MobileWrapper>
      <ChartContainer>
        <canvas ref={chartRef} />
      </ChartContainer>
    </MobileWrapper>
  )
}

export default TrendChart
