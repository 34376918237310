import { useState } from 'react'

import { track } from 'pared/analytics/alert'

import Main from './Main'

interface IProps {
  tableData: object
  locationId: number
}

const OpsCompTable = ({ tableData, locationId }: IProps) => {
  const onClickSeeTeam = () => {
    setIsOpsCompModalVisible(true)
    track.clickOpsCompAlertSeeTeam(locationId)
  }

  const [isOpsCompModalVisible, setIsOpsCompModalVisible] =
    useState<boolean>(false)

  return (
    <Main
      tableData={tableData}
      onClickSeeTeam={onClickSeeTeam}
      locationId={locationId}
      isOpsCompModalVisible={isOpsCompModalVisible}
      setIsOpsCompModalVisible={setIsOpsCompModalVisible}
    />
  )
}

export default OpsCompTable
