import { useQuery } from '@apollo/client'

import { INullableType } from 'pared/types'

import {
  ILossPreventionSummaryType,
  ILossPreventionSummaryVariablesType,
  getLocationLossPreventionSummary,
} from '../gqls/lossPrevention'

interface ILossPreventionActionType {
  hasSummary: Boolean
  name: string
  annualOpportunityCost: number
}

interface IReturnType {
  loading: boolean
  action: ILossPreventionActionType
}

export default (
  startDate: string,
  endDate: string,
  locationId: number,
): IReturnType => {
  const action: ILossPreventionActionType = {
    hasSummary: true,
    name: '',
    annualOpportunityCost: 0,
  }
  const variables: INullableType<ILossPreventionSummaryVariablesType> = {
    iLocationId: locationId,
    iStartDate: startDate,
    iEndDate: endDate,
  }

  const { data, loading } = useQuery<ILossPreventionSummaryType>(
    getLocationLossPreventionSummary,
    {
      variables,
    },
  )

  const dataSource = data?.locationLossPreventionSummary?.nodes[0]

  if (!dataSource) {
    action.hasSummary = false
  } else {
    const employees =
      dataSource.employeeDiscountInfos?.filter(
        (e) => e.preferredName !== 'Unknown Name',
      ) || []
    if (employees.length > 0) {
      action.name = `${employees[0].preferredName} ${employees[0].familyName}`
    }

    const annualOpportunityCost = dataSource.annualOpportunityCost
    if (annualOpportunityCost > 0) {
      action.annualOpportunityCost = annualOpportunityCost / 100
    }
  }

  return {
    loading,
    action,
  }
}
