import { IRouteType } from '../../types'
import irmgCcPlaidPageConfig from './irmgCcPlaidPageConfig'

export const irmgCcRoutes: IRouteType[] = [
  {
    title: 'Corporate',
    children: [
      {
        title: 'Plaid',
        link: '/:brand/plaid',
        default: true,
      },
    ],
  },
]

export default [irmgCcPlaidPageConfig]
