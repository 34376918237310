export type ITurnoverType = 'annualized' | 30 | 60 | 90

export const getTypeDisplayValue = (type: ITurnoverType) => {
  switch (type) {
    case 'annualized':
      return 'Annualized'
    case 30:
      return '30 Day'
    case 60:
      return '60 Day'
    case 90:
      return '90 Day'
    default:
      return ''
  }
}

export const turnoverTypes = (isTeamV2: boolean) => {
  if (isTeamV2) return ['annualized'] as ITurnoverType[]

  return ['annualized', 30, 60, 90] as ITurnoverType[]
}
