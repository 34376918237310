import { useCallback, useState } from 'react'

const useCollapseArrowClicked = (): {
  clickedHeaders: Set<string>
  onClick: (name: string) => void
} => {
  const allSections: string[] = []

  const [clickedHeaders, setClickedHeaders] = useState<Set<string>>(
    new Set(allSections),
  )
  const onClick = useCallback(
    (name: string) => {
      const newClickedHeaders = new Set([...Array.from(clickedHeaders)])

      if (newClickedHeaders.has(name)) {
        newClickedHeaders.delete(name)
      } else {
        newClickedHeaders.add(name)
      }

      setClickedHeaders(newClickedHeaders)
    },
    [clickedHeaders],
  )

  return {
    clickedHeaders,
    onClick,
  }
}

export default useCollapseArrowClicked
