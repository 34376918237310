import { useMutation } from '@apollo/client'
import MenuItem from '@material-ui/core/MenuItem'
import Modal from '@material-ui/core/Modal'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@mui/material/Button'
import { useState } from 'react'
import styled from 'styled-components'

import { InputWithLeftLabel } from 'pared/components/basicUi/InputWithLeftLabel'
import Spin from 'pared/components/basicUi/spin'
import COLORS from 'pared/constants/colors'

import useLocationGroups, {
  INode as ILocationGroupNode,
} from '../../hooks/common/useLocationGroups'
import { INode } from '../../hooks/common/useLocations'
import { MUTATION_EDIT_LOCATION } from './gql'

type ILocationGroupType = Pick<
  ILocationGroupNode,
  'locationGroupType' | 'locationGroupId' | 'locationGroupName'
>

const useStyles = makeStyles({
  text: {
    fontFamily: 'Lexend-Regular',
  },
})

const EditLocationButton = ({ row, refetch }: { row: INode; refetch: any }) => {
  const {
    id: locationId,
    code,
    name,
    openedAt: initialOpenDate,
    locationGroups,
  } = row

  const idsOnly: { [locationGroupType: string]: string } = locationGroups
    ? Object.values(locationGroups)?.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.type]: cur.id,
        }
      }, {})
    : {}

  const message = `Editing ${code}: ${name}`

  const initialFormValues = Object.assign(
    { openDate: initialOpenDate },
    idsOnly,
  )
  const { data: allLocationGroups, refetch: refetchLocationGroups } =
    useLocationGroups()
  const locationGroupMap = allLocationGroups?.reduce((acc, cur) => {
    const isLocationGroup = 'locationGroupId' in cur && !('locationId' in cur)
    if (isLocationGroup) {
      const locationGroupType = cur.locationGroupType as string
      const locationGroupId = cur.locationGroupId as string
      const locationGroupName = cur.locationGroupName as string
      const locationGroup = {
        locationGroupType,
        locationGroupId,
        locationGroupName,
      }

      if (!acc[locationGroupType]) {
        acc[locationGroupType] = [locationGroup]
      } else {
        acc[locationGroupType].push(locationGroup)
      }
    }

    return acc
  }, {} as { [locationGroupType: string]: ILocationGroupType[] })

  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [formValues, setFormValues] = useState(initialFormValues)
  const [hasFormSubmitted, setHasFormSubmitted] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [editLocation] = useMutation(MUTATION_EDIT_LOCATION)

  const handleInputChange = (e: any) => {
    const { name, value } = e.target as HTMLInputElement
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const onSubmit = async () => {
    if (!formValues.openDate) {
      setHasError(true)
      setErrorMessage('Date required')
      return
    }

    try {
      setHasFormSubmitted(true)
      await editLocation({
        variables: {
          locationId,
          formValues,
        },
      })

      setOpen(false)
      setHasError(false)
      setErrorMessage('')
      refetch()
      refetchLocationGroups()
    } catch (error) {
      setErrorMessage('Error: Please try again or email support@getexpo.com')
      setHasError(true)
    } finally {
      setHasFormSubmitted(false)
    }
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>Edit</Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Container>
          <Header>{message}</Header>
          <InputWithLeftLabel label="Open Date">
            <TextField
              name="openDate"
              variant="outlined"
              value={formValues.openDate}
              onChange={handleInputChange}
              error={hasError && !formValues.openDate}
              fullWidth
            />
          </InputWithLeftLabel>
          <VerticalSpacer />
          <div>
            {!locationGroupMap
              ? null
              : Object.keys(locationGroupMap)
                  .sort()
                  .map((group) => {
                    return (
                      <>
                        <InputWithLeftLabel label={group}>
                          <Select
                            fullWidth
                            name={group}
                            variant="outlined"
                            value={formValues[group]}
                            displayEmpty
                            onChange={handleInputChange}
                            className={`${classes.text}`}
                          >
                            <MenuItem
                              value=""
                              disabled
                              className={`${classes.text}`}
                            >
                              Select {group}
                            </MenuItem>
                            {locationGroupMap?.[group]?.map((locationGroup) => (
                              <MenuItem
                                key={locationGroup.locationGroupId}
                                value={locationGroup.locationGroupId}
                                className={`${classes.text}`}
                              >
                                {locationGroup.locationGroupName}
                              </MenuItem>
                            ))}
                          </Select>
                        </InputWithLeftLabel>
                        <VerticalSpacer />
                      </>
                    )
                  })}
          </div>
          <Footer>
            <ButtonContainer disabled={hasFormSubmitted}>
              <CancelButton onClick={() => setOpen(false)}>
                Go Back
              </CancelButton>
              <SubmitButton onClick={onSubmit}>
                <Spin spinning={hasFormSubmitted}>Submit</Spin>
              </SubmitButton>
            </ButtonContainer>
            <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
          </Footer>
        </Container>
      </Modal>
    </>
  )
}

export default EditLocationButton

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px 50px;
  max-height: 80vh;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: none;
  overflow: hidden scroll;
  border-radius: 10px;
`

const Header = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 16px;
  margin-bottom: 20px;
`

const ButtonContainer = styled.div<{ disabled: boolean }>`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  ${({ disabled }) => (disabled ? 'pointer-events: none' : '')}
`

const SubmitButton = styled.div`
  background-color: ${COLORS.Expo};
  flex: 1;
  color: white;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  font-family: Lexend-Semibold;
  cursor: pointer;
  &:hover {
    opacity: 50%;
  }
`

const CancelButton = styled(SubmitButton)`
  background-color: #cfcece;
  color: black;
`

const Footer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`

const ErrorMessageContainer = styled.div`
  color: red;
`

const VerticalSpacer = styled.div`
  height: 10px;
`
