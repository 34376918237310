import React from 'react'

import Chart from 'pared/charts'
import { RAW_AVG_HOURLY_RATES } from 'pared/constants/raw_data/avgHourlyRates'

import useConfig from './hooks/useConfig'

interface IPropsType {
  locationGroupId: keyof typeof RAW_AVG_HOURLY_RATES
}

const AvgHourlyRatesChart = ({ locationGroupId }: IPropsType) => {
  const config = useConfig(locationGroupId)

  return <Chart {...config} width={1100} height={350} />
}

export default React.memo(AvgHourlyRatesChart)
