import { ApolloError, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { LIST_LOCATION_SALESMANSHIP_KPIS } from '../gql'
import useSalesmanshipConfig from './useSalesmanshipConfig'

const useLocationSalesmanshipKpiData = (
  locationGroupId: number | undefined,
  startDate: string | undefined,
  endDate: string | undefined,
  isFetching: boolean = true,
): {
  isLoading: boolean
  data: any[] | null
  sosData: any[] | null
  error: ApolloError | null
} => {
  const salesmanshipConfig = useSalesmanshipConfig()
  const { data, error, loading } = useQuery(LIST_LOCATION_SALESMANSHIP_KPIS, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: [locationGroupId],
        use_location_data: !!salesmanshipConfig.useLocationData,
      },
    },
    skip: !isFetching || !locationGroupId || !startDate || !endDate,
  })

  return useMemo(() => {
    if (
      data &&
      data.listLocationSalesmanshipKpis &&
      data.listLocationSpeedOfService &&
      Array.isArray(data.listLocationSalesmanshipKpis.nodes) &&
      Array.isArray(data.listLocationSpeedOfService.nodes)
    ) {
      return {
        isLoading: loading,
        data: [...data.listLocationSalesmanshipKpis.nodes],
        sosData: [...data.listLocationSpeedOfService.nodes],
        error: null,
      }
    } else {
      return {
        isLoading: loading,
        data: null,
        sosData: null,
        error: error || null,
      }
    }
  }, [data, locationGroupId, startDate, endDate])
}

export default useLocationSalesmanshipKpiData
