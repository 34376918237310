import { useLazyQuery, useQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { track } from 'pared/analytics/corporateDelivery'
import { feature, page } from 'pared/analytics/user'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import getDateRanges, { IDateRange } from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'
import getDirectors from 'pared/data/getDirectors'
import useDirectorFilterSelectorConfig from 'pared/pages/StoreList/DirectorFilterSelector/hooks/useConfig'
import { getBrand } from 'pared/utils/brand'
import {
  toDisplayedWaitTime,
  toPercentageString,
  toUsdString,
} from 'pared/utils/number'
import { scrollToTop } from 'pared/utils/web'

import Main from './Main'
import { GET_GLOBAL_DELIVERY_KPI } from './gql'
import useDeliveryConfig from './hooks/useDeliveryConfig'

export interface IDetailDeliveryKpi {
  doEmployeeId: number
  locationId?: number
  totalOrderCount: string
  avgDasherWaitTime: string
  avgDasherWaitTimeNumber: number
  sumSubtotal: string
  sumErrorCharges: string
  orderWithAnyIssuePercent: string
  avgCustomerReviewScore: string
  cancelledOrderPercent: string
  delayedOrderPercent: string
  inaccurateOrderPercent: string
  formattedLocationName: string
  averageTripTime: number
}

export interface IGlobalDeliveryKpi {
  totalOrderCount: string
  avgDasherWaitTime: string
  sumSubtotal: string
  sumErrorCharges: string
  orderWithAnyIssuePercent: string
  avgCustomerReviewScore: string
  cancelledOrderPercent: string
  delayedOrderPercent: string
  inaccurateOrderPercent: string
  averageTripTime: string
}

interface IProps {
  navParams: INavParams
}

const DEFAULT_DIRECTOR: IDirector = {
  employeeId: -1,
  firstName: 'All',
  lastName: 'Stores',
  directorId: -1,
  directorLevel: -1,
  brandId: -1,
  brandCode: '',
  brandName: '',
  locationGroupId: -1,
}

function CorporateDelivery({ navParams }: IProps) {
  const directorFilterSelectorConfig = useDirectorFilterSelectorConfig()
  const dateRange = navParams.dateRange || ''
  const brand = getBrand()
  const config = useDeliveryConfig(brand)

  const [allDirectors, setAllDirectors] = useState<IDirector[] | null>([])
  const [selectedDirector, setSelectedDirector] = useState<IDirector | null>(
    DEFAULT_DIRECTOR,
  )
  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange | null>(
    null,
  )
  const [breakdownType, setBreakdownType] = useState<string | null>(null)

  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  let selectedLocationGroupId = _.get(selectedDirector, 'locationGroupId') || -1
  selectedLocationGroupId =
    selectedLocationGroupId < 0 ? brandLocationGroupId : selectedLocationGroupId

  const [
    getGlobalDeliveryKpi,
    {
      loading: getGlobalDeliveryKpiLoading,
      error: getGlobalDeliveryKpiError,
      data: getGlobalDeliveryKpiData,
    },
  ] = useLazyQuery(GET_GLOBAL_DELIVERY_KPI(config.customizedRealTimeKpis))

  useEffect(() => {
    async function fetchData() {
      const [newAllDateRangeData, directors] = await Promise.all([
        getDateRanges(),
        getDirectors(),
      ])

      if (Array.isArray(directors) && directors.length > 0) {
        setAllDirectors(directors)
      }

      let newSelectedDateRange: IDateRange | null = null
      if (dateRange) {
        newSelectedDateRange = newAllDateRangeData.dateRangeMap[dateRange]
      }

      if (!newSelectedDateRange) {
        newSelectedDateRange = newAllDateRangeData.defaultPeriod
      }

      if (newSelectedDateRange) {
        setSelectedDateRange(newSelectedDateRange)

        const periodStartDate = _.get(newSelectedDateRange, 'startDateStr', '')
        const periodEndDate = _.get(newSelectedDateRange, 'endDateStr', '')

        if (selectedDirctorEmployeeId === -1) {
          // systemwide
          getGlobalDeliveryKpi({
            variables: {
              iLocationGroupId: selectedLocationGroupId,
              iStartDate: periodStartDate,
              iEndDate: periodEndDate,
            },
          })
        } else if (selectedDirctorEmployeeId <= -2) {
          // breakdownByDirectors
          getGlobalDeliveryKpi({
            variables: {
              iLocationGroupId: selectedLocationGroupId,
              iStartDate: periodStartDate,
              iEndDate: periodEndDate,
            },
          })
        } else {
          getGlobalDeliveryKpi({
            variables: {
              iLocationGroupId: selectedLocationGroupId,
              iStartDate: periodStartDate,
              iEndDate: periodEndDate,
            },
          })
        }
      }
    }

    fetchData()
  }, [selectedDirector, breakdownType, dateRange])

  useEffect(() => {
    if (navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl)
      feature.used('Corporate Delivery')
    }
  }, [navParams.pageUrl])

  const onDirectorChange = async (directorEmployeeId: number) => {
    let processedDirectorEmployeeId = 0

    track.directorChanged(directorEmployeeId)

    if (directorEmployeeId === -1) {
      // The all stores case

      setSelectedDirector({
        ...DEFAULT_DIRECTOR,
        employeeId: -1,
      })

      setBreakdownType(null)
    } else if (directorEmployeeId === -2) {
      // Case: breakdown by directors

      setSelectedDirector({
        ...DEFAULT_DIRECTOR,
        employeeId: -2,
      })

      setBreakdownType(null)
    } else if (directorEmployeeId < -2) {
      const locationGroupType =
        directorFilterSelectorConfig.locationGroupTypes.find(
          ({ id }: { id: number }) => id === directorEmployeeId,
        )

      setSelectedDirector({
        ...DEFAULT_DIRECTOR,
        employeeId: directorEmployeeId,
      })

      setBreakdownType(locationGroupType?.name || '')
    } else {
      processedDirectorEmployeeId = directorEmployeeId
      const selectedDirector = _.first(
        _.filter(allDirectors, { employeeId: processedDirectorEmployeeId }),
      )
      setSelectedDirector(selectedDirector || null)
      setBreakdownType(null)
    }
  }

  let globalDeliveryKpiSummary: IGlobalDeliveryKpi = {
    totalOrderCount: '-',
    avgDasherWaitTime: '-',
    sumSubtotal: '-',
    sumErrorCharges: '-',
    orderWithAnyIssuePercent: '-',
    avgCustomerReviewScore: '-',
    cancelledOrderPercent: '-',
    delayedOrderPercent: '-',
    inaccurateOrderPercent: '-',
    averageTripTime: '-',
  }

  if (
    getGlobalDeliveryKpiData &&
    getGlobalDeliveryKpiData.getGlobalDeliveryKpi &&
    Array.isArray(getGlobalDeliveryKpiData.getGlobalDeliveryKpi.nodes)
  ) {
    const rawGlobalDeliveryKpi = _.first(
      _.get(getGlobalDeliveryKpiData, 'getGlobalDeliveryKpi.nodes', []),
    )

    const avgCustomerReviewScore = _.get(
      rawGlobalDeliveryKpi,
      'avgCustomerReviewScore',
      0,
    )
    const avgDasherWaitTime = _.get(
      rawGlobalDeliveryKpi,
      'avgDasherWaitTime',
      0,
    )

    globalDeliveryKpiSummary = {
      totalOrderCount: _.get(
        rawGlobalDeliveryKpi,
        'totalOrderCount',
        '',
      )?.toLocaleString('en-US'),
      avgDasherWaitTime: toDisplayedWaitTime(avgDasherWaitTime),
      sumSubtotal: toUsdString(
        _.get(rawGlobalDeliveryKpi, 'sumSubtotal', 0) / 100,
      ),
      sumErrorCharges: toUsdString(
        _.get(rawGlobalDeliveryKpi, 'sumErrorCharges', 0) / 100,
      ),
      orderWithAnyIssuePercent: toPercentageString(
        _.get(rawGlobalDeliveryKpi, 'orderWithAnyIssuePercent', 0) / 100,
        1,
      ),
      avgCustomerReviewScore: avgCustomerReviewScore
        ? avgCustomerReviewScore.toFixed(1)
        : '-',
      cancelledOrderPercent: toPercentageString(
        _.get(rawGlobalDeliveryKpi, 'cancelledOrderPercent', 0) / 100,
        1,
      ),
      delayedOrderPercent: toPercentageString(
        _.get(rawGlobalDeliveryKpi, 'delayedOrderPercent', 0) / 100,
        1,
      ),
      inaccurateOrderPercent: toPercentageString(
        _.get(rawGlobalDeliveryKpi, 'inaccurateOrderPercent', 0) / 100,
        1,
      ),
      averageTripTime: `${(
        _.get(rawGlobalDeliveryKpi, 'averageTripTime', 0) / 60
      ).toFixed(1)} min`,
    }

    if (getGlobalDeliveryKpiData.customizedSummaryDelivery) {
      const customizedData =
        getGlobalDeliveryKpiData?.customizedSummaryDelivery.nodes[0] || {}

      Object.keys(customizedData).forEach((key) => {
        switch (key) {
          case 'totalOrderCount':
            globalDeliveryKpiSummary[key] =
              customizedData[key]?.toLocaleString('en-US')
            break

          case 'sumSubtotal':
          case 'completedDisputeAmount':
            globalDeliveryKpiSummary[key] = toUsdString(
              customizedData[key] / 100,
            )
            break

          default:
            globalDeliveryKpiSummary[key] = customizedData[key]
            break
        }
      })
    }
  }

  if (getGlobalDeliveryKpiLoading) {
    globalDeliveryKpiSummary = {
      totalOrderCount: 'loading',
      avgDasherWaitTime: 'loading',
      sumSubtotal: 'loading',
      sumErrorCharges: 'loading',
      orderWithAnyIssuePercent: 'loading',
      avgCustomerReviewScore: 'loading',
      cancelledOrderPercent: 'loading',
      delayedOrderPercent: 'loading',
      inaccurateOrderPercent: 'loading',
      averageTripTime: 'loading',
    }
  }

  return (
    <>
      <Main
        navParams={navParams}
        allDirectors={allDirectors || []}
        selectedDirector={selectedDirector}
        onDirectorChange={onDirectorChange}
        selectedDateRange={selectedDateRange}
        globalDeliveryKpiSummary={globalDeliveryKpiSummary}
        selectedLocationGroupId={selectedLocationGroupId}
        breakdownType={breakdownType}
      />
    </>
  )
}

export default CorporateDelivery
