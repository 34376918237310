import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import { ITurnoverType } from './constants'

interface IProps {
  chartOptions: any
  xAxisData: string[]
  yAxisDataArr: number[][]
  isLoading: boolean
  type: ITurnoverType
  setType: (type: ITurnoverType) => void
}

function Main({
  chartOptions,
  xAxisData,
  yAxisDataArr,
  isLoading,
  type,
  setType,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          isLoading={isLoading}
          xAxisData={xAxisData}
          chartOptions={chartOptions}
          yAxisDataArr={yAxisDataArr}
          type={type}
          setType={setType}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          isLoading={isLoading}
          xAxisData={xAxisData}
          chartOptions={chartOptions}
          yAxisDataArr={yAxisDataArr}
          type={type}
          setType={setType}
        />
      </SmallScreen>
    </>
  )
}

export default Main
