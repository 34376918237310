import { gql, useMutation } from '@apollo/client'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { getUser } from 'pared/utils/user'

import { useReset } from '../../../ResetProvider'

export interface IEditFeedbackType {
  performance?: string
  feedback?: string
  locationId: number
  employeeId: number
  updateUuid: string
}

export interface IFormType
  extends Pick<IEditFeedbackType, 'performance' | 'feedback'> {
  plans: {
    key: number
    name: string
    date?: Date
    errors: string[]
  }[]
  performanceErrors: string[]
  feedbackErrors: string[]
}

interface IInputFeedbackType {
  reviewer_id: number
  reviewee_id: number
  location_id: number
  feedback?: string
  performance?: string
  update_uuid: string
}

interface IInputPlanType {
  location_id: number
  employee_id: number
  action_item: string
  update_uuid: string
  dueDate?: Date
}

type IActionType =
  | {
      type: 'performance'
      value: NonNullable<IFormType['performance']>
    }
  | {
      type: 'feedback'
      value: NonNullable<IFormType['feedback']>
    }
  | {
      type: 'plan-name'
      index: number
      value: Required<IFormType['plans'][number]>['name']
    }
  | {
      type: 'plan-date'
      index: number
      value: Required<IFormType['plans'][number]>['date']
    }
  | {
      type: 'plan-add'
    }
  | {
      type: 'plan-remove'
      index: number
    }

const validatePlan = (data: IFormType['plans'][number]) => {
  const errors = []

  if (data.name && !data.date) {
    errors.push('Please choose a dute date')
  } else if (data.date && !data.name) {
    errors.push('Please fill in the item name')
  } else if (!data.name && !data.date) {
    errors.push(
      'Please delete it or fill in the item name and choose a due date',
    )
  }

  return {
    ...data,
    errors,
  }
}

const validatePerformance = (
  data: IFormType['performance'],
): {
  performance: string
  performanceErrors: string[]
} => {
  const errors = []

  if (!data) errors.push('Please select Rating')

  return {
    performance: data || '',
    performanceErrors: errors,
  }
}

const validateFeedback = (
  data: IFormType['feedback'],
): {
  feedback: string
  feedbackErrors: string[]
} => {
  const errors = []

  if (!data) errors.push('Please provide Feedback')

  return {
    feedback: data || '',
    feedbackErrors: errors,
  }
}

const reducer = (state: IFormType, action: IActionType) => {
  switch (action.type) {
    case 'performance':
      const performance = action.value

      return { ...state, ...validatePerformance(performance) }

    case 'feedback':
      const feedback = action.value

      return { ...state, ...validateFeedback(feedback) }

    case 'plan-name': {
      const plans = [...state.plans]

      plans[action.index].name = action.value
      plans[action.index] = validatePlan(plans[action.index])

      return { ...state, plans }
    }

    case 'plan-date': {
      const plans = [...state.plans]

      plans[action.index].date = action.value
      plans[action.index] = validatePlan(plans[action.index])

      return { ...state, plans }
    }

    case 'plan-add':
      return {
        ...state,
        plans: [
          ...state.plans,
          validatePlan({
            key: state.plans[state.plans.length - 1].key + 1,
            name: '',
            errors: [],
          }),
        ],
      }

    case 'plan-remove': {
      const plans = [...state.plans]

      plans.splice(action.index, 1)

      return { ...state, plans }
    }

    default:
      return state
  }
}

const UPDATE_DEVELOPMENT_FEEDBACK = gql`
  mutation CreateDevelopmentFeedback($iInput: JSON!) {
    createDevelopmentFeedback(input: { iInput: $iInput }) {
      clientMutationId
    }
  }
`

// TODO: use form library
const useEditFeedback = (data: IEditFeedbackType, onClose: () => void) => {
  const { locationId, employeeId, performance, feedback, updateUuid } = data
  const reviewer = getUser()
  const reviewerId = reviewer.employeeId ? reviewer.employeeId : -1

  const [developmentFeedback] = useMutation(UPDATE_DEVELOPMENT_FEEDBACK)
  const reset = useReset()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useReducer(reducer, {
    ...validatePerformance(performance),
    ...validateFeedback(feedback),
    plans: [validatePlan({ key: 0, name: '', errors: [] })],
  })
  const hasErrors = useMemo(() => {
    if (
      form.performanceErrors.length !== 0 ||
      form.feedbackErrors.length !== 0
    ) {
      return true
    }

    if (form.plans.length === 1) {
      return Boolean(
        (form.plans[0].name && !form.plans[0].date) ||
          (!form.plans[0].name && form.plans[0].date),
      )
    } else {
      return form.plans.some((p) => p.errors.length !== 0)
    }
  }, [isSubmitting, form])
  const submit = useCallback(async () => {
    setIsSubmitting(true)
    setIsLoading(true)

    if (hasErrors) {
      setIsLoading(false)
      return
    }

    const feedbackUuid = updateUuid ? updateUuid : uuidv4()

    const inputPlan: IInputPlanType[] = form.plans
      .filter((p) => p.date)
      .map((plan) => {
        return {
          location_id: locationId,
          employee_id: employeeId,
          action_item: plan.name,
          update_uuid: feedbackUuid,
          due_date: plan.date,
        }
      })

    const inputFeedback: IInputFeedbackType = {
      reviewer_id: reviewerId,
      reviewee_id: employeeId,
      location_id: locationId,
      feedback: form.feedback,
      performance: form.performance,
      update_uuid: feedbackUuid,
    }

    await developmentFeedback({
      variables: {
        iInput: {
          feedback: inputFeedback,
          plan: inputPlan,
        },
      },
    })
    reset()
  }, [locationId, employeeId, reset, form, hasErrors])

  useEffect(() => {
    if (isLoading) onClose()
  }, [data])

  return {
    form,
    setForm,
    submit,
    isLoading,
    hasErrors: isSubmitting && hasErrors,
  }
}

export default useEditFeedback
