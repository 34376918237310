import Main from './Main'
import { INavParams } from './navParams'

interface IProps {
  hasUserSignedIn: boolean
  navParams: INavParams
  accessibleBrands: number[]
}

function Routes({ hasUserSignedIn, navParams, accessibleBrands }: IProps) {
  return (
    <Main
      hasUserSignedIn={hasUserSignedIn}
      navParams={navParams}
      accessibleBrands={accessibleBrands}
    />
  )
}

export default Routes
