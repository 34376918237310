import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { INullableType } from 'pared/types'

import {
  IOpsCompsNodeType,
  IOpsCompsType,
  IOpsCompsVariablesType,
  OPS_COMPS,
} from '../gqls'

export interface IOpsCompsDataType extends IOpsCompsNodeType {
  rank: number
}

export default (
  variables: INullableType<IOpsCompsVariablesType>,
): IOpsCompsDataType[] => {
  const { data } = useQuery<
    IOpsCompsType,
    INullableType<IOpsCompsVariablesType>
  >(OPS_COMPS, {
    variables,
    skip:
      !variables?.iLocationId || !variables?.iStartDate || !variables?.iEndDate,
  })

  return useMemo(
    () =>
      data?.listLocationOpsCompsBreakdownByReason.nodes.map((node, index) => ({
        ...node,
        rank: index + 1,
      })) || [],
    [data],
  )
}
