import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings, getBusinessLabel } from 'pared/customer'

import useLocationInfo from './hooks/useLocationInfo'

const LocationInfoDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;

  @media ${MOBILE_WIDTH} {
    font-size: 16px;
    & ~ & {
      margin-top: 10px;
    }
  }
`

interface IProps {
  locationId: number
}

const LocationInfo = ({ locationId }: IProps) => {
  const brandSettings = getBrandSettings()
  const locationInfo = useLocationInfo(locationId)
  if (!locationInfo) {
    return null
  }

  const { doEmployeeId, doName, gmEmployeeId, gmName, locationGroups } =
    locationInfo

  return (
    <>
      {brandSettings.labels.business.showGm ? (
        <LocationInfoDiv>
          {'GM: '}
          {brandSettings.labels.business.isGmClickable ? (
            <Link to={navigator.employeeProfile(gmEmployeeId || '')}>
              {gmName}
            </Link>
          ) : (
            gmName
          )}
        </LocationInfoDiv>
      ) : null}
      <LocationInfoDiv>
        {getBusinessLabel('director')}
        {': '}
        {brandSettings.labels.business.isDirectorClickable ? (
          <Link to={navigator.employeeProfile(doEmployeeId || '')}>
            {doName}
          </Link>
        ) : (
          doName
        )}
      </LocationInfoDiv>
      {brandSettings.locationGroups.map((type) => {
        const locationGroup = locationGroups?.find(
          (group) => group.type === type,
        )
        if (!locationGroup) {
          return null
        }

        return (
          <LocationInfoDiv key={locationGroup.name}>
            {type}
            {': '}
            {locationGroup.name}
          </LocationInfoDiv>
        )
      })}
    </>
  )
}

export default LocationInfo
