import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import parseSummary from 'pared/pages/Calls/Summary/parseSummary'
import { toUsdStr } from 'pared/utils/number'

import { IApiDataType, IExrayDataType } from '../../types'

interface ILocationEmployeeCallsNodeType {
  locationId: number
  employeeName: string
  employeeRole: string
  inboundTotal: number
  percentAnswered: number
  inboundMissedAndAbandoned: number
  percentMissedAndAbandoned: number
  inboundMissed: number
  percentMissed: number
  inboundAbandoned: number
  percentAbandoned: number
}

interface ILocationInboundCallsNodeType {
  locationId: number
  inboundTotal: number
  inboundAnswered: number
  percentAnswered: number
  inboundMissedAndAbandoned: number
  percentMissedAndAbandoned: number
  inboundMissed: number
  percentMissed: number
  inboundAbandoned: number
  percentAbandoned: number
  missedOpportunity: string
  annualOpportunityCost: string
  brandInboundTotal: number
  brandInboundAnswered: number
  brandPercentAnswered: number
  brandInboundMissedAndAbandoned: number
  brandPercentMissedAndAbandoned: number
  brandInboundMissed: number
  brandPercentMissed: number
  brandInboundAbandoned: number
  brandPercentAbandoned: number
}

interface ICallSummaryType {
  listLocationEmployeeCalls: {
    nodes: ILocationEmployeeCallsNodeType[]
  }

  listLocationInboundCalls: {
    nodes: ILocationInboundCallsNodeType[]
  }
}

const query = gql`
  query ListLocationInboundCalls(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationInboundCalls(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        inboundTotal
        inboundAnswered
        percentAnswered
        inboundMissedAndAbandoned
        percentMissedAndAbandoned
        inboundMissed
        percentMissed
        inboundAbandoned
        percentAbandoned
        missedOpportunity
        annualOpportunityCost
        brandInboundTotal
        brandInboundAnswered
        brandPercentAnswered
        brandInboundMissedAndAbandoned
        brandPercentMissedAndAbandoned
        brandInboundMissed
        brandPercentMissed
        brandInboundAbandoned
        brandPercentAbandoned
      }
    }

    listLocationEmployeeCalls(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        employeeName
        employeeRole
        inboundTotal
        percentAnswered
        inboundMissedAndAbandoned
        percentMissedAndAbandoned
        inboundMissed
        percentMissed
        inboundAbandoned
        percentAbandoned
      }
    }
  }
`

const getCallSummaryData = (
  locationName: string,
  data: ICallSummaryType,
): IExrayDataType => {
  const callsAction = {
    summary: '',
    annualOpportunityCost: 0,
  }

  if (locationName) {
    const summary = parseSummary(data, locationName)
    callsAction.summary = `
      ${summary.summaryMessage}
      ${
        summary.annualOpportunityCost
          ? `This projects to an annual opportunity cost of ${toUsdStr(
              summary.annualOpportunityCost,
            )}.`
          : ''
      }
      ${summary.areasOfImprovementMessages.join(' ')}
    `
    callsAction.annualOpportunityCost = summary.annualOpportunityCost
  }

  return {
    title: 'Calls',
    total: callsAction.annualOpportunityCost,
    detail: callsAction.summary,
  }
}

const useCalls = (): IApiDataType => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'
  const { data, loading } = useQuery<ICallSummaryType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_ids: [locationId],
      },
    },
    skip: !groupFilter,
  })

  return {
    data: useMemo(() => {
      if (!data) return null

      return getCallSummaryData(locationName, data)
    }, [locationName, data]),
    loading,
  }
}

export default useCalls
