import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import { IDirector } from 'pared/data/getDirectors'

import { IDetailTeamKpi } from '../index'
import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import { IOrderBy } from './index'

interface IProps {
  itemizedKpis: IDetailTeamKpi[]
  isTableExpanded: boolean
  onToggleExpansion: () => void
  orderBy: IOrderBy
  setOrderBy: any
  selectedDirector: IDirector | null
  breakdownType: string | null
}

function Main({
  itemizedKpis,
  isTableExpanded,
  onToggleExpansion,
  orderBy,
  setOrderBy,
  selectedDirector,
  breakdownType,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          itemizedKpis={itemizedKpis}
          isTableExpanded={isTableExpanded}
          onToggleExpansion={onToggleExpansion}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          selectedDirector={selectedDirector}
          breakdownType={breakdownType}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          itemizedKpis={itemizedKpis}
          isTableExpanded={isTableExpanded}
          onToggleExpansion={onToggleExpansion}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          selectedDirector={selectedDirector}
          breakdownType={breakdownType}
        />
      </SmallScreen>
    </>
  )
}

export default Main
