import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiDataType } from '../../types'

interface IDataType {
  listLocationGroupEmployeeMetricValues: {
    nodes: {
      metricData: Record<
        string,
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
      endDate: string
      employeeLocationGroupId: number
      employeeId: string
      employeeNumber: string
      employeeName: string
    }[]
  }
  listEmployeeMetricValues: {
    nodes: {
      metricData: Record<
        string,
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
      endDate: string
      metricLocationId: string
      employeeId: string
      employeeNumber: string
      employeeName: string
    }[]
  }
  trendLocationGroupEmployeeMetricValues: {
    nodes: {
      metricData: Record<
        string,
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
      endDate: string
      employeeLocationGroupId: number
      employeeId: string
    }[]
  }
  trendLocationEmployeeMetricValues: {
    nodes: {
      metricData: Record<
        string,
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
      endDate: string
      metricLocationId: string
      employeeId: string
    }[]
  }
}

const query = gql`
  query ListLocationGroupEmployeeMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    listLocationGroupEmployeeMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        endDate
        employeeLocationGroupId
        employeeId
        employeeName
        employeeNumber
        metricData
      }
    }

    listEmployeeMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        endDate
        metricLocationId
        employeeId
        employeeName
        employeeNumber
        metricData
      }
    }
  }
`

const queryForTrend = gql`
  query TrendLocationGroupEmployeeMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    trendLocationGroupEmployeeMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "last_x_days"
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        employeeId
        employeeLocationGroupId
        endDate
        metricData
      }
    }

    trendLocationEmployeeMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "last_x_days"
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        employeeId
        metricLocationId
        endDate
        metricData
      }
    }
  }
`

const METRIC_CODES = [
  'hours_worked',
  'scheduled_hours_remaining',
  'total_hours',
  'meal_break_violations',
]

export const fwWingstopTimeClockConfigs = {
  locationName: 'string',
  employeeNumber: 'string',
  hoursWorked: 'number',
  scheduledHoursRemaining: 'number',
  totalHoursOverThirtyFive: 'number',
  totalHoursOverForty: 'number',
  mealBreakViolations: 'number',
  timeClockDetails: 'string',
} as const

const useTimeClock = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: groupFilter?.ids,
        intersected_location_group_ids: groupFilter?.intersectedIds,
        metrics: METRIC_CODES,
      },
      hasGroupBy,
    },
    skip: !startDate || !endDate || !groupFilter,
  })
  const { data: trendData, loading: trendLoading } = useQuery<IDataType>(
    queryForTrend,
    {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: {
          location_group_ids: groupFilter?.ids,
          intersected_location_group_ids: groupFilter?.intersectedIds,
          metrics: ['meal_break_violations'],
        },
        hasGroupBy,
      },
      skip: !startDate || !endDate || !groupFilter,
    },
  )

  return {
    data: useMemo((): IApiDataType => {
      const employeeMetricData =
        data?.listEmployeeMetricValues.nodes ||
        data?.listLocationGroupEmployeeMetricValues.nodes

      const employeeTrendMetricData =
        trendData?.trendLocationGroupEmployeeMetricValues ||
        trendData?.trendLocationEmployeeMetricValues

      if (!employeeMetricData || !employeeTrendMetricData) return null

      return employeeMetricData?.reduce((result, row) => {
        const groupId = hasGroupBy
          ? row.metricLocationId
          : row.employeeLocationGroupId
        const group = groupFilter?.list?.find((l) => l.id === groupId)?.name
        if (!group) {
          return result
        }

        const existGroup = result?.find((r) => r.id === group)
        const rowId = `${row.employeeId}-${row.employeeName}`

        const totalHours = row.metricData.total_hours.value
        const timeClockDetails = (() => {
          const employeeTrendData = employeeTrendMetricData.nodes.filter(
            (n) => n.employeeId === row.employeeId,
          )

          return (
            employeeTrendData
              .reduce(
                (sum, td) =>
                  td.metricData['meal_break_violations']?.value > 0
                    ? [
                        ...sum,
                        `${moment
                          .utc(td.endDate, 'YYYY-MM-DD')
                          .format('M/D/YY')} (${
                          td.metricData['meal_break_violations']?.value
                        })`,
                      ]
                    : sum,
                [] as string[],
              )
              ?.join(', ') || '-'
          )
        })()
        const metricData = {
          id: rowId,
          parentId: group,
          locationName: group,
          employeeNumber: `${row.employeeNumber} - ${row.employeeName}`,
          totalHoursOverThirtyFive:
            totalHours > 35 && totalHours <= 40 ? totalHours : null,
          totalHoursOverForty: totalHours > 40 ? totalHours : null,
          timeClockDetails,
          ...Object.keys(row.metricData).reduce((r, key) => {
            const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
            return {
              ...r,
              [newKey]: row.metricData[key]?.value,
            }
          }, {}),
        }

        if (!existGroup) {
          const sumCondition = (data: any) => {
            if (hasGroupBy) return data.metricLocationId === groupId
            else return data.employeeLocationGroupId === groupId
          }

          return [
            ...result,
            {
              id: group,
              parentId: 'root',
              employeeNumber: group,
              hoursWorked: employeeMetricData?.reduce(
                (sum, d) =>
                  sumCondition(d)
                    ? sum + (d.metricData['hours_worked']?.value || 0)
                    : sum,
                0,
              ),
              scheduledHoursRemaining: employeeMetricData?.reduce(
                (sum, d) =>
                  sumCondition(d)
                    ? sum +
                      (d.metricData['scheduled_hours_remaining']?.value || 0)
                    : sum,
                0,
              ),
              totalHoursOverThirtyFive: employeeMetricData?.reduce((sum, d) => {
                if (sumCondition(d)) {
                  const hours = d.metricData['total_hours']?.value
                  if (hours > 35 && hours <= 40) return sum + hours
                  else return sum
                } else {
                  return sum
                }
              }, 0),
              totalHoursOverForty: employeeMetricData?.reduce((sum, d) => {
                if (sumCondition(d)) {
                  const hours = d.metricData['total_hours']?.value
                  if (hours > 40) return sum + hours
                  else return sum
                } else {
                  return sum
                }
              }, 0),
              mealBreakViolations: employeeMetricData?.reduce(
                (sum, d) =>
                  sumCondition(d)
                    ? sum + (d.metricData['meal_break_violations']?.value || 0)
                    : sum,
                0,
              ),
            },
            metricData,
          ]
        } else {
          return [...result, metricData]
        }
      }, [])
    }, [data, trendData, hasGroupBy]),
    loading: loading || trendLoading,
  }
}

export default useTimeClock
