import moment from 'moment'
import { useMemo } from 'react'

const getRandomNumber = (data: number) => Math.floor(Math.random() * data + 1)

const useMockData = () =>
  useMemo(() => {
    const firstDay = moment().startOf('weeks')
    const yesterday = moment().subtract(1, 'days')

    return [...new Array(21)].map((_, index) => {
      const date = firstDay.clone().add(index, 'days')
      const dateStr = date.format('YYYY-MM-DD')

      if (date.isBefore(yesterday)) return { date: dateStr }

      return {
        date: dateStr,
        targetCost: getRandomNumber(300),
        labor: [
          {
            role: 'Cashier',
            time: getRandomNumber(10),
            scheduledCost: getRandomNumber(100),
          },
          {
            role: 'Prep',
            time: getRandomNumber(10),
            scheduledCost: getRandomNumber(100),
          },
          {
            role: 'Customer Service',
            time: getRandomNumber(14),
            scheduledCost: getRandomNumber(125),
          },
          {
            role: 'Grill Cook',
            time: getRandomNumber(10),
            scheduledCost: getRandomNumber(100),
          },
          {
            role: 'Digital Ordering',
            time: getRandomNumber(12),
            scheduledCost: getRandomNumber(100),
          },
        ],
      }
    })
  }, [])

export default useMockData
