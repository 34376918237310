import { Link as ReactLink } from 'react-router-dom'
import styled from 'styled-components'

export interface IPropsType {
  label: string
  url: string
  disabled?: boolean
}

const DisabledText = styled.div`
  font-size: 14px;
`

export const sorter = (a: IPropsType, b: IPropsType) =>
  b.label.localeCompare(a.label)

const Link = ({ label, url, disabled }: IPropsType) =>
  disabled ? (
    <DisabledText>{label}</DisabledText>
  ) : (
    <ReactLink to={url}>{label}</ReactLink>
  )

export default Link
