import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { getBrandDefaultCalendarStartDate } from 'pared/utils/brand'

const queries = gql`
  query GetPeriods($minYear: Int!, $queryDate: Datetime!) {
    listBusinessMonths(minYear: $minYear, maxYear: ${moment()
      .add(1, 'years')
      .format('YYYY')}) {
      nodes {
        year: businessYear
        period: businessMonth
        periodStart: businessMonthBeginDate
        periodEnd: businessMonthEndDate
        quarter: businessQuarter
      }
    }

    calendarByDate(date: $queryDate) {
      businessMonth
      businessYear
    }
  }
`

const CUSTOM_START_DATE = '01/01/2019'

const useGetPeriod = () => {
  const currentTime = moment()
  const customStartDate =
    getBrandDefaultCalendarStartDate() || CUSTOM_START_DATE

  const { data, loading } = useQuery(queries, {
    variables: {
      minYear: moment.utc(customStartDate, 'MM/DD/YYYY', true).year(),
      queryDate: currentTime.format('YYYY-MM-DD'),
    },
    skip: !customStartDate,
  })

  return {
    data: useMemo((): string[] | null => {
      const currentPeriod = data?.calendarByDate.businessMonth || 0
      const currentYear = data?.calendarByDate.businessYear || 0
      return data?.listBusinessMonths.nodes.reduce((result: any, date: any) => {
        if (date.year < currentYear) {
          return [...result, `${date.year} - P${date.period}`]
        }

        if (date.year === currentYear && date.period <= currentPeriod) {
          return [...result, `${date.year} - P${date.period}`]
        }

        return result
      }, [] as string[])
    }, [data]),
    loading,
  }
}

export default useGetPeriod
