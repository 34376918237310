import { useQuery } from '@apollo/client'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { INullableType } from 'pared/types'
import { getBrand } from 'pared/utils/brand'

import {
  ICorporateSalesmanshipLocations,
  ISalesSummaryNodeType,
  ISalesSummaryType,
  ISalesSummaryVariablesType,
  getCorporateSalesmanshipSummary,
} from '../gqls/sales'

interface ISalesActionType {
  isBetterThanCompany: boolean
  menuCategory: string
  annualOpportunityCost: number
  basisPoint: string
}

interface IReturnType {
  loading: boolean
  action: ISalesActionType
}

interface ILocationSalesmanshipDataType
  extends ICorporateSalesmanshipLocations {
  menuCategory: string
}

export default (
  startDate: string,
  endDate: string,
  locationId: number,
): IReturnType => {
  const action = {
    isBetterThanCompany: false,
    menuCategory: '',
    annualOpportunityCost: 0,
    basisPoint: '0',
  }
  const brand = getBrand()
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  const variables: INullableType<ISalesSummaryVariablesType> = {
    iLocationGroupId: brandLocationGroupId,
    iBrandLocationGroupId: brandLocationGroupId,
    iStartDate: startDate,
    iEndDate: endDate,
  }

  const { data, loading } = useQuery<ISalesSummaryType>(
    getCorporateSalesmanshipSummary,
    {
      variables,
    },
  )

  const dataSource = data?.corporateSalesmanshipSummaryV2?.nodes

  const locationData =
    dataSource?.reduce(
      (
        result: ILocationSalesmanshipDataType[],
        salesData: ISalesSummaryNodeType,
      ) => {
        const location = salesData?.locations?.filter(
          (l) => parseInt(l.id) === locationId,
        )?.[0]
        if (location)
          return [{ menuCategory: salesData.area, ...location }, ...result]
        else return [...result]
      },
      [] as ILocationSalesmanshipDataType[],
    ) || []

  if (locationData.length === 0) {
    action.isBetterThanCompany = true
  } else {
    const cost = Math.max(
      ...locationData?.map(
        (l: ILocationSalesmanshipDataType) => l.annualOpportunityCost,
      ),
    )

    action.annualOpportunityCost = cost / 100

    if (dataSource) {
      action.basisPoint = (
        (10000.0 * cost) /
        dataSource?.[0]?.annualTotalNetSales
      ).toFixed(0)
    }

    const topOpportunity = locationData?.filter(
      (l: ILocationSalesmanshipDataType) => l.annualOpportunityCost === cost,
    )?.[0]

    action.menuCategory = topOpportunity?.menuCategory
  }

  return {
    loading,
    action,
  }
}
