import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import { IOpsCompsByReason } from '../index'
import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'

interface IProps {
  opsCompsByReasons: IOpsCompsByReason[]
}

function Main({ opsCompsByReasons }: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain opsCompsByReasons={opsCompsByReasons} />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain opsCompsByReasons={opsCompsByReasons} />
      </SmallScreen>
    </>
  )
}

export default Main
