import { gql } from '@apollo/client'

export interface IDirectorInfo {
  id: number
  given_name: string
  family_name: string
  preferred_name: string
}

export interface ILocationInfo {
  id: number
  code: string
  name: string
  director: IDirectorInfo
}

export interface IDailyDashboardTableNodesType {
  startDate: string
  endDate: string
  locationId: number
  locationInfo: ILocationInfo
  yesterdayHourlyLaborCost: number
  yesterdaySales: number
  yesterdayCashOverShort: number
  mtdHourlyLaborCost: number
  mtdSales: number
  mtdCashOverShort: number
  yesterdayDriveThruTotalCount: string
  yesterdayDriveThruTotalSeconds: string
  mtdAverageTotalCount: string
  mtdAverageTotalSeconds: string
  yesterdayPickupTotalCount: string
  yesterdayPickupTotalSeconds: string
  mtdPickupTotalCount: string
  mtdPickupTotalSeconds: string
  mtdTotalTraffic: string
  mtdTotalB3sValue: string
  revScore: string
}

export interface IDailyDashboardTableType {
  listLocationGroupDailyDashboardTable: {
    nodes: IDailyDashboardTableNodesType[]
  }
}

export interface IDailyDashboardTableVariablesType {
  iEndDate: string
  iFilter: any
}

export const listLocationGroupDailyDashboardTable = gql`
  query ListLocationGroupDailyDashboardTable(
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupDailyDashboardTable(
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        businessMonthBeginDate
        date
        yesterday
        locationId
        locationInfo
        yesterdayHourlyLaborCost
        yesterdaySales
        yesterdayCashOverShort
        mtdHourlyLaborCost
        mtdSales
        mtdCashOverShort
        yesterdayDriveThruTotalCount
        yesterdayDriveThruTotalSeconds
        mtdAverageTotalCount
        mtdAverageTotalSeconds
        yesterdayPickupTotalCount
        yesterdayPickupTotalSeconds
        mtdPickupTotalCount
        mtdPickupTotalSeconds
        mtdTotalTraffic
        mtdTotalB3sValue
        revScore
      }
    }
  }
`
