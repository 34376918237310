import { RAW_LOCATION_GROUP } from './locationGroup'

interface IRawData {
  date: string
  avgHourlyRate: number
}

export const RAW_LOCATION_AVG_HOURLY_RATES = [
  2, 3, 4, 6, 8, 9, 10, 11, 16, 18, 21, 24, 29, 30, 32, 35, 36, 40, 41, 43, 45,
  47, 48, 51, 54, 55, 61, 62, 64, 65, 67, 68, 71, 72, 76,
].reduce(
  (result, key) => ({
    ...result,
    [key]: ['06/21', '06/22', '06/23', '06/24', '06/25', '06/26', '06/27'].map(
      (date) => ({
        date,
        avgHourlyRate: Math.random() * 5 + 10,
      }),
    ),
  }),
  {} as { [key: string]: IRawData[] },
)

export const RAW_LIST_LOCATION_AVG_HOURLY_RATES = Object.keys(
  RAW_LOCATION_AVG_HOURLY_RATES,
).reduce((result, key) => {
  const sumHourlyRate = RAW_LOCATION_AVG_HOURLY_RATES[key].reduce(
    (subResult, { avgHourlyRate }) => subResult + avgHourlyRate,
    0,
  )

  return {
    ...result,
    [key]: {
      avgHourlyRate: sumHourlyRate / RAW_LOCATION_AVG_HOURLY_RATES[key].length,
    },
  }
}, {} as { [key: string]: Pick<IRawData, 'avgHourlyRate'> })

export const RAW_LIST_LOCATION_GROUP_AVG_HOURLY_RATES = (
  Object.keys(
    RAW_LOCATION_GROUP,
  ) as unknown as (keyof typeof RAW_LOCATION_GROUP)[]
).reduce((result, key) => {
  const sumHourlyRate = RAW_LOCATION_GROUP[key].reduce(
    (subResult: number, locationId: number) =>
      subResult +
        RAW_LIST_LOCATION_AVG_HOURLY_RATES[locationId]?.avgHourlyRate || 0,
    0,
  )
  const amount = RAW_LOCATION_GROUP[key].filter(
    (locationId) => RAW_LIST_LOCATION_AVG_HOURLY_RATES[locationId],
  ).length

  return {
    ...result,
    [key]: {
      avgHourlyRate: sumHourlyRate / amount,
    },
  }
}, {} as { [key: string]: Pick<IRawData, 'avgHourlyRate'> })

export const RAW_AVG_HOURLY_RATES = [-1, 7, 10, 34, 35, 36, 38].reduce(
  (result, key) => ({
    ...result,
    [key]: ['06/21', '06/22', '06/23', '06/24', '06/25', '06/26', '06/27'].map(
      (date) => ({
        date,
        avgHourlyRate: Math.random() * 5 + 10,
      }),
    ),
  }),
  {} as { [key: string]: IRawData[] },
)
