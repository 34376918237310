import { toUsdStr } from 'pared/utils/number'

import { IListLocationInboundCalls } from './gql'

const parseSummary = (
  data: IListLocationInboundCalls,
  locationName: string,
) => {
  const inboundCallsData = data?.listLocationInboundCalls?.nodes?.[0]
  const employeeCallsData = data?.listLocationEmployeeCalls?.nodes

  const parsedSummary: {
    summaryMessage: string | React.ReactElement
    areasOfImprovementMessages: (string | React.ReactElement)[]
    annualOpportunityCost: number
  } = {
    summaryMessage: '',
    areasOfImprovementMessages: [],
    annualOpportunityCost: 0,
  }

  if (inboundCallsData?.inboundTotal) {
    const {
      percentAnswered,
      brandPercentAnswered,
      missedOpportunity,
      annualOpportunityCost,
    } = inboundCallsData

    if (percentAnswered > brandPercentAnswered) {
      parsedSummary.summaryMessage = `Great job! ${locationName} is answering ${percentAnswered.toFixed(
        1,
      )}% of calls which is higher than company averages for the time period selected.`
    } else {
      parsedSummary.summaryMessage = `Anyone home? ${locationName} is performing worse than company averages and only answering ${percentAnswered.toFixed(
        1,
      )}% of calls.`
    }

    if (missedOpportunity) {
      const missedOpportunityDollars = toUsdStr(
        parseInt(missedOpportunity) / 100,
      )
      parsedSummary.summaryMessage += ` Missed calls are a ${missedOpportunityDollars} opportunity for the time period selected.`
    }

    if (annualOpportunityCost) {
      parsedSummary.annualOpportunityCost =
        parseInt(annualOpportunityCost) / 100
    }
  } else {
    parsedSummary.summaryMessage = 'No calls in this time period.'
  }

  if (Array.isArray(employeeCallsData) && employeeCallsData.length > 0) {
    const employeeWithHighestMissPercent = employeeCallsData.reduce(
      (acc, cur) =>
        cur.percentMissedAndAbandoned > acc.percentMissedAndAbandoned
          ? cur
          : acc,
    )
    parsedSummary.areasOfImprovementMessages.push(
      `Manager ${
        employeeWithHighestMissPercent.employeeName
      } is on duty when the most calls are missed. ${employeeWithHighestMissPercent.percentMissedAndAbandoned.toFixed(
        1,
      )}% missed + abandoned calls in the time period selected.`,
    )
  }

  return parsedSummary
}

export default parseSummary
