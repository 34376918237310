import React from 'react'

interface IProps {
  names: string[]
  target: string
}

const EmployeeNames = ({ names, target }: IProps) => (
  <div>
    {names.map((n, index) => (
      <span key={index}>
        {n === target ? <strong>{n}</strong> : n}
        {index < names.length - 1 && ', '}
      </span>
    ))}
  </div>
)
export default React.memo(EmployeeNames)
