import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { IApiDataType } from '../types'

const query = gql`
  query bbbHaccps($iStartDate: Date!, $iEndDate: Date!) {
    haccps(iStartDate: $iStartDate, iEndDate: $iEndDate) {
      nodes {
        id
        batchId
        name
        startDate
        duration
        openDoorCount
        deviceName
        deviceSerialNumber
        groupName
      }
    }
  }
`

export const bbbHaccpsConfigs = {
  id: 'string',
  batchId: 'string',
  name: 'string',
  startDate: 'date-string',
  duration: 'second',
  openDoorCount: 'number',
  deviceName: 'string',
  deviceSerialNumber: 'string',
  groupName: 'string',
} as const

const useBbbHaccps = () => {
  const { startDate, endDate } = useDateFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip: !startDate || !endDate,
  })

  return {
    data: useMemo((): IApiDataType => {
      const haccps = data?.haccps.nodes

      if (!haccps) return null

      return {
        source: haccps,
      }
    }, [data]),
    loading,
  }
}

export default useBbbHaccps
