import _ from 'lodash'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import COLORS from 'pared/constants/colors'
import { getBrandSettings, getBusinessLabel } from 'pared/customer'
import {
  IDateRange,
  TYPE_PERIOD,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'
import DirectorFilterSelector from 'pared/pages/StoreList/DirectorFilterSelector'

import BreakdownTable from './BreakdownTable'
import OpentableBreakdownTable from './OpentableBreakdownTable'
import OpsCompsTrendChart from './OpsCompsTrendChart'
import RatingsChart from './RatingsChart'
import SmgTable from './SmgTable'
import { IGlobalGuestKpi, IGlobalGuestOpentableKpi } from './index'

interface IProps {
  allDirectors: IDirector[]
  selectedDirector: IDirector | null
  navParams: INavParams
  onDirectorChange: (directorEmployeeId: number) => Promise<void>
  selectedDateRange: IDateRange | null
  globalGuestKpiSummary: IGlobalGuestKpi
  selectedLocationGroupId: number
  breakdownType: string | null
  globalGuestOpentableKpis: IGlobalGuestOpentableKpi
}

function WebSmallMain({
  navParams,
  allDirectors,
  selectedDirector,
  onDirectorChange,
  selectedDateRange,
  globalGuestKpiSummary,
  selectedLocationGroupId,
  breakdownType,
  globalGuestOpentableKpis,
}: IProps) {
  const guestConfig = getBrandSettings().guest

  const acrScore = _.get(globalGuestKpiSummary, 'acr.ratingScore', '')
  const acrRatingCountStr = _.get(
    globalGuestKpiSummary,
    'acr.ratingCountStr',
    '',
  )
  const acrScoreAndStr = `${acrScore} (${acrRatingCountStr})`

  const momentfeedRatingScore = _.get(
    globalGuestKpiSummary,
    'momentfeed.ratingScore',
    '',
  )
  const momentfeedRatingCountStr = _.get(
    globalGuestKpiSummary,
    'momentfeed.ratingCountStr',
    '',
  )
  const momentfeedScoreAndStr = `${momentfeedRatingScore} (${momentfeedRatingCountStr})`

  const yelpRatingScore = _.get(globalGuestKpiSummary, 'yelp.ratingScore', '')
  const yelpRatingCountStr = _.get(
    globalGuestKpiSummary,
    'yelp.ratingCountStr',
    '',
  )
  const yelpScoreAndStr = `${yelpRatingScore} (${yelpRatingCountStr})`

  const googleRatingScore = _.get(
    globalGuestKpiSummary,
    'google.ratingScore',
    '',
  )
  const googleRatingCountStr = _.get(
    globalGuestKpiSummary,
    'google.ratingCountStr',
    '',
  )
  const googleScoreAndStr = `${googleRatingScore} (${googleRatingCountStr})`

  const facebookRatingScore = _.get(
    globalGuestKpiSummary,
    'facebook.ratingScore',
    '',
  )
  const facebookRatingCountStr = _.get(
    globalGuestKpiSummary,
    'facebook.ratingCountStr',
    '',
  )
  const facebookScoreAndStr = `${facebookRatingScore} (${facebookRatingCountStr})`

  const deliveryRatingScore = _.get(
    globalGuestKpiSummary,
    'delivery.ratingScore',
    '',
  )
  const deliveryRatingCountStr = _.get(
    globalGuestKpiSummary,
    'delivery.ratingCountStr',
    '',
  )
  const deliveryScoreAndStr = `${deliveryRatingScore} (${deliveryRatingCountStr})`

  const allRatingScore = _.get(globalGuestKpiSummary, 'all.ratingScore', '')
  const allRatingCountStr = _.get(
    globalGuestKpiSummary,
    'all.ratingCountStr',
    '',
  )
  const allScoreAndStr = `${allRatingScore} (${allRatingCountStr})`

  const yextRatingScore = _.get(globalGuestKpiSummary, 'yext.ratingScore', '')
  const yextRatingCountStr = _.get(
    globalGuestKpiSummary,
    'yext.ratingCountStr',
    '',
  )
  const yextScoreAndStr = `${yextRatingScore} (${yextRatingCountStr})`

  const dissatisfactionRatingScore = _.get(
    globalGuestKpiSummary,
    'dissatisfaction.ratingScore',
    '',
  )
  const dissatisfactionRatingCountStr = _.get(
    globalGuestKpiSummary,
    'dissatisfaction.ratingCountStr',
    '',
  )
  const dissatisfactionScoreAndStr = `${dissatisfactionRatingScore} (${dissatisfactionRatingCountStr})`

  const inaccurateRatingScore = _.get(
    globalGuestKpiSummary,
    'inaccurate.ratingScore',
    '',
  )
  const inaccurateRatingCountStr = _.get(
    globalGuestKpiSummary,
    'inaccurate.ratingCountStr',
    '',
  )
  const inaccurateScoreAndStr = `${inaccurateRatingScore} (${inaccurateRatingCountStr})`

  const overallSatisfactionRatingScore = _.get(
    globalGuestKpiSummary,
    'overallSatisfaction.ratingScore',
    '',
  )
  const overallSatisfactionRatingCountStr = _.get(
    globalGuestKpiSummary,
    'overallSatisfaction.ratingCountStr',
    '',
  )
  const overallSatisfactionScoreAndStr = `${overallSatisfactionRatingScore} (${overallSatisfactionRatingCountStr})`

  const orderNotOnTimeRatingScore = _.get(
    globalGuestKpiSummary,
    'orderNotOnTime.ratingScore',
    '',
  )
  const orderNotOnTimeRatingCountStr = _.get(
    globalGuestKpiSummary,
    'orderNotOnTime.ratingCountStr',
    '',
  )
  const orderNotOnTimeScoreAndStr = `${orderNotOnTimeRatingScore} (${orderNotOnTimeRatingCountStr})`

  const tasteOfFoodRatingScore = _.get(
    globalGuestKpiSummary,
    'tasteOfFood.ratingScore',
    '',
  )
  const tasteOfFoodRatingCountStr = _.get(
    globalGuestKpiSummary,
    'tasteOfFood.ratingCountStr',
    '',
  )
  const tasteOfFoodScoreAndStr = `${tasteOfFoodRatingScore} (${tasteOfFoodRatingCountStr})`

  const opentableRatingScore = _.get(
    globalGuestOpentableKpis,
    'ratingScore',
    '',
  )
  const opentableRatingScoreChange = _.get(
    globalGuestOpentableKpis,
    'ratingScoreChange',
    '',
  )
  const opentableSeatedCovers = _.get(
    globalGuestOpentableKpis,
    'seatedCovers',
    '',
  )
  const opentableSeatedCoversChange = _.get(
    globalGuestOpentableKpis,
    'seatedCoversChange',
    '',
  )
  const opentableOpentableCovers = _.get(
    globalGuestOpentableKpis,
    'opentableCovers',
    '',
  )
  const opentableOpentableCoversChange = _.get(
    globalGuestOpentableKpis,
    'opentableCoversChange',
    '',
  )
  const opentableYourNetworkCovers = _.get(
    globalGuestOpentableKpis,
    'yourNetworkCovers',
    '',
  )
  const opentableYourNetworkCoversChange = _.get(
    globalGuestOpentableKpis,
    'yourNetworkCoversChange',
    '',
  )
  const opentablePhoneCovers = _.get(
    globalGuestOpentableKpis,
    'phoneCovers',
    '',
  )
  const opentablePhoneCoversChange = _.get(
    globalGuestOpentableKpis,
    'phoneCoversChange',
    '',
  )
  const opentableWalkinCovers = _.get(
    globalGuestOpentableKpis,
    'walkinCovers',
    '',
  )
  const opentableWalkinCoversChange = _.get(
    globalGuestOpentableKpis,
    'walkinCoversChange',
    '',
  )
  const opentableNoShowRate = _.get(globalGuestOpentableKpis, 'noShowRate', '')
  const opentableNoShowRateChange = _.get(
    globalGuestOpentableKpis,
    'noShowRateChange',
    '',
  )

  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)
  let breakdownTitle = `BREAKDOWN BY ${getBusinessLabel('store').toUpperCase()}`
  if (guestConfig.kpiDisplayName) {
    breakdownTitle = guestConfig.kpiDisplayName
  } else if (selectedDirctorEmployeeId === -2) {
    // breakdownByDirectors
    breakdownTitle = `BREAKDOWN BY ${getBusinessLabel(
      'director',
    ).toUpperCase()}`
  } else if (breakdownType) {
    breakdownTitle = `BREAKDOWN BY ${breakdownType}`.toUpperCase()
  }

  return (
    <>
      <SelectorContainer>
        {selectedDirector ? (
          <DirectorFilterSelector
            allDirectors={allDirectors}
            selectedDirector={selectedDirector}
            onDirectorChange={onDirectorChange}
            width={'87vw'}
          />
        ) : null}
        <DateRangeSelector
          navParams={navParams}
          excludedDateRanges={[TYPE_YESTERDAY]}
          preSelectedDateRange={TYPE_PERIOD}
        />
      </SelectorContainer>
      <MainContainer>
        <PageTitleDiv>Systemwide Guest</PageTitleDiv>
        {guestConfig.isUpdatedWeekly ? (
          <SubtitleContainer>Updated Weekly</SubtitleContainer>
        ) : null}

        <VerticalSpacer30px />
        {guestConfig.hideKpis ? null : (
          <>
            <FullScreenTable>
              <thead>
                <tr>
                  <th>{guestConfig.kpiDisplayName || 'KPIs'}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {guestConfig.smgMwb?.isVisible ? (
                  <>
                    <tr>
                      <td>SMG Overall Satisfaction Rating</td>
                      <BoldNumTd>{overallSatisfactionScoreAndStr}</BoldNumTd>
                    </tr>
                    <tr>
                      <td>SMG Time to Receive Order Rating</td>
                      <BoldNumTd>{orderNotOnTimeScoreAndStr}</BoldNumTd>
                    </tr>
                    <tr>
                      <td>SMG Taste of Food Rating</td>
                      <BoldNumTd>{tasteOfFoodScoreAndStr}</BoldNumTd>
                    </tr>
                  </>
                ) : null}
                {guestConfig.acr.isVisible ? (
                  <tr>
                    <td>ACR Score</td>
                    <BoldNumTd>{acrScoreAndStr}</BoldNumTd>
                  </tr>
                ) : null}
                {guestConfig.momentfeed ? (
                  <tr>
                    <td>Momentfeed Rating</td>
                    <BoldNumTd>{momentfeedScoreAndStr}</BoldNumTd>
                  </tr>
                ) : null}
                {guestConfig.yext ? (
                  <tr>
                    <td>Yext Rating</td>
                    <BoldNumTd>{yextScoreAndStr}</BoldNumTd>
                  </tr>
                ) : null}
                {guestConfig.facebook ? (
                  <tr>
                    <td>Facebook Rating</td>
                    <BoldNumTd>{facebookScoreAndStr}</BoldNumTd>
                  </tr>
                ) : null}
                {guestConfig.google ? (
                  <tr>
                    <td>Google Rating</td>
                    <BoldNumTd>{googleScoreAndStr}</BoldNumTd>
                  </tr>
                ) : null}
                {guestConfig.delivery ? (
                  <tr>
                    <td>Delivery Rating</td>
                    <BoldNumTd>{deliveryScoreAndStr}</BoldNumTd>
                  </tr>
                ) : null}
                {guestConfig.yelp ? (
                  <tr>
                    <td>Yelp Rating</td>
                    <BoldNumTd>{yelpScoreAndStr}</BoldNumTd>
                  </tr>
                ) : null}
                {guestConfig.allRating.isVisible ? (
                  <tr>
                    <td>{guestConfig.allRating.displayName} Rating</td>
                    <BoldNumTd>{allScoreAndStr}</BoldNumTd>
                  </tr>
                ) : null}
                {guestConfig.smg?.isVisible ? (
                  <tr>
                    <td>Dissatisfaction %</td>
                    <BoldNumTd>{dissatisfactionScoreAndStr}</BoldNumTd>
                  </tr>
                ) : null}
                {guestConfig.smg?.isVisible ? (
                  <tr>
                    <td>Inaccurate %</td>
                    <BoldNumTd>{inaccurateScoreAndStr}</BoldNumTd>
                  </tr>
                ) : null}
              </tbody>
            </FullScreenTable>
            <VerticalSpacer40px />
          </>
        )}

        {guestConfig.opentable?.isVisible ? (
          <>
            <FullScreenTable>
              <thead>
                <tr>
                  <th>Opentable</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Rating</td>
                  <BoldNumTd>{opentableRatingScore}</BoldNumTd>
                </tr>
                <tr>
                  <td>YoY Rating Change %</td>
                  <BoldNumTd>{opentableRatingScoreChange}</BoldNumTd>
                </tr>
                <tr>
                  <td>Seated Covers</td>
                  <BoldNumTd>{opentableSeatedCovers}</BoldNumTd>
                </tr>
                <tr>
                  <td>YoY Seated Covers Change %</td>
                  <BoldNumTd>{opentableSeatedCoversChange}</BoldNumTd>
                </tr>
                <tr>
                  <td>Opentable Covers</td>
                  <BoldNumTd>{opentableOpentableCovers}</BoldNumTd>
                </tr>
                <tr>
                  <td>YoY Opentable Covers Change %</td>
                  <BoldNumTd>{opentableOpentableCoversChange}</BoldNumTd>
                </tr>
                <tr>
                  <td>Your Network Covers</td>
                  <BoldNumTd>{opentableYourNetworkCovers}</BoldNumTd>
                </tr>
                <tr>
                  <td>YoY Your Network Covers Change %</td>
                  <BoldNumTd>{opentableYourNetworkCoversChange}</BoldNumTd>
                </tr>
                <tr>
                  <td>Phone Covers</td>
                  <BoldNumTd>{opentablePhoneCovers}</BoldNumTd>
                </tr>
                <tr>
                  <td>YoY Phone Covers Change %</td>
                  <BoldNumTd>{opentablePhoneCoversChange}</BoldNumTd>
                </tr>
                <tr>
                  <td>Walk-in Covers</td>
                  <BoldNumTd>{opentableWalkinCovers}</BoldNumTd>
                </tr>
                <tr>
                  <td>YoY Walk-in Covers Change %</td>
                  <BoldNumTd>{opentableWalkinCoversChange}</BoldNumTd>
                </tr>
                <tr>
                  <td>No-Show Rate</td>
                  <BoldNumTd>{opentableNoShowRate}</BoldNumTd>
                </tr>
                <tr>
                  <td>YoY No-Show Rate Change %</td>
                  <BoldNumTd>{opentableNoShowRateChange}</BoldNumTd>
                </tr>
              </tbody>
            </FullScreenTable>

            <VerticalSpacer40px />
          </>
        ) : null}

        {!guestConfig.hideRatingChart && selectedDateRange ? (
          <RatingsChart
            endDate={selectedDateRange.endDateStr}
            dateRangeType={selectedDateRange.type}
            locationGroupId={selectedLocationGroupId}
          />
        ) : null}

        {!guestConfig.opsComps ? null : (
          <>
            <VerticalSpacer60px />
            <ScrollableTableContainer>
              <ScrollableTable>
                <OpsCompsTrendChart
                  navParams={navParams}
                  selectedDateRange={selectedDateRange}
                  selectedDirector={selectedDirector}
                />
              </ScrollableTable>
            </ScrollableTableContainer>
            <VerticalSpacer40px />
          </>
        )}

        {guestConfig.hideRatingChart ? null : (
          <>
            <SectionTitleDiv>{`SOCIAL ${breakdownTitle}`}</SectionTitleDiv>
            {selectedDateRange && selectedDirector ? (
              <BreakdownTable
                startDate={selectedDateRange.startDateStr}
                endDate={selectedDateRange.endDateStr}
                locationGroupId={selectedLocationGroupId}
                locationGroupIds={allDirectors.map(
                  ({ locationGroupId }) => locationGroupId,
                )}
                isBreakdownByDirectors={selectedDirector.employeeId === -2}
                breakdownType={breakdownType}
              />
            ) : null}
          </>
        )}

        {guestConfig.smg?.isVisible ? (
          <>
            <SectionTitleDiv>{`SMG ${breakdownTitle}`}</SectionTitleDiv>
            <SmgTable
              locationGroupId={selectedLocationGroupId}
              isBreakdownByDirectors={selectedDirector?.employeeId === -2}
              breakdownType={breakdownType}
            />
          </>
        ) : null}

        {selectedDateRange &&
        selectedDirector &&
        guestConfig.opentable?.isVisible ? (
          <>
            <VerticalSpacer40px />
            <SectionTitleDiv>Opentable</SectionTitleDiv>
            <OpentableBreakdownTable
              startDate={selectedDateRange.startDateStr}
              endDate={selectedDateRange.endDateStr}
              locationGroupId={selectedLocationGroupId}
              locationGroupIds={allDirectors.map(
                ({ locationGroupId }) => locationGroupId,
              )}
              isBreakdownByDirectors={selectedDirector.employeeId === -2}
              breakdownType={breakdownType}
            />
          </>
        ) : null}
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  padding: 30px 25px 50px 25px;
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;
`

const ScrollableTableContainer = styled.div`
  overflow-x: scroll;
  border: 1px solid;
  padding-left: 30px;
  width: 90vw;
`

const ScrollableTable = styled.div`
  width: 850px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
`

const PageTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 0;
`

const VerticalSpacer30px = styled.div`
  width: 100%;
  height: 30px;
`

const VerticalSpacer40px = styled.div`
  width: 100%;
  height: 40px;
`

const BoldNumTd = styled.td`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
`

const FullScreenTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};
  text-align: right;

  th {
    font-family: Lexend-SemiBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }

  td,
  th {
    vertical-align: middle;
    padding: 8px 0;
  }

  th:first-child {
    text-align: left;
  }

  td:first-child {
    text-align: left;
  }

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  thead::after {
    content: '';
    display: block;
    height: 10px;
    width: 100%;
    background: transparent;
  }
`

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 20px 0;
`

const VerticalSpacer60px = styled.div`
  width: 100%;
  height: 60px;
`

const SelectorContainer = styled.div`
  padding: 10px 25px 10px 25px;
  background-color: rgb(196, 196, 196);
`

export default WebSmallMain
