import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import calc from '../../../utils/calc'
import { corporateGroupTableConfigs } from '../../../variables'
import { useVariables } from '../../../variables'
import { IApiDataType } from '../../types'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

type IDataType<
  T extends string =
    | 'listLocationGroupAvgWeeklySales'
    | 'listLocationAvgWeeklySales',
> = Record<
  T,
  {
    nodes: ((T extends 'listLocationGroupAvgWeeklySales'
      ? {
          locationGroupId: number
        }
      : {
          locationId: number
        }) & {
      avgWeeklySales: number
      annualizedAvgWeeklySales: number
    })[]
  }
>

const query = gql`
  query metricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    listLocationGroupAvgWeeklySales(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        avgWeeklySales
        annualizedAvgWeeklySales
      }
    }

    listLocationAvgWeeklySales(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        avgWeeklySales
        annualizedAvgWeeklySales
      }
    }
  }
`

export const bbbSystemwideOverviewConfigs = {
  ...corporateGroupTableConfigs,
  totalSales: 'price',
  totalSalesBudget: 'price',
  totalSalesBudgetDiff: 'price',
  totalSalesBudgetVariance: 'percent',
  netSalesSssPercent: 'percent',
  avgWeeklySales: 'price',
  annualizedAvgWeeklySales: 'price',
  totalLaborPercent: 'percent',
  totalLaborVariance: 'percent',
  totalFoodAndPaperPercent: 'percent',
  totalFoodAndPaperVariance: 'percent',
  ebitdaAfterBonus: 'price',
  ebitdaAfterBonusDiff: 'price',
  ebitdaAfterBonusBudget: 'price',
  ebitdaBeforeBonusPercent: 'percent',
  ebitdaAfterBonusPercent: 'percent',
  grossProfitPercent: 'percent',
  restaurantLevelProfitPercent: 'percent',
} as const

const useCommonBbbSystemwideOverview = buildMetricValuesHook(
  {
    groupFilterType: 'corporate',
    metrics: {
      codes: [
        'total_sales',
        'total_sales_budget',
        'net_sales_sss_percent',
        'total_labor',
        'total_labor_budget',
        'total_food_and_paper',
        'total_food_and_paper_budget',
        'ebitda_after_bonus',
        'ebitda_after_bonus_diff',
        'ebitda_after_bonus_budget',
        'ebitda_before_bonus_percent',
        'ebitda_after_bonus_percent',
        'gross_profit_percent',
        'restaurant_level_profit_percent',
      ],
    },
  },
  (data) => ({
    ...data,
    totalSalesBudgetVariance: calc(
      calc(
        calc(data.totalSales, '-', data.totalSalesBudget),
        '/',
        data.totalSalesBudget,
      ),
      '*',
      100,
    ),
    totalLaborPercent: calc(
      calc(data.totalLabor, '/', data.totalSales),
      '*',
      100,
    ),
    totalLaborVariance: calc(
      calc(
        calc(data.totalLabor, '/', data.totalSales),
        '-',
        calc(data.totalLaborBudget, '/', data.totalSalesBudget),
      ),
      '*',
      100,
    ),
    totalFoodAndPaperPercent: calc(
      calc(data.totalFoodAndPaper, '/', data.totalSales),
      '*',
      100,
    ),
    totalFoodAndPaperVariance: calc(
      calc(
        calc(data.totalFoodAndPaper, '/', data.totalSales),
        '-',
        calc(data.totalFoodAndPaperBudget, '/', data.totalSalesBudget),
      ),
      '*',
      100,
    ),
    totalSalesBudgetDiff: calc(data.totalSales, '-', data.totalSalesBudget),
  }),
)

const useBbbSystemwideOverview = () => {
  const { data: commonData, loading: commonLoading } =
    useCommonBbbSystemwideOverview()
  const { variables } = useVariables()
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: hasGroupBy
          ? groupFilter?.ids
          : groupFilter?.list?.map((g) => g.id),
      },
      hasGroupBy,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      if (!commonData) return commonData

      const nodes = (
        data?.listLocationGroupAvgWeeklySales ||
        data?.listLocationAvgWeeklySales
      )?.nodes
      const { locations, locationGroups } = variables.corporateGroup || {}

      return commonData?.map((c) => {
        const node = nodes?.find((n) => {
          const id = (() => {
            if (hasGroupBy && locations && 'locationId' in n)
              return n.locationId
            if (!hasGroupBy && locationGroups && 'locationGroupId' in n)
              return n.locationGroupId
            return -1
          })()

          return c.id === id.toString()
        })

        return {
          ...c,
          ...node,
        }
      })
    }, [commonData, data, variables]),
    loading: commonLoading || loading,
  }
}

export default useBbbSystemwideOverview
