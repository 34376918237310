import { useState } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import add from 'pared/images/Pared/add.svg'

import EditModal, { IPropsType as IEditModalPropsType } from './EditModal'

export interface IPropsType extends Omit<IEditModalPropsType, 'onClose'> {
  reviewer?: string
  reviewAt: string
}

const Performance = styled.div`
  min-width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 46px;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`

const Content = styled.div`
  max-width: 500px;
  overflow-wrap: break-word;
  font-size: 14px;
  font-weight: 300;
  line-height: 17.5px;
  text-align: left;
`

const Reviewer = styled.div`
  font-size: 14px;
  font-weight: 200;
  line-height: 46px;
  text-align: left;
`

const EditText = styled.div`
  display: inline-block;
  color: ${COLORS.Plum};
  margin-left: 16px;
  cursor: pointer;
`

const Edit = styled.img`
  display: inline-block;
  cursor: pointer;
  float: right;
`

const LoadMore = styled.div`
  margin-top: 8px;
  color: ${COLORS.Plum};
  cursor: pointer;
`

const Feedback = ({
  reviewer,
  performance,
  feedback,
  reviewAt,
  ...props
}: IPropsType) => {
  const [open, setOpen] = useState(false)
  const [loadMore, setLoadMore] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const handleEdit = (edit: boolean) => {
    setIsEdit(edit)
    setOpen(true)
  }

  if (!feedback)
    return (
      <>
        <Performance>
          -
          <Edit src={add} onClick={() => handleEdit(false)} />
        </Performance>

        {open && (
          <EditModal
            {...props}
            performance=""
            feedback=""
            updateUuid=""
            onClose={() => setOpen(false)}
          />
        )}
      </>
    )

  return (
    <>
      <Performance>
        <div>{performance}</div>
        <Edit src={add} onClick={() => handleEdit(false)} />
      </Performance>
      <Content>
        {feedback.length < 150 ? (
          feedback
        ) : (
          <>
            {loadMore ? feedback : `${feedback.slice(0, 150)}...`}

            <div>
              <LoadMore onClick={() => setLoadMore(!loadMore)}>
                {loadMore ? 'Show Less' : 'Load More'}
              </LoadMore>
            </div>
          </>
        )}
      </Content>
      <Reviewer>
        {reviewer} ({reviewAt})
        <EditText onClick={() => handleEdit(true)}>EDIT</EditText>
      </Reviewer>
      {open && isEdit && (
        <EditModal
          {...props}
          performance={performance}
          feedback={feedback}
          onClose={() => setOpen(false)}
        />
      )}
      {open && !isEdit && (
        <EditModal
          {...props}
          performance=""
          feedback=""
          updateUuid=""
          onClose={() => setOpen(false)}
        />
      )}
    </>
  )
}

export default Feedback
