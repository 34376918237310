import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/constants/styles'

import TableHelper, { ITableProps } from './TableHelper'

const MobileContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    overflow: scroll hidden;
    padding: 20px 30px 0 30px;
    border: 1px solid;
  }
`

const Table: React.FC<ITableProps> = ({ data }) => {
  return (
    <MobileContainer>
      <TableHelper data={data} />
    </MobileContainer>
  )
}

export default Table
