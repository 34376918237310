import { v4 as uuidv4 } from 'uuid'

import Trend from 'pared/pages/ExpoAi/Trend'
import { FROM_EXPO } from 'pared/pages/ExpoAi/constants'
import { IMessage } from 'pared/pages/ExpoAi/type'

import formatDate from '../formatDate'

export default function handlePurchaseTrends(responseData: any) {
  if (responseData?.purchaseTrends) {
    const result: any = responseData?.purchaseTrends
    const answerMessage: IMessage = {
      id: uuidv4(),
      from: FROM_EXPO,
      content: (
        <div>
          <div>
            {formatDate(result.startDate)} ~ {formatDate(result.endDate)}
          </div>
          <div>{(result.entityNames || []).join(', ')}</div>
          <Trend data={result.trendData} />
        </div>
      ),
    }
    return answerMessage
  }

  return null
}
