import _ from 'lodash'
import { store } from 'state-pool'

import useBrands from './useBrands'

// FIXME: Remove 'state-pool'
export default () => {
  const { brand } = useBrands()

  return store.setState('brand', brand)
}
