import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { TYPE_PERIOD } from 'pared/data/getDateRanges'
import CorporateTrendChart from 'pared/pages/CorporateDelivery/Chart'
import StoreTrendChart from 'pared/pages/Delivery/Chart'
import { FROM_EXPO } from 'pared/pages/ExpoAi/constants'
import { IMessage } from 'pared/pages/ExpoAi/type'

const VerticalSpacer = styled.div`
  width: 100%;
  height: 50px;
`

export default function handleDeliveryKpiTrends(responseData: any) {
  if (responseData?.deliveryKpiTrends) {
    const messageId = uuidv4()
    const results = responseData.deliveryKpiTrends

    const uiComponents: React.ReactNode[] = []

    if (results.delivery) {
      if (results.delivery.locations) {
        const dlData = results.delivery.locations
        uiComponents.push(
          <div>
            <div>{dlData.locationNames.join(', ')}</div>
            <StoreTrendChart
              key={`delivery.locations.${messageId}`}
              startDate={dlData?.startDate}
              endDate={dlData?.endDate}
              dateRangeType={TYPE_PERIOD}
              locationId={dlData?.locationIds[0]}
              locationName={dlData?.locationNames[0]}
            />
          </div>,
        )
      }

      if (results.delivery.locationGroups) {
        const dlgData = results.delivery.locationGroups
        uiComponents.push(
          <div>
            {uiComponents.length > 0 ? <VerticalSpacer /> : null}
            <div>{dlgData.locationGroupNames.join(', ')}</div>
            <CorporateTrendChart
              key={`discount.locationGroups.${messageId}`}
              endDate={dlgData?.endDate}
              dateRangeType={TYPE_PERIOD}
              locationGroupId={dlgData?.locationGroupIds[0]}
            />
          </div>,
        )
      }
    }

    if (uiComponents.length > 0) {
      const answerMessage: IMessage = {
        id: messageId,
        from: FROM_EXPO,
        content: <div>{uiComponents}</div>,
      }

      return answerMessage
    }
  }

  return null
}
