import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import React, { useState } from 'react'
import styled from 'styled-components'

import CustomizedReport from 'pared/components/CustomizedReport'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import { getBrandLocationGroupId } from 'pared/utils/brand'

interface IProps {
  locationGroupId: number | undefined
}

type IKpiType =
  | 'hourly_labor_cost'
  | 'cogs_percent'
  | 'missed_call_percent'
  | 'dissatisfaction_percent'
  | 'inaccurate_percent'
const kpis: IKpiType[] = [
  'hourly_labor_cost',
  'cogs_percent',
  'missed_call_percent',
  'dissatisfaction_percent',
  'inaccurate_percent',
]
const DISPLAY_STRINGS = {
  hourly_labor_cost: 'Labor %',
  cogs_percent: 'COGS %',
  missed_call_percent: 'Missed + Abandoned Call %',
  dissatisfaction_percent: 'SMG - Dissatisfaction %',
  inaccurate_percent: 'SMG - Inaccurate %',
} as const

const BottomTenTable = ({ locationGroupId }: IProps) => {
  const dateRange = useGetDateRange()
  const brandLocationGroupId = getBrandLocationGroupId()
  const [kpi, setKpi] = useState<IKpiType>('hourly_labor_cost')
  const inputParams = {
    startDate: dateRange?.startDateStr,
    endDate: dateRange?.endDateStr,
    locationGroupId: locationGroupId || brandLocationGroupId,
  }

  return (
    <Container>
      <Header>
        <SubHeader>Bottom 10 Week over Week</SubHeader>
        <StyledFormControl variant="outlined" size="small">
          <Select
            value={kpi}
            onChange={(e: any) => {
              const value: IKpiType = e.target.value
              setKpi(value)
            }}
          >
            {kpis.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {DISPLAY_STRINGS[option]}
                </MenuItem>
              )
            })}
          </Select>
        </StyledFormControl>
      </Header>
      <VerticalSpacer />
      <CustomizedReport
        reportName="BOTTOM_TEN_TABLE"
        inputParams={inputParams}
        selectedFilter={{ kpi }}
      />
    </Container>
  )
}

export default React.memo(BottomTenTable)

const Container = styled.div`
  margin: 0;
  padding-top: 80px;
  overflow: scroll hidden;
`

const SubHeader = styled.div`
  margin: 0px;
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
`

const StyledFormControl = styled(FormControl)`
  width: 250px;
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

const VerticalSpacer = styled.div`
  height: 20px;
`

const Header = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    gap: 10px;
    align-items: start;
  }
`
