import { gql, useMutation } from '@apollo/client'
import _ from 'lodash'
import { useState } from 'react'

import { track } from 'pared/analytics/team'
import { getUser } from 'pared/utils/user'

import Main from './Main'

const CREATE_MANAGER_REVIEW = gql`
  mutation CreateManagerReview(
    $reviewerId: Int!
    $revieweeId: Int!
    $locationId: Int!
    $feedback: String!
    $performance: EnumLocationManagerReviewPerformance!
  ) {
    createLocationManagerReview(
      input: {
        locationManagerReview: {
          reviewerId: $reviewerId
          revieweeId: $revieweeId
          locationId: $locationId
          feedback: $feedback
          performance: $performance
        }
      }
    ) {
      locationManagerReview {
        id
        reviewer {
          id
          firstName: preferredName
          lastName: familyName
        }
        reviewee {
          id
          firstName: preferredName
          lastName: familyName
        }
        location {
          id
          code
        }
        feedback
        performance
      }
    }
  }
`

const UPDATE_MANAGER_REVIEW = gql`
  mutation UpdateManagerReview(
    $id: Int!
    $feedback: String!
    $performance: EnumLocationManagerReviewPerformance!
  ) {
    updateLocationManagerReview(
      input: {
        id: $id
        patch: { feedback: $feedback, performance: $performance }
      }
    ) {
      locationManagerReview {
        id
        reviewer {
          id
          firstName: preferredName
          lastName: familyName
        }
        reviewee {
          id
          firstName: preferredName
          lastName: familyName
        }
        location {
          id
          code
        }
        feedback
        performance
      }
    }
  }
`

interface IProps {
  firstName: string
  lastName: string
  role: string
  storeCode: string
  storeName: string
  revieweeId: number
  locationId: number
  currentFeedback: string
  isUpdatingFeedback: boolean
  managerReviewId?: number
  closePopover: () => void
  onRefresh: () => Promise<void>
}

const EmployeeFeedbackPopover = ({
  firstName,
  lastName,
  role,
  storeCode,
  storeName,
  revieweeId,
  locationId,
  closePopover,
  onRefresh,
  currentFeedback,
  isUpdatingFeedback,
  managerReviewId,
}: IProps) => {
  const [performanceValue, setPerformanceValue] = useState<string>('')
  const [feedback, setFeedback] = useState<string>(currentFeedback)
  const [
    shouldDisplayPerformanceValueError,
    setShouldDisplayPerformanceValueError,
  ] = useState<boolean>(false)
  const [shouldDisplayFeedbackEmptyError, setShouldDisplayFeedbackEmptyError] =
    useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [createManagerReview, createManagerReviewStatus] = useMutation(
    CREATE_MANAGER_REVIEW,
  )
  const [updateManagerReview, updateManagerReviewStatus] = useMutation(
    UPDATE_MANAGER_REVIEW,
  )

  const user = getUser()
  const userEmployeeId = _.get(user, 'employeeId', 0)

  const onSubmitFeedback = _.once(async () => {
    if (isSubmitting) {
      return
    }

    if (performanceValue === '' || feedback === '') {
      if (performanceValue === '') {
        setShouldDisplayPerformanceValueError(true)
      }
      if (feedback === '') {
        setShouldDisplayFeedbackEmptyError(true)
      }
    } else {
      setIsSubmitting(true)
      try {
        if (isUpdatingFeedback) {
          await updateManagerReview({
            variables: {
              id: managerReviewId,
              feedback,
              performance: performanceValue,
            },
          })
          track.managerFeedbackEdited(revieweeId)
        } else {
          await createManagerReview({
            variables: {
              reviewerId: userEmployeeId,
              revieweeId,
              locationId,
              feedback,
              performance: performanceValue,
            },
          })
          track.managerFeedbackAdded(revieweeId)
        }

        await onRefresh()
        closePopover()
      } catch (error) {
        // mute the error
        setIsSubmitting(false)
      }
    }
  })

  const onPerformanceReviewChange = (event: any) => {
    const selectedValue = event.target.value || ''
    setPerformanceValue(selectedValue)
    if (!_.isEmpty(selectedValue)) {
      setShouldDisplayPerformanceValueError(false)
    }
  }

  return (
    <Main
      firstName={firstName}
      lastName={lastName}
      role={role}
      storeCode={storeCode}
      storeName={storeName}
      performanceValue={performanceValue}
      setPerformanceValue={setPerformanceValue}
      shouldDisplayPerformanceValueError={shouldDisplayPerformanceValueError}
      setShouldDisplayPerformanceValueError={
        setShouldDisplayPerformanceValueError
      }
      shouldDisplayFeedbackEmptyError={shouldDisplayFeedbackEmptyError}
      onPerformanceReviewChange={onPerformanceReviewChange}
      setShouldDisplayFeedbackEmptyError={setShouldDisplayFeedbackEmptyError}
      feedback={feedback}
      setFeedback={setFeedback}
      onSubmitFeedback={onSubmitFeedback}
      isSubmitting={isSubmitting}
    />
  )
}

export default EmployeeFeedbackPopover
