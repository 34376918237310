import { getBrand } from 'pared/utils/brand'

interface IConfig {
  isLaborPercentVisible?: boolean
}

export default (): IConfig => {
  const brand = getBrand()
  const defaultConfig = {
    isLaborPercentVisible: false,
  }

  switch (brand) {
    case 'mwb':
    case 'burgerworks':
      return {
        isLaborPercentVisible: true,
      }

    default:
      return defaultConfig
  }
}
