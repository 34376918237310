import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

interface IConfig {
  hideForgotPassword?: boolean
}

export default (): IConfig => {
  const brand = getBrand()

  return useMemo(() => {
    switch (brand) {
      case 'ghai_pop':
        return {
          hideForgotPassword: true,
        }

      default:
        return {}
    }
  }, [brand])
}
