import * as _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import COLORS from 'pared/constants/colors'

import { useVariables } from '../../variables'
import Action from '../Action'
import { sorter as feedbackNameSorter } from '../FeedbackName'
import Format, { IPropsType as IFormatPropsType } from '../Format'
import { sorter as linkSorter } from '../Link'
import { sorter as trackerNameSorter } from '../TrackerName'
import { IColumnsType as ITableColumnsType } from '../types'
import { IColoredText } from '../types'
import { IApiKeyType, configs } from './useApi'

interface IBaseTableColumnsType
  extends Pick<ITableColumnsType<unknown>, 'align' | 'width'> {
  highlight?: boolean
  sortable?: boolean
  hide?: `<%- ${string} %>`
  actionName?: string
  hasLeftGutter?: boolean // only works while "highlight" is set to TRUE
  hasRightGutter?: boolean // only works while "highlight" is set to TRUE
}

type IColumnType<
  V extends Record<string, Pick<IFormatPropsType, 'type'>['type']>,
> = {
  [K in keyof V]: Omit<
    Extract<
      IFormatPropsType,
      {
        type: V[K]
      }
    >,
    'value' | 'values' | 'type'
  > & {
    key: K
    title?: string
  } & IBaseTableColumnsType
}

type IColumnsType = {
  [K in keyof typeof configs]: IColumnType<typeof configs[K]>
}

export interface IColumnsOptionsType<K extends IApiKeyType> {
  api: K
  columns: (
    | ({
        key: string
        title?: string
        children: IColumnsOptionsType<K>['columns']
      } & IBaseTableColumnsType)
    | IColumnsType[K][keyof IColumnsType[K]]
  )[]
  summary?: { [key: string]: unknown }
}

const dateSorter = (a: string, b: string) => {
  const aDate = moment(a)
  const bDate = moment(b)

  if (!aDate.isValid() || !bDate.isValid()) return 0

  return aDate.diff(bDate)
}

const coloredTextSorter = (a: IColoredText, b: IColoredText) => {
  if (a.type === 'number' && b.type === 'number') {
    if (_.isNil(a.value)) return 1
    if (_.isNil(b.value)) return -1

    return b.value - a.value
  }

  return b.text.localeCompare(a.text)
}

function getCellStyle({
  highlight,
  hasLeftGutter,
  hasRightGutter,
}: {
  highlight?: boolean
  hasLeftGutter?: boolean
  hasRightGutter?: boolean
}) {
  let cellStyle = ''
  if (highlight) {
    cellStyle = `background: ${COLORS.Porcelain};`
    if (hasLeftGutter) {
      cellStyle += ` border-left: 10px solid ${COLORS.Porcelain};`
    }
    if (hasRightGutter) {
      cellStyle += ` border-right: 10px solid ${COLORS.Porcelain};`
    }
  }
  return cellStyle
}

const SORTERS = {
  'feedback-name': feedbackNameSorter,
  date: dateSorter,
  'date-string': dateSorter,
  'date-diff': dateSorter,
  link: linkSorter,
  'tracker-name': trackerNameSorter,
  'colored-text': coloredTextSorter,
} as const

const generateColumns = <K extends IApiKeyType>(
  api: K,
  columns: IColumnsOptionsType<K>['columns'],
  template: (str: string) => string | { key: string; title: string }[],
  summary?: { [key: string]: unknown },
  parentHighlight?: boolean,
  parentKey?: keyof typeof configs[K],
): ITableColumnsType<Record<string, unknown>>[] =>
  columns.reduce(
    (
      result,
      {
        key: originKey,
        title: originTitle,
        hide: originHide,
        actionName,
        align,
        width,
        sortable,
        hasLeftGutter,
        hasRightGutter,
        highlight = parentHighlight,
        ...column
      },
    ) => {
      const key: ReturnType<typeof template> =
        template(originKey as string) || ''
      const hide = !originHide ? false : template(originHide) === 'true'

      if (hide) return result

      if (key instanceof Array) {
        const newColumns = key.map((d) => ({
          align,
          width,
          sortable,
          highlight,
          ...column,
          ...d,
        })) as IColumnsOptionsType<K>['columns']

        return [
          ...result,
          ...generateColumns(
            api,
            newColumns,
            template,
            summary,
            parentHighlight,
            originKey as keyof typeof configs[K],
          ),
        ]
      }

      const title = (template(originTitle || key) as string) || ' '

      if ('children' in column) {
        return [
          ...result,
          {
            key,
            title,
            align,
            cellStyle: getCellStyle({
              highlight,
              hasLeftGutter,
              hasRightGutter,
            }),
            children: generateColumns<K>(
              api,
              column.children,
              template,
              summary,
              highlight,
            ),
          },
        ]
      }

      const type = configs[api][parentKey || (key as keyof typeof configs[K])]
      const sorter = SORTERS[type as keyof typeof SORTERS] || true

      return [
        ...result,
        {
          key,
          title,
          width,
          align,
          sorter: sortable ? sorter : undefined,
          cellStyle: getCellStyle({ highlight, hasLeftGutter, hasRightGutter }),
          render: (value, values) => {
            const formatProps = {
              ...column,
              type,
              value,
              values,
            } as unknown as IFormatPropsType

            return (
              <Action actionName={actionName} value={value} values={values}>
                <Format {...formatProps} />
              </Action>
            )
          },
          summary: () => {
            if (!summary) return null

            const value = summary[key as string]
            const formatProps = {
              ...column,
              type,
              value,
              values: summary,
            } as unknown as IFormatPropsType

            return (
              <Action actionName={actionName} value={value} values={summary}>
                <Format {...formatProps} />
              </Action>
            )
          },
        },
      ]
    },
    [] as ITableColumnsType<Record<string, unknown>>[],
  )

const useColumns = ({
  api,
  columns,
  summary,
}: IColumnsOptionsType<IApiKeyType>) => {
  const { template } = useVariables()

  return useMemo(
    () => generateColumns(api, columns, template, summary),
    [api, columns, summary, template],
  )
}

export default useColumns
