import { gql } from '@apollo/client'

export interface IGetDiscountItemTypesVariableTypes {
  iStartDate: string
  iEndDate: string
  iFilter: any
}

export interface IDiscountItemNodeType {
  id: number
  itemType: string
}

export interface IGetDiscountItemTypesType {
  getDiscountItemTypes: {
    nodes: IDiscountItemNodeType[]
  }
}

export const GET_DISCOUNT_ITEM_TYPES = gql`
  query GetDiscountItemTypes(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    getDiscountItemTypes(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        id
        itemType
      }
    }
  }
`

export const LIST_LOCATION_DISCOUNT_DETAILS = gql`
  query ListLocationDiscountDetails(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationDiscountDetails(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationInfo {
          id
          code
          name
        }
        totalComps
        totalCompsCount
        totalGrossSales
        managerComps
        managerCompsCount
        compsItemDetails
        managerCompsPercent
        nonManagerCompsPercent
      }
    }
  }
`

export const LIST_LOCATION_GROUP_DISCOUNT_DETAILS = gql`
  query ListLocationGroupDiscountDetails(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupDiscountDetails(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        locationGroupName
        startDate
        endDate
        totalGrossSales
        totalCount
        totalAmount
        compsDetails
      }
    }
  }
`
