import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import styled from 'styled-components'

import { getBusinessLabel } from 'pared/customer'
import { IDirector } from 'pared/data/getDirectors'

import useConfig, { ILocationGroupType } from './hooks/useConfig'

interface IProps {
  allDirectors: IDirector[]
  director: IDirector
  onDirectorChange: (event: any) => Promise<void>
  disabledDirectors: boolean
  locationGroupTypes: ILocationGroupType[]
  temporaryValue?: string
}

const useStyles = makeStyles(() => ({
  text: {
    fontFamily: 'Lexend-Regular',
  },
}))

const TEMP_KEY = 'temp'

function WebLargeMain({
  allDirectors,
  director,
  onDirectorChange,
  disabledDirectors,
  locationGroupTypes,
  temporaryValue,
}: IProps) {
  const classes = useStyles()
  const config = useConfig()

  const directors = _.map(allDirectors, (d) => {
    const firstName = _.get(d, 'firstName', '')
    const lastName = _.get(d, 'lastName', '')
    const employeeId = _.get(d, 'employeeId', 22)
    const fullName = `${firstName} ${lastName}`

    return (
      <MenuItem key={fullName} value={employeeId} className={classes.text}>
        {fullName}
      </MenuItem>
    )
  })

  return (
    <SelectorFormControl variant="outlined" margin="dense">
      <MainSelect
        value={temporaryValue !== undefined ? TEMP_KEY : director.employeeId}
        onChange={onDirectorChange}
        displayEmpty
        className={classes.text}
      >
        {temporaryValue !== undefined ? (
          <MenuItem key={TEMP_KEY} value={TEMP_KEY} className={classes.text}>
            {temporaryValue}
          </MenuItem>
        ) : null}
        <MenuItem key="allStores" value={-1} className={classes.text}>
          All {getBusinessLabel('store')}s
        </MenuItem>
        {locationGroupTypes.map((type) => (
          <MenuItem
            key={`breakdownBy${type.name}`}
            value={type.id}
            className={classes.text}
          >
            Breakdown By {type.name}
          </MenuItem>
        ))}
        {disabledDirectors ||
        locationGroupTypes.some(
          (groupType) => groupType.name === getBusinessLabel('director'),
        ) ? null : (
          <MenuItem key="breakdownByDos" value={-2} className={classes.text}>
            Breakdown By {getBusinessLabel('director')}
          </MenuItem>
        )}
        {disabledDirectors ? null : directors}
      </MainSelect>
    </SelectorFormControl>
  )
}

const SelectorFormControl = styled(FormControl)`
  min-width: 150px;
`

const MainSelect = styled(Select)`
  border-radius: 0;
`

export default WebLargeMain
