import _ from 'lodash'
import { useState } from 'react'

import { IDirector } from 'pared/data/getDirectors'

import { IDetailTeamKpi } from '../index'
import Main from './Main'

export interface IOrderBy {
  columnName: string
  isAscending: boolean
}

interface IProps {
  itemizedKpis: IDetailGuestKpi[]
  selectedDirector: IDirector | null
  breakdownType: string | null
}

function BreakdownTable({
  itemizedKpis,
  selectedDirector,
  breakdownType,
}: IProps) {
  const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false)

  const onToggleExpansion = () => {
    setIsTableExpanded(!isTableExpanded)
  }

  const [orderBy, setOrderBy] = useState<IOrderBy>({
    columnName: 'annualizedHourlyTurnoverRate',
    isAscending: false,
  })

  let rankedItemizedKpis = []
  const columnName = _.get(orderBy, 'columnName', '')
  const ascending = orderBy.isAscending ? 'asc' : 'desc'

  const ascendingCoefficient = orderBy.isAscending ? 1 : -1

  switch (columnName) {
    case 'managerPar':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return parseFloat(_.get(k, 'managerPar', '0')) * ascendingCoefficient
      })
      break
    case 'annualizedHourlyTurnoverRate':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'annualizedHourlyTurnoverRate', '0')) *
          ascendingCoefficient
        )
      })
      break
    case 'annualizedSalariedTurnoverRate':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'annualizedSalariedTurnoverRate', '0')) *
          ascendingCoefficient
        )
      })
      break
    case 'hourlyTerminatedCountInThisMonth':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'hourlyTerminatedCountInThisMonth', '0')) *
          ascendingCoefficient
        )
      })
      break
    case 'monthlyHourlyTurnoverRate':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'monthlyHourlyTurnoverRate', '0')) *
          ascendingCoefficient
        )
      })
      break
    case 'salariedTerminatedCountInThisMonth':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'salariedTerminatedCountInThisMonth', '0')) *
          ascendingCoefficient
        )
      })
      break
    case 'monthlySalariedTurnoverRate':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'monthlySalariedTurnoverRate', '0')) *
          ascendingCoefficient
        )
      })
      break
    case 'avgHourlyRate':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return parseFloat(_.get(k, 'avgHourlyRate', '0')) * ascendingCoefficient
      })
      break
    default:
      rankedItemizedKpis = _.orderBy(itemizedKpis, ['managerPar'], [ascending])
      break
  }

  return (
    <Main
      itemizedKpis={rankedItemizedKpis}
      isTableExpanded={isTableExpanded}
      onToggleExpansion={onToggleExpansion}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      selectedDirector={selectedDirector}
      breakdownType={breakdownType}
    />
  )
}

export default BreakdownTable
