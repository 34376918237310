import { gql, useMutation } from '@apollo/client'
import Button from '@mui/material/Button'

const MUTATION_CHANGE_PASSWORD = gql`
  mutation ChangePassword($userId: Int!, $newPassword: String!) {
    updatePasswordByExpoAdmin(
      input: { iUserId: $userId, iNewPassword: $newPassword }
    ) {
      boolean
    }
  }
`

const ChangePasswordButton = ({ userId }: { userId: number }) => {
  const [changePassword, { loading }] = useMutation(MUTATION_CHANGE_PASSWORD)

  const onChangePassword = async () => {
    const inputtedNewPassword = prompt('Enter new password:')
    if (inputtedNewPassword === null) {
      return
    }

    const trimmedNewPassword = inputtedNewPassword.trim()
    if (trimmedNewPassword.length < 8) {
      alert('Invalid password. The length of the password must be at least 8.')

      return
    }

    if (trimmedNewPassword.length !== inputtedNewPassword.length) {
      alert('Invalid password. White spaces are not allowed.')
      return
    }

    await changePassword({
      variables: {
        userId,
        newPassword: trimmedNewPassword,
      },
    })
  }

  const handleClick = async () => {
    await onChangePassword()
  }

  return (
    <Button disabled={loading} onClick={handleClick}>
      Change Password
    </Button>
  )
}

export default ChangePasswordButton
