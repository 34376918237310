import { INavParams } from 'pared/Routes/navParams'

import useSalesmanshipConfig from './hooks/useSalesmanshipConfig'

interface IProps {
  navParams: INavParams
}

const Salesmanship = ({ navParams }: IProps) => {
  const SalesmanshipModule = useSalesmanshipConfig()

  if (SalesmanshipModule) {
    return <SalesmanshipModule navParams={navParams} />
  } else {
    return null
  }
}

export default Salesmanship
