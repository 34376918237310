import { useMemo } from 'react'

export type IVariablesType = 'trend'
export interface IDataType {
  isTrend?: true
}

const useTrend = (): IDataType =>
  useMemo(
    () => ({
      isTrend: true,
    }),
    [],
  )

export const hooks = [['trend', useTrend]] as const
