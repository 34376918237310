import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useBrands from 'pared/layouts/hooks/useBrands'
import { getBrandLocationGroupId } from 'pared/utils/brand'
import { getDateRangeLabelV2 } from 'pared/utils/date'

import { useDateFilter } from '../../../dateFilter'
import { IApiDataType } from '../../types'

const query = gql`
  query trendLocationGroupFinancialKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $iLocationGroupId: Int!
    $useCustom: Boolean!
  ) {
    trendLocationGroupFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
      iGroupBy: "business_week"
    ) {
      nodes {
        locationGroupId
        locationGroupName
        startDate
        endDate
        netSales
        businessWeek
        businessWeekOfMonth
        businessMonth
        businessQuarter
        businessYear
      }
    }

    trendLocationGroupCustomizedReport: trendLocationGroupCustomizedReportBypassRls(
      iReportName: "LIST_LOCATION_GROUP_STORES_CUSTOMIZED_EXTEND_TABLE"
      iLocationGroupId: $iLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: "business_week"
    ) @include(if: $useCustom) {
      nodes {
        startDate
        endDate
        businessWeek
        businessWeekOfMonth
        businessMonth
        businessQuarter
        businessYear
        reportResult
      }
    }
  }
`

export const trendLocationGroupFinancialKpisConfigs = {
  date: 'string',
  netSales: 'price',

  // customized
  avgWeeklySales: 'price',
} as const

const USE_CUSTOM = ['mwb', 'bibibop', 'burgerworks']

const useTrendLocationGroupFinancialKpis = () => {
  const { startDate, endDate } = useDateFilter()
  const { brand } = useBrands()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const brandLocationGroupId = getBrandLocationGroupId()
  const locationGroupIds = !hasGroupBy
    ? [brandLocationGroupId]
    : groupFilter?.ids
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_group_ids: locationGroupIds },
      // FIXME: should use location group ids
      iLocationGroupId: locationGroupIds?.[0],
      useCustom: USE_CUSTOM.includes(brand),
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const trendLocationGroupFinancialKpis = data
        ?.trendLocationGroupFinancialKpis.nodes as
        | (Parameters<typeof getDateRangeLabelV2>[1] & {
            startDate: string
          })[]
        | undefined
      const customizedReport = data?.trendLocationGroupCustomizedReport
        ?.nodes as
        | (Parameters<typeof getDateRangeLabelV2>[1] & {
            startDate: string
            reportResult: {
              tableData: Record<string, unknown>[]
            }
          })[]
        | undefined

      if (!trendLocationGroupFinancialKpis) return null

      return trendLocationGroupFinancialKpis.map((n) => {
        const customizedData = customizedReport?.find(
          (c) => c.startDate === n.startDate,
        )?.reportResult.tableData?.[0]

        return {
          ...n,
          ...customizedData,
          date: getDateRangeLabelV2('business_week', n),
        }
      })
    }, [data]),
    loading,
  }
}

export default useTrendLocationGroupFinancialKpis
