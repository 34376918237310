import { useState } from 'react'

import { track } from 'pared/analytics/storeList'
import { feature } from 'pared/analytics/user'

import { IManagerReview } from '../index'
import Main from './Main'

interface IProps {
  managerReviews: IManagerReview[]
  isListManagerReviewloading: boolean
  managerReviewRef: any
}

function UnfilledManagerialPositionTable({
  managerReviews,
  isListManagerReviewloading,
  managerReviewRef,
}: IProps) {
  const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false)

  const onToggleTable = () => {
    if (managerReviewRef && managerReviewRef.current && isTableExpanded) {
      managerReviewRef.current.scrollIntoView()
    }
    setIsTableExpanded(!isTableExpanded)
    feature.used('Exec View Team - Manager Performance')
    track.managerPerformanceToggled()
  }

  return (
    <Main
      isTableExpanded={isTableExpanded}
      onToggleTable={onToggleTable}
      managerReviews={managerReviews}
      isListManagerReviewloading={isListManagerReviewloading}
    />
  )
}

export default UnfilledManagerialPositionTable
