import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import { getKpiDisplayNames } from '../../constants'
import useConfig from '../../hooks/useConfig'

interface IProps {
  kpi: string
  onSelectKpi: (event: any) => null
  shouldShowScorecard: boolean
}

const useStyles = makeStyles(() => ({
  text: {
    fontFamily: 'Lexend-Regular',
  },
}))

function Main({ kpi, onSelectKpi, shouldShowScorecard }: IProps) {
  const classes = useStyles()
  const config = useConfig()
  const scorecardKpis = ['score', ...config.scorecardCategories]
  const kpiDisplayNames = getKpiDisplayNames()

  return (
    <KpiSelect value={kpi} onChange={onSelectKpi} className={classes.text}>
      {scorecardKpis
        .filter(
          (kpi) => kpi !== 'score' || (kpi === 'score' && shouldShowScorecard),
        )
        .map((kpi) => (
          <MenuItem className={classes.text} value={kpi}>
            {kpiDisplayNames[kpi]}
          </MenuItem>
        ))}
    </KpiSelect>
  )
}

const KpiSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

export default Main
