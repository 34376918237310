import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { getLocationCode, getLocationName } from 'pared/utils/location'

import {
  GET_LOCATION_INFO,
  IGetLocationInfo,
  IGetLocationInfoVariables,
} from '../gql'

export interface ILocationInfo {
  id: number
  code: string
  name: string
  displayName: string
  doEmployeeId: number | null
  doName: string
  gmEmployeeId: number | null
  gmName: string
  locationGroups:
    | {
        type: string
        name: string
        id: number
      }[]
    | null
}

const useLocationInfo = (locationId: number): ILocationInfo | null => {
  const { data } = useQuery<IGetLocationInfo, IGetLocationInfoVariables>(
    GET_LOCATION_INFO,
    {
      variables: {
        iFilter: {
          location_ids: [locationId],
        },
      },
    },
  )

  const locationInfo = useMemo(() => {
    const nodes = data?.listLocationDetails?.nodes || []
    if (nodes.length === 0) {
      return null
    }

    const location = nodes[0]

    const locationCode = getLocationCode(locationId, location.code || '')
    const locationName = getLocationName(locationId, location.name || '')

    return {
      id: location.id,
      code: locationCode,
      name: locationName,
      displayName: [locationCode, locationName].join(' - '),
      doEmployeeId: location.directorEmployeeInfo
        ? location.directorEmployeeInfo.id
        : null,
      doName: location.directorEmployeeInfo
        ? `${location.directorEmployeeInfo.preferredName} ${location.directorEmployeeInfo.familyName}`
        : '-',
      gmEmployeeId: location.gmEmployeeInfo ? location.gmEmployeeInfo.id : null,
      gmName: location.gmEmployeeInfo
        ? `${location.gmEmployeeInfo.preferredName} ${location.gmEmployeeInfo.familyName}`
        : '-',
      locationGroups: location.locationGroups,
    }
  }, [data])

  return locationInfo
}

export default useLocationInfo
