import _ from 'lodash'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import NormalizationStatus from 'pared/components/NormalizationStatus'
import Tooltip from 'pared/components/basicUi/Tooltip'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import COLORS from 'pared/constants/colors'
import { getBrandSettings, getBusinessLabel } from 'pared/customer'
import {
  IDateRange,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_TRAILING_7_DAYS,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'

import CustomBreakdownTable from './CustomBreakdownTable'
import DirectorFilterSelector from './DirectorFilterSelector'
import Stores from './Stores'
import useConfig from './hooks/useConfig'
import {
  IFormattedDoBreakdownInfo,
  IFormattedLocationGroupBreakdownInfo,
  IFormattedStoreInfo,
  ISummaryPerformance,
} from './index'

interface IProps {
  navParams: INavParams
  isLoading: boolean
  allDirectors: IDirector[]
  selectedDateRange: IDateRange | null
  selectedDirector: IDirector | null
  selectedLocationGroupIds: number[]
  onDirectorChange: (directorEmployeeId: number) => void
  summaryPerformance: ISummaryPerformance
  sortedStoreList: IFormattedStoreInfo[]
  sortedDoBreakdownList: IFormattedDoBreakdownInfo[] | null
  sortedLocationGroupBreakdownList:
    | IFormattedLocationGroupBreakdownInfo[]
    | null
  breakdownType: string | null
  handleLocationGroupNameClick: (
    locationGroupId: number,
    locationGroupName: string,
  ) => void
  handleGoBack: () => void
  selectedLocationGroup: { id: number; name?: string } | null
  errorMessage: string
}

interface IKpiTableTd {
  bold?: boolean
  textAlign?: string
  danger?: boolean
}

interface IKpiTableTh {
  textAlign?: string
  italic?: boolean
}

function WebLargeMain({
  navParams,
  isLoading,
  allDirectors,
  selectedDateRange,
  selectedDirector,
  selectedLocationGroupIds,
  onDirectorChange,
  summaryPerformance,
  sortedStoreList,
  sortedDoBreakdownList,
  sortedLocationGroupBreakdownList,
  breakdownType,
  handleLocationGroupNameClick,
  handleGoBack,
  selectedLocationGroup,
  errorMessage,
}: IProps) {
  const config = useConfig()
  if (errorMessage || !selectedDirector) {
    return (
      <MainContainer>
        <PageHeader>{config.pageTitle || 'Systemwide Overview'}</PageHeader>
        <PageStatusDiv>{errorMessage}</PageStatusDiv>
      </MainContainer>
    )
  }

  if (isLoading || !selectedDateRange) {
    return (
      <MainContainer>
        <PageHeader>{config.pageTitle || 'Systemwide Overview'}</PageHeader>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </MainContainer>
    )
  }

  const brandSettings = getBrandSettings()
  const financialLabel = brandSettings.labels.financial
  const isBreakdownByDirectors =
    selectedDirector && selectedDirector.employeeId === -2
  const isBreakdownByLocationGroupType =
    breakdownType !== null && selectedLocationGroup === null
  const isBackButtonVisible =
    breakdownType !== null && selectedLocationGroup !== null
  const isAvtMonthlyVisible =
    brandSettings.overview.avtMonthly.isVisible &&
    ![TYPE_YESTERDAY, TYPE_TRAILING_7_DAYS].includes(selectedDateRange.type)
  const displayPeriodCogs =
    config.usePeriodCogs &&
    [TYPE_PERIOD, TYPE_QUARTER, TYPE_YEAR].includes(selectedDateRange.type)

  const netSales = _.get(summaryPerformance, 'netSales', '-')
  const netSalesBudgetVariance = _.get(
    summaryPerformance,
    'netSalesBudgetVariance',
    '-',
  )
  const grossSales = _.get(summaryPerformance, 'grossSales', '-')
  const grossSalesBudgetVariance = _.get(
    summaryPerformance,
    'grossSalesBudgetVariance',
    '-',
  )
  const netSalesSssPercent = _.get(
    summaryPerformance,
    'netSalesSssPercent',
    '-',
  )
  const grossSalesSssPercent = _.get(
    summaryPerformance,
    'grossSalesSssPercent',
    '-',
  )
  const checkCount = _.get(summaryPerformance, 'checkCount', '-')
  const checkCountVsLyPercent = _.get(
    summaryPerformance,
    'checkCountVsLyPercent',
    '-',
  )
  const checkAverage = _.get(summaryPerformance, 'checkAverage', '-')
  const hourlyLaborPerformanceAgainstBudgetPercent = _.get(
    summaryPerformance,
    'hourlyLaborPerformanceAgainstBudgetPercent',
    '-',
  )
  const hourlyLaborPercent = _.get(
    summaryPerformance,
    'hourlyLaborPercent',
    '-',
  )
  const cogsPercent = _.get(summaryPerformance, 'cogsPercent', '-')
  const cogsBudgetVariance =
    cogsPercent === '-'
      ? '-'
      : _.get(summaryPerformance, 'cogsBudgetVariance', '-')
  const periodCogsPercent = _.get(summaryPerformance, 'periodCogsPercent', '-')
  const periodCogsBudgetVariance =
    periodCogsPercent === '-'
      ? '-'
      : _.get(summaryPerformance, 'periodCogsBudgetVariance', '-')
  const rcpPercent = _.get(summaryPerformance, 'rcpPercent', '-')
  const salesPerLaborHour = _.get(summaryPerformance, 'salesPerLaborHour', '-')
  const avtPercent = _.get(summaryPerformance, 'avtPercent', '-')
  const avtPercentWeekly = _.get(summaryPerformance, 'avtPercentWeekly', '-')
  const avtPercentMonthly = _.get(summaryPerformance, 'avtPercentMonthly', '-')
  const isMonthlyAvtReady = avtPercentMonthly !== '-'
  const plvPercent = _.get(summaryPerformance, 'plvPercent', '-')
  const sosAvgSeconds = _.get(summaryPerformance, 'sosAvgSeconds', '-')

  const isNetSalesSssPercentNegative = _.includes(
    _.get(summaryPerformance, 'netSalesSssPercent', '0'),
    '(',
  )
  const isGrossSalesSssPercentNegative = _.includes(
    _.get(summaryPerformance, 'grossSalesSssPercent', '0'),
    '(',
  )
  const isNetSalesBudgetVarianceNegative = _.includes(
    _.get(summaryPerformance, 'netSalesBudgetVariance', '0'),
    '(',
  )
  const isgrossSalesBudgetVarianceNegative = _.includes(
    _.get(summaryPerformance, 'grossSalesBudgetVariance', '0'),
    '(',
  )
  const isCogsBudgetVarianceNegative = _.includes(
    _.get(summaryPerformance, 'cogsBudgetVariance', '0'),
    '(',
  )
  const isPeriodCogsBudgetVarianceNegative = _.includes(
    _.get(summaryPerformance, 'periodCogsBudgetVariance', '0'),
    '(',
  )
  const isLaborPercentNegative = _.includes(
    hourlyLaborPerformanceAgainstBudgetPercent,
    '(',
  )
  const isAvtPercentNegative = _.includes(avtPercent, '(')
  const isAvtPercentWeeklyNegative = _.includes(avtPercentWeekly, '(')
  const isAvtPercentMonthlyNegative = _.includes(avtPercentMonthly, '(')
  const isPlvPercentNegative = _.includes(plvPercent, '(')

  return (
    <>
      <SelectorContainer>
        <DateRangeSelector
          navParams={navParams}
          dateRangeOptions={config.dateRangeOptions}
          preSelectedDateRange={config.preSelectedDateRange}
        />
      </SelectorContainer>
      <MainContainer>
        <PageHeaderContainer>
          <LeftHeaderContainer>
            <PageHeader>{config.pageTitle || 'Systemwide Overview'}</PageHeader>
            <NormalizationStatusDiv>
              <NormalizationStatus
                normalizationTable="location_normalized_data_daily"
                brandId={brandSettings.brandId}
              />
            </NormalizationStatusDiv>
          </LeftHeaderContainer>
          <RightHeaderContainer></RightHeaderContainer>
        </PageHeaderContainer>
        <SystemOverviewContainer>
          <KpiTable>
            <tbody>
              {!brandSettings.overview.netSales.isVisible ? null : (
                <tr>
                  <KpiTableTd textAlign={'left'}>Total Sales</KpiTableTd>
                  <KpiTableTd bold={netSales !== 'loading'}>
                    {netSales}
                  </KpiTableTd>
                </tr>
              )}
              {!brandSettings.overview.grossSales.isVisible ? null : (
                <tr>
                  <KpiTableTd textAlign={'left'}>Total Sales</KpiTableTd>
                  <KpiTableTd bold={grossSales !== 'loading'}>
                    {grossSales}
                  </KpiTableTd>
                </tr>
              )}
              {!brandSettings.overview.netSalesSssPercent.isVisible ? null : (
                <tr>
                  <KpiTableTd textAlign={'left'}>
                    {financialLabel.sss}
                    {config.tooltips?.sss ? (
                      <Tooltip title={config.tooltips.sss} placement="right" />
                    ) : null}
                  </KpiTableTd>
                  <KpiTableTd
                    bold={netSalesSssPercent !== 'loading'}
                    danger={isNetSalesSssPercentNegative}
                  >
                    {netSalesSssPercent}
                  </KpiTableTd>
                </tr>
              )}
              {!brandSettings.overview.grossSalesSssPercent.isVisible ? null : (
                <tr>
                  <KpiTableTd textAlign={'left'}>
                    {financialLabel.sss}
                    {config.tooltips?.sss ? (
                      <Tooltip title={config.tooltips.sss} placement="right" />
                    ) : null}
                  </KpiTableTd>
                  <KpiTableTd
                    bold={grossSalesSssPercent !== 'loading'}
                    danger={isGrossSalesSssPercentNegative}
                  >
                    {grossSalesSssPercent}
                  </KpiTableTd>
                </tr>
              )}
              {!brandSettings.overview.netSalesBudgetVariance
                .isVisible ? null : (
                <tr>
                  <KpiTableTd textAlign={'left'}>
                    {financialLabel.netSalesVariance ||
                      `Net Sales ${financialLabel.variance}`}
                  </KpiTableTd>
                  <KpiTableTd
                    bold={netSalesBudgetVariance !== 'loading'}
                    danger={isNetSalesBudgetVarianceNegative}
                  >
                    {netSalesBudgetVariance}
                  </KpiTableTd>
                </tr>
              )}
              {!brandSettings.overview.grossSales.isVisible ? null : (
                <tr>
                  <KpiTableTd
                    textAlign={'left'}
                  >{`Gross Sales ${financialLabel.variance}`}</KpiTableTd>
                  <KpiTableTd
                    bold={grossSalesBudgetVariance !== 'loading'}
                    danger={isgrossSalesBudgetVarianceNegative}
                  >
                    {grossSalesBudgetVariance}
                  </KpiTableTd>
                </tr>
              )}
              {!brandSettings.overview.checkCount.isVisible ? null : (
                <tr>
                  <KpiTableTd textAlign={'left'}>
                    {financialLabel.transaction}
                    {config.tooltips?.transaction ? (
                      <Tooltip
                        title={config.tooltips.transaction}
                        placement="right"
                      />
                    ) : null}
                  </KpiTableTd>
                  <KpiTableTd bold={checkCount !== 'loading'}>
                    {checkCount}
                  </KpiTableTd>
                </tr>
              )}
              {!brandSettings.overview.checkCountVsLyPercent
                .isVisible ? null : (
                <tr>
                  <KpiTableTd textAlign={'left'}>
                    {financialLabel.transactionVsLyPercent}
                    {config.tooltips?.transactionVsLyPercent ? (
                      <Tooltip
                        title={config.tooltips.transactionVsLyPercent}
                        placement="right"
                      />
                    ) : null}
                  </KpiTableTd>
                  <KpiTableTd bold={checkCountVsLyPercent !== 'loading'}>
                    {checkCountVsLyPercent}
                  </KpiTableTd>
                </tr>
              )}
              {!brandSettings.overview.checkAverage.isVisible ? null : (
                <tr>
                  <KpiTableTd textAlign={'left'}>
                    {financialLabel.transactionAvg}
                    {config.tooltips?.transactionAvg ? (
                      <Tooltip
                        title={config.tooltips.transactionAvg}
                        placement="right"
                      />
                    ) : null}
                  </KpiTableTd>
                  <KpiTableTd bold={checkAverage !== 'loading'}>
                    {checkAverage}
                  </KpiTableTd>
                </tr>
              )}
              <tr>
                <KpiTableTd textAlign={'left'}>
                  {financialLabel.hourlyLabor} %
                </KpiTableTd>
                <KpiTableTd bold={hourlyLaborPercent !== 'loading'}>
                  {hourlyLaborPercent}
                </KpiTableTd>
              </tr>
              <tr>
                <KpiTableTd textAlign={'left'}>
                  {financialLabel.hourlyLaborVariance ||
                    `${financialLabel.hourlyLabor} ${financialLabel.variance}`}
                </KpiTableTd>
                <KpiTableTd
                  bold={
                    hourlyLaborPerformanceAgainstBudgetPercent !== 'loading'
                  }
                  danger={!isLaborPercentNegative}
                >
                  {hourlyLaborPerformanceAgainstBudgetPercent}
                </KpiTableTd>
              </tr>
              {!brandSettings.overview.salesPerLaborHour.isVisible ? null : (
                <tr>
                  <KpiTableTd textAlign={'left'}>
                    {financialLabel.salesPerLaborHour}
                  </KpiTableTd>
                  <KpiTableTd bold={salesPerLaborHour !== 'loading'}>
                    {salesPerLaborHour}
                  </KpiTableTd>
                </tr>
              )}
              {!brandSettings.overview.sosAvgSeconds.isVisible ? null : (
                <tr>
                  <KpiTableTd textAlign={'left'}>
                    {financialLabel.sos}
                  </KpiTableTd>
                  <KpiTableTd bold={sosAvgSeconds !== 'loading'}>
                    {sosAvgSeconds}
                  </KpiTableTd>
                </tr>
              )}
              {!brandSettings.overview.avt.isVisible ? null : (
                <tr>
                  <KpiTableTd textAlign={'left'} danger={isAvtPercentNegative}>
                    {financialLabel.avt}
                  </KpiTableTd>
                  <KpiTableTd bold={avtPercent !== 'loading'}>
                    {avtPercent}
                  </KpiTableTd>
                </tr>
              )}
              {!isAvtMonthlyVisible ? null : (
                <tr>
                  <KpiTableTd
                    textAlign={'left'}
                    danger={
                      isMonthlyAvtReady
                        ? isAvtPercentMonthlyNegative
                        : isAvtPercentWeeklyNegative
                    }
                  >
                    {financialLabel.avt}
                  </KpiTableTd>
                  <KpiTableTd
                    bold={
                      isMonthlyAvtReady
                        ? avtPercentMonthly !== 'loading'
                        : avtPercentWeekly !== 'loading'
                    }
                  >
                    {isMonthlyAvtReady ? avtPercentMonthly : avtPercentWeekly}
                  </KpiTableTd>
                </tr>
              )}
              {!brandSettings.overview.plv.isVisible ? null : (
                <tr>
                  <KpiTableTd textAlign={'left'} danger={isPlvPercentNegative}>
                    {financialLabel.plv}
                  </KpiTableTd>
                  <KpiTableTd bold={plvPercent !== 'loading'}>
                    {plvPercent}
                  </KpiTableTd>
                </tr>
              )}
            </tbody>
          </KpiTable>
          <KpiTable>
            <tbody>
              {!brandSettings.overview.cogs.isVisible ? null : (
                <tr>
                  <KpiTableTd textAlign={'left'}>
                    {financialLabel.cogs} %
                    {config.tooltips?.cogsKpi &&
                    selectedDateRange.type === TYPE_PERIOD ? (
                      <Tooltip title={config.tooltips.cogsKpi} />
                    ) : null}
                  </KpiTableTd>
                  <KpiTableTd
                    bold={
                      displayPeriodCogs
                        ? periodCogsPercent !== 'loading'
                        : cogsPercent !== 'loading'
                    }
                  >
                    {displayPeriodCogs ? periodCogsPercent : cogsPercent}
                  </KpiTableTd>
                </tr>
              )}
              {!brandSettings.overview.cogsBudgetVariance.isVisible ? null : (
                <tr>
                  <KpiTableTd textAlign={'left'}>
                    {financialLabel.cogsVariance ||
                      `${financialLabel.cogs} ${financialLabel.variance}`}
                  </KpiTableTd>
                  <KpiTableTd
                    danger={
                      displayPeriodCogs
                        ? !isPeriodCogsBudgetVarianceNegative
                        : !isCogsBudgetVarianceNegative
                    }
                    bold={
                      displayPeriodCogs
                        ? periodCogsBudgetVariance !== 'loading'
                        : cogsBudgetVariance !== 'loading'
                    }
                  >
                    {displayPeriodCogs
                      ? periodCogsBudgetVariance
                      : cogsBudgetVariance}
                  </KpiTableTd>
                </tr>
              )}
              {!brandSettings.overview.rcp.isVisible ? null : (
                <tr>
                  <KpiTableTd textAlign={'left'}>
                    {financialLabel.rcp} %
                  </KpiTableTd>
                  <KpiTableTd bold={rcpPercent !== 'loading'}>
                    {rcpPercent}
                  </KpiTableTd>
                </tr>
              )}
            </tbody>
          </KpiTable>
          <VerticalSpacer30px />
          <BreakdownDiv>
            <BreakdownButton
              isClickable={isBackButtonVisible}
              onClick={isBackButtonVisible ? handleGoBack : () => {}}
            >
              {isBreakdownByDirectors
                ? `Breakdown by ${getBusinessLabel('director')}`
                : breakdownType !== null
                ? `Breakdown by ${breakdownType}`
                : `Breakdown by ${getBusinessLabel('store')}`}
            </BreakdownButton>
            {isBackButtonVisible ? (
              <div>{`/ ${selectedLocationGroup?.name}`}</div>
            ) : null}
          </BreakdownDiv>
          <DirectorFilterSelectorContainer>
            <DirectorFilterSelector
              allDirectors={allDirectors}
              selectedDirector={selectedDirector}
              onDirectorChange={onDirectorChange}
              temporaryValue={selectedLocationGroup?.name}
            />
          </DirectorFilterSelectorContainer>
        </SystemOverviewContainer>

        {brandSettings.overview.breakdownByStore.isCustom ? (
          <CustomBreakdownTable
            isBreakdownByDirectors={isBreakdownByDirectors}
            locationGroupId={selectedDirector.locationGroupId}
          />
        ) : (
          <Stores
            isBreakdownByDirectors={isBreakdownByDirectors}
            isBreakdownByLocationGroupType={isBreakdownByLocationGroupType}
            locationGroupType={breakdownType}
            handleLocationGroupNameClick={handleLocationGroupNameClick}
            selectedLocationGroupIds={selectedLocationGroupIds}
            dataSource={
              isBreakdownByDirectors
                ? sortedDoBreakdownList || []
                : isBreakdownByLocationGroupType
                ? sortedLocationGroupBreakdownList || []
                : sortedStoreList
            }
            isAvtMonthlyVisible={isAvtMonthlyVisible}
          />
        )}

        <VerticalSpacer80px />
      </MainContainer>
    </>
  )
}

const MainContainer = styled.div`
  padding: 30px 25px 50px 25px;
`

const SelectorContainer = styled.div`
  padding: 10px 25px 10px 25px;
  background-color: rgb(196, 196, 196);
`

const KpiTable = styled.table`
  width: 88vw;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }
`

const KpiTableTh = styled.th<IKpiTableTh>`
  font-family: ${(props) =>
    props.italic ? 'Lexend-Regular' : 'Lexend-SemiBold;'};
  font-size: 14px;
  font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
  font-weight: ${(props) => (props.italic ? '400' : '700')};
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'right')};
`

const KpiTableTd = styled.th<IKpiTableTd>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: ${(props) => (props.bold ? '20px' : '16px')};
  font-style: normal;
  font-weight: ${(props) => (props.bold ? '700' : '400')};
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'right')};
  line-height: 30px;
  color: ${(props) => (props.danger ? COLORS.Pomodoro : COLORS.Chalkboard)};
`

const BreakdownDiv = styled.div`
  font-family: 'Lexend-SemiBold';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  display: flex;
  gap: 10px;
`

const BreakdownButton = styled.div<{ isClickable: boolean }>`
  ${({ isClickable }) =>
    isClickable
      ? `
      color: ${COLORS.Link};
      cursor: pointer;
      text-decoration: underline;
    `
      : ''}
`

const VerticalSpacer30px = styled.div`
  height: 30px;
`

const VerticalSpacer80px = styled.div`
  height: 80px;
`

const LeftHeaderContainer = styled.div`
  float: left;
`

const NormalizationStatusDiv = styled.div`
  padding: 3px 0 20px 2px;
`

const RightHeaderContainer = styled.div`
  float: right;
`

const SystemOverviewContainer = styled.div`
  padding-bottom: 10px;
`

const PageHeaderContainer = styled.div`
  padding: 0 0 30px 0;
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 0 20px 0 0;
`

const DirectorFilterSelectorContainer = styled.div`
  margin: 5px 0 0 0;
`

const PageStatusDiv = styled.div`
  padding: 30px 0 0 0;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

export default WebLargeMain
