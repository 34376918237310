import { Chart, ChartType } from 'chart.js'
import _ from 'lodash'

import base from './base'

export default _.merge({}, base, {
  type: 'bubbleWithLabels' as ChartType,
  options: {
    plugins: {
      legend: {
        position: 'top',
      },
      labelsButton: {
        display: true,
      },
      zoom: {
        limits: {
          x: { min: 'original', max: 'original' },
          y: { min: 'original', max: 'original' },
        },
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
          onZoomComplete: ({ chart }: { chart: Chart }) => {
            chart.update('none')
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
        },
      },
    },
    interaction: {
      mode: 'nearest',
      intersect: true,
    },
  },
})
