import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { toUsdStr } from 'pared/utils/number'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

interface IListConsolidatedGfpReportsNodeType {
  kpis: string
  kpiDisplayIndex: number
  kpiValue: number
  kpiPercentage: number
  ttmKpiValue: number
  ttmKpiPercentage: number
}

const query = gql`
  query ListConsolidatedGfpReport(
    $iStartDate: Date!
    $iEndDate: Date!
    $iBrand: Int!
    $isCorp: Boolean!
    $isSunrise: Boolean!
    $isOthers: Boolean!
  ) {
    listConsolidatedGfpReports(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iBrand: $iBrand
    ) @include(if: $isOthers) {
      nodes {
        kpis
        kpiDisplayIndex
        kpiValue
        kpiPercentage
        ttmKpiValue
        ttmKpiPercentage
      }
    }

    listCorpGfpReports(iStartDate: $iStartDate, iEndDate: $iEndDate)
      @include(if: $isCorp) {
      nodes {
        kpis
        kpiDisplayIndex
        kpiValue
        kpiPercentage
        ttmKpiValue
        ttmKpiPercentage
      }
    }

    listGfpSunrisedReports(iStartDate: $iStartDate, iEndDate: $iEndDate)
      @include(if: $isSunrise) {
      nodes {
        kpis
        kpiDisplayIndex
        kpiValue
        kpiPercentage
        ttmKpiValue
        ttmKpiPercentage
      }
    }
  }
`

export const lfrListConsolidatedGfpReportConfigs = {
  name: 'string',
  kpiValue: 'string',
  kpiPercentage: 'percent',
  ttmKpiValue: 'string',
  ttmKpiPercentage: 'percent',
} as const

const GROUP_MAPPING = {
  corp: {
    Covers: 0,
    PPA$: 0,
    'Mgmt Fees': 1,
    'Royalty Fees': 1,
    'Other Income': 1,
    'Total Sales': 1,
    'COGS - Fees': 2,
    'COGS - Royalties': 2,
    'COGS - Other': 2,
    'Total Labor': 2,
    'Prime Costs': 2,
    Controllables: 3,
    'R&M': 3,
    Utilities: 3,
    Occupancy: 3,
    Advertising: 3,
    Insurance: 3,
    'G&A Exp': 3,
    'Mgmt Fee': 3,
    EBITDA: 3,
    'Debt Serv': 4,
    CAPEX: 4,
    NWC: 4,
    'Net Cash Flow': 4,
    'Open Balance': 5,
    'Cash Position': 5,
  },
  sunrise: {
    'Food - External': 0,
    'Food - El Fenix': 0,
    'Food - Other': 0,
    'Total Sales': 0,
    'COGS - Food': 1,
    'COGS - Packaging': 1,
    'COGS - Other': 1,
    'COGS - Labor': 1,
    'Gross Profit': 1,
    'Mgmt Payroll': 2,
    'R&M': 2,
    Utilities: 2,
    Occupancy: 2,
    'Sales & Marketing': 2,
    Insurance: 2,
    'G&A Exp': 2,
    'Mgmt Fee': 2,
    EBITDA: 2,
    'Debt Serv': 3,
    CAPEX: 3,
    NWC: 3,
    'Net Cash Flow': 3,
    'Open Balance': 4,
    'Cash Position': 4,
  },
  others: {
    Covers: 0,
    PPA$: 0,
    'Sales - Food': 1,
    'Sales - Bar': 1,
    'Sales - Other': 1,
    'Total Sales': 1,
    'COGS - Food': 2,
    'COGS - Bar': 2,
    'COGS - Other': 2,
    'Total Labor': 2,
    'Prime Costs': 2,
    Controllables: 3,
    'R&M': 3,
    Utilities: 3,
    Occupancy: 3,
    Advertising: 3,
    Insurance: 3,
    'G&A Exp': 3,
    'Mgmt Fee': 3,
    EBITDA: 3,
    'Debt Serv': 4,
    CAPEX: 4,
    NWC: 4,
    'Net Cash Flow': 4,
    'Open Balance': 5,
    'Cash Position': 5,
  },
}

const SUMARY_MAPPING = {
  corp: {
    0: '-',
    1: 'Total Sales',
    2: 'Prime Costs',
    3: 'EBITDA',
    4: 'Net Cash Flow',
    5: 'Cash Position',
  },
  sunrise: {
    0: 'Total Sales',
    1: 'Gross Profit',
    2: 'EBITDA',
    3: 'Net Cash Flow',
    4: 'Cash Position',
  },
  others: {
    0: '-',
    1: 'Total Sales',
    2: 'Prime Costs',
    3: 'EBITDA',
    4: 'Net Cash Flow',
    5: 'Cash Position',
  },
}

const useLfrListConsolidatedGfpReport = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const brand =
    {
      9992: 'corp',
      9991: 'sunrise',
    }[groupFilter?.id || ''] || 'others'

  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iBrand: groupFilter?.ids[0],
      isCorp: brand === 'corp',
      isSunrise: brand === 'sunrise',
      isOthers: brand === 'others',
    },
    skip: !startDate || !endDate || !groupFilter || !hasGroupBy,
  })

  return {
    data: useMemo((): IApiDataType => {
      const rawReports: IListConsolidatedGfpReportsNodeType[] | undefined =
        data?.listCorpGfpReports?.nodes ||
        data?.listGfpSunrisedReports?.nodes ||
        data?.listConsolidatedGfpReports?.nodes

      const reports = rawReports?.map((r) => {
        return {
          id: `${brand}-${r.kpis}`,
          name: r.kpis,
          groupId: (GROUP_MAPPING as any)[brand][r.kpis],
          kpis: r.kpis,
          kpiValue:
            r.kpis === 'Covers'
              ? (Math.round(r.kpiValue) || '-').toLocaleString()
              : toUsdStr(r.kpiValue || '-'),
          kpiPercentage: r.kpiPercentage,
          ttmKpiValue:
            r.kpis === 'Covers'
              ? (Math.round(r.ttmKpiValue) || '-').toLocaleString()
              : toUsdStr(r.ttmKpiValue || '-'),
          ttmKpiPercentage: r.ttmKpiPercentage,
        }
      })

      return reports?.reduce((result, row) => {
        const groupId = row.groupId
        if (groupId === undefined) return result

        const index = result.findIndex((r: any) => r.id === groupId.toString())
        const groupName = (SUMARY_MAPPING as any)[brand][groupId]

        if (index !== -1) {
          if (groupName !== row.kpis) {
            result[index].source.push(row)
          } else {
            result[index].summary = {
              name: row.kpis,
              kpiValue: row.kpiValue,
              kpiPercentage: row.kpiPercentage,
              ttmKpiValue: row.ttmKpiValue,
              ttmKpiPercentage: row.ttmKpiPercentage,
            }
          }
        } else {
          if (groupName === '-') {
            result.push({
              id: groupId.toString(),
              parentId: 'root',
              source: [row],
              summary: {
                name: ' ',
                kpiValue: null,
                kpiPercentage: null,
                ttmKpiValue: null,
                ttmKpiPercentage: null,
              },
            })
          } else if (groupName !== row.kpis) {
            result.push({
              id: groupId.toString(),
              parentId: 'root',
              source: [row],
              summary: {
                name: groupName,
              },
            })
          }
        }

        return result
      }, [] as any)
    }, [data]),
    loading: loading,
  }
}

export default useLfrListConsolidatedGfpReport
