import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { track } from 'pared/analytics/storeList'
import COLORS from 'pared/constants/colors'

export interface IPropsType {
  name: string
  link: string
  id: number
}

const LinkButton = styled(Link)`
  color: ${COLORS.Link};
`

export const sorter = (a: IPropsType, b: IPropsType) =>
  b.name.localeCompare(a.name)

const GroupLinkButton = ({ link, name, id }: IPropsType) => (
  <LinkButton
    to={link}
    onClick={() => {
      if (id) track.directorProfileLinkClicked(id)
    }}
  >
    {name}
  </LinkButton>
)

export default GroupLinkButton
