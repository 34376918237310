import { useState } from 'react'

import Main from './Main'

interface IProps {
  popoverDescription: string
  children: any
}

function InformationSpan({ popoverDescription, children }: IProps) {
  const [informationAnchorEl, setInformationAnchorEl] = useState(null)

  return (
    <Main
      informationAnchorEl={informationAnchorEl}
      setInformationAnchorEl={setInformationAnchorEl}
      popoverDescription={popoverDescription}
      children={children}
    />
  )
}

export default InformationSpan
