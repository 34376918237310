import { useMemo } from 'react'

import COLORS from 'pared/constants/colors'
import { TYPE_PERIOD, TYPE_QUARTER, TYPE_YEAR } from 'pared/data/getDateRanges'

import { IListLocationVsSystemwideAcrScoreNodesType } from '../gql'

const useChartConfig = (
  data: IListLocationVsSystemwideAcrScoreNodesType[],
  dateType: typeof TYPE_YEAR | typeof TYPE_QUARTER | typeof TYPE_PERIOD,
  locationName: string,
) =>
  useMemo(
    () => ({
      type: 'line' as const,
      data: {
        labels: data?.map(
          ({ businessYear, businessQuarter, businessMonth, businessWeek }) =>
            ({
              [TYPE_YEAR]: `Q${businessQuarter} Y${businessYear}`,
              [TYPE_QUARTER]: `P${businessMonth} ${businessYear}`,
              [TYPE_PERIOD]: `P${businessMonth} W${businessWeek} ${businessYear}`,
            }[dateType]),
        ),
        datasets: [
          {
            label: `${locationName} Average ACR Score`,
            data: data?.map(({ locationAcrScore }) => locationAcrScore),
            borderColor: COLORS.Pomodoro,
            backgroundColor: COLORS.Pomodoro,
          },
          {
            label: 'Systemwide Average ACR Score',
            data: data?.map(({ systemwideAcrScore }) => systemwideAcrScore),
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
        ],
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Store Level ACR Score',
          },
        },
      },
    }),
    [data, dateType],
  )

export default useChartConfig
