import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

interface IListLocationDailyFlashNodeType {
  locationId: number
  netSales: string
  yoyNetSales: string
  mtdNetSales: string
  mtdYoyNetSales: string
  dailyUpDownPercent: string
  mtdUpDownPercent: string
  dailyOverShort: string
  mtdOverShort: string
  checkCount: string
  checkNetSales: string
  checkAverage: string
  trailingCheckCount: string
  trailingNetSales: string
  trailingCheckAverage: string
}

const query = gql`
  query wingitnorthListLocationDailyFlash(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationDailyFlash(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        netSales
        yoyNetSales
        mtdNetSales
        mtdYoyNetSales
        dailyUpDownPercent
        mtdUpDownPercent
        dailyOverShort
        mtdOverShort
        checkCount
        checkNetSales
        checkAverage
        trailingCheckCount
        trailingNetSales
        trailingCheckAverage
      }
    }
  }
`

export const wingitnorthListLocationDailyFlashConfigs = {
  netSales: 'price',
  yoyNetSales: 'price',
  dailyUpDownPercent: 'percent',
  mtdUpDownPercent: 'percent',
  dailyOverShort: 'price',
  mtdOverShort: 'price',
  checkCount: 'number',
  checkAverage: 'price',
  trailingCheckAverage: 'price',
} as const

const useWingitnorthListLocationDailyFlash = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_group_ids: groupFilter?.ids },
    },
    skip: !startDate || !endDate || !hasGroupBy,
  })

  return {
    data: useMemo((): IApiDataType => {
      const listLocationDailyFlash = data?.listLocationDailyFlash.nodes as
        | IListLocationDailyFlashNodeType[]
        | undefined

      if (!listLocationDailyFlash) return null

      const source = listLocationDailyFlash.map(
        (n: IListLocationDailyFlashNodeType) => {
          const groupInfo = groupFilter?.list?.find(
            (l) => l.id === n.locationId,
          )
          const yoyNetSales = parseInt(n?.yoyNetSales || '0', 10)
          const netSales = parseInt(n?.netSales || '0', 10)
          const mtdYoyNetSales = parseInt(n?.mtdYoyNetSales || '0', 10)
          const mtdNetSales = parseInt(n?.mtdNetSales || '0', 10)
          const dailyUpDownPercent = n?.dailyUpDownPercent
          const mtdUpDownPercent = n?.mtdUpDownPercent
          const dailyOverShort = parseInt(n?.dailyOverShort || '0', 10)
          const mtdOverShort = parseInt(n?.mtdOverShort || '0', 10)
          const checkCount = parseInt(n?.checkCount || '0', 10)
          const checkNetSales = parseInt(n?.checkNetSales || '0', 10)
          const checkAverage = n?.checkAverage
          const trailingCheckCount = parseInt(n?.trailingCheckCount || '0', 10)
          const trailingNetSales = parseInt(n?.trailingNetSales || '0', 10)
          const trailingCheckAverage = n?.trailingCheckAverage

          return {
            ...n,
            netSales,
            yoyNetSales,
            mtdNetSales,
            mtdYoyNetSales,
            dailyUpDownPercent,
            mtdUpDownPercent,
            dailyOverShort,
            mtdOverShort,
            checkCount,
            checkNetSales,
            checkAverage,
            trailingCheckCount,
            trailingNetSales,
            trailingCheckAverage,
            groupInfo,
          }
        },
      )

      const total = source?.reduce(
        (sum, row) => ({
          netSales: sum.netSales + (row?.netSales || 0),
          yoyNetSales: sum.yoyNetSales + (row?.yoyNetSales || 0),
          mtdNetSales: sum.mtdNetSales + (row?.mtdNetSales || 0),
          mtdYoyNetSales: sum.mtdYoyNetSales + (row?.mtdYoyNetSales || 0),
          dailyOverShort: sum.dailyOverShort + (row?.dailyOverShort || 0),
          mtdOverShort: sum.mtdOverShort + (row?.mtdOverShort || 0),
          checkCount: sum.checkCount + (row?.checkCount || 0),
          checkNetSales: sum.checkNetSales + (row?.checkNetSales || 0),
          trailingCheckCount:
            sum.trailingCheckCount + (row?.trailingCheckCount || 0),
          trailingNetSales: sum.trailingNetSales + (row?.trailingNetSales || 0),
        }),
        {
          netSales: 0,
          yoyNetSales: 0,
          mtdNetSales: 0,
          mtdYoyNetSales: 0,
          dailyOverShort: 0,
          mtdOverShort: 0,
          checkCount: 0,
          checkNetSales: 0,
          trailingCheckCount: 0,
          trailingNetSales: 0,
        },
      )

      return {
        source,
        summary: {
          ...total,
          dailyUpDownPercent: total.yoyNetSales
            ? (100 * (total.netSales - total.yoyNetSales)) / total.yoyNetSales
            : null,
          mtdUpDownPercent: total.mtdYoyNetSales
            ? (100 * (total.mtdNetSales - total.mtdYoyNetSales)) /
              total.mtdYoyNetSales
            : null,
          checkAverage: total.checkCount
            ? total.checkNetSales / total.checkCount
            : null,
          trailingCheckAverage: total.trailingCheckCount
            ? total.trailingNetSales / total.trailingCheckCount
            : null,
        },
      }
    }, [groupFilter, data]),
    loading,
  }
}

export default useWingitnorthListLocationDailyFlash
