import { gql } from '@apollo/client'

export interface IGetStoreListByBrandVariableTypes {
  iFilter: { location_group_ids: number[] }
}

export interface IStoreListNodeType {
  id: number
  code: string
  name: string
  brandId: number
  directorId: number
  directorEmployeeInfo: {
    id: number
    givenName: string
    familyName: string
    preferredName: string
  }
  gmEmployeeInfo: {
    id: number
    givenName: string
    familyName: string
    preferredName: string
  }
}

export interface IGetStoreListByBrandType {
  listLocationDetails: {
    nodes: IStoreListNodeType[]
  }
}

export const GET_STORE_LIST = gql`
  query GetStoreListByBrand($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        brandId
        directorId
        directorEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        gmEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
      }
    }
  }
`
