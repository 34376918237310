import _ from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import CustomizedReport from 'pared/components/CustomizedReport'
import List from 'pared/components/basicUi/List'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'
import { getBusinessLabel } from 'pared/customer'
import { IDateRange } from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'
import DirectorFilterSelector from 'pared/pages/StoreList/DirectorFilterSelector'

import BreakdownTable from './BreakdownTable'
import Chart from './Chart'
import EntreesPerLaborHour from './EntreesPerLaborHour'
import ProductivityCoefficientChart from './ProductivityCoefficientChart'
import SpeedOfServiceTrendChart from './SpeedOfServiceTrendChart'
import UpsizeTrendChart from './UpsizeTrendChart'
import useSalesmanshipConfig from './hooks/useSalesmanshipConfig'

interface IProps {
  allDirectors: IDirector[]
  selectedDirector: IDirector
  navParams: INavParams
  onDirectorChange: (directorEmployeeId: number) => void
  selectedDateRange: IDateRange | null
  displayData: any
  locationGroupIds: number[] | null
  locationGroupTypes: string[] | null
  selectedLocationGroupId: number
}

interface IKpiDiv {
  book?: boolean
}

function WebLargeMain({
  navParams,
  allDirectors,
  selectedDirector,
  selectedDateRange,
  displayData,
  locationGroupIds,
  locationGroupTypes,
  selectedLocationGroupId,
  onDirectorChange,
}: IProps) {
  const brandSettings = getBrandSettings()
  const { isQsr, isLegacy } = brandSettings
  const salesmanshipConfig = useSalesmanshipConfig()
  const kpiDisplayData = displayData?.kpiDisplayData || []

  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)
  let breakdownTitle = `BREAKDOWN BY ${getBusinessLabel('store').toUpperCase()}`
  let isBreakdownByDirectors = false
  if (selectedDirctorEmployeeId === -2) {
    isBreakdownByDirectors = true

    // breakdownByDirectors
    breakdownTitle = `BREAKDOWN BY ${getBusinessLabel(
      'director',
    ).toUpperCase()}`
  } else if (locationGroupTypes) {
    breakdownTitle = `BREAKDOWN BY ${locationGroupTypes[0]}`.toUpperCase()
  }
  const customizedBreakdownTitle = useMemo(() => {
    if (selectedDirctorEmployeeId === -2) return getBusinessLabel('director')

    if (locationGroupTypes) return locationGroupTypes[0]

    return getBusinessLabel('store')
  }, [selectedDirctorEmployeeId, locationGroupTypes])

  return (
    <>
      <Header>
        <div>
          <h1>Systemwide Salesmanship</h1>

          {isQsr || !isLegacy ? null : (
            <SubtitleContainer>
              Main Dining and Bar Sales Only
            </SubtitleContainer>
          )}

          {salesmanshipConfig.subtitle ? (
            <SubtitleContainer>{salesmanshipConfig.subtitle}</SubtitleContainer>
          ) : null}
        </div>

        <Selectors>
          <DirectorFilterSelector
            selectedDirector={selectedDirector}
            allDirectors={allDirectors}
            onDirectorChange={onDirectorChange}
          />

          <DateRangeSelector navParams={navParams} />
        </Selectors>
      </Header>

      <MainContainer>
        <StyledList
          title="KPIs"
          items={[
            ...kpiDisplayData.map(({ name, displayString }: any) => ({
              key: name,
              value: displayString,
              text: name,
            })),
            ...(!salesmanshipConfig.upsizing
              ? []
              : [
                  {
                    key: 'upsizing',
                    value: (
                      <CustomizedReport
                        reportName="CORPORATE_UPSIZE_KPI"
                        customizedLoadingStatus="0.0%"
                        inputParams={{
                          locationGroupId: selectedLocationGroupId,
                          startDate: selectedDateRange?.startDateStr || '',
                          endDate: selectedDateRange?.endDateStr || '',
                        }}
                      />
                    ),
                    text: '% Upsized',
                  },
                ]),
          ]}
        />

        <VerticalSpacer60px />

        {salesmanshipConfig['salesmanshipChart']?.isVisible ? (
          <>
            <SectionTitleDiv>SALESMANSHIP CHART</SectionTitleDiv>

            <MobileContainer>
              <Chart
                navParams={navParams}
                selectedDateRange={selectedDateRange}
                selectedDirector={selectedDirector}
              />
            </MobileContainer>
            <VerticalSpacer60px />
          </>
        ) : null}

        {salesmanshipConfig['productivityCoefficient']?.isVisible ? (
          <>
            <MobileContainer>
              <ProductivityCoefficientChart />
            </MobileContainer>

            <VerticalSpacer60px />
          </>
        ) : null}

        {!salesmanshipConfig.upsizing ? null : (
          <>
            <SectionTitleDiv>UPSIZING</SectionTitleDiv>

            <CustomizedReport
              reportName={
                isBreakdownByDirectors || locationGroupTypes
                  ? 'LIST_LOCATION_GROUP_UPSIZE_TABLE'
                  : 'LIST_LOCATION_UPSIZE_TABLE'
              }
              breakdownTitle={customizedBreakdownTitle}
              inputParams={{
                ...(locationGroupTypes
                  ? {
                      locationGroupTypes,
                    }
                  : {
                      locationGroupIds,
                    }),
                locationGroupId: selectedLocationGroupId,
                startDate: selectedDateRange?.startDateStr || '',
                endDate: selectedDateRange?.endDateStr || '',
              }}
            />

            <VerticalSpacer60px />

            <SectionTitleDiv>UPSIZING TREND</SectionTitleDiv>

            <MobileContainer>
              <UpsizeTrendChart
                trendChartParams={{
                  iReportName: 'CORPORATE_UPSIZE_KPI',
                  iLocationGroupId: selectedLocationGroupId,
                  iEndDate: selectedDateRange?.endDateStr || '',
                  iGroupBy: 'business_month',
                }}
              />
            </MobileContainer>
          </>
        )}

        <VerticalSpacer60px />

        <SectionTitleDiv>{breakdownTitle}</SectionTitleDiv>
        <BreakdownTable
          locationGroupIds={locationGroupIds}
          locationGroupTypes={locationGroupTypes}
          isBreakdownByDirectors={isBreakdownByDirectors}
          allDirectors={allDirectors}
          startDate={selectedDateRange?.startDateStr || ''}
          endDate={selectedDateRange?.endDateStr || ''}
        />

        <VerticalSpacer60px />

        {!salesmanshipConfig?.speedOfService?.isVisible ? null : (
          <>
            <SectionTitleDiv>SPEED OF SERVICE TREND</SectionTitleDiv>

            <MobileContainer>
              <SpeedOfServiceTrendChart
                trendChartParams={{
                  iLocationGroupId: selectedLocationGroupId,
                  iEndDate: selectedDateRange?.endDateStr || '',
                  iGroupBy: 'business_month',
                }}
              />
            </MobileContainer>

            <VerticalSpacer60px />
          </>
        )}

        {salesmanshipConfig.entreesPerLaborHour?.isVisible ? (
          <>
            <EntreesPerLaborHour locationGroupId={selectedLocationGroupId} />

            <VerticalSpacer60px />
          </>
        ) : null}

        {!salesmanshipConfig.frozenCoke ? null : (
          <>
            <SectionTitleDiv>FROZEN COKE SALES</SectionTitleDiv>

            <CustomizedReport
              reportName={
                isBreakdownByDirectors || locationGroupTypes
                  ? 'IRMG_LIST_LOCATION_GROUP_FROZEN_COKE_TABLE'
                  : 'IRMG_LIST_LOCATION_FROZEN_COKE_TABLE'
              }
              breakdownTitle={customizedBreakdownTitle}
              inputParams={{
                ...(locationGroupTypes
                  ? {
                      locationGroupTypes,
                    }
                  : {
                      locationGroupIds,
                    }),
                locationGroupId: selectedLocationGroupId,
                startDate: selectedDateRange?.startDateStr || '',
                endDate: selectedDateRange?.endDateStr || '',
              }}
            />
          </>
        )}

        {!salesmanshipConfig.coffeeTable ? null : (
          <>
            <SectionTitleDiv>COFFEE</SectionTitleDiv>
            <CustomizedReport
              reportName={
                isBreakdownByDirectors || locationGroupTypes
                  ? 'LIST_LOCATION_GROUP_COFFEE_TABLE'
                  : 'LIST_LOCATION_COFFEE_TABLE'
              }
              breakdownTitle={customizedBreakdownTitle}
              inputParams={{
                ...(locationGroupTypes
                  ? {
                      locationGroupTypes,
                    }
                  : {
                      locationGroupIds,
                    }),
                locationGroupId: selectedLocationGroupId,
                startDate: selectedDateRange?.startDateStr || '',
                endDate: selectedDateRange?.endDateStr || '',
              }}
            />

            <VerticalSpacer60px />

            <SectionTitleDiv>SHAKE</SectionTitleDiv>
            <CustomizedReport
              reportName={
                isBreakdownByDirectors || locationGroupTypes
                  ? 'LIST_LOCATION_GROUP_SHAKE_TABLE'
                  : 'LIST_LOCATION_SHAKE_TABLE'
              }
              breakdownTitle={customizedBreakdownTitle}
              inputParams={{
                ...(locationGroupTypes
                  ? {
                      locationGroupTypes,
                    }
                  : {
                      locationGroupIds,
                    }),
                locationGroupId: selectedLocationGroupId,
                startDate: selectedDateRange?.startDateStr || '',
                endDate: selectedDateRange?.endDateStr || '',
              }}
            />
          </>
        )}

        <VerticalSpacer150px />
      </MainContainer>
    </>
  )
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 36px;
    font-weight: 700;
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 25px 0px;
    }
  }
`

const SubtitleContainer = styled.div`
  position: relative;
  top: 7px;
  height: 0px;
  font-family: Lexend-Regular;
  font-style: italic;

  @media ${MOBILE_WIDTH} {
    position: initial;
    height: auto;
    padding: 0px 25px;
  }
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const MainContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px 25px;
  }
`

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 20px 0;
`

const VerticalSpacer60px = styled.div`
  width: 100%;
  height: 60px;
`

const VerticalSpacer150px = styled.div`
  width: 100%;
  height: 150px;
`
const StyledList = styled(List)`
  margin: 46px 0px 0px;
  width: 100%;
  max-width: 1000px;
`

const MobileContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

export default WebLargeMain
