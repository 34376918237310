import { useState } from 'react'

import Main from './Main'

interface IProps {
  dateRangeStartDate: string
  dateRangeEndDate: string
  locationId: number
  isOpsCompModalVisible: boolean
  setIsOpsCompModalVisible: any
}

const OpsCompModal = ({
  dateRangeStartDate,
  dateRangeEndDate,
  locationId,
  isOpsCompModalVisible,
  setIsOpsCompModalVisible,
}: IProps) => {
  return (
    <Main
      dateRangeStartDate={dateRangeStartDate}
      dateRangeEndDate={dateRangeEndDate}
      locationId={locationId}
      isOpsCompModalVisible={isOpsCompModalVisible}
      setIsOpsCompModalVisible={setIsOpsCompModalVisible}
    />
  )
}

export default OpsCompModal
