import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { track } from 'pared/analytics/salesmanship'
import { FILTER_TYPE, RANKINGS_ROW_TYPE, SALES_TYPE } from 'pared/constants'
import getPeriods, { IPeriod } from 'pared/data/getPeriods'
import { toUsdString } from 'pared/utils/number'

import Main from './Main'
import useStaffSalesTableConfig from './hooks/useStaffSalesTableConfig'
import useStaffSalesTableData from './hooks/useStaffSalesTableData'

export interface IStaffSale {
  employeeId: number
  dayPart: string
  firstName: string
  lastName: string
  sales: number
  salesRank: number
  salesPpa: number
  salesPpaRank: number
  roleName: string
  daysWorked: number
}

export interface IOrderBy {
  columnName: string
  isAscending: boolean
}

interface IProps {
  storeId: number
  dayPart: string
  startDate: string
  endDate: string
  storeTitle: string
  preSelectedKpi?: string
}

const StaffSalesTable = ({
  storeId,
  dayPart,
  startDate,
  endDate,
  storeTitle,
  preSelectedKpi,
}: IProps) => {
  const staffSalesTableConfig = useStaffSalesTableConfig()

  const [salesType, setSalesType] = useState<string>(
    preSelectedKpi || staffSalesTableConfig.defaultKpi,
  )
  const [orderBy, setOrderBy] = useState<IOrderBy>({
    columnName: 'value',
    isAscending: false,
  })

  const [isStaffSalesExpanded, setIsStaffSalesExpanded] =
    useState<boolean>(false)

  const { isLoading, tableData, matchedMenuKey } = useStaffSalesTableData(
    salesType,
    storeId,
    startDate,
    endDate,
    dayPart,
  )

  const sortedTableData = _.orderBy(
    tableData,
    orderBy.columnName,
    orderBy.isAscending ? 'asc' : 'desc',
  )

  const staffSalesRowData = _.map(sortedTableData, (rowData) => {
    return {
      rank: rowData.rank,
      name: rowData.employeeName,
      roleName: rowData.roleName,
      daysWorked: rowData.daysWorked,
      value: rowData.value,
      displayString: rowData.displayString,
      isStoreAvg: rowData.isStoreAvg,
    }
  })

  const handleSetSalesType = (newSalesType: string) => {
    track.overallStaffSalesTypeChanged(newSalesType)
    setSalesType(newSalesType)
  }

  return (
    <Main
      isLoading={isLoading}
      salesType={matchedMenuKey}
      setSalesType={handleSetSalesType}
      isStaffSalesExpanded={isStaffSalesExpanded}
      setIsStaffSalesExpanded={setIsStaffSalesExpanded}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      staffSalesRowData={staffSalesRowData}
      storeTitle={storeTitle}
      dayPart={dayPart}
    />
  )
}

export default StaffSalesTable
