import GetAppIcon from '@mui/icons-material/GetApp'
import moment, { Moment } from 'moment'
import 'rc-calendar/assets/index.css'
import React, { useState } from 'react'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/constants/styles'

import Picker from './Picker'
import useDataSource from './hooks/useDataSource'

const StyledCSVLink = styled(CSVLink)`
  font-family: Lexend-Regular;
  display: inline-flex;
  align-items: center;
  gap: 5px;

  @media ${MOBILE_WIDTH} {
    margin-left: 25px;
  }
`
const StyledDiv = styled.div`
  display: inline-flex;
  align-items: center;
  max-width: 250px;
  font-family: Lexend-Regular;
  gap: 5px;
`
const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 80px 120px;
  gap: 15px;
  margin-bottom: 15px;

  @media ${MOBILE_WIDTH} {
    margin-left: 25px;
    margin-bottom: 10px;
    gap: 10px;
  }
`
const LoadingDiv = styled.div`
  margin-left: 100px;
  max-width: 100px;
  font-family: Lexend-Regular;
  color: gray;
`

const csvFileName = 'ACCOUNTING_DATA'
const yesterday = moment().subtract(1, 'days')
const lastWeek = yesterday.clone().subtract(1, 'weeks')

const ExportAccountingData = () => {
  const [startDate, setStartDate] = useState(lastWeek)
  const [endDate, setEndDate] = useState(yesterday)

  const disabledEndDate = (endValue: Moment | undefined) => {
    if (!endValue) {
      return false
    }

    if (!startDate) {
      return false
    }
    return (
      endValue.diff(startDate, 'days') < 0 ||
      endValue.diff(yesterday, 'days') > 0
    )
  }
  const disabledStartDate = (startValue: Moment | undefined) => {
    if (!startValue) {
      return false
    }

    if (!endDate) {
      return false
    }
    return (
      endDate.diff(startValue, 'days') < 0 ||
      startValue.diff(yesterday, 'days') > 0
    )
  }

  const { csvData, loading } = useDataSource(startDate, endDate)

  return (
    <>
      <GridWrapper>
        <StyledDiv>Start date :</StyledDiv>
        <Picker
          onChange={setStartDate}
          disabledDate={disabledStartDate}
          value={startDate}
        />
        <StyledDiv>End date :</StyledDiv>
        <Picker
          onChange={setEndDate}
          disabledDate={disabledEndDate}
          value={endDate}
        />
      </GridWrapper>
      {loading ? (
        <LoadingDiv>Loading...</LoadingDiv>
      ) : (
        <StyledCSVLink data={csvData} filename={csvFileName}>
          <GetAppIcon height="30px" />
          <div> Export as CSV</div>
        </StyledCSVLink>
      )}
    </>
  )
}

export default React.memo(ExportAccountingData)
