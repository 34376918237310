import { gql } from '@apollo/client'

export const LIST_LOCATION_EMPLOYEE_OPS_COMPS = gql`
  query ListLocationEmployeeOpsComps(
    $iStartDate: Date!
    $iEndDate: Date!
    $iDayPart: String!
    $iFilter: JSON!
  ) {
    listLocationEmployeeOpsComps(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iDayPart: $iDayPart
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        employeeId
        employeeInfo {
          id
          preferredName
          familyName
        }
        roleId
        roleInfo {
          id
          name
          code
        }
        dayPart
        totalSales
        opsComps
        opsCompsPercent
        opsCompsDetails
      }
    }
  }
`
