import styled from 'styled-components'

import useGroupSelector from 'pared/components/GroupSelector/hooks/useGroupSelector'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import ExpoAiLogo from 'pared/images/Expo/expoai.png'
import { getUser } from 'pared/utils/user'

import ChatBox from './ChatBox'
import Exray from './Exray'
import { PAGE_PADDING_LEFT } from './constants'
import useConfig from './hooks/useConfig'

const MainContainer = styled.div`
  padding: 0 0 50px 0;
`

const PageHeader = styled.div`
  padding: 10px 0 30px ${PAGE_PADDING_LEFT}px;

  @media ${MOBILE_WIDTH} {
    padding: 30px 0 30px 20px;
  }
`

function ExpoAi() {
  const config = useConfig()
  const user = getUser()

  const groupSelector = useGroupSelector({
    initialGroupBy: 'store',
    shouldHideBreakdown: true,
  })

  return (
    <MainContainer>
      <PageHeader>
        <img height={40} src={ExpoAiLogo} alt="Expo AI" />
      </PageHeader>
      {config.showExray && !user.isStoreLevelUser ? (
        <Exray {...groupSelector} />
      ) : null}
      {config.showChatBox ? <ChatBox {...groupSelector} /> : null}
    </MainContainer>
  )
}

export default ExpoAi
