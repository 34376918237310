import { INavParams } from 'pared/Routes/navParams'
import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import { IDateRange } from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import {
  IGlobalCurrentTeamKpis,
  IManagerReview,
  IUnfilledManagerialPosition,
} from './index'
import { IDetailTeamKpi } from './index'

interface IProps {
  navParams: INavParams
  allDirectors: IDirector[]
  selectedDirector: IDirector
  onDirectorChange: (directorEmployeeId: number) => void
  selectedDateRange: IDateRange | null
  globalCurrentTeamKpis: IGlobalCurrentTeamKpis
  itemizedKpis: IDetailTeamKpi[]
  unfilledManagerialPositions: IUnfilledManagerialPosition[]
  unfilledManagerialPositionOption: string
  onSelectUnfilledManagerialPositionOption: (event: any) => void
  performanceOption: string
  onSelectPerformanceOption: (event: any) => void
  managerReviews: IManagerReview[]
  locationGroupIds: number[] | null
  locationGroupTypes: string[] | null
  selectedLocationGroupId: number
}

function Main({
  navParams,
  allDirectors,
  selectedDirector,
  onDirectorChange,
  selectedDateRange,
  globalCurrentTeamKpis,
  itemizedKpis,
  unfilledManagerialPositions,
  unfilledManagerialPositionOption,
  onSelectUnfilledManagerialPositionOption,
  performanceOption,
  onSelectPerformanceOption,
  managerReviews,
  locationGroupIds,
  locationGroupTypes,
  selectedLocationGroupId,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          navParams={navParams}
          allDirectors={allDirectors}
          selectedDirector={selectedDirector}
          selectedDateRange={selectedDateRange}
          onDirectorChange={onDirectorChange}
          globalCurrentTeamKpis={globalCurrentTeamKpis}
          itemizedKpis={itemizedKpis}
          unfilledManagerialPositions={unfilledManagerialPositions}
          unfilledManagerialPositionOption={unfilledManagerialPositionOption}
          onSelectUnfilledManagerialPositionOption={
            onSelectUnfilledManagerialPositionOption
          }
          performanceOption={performanceOption}
          onSelectPerformanceOption={onSelectPerformanceOption}
          managerReviews={managerReviews}
          locationGroupIds={locationGroupIds}
          locationGroupTypes={locationGroupTypes}
          selectedLocationGroupId={selectedLocationGroupId}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          navParams={navParams}
          allDirectors={allDirectors}
          selectedDirector={selectedDirector}
          selectedDateRange={selectedDateRange}
          onDirectorChange={onDirectorChange}
          globalCurrentTeamKpis={globalCurrentTeamKpis}
          itemizedKpis={itemizedKpis}
          unfilledManagerialPositions={unfilledManagerialPositions}
          unfilledManagerialPositionOption={unfilledManagerialPositionOption}
          onSelectUnfilledManagerialPositionOption={
            onSelectUnfilledManagerialPositionOption
          }
          performanceOption={performanceOption}
          onSelectPerformanceOption={onSelectPerformanceOption}
          managerReviews={managerReviews}
          locationGroupIds={locationGroupIds}
          locationGroupTypes={locationGroupTypes}
          selectedLocationGroupId={selectedLocationGroupId}
        />
      </SmallScreen>
    </>
  )
}

export default Main
