import { useEffect, useMemo, useState } from 'react'

import { getBrand } from 'pared/utils/brand'

export interface IKpiFilterType {
  id: string
  parentId: 'root' | null
  value: string
  text: string
}

export const INVENTORY_KPI_TYPE = {
  VARIANCE: 'variance',
  TOTAL_WASTE: 'totalWaste',
  MENU_WASTE: 'menuWaste',
  RAW_WASTE: 'rawWaste',
  AVT: 'avt',
}

const useKpiFilter = () => {
  const [kpiFilter, setKpiFilter] = useState<string[]>([])
  const brand = getBrand()

  const defaultMenuItemList = [
    {
      id: 'root',
      parentId: null,
      value: 'root',
      text: 'root',
    },
    {
      id: INVENTORY_KPI_TYPE.VARIANCE,
      parentId: 'root',
      value: INVENTORY_KPI_TYPE.VARIANCE,
      text: 'Over/Short',
    },
    {
      id: INVENTORY_KPI_TYPE.TOTAL_WASTE,
      parentId: 'root',
      value: INVENTORY_KPI_TYPE.TOTAL_WASTE,
      text: 'Total Waste',
    },
    {
      id: INVENTORY_KPI_TYPE.MENU_WASTE,
      parentId: 'root',
      value: INVENTORY_KPI_TYPE.MENU_WASTE,
      text: 'Menu Waste',
    },
    {
      id: INVENTORY_KPI_TYPE.RAW_WASTE,
      parentId: 'root',
      value: INVENTORY_KPI_TYPE.RAW_WASTE,
      text: 'Raw Waste',
    },
  ]

  const jscMenuItemList = [
    {
      id: 'root',
      parentId: null,
      value: 'root',
      text: 'root',
    },
    {
      id: INVENTORY_KPI_TYPE.AVT,
      parentId: 'root',
      value: INVENTORY_KPI_TYPE.AVT,
      text: 'AvT',
    },
  ]

  const ghaiPopMenuItemList = [
    {
      id: 'root',
      parentId: null,
      value: 'root',
      text: 'root',
    },
    {
      id: INVENTORY_KPI_TYPE.VARIANCE,
      parentId: 'root',
      value: INVENTORY_KPI_TYPE.VARIANCE,
      text: 'Over/Short',
    },
    {
      id: INVENTORY_KPI_TYPE.RAW_WASTE,
      parentId: 'root',
      value: INVENTORY_KPI_TYPE.RAW_WASTE,
      text: 'Waste',
    },
  ]

  const menuItemList = useMemo(() => {
    switch (brand) {
      case 'jsc_bk':
      case 'jsc_bk_nso':
      case 'mwb':
      case 'burgerworks':
        return jscMenuItemList

      case 'ghai_pop':
        return ghaiPopMenuItemList

      default:
        return defaultMenuItemList
    }
  }, [brand])

  useEffect(() => {
    setKpiFilter([menuItemList[1].value])
  }, [menuItemList])

  return {
    kpiFilter,
    setKpiFilter,
    kpiFilters: menuItemList,
  }
}

export default useKpiFilter
