import { useMemo } from 'react'

import { IGroupInfoType } from '../../table/hooks/useGroupInfoColumns'
import useLfrListLocationBusinessReview, {
  lfrListLocationBusinessReviewConfigs,
} from '../../table/hooks/useLfrListLocationBusinessReview'
import useLfrListLocationGuest, {
  lfrListLocationGuestConfigs,
} from '../../table/hooks/useLfrListLocationGuest'

export const lfrBusinessReviewAndGuestConfigs = {
  ...lfrListLocationBusinessReviewConfigs,
  ...lfrListLocationGuestConfigs,
}

const useLfrBusinessReviewAndGuest = () => {
  const lfrListLocationBusinessReview = useLfrListLocationBusinessReview()
  const lfrListLocationGuest = useLfrListLocationGuest()

  return useMemo(
    (): {
      data: { source: { groupInfo?: IGroupInfoType }[]; summary?: {} } | null
      loading: boolean
    } => ({
      data: lfrListLocationBusinessReview?.data
        ? {
            source: lfrListLocationBusinessReview?.data?.source?.map(
              ({ groupInfo, ...d }) => {
                const guest = lfrListLocationGuest?.data?.source?.find(
                  (l) => l.groupInfo?.id === groupInfo?.id,
                )

                return {
                  ...d,
                  ...guest,
                  groupInfo,
                }
              },
            ),
          }
        : null,
      loading:
        lfrListLocationBusinessReview.loading || lfrListLocationGuest.loading,
    }),
    [lfrListLocationBusinessReview, lfrListLocationGuest],
  )
}

export default useLfrBusinessReviewAndGuest
