import { IConfigsType } from '../../types'

const wingitnorthConfig: IConfigsType = {
  wingitnorth: {
    '/:brand/daily_flash': {
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: ['yesterday', 'custom'],
        defaultType: 'yesterday',
        isDailyCustom: true,
      },
      flash: {
        type: 'title',
        title: 'Systemwide Flash Report',
      },
      downloadFlashTable: {
        type: 'download-csv',
        title: 'DAILY FLASH',
        api: 'wingitnorthDailyFlash',
        fileName: 'getexpo-daily-flash',
        fileNameFn: (date: string) => `getexpo-daily-flash-${date}`,
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'netSales',
            title: 'Total Sales',
          },
          {
            key: 'yoyNetSales',
            title: 'Total Sales LY',
          },
          {
            key: 'dailyUpDownPercent',
            title: '% Daily Up/Down',
          },
          {
            key: 'mtdUpDownPercent',
            title: '% Month Up/Down',
          },
          {
            key: 'dailyOverShort',
            title: 'O/S',
          },
          {
            key: 'mtdOverShort',
            title: 'Accum O/S',
          },
          {
            key: 'checkCount',
            title: 'Customer Count',
          },
          {
            key: 'checkAverage',
            title: 'Daily Ticket Avg',
          },
          {
            key: 'trailingCheckAverage',
            title: '4-day Ticket Avg',
          },
        ],
      },

      dailyFlashTable: {
        type: 'table',
        api: 'wingitnorthDailyFlash',
        highlineEvenRows: true,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        columns: [
          {
            key: 'netSales',
            title: 'Total Sales',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'yoyNetSales',
            title: 'Total Sales LY',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'dailyUpDownPercent',
            title: '% Daily Up/Down',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'mtdUpDownPercent',
            title: '% Month Up/Down',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'dailyOverShort',
            title: 'O/S',
            width: '150px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'mtdOverShort',
            title: 'Accum O/S',
            width: '150px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'checkCount',
            title: 'Customer Count',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'checkAverage',
            title: 'Daily Ticket Avg',
            width: '150px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
          {
            key: 'trailingCheckAverage',
            title: '4-day Ticket Avg',
            width: '150px',
            align: 'center',
            sortable: true,
            decimal: 2,
          },
        ],
      },
    },
  },
}

export default wingitnorthConfig
