import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import styled from 'styled-components'

interface IPropsType {
  rootStyle?: string
  spinning?: boolean
  children: React.ReactNode
}

const SpinRoot = styled.div<{
  rootStyle: string
}>`
  position: relative;
  display: inline-block;
  ${({ rootStyle }) => rootStyle}
`

const SpinProgress = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  width: 100%;
  height: 100%;

  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -20px;
  }
`

const SpinContainer = styled.div<{
  spinning: boolean
}>`
  display: inline-block;
  transition: opacity 0.3s;

  ${({ spinning }) =>
    !spinning
      ? ''
      : `
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
  `}
`

const Spin = ({ rootStyle = '', spinning = true, children }: IPropsType) => (
  <SpinRoot rootStyle={rootStyle}>
    {!spinning ? null : (
      <div>
        <SpinProgress>
          <CircularProgress />
        </SpinProgress>
      </div>
    )}

    <SpinContainer spinning={spinning}>{children}</SpinContainer>
  </SpinRoot>
)

export default React.memo(Spin) as unknown as typeof Spin
