import { gql } from '@apollo/client'

export interface IGetDiscountItemTypesVariableTypes {
  iStartDate: string
  iEndDate: string
  iFilter: any
}

interface IDiscountItemNodeType {
  id: number
  itemType: string
  catogories: string
}

export interface IGetDiscountItemTypesType {
  getMarketingAnalysisItems: {
    nodes: IDiscountItemNodeType[]
  }
}

export const TREND_LOCATION_MARKET_ANALYSIS = gql`
  query TrendLocationMarketAnalysis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    trendLocationMarketAnalysis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        dayOfWeek
        compsName
        category
        totalCount
        totalAmount
      }
    }
  }
`

export const GET_MARKETING_ANALYSIS_ITEMS = gql`
  query GetMarketingAnalysisItems(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    getMarketingAnalysisItems(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        id
        itemType
        catogories
      }
    }
  }
`
