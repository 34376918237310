import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { getUser } from 'pared/utils/user'

import { useAdvancedFilter } from '../../advancedFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const queries = gql`
  query ListLocationDevelopmentPlan($iFilter: JSON!, $iUserId: Int!) {
    listLocationDevelopmentPlan(iFilter: $iFilter) {
      nodes {
        planId
        employeeId
        jobTitle
        employeeFirstName
        employeeLastName
        actionItem
        dueDate
        isCompleted
        deletedAt
      }
    }

    employee(id: $iUserId) {
      id
      jobTitle
    }
  }
`

export const jscDevelopmentPlanConfigs = {
  planId: 'string',
  role: 'string',
  name: 'string',
  actionItem: 'string',
  dueDate: 'date',
  isCompleted: 'toggle',
  delete: 'delete-development-plan',
} as const

export const useJscDevelopmentPlanData = () => {
  const user = getUser()
  const userId = user.employeeId ? user.employeeId : -1
  const { groupFilter } = useGroupFilter()

  return useQuery(queries, {
    variables: {
      iFilter: { location_ids: groupFilter?.ids },
      iUserId: userId,
    },
    skip: !groupFilter || !user,
  })
}

const useJscDevelopmentPlan = () => {
  const advancedFilter = useAdvancedFilter('select')
  const { data, loading } = useJscDevelopmentPlanData()

  return {
    data: useMemo((): IApiDataType => {
      let plans = data?.listLocationDevelopmentPlan.nodes

      if (!plans) return null

      plans = plans.filter((plan: any) => !plan.deletedAt)

      if (advancedFilter?.value.plan === 'COMPLETED') {
        plans = plans.filter((plan: any) => plan.isCompleted)
      } else if (advancedFilter?.value.plan === 'OUTSTANDING') {
        plans = plans.filter((plan: any) => !plan.isCompleted)
      }

      if (
        data?.employee.jobTitle === 'Restaurant Leader' ||
        data?.employee.jobTitle === 'Restaurant Leader in Training'
      ) {
        plans = plans.filter(
          (plan: any) =>
            (plan.jobTitle !== 'Restaurant Leader' &&
              plan.jobTitle !== 'Restaurant Leader in Training') ||
            plan.employeeId === data?.employee.id,
        )
      }

      if (advancedFilter?.value.role !== 'ALL')
        plans = plans?.filter(
          (plan: any) => plan.jobTitle === advancedFilter?.value.role,
        )

      return {
        source: plans.map((item: any) => {
          const employeeName = `${item.employeeFirstName} ${item.employeeLastName}`

          return {
            planId: item.planId,
            role: item.jobTitle,
            name: employeeName,
            actionItem: item.actionItem,
            dueDate: item.dueDate,
            isCompleted: {
              checked: item.isCompleted,
            },
            delete: {
              planId: item.planId,
              name: employeeName,
              actionItem: item.actionItem,
            },
          }
        }),
      }
    }, [data, advancedFilter]),
    loading,
  }
}

export default useJscDevelopmentPlan
