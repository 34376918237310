import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query arbListLocationPrimantiTattle(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationPrimantiTattle(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        avgScore
        negativeReviews
        totalReviews
        numOneStar
        numTwoStar
        numThreeStar
        numFourStar
        numFiveStar
      }
    }
  }
`

export const arbListLocationPrimantiTattleConfigs = {
  avgScore: 'number',
  negativeReviews: 'number',
  totalReviews: 'number',
  numOneStar: 'number',
  numTwoStar: 'number',
  numThreeStar: 'number',
  numFourStar: 'number',
  numFiveStar: 'number',
} as const

const useArbListLocationPrimantiTattle = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_group_ids: groupFilter?.ids },
    },
    skip: !startDate || !endDate || !hasGroupBy,
  })

  return {
    data: useMemo((): IApiDataType => {
      const listLocationPrimantiTattle = data?.listLocationPrimantiTattle
        .nodes as
        | {
            locationId: number
          }[]
        | undefined

      if (!listLocationPrimantiTattle) return null

      return {
        source: listLocationPrimantiTattle.map((n) => {
          const groupInfo = groupFilter?.list?.find(
            (l) => l.id === n.locationId,
          )

          return {
            ...n,
            groupInfo,
          }
        }),
      }
    }, [data]),
    loading,
  }
}

export default useArbListLocationPrimantiTattle
