import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import CustomizedReport from 'pared/components/CustomizedReport'
import { ILocationInfo } from 'pared/components/LocationInfo/hooks/useLocationInfo'
import LocationInfo from 'pared/components/LocationInfo/index'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import LocationSelector from 'pared/components/nav/LocationSelector'
import { FILTER_TYPE } from 'pared/constants'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'
import { TYPE_PERIOD } from 'pared/data/getDateRanges'
import { IPeriod } from 'pared/data/getPeriods'
import useGetDateRange from 'pared/hooks/useGetDateRange'

import EntreesPerLaborHourChart from './EntreesPerLaborHour'
import LtoLeaderboard from './Leaderboard/LtoLeaderboard'
import SalesLeaderboard from './Leaderboard/SalesLeaderboard'
import UpsizeLeaderboard from './Leaderboard/UpsizeLeaderboard'
import PlusMinusTable from './PlusMinusTable'
import ProductivityCoefficientChart from './ProductivityCoefficientChart'
import SalesByHourByDayChart from './SalesByHourByDayChart'
import SalesPerLaborHourChart from './SalesPerLaborHour'
import SalesmanshipTrendChart from './SalesmanshipTrendChart'
import SchedulingAnalysisTable from './SchedulingAnalysisTable'
import SpeedOfServiceDayPartTrendChart from './SpeedOfServiceDayPartTrendChart'
import SpeedOfServiceTrendChart from './SpeedOfServiceTrendChart'
import StaffSalesTable from './StaffSalesTable'
import UpsizeTrendChart from './UpsizeTrendChart'
import useSalesmanshipConfig from './hooks/useSalesmanshipConfig'
import { ISalesmanship } from './index'

interface IProps {
  isLoading: boolean
  navParams: INavParams
  locationData: ILocationInfo | null
  errorMessage: string
  salesmanships: ISalesmanship[]
  dayPart: string
  setDayPart: any
  isLeaderboardsExpanded: boolean
  setIsLeaderboardsExpanded: any
  filterType: string
  setFilterType: any
  numDaysWorked: number
  setNumDaysWorked: any
  selectedPeriod: IPeriod | null
  setSelectedPeriod: any
  dateRangeStartDate: any
  setDateRangeStartDate: any
  dateRangeEndDate: any
  setDateRangeEndDate: any
  selectedValue: string
  setSelectedValue: (selectedValue: string) => void
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
  isAvg?: boolean
}

const DATE_FORMAT = 'MM/DD/yyyy'

const WebLargeMain = ({
  isLoading,
  navParams,
  locationData,
  errorMessage,
  salesmanships,
  dayPart,
  isLeaderboardsExpanded,
  setIsLeaderboardsExpanded,
  filterType,
  selectedValue,
}: IProps) => {
  const brandSettings = getBrandSettings()
  const salesmanshipConfig = useSalesmanshipConfig()
  const dateRange = useGetDateRange()
  const startDate = dateRange?.startDateStr || ''
  const endDate = dateRange?.endDateStr || ''

  const onToggleExpandLeaderboard = () => {
    setIsLeaderboardsExpanded(!isLeaderboardsExpanded)
  }

  if (errorMessage) {
    return <PageStatusDiv>{errorMessage}</PageStatusDiv>
  }

  if (!locationData?.id) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  const locationId = locationData.id
  const storeName = locationData.displayName

  const leaderboardToggleText = isLeaderboardsExpanded
    ? 'Collapse All'
    : 'Expand All'

  const storeTitle = `${brandSettings.labels.business.store}: ${storeName}`

  let printableSubtitle = selectedValue
  if (filterType === FILTER_TYPE.SHIFTS) {
    printableSubtitle = `${selectedValue} as of ${moment().format(DATE_FORMAT)}`
  }

  const Leaderboards = !salesmanshipConfig?.leaderboardSections
    ?.length ? null : (
    <>
      <LeaderboardsContainer>Leaderboards</LeaderboardsContainer>
      <VerticalSpacer30px />
      {isLoading ? (
        <LoadingContainer>
          <PageStatusDiv>Loading ...</PageStatusDiv>
        </LoadingContainer>
      ) : (
        <>
          <DetailContainer
            amount={salesmanshipConfig.leaderboardSections.length}
          >
            {salesmanshipConfig.leaderboardSections.map((section) => {
              switch (section) {
                case 'sales':
                  return (
                    <SalesLeaderboard
                      leaderboardTitle="TOTAL SALES"
                      dataColumnHeader="Total Sales"
                      salesLeaderboardData={salesmanships}
                      salesType="grossSales"
                      storeName={storeName}
                      isExpanded={isLeaderboardsExpanded}
                    />
                  )
                case 'ppa':
                  return (
                    <SalesLeaderboard
                      leaderboardTitle={
                        salesmanshipConfig.ppaLabel?.toUpperCase() ??
                        'TOTAL PPA'
                      }
                      dataColumnHeader={
                        salesmanshipConfig.ppaLabel ?? 'Total PPA'
                      }
                      salesLeaderboardData={salesmanships}
                      salesType="salesPpa"
                      numberOfDecimals={2}
                      storeName={storeName}
                      isExpanded={isLeaderboardsExpanded}
                    />
                  )
                case 'salesPerLaborHour':
                  return (
                    <SalesLeaderboard
                      leaderboardTitle="SALES PER LABOR HOUR"
                      dataColumnHeader="Sales/Hour"
                      salesLeaderboardData={salesmanships}
                      salesType="salesPerHour"
                      numberOfDecimals={2}
                      storeName={storeName}
                      isExpanded={isLeaderboardsExpanded}
                    />
                  )
                case 'upsize':
                  return (
                    <UpsizeLeaderboard
                      leaderboardTitle="VALUE MEAL UPSIZE"
                      dataColumnHeader="% Meals Upsized"
                      upsizeType="VM Upsize"
                      locationId={locationId}
                      startDate={startDate}
                      endDate={endDate}
                      dayPart="ALL"
                      storeName={storeName}
                      isExpanded={isLeaderboardsExpanded}
                    />
                  )
                case 'lto':
                  return (
                    <LtoLeaderboard
                      leaderboardTitle="LTO"
                      dataColumnHeader="% Checks with LTO"
                      salesType="LTO"
                      leaderboardType="checkPercent"
                      locationId={locationId}
                      startDate={startDate}
                      endDate={endDate}
                      dayPart="ALL"
                      storeName={storeName}
                      isExpanded={isLeaderboardsExpanded}
                    />
                  )
                case 'wraps':
                  return (
                    <LtoLeaderboard
                      leaderboardTitle="WRAPS"
                      dataColumnHeader="% Checks with Wraps"
                      salesType="Wrap"
                      leaderboardType="checkPercent"
                      locationId={locationId}
                      startDate={startDate}
                      endDate={endDate}
                      dayPart="ALL"
                      storeName={storeName}
                      isExpanded={isLeaderboardsExpanded}
                    />
                  )
                case 'lbw':
                  return (
                    <LtoLeaderboard
                      leaderboardTitle="LBW"
                      dataColumnHeader="% Checks with LBW"
                      salesType="lbw"
                      leaderboardType="checkPercent"
                      locationId={locationId}
                      startDate={startDate}
                      endDate={endDate}
                      dayPart="ALL"
                      storeName={storeName}
                      isExpanded={isLeaderboardsExpanded}
                    />
                  )
                default:
                  return null
              }
            })}
          </DetailContainer>

          <ExpandAllSpan onClick={onToggleExpandLeaderboard}>
            {leaderboardToggleText}
          </ExpandAllSpan>
          <VerticalSpacer40px />
        </>
      )}
    </>
  )

  const Sections = (
    <>
      {salesmanshipConfig.sections.map((section) => {
        switch (section) {
          case 'plusMinusTable':
            return (
              <>
                <PlusMinusTable navParams={navParams} />
                <VerticalSpacer40px />
              </>
            )
          case 'productivityCoefficient':
            return (
              <>
                <MobileContainer>
                  <ProductivityCoefficientChart
                    selectedLocation={locationData}
                  />
                </MobileContainer>
                <VerticalSpacer40px />
              </>
            )
          case 'leaderboards':
            return Leaderboards
          case 'staffSalesTable':
            return salesmanshipConfig?.staffSales?.hide ? null : (
              <>
                <StaffSalesTable
                  storeId={locationId}
                  dayPart={dayPart}
                  startDate={startDate}
                  endDate={endDate}
                  storeTitle={storeTitle}
                />
                <VerticalSpacer40px />
              </>
            )
          case 'salesmanshipTrendChart':
            return (
              <>
                <MobileContainer>
                  <SalesmanshipTrendChart
                    locationId={locationId}
                    endDate={dateRange?.endDateStr || ''}
                  />
                </MobileContainer>
                <VerticalSpacer40px />
              </>
            )
          case 'upsizeTrendChart':
            return (
              <>
                <SectionTitleDiv>UPSIZING TREND</SectionTitleDiv>
                <MobileContainer>
                  <UpsizeTrendChart
                    trendChartParams={{
                      iLocationReportName: 'LOCATION_UPSIZE_KPI',
                      iLocationId: locationId,
                      iLocationGroupReportName: 'CORPORATE_UPSIZE_KPI',
                      iLocationGroupId: brandSettings.brandLocationGroupId,
                      iEndDate: dateRange?.endDateStr || '',
                      iGroupBy: 'business_month',
                    }}
                  />
                </MobileContainer>
                <VerticalSpacer40px />
              </>
            )
          case 'entreesPerLaborHour':
            return (
              <>
                <SectionTitleDiv>Entrees Per Labor Hour</SectionTitleDiv>
                <MobileContainer>
                  <EntreesPerLaborHourChart
                    locationName={locationData.name}
                    locationId={locationId}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </MobileContainer>
                <VerticalSpacer40px />
              </>
            )
          case 'salesPerLaborHour':
            return (
              <>
                <SectionTitleDiv>Sales Per Labor Hour</SectionTitleDiv>
                <SalesPerLaborHourChartContainer>
                  <SalesPerLaborHourChart
                    locationName={locationData.name}
                    locationId={locationId}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </SalesPerLaborHourChartContainer>
                <VerticalSpacer40px />
                <SalesPerLaborHourChartContainer>
                  <SalesByHourByDayChart
                    locationId={locationId}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </SalesPerLaborHourChartContainer>
                <VerticalSpacer40px />
              </>
            )
          case 'schedulingAnalysis':
            return (
              <>
                <SalesPerLaborHourChartContainer>
                  <SchedulingAnalysisTable locationId={locationId} />
                </SalesPerLaborHourChartContainer>
                <VerticalSpacer40px />
              </>
            )
          case 'speedOfService':
            return salesmanshipConfig.speedOfService?.isVisible ? (
              <>
                <SectionTitleDiv>SPEED OF SERVICE TREND</SectionTitleDiv>
                <MobileContainer>
                  <SpeedOfServiceTrendChart
                    trendChartParams={{
                      iLocationId: locationId,
                      iLocationGroupId: brandSettings.brandLocationGroupId,
                      iEndDate: dateRange?.endDateStr || '',
                      iGroupBy: 'business_month',
                    }}
                  />
                </MobileContainer>
                <VerticalSpacer40px />
              </>
            ) : null

          case 'speedOfServiceDayPart':
            return salesmanshipConfig.speedOfService?.isVisible ? (
              <>
                <SectionTitleDiv>SPEED OF SERVICE TREND</SectionTitleDiv>
                <MobileContainer>
                  <SpeedOfServiceDayPartTrendChart
                    trendChartParams={{
                      iLocationId: locationId,
                      iEndDate: dateRange?.endDateStr || '',
                      iGroupBy: 'last_x_weeks',
                      iDateRangeNumber: 12,
                    }}
                  />
                </MobileContainer>
                <VerticalSpacer40px />
              </>
            ) : null
          case 'employeeShifts':
            return (
              <>
                <SectionTitleDiv>Manager on Duty</SectionTitleDiv>
                <CustomizedReport
                  reportName="LIST_LOCATION_EMPLOYEE_SHIFT_DATA"
                  inputParams={{
                    startDate,
                    endDate,
                    locationId,
                  }}
                />
                <VerticalSpacer40px />
              </>
            )
          default:
            return null
        }
      })}
    </>
  )

  return (
    <>
      <Header>
        <div>
          <h1>{storeTitle}</h1>
          <LocationInfo locationId={locationId} />
        </div>

        <Selectors>
          <LocationSelector navParams={navParams} />

          <DateRangeSelector
            navParams={navParams}
            preSelectedDateRange={TYPE_PERIOD}
          />
        </Selectors>
      </Header>

      <MainContainer>
        <VerticalSpacer30px />
        {Sections}
      </MainContainer>
    </>
  )
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 0px 0px;
    }
  }

  & > div:first-child {
    @media ${MOBILE_WIDTH} {
      padding: 0px 25px;
    }
  }
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const MainContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px;
  }
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const VerticalSpacer20px = styled.div`
  padding: 20px 50px 20px 50px;
`

const VerticalSpacer30px = styled.div`
  padding: 30px 50px 30px 50px;
`

const VerticalSpacer40px = styled.div`
  padding: 40px 50px 40px 50px;
`

const ExpandAllSpan = styled.div`
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;

  @media ${MOBILE_WIDTH} {
    display: none;
  }
`

const DetailContainer = styled.div<{
  amount: number
}>`
  display: flex;
  gap: 20px;
  max-width: 1600px;

  @media ${MOBILE_WIDTH} {
    display: block;
  }

  & > div {
    ${({ amount }) => `flex: 1 0 calc(25% - 20px * ${amount - 1} / ${amount});`}

    @media ${MOBILE_WIDTH} {
      &:not(:first-child) {
        margin: 20px 0px 0px;
      }
    }
  }
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  float: left;
`

const LeaderboardsContainer = styled(PageHeader)`
  width: 100%;
`

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 20px 0;
`

const MobileContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const SalesPerLaborHourChartContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    overflow: hidden;
  }
`

const LoadingContainer = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  height: 400px;
  width: 100%;
`

export default WebLargeMain
