import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const jscConfig: IConfigsType = {
  jsc_bk: {
    '/:brand/training_tracker_profile/:id': {
      trainingTracker: {
        type: 'title',
        title: 'jscBaseballCard',
      },
      trainingTrackerSubtitle: {
        type: 'tracker-subtitle',
        title: 'Training Tracker',
        api: 'jscEmployeeTracker',
      },
      trainingTrackerHistory: {
        type: 'table',
        api: 'jscBaseballCard',
        title: 'Training Tracker History',
        pageSize: 30,
        minWidth: '700px',
        rowKey: 'trackerUuid',
        columns: [
          {
            key: 'trackerName',
            title: 'Training Tracker',
            width: '350px',
            sortable: true,
          },
          {
            key: 'completionRate',
            title: 'Completion Rate',
            width: '250px',
          },
          {
            key: 'completedAt',
            title: 'Date Completed',
            width: '200px',
            align: 'center',
          },
          {
            key: 'approvedAt',
            title: 'Approval Status',
            sortable: true,
            align: 'center',
            mutation: 'updateNeedApproved',
            color: {
              unchecked: COLORS.Plum,
              checked: COLORS.Basil,
              disabled: COLORS.Mango,
            },
          },
          {
            key: 'deleteTrainingTracker',
            title: ' ',
            align: 'center',
          },
        ],
      },
    },
    '/:brand/training_tracker_detail/:id': {
      trainingTrackerTable: {
        type: 'tracker-form',
      },
    },
  },
}

export default jscConfig
