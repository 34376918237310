import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'

interface IProps {
  firstName: string
  lastName: string
  email: string
  password: string
  onBackToUserCreationForm: () => void
  wasEmailSent: boolean
}

function UserCreatedMessage({
  firstName,
  lastName,
  email,
  password,
  onBackToUserCreationForm,
  wasEmailSent,
}: IProps) {
  return (
    <Container>
      <Title>
        User successfully created for: {firstName} {lastName}
      </Title>
      <div>
        Account: <BoldText>{email}</BoldText>
      </div>
      <div>
        Password: <BoldText>{password}</BoldText>
      </div>
      <br />
      {wasEmailSent ? (
        <div>
          A welcome email has been sent out. The new user can use the link in
          the email to sign in.
        </div>
      ) : null}
      <ButtonContainer>
        <SubmitButton onClick={onBackToUserCreationForm}>Back</SubmitButton>
      </ButtonContainer>
    </Container>
  )
}

const Container = styled.div`
  padding: 0 40px;
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px;
  }
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const Title = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  margin-bottom: 20px;
`

const BoldText = styled.span`
  font-family: Lexend-SemiBold;
`

const ButtonContainer = styled.div`
  padding: 40px 0;
`

const SubmitButton = styled.div`
  background-color: ${COLORS.Expo};
  color: white;
  width: 150px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  font-family: Lexend-Semibold;
  cursor: pointer;
  &:hover {
    opacity: 50%;
  }
`

export default UserCreatedMessage
