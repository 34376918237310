import * as d3 from 'd3-hierarchy'
import _ from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'

import Body from './Body'
import Head from './Head'
import useApi, { IApiKeyType, configs } from './hooks/useApi'
import { IColumnsOptionsType } from './hooks/useColumns'
import { IGroupInfoConfigsType } from './hooks/useGroupInfoColumns'
import useSortedField from './hooks/useSortedField'
import { IApiDataType, IDetailsType } from './types'

export interface IPropsType<K extends IApiKeyType = IApiKeyType>
  extends IColumnsOptionsType<K> {
  type: 'temp-table'
  api: K
  minWidth?: string
  rowKey?: keyof typeof configs[K] | 'groupInfo.id'
  pageSize?: number
  defaultSortedField?: keyof typeof configs[K] | 'groupInfo'
  highlineEvenRows?: boolean
  groupInfo?: IGroupInfoConfigsType
  bordered?: boolean
}

export interface IDataSourceType {
  id: string
  parent: IDataSourceType | null
  data: IDetailsType
  children?: IDataSourceType[]
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const StyledTable = styled.table<{ tableStyle: string }>`
  border-collapse: collapse;
  ${({ tableStyle }) => tableStyle}
`

const TempTable = ({
  api,
  minWidth,
  pageSize,
  rowKey = 'groupInfo.id',
  defaultSortedField,
  highlineEvenRows,
  groupInfo,
  bordered,
  ...columnsOptions
}: IPropsType) => {
  const { data, loading } = useApi(api)

  const config = useMemo(
    () => ({
      ...configs[api],
      groupInfo: 'group-info',
      'groupInfo.groupBy': 'group-info',
    }),
    [configs, api],
  )

  const tableDataSource = useMemo(() => {
    if (!data) return null

    const d = [
      {
        id: 'root' as const,
        parentId: null,
      },
      ...data,
    ]
    return d3.stratify<NonNullable<IApiDataType>[number]>()(d)
  }, [data])

  const columnsInfo = useMemo(() => {
    const sampleData = tableDataSource?.leaves()[0]?.data

    if (!(sampleData && 'source' in sampleData))
      return [...columnsOptions.columns]

    const dataGroupInfo = sampleData.source[0]?.groupInfo

    if (!dataGroupInfo) {
      return [...columnsOptions.columns]
    }

    if (!dataGroupInfo?.groupBy) {
      return [
        {
          key: 'groupInfo',
          title: dataGroupInfo.header,
          sortable: true,
          ...groupInfo,
        },
        ...columnsOptions.columns,
      ]
    }

    return [
      {
        key: 'groupInfo',
        title: dataGroupInfo.header,
        sortable: true,
        ...groupInfo,
      },
      {
        key: 'groupInfo.groupBy',
        title: dataGroupInfo.groupBy.header,
        sortable: true,
        ...groupInfo?.groupBy,
      },
      ...columnsOptions.columns,
    ]
  }, [columnsOptions, tableDataSource])

  const { sortedField, setSortedField } = useSortedField<
    Record<string, unknown>
  >(columnsInfo, defaultSortedField as string, 'descend')

  const tableStyle = `
    ${!minWidth ? '' : `min-width: ${minWidth};`}
  `

  return (
    <Spin spinning={loading}>
      <StyledTable tableStyle={tableStyle}>
        <thead>
          <Head<Record<string, unknown>>
            columns={columnsInfo}
            sortedField={sortedField}
            setSortedField={setSortedField}
          />
        </thead>

        <tbody>
          {tableDataSource?.children?.map((d) => {
            return (
              <Body
                config={config}
                columnsInfo={columnsInfo}
                sortedField={sortedField}
                bodyDataSource={d}
                pageSize={pageSize}
                rowKey={rowKey}
                highlineEvenRows={highlineEvenRows}
                bordered={bordered}
              />
            )
          })}
        </tbody>
      </StyledTable>
    </Spin>
  )
}

export default TempTable
