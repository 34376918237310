import { useQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import { getLocationCode, getLocationName } from 'pared/utils/location'

interface ILocationInfo {
  id: number
  displayName: string
}

interface IGetLocationInfoNode {
  id: number
  code: string
  name: string
}

interface IGetLocationInfo {
  listLocationDetails: {
    nodes: IGetLocationInfoNode[]
  }
}

interface IGetLocationInfoVariables {
  iFilter: {
    location_ids: number[]
  }
}

const GET_LOCATION_INFO = gql`
  query GetLocationInfo($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
      }
    }
  }
`

const useLocationInfo = () => {
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery<
    IGetLocationInfo,
    IGetLocationInfoVariables
  >(GET_LOCATION_INFO, {
    variables: {
      iFilter: {
        location_ids: groupFilter?.ids || [],
      },
    },
    skip: !groupFilter,
  })

  const locationInfo = useMemo((): ILocationInfo | null => {
    const nodes = data?.listLocationDetails?.nodes || []
    if (nodes.length === 0) {
      return null
    }

    const location = nodes[0]
    const locationCode = getLocationCode(location.id, location.code || '')
    const locationName = getLocationName(location.id, location.name || '')

    return {
      id: location.id,
      displayName: [locationCode, locationName].join(' - '),
    }
  }, [data])

  return {
    data: useMemo((): string | null => {
      return locationInfo?.displayName
        ? `Store: ${locationInfo?.displayName}`
        : null
    }, [locationInfo]),
    loading,
  }
}

export default useLocationInfo
