import _ from 'lodash'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'

import downloadIcon from 'pared/images/basic/download.png'

import { useDateFilter } from '../dateFilter'
import useApi, { IApiKeyType } from './hooks/useApi'
import useCsvData, { ICsvDataArgsType } from './hooks/useCsvData'

export interface IPropsType<K extends IApiKeyType = IApiKeyType>
  extends ICsvDataArgsType<K> {
  type: 'download-csv'
  api: K
  fileName: string
  fileNameFn?: Function
  message: string
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const StyledCSVLink = styled(CSVLink)`
  font-family: Lexend-Regular;
  display: inline-flex;
  align-items: center;
  gap: 5px;
`

const DownloadCsv = ({
  api,
  fileName,
  fileNameFn,
  message,
  ...props
}: IPropsType) => {
  const { data, loading } = useApi(api)
  const { endDate } = useDateFilter()
  let finalFileName = fileName
  if (fileNameFn) {
    finalFileName = fileNameFn(endDate)
  }

  const csvData = useCsvData({ ...props, api }, data)

  if (loading || !data) return null

  return (
    <div>
      <StyledCSVLink data={csvData} filename={`${finalFileName}.csv`}>
        <img src={downloadIcon} height="30px" />
        <div>{message}</div>
      </StyledCSVLink>
    </div>
  )
}

export default DownloadCsv
