import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { feature, page } from 'pared/analytics/user'
import { PERFORMANCE_ENUM_MAP } from 'pared/constants'
import { getYearMonthDiff } from 'pared/utils/date'
import { toUsdString } from 'pared/utils/number'
import { hasPrivilegeToViewEmployeeFeedback } from 'pared/utils/user'
import { scrollToTop } from 'pared/utils/web'

import Main from './Main'

export interface IPositionDetails {
  id: number
  startDate: string
  timeTillNextEvent: string
  store: null | {
    id: number
    code: string
    name: string
    brandId: number
  }
  role: string
  employmentType: string
  payType: string
  payAmount: string
  event: string
  eventDetails: string
}

export interface IDevelopmentalFeedback {
  id: string
  time: string
  feedback: string
  performance: string
  location: null | {
    id: number
    code: string
    name: string
  }
  reviewer: null | {
    id: number
    firstName: string
    lastName: string
  }
}

export interface IEmployeeData {
  id: number
  firstName: string
  lastName: string
  role?: string
  employmentType?: string
  status?: string
  photoUrl?: string
  store?: {
    id: number
    name: string
    code: string
    concept: string
  }
  employeeManager?: {
    employeeId: number
    firstName: string
    lastName: string
  }
  tenure?: string
  startDate?: string
  endDate?: string
  timeInPosition?: string
  lastPayAdjustmentAt?: string
  potentialForPromotion?: string
  employeeHistories: IPositionDetails[]
  developmentalFeedbackHistory?: IDevelopmentalFeedback[]
}

interface IProps {
  navParams: INavParams
}

function getGqlForGetEmployeeData(shouldShowSensitiveData: boolean) {
  let sensitiveDataQueryStr = ''
  if (shouldShowSensitiveData) {
    sensitiveDataQueryStr = `
      listEmployeeEvents(iEmployeeId: $employeeId) {
        nodes {
          employeeEventId
          employeeId
          locationId
          locationInfo {
            id
            code
            name
          }
          locationBrandId
          roleId
          roleName
          employmentType
          event
          eventOccurredAt
          description
        }
      }

      locationManagerReviews(condition: {revieweeId: $employeeId}) {
        nodes {
          id
          reviewedAt
          performance
          feedback
          location {
            id
            code
            name
          }
          revieweeId
          reviewer {
            id
            firstName: preferredName
            lastName: familyName
          }
        }
      }
    `
  }

  return gql`
    query GetEmployeeData($employeeId: Int!) {
      employee(id: $employeeId) {
        id
        firstName: preferredName
        lastName: familyName
        lastHiredAt
        terminatedAt
        status
        role {
          name
          code
        }
        location {
          id
          code
          name
        }
      }
      ${sensitiveDataQueryStr}
    }
  `
}

const employmentTypeNameMap: { [type: string]: string } = {
  FULL_TIME: 'Full-Time',
  PART_TIME: 'Part-Time',
}

const eventTypeNameMap: { [type: string]: string } = {
  UNKNOWN: '-',
  HIRED: 'Hired',
  TERMINATED: 'Terminated',
  INACTIVE: 'Inactive',
  RETURN_TO_WORK: 'Return To Work',
  ROLE_CHANGED: 'Position Changed',
  LOCATION_CHANGED: 'Location Changed',
  EMPLOYMENT_TYPE_CHANGED: 'Employment Type Changed',
}

const DATE_DISPLAY_FORMAT = 'M/D/YYYY'

function EmployeeProfile({ navParams }: IProps) {
  const employeeId = navParams.employeeId
  const shouldShowSensitiveData = hasPrivilegeToViewEmployeeFeedback()

  useEffect(() => {
    if (employeeId && navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl || '', { employeeId })
      feature.used('Employee Profile', { employeeId })
    }
  }, [employeeId, navParams.pageUrl])

  let currentRoleCode = ''
  let currentLocationId = ''
  let employeeData: IEmployeeData | null | undefined = undefined
  const { loading, error, data } = useQuery(
    getGqlForGetEmployeeData(shouldShowSensitiveData),
    {
      variables: { employeeId: parseInt(employeeId || '0', 10) },
    },
  )
  if (data) {
    if (data.employee) {
      const rawEmployeeData: any = { ...data.employee }
      if (
        rawEmployeeData.manager &&
        Array.isArray(rawEmployeeData.manager.nodes) &&
        rawEmployeeData.manager.nodes.length > 0
      ) {
        rawEmployeeData.employeeManager = {
          ...rawEmployeeData.manager.nodes[0],
        }
      }
      if (rawEmployeeData.location) {
        rawEmployeeData.store = { ...rawEmployeeData.location }
        currentLocationId = rawEmployeeData.store.id
      }
      if (rawEmployeeData.role && rawEmployeeData.role.name) {
        rawEmployeeData.role = rawEmployeeData.role.name
        currentRoleCode = rawEmployeeData.role.code
      }
      if (rawEmployeeData.lastHiredAt) {
        const hiredAt = moment(rawEmployeeData.lastHiredAt)
        const latest = rawEmployeeData.terminatedAt
          ? moment(rawEmployeeData.terminatedAt)
          : moment()
        if (hiredAt.isValid()) {
          rawEmployeeData.startDate = hiredAt.format(DATE_DISPLAY_FORMAT)
          if (latest.isValid()) {
            rawEmployeeData.tenure = getYearMonthDiff(hiredAt, latest)
          }
        }
      }
      if (rawEmployeeData.terminatedAt) {
        const terminatedAt = moment(rawEmployeeData.terminatedAt)
        if (terminatedAt.isValid()) {
          rawEmployeeData.endDate = terminatedAt.format(DATE_DISPLAY_FORMAT)
        }
      }
      employeeData = rawEmployeeData
    } else {
      employeeData = null
    }

    if (
      data.listEmployeeEvents &&
      Array.isArray(data.listEmployeeEvents.nodes) &&
      data.listEmployeeEvents.nodes.length > 0
    ) {
      const rawEvents = data.listEmployeeEvents.nodes
      const eventCount = rawEvents.length
      const employeeHistories: IPositionDetails[] = []

      for (let eventIndex = 0; eventIndex < eventCount; ++eventIndex) {
        const eventData = rawEvents[eventIndex]
        const startDate = moment.utc(eventData.eventOccurredAt)

        let timeTillNextEvent = '-'
        if (eventData.event !== 'TERMINATED' && eventData.event !== 'UNKNOWN') {
          if (eventIndex < eventCount - 1) {
            const nextEventData = rawEvents[eventIndex + 1]
            const nextEventStartDate = moment.utc(nextEventData.eventOccurredAt)
            if (startDate.isValid() && nextEventStartDate.isValid()) {
              timeTillNextEvent = getYearMonthDiff(
                startDate,
                nextEventStartDate,
              )
            }
          } else if (eventIndex === eventCount - 1) {
            if (startDate.isValid()) {
              timeTillNextEvent = getYearMonthDiff(startDate, moment())
            }
          }
        }

        employeeHistories.unshift({
          timeTillNextEvent,
          id: eventData.employeeEventId,
          startDate: startDate.format(DATE_DISPLAY_FORMAT),
          store: {
            id: eventData.locationInfo.id,
            code: eventData.locationInfo.code,
            name: eventData.locationInfo.name,
            brandId: eventData.locationBrandId,
          },
          role: eventData.roleName || '-',
          employmentType:
            employmentTypeNameMap[eventData.employmentType] || '-',
          event: eventTypeNameMap[eventData.event] || '-',
          eventDetails: eventData.description,
          payType: '',
          payAmount: '',
        })
      }

      if (employeeData) {
        employeeData.employeeHistories = employeeHistories
      }
    }

    if (
      data.locationManagerReviews &&
      Array.isArray(data.locationManagerReviews.nodes) &&
      data.locationManagerReviews.nodes.length > 0
    ) {
      const developmentalFeedbackHistory = _.sortBy(
        data.locationManagerReviews.nodes,
        ['id'],
      )
        .reverse()
        .map((managerReview: any) => {
          const reviewedAt = moment(managerReview.reviewedAt)
          const performance = (
            PERFORMANCE_ENUM_MAP[managerReview.performance] || {}
          ).name
          return {
            ...managerReview,
            time: reviewedAt.isValid()
              ? reviewedAt.format(DATE_DISPLAY_FORMAT)
              : '-',
            performance: performance ? performance : '-',
          }
        })
      if (employeeData) {
        employeeData.developmentalFeedbackHistory = developmentalFeedbackHistory
      }
    }
  }

  return (
    <Main
      shouldShowSensitiveData={shouldShowSensitiveData}
      isLoading={loading}
      employeeData={employeeData}
      error={error}
    />
  )
}

export default EmployeeProfile
