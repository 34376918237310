import _ from 'lodash'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import COLORS from 'pared/constants/colors'
import { getBrandSettings, getBusinessLabel } from 'pared/customer'
import DownArrow from 'pared/images/basic/arrow/caret-down.svg'
import UpArrow from 'pared/images/basic/arrow/caret-up.svg'

import { IDetailGuestKpi } from '../index'
import { IOrderBy } from './index'

interface IProps {
  itemizedKpis: IDetailGuestKpi[]
  isTableExpanded: boolean
  onToggleExpansion: () => void
  orderBy: IOrderBy
  setOrderBy: any
  isBreakdownByDirectors: boolean
  breakdownType: string | null
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
}

interface IStorePerformanceSortableTh {
  isBackgroundGrey?: boolean
}

interface IRankTablTh {
  textAlign?: string
}

function WebSmallMain({
  itemizedKpis,
  isTableExpanded,
  onToggleExpansion,
  orderBy,
  setOrderBy,
  isBreakdownByDirectors,
  breakdownType,
}: IProps) {
  const guestConfig = getBrandSettings().guest

  const handleOrdering = (event: any) => {
    const orderingColumnValue = _.get(event, 'currentTarget.dataset.value')
    setOrderBy({
      columnName: orderingColumnValue,
      isAscending: !orderBy.isAscending,
    })
  }

  let nameLabel = getBusinessLabel('store')

  if (isBreakdownByDirectors) {
    // breakdownByDirectors
    nameLabel = getBusinessLabel('director')
  } else if (breakdownType) {
    nameLabel = breakdownType
  }

  let orderStatus = null
  if (orderBy.isAscending) {
    orderStatus = <UpArrowImg src={UpArrow} alt="ASC"></UpArrowImg>
  } else {
    orderStatus = <DownArrowImg src={DownArrow} alt="DESC"></DownArrowImg>
  }

  const locationRows = _.map(itemizedKpis, (k) => {
    const locationId = _.get(k, 'locationId', 1)
    const employeeId = _.get(k, 'employeeId', 1)
    const formattedName = _.get(k, 'formattedName', '')
    const key = `${locationId}-${employeeId}-${formattedName}`

    let linkAction
    if (isBreakdownByDirectors) {
      // breakdownByDirectors
      linkAction = navigator.employeeProfile(employeeId)
    } else {
      linkAction = navigator.guest(locationId)
    }

    return (
      <tr key={key}>
        <RankTableTd textAlign={'left'}>
          {breakdownType ? (
            <div>{formattedName}</div>
          ) : (
            <LightBlueLink to={linkAction} onClick={() => {}}>
              {_.get(k, 'formattedName', '')}
            </LightBlueLink>
          )}
        </RankTableTd>
        {guestConfig.smgMwb?.isVisible ? (
          <>
            <RankTableTd>
              {_.get(k, 'overallSatisfactionRatings', '-')}
            </RankTableTd>
            <RankTableTd>{_.get(k, 'orderNotOnTimeRatings', '-')}</RankTableTd>
            <RankTableTd>{_.get(k, 'tasteOfFoodRatings', '-')}</RankTableTd>
          </>
        ) : null}
        {guestConfig.acr.isVisible ? (
          <>
            <RankTableTd>{_.get(k, 'acrRatings', '-')}</RankTableTd>
            {guestConfig.acr.hideSurveyCount ? null : (
              <RankTableTd>{_.get(k, 'acrRatingCount', '-')}</RankTableTd>
            )}
          </>
        ) : null}
        {guestConfig.momentfeed ? (
          <RankTableTd>{_.get(k, 'momentfeedRatings', '-')}</RankTableTd>
        ) : null}
        {guestConfig.yext ? (
          <RankTableTd>{_.get(k, 'yextRatings', '-')}</RankTableTd>
        ) : null}
        {guestConfig.yelp ? (
          <RankTableTd>{_.get(k, 'yelpRatings', '-')}</RankTableTd>
        ) : null}
        {guestConfig.google ? (
          <RankTableTd>{_.get(k, 'googleRatings', '-')}</RankTableTd>
        ) : null}
        {guestConfig.facebook ? (
          <RankTableTd>{_.get(k, 'facebookRatings', '-')}</RankTableTd>
        ) : null}
        {guestConfig.delivery ? (
          <RankTableTd>{_.get(k, 'deliveryRatings', '-')}</RankTableTd>
        ) : null}
        {guestConfig.allRating.isVisible ? (
          <RankTableTd>{_.get(k, 'allRatings', '-')}</RankTableTd>
        ) : null}
        {guestConfig.opsComps ? (
          <RankTableTd>{_.get(k, 'opsCompsPercent', '-')}</RankTableTd>
        ) : null}
      </tr>
    )
  })

  let toggledlocationRows = locationRows

  if (!isTableExpanded) {
    toggledlocationRows = _.slice(locationRows, 0, 5)
  }

  const toggleText = isTableExpanded ? 'Collapse all' : 'Expand All'

  const expandSpan =
    _.size(locationRows) > 5 ? (
      <ExpandAllSpan onClick={onToggleExpansion}>{toggleText}</ExpandAllSpan>
    ) : null

  return (
    <ScrollableTable>
      <ScrollableBreakdownTable>
        <MainContainer>
          <RankTable>
            <thead>
              <BreakdownSecondHeaderTr>
                <RankTableTh>{nameLabel}</RankTableTh>
                {guestConfig.smgMwb?.isVisible ? (
                  <>
                    <RankTableSortableTh
                      onClick={handleOrdering}
                      data-value={'overallSatisfactionRatings'}
                    >
                      <span>SMG Overall Satisfaction Rating</span>
                      {orderBy.columnName === 'overallSatisfactionRatings'
                        ? orderStatus
                        : null}
                    </RankTableSortableTh>
                    <RankTableSortableTh
                      onClick={handleOrdering}
                      data-value={'orderNotOnTimeRatings'}
                    >
                      <span>SMG Overall Satisfaction Rating</span>
                      {orderBy.columnName === 'orderNotOnTimeRatings'
                        ? orderStatus
                        : null}
                    </RankTableSortableTh>
                    <RankTableSortableTh
                      onClick={handleOrdering}
                      data-value={'tasteOfFoodRatings'}
                    >
                      <span>SMG Overall Satisfaction Rating</span>
                      {orderBy.columnName === 'tasteOfFoodRatings'
                        ? orderStatus
                        : null}
                    </RankTableSortableTh>
                  </>
                ) : null}
                {guestConfig.acr.isVisible ? (
                  <>
                    <RankTableSortableTh
                      onClick={handleOrdering}
                      data-value={'acrRatings'}
                    >
                      <span>ACR</span>
                      {orderBy.columnName === 'acrRatings' ? orderStatus : null}
                    </RankTableSortableTh>
                    {guestConfig.acr?.hideSurveyCount ? null : (
                      <RankTableSortableTh
                        onClick={handleOrdering}
                        data-value={'acrRatingCount'}
                      >
                        <span>ACR Survey Count</span>
                        {orderBy.columnName === 'acrRatingCount'
                          ? orderStatus
                          : null}
                      </RankTableSortableTh>
                    )}
                  </>
                ) : null}
                {guestConfig.momentfeed ? (
                  <RankTableSortableTh
                    onClick={handleOrdering}
                    data-value={'momentfeedRatings'}
                  >
                    <span>Momentfeed</span>
                    {orderBy.columnName === 'momentfeedRatings'
                      ? orderStatus
                      : null}
                  </RankTableSortableTh>
                ) : null}
                {guestConfig.yext ? (
                  <RankTableSortableTh
                    onClick={handleOrdering}
                    data-value={'yextRatings'}
                  >
                    <span>Yext</span>
                    {orderBy.columnName === 'yextRatings' ? orderStatus : null}
                  </RankTableSortableTh>
                ) : null}
                {guestConfig.yelp ? (
                  <RankTableSortableTh
                    onClick={handleOrdering}
                    data-value={'yelpRatings'}
                  >
                    <span>Yelp</span>
                    {orderBy.columnName === 'yelpRatings' ? orderStatus : null}
                  </RankTableSortableTh>
                ) : null}
                {guestConfig.google ? (
                  <RankTableSortableTh
                    onClick={handleOrdering}
                    data-value={'googleRatings'}
                  >
                    <span>Google</span>
                    {orderBy.columnName === 'googleRatings'
                      ? orderStatus
                      : null}
                  </RankTableSortableTh>
                ) : null}
                {guestConfig.facebook ? (
                  <RankTableSortableTh
                    onClick={handleOrdering}
                    data-value={'facebookRatings'}
                  >
                    <span>Facebook</span>
                    {orderBy.columnName === 'facebookRatings'
                      ? orderStatus
                      : null}
                  </RankTableSortableTh>
                ) : null}
                {guestConfig.delivery ? (
                  <RankTableSortableTh
                    onClick={handleOrdering}
                    data-value={'deliveryRatings'}
                  >
                    <span>Delivery</span>
                    {orderBy.columnName === 'deliveryRatings'
                      ? orderStatus
                      : null}
                  </RankTableSortableTh>
                ) : null}
                {guestConfig.allRating.isVisible ? (
                  <RankTableSortableTh
                    onClick={handleOrdering}
                    data-value={'allRatings'}
                  >
                    <span>{guestConfig.allRating.displayName}</span>
                    {orderBy.columnName === 'allRatings' ? orderStatus : null}
                  </RankTableSortableTh>
                ) : null}
                {guestConfig.opsComps ? (
                  <RankTableSortableTh
                    onClick={handleOrdering}
                    data-value={'opsCompsPercent'}
                  >
                    <span>Ops Comps Rate</span>
                    {orderBy.columnName === 'opsCompsPercent'
                      ? orderStatus
                      : null}
                  </RankTableSortableTh>
                ) : null}
              </BreakdownSecondHeaderTr>
            </thead>
            <tbody>{toggledlocationRows}</tbody>
          </RankTable>
          {expandSpan}
        </MainContainer>
      </ScrollableBreakdownTable>
    </ScrollableTable>
  )
}

const MainContainer = styled.div``

const UpArrowImg = styled.img`
  width: 10px;
  margin: 0 0 2px 5px;
`

const DownArrowImg = styled.img`
  width: 10px;
  margin: 0 0 -1px 5px;
`

const LightBlueLink = styled(Link)`
  color: ${COLORS.Link};
`

const BreakdownSecondHeaderTr = styled.tr`
  border-bottom: 1px solid ${COLORS.Chalkboard};
`

const RankTableSortableTh = styled.th<IRankTablTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  color: ${COLORS.Plum};
  cursor: pointer;
`

const RankTableTd = styled.td<IRankTableTd>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  padding-left: ${(props) => (props.textAlign === 'left' ? '10px' : '0')};
`

const RankTable = styled.table`
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
    min-width: 100px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`

const ExpandAllSpan = styled.div`
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: ${COLORS.Link};
  text-decoration: underline;
  cursor: pointer;
`

const RankTableTh = styled.th<IRankTablTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const ScrollableTable = styled.div`
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
`

const ScrollableBreakdownTable = styled(ScrollableTable)`
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
`

export default WebSmallMain
