import { FormControl } from '@material-ui/core'
import styled from 'styled-components'

import Select from 'pared/components/basicUi/select'
import Spin from 'pared/components/basicUi/spin'
import Table from 'pared/components/basicUi/table'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import useGetDirectors from 'pared/hooks/useGetDirectors'

import useDiscountTypeItems from './Discount/hooks/useDiscountTypeItems'
import useDiscounts from './Discount/hooks/useDiscounts'
import useDiscountsColumns from './Discount/hooks/useDiscountsColumns'

interface IProps {
  isBreakdownByDirectors: boolean
  startDate?: string
  endDate?: string
  locationIds?: number[]
  locationGroupIds?: number[]
  locationGroupType?: string
}

const Container = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const SubHeaderDiscount = styled.div`
  padding: 20px 0;
  display: flex;
  width: 670px;
  justify-content: space-between;
  align-items: center;

  @media ${MOBILE_WIDTH} {
    display: block;
    width: 100%;
    margin: 60px 0px 0px;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
`

const MobileCotainer = styled.div`
  @media ${MOBILE_WIDTH} {
    margin: 20px 0px 0px;
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const DiscountFormControl = styled(FormControl)`
  background-color: white;
  width: 220px;
`

const DiscountSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const DiscountType = styled.div`
  display: flex;
  gap: 10px;

  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

function DiscountTable({
  startDate,
  endDate,
  locationIds,
  locationGroupIds,
  isBreakdownByDirectors,
  locationGroupType,
}: IProps) {
  const { directors } = useGetDirectors()

  const { selectedDiscount, discountChanged, discountItems } =
    useDiscountTypeItems(isBreakdownByDirectors, locationIds, locationGroupIds)

  const { discounts, discountLoading } = useDiscounts(
    directors,
    isBreakdownByDirectors,
    selectedDiscount,
    locationIds,
    locationGroupIds,
    startDate,
    endDate,
    locationGroupType,
  )

  const { discountsColumns } = useDiscountsColumns(
    isBreakdownByDirectors,
    discounts,
    discountLoading,
    selectedDiscount,
    locationGroupType,
  )

  return (
    <Container>
      <SubHeaderDiscount>
        <h1>Breakdown by Restaurant</h1>

        <DiscountType>
          <h3>Type:</h3>
          <DiscountFormControl variant="outlined" margin="dense">
            <Select
              value={selectedDiscount}
              onChange={discountChanged}
              dataSource={discountItems}
              fullWidth
              displayEmpty
            />
          </DiscountFormControl>
        </DiscountType>
      </SubHeaderDiscount>

      <MobileCotainer>
        <Spin spinning={discountLoading}>
          <Table
            dataSource={discounts}
            columns={discountsColumns}
            rowKey="storeId"
            csvFileName="expo-restaurant-marketing"
          />
        </Spin>
      </MobileCotainer>
    </Container>
  )
}

export default DiscountTable
