import CustomizedReport from 'pared/components/CustomizedReport'

interface IProps {
  locationId: number
  startDate: string
  endDate: string
}

const KpiBar = ({ locationId, startDate, endDate }: IProps) => {
  const inputParams = {
    locationId,
    startDate,
    endDate,
  }

  return (
    <>
      <CustomizedReport
        title="Real-Time KPIs"
        reportName="STORE_CALLS_KPIS"
        inputParams={inputParams}
      />
    </>
  )
}

export default KpiBar
