import moment from 'moment'
import { useCallback, useMemo, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { track } from 'pared/analytics/dateRangeSelector'
import { IDateRangeMap } from 'pared/data/getDateRanges'

import { IDateOptionNodeType, IDateType } from './types'

export interface ISetDateType {
  currentDate: {
    type: string
    startDate: moment.Moment
    startDateStr: string
    endDate: moment.Moment
    endDateStr: string
  } | null
  setDate: (date: IDateOptionNodeType) => void
}

const useSetDate = (
  types: IDateType[],
  dateRangeMap: IDateRangeMap | null,
): ISetDateType => {
  const { search } = useLocation()
  const currentDateType = useRef<string | null>(null)
  const currentDate = useMemo(() => {
    const queryObject = new URLSearchParams(search)
    const dateRange = queryObject.get('date_range') || ''
    const current = dateRangeMap?.[dateRange]

    if (!dateRange) return null

    if (!current) {
      if (
        types.includes('custom_date') ||
        types.includes('custom_date_range')
      ) {
        const [customStartDateStr, customEndDateStr] = dateRange.split('-')
        const startDate = moment(customStartDateStr, 'YYYYMMDD')
        const endDate = moment(customEndDateStr, 'YYYYMMDD')

        return {
          type: currentDateType.current || dateRange,
          startDate,
          startDateStr: startDate.format('YYYY-MM-DD'),
          endDate,
          endDateStr: endDate.format('YYYY-MM-DD'),
        }
      }

      return null
    }

    if (current.type !== 'custom' && !types.includes(current.type)) return null

    const { startDate, startDateStr, endDate, endDateStr } = current

    return {
      type: currentDateType.current || dateRange,
      startDate,
      startDateStr,
      endDate,
      endDateStr,
    }
  }, [search, dateRangeMap, types])

  const history = useHistory()
  const setDate = useCallback(
    (date: IDateOptionNodeType) => {
      const dateRangeStr = (() => {
        switch (date.type) {
          case 'this_week':
          case 'last_week':
          case 'yesterday':
            return date.type

          default:
            if (/^trailing/.test(date.type)) return date.type

            return `${date.dateRange.startDate.format(
              'YYYYMMDD',
            )}-${date.dateRange.endDate.format('YYYYMMDD')}`
        }
      })()

      const currentPath = `${history.location.pathname}${history.location.search}`
      const queryObject = new URLSearchParams(history.location.search)

      queryObject.set('date_range', dateRangeStr)
      track.newTimeFrameConfirmed(date.year, date.type, dateRangeStr)
      currentDateType.current = ['custom_date', 'custom_date_range'].includes(
        date.type,
      )
        ? date.type
        : dateRangeStr

      const newPath = `${history.location.pathname}?${queryObject.toString()}`

      if (currentPath !== newPath) history.push(newPath)
    },
    [history],
  )

  return {
    currentDate,
    setDate,
  }
}

export default useSetDate
