import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'

import Summary from 'pared/components/basicUi/Summary'
import { getBrand } from 'pared/utils/brand'

import { LOCATION_GUEST_SUMMARY_V2 } from '../gql'
import useGuestConfig from '../hooks/useGuestConfig'

interface IProps {
  locationId: number
  startDate: string
  endDate: string
}

const GuestSummary = ({ locationId, startDate, endDate }: IProps) => {
  const config = useGuestConfig(getBrand())
  const osatDisplayString = config.osatDisplayString ?? 'OSAT'
  const [getLocationGuestSummaryV2, { loading, data: fetchedData }] =
    useLazyQuery(LOCATION_GUEST_SUMMARY_V2)

  useEffect(() => {
    getLocationGuestSummaryV2({
      variables: {
        iLocationId: locationId,
        iStartDate: startDate,
        iEndDate: endDate,
      },
    })
  }, [locationId, startDate, endDate])

  const data = fetchedData?.locationGuestSummaryV2?.nodes?.[0]

  let summaryMessage: string | React.ReactElement = ''
  let areasOfImprovementMessages: (string | React.ReactElement)[] = []

  if (loading) {
    summaryMessage = 'Loading...'
  } else {
    if (!data) {
      summaryMessage = `Great job!  None of your ${osatDisplayString} responses had a problem!`
    } else {
      if (data.problemPercentage === null) {
        summaryMessage = `There are no ${osatDisplayString} comments.`
      } else {
        summaryMessage = `${data.problemPercentage}% of your ${osatDisplayString} responses had a problem.`
      }

      if (data.mostCommonProblem?.length === 1) {
        areasOfImprovementMessages.push(
          `The top problem was: ${data.mostCommonProblem[0]}.`,
        )
      } else if (data.mostCommonProblem?.length > 1) {
        areasOfImprovementMessages.push(
          `The top problems were: ${data.mostCommonProblem?.join(', ')}.`,
        )
      }

      if (Array.isArray(data.mostCommonDay)) {
        areasOfImprovementMessages.push(
          `${data.mostCommonDay
            .map((day: string) => `${day}s`)
            .join(', ')} have the most negative reviews.`,
        )
      }

      if (Array.isArray(data.mostCommonDaypart)) {
        areasOfImprovementMessages.push(
          `${data.mostCommonDaypart.join(', ')} ${
            data.mostCommonDaypart.length > 1 ? 'have' : 'has'
          } the most negative reviews.`,
        )
      }
    }
  }

  return (
    <Summary
      summaryMessage={summaryMessage}
      areasOfImprovementMessages={areasOfImprovementMessages}
    />
  )
}

export default GuestSummary
