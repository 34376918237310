import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'
import COLORS from 'pared/constants/colors'

import { SignedShiftLeaderAssessmentType } from '../hooks/useTrainingTrackerItems'
import useSignedShiftLeaderAssessment from './hooks/useSignedShiftLeaderAssessment'

export interface IPropsType {
  signedShiftLeaderAssessment: SignedShiftLeaderAssessmentType | null
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  font-family: Lexend-Regular;
  line-height: 20px;
`

const StyledButton = styled(Button)`
  padding: 10px 24px;
  border: 1px solid;
  border-color: ${COLORS.Steel};
`

const StyledDate = styled.div`
  font-size: 12px;
`

const SignedShiftLeaderAssessment = ({
  trainerName,
  show,
  signedAt,
}: SignedShiftLeaderAssessmentType) => {
  const [loading, setLoading] = useState(false)
  const updateSigned = useSignedShiftLeaderAssessment(setLoading, !!signedAt)

  useEffect(() => {
    setLoading(false)
  }, [!!signedAt])

  return (
    <div>
      <CheckboxContainer>
        <Spin spinning={loading}>
          {signedAt && (
            <div>
              {trainerName}
              <StyledDate>{signedAt}</StyledDate>
            </div>
          )}
          {!signedAt && show && (
            <StyledButton
              variant="outlined"
              disabled={loading}
              onClick={async () => {
                await updateSigned({ checked: !signedAt })
              }}
            />
          )}
        </Spin>
      </CheckboxContainer>
    </div>
  )
}

export default SignedShiftLeaderAssessment
