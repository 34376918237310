import { gql } from '@apollo/client'

export const LIST_LOCATION_SALESMANS_BY_SALES_TYPE = gql`
  query ListLocationSalesmansBySalesType(
    $locationId: Int!
    $startDate: Date!
    $endDate: Date!
    $salesType: String!
    $dayPart: String!
  ) {
    listLocationSalesmansBySalesType(
      iLocationId: $locationId
      iStartDate: $startDate
      iEndDate: $endDate
      iSalesType: $salesType
      iDayPart: $dayPart
    ) {
      nodes {
        startDate
        endDate
        locationId
        employeeId
        roleName
        firstName
        lastName
        salesType
        dayPart
        sales
        salesPpa
        salesRank
        salesPpaRank
        daysWorked
        avgSales
        avgSalesPpa
        sosAvgTime
        sosAvgTimeRank
        avgSosAvgTime
      }
    }
  }
`

export const LIST_LOCATION_SALESMANS_BY_SALES_TYPE_AND_DAYS_WORKED = gql`
  query ListLocationSalesmansBySalesTypeAndDaysWorked(
    $locationId: Int!
    $numDaysWorked: Int!
    $salesType: String!
    $dayPart: String!
  ) {
    listLocationSalesmansBySalesTypeAndDaysWorked(
      iLocationId: $locationId
      iNumDaysWorked: $numDaysWorked
      iSalesType: $salesType
      iDayPart: $dayPart
    ) {
      nodes {
        locationId
        employeeId
        roleName
        firstName
        lastName
        salesType
        dayPart
        sales
        salesPpa
        salesRank
        salesPpaRank
        daysWorked
        avgSalesPpa
        avgSales
        sosAvgTime
        sosAvgTimeRank
        avgSosAvgTime
      }
    }
  }
`
