import { RAW_LOCATION_GROUP } from './locationGroup'

interface IRawData {
  period: number
  week: number
  hours: number
  transactionsCount: number
  value: number
}

export const RAW_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA = [
  2, 3, 4, 6, 8, 9, 10, 11, 16, 18, 21, 24, 29, 30, 32, 35, 36, 40, 41, 43, 45,
  47, 48, 51, 54, 55, 61, 62, 64, 65, 67, 68, 71, 72, 76,
].reduce(
  (result, key) => ({
    ...result,
    [key]: [1, 2, 3, 4, 5, 6].map((week) => {
      const hours = Math.random() * 150 + 350
      const transactionsCount = Math.random() * 300 + 800

      return {
        period: 7,
        week,
        hours,
        transactionsCount,
        value: transactionsCount / hours,
      }
    }),
  }),
  {} as { [key: string]: IRawData[] },
)

export const RAW_LIST_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA = Object.keys(
  RAW_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA,
).reduce((result, key) => {
  const { hours, transactionsCount } =
    RAW_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA[key].reduce(
      (subResult, data) => ({
        hours: subResult.hours + data.hours,
        transactionsCount: subResult.transactionsCount + data.transactionsCount,
      }),
      {
        hours: 0,
        transactionsCount: 0,
      },
    )

  return {
    ...result,
    [key]: {
      hours,
      transactionsCount,
      value: transactionsCount / hours,
    },
  }
}, {} as { [key: string]: Pick<IRawData, 'hours' | 'transactionsCount' | 'value'> })

export const RAW_LIST_LOCATION_GROUP_PRODUCTIVITY_COEFFICIENT_DATA = (
  Object.keys(
    RAW_LOCATION_GROUP,
  ) as unknown as (keyof typeof RAW_LOCATION_GROUP)[]
).reduce((result, key) => {
  const { hours, transactionsCount } = RAW_LOCATION_GROUP[key].reduce(
    (subResult, locationId: number) => ({
      hours:
        subResult.hours +
          RAW_LIST_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA[locationId]?.hours ||
        0,
      transactionsCount:
        subResult.transactionsCount +
          RAW_LIST_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA[locationId]
            ?.transactionsCount || 0,
    }),
    {
      hours: 0,
      transactionsCount: 0,
    },
  )

  return {
    ...result,
    [key]: {
      hours,
      transactionsCount,
      value: transactionsCount / hours,
    },
  }
}, {} as { [key: string]: Pick<IRawData, 'hours' | 'transactionsCount' | 'value'> })

export const RAW_PRODUCTIVITY_COEFFICIENT_DATA = Object.values(
  RAW_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA,
).reduce(
  (result, datas) =>
    datas.reduce((subResult, { week, ...data }) => {
      const item = subResult.find((d) => d.week === week)

      if (!item) return [...subResult, { ...data, week }]

      item.hours += data.hours
      item.transactionsCount += data.transactionsCount
      item.value = item.transactionsCount / item.hours

      return subResult
    }, result),
  [] as IRawData[],
)
