import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useReactToPrint } from 'react-to-print'
import styled from 'styled-components'

import { BRAND_LOGOS, BRAND_NAME } from 'pared/constants/brands'
import ExpoLogo from 'pared/images/Expo/expo-logo-blue.png'
import PrintIcon from 'pared/images/Pared/print-icon.svg'
import { getBrand } from 'pared/utils/brand'

interface IPropsType {
  children: React.ReactNode
  hideHeader?: boolean
}

const PrintImg = styled.img`
  cursor: pointer;
`

const Root = styled.div`
  padding: 20px 50px;

  @media screen {
    width: 0px;
    height: 0px;
    visibility: hidden;
    overflow: hidden;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  h3 {
    margin: 0px 0px 10px;
    font-family: Lexend-SemiBold;
    font-size: 12px;
    text-align: center;
  }
`

const LogoImg = styled.img<{
  height: number
}>`
  height: ${({ height }) => height}px;
`

const Print = ({ children, hideHeader }: IPropsType) => {
  const brand = getBrand()
  const printRef = useRef<HTMLDivElement>(null)
  const domRef = useRef<HTMLDivElement>(document.createElement('div'))
  const print = useReactToPrint({
    content: () => printRef.current,
    documentTitle: 'Expo',
    removeAfterPrint: true,
  })

  useEffect(() => {
    const root = document.querySelector('body')

    if (!root) return

    root.appendChild(domRef.current)

    return () => {
      root.removeChild(domRef.current)
    }
  }, [])

  return (
    <>
      <PrintImg src={PrintIcon} onClick={print} alt="Print" />

      {ReactDOM.createPortal(
        <Root ref={printRef}>
          {!hideHeader && (
            <Header>
              <LogoImg
                height={50}
                src={BRAND_LOGOS[brand].dark}
                alt={`${BRAND_NAME[brand]} Logo`}
              />

              <div>
                <h3>powered by</h3>

                <LogoImg height={40} src={ExpoLogo} alt="Expo" />
              </div>
            </Header>
          )}

          {children}
        </Root>,
        domRef.current,
      )}
    </>
  )
}

export default React.memo(Print)
