import { gql } from '@apollo/client'

export const GET_SALES_PER_LABOR_HOUR = gql`
  query GetSalesPerLaborHour(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    getSalesPerLaborHour(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        hour
        sales
        systemwideSales
        differenceSales
        laborHours
        systemwideLaborHours
        differenceLaborHours
        salesPerLaborHour
        systemwideSalesPerLaborHour
        differenceSalesPerLaborHour
        laborPercent
        systemwideLaborPercent
        totalSales
        totalSystemwideSales
        totalDifferenceSales
        totalLaborHours
        totalSystemwideLaborHours
        totalDifferenceLaborHours
        totalSalesPerLaborHour
        totalSystemwideSalesPerLaborHour
        totalDifferenceSalesPerLaborHour
      }
    }
  }
`
