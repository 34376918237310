import { gql, useMutation } from '@apollo/client'
import moment from 'moment'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useReset } from '../../../ResetProvider'

interface IParamsPropsType {
  id: string
}

const query = gql`
  mutation UpdateTrainingTracker($listUuid: String!, $now: Datetime!) {
    updateTrainingTrackerByListUuid(
      input: {
        listUuid: $listUuid
        patch: { signedShiftLeaderAssessment: $now, updatedAt: $now }
      }
    ) {
      trainingTracker {
        id
        signedShiftLeaderAssessment
        updatedAt
      }
    }
  }
`

const useSignedShiftLeaderAssessment = (
  setLoading: any,
  signedShiftLeaderAssessment: boolean,
) => {
  const { id }: IParamsPropsType = useParams()
  const [updateSignedShiftLeaderAssessment] = useMutation(query)
  const reset = useReset()

  return useCallback(
    async ({ checked }) => {
      const now = moment().toISOString()

      setLoading(true)
      await updateSignedShiftLeaderAssessment({
        variables: {
          listUuid: id,
          now: now,
        },
      })
      reset()
    },
    [signedShiftLeaderAssessment, reset],
  )
}

export default useSignedShiftLeaderAssessment
