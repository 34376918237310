import { gql } from '@apollo/client'

export const TREND_LOCATION_SPEED_OF_SERVICE_DAY_PART = gql`
  query TrendLocationSpeedOfServiceDayPart(
    $iLocationId: Int!
    $iEndDate: Date!
    $iGroupBy: String!
    $iDateRangeNumber: Int!
  ) {
    trendLocationSpeedOfServiceDayPart(
      iLocationId: $iLocationId
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iDateRangeNumber: $iDateRangeNumber
    ) {
      nodes {
        startDate
        endDate
        sosDetails
      }
    }
  }
`
