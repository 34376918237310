import { useQuery } from '@apollo/client'
import moment from 'moment'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import COLORS from 'pared/constants/colors'
import { getDateRangeLabelV2 } from 'pared/utils/date'

import { TREND_LOCATION_GROUP_CUSTOMIZED_REPORT } from './gql'

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350
const CHART_START_DATE = moment.utc('2022-05-15', 'YYYY-MM-DD', true)

interface IProps {
  trendChartParams: {
    iReportName: string
    iLocationGroupId: number
    iEndDate: string
    iGroupBy: string
  }
}

interface IData {
  trendLocationGroupCustomizedReport: {
    nodes: {
      startDate: string
      endDate: string
      reportResult: {
        textData: {
          upsizePercent: string
        }
      }
      businessWeek: string
      businessWeekOfMonth: string
      businessMonth: string
      businessQuarter: string
      businessYear: string
    }[]
  }
}

interface IOrganizedData {
  dateRanges: string[]
  upsizePercent: number[]
}

const UpsizeTrendChart = ({ trendChartParams }: IProps) => {
  const { loading, data }: { loading: boolean; data: IData | undefined } =
    useQuery(TREND_LOCATION_GROUP_CUSTOMIZED_REPORT, {
      variables: trendChartParams,
      skip: !trendChartParams.iEndDate,
    })

  if (loading) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  if (!data) {
    return (
      <PageStatusDiv>
        There is no data within the date range selected. Please select a
        different date range.
      </PageStatusDiv>
    )
  }

  const {
    trendLocationGroupCustomizedReport: { nodes },
  } = data
  const organizedData = nodes.reduce(
    (
      acc,
      {
        startDate,
        endDate,
        businessWeek,
        businessWeekOfMonth,
        businessMonth,
        businessQuarter,
        businessYear,
        reportResult,
      },
    ) => {
      if (CHART_START_DATE.isBefore(startDate)) {
        const upsizePercent = reportResult?.textData?.upsizePercent || ''
        const upsizePercentNum = parseFloat(upsizePercent) || 0.0

        acc.dateRanges.push(
          getDateRangeLabelV2(trendChartParams.iGroupBy, {
            groupByStartDate: startDate,
            groupByEndDate: endDate,
            businessWeek,
            businessWeekOfMonth,
            businessMonth,
            businessQuarter,
            businessYear,
          }),
        )
        acc.upsizePercent.push(upsizePercentNum)
      }
      return acc
    },
    {
      dateRanges: [],
      upsizePercent: [],
    } as IOrganizedData,
  )

  const yAxisDataArr = [
    {
      type: 'line',
      data: organizedData.upsizePercent,
      tooltipLabel: 'Systemwide Upsizing %',
      borderColor: COLORS.Basil,
      backgroundColor: COLORS.Basil,
    },
  ]

  const chartOptions = {
    title: 'Upsizing',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: '% Upsized',
    yLeftTickCallback: (value: number) => {
      return `${value}%`
    },
  }

  return (
    <MixedChart
      xAxisData={organizedData.dateRanges}
      yAxisDataArr={yAxisDataArr}
      options={chartOptions}
    />
  )
}

export default UpsizeTrendChart

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`
