import { useQuery } from '@apollo/client'
import moment from 'moment'

import useDateRange from 'pared/components/DateRangeNumberSelector/hooks/useDateRange'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'
import { getDateRangeLabel } from 'pared/utils/date'
import { toPercentString, toUsdString } from 'pared/utils/number'

import useLossPreventionConfig from '../hooks/useLossPreventionConfig'
import Main from './Main'
import { TREND_SUMMED_CASH_CONTROL_DETAILS } from './gql'

interface IProps {
  isBreakdownByDirectors: boolean
  endDate: string
  dateRange: string
  locationIds?: number[]
  locationGroupIds?: number[]
  shouldDisplayAll?: boolean
}

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

const CashControlTrendChart = ({
  locationIds,
  locationGroupIds,
  isBreakdownByDirectors,
  endDate,
  dateRange,
  shouldDisplayAll,
}: IProps) => {
  const brand = getBrand()

  let iFilter: any = null
  if (locationIds && locationIds.length > 0) {
    iFilter = { location_ids: [...locationIds] }
  } else if (isBreakdownByDirectors) {
    iFilter = { location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]] }
  } else if (locationGroupIds && locationGroupIds.length > 0) {
    iFilter = { location_group_ids: [...locationGroupIds] }
  }

  const config = useLossPreventionConfig()
  const { dateRangeNumber, dateRangeGroupBy } = useDateRange(dateRange)

  const newEndDate =
    moment(endDate, DATE_FORMAT) > moment()
      ? moment().format(DATE_FORMAT)
      : endDate

  const { loading: listKpiLoading, data: listKpiData } = useQuery(
    TREND_SUMMED_CASH_CONTROL_DETAILS,
    {
      variables: {
        iFilter,
        iEndDate: newEndDate,
        iGroupBy: dateRangeGroupBy,
        iDateRangeNumber: dateRangeNumber,
      },
      skip: !newEndDate || !iFilter,
    },
  )

  const rawKpiMonthly = listKpiData?.trendSummedCashControlDetails?.nodes || []

  const xAxisData: any[] = []

  rawKpiMonthly.forEach(({ startDate }) => {
    if (startDate) {
      xAxisData.push(getDateRangeLabel(startDate, dateRangeGroupBy))
    }
  })

  const yLeftAxis = config.cashControlTrendChart.yLeftAxisKpiDefs
  const yAxisDataArr = []

  for (let i = 0; i < yLeftAxis.length; i++) {
    const colDef = yLeftAxis[i]
    const kpiDataArr: any = []
    rawKpiMonthly.forEach((rawData: any) => {
      let value = 0
      let displayValue = 0

      value = rawData?.cashControlDetails?.[colDef.key]?.[colDef.kpiKey] || 0

      switch (colDef.valueType) {
        case 'cent':
          displayValue = value / 100.0
          break
        default:
        // do nothing
      }

      kpiDataArr.push(displayValue)
    })

    yAxisDataArr.push({
      type: 'line',
      data: kpiDataArr,
      tooltipLabel: colDef.name,
      borderColor: colDef.color,
      backgroundColor: colDef.color,
      hidden: shouldDisplayAll ? false : !!colDef.hidden,
    })
  }

  const chartOptions = {
    title: '',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: config.cashControlTrendChart.yLeftAxisLabel,
    yLeftTickCallback: (value: number) => {
      switch (config.cashControlTrendChart.yLeftAxisValueType) {
        case 'cent':
          return toUsdString(value, 2)
        default:
          return toUsdString(value, 2)
      }
    },
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let datasetLabel = ''
        let separator = ''
        let yValue = ''
        if (tooltipItemContext.dataset && tooltipItemContext.dataset.label) {
          datasetLabel = tooltipItemContext.dataset.label
          separator = ': '
        }
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          let axisValueType = config.cashControlTrendChart.yLeftAxisValueType
          let displayString = ''
          const value = tooltipItemContext.parsed.y
          switch (axisValueType) {
            case 'cent':
              if (Math.abs(value) > 100.0) {
                displayString = toUsdString(value, 0)
              } else {
                displayString = toUsdString(value, 2)
              }
              break
            case 'percent':
              displayString = toPercentString(value, 2, false)
              break
            default:
              displayString = toUsdString(value, 2)
          }

          if (tooltipItemContext.datasetIndex === 0) {
            yValue += displayString
          } else {
            yValue = displayString
          }
        }
        return [datasetLabel, separator, yValue].join('')
      }
      return ''
    },
    xTickCallback: (_: any, index: number) => {
      return `  ${xAxisData[index]}  `
    },
  }

  return (
    <Main
      isLoading={listKpiLoading}
      xAxisData={xAxisData}
      chartOptions={chartOptions}
      yAxisDataArr={yAxisDataArr}
    />
  )
}

export default CashControlTrendChart
