import { useMemo } from 'react'

const defaultConfig = {
  oloWaitTime: {
    isVisible: false,
  },
  realTimeKpis: [
    {
      variableName: 'totalOrderCount',
      displayName: '# of Delivery Orders',
    },
    {
      variableName: 'sumSubtotal',
      displayName: 'Delivery Sales',
    },
    {
      variableName: 'sumErrorCharges',
      displayName: '$ of Adjustments',
    },
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
  ],
  realTimeKpisSmall: [
    {
      variableName: 'totalOrderCount',
      displayName: '# of Delivery Orders',
    },
    {
      variableName: 'sumSubtotal',
      displayName: 'Delivery Sales',
    },
    {
      variableName: 'sumErrorCharges',
      displayName: '$ of Adjustments',
    },
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'inaccurateOrderPercent',
      displayName: '% Orders w/ Accuracy Issues',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: '% Orders w/ Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: '% Orders w/ Late Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
  ],
  breakdownOfIssues: [
    {
      variableName: 'inaccurateOrderPercent',
      displayName: 'Accuracy',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: 'Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: 'Lateness',
    },
  ],
  deliveryChart: {
    isVisible: true,
  },
  breakdownByStore: [
    {
      variableName: 'totalOrderCount',
      displayName: '# of Delivery Orders',
    },
    {
      variableName: 'sumSubtotal',
      displayName: 'Delivery Sales ($)',
    },
    {
      variableName: 'sumErrorCharges',
      displayName: '$ of Adjustments',
    },
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Avg Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: 'Orders with Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Delivery Rating',
    },
  ],
}

const drgConfig = {
  oloWaitTime: {
    isVisible: false,
  },
  realTimeKpis: [
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
  ],
  realTimeKpisSmall: [
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'inaccurateOrderPercent',
      displayName: '% Orders w/ Accuracy Issues',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: '% Orders w/ Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: '% Orders w/ Late Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
  ],
  breakdownOfIssues: [
    {
      variableName: 'inaccurateOrderPercent',
      displayName: 'Accuracy',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: 'Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: 'Lateness',
    },
  ],
  deliveryChart: {
    isVisible: true,
  },
  breakdownByStore: [
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Avg Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: 'Orders with Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Delivery Rating',
    },
  ],
}

const pjConfig = {
  realTimeKpis: [
    {
      variableName: 'totalOrderCount',
      displayName: '# of Delivery Orders',
    },
    {
      variableName: 'sumSubtotal',
      displayName: 'Delivery Sales',
    },
    {
      variableName: 'averageTripTime',
      displayName: 'Average Driver Trip Time',
    },
  ],
  realTimeKpisSmall: [
    {
      variableName: 'totalOrderCount',
      displayName: '# of Delivery Orders',
    },
    {
      variableName: 'sumSubtotal',
      displayName: 'Delivery Sales',
    },
    {
      variableName: 'averageTripTime',
      displayName: 'Average Driver Trip Time',
    },
  ],
  breakdownByStore: [
    {
      variableName: 'totalOrderCount',
      displayName: '# of Delivery Orders',
    },
    {
      variableName: 'sumSubtotal',
      displayName: 'Delivery Sales ($)',
    },
    {
      variableName: 'averageTripTime',
      displayName: 'Average Trip Time (min)',
    },
  ],
}

const pjDemoConfig = {
  realTimeKpis: [
    {
      variableName: 'totalOrderCount',
      displayName: '# of Delivery Orders',
    },
    {
      variableName: 'sumSubtotal',
      displayName: 'Delivery Sales',
    },
    {
      variableName: 'sumErrorCharges',
      displayName: '$ of Adjustments',
    },
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
    {
      variableName: 'averageTripTime',
      displayName: 'Average Driver Trip Time',
    },
  ],
  realTimeKpisSmall: [
    {
      variableName: 'totalOrderCount',
      displayName: '# of Delivery Orders',
    },
    {
      variableName: 'sumSubtotal',
      displayName: 'Delivery Sales',
    },
    {
      variableName: 'sumErrorCharges',
      displayName: '$ of Adjustments',
    },
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'inaccurateOrderPercent',
      displayName: '% Orders w/ Accuracy Issues',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: '% Orders w/ Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: '% Orders w/ Late Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
    {
      variableName: 'averageTripTime',
      displayName: 'Average Driver Trip Time',
    },
  ],
  breakdownOfIssues: [
    {
      variableName: 'inaccurateOrderPercent',
      displayName: 'Accuracy',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: 'Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: 'Lateness',
    },
  ],
  deliveryChart: {
    isVisible: true,
  },
  breakdownByStore: [
    {
      variableName: 'totalOrderCount',
      displayName: '# of Delivery Orders',
    },
    {
      variableName: 'sumSubtotal',
      displayName: 'Delivery Sales ($)',
    },
    {
      variableName: 'sumErrorCharges',
      displayName: '$ of Adjustments',
    },
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Avg Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: 'Orders with Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Delivery Rating',
    },
    {
      variableName: 'averageTripTime',
      displayName: 'Average Trip Time (min)',
    },
  ],
}

const wingitnorthConfig = {
  ...defaultConfig,
  realTimeKpis: defaultConfig.realTimeKpis.map((kpi) => {
    if (kpi.variableName === 'sumErrorCharges') {
      return {
        ...kpi,
        displayName: 'M&I',
      }
    }

    return kpi
  }),
  realTimeKpisSmall: defaultConfig.realTimeKpisSmall.map((kpi) => {
    if (kpi.variableName === 'sumErrorCharges') {
      return {
        ...kpi,
        displayName: 'M&I',
      }
    }

    return kpi
  }),
  breakdownByStore: defaultConfig.breakdownByStore.map((kpi) => {
    if (kpi.variableName === 'sumErrorCharges') {
      return {
        ...kpi,
        displayName: 'M&I',
      }
    }

    return kpi
  }),
}

const wingstopConfig = {
  ...defaultConfig,
  oloWaitTime: {
    isVisible: true,
    displayName: 'Avg Wait Time (Olo)',
  },
  realTimeKpis: defaultConfig.realTimeKpis.filter(
    ({ variableName }) => variableName !== 'avgDasherWaitTime',
  ),
  realTimeKpisSmall: defaultConfig.realTimeKpisSmall.filter(
    ({ variableName }) => variableName !== 'avgDasherWaitTime',
  ),
  breakdownByStore: defaultConfig.breakdownByStore.filter(
    ({ variableName }) =>
      variableName !== 'avgDasherWaitTime' &&
      variableName !== 'sumErrorCharges',
  ),
}

const fwWingstopConfig = {
  oloWaitTime: {
    isVisible: false,
  },
  realTimeKpis: [
    {
      variableName: 'sumErrorCharges',
      displayName: 'M&I',
    },
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
  ],
  realTimeKpisSmall: [
    {
      variableName: 'sumErrorCharges',
      displayName: 'M&I',
    },
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'inaccurateOrderPercent',
      displayName: '% Orders w/ Accuracy Issues',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: '% Orders w/ Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: '% Orders w/ Late Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
  ],
  breakdownOfIssues: [
    {
      variableName: 'inaccurateOrderPercent',
      displayName: 'Accuracy',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: 'Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: 'Lateness',
    },
  ],
  deliveryChart: {
    isVisible: true,
  },
  breakdownByStore: [
    {
      variableName: 'sumErrorCharges',
      displayName: 'M&I',
    },
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Avg Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: 'Orders with Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Delivery Rating',
    },
  ],
}

const config = {
  rmg: drgConfig,
  sullivans: drgConfig,
  eddiemerlots: drgConfig,
  papajohns: pjConfig,
  papajohns_demo: pjDemoConfig,
  wingstop: wingstopConfig,
  wingitnorth: wingitnorthConfig,
  fw_wingstop: fwWingstopConfig,
}

const useDeliveryConfig = (brand: string): any =>
  useMemo(() => {
    switch (brand) {
      case 'rmg':
      case 'rmg_nso':
        return config.rmg
      case 'sullivans':
      case 'sullivans_nso':
        return config.sullivans
      case 'eddiemerlots':
      case 'eddiemerlots_nso':
        return config.eddiemerlots
      case 'papajohns':
        return config.papajohns
      case 'papajohns_demo':
        return config.papajohns_demo
      case 'wingitnorth':
        return config.wingitnorth
      case 'wingstop':
        return config.wingstop
      case 'bibibop':
        return {
          ...defaultConfig,
          customizedRealTimeKpis: [
            'totalOrderCount',
            'sumSubtotal',
            'completedDisputeAmount',
          ],
          realTimeKpis: defaultConfig.realTimeKpis.map((data) =>
            data.variableName !== 'sumErrorCharges'
              ? data
              : {
                  variableName: 'completedDisputeAmount',
                  displayName: 'Completed Disputes',
                },
          ),
          realTimeKpisSmall: defaultConfig.realTimeKpisSmall.map((data) =>
            data.variableName !== 'sumErrorCharges'
              ? data
              : {
                  variableName: 'completedDisputeAmount',
                  displayName: 'Completed Disputes',
                },
          ),
          customizedbreakdownByStore: ['totalOrderCount', 'sumSubtotal'],
          breakdownByStoreTitle: 'Delivery Metrics',
          breakdownByStore: defaultConfig.breakdownByStore.filter(
            ({ variableName }) =>
              !['sumErrorCharges', 'orderWithAnyIssuePercent'].includes(
                variableName,
              ),
          ),
          customizedDeliveryChart: ['totalOrderCount'],
          isDisputeTableVisible: true,
        }
      case 'fw_wingstop':
        return config.fw_wingstop

      default:
        return defaultConfig
    }
  }, [brand])

export default useDeliveryConfig
