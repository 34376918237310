import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'
import {
  toFormattedInteger,
  toPercentString,
  toUsdStr,
} from 'pared/utils/number'

import {
  IListLocationLossPreventionKpisType,
  LIST_LOCATION_LOSS_PREVENTION_KPIS,
} from '../gqls'
import useLossPreventionConfig, {
  IKpiSettingType,
} from './useLossPreventionConfig'

export default (locationId: number, startDate?: string, endDate?: string) => {
  const brand = getBrand()
  const config = useLossPreventionConfig(brand)

  const { data, error, loading } =
    useQuery<IListLocationLossPreventionKpisType>(
      LIST_LOCATION_LOSS_PREVENTION_KPIS,
      {
        variables: {
          iStartDate: startDate,
          iEndDate: endDate,
          iFilter: { location_ids: [locationId] },
        },
        skip: !startDate || !endDate || !locationId,
      },
    )

  return useMemo(() => {
    const rawData = data?.listLocationLossPreventionKpis?.nodes?.[0]

    if (!rawData) return []

    const kpis = config?.kpiSettings?.map((kpi: IKpiSettingType) => {
      if (kpi.category === 'basic') {
        const value = (() => {
          switch (kpi.key) {
            case 'cashOverOrShort':
              return toUsdStr(rawData?.cashOverOrShort / 100.0)
            case 'oloOverOrShort':
              return toUsdStr(rawData?.oloOverOrShort / 100.0)
            case 'totalComps':
              return toUsdStr(rawData?.totalComps / 100.0)
            case 'inaccurateCashierShiftPercent':
              return toPercentString(rawData?.inaccurateCashierShiftPercent)
            case 'inaccurateCashierShiftCount':
              return rawData?.inaccurateCashierShiftCount
            case 'compsPercent':
              return toPercentString(rawData?.compsPercent)
          }
        })()

        return {
          key: kpi.key,
          value,
          text: kpi.title,
        }
      } else if (kpi.category === 'custom') {
        const data = rawData?.lossPreventionItems?.find(
          (item) => item.type === kpi.type,
        )
        const value = (() => {
          switch (kpi.key) {
            case 'amount':
              return toUsdStr((data?.amount || 0) / 100.0 || '')
            case 'count':
              return toFormattedInteger(data?.count || '')
            case 'checkPercent':
              return toPercentString(data?.checkPercent || '')
          }
        })()

        return {
          key: kpi.type,
          value,
          text: kpi.title,
        }
      } else {
        return { key: '', value: '', text: '' }
      }
    })

    return kpis
  }, [data, locationId, startDate, endDate])
}
