import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import styled from 'styled-components'

import Print from 'pared/components/basicUi/Print'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'

import Table from './Table'
import useStaffSalesTableConfig from './hooks/useStaffSalesTableConfig'
import { IOrderBy } from './index'

interface IProps {
  isLoading: boolean
  salesType: string
  setSalesType: any
  isStaffSalesExpanded: boolean
  setIsStaffSalesExpanded: any
  orderBy: IOrderBy
  setOrderBy: any
  staffSalesRowData: any
  storeTitle: string
  dayPart: string
}

const WebLargeMain = ({
  isLoading,
  salesType,
  setSalesType,
  isStaffSalesExpanded,
  setIsStaffSalesExpanded,
  orderBy,
  setOrderBy,
  staffSalesRowData,
  storeTitle,
}: IProps) => {
  const staffSalesTableConfig = useStaffSalesTableConfig()
  const brandSettings = getBrandSettings()
  const isQsr = brandSettings.isQsr
  const isLegacy = brandSettings.isLegacy
  const salesmanshipLabels = brandSettings.labels.salesmanship
  const kpis = staffSalesTableConfig.kpis

  if (isLoading) {
    return (
      <>
        <PageHeader>{salesmanshipLabels.staffSales}</PageHeader>
        <LoadingContainer>
          <PageStatusDiv>Loading ...</PageStatusDiv>
        </LoadingContainer>
      </>
    )
  }

  const handleStaffSalesChange = async (event: any) => {
    const value = _.get(event, 'target.value', '')
    setSalesType(value)
  }

  const onToggleExpandStaffSales = () => {
    setIsStaffSalesExpanded(!isStaffSalesExpanded)
  }

  const handleOrdering = (event: any) => {
    const orderingColumnValue = _.get(event, 'currentTarget.dataset.value')
    setOrderBy({
      columnName: orderingColumnValue,
      isAscending: !orderBy.isAscending,
    })
  }

  const staffSalesToggleText = isStaffSalesExpanded
    ? 'Collapse all'
    : 'Expand All'

  return (
    <>
      <StaffOuterContainer>
        <div>
          <StaffOuterHeader>
            <PageHeader>
              {salesmanshipLabels.staffSales}

              <Print>
                <Header>{storeTitle}</Header>

                <PrintTableHeader>
                  {salesmanshipLabels.staffSales}

                  <RankBy>Rank by {kpis[salesType]?.name}</RankBy>
                </PrintTableHeader>

                <PrintContinaer>
                  <Table
                    dataSource={[...staffSalesRowData]}
                    fieldName={kpis[salesType]?.name}
                    orderBy={orderBy}
                    handleOrdering={handleOrdering}
                  />
                </PrintContinaer>
              </Print>
            </PageHeader>
          </StaffOuterHeader>

          {isQsr || !isLegacy ? null : (
            <SubtitleContainer>
              Main Dining and Bar Sales Only
            </SubtitleContainer>
          )}
        </div>

        <FilterContainer>
          <DescriptionContainer>Rank by</DescriptionContainer>
          <SelectorContainer>
            <FormControl variant="outlined" margin="dense">
              <SalesmanSelect
                displayEmpty
                value={salesType}
                onChange={handleStaffSalesChange}
              >
                {Object.keys(kpis).map((kpiKey: string) => {
                  const config = kpis[kpiKey]
                  return (
                    <MenuItem key={kpiKey} value={kpiKey}>
                      {config.name}
                    </MenuItem>
                  )
                })}
              </SalesmanSelect>
            </FormControl>
          </SelectorContainer>
        </FilterContainer>
      </StaffOuterContainer>

      <MobileContainer>
        <StaffTableContainer>
          <Table
            dataSource={
              isStaffSalesExpanded
                ? [...staffSalesRowData]
                : [...staffSalesRowData].slice(0, 5)
            }
            fieldName={kpis[salesType]?.name}
            orderBy={orderBy}
            handleOrdering={handleOrdering}
          />
        </StaffTableContainer>
        <ExpandAllSpan onClick={onToggleExpandStaffSales}>
          {staffSalesToggleText}
        </ExpandAllSpan>
      </MobileContainer>
    </>
  )
}

const LoadingContainer = styled.div`
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 50px;
  height: 400px;
  width: 100%;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const StaffOuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 14px;
  max-width: 980px;

  @media ${MOBILE_WIDTH} {
    display: block;
  }

  .MuiFormControl-root {
    width: 220px;

    @media ${MOBILE_WIDTH} {
      width: 100%;
    }
  }
`

const StaffOuterHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

const PageHeader = styled.div`
  display: flex;
  gap: 15px;
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
`

const Header = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-weight: 700;
`

const PrintTableHeader = styled.div`
  margin: 30px 0px 10px;
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-weight: 700;
`

const RankBy = styled.span`
  margin-left: 10px;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-weight: normal;
`

const PrintContinaer = styled.div`
  max-width: 650px;
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
`

const DescriptionContainer = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  white-space: nowrap;
`

const SelectorContainer = styled.div`
  margin-left: 25px;

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

const SalesmanSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const StaffTableContainer = styled.div`
  margin-top: 30px;
  width: 1000px;
`

const ExpandAllSpan = styled.div`
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;
`

const SubtitleContainer = styled.div`
  position: relative;
  top: 7px;
  height: 0px;
  font-family: Lexend-Regular;
  font-style: italic;
`

const MobileContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

export default WebLargeMain
