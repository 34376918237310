import { useEffect, useMemo, useState } from 'react'

import {
  GROUP_BY_PERIOD,
  GROUP_BY_QUARTER,
  GROUP_BY_WEEK,
  GROUP_BY_YEAR,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_YEAR,
} from 'pared/data/getDateRanges'
import getGroupedTimeIntervals, {
  IGroupedTimeInterval,
} from 'pared/data/getGroupedTimeIntervals'

export interface IReportFilterType {
  id: string
  parentId: 'root' | null
  value: string
  text: string
}

export interface IReportInfo {
  type: string
  groupBy: string
  startDateStr: string
  endDateStr: string
}

interface IReportInfoMap {
  [filterDisplayStr: string]: IReportInfo
}

export const REPORT_TYPE_DETAILS = 'details'
export const REPORT_TYPE_TREND = 'trend'

export const PERIOD_FILTER_DISPLAY_STR = 'Period'
export const QUARTER_FILTER_DISPLAY_STR = 'Quarter'
export const YEAR_FILTER_DISPLAY_STR = 'Year'

const useReportFilter = (
  startDateStr?: string,
  endDateStr?: string,
  intervalType?: string,
) => {
  const [reportFilter, setReportFilter] = useState<string[]>([])
  const [subTimeIntervals, setSubTimeIntervals] = useState<
    IGroupedTimeInterval[]
  >([])
  const [reportInfoMap, setReportInfoMap] = useState<IReportInfoMap>({})

  let intervalDisplayStr = ''
  let subIntervalTrendDisplayStr = ''
  let subIntervalDisplayStr = ''
  let intervalGroupType = ''
  let subIntervalGroupType = ''

  switch (intervalType) {
    case TYPE_PERIOD:
      intervalDisplayStr = PERIOD_FILTER_DISPLAY_STR
      subIntervalTrendDisplayStr = 'Weekly'
      subIntervalDisplayStr = 'Week'
      intervalGroupType = GROUP_BY_PERIOD
      subIntervalGroupType = GROUP_BY_WEEK
      break
    case TYPE_QUARTER:
      intervalDisplayStr = QUARTER_FILTER_DISPLAY_STR
      subIntervalTrendDisplayStr = 'By Period'
      subIntervalDisplayStr = PERIOD_FILTER_DISPLAY_STR
      intervalGroupType = GROUP_BY_QUARTER
      subIntervalGroupType = GROUP_BY_PERIOD
      break
    case TYPE_YEAR:
      intervalDisplayStr = YEAR_FILTER_DISPLAY_STR
      subIntervalTrendDisplayStr = 'Quarterly'
      subIntervalDisplayStr = QUARTER_FILTER_DISPLAY_STR
      intervalGroupType = GROUP_BY_YEAR
      subIntervalGroupType = GROUP_BY_QUARTER
      break
    default:
      subIntervalGroupType = ''
  }

  useEffect(() => {
    async function fetchSubTimeIntervals() {
      if (startDateStr && endDateStr && subIntervalGroupType) {
        const groupedTimeIntervals = await getGroupedTimeIntervals(
          startDateStr,
          endDateStr,
          subIntervalGroupType,
        )
        setSubTimeIntervals(groupedTimeIntervals)
      }
    }

    fetchSubTimeIntervals()
  }, [startDateStr, endDateStr, intervalType])

  const filterData = useMemo(() => {
    const menuItemList: IReportFilterType[] = [
      {
        id: 'root',
        parentId: null,
        value: 'root',
        text: 'root',
      },
    ]

    if (
      startDateStr &&
      endDateStr &&
      intervalType &&
      intervalDisplayStr &&
      subIntervalTrendDisplayStr
    ) {
      menuItemList.push({
        id: intervalDisplayStr,
        parentId: 'root' as const,
        value: intervalDisplayStr,
        text: intervalDisplayStr,
      })
      menuItemList.push({
        id: subIntervalTrendDisplayStr,
        parentId: 'root' as const,
        value: subIntervalTrendDisplayStr,
        text: subIntervalTrendDisplayStr,
      })

      const newReportInfoMap: IReportInfoMap = {}
      newReportInfoMap[intervalDisplayStr] = {
        startDateStr,
        endDateStr,
        type: REPORT_TYPE_DETAILS,
        groupBy: intervalGroupType,
      }
      newReportInfoMap[subIntervalTrendDisplayStr] = {
        startDateStr,
        endDateStr,
        type: REPORT_TYPE_TREND,
        groupBy: subIntervalGroupType,
      }

      subTimeIntervals.forEach((subInterval) => {
        const displayStr = `${subIntervalDisplayStr} ${subInterval.groupNumber}`
        menuItemList.push({
          id: displayStr,
          parentId: 'root' as const,
          value: displayStr,
          text: displayStr,
        })
        newReportInfoMap[displayStr] = {
          type: REPORT_TYPE_DETAILS,
          groupBy: subIntervalGroupType,
          startDateStr: subInterval.startDateStr,
          endDateStr: subInterval.endDateStr,
        }
      })

      setReportInfoMap(newReportInfoMap)
    }

    return menuItemList
  }, [subTimeIntervals, startDateStr, endDateStr, intervalType])

  useEffect(() => {
    setReportFilter([filterData?.[1]?.value])
  }, [filterData])

  return {
    reportFilter,
    setReportFilter,
    reportFilters: filterData,
    reportInfoMap,
  }
}

export default useReportFilter
