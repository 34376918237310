import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import { track } from 'pared/analytics/storeList'
import { IColumnsType } from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'
import { getBrandSettings, getFinancialLabel } from 'pared/customer'
import { TYPE_PERIOD, TYPE_QUARTER, TYPE_YEAR } from 'pared/data/getDateRanges'
import { toFormattedInteger, toPercentString } from 'pared/utils/number'

import useConfig from './useConfig'
import { IDataSourceType } from './useStoresDataSource'

const Span = styled.span<{ danger: boolean }>`
  color: ${({ danger }) => (danger ? 'red' : COLORS.Chalkboard)};
`

const LinkButton = styled(Link)`
  color: ${COLORS.Link};
`

const LocationGroupNameButton = styled.div`
  color: ${COLORS.Link};
  cursor: pointer;
  text-decoration: underline;
`

const styleBackground = `background: ${COLORS.Porcelain};`

const useStoresColumns = (
  isBreakdownByDirectors: boolean,
  isBreakdownByLocationGroupType: boolean,
  locationGroupType: string | null,
  handleLocationGroupNameClick: (
    locationGroupId: number,
    locationGroupName: string,
    directorId?: number,
  ) => void,
  isAvtMonthlyVisible: boolean,
  selectedDateRangeType: string,
): IColumnsType<IDataSourceType>[] => {
  const config = useConfig()
  const brandSettings = getBrandSettings()
  const businessLabels = brandSettings.labels.business
  const displayPeriodCogs =
    config.usePeriodCogs &&
    [TYPE_PERIOD, TYPE_QUARTER, TYPE_YEAR].includes(selectedDateRangeType)

  return useMemo(
    () => [
      ...((isBreakdownByDirectors || isBreakdownByLocationGroupType
        ? []
        : [
            {
              key: 'storeCode',
              title: `${businessLabels.store}s`,
              width: '225px',
              render: (_, values) => {
                if (!businessLabels.isStoreClickable)
                  return `${values?.storeCode} - ${values?.storeName}`

                return !values || !('storeId' in values) ? null : (
                  <LinkButton
                    to={navigator.storeDetail(values.storeId)}
                    onClick={() => track.locationLinkClicked(values.storeId)}
                  >
                    {values.storeCode} - {values.storeName}
                  </LinkButton>
                )
              },
              sorter: true,
            },
          ]) as IColumnsType<IDataSourceType>[]),
      ...((isBreakdownByLocationGroupType
        ? []
        : [
            {
              key: 'directorName',
              title: businessLabels['director'],
              width: isBreakdownByDirectors ? '320px' : '200px',
              render: (value: IDataSourceType['directorName'], data) =>
                data && 'directorId' in data ? (
                  businessLabels.isDirectorClickable ? (
                    <LinkButton
                      to={navigator.employeeProfile(data.directorId)}
                      onClick={() =>
                        track.directorProfileLinkClicked(data.directorId)
                      }
                    >
                      {value}
                    </LinkButton>
                  ) : (
                    <LocationGroupNameButton
                      onClick={() =>
                        handleLocationGroupNameClick(0, '', data.directorId)
                      }
                    >
                      {value}
                    </LocationGroupNameButton>
                  )
                ) : (
                  value
                ),
              sorter: true,
            },
          ]) as IColumnsType<IDataSourceType>[]),
      ...((isBreakdownByLocationGroupType
        ? [
            {
              key: 'locationGroupName',
              title: locationGroupType,
              width: '200px',
              render: (value: IDataSourceType['locationGroupId'], data) =>
                data && 'locationGroupId' in data ? (
                  <LocationGroupNameButton
                    onClick={() =>
                      handleLocationGroupNameClick(
                        data.locationGroupId,
                        data.locationGroupName,
                      )
                    }
                  >
                    {value}
                  </LocationGroupNameButton>
                ) : null,
              sorter: true,
            },
          ]
        : []) as IColumnsType<IDataSourceType>[]),
      ...(!brandSettings.overview.netSales.isVisible
        ? []
        : ([
            {
              key: 'Net Sales',
              align: 'center',
              cellStyle: styleBackground,
              children: [
                {
                  key: 'netSalesNumber',
                  title: '$',
                  align: 'center',
                  width: '80px',
                  render: (
                    _: IDataSourceType['netSalesNumber'],
                    { netSales },
                  ) => netSales,
                  sorter: true,
                  cellStyle: styleBackground,
                },
                ...(!brandSettings.overview.netSalesBudgetVariance.isVisible
                  ? []
                  : ([
                      {
                        key: 'netSalesPerformanceAgainstBudgetPercentNumber',
                        title:
                          getFinancialLabel('netSalesVariance') || 'Variance',
                        align: 'center',
                        width: '80px',
                        render: (
                          value: IDataSourceType['netSalesPerformanceAgainstBudgetPercentNumber'],
                          { netSalesPerformanceAgainstBudgetPercent },
                        ) => (
                          <Span danger={value < 0}>
                            {netSalesPerformanceAgainstBudgetPercent}
                          </Span>
                        ),
                        sorter: true,
                        cellStyle: styleBackground,
                      },
                    ] as IColumnsType<IDataSourceType>[])),
                ...(!brandSettings.overview.netSalesSssPercent.isVisible
                  ? []
                  : ([
                      {
                        key: 'netSalesSssPercentNumber',
                        title: getFinancialLabel('sss'),
                        align: 'center',
                        width: '80px',
                        render: (
                          value: IDataSourceType['netSalesSssPercentNumber'],
                          { ptdNetSalesSss },
                        ) => <Span danger={value < 0}>{ptdNetSalesSss}</Span>,
                        sorter: true,
                        cellStyle: styleBackground,
                      },
                    ] as IColumnsType<IDataSourceType>[])),
              ],
            },
          ] as IColumnsType<IDataSourceType>[])),
      ...(!brandSettings.overview.grossSales.isVisible
        ? []
        : ([
            {
              key: 'Gross Sales',
              align: 'center',
              cellStyle: styleBackground,
              children: [
                {
                  key: 'grossSalesNumber',
                  title: '$',
                  align: 'center',
                  width: '80px',
                  render: (
                    _: IDataSourceType['grossSalesNumber'],
                    { grossSales },
                  ) => grossSales,
                  sorter: true,
                  cellStyle: styleBackground,
                },
                {
                  key: 'grossSalesPerformanceAgainstBudgetPercentNumber',
                  title: 'Variance',
                  align: 'center',
                  width: '80px',
                  render: (
                    value: IDataSourceType['grossSalesPerformanceAgainstBudgetPercentNumber'],
                    { grossSalesPerformanceAgainstBudgetPercent },
                  ) => (
                    <Span danger={value < 0}>
                      {grossSalesPerformanceAgainstBudgetPercent}
                    </Span>
                  ),
                  sorter: true,
                  cellStyle: styleBackground,
                },
                ...(!brandSettings.overview.grossSalesSssPercent.isVisible
                  ? []
                  : ([
                      {
                        key: 'grossSalesSssPercentNumber',
                        title: getFinancialLabel('sss'),
                        align: 'center',
                        width: '80px',
                        render: (
                          value: IDataSourceType['grossSalesSssPercentNumber'],
                          { ptdGrossSalesSss },
                        ) => <Span danger={value < 0}>{ptdGrossSalesSss}</Span>,
                        sorter: true,
                        cellStyle: styleBackground,
                      },
                    ] as IColumnsType<IDataSourceType>[])),
              ],
            },
          ] as IColumnsType<IDataSourceType>[])),
      {
        key: getFinancialLabel('hourlyLabor'),
        align: 'center',
        children: [
          {
            key: 'hourlyLaborPercentNumber',
            title: '%',
            align: 'center',
            width: '80px',
            render: (
              _: IDataSourceType['hourlyLaborPercentNumber'],
              { hourlyLaborPercent },
            ) => hourlyLaborPercent,
            sorter: true,
          },
          ...(!brandSettings.overview.hourlyLaborBudgetVariance.isVisible
            ? []
            : [
                {
                  key: 'hourlyLaborPerformanceAgainstBudgetPercentNumber',
                  title: getFinancialLabel('hourlyLaborVariance') || 'Variance',
                  align: 'center',
                  width: '80px',
                  render: (
                    value: IDataSourceType['hourlyLaborPerformanceAgainstBudgetPercentNumber'],
                    { hourlyLaborPerformanceAgainstBudgetPercent },
                  ) => (
                    <Span danger={value > 0}>
                      {hourlyLaborPerformanceAgainstBudgetPercent}
                    </Span>
                  ),
                  sorter: true,
                },
              ]),
        ],
      },
      ...(!brandSettings.overview.cogs.isVisible &&
      !brandSettings.overview.cogsBudgetVariance.isVisible &&
      !brandSettings.overview.avt.isVisible &&
      !brandSettings.overview.avtMonthly.isVisible &&
      !brandSettings.overview.plv.isVisible
        ? []
        : [
            {
              key: brandSettings.overview.cogs.isVisible
                ? getFinancialLabel('cogs')
                : getFinancialLabel('avt'),
              align: 'center',
              cellStyle: styleBackground,
              children: [
                ...(!brandSettings.overview.cogs.isVisible
                  ? []
                  : ([
                      {
                        key: displayPeriodCogs
                          ? 'periodCogsPercentNumber'
                          : 'cogsPercentNumber',
                        title: '%',
                        align: 'center',
                        width: '80px',
                        render: (
                          _: IDataSourceType['cogsPercentNumber'],
                          { ptdCogsPercent, periodCogsPercent },
                        ) =>
                          displayPeriodCogs
                            ? periodCogsPercent
                            : ptdCogsPercent,
                        sorter: true,
                        cellStyle: styleBackground,
                      },
                    ] as IColumnsType<IDataSourceType>[])),
                ...(!brandSettings.overview.cogsBudgetVariance.isVisible
                  ? []
                  : ([
                      {
                        key: displayPeriodCogs
                          ? 'periodCogsBudgetVarianceNumber'
                          : 'cogsBudgetVarianceNumber',
                        title: getFinancialLabel('cogsVariance') || 'Variance',
                        align: 'center',
                        width: '80px',
                        render: (
                          _: IDataSourceType['cogsBudgetVarianceNumber'],
                          {
                            ptdCogsPercent,
                            cogsBudgetVariance,
                            cogsBudgetVarianceNumber,
                            periodCogsPercent,
                            periodCogsBudgetVariance,
                            periodCogsBudgetVarianceNumber,
                          },
                        ) => {
                          if (displayPeriodCogs) {
                            return periodCogsPercent === '-' ? (
                              '-'
                            ) : (
                              <Span danger={periodCogsBudgetVarianceNumber > 0}>
                                {periodCogsBudgetVariance}
                              </Span>
                            )
                          } else {
                            return ptdCogsPercent === '-' ? (
                              '-'
                            ) : (
                              <Span danger={cogsBudgetVarianceNumber > 0}>
                                {cogsBudgetVariance}
                              </Span>
                            )
                          }
                        },
                        sorter: true,
                        cellStyle: styleBackground,
                      },
                    ] as IColumnsType<IDataSourceType>[])),
                ...(!brandSettings.overview.avt.isVisible
                  ? []
                  : ([
                      {
                        key: 'avtPercentNumber',
                        title: brandSettings.overview.cogs.isVisible
                          ? getFinancialLabel('avt')
                          : '%',
                        align: 'center',
                        width: '80px',
                        render: (
                          value: IDataSourceType['avtPercentNumber'],
                          { avtPercent },
                        ) => <Span danger={value < 0}>{avtPercent}</Span>,
                        sorter: true,
                        cellStyle: styleBackground,
                      },
                    ] as IColumnsType<IDataSourceType>[])),
                ...(!brandSettings.overview.avtMonthly.isVisible
                  ? []
                  : ([
                      {
                        key: 'avtPercentMonthlyNumber',
                        title: brandSettings.overview.cogs.isVisible
                          ? getFinancialLabel('avt')
                          : '%',
                        align: 'center',
                        width: '80px',
                        render: (
                          value: IDataSourceType['avtPercentMonthlyNumber'],
                          { avtPercentMonthly, avtPercentWeekly },
                        ) => (
                          <Span danger={value < 0}>
                            {isAvtMonthlyVisible
                              ? avtPercentMonthly !== '-'
                                ? avtPercentMonthly
                                : avtPercentWeekly
                              : '-'}
                          </Span>
                        ),
                        sorter: true,
                        cellStyle: styleBackground,
                      },
                    ] as IColumnsType<IDataSourceType>[])),
                ...(!brandSettings.overview.plv.isVisible
                  ? []
                  : ([
                      {
                        key: 'plvPercentNumber',
                        title: brandSettings.overview.cogs.isVisible
                          ? getFinancialLabel('plv')
                          : '%',
                        align: 'center',
                        width: '80px',
                        render: (
                          value: IDataSourceType['plvPercentNumber'],
                          { plvPercent },
                        ) => <Span danger={value < 0}>{plvPercent}</Span>,
                        sorter: true,
                        cellStyle: styleBackground,
                      },
                    ] as IColumnsType<IDataSourceType>[])),
              ],
            },
          ]),
      ...(!brandSettings.overview.rcp.isVisible
        ? []
        : ([
            {
              key: getFinancialLabel('rcp'),
              align: 'center',
              children: [
                {
                  key: 'rcpNumber',
                  title: '$',
                  align: 'center',
                  width: '80px',
                  render: (_: IDataSourceType['rcpNumber'], { rcp }) => rcp,
                  sorter: true,
                },
                {
                  key: 'rcpPercentNumber',
                  title: '%',
                  align: 'center',
                  width: '80px',
                  render: (
                    _: IDataSourceType['rcpPercentNumber'],
                    { ptdRcp },
                  ) => ptdRcp,
                  sorter: true,
                },
                {
                  key: 'rcpBudgetVarianceNumber',
                  title: 'Variance',
                  align: 'center',
                  width: '80px',
                  render: (
                    value: IDataSourceType['rcpBudgetVarianceNumber'],
                    { rcpBudgetVariance },
                  ) => <Span danger={value < 0}>{rcpBudgetVariance}</Span>,
                  sorter: true,
                },
              ],
            },
          ] as IColumnsType<IDataSourceType>[])),
      ...(!brandSettings.overview.checkCount.isVisibleOnBreakDownTable
        ? []
        : ([
            {
              key: getFinancialLabel('transaction'),
              align: 'center',
              children: [
                ...(!brandSettings.overview.checkCount
                  ?.isVisibleOnBreakDownTable
                  ? []
                  : ([
                      {
                        key: 'totalCheckCount',
                        title: '#',
                        align: 'center',
                        width: '80px',
                        render: (
                          _: IDataSourceType['totalCheckCount'],
                          dataSource,
                        ) => {
                          if ((dataSource?.totalCheckCount || 0) > 0) {
                            return toFormattedInteger(
                              dataSource?.totalCheckCount || 0,
                            )
                          }
                          return '-'
                        },
                        sorter: true,
                      },
                    ] as IColumnsType<IDataSourceType>[])),
                ...(!brandSettings.overview.checkCountVsLyCount
                  ?.isVisibleOnBreakDownTable
                  ? []
                  : ([
                      {
                        key: 'totalCheckCountVsLyCount',
                        title: '# vs LY',
                        align: 'center',
                        width: '80px',
                        render: (
                          _: IDataSourceType['totalCheckCountVsLyCount'],
                          dataSource,
                        ) => {
                          if ((dataSource?.totalCheckCount || 0) > 0) {
                            return (
                              <Span
                                danger={
                                  (dataSource?.totalCheckCountVsLyCount || 0) <
                                  0
                                }
                              >
                                {toFormattedInteger(
                                  dataSource?.totalCheckCountVsLyCount || 0,
                                )}
                              </Span>
                            )
                          }
                          return '-'
                        },
                        sorter: true,
                      },
                    ] as IColumnsType<IDataSourceType>[])),
                ...(!brandSettings.overview.checkCountVsLyPercent
                  ?.isVisibleOnBreakDownTable
                  ? []
                  : ([
                      {
                        key: 'totalCheckCountVsLyPercent',
                        title: '% vs LY',
                        align: 'center',
                        width: '80px',
                        render: (
                          _: IDataSourceType['totalCheckCountVsLyPercent'],
                          dataSource,
                        ) => {
                          if (
                            (dataSource?.totalCheckCount || 0) > 0 &&
                            (dataSource?.yoyTotalCheckCount || 0) > 0
                          ) {
                            return (
                              <Span
                                danger={
                                  (dataSource?.totalCheckCountVsLyPercent ||
                                    0) < 0
                                }
                              >
                                {toPercentString(
                                  dataSource?.totalCheckCountVsLyPercent || 0,
                                  1,
                                )}
                              </Span>
                            )
                          }
                          return '-'
                        },
                        sorter: true,
                      },
                    ] as IColumnsType<IDataSourceType>[])),
              ],
            },
          ] as IColumnsType<IDataSourceType>[])),
      ...(!brandSettings.overview.sosAvgSeconds.isVisible
        ? []
        : ([
            {
              key: getFinancialLabel('sos'),
              align: 'center',
              children: [
                {
                  key: 'sosAvgSeconds',
                  title: 'Seconds',
                  align: 'center',
                  width: '150px',
                  render: (
                    _: IDataSourceType['sosAvgSeconds'],
                    { sosAvgSeconds },
                  ) => sosAvgSeconds || '-',
                  sorter: true,
                },
              ],
            },
          ] as IColumnsType<IDataSourceType>[])),
    ],
    [
      isBreakdownByDirectors,
      isBreakdownByLocationGroupType,
      locationGroupType,
      brandSettings,
      isAvtMonthlyVisible,
    ],
  )
}

export default useStoresColumns
