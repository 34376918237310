import { useMediaQuery } from 'react-responsive'
import { matchPath, useLocation } from 'react-router-dom'

import navParams from 'pared/Routes/navParams'
import {
  URL_SEARCH_DATE_RANGE,
  URL_SEARCH_REDIRECT,
  URL_SEARCH_STORE,
} from 'pared/Routes/navigator'
import { IRoute, signInRequiredRoutes } from 'pared/Routes/webRoutes'
import { decodedUserJwtToken } from 'pared/reactiveVariables/user'
import { getUser } from 'pared/utils/user'

export default () => {
  const urlInfo = useLocation()
  const user = getUser()
  const jwt = decodedUserJwtToken()
  const isMobileView = useMediaQuery({ maxWidth: 599 })

  if (jwt) {
    navParams.canManageUsers = jwt.can_manage_users
  }

  navParams.canViewUserEventLogs = user.canViewUserEventLogs

  if (user?.defaultLocationId) {
    navParams.userDefaultStoreId = user.defaultLocationId
    navParams.isStoreLevelUser = user.isStoreLevelUser || false
  }

  navParams.isMobileView = isMobileView
  navParams.pagePathname = urlInfo.pathname
  navParams.pageUrl = [urlInfo.pathname, urlInfo.search].join('')

  const parsedSearchParams = new URLSearchParams(urlInfo.search)
  const storeId = parsedSearchParams.get(URL_SEARCH_STORE)
  if (storeId) {
    navParams.storeId = storeId
  } else {
    delete navParams.storeId
  }

  const dateRange = parsedSearchParams.get(URL_SEARCH_DATE_RANGE)
  if (dateRange) {
    navParams.dateRange = dateRange
  } else {
    delete navParams.dateRange
  }

  const redirect = parsedSearchParams.get(URL_SEARCH_REDIRECT)
  if (redirect) {
    navParams.redirect = redirect
  } else {
    delete navParams.redirect
  }

  const routeCount = signInRequiredRoutes.length
  let route: IRoute | null = null
  let urlMatch: any = null
  for (let routeIndex = 0; routeIndex < routeCount; ++routeIndex) {
    route = signInRequiredRoutes[routeIndex]
    urlMatch = matchPath(urlInfo.pathname, route)
    if (urlMatch) {
      break
    }
  }

  if (urlMatch?.params) {
    const matchedParams = urlMatch.params
    if (matchedParams.employeeId) {
      navParams.employeeId = matchedParams.employeeId
    }
  }

  if (route?.navTabType) {
    navParams.navTabType = route.navTabType
  } else {
    delete navParams.navTabType
  }
  if (route?.isPageFullWidth) {
    navParams.isPageFullWidth = route.isPageFullWidth
  } else {
    delete navParams.isPageFullWidth
  }

  return navParams
}
