import paredAnalytics from 'pared/analytics/utils/ParedAnalytics'
import { IUser } from 'pared/data/getUserByEmail'

export async function identifyUserForAnalytics(user: IUser) {
  await paredAnalytics.identify(user)
}

export async function resetUserForAnalytics() {
  await paredAnalytics.reset()
}

export const track = {
  async signIn() {
    await paredAnalytics.track('User - Sign In')
  },

  async signOut() {
    await paredAnalytics.track('User - Sign Out')
  },

  async changePasswordButtonClicked() {
    await paredAnalytics.track('User - Change Password Button Clicked')
  },

  async passwordChanged() {
    await paredAnalytics.track('User - Password Changed')
  },

  async forgotPasswordEmailContinueClicked(email: string) {
    await paredAnalytics.track(
      'User - Forgot Password Email Continue Clicked',
      { email },
    )
  },
}

export const page = {
  async visit(pageUrl: string, pageDetails?: any) {
    await paredAnalytics.page(pageUrl, pageDetails)
  },
}

export const feature = {
  async used(featureName: string, featureDetails?: any) {
    await paredAnalytics.feature(featureName, featureDetails)
  },
}
