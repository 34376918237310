import { gql } from '@apollo/client'

export const GET_NORMALIZATION_STATUS = gql`
  query GetNormalizationStatus($iNormalizationTable: String!, $iBrandId: Int!) {
    getNormalizationStatus(
      iNormalizationTable: $iNormalizationTable
      iBrandId: $iBrandId
    ) {
      nodes {
        status
      }
    }
  }
`
