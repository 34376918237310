import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import useBrands from 'pared/layouts/hooks/useBrands'

import { IApiDataType } from '../types'

interface IParamsPropsType {
  id: string
}

const queries = gql`
  query ListEmployeeTrainingTracker($iEmployeeId: Int!) {
    listEmployeeTrainingTracker(iEmployeeId: $iEmployeeId) {
      nodes {
        assignedTracker
        trackerUuid
        needApproved
        assignedDate
        completionRate
        completedDate
        approvedDate
        approverFirstName
        approverLastName
      }
    }
  }
`

export const jscBaseballCardConfigs = {
  trackerUuid: 'string',
  trackerName: 'link',
  completionRate: 'progress-bar',
  completedAt: 'string',
  approvedAt: 'toggle',
  deleteTrainingTracker: 'delete-training-tracker',
} as const

const useJscBaseballCard = () => {
  const { brand } = useBrands()
  const { id }: IParamsPropsType = useParams()
  const { data, loading } = useQuery(queries, {
    variables: {
      iEmployeeId: parseInt(id),
    },
    skip: !id,
  })

  return {
    data: useMemo((): IApiDataType => {
      if (!data?.listEmployeeTrainingTracker) return null

      return {
        source: data.listEmployeeTrainingTracker.nodes.map((tracker: any) => {
          const trackerUrl = `/${brand}/training_tracker_detail/${tracker.trackerUuid}`
          const trackerName =
            tracker.assignedTracker === 'Restaurant Leader' &&
            !tracker.assignedDate
              ? 'RL Submitted for Approval'
              : tracker.assignedTracker
          const assignedAt = tracker.assignedDate
            ? moment(tracker.assignedDate).format('M/D/YY')
            : ''

          const completionRate = tracker.assignedDate
            ? tracker.completionRate * 100
            : null
          const completedAt = tracker.completedDate
            ? moment(tracker.completedDate).format('M/D/YY')
            : 'In Progress'

          const approveText = !tracker.completedDate
            ? ' '
            : tracker.needApproved ||
              (tracker.assignedTracker === 'Crew Training' &&
                !tracker.approvedDate)
            ? 'Waiting for Approval'
            : tracker.approvedDate
            ? `Approved by
                ${tracker.approverFirstName} ${tracker.approverLastName}
                (${moment(tracker.approvedDate).format('M/D/YY')})`
            : 'Submit for Approval'

          return {
            trackerUuid: tracker.trackerUuid,
            trackerName: {
              label: trackerName,
              url: trackerUrl,
              disabled: !tracker.assignedDate,
            },
            completionRate: completionRate,
            completedAt: completedAt,
            approvedAt: {
              checked: !(
                tracker.completedDate &&
                !tracker.needApproved &&
                !tracker.approvedDate
              ),
              label: approveText,
              disabled:
                tracker.needApproved ||
                (tracker.assignedTracker === 'Crew Training' &&
                  !tracker.approvedDate),
            },
            deleteTrainingTracker: {
              trackerUuid: tracker.trackerUuid,
              assignedTracker: tracker.assignedTracker,
              assignedAt: assignedAt,
            },
          }
        }),
      }
    }, [data]),
    loading,
  }
}

export default useJscBaseballCard
