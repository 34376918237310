import { useMemo } from 'react'

import { toPercentString, toUsdString } from 'pared/utils/number'

import { useVariables } from '../../../variables'
import type { IApiDataType } from '../../types'
import useFetchGroupMetricValue from './useFetchGroupMetricValue'

const useBbbCorpFnp = (): IApiDataType => {
  const { variables } = useVariables()

  const { data, loading } = useFetchGroupMetricValue([
    'total_food_and_paper',
    'total_food_and_paper_budget',
    'f_and_p_percentage',
    'f_and_p_budget_percent',
  ])

  return {
    data: useMemo(() => {
      if (!data) return null

      const currentPeriod = variables.date?.getInfo(0)
      const fnp = data.total_food_and_paper ?? 0
      const fnpBudget = data.total_food_and_paper_budget ?? 0
      const fnpPct = data.f_and_p_percentage ?? 0
      const fnpBudgetPct = data.f_and_p_budget_percent ?? 0
      const priorFnpPct = data.prior_f_and_p_percentage ?? 0
      const yoyFnpPct = data.yoy_f_and_p_percentage ?? 0
      const fnpVarPct = fnpPct - fnpBudgetPct

      // ADD TEXT HERE START

      const fnpVarPctText =
        fnpVarPct >= 0
          ? `Missing the ${toPercentString(
              fnpBudgetPct,
            )} budget by ${toPercentString(fnpVarPct)}`
          : `Beating the ${toPercentString(
              fnpBudgetPct,
            )} budget by ${toPercentString(-fnpVarPct)}`

      const fnpVarPctPeriodChgText =
        priorFnpPct === 0
          ? `No Food & Paper data from Prior Period for comparison`
          : fnpPct >= priorFnpPct
          ? `Increasing ${toPercentString(
              fnpPct - priorFnpPct,
            )} from Prior Period of ${toPercentString(priorFnpPct)}`
          : `Decreasing ${toPercentString(
              priorFnpPct - fnpPct,
            )} from Prior Period of ${toPercentString(priorFnpPct)}`

      const fnpPctYoyChgText =
        yoyFnpPct === 0
          ? `No Food & Paper data from Prior Year for comparison`
          : fnpPct >= yoyFnpPct
          ? `Increasing ${toPercentString(
              fnpPct - yoyFnpPct,
            )} from Prior Year of ${toPercentString(yoyFnpPct)}`
          : `Decreasing ${toPercentString(
              yoyFnpPct - fnpPct,
            )} from Prior Year of ${toPercentString(yoyFnpPct)}`

      // ADD TEXT HERE END

      return {
        title: `Food & Paper`,
        total: fnpVarPct,
        summary: `Food & Paper Cost in ${
          currentPeriod?.displayName
        } was ${toPercentString(fnpPct)}`,
        detail: `
          <ul>
            <li>${fnpVarPctText} or ${toUsdString(
          Math.abs(fnp - fnpBudget),
        )}</li>
            <li>${fnpVarPctPeriodChgText}</li>
            <li>${fnpPctYoyChgText}</li>
          </ul>`,
        hasDetails: true,
      }
    }, [data, variables]),
    loading,
  }
}

export default useBbbCorpFnp
