import useWingitnorthListLocationDailyFlash, {
  wingitnorthListLocationDailyFlashConfigs,
} from './useWingitnorthListLocationDailyFlash'
import useWingitnorthListLocationGroupDailyFlash, {
  wingitnorthListLocationGroupDailyFlashConfigs,
} from './useWingitnorthListLocationGroupDailyFlash'

export const wingitnorthDailyFlashConfigs = {
  ...wingitnorthListLocationDailyFlashConfigs,
  ...wingitnorthListLocationGroupDailyFlashConfigs,
}

const useWingitnorthDailyFlash = () => {
  const wingitnorthListLocationDailyFlash =
    useWingitnorthListLocationDailyFlash()
  const wingitnorthListLocationGroupDailyFlash =
    useWingitnorthListLocationGroupDailyFlash()

  return (wingitnorthListLocationDailyFlash?.data?.source || []).length === 0
    ? wingitnorthListLocationGroupDailyFlash
    : wingitnorthListLocationDailyFlash
}

export default useWingitnorthDailyFlash
