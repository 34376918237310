import Modal from '@material-ui/core/Modal'
import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'

import useDeleteTrackerModal, {
  IDeleteTrackerModalType,
} from './hooks/useDeleteTrackerModal'

export interface IPropsType extends IDeleteTrackerModalType {
  assignedTracker: string
  assignedAt: string
  onClose: () => void
}

const Container = styled.div`
  width: 40%;
  min-width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px;
  outline: none;
  background-color: white;
`

const Title = styled.div`
  padding: 0 0 15px 0;
  font-family: Lexend-SemiBold;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
`

const Text = styled.div`
  font-family: Lexend-Regular;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const ConfirmButton = styled.button<{ disabled: boolean }>`
  margin-top: 30px;
  background-color: #07a4c7;
  border: none;
  color: white;
  padding: 10px 15px 10px 15px;
  width: 130px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-family: Lexend-Regular;
  ${({ disabled }) =>
    disabled
      ? `
          cursor: none;
          pointer-events: none;
        `
      : `cursor: pointer;`};
`

const DeleteTrackerModal = ({ onClose, ...props }: IPropsType) => {
  const { submit, isLoading, isSubmitting } = useDeleteTrackerModal(
    props,
    onClose,
  )

  return (
    <Modal onClose={onClose} open>
      <Container>
        <Title>Do you want to delete this tracker?</Title>
        <Text>{`Assigned Tracker: ${props.assignedTracker}`}</Text>
        <Text>{`Assigned Date: ${props.assignedAt}`}</Text>
        <ButtonContainer>
          <ConfirmButton onClick={submit} disabled={isLoading || isSubmitting}>
            <Spin spinning={isLoading || isSubmitting}>Delete</Spin>
          </ConfirmButton>
        </ButtonContainer>
      </Container>
    </Modal>
  )
}

export default DeleteTrackerModal
