export const jitbKpiDefs = {
  baconAttachmentRate: {
    key: 'baconAttachmentRate',
    name: 'Bacon Attachment Rate',
    kpiType: 'sales',
    salesType: 'Bacon',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  cheeseAttachmentRate: {
    key: 'cheeseAttachmentRate',
    name: 'Cheese Attachment Rate',
    kpiType: 'sales',
    salesType: 'Cheese',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  beverageAttachmentRate: {
    key: 'beverageAttachmentRate',
    name: 'Beverage Attachment Rate',
    kpiType: 'sales',
    salesType: 'Beverage',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
}
