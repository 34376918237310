import _ from 'lodash'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import { MONTH_ABBREVIATION_MAP } from 'pared/constants'
import COLORS from 'pared/constants/colors'
import { toPercentageString } from 'pared/utils/number'

interface IProps {
  xAxisData: string[]
  yAxisInaccurateData: number[]
  yAxisCancelledData: number[]
  yAxisDelayedData: number[]
  yAxisTotalOrderCountData: number[]
  yAxisAnyIssueData: number[]
  chartOptions: any
  yAxisDataArr: number[][]
  isLoading: boolean
}

function WebLargeMain({
  xAxisData,
  chartOptions,
  yAxisDataArr,
  isLoading,
}: IProps) {
  if (isLoading) {
    return (
      <LoadingContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </LoadingContainer>
    )
  }

  return (
    <>
      <MixedChart
        xAxisData={xAxisData}
        yAxisDataArr={yAxisDataArr}
        options={chartOptions}
      />
    </>
  )
}

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 20px 0;
`

const LoadingContainer = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  height: 400px;
  width: 100%;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

export default WebLargeMain
