import _ from 'lodash'
import { useMemo } from 'react'

import { getBrandSettings } from 'pared/customer'
import { toUsdString } from 'pared/utils/number'

import { ISalesmanship } from '../../../index'
import { addRankToTableData } from '../../../util'
import { ILeaderboardData } from '../../LeaderboardTable'

const getEmployeeName = (data: ISalesmanship): string => {
  const employeeNameParts = []
  const firstName = _.startCase(data?.firstName) || ''
  if (firstName) {
    employeeNameParts.push(firstName)
  }
  const lastName = _.startCase(data?.lastName) || ''
  if (lastName) {
    employeeNameParts.push(lastName)
  }

  return employeeNameParts.join(' ')
}

function useSalesLeaderboardData(
  salesLeaderboardData: ISalesmanship[],
  salesType: string,
  numberOfDecimals: number,
) {
  return useMemo(() => {
    const brandSettings = getBrandSettings()
    let leaderboardData: ILeaderboardData[] = salesLeaderboardData.map(
      (rawData: any) => {
        const value = rawData?.[salesType] || 0
        const isStoreAvg = rawData?.rowType === 'avg'
        const id = isStoreAvg ? '' : rawData?.employeeId
        const name = isStoreAvg
          ? `${brandSettings.labels.business.store} Average`
          : getEmployeeName(rawData)

        return {
          employeeId: id,
          employeeName: name,
          value,
          displayString: toUsdString(value, numberOfDecimals),
          isStoreAvg,
        }
      },
    )

    leaderboardData = _.orderBy(
      leaderboardData,
      ['value', 'employeeName'],
      ['desc', 'asc'],
    )
    leaderboardData = addRankToTableData(leaderboardData)

    return {
      leaderboardData,
    }
  }, [salesLeaderboardData, salesType, numberOfDecimals])
}

export default useSalesLeaderboardData
