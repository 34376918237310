import { useQuery } from '@apollo/client'
import _ from 'lodash'

import COLORS from 'pared/constants/colors'
import { getBusinessLabel } from 'pared/customer'

import Main from './Main'
import { GET_ENTREES_PER_LABOR_HOUR } from './gql'

interface IProps {
  locationName: string
  locationId: number
  startDate: string
  endDate: string
}

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

const convertHourToString = (hour: number) => {
  if (hour === 0) {
    return '12am'
  }

  if (hour === 12) {
    return '12pm'
  }

  if (hour < 12) {
    return `${hour}am`
  }

  if (hour > 12) {
    return `${hour - 12}pm`
  }

  return ''
}

const prependToLastElement = (arr: string[], str: string) => {
  const clonedArr = [...arr]
  if (clonedArr.length > 1) {
    clonedArr[clonedArr.length - 1] = `${str}${clonedArr[clonedArr.length - 1]}`
  }

  return clonedArr
}

function EntreesPerLaborHourChart({
  locationName,
  locationId,
  startDate,
  endDate,
}: IProps) {
  const { loading, data: response } = useQuery(GET_ENTREES_PER_LABOR_HOUR, {
    variables: {
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip: !locationId || !startDate || !endDate,
  })

  const data: {
    hour: number
    entrees: number
    systemwideEntrees: number
    differenceEntrees: number
    laborHours: number
    systemwideLaborHours: number
    differenceLaborHours: number
    entreesPerLaborHour: number
    systemwideEntreesPerLaborHour: number
    differenceEntreesPerLaborHour: number
    totalEntrees: number
    totalSystemwideEntrees: number
    totalDifferenceEntrees: number
    totalLaborHours: number
    totalSystemwideLaborHours: number
    totalDifferenceLaborHours: number
    totalEntreesPerLaborHour: number
    totalSystemwideEntreesPerLaborHour: number
    totalDifferenceEntreesPerLaborHour: number
  }[] = response?.getEntreesPerLaborHour?.nodes || []

  const { totalDifferenceEntreesPerLaborHour } = data[0] ?? {}
  let summaryMessage: string | React.ReactElement = ''
  if (totalDifferenceEntreesPerLaborHour >= 0) {
    summaryMessage = `Good job! ${locationName} is efficient and performing above Entree Per Labor Hour average.`
  } else {
    summaryMessage = (
      <p>
        {locationName} is less productive than average and produces{' '}
        <strong>
          {(-1 * totalDifferenceEntreesPerLaborHour).toFixed(1)} Entrees Less
          Per Labor Hour
        </strong>{' '}
        than benchmark.
      </p>
    )
  }

  let doubleRedFlags = []
  let entreeRedFlags = []
  let laborRedFlags = []
  for (const { hour, differenceEntrees, differenceLaborHours } of data) {
    const hourStr = convertHourToString(hour)
    if (differenceEntrees < 0 && differenceLaborHours > 0) {
      doubleRedFlags.push(hourStr)
    }

    if (differenceEntrees < 0) {
      entreeRedFlags.push(hourStr)
    }

    if (differenceLaborHours > 0) {
      laborRedFlags.push(hourStr)
    }
  }

  doubleRedFlags = prependToLastElement(doubleRedFlags, 'and ')
  entreeRedFlags = prependToLastElement(entreeRedFlags, 'and ')
  laborRedFlags = prependToLastElement(laborRedFlags, 'and ')

  let areasOfImprovementMessages = []
  if (doubleRedFlags.length > 0) {
    areasOfImprovementMessages.push(
      <p>
        At {doubleRedFlags.join(', ')}, {locationName} is staffing{' '}
        <strong>higher Labor Hours yet serving less Entrees</strong> than other
        CAVA restaurants.
      </p>,
    )
  } else if (laborRedFlags.length === 0 && entreeRedFlags.length === 0) {
    areasOfImprovementMessages.push('None at this time.')
  } else {
    if (laborRedFlags.length > 0) {
      areasOfImprovementMessages.push(
        `Labor hours are higher than average at ${laborRedFlags.join(', ')}.`,
      )
    }

    if (entreeRedFlags.length > 0) {
      areasOfImprovementMessages.push(
        `Entree sales are below average at ${entreeRedFlags.join(', ')}.`,
      )
    }
  }

  const xAxisData = data.map(({ hour }) => convertHourToString(hour))

  const yAxisDataArr = [
    {
      type: 'line',
      tooltipLabel: `${locationName}`,
      backgroundColor: COLORS.Plum,
      borderColor: COLORS.Plum,
      data: data.map(
        ({ entreesPerLaborHour }) => entreesPerLaborHour?.toFixed(1) ?? 0,
      ),
    },
    {
      type: 'line',
      tooltipLabel: getBusinessLabel('accessGroupType') || 'Systemwide',
      backgroundColor: COLORS.Mango,
      borderColor: COLORS.Mango,
      data: data.map(
        ({ systemwideEntreesPerLaborHour }) =>
          systemwideEntreesPerLaborHour?.toFixed(1) ?? 0,
      ),
    },
  ]

  const chartOptions = {
    title: '',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: 'Entrees per Labor Hour',
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let yValue = ''
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          if (tooltipItemContext.datasetIndex === 0) {
            const dataIndex = tooltipItemContext.dataIndex
            yValue = [
              `Average Entrees: ${data[dataIndex].entrees.toFixed(1)}`,
              `Average Labor Hours: ${data[dataIndex].laborHours.toFixed(1)}`,
              `Entrees per Labor Hour: ${tooltipItemContext.parsed.y}`,
            ].join('; ')
          } else if (tooltipItemContext.datasetIndex === 1) {
            const dataIndex = tooltipItemContext.dataIndex
            yValue = [
              `Average Entrees: ${data[dataIndex].systemwideEntrees.toFixed(
                1,
              )}`,
              `Average Labor Hours: ${data[
                dataIndex
              ].systemwideLaborHours.toFixed(1)}`,
              `Entrees per Labor Hour: ${tooltipItemContext.parsed.y}`,
            ].join('; ')
          } else {
            yValue = tooltipItemContext.parsed.y
          }
        } else {
          yValue = tooltipItemContext.parsed.y
        }

        return yValue
      }

      return ''
    },
  }

  return (
    <Main
      isLoading={loading}
      xAxisData={xAxisData}
      chartOptions={chartOptions}
      yAxisDataArr={yAxisDataArr}
      summaryMessage={summaryMessage}
      areasOfImprovementMessages={areasOfImprovementMessages}
    />
  )
}

export default EntreesPerLaborHourChart
