import _ from 'lodash'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { getBrand } from 'pared/utils/brand'

import useGuestConfig from '../hooks/useGuestConfig'
import { IOpsCompsByReason } from '../index'

interface IProps {
  opsCompsByReasons: IOpsCompsByReason[]
}

interface IRankTableTh {
  textAlign?: string
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
}

function WebLargeMain({ opsCompsByReasons }: IProps) {
  const guestConfig = useGuestConfig(getBrand())
  const opsCompsByReasonRows = _.map(opsCompsByReasons, (c, index) => {
    return (
      <tr key={index + 1}>
        <RankNumberTd textAlign={'left'} bold>
          {index + 1}
        </RankNumberTd>
        <RankTableTd textAlign={'left'}>
          {_.get(c, 'opsCompsReason', '')}
        </RankTableTd>
        <RankTableTd>{_.get(c, 'quantity', 1)}</RankTableTd>
        <RankTableTd>{_.get(c, 'totalCompsAmount', 0)}</RankTableTd>
      </tr>
    )
  })

  return (
    <MainContainer>
      <BreakdownContainer>
        {guestConfig['opsCompsBreakdown'].title}
      </BreakdownContainer>
      <TableDescription>
        {guestConfig['opsCompsBreakdown'].subtitle}
      </TableDescription>
      <RankTable>
        <thead>
          <tr>
            <RankTableTh textAlign={'left'}>Rank</RankTableTh>
            <RankTableTh textAlign={'left'}>Menu Item</RankTableTh>
            <RankTableTh>Quantity</RankTableTh>
            <RankTableTh>$ Comps</RankTableTh>
          </tr>
        </thead>
        <tbody>{opsCompsByReasonRows}</tbody>
      </RankTable>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 600px;
`

const TableDescription = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 10px;
`

const RankTableTh = styled.th<IRankTableTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankTableTd = styled.td<IRankTableTd>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankNumberTd = styled(RankTableTd)`
  padding-left: 12px;
`

const RankTable = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`

const BreakdownContainer = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 50px;
`

export default WebLargeMain
