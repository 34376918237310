import { useMemo } from 'react'

import { IColumnsType } from '../types'

const mergeBody = <Data>(columns: IColumnsType<Data>[]): IColumnsType<Data>[] =>
  columns.reduce(
    (result, column) => [
      ...result,
      ...(!column.children ? [column] : mergeBody(column.children)),
    ],
    [] as IColumnsType<Data>[],
  )

const useMergedBody = <Data>(columns: IColumnsType<Data>[]) =>
  useMemo(() => mergeBody(columns), [columns])

export default useMergedBody
