import SegmentAnalytics from 'analytics-node'
import moment from 'moment'

import navParams from 'pared/Routes/navParams'
import { IUser } from 'pared/data/getUserByEmail'
import { getBrand } from 'pared/utils/brand'

// To satisfy Amplitude's requirement for userId to have a minimum length of 5 characters
const USER_ID_PREFIX = '0000'

class ParedAnalytics {
  segmentWriteKey: string
  analytics: any

  userId: number | null

  constructor() {
    this.segmentWriteKey = process.env.REACT_APP_SEGMENT_WRITE_KEY || ''
    this.userId = null

    this.analytics = new SegmentAnalytics(this.segmentWriteKey, {
      flushAt: 1,
      flushInterval: 10000,
    })
  }

  // Note: Identify must be called to set the currentUserId for subsequent calls to
  // screen() & track() populate the correct user id
  async identify(user: IUser) {
    if (user && user.userId) {
      this.userId = user.userId
    } else {
      this.userId = null
    }

    const brand = getBrand()

    if (this.analytics && this.userId) {
      const recordedAt = moment()
      const userName = `${user.firstName} ${user.lastName}`
      const userEmail = user.email
      await this.analytics.identify({
        userId: `${USER_ID_PREFIX}${this.userId}`,
        originalUserId: this.userId,
        userRoleCode: user.employeeRoleCode,
        userName,
        userEmail,
        brand,
        traits: {
          userId: `${USER_ID_PREFIX}${this.userId}`,
          originalUserId: this.userId,
          userRoleCode: user.employeeRoleCode,
          userName,
          userEmail,
          recordedAt: recordedAt.format(),
          brand,
        },
        timestamp: recordedAt.toDate(),
      })
    }
  }

  // track - events
  async track(event: string, properties?: any) {
    if (event) {
      if (this.analytics && this.userId) {
        const isMobileView = !!navParams.isMobileView
        const eventName = isMobileView
          ? `[track][mobile] ${event}`
          : `[track] ${event}`
        const recordedAt = moment()
        const brand = getBrand()
        // console.log(`-- track event: "${event}" for the user (ID ${this.userId}) at ${recordedAt.format()}`)
        await this.analytics.track({
          isMobileView,
          event: eventName,
          userId: `${USER_ID_PREFIX}${this.userId}`,
          originalUserId: this.userId,
          brand,
          properties: {
            ...properties,
            isMobileView,
            event: eventName,
            userId: `${USER_ID_PREFIX}${this.userId}`,
            originalUserId: this.userId,
            recordedAt: recordedAt.format(),
            brand,
          },
          timestamp: recordedAt.toDate(),
        })
      }
    }
  }

  // feature
  async feature(featureName: string, properties?: any) {
    if (featureName) {
      if (this.analytics && this.userId) {
        const isMobileView = !!navParams.isMobileView
        const featureUsed = isMobileView
          ? `[feature][mobile] ${featureName}`
          : `[feature] ${featureName}`
        const recordedAt = moment()
        const brand = getBrand()
        // console.log(`-- track event: "${event}" for the user (ID ${this.userId}) at ${recordedAt.format()}`)
        await this.analytics.track({
          isMobileView,
          event: featureUsed,
          userId: `${USER_ID_PREFIX}${this.userId}`,
          originalUserId: this.userId,
          brand,
          properties: {
            ...properties,
            isMobileView,
            event: featureUsed,
            userId: `${USER_ID_PREFIX}${this.userId}`,
            originalUserId: this.userId,
            recordedAt: recordedAt.format(),
            brand,
          },
          timestamp: recordedAt.toDate(),
        })
      }
    }
  }

  // page
  async page(pageUrl: string, properties?: any) {
    if (pageUrl) {
      if (this.analytics && this.userId) {
        const isMobileView = !!navParams.isMobileView
        const pageName = isMobileView ? '[page][mobile]' : '[page]'
        const recordedAt = moment()
        const brand = getBrand()
        // console.log(`-- page event: the user (ID ${this.userId}) visited the page "${pageUrl}" at ${recordedAt.format()}`)
        await this.analytics.page({
          isMobileView,
          pageUrl,
          name: pageName,
          userId: `${USER_ID_PREFIX}${this.userId}`,
          originalUserId: this.userId,
          brand,
          properties: {
            ...properties,
            isMobileView,
            pageUrl,
            event: `Visit Page: ${pageName}`,
            userId: `${USER_ID_PREFIX}${this.userId}`,
            originalUserId: this.userId,
            recordedAt: recordedAt.format(),
            brand,
          },
          timestamp: recordedAt.toDate(),
        })
      }
    }
  }

  // Reset
  async reset() {
    this.userId = null
  }
}

const paredAnalytics = new ParedAnalytics()
export default paredAnalytics
