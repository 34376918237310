import { IApiDataType } from '../types'
import useAcePopDailyFlash, {
  acePopDailyFlashConfigs,
} from './useAcePopDailyFlash'
import useJscBkFlash, { jscBkFlashConfigs } from './useJscBkFlash'
import useLfrListRestaurantGfpReport, {
  lfrListRestaurantGfpReportConfigs,
} from './useLfrListRestaurantGfpReport'

export type IApiKeyType = keyof typeof api

export const configs = {
  jscBkFlash: jscBkFlashConfigs,
  lfrListRestaurantGfpReport: lfrListRestaurantGfpReportConfigs,
  acePopDailyFlash: acePopDailyFlashConfigs,
}

export const api = {
  jscBkFlash: useJscBkFlash,
  lfrListRestaurantGfpReport: useLfrListRestaurantGfpReport,
  acePopDailyFlash: useAcePopDailyFlash,
}

const useApi = (
  apiName: IApiKeyType,
): {
  data: IApiDataType
  loading: boolean
} => api[apiName]()

export default useApi
