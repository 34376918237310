import React from 'react'
import styled from 'styled-components'

import Chart from 'pared/charts'
import Select from 'pared/components/basicUi/select'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import useGetLocationKpiTrend from './hooks/useGetLocationKpiTrend'

interface IPropsType {
  locationId: number
}

const Title = styled.div`
  margin: 0px 0px 20px;
  padding: 0px 0px 20px;
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-weight: 700;

  .MuiOutlinedInput-root {
    margin: 0px 0px 0px 20px;
    @media ${MOBILE_WIDTH} {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
`

const MobileContainer = styled.div`
  margin: 0px 0px 60px;

  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const LoadingContainer = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
  margin-bottom: 50px;
`

const LaborTrends = ({ locationId }: IPropsType) => {
  const { config, option, options, onChange, isLoading } =
    useGetLocationKpiTrend(locationId)

  return (
    <>
      <Title>
        LABOR TRENDS
        <Select value={option} onChange={onChange} dataSource={options} />
      </Title>

      {isLoading ? (
        <LoadingContainer>Loading...</LoadingContainer>
      ) : (
        <MobileContainer>
          <Chart {...config} width={1100} height={350} />
        </MobileContainer>
      )}
    </>
  )
}

export default React.memo(LaborTrends)
