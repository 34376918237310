import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAsync } from 'react-use'

import getDateRanges from 'pared/data/getDateRanges'

interface IOptions {
  isCustomAllowed?: boolean
}

// TODO
// FIXME: should be rewritten by useQuery
const useGetDateRange = (options?: IOptions) => {
  const { search } = useLocation()
  const state = useAsync(getDateRanges, [search])

  return useMemo(() => {
    const queryObject = new URLSearchParams(search)
    const currentDateRange = queryObject.get('date_range')

    return currentDateRange ? state.value?.dateRangeMap[currentDateRange] : null
  }, [state, search])
}

export default useGetDateRange
