import { useMap } from 'react-leaflet'

export interface IProps {
  center: number[]
  zoom: number
}

const ChangeView = ({ center, zoom }: IProps) => {
  const map = useMap()
  // @ts-ignore
  map.setView(center, zoom)
  return null
}

export default ChangeView
