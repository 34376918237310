import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useDateRange from 'pared/components/DateRangeNumberSelector/hooks/useDateRange'
import { getDateRangeLabelV2 } from 'pared/utils/date'

import { useDateFilter } from '../../../dateFilter'
import { IApiDataType } from '../../types'

type IMetricType = string | { key: string; type: 'yoy' }

type IMetricDataType = Record<
  string,
  {
    name: string
    unit: 'CENT' | 'PERCENTAGE' | 'DOLLAR' | 'COUNT'
    value: number
  }
>

type INodeType = Parameters<typeof getDateRangeLabelV2>[1] & {
  locationGroupId: number
  startDate: string
  endDate: string
  metricSummaryData: IMetricDataType
}

interface IDataType {
  trendLocationGroupMetricValues: {
    nodes: INodeType[]
  }
}

const query = gql`
  query TrendLocationGroupMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
      iGroupBy: $iGroupBy
    ) {
      nodes {
        locationGroupId
        groupByStartDate: startDate
        groupByEndDate: endDate
        businessYear
        businessWeek
        businessWeekOfMonth
        businessMonth
        metricSummaryData
      }
    }
  }
`

const format = (data: IMetricDataType) =>
  Object.entries(data).reduce((result, [key, value]) => {
    switch (value.unit) {
      case 'PERCENTAGE':
      case 'DOLLAR':
        return {
          ...result,
          [_.camelCase(key)]: value.value * 100,
        }
      default:
        return {
          ...result,
          [_.camelCase(key)]: value.value,
        }
    }
  }, {})

const buildMetricValuesHook = (
  metrics: {
    codes?: IMetricType[]
    groups?: IMetricType[]
  },
  dateRange: string,
) => {
  const useMetricValues = () => {
    const { startDate, endDate } = useDateFilter()
    const { dateRangeNumber, dateRangeGroupBy } = useDateRange(dateRange)
    const newStartDate = (() => {
      const currentEndDate = moment(endDate, 'YYYY-MM-DD').clone()
      switch (dateRangeGroupBy) {
        case 'last_x_days':
          return currentEndDate
            .subtract(dateRangeNumber, 'days')
            .format('YYYY-MM-DD')
        case 'last_x_weeks':
          return currentEndDate
            .subtract(dateRangeNumber, 'weeks')
            .format('YYYY-MM-DD')
        case 'last_x_periods':
          return currentEndDate
            .subtract(dateRangeNumber, 'months')
            .format('YYYY-MM-DD')
        default:
          return startDate
      }
    })()

    const { groupFilter } = useGroupFilter()
    const { data, loading } = useQuery<IDataType>(query, {
      variables: {
        iStartDate: newStartDate,
        iEndDate: endDate,
        iGroupBy: dateRangeGroupBy,
        iFilter: {
          location_group_ids: groupFilter?.ids,
          metrics:
            metrics.codes
              ?.map((m) => (typeof m === 'string' ? m : null))
              .filter(Boolean) || [],
          metric_groups:
            metrics.groups
              ?.map((m) => (typeof m === 'string' ? m : null))
              ?.filter(Boolean) || [],
        },
      },
      skip:
        !newStartDate ||
        !endDate ||
        !groupFilter ||
        !dateRange ||
        (!metrics.codes && !metrics.groups),
    })

    return {
      data: useMemo((): IApiDataType => {
        const trendLocationGroupData =
          data?.trendLocationGroupMetricValues.nodes

        if (!trendLocationGroupData || !dateRangeGroupBy) return null

        return trendLocationGroupData
          .filter((d) => d.metricSummaryData)
          .map((d) => ({
            ...format(d.metricSummaryData),
            date: getDateRangeLabelV2(dateRangeGroupBy, d),
          }))
      }, [data, dateRangeGroupBy]),
      loading,
    }
  }
  return useMetricValues
}

export default buildMetricValuesHook
