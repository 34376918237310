import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/icons/Menu'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import paredAnalytics from 'pared/analytics/utils/ParedAnalytics'
import { CORPORATE_LEVEL_NAV_TABS, STORE_LEVEL_NAV_TABS } from 'pared/constants'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'
import ExpoWhiteLogo from 'pared/images/Expo/expo-symbol-white-1x.svg'
import UploadIcon from 'pared/images/Pared/upload.svg'

import { INavParams } from '../Routes/navParams'
import BrandSelector from './BrandSelector'
import {
  leftDrawerWidthLarge,
  topMenuHeightLarge,
  topMenuHeightSmall,
  topMenuVerticalPaddingLarge,
} from './constant'
import useConfig from './hooks/useConfig'

interface IProps {
  navParams: INavParams
  isOpen: boolean
  handleDrawerOpen: () => void
  contentRef: React.RefObject<HTMLDivElement>
}

const MenuContainer = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  gap: 20px;
  height: ${topMenuHeightLarge}px;
  width: 100%;
  position: sticky;
  left: 0px;
  padding: ${topMenuVerticalPaddingLarge}px 20px;
  background-color: rgb(59, 59, 59);
  color: white;

  svg {
    color: white;
  }

  @media ${MOBILE_WIDTH} {
    height: ${topMenuHeightSmall}px;
    width: 100vw;
  }
`

const LogoImg = styled.img`
  height: 30px;

  @media ${MOBILE_WIDTH} {
    display: none;
  }
`

const Spacer = styled.div`
  flex: 1;
`

const TopNavLink = styled(NavLink)`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: white;
  padding: 0px 25px 3px;
  cursor: pointer;
  text-decoration: none;

  &.active {
    border-bottom: 1px solid #ffffff;
  }

  @media ${MOBILE_WIDTH} {
    display: none;
  }
`

const FilterImg = styled.img`
  display: none;

  @media ${MOBILE_WIDTH} {
    display: initial;
    height: 25px;
    margin: 5px 0 0 0;
  }
`

const NavigationButton = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'none' : 'initial')};

  @media ${MOBILE_WIDTH} {
    display: initial;
  }
`

const TopMenu = ({
  navParams,
  isOpen,
  handleDrawerOpen,
  contentRef,
}: IProps) => {
  const config = useConfig()
  const brandSettings = getBrandSettings()
  const businessLabels = brandSettings.labels.business
  return (
    <MenuContainer open={isOpen}>
      <NavigationButton open={isOpen}>
        <IconButton edge="start" aria-label="menu" onClick={handleDrawerOpen}>
          <Menu fontSize="large" />
        </IconButton>
      </NavigationButton>
      <BrandSelector />
      <Spacer />
      <TopNavLink
        to={config.corporateDefault}
        onClick={() => {
          paredAnalytics.track('TopNavigation - Corporate Link Clicked')
        }}
        isActive={() => {
          return navParams.navTabType === CORPORATE_LEVEL_NAV_TABS
        }}
      >
        {businessLabels.corporate}
      </TopNavLink>

      {brandSettings.modules.stores.length === 0 ? null : (
        <TopNavLink
          to={config.storeDefault}
          onClick={() => {
            paredAnalytics.track('TopNavigation - Store Link Clicked')
          }}
          isActive={() => {
            return navParams.navTabType === STORE_LEVEL_NAV_TABS
          }}
        >
          {`${businessLabels.store}s`}
        </TopNavLink>
      )}

      <LogoImg src={ExpoWhiteLogo} alt="Expo Logo" />
      <FilterImg
        onClick={() => {
          contentRef.current?.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        }}
        src={UploadIcon}
        alt="Filters"
      />
    </MenuContainer>
  )
}

export default React.memo(TopMenu)
