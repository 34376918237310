import useBbbAllPeriodMetric, {
  bbbAllPeriodMetricConfigs,
} from './useAllPeriodMetric'
import useBbbFlash, { bbbFlashConfigs } from './useFlash'
import useBbbPAndL, { bbbPAndLConfigs } from './usePAndL'
import useBbbPnlRankings, { bbbPnlRankingsConfigs } from './usePnLRankings'
import useBbbProfitAfterControllables, {
  bbbProfitAfterControllablesConfigs,
} from './useProfitAfterControllables'
import useBbbSystemwideOverview, {
  bbbSystemwideOverviewConfigs,
} from './useSystemwideOverview'
import useBbbTrendPeriodMetric, {
  bbbTrendPeriodMetricConfigs,
} from './useTrendPeriodMetric'

export const configs = {
  bbbSystemwideOverview: bbbSystemwideOverviewConfigs,
  bbbFlash: bbbFlashConfigs,
  bbbPAndL: bbbPAndLConfigs,
  bbbProfitAfterControllables: bbbProfitAfterControllablesConfigs,
  bbbTrendPeriodMetric: bbbTrendPeriodMetricConfigs,
  bbbAllPeriodMetric: bbbAllPeriodMetricConfigs,
  bbbPnlRankings: bbbPnlRankingsConfigs,
}

export const api = {
  bbbSystemwideOverview: useBbbSystemwideOverview,
  bbbFlash: useBbbFlash,
  bbbPAndL: useBbbPAndL,
  bbbProfitAfterControllables: useBbbProfitAfterControllables,
  bbbTrendPeriodMetric: useBbbTrendPeriodMetric,
  bbbAllPeriodMetric: useBbbAllPeriodMetric,
  bbbPnlRankings: useBbbPnlRankings,
}
