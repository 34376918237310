import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import IconButton from '@mui/material/IconButton'
import * as _ from 'lodash'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/constants/styles'
import { toUsdStr } from 'pared/utils/number'

import Table, {
  ActionProvider,
  IConfigsType as ITableConfigsType,
  IPropsType as ITablePropsType,
} from '../table'
import { useVariables } from '../variables'
import { IExrayDataType } from './types'

export interface IDetailConfigsType extends Omit<ITableConfigsType, 'type'> {
  key: string
  hide?: `<%- ${string} %>`
}

interface IPropsType extends IExrayDataType {
  backgroundColor: string
  details: IDetailConfigsType[] | null
}

const Section = styled.div`
  display: flex;
  border-bottom: 1px solid black;

  &:last-child {
    border-bottom: 0;
  }
  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`

const Title = styled.div<{
  backgroundColor: string
}>`
  font-family: Lexend-Semibold;
  font-size: 18px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: white;
  padding: 20px 0 15px 15px;
  flex: 1;
`

const Content = styled.div`
  font-family: Lexend-Regular;
  padding: 15px;
  flex: 6;
`

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 18px;
  font-style: normal;
  font-family: Lexend-Medium;
  line-height: 34px;
  min-height: 40px;
  padding-bottom: 5px;
  gap: 8px;
`

const TableWrapper = ({
  aiSummaryKey,
  hide,
  ...props
}: Omit<IDetailConfigsType, 'key'> & {
  aiSummaryKey: IDetailConfigsType['key']
}) => {
  const { variables, setVariables, template } = useVariables()
  const action = useMemo(
    () => ({
      aiSummary: (value: unknown, values: unknown) => {
        const id = _.get(values, props.rowKey || 'groupInfo.id')
        const prevAiSummary = variables.aiSummary[aiSummaryKey]
        const isClicked =
          prevAiSummary.clickedId === id ? !prevAiSummary.isClicked : true

        setVariables({
          ...variables,
          aiSummary: {
            ...variables.aiSummary,
            [aiSummaryKey]: {
              isClicked,
              clickedId: id,
              value,
            },
          },
        })
      },
    }),
    [props, variables, setVariables],
  )

  if (hide && template(hide) !== 'false') return null

  return (
    <ActionProvider value={action}>
      <Table {...(props as ITablePropsType)} type="table" />
    </ActionProvider>
  )
}

const Category = ({
  title,
  total,
  summary = toUsdStr(total || 0),
  detail,
  backgroundColor,
  hasDetails,
  details,
}: IPropsType) => {
  const [isExpanded, setIsExpanded] = useState(false)

  if (!detail)
    <Section key={title}>
      <Title backgroundColor="black">{title}</Title>
      <Content>No data available for the selected time period.</Content>
    </Section>

  return (
    <Section>
      <Title backgroundColor={backgroundColor}>{title}</Title>
      <Content>
        {!total ? null : (
          <Header>
            <div>{summary}</div>

            {!hasDetails || !details ? null : (
              <IconButton
                size="small"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}
              </IconButton>
            )}
          </Header>
        )}

        <div dangerouslySetInnerHTML={{ __html: detail || '' }} />

        {!isExpanded || !details
          ? null
          : details.map(({ key, ...detail }) => (
              <TableWrapper {...detail} key={key} aiSummaryKey={key} />
            ))}
      </Content>
    </Section>
  )
}

export default Category
