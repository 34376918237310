import { gql } from '@apollo/client'

export interface ITrendLocationPlvPercentNodesType {
  startDate: string
  endDate: string
  locationId: number
  plvPercent: number
  systemwidePlvPercent: number
}

export interface ITrendLocationPlvPercentType {
  nodes: ITrendLocationPlvPercentNodesType[]
}

export interface ITrendLocationPlvPercentQueryType {
  trendLocationPlvPercentV2: ITrendLocationPlvPercentType | null
}

export interface IGetTrendLocationPlvPercentVariablesType {
  iEndDate: string
  iLocationId: number
  iGroupBy: string
  iDateRangeNumber: number
}

export const trendLocationPlvPercent = gql`
  query trendLocationPlvPercent(
    $iEndDate: Date
    $iLocationId: Int
    $iGroupBy: String
    $iDateRangeNumber: Int
  ) {
    trendLocationPlvPercentV2(
      iEndDate: $iEndDate
      iLocationId: $iLocationId
      iGroupBy: $iGroupBy
      iDateRangeNumber: $iDateRangeNumber
    ) {
      nodes {
        locationId
        startDate
        endDate
        plvPercent
        systemwidePlvPercent
      }
    }
  }
`
