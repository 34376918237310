import MaterialTypography from '@material-ui/core/Typography'
import styled from 'styled-components'

import useApi, { IApiKeyType } from './hooks/useApi'

export interface IPropsType<K extends IApiKeyType = IApiKeyType> {
  type: 'typography'
  apiOrContent: K | string
  variant?: 'h1' | 'body1' | 'body2'
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const StyledTypography = styled(MaterialTypography)`
  &.MuiTypography-root {
    margin-top: 20px;
    font-family: Lexend-Regular;
  }
`

const Typography = ({ apiOrContent, variant }: IPropsType) => {
  const { data, loading } = useApi(apiOrContent)

  return loading ? null : (
    <StyledTypography
      variant={variant}
      dangerouslySetInnerHTML={{ __html: data || '' }}
    />
  )
}

export default Typography
