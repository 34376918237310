import { v4 as uuidv4 } from 'uuid'

import Chart from 'pared/pages/CorporateFss/CorporateFssTable'
import { FROM_EXPO } from 'pared/pages/ExpoAi/constants'
import { IMessage } from 'pared/pages/ExpoAi/type'

export default function handleFssKpiRankings(responseData: any) {
  if (responseData?.fssKpiRankings) {
    const messageId = uuidv4()
    const results = responseData.fssKpiRankings
    const uiComponents: React.ReactNode[] = []

    if (results.fss) {
      uiComponents.push(
        <div>
          <Chart />
        </div>,
      )
    }

    if (uiComponents.length > 0) {
      const answerMessage: IMessage = {
        id: messageId,
        from: FROM_EXPO,
        content: <div>{uiComponents}</div>,
      }

      return answerMessage
    }
  }

  return null
}
