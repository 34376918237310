import moment from 'moment'

// dateStr must be in the format: YYYY-MM-DD
function formatDate(dateStr: string) {
  const date = moment(dateStr, 'YYYY-MM-DD', true)
  if (date.isValid()) {
    return date.format('M/D')
  }
  return dateStr
}

export default formatDate
