import WebLargeMain from './WebLargeMain'
import { IHandleOrderBy, IPlayerPlusMinusData } from './index'

interface IProps {
  data: IPlayerPlusMinusData[]
  roles: Array<string>
  tcMinMax: { min: number; max: number }
  gcMinMax: { min: number; max: number }
  handleOrderBy: IHandleOrderBy
}

const Main = ({ data, roles, tcMinMax, gcMinMax, handleOrderBy }: IProps) => {
  return (
    <WebLargeMain
      data={data}
      roles={roles}
      tcMinMax={tcMinMax}
      gcMinMax={gcMinMax}
      handleOrderBy={handleOrderBy}
    />
  )
}

export default Main
