export interface INestedDataType {
  [key: string]: unknown
  id: string
  parentId: string
}

export type IApiDataType = INestedDataType[] | null

export interface ILoadMoreOptionType {
  id: string
  fields: string[]
}

export interface IApiType {
  data: IApiDataType
  loading: boolean
  loadMore?: (options: ILoadMoreOptionType[]) => Promise<void> | void
  refetch?: any
}

export const LOADING = Symbol('loading')
