import { ApolloError, useQuery } from '@apollo/client'
import _ from 'lodash'
import React, { SetStateAction, useMemo } from 'react'

import useGetDateRange from 'pared/hooks/useGetDateRange'
import { getBrand } from 'pared/utils/brand'

import { LIST_LOCATION_GROUP_PL, LIST_LOCATION_PL } from '../gql'
import processPlDetailData from './processPlDetailData'
import processPlTrendData from './processPlTrendData'
import useLocationFilter from './useLocationFilter'
import usePlConfig from './usePlConfig'
import useReportFilter, {
  IReportFilterType,
  REPORT_TYPE_TREND,
} from './useReportFilter'

const useDataSource = (): {
  isLoading: boolean
  data: any[] | null
  error: ApolloError | null
  reportFilterValue: string
  locationFilter: string[]
  setLocationFilter: React.Dispatch<SetStateAction<string[]>>
  locationFilters: IReportFilterType[]
  reportFilter: string[]
  setReportFilter: React.Dispatch<SetStateAction<string[]>>
  reportFilters: IReportFilterType[]
} => {
  const brand = getBrand()
  const dateRange = useGetDateRange()

  const { reportFilter, setReportFilter, reportFilters, reportInfoMap } =
    useReportFilter(
      dateRange?.startDateStr,
      dateRange?.endDateStr,
      dateRange?.type,
    )
  const {
    locationFilter,
    setLocationFilter,
    locationFilters,
    locationFilterInfoMap,
  } = useLocationFilter(brand)

  const reportFilterValue = _.get(reportFilter, '[0]')
  const reportInfo = reportInfoMap[reportFilterValue]

  const locationFilterValue = _.get(locationFilter, '[0]')
  const locationFilterInfo = locationFilterInfoMap[locationFilterValue]
  const locationId = locationFilterInfo?.locationId
  const locationGroupId = locationFilterInfo?.locationGroupId
  const startDate = reportInfo?.startDateStr
  const endDate = reportInfo?.endDateStr
  const groupBy = reportInfo?.groupBy
  const isTrendReport = reportInfo?.type === REPORT_TYPE_TREND ? true : false
  const plConfig = usePlConfig()

  let gqlQuery = LIST_LOCATION_GROUP_PL
  let iFilter = {}
  if (locationId) {
    gqlQuery = LIST_LOCATION_PL
    iFilter = {
      location_ids: [locationId],
    }
  } else {
    gqlQuery = LIST_LOCATION_GROUP_PL
    iFilter = {
      location_group_ids: [locationGroupId],
    }
  }

  const { data, error, loading } = useQuery(gqlQuery, {
    variables: {
      iFilter,
      iStartDate: startDate,
      iEndDate: endDate,
      iGroupBy: groupBy,
    },
    skip:
      (!locationId && !locationGroupId) || !startDate || !endDate || !groupBy,
  })

  const dataSource = useMemo(() => {
    if (data && data.listPl && Array.isArray(data.listPl.nodes)) {
      const rawPlData = data.listPl.nodes || []
      const processedData = isTrendReport
        ? processPlTrendData(rawPlData, plConfig, groupBy)
        : processPlDetailData(rawPlData, plConfig)

      return {
        isLoading: loading,
        data: processedData,
        error: null,
      }
    } else {
      return {
        isLoading: loading,
        data: null,
        error: error || null,
      }
    }
  }, [plConfig, data, locationId, startDate, endDate, groupBy, isTrendReport])

  return {
    ...dataSource,
    reportFilterValue,
    locationFilter,
    setLocationFilter,
    locationFilters,
    reportFilter,
    setReportFilter,
    reportFilters,
  }
}

export default useDataSource
