import React from 'react'
import styled from 'styled-components'

import CustomizedReport from 'pared/components/CustomizedReport'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { getBrandSettings } from 'pared/customer'
import useGetDateRange from 'pared/hooks/useGetDateRange'

interface IProps {
  isBreakdownByDirectors: boolean
  locationGroupId: number
}

const CustomBreakdownTable = ({
  isBreakdownByDirectors,
  locationGroupId,
}: IProps) => {
  const dateRange = useGetDateRange()
  const { brandId, brandLocationGroupId } = getBrandSettings()
  const inputParams = {
    startDate: dateRange?.startDateStr,
    endDate: dateRange?.endDateStr,
    locationGroupId:
      locationGroupId > 0 ? locationGroupId : brandLocationGroupId,
    brandId,
  }

  return (
    <>
      <SubHeader>{`Breakdown By ${
        isBreakdownByDirectors ? 'Director' : 'Store'
      }`}</SubHeader>
      <Container>
        <CustomizedReport
          reportName={
            isBreakdownByDirectors
              ? 'CORPORATE_OVERVIEW_BREAKDOWN_BY_DIRECTOR'
              : 'CORPORATE_OVERVIEW_BREAKDOWN_BY_STORE'
          }
          inputParams={inputParams}
        />
      </Container>
    </>
  )
}

const Container = styled.div`
  margin: 0;
  width: 90vw;
  padding: 40px 0;
  overflow: scroll hidden;
`

const SubHeader = styled.div`
  margin: 0px;
  padding: 0;
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  color: ${COLORS.Chalkboard};

  @media ${MOBILE_WIDTH} {
    display: none;
  }
`

export default React.memo(CustomBreakdownTable)
