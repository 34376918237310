import { INavParams } from 'pared/Routes/navParams'
import { IDateRange } from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'

import WebLargeMain from './WebLargeMain'

interface IProps {
  navParams: INavParams
  allDirectors: IDirector[]
  selectedDirector: IDirector
  onDirectorChange: (directorEmployeeId: number) => void
  selectedDateRange: IDateRange | null
  displayData: any
  locationGroupIds: number[] | null
  locationGroupTypes: string[] | null
  selectedLocationGroupId: number
}

function Main({
  navParams,
  allDirectors,
  selectedDirector,
  onDirectorChange,
  selectedDateRange,
  displayData,
  locationGroupIds,
  locationGroupTypes,
  selectedLocationGroupId,
}: IProps) {
  return (
    <WebLargeMain
      navParams={navParams}
      allDirectors={allDirectors}
      selectedDirector={selectedDirector}
      selectedDateRange={selectedDateRange}
      onDirectorChange={onDirectorChange}
      displayData={displayData}
      locationGroupIds={locationGroupIds}
      locationGroupTypes={locationGroupTypes}
      selectedLocationGroupId={selectedLocationGroupId}
    />
  )
}

export default Main
