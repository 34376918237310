import { useMemo } from 'react'

import { IColumnsType } from 'pared/components/basicUi/table'

import { getCount, getPercent, getPrice } from '../../utils'
import { IDataSourceType } from './useDataSource'

const useColumns = (): IColumnsType<IDataSourceType>[] => {
  return useMemo(
    () =>
      [
        {
          key: 'reason',
          title: 'Reason',
          minWidth: '150px',
          align: 'center',
          sorter: true,
        },
        {
          key: 'totalCount',
          title: '# of Orders',
          minWidth: '75px',
          align: 'center',
          render: getCount,
          sorter: true,
        },
        {
          key: 'totalOrderPercent',
          title: '% of Orders',
          minWidth: '75px',
          align: 'center',
          render: getPercent,
          sorter: true,
        },
        {
          key: 'totalAmount',
          title: '$',
          minWidth: '75px',
          align: 'center',
          render: getPrice,
          sorter: true,
        },
      ] as IColumnsType<IDataSourceType>[],
    [],
  )
}

export default useColumns
