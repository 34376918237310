import COLORS from 'pared/constants/colors'
import { ILossPreventionConfigType } from 'pared/pages/CorporateLossPrevention/hooks/useLossPreventionConfig'

const cashControlKpiName = {
  cashOverOrShort: 'Cash +/-',
  overRing: 'Voids',
  deletes: 'Deletes',
}

export const vikingBkConfig: ILossPreventionConfigType = {
  kpiSettings: [
    { category: 'basic', key: 'compsTotalCount', title: 'Total Discounts' },
    { category: 'basic', key: 'cashOverOrShort', title: 'Cash +/-' },
    {
      category: 'custom',
      type: 'Over Ring',
      title: '% Checks with Voids',
      key: 'checkPercent',
    },
    {
      category: 'custom',
      type: 'Deal after Total',
      title: '% Checks with Deletes',
      key: 'checkPercent',
    },
    {
      category: 'basic',
      key: 'inaccurateCashierShiftPercent',
      title: '% Shifts with Inaccurate Cash',
    },
  ],

  discountTable: {
    isVisible: true,
    discountColumns: [
      {
        key: '100 Discounts',
        title: '100 Discounts',
        colSpan: 4,
        children: [
          {
            key: 'DISCOUNT - Manager Meal',
            title: 'Manager Meal',
          },
          {
            key: 'DISCOUNT - Employee Meal',
            title: 'Employee Meal',
          },
        ],
      },
      {
        key: 'DISCOUNT - 50 Meal',
        title: '50 Meal',
      },
      {
        key: 'DISCOUNT - Free Item',
        title: 'Free Item',
      },
      {
        key: 'DISCOUNT - Military',
        title: 'Military',
      },
      {
        key: 'DISCOUNT - Beverage',
        title: 'Beverage',
      },
      {
        key: 'DISCOUNT - Crew Meal',
        title: 'Crew Meal',
      },
      {
        key: 'DISCOUNT - Cents Off',
        title: 'Cents Off',
      },
      {
        key: 'Guest Recovery',
        title: 'Guest Recovery',
      },
    ],
  },

  discountTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Discount',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [
      {
        key: '100 Discounts',
        name: '100 Discounts',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Plum,
        hidden: true,
      },
      {
        key: 'DISCOUNT - 50 Meal',
        name: '50 Meal',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.DarkOrchid,
        hidden: true,
      },
      {
        key: 'DISCOUNT - Free Item',
        name: 'Free Item',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Mango,
        hidden: true,
      },
      {
        key: 'DISCOUNT - Military',
        name: 'Military',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Basil,
        hidden: true,
      },
      {
        key: 'DISCOUNT - Beverage',
        name: 'Beverage',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Pomodoro,
        hidden: true,
      },
      {
        key: 'DISCOUNT - Crew Meal',
        name: 'Crew Meal',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Acai,
        hidden: true,
      },
    ],
  },

  cashControlTable: {
    isVisible: true,
    cashControlColumns: [
      {
        key: 'cashOverOrShort',
        title: 'Cash +/-',
        width: '150px',
        children: [
          {
            key: 'cashOverOrShort.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'cashOverOrShort.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Over Ring',
        title: 'Voids',
        width: '150px',
        children: [
          {
            key: 'Over Ring.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'Over Ring.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Deal after Total',
        title: 'Deletes',
        width: '150px',
        children: [
          {
            key: 'Deal after Total.count',
            title: '#',
            valueType: 'count',
          },
          {
            key: 'Deal after Total.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'cashOverOrShort.checkPercent',
        title: '% Shifts with Inaccurate Cash',
        valueType: 'percent',
        width: '150px',
      },
      {
        key: 'Over Ring.checkPercent',
        title: '% Checks w/ Over Ring',
        valueType: 'percent',
        width: '150px',
      },
      {
        key: 'Deal after Total.checkPercent',
        title: '% Checks w/ Deletes',
        valueType: 'percent',
        width: '150px',
      },
    ],
  },

  cashControlTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Amount',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [
      {
        key: 'cashOverOrShort',
        name: `${cashControlKpiName.cashOverOrShort} $`,
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Pomodoro,
      },
      {
        key: 'Over Ring',
        name: `${cashControlKpiName.overRing} $`,
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Plum,
        hidden: true,
      },
      {
        key: 'Deal after Total',
        name: `${cashControlKpiName.deletes} $`,
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Basil,
        hidden: true,
      },
    ],
  },
}
