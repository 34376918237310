import { IConfigsType } from '../../types'

const jscBkFlash: IConfigsType = {
  jsc_bk: {
    '/:brand/flash': {
      groupFilter: {
        api: 'jscBkGroupFilter',
      },
      dateFilter: {
        types: [
          'year',
          'quarter',
          'period',
          'week',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'custom',
        ],
        defaultType: 'yesterday',
      },
      flash: {
        type: 'title',
        title: 'Systemwide Flash Report',
      },
      downloadFlashTable: {
        title: 'DAILY FLASH',
        type: 'temp-download-csv',
        api: 'jscBkFlash',
        fileName: 'getexpo-flash',
        message: 'Download Daily Flash as CSV',
        fields: [
          'groupInfo',
          {
            key: 'netSales',
            title: 'Net Sales',
          },
          {
            key: 'yoyNetSales',
            title: 'Net Sales LY',
          },
          {
            key: 'netSalesDiffVsLy',
            title: 'Net Sales vs LY',
          },
          {
            key: 'percentVsLY',
            title: '% vs LY',
          },
          {
            key: 'totalCheckCount',
            title: 'Check Count',
          },
          {
            key: 'yoyTotalCheckCount',
            title: 'Check Count LY',
          },
          {
            key: 'checkCountDiffVsLy',
            title: 'Check Count vs LY',
          },
          {
            key: 'customerPercentVsLY',
            title: 'Check Count % vs LY',
          },
          {
            key: 'totalCheckAverage',
            title: 'Average Check',
          },
        ],
      },
      dailyFlashTable: {
        type: 'temp-table',
        api: 'jscBkFlash',
        advancedFilter: [
          {
            type: 'select',
            selectKey: 'groupBy',
            label: 'Group by',
            options: [
              {
                value: 'none',
                label: 'None',
              },
              {
                value: 'district-leader',
                label: 'District Leader',
              },
            ],
          },
        ],
        highlineEvenRows: true,
        pageSize: 100,
        defaultSortedField: 'groupInfo',
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        columns: [
          {
            key: 'netSales',
            title: 'Net Sales',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'yoyNetSales',
            title: 'Net Sales LY',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'netSalesDiffVsLy',
            title: 'Net Sales vs LY',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'percentVsLY',
            title: '% vs LY',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'totalCheckCount',
            title: 'Check Count',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'yoyTotalCheckCount',
            title: 'Check Count LY',
            width: '150px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'checkCountDiffVsLy',
            title: 'Check Count vs LY',
            width: '170px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'customerPercentVsLY',
            title: 'Check Count % vs LY',
            width: '170px',
            align: 'center',
            sortable: true,
          },
          {
            key: 'totalCheckAverage',
            title: 'Average Check',
            width: '150px',
            align: 'center',
            decimal: 2,
            sortable: true,
          },
        ],
      },
    },
  },
}

export default jscBkFlash
