import { useFetchCustomizedReportData } from 'pared/components/CustomizedReport'
import { toUsdString } from 'pared/utils/number'

import useConfig from './useConfig'

interface IInputParams {
  todayDate?: string
  queryDate?: string
  startDate?: string
  endDate?: string
  locationId?: number
  locationGroupId?: number
  locationGroupIds?: number[] | null
  locationGroupTypes?: string[] | null
  otherParams?: any
  i_filter?: any
}

const useFetchPurchaseReportData = (
  startDate: string,
  endDate: string,
  locationId: number,
) => {
  const config = useConfig()
  const reportName = 'LOCATION_PURCHASE_TABLE_V2'

  const inputParams: IInputParams = {
    locationId: locationId,
    startDate: startDate,
    endDate: endDate,
  }

  const { reportResult, isLoading } = useFetchCustomizedReportData(
    reportName,
    inputParams,
  )

  let tableData = [...(reportResult?.tableData || [])].filter(
    ({ difference, isKeyItem }) =>
      difference > 0 && (config.hasKeyItems ? isKeyItem : true),
  )

  tableData.sort((a, b) => {
    return b.difference - a.difference
  })

  const totalAnnualOpportunityCost = tableData.reduce(
    (sum, { annualOpportunityCost }) => sum + annualOpportunityCost,
    0,
  )
  tableData = tableData.slice(0, 1)

  const topItemName = tableData.map(({ item }, index) =>
    index === tableData.length - 1 && index > 0 ? `and ${item}` : item,
  )

  const topItemOpportunity = toUsdString(
    Math.round(tableData.reduce((sum, { difference }) => sum + difference, 0)),
  )

  return {
    isLoading,
    topItemName,
    topItemOpportunity,
    totalAnnualOpportunityCost,
  }
}

export default useFetchPurchaseReportData
