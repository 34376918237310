import { useLazyQuery, useMutation } from '@apollo/client'
import Modal from '@material-ui/core/Modal'
import TextField from '@material-ui/core/TextField'
import Button from '@mui/material/Button'
import { useState } from 'react'
import styled from 'styled-components'

import { InputWithLeftLabel } from 'pared/components/basicUi/InputWithLeftLabel'
import Table from 'pared/components/basicUi/table'
import COLORS from 'pared/constants/colors'

import { MUTATION_UPDATE_USER_EMPLOYEE_ID, QUERY_SEARCH_EMPLOYEES } from './gql'
import { IUserManagementData } from './types'

const columns = [
  {
    key: 'id',
    title: 'ID',
  },
  {
    key: 'givenName',
    title: 'First Name',
  },
  {
    key: 'familyName',
    title: 'Last Name',
  },
  {
    key: 'jobTitle',
    title: 'Job Title',
  },
  {
    key: 'locationId',
    title: 'Location ID',
  },
  {
    key: 'locationCode',
    title: 'Location Code',
  },
  {
    key: 'locationName',
    title: 'Location Name',
  },
  {
    key: 'select',
    title: 'Select',
  },
]

const ChangeEmployeeButton = ({
  allData,
  refetch,
}: {
  allData: IUserManagementData
  refetch: any
}) => {
  const {
    email,
    expoEmployeeId,
    familyName,
    givenName,
    jobTitle,
    locationCode,
    locationId,
    locationName,
    userId,
  } = allData
  const [open, setOpen] = useState(false)
  const [hasFormSubmitted, setHasFormSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [matchingEmployees, setMatchingEmployees] = useState<any[]>([])
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null)
  const [searchEmployees] = useLazyQuery(QUERY_SEARCH_EMPLOYEES, {
    onCompleted: (data) => {
      setMatchingEmployees(data?.searchEmployeesV2?.nodes || [])
    },
  })

  const [updateUserEmployeeId] = useMutation(MUTATION_UPDATE_USER_EMPLOYEE_ID)
  const [employeeId, setEmployeeId] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const handleSearch = () => {
    if (!employeeId && !firstName && !lastName) {
      setErrorMessage('No blank search allowed')
      return
    }

    const filter = {
      employee_id: employeeId || null,
      given_name: firstName || null,
      family_name: lastName || null,
    }

    searchEmployees({
      variables: {
        iFilter: filter,
      },
    })

    setErrorMessage('')
  }

  const onSubmit = async () => {
    if (!selectedEmployeeId) {
      setErrorMessage('Employee selection required')
      return
    }

    try {
      setHasFormSubmitted(true)
      await updateUserEmployeeId({
        variables: {
          userId,
          employeeId: selectedEmployeeId,
        },
      })

      setErrorMessage('')
      refetch()
    } catch (error) {
      setErrorMessage('Error: Please try again or email support@getexpo.com')
    } finally {
      setHasFormSubmitted(false)
    }
  }

  const matchingEmployeesWithButton = matchingEmployees.map((employee) => ({
    ...employee,
    select: (
      <input
        type="checkbox"
        checked={employee.id === selectedEmployeeId}
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedEmployeeId(employee.id)
          } else {
            setSelectedEmployeeId(null)
          }
        }}
      />
    ),
  }))

  const filteredEmployees = matchingEmployeesWithButton.filter((employee) =>
    selectedEmployeeId ? employee.id === selectedEmployeeId : true,
  )

  return (
    <>
      <div>{expoEmployeeId}</div>
      <Button onClick={() => setOpen(true)}>Edit</Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Container>
          <Header>{`Change employee mapping for ${email}`}</Header>
          <Subheader>
            Current Employee Info:{expoEmployeeId ? '' : ' None'}
          </Subheader>
          {expoEmployeeId ? (
            <Table
              columns={columns.filter(({ key }) => key !== 'select')}
              dataSource={[
                {
                  id: expoEmployeeId,
                  givenName,
                  familyName,
                  jobTitle,
                  locationId,
                  locationCode,
                  locationName,
                },
              ]}
            />
          ) : null}
          <VerticalSpacer />
          <InputWithLeftLabel label="Employee ID">
            <TextField
              name="employeeId"
              variant="outlined"
              value={employeeId}
              onChange={(e) => setEmployeeId(e.target.value)}
              fullWidth
            />
          </InputWithLeftLabel>
          <VerticalSpacer />
          <InputWithLeftLabel label="First Name">
            <TextField
              name="firstName"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
            />
          </InputWithLeftLabel>
          <VerticalSpacer />

          <InputWithLeftLabel label="Last Name">
            <TextField
              name="lastName"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
            />
          </InputWithLeftLabel>
          <VerticalSpacer />

          <SearchButton onClick={handleSearch}>Search</SearchButton>
          <VerticalSpacer />
          <Header>Search Results</Header>
          <Table columns={columns} dataSource={filteredEmployees} />
          <Footer>
            {hasFormSubmitted ? (
              <div>Updating ...</div>
            ) : (
              <ButtonContainer>
                <CancelButton onClick={() => setOpen(false)}>
                  Cancel
                </CancelButton>
                <SubmitButton onClick={onSubmit}>Update</SubmitButton>
              </ButtonContainer>
            )}
            <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
          </Footer>
        </Container>
      </Modal>
    </>
  )
}

export default ChangeEmployeeButton

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 30px 20px;
  max-height: 80vh;
  transform: translate(-50%, -50%);
  background-color: white;
  outline: none;
  overflow: hidden scroll;
`

const Header = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 16px;
  margin-bottom: 20px;
`

const Subheader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 14px;
`

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
`

const SubmitButton = styled.div`
  background-color: ${COLORS.Expo};
  flex: 1;
  color: white;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  font-family: Lexend-Semibold;
  cursor: pointer;
  &:hover {
    opacity: 50%;
  }
`

const CancelButton = styled(SubmitButton)`
  background-color: #cfcece;
  color: black;
`

const SearchButton = styled(SubmitButton)`
  background-color: white;
  color: ${COLORS.Expo};
  border: 1px solid ${COLORS.Expo};
`

const VerticalSpacer = styled.div`
  height: 20px;
`

const Footer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`

const ErrorMessageContainer = styled.div`
  color: red;
`
