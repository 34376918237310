import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { useState } from 'react'
import styled from 'styled-components'

import CustomizedReport from 'pared/components/CustomizedReport'
import Tooltip from 'pared/components/basicUi/Tooltip'
import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import COLORS from 'pared/constants/colors'

import { employeesTermedWithinTooltip } from '../constants'

interface IProps {
  locationGroupId: number
}

const EmployeesTermChart = ({ locationGroupId }: IProps) => {
  const [employeeType, setEmployeeType] = useState<'hourly' | 'salaried'>(
    'hourly',
  )

  const handleEmployeeTypeChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    setEmployeeType(event.target.value as 'hourly' | 'salaried')
  }

  return (
    <>
      <Header>
        <FlexContainer>
          <SectionTitleDiv>
            <div>EMPLOYEES TERMED WITHIN</div>
            <Tooltip title={employeesTermedWithinTooltip} />
          </SectionTitleDiv>
          <Subtitle>Daily Snapshot</Subtitle>
        </FlexContainer>
        <StyledFormControl variant="outlined" size="small">
          <Select value={employeeType} onChange={handleEmployeeTypeChange}>
            <MenuItem value="hourly">Hourly</MenuItem>
            <MenuItem value="salaried">Salaried</MenuItem>
          </Select>
        </StyledFormControl>
      </Header>
      <VerticalSpacer />
      <CustomizedReport
        reportName="LIST_LOCATION_TURNOVER_TABLE"
        inputParams={{
          i_filter: {
            location_group_ids: [locationGroupId],
            employee_type: employeeType,
          },
        }}
      />
    </>
  )
}

export default EmployeesTermChart

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 155px;
  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  display: flex;
`

const Subtitle = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};
  margin-top: 10px;
`

const VerticalSpacer = styled.div`
  height: 20px;
`

const StyledFormControl = styled(FormControl)`
  width: 125px;
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`
