import { gql, useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { DATA_DISPLAY_FORMAT, PERFORMANCE_ENUM_MAP } from 'pared/constants'
import getPeriods, { IPeriod } from 'pared/data/getPeriods'
import { getYearMonthDiff } from 'pared/utils/date'
import { getUser } from 'pared/utils/user'

import useTeamConfig from '../hooks/useTeamConfig'
import Main from './Main'

const GET_LOCATION_TEAM_MEMBER_DATA = gql`
  query GetLocationTeamMemberData($locationId: Int!) {
    location(id: $locationId) {
      id
      code
      name
      management(first: 30) {
        nodes {
          employeeId
          firstName
          lastName
          roleId
          roleCode
          roleName
          roleStartedAt
          roleEmploymentType
          hiredAt
          isActive
          employeeStatus
          employeeStatusRecordedBy
          employeeStatusRecordedByName
          employeeStatusRecordedAt
        }
      }
      locationManagerReviews {
        nodes {
          id
          reviewer {
            id
            firstName: preferredName
            lastName: familyName
          }
          reviewedAt
          revieweeId
          performance
          feedback
        }
      }
    }
  }
`

const GET_LOCATION_TEAM_MEMBER_DATA_WITHOUT_FEEDBACK = gql`
  query GetLocationTeamMemberData($locationId: Int!) {
    location(id: $locationId) {
      id
      code
      name
      management(first: 30) {
        nodes {
          employeeId
          firstName
          lastName
          roleId
          roleCode
          roleName
          roleStartedAt
          roleEmploymentType
          hiredAt
          isActive
        }
      }
    }
  }
`

export interface ILocation {
  id: number
  code: string
  name: string
}

export interface IManagerReview {
  id: number
  feedback: string
  performanceName: string
  formattedReviewedAt: string
  reviewedAt: string
  reviewer: {
    id: number
    firstName: string
    lastName: string
  }
}

export interface ITeamMember {
  employeeId: number
  locationId: number
  firstName: string
  lastName: string
  roleId: number | null
  roleCode: string
  roleName: string
  employmentType: string
  timeInPosition: string
  tenure: string
  isActive: boolean
  status: string | null
  statusRecordedBy: number
  statusRecordedByName: string
  statusRecordedAt: string
  managerReviews: IManagerReview[]
}

interface IProps {
  locationId: number | null
  shouldShowFeedback: boolean
}

function TeamFeedbackTable({ locationId, shouldShowFeedback }: IProps) {
  const teamConfig = useTeamConfig()

  const [allPeriods, setAllPeriods] = useState<IPeriod[]>([])
  const locationTeamQuery = shouldShowFeedback
    ? GET_LOCATION_TEAM_MEMBER_DATA
    : GET_LOCATION_TEAM_MEMBER_DATA_WITHOUT_FEEDBACK

  const [
    getLocationTeamMemberData,
    { data, refetch: refetchLocationTeamMemberData },
  ] = useLazyQuery(locationTeamQuery)

  useEffect(() => {
    if (locationId) {
      const fetchPeriods = async () => {
        const { periods } = await getPeriods()
        if (Array.isArray(periods) && periods.length > 0) {
          setAllPeriods(periods)
        }
      }

      fetchPeriods()

      getLocationTeamMemberData({
        variables: {
          locationId,
        },
      })
    }
  }, [getLocationTeamMemberData, locationId])

  let location: ILocation | null = null
  let teamMembers: ITeamMember[] = []

  const user = getUser()
  const userEmployeeId: number | null = _.get(user, 'employeeId') || null

  if (data && data.location && data.location.id === locationId) {
    if (
      data.location.management &&
      Array.isArray(data.location.management.nodes)
    ) {
      const now = moment()
      location = {
        id: data.location.id,
        code: data.location.code || '',
        name: data.location.name || '',
      }

      data.location.management.nodes.forEach((member: any) => {
        if (member && member.employeeId) {
          const roleStartedAt = moment(member.roleStartedAt)
          const hiredAt = moment(member.hiredAt)

          let timeInPosition = '-'
          if (roleStartedAt.isValid()) {
            timeInPosition = getYearMonthDiff(roleStartedAt, now)
          }

          let tenure = '-'
          if (hiredAt.isValid()) {
            tenure = getYearMonthDiff(hiredAt, now)
          }

          const statusRecordedAt = moment(
            member.employeeStatusRecordedAt,
            'YYYY-MM-DD',
            true,
          )

          const teamMembersInfo = {
            tenure,
            timeInPosition,
            employeeId: member.employeeId,
            locationId: data.location.id,
            firstName: member.firstName || '',
            lastName: member.lastName || '',
            roleId: member.roleId || null,
            roleCode: member.roleCode || '',
            roleName: member.roleName || '',
            employmentType:
              member.roleEmploymentType === 'FULL_TIME'
                ? 'Full-Time'
                : 'Part-Time',
            isActive: member.isActive,
            status: member.employeeStatus || null,
            statusRecordedBy: member.employeeStatusRecordedBy,
            statusRecordedByName: member.employeeStatusRecordedByName || '-',
            statusRecordedAt: statusRecordedAt.isValid()
              ? statusRecordedAt.format(DATA_DISPLAY_FORMAT)
              : '-',
            managerReviews: [],
          }

          const employeeIdNum = parseInt(teamMembersInfo.employeeId, 10)
          if (employeeIdNum && employeeIdNum > 0) {
            if (
              teamConfig.isInactiveEmployeeVisible ||
              teamMembersInfo.isActive
            ) {
              teamMembers.push(teamMembersInfo)
            }
          }
        }
      })
    }

    if (
      data.location.locationManagerReviews &&
      Array.isArray(data.location.locationManagerReviews.nodes)
    ) {
      const employeeReviewMap: { [employeeId: number]: IManagerReview[] } = {}
      data.location.locationManagerReviews.nodes.forEach((rawData: any) => {
        if (rawData && rawData.revieweeId) {
          const performanceCode = rawData.performance || ''
          const performanceName =
            (PERFORMANCE_ENUM_MAP[performanceCode] || {}).name || ''

          const formattedData: IManagerReview = {
            performanceName,
            id: rawData.id,
            feedback: rawData.feedback,
            formattedReviewedAt: moment(rawData.reviewedAt).format('M/DD/YY'),
            reviewedAt: rawData.reviewedAt,
            reviewer: rawData.reviewer,
          }

          if (employeeReviewMap[rawData.revieweeId]) {
            employeeReviewMap[rawData.revieweeId].push(formattedData)
          } else {
            employeeReviewMap[rawData.revieweeId] = [formattedData]
          }
        }
      })

      teamMembers.forEach((member) => {
        const reviews = employeeReviewMap[member.employeeId] || []
        member.managerReviews = _.sortBy(reviews, [
          'reviewedAt',
          'id',
        ]).reverse()
      })
    }
  }

  const onRefreshTable = async () => {
    if (locationId && refetchLocationTeamMemberData) {
      await refetchLocationTeamMemberData({
        variables: {
          locationId,
        },
      })
    }
  }

  return (
    <Main
      userEmployeeId={userEmployeeId}
      allPeriods={allPeriods}
      location={location}
      teamMembers={teamMembers}
      onRefreshTable={onRefreshTable}
      shouldShowFeedback={shouldShowFeedback}
    />
  )
}

export default TeamFeedbackTable
