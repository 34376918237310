import _ from 'lodash'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import navigator from 'pared/Routes/navigator'
import COLORS from 'pared/constants/colors'
import { getBusinessLabel } from 'pared/customer'
import DownArrow from 'pared/images/basic/arrow/caret-down.svg'
import UpArrow from 'pared/images/basic/arrow/caret-up.svg'

import { IDetailGuestOpentableKpi, IOrderBy } from './index'

interface IProps {
  itemizedKpis: IDetailGuestOpentableKpi[]
  isTableExpanded: boolean
  onToggleExpansion: () => void
  orderBy: IOrderBy
  setOrderBy: any
  isBreakdownByDirectors: boolean
  breakdownType: string | null
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
}

interface IStorePerformanceSortableTh {
  isBackgroundGrey?: boolean
}

interface IRankTablTh {
  textAlign?: string
}

function WebSmallMain({
  itemizedKpis,
  isTableExpanded,
  onToggleExpansion,
  orderBy,
  setOrderBy,
  isBreakdownByDirectors,
  breakdownType,
}: IProps) {
  const handleOrdering = (event: any) => {
    const orderingColumnValue = _.get(event, 'currentTarget.dataset.value')
    setOrderBy({
      columnName: orderingColumnValue,
      isAscending: !orderBy.isAscending,
    })
  }

  let nameLabel = getBusinessLabel('store')

  if (isBreakdownByDirectors) {
    // breakdownByDirectors
    nameLabel = getBusinessLabel('director')
  } else if (breakdownType) {
    nameLabel = breakdownType
  }

  let orderStatus = null
  if (orderBy.isAscending) {
    orderStatus = <UpArrowImg src={UpArrow} alt="ASC"></UpArrowImg>
  } else {
    orderStatus = <DownArrowImg src={DownArrow} alt="DESC"></DownArrowImg>
  }

  const locationRows = _.map(itemizedKpis, (k) => {
    const locationId = _.get(k, 'locationId', 1)
    const employeeId = _.get(k, 'employeeId', 1)
    const formattedName = _.get(k, 'formattedName', '')
    const key = `${locationId}-${employeeId}-${formattedName}`
    const linkAction = isBreakdownByDirectors
      ? navigator.employeeProfile(employeeId)
      : navigator.guest(locationId)

    return (
      <tr key={key}>
        <RankTableTd textAlign={'left'}>
          {breakdownType ? (
            <div>{formattedName}</div>
          ) : (
            <LightBlueLink to={linkAction} onClick={() => {}}>
              {_.get(k, 'formattedName', '')}
            </LightBlueLink>
          )}
        </RankTableTd>
        <RankTableTd>{_.get(k, 'ratingScore', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'ratingScoreChange', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'seatedCovers', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'seatedCoversChange', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'opentableCovers', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'opentableCoversChange', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'yourNetworkCovers', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'yourNetworkCoversChange', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'phoneCovers', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'phoneCoversChange', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'walkinCovers', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'walkinCoversChange', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'noShowRate', '-')}</RankTableTd>
        <RankTableTd>{_.get(k, 'noShowRateChange', '-')}</RankTableTd>
      </tr>
    )
  })

  let toggledlocationRows = locationRows

  if (!isTableExpanded) {
    toggledlocationRows = _.slice(locationRows, 0, 5)
  }

  const toggleText = isTableExpanded ? 'Collapse all' : 'Expand All'

  const expandSpan =
    _.size(locationRows) > 5 ? (
      <ExpandAllSpan onClick={onToggleExpansion}>{toggleText}</ExpandAllSpan>
    ) : null

  return (
    <ScrollableTable>
      <ScrollableBreakdownTable>
        <MainContainer>
          <RankTable>
            <thead>
              <BreakdownSecondHeaderTr>
                <RankTableTh>{nameLabel}</RankTableTh>
                <RankTableSortableTh
                  onClick={handleOrdering}
                  data-value={'ratingScore'}
                >
                  <span>Ratings</span>
                  {orderBy.columnName === 'ratingScore' ? orderStatus : null}
                </RankTableSortableTh>
                <RankTableSortableTh
                  onClick={handleOrdering}
                  data-value={'ratingScoreChange'}
                >
                  <span>YoY Ratings %</span>
                  {orderBy.columnName === 'ratingScoreChange'
                    ? orderStatus
                    : null}
                </RankTableSortableTh>
                <RankTableSortableTh
                  onClick={handleOrdering}
                  data-value={'seatedCovers'}
                >
                  <span>Seated Covers</span>
                  {orderBy.columnName === 'seatedCovers' ? orderStatus : null}
                </RankTableSortableTh>
                <RankTableSortableTh
                  onClick={handleOrdering}
                  data-value={'seatedCoversChange'}
                >
                  <span>YoY Seated Covers %</span>
                  {orderBy.columnName === 'seatedCoversChange'
                    ? orderStatus
                    : null}
                </RankTableSortableTh>
              </BreakdownSecondHeaderTr>
            </thead>
            <tbody>{toggledlocationRows}</tbody>
          </RankTable>
          {expandSpan}
        </MainContainer>
      </ScrollableBreakdownTable>
    </ScrollableTable>
  )
}

const MainContainer = styled.div``

const UpArrowImg = styled.img`
  width: 10px;
  margin: 0 0 2px 5px;
`

const DownArrowImg = styled.img`
  width: 10px;
  margin: 0 0 -1px 5px;
`

const LightBlueLink = styled(Link)`
  color: ${COLORS.Link};
`

const BreakdownSecondHeaderTr = styled.tr`
  border-bottom: 1px solid ${COLORS.Chalkboard};
`

const RankTableSortableTh = styled.th<IRankTablTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  color: ${COLORS.Plum};
  cursor: pointer;
`

const RankTableTd = styled.td<IRankTableTd>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  padding-left: ${(props) => (props.textAlign === 'left' ? '10px' : '0')};
`

const RankTable = styled.table`
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
    min-width: 100px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`

const ExpandAllSpan = styled.div`
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: ${COLORS.Link};
  text-decoration: underline;
  cursor: pointer;
`

const RankTableTh = styled.th<IRankTablTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const ScrollableTable = styled.div`
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
`

const ScrollableBreakdownTable = styled(ScrollableTable)`
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
`

export default WebSmallMain
