export const SHOULD_RANDOMIZE_LOCATION_INFO =
  process.env.REACT_APP_SHOULD_RANDOMIZE_LOCATION_INFO === 'YES'

export const DATE_DATABASE_FORMAT = 'YYYY-MM-DD'
export const DATE_DISPLAY_FORMAT = 'M/D/YY'

export const SALESMANSHIP_COLUMN_NAMES = {
  total_sales: 'Total Sales',
  sales_per_labor_hour: 'Sales/Hour',
  per_person_average: 'PPA',
  speed_of_service: 'Speed of Service',
}

export const HIDE_SULLIVANS_2019 = false

export const RANKINGS_ROW_TYPE = {
  AVG: 'avg',
}

export const SYSTEMWIDE_AVG_TEXT = 'Systemwide Average'
export const STORE_AVG_TEXT = 'Store Average'

export const PRINTABLE_TYPE = {
  SALESMANSHIP_TABLE: 'salesmanshipTable',
  STAFF_TABLE: 'staffTable',
}

export const GUEST_REVIEW_SOURCES = [
  'acr',
  'momentfeed',
  'google',
  'facebook',
  'delivery',
  'yelp',
  'all',
  'yext',
  'dissatisfaction',
  'inaccurate',
] as const

export const DEFAULT_AVATAR_URL =
  'https://s3.amazonaws.com/pared/shared/pared-temp-avatar.png'

export const PERFORMANCE_ENUM = [
  { name: 'Promote Ready', code: 'PROMOTE_READY' },
  { name: 'HiPo', code: 'HIPO' },
  { name: 'Solid Performer', code: 'SOLID_PERFORMER' },
  { name: 'Development Needed', code: 'DEVELOPMENT_NEEDED' },
  { name: 'Upgrade', code: 'UPGRADE' },
]
export const PERFORMANCE_ENUM_MAP = PERFORMANCE_ENUM.reduce<{
  [code: string]: { name: string; code: string }
}>((map, item) => {
  map[item.code] = item
  return map
}, {})

export const DEFAULT_EMPLOYEE_STATUS = {
  name: 'New in Role',
  code: 'NEW_IN_ROLE',
}
export const EMPLOYEE_STATUS_ENUM = [
  DEFAULT_EMPLOYEE_STATUS,
  { name: 'MIT', code: 'MIT' },
  { name: 'Active', code: 'ACTIVE' },
  { name: 'Filled-Pending Start Date', code: 'FILLED_PENDING_START_DATE' },
  { name: 'Leave of Absence', code: 'LEAVE_OF_ABSENCE' },
  { name: 'Quitting', code: 'QUITTING' },
]

export const MONTH_ABBREVIATION_MAP: { [monthNum: number]: string } = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
}

export const WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const ALERTS_DISPLAYED = {
  UNARCHIVED_ALERTS: 'UNARCHIVED_ALERTS',
  ARCHIVED_ALERTS: 'ARCHIVED_ALERTS',
  ALL_ALERTS: 'ALL_ALERTS',
}

export const DATA_DISPLAY_FORMAT = 'M/D/YY'

export const DAYPARTS = {
  DINNER: 'dinner',
  LUNCH: 'lunch',
  ALL: 'all',
}

export const SALES_TYPE = {
  GROSS_SALES: 'gross_sales',
  APP_SALES: 'app_sales',
  LBW_SALES: 'lbw_sales',
  DESSERT_SALES: 'dessert_sales',
  TOTAL_PPA: 'total_ppa',
  APP_PPA: 'app_ppa',
  LBW_PPA: 'lbw_ppa',
  DESSERT_PPA: 'dessert_ppa',
}

export const DAYPART_DISPLAY_NAME = {
  dinner: 'Dinner Only',
  lunch: 'Lunch Only',
  all: 'All',
}

export const FILTER_TYPE = {
  SHIFTS: 'shifts',
  PERIOD: 'period',
  DATE: 'date',
}

export const NUM_DAYS_WORKED = {
  LAST_10_SHIFTS: 'Last 10 Shifts',
  LAST_30_SHIFTS: 'Last 30 Shifts',
}

export const COMP_TYPES_DISPLAY_NAME = {
  foodQualityComps: 'Food Quality',
  mgrOverrideComps: 'Mgr Override',
  qualityComps: 'Quality',
  serviceCompComps: 'Service Comp',
  serviceIssuesComps: 'Service Issues',
  timeComps: 'Time',
  walkOutComps: 'Walk Out',
}

export const OPS_COMPS_DAYPART_KEY = {
  dinner: 'DINNER',
  lunch: 'LUNCH',
  all: 'NULL',
}

export const DELIVERY_DAYPART_KEY = {
  dinner: 'DINNER',
  lunch: 'LUNCH',
  all: 'ALL',
}

export const EE_COMPS_METRICS = {
  EE_OPS_COMPS: 'eeOpsComps',
  EE_GROSS_SALES: 'eeGrossSales',
  OPS_COMP_RATE: 'opsCompRate',
}

export const CORPORATE_LEVEL_NAV_TABS = 'corporate'
export const STORE_LEVEL_NAV_TABS = 'store'

export const S_PORTLAND_LOCATION_ID = 14

export const UNFILLED_MANAGERIAL_POSITION_OPTIONS = {
  NO_CANDIDATES: 'noCandidates',
  FILLED_POSITIONS: 'filledPositions',
  ALL_POSITIONS: 'allPositions',
}

export const PERFORMANCE_OPTIONS = {
  PROMOTE_READY: 'PROMOTE_READY',
  UPGRADE: 'UPGRADE',
  DEVELOPMENT_NEEDED: 'DEVELOPMENT_NEEDED',
  SOLID_PERFORMER: 'SOLID_PERFORMER',
  HIPO: 'HIPO',
  NO_FEEDBACK_IN_THE_PAST_90_DAYS: 'NO_FEEDBACK_IN_THE_PAST_90_DAYS',
}

export const PERFORMANCE_DISPLAY_TEXTS = {
  PROMOTE_READY: 'Promote Ready',
  UPGRADE: 'Upgrade',
  DEVELOPMENT_NEEDED: 'Development Needed',
  SOLID_PERFORMER: 'Solid Performer',
  HIPO: 'HiPo',
  NO_FEEDBACK_IN_THE_PAST_90_DAYS: 'No Feedback',
}

export const DELIVERY_CATEGORY = {
  INACCURATE_ORDER_PERCENT: 'inaccurateOrderPercent',
  CANCELLED_ORDER_PERCENT: 'cancelledOrderPercent',
  DELAYED_ORDER_PERCENT: 'delayedOrderPercent',
  AVG_CUSTOMER_REVIEW_SCORE: 'avgCustomerReviewScore',
  AVERAGE_TRIP_TIME: 'averageTripTime',
}

export const DELIVERY_CATEGORY_DISPLAY_TEXT = {
  inaccurateOrderPercent: '% Orders w/ Accuracy Issues',
  cancelledOrderPercent: '% Orders w/ Cancellations',
  delayedOrderPercent: '% Orders w/ Late Issues',
  avgCustomerReviewScore: 'Combined Star Rating',
  averageTripTime: 'Average Driver Trip Time (min)',
}

export const AI_FEEDBACK_USER_SATISFIED = 'USER_SATISFIED'
export const AI_FEEDBACK_USER_DISSATISFIED = 'USER_DISSATISFIED'
export const AI_FEEDBACK_EXPO_FAILED_TO_ANSWER = 'EXPO_FAILED_TO_ANSWER'
