// FIXME: should add download-csv-v2
import _ from 'lodash'
import { useMemo } from 'react'

import { useGroupFilter } from '../../groupFilter'
import useBbbPnlRankings, {
  bbbPnlRankingsConfigs,
} from '../../tableV2/hooks/bbb/usePnLRankings'

export const bbbPnlRankingsV2Configs = {
  ...bbbPnlRankingsConfigs,
}

const useBbbPnlRankingsV2 = () => {
  const { data, loading } = useBbbPnlRankings()
  const { groupFilter } = useGroupFilter()

  return {
    data: useMemo((): {
      source: Record<string, unknown>[]
      summary: Record<string, unknown> | null
    } | null => {
      if (!data) return null

      const summary = data.filter((d) => d.parentId === 'root')?.[0] || null

      return {
        source: [
          ...data
            .filter((d) => d.parentId === 'summary')
            .map((d, index) => {
              const groupInfo = groupFilter?.list?.find(
                (l) => l.id.toString() === d.id,
              )
              const copiedGroupInfo = !groupInfo ? undefined : { ...groupInfo }

              delete copiedGroupInfo?.groupBy

              return {
                ...d,
                rowIndex: index + 1,
                groupInfo: copiedGroupInfo,
              }
            }),
          ...(!summary
            ? []
            : [
                {
                  ...summary,
                  groupInfo: {
                    name: ' ',
                  },
                },
              ]),
        ],
        summary: null,
      }
    }, [data, groupFilter]),
    loading,
  }
}

export default useBbbPnlRankingsV2
