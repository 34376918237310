import Dialog from '@material-ui/core/Dialog'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import moment from 'moment'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import styled from 'styled-components'

import { FILTER_TYPE, NUM_DAYS_WORKED } from 'pared/constants'
import { IPeriod } from 'pared/data/getPeriods'
import DownArrow from 'pared/images/basic/arrow/triangle-arrow-down.svg'

interface IProps {
  selectedValue: string
  canDropdownOpen: boolean
  setCanDropdownOpen: any
  allPeriods: IPeriod[] | null
  onClickShifts: (event: any) => void
  onClickPeriod: (event: any) => void
  onClickSmallScreenFilterType: (event: any) => void
  onClickDateRange: (ranges: any) => void
  dateRangeStartDate: any
  dateRangeEndDate: any
  selectedPeriod: IPeriod | null
  filterType: string
  numDaysWorked: number
  isSmallScreenDateRangeDiaglogOpen: boolean
  setIsSmallScreenDateRangeDiaglogOpen: any
}

const DATE_DISPLAY_FORMAT = 'M/D/YY'

const useStyles = makeStyles(() => ({
  menuItem: {
    '&:hover': {
      backgroundColor: '#07A4C7 !important',
    },
  },
}))

const WebSmallMain = ({
  allPeriods,
  onClickSmallScreenFilterType,
  onClickShifts,
  onClickPeriod,
  onClickDateRange,
  selectedPeriod,
  filterType,
  numDaysWorked,
  dateRangeStartDate,
  dateRangeEndDate,
  isSmallScreenDateRangeDiaglogOpen,
  setIsSmallScreenDateRangeDiaglogOpen,
}: IProps) => {
  const classes = useStyles()

  const selectedPeriodKey = _.get(selectedPeriod, 'periodKey', '')

  const periodRows = _.map(allPeriods, (p) => {
    const periodKey = _.get(p, 'periodKey', '')
    const period = _.get(p, 'period', 1)
    const periodStart = p.periodStart.format(DATE_DISPLAY_FORMAT)
    const periodEnd = p.periodEnd.format(DATE_DISPLAY_FORMAT)

    return (
      <FilterMenuItem
        key={periodKey}
        value={periodKey}
        className={classes.menuItem}
      >
        P{period} ({periodStart} to {periodEnd})
      </FilterMenuItem>
    )
  })

  const periodSelect = (
    <SalesmanSelect
      displayEmpty
      value={selectedPeriodKey}
      onChange={onClickPeriod}
    >
      {periodRows}
    </SalesmanSelect>
  )

  const shiftSelect = (
    <SalesmanSelect displayEmpty value={numDaysWorked} onChange={onClickShifts}>
      <FilterMenuItem className={classes.menuItem} key={10} value={10}>
        {NUM_DAYS_WORKED.LAST_10_SHIFTS}
      </FilterMenuItem>
      <FilterMenuItem className={classes.menuItem} key={30} value={30}>
        {NUM_DAYS_WORKED.LAST_30_SHIFTS}
      </FilterMenuItem>
    </SalesmanSelect>
  )

  const formattedStartDate =
    moment(dateRangeStartDate).format(DATE_DISPLAY_FORMAT)
  const formattedEndDate = moment(dateRangeEndDate).format(DATE_DISPLAY_FORMAT)

  const onClickDate = () => {
    setIsSmallScreenDateRangeDiaglogOpen(true)
  }

  const dateSelect = (
    <TimeframeAnchor onClick={onClickDate}>
      <SelectionDescription>
        {`${formattedStartDate} to ${formattedEndDate}`}
      </SelectionDescription>
      <ArrowContainer>
        <img src={DownArrow} alt="Down Arrow" />
      </ArrowContainer>
    </TimeframeAnchor>
  )

  let timeframeSelect
  switch (filterType) {
    case FILTER_TYPE.SHIFTS: {
      timeframeSelect = shiftSelect
      break
    }
    case FILTER_TYPE.PERIOD: {
      timeframeSelect = periodSelect
      break
    }
    case FILTER_TYPE.DATE: {
      timeframeSelect = dateSelect
      break
    }
    default: {
      timeframeSelect = shiftSelect
    }
  }

  const selectionRange = {
    startDate: dateRangeStartDate,
    endDate: dateRangeEndDate,
    key: 'selection',
  }

  let date = new Date()
  date.setDate(date.getDate() - 1)

  return (
    <>
      <TimeframeFormControl
        variant="outlined"
        margin="dense"
        style={{ width: '90vw' }}
      >
        <SalesmanSelect
          displayEmpty
          value={filterType}
          onChange={onClickSmallScreenFilterType}
        >
          <MenuItem key="shifts" value="shifts">
            Filter by Shifts
          </MenuItem>
          <MenuItem key="period" value="period">
            Filter by Period
          </MenuItem>
          <MenuItem key="date" value="date">
            Filter by Date
          </MenuItem>
        </SalesmanSelect>
      </TimeframeFormControl>
      <TimeframeFormControl
        variant="outlined"
        margin="dense"
        style={{ width: '90vw' }}
      >
        {timeframeSelect}
      </TimeframeFormControl>
      <Dialog open={isSmallScreenDateRangeDiaglogOpen} onClose={() => {}}>
        <div>
          <DateRange
            ranges={[selectionRange]}
            onChange={onClickDateRange}
            maxDate={date}
            editableDateInputs
            dateDisplayFormat={'M-dd-yyyy'}
          />
        </div>
      </Dialog>
    </>
  )
}

const TimeframeFormControl = styled(FormControl)`
  background-color: white;
`

const TimeframeAnchor = styled.a`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding-top: 8px;
  padding-down: 10px;
  padding-left: 12px;
  height: 40px;
  cursor: pointer;
  border: 1px solid #c4c4c4;
`

const SelectionDescription = styled.div`
  width: 180px;
  float: left;
`

const ArrowContainer = styled.div`
  float: right;
  margin-right: 12px;
`

const SalesmanSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const FilterMenuItem = styled(MenuItem)`
  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-top: 10px;
  height: 40px;
`

export default WebSmallMain
