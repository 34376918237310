import React from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'

export interface IPropsType {
  className?: string
  title: string
  items: {
    key: string | React.Key
    value: React.ReactNode
    text: React.ReactNode
  }[]
}

const Header = styled.div`
  width: 100%;
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  border-bottom: 1px solid ${COLORS.Chalkboard};

  @media ${MOBILE_WIDTH} {
    padding: 10px 0px;
    font-size: 18px;
  }
`

const Table = styled.table`
  width: 100%;
  table-layout: fixed;

  td {
    text-align: center;
  }

  tr {
    &:first-child td {
      padding: 25px 0px 5px;
      font-family: Lexend-SemiBold;
      font-size: 24px;
      font-weight: 700;
    }

    &:last-child td {
      vertical-align: top;
      font-family: Lexend-Regular;
      font-size: 14px;
      font-weight: 400;
    }
  }

  @media ${MOBILE_WIDTH} {
    display: none;
  }
`

const MobileList = styled.div`
  display: none;

  & > div {
    display: flex;
    justify-content: space-between;
    font-family: Lexend-Regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: ${COLORS.Chalkboard};

    & > div:last-child {
      font-family: Lexend-SemiBold;
      font-size: 20px;
      font-weight: 700;
    }
  }

  @media ${MOBILE_WIDTH} {
    display: block;
  }
`

const List = ({ className, title, items }: IPropsType) => (
  <div className={className}>
    <Header>{title}</Header>

    <Table>
      <tbody>
        <tr>
          {items.map(({ key, value }) => (
            <td key={key}>{value}</td>
          ))}
        </tr>

        <tr>
          {items.map(({ key, text }) => (
            <td key={key}>{text}</td>
          ))}
        </tr>
      </tbody>
    </Table>

    <MobileList>
      {items.map(({ key, value, text }) => (
        <div key={key}>
          <div>{text}</div>

          <div>{value}</div>
        </div>
      ))}
    </MobileList>
  </div>
)

export default React.memo(List)
