const COLORS = {
  // background
  background: '#FAFAFA',

  // Green//
  Basil: '#12813E',
  Mint: '#A9DAD0',
  Cucumber: '#F7FCF9',
  PxBgGreen: '#EBF9F0',

  // Red//
  Pomodoro: '#E53D48',
  PomodoroTransparent: 'rgba(229, 61, 72, 0.25)',
  Spritz: '#FBE0E2',

  // Purple //
  DarkOrchid: '#9932CC',

  // Blue//
  Plum: '#4A90E2',
  PlumTransparent: 'rgba(74, 145, 226, 0.25)',
  Lavender: '#E2EDFA',
  Expo: '#0071E3',
  Link: '#07A4C7',
  Sky: '#57AFE7',
  BackgroundBlue: '#F7F9FF',

  // Orange//
  Mango: '#F5A623',
  MangoTransparent: 'rgba(245, 165, 36, 0.25)',
  Flan: '#FDF1DC',

  // Dark Blue//
  Acai: '#37394D',
  Shade: '#DFDFE2',

  // Grays//
  Chalkboard: '#4A4A4A',
  Steel: '#9C9C9C',
  Smoke: '#D7D7D7',
  Ash: '#EBEBEB',
  Porcelain: '#F6F6F6',
  Salt: '#FFFFFF',

  // Expo Red-Black Hue
  ExpoRed1: '#E04C4C',
  ExpoRed2: '#DF9F9F',
  ExpoGray2: '#AAA9A7',
  ExpoGray1: '#4A4C4A',
  ExpoGray3: '#8c7373',

  COLOR_HUE: [
    '#CB5A9E',
    '#E1A88A',
    '#BFCE72',
    '#9D85AA',
    '#5A8674',
    '#96CEC5',
    '#93BDEF',
  ],

  STACKED_BAR_COLOR_HUE: [
    '#0071E3',
    '#E53D48',
    '#F5A623',
    '#12813E',
    '#FBE0E2',
    '#07A4C7',
    '#FDF1DC',
    '#A9DAD0',
  ],

  MARKET_ANALYSIS_BAR_COLOR_HUE: [
    '#0071E3',
    '#E53D48',
    '#F5A623',
    '#12813E',
    '#FBE0E2',
    '#07A4C7',
    '#FDF1DC',
    '#A9DAD0',
    '#23AF93',
    '#8AB25F',
    '#32EBDF',
    '#C78EC2',
    '#44FFC7',
    '#8CBC0B',
    '#13CA19',
    '#43A192',
    '#1BB8CB',
    '#DE842A',
  ],
} as const

export default COLORS
