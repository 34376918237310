import { INavParams } from 'pared/Routes/navParams'
import { ILocationInfo } from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { IPeriod } from 'pared/data/getPeriods'

import WebLargeMain from './WebLargeMain'
import { ISalesmanship } from './index'

interface IProps {
  isLoading: boolean
  navParams: INavParams
  locationData: ILocationInfo | null
  errorMessage: string
  salesmanships: ISalesmanship[]
  dayPart: string
  setDayPart: any
  isLeaderboardsExpanded: boolean
  setIsLeaderboardsExpanded: any
  filterType: string
  setFilterType: any
  numDaysWorked: number
  setNumDaysWorked: any
  selectedPeriod: IPeriod | null
  setSelectedPeriod: any
  dateRangeStartDate: any
  setDateRangeStartDate: any
  dateRangeEndDate: any
  setDateRangeEndDate: any
  isTotalSalesExpanded: boolean
  setIsTotalSalesExpanded: any
  isSalesPerHourExpanded: boolean
  setIsSalesPerHourExpanded: any
  isPpaExpanded: boolean
  setIsPpaExpanded: any
  selectedValue: string
  setSelectedValue: (selectedValue: string) => void
}

const Main = ({
  isLoading,
  navParams,
  locationData,
  errorMessage,
  salesmanships,
  dayPart,
  setDayPart,
  isLeaderboardsExpanded,
  setIsLeaderboardsExpanded,
  filterType,
  setFilterType,
  numDaysWorked,
  setNumDaysWorked,
  selectedPeriod,
  setSelectedPeriod,
  dateRangeStartDate,
  setDateRangeStartDate,
  dateRangeEndDate,
  setDateRangeEndDate,
  selectedValue,
  setSelectedValue,
}: IProps) => {
  return (
    <WebLargeMain
      isLoading={isLoading}
      navParams={navParams}
      locationData={locationData}
      errorMessage={errorMessage}
      salesmanships={salesmanships}
      dayPart={dayPart}
      setDayPart={setDayPart}
      isLeaderboardsExpanded={isLeaderboardsExpanded}
      setIsLeaderboardsExpanded={setIsLeaderboardsExpanded}
      filterType={filterType}
      setFilterType={setFilterType}
      numDaysWorked={numDaysWorked}
      setNumDaysWorked={setNumDaysWorked}
      selectedPeriod={selectedPeriod}
      setSelectedPeriod={setSelectedPeriod}
      dateRangeStartDate={dateRangeStartDate}
      dateRangeEndDate={dateRangeEndDate}
      setDateRangeStartDate={setDateRangeStartDate}
      setDateRangeEndDate={setDateRangeEndDate}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
    />
  )
}

export default Main
