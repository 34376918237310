import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { IColumnsType } from 'pared/components/basicUi/table'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { IDirector } from 'pared/data/getDirectors'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import { getBrand, getBrandId } from 'pared/utils/brand'
import { getLocationCode, getLocationName } from 'pared/utils/location'
import { toPercentString, toUsdStr } from 'pared/utils/number'

import {
  FLASH_CUSTOMIZED,
  GET_STORE_LIST,
  LIST_LOCATION_FINANCIAL_KPIS,
  LIST_LOCATION_GROUP_FINANCIAL_KPIS,
} from '../gql'
import { IDataSourceType } from '../types'
import useFlashConfig from './useFlashConfig'

function getCustomizedColumnRender(columnDataType: string) {
  let render: any = null

  switch (columnDataType) {
    case 'CENT':
      render = (value: any) => {
        if (value || value === 0) {
          return toUsdStr(value / 100.0)
        } else {
          return '-'
        }
      }
      break
    case 'PERCENT':
      render = (value: any) => {
        if (value || value === 0) {
          return toPercentString(value)
        } else {
          return '-'
        }
      }
      break

    case 'SECONDS': {
      render = (value: any) => {
        if (value || value === 0) {
          const minutes = Math.floor(value / 60)
          const seconds = value % 60
          return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
        } else {
          return '-'
        }
      }

      break
    }
    default:
      render = (value: any) => {
        return value
      }
  }

  return render
}

const getPercentVsLY = (values: any) => {
  const netSales = parseInt(values?.netSales || '0', 10)
  const yoyNetSales = parseInt(values?.yoyNetSales || '0', 10)

  if (yoyNetSales === 0) return 0

  return (100 * (netSales - yoyNetSales)) / yoyNetSales
}

const getCustomerPercentVsLY = (values: any) => {
  const totalCheckCount = parseInt(values?.totalCheckCount || '0', 10)
  const yoyTotalCheckCount = parseInt(values?.yoyTotalCheckCount || '0', 10)

  if (yoyTotalCheckCount === 0) return 0

  return (totalCheckCount - yoyTotalCheckCount) / yoyTotalCheckCount
}

const getCheckCountDiffVsLY = (values: any) => {
  const totalCheckCount = parseInt(values?.totalCheckCount || '0', 10)
  const yoyTotalCheckCount = parseInt(values?.yoyTotalCheckCount || '0', 10)

  if (yoyTotalCheckCount === 0) return 0

  return totalCheckCount - yoyTotalCheckCount
}

const getNetSalesDiffVsLY = (values: any) => {
  const netSales = parseInt(values?.netSales || '0', 10)
  const yoyNetSales = parseInt(values?.yoyNetSales || '0', 10)

  if (yoyNetSales === 0) return 0

  return netSales - yoyNetSales
}

const useDataSource = (
  isBreakdownByDirectors: boolean,
  directors: IDirector[],
  locationGroupIds: number[],
  groupByType: string,
): {
  extendColumns: IColumnsType<IDataSourceType>[]
  dataSource: IDataSourceType[]
} => {
  const dateRange = useGetDateRange({ isCustomAllowed: true })
  const brand = getBrand()
  const brandId = getBrandId()
  const flashConfig = useFlashConfig()
  const { data: locationFinancial } = useQuery(LIST_LOCATION_FINANCIAL_KPIS, {
    variables: {
      iStartDate: dateRange?.startDateStr,
      iEndDate: dateRange?.endDateStr,
      iFilter: {
        location_group_ids: locationGroupIds,
      },
    },
    skip: !dateRange || isBreakdownByDirectors,
  })
  const { data: locationGroupFinancial } = useQuery(
    LIST_LOCATION_GROUP_FINANCIAL_KPIS,
    {
      variables: {
        iStartDate: dateRange?.startDateStr,
        iEndDate: dateRange?.endDateStr,
        iFilter: {
          location_group_ids: groupByType !== 'store' ? null : locationGroupIds,
          location_group_types: groupByType !== 'store' ? [groupByType] : null,
          brand_ids: [brandId],
        },
      },
      skip: !dateRange || !isBreakdownByDirectors,
    },
  )
  const { data: storeList } = useQuery(GET_STORE_LIST, {
    variables: {
      iFilter: {
        location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]],
      },
    },
  })
  const { data: flashCustomized } = useQuery(FLASH_CUSTOMIZED, {
    variables: {
      iCustomizedReportName:
        locationGroupIds.length > 1 ||
        !['store', 'director'].includes(groupByType)
          ? 'LIST_LOCATION_GROUP_FLASH_CUSTOMIZED_EXTEND_TABLE'
          : 'LIST_LOCATION_FLASH_CUSTOMIZED_EXTEND_TABLE',
      iInputParams: {
        ...(!['store', 'director'].includes(groupByType)
          ? {
              locationGroupTypes: [groupByType],
            }
          : {
              locationGroupIds,
            }),
        locationGroupId: locationGroupIds[0],
        startDate: dateRange?.startDateStr,
        endDate: dateRange?.endDateStr,
      },
    },
    skip: !dateRange || !flashConfig.customize,
  })
  const data =
    locationFinancial?.listLocationFinancialKpis ||
    locationGroupFinancial?.listLocationGroupFinancialKpis
  const customizedData = flashCustomized?.getCustomizedReport.nodes[0]

  const dataSource = useMemo(() => {
    if (!data?.nodes) return []

    return data.nodes.map((node: any) => {
      const store = storeList?.listLocationDetails?.nodes.find(
        ({ id }: any) => node?.locationId === id,
      )
      const director = directors.find(
        ({ locationGroupId }) => node.locationGroupId === locationGroupId,
      )
      const extendData = customizedData?.reportResult.tableData?.find(
        ({ locationId, locationGroupId }: any) => {
          if (locationId) return node?.locationId === locationId
          if (locationGroupId) return node?.locationGroupId === locationGroupId
          return false
        },
      )

      if (store)
        return {
          ...node,
          ...extendData,
          storeId: store.id,
          storeCode: getLocationCode(store.id, store.code),
          storeName: getLocationName(store.id, store.name),
          directorId: store.directorEmployeeInfo?.id || 0,
          directorName: [
            store.directorEmployeeInfo?.preferredName,
            store.directorEmployeeInfo?.familyName,
          ]
            .filter(Boolean)
            .join(' '),
          percentVsLY: getPercentVsLY(node),
          customerPercentVsLY: getCustomerPercentVsLY(node),
          checkCountDiffVsLy: getCheckCountDiffVsLY(node),
          netSalesDiffVsLy: getNetSalesDiffVsLY(node),
        }

      if (director)
        return {
          ...node,
          ...extendData,
          directorId: director.employeeId,
          directorName: [director.firstName, director.lastName]
            .filter(Boolean)
            .join(' '),
          percentVsLY: getPercentVsLY(node),
          customerPercentVsLY: getCustomerPercentVsLY(node),
          checkCountDiffVsLy: getCheckCountDiffVsLY(node),
          netSalesDiffVsLy: getNetSalesDiffVsLY(node),
        }

      if (groupByType) {
        return {
          ...node,
          ...extendData,
          percentVsLY: getPercentVsLY(node),
          customerPercentVsLY: getCustomerPercentVsLY(node),
          checkCountDiffVsLy: getCheckCountDiffVsLY(node),
          netSalesDiffVsLy: getNetSalesDiffVsLY(node),
        }
      }

      return { ...node, ...extendData }
    })
  }, [data, directors, storeList, customizedData, groupByType])

  const extendColumns = useMemo(() => {
    const columns = customizedData?.reportDefinition.columns
    const summary = customizedData?.reportResult.summary

    if (!columns) return []

    return columns.map(({ key, dataType, ...c }: any) => {
      let render: any = getCustomizedColumnRender(dataType)

      const tableColumnDef = {
        ...c,
        key,
        summary: () => render?.(summary?.[key]),
      }

      if (render) {
        tableColumnDef.render = render
        tableColumnDef.csvRender = render
      }

      return tableColumnDef
    })
  }, [customizedData])

  return {
    extendColumns,
    dataSource,
  }
}

export default useDataSource
