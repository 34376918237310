import React, { useMemo } from 'react'
import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/constants/styles'
import SalesmanshipV1 from 'pared/pages/v1/Salesmanship'
import SalesmanshipV2 from 'pared/pages/v2/Salesmanship'
import { getBrand } from 'pared/utils/brand'

const Wrapper = styled.div`
  padding: 0px 50px;

  @media ${MOBILE_WIDTH} {
    padding: 0px;
  }
`

// TODO: should remove this after v1 remove fullWidth setting
const fullWidthHOC =
  <P extends Object>(Component: React.ComponentType<P>) =>
  (props: P) =>
    (
      <Wrapper>
        <Component {...props} />
      </Wrapper>
    )

const useSalesmanshipConfig = (): any => {
  const brand = getBrand()

  return useMemo(() => {
    if (!brand) {
      return null
    }

    switch (brand) {
      case 'rmg':
      case 'rmg_nso':
      case 'sullivans':
      case 'sullivans_nso':
      case 'eddiemerlots':
      case 'eddiemerlots_nso':
      case 'papajohns':
      case 'papajohns_demo':
      case 'qsr_shack':
      case 'casual_fare':
        return SalesmanshipV1
      case 'irmg_bk':
      case 'viking_bk':
      case 'jsc_bk':
      case 'jsc_bk_nso':
      case 'sandbox_bk':
      case 'supreme_bk':
      case 'timoney_bk':
      case 'mbn_bk':
      case 'demo_bk':
      case 'irmg_pop':
      case 'supreme_pop':
      case 'ghai_pop':
      case 'demo_pop':
      case 'wingstop':
      case 'jitb':
      default:
        return fullWidthHOC(SalesmanshipV2)
    }
  }, [brand])
}
export default useSalesmanshipConfig
