import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import Table from 'pared/pages/ExpoAi/Table'
import { FROM_EXPO } from 'pared/pages/ExpoAi/constants'
import { IMessage } from 'pared/pages/ExpoAi/type'

export default function handleCannedQuestionAnswer(responseData: any) {
  if (responseData?.cannedQuestionAnswer) {
    const answerMessage: IMessage = {
      id: uuidv4(),
      from: FROM_EXPO,
      content: (
        <MainContainer>
          {responseData.cannedQuestionAnswer.description ? (
            <Description>
              {responseData.cannedQuestionAnswer.description}
            </Description>
          ) : null}
          <Table data={responseData.cannedQuestionAnswer.tableData} />
          {responseData.cannedQuestionAnswer.sql ? (
            <SqlCode>
              <div>SQL used for this analysis:</div>
              <pre>{responseData.cannedQuestionAnswer.sql}</pre>
            </SqlCode>
          ) : null}
        </MainContainer>
      ),
    }

    return answerMessage
  }

  return null
}

const MainContainer = styled.div`
  max-width: 1000px;
`

const Description = styled.div`
  padding: 0 0 10px 0;
`

const SqlCode = styled.div`
  padding: 10px 0 0 0;

  pre {
    padding: 10px 50px;
    font-size: 12px;
    max-height: 250px;
    overflow: auto;
  }
`
