import { useMemo } from 'react'

import { IApiDataType } from '../../../types'
import buildLocationEmployeeMetricValuesHook from '../../utils/buildLocationEmployeeMetricValuesHook'

const commonLossPreventionDataConfigs = {
  checkCount: 'number',
  lossPreventionVoidCheckCount: 'number',
  lossPreventionVoidAmount: 'price',
  lossPreventionVoidCheckCountPercent: 'percent',
} as const

export const fwWingstopListEmployeeLossPreventionConfigs = {
  ...commonLossPreventionDataConfigs,
  employeeName: 'string',
} as const

const useCommonEmployeeDiscountData = buildLocationEmployeeMetricValuesHook({
  codes: ['check_count'],
  groups: [
    'Expo Module - Loss Prevention Comps',
    'Expo Module - Loss Prevention Check Count',
    'Expo Module - Loss Prevention Check Count Percent',
  ],
})

const useListEmployeeLossPreventionData = () => {
  const { data: commonData, loading } = useCommonEmployeeDiscountData()

  return {
    data: useMemo((): IApiDataType => {
      if (!commonData) return null

      const filteredCommonData = commonData.filter((data: any) => {
        return (
          data?.lossPreventionVoidAmount ||
          data?.lossPreventionVoidCheckCount ||
          data?.lossPreventionVoidCheckCountPercent
        )
      })

      const keys = Object.keys(filteredCommonData?.[0] || {}) || []

      const keyInfo = keys
        .filter((k) => Object.keys(commonLossPreventionDataConfigs).includes(k))
        .reduce(
          (r, k) => {
            const kpiData =
              filteredCommonData?.map((cd) => cd[k] as number) || []
            const sortedData = [...kpiData].sort((a, b) => a - b)

            return {
              ...r,
              [k]: {
                sortedData,
              },
            }
          },
          {} as {
            [k: string]: {
              sortedData: number[]
            }
          },
        )

      const newData =
        filteredCommonData?.map((cd) => {
          return {
            ...cd,
            ...Object.keys(cd).reduce((r, c) => {
              if (Object.keys(commonLossPreventionDataConfigs).includes(c)) {
                const sorted = keyInfo[c].sortedData
                const rank =
                  sorted.findIndex((sortedItem) => sortedItem === cd[c]) + 1
                return {
                  ...r,
                  [`${c}Heatmap`]: ((rank - 1) / (sorted.length - 1)) * 100,
                }
              } else {
                return r
              }
            }, {}),
          }
        }) || []

      return newData
    }, [commonData, loading]),
    loading: loading,
  }
}

export default useListEmployeeLossPreventionData
