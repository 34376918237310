import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { allStoresTableConfigs, useVariables } from '../../../variables'
import { IApiDataType } from '../../types'

interface IDataType {
  listLocationMetricValues: {
    nodes: {
      locationId: number
      metricData: Record<
        'profit_after_controllables_after_donation' | 'total_sales',
        {
          name: string
          unit: 'CENT'
          value: number
        }
      >
    }[]
  }
}

const query = gql`
  query listLocationMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationGroupIds: [Int!]!
    $iMetricCodes: [String!]!
  ) {
    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: {
        location_group_ids: $iLocationGroupIds
        metrics: $iMetricCodes
      }
    ) {
      nodes {
        locationId
        metricData
      }
    }
  }
`

export const bbbProfitAfterControllablesConfigs = {
  ...allStoresTableConfigs,
  profitAfterControllablesAfterDonation: 'price',
  profitAfterControllablesAfterDonationPercent: 'percent',
  sales: 'price',
} as const

const useBbbProfitAfterControllables = () => {
  const { startDate, endDate } = useDateFilter()
  const { variables } = useVariables()
  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      // Fixed Company(Period) location group
      iLocationGroupIds: [175],
      iMetricCodes: [
        'profit_after_controllables_after_donation',
        'total_sales',
      ],
    },
    skip: !startDate || !endDate || !variables.allStores,
  })

  return {
    data: useMemo((): IApiDataType => {
      const metricData = data?.listLocationMetricValues.nodes

      if (!metricData) return null

      return metricData
        .map((m) => ({
          ...variables.allStores?.locations[m.locationId].tableRow,
          id: m.locationId.toString(),
          parentId: 'root',
          profitAfterControllablesAfterDonation:
            m.metricData.profit_after_controllables_after_donation.value,
          profitAfterControllablesAfterDonationPercent:
            (m.metricData.profit_after_controllables_after_donation.value *
              100) /
            m.metricData.total_sales.value,
          sales: m.metricData.total_sales.value,
        }))
        .sort((a, b) => a.sales - b.sales)
    }, [data, variables.allStores]),
    loading,
  }
}

export default useBbbProfitAfterControllables
