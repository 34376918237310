import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { IDirector } from 'pared/data/getDirectors'

import { LIST_LOCATION_GROUP_DISCOUNT_DETAILS } from '../gql'
import { ALL } from './useDiscountTypeItems'
import { IHeatMapCell, percentageToColor } from './useDiscounts'

const useLocationGroupDiscounts = (
  directors: IDirector[],
  selectedDiscount: string,
  locationGroupIds?: number[],
  startDate?: string,
  endDate?: string,
  locationGroupType?: string,
) => {
  const { data: discountsData, loading: discountLocationGroupLoading } =
    useQuery(LIST_LOCATION_GROUP_DISCOUNT_DETAILS, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: !locationGroupType
          ? {
              location_group_ids: [...(locationGroupIds || [])],
            }
          : {
              location_group_types: [locationGroupType],
            },
      },
      skip: !startDate || !endDate || !locationGroupIds,
    })

  let compsDetailType: string[] = []

  const discountLocationGroupData = useMemo(() => {
    const rawData = discountsData?.listLocationGroupDiscountDetails?.nodes || []

    const tableData = rawData
      .map((row: any) => {
        const locationGroupId = row.locationGroupId
        const director = directors.find(
          (d: IDirector) => d.locationGroupId === locationGroupId,
        )

        let grossSales: string = '-'
        let totalDiscounts: [string, IHeatMapCell, string] = [
          '-',
          { value: 0, color: 'transparent' },
          '-',
        ]

        if (row.totalGrossSales || row.totalGrossSales === 0) {
          grossSales = (row.totalGrossSales / 100.0).toFixed(0)
        }

        if (row.totalCount || row.totalCount === 0) {
          totalDiscounts[0] = row.totalCount
        }

        if (row.totalAmount || row.totalAmount === 0) {
          totalDiscounts[2] = (row.totalAmount / 100.0).toFixed(0)
          if (row.totalGrossSales)
            totalDiscounts[1].value = row.totalAmount / row.totalGrossSales
        }

        const itemDetails: {
          [compsType: string]: [string, IHeatMapCell, string]
        } = {}

        for (let key in row.compsDetails) {
          const itemData = row.compsDetails[key]
          let count = '-'
          let amount = '-'
          if (itemData?.count || itemData.count === 0) {
            count = itemData.count
          }
          if (itemData?.amount || itemData.amount === 0) {
            amount = (itemData.amount / 100.0).toFixed(0)
          }

          itemDetails[itemData.type] = [
            count,
            {
              value: row.totalGrossSales
                ? itemData.amount / row.totalGrossSales
                : 0,
              color: 'transparent',
            },
            amount,
          ]

          if (!compsDetailType.includes(itemData.type))
            compsDetailType.push(itemData.type)
        }

        return {
          ...itemDetails,
          directorId: director?.directorId || 0,
          directorName: [director?.firstName || '', director?.lastName || '']
            .filter(Boolean)
            .join(' '),
          totalDiscounts,
          grossSales,
          locationGroupName: row.locationGroupName,
        }
      })
      .filter((data) =>
        selectedDiscount === ALL ? true : data.hasOwnProperty(selectedDiscount),
      )

    if (tableData?.length > 1) {
      compsDetailType?.forEach((type: string) => {
        tableData
          .sort((a: any, b: any) => {
            if (a[type] === undefined) return 1
            if (b[type] === undefined) return -1
            return b[type][1].value - a[type][1].value
          })
          .map((value, index, array) => {
            if (value[type]) {
              value[type][1].color = percentageToColor(
                index /
                  (array.length -
                    array.filter((obj: any) => !obj.hasOwnProperty(type))
                      .length),
              )
              return value
            }
          })
      })
    }

    if (tableData?.length > 1) {
      tableData
        .sort((a: any, b: any) => {
          return b?.totalDiscounts[1].value - a?.totalDiscounts[1].value
        })
        .map((value, index, array) => {
          value.totalDiscounts[1].color = percentageToColor(
            index / array.length,
          )
          return value
        })
    }

    return tableData
  }, [
    discountsData,
    selectedDiscount,
    directors,
    locationGroupIds,
    startDate,
    endDate,
    locationGroupType,
  ])

  return { discountLocationGroupData, discountLocationGroupLoading }
}

export default useLocationGroupDiscounts
