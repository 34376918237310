import { gql } from '@apollo/client'

export interface IGuestSummaryNodeType {
  problemPercentage: number
  mostCommonProblem: string[]
  mostCommonDay: string[]
}

export interface IGuestSummaryType {
  locationGuestSummaryV2: {
    nodes: IGuestSummaryNodeType[]
  }
}

export interface IGuestSummaryVariablesType {
  iLocationId: number
  iStartDate?: string
  iEndDate?: string
}

export const getLocationGuestSummary = gql`
  query LocationGuestSummaryV2(
    $iStartDate: Date!
    $iEndDate: Date!
    $iLocationId: Int!
  ) {
    locationGuestSummaryV2(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iLocationId: $iLocationId
    ) {
      nodes {
        problemPercentage
        mostCommonProblem
        mostCommonDay
      }
    }
  }
`
