import { gql } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { apolloClient } from 'pared/reactiveVariables/apollo'
import { getBrand } from 'pared/utils/brand'
import { getLocationCode, getLocationName } from 'pared/utils/location'

export interface ILocation {
  id: number
  isOpen: boolean
  locationId: string
  code: string
  name: string
  geo: any
  timezone: string
  brandId?: number
  directorId?: number
  doEmployeeId?: number
  directorEmployeeInfo?: {
    id: number
    givenName: string
    familyName: string
    preferredName: string
  }
  gmEmployeeInfo?: {
    id: number
    givenName: string
    familyName: string
    preferredName: string
  }
}

export interface ILocationMap {
  [locationId: string]: ILocation
}

const FETCH_LOCATIONS = gql`
  query FetchLocations($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        geo
        timezone
        brandId
        directorId
        directorEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        gmEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
      }
    }
  }
`

const DATE_FORMAT = 'YYYY-MM-DD'

let locations: ILocation[] = []
let locationMap: ILocationMap = {}
let lastDataFetchDate: string = ''
let lastBrand: string = ''

async function getLocations() {
  const brand = getBrand()

  if (
    locations.length > 0 &&
    lastDataFetchDate &&
    moment().format(DATE_FORMAT) === lastDataFetchDate &&
    lastBrand === brand
  ) {
    // use cached data
    return { locations, locationMap }
  } else {
    const currentApolloClient = apolloClient()

    if (currentApolloClient) {
      let response: any
      try {
        response = await currentApolloClient.query({
          query: FETCH_LOCATIONS,
          variables: {
            iFilter: {
              location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]],
            },
          },
        })
      } catch (error: any) {
        console.log(error)
      }
      const rawLocationList = _.get(response, 'data.listLocationDetails.nodes')
      const currentTime = moment()

      if (Array.isArray(rawLocationList)) {
        locations = []
        locationMap = {}
        lastDataFetchDate = currentTime.format(DATE_FORMAT)
        lastBrand = brand

        rawLocationList.forEach((rawLocationData: any) => {
          if (rawLocationData && rawLocationData.id) {
            let location: ILocation = {
              isOpen: true,
              id: rawLocationData.id,
              locationId: rawLocationData.id.toString(),
              code: getLocationCode(
                rawLocationData.id,
                rawLocationData.code || '',
              ),
              name: getLocationName(
                rawLocationData.id,
                rawLocationData.name || '',
              ),
              geo: rawLocationData.geo,
              timezone: rawLocationData.timezone,
            }

            if (rawLocationData.brandId) {
              location.brandId = rawLocationData.brandId
            }
            if (rawLocationData.directorId) {
              location.directorId = rawLocationData.directorId
            }

            if (
              rawLocationData.directorEmployeeInfo &&
              rawLocationData.directorEmployeeInfo.id
            ) {
              location.doEmployeeId = rawLocationData.directorEmployeeInfo.id
              location.directorEmployeeInfo = {
                ...rawLocationData.directorEmployeeInfo,
              }
            }

            if (
              rawLocationData.gmEmployeeInfo &&
              rawLocationData.gmEmployeeInfo.id
            ) {
              location.gmEmployeeInfo = { ...rawLocationData.gmEmployeeInfo }
            }

            locations.push(location)
            locationMap[location.locationId] = location
          }
        })
        locations = _.sortBy(locations, ['code'])
      }
    }

    return { locations, locationMap }
  }
}

export default getLocations
