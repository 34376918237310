import DateFnsUtils from '@date-io/date-fns'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import _ from 'lodash'
import styled from 'styled-components'

import { ILocation } from 'pared/data/getLocations'

interface IProps {
  locationId: number
  setLocationId: any
  name?: string
  setName: any
  shouldDisplayPerformanceValueError: boolean
  setShouldDisplayPerformanceValueError: any
  shouldDisplayFeedbackEmptyError: boolean
  setShouldDisplayFeedbackEmptyError: any
  comment: string
  setComment: any
  onSubmitFeedback: () => Promise<void>
  onStoreValueChange: (event: any) => void
  isSubmitting: boolean
  allLocations: ILocation[] | null
  roleName: string
  timeVacant: string
  pendingStartDate: any
  setPendingStartDate: any
  shouldDisplayCommentEmptyError: boolean
  setShouldDisplayCommentEmptyError: any
}

const useStyles = makeStyles(() => ({
  menuItem: {
    '&:hover': {
      backgroundColor: '#07A4C7 !important',
    },
  },
}))

const Main = ({
  locationId,
  name,
  setName,
  shouldDisplayCommentEmptyError,
  setShouldDisplayCommentEmptyError,
  comment,
  setComment,
  onSubmitFeedback,
  onStoreValueChange,
  isSubmitting,
  allLocations,
  roleName,
  timeVacant,
  pendingStartDate,
  setPendingStartDate,
}: IProps) => {
  const classes = useStyles()

  const commentErrorText = shouldDisplayCommentEmptyError ? (
    <ErrorText>Please complete the form</ErrorText>
  ) : null

  const onChangeFeedback = (event: any) => {
    if (comment !== '') {
      setShouldDisplayCommentEmptyError(false)
    }
    setComment(event.target.value)
  }

  const handleDateChange = (date: string) => {
    setPendingStartDate(date)
  }

  const onNameChange = (event: any) => {
    setName(event.target.value)
  }

  const locations = _.map(allLocations, (l) => {
    const code = _.get(l, 'code', '')
    const name = _.get(l, 'name', '')
    const locationId = _.get(l, 'locationId', 1)

    return (
      <MenuItem
        value={locationId}
        className={classes.menuItem}
      >{`${code} - ${name}`}</MenuItem>
    )
  })

  return (
    <PopoverContainer>
      <SectionHeader>UNFILLED MANAGERIAL POSITION</SectionHeader>
      <PositionTable>
        <tbody>
          <tr>
            <TitleTd>Role</TitleTd>
            <td>{roleName}</td>
          </tr>
          <tr>
            <TitleTd>Time Vacant</TitleTd>
            <td>{timeVacant}</td>
          </tr>
          <tr>
            <TitleTd>Filled Pending Start Date</TitleTd>
            <td>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  format="MM/dd/yyyy"
                  value={pendingStartDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </td>
          </tr>
          <tr>
            <TitleTd>Name</TitleTd>
            <td>
              <TextField
                id="outlined-basic"
                variant="outlined"
                onChange={onNameChange}
                defaultValue={name}
              />
            </td>
          </tr>
          <tr>
            <TitleTd>Training Store</TitleTd>
            <td>
              <PerformanceFormControl>
                <Select
                  value={locationId}
                  onChange={onStoreValueChange}
                  displayEmpty
                >
                  <MenuItem value={0} disabled>
                    Select Store
                  </MenuItem>
                  {locations}
                </Select>
              </PerformanceFormControl>
            </td>
          </tr>
          <tr>
            <TitleTd>Comments</TitleTd>
            <td>
              <DetailsTextarea
                placeholder={`Please provide comments`}
                value={comment}
                onInput={onChangeFeedback}
              />
            </td>
          </tr>
        </tbody>
      </PositionTable>
      {commentErrorText}
      <ButtonContainer>
        <SubmitButton disabled={isSubmitting} onClick={onSubmitFeedback}>
          {isSubmitting ? 'Submitting ...' : 'Submit'}
        </SubmitButton>
      </ButtonContainer>
    </PopoverContainer>
  )
}

const PositionTable = styled.table`
  width: 90%;
  font-size: 14px;
  font-style: normal;

  td,
  th {
    vertical-align: top;
    padding: 10px 0;
  }
`

const TitleTd = styled.td`
  font-family: Lexend-SemiBold;
  width: 130px;
`

const ErrorText = styled(FormHelperText)`
  font-family: Lexend-Regular;
  color: red;
`

const PopoverContainer = styled.div`
  width: 622px;
  padding: 30px 20px 20px 20px;
  margin: 1px 0 0 0;
  border: 1px solid black;
`

const SectionHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 10px 0;
`

const PerformanceFormControl = styled(FormControl)`
  margin: 0 0 10px 0;
`

const ButtonContainer = styled.div`
  margin: auto;
  width: 179px;
`

const SubmitButton = styled.button`
  background-color: #07a4c7;
  border: none;
  color: white;
  padding: 15px 15px 15px 15px;
  width: 179px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: Lexend-SemiBold;
  font-size: 18px;
  margin: 4px 2px;
  cursor: pointer;
`

const DetailsTextarea = styled.textarea`
  width: 100%;
  height: 195px;
  resize: none;
  padding: 8px 10px 10px 8px;

  ::placeholder {
    font-style: italic;
  }
`

export default Main
