import { useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import { INullableType } from 'pared/types'
import { getBrand } from 'pared/utils/brand'

import {
  GET_MARKETING_ANALYSIS_ITEMS,
  IGetDiscountItemTypesType,
  IGetDiscountItemTypesVariableTypes,
} from '../gql'

export const ALL = 'All'

interface IItemType {
  id: string
  parentId: string | null
  value: string
  text: string
}

interface IReturnType {
  selectedDiscount: string[]
  discountChanged: (value: string[]) => void
  discountItems: IItemType[]
}

const useDiscountTypeItems = (
  isBreakdownByDirectors: boolean,
  locationIds?: number[],
  locationGroupIds?: number[],
): IReturnType => {
  const brand = getBrand()

  let iFilter: any = null
  if (locationIds && locationIds.length > 0) {
    iFilter = { location_ids: [...locationIds] }
  } else if (isBreakdownByDirectors) {
    iFilter = { location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]] }
  } else if (locationGroupIds && locationGroupIds.length > 0) {
    iFilter = { location_group_ids: [...locationGroupIds] }
  }

  const dateRange = useGetDateRange()
  const variables: INullableType<IGetDiscountItemTypesVariableTypes> = {
    iFilter,
    iStartDate: dateRange?.startDateStr,
    iEndDate: dateRange?.endDateStr,
  }
  const { data } = useQuery<
    IGetDiscountItemTypesType,
    INullableType<IGetDiscountItemTypesVariableTypes>
  >(GET_MARKETING_ANALYSIS_ITEMS, {
    variables,
    skip: !variables?.iFilter || !variables?.iStartDate || !variables?.iEndDate,
  })

  const discountItemData = data?.getMarketingAnalysisItems?.nodes
  const [selectedDiscount, setSelectedDiscount] = useState<string[]>([ALL])

  const discountItems = useMemo(() => {
    const discountTypes = discountItemData?.reduce((result, item) => {
      if (!result.find((resultItem) => resultItem.value === item.catogories)) {
        const categoryName =
          item.catogories === 'PROMO - PPROMOTIONAL MENUS'
            ? 'Promotional Menus'
            : item.catogories === 'PROMO - CUSTOMER RELATIONS'
            ? 'Customer Relations'
            : item.catogories === 'PROMO ITEMS (COUPONS DISCOUNT'
            ? 'Coupons Discount'
            : 'Others'

        result.push({
          id: item.catogories,
          parentId: 'root',
          value: item.catogories,
          text: categoryName,
        })

        result.push({
          id: `${item.catogories}-All`,
          parentId: item.catogories,
          value: ALL,
          text: `Show All ${categoryName}`,
        })
      }

      if (!result.find((resultItem) => resultItem.value === item.itemType)) {
        result.push({
          id: item.itemType,
          parentId: item.catogories,
          value: item.itemType,
          text: item.itemType,
        })
      }

      return result
    }, [] as IItemType[])

    return [
      {
        id: 'root',
        parentId: null,
        value: 'root',
        text: 'root',
      },
      {
        id: ALL,
        parentId: 'root',
        value: ALL,
        text: ALL,
      },
      ...(discountTypes || []),
    ]
  }, [discountItemData])

  return {
    selectedDiscount,
    discountChanged: setSelectedDiscount,
    discountItems,
  }
}

export default useDiscountTypeItems
