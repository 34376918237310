import { gql } from '@apollo/client'

export const GET_LOCATION_DATA = gql`
  query GetLocationInfo($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        brandId
        directorId
        directorEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        gmEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
      }
    }
  }
`

export const GET_LOCATION_DELIVERY_KPI = (customizedFields?: string[]) => gql`
  query GetLocationDeliveryKpi(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    ${(customizedFields || []).length <= 0 ? '' : '$iFilter: JSON!'}
  ) {
    getLocationDeliveryKpi(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        totalStoreCount
        totalOrderCount
        avgDasherWaitTime
        sumSubtotal
        sumSubtotalRanking
        sumErrorCharges

        inaccurateOrderPercent
        inaccurateOrderPercentRanking
        cancelledOrderPercent
        cancelledOrderPercentRanking
        delayedOrderPercent
        delayedOrderPercentRanking
        orderWithAnyIssuePercent
        orderWithAnyIssuePercentRanking
        avgCustomerReviewScore
        avgCustomerReviewScoreRanking
        totalOrderCountRanking
        averageTripTime
        averageTripTimeRanking
      }
    }

    ${
      (customizedFields || []).length <= 0
        ? ''
        : `
      customizedRankLocationDelivery(
        iStartDate: $iStartDate
        iEndDate: $iEndDate
        iFilter: $iFilter
      ) {
        nodes {
        locationId
          ${customizedFields?.join('\n')}
        }
      }
    `
    }
  }
`

export const GET_LOCATION_VS_SYSTEMWIDE_DELIVERY_KPI_WEEKLY = gql`
  query ListLocationVsSystemwideDeliveryKpi(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
  ) {
    listLocationVsSystemwideDeliveryKpiV2(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) {
      nodes {
        locationId
        year
        quarter
        period
        weekOfPeriod
        groupByStartDate
        groupByEndDate
        locationCorrectOrderPercent
        systemwideCorrectOrderPercent
      }
    }
  }
`

export const GET_LOCATION_VS_SYSTEMWIDE_DELIVERY_KPI = gql`
  query GetLocationVsSystemwideDeliveryKpi(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    getLocationVsSystemwideDeliveryKpi(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        locationId
        locationCorrectOrderPercent
        systemwideCorrectOrderPercent
        locationInaccurateOrderPercent
        systemwideInaccurateOrderPercent
        locationCancelledOrderPercent
        systemwideCancelledOrderPercent
        locationDelayedOrderPercent
        systemwideDelayedOrderPercent
      }
    }
  }
`

export const LIST_LOCATION_DELIVERY_DAY_TRENDS = gql`
  query ListLocationDeliveryDayTrends(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationDeliveryDayTrends(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        dayOfWeek
        dayPart
        totalOrderCount
        inaccurateOrderPercent
        cancelledOrderPercent
        delayedOrderPercent
        avgCustomerReviewScore
        reviewedOrderCount
        averageTripTime
      }
    }
  }
`

export const LIST_LOCATION_DELIVERY_STAFF_KPI = gql`
  query ListLocationDeliveryStaffKpi(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    listLocationDeliveryStaffKpi(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        locationId
        employeeInfo {
          id
          firstName: preferredName
          lastName: familyName
        }
        employeeRoleName
        employeeRoleCode
        totalOrderCount
        inaccurateOrderPercent
        cancelledOrderPercent
        delayedOrderPercent
        avgCustomerReviewScore
        totalReviewCount
        averageTripTime
      }
    }
  }
`
