import _ from 'lodash'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import NormalizationStatus from 'pared/components/NormalizationStatus'
import Tooltip from 'pared/components/basicUi/Tooltip'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import COLORS from 'pared/constants/colors'
import { getBrandSettings, getBusinessLabel } from 'pared/customer'
import {
  IDateRange,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_TRAILING_7_DAYS,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'

import CustomBreakdownTable from './CustomBreakdownTable'
import DirectorFilterSelector from './DirectorFilterSelector'
import Map from './Map'
import Stores from './Stores'
import useConfig from './hooks/useConfig'
import {
  IFormattedDoBreakdownInfo,
  IFormattedLocationGroupBreakdownInfo,
  IFormattedStoreInfo,
  ILocationCoordinate,
  ISummaryPerformance,
} from './index'

interface IOpenPositionsTd {
  danger?: boolean
  success?: boolean
  book?: boolean
  isBackgroundGrey?: boolean
}

interface IProps {
  navParams: INavParams
  isLoading: boolean
  allDirectors: IDirector[]
  selectedDateRange: IDateRange | null
  selectedDirector: IDirector | null
  selectedLocationGroupIds: number[]
  onDirectorChange: (directorEmployeeId: number) => void
  summaryPerformance: ISummaryPerformance
  sortedStoreList: IFormattedStoreInfo[]
  sortedDoBreakdownList: IFormattedDoBreakdownInfo[] | null
  sortedLocationGroupBreakdownList:
    | IFormattedLocationGroupBreakdownInfo[]
    | null
  breakdownType: string | null
  handleLocationGroupNameClick: (
    locationGroupId: number,
    locationGroupName: string,
  ) => void
  handleGoBack: () => void
  selectedLocationGroup: { id: number; name?: string } | null
  errorMessage: string
  locationsWithCoordinates: ILocationCoordinate[]
}

function WebLargeMain({
  navParams,
  isLoading,
  allDirectors,
  selectedDateRange,
  selectedDirector,
  selectedLocationGroupIds,
  onDirectorChange,
  summaryPerformance,
  sortedStoreList,
  sortedDoBreakdownList,
  sortedLocationGroupBreakdownList,
  breakdownType,
  handleLocationGroupNameClick,
  handleGoBack,
  selectedLocationGroup,
  errorMessage,
  locationsWithCoordinates,
}: IProps) {
  const config = useConfig()
  if (errorMessage || !selectedDirector) {
    return (
      <MainContainer>
        <PageHeader>{config.pageTitle || 'Systemwide Overview'}</PageHeader>
        <PageStatusDiv>{errorMessage}</PageStatusDiv>
      </MainContainer>
    )
  }

  if (isLoading || !selectedDateRange) {
    return (
      <MainContainer>
        <PageHeader>{config.pageTitle || 'Systemwide Overview'}</PageHeader>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </MainContainer>
    )
  }

  const brandSettings = getBrandSettings()
  const financialLabel = brandSettings.labels.financial
  const isBreakdownByDirectors =
    selectedDirector && selectedDirector.employeeId === -2
  const isBreakdownByLocationGroupType =
    breakdownType !== null && selectedLocationGroup === null
  const isBackButtonVisible =
    breakdownType !== null && selectedLocationGroup !== null
  const isAvtMonthlyVisible =
    brandSettings.overview.avtMonthly.isVisible &&
    ![TYPE_YESTERDAY, TYPE_TRAILING_7_DAYS].includes(selectedDateRange.type)
  const displayPeriodCogs =
    config.usePeriodCogs &&
    [TYPE_PERIOD, TYPE_QUARTER, TYPE_YEAR].includes(selectedDateRange.type)
  const netSales = _.get(summaryPerformance, 'netSales', '-')
  const netSalesBudgetVariance = _.get(
    summaryPerformance,
    'netSalesBudgetVariance',
    '-',
  )
  const grossSales = _.get(summaryPerformance, 'grossSales', '-')
  const grossSalesBudgetVariance = _.get(
    summaryPerformance,
    'grossSalesBudgetVariance',
    '-',
  )
  const netSalesSssPercent = _.get(
    summaryPerformance,
    'netSalesSssPercent',
    '-',
  )
  const grossSalesSssPercent = _.get(
    summaryPerformance,
    'grossSalesSssPercent',
    '-',
  )
  const checkCount = _.get(summaryPerformance, 'checkCount', '-')
  const checkCountVsLyPercent = _.get(
    summaryPerformance,
    'checkCountVsLyPercent',
    '-',
  )
  const checkAverage = _.get(summaryPerformance, 'checkAverage', '-')
  const hourlyLaborPerformanceAgainstBudgetPercent = _.get(
    summaryPerformance,
    'hourlyLaborPerformanceAgainstBudgetPercent',
    '-',
  )
  const hourlyLaborPercent = _.get(
    summaryPerformance,
    'hourlyLaborPercent',
    '-',
  )
  const cogsPercent = _.get(summaryPerformance, 'cogsPercent', '-')
  const cogsBudgetVariance =
    cogsPercent === '-'
      ? '-'
      : _.get(summaryPerformance, 'cogsBudgetVariance', '-')

  const periodCogsPercent = _.get(summaryPerformance, 'periodCogsPercent', '-')
  const periodCogsBudgetVariance =
    periodCogsPercent === '-'
      ? '-'
      : _.get(summaryPerformance, 'periodCogsBudgetVariance', '-')
  const rcpPercent = _.get(summaryPerformance, 'rcpPercent', '-')
  const salesPerLaborHour = _.get(summaryPerformance, 'salesPerLaborHour', '-')
  const avtPercent = _.get(summaryPerformance, 'avtPercent', '-')
  const avtPercentWeekly = _.get(summaryPerformance, 'avtPercentWeekly', '-')
  const avtPercentMonthly = _.get(summaryPerformance, 'avtPercentMonthly', '-')
  const isMonthlyAvtReady = avtPercentMonthly !== '-'
  const plvPercent = _.get(summaryPerformance, 'plvPercent', '-')
  const sosAvgSeconds = _.get(summaryPerformance, 'sosAvgSeconds', '-')

  const isNetSalesSssPercentNegative = _.includes(
    _.get(summaryPerformance, 'netSalesSssPercent', '0'),
    '(',
  )
  const isGrossSalesSssPercentNegative = _.includes(
    _.get(summaryPerformance, 'grossSalesSssPercent', '0'),
    '(',
  )
  const isNetSalesBudgetVarianceNegative = _.includes(
    _.get(summaryPerformance, 'netSalesBudgetVariance', '0'),
    '(',
  )
  const isgrossSalesBudgetVarianceNegative = _.includes(
    _.get(summaryPerformance, 'grossSalesBudgetVariance', '0'),
    '(',
  )
  const isCogsBudgetVarianceNegative = _.includes(
    _.get(summaryPerformance, 'cogsBudgetVariance', '0'),
    '(',
  )
  const isPeriodCogsBudgetVarianceNegative = _.includes(
    _.get(summaryPerformance, 'periodCogsBudgetVariance', '0'),
    '(',
  )
  const isLaborPercentNegative = _.includes(
    hourlyLaborPerformanceAgainstBudgetPercent,
    '(',
  )
  const isAvtPercentNegative = _.includes(avtPercent, '(')
  const isAvtPercentWeeklyNegative = _.includes(avtPercentWeekly, '(')
  const isAvtPercentMonthlyNegative = _.includes(avtPercentMonthly, '(')
  const isPlvPercentNegative = _.includes(plvPercent, '(')

  return (
    <MainContainer>
      <PageHeaderContainer>
        <LeftHeaderContainer>
          <PageHeaderLineContainer>
            <PageLeftHeader>
              {config.pageTitle || 'Systemwide Overview'}
              <NormalizationStatusDiv>
                <NormalizationStatus
                  normalizationTable="location_normalized_data_daily"
                  brandId={brandSettings.brandId}
                />
              </NormalizationStatusDiv>
            </PageLeftHeader>
            <SelectorContainer>
              <DirectorFilterSelectorContainer>
                <DirectorFilterSelector
                  allDirectors={allDirectors}
                  selectedDirector={selectedDirector}
                  onDirectorChange={onDirectorChange}
                  temporaryValue={selectedLocationGroup?.name}
                />
              </DirectorFilterSelectorContainer>
              <DateRangeSelector
                navParams={navParams}
                dateRangeOptions={config.dateRangeOptions}
                preSelectedDateRange={config.preSelectedDateRange}
              />
            </SelectorContainer>
          </PageHeaderLineContainer>
        </LeftHeaderContainer>
        <RightHeaderContainer></RightHeaderContainer>
      </PageHeaderContainer>
      <SystemOverviewContainer>
        <PtdOverviewTable>
          <tbody>
            <tr>
              <td>
                <PtdKpiTable>
                  <thead>
                    <tr>
                      {!brandSettings.overview.netSales.isVisible ? null : (
                        <OverviewTableTh book={netSales === 'loading'}>
                          {netSales}
                        </OverviewTableTh>
                      )}
                      {!brandSettings.overview.grossSales.isVisible ? null : (
                        <OverviewTableTh book={grossSales === 'loading'}>
                          {grossSales}
                        </OverviewTableTh>
                      )}
                      {!brandSettings.overview.netSalesSssPercent
                        .isVisible ? null : (
                        <OverviewTableTh
                          book={netSalesSssPercent === 'loading'}
                          danger={isNetSalesSssPercentNegative}
                        >
                          {netSalesSssPercent}
                        </OverviewTableTh>
                      )}
                      {!brandSettings.overview.grossSalesSssPercent
                        .isVisible ? null : (
                        <OverviewTableTh
                          book={grossSalesSssPercent === 'loading'}
                          danger={isGrossSalesSssPercentNegative}
                        >
                          {grossSalesSssPercent}
                        </OverviewTableTh>
                      )}
                      {!brandSettings.overview.netSalesBudgetVariance
                        .isVisible ? null : (
                        <OverviewTableTh
                          book={netSalesBudgetVariance === 'loading'}
                          danger={isNetSalesBudgetVarianceNegative}
                        >
                          {netSalesBudgetVariance}
                        </OverviewTableTh>
                      )}
                      {!brandSettings.overview.grossSales.isVisible ? null : (
                        <OverviewTableTh
                          book={grossSalesBudgetVariance === 'loading'}
                          danger={isgrossSalesBudgetVarianceNegative}
                        >
                          {grossSalesBudgetVariance}
                        </OverviewTableTh>
                      )}
                      {!brandSettings.overview.checkCount.isVisible ? null : (
                        <OverviewTableTh book={checkCount === 'loading'}>
                          {checkCount}
                        </OverviewTableTh>
                      )}
                      {!brandSettings.overview.checkCountVsLyPercent
                        .isVisible ? null : (
                        <OverviewTableTh
                          book={checkCountVsLyPercent === 'loading'}
                        >
                          {checkCountVsLyPercent}
                        </OverviewTableTh>
                      )}
                      {!brandSettings.overview.checkAverage.isVisible ? null : (
                        <OverviewTableTh book={checkAverage === 'loading'}>
                          {checkAverage}
                        </OverviewTableTh>
                      )}
                      <OverviewTableTh book={hourlyLaborPercent === 'loading'}>
                        {hourlyLaborPercent}
                      </OverviewTableTh>
                      {!brandSettings.overview.hourlyLaborBudgetVariance
                        .isVisible ? null : (
                        <OverviewTableTh
                          book={
                            hourlyLaborPerformanceAgainstBudgetPercent ===
                            'loading'
                          }
                          danger={!isLaborPercentNegative}
                        >
                          {hourlyLaborPerformanceAgainstBudgetPercent}
                        </OverviewTableTh>
                      )}
                      {!brandSettings.overview.salesPerLaborHour
                        .isVisible ? null : (
                        <OverviewTableTh book={salesPerLaborHour === 'loading'}>
                          {salesPerLaborHour}
                        </OverviewTableTh>
                      )}
                      {!brandSettings.overview.sosAvgSeconds
                        .isVisible ? null : (
                        <OverviewTableTh book={sosAvgSeconds === 'loading'}>
                          {sosAvgSeconds}
                        </OverviewTableTh>
                      )}
                      {!brandSettings.overview.avt.isVisible ? null : (
                        <OverviewTableTh
                          book={avtPercent === 'loading'}
                          danger={isAvtPercentNegative}
                        >
                          {avtPercent}
                        </OverviewTableTh>
                      )}
                      {!isAvtMonthlyVisible ? null : (
                        <OverviewTableTh
                          book={
                            isMonthlyAvtReady
                              ? avtPercentMonthly === 'loading'
                              : avtPercentWeekly === 'loading'
                          }
                          danger={
                            isMonthlyAvtReady
                              ? isAvtPercentMonthlyNegative
                              : isAvtPercentWeeklyNegative
                          }
                        >
                          {isMonthlyAvtReady
                            ? avtPercentMonthly
                            : avtPercentWeekly}
                        </OverviewTableTh>
                      )}
                      {!brandSettings.overview.plv.isVisible ? null : (
                        <OverviewTableTh
                          book={plvPercent === 'loading'}
                          danger={isPlvPercentNegative}
                        >
                          {plvPercent}
                        </OverviewTableTh>
                      )}
                      {!brandSettings.overview.cogs.isVisible ? null : (
                        <OverviewTableTh
                          book={
                            displayPeriodCogs
                              ? periodCogsPercent === 'loading'
                              : cogsPercent === 'loading'
                          }
                        >
                          {displayPeriodCogs ? periodCogsPercent : cogsPercent}
                        </OverviewTableTh>
                      )}
                      {!brandSettings.overview.cogsBudgetVariance
                        .isVisible ? null : (
                        <OverviewTableTh
                          book={
                            displayPeriodCogs
                              ? periodCogsBudgetVariance === 'loading'
                              : cogsBudgetVariance === 'loading'
                          }
                          danger={
                            displayPeriodCogs
                              ? !isPeriodCogsBudgetVarianceNegative
                              : !isCogsBudgetVarianceNegative
                          }
                        >
                          {displayPeriodCogs
                            ? periodCogsBudgetVariance
                            : cogsBudgetVariance}
                        </OverviewTableTh>
                      )}
                      {!brandSettings.overview.rcp.isVisible ? null : (
                        <OverviewTableTh book={rcpPercent === 'loading'}>
                          {rcpPercent}
                        </OverviewTableTh>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {!brandSettings.overview.netSales.isVisible ? null : (
                        <td>Total Sales</td>
                      )}
                      {!brandSettings.overview.grossSales.isVisible ? null : (
                        <td>Total Sales</td>
                      )}
                      {!brandSettings.overview.netSalesSssPercent
                        .isVisible ? null : (
                        <OverviewTableTdWithTooltip>
                          {financialLabel.sss}
                          {config.tooltips?.sss ? (
                            <Tooltip
                              title={config.tooltips.sss}
                              customStyle={{
                                position: 'absolute',
                                top: '12px',
                              }}
                            />
                          ) : null}
                        </OverviewTableTdWithTooltip>
                      )}
                      {!brandSettings.overview.grossSalesSssPercent
                        .isVisible ? null : (
                        <OverviewTableTdWithTooltip>
                          {financialLabel.sss}
                          {config.tooltips?.sss ? (
                            <Tooltip
                              title={config.tooltips.sss}
                              customStyle={{
                                position: 'absolute',
                                top: '12px',
                              }}
                            />
                          ) : null}
                        </OverviewTableTdWithTooltip>
                      )}
                      {!brandSettings.overview.netSalesBudgetVariance
                        .isVisible ? null : (
                        <td>
                          {financialLabel.netSalesVariance ||
                            `Net Sales ${financialLabel.variance}`}
                        </td>
                      )}
                      {!brandSettings.overview.grossSales.isVisible ? null : (
                        <td>{`Gross Sales ${financialLabel.variance}`}</td>
                      )}
                      {!brandSettings.overview.checkCount.isVisible ? null : (
                        <OverviewTableTdWithTooltip>
                          {financialLabel.transaction}
                          {config.tooltips?.transaction ? (
                            <Tooltip
                              title={config.tooltips.transaction}
                              customStyle={{
                                position: 'absolute',
                                top: '12px',
                              }}
                            />
                          ) : null}
                        </OverviewTableTdWithTooltip>
                      )}
                      {!brandSettings.overview.checkCountVsLyPercent
                        .isVisible ? null : (
                        <OverviewTableTd>
                          {financialLabel.transactionVsLyPercent}
                        </OverviewTableTd>
                      )}
                      {!brandSettings.overview.checkAverage.isVisible ? null : (
                        <OverviewTableTdWithTooltip>
                          {financialLabel.transactionAvg}
                          {config.tooltips?.transactionAvg ? (
                            <Tooltip
                              title={config.tooltips.transactionAvg}
                              customStyle={{
                                position: 'absolute',
                                top: '12px',
                              }}
                            />
                          ) : null}
                        </OverviewTableTdWithTooltip>
                      )}
                      <td>{financialLabel.hourlyLabor} Percent</td>
                      {!brandSettings.overview.hourlyLaborBudgetVariance
                        .isVisible ? null : (
                        <OverviewTableTd>
                          {financialLabel.hourlyLaborVariance ||
                            `${financialLabel.hourlyLabor} ${financialLabel.variance}`}
                        </OverviewTableTd>
                      )}
                      {!brandSettings.overview.salesPerLaborHour
                        .isVisible ? null : (
                        <OverviewTableTd>
                          {financialLabel.salesPerLaborHour}
                        </OverviewTableTd>
                      )}
                      {!brandSettings.overview.sosAvgSeconds
                        .isVisible ? null : (
                        <OverviewTableTd>{financialLabel.sos}</OverviewTableTd>
                      )}
                      {!brandSettings.overview.avt.isVisible ? null : (
                        <OverviewTableTd>{financialLabel.avt}</OverviewTableTd>
                      )}
                      {!isAvtMonthlyVisible ? null : (
                        <OverviewTableTd>{financialLabel.avt}</OverviewTableTd>
                      )}
                      {!brandSettings.overview.plv.isVisible ? null : (
                        <OverviewTableTd>{financialLabel.plv}</OverviewTableTd>
                      )}
                      {!brandSettings.overview.cogs.isVisible ? null : (
                        <td>
                          <WeeklyKPIContainerWithTooltip>
                            {financialLabel.cogs}
                            {config.tooltips?.cogsKpi &&
                            selectedDateRange.type === TYPE_PERIOD ? (
                              <Tooltip
                                title={config.tooltips.cogsKpi}
                                customStyle={{
                                  position: 'absolute',
                                  top: '12px',
                                }}
                              />
                            ) : null}
                          </WeeklyKPIContainerWithTooltip>
                        </td>
                      )}
                      {!brandSettings.overview.cogsBudgetVariance
                        .isVisible ? null : (
                        <WeeklyKPIContainer>
                          {financialLabel.cogsVariance ||
                            `${financialLabel.cogs} ${financialLabel.variance}`}
                        </WeeklyKPIContainer>
                      )}
                      {!brandSettings.overview.rcp.isVisible ? null : (
                        <WeeklyKPIContainer>
                          {financialLabel.rcp}
                        </WeeklyKPIContainer>
                      )}
                    </tr>
                  </tbody>
                </PtdKpiTable>
              </td>
            </tr>
          </tbody>
        </PtdOverviewTable>
      </SystemOverviewContainer>
      {brandSettings.overview.breakdownByStore.isCustom ? (
        <CustomBreakdownTable
          isBreakdownByDirectors={isBreakdownByDirectors}
          locationGroupId={selectedDirector.locationGroupId}
        />
      ) : (
        <>
          <BreakdownHeader>
            <PageHeader>
              <BreakdownButton
                isClickable={isBackButtonVisible}
                onClick={isBackButtonVisible ? handleGoBack : () => {}}
              >
                {isBreakdownByDirectors
                  ? `Breakdown by ${getBusinessLabel('director')}`
                  : breakdownType !== null
                  ? `Breakdown by ${breakdownType}`
                  : `Breakdown by ${getBusinessLabel('store')}`}
              </BreakdownButton>
              {isBackButtonVisible ? (
                <div>{`/ ${selectedLocationGroup?.name}`}</div>
              ) : null}
            </PageHeader>
          </BreakdownHeader>
          <Stores
            isBreakdownByDirectors={isBreakdownByDirectors}
            isBreakdownByLocationGroupType={isBreakdownByLocationGroupType}
            locationGroupType={breakdownType}
            handleLocationGroupNameClick={handleLocationGroupNameClick}
            selectedLocationGroupIds={selectedLocationGroupIds}
            dataSource={
              isBreakdownByDirectors
                ? sortedDoBreakdownList || []
                : isBreakdownByLocationGroupType
                ? sortedLocationGroupBreakdownList || []
                : sortedStoreList
            }
            isAvtMonthlyVisible={isAvtMonthlyVisible}
            selectedDateRangeType={selectedDateRange.type}
          />
        </>
      )}
      <VerticalSpacer80px />

      {config.isMapVisible ? (
        <>
          <VerticalSpacer30px />
          <Map
            selectedDirector={selectedDirector}
            allDirectors={allDirectors}
            locationsWithCoordinates={locationsWithCoordinates}
          />
        </>
      ) : null}
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 1200px;
  padding: 0 0 50px 0;
`

const SelectorContainer = styled.div`
  float: right;
  display: inline-block;
`

const VerticalSpacer30px = styled.div`
  width: 100%;
  height: 30px;
`

const VerticalSpacer80px = styled.div`
  width: 100%;
  height: 80px;
`

const BreakdownHeader = styled.div`
  margin-top: 20px;
`

const LeftHeaderContainer = styled.div`
  float: left;
`

const NormalizationStatusDiv = styled.div`
  padding: 3px 0 0 3px;
`

const RightHeaderContainer = styled.div`
  float: right;
`

const SystemOverviewContainer = styled.div`
  padding-bottom: 40px;
`

const OverviewTableTd = styled.td`
  width: 150px;
`

const OverviewTableTdWithTooltip = styled(OverviewTableTd)`
  position: relative;
`

const OverviewTableTh = styled.th<IOpenPositionsTd>`
  color: ${(props) =>
    props.danger ? COLORS.Pomodoro : props.success ? COLORS.Expo : 'black'};
  font-family: ${(props) =>
    props.book ? 'Lexend-Regular' : 'Lexend-SemiBold'};
  font-size: ${(props) => (props.book ? '18px' : '30px')};
  font-weight: ${(props) => (props.book ? '400' : '700')};
`

const PtdOverviewTable = styled.table`
  width: 100%;
`

const PtdKpiTable = styled.table`
  width: 100%;
  text-align: center;

  thead th {
    font-style: normal;
    font-size: 24px;
    min-width: 120px;
  }

  tbody td {
    font-family: Lexend-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`

const PageHeaderContainer = styled.div`
  padding: 0 0 50px 0;
  margin: 0 0 50px 0;
`

const PageHeaderLineContainer = styled.div`
  display: inline-block;
  width: 1200px;
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0 0;
  display: flex;
  gap: 10px;
`

const BreakdownButton = styled.div<{ isClickable: boolean }>`
  ${({ isClickable }) =>
    isClickable
      ? `
      color: ${COLORS.Link};
      cursor: pointer;
      text-decoration: underline;
    `
      : ''}
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0 0;
  float: left;
`

const DirectorFilterSelectorContainer = styled.div`
  float: left;
  margin-right: 7px;
`

const PageStatusDiv = styled.div`
  padding: 30px 0 0 0;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const WeeklyKPIContainer = styled.td`
  height: 50px;
`

const WeeklyKPIContainerWithTooltip = styled.div`
  height: 50px;
  padding-top: 15px;
  position: relative;
`

export default WebLargeMain
