import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query arbListLocationPrimantiTattleCategoryRating(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationPrimantiTattle(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        avgScore
        negativeReviews
        totalReviews
        details
      }
    }
  }
`

export const arbListLocationPrimantiTattleCategoryRatingConfigs = {
  label: 'string',
  avgScore: 'number',
  incidentInvolvement: 'percent',
} as const

const ORDERS = [
  'Accuracy',
  'Food Quality',
  'Hospitality',
  'Meal Packaging',
  'Online Ordering',
  'Speed of Service',
  'Atmosphere',
  'Cleanliness',
  'Host/Hostess',
  'Menu Knowledge',
]

const useArbListLocationPrimantiTattleCategoryRating = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_ids: groupFilter?.ids },
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const details = data?.listLocationPrimantiTattle.nodes[0]?.details as
        | { label: string }[]
        | null
      const detailsObj = (details || []).reduce(
        (result, d) => ({
          ...result,
          [d.label]: d,
        }),
        {} as Record<string, NonNullable<typeof details>[number]>,
      )

      return {
        source: ORDERS.map((label) => ({
          ...detailsObj[label],
          label,
        })),
      }
    }, [data]),
    loading,
  }
}

export default useArbListLocationPrimantiTattleCategoryRating
