import * as d3 from 'd3-hierarchy'
import * as _ from 'lodash'
import { useMemo } from 'react'

import { IApiDataType, INestedDataType } from '../types'

type IDataTreeType = { id: 'root' } | INestedDataType

export interface IDataType {
  [key: string]: unknown
  subRows?: IDataType[]
}

const getData = ({
  data,
  children,
}: d3.HierarchyNode<IDataTreeType>): IDataType => ({
  ...data,
  subRows: children?.map(getData),
})

const useData = (data: IApiDataType) =>
  useMemo(() => {
    if (!data) return []

    const dataTree = d3.stratify<IDataTreeType>()(
      [{ id: 'root' } as const, ...data].flat(),
    )

    if (dataTree.children && dataTree.children.length === 1)
      return [
        ...(getData(dataTree.children[0]).subRows || []),
        dataTree.children[0].data as IDataType,
      ]

    return getData(dataTree).subRows || []
  }, [data])

export default useData
