import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { getLocationCode, getLocationName } from 'pared/utils/location'

import {
  LIST_LOCATION_GROUP_GUEST_OPENTABLE_KPIS,
  LIST_LOCATION_GUEST_OPENTABLE_KPIS,
} from '../gql'
import Main from './Main'

export interface IOrderBy {
  columnName: string
  isAscending: boolean
}

export interface IDetailGuestOpentableKpi {
  employeeId?: number
  locationId?: number
  ratingScore: string
  ratingScoreChange: string
  seatedCovers: string
  seatedCoversChange: string
  phoneCovers: string
  phoneCoversChange: string
  walkinCovers: string
  walkinCoversChange: string
  yourNetworkCovers: string
  yourNetworkCoversChange: string
  opentableCovers: string
  opentableCoversChange: string
  noShowRate: string
  noShowRateChange: string
  formattedLocationName?: string
}

interface IProps {
  isBreakdownByDirectors: boolean
  breakdownType: string | null
  startDate: string
  endDate: string
  locationGroupId: number
  locationGroupIds: number[]
}

//TODO: remove redundant Change code
function OpentableBreakdownTable({
  isBreakdownByDirectors,
  breakdownType,
  startDate,
  endDate,
  locationGroupId,
  locationGroupIds,
}: IProps) {
  const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false)

  const onToggleExpansion = () => {
    setIsTableExpanded(!isTableExpanded)
  }

  const [orderBy, setOrderBy] = useState<IOrderBy>({
    columnName: 'ratingScore',
    isAscending: false,
  })

  const [
    listLocationGuestOpentableKpis,
    {
      loading: listLocationGuestOpentableKpisLoading,
      data: listLocationGuestOpentableKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_GUEST_OPENTABLE_KPIS)

  const [
    listLocationGroupGuestOpentableKpis,
    {
      loading: listLocationGroupGuestOpentableKpisLoading,
      data: listLocationGroupGuestOpentableKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_GROUP_GUEST_OPENTABLE_KPIS)

  useEffect(() => {
    async function fetchData() {
      listLocationGuestOpentableKpis({
        variables: {
          iFilter: {
            location_group_ids: [locationGroupId],
          },
          iStartDate: startDate,
          iEndDate: endDate,
        },
      })

      listLocationGroupGuestOpentableKpis({
        variables: {
          iFilter: {
            location_group_ids: locationGroupIds,
          },
          iStartDate: startDate,
          iEndDate: endDate,
        },
      })
    }
    fetchData()
  }, [
    startDate,
    endDate,
    locationGroupId,
    isBreakdownByDirectors,
    breakdownType,
  ])

  let rankedItemizedKpis = []
  const columnName = _.get(orderBy, 'columnName', '')
  const ascending = orderBy.isAscending ? 'asc' : 'desc'

  const ascendingCoefficient = orderBy.isAscending ? 1 : -1

  let itemizedKpis: IDetailGuestOpentableKpi[] = []
  if (!isBreakdownByDirectors && !breakdownType) {
    // Breakdown by location
    if (
      Array.isArray(
        listLocationGuestOpentableKpisData?.listLocationGuestOpentableKpis
          ?.nodes,
      )
    ) {
      const rawGuestOpentableKpis =
        listLocationGuestOpentableKpisData?.listLocationGuestOpentableKpis
          ?.nodes

      itemizedKpis = rawGuestOpentableKpis.map((k: any) => {
        const locationId = _.get(k, 'locationId', 1)
        const locationCode = getLocationCode(
          locationId,
          _.get(k, 'locationInfo.code', ''),
        )
        const locationName = getLocationName(
          locationId,
          _.get(k, 'locationInfo.name', ''),
        )
        const ratingScore = _.get(k, 'ratingScore', '')
        const ratingChange = _.get(k, 'ratingScoreChange', '')
        const seatedCovers = _.get(k, 'seatedCovers')
        const seatedCoversChange = _.get(k, 'seatedCoversChange')
        const opentableCovers = _.get(k, 'opentableCovers')
        const opentableCoversChange = _.get(k, 'opentableCoversChange')
        const yourNetworkCovers = _.get(k, 'yourNetworkCovers')
        const yourNetworkCoversChange = _.get(k, 'yourNetworkCoversChange')
        const phoneCovers = _.get(k, 'phoneCovers')
        const phoneCoversChange = _.get(k, 'phoneCoversChange')
        const walkinCovers = _.get(k, 'walkinCovers')
        const walkinCoversChange = _.get(k, 'walkinCoversChange')
        const noShowRate = _.get(k, 'noShowRate')
        const noShowRateChange = _.get(k, 'noShowRateChange')

        const base = {
          locationId,
          ratingScore: ratingScore ? ratingScore.toFixed(1) : '-',
          ratingScoreChange: ratingChange ? `${ratingChange.toFixed(1)}%` : '-',
          seatedCovers: seatedCovers ? seatedCovers.toLocaleString() : '-',
          seatedCoversChange: seatedCoversChange
            ? `${seatedCoversChange.toFixed(1)}%`
            : '-',
          opentableCovers: opentableCovers
            ? opentableCovers.toLocaleString()
            : '-',
          opentableCoversChange: opentableCoversChange
            ? `${opentableCoversChange.toFixed(1)}%`
            : '-',
          yourNetworkCovers: yourNetworkCovers
            ? yourNetworkCovers.toLocaleString()
            : '-',
          yourNetworkCoversChange: yourNetworkCoversChange
            ? `${yourNetworkCoversChange.toFixed(1)}%`
            : '-',
          phoneCovers: phoneCovers ? phoneCovers.toLocaleString() : '-',
          phoneCoversChange: phoneCoversChange
            ? `${phoneCoversChange.toFixed(1)}%`
            : '-',
          walkinCovers: walkinCovers ? walkinCovers.toLocaleString() : '-',
          walkinCoversChange: walkinCoversChange
            ? `${walkinCoversChange.toFixed(1)}%`
            : '-',
          noShowRate: noShowRate ? `${noShowRate.toFixed(2)}%` : '-',
          noShowRateChange: noShowRateChange
            ? `${noShowRateChange.toFixed(1)}%`
            : '-',
        }

        return {
          ...base,
          formattedName: `${locationCode} - ${locationName}`,
        }
      })
    }
  } else {
    // breakdownByDirectors or location group type
    if (
      Array.isArray(
        listLocationGroupGuestOpentableKpisData
          ?.listLocationGroupGuestOpentableKpis?.nodes,
      )
    ) {
      const rawGuestOpentableKpis =
        listLocationGroupGuestOpentableKpisData
          ?.listLocationGroupGuestOpentableKpis?.nodes

      itemizedKpis = rawGuestOpentableKpis.map((k: any) => {
        const doFirstName = _.get(k, 'employeeInfo.firstName', '')
        const doLastName = _.get(k, 'employeeInfo.lastName', '')
        const doEmployeeId = _.get(k, 'employeeInfo.id')
        const locationGroupName = _.get(k, 'locationGroupName', '')
        const ratingScore = _.get(k, 'ratingScore', '')
        const ratingChange = _.get(k, 'ratingScoreChange', '')
        const seatedCovers = _.get(k, 'seatedCovers')
        const seatedCoversChange = _.get(k, 'seatedCoversChange')
        const opentableCovers = _.get(k, 'opentableCovers')
        const opentableCoversChange = _.get(k, 'opentableCoversChange')
        const yourNetworkCovers = _.get(k, 'yourNetworkCovers')
        const yourNetworkCoversChange = _.get(k, 'yourNetworkCoversChange')
        const phoneCovers = _.get(k, 'phoneCovers')
        const phoneCoversChange = _.get(k, 'phoneCoversChange')
        const walkinCovers = _.get(k, 'walkinCovers')
        const walkinCoversChange = _.get(k, 'walkinCoversChange')
        const noShowRate = _.get(k, 'noShowRate')
        const noShowRateChange = _.get(k, 'noShowRateChange')

        const base = {
          ratingScore: ratingScore ? ratingScore.toFixed(1) : '-',
          ratingScoreChange: ratingChange ? `${ratingChange.toFixed(1)}%` : '-',
          seatedCovers: seatedCovers ? seatedCovers.toLocaleString() : '-',
          seatedCoversChange: seatedCoversChange
            ? `${seatedCoversChange.toFixed(1)}%`
            : '-',
          opentableCovers: opentableCovers
            ? opentableCovers.toLocaleString()
            : '-',
          opentableCoversChange: opentableCoversChange
            ? `${opentableCoversChange.toFixed(1)}%`
            : '-',
          yourNetworkCovers: yourNetworkCovers
            ? yourNetworkCovers.toLocaleString()
            : '-',
          yourNetworkCoversChange: yourNetworkCoversChange
            ? `${yourNetworkCoversChange.toFixed(1)}%`
            : '-',
          phoneCovers: phoneCovers ? phoneCovers.toLocaleString() : '-',
          phoneCoversChange: phoneCoversChange
            ? `${phoneCoversChange.toFixed(1)}%`
            : '-',
          walkinCovers: walkinCovers ? walkinCovers.toLocaleString() : '-',
          walkinCoversChange: walkinCoversChange
            ? `${walkinCoversChange.toFixed(1)}%`
            : '-',
          noShowRate: noShowRate ? `${(noShowRate * 100).toFixed(2)}%` : '-',
          noShowRateChange: noShowRateChange
            ? `${noShowRateChange.toFixed(1)}%`
            : '-',
        }

        if (isBreakdownByDirectors && doEmployeeId) {
          return {
            ...base,
            employeeId: doEmployeeId,
            formattedName: `${doFirstName} ${doLastName}`,
          }
        }

        return {
          ...base,
          formattedName: locationGroupName,
        }
      })
    }
  }

  switch (columnName) {
    case 'ratingScore':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return parseFloat(_.get(k, 'ratingScore', '0')) * ascendingCoefficient
      })
      break
    case 'ratingScoreChange':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'ratingScoreChange', '0')) * ascendingCoefficient
        )
      })
      break
    case 'seatedCovers':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return parseFloat(_.get(k, 'seatedCovers', '0')) * ascendingCoefficient
      })
      break
    case 'seatedCoversChange':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'seatedCoversChange', '0')) * ascendingCoefficient
        )
      })
      break
    case 'opentableCovers':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'opentableCovers', '0')) * ascendingCoefficient
        )
      })
      break
    case 'opentableCoversChange':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'opentableCoversChange', '0')) *
          ascendingCoefficient
        )
      })
      break
    case 'yourNetworkCovers':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'yourNetworkCovers', '0')) * ascendingCoefficient
        )
      })
      break
    case 'yourNetworkCoversChange':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'yourNetworkCoversChange', '0')) *
          ascendingCoefficient
        )
      })
      break
    case 'phoneCovers':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return parseFloat(_.get(k, 'phoneCovers', '0')) * ascendingCoefficient
      })
      break
    case 'phoneCoversChange':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'phoneCoversChange', '0')) * ascendingCoefficient
        )
      })
      break
    case 'walkinCovers':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return parseFloat(_.get(k, 'walkinCovers', '0')) * ascendingCoefficient
      })
      break
    case 'walkinCoversChange':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'walkinCoversChange', '0')) * ascendingCoefficient
        )
      })
      break
    case 'noShowRate':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return parseFloat(_.get(k, 'noShowRate', '0')) * ascendingCoefficient
      })
      break
    case 'noShowRateChange':
      rankedItemizedKpis = _.sortBy(itemizedKpis, (k) => {
        return (
          parseFloat(_.get(k, 'noShowRateChange', '0')) * ascendingCoefficient
        )
      })
      break
    default:
      rankedItemizedKpis = _.orderBy(
        itemizedKpis,
        ['totalOrderCount'],
        [ascending],
      )
      break
  }

  return (
    <Main
      itemizedKpis={rankedItemizedKpis}
      isTableExpanded={isTableExpanded}
      onToggleExpansion={onToggleExpansion}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      isBreakdownByDirectors={isBreakdownByDirectors}
      breakdownType={breakdownType}
    />
  )
}

export default OpentableBreakdownTable
