import _ from 'lodash'
import { useMemo } from 'react'

import { toPercentString } from 'pared/utils/number'

import useLocationEmployeeUpsizeData from '../../../hooks/useLocationEmployeeUpsizeData'
import { addRankToTableData, getEmployeeInfo } from '../../../util'
import { ILeaderboardData } from '../../LeaderboardTable'

function useUpsizeLeaderboardData(
  upsizeType: string,
  locationId: number | string,
  startDate: string,
  endDate: string,
  dayPart: string,
) {
  const { isLoading, data, error } = useLocationEmployeeUpsizeData(
    locationId,
    startDate,
    endDate,
    dayPart,
  )

  return useMemo(() => {
    let leaderboardData: ILeaderboardData[] | null = null

    if (data) {
      let storeAvg = 0

      leaderboardData = data.map((rawData: any) => {
        const salesTypeData = rawData?.upsizeDetails?.[upsizeType]
        const value = salesTypeData?.upsizePercent || 0
        if (salesTypeData?.upsizePercentStoreAvg) {
          storeAvg = salesTypeData.upsizePercentStoreAvg
        }

        return {
          ...getEmployeeInfo(rawData),
          value,
          displayString: toPercentString(value, 1),
        }
      })

      leaderboardData.push({
        employeeId: '',
        value: storeAvg,
        employeeName: 'Store Average',
        displayString: toPercentString(storeAvg, 1),
        isStoreAvg: true,
      })

      leaderboardData = _.orderBy(
        leaderboardData,
        ['value', 'employeeName'],
        ['desc', 'asc'],
      )
      leaderboardData = addRankToTableData(leaderboardData)
    }

    return {
      isLoading,
      leaderboardData,
      error,
    }
  }, [
    isLoading,
    data,
    error,
    upsizeType,
    locationId,
    startDate,
    endDate,
    dayPart,
  ])
}

export default useUpsizeLeaderboardData
