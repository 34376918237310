import { ILossPreventionConfig } from '../useLossPreventionConfig'

export const acePopConfig: ILossPreventionConfig = {
  summary: {
    isVisible: true,
  },
  kpiSettings: [
    { category: 'basic', key: 'totalComps', title: 'Total Discounts' },
    { category: 'basic', key: 'cashOverOrShort', title: 'Cash +/-' },
    {
      category: 'custom',
      type: 'Over Ring',
      title: '% Checks with Over Ring',
      key: 'checkPercent',
    },
    {
      category: 'custom',
      type: 'Deal after Total',
      title: '% Checks with Deletes',
      key: 'checkPercent',
    },
    {
      category: 'basic',
      key: 'inaccurateCashierShiftPercent',
      title: '% Shifts with Inaccurate Cash',
    },
  ],
  cashHandlingTable: {
    display: true,
    columns: [
      {
        key: 'cashOverOrShort.amount',
        title: 'Cash +/-',
        valueType: 'price',
        width: '150px',
      },
      {
        key: 'Over Ring.amount',
        title: 'Over Ring $',
        valueType: 'price',
        width: '150px',
        sorter: true,
      },
      {
        key: 'Deal after Total.amount',
        title: 'Deletes $',
        valueType: 'price',
        width: '150px',
        sorter: true,
      },
      {
        key: 'cashOverOrShort.checkPercent',
        title: '% Shifts with Inaccurate Cash',
        valueType: 'percent',
        width: '150px',
        sorter: true,
      },
    ],
  },
  voidsAndDeletesTable: {
    display: false,
    columns: [],
  },
  discountByEmployeeTable: {
    display: true,
  },
  opsCompsBreakdownTable: {
    display: true,
  },
}
