import jwt_decode from 'jwt-decode'
import _ from 'lodash'

import {
  identifyUserForAnalytics,
  resetUserForAnalytics,
  track,
} from 'pared/analytics/user'
import getUserByEmail, {
  IUser,
  USER_INFO_VERSION,
} from 'pared/data/getUserByEmail'
import { decodedUserJwtToken, userProfile } from 'pared/reactiveVariables/user'

interface IUserWithJwt extends IUser {
  jwt: string
}

let urlBeforeSignOut: string = ''

function setUrlBeforeSignOut(url: string) {
  urlBeforeSignOut = url
}

export function getUrlBeforeSignOut() {
  return urlBeforeSignOut
}

export function resetUrlBeforeSignOut() {
  urlBeforeSignOut = ''
}

export async function signInUser(jwtToken: string, email: string) {
  const user = await getUserByEmail(email)
  if (user.isDeactivated) {
    throw 'User has been deactivated'
  }

  if (user && user.userId) {
    await identifyUserForAnalytics(user)
    await track.signIn()
    localStorage.setItem('userJwtToken', jwtToken)
    localStorage.setItem('user', JSON.stringify(user))
    userProfile(user)
    decodedUserJwtToken(jwt_decode(jwtToken))
  }
}

export async function signOutUser() {
  setUrlBeforeSignOut(window.location.href)
  if (window.location.href.indexOf('unauthorized') >= 0) {
    resetUrlBeforeSignOut()
  }

  if (!decodedUserJwtToken() || !_.isEqual(userProfile(), {})) {
    decodedUserJwtToken(null)
    userProfile({})
    localStorage.setItem('userJwtToken', '')
    localStorage.setItem('user', '')

    await track.signOut()
    await resetUserForAnalytics()
  }
}

export function getUserPersistedJwtToken() {
  const jwtToken = localStorage.getItem('userJwtToken')
  return jwtToken
}

export function getUser() {
  const user: IUserWithJwt = JSON.parse(localStorage.getItem('user') || '{}')
  user.jwt = localStorage.getItem('userJwtToken') || ''
  return user
}

export async function loadUserPersistedData() {
  const jwtToken = getUserPersistedJwtToken()
  if (jwtToken) {
    decodedUserJwtToken(jwt_decode(jwtToken))
  } else {
    decodedUserJwtToken(null)
  }

  const user = getUser()

  // make sure the user has userId and has the correct version, otherwise sign the user out
  if (user && user.userId && user.version === USER_INFO_VERSION) {
    userProfile(user)
    await identifyUserForAnalytics(user)
  } else {
    await signOutUser()
    resetUrlBeforeSignOut()
  }
}

export function hasPrivilegeToViewEmployeeFeedback() {
  const user = getUser()
  const decodedJwt = decodedUserJwtToken()

  if (
    decodedJwt &&
    (decodedJwt.role === 'expo_user' || decodedJwt.role === 'postgres') &&
    user.isStoreLevelUser === false
  ) {
    return true
  }
  return false
}
