import { v4 as uuidv4 } from 'uuid'

import Table from 'pared/pages/ExpoAi/Table'
import { FROM_EXPO } from 'pared/pages/ExpoAi/constants'
import { IMessage } from 'pared/pages/ExpoAi/type'

const handleForecastedResults = (responseData: any) => {
  if (responseData?.forecastedNetSales) {
    const answerMessage: IMessage = {
      id: uuidv4(),
      from: FROM_EXPO,
      content: <Table data={responseData.forecastedNetSales} />,
    }

    return answerMessage
  }

  return null
}

export default handleForecastedResults
