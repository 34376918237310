import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'

interface IProps {
  xAxisData: string[]
  yAxisLocationData: number[]
  yAxisSystemwideData: number[]
  locationName: string
  isLoading: boolean
}

function Main({
  xAxisData,
  yAxisLocationData,
  yAxisSystemwideData,
  locationName,
  isLoading,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          xAxisData={xAxisData}
          yAxisLocationData={yAxisLocationData}
          yAxisSystemwideData={yAxisSystemwideData}
          locationName={locationName}
          isLoading={isLoading}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          xAxisData={xAxisData}
          yAxisLocationData={yAxisLocationData}
          yAxisSystemwideData={yAxisSystemwideData}
          locationName={locationName}
          isLoading={isLoading}
        />
      </SmallScreen>
    </>
  )
}

export default Main
