import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import { IGuestReview } from '../index'
import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import { IOrderBy } from './index'

interface IProps {
  isReviewsExpanded: boolean
  onToggleExpandReviews: () => void
  guestReviews: IGuestReview[]
  orderBy: IOrderBy
  setOrderBy: any
  isLoading: boolean
}

function Main({
  isReviewsExpanded,
  onToggleExpandReviews,
  guestReviews,
  orderBy,
  setOrderBy,
  isLoading,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          isReviewsExpanded={isReviewsExpanded}
          onToggleExpandReviews={onToggleExpandReviews}
          guestReviews={guestReviews}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          isLoading={isLoading}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          isReviewsExpanded={isReviewsExpanded}
          onToggleExpandReviews={onToggleExpandReviews}
          guestReviews={guestReviews}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          isLoading={isLoading}
        />
      </SmallScreen>
    </>
  )
}

export default Main
