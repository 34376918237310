import styled from 'styled-components'

import { IVoidsDetails } from './hooks/useCash'

function VoidsDetailsRender(details: IVoidsDetails[]) {
  if (!details || details.length <= 0) {
    return '-'
  }

  const voidsList = details.map((voidsItem) => {
    return (
      <div key={JSON.stringify(voidsItem)}>
        {voidsItem.timeStr} [Check #: {voidsItem.checkNumber}] $
        {voidsItem.amount}
      </div>
    )
  })

  return <Container>{voidsList}</Container>
}

const Container = styled.div`
  text-align: left;
  verflow: hidden;
  white-space: nowrap;
`

export default VoidsDetailsRender
