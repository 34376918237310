import React from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import { feature, page } from 'pared/analytics/user'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import {
  TYPE_CUSTOM,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import useGetDirectors from 'pared/hooks/useGetDirectors'
import DirectorFilterSelector from 'pared/pages/StoreList/DirectorFilterSelector'
import { scrollToTop } from 'pared/utils/web'

import CampaignTable from './CampaignTable'
import DiscountTable from './DiscountTable'
import WeeklyChart from './WeeklyChart'

interface IPropsType {
  navParams: INavParams
}

const CorporateMarketAnalysis = ({ navParams }: IPropsType) => {
  const dateRange = useGetDateRange({ isCustomAllowed: true })
  const startDate = dateRange?.startDateStr
  const endDate = dateRange?.endDateStr

  const {
    director,
    directors,
    directorChange,
    locationGroupIds,
    locationGroupTypes,
  } = useGetDirectors()
  const isBreakdownByDirectors = director?.employeeId <= -2

  return (
    <>
      <Header>
        <div>
          <h1>Marketing Analysis</h1>
        </div>

        <Selectors>
          <DirectorFilterSelector
            selectedDirector={director}
            allDirectors={directors}
            onDirectorChange={directorChange}
          />

          <DateRangeSelector
            navParams={navParams}
            dateRangeOptions={[
              TYPE_YEAR,
              TYPE_QUARTER,
              TYPE_PERIOD,
              TYPE_WEEK,
              TYPE_LAST_WEEK,
              TYPE_THIS_WEEK,
              TYPE_YESTERDAY,
              TYPE_TRAILING_7_DAYS,
              TYPE_CUSTOM,
            ]}
          />
        </Selectors>
      </Header>

      <Container>
        <DiscountTable
          startDate={startDate}
          endDate={endDate}
          locationGroupIds={locationGroupIds}
          isBreakdownByDirectors={isBreakdownByDirectors}
          locationGroupType={locationGroupTypes?.[0]}
        />
      </Container>

      <VerticalSpacer60px />

      <Container>
        <WeeklyChart
          startDate={startDate}
          endDate={endDate}
          locationGroupIds={locationGroupIds}
        />
      </Container>

      <VerticalSpacer60px />

      <Container>
        <CampaignTable
          startDate={startDate}
          endDate={endDate}
          locationGroupIds={locationGroupIds}
        />
      </Container>
    </>
  )
}

export default React.memo(CorporateMarketAnalysis)

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 25px 0px;
    }
  }
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const Container = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px;
  }
`

const VerticalSpacer60px = styled.div`
  width: 100%;
  height: 60px;
`
