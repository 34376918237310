export const getNumber = (value: number | string) =>
  typeof value === 'string' ? parseInt(value, 10) : value

const formatPriceWithCent = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencySign: 'accounting',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const formatPriceRoundToDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  currencySign: 'accounting',
  maximumFractionDigits: 0,
})

export const getCount = (value: number | string) => {
  if (value === null) {
    return '-'
  }

  if (typeof value === 'string') {
    const count = parseInt(value, 10)
    if (isNaN(count)) {
      return '-'
    } else {
      return count.toLocaleString('en-US')
    }
  } else {
    return value.toLocaleString('en-US')
  }
}

export const getPrice = (value: number | string) => {
  const dollarValue = getNumber(value) / 100
  if (isNaN(dollarValue)) {
    return '-'
  }
  if (Math.abs(dollarValue) < 100.0) {
    return formatPriceWithCent.format(dollarValue)
  } else {
    return formatPriceRoundToDollar.format(dollarValue)
  }
}

export const getTotalPrice = (value: number | string) => getPrice(value)

export const numberPlus = (
  [aValue, ...a]: string[],
  [bValue, ...b]: string[],
  extra = 0,
): string[] => {
  if (!aValue && !bValue) {
    if (extra !== 0) return [extra.toString()]

    return []
  }

  const result =
    parseInt(aValue || '0', 10) + parseInt(bValue || '0', 10) + extra
  const value = result % 10

  return [value.toString(), ...numberPlus(a, b, (result - value) / 10)]
}

// export const plus = (a: string, b: string) =>
//   numberPlus(a.split('').reverse(), b.split('').reverse()).reverse().join('')

export const plus = (strA: string, strB: string) => {
  const numA = parseFloat(strA)
  const numB = parseFloat(strB)

  if (isNaN(numA) || isNaN(numB)) {
    return 0
  } else {
    return numA + numB
  }
}

export const getFloat = (value: number | string) =>
  typeof value === 'string' ? parseFloat(value) || 0 : value

export function getPercent(value: number | string | undefined) {
  if (value === undefined) {
    return ''
  }
  const num = getFloat(value)
  if (num < 0) {
    return `(${Math.abs(num).toFixed(2)}%)`
  } else if (num > 0) {
    return `${num.toFixed(2)}%`
  }
  return '-'
}
