import { INavParams } from 'pared/Routes/navParams'
import { ILocation } from 'pared/data/getLocations'

import WebLargeMain from './WebLargeMain'
import { ILocationTeamData } from './index'

interface IProps {
  navParams: INavParams
  shouldShowFeedback: boolean
  locationTeamData: ILocationTeamData | null
  errorMessage: string
  isCommentPopoverOpen: boolean
  setIsCommentPopoverOpen: any
  allLocations: ILocation[] | null
  onRefresh: () => Promise<void>
  isTurnoverChartLoading: boolean
}

function Main({
  navParams,
  shouldShowFeedback,
  locationTeamData,
  errorMessage,
  isCommentPopoverOpen,
  setIsCommentPopoverOpen,
  allLocations,
  onRefresh,
  isTurnoverChartLoading,
}: IProps) {
  return (
    <WebLargeMain
      navParams={navParams}
      shouldShowFeedback={shouldShowFeedback}
      locationTeamData={locationTeamData}
      errorMessage={errorMessage}
      isCommentPopoverOpen={isCommentPopoverOpen}
      setIsCommentPopoverOpen={setIsCommentPopoverOpen}
      allLocations={allLocations}
      isTurnoverChartLoading={isTurnoverChartLoading}
      onRefresh={onRefresh}
    />
  )
}

export default Main
