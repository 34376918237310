import { Moment } from 'moment'
import Calendar from 'rc-calendar'
import 'rc-calendar/assets/index.css'
import DatePicker from 'rc-calendar/lib/Picker'
import React from 'react'
import styled from 'styled-components'

import { DATE_FORMAT } from 'pared/data/getDateRanges'

interface IProps {
  onChange: (value: Moment) => void
  value: Moment
  disabledDate: (value: Moment | undefined) => boolean
}

const StyledInput = styled.input`
  max-width: 100px;
  font-family: Lexend-Regular;
  font-size: 14px;
  cursor: pointer;
`

const Picker = ({ onChange, value, disabledDate }: IProps) => {
  const calendar = <Calendar disabledDate={disabledDate} />

  return (
    <DatePicker
      animation="slide-up"
      calendar={calendar}
      value={value}
      onChange={onChange}
    >
      {({ value }: any) => {
        return (
          <span>
            <StyledInput
              placeholder="Please select a date"
              readOnly
              value={(value && value.format(DATE_FORMAT)) || ''}
            />
          </span>
        )
      }}
    </DatePicker>
  )
}

export default React.memo(Picker)
