import Main from './Main'

interface IProps {
  dayTrendsInfo: any[]
  dayTrendsCategory: string
  isOrdersHidden: boolean
}

function DayTrends({
  dayTrendsInfo,
  dayTrendsCategory,
  isOrdersHidden,
}: IProps) {
  return (
    <Main
      dayTrendsInfo={dayTrendsInfo}
      dayTrendsCategory={dayTrendsCategory}
      isOrdersHidden={isOrdersHidden}
    />
  )
}

export default DayTrends
