import { addDays, isSameDay, startOfWeek } from 'date-fns'
import { useMemo } from 'react'

export interface IDataSourceType {
  date: Date
  text?: string
  background?: string
}

export default (dataSource: IDataSourceType[]): IDataSourceType[] =>
  useMemo(() => {
    const today = new Date()
    const startDate = startOfWeek(today)

    return [].constructor
      .apply({}, new Array(21))
      .map((_: unknown, index: number) => {
        const date = addDays(startDate, index)
        const data = dataSource.find((d) => isSameDay(d.date, date))

        if (!data && isSameDay(date, today))
          return {
            date,
            text: 'TODAY',
          }

        return {
          ...data,
          date,
        }
      })
  }, [dataSource])
