import styled from 'styled-components'

import Format, { IPropsType as IFormatPropsType } from './Format'
import useApi, { IApiKeyType, configs } from './hooks/useApi'

type IFieldType<
  V extends Record<string, Pick<IFormatPropsType, 'type'>['type']>,
> = {
  [K in keyof V]: Omit<
    Extract<
      IFormatPropsType,
      {
        type: V[K]
      }
    >,
    'value' | 'loading' | 'type'
  > & {
    key: K
    title: string
  }
}

type IFieldsType = {
  [K in keyof typeof configs]: IFieldType<typeof configs[K]>
}

export interface IPropsType<K extends IApiKeyType = IApiKeyType> {
  type: 'list'
  api: K
  width?: string
  fields: IFieldsType[K][keyof IFieldsType[K]][]
}

export type IConfigsType = {
  [K in IApiKeyType]: IPropsType<K>
}[IApiKeyType]

const Container = styled.table`
  margin: 20px 0px 0px;
  text-align: center;

  thead th {
    font-style: normal;
    font-size: 24px;
  }

  tbody td {
    font-family: Lexend-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`

const List = ({ api, width, fields }: IPropsType) => {
  const { data, loading } = useApi(api)

  return (
    <Container>
      <thead>
        <tr>
          {(fields as IConfigsType['fields']).map(({ key, ...config }) => (
            <Format
              {...config}
              key={key}
              type={configs[api][key as keyof typeof data]}
              value={data?.[key] ?? '-'}
              loading={loading}
              width={config.width ?? width}
            />
          ))}
        </tr>
      </thead>

      <tbody>
        <tr>
          {fields.map(({ key, title }) => (
            <td key={key}>{title}</td>
          ))}
        </tr>
      </tbody>
    </Container>
  )
}

export default List
