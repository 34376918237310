import { useMemo, useState } from 'react'

import {
  LAST_8_WEEKS,
  LAST_13_PERIODS,
  LAST_14_DAYS,
} from 'pared/components/DateRangeNumberSelector/hooks/useDateRange'

export interface IDataType {
  lastTimeRange?: {
    options: {
      id: string
      parentId: string
      displayName: string
    }[]
    value: string[][]
  }
}

const useLastTimeRange = (): IDataType => {
  const lastTimeRangeHook = useState([[LAST_14_DAYS.value]])

  return {
    lastTimeRange: useMemo(() => {
      const options = [LAST_14_DAYS, LAST_8_WEEKS, LAST_13_PERIODS].map(
        (o) => ({
          id: o.value,
          parentId: 'root',
          displayName: o.title,
        }),
      )

      return {
        value: lastTimeRangeHook[0],
        options: options,
        onChange: lastTimeRangeHook[1],
      }
    }, [lastTimeRangeHook]),
  }
}

export default useLastTimeRange
