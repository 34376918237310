import _ from 'lodash'
import { forwardRef } from 'react'
import styled from 'styled-components'

import {
  DAYPART_DISPLAY_NAME,
  RANKINGS_ROW_TYPE,
  STORE_AVG_TEXT,
} from 'pared/constants'
import { BRAND_LOGOS, BRAND_NAME } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import ExpoLogo from 'pared/images/Expo/expo-logo-blue.png'
import DownArrow from 'pared/images/basic/arrow/caret-down.svg'
import UpArrow from 'pared/images/basic/arrow/caret-up.svg'
import { getBrand } from 'pared/utils/brand'

import { getSaleTypeDisplayNames } from '../../constants'
import { IOrderBy, ISalesmanship } from '../../index'

interface IRankTableTh {
  textAlign?: string
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
  isAvg?: boolean
}

interface IProps {
  rankedData: ISalesmanship[]
  storeTitle: string
  selectedValue: string
  tableTitle: string
  dataPointColumnName: string
  orderBy?: IOrderBy
  salesType?: string
  dayPart: string
}

interface IRankTablTh {
  textAlign?: string
}

export const StaffTable = forwardRef(
  (
    {
      rankedData,
      storeTitle,
      selectedValue,
      orderBy,
      salesType,
      dayPart,
    }: IProps,
    ref,
  ) => {
    const brand = getBrand()
    const saleTypeDisplayNames = getSaleTypeDisplayNames()
    const brandSettings = getBrandSettings()
    const salesmanshipLabels = brandSettings.labels.salesmanship

    let orderStatus = null
    if (orderBy.isAscending) {
      orderStatus = <UpArrowImg src={UpArrow} alt="ASC"></UpArrowImg>
    } else {
      orderStatus = <DownArrowImg src={DownArrow} alt="DESC"></DownArrowImg>
    }

    const staffSalesRows = _.map(rankedData, (d) => {
      const rank = _.get(d, 'rank', '')
      const name = _.get(d, 'name', '')
      const roleName = _.get(d, 'roleName', '')
      const daysWorked = _.get(d, 'daysWorked', '')
      const salesOrPpa = _.get(d, 'salesOrPpa', '')
      const rowType = _.get(d, 'rowType', '')

      return rowType === RANKINGS_ROW_TYPE.AVG ? (
        <AvgTr>
          <RankNumberTd textAlign={'left'} bold></RankNumberTd>
          <RankTableTd textAlign={'left'} isAvg>
            {STORE_AVG_TEXT}
          </RankTableTd>
          <RankTableTd textAlign={'left'} isAvg>
            {roleName}
          </RankTableTd>
          <RankTableTd isAvg>{salesOrPpa}</RankTableTd>
          <RankTableTd isAvg>{daysWorked}</RankTableTd>
        </AvgTr>
      ) : (
        <tr>
          <RankNumberTd textAlign={'left'} bold>
            {rank}
          </RankNumberTd>
          <RankTableTd textAlign={'left'}>{name}</RankTableTd>
          <RankTableTd textAlign={'left'}>{roleName}</RankTableTd>
          <RankTableTd>{salesOrPpa}</RankTableTd>
          <RankTableTd>{daysWorked}</RankTableTd>
        </tr>
      )
    })

    const rankByDisplayName = saleTypeDisplayNames[salesType || '']
    const dayPartDisplayName = DAYPART_DISPLAY_NAME[dayPart]

    const logo = (
      <LogoImg
        src={BRAND_LOGOS[brand].dark}
        alt={`${BRAND_NAME[brand]} Logo`}
      />
    )

    return (
      <div ref={ref} class="printContainer">
        <MainContainer>
          <table>
            <tbody>
              <tr>
                <TitleLeftTd>{logo}</TitleLeftTd>
                <td>
                  <PoweredBy>powered by</PoweredBy>
                  <div>
                    <ExpoLogoImg src={ExpoLogo} alt="Expo" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <PageLeftHeader>{storeTitle}</PageLeftHeader>
          <SubtitleContainer>{`${selectedValue} ${dayPartDisplayName}`}</SubtitleContainer>
          <BottomContainer>
            <table>
              <tbody>
                <tr>
                  <td>
                    <TableDescription>
                      {salesmanshipLabels['staffSales']}
                    </TableDescription>
                  </td>
                  <td>
                    <DescriptionContainer>
                      {`Rank by ${rankByDisplayName}`}
                    </DescriptionContainer>
                  </td>
                </tr>
              </tbody>
            </table>
            <RankTable>
              <thead>
                <tr>
                  <RankTableTh textAlign={'left'}>Rank</RankTableTh>
                  <RankTableTh textAlign={'left'}>Name</RankTableTh>
                  <RankTableSortableTh textAlign={'left'}>
                    Position
                    {orderBy.columnName === 'roleName' ? orderStatus : null}
                  </RankTableSortableTh>
                  <RankTableSortableTh textAlign={'left'}>
                    {rankByDisplayName}
                    {orderBy.columnName === '' || orderBy.columnName === 'sales'
                      ? orderStatus
                      : null}
                  </RankTableSortableTh>
                  <RankTableSortableTh textAlign={'left'}>
                    Shifts
                    {orderBy.columnName === 'daysWorked' ? orderStatus : null}
                  </RankTableSortableTh>
                </tr>
              </thead>
              <tbody>{staffSalesRows}</tbody>
            </RankTable>
          </BottomContainer>
        </MainContainer>
      </div>
    )
  },
)

const MainContainer = styled.div`
  padding-top: 20px;
  padding-left: 50px;
`

const AvgTr = styled.tr`
  background-color: ${COLORS.Sky} !important;
`

const TitleLeftTd = styled.td`
  width: 590px;
`

const LogoImg = styled.img`
  height: 50px;
  margin-bottom: 10px;
`

const SullivansLogoImg = styled.img`
  height: 100px;
`

const PoweredBy = styled.div`
  padding-bottom: 10px;
  font-family: Lexend-SemiBold;
  font-size: 12px;
  text-align: center;
`

const UpArrowImg = styled.img`
  width: 10px;
  margin: 0 0 2px 5px;
`

const DownArrowImg = styled.img`
  width: 10px;
  margin: 0 0 -1px 5px;
`

const RankTableSortableTh = styled.th<IRankTablTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  color: ${COLORS.Plum};
  cursor: pointer;
`

const DescriptionContainer = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  float: left;
  margin-top: 22px;
  margin-left: 10px;
`

const BottomContainer = styled.div`
  width: 650px;
`

const ExpoLogoImg = styled.img`
  width: 131px;
`

const TableDescription = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 10px;
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  padding: 10px 20px 0 0;
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  margin-top: 10px;
`

const RankTableTh = styled.th<IRankTableTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankTableTd = styled.td<IRankTableTd>`
  color: ${(props) => (props.isAvg ? 'white' : 'black')};
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankNumberTd = styled(RankTableTd)`
  padding-left: 12px;
`

const RankTable = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;
  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }
  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }
  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }
  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }
  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 9px;
    padding-bottom: 9px;
  }
  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`
