import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import {
  IReportResult,
  useFetchCustomizedReportData,
} from 'pared/components/CustomizedReport'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import { toUsdStr, toUsdString } from 'pared/utils/number'

import { IExrayDataType } from '../../types'

interface IDailyInventoryAnalysisType {
  listLocationDailyInventoryAnalysis: {
    nodes: {
      locationId: number
      date: string
      aggregatableData: {
        item: string
        counted: boolean
        ending_qty: number
      }[]
    }[]
  }
}

const query = gql`
  query ListLocationDailyInventoryAnalysis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationDailyInventoryAnalysis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        date
        aggregatableData
      }
    }
  }
`

const getFoodCostSummaryData = (
  locationId: number,
  locationName: string,
  data: IDailyInventoryAnalysisType,
  reportResult?: IReportResult,
): IExrayDataType => {
  const notCompletedDays =
    data?.listLocationDailyInventoryAnalysis.nodes
      .filter(
        (data) =>
          data.aggregatableData.length === 0 ||
          data.aggregatableData.find((aggData) => aggData.ending_qty === 0),
      )
      .map((d) => `${moment(d.date).format('dddd')} (${d.date})`) || []

  const notCompletedDaysStr = (() => {
    const length = notCompletedDays.length
    if (length === 0) {
      return null
    } else if (length === 1) {
      return notCompletedDays[0]
    } else if (length === 2) {
      return notCompletedDays.join(' and ')
    } else {
      const lastItem = notCompletedDays[length - 1]
      const otherItems = notCompletedDays.slice(0, length - 1)
      return `${otherItems.join(', ')}, and ${lastItem}`
    }
  })()

  const inventoryAnalysis = !notCompletedDaysStr
    ? ''
    : `In the past week, ${locationName} did not complete <a href="./inventory?store=${locationId}#daily-inventory-analysis">Daily Inventory</a> on ${notCompletedDaysStr}. <br/><br/>`

  const tableData = [...(reportResult?.tableData || [])]
    .filter(({ difference }) => difference > 0)
    .sort((a, b) => b.difference - a.difference)

  const totalAnnualOpportunityCost = tableData.reduce(
    (sum, { annualOpportunityCost }) => sum + annualOpportunityCost,
    0,
  )

  const topItem = tableData.slice(0, 1)

  const topItemName = topItem.map(({ item }, index) =>
    index === tableData.length - 1 && index > 0 ? `and ${item}` : item,
  )

  const topItemOpportunity = toUsdString(
    Math.round(topItem.reduce((sum, { difference }) => sum + difference, 0)),
  )

  const detail =
    topItemName.length === 0
      ? `${locationName} is purchasing items as expected for its sales. Keep up the good work!`
      : `${locationName}'s biggest opportunities to improve COGS% is ${topItemName}.
  These are being purchased and used in higher quantities than restaurants with similar sales and represent a ${topItemOpportunity} opportunity.
  Put in measures to reduce ${topItemName} waste. Annual opportunity cost for all items: ${toUsdStr(
          totalAnnualOpportunityCost,
        )}
  `

  return {
    title: 'Food Cost',
    total: totalAnnualOpportunityCost,
    detail: inventoryAnalysis + detail,
  }
}

const useGhaiPopFoodCost = () => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const sevenDaysBefore = moment().subtract(8, 'days').format(DATE_FORMAT)
  const yesterday = moment().subtract(1, 'days').format(DATE_FORMAT)
  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'
  const { data, loading } = useQuery<IDailyInventoryAnalysisType>(query, {
    variables: {
      iStartDate: sevenDaysBefore,
      iEndDate: yesterday,
      iFilter: { location_ids: [locationId] },
    },
    skip: !groupFilter,
  })
  const { reportResult, isLoading: foodCostLoading } =
    useFetchCustomizedReportData(
      'LOCATION_PURCHASE_TABLE_V2',
      !groupFilter
        ? null
        : {
            locationId: locationId,
            startDate: startDate,
            endDate: endDate,
          },
    )

  return {
    data: useMemo(() => {
      if (!data) return null

      return getFoodCostSummaryData(
        locationId,
        locationName,
        data,
        reportResult,
      )
    }, [locationId, locationName, data, reportResult]),
    loading: loading || foodCostLoading,
  }
}

export default useGhaiPopFoodCost
