import { useMemo } from 'react'

import { IColumnsType } from '../types'

const mergeHead = <Data>(
  columns: IColumnsType<Data>[],
): IColumnsType<Data>[][] => {
  const childrenColumns = columns.reduce(
    (result, { children }) => (!children ? result : [...result, ...children]),
    [] as IColumnsType<Data>[],
  )

  return childrenColumns.length === 0
    ? [columns]
    : [columns, ...mergeHead(childrenColumns)]
}

const useMergedHead = <Data>(columns: IColumnsType<Data>[]) =>
  useMemo(() => mergeHead(columns), [columns])

export default useMergedHead
