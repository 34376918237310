import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import { IManagerReview } from '../index'
import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'

interface IProps {
  isTableExpanded: boolean
  onToggleTable: () => void
  managerReviews: IManagerReview[]
  isListManagerReviewloading: boolean
}

function Main({
  isTableExpanded,
  onToggleTable,
  managerReviews,
  isListManagerReviewloading,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          isTableExpanded={isTableExpanded}
          onToggleTable={onToggleTable}
          managerReviews={managerReviews}
          isListManagerReviewloading={isListManagerReviewloading}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          isTableExpanded={isTableExpanded}
          onToggleTable={onToggleTable}
          managerReviews={managerReviews}
          isListManagerReviewloading={isListManagerReviewloading}
        />
      </SmallScreen>
    </>
  )
}

export default Main
