import { useMemo } from 'react'

import { IColumnsType } from 'pared/components/basicUi/table'

import { getCount } from '../../utils'
import { IDataSourceType } from './useDataSource'

const useColumns = (): IColumnsType<IDataSourceType>[] => {
  return useMemo(
    () =>
      [
        {
          key: 'itemName',
          title: 'Item Name',
          minWidth: '150px',
          align: 'center',
        },
        {
          key: 'totalCount',
          title: 'Missing #',
          minWidth: '75px',
          align: 'center',
          render: getCount,
        },
      ] as IColumnsType<IDataSourceType>[],
    [],
  )
}

export default useColumns
