import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { LIST_LOCATION_GROUP_DISCOUNT_CAMPAIGN } from '../gql'

export interface IDiscountCampaignType {
  itemType: string
  totalItemCounts: number
  totalItemDiscounts: number
  checkAvg: number
  ppa: number
}

const useDiscountCampaign = (
  locationGroupIds: number[],
  startDate?: string,
  endDate?: string,
) => {
  const iFilter = { location_group_ids: [...locationGroupIds] }

  const { data, loading: discountCampaignLoading } = useQuery(
    LIST_LOCATION_GROUP_DISCOUNT_CAMPAIGN,
    {
      variables: {
        iFilter,
        iStartDate: startDate,
        iEndDate: endDate,
      },
      skip: !startDate || !endDate || !iFilter,
    },
  )

  const rawData = data?.listLocationGroupDiscountCampaign.nodes || []

  const discountCampaignData = useMemo(() => {
    const unsortedData = rawData.map((item: any) => {
      return {
        itemType: item.itemType,
        totalItemCounts: item.totalItemCounts,
        totalItemDiscounts: (item.totalItemDiscounts / 100).toFixed(2),
        checkAvg: (item.checkAvg / 100).toFixed(2),
        ppa: (item.ppa / 100).toFixed(2),
      }
    })

    return unsortedData.sort((a: any, b: any) => {
      return b.totalItemCounts - a.totalItemCounts
    })
  }, [data, locationGroupIds, startDate, endDate])

  return { discountCampaignData, discountCampaignLoading }
}

export default useDiscountCampaign
