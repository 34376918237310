import Main from './Main'

interface IProps {
  tableData: object
}

const NetSalesTable = ({ tableData }: IProps) => {
  return <Main tableData={tableData} />
}

export default NetSalesTable
