import { gql } from '@apollo/client'

interface ILocationTopMissingItemsNodeType {
  startDate: string
  endDate: string
  itemName: string
  totalCount: number
}

export interface ILocationTopMissingItemsType {
  getLocationTopMissingItems: {
    nodes: ILocationTopMissingItemsNodeType[]
  }
}

export interface ILocationTopMissingItemsVariablesType {
  iStartDate: string
  iEndDate: string
  iFilter: {
    location_ids: number[]
  }
}

export const GET_LOCATION_TOP_MISSING_ITEMS = gql`
  query GetLocationTopMissingItems(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
  ) {
    getLocationTopMissingItems(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        startDate
        endDate
        itemName
        totalCount
      }
    }
  }
`
