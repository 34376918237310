import { IApiDataType } from '../types'
import { api as commonApi } from './common'

export type IApiKeyType = keyof typeof api

export const api = {
  ...commonApi,
}

const useApi = (apiName: IApiKeyType): IApiDataType => api[apiName]()

export default useApi
