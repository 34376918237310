import { IConfigsType } from '../../types'

const lfrConfig: IConfigsType = {
  'lfr-gfp-reports-f3csb9s5': {
    '/:brand/property': {
      dateFilter: {
        types: ['period'],
      },
      part1: {
        type: 'table',
        api: 'lfrYardi',
        defaultSortedField: 'ref',
        pageSize: 50,
        columns: [
          {
            key: 'ref',
            title: 'Ref',
          },
          {
            key: 'property',
            title: 'Property',
            highlight: true,
          },
          {
            key: 'CASH FLOW INFORMATION',
            title: 'CASH FLOW INFORMATION',
            align: 'center',
            children: [
              {
                key: 'grossRents',
                title: 'Gross Rents',
              },
              {
                key: 'opExp',
                title: 'OpExp',
                highlight: true,
              },
              {
                key: 'noi',
                title: 'NOI',
              },
              {
                key: 'ds',
                title: 'DS',
                highlight: true,
              },
              {
                key: 'cashFlow',
                title: 'Cash Flow',
              },
              {
                key: 'dscr',
                title: 'DSCR',
                decimal: 2,
                highlight: true,
              },
              {
                key: 'debtYield',
                title: 'Debt Yield',
              },
            ],
          },
        ],
      },
      part2: {
        type: 'table',
        api: 'lfrYardi',
        defaultSortedField: 'ref',
        pageSize: 50,
        columns: [
          {
            key: 'ref',
            title: 'Ref',
          },
          {
            key: 'property',
            title: 'Property',
            highlight: true,
          },
          {
            key: 'APPRAISED VALUES',
            title: 'APPRAISED VALUES',
            align: 'center',
            children: [
              {
                key: 'stabilized',
                title: 'Stabilized',
              },
              {
                key: 'valuation',
                title: 'Valuation',
                highlight: true,
              },
              {
                key: 'loanBal',
                title: 'Loan Bal',
              },
              {
                key: 'equity',
                title: 'Equity',
                highlight: true,
              },
              {
                key: 'cashBal',
                title: 'Cash Bal',
              },
              {
                key: 'equityCash',
                title: 'Equity + Cash',
                highlight: true,
              },
            ],
          },
        ],
      },
      part3: {
        type: 'table',
        api: 'lfrYardi',
        defaultSortedField: 'ref',
        pageSize: 50,
        columns: [
          {
            key: 'ref',
            title: 'Ref',
          },
          {
            key: 'property',
            title: 'Property',
            highlight: true,
          },
          {
            key: 'Loan Information',
            title: 'Wholly Owned Real Estate - Loan Information',
            align: 'center',
            children: [
              {
                key: 'rateTerms',
                title: 'Rate Terms',
              },
              {
                key: 'rate',
                title: 'Rate',
                highlight: true,
              },
              {
                key: 'interestRateFloor',
                title: 'Interest Rate Floor',
              },
              {
                key: 'loanOriginationDate',
                title: 'Loan Origination Date',
                highlight: true,
              },
              {
                key: 'ioUntil',
                title: 'IO Until',
              },
              {
                key: 'maturity',
                title: 'Maturity',
                highlight: true,
              },
              {
                key: 'collateral',
                title: 'Collateral',
              },
              {
                key: 'lenderBank',
                title: 'Lender/Bank',
                highlight: true,
              },
              {
                key: 'contact',
                title: 'Contact',
              },
              {
                key: 'acct',
                title: 'Acct #',
                highlight: true,
              },
            ],
          },
        ],
      },
      part4: {
        type: 'table',
        api: 'lfrYardi',
        title: 'Yardi Report',
        pageSize: 50,
        defaultSortedField: 'ref',
        columns: [
          {
            key: 'ref',
            title: 'Ref',
          },
          {
            key: 'property',
            title: 'Property',
            highlight: true,
          },
          {
            key: 'Loan Information',
            title: 'Wholly Owned Real Estate - Loan Information',
            align: 'center',
            children: [
              {
                key: 'acquired',
                title: 'Acquired',
              },
              {
                key: 'loanAmt',
                title: 'Loan Amt',
                highlight: true,
              },
              {
                key: 'balance',
                title: 'Balance',
              },
              {
                key: 'availableToDraw',
                title: 'Available To Draw',
                highlight: true,
              },
              {
                key: 'monthlyPayment',
                title: 'Monthly Payment',
              },
              {
                key: 'interest',
                title: 'Interest',
                highlight: true,
              },
              {
                key: 'principal',
                title: 'Principal',
              },
              {
                key: 'pmtDue',
                title: 'Pmt Due',
                highlight: true,
              },
            ],
          },
        ],
      },
    },
    '/:brand/gfp-restaurant': {
      groupFilter: {
        api: 'lfrGfpAllGroupFilter',
      },
      dateFilter: {
        types: ['period'],
      },
      report: {
        type: 'temp-table',
        api: 'lfrListRestaurantGfpReport',
        title: 'GFP Restaurant',
        pageSize: 30,
        rowKey: 'name',
        columns: [
          { key: 'name', title: ' ' },
          {
            key: 'kpiValue',
            title: 'MTD$',
            width: '150px',
            highlight: true,
          },
          {
            key: 'kpiPercentage',
            title: '%',
            width: '150px',
          },
          {
            key: 'ttmKpiValue',
            title: 'TTM$',
            width: '150px',
            highlight: true,
          },
          {
            key: 'ttmKpiPercentage',
            title: '%',
            width: '150px',
          },
        ],
      },
    },
    '/:brand/upload-gfp-reports': {
      upload: {
        type: 'upload-reports',
        title: 'Upload GFP Reports',
        uploadRoute: 'upload/gfp_reports',
        enableTimeSelector: true,
        reportTypes: [
          'LFR Consolidated Income Statement TTM',
          'LFR Consolidating Balance sheet',
          'Sunrise Income Statement TTM',
          'Sunrise Balance Sheet',
          'Yardi Debt',
          'Yardi TB',
        ],
      },
      uploadStatus: {
        type: 'table',
        api: 'lfrUploadGfpReportStatus',
        title: 'Upload Events',
        pageSize: 30,
        columns: [
          {
            key: 'time',
            format: 'M/D/YYYY, hh:mm A',
            timezone: -5,
            title: 'Event At (EST)',
          },
          {
            key: 'report',
            title: 'Report Name',
          },
          {
            key: 'status',
            title: 'Status',
          },
          {
            key: 'message',
            title: 'Notes',
          },
        ],
      },
    },
  },
}

export default lfrConfig
