import _ from 'lodash'
import { useState } from 'react'

import { EDDIE_MERLOTS_BRAND_CODE } from 'pared/constants/brands'
import { getBrand } from 'pared/utils/brand'

import { IScore } from '../index'
import Main from './Main'

export interface IOrderBy {
  columnName: string
  isAscending: boolean
}

interface IProps {
  data: IScore[]
  dateRangeType: string
}

function Scores({ data, dateRangeType }: IProps) {
  const [orderBy, setOrderBy] = useState<IOrderBy>({
    columnName: 'weight',
    isAscending: false,
  })

  let rankedScores = []
  const columnName = _.get(orderBy, 'columnName', '')
  const isAscending = orderBy.isAscending ? 'asc' : 'desc'
  let filteredData = data
  if (getBrand() === EDDIE_MERLOTS_BRAND_CODE) {
    filteredData = data.filter((data) => data.name !== 'ot_hours')
  }

  switch (columnName) {
    case 'weight':
      rankedScores = _.orderBy(filteredData, ['weight'], [isAscending])
      break
    case 'score':
      rankedScores = _.orderBy(
        filteredData,
        ({ score }) => (score === null && isAscending === 'desc' ? 0 : score),
        isAscending,
      )
      break
    case 'percentileRank':
      rankedScores = _.orderBy(
        filteredData,
        ({ percentileRank }) =>
          percentileRank === null && isAscending === 'desc'
            ? 0
            : percentileRank,
        isAscending,
      )
      break
    case 'rank':
      rankedScores = _.orderBy(
        filteredData,
        ({ rank }) => (rank === null && isAscending === 'desc' ? 0 : rank),
        isAscending === 'asc' ? 'desc' : 'asc',
      )
      break
    default:
      rankedScores = _.orderBy(filteredData, ['score'], [isAscending])
      break
  }

  return (
    <Main
      scores={rankedScores}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      dateRangeType={dateRangeType}
    />
  )
}

export default Scores
