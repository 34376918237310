import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { INullableType } from 'pared/types'

import {
  IOrderingAnalysisType,
  IOrderingAnalysisVariablesType,
  LIST_LOCATION_ORDERING_ANALYSIS,
} from '../gql'

export interface IOrderingGuideDataType {
  item: string
  itemType: string
  vendor: string
  currentInventory: number
  endingCountDate: string
  forecast: number
  suggestOrder: number
  orderUnit: string
}

const useDataSource = (
  locationId: number,
  nextDaysFilterIndex: number,
): {
  dataSource: IOrderingGuideDataType[]
  loading: boolean
} => {
  const { data, loading, error } = useQuery<
    IOrderingAnalysisType,
    INullableType<IOrderingAnalysisVariablesType>
  >(LIST_LOCATION_ORDERING_ANALYSIS, {
    variables: {
      iFilter: {
        location_ids: [locationId],
      },
    },
  })

  const dataSource = useMemo(() => {
    const orderingAnalysisData =
      data?.listLocationOrderingAnalysis?.nodes?.[0].orderingAnalysisData || []

    return orderingAnalysisData?.reduce((res, row) => {
      const projectedNeed = row.forecast?.reduce((sum, d, i) => {
        if (i > 0) {
          return [...sum, d.qty + sum[i - 1]]
        } else {
          return [d.qty]
        }
      }, [] as number[])

      const forecast = projectedNeed[nextDaysFilterIndex + 1]
      const suggestOrderQty = forecast - row.endingQty
      const itemPerOrderingUnit =
        row.endingCost != 0 ? (row.cost * 100) / row.endingCost : 0

      return suggestOrderQty <= 0
        ? [...res]
        : [
            ...res,
            {
              item: row.item,
              itemType: row.itemType,
              vendor: row.vendorName,
              currentInventory: row.endingQty,
              endingCountDate: row.endingCountDate,
              forecast,
              suggestOrder:
                itemPerOrderingUnit != 0
                  ? suggestOrderQty / itemPerOrderingUnit
                  : 0,
              orderUnit: row.itemUmDescription,
              endingCost: row.endingCost / 100,
              cost: row.cost,
            },
          ]
    }, [] as IOrderingGuideDataType[])
  }, [data, locationId, nextDaysFilterIndex])

  return { dataSource, loading }
}

export default useDataSource
