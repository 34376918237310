import useBbbListLocationFlash, {
  bbbListLocationFlashConfigs,
} from './useBbbListLocationFlash'
import useBbbListLocationGroupFlash, {
  bbbListLocationGroupFlashConfigs,
} from './useBbbListLocationGroupFlash'

export const bbbFlashConfigs = {
  ...bbbListLocationFlashConfigs,
  ...bbbListLocationGroupFlashConfigs,
}

const useBbbFlash = () => {
  const listLocationFlash = useBbbListLocationFlash()
  const listLocationGroupFlash = useBbbListLocationGroupFlash()

  return (listLocationFlash?.data?.source || []).length === 0
    ? listLocationGroupFlash
    : listLocationFlash
}

export default useBbbFlash
