import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import useBrands from 'pared/layouts/hooks/useBrands'

interface IParamsPropsType {
  id: string
}

const queries = gql`
  query CurrentTrainingTracker($id: Int!) {
    trainingTrackers(condition: { traineeId: $id }) {
      nodes {
        createdAt
        assignedTracker
        approvedAt
      }
    }
  }
`

export const jscEmployeeTrackerConfig = {
  content: 'string',
} as const

const useJscEmployeeTracker = () => {
  const { brand } = useBrands()
  const { id }: IParamsPropsType = useParams()
  const { data, loading } = useQuery(queries, {
    variables: {
      id: parseInt(id),
    },
    skip: !id,
  })

  return {
    data: useMemo((): Record<string, unknown>[] | null => {
      if (!data?.trainingTrackers.nodes[0]) {
        return [
          {
            content: `Currently Assigned:    None`,
            modalText: 'assign tracker',
            modalData: {
              callbackUrl: `/${brand}/training_tracker_profile/${id}`,
              employeeId: parseInt(id),
            },
          },
        ]
      }

      const latestData = data?.trainingTrackers.nodes.reduce(
        (latest: any, tracker: any) => {
          return moment(tracker.createdAt).isAfter(moment(latest.createdAt))
            ? tracker
            : latest
        },
        data?.trainingTrackers.nodes[0],
      )

      return [
        {
          content: `Currently Assigned:    ${latestData.assignedTracker}`,
          modalText: 'assign tracker',
          modalData: {
            callbackUrl: `/${brand}/training_tracker_profile/${id}`,
            employeeId: parseInt(id),
          },
        },
      ]
    }, [data]),
    loading,
  }
}

export default useJscEmployeeTracker
