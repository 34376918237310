import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import React from 'react'
import styled from 'styled-components'

import usePaginationPages, { IOptionsType } from './hooks/usePaginationPages'

interface IPropsType extends IOptionsType {
  onChange: (current: number) => void
}

const Container = styled.div`
  margin: 15px 0px 0px;
  display: flex;
  align-items: center;
`

const Span = styled.span<{
  disabled?: boolean
  selected?: boolean
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  user-select: none;
  font-family: Lexend-Regular;

  ${({ selected }) =>
    !selected
      ? ''
      : `
    border-radius: 3px;
    border: 1px black solid;
  `}

  &:not(:first-child) {
    margin: 0px 0px 0px 5px;
  }

  ${({ disabled }) =>
    disabled
      ? ''
      : `
    cursor: pointer;

    &:hover {
      border-radius: 3px;
      border: 1px black solid;
    }
  `}
`

const useStyles = makeStyles({
  icon: {
    fontSize: '14px',
  },
})

// FIXME: should use Pagination in the new version material-ui
const Pagination = ({ current, onChange, ...props }: IPropsType) => {
  const classes = useStyles()
  const pages = usePaginationPages({ ...props, current })

  return (
    <Container>
      <Span
        onClick={() => {
          if (current > pages[0]) onChange(current - 1)
        }}
        disabled={pages[0] === current}
      >
        <ArrowBackIosIcon
          className={classes.icon}
          color={pages[0] === current ? 'disabled' : 'inherit'}
        />
      </Span>

      {pages.map((page) =>
        typeof page !== 'number' ? (
          <Span key={page} disabled>
            ...
          </Span>
        ) : (
          <Span
            key={page}
            onClick={() => onChange(page)}
            selected={page === current}
          >
            {page + 1}
          </Span>
        ),
      )}

      <Span
        onClick={() => {
          if (current < pages[pages.length - 1]) onChange(current + 1)
        }}
        disabled={pages[pages.length - 1] === current}
      >
        <ArrowForwardIosIcon
          className={classes.icon}
          color={pages[pages.length - 1] === current ? 'disabled' : 'inherit'}
        />
      </Span>
    </Container>
  )
}

export default React.memo(Pagination) as unknown as typeof Pagination
