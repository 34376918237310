const isNumber = (d: unknown): d is number => typeof d === 'number'

const calc = (
  a: unknown,
  operator: '*' | '/' | '+' | '-' | 'variancePercentageOf',
  b: unknown,
): null | number => {
  if (!isNumber(a) || !isNumber(b)) return null

  switch (operator) {
    case '*':
      return a * b
    case '/':
      return b === 0 ? null : a / b
    case '-':
      return a - b
    case '+':
      return a + b
    case 'variancePercentageOf':
      return calc(calc(calc(a, '-', b), '/', b), '*', 100)
  }
}

export default calc
