import FormControl from '@material-ui/core/FormControl'
import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import useGroupSelector from 'pared/components/GroupSelector/hooks/useGroupSelector'
import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import Select from 'pared/components/basicUi/select'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { TYPE_PERIOD } from 'pared/data/getDateRanges'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import { getBrand } from 'pared/utils/brand'

import CorporatePurchasesTable from './CorporatePurchasesTable'

interface IPropsType {
  navParams: INavParams
}

const CorporatePurchases = ({ navParams }: IPropsType) => {
  const brand = getBrand()
  const dateRange = useGetDateRange()
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  const { groupBy, setGroupBy, options, locationGroupId } = useGroupSelector({
    initialGroupBy: 'store',
    shouldHideBreakdown: true,
  })

  const selectedLocationGroupId = locationGroupId
    ? parseInt(locationGroupId)
    : brandLocationGroupId

  return (
    <>
      <Header>
        <h1>Purchases</h1>
        <Selectors>
          <RankingFormControl variant="outlined" margin="dense">
            <Select
              value={groupBy}
              onChange={setGroupBy}
              dataSource={options}
              fullWidth
              displayEmpty
            />
          </RankingFormControl>
          <DateRangeSelector
            navParams={navParams}
            preSelectedDateRange={TYPE_PERIOD}
          />
        </Selectors>
      </Header>
      <CorporatePurchasesTable
        locationGroupId={selectedLocationGroupId}
        startDate={dateRange?.startDateStr}
        endDate={dateRange?.endDateStr}
      />
    </>
  )
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 25px 0px;
    }
  }
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const RankingFormControl = styled(FormControl)`
  background-color: white;
`

export default React.memo(CorporatePurchases)
