import { gql } from '@apollo/client'

export const UPDATE_LOCATION_MANAGER_POSITION = gql`
  mutation UpdateLocationManagerPosition(
    $positionId: Int!
    $candidateName: String!
    $comment: String!
    $commentedAt: Datetime!
    $commentedBy: Int!
    $filledPendingStartDate: Datetime!
    $trainingLocationId: Int
  ) {
    updateLocationManagerPosition(
      input: {
        id: $positionId
        patch: {
          candidateName: $candidateName
          comment: $comment
          commentedAt: $commentedAt
          commentedBy: $commentedBy
          filledPendingStartDate: $filledPendingStartDate
          trainingLocationId: $trainingLocationId
        }
      }
    ) {
      location {
        id
      }
    }
  }
`
