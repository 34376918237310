import _ from 'lodash'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import COLORS from 'pared/constants/colors'

interface IProps {
  xAxisData: string[]
  chartOptions: any
  yAxisDataArr: any[]
  isLoading: boolean
  summaryMessage: string | React.ReactElement
  areasOfImprovementMessages: (string | React.ReactElement)[]
}

function Main({
  xAxisData,
  chartOptions,
  yAxisDataArr,
  isLoading,
  summaryMessage,
  areasOfImprovementMessages,
}: IProps) {
  if (isLoading) {
    return (
      <LoadingContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </LoadingContainer>
    )
  }

  return (
    <>
      <Summary>
        <Header>SUMMARY</Header>
        <Text>{summaryMessage}</Text>
      </Summary>
      <AreasOfImprovement>
        <Header>AREAS OF IMPROVEMENT</Header>
        <Messages>
          {areasOfImprovementMessages.map((message) => (
            <Text>{message}</Text>
          ))}
        </Messages>
      </AreasOfImprovement>
      <VerticalSpacer />
      <MixedChart
        xAxisData={xAxisData}
        yAxisDataArr={yAxisDataArr}
        options={chartOptions}
      />
    </>
  )
}

const LoadingContainer = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  height: 400px;
  width: 100%;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const Header = styled.h3`
  font-family: Lexend-Regular;
  flex: 1;
`

const Text = styled.div`
  font-family: Lexend-Regular;
  font-size: 15px;
  flex: 5;
`

const VerticalSpacer = styled.div`
  height: 40px;
`

const Summary = styled.div`
  display: flex;
  align-items: center;
  width: 1000px;
  padding: 15px 25px;
  background-color: ${COLORS.Expo};
  color: white;
  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    width: 100%;
  }
`

const AreasOfImprovement = styled(Summary)`
  background-color: hsla(0, 0%, 77%, 1);
  color: black;
`

const Messages = styled.div`
  display: flex;
  flex-direction: column;
  flex: 5;
`

export default Main
