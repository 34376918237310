import useJscEmployeeTracker, {
  jscEmployeeTrackerConfig,
} from './useJscEmployeeTracker'
import useJscTrainingTracker, {
  jscTrainingTrackerConfig,
} from './useJscTrainingTracker'

export type IApiKeyType = keyof typeof api

export const configs = {
  jscTrainingTracker: jscTrainingTrackerConfig,
  jscEmployeeTracker: jscEmployeeTrackerConfig,
}

export const api = {
  jscTrainingTracker: useJscTrainingTracker,
  jscEmployeeTracker: useJscEmployeeTracker,
}

const useApi = (
  apiName: IApiKeyType,
): {
  data:
    | {
        [key: string]: unknown
      }[]
    | null
  loading: boolean
} => api[apiName]()

export default useApi
