import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import useConfig from 'pared/pages/Purchases/PurchasesTable/hooks/useConfig'
import useFetchPurchaseReportData from 'pared/pages/Scorecard/v2/hooks/usePurchasesReportData'
import { toUsdStr } from 'pared/utils/number'

import { IApiDataType } from '../../types'

const useFoodCost = (): IApiDataType => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const locationInfo = useLocationInfo(locationId)
  const locationName = locationInfo?.name || 'Unknown'
  const foodCostConfig = useConfig()

  const {
    isLoading: purchaseLoading,
    topItemName,
    topItemOpportunity,
    totalAnnualOpportunityCost,
  } = useFetchPurchaseReportData(startDate, endDate, locationId)

  const loading = purchaseLoading

  const data = useMemo(() => {
    if (loading || totalAnnualOpportunityCost === undefined) return null

    return {
      title: 'Food Cost',
      total: totalAnnualOpportunityCost,
      detail:
        topItemName.length === 0
          ? `${locationName} is purchasing items as expected for its sales. Keep up the good work!`
          : `${locationName}'s biggest opportunities to improve ${
              foodCostConfig.mainKpi
            } is ${topItemName}.
            These are being purchased and used in higher quantities than restaurants with similar sales and represent a ${topItemOpportunity} opportunity.
            Put in measures to reduce ${topItemName} waste. Annual opportunity cost for all items: ${toUsdStr(
              totalAnnualOpportunityCost,
            )}
          `,
    }
  }, [
    loading,
    locationName,
    totalAnnualOpportunityCost,
    topItemName,
    topItemOpportunity,
    foodCostConfig.mainKpi,
  ])

  return {
    data,
    loading,
  }
}

export default useFoodCost
