import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_CUSTOMIZED_REPORT } from '../gql'
import { ICustomizedReport, IInputParams } from '../types'

const useFetchCustomizedReportData = (
  reportName: String,
  inputParams: IInputParams | null,
): ICustomizedReport => {
  const { loading, data, refetch } = useQuery(GET_CUSTOMIZED_REPORT, {
    variables: {
      iReportName: reportName,
      iInputParams: inputParams,
    },
    skip: !reportName || !inputParams,
  })

  return useMemo(() => {
    const rawData = data?.getCustomizedReport?.nodes?.[0]
    return {
      reportName: rawData?.reportName,
      reportDefinition: rawData?.reportDefinition,
      reportResult: rawData?.reportResult,
      isLoading: loading,
      refetch,
    }
  }, [data, loading])
}

export default useFetchCustomizedReportData
