import { useMemo } from 'react'

import COLORS from 'pared/constants/colors'
import { RAW_LOCATION_AVG_HOURLY_RATES } from 'pared/constants/raw_data/avgHourlyRates'
import { toUsdString } from 'pared/utils/number'

const useConfig = (locationId: keyof typeof RAW_LOCATION_AVG_HOURLY_RATES) =>
  useMemo(
    () => ({
      type: 'line' as const,
      data: {
        labels: RAW_LOCATION_AVG_HOURLY_RATES[locationId].map(
          ({ date }) => date,
        ),
        datasets: [
          {
            label: 'Avg Hourly Rate',
            data: RAW_LOCATION_AVG_HOURLY_RATES[locationId].map(
              ({ avgHourlyRate }) => avgHourlyRate,
            ),
            borderColor: COLORS.Plum,
            backgroundColor: COLORS.Plum,
          },
        ],
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: ({
                dataset: { label },
                formattedValue,
              }: {
                dataset: { label?: string }
                formattedValue: string
              }) => `${label}: ${toUsdString(formattedValue, 2)}`,
            },
          },
        },
        scales: {
          y: {
            title: {
              text: 'Avg Hourly Rate',
            },
            ticks: {
              callback: (value: string | number) => toUsdString(value, 2),
            },
          },
        },
      },
    }),
    [locationId],
  )

export default useConfig
