import { useMemo } from 'react'

const defaultConfig = {
  oloWaitTime: {
    isVisible: false,
  },
  realTimeKpis: [
    {
      variableName: 'totalOrderCount',
      displayName: '# of Delivery Orders',
    },
    {
      variableName: 'sumSubtotal',
      displayName: 'Delivery Sales',
    },
    {
      variableName: 'sumErrorCharges',
      displayName: '$ of Adjustments',
    },
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
  ],
  realTimeKpisSmall: [
    {
      variableName: 'totalOrderCount',
      displayName: '# of Delivery Orders',
    },
    {
      variableName: 'sumSubtotal',
      displayName: 'Delivery Sales',
    },
    {
      variableName: 'sumErrorCharges',
      displayName: '$ of Adjustments',
    },
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'inaccurateOrderPercent',
      displayName: '% Orders w/ Accuracy Issues',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: '% Orders w/ Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: '% Orders w/ Late Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
  ],
  breakdownOfIssues: [
    {
      variableName: 'inaccurateOrderPercent',
      displayName: 'Accuracy',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: 'Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: 'Lateness',
    },
  ],
  rankings: [
    {
      variableName: 'sumSubtotalRanking',
      displayName: 'Delivery Sales',
    },
    {
      variableName: 'orderWithAnyIssuePercentRanking',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'inaccurateOrderPercentRanking',
      displayName: '% Orders w/ Accuracy Issues',
    },
    {
      variableName: 'cancelledOrderPercentRanking',
      displayName: '% Orders w/ Cancellations',
    },
    {
      variableName: 'delayedOrderPercentRanking',
      displayName: '% Orders w/ Late Issues',
    },
    {
      variableName: 'avgCustomerReviewScoreRanking',
      displayName: 'Combined Star Rating',
    },
  ],
  storeVsSystemChart: {
    isVisible: true,
  },
  staffAndDayTrendsFilter: {
    options: [
      {
        variableName: 'inaccurateOrderPercent',
        displayName: '% Orders w/ Accuracy Issues',
      },
      {
        variableName: 'cancelledOrderPercent',
        displayName: '% Orders w/ Cancellations',
      },
      {
        variableName: 'delayedOrderPercent',
        displayName: '% Orders w/ Late Issues',
      },
      {
        variableName: 'avgCustomerReviewScore',
        displayName: 'Combined Star Rating',
      },
    ],
    default: 'inaccurateOrderPercent',
  },
  staffTable: {
    roleNameOverride: '',
    isVisible: true,
  },
  dayTrendsDayPartDropdown: {
    isVisible: true,
  },
}

const drgConfig = {
  oloWaitTime: {
    isVisible: false,
  },
  realTimeKpis: [
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
  ],
  realTimeKpisSmall: [
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'inaccurateOrderPercent',
      displayName: '% Orders w/ Accuracy Issues',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: '% Orders w/ Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: '% Orders w/ Late Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
  ],
  breakdownOfIssues: [
    {
      variableName: 'inaccurateOrderPercent',
      displayName: 'Accuracy',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: 'Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: 'Lateness',
    },
  ],
  rankings: [
    {
      variableName: 'orderWithAnyIssuePercentRanking',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'inaccurateOrderPercentRanking',
      displayName: '% Orders w/ Accuracy Issues',
    },
    {
      variableName: 'cancelledOrderPercentRanking',
      displayName: '% Orders w/ Cancellations',
    },
    {
      variableName: 'delayedOrderPercentRanking',
      displayName: '% Orders w/ Late Issues',
    },
    {
      variableName: 'avgCustomerReviewScoreRanking',
      displayName: 'Combined Star Rating',
    },
  ],
  storeVsSystemChart: {
    isVisible: true,
  },
  staffAndDayTrendsFilter: {
    options: [
      {
        variableName: 'inaccurateOrderPercent',
        displayName: '% Orders w/ Accuracy Issues',
      },
      {
        variableName: 'cancelledOrderPercent',
        displayName: '% Orders w/ Cancellations',
      },
      {
        variableName: 'delayedOrderPercent',
        displayName: '% Orders w/ Late Issues',
      },
      {
        variableName: 'avgCustomerReviewScore',
        displayName: 'Combined Star Rating',
      },
    ],
    default: 'inaccurateOrderPercent',
  },
  staffTable: {
    roleNameOverride: '',
    isVisible: true,
  },
  dayTrendsDayPartDropdown: {
    isVisible: true,
  },
}

const pjConfig = {
  realTimeKpis: [
    {
      variableName: 'totalOrderCount',
      displayName: '# of Delivery Orders',
    },
    {
      variableName: 'sumSubtotal',
      displayName: 'Delivery Sales',
    },
    {
      variableName: 'averageTripTime',
      displayName: 'Average Driver Trip Time',
    },
  ],
  realTimeKpisSmall: [
    {
      variableName: 'totalOrderCount',
      displayName: '# of Delivery Orders',
    },
    {
      variableName: 'sumSubtotal',
      displayName: 'Delivery Sales',
    },
    {
      variableName: 'averageTripTime',
      displayName: 'Average Driver Trip Time',
    },
  ],
  rankings: [
    {
      variableName: 'totalOrderCountRanking',
      displayName: 'Delivery Orders',
    },
    {
      variableName: 'sumSubtotalRanking',
      displayName: 'Delivery Sales',
    },
    {
      variableName: 'averageTripTimeRanking',
      displayName: 'Average Driver Trip Time',
    },
  ],
  staffAndDayTrendsFilter: {
    options: [
      {
        variableName: 'averageTripTime',
        displayName: 'Average Driver Trip Time',
        defaultSortOrder: 'asc',
      },
    ],
    default: 'averageTripTime',
  },
  staffTable: {
    isVisible: true,
    roleNameOverride: 'Driver',
  },
}

const pjDemoConfig = {
  realTimeKpis: [
    {
      variableName: 'totalOrderCount',
      displayName: '# of Delivery Orders',
    },
    {
      variableName: 'sumSubtotal',
      displayName: 'Delivery Sales',
    },
    {
      variableName: 'sumErrorCharges',
      displayName: '$ of Adjustments',
    },
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
    {
      variableName: 'averageTripTime',
      displayName: 'Average Driver Trip Time',
    },
  ],
  realTimeKpisSmall: [
    {
      variableName: 'totalOrderCount',
      displayName: '# of Delivery Orders',
    },
    {
      variableName: 'sumSubtotal',
      displayName: 'Delivery Sales',
    },
    {
      variableName: 'sumErrorCharges',
      displayName: '$ of Adjustments',
    },
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'inaccurateOrderPercent',
      displayName: '% Orders w/ Accuracy Issues',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: '% Orders w/ Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: '% Orders w/ Late Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
    {
      variableName: 'averageTripTime',
      displayName: 'Average Driver Trip Time',
    },
  ],
  breakdownOfIssues: [
    {
      variableName: 'inaccurateOrderPercent',
      displayName: 'Accuracy',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: 'Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: 'Lateness',
    },
  ],
  rankings: [
    {
      variableName: 'totalOrderCountRanking',
      displayName: 'Delivery Orders',
    },
    {
      variableName: 'sumSubtotalRanking',
      displayName: 'Delivery Sales',
    },
    {
      variableName: 'orderWithAnyIssuePercentRanking',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'inaccurateOrderPercentRanking',
      displayName: '% Orders w/ Accuracy Issues',
    },
    {
      variableName: 'cancelledOrderPercentRanking',
      displayName: '% Orders w/ Cancellations',
    },
    {
      variableName: 'delayedOrderPercentRanking',
      displayName: '% Orders w/ Late Issues',
    },
    {
      variableName: 'avgCustomerReviewScoreRanking',
      displayName: 'Combined Star Rating',
    },
    {
      variableName: 'averageTripTimeRanking',
      displayName: 'Average Driver Trip Time',
    },
  ],
  storeVsSystemChart: {
    isVisible: true,
  },
  staffAndDayTrendsFilter: {
    options: [
      {
        variableName: 'inaccurateOrderPercent',
        displayName: '% Orders w/ Accuracy Issues',
      },
      {
        variableName: 'cancelledOrderPercent',
        displayName: '% Orders w/ Cancellations',
      },
      {
        variableName: 'delayedOrderPercent',
        displayName: '% Orders w/ Late Issues',
      },
      {
        variableName: 'avgCustomerReviewScore',
        displayName: 'Combined Star Rating',
      },
      {
        variableName: 'averageTripTime',
        displayName: 'Average Driver Trip Time',
        defaultSortOrder: 'asc',
      },
    ],
    default: 'inaccurateOrderPercent',
  },
  staffTable: {
    isVisible: true,
    roleNameOverride: 'Team Member',
  },
  dayTrendsDayPartDropdown: {
    isVisible: true,
  },
}

const fwWingstopConfig = {
  oloWaitTime: {
    isVisible: false,
  },
  realTimeKpis: [
    {
      variableName: 'sumErrorCharges',
      displayName: 'M&I',
    },
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
  ],
  realTimeKpisSmall: [
    {
      variableName: 'sumErrorCharges',
      displayName: 'M&I',
    },
    {
      variableName: 'avgDasherWaitTime',
      displayName: 'Average Driver Wait Time',
    },
    {
      variableName: 'orderWithAnyIssuePercent',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'inaccurateOrderPercent',
      displayName: '% Orders w/ Accuracy Issues',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: '% Orders w/ Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: '% Orders w/ Late Issues',
    },
    {
      variableName: 'avgCustomerReviewScore',
      displayName: 'Combined Star Rating',
    },
  ],
  breakdownOfIssues: [
    {
      variableName: 'inaccurateOrderPercent',
      displayName: 'Accuracy',
    },
    {
      variableName: 'cancelledOrderPercent',
      displayName: 'Cancellations',
    },
    {
      variableName: 'delayedOrderPercent',
      displayName: 'Lateness',
    },
  ],
  rankings: [
    {
      variableName: 'orderWithAnyIssuePercentRanking',
      displayName: '% Orders w/ Any Issues',
    },
    {
      variableName: 'inaccurateOrderPercentRanking',
      displayName: '% Orders w/ Accuracy Issues',
    },
    {
      variableName: 'cancelledOrderPercentRanking',
      displayName: '% Orders w/ Cancellations',
    },
    {
      variableName: 'delayedOrderPercentRanking',
      displayName: '% Orders w/ Late Issues',
    },
    {
      variableName: 'avgCustomerReviewScoreRanking',
      displayName: 'Combined Star Rating',
    },
  ],
  storeVsSystemChart: {
    isVisible: true,
  },
  staffAndDayTrendsFilter: {
    options: [
      {
        variableName: 'inaccurateOrderPercent',
        displayName: '% Orders w/ Accuracy Issues',
      },
      {
        variableName: 'cancelledOrderPercent',
        displayName: '% Orders w/ Cancellations',
      },
      {
        variableName: 'delayedOrderPercent',
        displayName: '% Orders w/ Late Issues',
      },
      {
        variableName: 'avgCustomerReviewScore',
        displayName: 'Combined Star Rating',
      },
    ],
    default: 'inaccurateOrderPercent',
  },
  staffTable: {
    roleNameOverride: '',
    isVisible: true,
  },
  dayTrendsDayPartDropdown: {
    isVisible: true,
  },
  isSummaryHidden: false,
  isDayTrendOrdersHidden: true,
}

const useDeliveryConfig = (brand: string): any =>
  useMemo(() => {
    switch (brand) {
      case 'rmg':
      case 'rmg_nso':
      case 'sullivans':
      case 'sullivans_nso':
        return drgConfig

      case 'eddiemerlots':
      case 'eddiemerlots_nso':
        return {
          ...drgConfig,
          staffTable: {
            isVisible: false,
          },
        }

      case 'supreme_bk':
      case 'timoney_bk':
      case 'demo_bk':
      case 'irmg_pop':
      case 'supreme_pop':
      case 'ghai_pop':
      case 'demo_pop':
        return {
          ...defaultConfig,
          realTimeKpis: defaultConfig.realTimeKpis.filter(
            ({ variableName }) =>
              !['totalOrderCount', 'sumSubtotal', 'sumErrorCharges'].includes(
                variableName,
              ),
          ),
          realTimeKpisSmall: defaultConfig.realTimeKpisSmall.filter(
            ({ variableName }) =>
              !['totalOrderCount', 'sumSubtotal', 'sumErrorCharges'].includes(
                variableName,
              ),
          ),
          staffTable: {
            isVisible: false,
          },
        }

      case 'irmg_bk':
      case 'jsc_bk':
      case 'jsc_bk_nso':
      case 'mbn_bk':
      case 'sandbox_bk':
        return {
          ...defaultConfig,
          realTimeKpis: defaultConfig.realTimeKpis.filter(
            ({ variableName }) =>
              !['totalOrderCount', 'sumSubtotal', 'sumErrorCharges'].includes(
                variableName,
              ),
          ),
          realTimeKpisSmall: defaultConfig.realTimeKpisSmall.filter(
            ({ variableName }) =>
              !['totalOrderCount', 'sumSubtotal', 'sumErrorCharges'].includes(
                variableName,
              ),
          ),
          staffTable: {
            isVisible: true,
          },
        }

      case 'viking_bk':
        return {
          ...defaultConfig,
          realTimeKpis: defaultConfig.realTimeKpis.filter(
            ({ variableName }) =>
              !['totalOrderCount', 'sumSubtotal', 'sumErrorCharges'].includes(
                variableName,
              ),
          ),
          realTimeKpisSmall: defaultConfig.realTimeKpisSmall.filter(
            ({ variableName }) =>
              !['totalOrderCount', 'sumSubtotal', 'sumErrorCharges'].includes(
                variableName,
              ),
          ),
          staffTable: {
            isVisible: true,
          },
          isSummaryHidden: true,
        }
      case 'papajohns':
        return pjConfig

      case 'papajohns_demo':
        return pjDemoConfig

      case 'wingitnorth':
        return {
          ...defaultConfig,
          realTimeKpis: defaultConfig.realTimeKpis.map((kpi) => {
            if (kpi.variableName === 'sumErrorCharges') {
              return {
                ...kpi,
                displayName: 'M&I',
              }
            }

            return kpi
          }),
          realTimeKpisSmall: defaultConfig.realTimeKpisSmall.map((kpi) => {
            if (kpi.variableName === 'sumErrorCharges') {
              return {
                ...kpi,
                displayName: 'M&I',
              }
            }

            return kpi
          }),
        }
      case 'wingstop':
        return {
          ...defaultConfig,
          oloWaitTime: {
            isVisible: true,
            displayName: 'Average Wait Time (Olo)',
          },
          realTimeKpis: defaultConfig.realTimeKpis.filter(
            ({ variableName }) => variableName !== 'avgDasherWaitTime',
          ),
          realTimeKpisSmall: defaultConfig.realTimeKpisSmall.filter(
            ({ variableName }) => variableName !== 'avgDasherWaitTime',
          ),
          staffTable: {
            isVisible: true,
            roleNameOverride: 'Team Member',
          },
        }

      case 'jitb':
        return {
          ...defaultConfig,
          realTimeKpis: defaultConfig.realTimeKpis.filter(
            ({ variableName }) =>
              !['totalOrderCount', 'sumErrorCharges'].includes(variableName),
          ),
          realTimeKpisSmall: defaultConfig.realTimeKpisSmall.filter(
            ({ variableName }) =>
              !['totalOrderCount', 'sumErrorCharges'].includes(variableName),
          ),
          staffTable: {
            isVisible: true,
          },
        }

      case 'bibibop':
        return {
          ...defaultConfig,
          staffTable: {
            hideToGo: true,
            isVisible: true,
          },
          customizedRealTimeKpis: [
            'totalOrderCount',
            'sumSubtotal',
            'completedDisputeAmount',
            'sumSubtotalRanking',
          ],
          realTimeKpis: defaultConfig.realTimeKpis.map((data) =>
            data.variableName !== 'sumErrorCharges'
              ? data
              : {
                  variableName: 'completedDisputeAmount',
                  displayName: 'Completed Disputes',
                },
          ),
          realTimeKpisSmall: defaultConfig.realTimeKpisSmall.map((data) =>
            data.variableName !== 'sumErrorCharges'
              ? data
              : {
                  variableName: 'completedDisputeAmount',
                  displayName: 'Completed Disputes',
                },
          ),
          isDisputeTableVisible: true,
        }

      case 'fw_wingstop':
        return fwWingstopConfig

      case 'sullivans':
      case 'sullivans_nso':
      case 'rmg':
      case 'rmg_nso':
      // Local Favorite
      case 'lfr_ef':
      case 'lfr_tr':
      case 'lfr_sn':
      case 'lfr_lv':
      case 'lfr_vb':
      case 'lfr_tu':
      case 'lfr_ws':
      case 'lfr_jn':
      case 'lfr_mm':
      case 'lfr_ol':
      case 'lfr_dt':
      case 'lfr_uv':
      case 'lfr_bth':
      default:
        return defaultConfig
    }
  }, [brand])

export default useDeliveryConfig
