import { gql, useQuery } from '@apollo/client'
import * as _ from 'lodash'
import { useMemo } from 'react'

import useBrands from 'pared/layouts/hooks/useBrands'

import { useDateFilter, useYoyDates } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

interface IListLocationFinancialKpisNodeType {
  locationId: number
  totalCheckAverage: string
}

interface ISummaryLocationGroupFinancialKpisNodeType {
  totalCheckAverage: string
}

interface IListLocationFlashExtendNodeType {
  locationId: number
  compsItemDetails: {
    type: string
    count?: number
    totalComps?: number
  }[]
}

interface ISummaryFlashExtendNodeType
  extends Omit<IListLocationFlashExtendNodeType, 'locationId'> {}

const getQuery = (fields: string[]) => gql`
  query listLocationFlash(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        netSales
        yoyNetSales
        netSalesLy
        netSalesLyPercent
        netSalesSssPercent
        netSalesBudget
        netSalesBudgetVariance
        totalCheckCount
        yoyTotalCheckCount
        totalCheckCountLy
        yoyCheckCountGrowth
        totalCheckAverage
      }
    }

    summaryLocationGroupFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        netSales
        yoyNetSales
        netSalesLy
        netSalesLyPercent
        netSalesSssPercent
        netSalesBudget
        netSalesBudgetVariance
        totalCheckCount: checkCount
        yoyTotalCheckCount
        totalCheckCountLy
        yoyCheckCountGrowth
        totalCheckAverage: checkAverage
      }
    }

    ${
      fields.length === 0
        ? ''
        : `
    listLocationFlashExtend(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        ${fields.join('\n')}
      }
    }

    summaryFlashExtend(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        ${fields.join('\n')}
      }
    }
    `
    }
  }
`

const yoyQuery = gql`
  query yoyListLocationGroupFlash(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        totalCheckAverage
      }
    }

    summaryLocationGroupFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        netSales
        totalCheckAverage: checkAverage
      }
    }
  }
`

const EXTEND_FIELDS: Record<string, string[]> = {
  ghai_pop: ['regularHours', 'overtimeHours'],
  bibibop: [
    'deliveryPercent',
    'totalComps',
    'compsPercent',
    'cashOverOrShort',
    'compsItemDetails',
    'bonus',
    'cookiesPer100Orders',
    'avocadoPer100Orders',
    'onlinePercent',
    'catering',
    'voids',
    'laborHours',
    'overtimeHours',
    'salesPerLaborHour',
    'laborPercent',
  ],
}

export const listLocationFlashConfigs = {
  netSales: 'price',
  yoyNetSales: 'price',
  netSalesLy: 'price',
  netSalesLyPercent: 'percent',
  netSalesSssPercent: 'percent',
  netSalesBudget: 'price',
  netSalesBudgetVariance: 'percent',
  totalCheckCount: 'number',
  yoyTotalCheckCount: 'number',
  totalCheckCountLy: 'number',
  yoyCheckCountGrowth: 'percent',
  totalCheckAverage: 'price',
  yoyTotalCheckAverage: 'price',
  totalCheckAverageSssPercent: 'percent',

  // customized ghai_pop
  regularHours: 'number',
  overtimeHours: 'number',

  // customized bibibop
  onlinePercent: 'percent',
  deliveryPercent: 'percent',
  catering: 'price',
  totalComps: 'price',
  compsPercent: 'percent',
  'Employee Discount.totalComps': 'price',
  voids: 'price',
  cashOverOrShort: 'price',
  cookiesPer100Orders: 'number',
  avocadoPer100Orders: 'number',
  customerRating: 'number',
  numberOfComplaints: 'number',
  bonus: 'price',
  laborHours: 'number',
  salesPerLaborHour: 'price',
  laborPercent: 'percent',
} as const

const getSss = (a?: unknown, b?: unknown) => {
  if (typeof a === 'number' && typeof b === 'number') return a - b

  if (typeof a === 'string' && typeof b === 'string')
    return parseFloat(a) - parseFloat(b)

  return null
}

const useListLocationFlash = () => {
  const { startDate, endDate } = useDateFilter()
  const { yoyStartDate, yoyEndDate } = useYoyDates(startDate, endDate)
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { brand } = useBrands()
  const { data, loading } = useQuery(getQuery(EXTEND_FIELDS[brand] || []), {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_group_ids: groupFilter?.ids },
    },
    skip: !startDate || !endDate || !hasGroupBy,
  })
  const { data: yoyData, loading: yoyLoading } = useQuery(yoyQuery, {
    variables: {
      iStartDate: yoyStartDate,
      iEndDate: yoyEndDate,
      iFilter: { location_group_ids: groupFilter?.ids },
    },
    skip: !yoyStartDate || !yoyStartDate || !hasGroupBy,
  })

  return {
    data: useMemo((): IApiDataType => {
      const listLocationFinancialKpis = data?.listLocationFinancialKpis
        .nodes as IListLocationFinancialKpisNodeType[] | undefined
      const yoyListLocationFinancialKpis = yoyData?.listLocationFinancialKpis
        .nodes as IListLocationFinancialKpisNodeType[] | undefined
      const listLocationFlashExtend = data?.listLocationFlashExtend?.nodes as
        | IListLocationFlashExtendNodeType[]
        | undefined
      const summaryLocationGroupFinancialKpis = (
        data?.summaryLocationGroupFinancialKpis?.nodes as
          | ISummaryLocationGroupFinancialKpisNodeType[]
          | undefined
      )?.[0]
      const yoySummaryLocationGroupFinancialKpis = (
        yoyData?.summaryLocationGroupFinancialKpis?.nodes as
          | ISummaryLocationGroupFinancialKpisNodeType[]
          | undefined
      )?.[0]
      const summaryFlashExtend = (
        data?.summaryFlashExtend?.nodes as
          | ISummaryFlashExtendNodeType[]
          | undefined
      )?.[0]

      if (!listLocationFinancialKpis) return null

      const source = listLocationFinancialKpis.map((n) => {
        const yoyD = yoyListLocationFinancialKpis?.find(
          (y) => y.locationId === n.locationId,
        )
        const groupInfo = groupFilter?.list?.find((l) => l.id === n.locationId)
        const flashExtend = listLocationFlashExtend?.find(
          (d: IListLocationFlashExtendNodeType) =>
            d.locationId === n.locationId,
        )

        return {
          ...n,
          ...flashExtend,
          ...flashExtend?.compsItemDetails?.reduce(
            (result, c) => ({
              ...result,
              [`${c.type}.count`]: c.count,
              [`${c.type}.totalComps`]: c.totalComps,
            }),
            {},
          ),
          groupInfo,
          yoyTotalCheckAverage: yoyD?.totalCheckAverage,
          totalCheckAverageSssPercent: getSss(
            n?.totalCheckAverage,
            yoyD?.totalCheckAverage,
          ),
        }
      })

      return {
        source,
        summary: {
          ...summaryLocationGroupFinancialKpis,
          ...summaryFlashExtend,
          ...summaryFlashExtend?.compsItemDetails?.reduce(
            (result, c) => ({
              ...result,
              [`${c.type}.count`]: c.count,
              [`${c.type}.totalComps`]: c.totalComps,
            }),
            {},
          ),
          yoyTotalCheckAverage:
            yoySummaryLocationGroupFinancialKpis?.totalCheckAverage,
          totalCheckAverageSssPercent: getSss(
            summaryLocationGroupFinancialKpis?.totalCheckAverage,
            yoySummaryLocationGroupFinancialKpis?.totalCheckAverage,
          ),
        },
      }
    }, [groupFilter, data, yoyData]),
    loading: loading || yoyLoading,
  }
}

export default useListLocationFlash
