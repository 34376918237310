import COLORS from 'pared/constants/colors'
import { ILossPreventionConfigType } from 'pared/pages/CorporateLossPrevention/hooks/useLossPreventionConfig'

const cashControlKpiName = {
  cashOverOrShort: 'Cash +/-',
  overRing: 'Over Ring',
  deletes: 'Voids',
}

export const jscBkConfig: ILossPreventionConfigType = {
  kpiSettings: [
    { category: 'basic', key: 'compsTotalAmount', title: 'Total Discounts' },
    { category: 'basic', key: 'cashOverOrShort', title: 'Cash +/-' },
    {
      category: 'custom',
      type: 'Deal after Total',
      title: 'Voids',
      key: 'amount',
    },
  ],

  discountTable: {
    isVisible: true,
    isTotalCountVisible: false,
    isTotalAmountVisible: true,

    discountColumns: [
      {
        key: 'Employee Discount - Discount',
        title: 'Employee Discount',
        isCountVisible: false,
        isAmountVisible: true,
      },
      {
        key: '$3 Off Delivery Purchase of $20 or more',
        title: 'Delivery Discount',
        isCountVisible: false,
        isAmountVisible: true,
      },
      {
        key: 'Senior Discount - Discount',
        title: 'Senior Discount',
        isCountVisible: false,
        isAmountVisible: true,
      },
      {
        key: 'Guest Recovery - Discount',
        title: 'Guest Recovery',
        isCountVisible: false,
        isAmountVisible: true,
      },
      {
        key: 'Manager Discount - Discount',
        title: 'Manager Discount',
        isCountVisible: false,
        isAmountVisible: true,
      },
    ],
  },

  discountTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Discount',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [
      {
        key: 'Employee Meal 50%',
        name: 'Employee Meal 50%',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.DarkOrchid,
        hidden: true,
      },
      {
        key: 'Uber Funded',
        name: 'Uber Funded',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Mango,
        hidden: true,
      },
      {
        key: 'Senior Citizen Discount 10%',
        name: 'Senior Citizen Discount 10%',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Basil,
        hidden: true,
      },
      {
        key: 'Guest Recovery 100%',
        name: 'Guest Recovery 100%',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Pomodoro,
        hidden: true,
      },
      {
        key: 'Manager Meal 100%',
        name: 'Manager Meal 100%',
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Acai,
        hidden: true,
      },
    ],
  },

  cashControlTable: {
    isVisible: true,
    cashControlColumns: [
      {
        key: 'cashOverOrShort',
        title: 'Cash +/-',
        width: '150px',
        children: [
          {
            key: 'cashOverOrShort.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
      {
        key: 'Deal after Total',
        title: 'Voids',
        width: '150px',
        children: [
          {
            key: 'Deal after Total.amount',
            title: '$',
            valueType: 'price',
          },
        ],
      },
    ],
  },

  cashControlTrendChart: {
    isVisible: true,
    yLeftAxisLabel: '$ Amount',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [
      {
        key: 'cashOverOrShort',
        name: `${cashControlKpiName.cashOverOrShort} $`,
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Pomodoro,
      },
      {
        key: 'Deal after Total',
        name: `${cashControlKpiName.deletes} $`,
        kpiKey: 'amount',
        valueType: 'cent',
        color: COLORS.Plum,
        hidden: true,
      },
    ],
  },
}
