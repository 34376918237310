import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

export const FIXED_DIRECTORS = {
  all: {
    employeeId: -1,
    firstName: 'All',
    lastName: 'Stores',
    directorId: -1,
    directorLevel: -1,
    brandId: -1,
    brandCode: '',
    brandName: '',
    locationGroupId: -1,
  },
  breakown: {
    employeeId: -2,
    firstName: 'Breakdown',
    lastName: 'directors',
    directorId: -2,
    directorLevel: -2,
    brandId: -2,
    brandCode: '',
    brandName: '',
    locationGroupId: -2,
  },
}

const FW_WINGSTOP_MARKET_BREAKDOWN = {
  employeeId: -4,
  firstName: 'Breakdown',
  lastName: 'Market',
  directorId: -4,
  directorLevel: -4,
  brandId: -4,
  brandCode: '',
  brandName: '',
  locationGroupId: -4,
}

export default () => {
  const brand = getBrand()

  return useMemo(() => {
    switch (brand) {
      case 'fw_wingstop':
        return FW_WINGSTOP_MARKET_BREAKDOWN
      case 'lfr':
        return FIXED_DIRECTORS.breakown
      default:
        return FIXED_DIRECTORS.all
    }
  }, [brand])
}
