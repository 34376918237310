import { gql } from '@apollo/client'

export const GET_LOCATION_DATA = gql`
  query GetLocationInfo($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        brandId
        directorId
        directorEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        gmEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
      }
    }
  }
`

export const LIST_LOCATION_FINANCIAL_KPIS = (usePeriodCogs: boolean) => gql`
  query ListLocationFinancialKpis(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
  ) {
    listLocationFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        startDate
        endDate
        netSales
        netSalesBudget
        netSalesBudgetVariance
        netSalesProjection
        netSalesProjectionVariance
        grossSales
        grossSalesBudget
        grossSalesBudgetVariance
        netSalesSssPercent
        grossSalesSssPercent
        checkCount
        inUnitYoyCheckCount
        inUnitYoyCheckCountGrowth
        checkAverage
        yoyTotalCheckCount
        totalCheckCount
        totalCheckAverage
        totalGuestCount
        totalGuestAverage
        cogs
        cogsPercent
        cogsBudget
        cogsBudgetPercent
        ${
          usePeriodCogs
            ? 'periodCogs periodCogsPercent periodCogsBudget periodCogsBudgetPercent'
            : ''
        }
        hourlyLaborCost
        hourlyLaborCostPercent
        hourlyLaborCostBudget
        hourlyLaborCostBudgetPercent
        hourlyLaborOvertimeCost
        hourlyLaborOvertimeHours
        avtPercent
        avtPercentMonthly
        avtPercentWeekly
        plvPercent
        rcp
        rcpPercent
        rcpBudget
        rcpBudgetPercent
        sosAvgSeconds
      }
    }
  }
`

export const LIST_LOCATION_COMPS_BREAKDOWN = gql`
  query ListLocationCompsBreakdown(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
  ) {
    listLocationCompsBreakdown(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        startDate
        endDate
        compsType
        compsAmount
        compsCount
        compsGrossSalesPercent
      }
    }
  }
`

export const TREND_LOCATION_FINANCIAL_KPIS = (usePeriodCogs: boolean) => gql`
  query TrendLocationFinancialKpis(
    $iLocationId: Int!
    $iEndDate: Date!
    $iStartDate: Date!
    $iGroupBy: String!
  ) {
    trendLocationFinancialKpis(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
    ) {
      nodes {
        locationId
        startDate
        endDate
        netSales
        netSalesBudget
        netSalesBudgetVarianceAmount
        grossSales
        grossSalesBudget
        grossSalesBudgetVarianceAmount
        cogs
        cogsPercent
        ${
          usePeriodCogs
            ? 'periodCogs periodCogsPercent periodCogsBudget periodCogsBudgetPercent'
            : ''
        }
        hourlyLaborCost
        hourlyLaborCostPercent
        hourlyLaborCostBudget
        hourlyLaborBudgetVarianceAmount
        avtPercent
        avtPercentMonthly
        avtPercentWeekly
        plvPercent
        rcp
        rcpPercent
        businessWeek
        businessMonth
        businessQuarter
      }
    }
  }
`

export const CUSTOMIZED_TREND_LOCATION_FINANCIAL_KPIS = gql`
  query CustomizedTrendLocationFinancialKpis($iInputParams: JSON!) {
    customizedTrendLocationFinancialKpis: getCustomizedReport(
      iCustomizedReportName: "CUSTOMIZED_TREND_LOCATION_FINANCIAL_KPIS"
      iInputParams: $iInputParams
    ) {
      nodes {
        reportName
        reportDefinition
        reportResult
      }
    }
  }
`

export const LIST_LOCATION_STORES_CUSTOMIZED_EXTEND_TABLE = gql`
  query CustomizedTrendLocationFinancialKpis($iInputParams: JSON!) {
    customizedTrendLocationFinancialKpis: getCustomizedReport(
      iCustomizedReportName: "LIST_LOCATION_STORES_CUSTOMIZED_EXTEND_TABLE"
      iInputParams: $iInputParams
    ) {
      nodes {
        reportName
        reportDefinition
        reportResult
      }
    }
  }
`

export const LIST_LOCATION_KPI_RANKINGS = gql`
  query ListLocationKpiRankings(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationKpiRankings(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        kpi
        rankingData
      }
    }
  }
`

export const LIST_LOCATION_REV_CENTER_BREAKDOWN = gql`
  query ListLocationRevenueCenterBreakdown(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationRevenueCenterBreakdown(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        startDate
        endDate
        revenueCenter
        revCenterNetSales
        revCenterGuestAverage
        revCenterCheckCount
        revCenterCheckAverage
      }
    }
  }
`

export const TREND_LOCATION_REV_CENTER_BREAKDOWN = gql`
  query TrendLocationRevenueCenterBreakdown(
    $iLocationId: Int!
    $iEndDate: Date!
    $iGroupBy: String!
    $iDateRangeNumber: Int!
  ) {
    trendLocationRevenueCenterBreakdown(
      iLocationId: $iLocationId
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iDateRangeNumber: $iDateRangeNumber
    ) {
      nodes {
        locationId
        startDate
        endDate
        revenueCenter
        revCenterNetSales
        businessWeek
        businessMonth
        businessQuarter
        businessYear
      }
    }
  }
`

export const LIST_LOCATION_DAYPART_BREAKDOWN = gql`
  query ListLocationDaypartBreakdown(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationDaypartBreakdown(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        startDate
        endDate
        daypart
        daypartSales
        daypartCheckCount
        daypartCheckAverage
        daypartHourlyLaborPercent
      }
    }
  }
`
