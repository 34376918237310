import { useAdvancedFilter } from '../../advancedFilter'
import useJscBkListLocationDailyFlash, {
  jscBkListLocationDailyFlashConfigs,
} from './useJscBkListLocationDailyFlash'
import useJscBkListLocationDailyFlashGroupByDistrictLeader, {
  jscBkListLocationDailyFlashGroupByDistrictLeaderConfigs,
} from './useJscBkListLocationDailyFlashGroupByDistrictLeader'
import useJscBkListLocationGroupDailyFlash, {
  jscBkListLocationGroupDailyFlashConfigs,
} from './useJscBkListLocationGroupDailyFlash'

export const jscBkFlashConfigs = {
  ...jscBkListLocationDailyFlashConfigs,
  ...jscBkListLocationGroupDailyFlashConfigs,
  ...jscBkListLocationDailyFlashGroupByDistrictLeaderConfigs,
}

const useJscBkFlash = () => {
  const advancedFilter = useAdvancedFilter('select')
  const groupBy = advancedFilter?.value['groupBy']

  const jscBkListLocationDailyFlashGroupByDistrictLeader =
    useJscBkListLocationDailyFlashGroupByDistrictLeader()
  const jscBkListLocationDailyFlash = useJscBkListLocationDailyFlash()
  const jscBkListLocationGroupDailyFlash = useJscBkListLocationGroupDailyFlash()

  if (!((jscBkListLocationGroupDailyFlash?.data || []).length === 0)) {
    return jscBkListLocationGroupDailyFlash
  }

  if (groupBy === 'district-leader')
    return jscBkListLocationDailyFlashGroupByDistrictLeader

  return jscBkListLocationDailyFlash
}

export default useJscBkFlash
