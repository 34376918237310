import useBurgerworksWeeklySales, {
  burgerworksWeeklySalesConfigs,
} from './useWeeklySales'

export const configs = {
  burgerworksWeeklySales: burgerworksWeeklySalesConfigs,
}

export const api = {
  burgerworksWeeklySales: useBurgerworksWeeklySales,
}
