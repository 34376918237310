import _ from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import { IDateRange, IDateRangeMap } from 'pared/data/getDateRanges'

import { IDateOptionNodeType, IDateOptionType, IDateType } from './types'
import { ISetDateType } from './useSetDate'

export const buildQPWOptions = <T extends 'quarter' | 'period' | 'week'>(
  type: T,
  d: IDateRange,
): IDateOptionNodeType<'quarter' | 'period' | 'week'> => {
  const { id, prefix } = (() => {
    switch (type) {
      case 'quarter':
        return {
          id: `Q${d.index as number}`,
          prefix: `Q${d.index as number}`,
        } as const
      case 'period':
        return {
          id: `P${d.index as number}`,
          prefix: `P${d.index as number}`,
        } as const
      case 'week':
        const prefix = d.index
          .toString()
          .replace(/(\d+)-(\d+)/, (_, s1, s2) => `P${s1} W${s2}`)

        return {
          id: `${prefix.replace(/ /g, '') as `P${number}W${number}`}`,
          prefix,
        } as const

      default:
        throw new Error('not match types')
    }
  })()

  return {
    id,
    parentId: `${d.year} ${type} root` as const,
    type,
    year: d.year,
    displayName: `${prefix} (${d.startDate.format(
      'M/D/YY',
    )} to ${d.endDate.format('M/D/YY')})`,
    dateRange: {
      startDate: d.startDate,
      startDateStr: d.startDateStr,
      endDate: d.endDate,
      endDateStr: d.endDateStr,
    },
  }
}

const useDateOptions = (
  types: IDateType[],
  currentDate: ISetDateType['currentDate'],
  dateRangeMap: IDateRangeMap | null,
): IDateOptionType[] | null => {
  return useMemo(() => {
    if (!dateRangeMap) return null

    const yesterday = moment()
    const dates = Object.values(dateRangeMap)
      .filter((a) => a.startDate.isSameOrBefore(yesterday, 'days'))
      .sort((a, b) => b.key.localeCompare(a.key))
    const yearDates = dates.filter((d) => d.type === 'year')

    const isYearNeeded =
      types.includes('year') ||
      types.includes('quarter') ||
      types.includes('period') ||
      types.includes('week')

    return [
      {
        id: 'root' as const,
      },
      ...(!currentDate
        ? []
        : types.map((type) => {
            switch (type) {
              case 'custom_date':
              case 'custom_date_range':
                return [
                  {
                    id: type,
                    parentId: 'root',
                    type: type,
                    year: currentDate.startDate.year(),
                    displayName: 'Custom',
                    dateRange: {
                      startDate: currentDate.startDate,
                      startDateStr: currentDate.startDateStr,
                      endDate: currentDate.endDate,
                      endDateStr: currentDate.endDateStr,
                    },
                  } as IDateOptionNodeType<typeof type>,
                ]

              default:
                return []
            }
          })
      ).flat(),

      ...(isYearNeeded
        ? []
        : types
            .map((type) => {
              switch (type) {
                case 'this_week':
                case 'last_week':
                case 'trailing_7_days':
                case 'trailing_90_days':
                case 'trailing_364_days':
                case 'trailing_12_months':
                case 'yesterday': {
                  const filteredDate = dates.find((d) => d.type === type)

                  if (!filteredDate)
                    throw new Error(
                      `not found date type '${type}' in date ranges`,
                    )

                  return [
                    {
                      id: type,
                      parentId: 'root',
                      type,
                      year: filteredDate.year,
                      displayName: (type === 'trailing_364_days'
                        ? 'trailing_12_months'
                        : type
                      )
                        .split(/_/)
                        .map(_.upperFirst)
                        .join(' '),
                      dateRange: {
                        startDate: filteredDate.startDate,
                        startDateStr: filteredDate.startDateStr,
                        endDate: filteredDate.endDate,
                        endDateStr: filteredDate.endDateStr,
                      },
                    } as IDateOptionNodeType<typeof type>,
                  ]
                }

                default:
                  return []
              }
            })
            .flat()),

      ...(!isYearNeeded
        ? []
        : yearDates.map((d) => ({
            id: `${d.index as number} year root` as const,
            parentId: 'root' as const,
            displayName: d.index.toString(),
          }))),
      ...types
        .map((type) => {
          switch (type) {
            case 'year':
              return yearDates.map((d) => ({
                id: `${d.index as number}` as const,
                parentId: `${d.index as number} year root` as const,
                type: 'year' as const,
                year: d.index as number,
                displayName: 'Year',
                dateRange: {
                  startDate: d.startDate,
                  startDateStr: d.startDateStr,
                  endDate: d.endDate,
                  endDateStr: d.endDateStr,
                },
              }))

            case 'quarter':
            case 'period':
            case 'week': {
              const filteredDates = dates.filter((d) => d.type === type)

              return [
                ...yearDates.map((d) => ({
                  id: `${d.index as number} ${type} root` as const,
                  parentId: `${d.index as number} year root` as const,
                  displayName: _.upperFirst(type),
                })),
                ...filteredDates.map((d) => buildQPWOptions(type, d)),
              ]
            }

            case 'this_week':
            case 'last_week':
            case 'trailing_7_days':
            case 'trailing_90_days':
            case 'trailing_364_days':
            case 'trailing_12_months':
            case 'yesterday': {
              const filteredDate = dates.find((d) => d.type === type)

              if (!filteredDate)
                throw new Error(`not found date type '${type}' in date ranges`)

              if (!isYearNeeded) return []

              return [
                {
                  id: type,
                  parentId: `${filteredDate.year} year root` as const,
                  type,
                  year: filteredDate.year,
                  displayName: (type === 'trailing_364_days'
                    ? 'trailing_12_months'
                    : type
                  )
                    .split(/_/)
                    .map(_.upperFirst)
                    .join(' '),
                  dateRange: {
                    startDate: filteredDate.startDate,
                    startDateStr: filteredDate.startDateStr,
                    endDate: filteredDate.endDate,
                    endDateStr: filteredDate.endDateStr,
                  },
                },
              ]
            }

            default:
              return []
          }
        })
        .flat(),
    ]
  }, [types, dateRangeMap, currentDate])
}

export default useDateOptions
