import _ from 'lodash'

import WebLargeMain from './WebLargeMain'
import { IOrderBy, IStaffSale } from './index'

interface IProps {
  isLoading: boolean
  salesType: string
  setSalesType: any
  isStaffSalesExpanded: boolean
  setIsStaffSalesExpanded: any
  orderBy: IOrderBy
  setOrderBy: any
  staffSalesRowData: any
  storeTitle: string
  dayPart: string
}

const Main = ({
  isLoading,
  salesType,
  setSalesType,
  isStaffSalesExpanded,
  setIsStaffSalesExpanded,
  orderBy,
  setOrderBy,
  staffSalesRowData,
  storeTitle,
  dayPart,
}: IProps) => {
  return (
    <WebLargeMain
      isLoading={isLoading}
      salesType={salesType}
      setSalesType={setSalesType}
      isStaffSalesExpanded={isStaffSalesExpanded}
      setIsStaffSalesExpanded={setIsStaffSalesExpanded}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      staffSalesRowData={staffSalesRowData}
      storeTitle={storeTitle}
      dayPart={dayPart}
    />
  )
}

export default Main
