import { useMemo } from 'react'

import { useJscCorporateDevelopmentPlanData } from '../../table/hooks/useJscCorporateDevelopmentPlan'
import { IOptionsType } from '../types'

const useJscCorporateDevelopmentPlan = () => {
  const { data, loading } = useJscCorporateDevelopmentPlanData()

  return {
    data: useMemo(() => {
      const options: IOptionsType = [
        {
          value: 'ALL',
          label: 'All',
        },
      ]

      data?.listDirectorDevelopmentPlan.nodes.forEach(
        (d: { jobTitle: string }) => {
          if (
            d.jobTitle &&
            !options.some((o) => 'value' in o && o.value === d.jobTitle)
          )
            options.push({
              value: d.jobTitle,
              label: d.jobTitle,
            })
        },
      )

      return options
    }, [data]),
    loading,
  }
}

export default useJscCorporateDevelopmentPlan
