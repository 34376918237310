import { v4 as uuidv4 } from 'uuid'

import Trend from '../../Trend'
import { FROM_EXPO } from '../../constants'
import { IMessage } from '../../type'

export default function handleSalesmanshipKpiTrends(responseData: any) {
  if (responseData?.salesmanshipKpiTrends) {
    const answerMessage: IMessage = {
      id: uuidv4(),
      from: FROM_EXPO,
      content: <Trend data={responseData?.salesmanshipKpiTrends} />,
    }

    return answerMessage
  }

  return null
}
