import { useMemo, useState } from 'react'

import { IGuestReview } from '../../types'

export const ALL_TEXT = 'All'

const useModSelect = (dataSource: IGuestReview[]) => {
  const [nameSelected, setSelectedName] = useState(ALL_TEXT)
  const nameList = useMemo(
    () => [
      ALL_TEXT,
      ...dataSource
        ?.reduce((r, d) => {
          if (!d.employeesOnDuty) {
            return r
          }

          return d.employeesOnDuty.reduce(
            (res, e) =>
              e.employeeName && !res.includes(e.employeeName)
                ? [...res, e.employeeName]
                : res,
            r,
          )
        }, [] as string[])
        .sort((a, b) => {
          if (a === 'Unknown') return 1
          if (b === 'Unknown') return -1
          return a.localeCompare(b)
        }),
    ],
    [dataSource],
  )

  return {
    nameList,
    nameSelected,
    setSelectedName,
  }
}

export default useModSelect
