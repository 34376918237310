import _ from 'lodash'

import { useFetchCustomizedReportData } from 'pared/components/CustomizedReport'

import useConfig from './useConfig'

const useFetchPurchaseReportData = (
  locationId: number,
  startDate: string,
  endDate: string,
) => {
  const config = useConfig()
  const reportName = 'LOCATION_PURCHASE_TABLE_V2'

  const inputParams = {
    locationId,
    startDate,
    endDate,
  }

  const { reportDefinition, reportResult, isLoading } =
    useFetchCustomizedReportData(reportName, inputParams)

  let tableData = [...(reportResult?.tableData || [])].filter(
    ({ difference, isKeyItem }) =>
      difference > 0 && (config.hasKeyItems ? isKeyItem : true),
  )
  tableData.sort((a, b) => {
    return b.difference - a.difference
  })

  const topItems = tableData.slice(0, 3)

  return {
    reportDefinition,
    isLoading,
    reportResult,
    topItems,
  }
}

export default useFetchPurchaseReportData
