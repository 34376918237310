// FIXME: should add download-csv-v2
import { useMemo } from 'react'

import { useGroupFilter } from '../../groupFilter'
import useBurgerworksFlash, {
  burgerworksFlashConfigs,
} from '../../tableV2/hooks/burgerworks/useFlash'

export const burgerworksFlashV2Configs = {
  ...burgerworksFlashConfigs,
}

const useBurgerworksFlashV2 = () => {
  const { data, loading } = useBurgerworksFlash()
  const { groupFilter } = useGroupFilter()

  return {
    data: useMemo((): {
      source: Record<string, unknown>[]
      summary: Record<string, unknown> | null
    } | null => {
      if (!data) return null

      return {
        source: data
          .filter((d) => d.parentId === 'summary')
          .map((d) => {
            const groupInfo = groupFilter?.list?.find(
              (l) => l.id.toString() === d.id,
            )

            return {
              ...d,
              groupInfo,
            }
          }),
        summary: data.filter((d) => d.parentId === 'root')?.[0] || null,
      }
    }, [data, groupFilter]),
    loading,
  }
}

export default useBurgerworksFlashV2
