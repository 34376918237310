import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import {
  GET_DISPUTE_REASONS,
  IDisputeReasonType,
  IDisputeReasonVariablesType,
} from '../gqls'

export interface IDataSourceType {
  reason: string
  totalCount: number
  totalAmount: number
  rowIndex: number
}

interface IProps {
  startDate: string
  endDate: string
  locationId: number
}

export default ({
  startDate,
  endDate,
  locationId,
}: IProps): {
  dataSource: IDataSourceType[]
  loading: boolean
} => {
  const { data, loading } = useQuery<
    IDisputeReasonType,
    IDisputeReasonVariablesType
  >(GET_DISPUTE_REASONS, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_ids: [locationId] },
    },
  })

  const dataSource = useMemo(() => {
    if (!data?.getDisputeReasons.nodes) return []

    return data.getDisputeReasons.nodes.map((row, idx) => ({
      ...row,
      rowIndex: idx,
    }))
  }, [data, loading])

  return {
    dataSource,
    loading,
  }
}
