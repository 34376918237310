import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import { IDateRange } from 'pared/data/getDateRanges'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'

interface IProps {
  kpi: string
  onSelectKpi: any
  navParams: any
  selectedDateRange: IDateRange
  storeName: string
  shouldShowScorecard: boolean
}

const Main = ({
  kpi,
  onSelectKpi,
  navParams,
  selectedDateRange,
  storeName,
  shouldShowScorecard,
}: IProps) => (
  <>
    <LargeScreen>
      <WebLargeMain
        kpi={kpi}
        onSelectKpi={onSelectKpi}
        navParams={navParams}
        selectedDateRange={selectedDateRange}
        storeName={storeName}
        shouldShowScorecard={shouldShowScorecard}
      />
    </LargeScreen>
    <SmallScreen>
      <WebSmallMain
        kpi={kpi}
        onSelectKpi={onSelectKpi}
        navParams={navParams}
        selectedDateRange={selectedDateRange}
        storeName={storeName}
        shouldShowScorecard={shouldShowScorecard}
      />
    </SmallScreen>
  </>
)

export default Main
