import { INavParams } from 'pared/Routes/navParams'
import { LargeScreen, SmallScreen } from 'pared/components/responsive'
import { IPeriod } from 'pared/data/getPeriods'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import { ILocationData, ISalesmanship } from './index'

interface IProps {
  isLoading: boolean
  navParams: INavParams
  locationData: ILocationData | null
  errorMessage: string
  salesmanships: ISalesmanship[]
  dayPart: string
  setDayPart: any
  isLeaderboardsExpanded: boolean
  setIsLeaderboardsExpanded: any
  filterType: string
  setFilterType: any
  numDaysWorked: number
  setNumDaysWorked: any
  selectedPeriod: IPeriod | null
  setSelectedPeriod: any
  dateRangeStartDate: any
  setDateRangeStartDate: any
  dateRangeEndDate: any
  setDateRangeEndDate: any
  isTotalSalesExpanded: boolean
  setIsTotalSalesExpanded: any
  isSalesPerHourExpanded: boolean
  setIsSalesPerHourExpanded: any
  isPpaExpanded: boolean
  setIsPpaExpanded: any
  selectedValue: string
  setSelectedValue: (selectedValue: string) => void
}

const Main = ({
  isLoading,
  navParams,
  locationData,
  errorMessage,
  salesmanships,
  dayPart,
  setDayPart,
  isLeaderboardsExpanded,
  setIsLeaderboardsExpanded,
  filterType,
  setFilterType,
  numDaysWorked,
  setNumDaysWorked,
  selectedPeriod,
  setSelectedPeriod,
  dateRangeStartDate,
  setDateRangeStartDate,
  dateRangeEndDate,
  setDateRangeEndDate,
  isTotalSalesExpanded,
  setIsTotalSalesExpanded,
  isSalesPerHourExpanded,
  setIsSalesPerHourExpanded,
  isPpaExpanded,
  setIsPpaExpanded,
  selectedValue,
  setSelectedValue,
}: IProps) => {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          isLoading={isLoading}
          navParams={navParams}
          locationData={locationData}
          errorMessage={errorMessage}
          salesmanships={salesmanships}
          dayPart={dayPart}
          setDayPart={setDayPart}
          isLeaderboardsExpanded={isLeaderboardsExpanded}
          setIsLeaderboardsExpanded={setIsLeaderboardsExpanded}
          filterType={filterType}
          setFilterType={setFilterType}
          numDaysWorked={numDaysWorked}
          setNumDaysWorked={setNumDaysWorked}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          dateRangeStartDate={dateRangeStartDate}
          dateRangeEndDate={dateRangeEndDate}
          setDateRangeStartDate={setDateRangeStartDate}
          setDateRangeEndDate={setDateRangeEndDate}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          isLoading={isLoading}
          navParams={navParams}
          locationData={locationData}
          errorMessage={errorMessage}
          salesmanships={salesmanships}
          dayPart={dayPart}
          setDayPart={setDayPart}
          filterType={filterType}
          setFilterType={setFilterType}
          numDaysWorked={numDaysWorked}
          setNumDaysWorked={setNumDaysWorked}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          dateRangeStartDate={dateRangeStartDate}
          dateRangeEndDate={dateRangeEndDate}
          setDateRangeStartDate={setDateRangeStartDate}
          setDateRangeEndDate={setDateRangeEndDate}
          isTotalSalesExpanded={isTotalSalesExpanded}
          setIsTotalSalesExpanded={setIsTotalSalesExpanded}
          isSalesPerHourExpanded={isSalesPerHourExpanded}
          setIsSalesPerHourExpanded={setIsSalesPerHourExpanded}
          isPpaExpanded={isPpaExpanded}
          setIsPpaExpanded={setIsPpaExpanded}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />
      </SmallScreen>
    </>
  )
}

export default Main
