import { useMemo } from 'react'

import COLORS from 'pared/constants/colors'
import {
  cavaKpiDefs,
  defaultKpiDefs,
  irmgBkKpiDefs,
  irmgPopKpiDefs,
  jitbKpiDefs,
  localFavoriteKpiDefs,
  mwbKpiDefs,
  primantiKpiDefs,
  wingitnorthKpiDefs,
  wingstopKpiDefs,
} from 'pared/customer/salesmanshipKpiDefs'
import { getBrand } from 'pared/utils/brand'

const defaultConfig = {
  productivityCoefficient: {
    isVisible: false,
  },
  salesmanshipChart: {
    isVisible: true,
    yLeftAxisKpiDefs: [],
  },
  upsizing: false,
  frozenCoke: false,
  kpiDefs: [defaultKpiDefs.checkAvg, defaultKpiDefs.salesPerHour],
  breakdownTableColumnDefs: [
    defaultKpiDefs.checkAvg,
    defaultKpiDefs.salesPerHour,
  ],
  speedOfService: {
    isVisible: false,
    windows: [],
    sosChart: null,
  },
}

// TODO: import this into another file
const irmgBkConfig = {
  ...defaultConfig,
  kpiDefs: [
    defaultKpiDefs.checkAvg,
    irmgBkKpiDefs.baconAttachmentRate,
    irmgBkKpiDefs.cheeseAttachmentRate,
    irmgBkKpiDefs.treatAttachmentRate,
    defaultKpiDefs.salesPerHour,
  ],
  upsizing: true,
  breakdownTableColumnDefs: [
    defaultKpiDefs.checkAvg,
    irmgBkKpiDefs.baconAttachmentRate,
    irmgBkKpiDefs.cheeseAttachmentRate,
    irmgBkKpiDefs.treatAttachmentRate,
    defaultKpiDefs.salesPerHour,
  ],
  salesmanshipChart: {
    isVisible: true,
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...irmgBkKpiDefs.baconAttachmentRate,
        color: COLORS.Plum,
        hidden: true,
      },
      {
        ...irmgBkKpiDefs.cheeseAttachmentRate,
        color: COLORS.Mango,
        hidden: true,
      },
      { ...irmgBkKpiDefs.treatAttachmentRate, color: COLORS.Basil },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
}

const jscBkConfig = {
  ...defaultConfig,
  kpiDefs: [
    defaultKpiDefs.checkAvg,
    irmgBkKpiDefs.baconAttachmentRate,
    irmgBkKpiDefs.cheeseAttachmentRate,
    irmgBkKpiDefs.treatAttachmentRate,
    irmgBkKpiDefs.beverageAttachmentRate,
    irmgBkKpiDefs.shakeAttachmentRate,
    defaultKpiDefs.salesPerHour,
  ],
  upsizing: true,
  breakdownTableColumnDefs: [
    defaultKpiDefs.checkAvg,
    irmgBkKpiDefs.baconAttachmentRate,
    irmgBkKpiDefs.cheeseAttachmentRate,
    irmgBkKpiDefs.treatAttachmentRate,
    irmgBkKpiDefs.beverageAttachmentRate,
    irmgBkKpiDefs.shakeAttachmentRate,
    defaultKpiDefs.salesPerHour,
  ],
  salesmanshipChart: {
    isVisible: true,
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...irmgBkKpiDefs.baconAttachmentRate,
        color: COLORS.Plum,
        hidden: true,
      },
      {
        ...irmgBkKpiDefs.cheeseAttachmentRate,
        color: COLORS.Mango,
        hidden: true,
      },
      { ...irmgBkKpiDefs.treatAttachmentRate, color: COLORS.Basil },
      {
        ...irmgBkKpiDefs.beverageAttachmentRate,
        color: COLORS.DarkOrchid,
        hidden: true,
      },
      {
        ...irmgBkKpiDefs.shakeAttachmentRate,
        color: COLORS.Acai,
        hidden: true,
      },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
}

const wingitnorthConfig = {
  ...defaultConfig,
  kpiDefs: [
    defaultKpiDefs.checkAvg,
    wingitnorthKpiDefs.cornAttachmentRate,
    wingitnorthKpiDefs.waterAttachmentRate,
    wingitnorthKpiDefs.saucesAttachmentRate,
    wingitnorthKpiDefs.sticksAttachmentRate,
    defaultKpiDefs.salesPerHour,
  ],
  breakdownTableColumnDefs: [
    defaultKpiDefs.checkAvg,
    wingitnorthKpiDefs.cornAttachmentRate,
    wingitnorthKpiDefs.waterAttachmentRate,
    wingitnorthKpiDefs.saucesAttachmentRate,
    wingitnorthKpiDefs.sticksAttachmentRate,
    defaultKpiDefs.salesPerHour,
  ],
  salesmanshipChart: {
    isVisible: true,
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...wingitnorthKpiDefs.cornAttachmentRate,
        color: COLORS.Plum,
      },
      {
        ...wingitnorthKpiDefs.waterAttachmentRate,
        color: COLORS.Mango,
      },
      {
        ...wingitnorthKpiDefs.saucesAttachmentRate,
        color: COLORS.Basil,
      },
      {
        ...wingitnorthKpiDefs.sticksAttachmentRate,
        color: COLORS.Basil,
      },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
}

const wingstopConfig = {
  ...defaultConfig,
  subtitle: 'Walk In, Call In Only',
  useLocationData: true,
  salesmanshipChart: {
    isVisible: true,
    yLeftAxisKpiDefs: [
      {
        ...defaultKpiDefs.checkAvg,
        color: COLORS.Plum,
        hidden: true,
      },
      wingstopKpiDefs.friesCheckAvg,
      wingstopKpiDefs.cornCheckAvg,
      wingstopKpiDefs.browniesCheckAvg,
    ],
    yLeftAxisValueType: 'cent',
    yLeftAxisLabel: 'Check Avg',
  },
  kpiDefs: [
    defaultKpiDefs.checkAvg,
    wingstopKpiDefs.friesCheckAvg,
    wingstopKpiDefs.cornCheckAvg,
    wingstopKpiDefs.browniesCheckAvg,
  ],
  breakdownTableColumnDefs: [
    defaultKpiDefs.checkAvg,
    wingstopKpiDefs.friesCheckAvg,
    wingstopKpiDefs.cornCheckAvg,
    wingstopKpiDefs.browniesCheckAvg,
  ],
}

const pjDemoConfig = {
  ...defaultConfig,
  productivityCoefficient: {
    isVisible: true,
  },
}

const cavaConfig = {
  ...defaultConfig,
  kpiDefs: [
    {
      ...defaultKpiDefs.totalPpa,
      name: 'Non-Digital PPA',
    },
    defaultKpiDefs.checkAvg,
    cavaKpiDefs.entreePremiumIncidents,
    cavaKpiDefs.curatedIncidents,
    cavaKpiDefs.sideAttachmentRate,
    cavaKpiDefs.dessertAttachmentRate,
    cavaKpiDefs.beverageAttachmentRate,
  ],
  breakdownTableColumnDefs: [
    {
      ...defaultKpiDefs.totalPpa,
      name: 'Non-Digital PPA',
    },
    defaultKpiDefs.checkAvg,
    cavaKpiDefs.entreePremiumIncidents,
    cavaKpiDefs.curatedIncidents,
    cavaKpiDefs.sideAttachmentRate,
    cavaKpiDefs.dessertAttachmentRate,
    cavaKpiDefs.beverageAttachmentRate,
  ],
  salesmanshipChart: {
    isVisible: true,
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...cavaKpiDefs.sideAttachmentRate,
        color: COLORS.Plum,
        hidden: true,
      },
      {
        ...cavaKpiDefs.dessertAttachmentRate,
        color: COLORS.Mango,
        hidden: true,
      },
      { ...cavaKpiDefs.beverageAttachmentRate, color: COLORS.Basil },
    ],
    yRightAxisLabel: 'Non-Digital PPA',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: {
      ...defaultKpiDefs.totalPpa,
      name: 'Non-Digital PPA',
      color: COLORS.Pomodoro,
    },
  },
  entreesPerLaborHour: {
    isVisible: true,
  },
}

const irmgPopConfig = {
  ...defaultConfig,
  kpiDefs: [
    defaultKpiDefs.checkAvg,
    irmgPopKpiDefs.comboRate,
    irmgPopKpiDefs.drinkRate,
    irmgPopKpiDefs.dessertRate,
    irmgPopKpiDefs.sideRate,
    defaultKpiDefs.salesPerHour,
  ],
  upsizing: false,
  breakdownTableColumnDefs: [
    defaultKpiDefs.checkAvg,
    irmgPopKpiDefs.comboRate,
    irmgPopKpiDefs.drinkRate,
    irmgPopKpiDefs.dessertRate,
    irmgPopKpiDefs.sideRate,
    defaultKpiDefs.salesPerHour,
  ],
  salesmanshipChart: {
    isVisible: true,
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...irmgPopKpiDefs.comboRate,
        color: COLORS.Mango,
      },
      {
        ...irmgPopKpiDefs.drinkRate,
        color: COLORS.Acai,
      },
      { ...irmgPopKpiDefs.dessertRate, color: COLORS.Basil },
      { ...irmgPopKpiDefs.sideRate, color: COLORS.DarkOrchid },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
}

const supremePopConfig = {
  ...defaultConfig,
  kpiDefs: [
    defaultKpiDefs.checkAvg,
    irmgPopKpiDefs.comboRate,
    irmgPopKpiDefs.drinkRate,
    irmgPopKpiDefs.dessertRate,
    irmgPopKpiDefs.sideRate,
    irmgPopKpiDefs.wingRate,
    defaultKpiDefs.salesPerHour,
  ],
  upsizing: false,
  breakdownTableColumnDefs: [
    defaultKpiDefs.checkAvg,
    irmgPopKpiDefs.comboRate,
    irmgPopKpiDefs.drinkRate,
    irmgPopKpiDefs.dessertRate,
    irmgPopKpiDefs.sideRate,
    irmgPopKpiDefs.wingRate,
    defaultKpiDefs.salesPerHour,
  ],
  salesmanshipChart: {
    isVisible: true,
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...irmgPopKpiDefs.comboRate,
        color: COLORS.Mango,
      },
      {
        ...irmgPopKpiDefs.drinkRate,
        color: COLORS.Acai,
      },
      { ...irmgPopKpiDefs.dessertRate, color: COLORS.Basil },
      { ...irmgPopKpiDefs.sideRate, color: COLORS.DarkOrchid },
      { ...irmgPopKpiDefs.wingRate, color: COLORS.Plum },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
  speedOfService: {
    isVisible: true,
    windows: [{ type: 'Pickup Window', displayName: 'Pickup (seconds)' }],
    sosChart: {
      isVisible: true,
      yLeftAxisLabel: 'Average Window Time (second)',
      yLeftAxisKpiDefs: [
        {
          key: 'Pickup Window',
          name: 'Pickup',
          color: COLORS.Basil,
        },
        {
          key: 'Menu Board',
          name: 'Menu Board',
          color: COLORS.Plum,
          hidden: true,
        },
        {
          key: 'Line',
          name: 'Line',
          color: COLORS.Mango,
          hidden: true,
        },
      ],
    },
  },
}

const ghaiPopConfig = {
  ...defaultConfig,
  kpiDefs: [
    defaultKpiDefs.checkAvg,
    irmgPopKpiDefs.drinkRate,
    irmgPopKpiDefs.dessertRate,
    irmgPopKpiDefs.sideRate,
    defaultKpiDefs.salesPerHour,
  ],
  upsizing: true,
  breakdownTableColumnDefs: [
    defaultKpiDefs.checkAvg,
    irmgPopKpiDefs.drinkRate,
    irmgPopKpiDefs.dessertRate,
    irmgPopKpiDefs.sideRate,
    defaultKpiDefs.salesPerHour,
  ],
  salesmanshipChart: {
    isVisible: true,
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...irmgPopKpiDefs.drinkRate,
        color: COLORS.Acai,
      },
      { ...irmgPopKpiDefs.dessertRate, color: COLORS.Basil },
      { ...irmgPopKpiDefs.sideRate, color: COLORS.DarkOrchid },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
}

const jitbConfig = {
  ...defaultConfig,
  kpiDefs: [
    defaultKpiDefs.checkAvg,
    jitbKpiDefs.baconAttachmentRate,
    jitbKpiDefs.cheeseAttachmentRate,
    jitbKpiDefs.beverageAttachmentRate,
    defaultKpiDefs.salesPerHour,
  ],
  upsizing: true,
  breakdownTableColumnDefs: [
    defaultKpiDefs.checkAvg,
    jitbKpiDefs.baconAttachmentRate,
    jitbKpiDefs.cheeseAttachmentRate,
    jitbKpiDefs.beverageAttachmentRate,
    defaultKpiDefs.salesPerHour,
  ],
  salesmanshipChart: {
    isVisible: true,
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...jitbKpiDefs.baconAttachmentRate,
        color: COLORS.Plum,
        hidden: true,
      },
      {
        ...jitbKpiDefs.cheeseAttachmentRate,
        color: COLORS.Mango,
        hidden: true,
      },
      { ...jitbKpiDefs.beverageAttachmentRate, color: COLORS.Basil },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
}

const mwbConfig = {
  ...defaultConfig,
  upsizing: true,
  coffeeTable: true,
  kpiDefs: [
    defaultKpiDefs.checkAvg,
    mwbKpiDefs.dessertsAttachmentRate,
    mwbKpiDefs.beveragesAttachmentRate,
    mwbKpiDefs.baconAttachmentRate,
    mwbKpiDefs.cheeseAttachmentRate,
    mwbKpiDefs.jalapenoAttachmentRate,
    defaultKpiDefs.salesPerHour,
  ],
  breakdownTableColumnDefs: [
    defaultKpiDefs.checkAvg,
    mwbKpiDefs.dessertsAttachmentRate,
    mwbKpiDefs.beveragesAttachmentRate,
    mwbKpiDefs.baconAttachmentRate,
    mwbKpiDefs.cheeseAttachmentRate,
    mwbKpiDefs.jalapenoAttachmentRate,
    defaultKpiDefs.salesPerHour,
  ],
  salesmanshipChart: {
    isVisible: true,
    yLeftAxisLabel: 'Attachment Rate %',
    yLeftAxisValueType: 'percent',
    yLeftAxisKpiDefs: [
      {
        ...mwbKpiDefs.dessertsAttachmentRate,
        color: COLORS.Plum,
      },
      {
        ...mwbKpiDefs.beveragesAttachmentRate,
        color: COLORS.Mango,
      },
      {
        ...mwbKpiDefs.baconAttachmentRate,
        color: COLORS.Basil,
      },
      {
        ...mwbKpiDefs.cheeseAttachmentRate,
        color: COLORS.Pomodoro,
      },
      {
        ...mwbKpiDefs.jalapenoAttachmentRate,
        color: COLORS.DarkOrchid,
      },
    ],
    yRightAxisLabel: 'Check Avg',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: { ...defaultKpiDefs.checkAvg, color: COLORS.Pomodoro },
  },
}

const getLocalFavoriteConfig = (
  keys: (keyof typeof localFavoriteKpiDefs)[],
) => ({
  ...defaultConfig,
  kpiDefs: [
    defaultKpiDefs.checkAvg,
    defaultKpiDefs.totalPpa,
    ...keys.map((key) => localFavoriteKpiDefs[key]),
    localFavoriteKpiDefs.salesPerLaborHour,
  ],
  breakdownTableColumnDefs: [
    defaultKpiDefs.totalPpa,
    defaultKpiDefs.checkAvg,
    ...keys.map((key) => localFavoriteKpiDefs[key]),
    localFavoriteKpiDefs.salesPerLaborHour,
  ],
  salesmanshipChart: {
    isVisible: true,
    yLeftAxisLabel: 'PPA($)',
    yLeftAxisValueType: 'cent',
    yLeftAxisKpiDefs: [
      {
        ...defaultKpiDefs.totalPpa,
        color: COLORS.Plum,
      },
      ...keys.map((key, index) => ({
        ...localFavoriteKpiDefs[key],
        color: COLORS.COLOR_HUE[index],
        hidden: true,
      })),
    ],
    yRightAxisLabel: 'Sales Per Labor Hour ($/hr)',
    yRightAxisValueType: 'cent',
    yRightAxisKpiDef: {
      ...defaultKpiDefs.salesPerHour,
      color: COLORS.Pomodoro,
    },
  },
})

const config = {
  rmg: defaultConfig,
  sullivans: defaultConfig,
  eddiemerlots: defaultConfig,
  papajohns: defaultConfig,
  papajohns_demo: pjDemoConfig,
}

const useSalesmanshipConfig = (): any => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'rmg':
      case 'rmg_nso':
        return config.rmg
      case 'sullivans':
      case 'sullivans_nso':
        return config.sullivans
      case 'eddiemerlots':
      case 'eddiemerlots_nso':
        return config.eddiemerlots
      case 'papajohns':
        return config.papajohns
      case 'papajohns_demo':
        return config.papajohns_demo
      case 'supreme_bk':
        return {
          ...irmgBkConfig,
          frozenCoke: true,
          speedOfService: {
            isVisible: true,
            windows: [
              { type: 'Pickup Window', displayName: 'Pickup (seconds)' },
            ],
            sosChart: {
              isVisible: true,
              yLeftAxisLabel: 'Average Window Time (second)',
              yLeftAxisKpiDefs: [
                {
                  key: 'Pickup Window',
                  name: 'Pickup',
                  color: COLORS.Basil,
                },
                {
                  key: 'Menu Board',
                  name: 'Menu Board',
                  color: COLORS.Plum,
                  hidden: true,
                },
                {
                  key: 'Line',
                  name: 'Line',
                  color: COLORS.Mango,
                  hidden: true,
                },
                {
                  key: 'End to End',
                  name: 'End to End',
                  color: COLORS.Acai,
                  hidden: true,
                },
              ],
            },
          },
        }
      case 'timoney_bk':
      case 'mbn_bk':
      case 'sandbox_bk':
        return {
          ...irmgBkConfig,
          speedOfService: {
            isVisible: true,
            windows: [
              { type: 'Pickup Window', displayName: 'Pickup (seconds)' },
            ],
            sosChart: {
              isVisible: true,
              yLeftAxisLabel: 'Average Window Time (second)',
              yLeftAxisKpiDefs: [
                {
                  key: 'Pickup Window',
                  name: 'Pickup',
                  color: COLORS.Basil,
                },
                {
                  key: 'Menu Board',
                  name: 'Menu Board',
                  color: COLORS.Plum,
                  hidden: true,
                },
                {
                  key: 'Line',
                  name: 'Line',
                  color: COLORS.Mango,
                  hidden: true,
                },
                {
                  key: 'End to End',
                  name: 'End to End',
                  color: COLORS.Acai,
                  hidden: true,
                },
              ],
            },
          },
        }

      case 'irmg_bk':
      case 'viking_bk':
      case 'demo_bk':
        return irmgBkConfig

      case 'jsc_bk':
      case 'jsc_bk_nso':
        return {
          ...jscBkConfig,
          speedOfService: {
            isVisible: true,
            windows: [
              { type: 'Pickup Window', displayName: 'Window Time (seconds)' },
            ],
            sosChart: {
              isVisible: true,
              yLeftAxisLabel: 'Window Time (seconds)',
              yLeftAxisKpiDefs: [
                {
                  key: 'Pickup Window',
                  name: 'Window Time (seconds)',
                  color: COLORS.Basil,
                },
              ],
            },
          },
        }

      case 'ghai_pop':
        return {
          ...ghaiPopConfig,
          speedOfService: {
            isVisible: true,
            windows: [
              { type: 'Pickup Window', displayName: 'Window Time (seconds)' },
            ],
            sosChart: {
              isVisible: true,
              yLeftAxisLabel: 'Window Time (seconds)',
              yLeftAxisKpiDefs: [
                {
                  key: 'Pickup Window',
                  name: 'Window Time (seconds)',
                  color: COLORS.Basil,
                },
              ],
            },
          },
        }

      case 'supreme_pop':
      case 'ace_pop':
        return supremePopConfig

      case 'irmg_pop':
      case 'demo_pop':
        return irmgPopConfig

      case 'wingitnorth':
        return wingitnorthConfig
      case 'wingstop':
        return wingstopConfig

      case 'cava':
        return cavaConfig

      case 'jitb':
        return jitbConfig

      // Local Favorite
      case 'lfr_ef':
        return getLocalFavoriteConfig([
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'lfr_tr':
        return getLocalFavoriteConfig([
          'appetizerPpa',
          'lbwPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'lfr_sn':
        return getLocalFavoriteConfig([
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'lfr_lv':
        return getLocalFavoriteConfig([
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'lfr_vb':
        return getLocalFavoriteConfig([
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'lfr_tu':
        return getLocalFavoriteConfig([
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
        ])
      case 'lfr_ws':
        return getLocalFavoriteConfig([
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
        ])
      case 'lfr_jn':
        return getLocalFavoriteConfig([
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
        ])
      case 'lfr_mm':
        return getLocalFavoriteConfig([
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'lfr_dt':
        return getLocalFavoriteConfig([
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'lfr_uv':
        return getLocalFavoriteConfig([
          'appetizerPpa',
          'lbwPpa',
          'dessertPpa',
          'naBeveragePpa',
          'sidesPpa',
        ])
      case 'primanti':
        return {
          ...defaultConfig,
          kpiDefs: [
            defaultKpiDefs.checkAvg,
            primantiKpiDefs.appetizersAndSidesAttachmentRate,
            primantiKpiDefs.beverageAttachmentRate,
            primantiKpiDefs.friesAttachmentRate,
            primantiKpiDefs.lbwAttachmentRate,
          ],
          breakdownTableColumnDefs: [
            defaultKpiDefs.checkAvg,
            primantiKpiDefs.appetizersAndSidesAttachmentRate,
            primantiKpiDefs.beverageAttachmentRate,
            primantiKpiDefs.friesAttachmentRate,
            primantiKpiDefs.lbwAttachmentRate,
          ],
          salesmanshipChart: {
            isVisible: true,
            yLeftAxisLabel: 'PPA($)',
            yLeftAxisValueType: 'cent',
            yLeftAxisKpiDefs: [
              {
                ...defaultKpiDefs.checkAvg,
                color: COLORS.Plum,
              },
              ...[
                primantiKpiDefs.appetizersAndSidesAttachmentRate,
                primantiKpiDefs.beverageAttachmentRate,
                primantiKpiDefs.friesAttachmentRate,
                primantiKpiDefs.lbwAttachmentRate,
              ].map((def, index) => ({
                ...def,
                color: COLORS.COLOR_HUE[index],
                hidden: true,
              })),
            ],
          },
        }
      case 'bibibop':
        return jscBkConfig
      case 'mwb':
      case 'burgerworks':
        return mwbConfig
      default:
        return defaultConfig
    }
  }, [brand])
}
export default useSalesmanshipConfig
