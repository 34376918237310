import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@mui/material/Autocomplete'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import React from 'react'
import styled from 'styled-components'

import { LargeScreen, SmallScreen } from 'pared/components/responsive'

interface IProps {
  allItems: { name: string; type: string }[]
  selectedItem: { name: string; type: string } | null
  onItemChange: (event: any, value: any) => void
  isEnabled: boolean
  isGrouped?: boolean
  isLoading?: boolean
  isLarge?: boolean
}

const useStyles = makeStyles(() => ({
  text: {
    fontFamily: 'Lexend-Regular',
  },
}))

const CustomPaper = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Paper
      sx={{
        '& .MuiAutocomplete-listbox': {
          fontFamily: 'Lexend-Regular',
          fontSize: 16,
        },
      }}
    >
      {children}
    </Paper>
  )
}

function Main({
  allItems,
  selectedItem,
  onItemChange,
  isEnabled,
  isGrouped,
  isLoading,
  isLarge,
}: IProps) {
  const classes = useStyles()

  const options = allItems

  let selector = null
  if (options && !isLoading) {
    selector = (
      <Autocomplete
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            size={isLarge ? 'medium' : 'small'}
            sx={{
              '& .MuiAutocomplete-input': {
                fontFamily: 'Lexend-Regular',
              },
            }}
          />
        )}
        onChange={onItemChange}
        value={selectedItem}
        disabled={!isEnabled}
        PaperComponent={CustomPaper}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        groupBy={isGrouped ? (option) => option.type : undefined}
      />
    )
  } else {
    selector = (
      <MainSelect
        displayEmpty
        value="loading"
        disabled
        className={classes.text}
      >
        <MenuItem value="loading" className={classes.text}>
          Loading ...
        </MenuItem>
      </MainSelect>
    )
  }

  return (
    <>
      <LargeScreen>
        <LargeScreenFormControl
          variant="outlined"
          style={{ width: 250 }}
          size={isLarge ? 'medium' : 'small'}
        >
          {selector}
        </LargeScreenFormControl>
      </LargeScreen>
      <SmallScreen>
        <SmallScreenFormControl
          variant="outlined"
          style={{ width: '100%' }}
          size="small"
        >
          {selector}
        </SmallScreenFormControl>
      </SmallScreen>
    </>
  )
}

const LargeScreenFormControl = styled(FormControl)`
  background-color: white;
`

const SmallScreenFormControl = styled(FormControl)`
  background-color: white;
`

const MainSelect = styled(Select)`
  border-radius: 4px;
`

export default Main
