import React, { useEffect } from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import { feature, page } from 'pared/analytics/user'
import List from 'pared/components/basicUi/List'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import useDateRangeConfig from 'pared/components/nav/DateRangeSelector/hooks/useConfig'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { TYPE_CUSTOM } from 'pared/data/getDateRanges'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import useGetDirectors from 'pared/hooks/useGetDirectors'
import DirectorFilterSelector from 'pared/pages/StoreList/DirectorFilterSelector'
import { scrollToTop } from 'pared/utils/web'

import CashControlTable from './CashControlTable'
import CashControlTrend from './CashControlTrend'
import DiscountTable from './DiscountTable'
import DiscountTrend from './DiscountTrend'
import useKpis from './hooks/useKpis'

interface IPropsType {
  navParams: INavParams
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 25px 0px;
    }
  }
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const Container = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px;
  }
`

export const SubHeader = styled.div`
  margin: 83px 0px 20px;
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    display: block;
    width: 100%;
    margin: 60px 0px 0px;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
`

const StyledList = styled(List)`
  margin: 46px 0px 0px;
  width: 100%;
  max-width: 930px;
`

const VerticalSpacer60px = styled.div`
  width: 100%;
  height: 60px;
`

const CorporateLossPrevention = ({ navParams }: IPropsType) => {
  const dateRange = useGetDateRange({ isCustomAllowed: true })
  const { dateRangeOptions } = useDateRangeConfig()
  if (!dateRangeOptions.includes(TYPE_CUSTOM)) {
    dateRangeOptions.push(TYPE_CUSTOM)
  }

  const startDate = dateRange?.startDateStr
  const endDate = dateRange?.endDateStr

  const {
    director,
    directors,
    directorChange,
    locationGroupIds,
    locationGroupTypes,
  } = useGetDirectors()
  const isBreakdownByDirectors = director?.employeeId <= -2

  const kpis = useKpis(
    locationGroupIds,
    isBreakdownByDirectors,
    startDate,
    endDate,
  )

  useEffect(() => {
    if (navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl)
      feature.used('Corporate Loss Prevention')
    }
  }, [navParams.pageUrl])

  return (
    <>
      <Header>
        <div>
          <h1>Loss Prevention</h1>
        </div>

        <Selectors>
          <DirectorFilterSelector
            selectedDirector={director}
            allDirectors={directors}
            onDirectorChange={directorChange}
          />

          <DateRangeSelector
            navParams={navParams}
            dateRangeOptions={dateRangeOptions}
          />
        </Selectors>
      </Header>

      <Container>
        <StyledList title="KPIs" items={kpis} />

        <VerticalSpacer60px />

        <DiscountTable
          startDate={startDate}
          endDate={endDate}
          locationGroupIds={locationGroupIds}
          isBreakdownByDirectors={isBreakdownByDirectors}
          locationGroupType={locationGroupTypes?.[0]}
        />

        <VerticalSpacer60px />

        <DiscountTrend
          endDate={endDate}
          locationGroupIds={locationGroupIds}
          isBreakdownByDirectors={isBreakdownByDirectors}
        />

        <SubHeader>
          <h1>CASH</h1>
        </SubHeader>

        <CashControlTable
          startDate={startDate}
          endDate={endDate}
          locationGroupIds={locationGroupIds}
          isBreakdownByDirectors={isBreakdownByDirectors}
          locationGroupType={locationGroupTypes?.[0]}
        />

        <VerticalSpacer60px />

        <CashControlTrend
          endDate={endDate}
          locationGroupIds={locationGroupIds}
          isBreakdownByDirectors={isBreakdownByDirectors}
        />
      </Container>
    </>
  )
}

export default React.memo(CorporateLossPrevention)
