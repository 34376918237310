import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { track } from 'pared/analytics/corporateTeam'
import { feature, page } from 'pared/analytics/user'
import {
  PERFORMANCE_OPTIONS,
  UNFILLED_MANAGERIAL_POSITION_OPTIONS,
} from 'pared/constants'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import {
  RAW_LIST_LOCATION_AVG_HOURLY_RATES,
  RAW_LIST_LOCATION_GROUP_AVG_HOURLY_RATES,
} from 'pared/constants/raw_data/avgHourlyRates'
import getDateRanges, { IDateRange } from 'pared/data/getDateRanges'
import getDirectors, { IDirector } from 'pared/data/getDirectors'
import useGetDirectors from 'pared/hooks/useGetDirectors'
import { getBrand } from 'pared/utils/brand'
import { getLocationCode, getLocationName } from 'pared/utils/location'
import { toPercentString } from 'pared/utils/number'
import { hasPrivilegeToViewEmployeeFeedback } from 'pared/utils/user'
import { scrollToTop } from 'pared/utils/web'

import Main from './Main'
import {
  LIST_LOCATION_GROUP_TEAM_KPIS,
  LIST_LOCATION_MANAGER_REVIEW,
  LIST_LOCATION_TEAM_KPIS,
  LIST_LOCATION_UNFILLED_MANAGERIAL_POSITIONS,
} from './gql'
import useTeamConfig from './hooks/useTeamConfig'

export interface IGlobalCurrentTeamKpis {
  annualizedHourlyTurnoverRate: string
  annualizedSalariedTurnoverRate: string
  managerParText: string
  salariedTurnoverText: string
  hourlyTurnoverText: string
  month: string
}

export interface IDetailTeamKpi {
  doEmployeeId?: number
  locationId?: number
  annualizedHourlyTurnoverRate: string
  annualizedSalariedTurnoverRate: string
  hourlyTerminatedCountInThisMonth: number
  managerPar: number
  totalSalariedCount: number
  monthlyHourlyTurnoverRate: string
  salariedTerminatedCountInThisMonth: number
  monthlySalariedTurnoverRate: string
  formattedName: string
}

export interface IUnfilledManagerialPosition {
  positionId: number
  candidateName: string
  doName: string
  doId: number
  filledPendingStartDate: string
  managerRoleName: string
  storeId: number
  storeName: string
  trainingStoreId: number
  trainingStoreName: string
  vacantFromNow: string
}

export interface IManagerReview {
  reviewId: number
  doId: number
  doName: string
  revieweeId: number
  revieweeName: string
  performance: string
  storeId: number
  storeName: string
  reviewedAt: string
  roleName: string
}

interface IProps {
  navParams: INavParams
}

const DATE_FORMAT = 'MM/DD/yyyy'

const DEFAULT_DIRECTOR: IDirector = {
  employeeId: -1,
  firstName: 'All',
  lastName: 'Stores',
  directorId: -1,
  directorLevel: -1,
  brandId: -1,
  brandCode: '',
  brandName: '',
  locationGroupId: -1,
}

function CorporateTeam({ navParams }: IProps) {
  const brand = getBrand()
  const isTeamV2 = useTeamConfig().isTeamV2 || false

  const {
    director: selectedDirector,
    directors: allDirectors,
    directorChange: onDirectorChange,
    locationGroupTypes,
  } = useGetDirectors()

  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange | null>(
    null,
  )
  const [
    unfilledManagerialPositionOption,
    setUnfilledManagerialPositionOption,
  ] = useState<string>(UNFILLED_MANAGERIAL_POSITION_OPTIONS.NO_CANDIDATES)
  const [performanceOption, setPerformanceOption] = useState<string>(
    PERFORMANCE_OPTIONS.PROMOTE_READY,
  )

  const onSelectUnfilledManagerialPositionOption = (event: any) => {
    setUnfilledManagerialPositionOption(event.target.value)
  }

  const onSelectPerformanceOption = (event: any) => {
    setPerformanceOption(event.target.value)
  }

  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  let selectedLocationGroupId = _.get(selectedDirector, 'locationGroupId') || -1
  selectedLocationGroupId =
    selectedLocationGroupId < 0 ? brandLocationGroupId : selectedLocationGroupId

  let locationGroupIds: number[] | null = []
  if (selectedDirctorEmployeeId === -2) {
    // breakdownByDirectors
    locationGroupIds = _.map(allDirectors, (doData) => {
      return doData.locationGroupId
    })
  } else if (selectedDirctorEmployeeId < -2) {
    locationGroupIds = null
  } else {
    locationGroupIds = [selectedLocationGroupId]
  }

  const [
    getTeamSummaryKpis,
    {
      loading: getTeamSummaryKpisLoading,
      error: getTeamSummaryKpisError,
      data: getTeamSummaryKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_GROUP_TEAM_KPIS(brand))

  const [
    listLocationGroupTeamKpis,
    {
      loading: listLocationGroupTeamKpisLoading,
      error: listLocationGroupTeamKpisError,
      data: listLocationGroupTeamKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_GROUP_TEAM_KPIS(brand))

  const [
    listLocationTeamKpis,
    {
      loading: listLocationTeamKpisLoading,
      error: listLocationTeamKpisError,
      data: listLocationTeamKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_TEAM_KPIS)

  const [
    listLocationUnfilledManagerialPositions,
    {
      loading: listLocationUnfilledManagerialPositionsLoading,
      error: listLocationUnfilledManagerialPositionsError,
      data: listLocationUnfilledManagerialPositionsData,
    },
  ] = useLazyQuery(LIST_LOCATION_UNFILLED_MANAGERIAL_POSITIONS)

  const [
    listLocationManagerReview,
    {
      loading: listLocationManagerReviewloading,
      error: listLocationManagerReviewError,
      data: listLocationManagerReviewData,
    },
  ] = useLazyQuery(LIST_LOCATION_MANAGER_REVIEW)

  useEffect(() => {
    async function fetchGeneralInfo() {
      const allDateRangeData = await getDateRanges()

      let newSelectedDateRange = allDateRangeData.defaultPeriod

      if (newSelectedDateRange) {
        setSelectedDateRange(newSelectedDateRange)
      }
    }

    async function fetchData() {
      if (selectedDirector && selectedDateRange) {
        setSelectedDateRange(selectedDateRange)
        getTeamSummaryKpis({
          variables: {
            iStartDate: selectedDateRange.startDateStr,
            iEndDate: selectedDateRange.endDateStr,
            iFilter: {
              location_group_ids: [selectedLocationGroupId],
            },
            isTeamV2: isTeamV2,
          },
        })

        if (selectedDirctorEmployeeId === -2) {
          // breakdownByDirectors
          listLocationGroupTeamKpis({
            variables: {
              iStartDate: selectedDateRange.startDateStr,
              iEndDate: selectedDateRange.endDateStr,
              iFilter: {
                location_group_ids: _.map(allDirectors, (doData) => {
                  return doData.locationGroupId
                }),
              },
              isTeamV2: isTeamV2,
            },
          })
        } else if (selectedDirctorEmployeeId < -2) {
          // breakdownByLocationGroupType
          listLocationGroupTeamKpis({
            variables: {
              iStartDate: selectedDateRange.startDateStr,
              iEndDate: selectedDateRange.endDateStr,
              iFilter: {
                location_group_types: locationGroupTypes,
              },
              isTeamV2: isTeamV2,
            },
          })
        } else {
          listLocationTeamKpis({
            variables: {
              iStartDate: selectedDateRange.startDateStr,
              iEndDate: selectedDateRange.endDateStr,
              iFilter: {
                location_group_ids: [selectedLocationGroupId],
              },
              isTeamV2: isTeamV2,
            },
          })
        }

        listLocationUnfilledManagerialPositions({
          variables: {
            iFilter: {
              location_group_ids: [selectedLocationGroupId],
            },
          },
        })

        const shouldShowSensitiveData = hasPrivilegeToViewEmployeeFeedback()

        if (shouldShowSensitiveData) {
          const performanceSelection =
            performanceOption ===
            PERFORMANCE_OPTIONS.NO_FEEDBACK_IN_THE_PAST_90_DAYS
              ? ''
              : performanceOption

          listLocationManagerReview({
            variables: {
              iPerformance: performanceSelection,
              iFilter: {
                location_group_ids: [selectedLocationGroupId],
              },
            },
          })
        }
      }
    }

    fetchGeneralInfo()

    if (selectedDirector && selectedDateRange) {
      fetchData()
    }
  }, [selectedDirector, selectedDateRange, performanceOption])

  useEffect(() => {
    if (navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl)
      feature.used('Corporate Team')
    }
  }, [navParams.pageUrl])

  let globalCurrentTeamKpis: IGlobalCurrentTeamKpis = {
    annualizedHourlyTurnoverRate: '',
    annualizedSalariedTurnoverRate: '',
    managerParText: '',
    salariedTurnoverText: '',
    hourlyTurnoverText: '',
    month: '',
  }

  let rawTeamSummaryKpis
  let annualizedHourlyTurnoverPercent = '-'
  let annualizedSalariedTurnoverPercent = '-'
  let month = moment().month() + 1

  const listLocationGroupTeamKpisVersion = isTeamV2
    ? 'listLocationGroupTeamKpisV2'
    : 'listLocationGroupTeamKpis'
  if (
    getTeamSummaryKpisData &&
    getTeamSummaryKpisData[listLocationGroupTeamKpisVersion] &&
    Array.isArray(
      getTeamSummaryKpisData[listLocationGroupTeamKpisVersion].nodes,
    ) &&
    getTeamSummaryKpisData[listLocationGroupTeamKpisVersion].nodes.length === 1
  ) {
    rawTeamSummaryKpis =
      getTeamSummaryKpisData[listLocationGroupTeamKpisVersion].nodes[0]
    annualizedHourlyTurnoverPercent = toPercentString(
      _.get(rawTeamSummaryKpis, 'annualizedHourlyTurnoverPercent'),
      1,
    )
    annualizedSalariedTurnoverPercent = toPercentString(
      _.get(rawTeamSummaryKpis, 'annualizedSalariedTurnoverPercent'),
      1,
    )
    month = moment.utc(rawTeamSummaryKpis.startDate).add(15, 'days').month() + 1
  }

  const hourlyTerminatedCountInThisMonth =
    _.get(rawTeamSummaryKpis, 'hourlyTurnoverCount') || 0
  const managerPar = _.get(rawTeamSummaryKpis, 'managerPar', '-')
  const salariedTerminatedCountInThisMonth =
    _.get(rawTeamSummaryKpis, 'salariedTurnoverCount') || 0
  const totalHourlyCount = _.get(rawTeamSummaryKpis, 'latestHourlyCount', '-')
  const totalSalariedCount = _.get(
    rawTeamSummaryKpis,
    'latestSalariedCount',
    '-',
  )

  const managerParText = `${totalSalariedCount} of ${managerPar}`
  const salariedTurnoverText = `${salariedTerminatedCountInThisMonth} of ${totalSalariedCount}`
  const hourlyTurnoverText = `${hourlyTerminatedCountInThisMonth} of ${totalHourlyCount}`

  globalCurrentTeamKpis = {
    annualizedHourlyTurnoverRate: annualizedHourlyTurnoverPercent
      ? annualizedHourlyTurnoverPercent
      : '-',
    annualizedSalariedTurnoverRate: annualizedSalariedTurnoverPercent
      ? annualizedSalariedTurnoverPercent
      : '-',
    managerParText,
    salariedTurnoverText,
    hourlyTurnoverText,
    month: `P${month}`,
  }

  let itemizedKpis: IDetailTeamKpi[] = []
  const listLocationTeamKpisVersion = isTeamV2
    ? 'listLocationTeamKpisV2'
    : 'listLocationTeamKpis'

  if (
    selectedDirctorEmployeeId === -1 || // systemwide
    selectedDirctorEmployeeId > 0 // individual DOs
  ) {
    if (
      listLocationTeamKpisData &&
      listLocationTeamKpisData[listLocationTeamKpisVersion] &&
      Array.isArray(listLocationTeamKpisData[listLocationTeamKpisVersion].nodes)
    ) {
      const rawListLocationCurrentTeamKpis = _.get(
        listLocationTeamKpisData,
        `${listLocationTeamKpisVersion}.nodes`,
        [],
      )
      itemizedKpis = _.map(rawListLocationCurrentTeamKpis, (k) => {
        const locationId = _.get(k, 'locationId', 1)
        const locationCode = getLocationCode(
          locationId,
          _.get(k, 'locationInfo.code', ''),
        )
        const locationName = getLocationName(
          locationId,
          _.get(k, 'locationInfo.name', ''),
        )

        const avgHourlyRate =
          RAW_LIST_LOCATION_AVG_HOURLY_RATES[k.locationInfo.id]?.avgHourlyRate

        return {
          locationId,
          annualizedHourlyTurnoverRate: toPercentString(
            _.get(k, 'annualizedHourlyTurnoverPercent', 0),
            1,
          ),
          annualizedSalariedTurnoverRate: toPercentString(
            _.get(k, 'annualizedSalariedTurnoverPercent', 0),
            1,
          ),
          hourlyTerminatedCountInThisMonth: _.get(k, 'hourlyTurnoverCount', 0),
          managerPar: _.get(k, 'managerPar', 0),
          totalSalariedCount: _.get(k, 'latestSalariedCount', 0),
          monthlyHourlyTurnoverRate: toPercentString(
            _.get(k, 'hourlyTurnoverPercent', 0),
          ),
          salariedTerminatedCountInThisMonth: _.get(
            k,
            'salariedTurnoverCount',
            0,
          ),
          monthlySalariedTurnoverRate: toPercentString(
            _.get(k, 'salariedTurnoverPercent', 0),
          ),
          avgHourlyRate: avgHourlyRate?.toFixed(2),
          formattedName: `${locationCode} - ${locationName}`,
        }
      })
    }
  } else if (selectedDirctorEmployeeId <= -2) {
    // breakdownByDirectors or breakdownByLocationGroupType
    if (
      listLocationGroupTeamKpisData &&
      listLocationGroupTeamKpisData[listLocationGroupTeamKpisVersion] &&
      Array.isArray(
        listLocationGroupTeamKpisData[listLocationGroupTeamKpisVersion].nodes,
      )
    ) {
      const rawDoTeamKpis = _.get(
        listLocationGroupTeamKpisData,
        `${listLocationGroupTeamKpisVersion}.nodes`,
        [],
      )

      itemizedKpis = _.map(rawDoTeamKpis, (k) => {
        const doFirstName = _.get(k, 'doEmployeeInfo.preferredName', '')
        const doLastName = _.get(k, 'doEmployeeInfo.familyName', '')
        const doEmployeeId = _.get(k, 'doEmployeeId', 1)
        const avgHourlyRate =
          RAW_LIST_LOCATION_GROUP_AVG_HOURLY_RATES[k.locationGroupId]
            ?.avgHourlyRate
        const locationGroupName = _.get(k, 'locationGroupName', '')

        const base = {
          locationId: _.get(k, 'locationId', 1),
          annualizedHourlyTurnoverRate: toPercentString(
            _.get(k, 'annualizedHourlyTurnoverPercent', 0),
            1,
          ),
          annualizedSalariedTurnoverRate: toPercentString(
            _.get(k, 'annualizedSalariedTurnoverPercent', 0),
            1,
          ),
          hourlyTerminatedCountInThisMonth: _.get(k, 'hourlyTurnoverCount', 0),
          managerPar: _.get(k, 'managerPar', 0),
          totalSalariedCount: _.get(k, 'latestSalariedCount', 0),
          monthlyHourlyTurnoverRate: toPercentString(
            _.get(k, 'hourlyTurnoverPercent', 0),
          ),
          salariedTerminatedCountInThisMonth: _.get(
            k,
            'salariedTurnoverCount',
            0,
          ),
          monthlySalariedTurnoverRate: toPercentString(
            _.get(k, 'salariedTurnoverPercent', 0),
          ),
          avgHourlyRate: avgHourlyRate?.toFixed(2),
        }

        if (selectedDirctorEmployeeId === -2 && doEmployeeId) {
          return {
            ...base,
            doEmployeeId,
            formattedName: `${doFirstName} ${doLastName}`,
          }
        }

        return {
          ...base,
          formattedName: locationGroupName,
        }
      })
    }
  }

  let processedUnfilledManagerPostions: IUnfilledManagerialPosition[] = []
  let fitleredUnfilledManagerPositions: IUnfilledManagerialPosition[] = []

  if (
    listLocationUnfilledManagerialPositionsData &&
    listLocationUnfilledManagerialPositionsData.listLocationUnfilledManagerialPositions &&
    Array.isArray(
      listLocationUnfilledManagerialPositionsData
        .listLocationUnfilledManagerialPositions.nodes,
    )
  ) {
    const rawUnfilledManagerialPositions =
      listLocationUnfilledManagerialPositionsData
        .listLocationUnfilledManagerialPositions.nodes

    processedUnfilledManagerPostions = _.map(
      rawUnfilledManagerialPositions,
      (p) => {
        const doInfo = _.get(p, 'doEmployeeInfo', {})
        const doFirstName = _.get(doInfo, 'preferredName', '')
        const doLastName = _.get(doInfo, 'familyName', '')
        const doId = _.get(doInfo, 'id', 1)

        const store = _.get(p, 'locationInfo', {})
        const storeId = _.get(store, 'id', 1)
        const storeCode = getLocationCode(storeId, _.get(store, 'code', ''))
        const storeName = getLocationName(storeId, _.get(store, 'name', ''))

        const trainingStore = _.get(p, 'trainingStore', {})
        const trainingStoreId = _.get(trainingStore, 'id', 1)
        const trainingStoreCode = getLocationCode(
          trainingStoreId,
          _.get(trainingStore, 'code', ''),
        )
        const trainingStoreName = getLocationName(
          trainingStoreId,
          _.get(trainingStore, 'name', ''),
        )
        const trainingStoreFullName = _.isEmpty(trainingStore)
          ? ''
          : `${trainingStoreCode} - ${trainingStoreName}`

        const formattedPendingStartDate = _.isEmpty(
          _.get(p, 'filledPendingStartDate', ''),
        )
          ? ''
          : moment(_.get(p, 'filledPendingStartDate', '')).format(DATE_FORMAT)

        const vacantSince = _.get(p, 'vacantSince', '')
        const vacantFromNow = _.isEmpty(vacantSince)
          ? ''
          : moment(vacantSince).fromNow(true)
        const vacantFromNowInMilliSeconds = _.isEmpty(vacantSince)
          ? ''
          : moment().diff(moment(vacantSince))

        return {
          positionId: _.get(p, 'positionId', 0),
          candidateName: _.get(p, 'candidateName', ''),
          doName: `${doFirstName} ${doLastName}`,
          doId,
          filledPendingStartDate: formattedPendingStartDate,
          managerRoleName: _.get(p, 'managerRoleName', ''),
          storeId,
          storeName: `${storeCode} - ${storeName}`,
          trainingStoreId,
          trainingStoreName: trainingStoreFullName,
          vacantFromNow,
          vacantFromNowInMilliSeconds,
        }
      },
    )

    processedUnfilledManagerPostions = _.orderBy(
      processedUnfilledManagerPostions,
      ['doName', 'vacantFromNowInMilliSeconds'],
      ['asc', 'desc'],
    )
  }

  let processedManagerReviews: IManagerReview[] = []
  let filteredManagerReviews: IManagerReview[] = []

  if (
    listLocationManagerReviewData &&
    listLocationManagerReviewData.listLocationManagerReview &&
    Array.isArray(listLocationManagerReviewData.listLocationManagerReview.nodes)
  ) {
    const rawListMangerReview = _.get(
      listLocationManagerReviewData,
      'listLocationManagerReview.nodes',
      [],
    )

    processedManagerReviews = _.map(rawListMangerReview, (r) => {
      const doInfo = _.get(r, 'doInfo', {})
      const doFirstName = _.get(doInfo, 'firstName', '')
      const doLastName = _.get(doInfo, 'lastName', '')
      const doId = _.get(doInfo, 'id', 1)

      const store = _.get(r, 'store', {})
      const storeId = _.get(store, 'id', 1)
      const storeCode = getLocationCode(storeId, _.get(store, 'code', ''))
      const storeName = getLocationName(storeId, _.get(store, 'name', ''))

      const reviewee = _.get(r, 'reviewee', {})
      const roleName = _.get(reviewee, 'role.name', '')
      const revieweeFirstName = _.get(reviewee, 'firstName', '')
      const revieweeLastName = _.get(reviewee, 'lastName', '')
      const revieweeId = _.get(reviewee, 'id', 1)

      const formattedReviewedAt = _.isEmpty(_.get(r, 'reviewedAt', ''))
        ? '-'
        : moment(_.get(r, 'reviewedAt', '')).format(DATE_FORMAT)

      const rawPerformance = _.get(r, 'performance', '')
      const displayedPerformance = _.isEmpty(rawPerformance)
        ? PERFORMANCE_OPTIONS.NO_FEEDBACK_IN_THE_PAST_90_DAYS
        : rawPerformance

      return {
        reviewId: 1,
        doId,
        doName: `${doFirstName} ${doLastName}`,
        revieweeId,
        revieweeName: `${revieweeFirstName} ${revieweeLastName}`,
        performance: displayedPerformance,
        storeId,
        storeName: `${storeCode} - ${storeName}`,
        reviewedAt: formattedReviewedAt,
        roleName,
      }
    })
  }

  if (selectedDirctorEmployeeId === -1 || selectedDirctorEmployeeId === -2) {
    // all stores OR breakdown by directors

    fitleredUnfilledManagerPositions = processedUnfilledManagerPostions
    filteredManagerReviews = processedManagerReviews
  } else {
    fitleredUnfilledManagerPositions = _.filter(
      processedUnfilledManagerPostions,
      ['doId', selectedDirctorEmployeeId],
    )
    filteredManagerReviews = _.filter(processedManagerReviews, [
      'doId',
      selectedDirctorEmployeeId,
    ])
  }

  if (getTeamSummaryKpisLoading) {
    globalCurrentTeamKpis = {
      annualizedHourlyTurnoverRate: 'loading',
      annualizedSalariedTurnoverRate: 'loading',
      managerParText: 'loading',
      salariedTurnoverText: 'loading',
      hourlyTurnoverText: 'loading',
      month: 'loading',
    }
  }

  return (
    <>
      <Main
        navParams={navParams}
        allDirectors={allDirectors || []}
        selectedDirector={selectedDirector}
        onDirectorChange={onDirectorChange}
        selectedDateRange={selectedDateRange}
        globalCurrentTeamKpis={globalCurrentTeamKpis}
        itemizedKpis={itemizedKpis}
        unfilledManagerialPositions={fitleredUnfilledManagerPositions}
        unfilledManagerialPositionOption={unfilledManagerialPositionOption}
        onSelectUnfilledManagerialPositionOption={
          onSelectUnfilledManagerialPositionOption
        }
        performanceOption={performanceOption}
        onSelectPerformanceOption={onSelectPerformanceOption}
        managerReviews={filteredManagerReviews}
        locationGroupIds={locationGroupIds}
        locationGroupTypes={locationGroupTypes}
        selectedLocationGroupId={selectedLocationGroupId}
      />
    </>
  )
}

export default CorporateTeam
