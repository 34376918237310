import { useQuery } from '@apollo/client'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import COLORS from 'pared/constants/colors'

import useSalesmanshipConfig from '../hooks/useSalesmanshipConfig'
import { TREND_LOCATION_SPEED_OF_SERVICE_DAY_PART } from './gql'

interface IProps {
  trendChartParams: {
    iLocationId: number
    iEndDate: string
    iGroupBy: string
    iDateRangeNumber: number
  }
}

interface IData {
  trendLocationSpeedOfServiceDayPart: {
    nodes: {
      startDate: string
      endDate: string
      sosDetails: {
        [windowType: string]: {
          name: string
          avgSeconds: number
          dayPart: {
            [dayPartType: string]: {
              dayPart: string
              avgSeconds: number
            }
          }
        }
      }
    }[]
  }
}

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const SpeedOfServiceDayPartTrendChart = ({ trendChartParams }: IProps) => {
  const config = useSalesmanshipConfig()

  const { loading, data }: { loading: boolean; data: IData | undefined } =
    useQuery(TREND_LOCATION_SPEED_OF_SERVICE_DAY_PART, {
      variables: trendChartParams,
      skip: !trendChartParams.iEndDate,
    })

  if (loading) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  if (!data) {
    return (
      <PageStatusDiv>
        There is no data within the date range selected. Please select a
        different date range.
      </PageStatusDiv>
    )
  }

  const kpiType = config?.speedOfService?.sosChartKpi?.type || ''
  const locationData = data?.trendLocationSpeedOfServiceDayPart?.nodes || []
  const chartData = locationData?.reduce((result, row) => {
    const startDate = row.startDate
    const dayPartDetails = row.sosDetails?.[kpiType]?.dayPart
    const dayPart = Object.keys(dayPartDetails)?.map((d) => ({
      name: d,
      locationAverageWindowTime: dayPartDetails[d].avgSeconds,
    }))
    return [...result, { startDate, dayPart }]
  }, [] as { startDate: string; dayPart: { name: string; locationAverageWindowTime: number }[] }[])

  const dateRanges = chartData.map((c) => c.startDate)
  const dayParts = [
    {
      name: 'BREAKFAST',
      color: COLORS.Basil,
    },
    {
      name: 'LUNCH',
      color: COLORS.DarkOrchid,
    },
    {
      name: 'AFTERNOON',
      color: COLORS.Mango,
    },
    {
      name: 'DINNER',
      color: COLORS.Acai,
    },
    {
      name: 'LATE_NIGHT',
      color: COLORS.Steel,
    },
  ]
  const yAxisDataArr = dayParts.map((dp) => {
    const data =
      chartData?.map(
        (cd) =>
          cd.dayPart.find((d) => d.name === dp.name)
            ?.locationAverageWindowTime || 0,
      ) || []

    return {
      type: 'line',
      data,
      tooltipLabel: dp.name,
      borderColor: dp.color,
      backgroundColor: dp.color,
    }
  })

  const chartOptions = {
    title: '',
    width: 1100,
    height: 350,
    yLeftAxisLabel:
      config?.speedOfService?.sosChartKpi?.displayName ||
      'Average Window Time (second)',
    yLeftTickCallback: (value: number) => {
      return `${value}`
    },
  }

  return (
    <MixedChart
      xAxisData={dateRanges}
      yAxisDataArr={yAxisDataArr}
      options={chartOptions}
    />
  )
}

export default SpeedOfServiceDayPartTrendChart
