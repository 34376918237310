import { gql, useQuery } from '@apollo/client'
import * as _ from 'lodash'
import { useMemo } from 'react'

import useBrands from 'pared/layouts/hooks/useBrands'

import { useDateFilter, useYoyDates } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

interface IListLocationGroupFinancialKpisNodeType {
  locationGroupId: number
  totalCheckAverage: string
}

interface ISummaryLocationGroupFinancialKpisNodeType {
  totalCheckAverage: string
}

interface IListLocationGroupFlashExtendNodeType {
  locationGroupId: number
  compsItemDetails: {
    type: string
    count?: number
    totalComps?: number
  }[]
}

interface ISummaryFlashExtendNodeType
  extends Omit<IListLocationGroupFlashExtendNodeType, 'locationGroupId'> {}

const getQuery = (fields: string[]) => gql`
  query listLocationGroupFlash(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        netSales
        yoyNetSales
        netSalesLy
        netSalesLyPercent
        netSalesSssPercent
        netSalesBudget
        netSalesBudgetVariance
        totalCheckCount
        yoyTotalCheckCount
        totalCheckCountLy
        yoyCheckCountGrowth
        totalCheckAverage
      }
    }

    summaryLocationGroupFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        netSales
        yoyNetSales
        netSalesLy
        netSalesLyPercent
        netSalesSssPercent
        netSalesBudget
        netSalesBudgetVariance
        totalCheckCount: checkCount
        yoyTotalCheckCount
        totalCheckCountLy
        yoyCheckCountGrowth
        totalCheckAverage: checkAverage
      }
    }

    ${
      fields.length === 0
        ? ''
        : `
    listLocationGroupFlashExtend(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        ${fields.join('\n')}
      }
    }

    summaryFlashExtend(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        ${fields.join('\n')}
      }
    }
    `
    }
  }
`

const yoyQuery = gql`
  query yoyListLocationGroupFlash(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        totalCheckAverage
      }
    }

    summaryLocationGroupFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        netSales
        totalCheckAverage: checkAverage
      }
    }
  }
`

const EXTEND_FIELDS: Record<string, string[]> = {
  ghai_pop: ['regularHours', 'overtimeHours'],
  bibibop: [
    'deliveryPercent',
    'totalComps',
    'compsPercent',
    'cashOverOrShort',
    'compsItemDetails',
    'bonus',
    'cookiesPer100Orders',
    'avocadoPer100Orders',
    'onlinePercent',
    'catering',
    'voids',
  ],
}

export const listLocationGroupFlashConfigs = {
  netSales: 'price',
  yoyNetSales: 'price',
  netSalesLy: 'price',
  netSalesLyPercent: 'percent',
  netSalesSssPercent: 'percent',
  netSalesBudget: 'price',
  netSalesBudgetVariance: 'percent',
  totalCheckCount: 'number',
  yoyTotalCheckCount: 'number',
  totalCheckCountLy: 'number',
  yoyCheckCountGrowth: 'percent',
  totalCheckAverage: 'price',
  yoyTotalCheckAverage: 'price',
  totalCheckAverageSssPercent: 'percent',

  // customized ghai_pop
  regularHours: 'number',
  overtimeHours: 'number',

  // customized bibibop
  onlinePercent: 'percent',
  deliveryPercent: 'percent',
  catering: 'price',
  totalComps: 'price',
  compsPercent: 'percent',
  'Employee Discount.totalComps': 'price',
  voids: 'price',
  cashOverOrShort: 'price',
  cookiesPer100Orders: 'number',
  avocadoPer100Orders: 'number',
  customerRating: 'number',
  numberOfComplaints: 'number',
  bonus: 'price',
} as const

const getSss = (a?: unknown, b?: unknown) => {
  if (typeof a === 'number' && typeof b === 'number') return a - b

  if (typeof a === 'string' && typeof b === 'string')
    return parseFloat(a) - parseFloat(b)

  return null
}

const useListLocationGroupFlash = () => {
  const { startDate, endDate } = useDateFilter()
  const { yoyStartDate, yoyEndDate } = useYoyDates(startDate, endDate)
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { brand } = useBrands()
  const { data, loading } = useQuery(getQuery(EXTEND_FIELDS[brand] || []), {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: { location_group_ids: groupFilter?.list?.map((g) => g.id) },
    },
    skip: !startDate || !endDate || !groupFilter || hasGroupBy,
  })
  const { data: yoyData, loading: yoyLoading } = useQuery(yoyQuery, {
    variables: {
      iStartDate: yoyStartDate,
      iEndDate: yoyEndDate,
      iFilter: { location_group_ids: groupFilter?.list?.map((g) => g.id) },
    },
    skip: !yoyStartDate || !yoyEndDate || !groupFilter || hasGroupBy,
  })

  return {
    data: useMemo((): IApiDataType => {
      const listLocationGroupFinancialKpis = data
        ?.listLocationGroupFinancialKpis.nodes as
        | IListLocationGroupFinancialKpisNodeType[]
        | undefined
      const yoyListLocationGroupFinancialKpis = yoyData
        ?.listLocationGroupFinancialKpis.nodes as
        | IListLocationGroupFinancialKpisNodeType[]
        | undefined
      const listLocationGroupFlashExtend = data?.listLocationGroupFlashExtend
        ?.nodes as IListLocationGroupFlashExtendNodeType[] | undefined
      const summaryLocationGroupFinancialKpis = (
        data?.summaryLocationGroupFinancialKpis?.nodes as
          | ISummaryLocationGroupFinancialKpisNodeType[]
          | undefined
      )?.[0]
      const yoySummaryLocationGroupFinancialKpis = (
        yoyData?.summaryLocationGroupFinancialKpis?.nodes as
          | ISummaryLocationGroupFinancialKpisNodeType[]
          | undefined
      )?.[0]
      const summaryFlashExtend = (
        data?.summaryFlashExtend?.nodes as
          | ISummaryFlashExtendNodeType[]
          | undefined
      )?.[0]

      if (!listLocationGroupFinancialKpis) return null

      const source = listLocationGroupFinancialKpis.map((n) => {
        const yoyD = yoyListLocationGroupFinancialKpis?.find(
          (y) => y.locationGroupId === n.locationGroupId,
        )
        const groupInfo = groupFilter?.list?.find(
          (g) => g.id === n.locationGroupId,
        )
        const flashExtend = listLocationGroupFlashExtend?.find(
          (d) => d.locationGroupId === n.locationGroupId,
        )

        return {
          ...n,
          ...flashExtend,
          ...flashExtend?.compsItemDetails?.reduce(
            (result, c) => ({
              ...result,
              [`${c.type}.count`]: c.count,
              [`${c.type}.totalComps`]: c.totalComps,
            }),
            {},
          ),
          groupInfo,
          yoyTotalCheckAverage: yoyD?.totalCheckAverage,
          totalCheckAverageSssPercent: getSss(
            n?.totalCheckAverage,
            yoyD?.totalCheckAverage,
          ),
        }
      })

      return {
        source,
        summary: {
          ...summaryLocationGroupFinancialKpis,
          ...summaryFlashExtend,
          ...summaryFlashExtend?.compsItemDetails?.reduce(
            (result, c) => ({
              ...result,
              [`${c.type}.count`]: c.count,
              [`${c.type}.totalComps`]: c.totalComps,
            }),
            {},
          ),
          yoyTotalCheckAverage:
            yoySummaryLocationGroupFinancialKpis?.totalCheckAverage,
          totalCheckAverageSssPercent: getSss(
            summaryLocationGroupFinancialKpis?.totalCheckAverage,
            yoySummaryLocationGroupFinancialKpis?.totalCheckAverage,
          ),
        },
      }
    }, [groupFilter, data, yoyData]),
    loading: loading || yoyLoading,
  }
}

export default useListLocationGroupFlash
