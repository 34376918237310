import { useMemo } from 'react'

import { useJscDevelopmentTrackerFeedbackData } from '../../table/hooks/useJscDevelopmentTrackerFeedback'
import { IOptionsType } from '../types'

const useJscDevelopmentalFeedback = () => {
  const { data, loading } = useJscDevelopmentTrackerFeedbackData()

  return {
    data: useMemo(() => {
      const options: IOptionsType = [
        {
          value: 'ALL',
          label: 'All',
        },
      ]
      const roles: string[] = []
      const feedbacks: string[] = []

      data?.listLocationTrainingTrackerFeedbacks.nodes.forEach(
        (d: { revieweeJobTitle: string; performace: string }) => {
          if (d.revieweeJobTitle && !roles.includes(d.revieweeJobTitle))
            roles.push(d.revieweeJobTitle)

          if (d.performace && !feedbacks.includes(d.performace))
            feedbacks.push(d.performace)
        },
      )

      if (roles.length !== 0) {
        options.push(
          {
            type: 'group',
            label: 'Role',
          },
          ...roles.map((r) => ({
            value: r,
            label: r,
          })),
        )
      }

      if (feedbacks.length !== 0) {
        options.push(
          {
            type: 'group',
            label: 'Feedback',
          },
          ...feedbacks.map((r) => ({
            value: r,
            label: r,
          })),
        )
      }

      return options
    }, [data]),
    loading,
  }
}

export default useJscDevelopmentalFeedback
