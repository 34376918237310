import { useMemo } from 'react'

import COLORS from 'pared/constants/colors'

const generateNthChild = (datas: number[]) => `
  ${datas.map((data) => `&:nth-child(${data})`).join(', ')} {
    background: ${COLORS.Porcelain};
  }
`

const useStyles = () =>
  useMemo(
    () => ({
      headerStyle: `
        &:first-child th:nth-child(even) {
          background: ${COLORS.Porcelain};
        }

        &:nth-child(2) th {
          ${generateNthChild([1, 2, 5, 6, 8])}
        }
      `,

      rowStyle: `
        td {
          font-size: 13px;
          white-space: nowrap;

          ${generateNthChild([2, 3, 6, 7, 9])}
        }
      `,
    }),
    [],
  )

export default useStyles
