import { useMemo } from 'react'

import { IColumnsType } from 'pared/components/basicUi/table'
import { getBrand } from 'pared/utils/brand'

import VoidsDetailsRender from '../VoidsDetailsRender'
import VoidsDetailsRenderForDemo from '../VoidsDetailsRenderForDemo'
import { getPriceOrDash } from '../utils'
import { ICashType } from './useCash'
import useLossPreventionConfig, {
  ICashHandlingColumnType,
} from './useLossPreventionConfig'

function isDemo() {
  const brand = getBrand()
  switch (brand) {
    case 'demo_bk':
      return true
    default:
      return false
  }
}

const makeColumnSettings = (item: ICashHandlingColumnType): any => {
  if (item?.children) {
    return {
      key: item.key,
      title: item.title,
      width: item?.width || '75px',
      align: 'center',
      children: item.children.map((child) => makeColumnSettings(child)),
    }
  } else {
    const format = (() => {
      switch (item?.valueType) {
        case 'count':
          return (value: any) => value || '-'

        case 'percent':
          return (value: any) => `${value?.toFixed(2) || '-'}%`

        case 'price':
          return (value: any) =>
            getPriceOrDash(value ? (value / 100.0).toFixed(2) : '-')

        case 'detail':
          return (value: any) =>
            isDemo()
              ? VoidsDetailsRenderForDemo(value)
              : VoidsDetailsRender(value)

        default:
          return undefined
      }
    })()
    return {
      key: item.key,
      title: item.title,
      width: item?.width || '75px',
      align: 'center',
      sorter: item?.sorter,
      ...(format ? { render: format } : null),
    }
  }
}

export default (
  tableType: 'cashTable' | 'voidsAndDeletesTable',
): IColumnsType<ICashType>[] => {
  const brand = getBrand()
  const config = (() => {
    switch (tableType) {
      case 'cashTable':
        return useLossPreventionConfig(brand).cashHandlingTable?.columns

      case 'voidsAndDeletesTable':
        return useLossPreventionConfig(brand).voidsAndDeletesTable?.columns
    }
  })()

  return useMemo(() => {
    const cashTableColumnDefs: IColumnsType<ICashType>[] = [
      {
        key: 'name',
        title: 'Name',
        sorter: true,
      },
      {
        key: 'position',
        title: 'Position',
        align: 'center',
        sorter: true,
      },
    ]

    if (config) {
      const customColumns = config.map((item) => makeColumnSettings(item))
      cashTableColumnDefs.push(...customColumns)
    }

    return cashTableColumnDefs
  }, [config])
}
