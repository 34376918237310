import { ApolloError, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { LIST_LOCATION_EMPLOYEE_UPSIZE } from '../gql'

const useLocationEmployeeSalesData = (
  locationId: number | string,
  startDate: string,
  endDate: string,
  dayPart: string,
): {
  isLoading: boolean
  data: any[] | null
  error: ApolloError | null
} => {
  const { data, error, loading } = useQuery(LIST_LOCATION_EMPLOYEE_UPSIZE, {
    variables: {
      startDate,
      endDate,
      dayPart,
      locationId:
        typeof locationId === 'string' ? parseInt(locationId, 10) : locationId,
    },
    skip: !locationId || !startDate || !endDate || !dayPart,
  })

  return useMemo(() => {
    if (
      data &&
      data.listLocationEmployeeUpsize &&
      Array.isArray(data.listLocationEmployeeUpsize.nodes)
    ) {
      return {
        isLoading: loading,
        data: [...data.listLocationEmployeeUpsize.nodes],
        error: null,
      }
    } else {
      return {
        isLoading: loading,
        data: null,
        error: error || null,
      }
    }
  }, [data, locationId, startDate, endDate, dayPart])
}

export default useLocationEmployeeSalesData
