import { format } from 'date-fns'
import React from 'react'
import styled from 'styled-components'

import useDates, { IDataSourceType } from './hooks/useDates'

interface IPropsType {
  dataSource?: IDataSourceType[]
}

const DAYS = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
]

const Day = styled.div`
  margin: 0px 0px 20px;
  font-family: Lexend-SemiBold;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
`

const Root = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, 175px);

  & > :not(${Day}) {
    position: relative;
    min-height: 150px;
    border-right: 1px solid black;
    border-bottom: 1px solid black;

    &:nth-child(7n + 1) {
      border-left: 1px solid black;
    }

    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11),
    &:nth-child(12),
    &:nth-child(13),
    &:nth-child(14) {
      border-top: 1px solid black;
    }
  }
`

const Item = styled.div<{
  background?: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${({ background }) => background || 'initial'};
  font-family: Lexend-Regular;
  font-weight: 500;
  font-size: 18px;

  & > span {
    position: absolute;
    top: 13px;
    right: 18px;
    font-family: Lexend-SemiBold;
    font-weight: 700;
    font-size: 20px;
  }
`

const Calendar = ({ dataSource = [] }: IPropsType) => {
  const dates = useDates(dataSource)

  return (
    <Root>
      {DAYS.map((day) => (
        <Day key={day}>{day}</Day>
      ))}

      {dates.map(({ date, text, background }) => {
        const dateStr = format(date, 'd')

        return (
          <Item key={dateStr} background={background}>
            <span>
              {dateStr !== '1' ? null : format(date, 'MMM ').toUpperCase()}

              {dateStr}
            </span>

            {text}
          </Item>
        )
      })}
    </Root>
  )
}

export default React.memo(Calendar)
