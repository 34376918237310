import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import useBrands from 'pared/layouts/hooks/useBrands'

interface IQueryDataType {
  yoyStartDate: {
    nodes: {
      date: string
    }[]
  }

  yoyEndDate: {
    nodes: {
      date: string
    }[]
  }
}

interface IQueryVariablesType {
  startDate: string
  endDate: string
}

const query = gql`
  query yoyDates($startDate: Date!, $endDate: Date!) {
    yoyStartDate: getYoyDateInfo(iDate: $startDate) {
      nodes {
        date
      }
    }

    yoyEndDate: getYoyDateInfo(iDate: $endDate) {
      nodes {
        date
      }
    }
  }
`

const useSpecificDate = (date?: string) => {
  const { brand } = useBrands()

  return useMemo(() => {
    if (!/^lfr/.test(brand)) return date

    if (
      moment.utc(date).diff(moment.utc('2023-12-25'), 'days') >= 0 &&
      moment.utc('2023-12-31').diff(moment.utc(date), 'days') >= 0
    )
      return '2023-12-24'

    return date
  }, [brand, date])
}

const useYoyDates = (prevStartDate?: string, prevEndDate?: string) => {
  const startDate = useSpecificDate(prevStartDate)
  const endDate = useSpecificDate(prevEndDate)
  const variables = useMemo(() => {
    if (!prevStartDate || !prevEndDate || !startDate || !endDate) return

    if (startDate === endDate)
      return { startDate: prevStartDate, endDate: prevEndDate }

    return { startDate, endDate }
  }, [prevStartDate, prevEndDate, startDate, endDate])
  const { data } = useQuery<IQueryDataType, IQueryVariablesType>(query, {
    variables,
    skip: !startDate || !endDate,
  })

  return {
    yoyStartDate: data?.yoyStartDate.nodes[0]?.date,
    yoyEndDate: data?.yoyEndDate.nodes[0]?.date,
  }
}

export default useYoyDates
