import styled from 'styled-components'

import CustomizedReport from 'pared/components/CustomizedReport'
import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'

const AcrTable = ({ locationGroupId }: { locationGroupId: number }) => {
  const inputParams = {
    locationGroupIds: [locationGroupId],
  }

  const getRowKey = (data: { locationCode: string | { value: string } }) => {
    if (typeof data.locationCode === 'object' && 'value' in data.locationCode) {
      return data.locationCode.value
    }

    return data.locationCode
  }

  return (
    <Container>
      <CustomizedReport
        reportName="LIST_LOCATION_ACR_TABLE"
        inputParams={inputParams}
        rowKey={getRowKey}
      />
    </Container>
  )
}

export default AcrTable

const Container = styled.div`
  padding: 0;
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
  }
`
