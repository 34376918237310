import { v4 as uuidv4 } from 'uuid'

import Table from '../../Table'
import { FROM_EXPO } from '../../constants'
import { IMessage } from '../../type'

const handleFssKpiValues = (responseData: any) => {
  if (responseData?.fssKpiValues) {
    const answerMessage: IMessage = {
      id: uuidv4(),
      from: FROM_EXPO,
      content: (
        <>
          <Table data={responseData.fssKpiValues} />
        </>
      ),
    }

    return answerMessage
  }

  return null
}

export default handleFssKpiValues
