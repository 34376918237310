import { gql } from '@apollo/client'

export const LIST_LOCATION_FINANCIAL_KPIS = gql`
  query ListLocationFinancialKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        netSales
        yoyNetSales
        totalCheckCount
        yoyTotalCheckCount
        totalCheckAverage
        totalComps
        cashOverOrShort
      }
    }
  }
`

export const LIST_LOCATION_GROUP_FINANCIAL_KPIS = gql`
  query ListLocationGroupFinancialKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupFinancialKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        locationGroupName
        netSales
        yoyNetSales
        totalCheckCount
        yoyTotalCheckCount
        totalCheckAverage
        cashOverOrShort
      }
    }
  }
`

export const GET_STORE_LIST = gql`
  query GetStoreListByBrand($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        geo
        brandId
        directorId
        directorEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        gmEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
      }
    }
  }
`

export const FLASH_CUSTOMIZED = gql`
  query GetCustomizedReport(
    $iCustomizedReportName: String!
    $iInputParams: JSON!
  ) {
    getCustomizedReport(
      iCustomizedReportName: $iCustomizedReportName
      iInputParams: $iInputParams
    ) {
      nodes {
        reportName
        reportDefinition
        reportResult
      }
    }
  }
`
