import { FormControl } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import COLORS from 'pared/constants/colors'

import useTeamConfig from '../hooks/useTeamConfig'
import { ITurnoverType, getTypeDisplayValue, turnoverTypes } from './constants'

interface IProps {
  xAxisData: string[]
  chartOptions: any
  yAxisDataArr: number[][]
  isLoading: boolean
  type: ITurnoverType
  setType: (type: ITurnoverType) => void
}

function WebSmallMain({
  xAxisData,
  chartOptions,
  yAxisDataArr,
  isLoading,
  type,
  setType,
}: IProps) {
  const config = useTeamConfig()
  let Body = null
  if (isLoading) {
    Body = (
      <LoadingContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </LoadingContainer>
    )
  } else {
    Body = (
      <MixedChart
        xAxisData={xAxisData}
        yAxisDataArr={yAxisDataArr}
        options={chartOptions}
      />
    )
  }

  return (
    <>
      {config.newHireTurnover?.isVisible ? (
        <>
          <StyledFormControl variant="outlined" size="small">
            <Select
              value={type}
              onChange={(e: any) => {
                const value: ITurnoverType = e.target.value
                setType(value)
              }}
            >
              {turnoverTypes(config.isTeamV2 || false).map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {getTypeDisplayValue(item)}
                  </MenuItem>
                )
              })}
            </Select>
          </StyledFormControl>
          <VerticalSpacer />
        </>
      ) : null}
      {Body}
    </>
  )
}

const LoadingContainer = styled.div`
  height: 400px;
  width: 100%;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const StyledFormControl = styled(FormControl)`
  width: 180px;
`

const VerticalSpacer = styled.div`
  height: 30px;
`

export default WebSmallMain
