import { v4 as uuidv4 } from 'uuid'

import Table from 'pared/pages/Delivery/StaffTable'

import { FROM_EXPO } from '../../constants'
import { IMessage } from '../../type'
import formatDate from '../formatDate'

export default function handleDeliveryStoreEmployeeKpis(responseData: any) {
  if (responseData?.deliveryStoreEmployeeKpis) {
    const messageId = uuidv4()
    const result = responseData.deliveryStoreEmployeeKpis

    const uiComponent: React.ReactNode = (
      <div>
        <div>
          {result.locationName} ({formatDate(result.startDate)} ~{' '}
          {formatDate(result.endDate)})
        </div>
        <Table
          locationId={result.locationId}
          startDate={result.startDate}
          endDate={result.endDate}
        />
      </div>
    )

    if (uiComponent) {
      const answerMessage: IMessage = {
        id: messageId,
        from: FROM_EXPO,
        content: uiComponent,
      }

      return answerMessage
    }
  }

  return null
}
