import Main from './Main'

interface IProps {
  rankBy: string
  onSelectRankBy: (event: any) => void
}

function RankBySelect({ rankBy, onSelectRankBy }: IProps) {
  return <Main rankBy={rankBy} onSelectRankBy={onSelectRankBy} />
}

export default RankBySelect
