import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import CashControlTable from 'pared/pages/CorporateLossPrevention/CashControlTable'
import DiscountTable from 'pared/pages/CorporateLossPrevention/DiscountTable'
import { FROM_EXPO } from 'pared/pages/ExpoAi/constants'
import { IMessage } from 'pared/pages/ExpoAi/type'

import formatDate from '../formatDate'

const VerticalSpacer = styled.div`
  width: 100%;
  height: 50px;
`

export default function handleLossPreventionKpiValues(responseData: any) {
  if (responseData?.lossPreventionKpiValues) {
    const messageId = uuidv4()
    const results = responseData.lossPreventionKpiValues

    let startDate: string = ''
    let endDate: string = ''
    const uiComponents: React.ReactNode[] = []

    if (results.discount) {
      if (results.discount.locations) {
        const dlData = results.discount.locations
        startDate = dlData.startDate
        endDate = dlData.endDate

        uiComponents.push(
          <DiscountTable
            key={`discount.locations.${messageId}`}
            startDate={dlData?.startDate}
            endDate={dlData?.endDate}
            locationIds={dlData?.locationIds}
            isBreakdownByDirectors={dlData?.isBreakdownByDirectors}
          />,
        )
      }

      if (results.discount.locationGroups) {
        const dlgData = results.discount.locationGroups
        startDate = dlgData.startDate
        endDate = dlgData.endDate

        uiComponents.push(
          <div>
            {uiComponents.length > 0 ? <VerticalSpacer /> : null}
            <DiscountTable
              key={`discount.locationGroups.${messageId}`}
              startDate={dlgData?.startDate}
              endDate={dlgData?.endDate}
              locationGroupIds={dlgData?.locationGroupIds}
              isBreakdownByDirectors={dlgData?.isBreakdownByDirectors}
            />
          </div>,
        )
      }
    }

    if (results.cashControl) {
      if (results.cashControl.locations) {
        const cclData = results.cashControl.locations
        startDate = cclData.startDate
        endDate = cclData.endDate

        uiComponents.push(
          <div>
            {uiComponents.length > 0 ? <VerticalSpacer /> : null}
            <CashControlTable
              key={`cashControl.locations.${messageId}`}
              startDate={cclData?.startDate}
              endDate={cclData?.endDate}
              locationIds={cclData?.locationIds}
              isBreakdownByDirectors={cclData?.isBreakdownByDirectors}
            />
          </div>,
        )
      }

      if (results.cashControl.locationGroups) {
        const cclgData = results.cashControl.locationGroups
        startDate = cclgData.startDate
        endDate = cclgData.endDate

        uiComponents.push(
          <div>
            {uiComponents.length > 0 ? <VerticalSpacer /> : null}
            <CashControlTable
              key={`cashControl.locationGroups.${messageId}`}
              startDate={cclgData?.startDate}
              endDate={cclgData?.endDate}
              locationGroupIds={cclgData?.locationGroupIds}
              isBreakdownByDirectors={cclgData?.isBreakdownByDirectors}
            />
          </div>,
        )
      }
    }

    if (uiComponents.length > 0) {
      const answerMessage: IMessage = {
        id: messageId,
        from: FROM_EXPO,
        content: (
          <div>
            {startDate && endDate ? (
              <div>
                {formatDate(startDate)} ~ {formatDate(endDate)}
              </div>
            ) : null}
            {uiComponents}
          </div>
        ),
      }

      return answerMessage
    }
  }

  return null
}
