import React from 'react'

import Chart from 'pared/charts'
import Spin from 'pared/components/basicUi/spin'

import ChartContainer from './ChartContainer'
import { IPerformanceChartFragmentType } from './gqls/performanceChart'
import usePerformanceChartConfig from './hooks/usePerformanceChartConfig'

interface IPropsType {
  listLocationMenuItemsRecords: IPerformanceChartFragmentType[]
  loading: boolean
}

const PerformanceChart = ({
  listLocationMenuItemsRecords,
  loading,
}: IPropsType) => {
  const performanceChartConfig = usePerformanceChartConfig(
    listLocationMenuItemsRecords,
  )

  return (
    <ChartContainer>
      <Spin spinning={loading}>
        <Chart {...performanceChartConfig} width={1100} height={750} />
      </Spin>
    </ChartContainer>
  )
}

export default React.memo(
  PerformanceChart,
) as unknown as typeof PerformanceChart
