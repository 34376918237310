import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import DateRangeNumberSelector from 'pared/components/DateRangeNumberSelector'
import useDateRange, {
  IItem,
  LAST_3_PERIODS,
  LAST_4_WEEKS,
  LAST_7_DAYS,
  LAST_13_PERIODS,
} from 'pared/components/DateRangeNumberSelector/hooks/useDateRange'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import { getDateRangeLabelV2 } from 'pared/utils/date'
import { toPercentString, toUsdString } from 'pared/utils/number'

import useSalesmanshipConfig from '../hooks/useSalesmanshipConfig'
import { TREND_LOCATION_SALESMANSHIP_KPIS } from './gql'

interface IProps {
  locationId: number
  endDate: string
}

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

function SalesmanshipTrendChart({ locationId, endDate }: IProps) {
  const config = useSalesmanshipConfig()
  const [dateRange, setDateRange] = useState<IItem['value']>(LAST_7_DAYS.value)
  const { dateRangeNumber, dateRangeGroupBy } = useDateRange(dateRange)

  const [
    trendLocationSalesmanshipKpis,
    { loading: listKpiLoading, data: listKpiData },
  ] = useLazyQuery(TREND_LOCATION_SALESMANSHIP_KPIS)

  useEffect(() => {
    const newEndDate =
      moment(endDate, DATE_FORMAT) > moment()
        ? moment().format(DATE_FORMAT)
        : endDate

    trendLocationSalesmanshipKpis({
      variables: {
        iLocationId: locationId,
        iEndDate: newEndDate,
        iGroupBy: dateRangeGroupBy,
        iDateRangeNumber: dateRangeNumber,
      },
    })
  }, [locationId, endDate, dateRange])

  let rawKpiMonthly = []

  if (
    listKpiData &&
    listKpiData.trendLocationSalesmanshipKpis &&
    Array.isArray(listKpiData.trendLocationSalesmanshipKpis.nodes)
  ) {
    rawKpiMonthly =
      _.get(listKpiData, 'trendLocationSalesmanshipKpis.nodes') || []
  }

  const xAxisData: any[] = []

  _.forEach(rawKpiMonthly, (k) => {
    const dateInfo = {
      groupByStartDate: k.startDate,
      groupByEndDate: k.endDate,
      businessWeek: k.businessWeek,
      businessMonth: k.businessMonth,
      businessQuarter: k.businessQuarter,
      businessYear: k.businessYear,
    }

    if (dateInfo) {
      xAxisData.push(getDateRangeLabelV2(dateRangeGroupBy, dateInfo))
    }
  })

  const yLeftAxis = config.salesmanshipTrendChart.yLeftAxisKpiDefs
  const yAxisDataArr = []

  for (let i = 0; i < yLeftAxis.length; i++) {
    const colDef = yLeftAxis[i]
    const kpiDataArr: any = []
    rawKpiMonthly.forEach((rawData: any) => {
      let value = 0
      let displayValue = 0

      if (colDef.kpiType === 'sales') {
        let salesTypeData: any = {}
        if (colDef.salesType === 'total') {
          salesTypeData = rawData?.totalSalesDetails
        } else {
          salesTypeData = rawData?.categorizedSalesDetails?.[colDef.salesType]
        }

        value = salesTypeData?.[colDef.kpiKey] || 0

        switch (colDef.valueType) {
          case 'cent':
            displayValue = value / 100.0
            break
          case 'percent':
            displayValue = value
            break
          default:
          // do nothing
        }

        kpiDataArr.push(displayValue)
      }
    })

    yAxisDataArr.push({
      type: 'line',
      data: kpiDataArr,
      tooltipLabel: colDef.name,
      borderColor: colDef.color,
      backgroundColor: colDef.color,
      hidden: !!colDef.hidden,
    })
  }

  const yRightAxisDataArr: any = []
  rawKpiMonthly.forEach((rawData: any) => {
    let value = 0
    let displayValue = 0
    const colDef = config.salesmanshipTrendChart.yRightAxisKpiDef

    if (colDef?.kpiType === 'sales') {
      let salesTypeData: any = {}
      if (colDef.salesType === 'total') {
        salesTypeData = rawData?.totalSalesDetails
      } else {
        salesTypeData = rawData?.categorizedSalesDetails?.[colDef.salesType]
      }

      value = salesTypeData?.[colDef.kpiKey] || 0

      switch (colDef.valueType) {
        case 'cent':
          displayValue = value / 100.0
          break
        default:
        // do nothing
      }

      yRightAxisDataArr.push(displayValue)
    }
  })

  if (yRightAxisDataArr.length > 0) {
    yAxisDataArr.push({
      type: 'bar',
      yAxisId: 'yRight',
      data: yRightAxisDataArr,
      tooltipLabel: config.salesmanshipTrendChart.yRightAxisKpiDef?.name,
    })
  }

  const chartOptions = {
    title: '',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: config.salesmanshipTrendChart.yLeftAxisLabel,
    yLeftTickCallback: (value: number) => {
      switch (config.salesmanshipTrendChart.yLeftAxisValueType) {
        case 'cent':
          return toUsdString(value, 2)
        case 'percent':
          return toPercentString(value, 2, false)
        default:
          return toUsdString(value, 2)
      }
    },
    yRightAxisLabel: config.salesmanshipTrendChart.yRightAxisLabel,
    yRightShowGridLines: false,
    yRightTickCallback: (value: number) => {
      switch (config.salesmanshipTrendChart.yRightAxisValueType) {
        case 'cent':
          return toUsdString(value, 2)
        case 'percent':
          return toPercentString(value, 2, false)
        default:
          return toUsdString(value, 2)
      }
    },
    tooltipLabelCallback: (tooltipItemContext: any) => {
      if (tooltipItemContext) {
        let datasetLabel = ''
        let separator = ''
        let yValue = ''
        if (tooltipItemContext.dataset && tooltipItemContext.dataset.label) {
          datasetLabel = tooltipItemContext.dataset.label
          separator = ': '
        }
        if (
          tooltipItemContext.parsed &&
          (tooltipItemContext.parsed.y || tooltipItemContext.parsed.y === 0)
        ) {
          let axisValueType = config.salesmanshipTrendChart.yLeftAxisValueType
          if (tooltipItemContext.dataset.yAxisID === 'yRight') {
            axisValueType = config.salesmanshipTrendChart.yRightAxisValueType
          }

          let displayString = ''
          const value = tooltipItemContext.parsed.y
          switch (axisValueType) {
            case 'cent':
              displayString = toUsdString(value, 2)
              break
            case 'percent':
              displayString = toPercentString(value, 2, false)
              break
            default:
              displayString = toUsdString(value, 2)
          }

          if (tooltipItemContext.datasetIndex === 0) {
            yValue += displayString
          } else {
            yValue = displayString
          }
        }
        return [datasetLabel, separator, yValue].join('')
      }
      return ''
    },
  }

  if (listKpiLoading) {
    return (
      <LoadingContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </LoadingContainer>
    )
  }

  return (
    <>
      <Container>
        <SectionHeader>Salesmanship Chart</SectionHeader>
        <DateRangeNumberSelector
          value={dateRange}
          onChange={setDateRange}
          dateRangeOptions={[
            LAST_7_DAYS,
            LAST_4_WEEKS,
            LAST_3_PERIODS,
            LAST_13_PERIODS,
          ]}
        />
      </Container>
      <MixedChart
        xAxisData={xAxisData}
        yAxisDataArr={yAxisDataArr}
        options={chartOptions}
      />
    </>
  )
}

export default SalesmanshipTrendChart

const Container = styled.div`
  width: 1100px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 30px;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    width: 100%;
  }
`

const SectionHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
`

const LoadingContainer = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  height: 400px;
  width: 100%;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`
