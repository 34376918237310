import React from 'react'
import styled from 'styled-components'

import Table from 'pared/components/basicUi/table'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { IDirector } from 'pared/data/getDirectors'

import useBreakdownTableData from './hooks/useBreakdownTableData'
import useColumns from './hooks/useColumns'

const MobileCotainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const rowStyle = `
  &:nth-child(even):not(:last-child) {
    background-color: #E5E5E5;
  }
`

interface IProps {
  locationGroupIds: number[] | null
  locationGroupTypes: string[] | null
  isBreakdownByDirectors: boolean
  allDirectors: IDirector[]
  startDate: string
  endDate: string
}

const PAGINATION_PAGE_SIZE = 20

const BreakdownTable = ({
  locationGroupIds,
  locationGroupTypes,
  isBreakdownByDirectors,
  allDirectors,
  startDate,
  endDate,
}: IProps) => {
  const { tableData } = useBreakdownTableData(
    locationGroupIds,
    locationGroupTypes,
    isBreakdownByDirectors,
    startDate,
    endDate,
  )
  const columns = useColumns(
    isBreakdownByDirectors,
    locationGroupTypes,
    allDirectors,
  )

  return (
    <MobileCotainer>
      <Table
        dataSource={tableData}
        columns={columns}
        rowKey={(values) => values.rowKey}
        rowStyle={rowStyle}
        pagination={{ pageSize: PAGINATION_PAGE_SIZE }}
      />
    </MobileCotainer>
  )
}

export default React.memo(BreakdownTable)
