import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import { getBrand } from 'pared/utils/brand'

import { getRankByDisplayNames } from '../constants'
import useRankingConfig from '../hooks/useRankingConfig'

interface IProps {
  rankBy: string
  onSelectRankBy: (event: any) => void
}

const useStyles = makeStyles(() => ({
  text: {
    fontFamily: 'Lexend-Regular',
  },
  subheaderMenuItem: {
    '&.Mui-disabled': {
      opacity: 1,
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
}))

function Main({ rankBy, onSelectRankBy }: IProps) {
  const classes = useStyles()
  const rankByDisplayNames = getRankByDisplayNames()

  const brand = getBrand()
  const rankingConfig = useRankingConfig(brand)

  return (
    <RankingSelect
      value={rankBy}
      onChange={onSelectRankBy}
      className={classes.text}
    >
      {rankingConfig.map(({ isHeader, key, name }) => {
        if (isHeader) {
          return (
            <RankingSubheaderMenuItem
              disabled
              className={classes.subheaderMenuItem}
            >
              {name}
            </RankingSubheaderMenuItem>
          )
        }

        return (
          <MenuItem className={classes.text} value={key}>
            {name ? name : key && rankByDisplayNames[key]}
          </MenuItem>
        )
      })}
    </RankingSelect>
  )
}

const RankingSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const RankingSubheaderMenuItem = styled(MenuItem)`
  font-family: Lexend-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #000000;
`

export default Main
