import { useQuery } from '@apollo/client'
import moment from 'moment'
import { useState } from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import MixedChart from 'pared/charts/MixedChart'
import DateRangeNumberSelector from 'pared/components/DateRangeNumberSelector'
import useDateRange, {
  IItem,
  LAST_3_PERIODS,
  LAST_4_WEEKS,
  LAST_7_DAYS,
  LAST_13_PERIODS,
} from 'pared/components/DateRangeNumberSelector/hooks/useDateRange'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import { DATE_FORMAT, IDateRange } from 'pared/data/getDateRanges'
import { getDateRangeLabel } from 'pared/utils/date'

import {
  IGetTrendLocationPlvPercentVariablesType,
  ITrendLocationPlvPercentQueryType,
  trendLocationPlvPercent,
} from './gql'

interface IProps {
  navParams: INavParams
  selectedDateRange: IDateRange | null
}

interface IOrganizedData {
  dateRanges: string[]
  locationPlvPercent: number[]
  locationGroupPlvPercent: number[]
}

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const Container = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 30px;

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    width: 100%;
  }
`

const MobileContainer = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const SectionHeader = styled.div`
  font-family: Lexend-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
`

const PlvPercentTrendChart = ({ navParams, selectedDateRange }: IProps) => {
  const storeId = navParams.storeId
  const locationId = parseInt(storeId || '0', 10) || 0
  const [dateRange, setDateRange] = useState<IItem['value']>(LAST_7_DAYS.value)
  const { dateRangeNumber, dateRangeGroupBy } = useDateRange(dateRange)
  const periodEndDate = _.get(selectedDateRange, 'endDateStr', '')
  const newEndDate =
    moment(periodEndDate, DATE_FORMAT) > moment()
      ? moment().format(DATE_FORMAT)
      : periodEndDate
  const { data, previousData, loading } = useQuery<
    ITrendLocationPlvPercentQueryType,
    IGetTrendLocationPlvPercentVariablesType
  >(trendLocationPlvPercent, {
    variables: {
      iLocationId: locationId,
      iEndDate: newEndDate,
      iGroupBy: dateRangeGroupBy,
      iDateRangeNumber: dateRangeNumber,
    },
  })

  if (loading) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  if (!data) {
    return (
      <PageStatusDiv>
        There is no data within the date range selected. Please select a
        different date range.
      </PageStatusDiv>
    )
  }

  const trendLocationPlvPercentData =
    (data || previousData)?.trendLocationPlvPercentV2?.nodes || []

  const processedDataMap: {
    [startDate: string]: {
      locationPlvPercent: number
      locationGroupPlvPercent: number
    }
  } = {}
  trendLocationPlvPercentData.forEach((rawData: any) => {
    const startDate = rawData?.startDate
    const locationPlvPercent = rawData?.plvPercent || ''
    const locationGroupPlvPercent = rawData?.systemwidePlvPercent || ''
    const plvPercentNum = parseFloat(locationPlvPercent) || 0.0
    const groupPlvPercentNum = parseFloat(locationGroupPlvPercent) || 0.0
    const processedData = processedDataMap[startDate] || {}
    processedData.locationPlvPercent = plvPercentNum
    processedData.locationGroupPlvPercent = groupPlvPercentNum
    processedDataMap[startDate] = processedData
  })

  const organizedData = trendLocationPlvPercentData.reduce(
    (acc, { startDate }) => {
      acc.dateRanges.push(getDateRangeLabel(startDate, dateRangeGroupBy))
      acc.locationPlvPercent.push(
        processedDataMap[startDate]?.locationPlvPercent || 0.0,
      )
      acc.locationGroupPlvPercent.push(
        processedDataMap[startDate]?.locationGroupPlvPercent || 0.0,
      )

      return acc
    },
    {
      dateRanges: [],
      locationPlvPercent: [],
      locationGroupPlvPercent: [],
    } as IOrganizedData,
  )

  const yAxisDataArr = [
    {
      type: 'line',
      data: organizedData?.locationPlvPercent,
      tooltipLabel: 'Store PLV %',
      borderColor: COLORS.Basil,
      backgroundColor: COLORS.Basil,
    },
    {
      type: 'line',
      data: organizedData?.locationGroupPlvPercent,
      tooltipLabel: 'Systemwide PLV %',
      borderColor: COLORS.Pomodoro,
      backgroundColor: COLORS.Pomodoro,
    },
  ]

  const chartOptions = {
    title: 'PLV Percent',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: '% PLV',
    yLeftTickCallback: (value: number) => {
      return `${value}%`
    },
  }

  return (
    <MobileContainer>
      <Container>
        <SectionHeader>PLV Percent TREND</SectionHeader>
        <DateRangeNumberSelector
          value={dateRange}
          onChange={setDateRange}
          dateRangeOptions={[
            LAST_7_DAYS,
            LAST_4_WEEKS,
            LAST_3_PERIODS,
            LAST_13_PERIODS,
          ]}
        />
      </Container>
      <MixedChart
        xAxisData={organizedData.dateRanges}
        yAxisDataArr={yAxisDataArr}
        options={chartOptions}
      />
    </MobileContainer>
  )
}

export default PlvPercentTrendChart
