import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

export interface IUnitFilterItemType {
  id: string
  parentId: 'root' | null
  value: string
  text: string
}

export enum INVENTORY_UNIT_TYPE {
  DOLLAR = 'Dollar',
  BASIS_POINT = 'Basis Point',
}

export enum INVENTORY_UNIT_TYPE_DISPLAY {
  DOLLAR = 'Dollars - Purchased vs Net Sales',
  BASIS_POINT = 'Basis Points - Purchased vs COGS',
}

interface IUnitFiltersConfigType {
  unitFilters: IUnitFilterItemType[]
  initialUnit: INVENTORY_UNIT_TYPE
}

const defaultMenuItemList: IUnitFilterItemType[] = [
  {
    id: 'root',
    parentId: null,
    value: 'root',
    text: 'root',
  },
  {
    id: INVENTORY_UNIT_TYPE.BASIS_POINT,
    parentId: 'root' as const,
    value: INVENTORY_UNIT_TYPE.BASIS_POINT,
    text: INVENTORY_UNIT_TYPE_DISPLAY.BASIS_POINT,
  },
  {
    id: INVENTORY_UNIT_TYPE.DOLLAR,
    parentId: 'root' as const,
    value: INVENTORY_UNIT_TYPE.DOLLAR,
    text: INVENTORY_UNIT_TYPE_DISPLAY.DOLLAR,
  },
]

const cavaMenuItemList: IUnitFilterItemType[] = [
  {
    id: 'root',
    parentId: null,
    value: 'root',
    text: 'root',
  },
  {
    id: INVENTORY_UNIT_TYPE.BASIS_POINT,
    parentId: 'root' as const,
    value: INVENTORY_UNIT_TYPE.BASIS_POINT,
    text: INVENTORY_UNIT_TYPE_DISPLAY.BASIS_POINT,
  },
  {
    id: INVENTORY_UNIT_TYPE.DOLLAR,
    parentId: 'root' as const,
    value: INVENTORY_UNIT_TYPE.DOLLAR,
    text: INVENTORY_UNIT_TYPE_DISPLAY.DOLLAR,
  },
]

const useUnitFiltersConfig = (): IUnitFiltersConfigType => {
  const brand = getBrand()

  return useMemo(() => {
    switch (brand) {
      case 'cava':
        return {
          unitFilters: cavaMenuItemList,
          initialUnit: INVENTORY_UNIT_TYPE.BASIS_POINT,
        }

      case 'demo_bk':
      case 'mbn_bk':
      case 'supreme_bk':
      case 'jsc_bk':
      case 'jsc_bk_nso':
      case 'timoney_bk':
      case 'irmg_bk':
      case 'viking_bk':
      case 'mwb':
      case 'burgerworks':
      case 'bibibop':
        return {
          unitFilters: [
            {
              id: 'root',
              parentId: null,
              value: 'root',
              text: 'root',
            },
            {
              id: INVENTORY_UNIT_TYPE.DOLLAR,
              parentId: 'root' as const,
              value: INVENTORY_UNIT_TYPE.DOLLAR,
              text: INVENTORY_UNIT_TYPE_DISPLAY.DOLLAR,
            },
          ],
          initialUnit: INVENTORY_UNIT_TYPE.DOLLAR,
        }
      default:
        return {
          unitFilters: defaultMenuItemList,
          initialUnit: INVENTORY_UNIT_TYPE.BASIS_POINT,
        }
    }
  }, [brand])
}

export default useUnitFiltersConfig
