import { IYAxisData } from 'pared/charts/MixedChart/web/drawMixedChart'
import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'

interface IProps {
  xAxisData: string[]
  chartOptions: any
  yAxisDataArr: IYAxisData[]
  isLoading: boolean
}

function Main({ xAxisData, chartOptions, yAxisDataArr, isLoading }: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          isLoading={isLoading}
          xAxisData={xAxisData}
          chartOptions={chartOptions}
          yAxisDataArr={yAxisDataArr}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          isLoading={isLoading}
          xAxisData={xAxisData}
          chartOptions={chartOptions}
          yAxisDataArr={yAxisDataArr}
        />
      </SmallScreen>
    </>
  )
}

export default Main
