import { v4 as uuidv4 } from 'uuid'

import { TYPE_PERIOD, TYPE_QUARTER } from 'pared/data/getDateRanges'
import CorporateTrendChart from 'pared/pages/CorporateGuest/RatingsChart'
import { FROM_EXPO } from 'pared/pages/ExpoAi/constants'
import { IMessage } from 'pared/pages/ExpoAi/type'
import StoreTrendChart from 'pared/pages/Guest/Chart'

export default function handleGuestKpiTrends(responseData: any) {
  if (responseData?.guestKpiTrends) {
    const messageId = uuidv4()
    const results = responseData.guestKpiTrends

    const uiComponents: React.ReactNode[] = []

    if (results.guest) {
      if (results.guest.locationGroups) {
        const dlData = results.guest.locationGroups
        uiComponents.push(
          <div>
            <div>{dlData.locationGroupNames.join(', ')}</div>
            <CorporateTrendChart
              key={`delivery.locationGroups.${messageId}`}
              endDate={dlData?.endDate}
              dateRangeType={TYPE_PERIOD}
              locationGroupId={dlData?.locationGroupIds[0]}
            />
          </div>,
        )
      } else if (results.guest.locations) {
        const dlData = results.guest.locations
        uiComponents.push(
          <div>
            <div>{dlData.locationNames.join(', ')}</div>
            <StoreTrendChart
              key={`delivery.locations.${messageId}`}
              startDate={dlData?.startDate}
              endDate={dlData?.endDate}
              dateRangeType={TYPE_QUARTER}
              locationId={dlData?.locationIds[0]}
              locationName={dlData?.locationNames[0]}
            />
          </div>,
        )
      }
    }

    if (uiComponents.length > 0) {
      const answerMessage: IMessage = {
        id: messageId,
        from: FROM_EXPO,
        content: <div>{uiComponents}</div>,
      }

      return answerMessage
    }
  }

  return null
}
