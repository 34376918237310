import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { BRAND_NAME } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import useBrands from 'pared/layouts/hooks/useBrands'
import { signOutUser } from 'pared/utils/user'

const Container = styled.div`
  font-family: 'Lexend-Regular';
  @media ${MOBILE_WIDTH} {
    padding: 30px;
  }
`

const BrandContainer = styled.div`
  margin: 0px 0px 20px;
`

const Button = styled.div`
  cursor: pointer;
  background-color: ${COLORS.Expo};
  color: white;
  border-radius: 10px;
  width: 100px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
`

const VerticalSpacer = styled.div`
  height: 20px;
`

export default () => {
  const { brands } = useBrands()

  return (
    <Container>
      <h1>Select a brand</h1>
      {brands.length === 0 ? null : (
        <BrandContainer>
          {brands.map((code: string) => {
            const name = BRAND_NAME[code]

            return (
              <div key={code}>
                - <Link to={`/${code}`}>{name}</Link>
              </div>
            )
          })}
        </BrandContainer>
      )}
      <p>
        If you do not see your brand, try signing out and signing back in, or
        email <a href="mailto:support@getexpo.com">support@getexpo.com</a>.
      </p>
      <VerticalSpacer />

      <Button onClick={() => signOutUser()}>Sign out</Button>
    </Container>
  )
}
