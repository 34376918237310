import { useMemo } from 'react'

import {
  TYPE_CUSTOM,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import { getBrand } from 'pared/utils/brand'

interface IConfig {
  tooltips: {
    sss?: string
    transaction?: string
    transactionAvg?: string
    cogsKpi?: string
  }
  usePeriodCogs: boolean
  pageTitle?: string
  isMapVisible: boolean
  useBrandLink: boolean
  dateRangeOptions?: string[]
  breakdownByStoreCsvFileName?: string
  disablePagination?: boolean
  preSelectedDateRange?: string
}

const defaultConfig = {
  tooltips: {},
  usePeriodCogs: false,
  isMapVisible: true,
  useBrandLink: false,
}

export default (): IConfig => {
  const brand = getBrand()

  return useMemo(() => {
    switch (brand) {
      case 'cava':
        return {
          ...defaultConfig,
          tooltips: {
            sss: 'Same Restaurant Sales',
            transaction: '1 Guest per Entree',
            transactionAvg: 'Per Person Average - Total Sales / # of Entrees',
          },
          pageTitle: 'Overview',
        }
      case 'irmg_pop':
      case 'demo_pop':
      case 'ghai_pop':
        return {
          ...defaultConfig,
          tooltips: {
            cogsKpi:
              'Estimated COGS% until Ending Period Inventory is completed',
          },
          usePeriodCogs: true,
        }
      case 'supreme_bk':
      case 'mbn_bk':
      case 'timoney_bk':
        return {
          ...defaultConfig,
          isMapVisible: false,
        }
      case 'supreme_pop':
      case 'ace_pop':
        return {
          ...defaultConfig,
          isMapVisible: false,
          disablePagination: true,
          tooltips: {
            cogsKpi:
              'Estimated COGS% until Ending Period Inventory is completed',
          },
          usePeriodCogs: true,
        }
      case 'lfr':
        return {
          ...defaultConfig,
          useBrandLink: true,
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
          ],
        }

      case 'lfr_ef':
      case 'lfr_tr':
      case 'lfr_sn':
      case 'lfr_lv':
      case 'lfr_vb':
      case 'lfr_tu':
      case 'lfr_ws':
      case 'lfr_jn':
      case 'lfr_mm':
      case 'lfr_ol':
      case 'lfr_dt':
      case 'lfr_uv':
      case 'lfr_bth':
        return {
          ...defaultConfig,
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
          ],
        }
      case 'jsc_bk':
        return {
          ...defaultConfig,
          breakdownByStoreCsvFileName: 'system-overview',
        }
      case 'fw_wingstop':
        return {
          ...defaultConfig,
          dateRangeOptions: [
            TYPE_WEEK,
            TYPE_PERIOD,
            TYPE_QUARTER,
            TYPE_YEAR,
            TYPE_LAST_WEEK,
            TYPE_THIS_WEEK,
            TYPE_YESTERDAY,
            TYPE_TRAILING_7_DAYS,
            TYPE_CUSTOM,
          ],
          preSelectedDateRange: 'yesterday',
        }
      default:
        return defaultConfig
    }
  }, [brand])
}
