import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

interface IConfig {
  isExrayVisible?: boolean
  scorecardCategories: string[]
  maxScore?: 'locationCount' | 'categoryCount'
  isWeightVisible?: boolean
  isScorecardColored?: boolean
  isScoreFavorable?: (score: number | '-') => boolean
  historicalPerformanceDefaultKpi: string
  scorecardTitle?: string
  isPercentileRankVisible: boolean
  isRankVisible: boolean
}

const scorecardCategories = [
  'net_sales_budget_variance',
  'ops_comps_percent',
  'avt_percent',
  'hourly_labor_budget_variance',
  'controllable_expenses_percent',
  'rcp_budget_variance',
  'momentfeed_score',
  'ot_hours',
  'yoy_in_unit_check_count_growth',
  'yoy_in_unit_avg_check_growth',
  'annualized_hourly_turnover_rate',
  'annualized_salaried_turnover_rate',
]

const defaultConfig: IConfig = {
  isExrayVisible: true,
  scorecardCategories,
  maxScore: 'locationCount',
  isWeightVisible: true,
  isScorecardColored: false,
  isScoreFavorable: () => false,
  historicalPerformanceDefaultKpi: 'net_sales_budget_variance',
  isPercentileRankVisible: true,
  isRankVisible: false,
}

const useConfig = (): IConfig => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'cava':
        return {
          ...defaultConfig,
          isExrayVisible: false,
          scorecardCategories: [
            'net_sales_budget_variance',
            'hourly_labor_budget_variance',
            'cogs_budget_variance',
            'yext_score',
            'zenput',
            'cavayou',
          ],
          isScorecardColored: true,
          isScoreFavorable: (score) => score === 1,
          maxScore: 'categoryCount',
          isWeightVisible: false,
          historicalPerformanceDefaultKpi: 'net_sales_budget_variance',
          scorecardTitle: 'KPIs',
          isPercentileRankVisible: false,
          isRankVisible: true,
        }
      case 'demo_bk':
        return {
          ...defaultConfig,
          isExrayVisible: true,
          scorecardCategories: [
            'hourly_labor_budget_variance',
            'rcp_budget_variance',
            'ot_hours',
            'annualized_hourly_turnover_rate',
            'annualized_salaried_turnover_rate',
          ],
          maxScore: 'locationCount',
          isWeightVisible: true,
          isScorecardColored: false,
          isScoreFavorable: () => false,
          historicalPerformanceDefaultKpi: 'rcp_budget_variance',
        }
      default:
        return defaultConfig
    }
  }, [brand])
}

export default useConfig
