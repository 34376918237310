import _ from 'lodash'
import React from 'react'

import { ISalesmanship } from '../../index'
import LeaderboardTable from '../LeaderboardTable'
import useSalesLeaderboardData from './hooks/useSalesLeaderboardData'

interface IProps {
  leaderboardTitle: string
  dataColumnHeader: string
  salesLeaderboardData: ISalesmanship[]
  salesType: string
  numberOfDecimals?: number
  storeName: string
  isExpanded: boolean
}

function SalesLeaderboard({
  leaderboardTitle,
  dataColumnHeader,
  salesLeaderboardData,
  salesType,
  numberOfDecimals = 0,
  storeName,
  isExpanded,
}: IProps) {
  const { leaderboardData } = useSalesLeaderboardData(
    salesLeaderboardData,
    salesType,
    numberOfDecimals,
  )

  return (
    <LeaderboardTable
      leaderboardTitle={leaderboardTitle}
      dataColumnHeader={dataColumnHeader}
      isExpanded={isExpanded}
      storeName={storeName}
      leaderboardData={leaderboardData}
    />
  )
}
export default React.memo(SalesLeaderboard)
