import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'
import Table from 'pared/components/basicUi/table'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import useGetDirectors from 'pared/hooks/useGetDirectors'

import useCash from './Cash/hooks/useCash'
import useCashColumns from './Cash/hooks/useCashColumns'
import useCashStyles from './Cash/hooks/useCashStyles'

interface IProps {
  isBreakdownByDirectors: boolean
  startDate?: string
  endDate?: string
  locationIds?: number[]
  locationGroupIds?: number[]
  locationGroupType?: string
}

const MobileCotainer = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 20px 0;

  @media ${MOBILE_WIDTH} {
    margin: 20px 0px 0px;
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

export default function CashControlTable({
  startDate,
  endDate,
  locationIds,
  locationGroupIds,
  isBreakdownByDirectors,
  locationGroupType,
}: IProps) {
  const { directors } = useGetDirectors()

  const { cash, cashLoading } = useCash(
    directors,
    isBreakdownByDirectors,
    locationIds,
    locationGroupIds,
    startDate,
    endDate,
    locationGroupType,
  )
  const cashColumns = useCashColumns(isBreakdownByDirectors, locationGroupType)
  const cashStyles = useCashStyles()

  return (
    <MobileCotainer>
      <Spin spinning={cashLoading}>
        <Table
          {...cashStyles}
          dataSource={cash}
          columns={cashColumns}
          rowKey="storeId"
        />
      </Spin>
    </MobileCotainer>
  )
}
