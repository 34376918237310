import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { IApiDataType } from '../types'

const query = gql`
  query userEvents($iInputParams: JSON!) {
    getCustomizedReport(
      iCustomizedReportName: "USER_EVENT_LOG_TABLE"
      iInputParams: $iInputParams
    ) {
      nodes {
        reportName
        reportDefinition
        reportResult
      }
    }
  }
`

export const userEventsConfigs = {
  email: 'string',
  lastEventAt: 'date-string',
} as const

const useUserEvents = () => {
  const { data, loading } = useQuery(query, {
    variables: {
      iInputParams: {
        startDate: '2024-06-01',
      },
    },
  })

  return {
    data: useMemo((): IApiDataType => {
      const tableData =
        data?.getCustomizedReport?.nodes[0]?.reportResult?.tableData

      if (!tableData) return null

      return { source: tableData }
    }, [data]),
    loading,
  }
}

export default useUserEvents
