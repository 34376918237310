import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Modal from '@material-ui/core/Modal'
import Select from '@material-ui/core/Select'
import styled from 'styled-components'

import Spin from 'pared/components/basicUi/spin'
import COLORS from 'pared/constants/colors'

import useAssignTracker, {
  IAssignedTrackerType,
} from './hooks/useAssignTracker'

export interface IPropsType extends IAssignedTrackerType {
  onClose: () => void
}

const Container = styled.div`
  width: 40%;
  min-width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px;
  outline: none;
  background-color: white;
`

const Title = styled.div`
  font-family: Lexend-SemiBold;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
`

const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  font-family: Lexend-Regular;
`

const StyledInputLabel = styled(InputLabel)`
  position: absolute;
  top: 10px;
  left: 10px;
`

const StyledSelect = styled(Select)`
  margin-top: 30px;
  width: 300px;
  height: 35px;
  border-radius: 0;
  border: 1px solid ${COLORS.Smoke};
  padding-left: 10px;
`

const StyledMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
}

const ConfirmButton = styled.button<{ disabled: boolean }>`
  margin-top: 30px;
  margin-left: 30px;
  background-color: #07a4c7;
  border: none;
  color: white;
  padding: 10px 15px 10px 15px;
  width: 130px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-family: Lexend-Regular;
  ${({ disabled }) =>
    disabled
      ? `
          cursor: none;
          pointer-events: none;
        `
      : `cursor: pointer;`};
`

const ASSIGN_JOB_TITLE = [
  'Restaurant Leader',
  'Assistant Restaurant Leader',
  'Breakfast Leader',
  'Shift Leader',
  'Team Leader',
  'Crew Training',
]

const AssignModal = ({ onClose, ...props }: IPropsType) => {
  const {
    assignTrackerValue,
    setAssignTrackerValue,
    submit,
    isLoading,
    hasErrors,
  } = useAssignTracker(props, onClose)
  return (
    <Modal onClose={onClose} open>
      <Container>
        <Title>Assign Training Tracker</Title>
        <StyledFormControl>
          <StyledInputLabel id="assign-tracker-review">
            Choose Tracker
          </StyledInputLabel>
          <StyledSelect
            labelId="assign-tracker-review"
            label="Assign Tracker"
            value={assignTrackerValue || ''}
            onChange={(event) =>
              setAssignTrackerValue(event.target.value as string)
            }
            error={hasErrors && !assignTrackerValue}
            MenuProps={StyledMenuProps}
          >
            {ASSIGN_JOB_TITLE.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </StyledSelect>
          <ConfirmButton onClick={submit} disabled={isLoading}>
            <Spin spinning={isLoading}>Assign</Spin>
          </ConfirmButton>
        </StyledFormControl>
      </Container>
    </Modal>
  )
}

export default AssignModal
