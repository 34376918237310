import * as _ from 'lodash'
import { useMemo } from 'react'

import { IColumnsType } from '../types'

export default <Data>(
  dataSource: Data[],
  mergedBody: IColumnsType<Data>[],
  field: 'summary',
) =>
  useMemo(() => {
    const summaryDataSource = mergedBody.map((column) => {
      const { key } = column
      const summary = column[field]
      const value = !summary ? null : summary(dataSource)

      return { ...column, key, value }
    })

    if (summaryDataSource.some((summary) => summary.value))
      return summaryDataSource

    return []
  }, [dataSource, mergedBody])
