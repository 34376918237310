import MaterialSelect from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import * as d3 from 'd3-hierarchy'
import React, { useMemo, useState } from 'react'

import MenuItem, { IValueType } from './MenuItem'
import useValidateItems from './hooks/useValidateItems'

interface IPropsType<Value extends IValueType> {
  className?: string
  value: Value['value'][]
  onChange: (value: Value['value'][]) => void
  dataSource: Value[]
  fullWidth?: boolean
  displayEmpty?: boolean
  disabled?: boolean
}

const useStyles = makeStyles({
  text: {
    fontFamily: 'Lexend-Regular',
  },
})

const Select = <Value extends IValueType>({
  className = '',
  value,
  onChange,
  dataSource,
  fullWidth,
  displayEmpty,
  disabled,
}: IPropsType<Value>) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const option = useMemo(() => d3.stratify<Value>()(dataSource), [dataSource])
  const node = useValidateItems({ value, onChange, option })

  return (
    <MaterialSelect
      className={`${classes.text} ${className}`}
      value={value.join('/')}
      renderValue={() => node.map(({ text }) => text).join(' / ')}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      variant="outlined"
      fullWidth={fullWidth}
      displayEmpty={displayEmpty}
      disabled={disabled}
    >
      {option?.children?.map((subOption) => (
        <MenuItem<Value>
          key={subOption.id}
          onChange={onChange}
          onClose={() => setOpen(false)}
          option={subOption}
        />
      ))}
    </MaterialSelect>
  )
}

export default React.memo(Select) as unknown as typeof Select
