import { useQuery } from '@apollo/client'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import COLORS from 'pared/constants/colors'

import { TREND_LOCATION_GROUP_CUSTOMIZED_REPORT } from './gql'
import { getDateRangeLabel } from './util'

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350

interface IProps {
  trendChartParams: {
    iReportName: string
    iLocationGroupId: number
    iEndDate: string
    iGroupBy: string
  }
}

interface IData {
  trendLocationGroupCustomizedReport: {
    nodes: {
      startDate: string
      endDate: string
      reportResult: {
        classicUsageVariancePercent: number
        bonelessUsageVariancePercent: number
        tendersUsageVariancePercent: number
      }[]
    }[]
  }
}

interface IOrganizedData {
  dateRanges: string[]
  classicUsageVariancePercent: number[]
  bonelessUsageVariancePercent: number[]
  tendersUsageVariancePercent: number[]
}

const TrendChart = ({ trendChartParams }: IProps) => {
  const { loading, data }: { loading: boolean; data: IData | undefined } =
    useQuery(TREND_LOCATION_GROUP_CUSTOMIZED_REPORT, {
      variables: trendChartParams,
    })

  if (loading) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  if (!data) {
    return (
      <PageStatusDiv>
        There is no data within the date range selected. Please select a
        different date range.
      </PageStatusDiv>
    )
  }

  const {
    trendLocationGroupCustomizedReport: { nodes },
  } = data
  const organizedData = nodes.reduce(
    (acc, { startDate, reportResult }) => {
      const [
        {
          classicUsageVariancePercent,
          bonelessUsageVariancePercent,
          tendersUsageVariancePercent,
        },
      ] = reportResult

      acc.dateRanges.push(
        getDateRangeLabel(startDate, trendChartParams.iGroupBy),
      )
      acc.classicUsageVariancePercent.push(classicUsageVariancePercent)
      acc.bonelessUsageVariancePercent.push(bonelessUsageVariancePercent)
      acc.tendersUsageVariancePercent.push(tendersUsageVariancePercent)
      return acc
    },
    {
      dateRanges: [],
      classicUsageVariancePercent: [],
      bonelessUsageVariancePercent: [],
      tendersUsageVariancePercent: [],
    } as IOrganizedData,
  )

  const yAxisDataArr = [
    {
      type: 'line',
      data: organizedData.classicUsageVariancePercent,
      tooltipLabel: 'Classic',
      borderColor: COLORS.Basil,
      backgroundColor: COLORS.Basil,
    },
    {
      type: 'line',
      data: organizedData.bonelessUsageVariancePercent,
      tooltipLabel: 'Boneless',
      borderColor: COLORS.Pomodoro,
      backgroundColor: COLORS.Pomodoro,
    },
    {
      type: 'line',
      data: organizedData.tendersUsageVariancePercent,
      tooltipLabel: 'Tenders',
      borderColor: COLORS.Plum,
      backgroundColor: COLORS.Plum,
    },
  ]

  const chartOptions = {
    title: 'Usage Variance',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel: 'Usage variance',
    yLeftTickCallback: (value: number) => {
      return `${value}%`
    },
  }

  return (
    <MixedChart
      xAxisData={organizedData.dateRanges}
      yAxisDataArr={yAxisDataArr}
      options={chartOptions}
    />
  )
}

export default TrendChart

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`
