import { gql } from '@apollo/client'

export interface IOrderingAnalysisNodeType {
  locationId: number
  orderingAnalysisData: {
    item: string
    itemType: string
    endingCountDate: string
    endingQty: number
    endingCost: number
    accountingDate: string
    vendorName: string
    itemUmDescription: string
    quantity: number
    cost: number
    purchaseValue: number
    forecast: { date: string; qty: number }[]
  }[]
}

export interface IOrderingAnalysisType {
  listLocationOrderingAnalysis: {
    nodes: IOrderingAnalysisNodeType[]
  }
}

export interface IOrderingAnalysisVariablesType {
  iFilter: { location_ids: number[] }
}

export const LIST_LOCATION_ORDERING_ANALYSIS = gql`
  query ListLocationOrderingAnalysis($iFilter: JSON!) {
    listLocationOrderingAnalysis(iFilter: $iFilter) {
      nodes {
        locationId
        orderingAnalysisData
      }
    }
  }
`
