import { useState } from 'react'

import { IReportResult } from 'pared/components/CustomizedReport'

export interface ICategoryFilterType {
  id: string
  parentId: 'root' | null
  value: string
  text: string
}

const useItemFilter = (customizedReportResult: IReportResult) => {
  const [itemFilter, setItemFilter] = useState('')
  if (!customizedReportResult.tableData || itemFilter === '') {
    return {
      itemFilter,
      setItemFilter,
      itemFilteredCustomizedReportResult: customizedReportResult,
    }
  }

  const filteredTableData = customizedReportResult.tableData.filter(
    ({ item }) => item.toLowerCase().search(itemFilter.toLowerCase()) !== -1,
  )

  // Summary row is generated on the backend, so must be replaced when filtering on the frontend
  const kpis = {
    cost: 0,
    quantity: 0,
    variance: 0,
    varianceUnits: 0,
    actualUsage: 0,
    menuWasteDollars: 0,
    menuWasteUnits: 0,
    rawWasteDollars: 0,
    rawWasteUnits: 0,
    totalWasteDollars: 0,
    totalWasteUnits: 0,
  }

  const summary = filteredTableData.reduce(
    (acc, cur) => {
      for (const kpi in kpis) {
        acc[kpi] += cur[kpi]
      }

      for (const week in cur.weekDetails) {
        if (acc.weekDetails[week] === undefined) {
          acc.weekDetails[week] = { ...kpis }
        }

        for (const weekKpi in kpis) {
          acc.weekDetails[week][weekKpi] += cur.weekDetails[week][weekKpi]
        }
      }

      return acc
    },
    {
      ...kpis,
      weekDetails: {},
    },
  )

  if (summary.variance + summary.actualUsage === 0) {
    summary.variancePercent = null
  } else {
    summary.variancePercent =
      (summary.variance * 100) / (summary.variance + summary.actualUsage)
  }

  return {
    itemFilter,
    setItemFilter,
    itemFilteredCustomizedReportResult: {
      ...customizedReportResult,
      summary: {
        ...customizedReportResult.summary,
        ...summary,
      },
      tableData: filteredTableData as any,
    },
  }
}

export default useItemFilter
