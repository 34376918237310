import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { IDirector } from 'pared/data/getDirectors'

import { LIST_LOCATION_GROUP_MARKET_ANALYSIS_DETAILS } from '../gql'
import { ALL } from './useDiscountTypeItems'

const useLocationGroupDiscounts = (
  directors: IDirector[],
  selectedDiscount?: string[],
  locationGroupIds?: number[],
  startDate?: string,
  endDate?: string,
  locationGroupType?: string,
) => {
  const { data: discountsData, loading: discountLocationGroupLoading } =
    useQuery(LIST_LOCATION_GROUP_MARKET_ANALYSIS_DETAILS, {
      variables: {
        iStartDate: startDate,
        iEndDate: endDate,
        iFilter: !locationGroupType
          ? {
              location_group_ids: [...(locationGroupIds || [])],
            }
          : {
              location_group_types: [locationGroupType],
            },
      },
      skip: !startDate || !endDate || !locationGroupIds,
    })

  let compsDetailType: string[] = []

  const discountLocationGroupData = useMemo(() => {
    const rawData =
      discountsData?.listLocationGroupMarketAnalysisDetails?.nodes || []

    let totalDetails: {
      [compsType: string]: [number, number, number]
    } = {}

    totalDetails['totalDiscounts'] = [0, 0, 0]

    const tableData = rawData.map((row: any) => {
      const locationGroupId = row.locationGroupId
      const director = directors.find(
        (d: IDirector) => d.locationGroupId === locationGroupId,
      )

      let totalDiscounts: [string, string, string] = ['-', '-', '-']

      if (row.totalCount || row.totalCount === 0) {
        totalDiscounts[0] = row.totalCount
      }

      if (row.totalAmount || row.totalAmount === 0) {
        totalDiscounts[2] = (row.totalAmount / 100.0).toFixed(0)
        totalDiscounts[1] = (row.totalAmount / 100.0).toFixed(0)
      }

      const itemDetails: {
        [compsType: string]: [string, string, string]
      } = {}

      for (let key in row.compsDetails) {
        const itemData = row.compsDetails[key]
        let count = '-'
        let amount = '-'
        if (itemData?.count || itemData.count === 0) {
          count = itemData.count
        }
        if (itemData?.amount || itemData.amount === 0) {
          amount = (itemData.amount / 100.0).toFixed(0)
        }

        if (!selectedDiscount || selectedDiscount[0] === ALL) {
          if (!totalDetails[itemData.type]) {
            totalDetails[itemData.type] = [
              (count = itemData.count || 0),
              itemData.amount / 100,
              itemData.amount / 100,
            ]
          } else {
            totalDetails[itemData.type][0] += itemData.count || 0
            totalDetails[itemData.type][1] += itemData.amount / 100 || 0
            totalDetails[itemData.type][2] += itemData.amount / 100 || 0
          }

          itemDetails[itemData.type] = [count, amount, amount]
        } else if (selectedDiscount[1] === ALL) {
          if (itemData.category === selectedDiscount[0]) {
            if (!totalDetails[itemData.type]) {
              totalDetails[itemData.type] = [
                (count = itemData.count || 0),
                itemData.amount / 100,
                itemData.amount / 100,
              ]
            } else {
              totalDetails[itemData.type][0] += itemData.count || 0
              totalDetails[itemData.type][1] += itemData.amount / 100 || 0
              totalDetails[itemData.type][2] += itemData.amount / 100 || 0
            }

            itemDetails[itemData.type] = [count, amount, amount]
          }
        } else {
          if (itemData.type === selectedDiscount[1]) {
            if (!totalDetails[itemData.type]) {
              totalDetails[itemData.type] = [
                (count = itemData.count || 0),
                itemData.amount / 100,
                itemData.amount / 100,
              ]
            } else {
              totalDetails[itemData.type][0] += itemData.count || 0
              totalDetails[itemData.type][1] += itemData.amount / 100 || 0
              totalDetails[itemData.type][2] += itemData.amount / 100 || 0
            }

            itemDetails[itemData.type] = [count, amount, amount]
          }
        }

        if (!compsDetailType.includes(itemData.type))
          compsDetailType.push(itemData.type)
      }

      itemDetails['Total'] = [row.totalCount, row.totalAmount, row.totalAmount]

      totalDetails['totalDiscounts'][0] += row.totalCount || 0
      totalDetails['totalDiscounts'][1] += row.totalAmount / 100 || 0
      totalDetails['totalDiscounts'][2] += row.totalAmount / 100 || 0

      return {
        ...itemDetails,
        directorId: director?.directorId || 0,
        directorName: [director?.firstName || '', director?.lastName || '']
          .filter(Boolean)
          .join(' '),
        totalDiscounts,
        locationGroupName: row.locationGroupName,
      }
    })

    if (tableData?.length > 1) {
      compsDetailType?.forEach((type: string) => {
        tableData.sort((a: any, b: any) => {
          if (a[type] === undefined) return 1
          if (b[type] === undefined) return -1
          return b[type][1] - a[type][1]
        })
      })
    }

    return [
      ...tableData,
      {
        directorId: 0,
        directorName: 'ALL',
        locationGroupName: 'All',
        ...totalDetails,
      },
    ]
  }, [
    discountsData,
    selectedDiscount,
    directors,
    locationGroupIds,
    startDate,
    endDate,
    locationGroupType,
  ])

  return { discountLocationGroupData, discountLocationGroupLoading }
}

export default useLocationGroupDiscounts
