import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query ListAcePopPeriodPurchaseKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        metricData
        metricSummaryData
      }
    }

    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        metricData
        metricSummaryData
      }
    }
  }
`

export const acePopPeriodPurchaseConfigs = {
  beginningInventory: 'price',
  purchaseAmount: 'price',
  endingInventory: 'price',
  netSales: 'price',
  foodAndPurchaseCostPercentage: 'percent',
  hourlyLaborCost: 'price',
  hourlyLaborCostPercentage: 'percent',
} as const

const KPIS = [
  'beginning_inventory',
  'purchase_amount',
  'ending_inventory',
  'net_sales',
  'food_and_purchase_cost_percentage',
  'hourly_labor_cost',
  'hourly_labor_cost_percentage',
]

const useAcePopPeriodPurchase = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: hasGroupBy
          ? groupFilter?.ids
          : groupFilter?.list?.map((g) => g.id),
        metrics: KPIS,
      },
      hasGroupBy,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const customizedData: any =
        data?.[
          hasGroupBy
            ? 'listLocationMetricValues'
            : 'listLocationGroupMetricValues'
        ]?.nodes

      if (!customizedData) return null

      const source = customizedData.map((locationData: any) => {
        const groupInfo = groupFilter?.list?.find(
          (l) =>
            l.id ===
            (hasGroupBy
              ? locationData.locationId
              : locationData.locationGroupId),
        )

        const kpisData: { [key: string]: number | null } = {}

        for (const key in locationData.metricData) {
          if (locationData.metricData.hasOwnProperty(key)) {
            const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
            switch (locationData.metricData[key].unit) {
              case 'DOLLAR':
              case 'PERCENTAGE':
                kpisData[newKey] = locationData.metricData[key].value
                  ? locationData.metricData[key].value * 100
                  : null
                break

              default:
                kpisData[newKey] = locationData.metricData[key].value
                break
            }
          }
        }

        return {
          ...kpisData,
          groupInfo,
        }
      })

      const [rawSummary] = customizedData.filter(
        (locationData: any) => locationData.metricSummaryData != null,
      )
      const summary: { [key: string]: number } = {}
      for (const key in rawSummary?.metricSummaryData) {
        if (rawSummary.metricSummaryData.hasOwnProperty(key)) {
          const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
          switch (rawSummary.metricSummaryData[key].unit) {
            case 'DOLLAR':
            case 'PERCENTAGE':
              summary[newKey] = rawSummary.metricSummaryData[key].value * 100
              break

            default:
              summary[newKey] = rawSummary.metricSummaryData[key].value
              break
          }
        }
      }

      return {
        source,
        summary: {
          ...summary,
        },
      }
    }, [groupFilter, data]),
    loading,
  }
}

export default useAcePopPeriodPurchase
