import { useMemo } from 'react'

import navigator from 'pared/Routes/navigator'

import useBrands from './useBrands'

export default () => {
  const { brand } = useBrands()

  return useMemo(() => {
    switch (brand) {
      case 'rmg':
      case 'rmg_nso':
      case 'sullivans':
      case 'sullivans_nso':
      case 'eddie_merlots':
        return {
          corporateDefault: navigator.systemwideOverview(),
          storeDefault: navigator.scorecard(),
        }

      case 'ghai_pop':
        return {
          corporateDefault: navigator.systemwideOverview(),
          storeDefault: navigator.storeDetail(),
          hideChangePassword: true,
        }

      default:
        return {
          corporateDefault: navigator.systemwideOverview(),
          storeDefault: navigator.storeDetail(),
        }
    }
  }, [brand])
}
