import React from 'react'

import Chart from 'pared/charts'

import { IDaypartBreakdown } from '..'
import useConfig from './hooks/useConfig'

interface IProps {
  data: IDaypartBreakdown[]
  width?: number
  height?: number
}

const DayPartPieChart = ({ data, width, height }: IProps) => {
  const config = useConfig(data)

  return <Chart {...config} width={width} height={height} />
}

export default React.memo(DayPartPieChart)
