import { useMemo } from 'react'

import { IDateOptionNodeType, IDateOptionType, IDateType } from './types'
import { ISetDateType } from './useSetDate'

const useInfos = (
  types: IDateType[],
  currentDate: ISetDateType['currentDate'],
  dateOptions: IDateOptionType[] | null,
) =>
  useMemo((): {
    date: IDateOptionNodeType | null
    dateByDiff: Record<number, IDateOptionNodeType>
  } => {
    if (!currentDate || !dateOptions) return { date: null, dateByDiff: {} }

    const option = dateOptions
      .filter((o) => {
        if ((types as string[]).includes(currentDate.type))
          return 'type' in o && o.type === currentDate.type

        return (
          'dateRange' in o &&
          o.dateRange.startDateStr === currentDate.startDateStr &&
          o.dateRange.endDateStr === currentDate.endDateStr
        )
      })
      .sort((a, b) => {
        if ('type' in a && 'type' in b)
          return types.indexOf(a.type) - types.indexOf(b.type)

        return 0
      })?.[0]

    if (!(option && 'type' in option)) return { date: null, dateByDiff: {} }

    const type = (() => {
      switch (option.type) {
        case 'this_week':
        case 'last_week':
          return 'week'
        default:
          return option.type
      }
    })()
    const options = dateOptions.filter(
      (o) => 'type' in o && o.type === type && 'dateRange' in o,
    )
    const optionIndex = options.findIndex((o) => {
      switch (option.type) {
        case 'this_week':
        case 'last_week':
          return (
            'dateRange' in o &&
            o.dateRange.startDate.isSame(option.dateRange.startDate) &&
            o.dateRange.endDate.isSame(option.dateRange.endDate)
          )
        default:
          return o === option
      }
    })

    if (optionIndex === -1) return { date: null, dateByDiff: {} }

    return {
      date: option,
      dateByDiff: options.reduce(
        (result, o, index) => ({
          ...result,
          [optionIndex - index]: o,
        }),
        {},
      ),
    }
  }, [types, currentDate, dateOptions])

export default useInfos
