export const defaultKpiDefs = {
  checkAvg: {
    key: 'checkAvg',
    name: 'Check Avg',
    kpiType: 'sales',
    salesType: 'total',
    kpiKey: 'checkAvg',
    valueType: 'cent',
    digitNum: 2,
  },
  totalPpa: {
    key: 'totalPpa',
    name: 'Total PPA',
    kpiType: 'sales',
    salesType: 'total',
    kpiKey: 'ppa',
    valueType: 'cent',
    digitNum: 2,
  },
  salesPerHour: {
    key: 'salesPerHour',
    name: 'Sales per Labor Hour',
    kpiType: 'sales',
    salesType: 'total',
    kpiKey: 'salesPerHour',
    valueType: 'cent',
    digitNum: 2,
  },
}
