import React from 'react'
import { INode, ReactHiererchyChart } from 'react-hierarchy-chart'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

export interface IOrgChartNode extends INode {
  name: string
  position: string
  childs?: IOrgChartNode[]
}

const StyleDiv = styled.div`
  background-color: ${COLORS.Lavender};
  padding: 50px;
`

const StyledNode = styled.div`
  font-family: Lexend-Regular;
  font-size: 12px;
  text-align: center;
  background-color: white;
  height: 80px;
  width: 200px;
  padding: 10px 20px;
  border: 1px solid;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
`

const EmployeeNameDiv = styled.div`
  font-family: Lexend-SemiBold;
`

interface IProps {
  data: IOrgChartNode[]
}

const HiererchyOrgChart = ({ data }: IProps) => {
  return (
    <StyleDiv>
      <ReactHiererchyChart
        nodes={data}
        direction="horizontal"
        randerNode={(node: IOrgChartNode) => {
          return (
            <StyledNode>
              <EmployeeNameDiv>{node.name}</EmployeeNameDiv>
              <div>{node.position}</div>
            </StyledNode>
          )
        }}
      />
    </StyleDiv>
  )
}

export default React.memo(HiererchyOrgChart)
