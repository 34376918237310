import { createSlice } from '@reduxjs/toolkit'

export interface IAppState {
  appVersion: string
}

const initialState: IAppState = {
  appVersion: '0.0.1',
}

const appSlice = createSlice({
  initialState,
  name: 'app',
  reducers: {
    setAppVersion(state, action: { payload: { appVersion: string } }) {
      const payload = action.payload
      if (payload && payload.appVersion) {
        state.appVersion = payload.appVersion
      }
    },
  },
})

export default appSlice.reducer

export const { setAppVersion } = appSlice.actions
