import { gql, useQuery } from '@apollo/client'
import React, { useMemo } from 'react'

import useBrands from 'pared/layouts/hooks/useBrands'

interface ILocationTopMissingItemsNodeType {
  startDate: string
  endDate: string
  itemName: string
  totalCount: number
}

export interface ILocationTopMissingItemsType {
  getLocationTopMissingItems?: {
    nodes?: ILocationTopMissingItemsNodeType[]
  }
}

export interface ILocationTopMissingItemsVariablesType {
  iStartDate?: string
  iEndDate?: string
  iFilter: {
    location_ids: number[]
  }
}

export const GET_LOCATION_TOP_MISSING_ITEMS = gql`
  query GetLocationTopMissingItems(
    $iEndDate: Date!
    $iStartDate: Date!
    $iFilter: JSON!
  ) {
    getLocationTopMissingItems(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        startDate
        endDate
        itemName
        totalCount
      }
    }
  }
`

const useCustomizedSummary = (
  variables: ILocationTopMissingItemsVariablesType,
) => {
  const { brand } = useBrands()
  const { data, loading } = useQuery<
    ILocationTopMissingItemsType,
    ILocationTopMissingItemsVariablesType
  >(GET_LOCATION_TOP_MISSING_ITEMS, {
    variables,
    skip:
      !variables.iStartDate ||
      !variables.iEndDate ||
      !['bibibop'].includes(brand),
  })

  return useMemo(
    () =>
      (data?.getLocationTopMissingItems?.nodes || []).length === 0
        ? {}
        : {
            customiedAreasOfImprovementMessages: [
              <>
                The Top Missing Items are{' '}
                {data?.getLocationTopMissingItems?.nodes?.map(
                  (d, index, allData) => {
                    return (
                      <React.Fragment key={d.itemName}>
                        <strong>{d.itemName}</strong>
                        {index === allData.length - 1 ? '' : ', '}
                        {index === allData.length - 2 ? 'And ' : ''}
                      </React.Fragment>
                    )
                  },
                )}
              </>,
            ],
          },
    [data],
  )
}

export default useCustomizedSummary
