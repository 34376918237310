import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/constants/styles'

const ChartContainer = styled.div`
  :not(:first-child) {
    margin: 103px 0px 0px;

    @media ${MOBILE_WIDTH} {
      margin: 60px 0px 0px;
    }
  }

  @media ${MOBILE_WIDTH} {
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

export default ChartContainer
