import { gql } from '@apollo/client'

export interface ILossPreventionItems {
  type: string
  amount: number
  count: number
  checkPercent: number
}

interface ICashControlDetails {
  [key: string]: {
    type: string
    amount: number
    count: number
    checkPercent: number
  }
}

export interface IListLocationLossPreventionKpisNodeType {
  locationInfo: {
    id: number
    code: string
    name: string
  }
  cashOverOrShort: number
  inaccurateCashierShiftPercent: number
  inaccurateCashierShiftCount: number
  lossPreventionItems: ILossPreventionItems[]
}

export interface IListLocationLossPreventionKpisType {
  listLocationLossPreventionKpis: {
    nodes: IListLocationLossPreventionKpisNodeType[]
  }
}

export interface IListLocationGroupCashControlDetailsNodeType {
  locationGroupId: number
  locationGroupName: string
  startDate: string
  endDate: string
  cashControlDetails: ICashControlDetails
}

export interface IListLocationGroupCashControlDetailsType {
  listLocationGroupCashControlDetails: {
    nodes: IListLocationGroupCashControlDetailsNodeType[]
  }
}

export const LIST_LOCATION_LOSS_PREVENTION_KPIS = gql`
  query ListLocationLossPreventionKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationLossPreventionKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationInfo {
          id
          code
          name
        }
        cashOverOrShort
        inaccurateCashierShiftCount
        inaccurateCashierShiftPercent
        lossPreventionItems
      }
    }
  }
`

export const LIST_LOCATION_GROUP_CASH_CONTROL_DETAILS = gql`
  query ListLocationGroupCashControlDetails(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupCashControlDetails(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        locationGroupName
        startDate
        endDate
        cashControlDetails
      }
    }
  }
`
