import FormControl from '@material-ui/core/FormControl'
import styled from 'styled-components'

import { IDateRange } from 'pared/data/getDateRanges'

import Chart from './Chart'
import KpiSelect from './KpiSelect'

interface IProps {
  kpi: string
  onSelectKpi: any
  navParams: any
  selectedDateRange: IDateRange
  storeName: string
  shouldShowScorecard: boolean
}

const WebLargeMain = ({
  kpi,
  onSelectKpi,
  navParams,
  selectedDateRange,
  storeName,
  shouldShowScorecard,
}: IProps) => {
  return (
    <>
      <FlexContainer>
        <TitleDiv>Historical Performance</TitleDiv>
        <KpiFormControl
          variant="outlined"
          margin="dense"
          style={{ width: 300 }}
        >
          <KpiSelect
            kpi={kpi}
            onSelectKpi={onSelectKpi}
            shouldShowScorecard={shouldShowScorecard}
          />
        </KpiFormControl>
      </FlexContainer>
      <Chart
        kpi={kpi}
        navParams={navParams}
        selectedDateRange={selectedDateRange}
        storeName={storeName}
      />
    </>
  )
}

export default WebLargeMain

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`

const TitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
`

const KpiFormControl = styled(FormControl)`
  background-color: white;
`
