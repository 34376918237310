import moment from 'moment'
import { useEffect } from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import { feature, page } from 'pared/analytics/user'
import CustomizedReport from 'pared/components/CustomizedReport'
import Select from 'pared/components/basicUi/select'
// import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import LocationSelector from 'pared/components/nav/LocationSelector'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import useDefaultLocation from 'pared/hooks/useDefaultLocation'
import { scrollToTop } from 'pared/utils/web'

// import {
//   TYPE_LAST_WEEK,
//   TYPE_PERIOD,
//   TYPE_QUARTER,
//   TYPE_THIS_WEEK,
//   TYPE_YEAR,
//   TYPE_YESTERDAY,
// } from 'pared/data/getDateRanges'
import BunForecast from './BunForecast'
import useBunFilter, { IBunFilterType } from './hooks/useBunFilter'
import useBunForecast from './hooks/useBunForecast'

interface IPropsType {
  navParams: INavParams
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 25px 0px;
    }
  }
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const Container = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px;
  }
`

export const SubHeader = styled.h1`
  margin: 83px 0px 20px;
  display: flex;
  width: 1200px;
  justify-content: space-between;

  @media ${MOBILE_WIDTH} {
    display: block;
    width: 100%;
    margin: 60px 0px 0px;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
  }

  h3 {
    margin: 0px 10px 0px 0px;
    display: inline-block;
    font-family: Lexend-SemiBold;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
  }
`

const MobileCotainer = styled.div`
  @media ${MOBILE_WIDTH} {
    margin: 20px 0px 0px;
    padding: 20px 30px;
    border: 1px solid;
    overflow: scroll hidden;
  }
`

const MenuItemSelect = styled(Select)`
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
` as typeof Select

const Buns = ({ navParams }: IPropsType) => {
  useDefaultLocation()
  const storeId = navParams.storeId
  const locationId = parseInt(storeId || '0', 10)

  const { bunFilter, setBunFilter, bunFilters } = useBunFilter(locationId)
  const displayData = useBunForecast(locationId, bunFilter)

  useEffect(() => {
    if (navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl)
      feature.used('Corporate Guest')
    }
  }, [navParams.pageUrl])

  const bunsOverviewInputParams = {
    locationId,
    queryDate: moment().format('YYYY-MM-DD'),
  }

  return (
    <>
      <Header>
        <div>
          <h1>Buns</h1>

          {/* <StoreName>TODO: store name</StoreName> */}
        </div>

        <Selectors>
          <LocationSelector navParams={navParams} />

          {/* <DateRangeSelector
            navParams={navParams}
            dateRangeOptions={[
              TYPE_YEAR,
              TYPE_QUARTER,
              TYPE_PERIOD,
              TYPE_LAST_WEEK,
              TYPE_THIS_WEEK,
              TYPE_YESTERDAY,
            ]}
          /> */}
        </Selectors>
      </Header>

      <Container>
        <SubHeader>
          <h1>Buns</h1>
        </SubHeader>

        <CustomizedReport
          reportName="IRMG_LOCATION_BUNS_USAGE_OVERVIEW_TABLE"
          inputParams={bunsOverviewInputParams}
        />

        <SubHeader>
          <h1>Bun Forecast</h1>

          <div>
            <h3>Bun Filter:</h3>

            <MenuItemSelect<IBunFilterType>
              value={bunFilter}
              onChange={setBunFilter}
              dataSource={bunFilters}
            />
          </div>
        </SubHeader>

        <MobileCotainer>
          <BunForecast dataSource={displayData} />
        </MobileCotainer>
      </Container>
    </>
  )
}

export default Buns
