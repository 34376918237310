import { IDirector } from 'pared/data/getDirectors'

import useLocationDiscounts from './useLocationDiscounts'
import useLocationGroupDiscounts from './useLocationGroupDiscounts'

export interface IHeatMapCell {
  value: number
  color: string
}

export interface IDiscountType {
  storeId: number
  storeCode: string
  storeName: string
  directorId: number
  directorName: string
  totalDiscounts: [string, IHeatMapCell, string]
  grossSales: string
}

export const percentageToColor = (
  percentage: number,
  maxHue = 120,
  minHue = 0,
) => {
  const hue = percentage * (maxHue - minHue) + minHue
  return `hsl(${hue}, 80%, 80%)`
}

const useDiscounts = (
  directors: IDirector[],
  isBreakdownByDirectors: boolean,
  selectedDiscount: string,
  locationIds?: number[],
  locationGroupIds?: number[],
  startDate?: string,
  endDate?: string,
  locationGroupType?: string,
) => {
  const { discountLocationData, discountLocationLoading } =
    useLocationDiscounts(
      selectedDiscount,
      locationIds,
      locationGroupIds,
      startDate,
      endDate,
    )
  const { discountLocationGroupData, discountLocationGroupLoading } =
    useLocationGroupDiscounts(
      directors,
      selectedDiscount,
      locationGroupIds,
      startDate,
      endDate,
      locationGroupType,
    )

  return {
    discounts: isBreakdownByDirectors
      ? discountLocationGroupData
      : discountLocationData,
    discountLoading: isBreakdownByDirectors
      ? discountLocationGroupLoading
      : discountLocationLoading,
  }
}

export default useDiscounts
