import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import IconButton from '@mui/material/IconButton'
import React, { useState } from 'react'
import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/constants/styles'

import { IExrayDataType } from './types'

export interface IPropsType {
  exrayData: IExrayDataType[]
}

const MainContainer = styled.div`
  border: 1px solid black;
  border-radius: 8px;
  overflow: hidden;
  width: 900px;

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

const Section = styled.div`
  display: flex;
  border-bottom: 1px solid black;

  &:last-child {
    border-bottom: 0;
  }
  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`

const Title = styled.div<{
  backgroundColor?: string
}>`
  font-family: Lexend-Semibold;
  font-size: 18px;
  background-color: ${({ backgroundColor }) => backgroundColor || 'black'};
  color: white;
  padding: 20px 0 15px 15px;
  flex: 1;
`

const Content = styled.div`
  font-family: Lexend-Regular;
  padding: 15px;
  flex: 6;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-style: normal;
  font-family: Lexend-Medium;
  height: 40px;
  padding-bottom: 5px;
`

const TableContainer = styled.div`
  padding: 20px 0;
`

const Category = ({ data }: { data: IExrayDataType }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpand = () => {
    setIsExpanded((isExpanded) => !isExpanded)
  }

  return (
    <Section>
      <Title backgroundColor={data.backgroundColor}>{data.title}</Title>
      <Content>
        {data.header ? (
          <Header>
            <div>{data.header}</div>
            {data.table ? (
              <IconButton size="small" aria-label="help" onClick={toggleExpand}>
                {isExpanded ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}
              </IconButton>
            ) : null}
          </Header>
        ) : null}
        {typeof data?.body === 'string' ? (
          <div dangerouslySetInnerHTML={{ __html: data?.body || '' }} />
        ) : (
          data?.body
        )}
        {isExpanded ? <TableContainer>{data.table}</TableContainer> : null}
      </Content>
    </Section>
  )
}

const ExrayTable = ({ exrayData }: IPropsType) => {
  return (
    <MainContainer>
      {exrayData?.map((data: IExrayDataType, index: number) => {
        return <Category key={index} data={data} />
      })}
    </MainContainer>
  )
}

export default React.memo(ExrayTable)
