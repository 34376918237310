import { BRAND_ID, BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { getBrand } from 'pared/utils/brand'

import { IBusinessLabels, getBusinessLabels } from './businessLabels'
import { IFinancialLabels, getFinancialLabels } from './financialLabels'
import {
  IDayPartSequence,
  getDayPartSequence,
  getIsLegacyBrand,
  getIsQsr,
  getLocationGroups,
} from './general'
import { IGuestConfig, getGuestConfig } from './guest'
import { IModules, getModules } from './modules'
import { IOverviewConfig, getOverviewConfig } from './overview'
import {
  ISalesmanshipLabels,
  getSalesmanshipLabels,
} from './salesmanshipLabels'

export interface IBrandSettings {
  brand: string
  brandId: number
  brandLocationGroupId: number
  isLegacy: boolean
  isQsr: boolean
  dayPartSequence: IDayPartSequence
  labels: {
    business: IBusinessLabels
    financial: IFinancialLabels
    salesmanship: ISalesmanshipLabels
  }
  modules: IModules
  guest: IGuestConfig
  overview: IOverviewConfig
  locationGroups: string[]
}

class CustomerSettings {
  readonly cachedBrandSettings: { [brand: string]: IBrandSettings }

  constructor() {
    this.cachedBrandSettings = {}
  }

  getBrandSettings(): IBrandSettings {
    const brand = getBrand()

    const settings: IBrandSettings = {
      brand,
      brandId: BRAND_ID[brand],
      brandLocationGroupId: BRAND_LOCATION_GROUP_ID[brand],
      isQsr: getIsQsr(brand),
      isLegacy: getIsLegacyBrand(brand),
      dayPartSequence: getDayPartSequence(brand),
      labels: {
        business: getBusinessLabels(brand),
        financial: getFinancialLabels(brand),
        salesmanship: getSalesmanshipLabels(brand),
      },
      modules: getModules(brand),
      guest: getGuestConfig(brand),
      overview: getOverviewConfig(brand),
      locationGroups: getLocationGroups(brand),
    }

    this.cachedBrandSettings[brand] = settings
    return settings
  }
}

const customerSettingsInstance = new CustomerSettings()

export function getBrandSettings() {
  return customerSettingsInstance.getBrandSettings()
}

export function getBusinessLabel(
  labelKey: Exclude<
    keyof IBusinessLabels,
    'isDirectorClickable' | 'isGmClickable' | 'isStoreClickable' | 'showGm'
  >,
): string {
  const brandSettings = customerSettingsInstance.getBrandSettings()
  return brandSettings.labels.business[labelKey] || ''
}

export function getFinancialLabel(labelKey: keyof IFinancialLabels): string {
  const brandSettings = customerSettingsInstance.getBrandSettings()
  return brandSettings.labels.financial[labelKey] || ''
}

export function getSalesmanshipLabel(
  labelKey: keyof ISalesmanshipLabels,
): string {
  const brandSettings = customerSettingsInstance.getBrandSettings()
  return brandSettings.labels.salesmanship[labelKey] || ''
}
