import _ from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'

import { WEEKDAYS } from 'pared/constants'
import { DELIVERY_CATEGORY_DISPLAY_TEXT } from 'pared/constants'
import COLORS from 'pared/constants/colors'
import useBrands from 'pared/layouts/hooks/useBrands'

interface IProps {
  dayTrendsInfo: any[]
  dayTrendsCategory: string
  isOrdersHidden?: boolean
}

interface IRankTablTh {
  textAlign?: string
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
}

function WebSmallMain({
  dayTrendsInfo,
  dayTrendsCategory,
  isOrdersHidden,
}: IProps) {
  const { brand } = useBrands()
  const weeks = useMemo(() => {
    switch (brand) {
      case 'fw_wingstop':
        return [...WEEKDAYS.slice(1), ...WEEKDAYS.slice(0, 1)]
      default:
        return WEEKDAYS
    }
  }, [brand])

  return (
    <>
      <TrendsTableContainer>
        <RankTable>
          <thead>
            <tr>
              <RankTableTh textAlign={'left'}>Day of Week</RankTableTh>
              <RankTableTh>
                {DELIVERY_CATEGORY_DISPLAY_TEXT[dayTrendsCategory]}
              </RankTableTh>
              {isOrdersHidden ? null : <RankTableTh># of Orders</RankTableTh>}
            </tr>
          </thead>
          <tbody>
            {weeks.map((day, i) => {
              return (
                <tr>
                  <RankNumberTd textAlign={'left'}>{day}</RankNumberTd>
                  <RankTableTd>
                    {_.get(dayTrendsInfo, [i + 1, 'value'], '')}
                  </RankTableTd>
                  {isOrdersHidden ? null : (
                    <RankTableTd>
                      {_.get(dayTrendsInfo, [i + 1, 'totalOrderCount'], '')}
                    </RankTableTd>
                  )}
                </tr>
              )
            })}
          </tbody>
        </RankTable>
      </TrendsTableContainer>
    </>
  )
}

const TrendsTableContainer = styled.div`
  margin-top: 30px;
  width: 90vw;
`

const RankTable = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`

const RankTableTh = styled.th<IRankTablTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  padding-left: 12px;
`

const RankTableSortableTh = styled.th<IRankTablTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
  color: ${COLORS.Plum};
  cursor: pointer;
`

const RankTableTd = styled.td<IRankTableTd>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankNumberTd = styled(RankTableTd)`
  padding-left: 12px;
`

export default WebSmallMain
