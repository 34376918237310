import { gql, useMutation } from '@apollo/client'
import moment from 'moment'
import { useCallback } from 'react'

import { useReset } from '../../../ResetProvider'

export interface ITrainerInitialTypes {
  id: number
  traineeInitials: boolean
  trainerId: number
  listUuid: string
}

const queries = gql`
  mutation UpdateTrainingTrackerList(
    $id: Int!
    $listUuid: String!
    $trainerInitials: Boolean!
    $trainerId: Int!
    $trainerInitialsDate: Datetime
    $completedAt: Datetime
    $updatedAt: Datetime!
  ) {
    updateTrainingTrackerList(
      input: {
        id: $id
        patch: {
          trainerInitials: $trainerInitials
          trainerId: $trainerId
          trainerInitialsDate: $trainerInitialsDate
          completedAt: $completedAt
          updatedAt: $updatedAt
        }
      }
    ) {
      clientMutationId
    }

    updateTrainingTrackerByListUuid(
      input: { listUuid: $listUuid, patch: { trainerId: $trainerId } }
    ) {
      clientMutationId
    }
  }
`

const useTrainerInitials = (
  setLoading: any,
  { id, traineeInitials, trainerId, listUuid }: ITrainerInitialTypes,
) => {
  const [mutation] = useMutation(queries)
  const reset = useReset()

  return useCallback(
    async ({ checked }) => {
      const now = moment().toISOString()
      const completedAt = checked && traineeInitials ? now : null
      const trainerInitialsDate = checked ? now : null

      setLoading(true)
      await mutation({
        variables: {
          id: id,
          listUuid,
          trainerInitials: checked,
          trainerId,
          trainerInitialsDate,
          completedAt,
          updatedAt: now,
        },
      })
      reset()
    },
    [mutation, reset],
  )
}

export default useTrainerInitials
