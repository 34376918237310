import _ from 'lodash'
import styled from 'styled-components'

import FullWidthTable from 'pared/components/FullWidthTable'
import COLORS from 'pared/constants/colors'
import {
  IOpenPositionsTd,
  IOverviewTableTh,
} from 'pared/pages/MyDashboard/OpsCompTable/Main'
import { toPercentageString, toUsdString } from 'pared/utils/number'

const ALCOHOLIC_BEVERAGES = 'Alcoholic Beverages'
const FOOD_AND_NA_BEVERAGES = 'Food and Non-alcoholic Beverages'

interface IProps {
  tableData: object
}

const Main = ({ tableData }: IProps) => {
  const foodNetSalesActual =
    _.get(tableData, ['items', 'Sales - Food', 'net_sales_actual'], 0) / 100.0
  const naBeverageNetSalesActual =
    _.get(tableData, ['items', 'Sales - N/A Beverage', 'net_sales_actual'], 0) /
    100.0
  const beerNetSalesActual =
    _.get(tableData, ['items', 'Sales - Beer', 'net_sales_actual'], 0) / 100.0
  const wineNetSalesActual =
    _.get(tableData, ['items', 'Sales - Wine', 'net_sales_actual'], 0) / 100.0
  const liquorNetSalesActual =
    _.get(tableData, ['items', 'Sales - Liquor', 'net_sales_actual'], 0) / 100.0
  const otherNetSalesActual =
    _.get(tableData, ['items', 'Sales - Other', 'net_sales_actual'], 0) / 100.0

  const foodAndNaBeveragesNetSalesActual =
    _.get(
      tableData,
      ['aggregated', 'Food and Non-alcoholic Beverages', 'net_sales_actual'],
      0,
    ) / 100.0
  const alcoholicBeveragesNetSalesActual =
    _.get(
      tableData,
      ['aggregated', 'Alcoholic Beverages', 'net_sales_actual'],
      0,
    ) / 100.0

  let grossSales =
    foodAndNaBeveragesNetSalesActual + alcoholicBeveragesNetSalesActual

  const foodBudgetVariance =
    _.get(tableData, ['items', 'Sales - Food', 'budget_variance'], 0) / 100.0
  const naBeverageBudgetVariance =
    _.get(tableData, ['items', 'Sales - N/A Beverage', 'budget_variance'], 0) /
    100.0
  const beerBudgetVariance =
    _.get(tableData, ['items', 'Sales - Beer', 'budget_variance'], 0) / 100.0
  const wineBudgetVariance =
    _.get(tableData, ['items', 'Sales - Wine', 'budget_variance'], 0) / 100.0
  const liquorBudgetVariance =
    _.get(tableData, ['items', 'Sales - Liquor', 'budget_variance'], 0) / 100.0
  const otherBudgetVariance =
    _.get(tableData, ['items', 'Sales - Other', 'budget_variance'], 0) / 100.0

  const foodAndNaBeveragesBudgetVariance =
    _.get(
      tableData,
      ['aggregated', 'Food and Non-alcoholic Beverages', 'budget_variance'],
      0,
    ) / 100.0
  const alcoholicBeveragesBudgetVariance =
    _.get(
      tableData,
      ['aggregated', 'Alcoholic Beverages', 'budget_variance'],
      0,
    ) / 100.0

  const comps = _.get(tableData, 'comps', 0) / 100.0
  const netSalesActual = _.get(tableData, 'net_sales_actual', 0) / 100.0
  const budgetVariance = _.get(tableData, 'budget_variance', 0) / 100.0

  const aggregatedItemKeys = _.keys(_.get(tableData, 'aggregated', []))

  const aggregatedItemRows = _.map(aggregatedItemKeys, (k) => {
    if (k !== ALCOHOLIC_BEVERAGES && k !== FOOD_AND_NA_BEVERAGES) {
      const netSalesActual =
        _.get(tableData, ['aggregated', k, 'net_sales_actual'], 0) / 100.0
      const budgetVariance = _.get(
        tableData,
        ['aggregated', k, 'budget_variance'],
        0,
      )

      grossSales += netSalesActual

      return (
        <tr>
          <OverviewTableTd bold>{k}</OverviewTableTd>
          <OverviewTableTd textAlign="right">
            {toUsdString(netSalesActual)}
          </OverviewTableTd>
          <OverviewTableTd textAlign="right" danger={budgetVariance < 0}>
            {toPercentageString(budgetVariance / 100.0, 1)}
          </OverviewTableTd>
        </tr>
      )
    }
  })

  return (
    <FullWidthTable>
      <thead>
        <tr>
          <OverviewTableTh></OverviewTableTh>
          <OverviewTableTh bold textAlign="right">
            Actual
          </OverviewTableTh>
          <BudgetVarianceTh bold textAlign="right">
            <GrossSalesBudgetVarianceContainer>
              Budget Variance
            </GrossSalesBudgetVarianceContainer>
          </BudgetVarianceTh>
        </tr>
      </thead>
      <tbody>
        <tr>
          <OverviewTableTd bold>Food</OverviewTableTd>
          <OverviewTableTd textAlign="right">
            {toUsdString(foodNetSalesActual)}
          </OverviewTableTd>
          <OverviewTableTd textAlign="right" danger={foodBudgetVariance < 0}>
            {toPercentageString(foodBudgetVariance, 1)}
          </OverviewTableTd>
        </tr>
        <tr>
          <OverviewTableTd bold>Non-Alcoholic Beverages</OverviewTableTd>
          <OverviewTableTd textAlign="right">
            {toUsdString(naBeverageNetSalesActual)}
          </OverviewTableTd>
          <OverviewTableTd
            textAlign="right"
            danger={naBeverageBudgetVariance < 0}
          >
            {toPercentageString(naBeverageBudgetVariance, 1)}
          </OverviewTableTd>
        </tr>
        <tr>
          <OverviewTableTd bold>Beer</OverviewTableTd>
          <OverviewTableTd textAlign="right">
            {toUsdString(beerNetSalesActual)}
          </OverviewTableTd>
          <OverviewTableTd textAlign="right" danger={beerBudgetVariance < 0}>
            {toPercentageString(beerBudgetVariance, 1)}
          </OverviewTableTd>
        </tr>
        <tr>
          <OverviewTableTd bold>Wine</OverviewTableTd>
          <OverviewTableTd textAlign="right">
            {toUsdString(wineNetSalesActual)}
          </OverviewTableTd>
          <OverviewTableTd textAlign="right" danger={wineBudgetVariance < 0}>
            {toPercentageString(wineBudgetVariance, 1)}
          </OverviewTableTd>
        </tr>
        <tr>
          <OverviewTableTd bold>Liquor</OverviewTableTd>
          <OverviewTableTd textAlign="right">
            {toUsdString(liquorNetSalesActual)}
          </OverviewTableTd>
          <OverviewTableTd textAlign="right" danger={liquorBudgetVariance < 0}>
            {toPercentageString(liquorBudgetVariance, 1)}
          </OverviewTableTd>
        </tr>
        <tr>
          <OverviewTableTd bold>Other</OverviewTableTd>
          <OverviewTableTd textAlign="right">
            {toUsdString(otherNetSalesActual)}
          </OverviewTableTd>
          <OverviewTableTd textAlign="right" danger={otherBudgetVariance < 0}>
            {toPercentageString(otherBudgetVariance, 1)}
          </OverviewTableTd>
        </tr>
        <SubTotalTr>
          <OverviewTableTd bold>
            <SubtitleContainer>
              Food and Non-Alcoholic Beverages
            </SubtitleContainer>
          </OverviewTableTd>
          <OverviewTableTd textAlign="right">
            <SubtitleContainer>
              {toUsdString(foodAndNaBeveragesNetSalesActual)}
            </SubtitleContainer>
          </OverviewTableTd>
          <OverviewTableTd
            textAlign="right"
            danger={foodAndNaBeveragesBudgetVariance < 0}
          >
            <SubtitleContainer>
              {toPercentageString(foodAndNaBeveragesBudgetVariance, 1)}
            </SubtitleContainer>
          </OverviewTableTd>
        </SubTotalTr>
        <tr>
          <OverviewTableTd bold>Alcoholic Beverages</OverviewTableTd>
          <OverviewTableTd textAlign="right">
            {toUsdString(alcoholicBeveragesNetSalesActual)}
          </OverviewTableTd>
          <OverviewTableTd
            textAlign="right"
            danger={alcoholicBeveragesBudgetVariance < 0}
          >
            {toPercentageString(alcoholicBeveragesBudgetVariance, 1)}
          </OverviewTableTd>
        </tr>
        {aggregatedItemRows}
        <SubTotalTr>
          <OverviewTableTd bold>
            <CompsContainer>Gross Sales</CompsContainer>
          </OverviewTableTd>
          <OverviewTableTd textAlign="right">
            <CompsContainer>{toUsdString(grossSales)}</CompsContainer>
          </OverviewTableTd>
          <OverviewTableTd textAlign="right" danger={budgetVariance < 0}>
            <CompsContainer></CompsContainer>
          </OverviewTableTd>
        </SubTotalTr>
        <tr>
          <OverviewTableTd bold>Comps</OverviewTableTd>
          <OverviewTableTd textAlign="right">
            {toUsdString(comps)}
          </OverviewTableTd>
          <OverviewTableTd></OverviewTableTd>
        </tr>
        <SubTotalTr>
          <OverviewTableTd bold>
            <SubtitleContainer>Net Sales</SubtitleContainer>
          </OverviewTableTd>
          <OverviewTableTd textAlign="right">
            <SubtitleContainer>{toUsdString(netSalesActual)}</SubtitleContainer>
          </OverviewTableTd>
          <OverviewTableTd textAlign="right" danger={budgetVariance < 0}>
            <SubtitleContainer>
              {toPercentageString(budgetVariance, 1)}
            </SubtitleContainer>
          </OverviewTableTd>
        </SubTotalTr>
      </tbody>
    </FullWidthTable>
  )
}

const SubTotalTr = styled.tr`
  border-top: 1px solid ${COLORS.Chalkboard};
`

const OverviewTableTh = styled.th<IOverviewTableTh>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: ${(props) => (props.largerFont ? '20px' : '14px')};
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'right' ? 'right' : 'left')};
`

const BudgetVarianceTh = styled(OverviewTableTh)`
  width: 160px;
`

const GrossSalesBudgetVarianceContainer = styled.div`
  width: 120px;
  float: right;
`

const OverviewTableTd = styled.td<IOpenPositionsTd>`
  font-family: Lexend-Regular;
  font-size: ${(props) => (props.bold ? '14px' : '18px')};
  font-style: normal;
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  text-align: ${(props) => (props.textAlign === 'right' ? 'right' : 'left')};
  color: ${(props) =>
    props.danger ? COLORS.Pomodoro : props.success ? COLORS.Expo : 'black'};
`

const SubtitleContainer = styled.div`
  padding-top: 10px;
`

const CompsContainer = styled(SubtitleContainer)`
  padding-top: 5px;
  padding-bottom: 5px;
`

export default Main
