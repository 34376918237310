import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect } from 'react'

import {
  GROUP_BY_PERIOD,
  GROUP_BY_QUARTER,
  GROUP_BY_WEEK,
  GROUP_BY_YEAR,
  IDateRange,
  TYPE_LAST_WEEK,
  TYPE_PERIOD,
  TYPE_QUARTER,
  TYPE_THIS_WEEK,
  TYPE_TRAILING_7_DAYS,
  TYPE_TRAILING_90_DAYS,
  TYPE_WEEK,
  TYPE_YEAR,
  TYPE_YESTERDAY,
} from 'pared/data/getDateRanges'
import useBrands from 'pared/layouts/hooks/useBrands'

import { LIST_GLOBAL_DELIVERY_KPI } from '../gql'
import useDeliveryConfig from '../hooks/useDeliveryConfig'
import Main from './Main'

interface IProps {
  endDate: string
  dateRangeType: IDateRange['type']
  locationGroupId: number
}

function Chart({ endDate, dateRangeType, locationGroupId }: IProps) {
  const { brand } = useBrands()
  const deliveryConfig = useDeliveryConfig(brand)
  const [
    listKpi,
    { loading: listKpiLoading, error: listKpiError, data: listKpiData },
  ] = useLazyQuery(
    LIST_GLOBAL_DELIVERY_KPI(deliveryConfig.customizedDeliveryChart),
  )

  useEffect(() => {
    let groupBy = ''
    if (dateRangeType === TYPE_YEAR) {
      groupBy = GROUP_BY_YEAR
    } else if (dateRangeType === TYPE_QUARTER) {
      groupBy = GROUP_BY_QUARTER
    } else if (dateRangeType === TYPE_PERIOD) {
      groupBy = GROUP_BY_PERIOD
    } else {
      groupBy = GROUP_BY_WEEK
    }

    listKpi({
      variables: {
        iEndDate: endDate,
        iDateRangeType: groupBy,
        iLocationGroupId: locationGroupId,
      },
    })
  }, [endDate, locationGroupId])

  let rawKpiMonthly = []

  if (
    listKpiData &&
    listKpiData.listGlobalDeliveryKpiV2 &&
    Array.isArray(listKpiData.listGlobalDeliveryKpiV2.nodes)
  ) {
    rawKpiMonthly = _.get(listKpiData, 'listGlobalDeliveryKpiV2.nodes', [])

    if (listKpiData.customizedDateGroupDelivery) {
      const customizedData = listKpiData.customizedDateGroupDelivery.nodes || []

      rawKpiMonthly = rawKpiMonthly.map((data) => {
        const customizedD = customizedData.find(
          (c) =>
            c.year === data.year &&
            c.quarter === data.quarter &&
            c.period === data.period &&
            c.week === data.week,
        )

        return {
          ...data,
          ...customizedD,
        }
      })
    }
  }

  const xAxisData = _.map(
    rawKpiMonthly,
    (k) =>
      ({
        [TYPE_YEAR]: `Y${k.year}`,
        [TYPE_QUARTER]: `Q${k.quarter} ${k.year}`,
        [TYPE_PERIOD]: `P${k.period} ${k.year}`,
        [TYPE_WEEK]: `P${k.period} W${k.week} ${k.year}`,
        [TYPE_THIS_WEEK]: `P${k.period} W${k.week} ${k.year}`,
        [TYPE_LAST_WEEK]: `P${k.period} W${k.week} ${k.year}`,
        [TYPE_YESTERDAY]: `P${k.period} W${k.week} ${k.year}`,
        [TYPE_TRAILING_7_DAYS]: `P${k.period} W${k.week} ${k.year}`,
        [TYPE_TRAILING_90_DAYS]: `P${k.period} W${k.week} ${k.year}`,
      }[(dateRangeType as string) || TYPE_PERIOD] ??
      `P${k.period} W${k.week} ${k.year}`),
  )

  const yAxisInaccurateData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'inaccurateOrderPercent', 0) / 100
  })

  const yAxisCancelledData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'cancelledOrderPercent', 0) / 100
  })

  const yAxisDelayedData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'delayedOrderPercent', 0) / 100
  })

  const yAxisAnyIssueData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'orderWithAnyIssuePercent', 0) / 100
  })

  const yAxisTotalOrderCountData = _.map(rawKpiMonthly, (k) => {
    return _.get(k, 'totalOrderCount', 0)
  })

  return (
    <Main
      xAxisData={xAxisData}
      yAxisInaccurateData={yAxisInaccurateData}
      yAxisCancelledData={yAxisCancelledData}
      yAxisDelayedData={yAxisDelayedData}
      yAxisAnyIssueData={yAxisAnyIssueData}
      yAxisTotalOrderCountData={yAxisTotalOrderCountData}
    />
  )
}

export default Chart
