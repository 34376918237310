import moment from 'moment'

import { GROUP_BY_QUARTER, GROUP_BY_YEAR } from 'pared/data/getDateRanges'

export const getDateRangeLabel = (startDate: string, groupBy: string) => {
  const midPeriodDate = moment.utc(startDate).add(15, 'days')
  const year = midPeriodDate.year()
  if (groupBy === GROUP_BY_YEAR) {
    return `Y${year}`
  } else if (groupBy === GROUP_BY_QUARTER) {
    const quarter = midPeriodDate.quarter()
    return `Q${quarter} ${year}`
  }

  const period = midPeriodDate.month() + 1
  return `P${period} ${year}`
}
