import Popover from '@material-ui/core/Popover'
import styled from 'styled-components'

import InformationPopover from 'pared/components/InformationPopover'

interface IProps {
  informationAnchorEl: any
  setInformationAnchorEl: any
  popoverDescription: string
  children: any
}

interface ISpanContainer {
  bold?: boolean
}

function Main({
  setInformationAnchorEl,
  informationAnchorEl,
  popoverDescription,
  children,
}: IProps) {
  const handlePopoverOpen = (event: any) => {
    setInformationAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setInformationAnchorEl(null)
  }

  const open = Boolean(informationAnchorEl)

  const popover = (
    <Popover
      id={'mouse-over-popover'}
      open={open}
      anchorEl={informationAnchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      disableRestoreFocus
      style={{
        pointerEvents: 'none',
      }}
    >
      <InformationPopover popoverDescription={popoverDescription} />
    </Popover>
  )

  return (
    <>
      <SpanContainer
        aria-owns={open ? 'mouse-over-popover' : undefined}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        bold
      >
        {children}
      </SpanContainer>
      {popover}
    </>
  )
}

const SpanContainer = styled.div<ISpanContainer>`
  font-family: Lexend-Regular;
  font-size: ${(props) => (props.bold ? '14px' : '18px')};
  font-style: normal;
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  text-align: left;
  color: black;
  display: inline-block;
  padding: 0 5px 0 0;
`

export default Main
