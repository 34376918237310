import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { IApiDataType } from '../types'

const query = gql`
  query bbbExpenses($iStartDate: Date!, $iEndDate: Date!) {
    listExpenses(iStartDate: $iStartDate, iEndDate: $iEndDate) {
      nodes {
        date
        location
        employee
        transactionAmount
        expenseType
        expenseName
        vendor
        businessPurpose
      }
    }
  }
`

export const bbbExpensesConfigs = {
  date: 'date-string',
  location: 'string',
  employee: 'string',
  transactionAmount: 'price',
  expenseType: 'string',
  expenseName: 'string',
  vendor: 'string',
  businessPurpose: 'string',
} as const

const useBbbExpenses = () => {
  const { startDate, endDate } = useDateFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip: !startDate || !endDate,
  })

  return {
    data: useMemo((): IApiDataType => {
      const expenses = data?.listExpenses.nodes

      if (!expenses) return null

      return {
        source: expenses,
      }
    }, [data]),
    loading,
  }
}

export default useBbbExpenses
