import _ from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

import Format from './Format'
import Pagination from './Pagination'
import useDataSource from './hooks/useDataSource'
import useMergedBody from './hooks/useMergedBody'
import usePagination, { MAX_AMOUNT } from './hooks/usePagination'
import { ISortedFieldOptionType } from './hooks/useSortedField'
import { IDataSourceType, IPropsType as ITablePropsType } from './index'
import { IColumnsType as ColumnsType } from './types'

type IColumnsType<Data> = ColumnsType<Data>

interface IPropsType<Data> extends ITablePropsType {
  config: any
  columnsInfo: any
  sortedField: ISortedFieldOptionType<Data>
  bodyDataSource: IDataSourceType
}

const TD_PROPS = ['align'] as const

const SummaryTr = styled.tr`
  border-top: 1px solid black;
  font-weight: 700;
`

const Tr = styled.tr<{
  highlineEvenRows?: boolean
  bordered?: boolean
}>`
  ${({ highlineEvenRows }) =>
    !highlineEvenRows
      ? ''
      : `
&:nth-child(even) {
  background-color: ${COLORS.Porcelain};
}
  `}

  ${({ bordered }) =>
    !bordered
      ? ''
      : `
    border-bottom: 1px solid ${COLORS.Smoke};
    `}
`

const Td = styled.td<Pick<IColumnsType<any>, typeof TD_PROPS[number]>>`
  padding: 8px 10px;
  text-align: ${({ align }) => align || 'left'};
  font-family: Lexend-Regular;
`

const ExpandButton = styled.button`
  width: 140px;
  height: 46px;
  color: #07a4c7;
  background: transparent;
  font-family: Lexend-Regular;
  text-align: left;
  text-decoration-line: underline;
  border: 0px;
  cursor: pointer;
  outline: none;
`

const Body = <Data,>({
  config,
  columnsInfo,
  sortedField,
  bodyDataSource,
  pageSize,
  rowKey = 'groupInfo.id',
  highlineEvenRows,
  bordered,
}: IPropsType<Data>) => {
  const mergedBody = useMergedBody<Record<string, unknown>>(columnsInfo)
  const originalDataSource = useMemo(
    () => bodyDataSource?.data?.source || [],
    [bodyDataSource],
  )
  const summary = useMemo(
    () => bodyDataSource?.data?.summary || {},
    [bodyDataSource],
  )

  const total = originalDataSource.length

  const {
    current,
    pageSize: newPageSize,
    onChange,
  } = usePagination(!pageSize ? 0 : pageSize, total)

  const dataSource = useDataSource<Record<string, unknown>>(
    config,
    originalDataSource,
    current,
    newPageSize,
    sortedField,
  )

  return (
    <>
      {dataSource?.map((data) => (
        <Tr
          key={_.get(data, rowKey) as unknown as React.Key}
          highlineEvenRows={highlineEvenRows}
          bordered={bordered}
        >
          {mergedBody.map(({ key, ...props }) => {
            const cellValue = _.get(data, key)
            return (
              <Td {..._.pick(props, TD_PROPS)} key={key}>
                <Format
                  {...props}
                  type={config[key]}
                  value={cellValue}
                  values={data}
                />
              </Td>
            )
          })}
        </Tr>
      ))}
      {total === 0 || total <= MAX_AMOUNT || pageSize || summary ? null : (
        <ExpandButton onClick={() => onChange(current)} data-cy="expand">
          {total === pageSize ? 'Collapse all' : 'Expand all'}
        </ExpandButton>
      )}
      {total === 0 ||
      !pageSize ||
      pageSize >= originalDataSource.length ? null : (
        <Pagination
          current={current}
          pageSize={pageSize}
          total={total}
          onChange={onChange}
        />
      )}
      {Object.keys(summary).length === 0 ? null : (
        <SummaryTr>
          {mergedBody.map(({ key, ...props }) => {
            const cellValue = _.get(summary, key)
            return (
              <Td {..._.pick(props, TD_PROPS)} key={key}>
                <Format
                  {...props}
                  type={config[key]}
                  value={cellValue}
                  values={summary}
                />
              </Td>
            )
          })}
        </SummaryTr>
      )}
      <Tr>
        {mergedBody.map(() => {
          return <Td> </Td>
        })}
      </Tr>
    </>
  )
}

export default Body
