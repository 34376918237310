import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { track } from 'pared/analytics/corporateDelivery'
import { feature, page } from 'pared/analytics/user'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import {
  RAW_LIST_LOCATION_GROUP_PRODUCTIVITY_COEFFICIENT_DATA,
  RAW_LIST_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA,
} from 'pared/constants/raw_data/productivityCoefficient'
import getDateRanges, { IDateRange } from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'
import getDirectors from 'pared/data/getDirectors'
import { getBrand } from 'pared/utils/brand'
import { toUsdString } from 'pared/utils/number'
import { scrollToTop } from 'pared/utils/web'

import Main from './Main'
import {
  LIST_LOCATION_GROUP_SALESMANSHIP_KPIS,
  LIST_LOCATION_SALESMANSHIP_KPIS,
} from './gql'

export interface IDetailSalesmanshipKpi {
  appetizerPpa: string
  dessertPpa: string
  grossSales: string
  grossSalesPerLaborHour: string
  lbwPpa: string
  ppa: string
  sosAvgTime: string
  locationId?: number
  doEmployeeId?: number
  formattedName: string
}

export interface IGlobalSalesmanshipKpi {
  appetizerPpa: string
  checkAverage: string
  dessertPpa: string
  grossSales: string
  grossSalesPerLaborHour: string
  lbwPpa: string
  sosAvgTime: string
  ppa: string
}

interface IProps {
  navParams: INavParams
}

function CorporateSalemanship({ navParams }: IProps) {
  const dateRange = navParams.dateRange || ''

  const [allDirectors, setAllDirectors] = useState<IDirector[] | null>([])
  const [selectedDirector, setSelectedDirector] = useState<IDirector | null>({
    employeeId: -1,
    firstName: 'All',
    lastName: 'Stores',
  })
  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange | null>(
    null,
  )

  const brand = getBrand()

  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)

  const [
    getSalesmanshipSummaryKpis,
    {
      loading: getSalesmanshipSummaryKpisLoading,
      error: getSalesmanshipSummaryKpisError,
      data: getSalesmanshipSummaryKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_GROUP_SALESMANSHIP_KPIS)

  const [
    listLocationSalesmanshipKpis,
    {
      loading: listLocationSalesmanshipKpisLoading,
      error: listLocationSalesmanshipKpisError,
      data: listLocationSalesmanshipKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_SALESMANSHIP_KPIS)

  const [
    listLocationGroupSalesmanshipKpis,
    {
      loading: listLocationGroupSalesmanshipKpisLoading,
      error: listLocationGroupSalesmanshipKpisError,
      data: listLocationGroupSalesmanshipKpisData,
    },
  ] = useLazyQuery(LIST_LOCATION_GROUP_SALESMANSHIP_KPIS)

  useEffect(() => {
    async function fetchData() {
      const [newAllDateRangeData, directors] = await Promise.all([
        getDateRanges(),
        getDirectors(),
      ])

      if (Array.isArray(directors) && directors.length > 0) {
        setAllDirectors(directors)
      }

      let newSelectedDateRange: IDateRange | null = null
      if (dateRange) {
        newSelectedDateRange = newAllDateRangeData.dateRangeMap[dateRange]
      }

      if (!newSelectedDateRange) {
        newSelectedDateRange = newAllDateRangeData.defaultPeriod
      }

      if (newSelectedDateRange) {
        setSelectedDateRange(newSelectedDateRange)

        const periodStartDate = _.get(newSelectedDateRange, 'startDateStr', '')
        const periodEndDate = _.get(newSelectedDateRange, 'endDateStr', '')

        const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
        let selectedDirectorLocationGroupId =
          _.get(selectedDirector, 'locationGroupId') || -1
        selectedDirectorLocationGroupId =
          selectedDirectorLocationGroupId < 0
            ? brandLocationGroupId
            : selectedDirectorLocationGroupId

        getSalesmanshipSummaryKpis({
          variables: {
            iStartDate: periodStartDate,
            iEndDate: periodEndDate,
            iFilter: {
              location_group_ids: [selectedDirectorLocationGroupId],
            },
          },
        })

        if (selectedDirctorEmployeeId === -2) {
          // breakdownByDirectors
          listLocationGroupSalesmanshipKpis({
            variables: {
              iStartDate: periodStartDate,
              iEndDate: periodEndDate,
              iFilter: {
                location_group_ids: _.map(allDirectors, (doData) => {
                  return doData.locationGroupId
                }),
              },
            },
          })
        } else {
          listLocationSalesmanshipKpis({
            variables: {
              iStartDate: periodStartDate,
              iEndDate: periodEndDate,
              iFilter: {
                location_group_ids: [selectedDirectorLocationGroupId],
              },
            },
          })
        }
      }
    }

    fetchData()
  }, [selectedDirector, dateRange, brand])

  useEffect(() => {
    if (navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl)
      feature.used('Corporate Salesmanship')
    }
  }, [navParams.pageUrl])

  const onDirectorChange = async (directorEmployeeId: number) => {
    let processedDirectorEmployeeId = 0

    track.directorChanged(directorEmployeeId)

    if (directorEmployeeId === -1) {
      // The all stores case

      setSelectedDirector({
        employeeId: -1,
        firstName: 'All',
        lastName: 'Stores',
      })
    } else if (directorEmployeeId === -2) {
      // Case: breakdown by directors

      setSelectedDirector({
        employeeId: -2,
        firstName: 'All',
        lastName: 'Stores',
      })
    } else {
      processedDirectorEmployeeId = directorEmployeeId
      const selectedDirector = _.first(
        _.filter(allDirectors, { employeeId: processedDirectorEmployeeId }),
      )
      setSelectedDirector(selectedDirector || null)
    }
  }

  let globalSalesmanshipKpiSummary: IGlobalSalesmanshipKpi = {
    appetizerPpa: '-',
    checkAverage: '-',
    dessertPpa: '-',
    grossSales: '-',
    grossSalesPerLaborHour: '-',
    lbwPpa: '-',
    ppa: '-',
    sosAvgTime: '-',
  }

  if (
    getSalesmanshipSummaryKpisData &&
    getSalesmanshipSummaryKpisData.listLocationGroupSalesmanshipKpis &&
    Array.isArray(
      getSalesmanshipSummaryKpisData.listLocationGroupSalesmanshipKpis.nodes,
    )
  ) {
    const rawGlobalSalesmanshipKpi = _.first(
      _.get(
        getSalesmanshipSummaryKpisData,
        'listLocationGroupSalesmanshipKpis.nodes',
        [],
      ),
    )

    globalSalesmanshipKpiSummary = {
      appetizerPpa: toUsdString(
        _.get(rawGlobalSalesmanshipKpi, 'appetizerPpa', 0) / 100,
        2,
      ),
      checkAverage: toUsdString(
        _.get(rawGlobalSalesmanshipKpi, 'checkAverage', 0) / 100,
        2,
      ),
      dessertPpa: toUsdString(
        _.get(rawGlobalSalesmanshipKpi, 'dessertPpa', 0) / 100,
        2,
      ),
      grossSales: toUsdString(
        _.get(rawGlobalSalesmanshipKpi, 'grossSales', 0) / 100,
        0,
      ),
      grossSalesPerLaborHour: toUsdString(
        _.get(rawGlobalSalesmanshipKpi, 'grossSalesPerLaborHour', 0) / 100,
        2,
      ),
      lbwPpa: toUsdString(
        _.get(rawGlobalSalesmanshipKpi, 'lbwPpa', 0) / 100,
        2,
      ),
      ppa: toUsdString(_.get(rawGlobalSalesmanshipKpi, 'ppa', 0) / 100, 2),
      sosAvgTime: (_.get(rawGlobalSalesmanshipKpi, 'sosAvgTime') || 0).toFixed(
        1,
      ),
    }
  }

  let itemizedKpis: IDetailSalesmanshipKpi[] = []

  if (
    selectedDirctorEmployeeId === -1 || // systemwide
    selectedDirctorEmployeeId > 0 // individual DOs
  ) {
    if (
      listLocationSalesmanshipKpisData &&
      listLocationSalesmanshipKpisData.listLocationSalesmanshipKpisV2 &&
      Array.isArray(
        listLocationSalesmanshipKpisData.listLocationSalesmanshipKpisV2.nodes,
      )
    ) {
      const rawLocationSalesmanshipKpis = _.get(
        listLocationSalesmanshipKpisData,
        'listLocationSalesmanshipKpisV2.nodes',
        [],
      )

      itemizedKpis = _.map(rawLocationSalesmanshipKpis, (k) => {
        const locationCode = _.get(k, 'locationInfo.code', '')
        const locationName = _.get(k, 'locationInfo.name', '')
        const locationId = _.get(k, 'locationInfo.id', '')
        const value =
          RAW_LIST_LOCATION_PRODUCTIVITY_COEFFICIENT_DATA[locationId]?.value

        return {
          ppa: toUsdString(_.get(k, 'ppa', '-') / 100.0, 2),
          appetizerPpa: toUsdString(_.get(k, 'appetizerPpa', '-') / 100.0, 2),
          lbwPpa: toUsdString(_.get(k, 'lbwPpa', '-') / 100.0, 2),
          dessertPpa: toUsdString(_.get(k, 'dessertPpa', '-') / 100.0, 2),
          grossSalesPerLaborHour: toUsdString(
            _.get(k, 'grossSalesPerLaborHour', '-') / 100.0,
            2,
          ),
          grossSales: toUsdString(_.get(k, 'grossSales', '-') / 100.0, 2),
          sosAvgTime: (_.get(k, 'sosAvgTime') || 0).toFixed(1),
          formattedName: `${locationCode} - ${locationName}`,
          productivityCoefficient: value?.toFixed(2),
          locationId,
        }
      })
    }
  } else if (selectedDirctorEmployeeId === -2) {
    // breakdownByDirectors
    if (
      listLocationGroupSalesmanshipKpisData &&
      listLocationGroupSalesmanshipKpisData.listLocationGroupSalesmanshipKpis &&
      Array.isArray(
        listLocationGroupSalesmanshipKpisData.listLocationGroupSalesmanshipKpis
          .nodes,
      )
    ) {
      const rawDoSalesmanshipKpis = _.get(
        listLocationGroupSalesmanshipKpisData,
        'listLocationGroupSalesmanshipKpis.nodes',
        [],
      )

      itemizedKpis = _.map(rawDoSalesmanshipKpis, (k) => {
        const doProfile = _.find(allDirectors, (d) => {
          return d.locationGroupId === k.locationGroupId
        })
        const value =
          RAW_LIST_LOCATION_GROUP_PRODUCTIVITY_COEFFICIENT_DATA[
            k.locationGroupId
          ]?.value

        return {
          ppa: toUsdString(_.get(k, 'ppa', '-') / 100, 2),
          appetizerPpa: toUsdString(_.get(k, 'appetizerPpa', '-') / 100, 2),
          lbwPpa: toUsdString(_.get(k, 'lbwPpa', '-') / 100, 2),
          dessertPpa: toUsdString(_.get(k, 'dessertPpa', '-') / 100, 2),
          grossSalesPerLaborHour: toUsdString(
            _.get(k, 'grossSalesPerLaborHour', '-') / 100,
            2,
          ),
          grossSales: toUsdString(_.get(k, 'grossSales', '-') / 100, 2),
          sosAvgTime: (_.get(k, 'sosAvgTime') || 0).toFixed(1),
          formattedName: doProfile
            ? `${doProfile.firstName} ${doProfile.lastName}`
            : '-',
          productivityCoefficient: value?.toFixed(2),
          doEmployeeId: doProfile ? doProfile.employeeId : undefined,
        }
      })
    }
  }

  if (listLocationGroupSalesmanshipKpisLoading) {
    globalSalesmanshipKpiSummary = {
      appetizerPpa: 'loading',
      checkAverage: 'loading',
      dessertPpa: 'loading',
      grossSales: 'loading',
      grossSalesPerLaborHour: 'loading',
      lbwPpa: 'loading',
      ppa: 'loading',
      sosAvgTime: 'loading',
    }
  }

  return (
    <>
      <Main
        navParams={navParams}
        allDirectors={allDirectors || []}
        selectedDirector={selectedDirector}
        onDirectorChange={onDirectorChange}
        selectedDateRange={selectedDateRange}
        globalSalesmanshipKpiSummary={globalSalesmanshipKpiSummary}
        itemizedKpis={itemizedKpis}
        selectedDirctorEmployeeId={selectedDirctorEmployeeId}
      />
    </>
  )
}

export default CorporateSalemanship
