import _ from 'lodash'
import { useEffect, useState } from 'react'

import { IDirector } from 'pared/data/getDirectors'

import { ILocationCoordinate, ILocationPerformanceByDoMap } from '../index'
import Main from './Main'

export interface IMapView {
  latitude: number
  longitude: number
  zoom: number
}

interface IProps {
  selectedDirector: IDirector | null
  allDirectors: IDirector[]
  locationsWithCoordinates: ILocationCoordinate[]
}

const Map = ({
  selectedDirector,
  allDirectors,
  locationsWithCoordinates,
}: IProps) => {
  const [mapView, setMapView] = useState<IMapView>({
    // US geographical center
    latitude: 37.0902,
    longitude: -95.7129,
    zoom: 4,
  })
  const selectedDirectorId = _.get(selectedDirector, 'employeeId', 22)

  // all stores OR breakdown by directors
  const shouldShowAllLocations =
    selectedDirectorId === -1 || selectedDirectorId === -2

  let filteredLocations = locationsWithCoordinates
  if (!shouldShowAllLocations) {
    filteredLocations = _.filter(locationsWithCoordinates, (l) => {
      return _.get(l, 'doEmployeeId', 0) === selectedDirectorId
    })
  }

  const locationByDo = _.groupBy(filteredLocations, 'doEmployeeId')

  useEffect(() => {
    const locationPerformanceByDo = filteredLocations

    const latitudes = _.map(locationPerformanceByDo, (l) => {
      return _.get(l, 'coordinates.latitude', 0)
    })
    const minLatitude = _.min(latitudes)
    const maxLatitude = _.max(latitudes)
    const averageLatitude = (minLatitude + maxLatitude) / 2

    const longitudes = _.map(locationPerformanceByDo, (l) => {
      return _.get(l, 'coordinates.longitude', 0)
    })
    const minLongitude = _.min(longitudes)
    const maxLongitude = _.max(longitudes)
    const averageLongitude = (minLongitude + maxLongitude) / 2

    setMapView({
      latitude:
        !_.isNaN(averageLatitude) && !shouldShowAllLocations
          ? averageLatitude
          : 37.0902,
      longitude:
        !_.isNaN(averageLongitude) && !shouldShowAllLocations
          ? averageLongitude
          : -95.7129,
      zoom: shouldShowAllLocations ? 4 : 5,
    })
  }, [selectedDirector, allDirectors])

  return (
    <Main
      mapView={mapView}
      allDirectors={allDirectors}
      locationByDo={locationByDo}
    />
  )
}

export default Map
