import { gql, useMutation } from '@apollo/client'
import _ from 'lodash'
import moment from 'moment'
import { useState } from 'react'

import { track } from 'pared/analytics/team'
import { ILocation } from 'pared/data/getLocations'
import { getUser } from 'pared/utils/user'

import Main from './Main'
import { UPDATE_LOCATION_MANAGER_POSITION } from './gql'

interface IProps {
  role: string
  revieweeId: number
  locationId: number
  currentComment?: string
  isUpdatingFeedback: boolean
  managerReviewId?: number
  closePopover: () => void
  onRefresh: () => Promise<void>
  allLocations: ILocation[] | null
  roleName: string
  timeVacant: string
  positionId: number
  candidateName?: string
  currentTrainingStoreId?: number
  currentFormattedPendingStartDate?: string
}

const DATE_FORMAT = 'MM/DD/yyyy'

const PositionPopover = ({
  role,
  allLocations,
  currentComment,
  roleName,
  timeVacant,
  onRefresh,
  closePopover,
  positionId,
  candidateName,
  currentTrainingStoreId,
  currentFormattedPendingStartDate,
}: IProps) => {
  const [locationId, setLocationId] = useState<number>(
    currentTrainingStoreId || 0,
  )
  const [comment, setComment] = useState<string>(currentComment || '')
  const [name, setName] = useState<string>(candidateName || '')
  const [shouldDisplayCommentEmptyError, setShouldDisplayCommentEmptyError] =
    useState<boolean>(false)
  const [pendingStartDate, setPendingStartDate] = useState<any>(
    moment(currentFormattedPendingStartDate),
  )
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [updateLocationManagerPosition, updateLocationManagerPositionStatus] =
    useMutation(UPDATE_LOCATION_MANAGER_POSITION)

  const user = getUser()
  const userEmployeeId = _.get(user, 'employeeId', 0)

  const onSubmitFeedback = _.once(async () => {
    if (isSubmitting) {
      return
    }

    if (
      locationId === 0 ||
      locationId === null ||
      comment === '' ||
      name === '' ||
      pendingStartDate === ''
    ) {
      setShouldDisplayCommentEmptyError(true)
    } else {
      setIsSubmitting(true)
      try {
        const formattedPendingStartDate =
          moment(pendingStartDate).format(DATE_FORMAT)

        await updateLocationManagerPosition({
          variables: {
            positionId,
            candidateName: name,
            comment,
            commentedAt: moment().format(DATE_FORMAT),
            commentedBy: userEmployeeId,
            filledPendingStartDate: formattedPendingStartDate,
            trainingLocationId: locationId,
          },
        })

        await onRefresh()
        closePopover()
      } catch (error) {
        // mute the error
        setIsSubmitting(false)
      }
    }
  })

  const onStoreValueChange = (event: any) => {
    const selectedValue = parseInt(event.target.value) || 0
    setLocationId(selectedValue)
    if (!_.isEmpty(selectedValue)) {
      setShouldDisplayCommentEmptyError(false)
    }
  }

  return (
    <Main
      locationId={locationId}
      setLocationId={setLocationId}
      name={name}
      setName={setName}
      shouldDisplayCommentEmptyError={shouldDisplayCommentEmptyError}
      setShouldDisplayCommentEmptyError={setShouldDisplayCommentEmptyError}
      comment={comment}
      setComment={setComment}
      onSubmitFeedback={onSubmitFeedback}
      isSubmitting={isSubmitting}
      allLocations={allLocations}
      roleName={roleName}
      timeVacant={timeVacant}
      pendingStartDate={pendingStartDate}
      setPendingStartDate={setPendingStartDate}
      onStoreValueChange={onStoreValueChange}
      currentTrainingStoreId={currentTrainingStoreId}
    />
  )
}

export default PositionPopover
