import leaflet from 'leaflet'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Marker, Popup } from 'react-leaflet'

interface IPropsType {
  name: string
  logo: string
  latitude: number
  longitude: number
}

const getPoint = ({ width, height }: HTMLImageElement) =>
  new leaflet.Point(75, (75 * height) / width)

const Point = ({ name, logo, latitude, longitude }: IPropsType) => {
  const imageRef = useRef(new Image())
  const [iconSize, setIconSize] = useState<leaflet.Point | null>(null)
  const icon = useMemo(() => {
    if (!iconSize) return undefined

    return new leaflet.Icon({
      iconUrl: logo,
      iconSize: iconSize,
      className: 'leaflet-div-icon',
    })
  }, [logo, iconSize])

  useEffect(() => {
    imageRef.current.onload = () => {
      if (!imageRef.current.src.includes(logo)) return

      setIconSize(getPoint(imageRef.current))
    }

    imageRef.current.src = logo
    setIconSize(getPoint(imageRef.current))
  }, [logo])

  if (!icon) return null

  return (
    <Marker position={[latitude, longitude]} icon={icon}>
      <Popup>{name}</Popup>
    </Marker>
  )
}

export default Point
