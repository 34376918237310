import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { IOrgChartNode } from 'pared/components/OrgChart'

import { useGroupFilter } from '../../groupFilter'

interface ILocationOrgInfoNodeType {
  id: number
  familyName: string
  preferredName: string
  jobTitle: string
  supervisor: {
    id: number
    familyName: string
    preferredName: string
    jobTitle: string
  }
}

export interface ILocationOrgInfoType {
  listLocationOrgWithSupervisor: {
    nodes: ILocationOrgInfoNodeType[]
  }
}

const query = gql`
  query ListLocationOrgWithSupervisor($iFilter: JSON!) {
    listLocationOrgWithSupervisor(iFilter: $iFilter) {
      nodes {
        id
        familyName
        preferredName
        jobTitle
        supervisor {
          id
          familyName
          preferredName
          jobTitle
        }
      }
    }
  }
`

const useLocationOrgInfo = () => {
  const { groupFilter } = useGroupFilter()

  const { data, loading } = useQuery<ILocationOrgInfoType>(query, {
    variables: {
      iFilter: {
        location_ids: groupFilter?.ids,
      },
    },
    skip: !groupFilter,
  })

  return {
    data: useMemo(() => {
      const supervisor =
        data?.listLocationOrgWithSupervisor?.nodes[0].supervisor
      const childs = data?.listLocationOrgWithSupervisor?.nodes.map(
        ({ familyName, preferredName, jobTitle }) =>
          ({
            name: `${preferredName} ${familyName}`,
            position: jobTitle,
            cssClass: '',
            childs: undefined,
          } as IOrgChartNode),
      )

      if (!childs || !supervisor) return []

      return [
        {
          name: `${supervisor.preferredName} ${supervisor.familyName}`,
          position: supervisor.jobTitle,
          cssClass: '',
          childs,
        } as IOrgChartNode,
      ]
    }, [data]),
    loading,
  }
}

export default useLocationOrgInfo
