import * as d3 from 'd3-hierarchy'
import _ from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'

import { IValueType } from '../MenuItem'

export interface IValidateItemsOptionsType {
  value: IValueType['id'][][]
  onChange: (
    value: IValidateItemsOptionsType['value'],
    selectedValue: IValueType[][],
  ) => void
  options: d3.HierarchyNode<IValueType>
  multiple?: true
}

const findNode = ({
  value,
  options,
}: {
  value: IValueType['id'][]
  options: IValidateItemsOptionsType['options']
}): IValueType[] => {
  if (!options.children || value.length === 0) return []

  const subOption = options.children.find(
    ({ data: { id: childId } }) => value[0] === childId,
  )

  if (!subOption) return []

  return [
    subOption.data,
    ...findNode({
      value: value.slice(1),
      options: subOption,
    }),
  ]
}

const getFirstNodes = ({
  data,
  children,
}: d3.HierarchyNode<IValueType>): IValueType[] => [
  data,
  ...(!children ? [] : getFirstNodes(children[0])),
]

const isEqual = (a: string[], b: string[]): boolean =>
  a.length === 0 ? false : _.isEqual(a, b) || isEqual(a.slice(0, -1), b)

const getParentNodes = (node: IValueType[]) =>
  [...node]
    .reverse()
    .reduce(
      (result, node) => [...result.map((d) => [node, ...d]), [node]],
      [] as IValueType[][],
    )

const useValidateItems = ({
  value,
  onChange,
  options,
  multiple,
}: IValidateItemsOptionsType) => {
  const node = useMemo(
    () => value.map((v) => findNode({ value: v, options })),
    [value, options],
  )

  useEffect(() => {
    if (options.children && node.length === 0) {
      const nodes = getParentNodes(getFirstNodes(options.children[0]))

      onChange(
        nodes.map((node) => node.map((n) => n.id)),
        nodes,
      )
    }
  }, [node, options])

  return {
    value: node,
    onChange: useCallback(
      (newValue: IValueType['id'][]) => {
        if (multiple) {
          const valueIndexs = value.reduce(
            (result, v, index) =>
              !isEqual(v, newValue) ? result : [...result, index],
            [] as number[],
          )

          if (valueIndexs.length === 0) {
            const nodes = getParentNodes(
              findNode({ value: newValue, options }),
            ).filter((n) => {
              const ids = n.map(({ id }) => id)

              return !value.some((v) => _.isEqual(v, ids))
            })

            onChange(
              [...value, ...nodes.map((n) => n.map(({ id }) => id))],
              [...node, ...nodes],
            )
            return
          }

          const newValues = [...value] as (IValueType['id'][] | null)[]
          const newNode = [...node] as (IValueType[] | null)[]

          valueIndexs.forEach((index) => {
            newValues[index] = null
            newNode[index] = null
          })

          onChange(
            newValues.filter(Boolean) as IValueType['id'][][],
            newNode.filter(Boolean) as IValueType[][],
          )
          return
        }

        onChange([newValue], [findNode({ value: newValue, options })])
      },
      [value, onChange, options, multiple, node],
    ),
  }
}

export default useValidateItems
