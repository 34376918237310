export const irmgBkKpiDefs = {
  baconAttachmentRate: {
    key: 'baconAttachmentRate',
    name: 'Bacon Attachment Rate',
    kpiType: 'sales',
    salesType: 'Bacon',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  cheeseAttachmentRate: {
    key: 'cheeseAttachmentRate',
    name: 'Cheese Attachment Rate',
    kpiType: 'sales',
    salesType: 'Cheese',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  treatAttachmentRate: {
    key: 'treatAttachmentRate',
    name: 'Treat Attachment Rate',
    kpiType: 'sales',
    salesType: 'Treat',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  beverageAttachmentRate: {
    key: 'beverageAttachmentRate',
    name: 'Beverage Attachment Rate',
    kpiType: 'sales',
    salesType: 'Beverage',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
  shakeAttachmentRate: {
    key: 'shakeAttachmentRate',
    name: 'Shake Attachment Rate',
    kpiType: 'sales',
    salesType: 'Shake',
    kpiKey: 'checkPercent',
    valueType: 'percent',
    orderBy: 'desc',
    digitNum: 2,
  },
}
