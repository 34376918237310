import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import _ from 'lodash'
import { useState } from 'react'
import { useRef } from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import LocationInfo from 'pared/components/LocationInfo'
import LocationSelector from 'pared/components/nav/LocationSelector'
import { RANKINGS_ROW_TYPE, STORE_AVG_TEXT } from 'pared/constants'
import { EDDIE_MERLOTS_BRAND_CODE } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import { IPeriod } from 'pared/data/getPeriods'
import { toUsdString } from 'pared/utils/number'

import CompsTable from './CompsTable'
import PlusMinusTable from './PlusMinusTable'
import ProductivityCoefficientChart from './ProductivityCoefficientChart'
import StaffSalesTable from './StaffSalesTable'
import TimeframeDropdown from './TimeframeDropdown'
import useSalesmanshipConfig from './hooks/useSalesmanshipConfig'
import { ILocationData, ISalesmanship } from './index'

interface IProps {
  isLoading: boolean
  navParams: INavParams
  locationData: ILocationData | null
  errorMessage: string
  salesmanships: ISalesmanship[]
  dayPart: string
  setDayPart: any
  filterType: string
  setFilterType: any
  numDaysWorked: number
  setNumDaysWorked: any
  selectedPeriod: IPeriod | null
  setSelectedPeriod: any
  dateRangeStartDate: any
  setDateRangeStartDate: any
  dateRangeEndDate: any
  setDateRangeEndDate: any
  isTotalSalesExpanded: boolean
  setIsTotalSalesExpanded: any
  isSalesPerHourExpanded: boolean
  setIsSalesPerHourExpanded: any
  isPpaExpanded: boolean
  setIsPpaExpanded: any
  selectedValue: string
  setSelectedValue: (selectedValue: string) => void
}

interface IRankTableTh {
  textAlign?: string
}

interface IRankTableTd {
  bold?: boolean
  textAlign?: string
  isAvg?: boolean
}

const WebSmallMain = ({
  isLoading,
  navParams,
  locationData,
  errorMessage,
  salesmanships,
  dayPart,
  setDayPart,
  filterType,
  setFilterType,
  numDaysWorked,
  setNumDaysWorked,
  selectedPeriod,
  setSelectedPeriod,
  dateRangeStartDate,
  setDateRangeStartDate,
  dateRangeEndDate,
  setDateRangeEndDate,
  isTotalSalesExpanded,
  setIsTotalSalesExpanded,
  isSalesPerHourExpanded,
  setIsSalesPerHourExpanded,
  isPpaExpanded,
  setIsPpaExpanded,
  selectedValue,
  setSelectedValue,
}: IProps) => {
  const brandSettings = getBrandSettings()
  const isQsr = brandSettings.isQsr
  const isLegacy = brandSettings.isLegacy
  const { ppa: ppaLabel, ppaFull: ppaFullLabel } =
    brandSettings.labels.salesmanship
  const salesmanshipConfig = useSalesmanshipConfig()

  const [isSpeedOfServiceExpanded, setIsSpeedOfServiceExpanded] =
    useState<boolean>(false)

  const handleLeaderboardsDaypartChange = async (event: any) => {
    const value = _.get(event, 'target.value', '')
    setDayPart(value)
  }

  const totalSalesRef = useRef(null)
  const salesPerHourRef = useRef(null)
  const ppaRef = useRef(null)
  const speedOfServiceRef = useRef(null)

  const onToggleExpandTotalSales = () => {
    setIsTotalSalesExpanded(!isTotalSalesExpanded)
    if (totalSalesRef && totalSalesRef.current) {
      totalSalesRef.current.scrollIntoView()
    }
  }

  const onToggleExpandSalesPerHour = () => {
    setIsSalesPerHourExpanded(!isSalesPerHourExpanded)
    if (salesPerHourRef && salesPerHourRef.current) {
      salesPerHourRef.current.scrollIntoView()
    }
  }

  const onToggleExpandPpa = () => {
    setIsPpaExpanded(!isPpaExpanded)
    if (ppaRef && ppaRef.current) {
      ppaRef.current.scrollIntoView()
    }
  }

  const onToggleExpandSpeedOfService = () => {
    setIsSpeedOfServiceExpanded(!isSpeedOfServiceExpanded)
    if (speedOfServiceRef && speedOfServiceRef.current) {
      speedOfServiceRef.current.scrollIntoView()
    }
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </LoadingContainer>
    )
  }

  if (errorMessage) {
    return (
      <MainContainer>
        <PageStatusDiv>{errorMessage}</PageStatusDiv>
      </MainContainer>
    )
  }

  if (!locationData || !locationData.location.id) {
    return (
      <LoadingContainer>
        <PageStatusDiv>Loading ...</PageStatusDiv>
      </LoadingContainer>
    )
  }

  const rankedTotalSales = _.orderBy(
    salesmanships,
    ({ grossSales }) => grossSales || 0,
    'desc',
  )
  const rankedSalesPerHour = _.orderBy(
    salesmanships,
    ({ salesPerHour }) => salesPerHour || 0,
    'desc',
  )
  const rankedPpa = _.orderBy(
    salesmanships,
    ({ salesPpa }) => salesPpa || 0,
    'desc',
  )
  const rankedSosAvgTime = _.orderBy(salesmanships, 'sosAvgTime', 'asc')

  const getRankedRows = (rankedData: any, numberOfDecimals: number = 0) => {
    return _.map(rankedData, (d) => {
      const name = _.get(d, 'name', '')
      const rank = _.get(d, 'rank', 1)
      const dataPoint = _.get(d, 'dataPoint', 0)
      const rowType = _.get(d, 'rowType', '')

      return rowType === RANKINGS_ROW_TYPE.AVG ? (
        <AvgTr>
          <RankNumberTd bold textAlign={'left'}></RankNumberTd>
          <RankTableTd textAlign={'left'} isAvg>
            {STORE_AVG_TEXT}
          </RankTableTd>
          <RankTableTd textAlign={'left'} isAvg>
            {toUsdString(dataPoint, numberOfDecimals)}
          </RankTableTd>
        </AvgTr>
      ) : (
        <tr>
          <RankNumberTd bold textAlign={'left'}>
            {rank}
          </RankNumberTd>
          <RankTableTd textAlign={'left'}>{name}</RankTableTd>
          <RankTableTd textAlign={'left'}>
            {toUsdString(dataPoint, numberOfDecimals)}
          </RankTableTd>
        </tr>
      )
    })
  }

  const getSpeedRankedRows = (
    rankedData: any,
    numberOfDecimals: number = 0,
  ) => {
    return _.map(rankedData, (d) => {
      const name = _.get(d, 'name', '')
      const rank = _.get(d, 'rank', 1)
      const dataPoint = _.get(d, 'dataPoint', 0)
      const rowType = _.get(d, 'rowType', '')

      return rowType === RANKINGS_ROW_TYPE.AVG ? (
        <AvgTr>
          <RankNumberTd bold textAlign={'left'}></RankNumberTd>
          <RankTableTd textAlign={'left'} isAvg>
            {STORE_AVG_TEXT}
          </RankTableTd>
          <RankTableTd textAlign={'left'} isAvg>
            {dataPoint.toFixed(numberOfDecimals)}
          </RankTableTd>
        </AvgTr>
      ) : (
        <tr>
          <RankNumberTd bold textAlign={'left'}>
            {rank}
          </RankNumberTd>
          <RankTableTd textAlign={'left'}>{name}</RankTableTd>
          <RankTableTd textAlign={'left'}>
            {dataPoint.toFixed(numberOfDecimals)}
          </RankTableTd>
        </tr>
      )
    })
  }

  const normalizedTotalSales = _.map(rankedTotalSales, (s) => {
    const lastName = _.startCase(_.get(s, 'lastName', ''))
    const firstName = lastName
      ? _.startCase(_.get(s, 'firstName', ''))
      : _.get(s, 'firstName', '')
    const name = `${firstName} ${lastName}`
    const rank = _.get(s, 'grossSalesRank', 1)
    const grossSales = _.get(s, 'grossSales', 0)
    const rowType = _.get(s, 'rowType', '')

    return {
      name,
      rank,
      dataPoint: grossSales,
      rowType,
    }
  })

  const normalizedSalesPerHour = _.map(rankedSalesPerHour, (s) => {
    const lastName = _.startCase(_.get(s, 'lastName', ''))
    const firstName = lastName
      ? _.startCase(_.get(s, 'firstName', ''))
      : _.get(s, 'firstName', '')
    const name = `${firstName} ${lastName}`
    const salesPerHourRank = _.get(s, 'salesPerHourRank', 1)
    const salesPerHour = _.get(s, 'salesPerHour', 0)
    const rowType = _.get(s, 'rowType', '')

    return {
      name,
      rank: salesPerHourRank,
      dataPoint: salesPerHour,
      rowType,
    }
  })

  const normalizedPpa = _.map(rankedPpa, (s) => {
    const lastName = _.startCase(_.get(s, 'lastName', ''))
    const firstName = lastName
      ? _.startCase(_.get(s, 'firstName', ''))
      : _.get(s, 'firstName', '')
    const name = `${firstName} ${lastName}`
    const salesPpaRank = _.get(s, 'salesPpaRank', 1)
    const salesPpa = _.get(s, 'salesPpa', 0)
    const rowType = _.get(s, 'rowType', '')

    return {
      name,
      rank: salesPpaRank,
      dataPoint: salesPpa,
      rowType,
    }
  })

  const normalizedSpeed: any[] = []
  _.forEach(rankedSosAvgTime, (s, index) => {
    const lastName = _.startCase(_.get(s, 'lastName', ''))
    const firstName = lastName
      ? _.startCase(_.get(s, 'firstName', ''))
      : _.get(s, 'firstName', '')
    const name = `${firstName} ${lastName}`
    const sosAvgTimeRank = _.get(s, 'sosAvgTimeRank', 1)
    const sosAvgTime = _.get(s, 'sosAvgTime', 0)
    const rowType = _.get(s, 'rowType', '')

    if (sosAvgTime > 0) {
      normalizedSpeed.push({
        name,
        rank: sosAvgTimeRank,
        dataPoint: sosAvgTime,
        rowType,
      })
    }
  })

  const totalSalesRows = getRankedRows(normalizedTotalSales)
  const salesPerHourRows = getRankedRows(normalizedSalesPerHour)
  const ppaRows = getRankedRows(normalizedPpa)
  const speedRows = getSpeedRankedRows(normalizedSpeed, 1)

  let toggledSalesRows = totalSalesRows
  let toggledSalesPerHourRows = salesPerHourRows
  let toggledPpaRows = ppaRows
  let toggledSpeedRows = speedRows

  if (!isTotalSalesExpanded) {
    toggledSalesRows = _.slice(totalSalesRows, 0, 5)
  }
  if (!isSalesPerHourExpanded) {
    toggledSalesPerHourRows = _.slice(salesPerHourRows, 0, 5)
  }
  if (!isPpaExpanded) {
    toggledPpaRows = _.slice(ppaRows, 0, 5)
  }
  if (!isSpeedOfServiceExpanded) {
    toggledSpeedRows = _.slice(speedRows, 0, 5)
  }

  const totalSalesToggleText = isTotalSalesExpanded
    ? 'Collapse all'
    : 'Show All'
  const salesPerHourToggleText = isSalesPerHourExpanded
    ? 'Collapse all'
    : 'Show All'
  const ppaToggleText = isPpaExpanded ? 'Collapse all' : 'Show All'
  const speedOfServiceToggleText = isSpeedOfServiceExpanded
    ? 'Collapse all'
    : 'Show All'

  const director = {
    name: locationData?.location?.doName,
    id: locationData?.location?.doEmployeeId,
  }
  const gm = {
    name: locationData?.location?.gmName,
    id: locationData?.location?.gmEmployeeId,
  }

  return (
    <>
      <LocationSelectorContainer>
        <LocationSelector navParams={navParams} />
      </LocationSelectorContainer>
      <MainContainer>
        <StoreInfoTable>
          <tbody>
            <tr>
              <td>
                <TitleDiv>Salesmanship</TitleDiv>
                <StoreNameDiv>
                  Store: {_.get(locationData, 'location.displayName', '')}
                </StoreNameDiv>
                <LocationInfo director={director} gm={gm} />
              </td>
            </tr>
          </tbody>
        </StoreInfoTable>
        {brandSettings.brand === EDDIE_MERLOTS_BRAND_CODE ? (
          <SubtitleContainer>
            This page uses CrunchTime data, so some locations may not be
            included yet
          </SubtitleContainer>
        ) : null}
        {isLegacy || isQsr ? <PlusMinusTable navParams={navParams} /> : null}
        {isLegacy || isQsr ? (
          <HeaderContainer>
            <TopFilterContainer>
              <TimeframeDropdown
                filterType={filterType}
                setFilterType={setFilterType}
                numDaysWorked={numDaysWorked}
                setNumDaysWorked={setNumDaysWorked}
                selectedPeriod={selectedPeriod}
                setSelectedPeriod={setSelectedPeriod}
                dateRangeStartDate={dateRangeStartDate}
                dateRangeEndDate={dateRangeEndDate}
                setDateRangeStartDate={setDateRangeStartDate}
                setDateRangeEndDate={setDateRangeEndDate}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
              />
            </TopFilterContainer>
            <TimeFilterSelectorContainer>
              <DaypartFormControl
                variant="outlined"
                margin="dense"
                style={{ width: '90vw' }}
              >
                <SalesmanSelect
                  displayEmpty
                  value={dayPart}
                  onChange={handleLeaderboardsDaypartChange}
                >
                  <MenuItem key="dinner" value="dinner">
                    Dinner Only
                  </MenuItem>
                  <MenuItem key="lunch" value="lunch">
                    Lunch Only
                  </MenuItem>
                  <MenuItem key="all" value="all">
                    All
                  </MenuItem>
                </SalesmanSelect>
              </DaypartFormControl>
            </TimeFilterSelectorContainer>
          </HeaderContainer>
        ) : (
          <VerticalSpacer15px />
        )}
        {salesmanshipConfig['productivityCoefficient']?.isVisible ? (
          <>
            <ScrollableTableContainer>
              <ScrollableTable>
                <ProductivityCoefficientChart
                  selectedLocation={locationData.location}
                />
              </ScrollableTable>
            </ScrollableTableContainer>
            <VerticalSpacer30px />
          </>
        ) : null}
        <a ref={totalSalesRef} />
        <ContentContainer>
          <LeaderboardsContainer>Leaderboards</LeaderboardsContainer>
          {isQsr || !isLegacy ? null : (
            <SubtitleContainer>
              Main Dining and Bar Sales Only
            </SubtitleContainer>
          )}
          <VerticalSpacer15px />
          <TableDescription>TOTAL SALES</TableDescription>
          <RankTable>
            <thead>
              <tr>
                <RankTableTh textAlign={'left'}>Rank</RankTableTh>
                <RankTableTh textAlign={'left'}>Name</RankTableTh>
                <RankTableTh textAlign={'left'}>Total Sales</RankTableTh>
              </tr>
            </thead>
            <tbody>{toggledSalesRows}</tbody>
          </RankTable>
          <ExpandAllSpan onClick={onToggleExpandTotalSales}>
            {totalSalesToggleText}
          </ExpandAllSpan>
          <a ref={salesPerHourRef} />
          <VerticalSpacer30px />
          <TableDescription>SALES PER LABOR HOUR</TableDescription>
          <RankTable>
            <thead>
              <tr>
                <RankTableTh textAlign={'left'}>Rank</RankTableTh>
                <RankTableTh textAlign={'left'}>Name</RankTableTh>
                <RankTableTh textAlign={'left'}>Sales/Hour</RankTableTh>
              </tr>
            </thead>
            <tbody>{toggledSalesPerHourRows}</tbody>
          </RankTable>
          <ExpandAllSpan onClick={onToggleExpandSalesPerHour}>
            {salesPerHourToggleText}
          </ExpandAllSpan>
          <a ref={ppaRef} />
          <VerticalSpacer30px />
          <TableDescription>{ppaFullLabel.toUpperCase()}</TableDescription>
          <RankTable>
            <thead>
              <tr>
                <RankTableTh textAlign={'left'}>Rank</RankTableTh>
                <RankTableTh textAlign={'left'}>Name</RankTableTh>
                <RankTableTh textAlign={'left'}>{ppaLabel}</RankTableTh>
              </tr>
            </thead>
            <tbody>{toggledPpaRows}</tbody>
          </RankTable>
          <ExpandAllSpan onClick={onToggleExpandPpa}>
            {ppaToggleText}
          </ExpandAllSpan>
          {isQsr ? (
            <>
              <a ref={speedOfServiceRef} />
              <VerticalSpacer30px />
              <TableDescription>SPEED OF SERVICE</TableDescription>
              <RankTable>
                <thead>
                  <tr>
                    <RankTableTh textAlign={'left'}>Rank</RankTableTh>
                    <RankTableTh textAlign={'left'}>Name</RankTableTh>
                    <RankTableTh textAlign={'left'}>Seconds</RankTableTh>
                  </tr>
                </thead>
                <tbody>{toggledSpeedRows}</tbody>
              </RankTable>
              <ExpandAllSpan onClick={onToggleExpandSpeedOfService}>
                {speedOfServiceToggleText}
              </ExpandAllSpan>
            </>
          ) : null}
          <VerticalSpacer30px />
          <StaffSalesTable
            navParams={navParams}
            selectedPeriod={selectedPeriod}
            dayPart={dayPart}
            filterType={filterType}
            numDaysWorked={numDaysWorked}
            dateRangeStartDate={dateRangeStartDate}
            dateRangeEndDate={dateRangeEndDate}
          />
          <VerticalSpacer100px />
        </ContentContainer>
      </MainContainer>
    </>
  )
}

const ScrollableTableContainer = styled.div`
  overflow-x: scroll;
  border: 1px solid;
  padding-left: 30px;
  width: 90vw;
`

const ScrollableTable = styled.div`
  width: 850px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
`

const MainContainer = styled.div`
  padding: 30px 25px 50px 25px;
`

const AvgTr = styled.tr`
  background-color: ${COLORS.Sky} !important;
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;
  padding: 15px 0 15px 0;
`

const LocationSelectorContainer = styled.div`
  padding: 10px 25px 10px 25px;
  background-color: rgb(196, 196, 196);
`

const LoadingContainer = styled.div`
  padding-right: 50px;
`

const DaypartFormControl = styled(FormControl)`
  background-color: white;
`

const SalesmanSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const TopFilterContainer = styled.div`
  display: inline-block;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const SelectorContainer = styled.div`
  float: left;
`

const TimeFilterSelectorContainer = styled(SelectorContainer)`
  margin-top: 5px;
`

const VerticalSpacer15px = styled.div`
  padding: 15px 50px 30px 50px;
`

const VerticalSpacer30px = styled.div`
  padding: 30px 50px 30px 50px;
`

const VerticalSpacer100px = styled.div`
  padding: 100px 50px 30px 50px;
`

const ExpandAllSpan = styled.div`
  margin-top: 20px;
  font-family: Lexend-Regular;
  color: #00bfff;
  text-decoration: underline;
  cursor: pointer;
  float: right;
`

const TableDescription = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 10px;
`

const HeaderContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
  display: inline-block;
`

const RankTableTh = styled.th<IRankTableTh>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankTableTd = styled.td<IRankTableTd>`
  color: ${(props) => (props.isAvg ? 'white' : 'black')};
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankNumberTd = styled(RankTableTd)`
  padding-left: 12px;
`

const PageHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  float: left;
`

const LeaderboardsContainer = styled(PageHeader)`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  float: initial;
`

const StoreInfoTable = styled.table`
  font-family: Lexend-Regular;
  color: ${COLORS.Chalkboard};
`

const ContentContainer = styled.div``

const TitleDiv = styled.div`
  margin: 0px 0px 15px;
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 0 0;
`

const StoreNameDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 10px 0;
`

const RankTable = styled.table`
  width: 90vw;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`

export default WebSmallMain
