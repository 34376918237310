import FormControl from '@material-ui/core/FormControl'
import React from 'react'
import styled from 'styled-components'

import useGroupSelector from 'pared/components/GroupSelector/hooks/useGroupSelector'
import ExpoSelect from 'pared/components/basicUi/select'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import ExrayTable from 'pared/pages/Scorecard/v2/Exray'

import { PAGE_PADDING_LEFT } from '../constants'
import useExraySummary from './hooks/useExraySummary'

type UseGroupSelectorReturn = ReturnType<typeof useGroupSelector>

interface ExrayProps extends UseGroupSelectorReturn {}

const CorporateExray: React.FC<ExrayProps> = ({
  groupBy,
  setGroupBy,
  options,
  locationGroupId,
}) => {
  const { totalOpportunityCost, data } = useExraySummary(
    typeof locationGroupId === 'string'
      ? parseInt(locationGroupId)
      : locationGroupId,
  )

  return (
    <Container>
      <Header>
        <FlexContainer>
          <Bold>Show</Bold>
          <StyledFormControl>
            <ExpoSelect
              value={groupBy}
              onChange={setGroupBy}
              dataSource={options}
              fullWidth
              displayEmpty
            />
          </StyledFormControl>
        </FlexContainer>
        <OpportunityCost>
          Total Opportunities: {totalOpportunityCost} Annually
        </OpportunityCost>
      </Header>
      <ExrayTable exrayData={data} />
    </Container>
  )
}

export default CorporateExray

const Container = styled.div`
  padding-left: ${PAGE_PADDING_LEFT}px;
  margin-bottom: 40px;
  @media ${MOBILE_WIDTH} {
    padding: 10px;
  }
`
const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
  @media ${MOBILE_WIDTH} {
    flex-direction: column;
  }
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

const Bold = styled.div`
  font-family: Lexend-Semibold;
`

const OpportunityCost = styled(Bold)`
  font-size: 18px;
`

const StyledFormControl = styled(FormControl)`
  background-color: white;
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`
