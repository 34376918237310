import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

import useApprovedTrainingTracker from './hooks/useApprovedTrainingTracker'
import useNeedApprovalTrainingTracker from './hooks/useNeedApprovalTrainingTracker'

export interface IPropsType {
  showSubmit: boolean
  showApprove: boolean
  needApprove: boolean
  isShiftLeaderTracker: Boolean
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`

const SummitButton = styled.button<{ disabled: boolean }>`
  color: white;
  padding: 16px 50px;
  border: none;
  border-radius: 0;
  margin-right: 10px;
  ${({ disabled }) =>
    disabled
      ? `
        background-color: ${COLORS.Steel};
        cursor: none;
        pointer-events: none;
      `
      : `
        cursor: pointer;
        background-color: ${COLORS.Plum};
    `};
`

const FinalButton = styled.button<{ disabled: boolean }>`
  background-color: ${COLORS.Plum};
  color: white;
  padding: 16px 50px;
  border: none;
  border-radius: 0;
  margin-right: 10px;
  ${({ disabled }) =>
    disabled
      ? `
        cursor: none;
        pointer-events: none;
      `
      : `cursor: pointer;`};
`

const CancelButton = styled.button`
  background-color: ${COLORS.Steel};
  color: white;
  padding: 16px 50px;
  border: none;
  border-radius: 0px;
  margin-right: 10px;
  cursor: pointer;
`

const Buttons = ({
  showSubmit,
  showApprove,
  needApprove,
  isShiftLeaderTracker,
}: IPropsType) => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const onApproval = useApprovedTrainingTracker()
  const onSubmit = useNeedApprovalTrainingTracker()

  useEffect(() => {
    setLoading(false)
  }, [needApprove, showApprove, showSubmit])

  return (
    <ButtonWrapper>
      {showApprove && (
        <FinalButton
          onClick={async () => {
            if (!loading) {
              setLoading(true)
              await onApproval()
              history.goBack()
            }
          }}
          disabled={loading}
        >
          {isShiftLeaderTracker ? (
            <>
              <div>Approve Shift Assessment and</div>
              <div>Final Approval</div>
            </>
          ) : (
            'Final Approval'
          )}
        </FinalButton>
      )}
      {showSubmit && (
        <SummitButton
          onClick={async () => {
            if (!needApprove && !loading) {
              setLoading(true)
              await onSubmit()
            }
          }}
          disabled={!!needApprove || loading}
        >
          {needApprove ? 'Awaiting Approval' : 'Submit for Approval'}
        </SummitButton>
      )}
      <CancelButton onClick={() => history.goBack()}>
        Return to Previous Page
      </CancelButton>
    </ButtonWrapper>
  )
}

export default Buttons
