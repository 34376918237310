import useFwWingstopListLocationGroupLossPreventionKpis, {
  fwWingstopListLocationGroupLossPreventionKpisConfigs,
} from './useListLocationGroupLossPreventionKpis'
import useFwWingstopListLocationLossPreventionKpis, {
  fwWingstopListLocationLossPreventionKpisConfigs,
} from './useListLocationLossPreventionKpis'

export const configs = {
  fwWingstopListLocationGroupLossPreventionKpis:
    fwWingstopListLocationGroupLossPreventionKpisConfigs,
  fwWingstopListLocationLossPreventionKpis:
    fwWingstopListLocationLossPreventionKpisConfigs,
}

export const api = {
  fwWingstopListLocationGroupLossPreventionKpis:
    useFwWingstopListLocationGroupLossPreventionKpis,
  fwWingstopListLocationLossPreventionKpis:
    useFwWingstopListLocationLossPreventionKpis,
}
