export const localFavoriteKpiDefs = {
  appetizerPpa: {
    key: 'appetizerPpaV2',
    name: 'Appetizer PPA',
    kpiType: 'sales',
    salesType: 'appetizer',
    kpiKey: 'ppa',
    valueType: 'cent',
    orderBy: 'desc',
    digitNum: 2,
  },
  lbwPpa: {
    key: 'lbwPpaV2',
    name: 'LBW PPA',
    kpiType: 'sales',
    salesType: 'lbw',
    kpiKey: 'ppa',
    valueType: 'cent',
    orderBy: 'desc',
    digitNum: 2,
  },
  dessertPpa: {
    key: 'dessertPpaV2',
    name: 'Dessert PPA',
    kpiType: 'sales',
    salesType: 'dessert',
    kpiKey: 'ppa',
    valueType: 'cent',
    orderBy: 'desc',
    digitNum: 2,
  },
  addOnPpa: {
    key: 'addOnPpa',
    name: 'AddOn PPA',
    kpiType: 'sales',
    salesType: 'addOn',
    kpiKey: 'ppa',
    valueType: 'cent',
    orderBy: 'desc',
    digitNum: 2,
  },
  naBeveragePpa: {
    key: 'naBeveragePpa',
    name: 'NA Beverage PPA',
    kpiType: 'sales',
    salesType: 'naBeverage',
    kpiKey: 'ppa',
    valueType: 'cent',
    orderBy: 'desc',
    digitNum: 2,
  },
  sidesPpa: {
    key: 'sidesPpa',
    name: 'Sides PPA',
    kpiType: 'sales',
    salesType: 'sides',
    kpiKey: 'ppa',
    valueType: 'cent',
    orderBy: 'desc',
    digitNum: 2,
  },
  shakePpa: {
    key: 'shakePpa',
    name: 'Shake PPA',
    kpiType: 'sales',
    salesType: 'shake',
    kpiKey: 'ppa',
    valueType: 'cent',
    orderBy: 'desc',
    digitNum: 2,
  },
  salesPerLaborHour: {
    key: 'salesPerLaborHour',
    name: 'Sales Per Labor Hour',
    kpiType: 'sales',
    salesType: 'total',
    kpiKey: 'salesPerHour',
    valueType: 'cent',
    orderBy: 'desc',
    digitNum: 2,
  },
}
