import { configureStore } from '@reduxjs/toolkit'
import storeSynchronize from 'redux-localstore'

import appSlice from 'pared/slices/app'
import userSlice, { userSliceName } from 'pared/slices/user'

const store = configureStore({
  reducer: {
    app: appSlice,
    [userSliceName]: userSlice,
  },
})

storeSynchronize(store, {
  whitelist: [userSliceName],
})

export default store
