import { useQuery } from '@apollo/client'
import moment from 'moment'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import COLORS from 'pared/constants/colors'
import { getDateRangeLabel } from 'pared/utils/date'

import useSalesmanshipConfig from '../hooks/useSalesmanshipConfig'
import { TREND_LOCATION_SPEED_OF_SERVICE } from './gql'

const CHART_WIDTH = 1100
const CHART_HEIGHT = 350
const CHART_START_DATE = moment.utc('2022-05-15', 'YYYY-MM-DD', true)

interface IProps {
  trendChartParams: {
    iLocationId: number
    iLocationGroupId: number
    iEndDate: string
    iGroupBy: string
  }
}

interface IData {
  trendLocationSpeedOfService: {
    nodes: {
      startDate: string
      endDate: string
      sosDetails: {
        [windowType: string]: {
          name: string
          avgSeconds: number
        }
      }
    }[]
  }

  trendLocationGroupSpeedOfServiceBypassRls: {
    nodes: {
      startDate: string
      endDate: string
      sosDetails: {
        [windowType: string]: {
          name: string
          avgSeconds: number
        }
      }
    }[]
  }
}

interface IOrganizedData {
  dateRanges: string[]
  locationAverageWindowTime: number[]
  locationGroupAverageWindowTime: number[]
}

const SpeedOfServiceTrendChart = ({ trendChartParams }: IProps) => {
  const config = useSalesmanshipConfig()

  const { loading, data }: { loading: boolean; data: IData | undefined } =
    useQuery(TREND_LOCATION_SPEED_OF_SERVICE, {
      variables: trendChartParams,
      skip: !trendChartParams.iEndDate,
    })

  if (loading) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  if (!data) {
    return (
      <PageStatusDiv>
        There is no data within the date range selected. Please select a
        different date range.
      </PageStatusDiv>
    )
  }

  const kpiType = config?.speedOfService?.sosChartKpi?.type || ''
  const kpiDisplayName = config?.speedOfService?.sosChartKpi?.displayName || ''

  const locationData = data?.trendLocationSpeedOfService?.nodes || []
  const locationGroupData =
    data?.trendLocationGroupSpeedOfServiceBypassRls?.nodes || []

  const processedDataMap: {
    [startDate: string]: {
      locationAverageWindowTime: number
      locationGroupAverageWindowTime: number
    }
  } = {}

  locationData.forEach((rawData: any) => {
    const startDate = rawData?.startDate
    const avgSeconds = rawData?.sosDetails?.[kpiType]?.avgSeconds
    const processedData = processedDataMap[startDate] || {}
    processedData.locationAverageWindowTime = avgSeconds
    processedDataMap[startDate] = processedData
  })

  locationGroupData.forEach((rawData: any) => {
    const startDate = rawData?.startDate
    const avgSeconds = rawData?.sosDetails?.[kpiType]?.avgSeconds
    const processedData = processedDataMap[startDate] || {}
    processedData.locationGroupAverageWindowTime = avgSeconds
    processedDataMap[startDate] = processedData
  })

  const organizedData = locationData.reduce(
    (acc, { startDate }) => {
      if (CHART_START_DATE.isBefore(startDate)) {
        acc.dateRanges.push(
          getDateRangeLabel(startDate, trendChartParams.iGroupBy),
        )
        acc.locationAverageWindowTime.push(
          processedDataMap[startDate]?.locationAverageWindowTime || 0.0,
        )
        acc.locationGroupAverageWindowTime.push(
          processedDataMap[startDate]?.locationGroupAverageWindowTime || 0.0,
        )
      }
      return acc
    },
    {
      dateRanges: [],
      locationAverageWindowTime: [],
      locationGroupAverageWindowTime: [],
    } as IOrganizedData,
  )

  const yAxisDataArr = [
    {
      type: 'line',
      data: organizedData.locationAverageWindowTime,
      tooltipLabel: `Store - ${kpiDisplayName}`,
      borderColor: COLORS.Basil,
      backgroundColor: COLORS.Basil,
    },
    {
      type: 'line',
      data: organizedData.locationGroupAverageWindowTime,
      tooltipLabel: `Systemwide - ${kpiDisplayName}`,
      borderColor: COLORS.Pomodoro,
      backgroundColor: COLORS.Pomodoro,
    },
  ]

  const chartOptions = {
    title: '',
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    yLeftAxisLabel:
      config?.speedOfService?.sosChartKpi?.displayName ||
      'Average Window Time (second)',
    yLeftTickCallback: (value: number) => {
      return `${value}`
    },
  }

  return (
    <MixedChart
      xAxisData={organizedData.dateRanges}
      yAxisDataArr={yAxisDataArr}
      options={chartOptions}
    />
  )
}

export default SpeedOfServiceTrendChart

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`
