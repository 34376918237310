import TextField from '@mui/material/TextField'
import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import { CustomizedReportRender } from 'pared/components/CustomizedReport'
import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import Select from 'pared/components/basicUi/select'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { TYPE_PERIOD } from 'pared/data/getDateRanges'
import useGetDateRange from 'pared/hooks/useGetDateRange'
import CorporatePurchasesTable from 'pared/pages/CorporatePurchases/CorporatePurchasesTable'
import { getBrand } from 'pared/utils/brand'

import getFilteredCustomReportDefinition from './getFilteredCustomReportDefinition'
import useCategoryFilter, {
  ICategoryFilterType,
} from './hooks/useCategoryFilter'
import useFetchSelectedInventoryReportData from './hooks/useFetchSelectedInventoryReportData'
import useInventoryConfig from './hooks/useInventoryConfig'
import useItemFilter from './hooks/useItemFilter'
import useReportFilter, { IReportFilterType } from './hooks/useReportFilter'
import useTimeFilter, { ITimeFilterType } from './hooks/useTimeFilter'
import useUnitFilter from './hooks/useUnitFilter'
import { IUnitFilterItemType } from './hooks/useUnitFiltersConfig'

interface IPropsType {
  navParams: INavParams
}

const Inventory = ({ navParams }: IPropsType) => {
  const config = useInventoryConfig()
  const brand = getBrand()
  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]

  const dateRange = useGetDateRange()

  const { reportFilter, setReportFilter, reportFilters } = useReportFilter()

  const { timeFilter, setTimeFilter, timeFilters } = useTimeFilter()

  const reportFilterValue = _.get(reportFilter, '[0]')
  const timeFilterValue = _.get(timeFilter, '[0]')

  const { reportDefinition, reportResult, isLoading } =
    useFetchSelectedInventoryReportData(
      brandLocationGroupId,
      timeFilterValue,
      dateRange,
    )

  const {
    categoryFilter,
    setCategoryFilter,
    categoryFilters,
    categoryFilteredCustomizedReportResult,
  } = useCategoryFilter(reportResult)

  const { itemFilter, setItemFilter, itemFilteredCustomizedReportResult } =
    useItemFilter(categoryFilteredCustomizedReportResult)

  const { unitFilter, setUnitFilter, unitFilters } = useUnitFilter('inventory')

  const selectedKpi = _.get(reportFilter, '[0]')
  const selectedCategory = _.get(categoryFilter, '[0]')
  const selectedUnit = _.get(unitFilter, '[0]')
  const filteredCustomizedReportDefinition = getFilteredCustomReportDefinition(
    reportDefinition,
    {
      kpi: selectedKpi,
      unit: selectedUnit,
    },
  )

  return (
    <>
      <Header>
        <h1>Inventory</h1>

        <Selectors>
          <DateRangeSelector
            navParams={navParams}
            preSelectedDateRange={config.preSelectedDateRange || TYPE_PERIOD}
            dateRangeOptions={config.dateRangeOptions}
          />
        </Selectors>
      </Header>

      {config.isPurchaseTableVisible ? (
        <CorporatePurchasesTableContainer>
          <Subheader>{config.purchaseTableTitle ?? 'Purchases'}</Subheader>
          <CorporatePurchasesTable
            locationGroupId={brandLocationGroupId}
            startDate={dateRange?.startDateStr}
            endDate={dateRange?.endDateStr}
          />
        </CorporatePurchasesTableContainer>
      ) : null}

      <Container>
        <h1>{config.varianceTableTitle ?? 'Variance'}</h1>

        <FilterContainer>
          {!config.varianceFilter?.typeVisible ? null : (
            <>
              <span>Type</span>
              <MenuItemSelect<ITimeFilterType>
                value={timeFilter}
                onChange={setTimeFilter}
                dataSource={timeFilters}
              />
            </>
          )}

          {!config.varianceFilter?.kpiVisible ? null : (
            <>
              <span>KPI</span>
              <MenuItemSelect<IReportFilterType>
                value={reportFilter}
                onChange={setReportFilter}
                dataSource={reportFilters}
              />
            </>
          )}

          <span>Category</span>
          <MenuItemSelect<ICategoryFilterType>
            value={categoryFilter}
            onChange={setCategoryFilter}
            dataSource={categoryFilters}
          />

          <span>Item</span>
          <StyledTextField
            value={itemFilter}
            placeholder="Start typing item name..."
            onChange={(e) => setItemFilter(e.target.value)}
          />

          {!config.varianceFilter?.unitVisible ? null : (
            <>
              <span>Unit</span>
              <MenuItemSelect<IUnitFilterItemType>
                value={unitFilter}
                onChange={setUnitFilter}
                dataSource={unitFilters}
              />
            </>
          )}
        </FilterContainer>
        <CustomizedReportRender
          key={`${reportFilterValue}-${dateRange?.key}-${selectedCategory}-${selectedUnit}`}
          reportDefinition={filteredCustomizedReportDefinition}
          reportResult={itemFilteredCustomizedReportResult}
          isLoading={isLoading}
          rowKey={(values) => `${values.item}-${values.itemUnit}`}
          csvFileName="getexpo-corporate-inventory"
        />
      </Container>
    </>
  )
}

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;
  padding-bottom: 40px;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 25px 0px;
    }
  }
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const Container = styled.div`
  padding: 0 0 60px 0;
  @media ${MOBILE_WIDTH} {
    padding: 30px 25px 50px;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 0px;
    }
  }
`

const MenuItemSelect = styled(Select)`
  width: 150px;
  background-color: white;
  margin: 0 30px 0 15px;

  @media ${MOBILE_WIDTH} {
    width: 100%;
    margin: 10px 0 20px 0;
  }
` as typeof Select

const StyledTextField = styled(TextField)`
  width: 250px;
  background-color: white;
  margin: 0 30px 0 15px;

  @media ${MOBILE_WIDTH} {
    width: 100%;
    margin: 10px 0 20px 0;
  }
`

const FilterContainer = styled.div`
  width: 1300px;
  padding: 25px 0;

  span {
    font-family: Lexend-SemiBold;
    font-size: 14px;
    font-weight: 700;
  }

  @media ${MOBILE_WIDTH} {
    text-align: left;
    width: 100%;
    padding: 5px 0 25px 0;

    span {
      display: block;
      padding: 0;
    }
  }
`

const Subheader = styled.div`
  margin: 0px;
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  line-height: 52px;

  @media ${MOBILE_WIDTH} {
    padding: 0 25px 25px 25px;
  }
`

const CorporatePurchasesTableContainer = styled.div`
  padding: 0 0 60px 0;

  @media ${MOBILE_WIDTH} {
    padding: 0 0 20px 0;
  }
`

export default React.memo(Inventory)
