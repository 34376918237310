import { getBrand } from 'pared/utils/brand'

export default () => {
  const brand = getBrand()
  switch (brand) {
    case 'ghai_pop':
      return [
        {
          key: 'Sales',
          title: 'Store Sales',
        },
        {
          key: 'Sales Per Labor Hour Difference',
          title: 'Store SPLH vs System',
        },
      ]

    case 'mwb':
    case 'burgerworks':
      return [
        {
          key: 'Sales',
          title: 'Store Sales',
        },
        {
          key: 'Sales Difference',
          title: 'Store Sales vs System',
        },
        {
          key: 'Labor Percent',
          title: 'Labor Percent',
        },
        {
          key: 'Sales Per Labor Hour Difference',
          title: 'Store SPLH vs System',
        },
        {
          key: 'Labor Hours Difference',
          title: 'Unproductive Labor Heatmap',
        },
      ]

    default:
      return [
        {
          key: 'Sales',
          title: 'Store Sales',
        },
        {
          key: 'Sales Difference',
          title: 'Store Sales vs System',
        },
        {
          key: 'Sales Per Labor Hour Difference',
          title: 'Store SPLH vs System',
        },
        {
          key: 'Labor Hours Difference',
          title: 'Unproductive Labor Heatmap',
        },
      ]
  }
}
