import moment from 'moment'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'
import { getYearMonthDiff } from 'pared/utils/date'
import { toPercentString, toUsdString } from 'pared/utils/number'

import GroupLinkButton, {
  IPropsType as IGroupLinkButtonPropsType,
} from './GroupLinkButton'
import Link, { IPropsType as ILinkPropsType } from './Link'
import { IColoredText } from './types'

interface IBasePropsType {
  value: unknown
  values: Record<string, unknown>
}

export type IPropsType =
  | (IBasePropsType & {
      type: 'price'
      decimal?: number
    })
  | (IBasePropsType & {
      type: 'percent'
      decimal?: number
      reverse?: boolean
    })
  | (IBasePropsType & {
      type: 'number'
      decimal?: number
      reverse?: boolean
    })
  | (IBasePropsType & {
      type: 'date'
    })
  | (IBasePropsType & {
      type: 'date-diff'
    })
  | (IBasePropsType & {
      type: 'string'
    })
  | (IBasePropsType & {
      type: 'link'
    })
  | (IBasePropsType & {
      type: 'group-info'
    })
  | (IBasePropsType & {
      type: 'colored-text'
    })

const Text = styled.span<{
  danger?: boolean
  warning?: boolean
  color?: string
}>`
  color: ${({ danger, warning, color }) =>
    danger
      ? COLORS.Pomodoro
      : warning
      ? COLORS.Mango
      : color
      ? color
      : 'black'};}
`

const displayDangerString = (number: string | number, danger: boolean) =>
  danger ? `(${number})` : `${number}`

const Format = ({ value, ...props }: IPropsType) => {
  switch (props.type) {
    case 'price':
      return (
        <>{toUsdString(parseFloat(value as string) / 100, props.decimal)}</>
      )

    case 'percent': {
      const danger = (value as number) < 0

      return (
        <Text danger={props.reverse ? !danger : danger}>
          {toPercentString(value as string, props.decimal)}
        </Text>
      )
    }

    case 'number': {
      if (value === '-' || value === undefined || value === null) return <>-</>

      if (typeof value === 'string') {
        const numberValue = parseFloat(value)
        const danger = numberValue < 0

        return (
          <Text danger={props.reverse ? !danger : danger}>
            {displayDangerString(
              Math.abs(numberValue).toLocaleString('en-US', {
                minimumFractionDigits: props.decimal || 0,
                maximumFractionDigits: props.decimal || 0,
              }),
              props.reverse ? !danger : danger,
            )}
          </Text>
        )
      }

      const danger = (value as number) < 0

      return (
        <Text danger={props.reverse ? !danger : danger}>
          {displayDangerString(
            Math.abs(value as number).toLocaleString('en-US', {
              minimumFractionDigits: props.decimal || 0,
              maximumFractionDigits: props.decimal || 0,
            }),
            props.reverse ? !danger : danger,
          )}
        </Text>
      )
    }

    case 'date':
      return (
        <Text danger={moment(value as string).diff(moment()) < 0}>
          {moment(value as string).format('M/D/YYYY')}
        </Text>
      )

    case 'date-diff': {
      const date = moment(value as string)

      return <>{date.isValid() ? getYearMonthDiff(date, moment()) : '-'}</>
    }

    case 'string':
      return <>{value}</>

    case 'link':
      if (value === undefined || value === null) return null
      return <Link {...(value as ILinkPropsType)} />

    case 'group-info':
      return (
        <>
          {!value?.link ? (
            value?.name || ''
          ) : (
            <GroupLinkButton {...(value as IGroupLinkButtonPropsType)} />
          )}
        </>
      )

    case 'colored-text':
      if (value) {
        const { text, textColor } = value as IColoredText
        if (textColor === 'danger') {
          return <Text danger>{text}</Text>
        } else if (textColor === 'warning') {
          return <Text color={COLORS.Mango}>{text}</Text>
        } else {
          return <Text color={textColor}>{text}</Text>
        }
      } else {
        return null
      }

    default:
      return null
  }
}

export type { ILinkPropsType }
export default Format
