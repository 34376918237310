import { useMemo } from 'react'

import { getBrandSettings } from 'pared/customer'
import useBrands from 'pared/layouts/hooks/useBrands'
import { getUser } from 'pared/utils/user'

import { routes } from '../configs'
import { IRouteType } from '../types'
import useAccessiblePages from './useAccessiblePages'

const useRoutes = () => {
  const { brand } = useBrands()
  const brandSettings = getBrandSettings()
  const accessiblePages = useAccessiblePages()
  const user = getUser()

  return useMemo(() => {
    const processRoutes = (routes: IRouteType[]): IRouteType[] => {
      const accessibleRoutes = (routes: IRouteType[]): IRouteType[] =>
        routes?.reduce((result, r) => {
          if (
            user.disableCorporatePages &&
            r.title === brandSettings.labels.business.corporate
          )
            return result

          if ('children' in r) {
            const children = accessibleRoutes(r.children)
            return children?.length > 0
              ? [...result, { ...r, children }]
              : result
          } else {
            if ('link' in r && Object.keys(accessiblePages).includes(r.link)) {
              return [...result, r]
            }
            return result
          }
        }, [] as IRouteType[]) || []

      return accessibleRoutes(routes)
    }

    return processRoutes(routes[brand])
  }, [brand, accessiblePages, brandSettings, user])
}

export default useRoutes
