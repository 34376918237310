import { v4 as uuidv4 } from 'uuid'

import PmixChart from 'pared/pages/CorporatePmix/PmixTable'

import Table from '../../Table'
import { FROM_EXPO } from '../../constants'
import { IMessage } from '../../type'

const handlePmixKpiValues = (responseData: any) => {
  if (responseData?.pmixKpiValues) {
    const {
      startDate,
      endDate,
      item,
      locationIds,
      locationGroupIds,
      allResults,
    } = responseData.pmixKpiValues

    const answerMessage: IMessage = {
      id: uuidv4(),
      from: FROM_EXPO,
      content: (
        <>
          <Table data={allResults} />
          <PmixChart
            startDate={startDate}
            endDate={endDate}
            locationIds={locationIds?.length > 0 ? locationIds : null}
            locationGroupIds={
              locationGroupIds?.length > 0 ? locationGroupIds : null
            }
            locationGroupTypes={null}
            presetItemFilter={item}
          />
        </>
      ),
    }

    return answerMessage
  }

  return null
}

export default handlePmixKpiValues
