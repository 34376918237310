import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

const defaultConfig = {
  // default week start day is Sunday
  weekStartDay: 0,
}

const useBunConfig = (): any => {
  const brand = getBrand()
  return useMemo(() => {
    switch (brand) {
      case 'irmg_bk':
      case 'viking_bk':
      case 'jsc_bk':
      case 'jsc_bk_nso':
      case 'sandbox_bk':
      case 'multi_king_bk':
      case 'supreme_bk':
      case 'timoney_bk':
      case 'mbn_bk':
      case 'demo_bk':
        return {
          // IRMG week start day is Monday
          weekStartDay: 1,
        }
      default:
        return defaultConfig
    }
  }, [brand])
}
export default useBunConfig
