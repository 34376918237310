import _ from 'lodash'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'

interface IProps {
  xAxisData: string[]
  yAxisInaccurateData: number[]
  yAxisCancelledData: number[]
  yAxisDelayedData: number[]
  yAxisTotalOrderCountData: number[]
  yAxisAnyIssueData: number[]
  chartOptions: any
  yAxisDataArr: number[][]
}

function WebSmallMain({ xAxisData, chartOptions, yAxisDataArr }: IProps) {
  return (
    <ScrollableTableContainer>
      <ScrollableTable>
        <SectionTitleDiv>RATINGS TREND</SectionTitleDiv>
        <MixedChart
          xAxisData={xAxisData}
          yAxisDataArr={yAxisDataArr}
          options={chartOptions}
        />
      </ScrollableTable>
    </ScrollableTableContainer>
  )
}

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 20px 0;
`

const ScrollableTableContainer = styled.div`
  overflow-x: scroll;
  border: 1px solid;
  padding-left: 30px;
  width: 90vw;
`

const ScrollableTable = styled.div`
  width: 850px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
`

export default WebSmallMain
