import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'

import { getBrand } from 'pared/utils/brand'

export interface IReportFilterType {
  id: string
  parentId: 'root' | null
  value: string
  text: string
}

export interface IWeekInfo {
  key: string
  weekIndex: number
  weekBeginDate: moment.Moment
  weekEndDate: moment.Moment
}

export const INVENTORY_REPORT_TYPE = {
  VARIANCE: 'variance',
  TOTAL_WASTE: 'totalWaste',
  MENU_WASTE: 'menuWaste',
  RAW_WASTE: 'rawWaste',
  AVT: 'avt',
}

const useReportFilter = () => {
  const [reportFilter, setReportFilter] = useState<string[]>([])
  const brand = getBrand()

  const defaultMenuItemList = [
    {
      id: 'root',
      parentId: null,
      value: 'root',
      text: 'root',
    },
    {
      id: INVENTORY_REPORT_TYPE.VARIANCE,
      parentId: 'root',
      value: INVENTORY_REPORT_TYPE.VARIANCE,
      text: 'Over/Short',
    },
    {
      id: INVENTORY_REPORT_TYPE.TOTAL_WASTE,
      parentId: 'root',
      value: INVENTORY_REPORT_TYPE.TOTAL_WASTE,
      text: 'Total Waste',
    },
    {
      id: INVENTORY_REPORT_TYPE.MENU_WASTE,
      parentId: 'root',
      value: INVENTORY_REPORT_TYPE.MENU_WASTE,
      text: 'Menu Waste',
    },
    {
      id: INVENTORY_REPORT_TYPE.RAW_WASTE,
      parentId: 'root',
      value: INVENTORY_REPORT_TYPE.RAW_WASTE,
      text: 'Raw Waste',
    },
  ]

  const jscMenuItemList = [
    {
      id: 'root',
      parentId: null,
      value: 'root',
      text: 'root',
    },
    {
      id: INVENTORY_REPORT_TYPE.AVT,
      parentId: 'root',
      value: INVENTORY_REPORT_TYPE.AVT,
      text: 'AvT',
    },
    {
      id: INVENTORY_REPORT_TYPE.TOTAL_WASTE,
      parentId: 'root',
      value: INVENTORY_REPORT_TYPE.TOTAL_WASTE,
      text: 'Total Waste',
    },
  ]

  const ghaiPopMenuItemList = [
    {
      id: 'root',
      parentId: null,
      value: 'root',
      text: 'root',
    },
    {
      id: INVENTORY_REPORT_TYPE.VARIANCE,
      parentId: 'root',
      value: INVENTORY_REPORT_TYPE.VARIANCE,
      text: 'Over/Short',
    },
    {
      id: INVENTORY_REPORT_TYPE.RAW_WASTE,
      parentId: 'root',
      value: INVENTORY_REPORT_TYPE.RAW_WASTE,
      text: 'Waste',
    },
  ]

  const menuItemList = useMemo(() => {
    switch (brand) {
      case 'jsc_bk':
      case 'jsc_bk_nso':
        return jscMenuItemList

      case 'ghai_pop':
        return ghaiPopMenuItemList
      case 'mwb':
      case 'burgerworks':
        return [
          {
            id: 'root',
            parentId: null,
            value: 'root',
            text: 'root',
          },
          {
            id: INVENTORY_REPORT_TYPE.VARIANCE,
            parentId: 'root',
            value: INVENTORY_REPORT_TYPE.VARIANCE,
            text: 'Over/Short',
          },
        ]

      default:
        return defaultMenuItemList
    }
  }, [brand])

  useEffect(() => {
    setReportFilter([menuItemList[1].value])
  }, [menuItemList, brand])

  return {
    reportFilter,
    setReportFilter,
    reportFilters: menuItemList,
  }
}

export default useReportFilter
