import _ from 'lodash'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import DateRangeSelector from 'pared/components/nav/DateRangeSelector'
import { EDDIE_MERLOTS_BRAND_CODE } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import { getBusinessLabel } from 'pared/customer'
import { IDateRange } from 'pared/data/getDateRanges'
import { IDirector } from 'pared/data/getDirectors'
import DirectorFilterSelector from 'pared/pages/StoreList/DirectorFilterSelector'

import BreakdownTable from './BreakdownTable'
import Chart from './Chart'
import ProductivityCoefficientChart from './ProductivityCoefficientChart'
import useSalesmanshipConfig from './hooks/useSalesmanshipConfig'
import { IDetailSalesmanshipKpi } from './index'
import { IGlobalSalesmanshipKpi } from './index'

interface IProps {
  allDirectors: IDirector[]
  selectedDirector: IDirector | null
  navParams: INavParams
  onDirectorChange: (directorEmployeeId: number) => Promise<void>
  selectedDateRange: IDateRange | null
  globalSalesmanshipKpiSummary: IGlobalSalesmanshipKpi
  itemizedKpis: IDetailSalesmanshipKpi[]
  unfilledManagerialPositions: IUnfilledManagerialPosition[]
  unfilledManagerialPositionOption: string
  onSelectUnfilledManagerialPositionOption: (event: any) => void
  performanceOption: string
  onSelectPerformanceOption: (event: any) => void
  managerReviews: IManagerReview[]
}

interface IKpiDiv {
  book?: boolean
}

function WebLargeMain({
  navParams,
  allDirectors,
  selectedDirector,
  onDirectorChange,
  selectedDateRange,
  globalSalesmanshipKpiSummary,
  itemizedKpis,
}: IProps) {
  const brandSettings = getBrandSettings()
  const { isQsr, isLegacy } = brandSettings
  const salesmanshipLabels = brandSettings.labels.salesmanship
  const salesmanshipConfig = useSalesmanshipConfig()

  const appetizerPpa = _.get(globalSalesmanshipKpiSummary, 'appetizerPpa', '')
  const checkAverage = _.get(globalSalesmanshipKpiSummary, 'checkAverage', '')
  const dessertPpa = _.get(globalSalesmanshipKpiSummary, 'dessertPpa', '')
  const grossSales = _.get(globalSalesmanshipKpiSummary, 'grossSales', '')
  const grossSalesPerLaborHour = _.get(
    globalSalesmanshipKpiSummary,
    'grossSalesPerLaborHour',
    '',
  )
  const lbwPpa = _.get(globalSalesmanshipKpiSummary, 'lbwPpa', '')
  const ppa = _.get(globalSalesmanshipKpiSummary, 'ppa', '')
  const sosAvgTime = _.get(globalSalesmanshipKpiSummary, 'sosAvgTime', '')

  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)
  let breakdownTitle = 'BREAKDOWN BY STORE'
  if (selectedDirctorEmployeeId === -2) {
    // breakdownByDirectors
    breakdownTitle = `BREAKDOWN BY ${getBusinessLabel(
      'director',
    ).toUpperCase()}`
  }

  return (
    <MainContainer>
      <PageHeaderContainer>
        <LeftHeaderContainer>
          <PageHeaderLineContainer>
            <PageLeftHeader>Systemwide Salesmanship</PageLeftHeader>
            <SelectorContainer>
              <DirectorFilterSelectorContainer>
                <DirectorFilterSelector
                  allDirectors={allDirectors}
                  selectedDirector={selectedDirector}
                  onDirectorChange={onDirectorChange}
                />
              </DirectorFilterSelectorContainer>
              <DateRangeSelector navParams={navParams} />
            </SelectorContainer>
          </PageHeaderLineContainer>
        </LeftHeaderContainer>
        {isQsr || !isLegacy ? (
          <VerticalSpacer60px />
        ) : (
          <SubtitleContainer>Main Dining and Bar Sales Only</SubtitleContainer>
        )}
        {brandSettings.brand === EDDIE_MERLOTS_BRAND_CODE ? (
          <SubtitleContainer>
            This page uses CrunchTime data, so some locations may not be
            included yet
          </SubtitleContainer>
        ) : null}
      </PageHeaderContainer>

      <KpiTable>
        <thead>
          <tr>
            <th colSpan={5}>KPIs</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {isQsr || !isLegacy ? null : (
              <td>
                <KpiValueDiv book={checkAverage === 'loading'}>
                  {checkAverage}
                </KpiValueDiv>
                <KpiNameDiv>{salesmanshipLabels.checkAvg}</KpiNameDiv>
              </td>
            )}
            <td>
              <KpiValueDiv book={ppa === 'loading'}>{ppa}</KpiValueDiv>
              <KpiNameDiv>{salesmanshipLabels.totalPpa}</KpiNameDiv>
            </td>
            <td>
              <KpiValueDiv book={appetizerPpa === 'loading'}>
                {appetizerPpa}
              </KpiValueDiv>
              <KpiNameDiv>{salesmanshipLabels.appetizerPpa}</KpiNameDiv>
            </td>
            <td>
              <KpiValueDiv book={lbwPpa === 'loading'}>{lbwPpa}</KpiValueDiv>
              <KpiNameDiv>{salesmanshipLabels.lbwPpa}</KpiNameDiv>
            </td>
            <td>
              <KpiValueDiv book={dessertPpa === 'loading'}>
                {dessertPpa}
              </KpiValueDiv>
              <KpiNameDiv>{salesmanshipLabels.dessertPpa}</KpiNameDiv>
            </td>
            <td>
              <KpiValueDiv book={grossSalesPerLaborHour === 'loading'}>
                {grossSalesPerLaborHour}
              </KpiValueDiv>
              <KpiNameDiv>{salesmanshipLabels.salesPerLaborHour}</KpiNameDiv>
            </td>
            {isLegacy ? (
              <td>
                <KpiValueDiv book={grossSales === 'loading'}>
                  {grossSales}
                </KpiValueDiv>
                <KpiNameDiv>
                  <div>{salesmanshipLabels.grossSales}</div>
                </KpiNameDiv>
              </td>
            ) : null}
            {isQsr ? (
              <td>
                <KpiValueDiv book={sosAvgTime === 'loading'}>
                  {sosAvgTime}
                </KpiValueDiv>
                <KpiNameDiv>
                  <div>{salesmanshipLabels.speedOfServiceAvg}</div>
                </KpiNameDiv>
              </td>
            ) : null}
          </tr>
        </tbody>
      </KpiTable>

      <VerticalSpacer60px />

      <SectionTitleDiv>SALESMANSHIP CHART</SectionTitleDiv>
      <Chart
        navParams={navParams}
        selectedDateRange={selectedDateRange}
        selectedDirector={selectedDirector}
      />

      <VerticalSpacer60px />

      {salesmanshipConfig['productivityCoefficient']?.isVisible ? (
        <>
          <ProductivityCoefficientChart />
          <VerticalSpacer60px />
        </>
      ) : null}

      <SectionTitleDiv>{breakdownTitle}</SectionTitleDiv>
      <BreakdownTable
        itemizedKpis={itemizedKpis}
        selectedDirector={selectedDirector}
      />

      <VerticalSpacer150px />
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 1200px;
  padding: 0 0 50px 0;
`

const LoadingContainer = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  height: 400px;
  width: 100%;
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const SubtitleContainer = styled.div`
  font-family: Lexend-Regular;
  font-style: italic;
`

const SectionTitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding: 0 0 20px 0;
`

const VerticalSpacer60px = styled.div`
  width: 100%;
  height: 60px;
`

const VerticalSpacer110px = styled.div`
  width: 100%;
  height: 110px;
`

const VerticalSpacer150px = styled.div`
  width: 100%;
  height: 150px;
`

const KpiTable = styled.table`
  width: 1000px;
  border-collapse: collapse;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  th {
    font-family: Lexend-SemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-align: left;
    padding: 5px 8px;
  }

  td {
    text-align: center;
    vertical-align: top;
  }
`

const KpiValueDiv = styled.div<IKpiDiv>`
  width: 100%;
  font-family: ${(props) =>
    props.book ? 'Lexend-Regular' : 'Lexend-SemiBold'};
  font-size: ${(props) => (props.book ? '18px' : '24px')};
  font-style: normal;
  font-weight: ${(props) => (props.book ? '400' : '700')};
  padding: 25px 0 5px 0;
`

const KpiNameDiv = styled.div`
  width: 100%;
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const PageHeaderContainer = styled.div`
  padding: 0 0 50px 0;
`

const LeftHeaderContainer = styled.div`
  float: left;
`

const PageHeaderLineContainer = styled.div`
  display: inline-block;
  width: 1200px;
`

const PageLeftHeader = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Chalkboard};
  padding: 10px 20px 0 0;
  float: left;
`

const SelectorContainer = styled.div`
  float: right;
  display: inline-block;
`

const DirectorFilterSelectorContainer = styled.div`
  float: left;
  margin-right: 7px;
`

export default WebLargeMain
