import {
  Checkbox,
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core'
import styled from 'styled-components'

import MixedChart from 'pared/charts/MixedChart'
import Spin from 'pared/components/basicUi/spin'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import useDiscountItems from './hooks/useDiscountItems'
import useLocationSelect from './hooks/useLocationSelect'
import useTrendDiscount from './hooks/useTrendDiscount'

interface IProps {
  startDate?: string
  endDate?: string
  locationGroupIds?: number[]
}

const Container = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`

const SubHeaderDiscount = styled.div`
  padding: 20px 0;
  display: flex;
  width: 1100px;
  justify-content: space-between;
  align-items: center;

  @media ${MOBILE_WIDTH} {
    display: block;
    width: 100%;
    margin: 60px 0px 0px;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
`

const LocationType = styled.div`
  display: flex;
  gap: 10px;

  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const LocationFormControl = styled(FormControl)`
  background-color: white;
  width: 220px;
`

const LocationSelect = styled(Select)`
  border-radius: 0;
  background-color: white;
`

const ItemCheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: #07a4c7;
  }
`

export default function WeeklyChart({
  startDate,
  endDate,
  locationGroupIds,
}: IProps) {
  const { selectedLocation, locationChanged, locations } = useLocationSelect()

  const { selectedDiscounts, discountsChanged, discountItems } =
    useDiscountItems(selectedLocation, locationGroupIds)

  const isDiscountsAllSelected =
    discountItems.length > 0 &&
    selectedDiscounts.length === discountItems.length

  const { trendDiscountsDataLoading, trendChartData, chartOptions } =
    useTrendDiscount(
      selectedLocation,
      selectedDiscounts,
      locationGroupIds,
      startDate,
      endDate,
    )

  return (
    <Container>
      <SubHeaderDiscount>
        <h1>Breakdown by Day of Week</h1>

        <LocationType>
          <h3>Store:</h3>
          <LocationFormControl variant="outlined" margin="dense">
            <LocationSelect
              value={selectedLocation}
              onChange={(e) => {
                locationChanged(e.target.value as number)
              }}
              displayEmpty
            >
              {locations?.map((item: any) => {
                return (
                  <MenuItem key={item.key} value={item.key}>
                    {item.title}
                  </MenuItem>
                )
              })}
            </LocationSelect>
          </LocationFormControl>
          <h3>Discount Codes:</h3>
          <LocationFormControl variant="outlined" margin="dense">
            <LocationSelect
              multiple
              value={selectedDiscounts}
              onChange={(e) => {
                const value = e.target.value as string[]
                if (value[value.length - 1] === 'all') {
                  discountsChanged(
                    selectedDiscounts.length === discountItems.length
                      ? []
                      : discountItems,
                  )
                  return
                }
                discountsChanged(value)
              }}
              renderValue={(selected) => (selected as string[]).join(', ')}
              displayEmpty
            >
              <MenuItem value="all">
                <ListItemIcon>
                  <ItemCheckbox
                    checked={isDiscountsAllSelected}
                    indeterminate={
                      selectedDiscounts.length > 0 &&
                      selectedDiscounts.length < discountItems.length
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="Select/Unselect All" />
              </MenuItem>
              {discountItems.map((option) => (
                <MenuItem key={option} value={option}>
                  <ListItemIcon>
                    <ItemCheckbox
                      checked={selectedDiscounts.indexOf(option) > -1}
                    />
                  </ListItemIcon>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </LocationSelect>
          </LocationFormControl>
        </LocationType>
      </SubHeaderDiscount>

      <Spin spinning={trendDiscountsDataLoading}>
        <MixedChart
          xAxisData={trendChartData?.xAxisData}
          yAxisDataArr={trendChartData?.yAxisDataArr}
          options={chartOptions}
        />
      </Spin>
    </Container>
  )
}
