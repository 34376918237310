import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { INullableType } from 'pared/types'

import {
  DISCOUNTS,
  IDiscountsNodeType,
  IDiscountsType,
  IDiscountsVariablesType,
} from '../gqls'

export interface IDiscountsDataType extends IDiscountsNodeType {
  rank: number
  opsCompsPercentColor: string
}

const percentageToColor = (percentage: number, maxHue = 120, minHue = 0) => {
  const hue = percentage * (maxHue - minHue) + minHue
  return `hsl(${hue}, 80%, 80%)`
}

export default (
  variables: INullableType<IDiscountsVariablesType>,
): IDiscountsDataType[] => {
  const { data } = useQuery<
    IDiscountsType,
    INullableType<IDiscountsVariablesType>
  >(DISCOUNTS, {
    variables,
    skip: !variables?.iFilter || !variables?.iStartDate || !variables?.iEndDate,
  })

  return useMemo(() => {
    const discounts = data?.listLocationEmployeeOpsComps.nodes || []
    const totalDiscountsPercent = discounts.filter((d) =>
      Boolean(d.opsCompsPercent),
    )

    return discounts.map((node, index) => ({
      ...node,
      rank: index + 1,
      opsCompsPercentColor: !node.opsCompsPercent
        ? 'initial'
        : percentageToColor(index / totalDiscountsPercent.length),
    }))
  }, [data])
}
