import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import useBrands from 'pared/layouts/hooks/useBrands'
import { getUser } from 'pared/utils/user'

import { useAdvancedFilter } from '../../advancedFilter'
import { IApiDataType } from '../types'

interface ISubordinateType {
  externalId: string
  supervisorExternalId?: string
}

const queries = gql`
  query ListDirectorTrainingTracker($iEmployeeId: Int!) {
    listDirectorTrainingTracker(iEmployeeId: $iEmployeeId) {
      nodes {
        userExternalId
        traineeId
        trainingTrackerUuid
        traineeFirstName
        traineeLastName
        traineeLocationCode
        traineeJobTitle
        trainerExternalId
        trainerSupervisorExternalId
        assignedTracker
        completedDate
        assignedDate
        approvedDate
        needApproved
        currentTraineeLeader {
          externalId
          supervisorExternalId
        }
      }
    }

    listOrgDirectors {
      nodes {
        externalId
        supervisorExternalId
      }
    }

    employee(id: $iEmployeeId) {
      id
      jobTitle
      locationId
    }
  }
`

export const jscCorporateTrainingTrackerConfigs = {
  trainingTrackerUuid: 'string',
  store: 'string',
  traineeName: 'link',
  traineeJobTitle: 'string',
  assignedTracker: 'toggle',
  completedDate: 'date-string',
  approvedDate: 'link',
} as const

const findSubordinate = (
  root: string,
  subordinate: ISubordinateType,
  data: ISubordinateType[],
): boolean => {
  if (
    root === 'internal test' ||
    subordinate.externalId === root ||
    subordinate.supervisorExternalId === root
  )
    return true

  const parent = data.find(
    (d) => d.externalId === subordinate.supervisorExternalId,
  )

  if (!parent || !parent.supervisorExternalId) {
    return false
  }

  return findSubordinate(root, parent, data)
}

const useJscCorporateTrainingTracker = () => {
  const { brand } = useBrands()
  const user = getUser()
  const userId = user.employeeId ? user.employeeId : -1
  const { data, loading } = useQuery(queries, {
    variables: {
      iEmployeeId: userId,
    },
    skip: !user,
  })
  const advancedFilter = useAdvancedFilter('select')

  return {
    data: useMemo((): IApiDataType => {
      const userExternalId =
        data?.listDirectorTrainingTracker.nodes.length > 0
          ? data?.listDirectorTrainingTracker.nodes[0].userExternalId
          : null

      const subordinateList = userExternalId
        ? data?.listOrgDirectors.nodes.filter((subordinate: ISubordinateType) =>
            findSubordinate(
              userExternalId,
              subordinate,
              data?.listOrgDirectors.nodes,
            ),
          )
        : null

      const trackers = data?.listDirectorTrainingTracker.nodes.filter(
        (tracker: any) => {
          const trainer = tracker.currentTraineeLeader
            ? {
                externalId: tracker.currentTraineeLeader.externalId,
                supervisorExternalId:
                  tracker.currentTraineeLeader.supervisorExternalId,
              }
            : {
                externalId: tracker.trainerExternalId,
                supervisorExternalId: tracker.trainerSupervisorExternalId,
              }

          return (
            subordinateList.some(
              (subordinate: ISubordinateType) =>
                subordinate.externalId === trainer.externalId ||
                subordinate.externalId === trainer.supervisorExternalId,
            ) ||
            trainer.externalId === 'internal test' ||
            data?.employee.jobTitle === 'Restaurant Leader'
          )
        },
      )

      const filterTrainingTracker = advancedFilter?.value.trainingTracker

      const now = moment()
      const filteredTrackers = (() => {
        switch (filterTrainingTracker) {
          case 'Needs Approval':
            return trackers?.filter(
              (tracker: any) =>
                !tracker.assignedDate ||
                tracker.needApproved ||
                (tracker.assignedTracker === 'Crew Training' &&
                  tracker.completedDate &&
                  !tracker.approvedDate),
            )

          case 'Approved - Last 30 days':
            return trackers?.filter(
              (tracker: any) =>
                tracker.approvedDate &&
                now.diff(moment.utc(tracker.approvedDate), 'days') <= 30,
            )

          case 'Approved - Last 60 days':
            return trackers?.filter(
              (tracker: any) =>
                tracker.approvedDate &&
                now.diff(moment.utc(tracker.approvedDate), 'days') <= 60,
            )

          case 'Approved - Last 90 days':
            return trackers?.filter(
              (tracker: any) =>
                tracker.approvedDate &&
                now.diff(moment.utc(tracker.approvedDate), 'days') <= 90,
            )

          case 'Approved - ALL':
            return trackers?.filter((tracker: any) => tracker.approvedDate)
        }
      })()

      if (!filteredTrackers) return null

      return {
        source: filteredTrackers.map((tracker: any) => {
          const employeeName = `${tracker.traineeFirstName} ${tracker.traineeLastName}`
          const employeeUrl = `/${brand}/training_tracker_profile/${tracker.traineeId}`
          const trackerUrl = `/${brand}/training_tracker_detail/${tracker.trainingTrackerUuid}`
          const completedAt = tracker.completedDate
            ? moment(tracker.completedDate).format('M/D/YY')
            : ' '
          const approvedAt = tracker.approvedDate
            ? moment(tracker.approvedDate).format('M/D/YY')
            : ''

          const neededAssign = !!tracker.assignedDate
          const neededAssignText = neededAssign
            ? tracker.assignedTracker
            : 'Approve Restaurant Leader'
          const neededApprove = !(
            !!tracker.completedDate && !tracker.approvedDate
          )

          return {
            trainingTrackerUuid: tracker.trainingTrackerUuid,
            store: tracker.traineeLocationCode,
            traineeName: {
              label: employeeName,
              url: employeeUrl,
            },
            traineeJobTitle: tracker.traineeJobTitle,
            assignedTracker: {
              checked: neededAssign,
              label: neededAssignText,
            },
            completedDate: completedAt,
            approvedDate: neededApprove
              ? {
                  label: approvedAt,
                  disabled: true,
                }
              : {
                  label: 'Needs Approval',
                  url: trackerUrl,
                },
          }
        }),
      }
    }, [data, advancedFilter]),
    loading,
  }
}

export default useJscCorporateTrainingTracker
