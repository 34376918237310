import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import ListItemButton from '@mui/material/ListItemButton'
import { useMemo, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import { MOBILE_WIDTH } from 'pared/constants/styles'

import Notification from './Notification'
import useBrands from './hooks/useBrands'
import { IRouteType } from './hooks/useRoutes'

export interface IPropsType {
  route: IRouteType
  onClose: () => void
  navParams: INavParams
  first?: boolean
}

const StyledListItemButton = styled(ListItemButton)<{ collapsible: boolean }>`
  padding-left: 0px;

  pointer-events: ${({ collapsible }) => (collapsible ? 'auto' : 'none')};

  &:hover {
    background-color: ${({ collapsible }) =>
      collapsible ? undefined : 'inherit'};
  }
`

const StyleListItemTextCategory = styled(ListItemText)`
  span {
    font-family: Lexend-SemiBold;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }
`

const StyleListItemText = styled(ListItemText)`
  padding: 8px 0px;
  cursor: pointer;
  span {
    font-family: Lexend-Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  :hover {
    text-decoration: underline;
  }
`

const StyleNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  cursor: inherit;
  position: relative;

  :hover {
    text-decoration: inherit;
  }

  svg {
    position: absolute;
    height: 15px;
    left: -30px;
    visibility: hidden;
  }

  &.active svg {
    visibility: visible;
  }
`

const StyledList = styled(List)`
  padding-left: 25px;
`

const RouteList = ({ route, onClose, navParams, first }: IPropsType) => {
  const [open, setOpen] = useState(true)
  const handleClick = () => {
    setOpen(!open)
  }
  const { brand } = useBrands()
  const location = useLocation()
  const matches = useMediaQuery(MOBILE_WIDTH)
  const queryString = useMemo(() => {
    const paramsString = location.search
    const searchParams = new URLSearchParams(paramsString)

    if (
      'type' in route &&
      route.type === 'store' &&
      !searchParams.has('store')
    ) {
      searchParams.append('store', navParams.userDefaultStoreId as string)
    }

    return `?${searchParams.toString()}`
  }, [route, navParams, location])

  const collapsible = 'collapsible' in route ? route.collapsible : false

  if ('children' in route)
    return route.hideFromNavMenu ? null : (
      <>
        <StyledListItemButton
          onClick={collapsible ? handleClick : undefined}
          collapsible
        >
          <StyleListItemTextCategory>{route.title}</StyleListItemTextCategory>
        </StyledListItemButton>

        <Collapse in={open}>
          <StyledList>
            {route.children.map((subRoute) => (
              <RouteList
                key={JSON.stringify(subRoute)}
                route={subRoute}
                onClose={onClose}
                navParams={navParams}
              />
            ))}
          </StyledList>
        </Collapse>
      </>
    )

  return route.hideFromNavMenu ? null : (
    <StyleListItemText key={route.title}>
      <StyleNavLink
        to={route.link.replace(':brand', brand).concat(queryString)}
        onClick={matches ? onClose : undefined}
      >
        {first ? null : <PlayArrowIcon />}

        <div>{route.title}</div>
        <Notification api={route.notification} />
      </StyleNavLink>
    </StyleListItemText>
  )
}

export default RouteList
