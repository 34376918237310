import React from 'react'
import styled from 'styled-components'

import CustomizedReport from 'pared/components/CustomizedReport'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'

interface IProps {
  locationId: number
  startDate: string | null
  endDate: string | null
}

const Container = styled.div`
  padding: 60px 0px;

  @media ${MOBILE_WIDTH} {
    padding: 20px 0px;
  }
`

const TitleDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 20px;

  @media ${MOBILE_WIDTH} {
    font-size: 16px;
  }
`

const PageStatusDiv = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};

  @media ${MOBILE_WIDTH} {
    padding: 10px;
  }
`

const SmgModTable = ({ locationId, startDate, endDate }: IProps) => {
  if (!locationId || !startDate || !endDate) {
    return <PageStatusDiv>Loading ...</PageStatusDiv>
  }

  return (
    <Container>
      <TitleDiv>Breakdown by Manager on Duty</TitleDiv>
      <CustomizedReport
        reportName={'LOCATION_SMG_MOD_TABLE'}
        inputParams={{
          startDate,
          endDate,
          i_filter: {
            location_ids: [locationId],
          },
        }}
      />
    </Container>
  )
}

export default React.memo(SmgModTable)
