import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import styled from 'styled-components'

import { MOBILE_WIDTH } from 'pared/components/basicUi/mobile'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import COLORS from 'pared/constants/colors'
import { getBrand } from 'pared/utils/brand'
import { getLocationName } from 'pared/utils/location'
import { toPercentString } from 'pared/utils/number'

import {
  GET_CORPORATE_TEAM_SUMMARY,
  ISummaryType,
  ISummaryVariablesType,
} from './gql'

interface IPropsType {
  locationId: number
}

const PageStatus = styled.div`
  font-family: Lexend-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: ${COLORS.Steel};
`

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 1000px;

  @media ${MOBILE_WIDTH} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

const ConclusionDiv = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  color: ${COLORS.Salt};
  padding: 30px;

  border-radius: 10px 10px 0 0;
  background: #0071e3;

  @media ${MOBILE_WIDTH} {
    flex: 1;
  }
`

const InsightDiv = styled.div`
  padding: 30px;
  font-family: Lexend-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: ${COLORS.Chalkboard};

  border-radius: 0 0 10px 10px;
  border: 1px solid #81abfd;

  @media ${MOBILE_WIDTH} {
    flex: 1;
  }
`

const InsightTitle = styled.div`
  font-family: Lexend-SemiBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 0 0 15px 0;
`

const ListItem = styled.li`
  margin-left: 20px;
`

const Summary = ({ locationId }: IPropsType) => {
  const locationGroupId = BRAND_LOCATION_GROUP_ID[getBrand()]
  const { data, loading } = useQuery<ISummaryType, ISummaryVariablesType>(
    GET_CORPORATE_TEAM_SUMMARY,
    {
      variables: {
        iLocationGroupId: locationGroupId,
      },
    },
  )

  const summary = data?.corporateTeamSummary.nodes.find(
    ({ locationInfo }) => locationInfo.id === locationId,
  )

  const summaryMessage = useMemo(() => {
    if (!summary) return null

    const comparison =
      summary.annualizedHourlyTurnoverPercent >
      summary.corporateCurrentAnnualizedHourlyTurnoverPercent
        ? 'above'
        : 'below'
    const locationName = getLocationName(locationId, summary.locationInfo.name)

    return (
      <>
        {locationName} is currently {comparison} Systemwide Averages for Hourly
        Turnover.
      </>
    )
  }, [summary])

  const improvementMessage = useMemo(() => {
    if (!summary) return null
    if (
      !summary.thirtyDayTurnoverRate &&
      !summary.sixtyDayTurnoverRate &&
      !summary.ninetyDayTurnoverRate
    ) {
      return 'Store is performing at company standards for turnover.'
    }

    const locationName = getLocationName(locationId, summary.locationInfo.name)

    return (
      <>
        <div>{locationName} Loses</div>
        {summary.thirtyDayTurnoverRate ? (
          <ListItem>
            {toPercentString(summary.thirtyDayTurnoverRate)} of employees within
            30 days of hire
          </ListItem>
        ) : null}
        {summary.sixtyDayTurnoverRate ? (
          <ListItem>
            {toPercentString(summary.sixtyDayTurnoverRate)} of employees within
            60 days of hire
          </ListItem>
        ) : null}
        {summary.ninetyDayTurnoverRate ? (
          <ListItem>
            {toPercentString(summary.ninetyDayTurnoverRate)} of employees within
            90 days of hire
          </ListItem>
        ) : null}
      </>
    )
  }, [summary])

  if (loading) return <PageStatus>Loading ...</PageStatus>

  if (!summary) return null

  return (
    <SummaryContainer>
      <ConclusionDiv>{summaryMessage}</ConclusionDiv>
      <InsightDiv>
        <InsightTitle>EXPO INSIGHTS</InsightTitle>
        {improvementMessage}
      </InsightDiv>
    </SummaryContainer>
  )
}

export default Summary
