import { IConfigsType } from '../../types'

const aceDailyFlash: IConfigsType = {
  ace_pop: {
    '/:brand/daily_flash': {
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: ['yesterday', 'custom'],
        defaultType: 'yesterday',
        isDailyCustom: true,
      },
      dailyFlashTitle: {
        type: 'title',
        title: 'Daily Flash Report',
      },
      downloadAllTables: {
        type: 'download-csv',
        api: 'acePopDailyFlash',
        fileName: 'getexpo-ace-pop-daily-flash',
        fileNameFn: (date: string) => `getexpo-ace-pop-daily-flash-${date}`,
        message: 'Download as CSV',
        fields: [
          'groupInfo',
          {
            key: 'netSales',
            title: 'Net Sales',
          },
          {
            key: 'yoyNetSalesPercentage',
            title: 'Net Sales +/- LY %',
          },
          {
            key: 'cashDeposits',
            title: 'Cash Deposits',
          },
          {
            key: 'cashOs',
            title: 'Cash +/-',
          },
          {
            key: 'overringAmount',
            title: 'Overrings',
          },
          {
            key: 'deletesAmount',
            title: 'Deletes',
          },
          {
            key: 'deletesAmountPercentage',
            title: 'Deletes %',
          },
          {
            key: 'maxDeleteBy',
            title: 'Max Deletes by',
          },
          {
            key: 'maxDeleteAmount',
            title: 'Deletes Amount',
          },
        ],
      },
      dailyFlashTable: {
        type: 'temp-table',
        api: 'acePopDailyFlash',
        pageSize: 30,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '170px',
          },
        },
        columns: [
          {
            key: 'netSales',
            title: 'Net Sales',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'yoyNetSalesPercentage',
            title: 'Net Sales +/- LY %',
            align: 'center',
            sortable: true,
          },
          {
            key: 'cashDeposits',
            title: 'Cash Deposits',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'cashOs',
            title: 'Cash +/-',
            align: 'center',
            sortable: true,
          },
          {
            key: 'overringAmount',
            title: 'Overrings',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'deletesAmount',
            title: 'Deletes',
            align: 'center',
            sortable: true,
          },
          {
            key: 'deletesAmountPercentage',
            title: 'Deletes %',
            align: 'center',
            highlight: true,
            sortable: true,
          },
          {
            key: 'maxDeleteBy',
            title: 'Max Deletes by',
            align: 'center',
          },
          {
            key: 'maxDeleteAmount',
            title: 'Delete Amount',
            align: 'center',
            highlight: true,
            sortable: true,
          },
        ],
      },
      dailyFlashAverageTable: {
        type: 'table',
        api: 'acePopDailyFlashAverage',
        title: 'Daily Average Sales Table',
        pageSize: 10,
        columns: [
          {
            key: 'name',
            title: ' ',
            align: 'center',
            width: '100px',
          },
          {
            key: 'netSales',
            title: 'Net Sales',
            align: 'center',
            highlight: true,
          },
          {
            key: 'cashDeposits',
            title: 'Cash Deposits',
            align: 'center',
          },
          {
            key: 'cashOs',
            title: 'Cash +/-',
            align: 'center',
            highlight: true,
          },
          {
            key: 'overringAmount',
            title: 'Overrings',
            align: 'center',
          },
          {
            key: 'deletesAmount',
            title: 'Deletes',
            align: 'center',
            highlight: true,
          },
        ],
      },
    },
  },
}

export default aceDailyFlash
