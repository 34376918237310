import _ from 'lodash'
import styled from 'styled-components'

import FullWidthTable from 'pared/components/FullWidthTable'
import COLORS from 'pared/constants/colors'
import { getBrandSettings } from 'pared/customer'
import {
  IOpenPositionsTd,
  IOverviewTableTh,
} from 'pared/pages/MyDashboard/OpsCompTable/Main'
import { toUsdString } from 'pared/utils/number'

interface IProps {
  tableData: object
}

const Main = ({ tableData }: IProps) => {
  const brandSettings = getBrandSettings()
  const financialLabel = brandSettings.labels.financial

  const roleKeys = _.keys(_.get(tableData, 'role', []))
  const sortedRoleKeys = _.sortBy(roleKeys, (k) => {
    return k
  })

  const roleRows = _.map(sortedRoleKeys, (k) => {
    const hourlyLaborActual = _.get(
      tableData,
      ['role', k, 'hourly_labor_actual'],
      0,
    )
    const hourlyLaborBudget = _.get(
      tableData,
      ['role', k, 'hourly_labor_budget'],
      0,
    )
    const overUnder = hourlyLaborBudget - hourlyLaborActual

    return (
      <tr>
        <OverviewTableTd bold>{k}</OverviewTableTd>
        <OverviewTableTd textAlign="right">
          {toUsdString(hourlyLaborActual / 100.0)}
        </OverviewTableTd>
        <OverviewTableTd textAlign="right">
          {toUsdString(hourlyLaborBudget / 100.0)}
        </OverviewTableTd>
        <OverviewTableTd textAlign="right" danger={overUnder < 0}>
          {toUsdString(overUnder / 100.0)}
        </OverviewTableTd>
      </tr>
    )
  })

  const fohHourlyLaborActual = _.get(
    tableData,
    'agg.FOH.hourly_labor_actual',
    0,
  )
  const fohHourlyLaborBudget = _.get(
    tableData,
    'agg.FOH.hourly_labor_budget',
    0,
  )
  const fohOverUnder = fohHourlyLaborBudget - fohHourlyLaborActual

  const bohHourlyLaborActual = _.get(
    tableData,
    'agg.BOH.hourly_labor_actual',
    0,
  )
  const bohHourlyLaborBudget = _.get(
    tableData,
    'agg.BOH.hourly_labor_budget',
    0,
  )
  const bohOverUnder = bohHourlyLaborBudget - bohHourlyLaborActual

  const otherHourlyLaborActual = _.get(
    tableData,
    'agg.OTHER.hourly_labor_actual',
    0,
  )
  const otherHourlyLaborBudget = _.get(
    tableData,
    'agg.OTHER.hourly_labor_budget',
    0,
  )
  const otherOverUnder = otherHourlyLaborBudget - otherHourlyLaborActual

  return (
    <FullWidthTable>
      <thead>
        <tr>
          <OverviewTableTh></OverviewTableTh>
          <OverviewTableTh bold textAlign="right">
            {financialLabel.hourlyLabor} Actual
          </OverviewTableTh>
          <OverviewTableTh bold textAlign="right">
            {financialLabel.hourlyLabor} Budget
          </OverviewTableTh>
          <VsBudgetTh bold textAlign="right">
            vs Budget
          </VsBudgetTh>
        </tr>
      </thead>
      <tbody>
        {roleRows}
        <SubTotalTr>
          <OverviewTableTd bold>
            <SubtitleContainer>Front of House</SubtitleContainer>
          </OverviewTableTd>
          <OverviewTableTd textAlign="right">
            <SubtitleContainer>
              {toUsdString(fohHourlyLaborActual / 100.0)}
            </SubtitleContainer>
          </OverviewTableTd>
          <OverviewTableTd textAlign="right">
            <SubtitleContainer>
              {toUsdString(fohHourlyLaborBudget / 100.0)}
            </SubtitleContainer>
          </OverviewTableTd>
          <OverviewTableTd textAlign="right" danger={fohOverUnder < 0}>
            <SubtitleContainer>
              {toUsdString(fohOverUnder / 100.0)}
            </SubtitleContainer>
          </OverviewTableTd>
        </SubTotalTr>
        <tr>
          <OverviewTableTd bold>Back of House</OverviewTableTd>
          <OverviewTableTd textAlign="right">
            {toUsdString(bohHourlyLaborActual / 100.0)}
          </OverviewTableTd>
          <OverviewTableTd textAlign="right">
            {toUsdString(bohHourlyLaborBudget / 100.0)}
          </OverviewTableTd>
          <OverviewTableTd textAlign="right" danger={bohOverUnder < 0}>
            {toUsdString(bohOverUnder / 100.0)}
          </OverviewTableTd>
        </tr>
        <tr>
          <OverviewTableTd bold>Other</OverviewTableTd>
          <OverviewTableTd textAlign="right">
            {toUsdString(otherHourlyLaborActual / 100.0)}
          </OverviewTableTd>
          <OverviewTableTd textAlign="right">
            {toUsdString(otherHourlyLaborBudget / 100.0)}
          </OverviewTableTd>
          <OverviewTableTd textAlign="right" danger={otherOverUnder < 0}>
            {toUsdString(otherOverUnder / 100.0)}
          </OverviewTableTd>
        </tr>
        <SubTotalTr>
          <OverviewTableTd bold>
            <SubtitleContainer>Total Overtime</SubtitleContainer>
          </OverviewTableTd>
          <OverviewTableTd></OverviewTableTd>
          <OverviewTableTd></OverviewTableTd>
          <OverviewTableTd textAlign="right">
            <SubtitleContainer>
              {toUsdString(_.get(tableData, 'overtime', 0) / 100.0)}
            </SubtitleContainer>
          </OverviewTableTd>
        </SubTotalTr>
      </tbody>
    </FullWidthTable>
  )
}

const SubTotalTr = styled.tr`
  border-top: 1px solid ${COLORS.Chalkboard};
`

const OverviewTableTh = styled.th<IOverviewTableTh>`
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: ${(props) => (props.largerFont ? '20px' : '14px')};
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'right' ? 'right' : 'left')};
`

const VsBudgetTh = styled(OverviewTableTh)`
  width: 140px;
`

const OverviewTableTd = styled.td<IOpenPositionsTd>`
  font-family: Lexend-Regular;
  font-size: ${(props) => (props.bold ? '14px' : '18px')};
  font-style: normal;
  font-weight: ${(props) => (props.bold ? 600 : 400)};
  text-align: ${(props) => (props.textAlign === 'right' ? 'right' : 'left')};
  color: ${(props) =>
    props.danger ? COLORS.Pomodoro : props.success ? COLORS.Expo : 'black'};
`

const SubtitleContainer = styled.div`
  padding-top: 10px;
`

export default Main
