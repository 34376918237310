import { gql } from '@apollo/client'

export interface IGetLocationKpiTrendNode {
  value: number | null
  intermediateValues: {
    hourly_labor_budget_variance_amount: number | null
    hourly_labor_cost: number | null
    hourly_labor_cost_budget: number | null
    hourly_labor_percent: number | null
    hourly_labor_percent_budget: number | null
  }
  groupingIndex: number
}

export interface IGetLocationKpiTrend {
  getLocationKpiTrend: {
    nodes: IGetLocationKpiTrendNode[]
  }
}

export interface IGetLocationKpiTrendVariables {
  iLocationId: number
  iGroupBy: string
  iEndDate: string
}

export const GET_LOCATION_KPI_TREND = gql`
  query getLocationKpiTrend(
    $iLocationId: Int!
    $iGroupBy: String!
    $iEndDate: Date!
  ) {
    getLocationKpiTrend(
      iKpi: "hourly_labor_budget_variance"
      iLocationId: $iLocationId
      iGroupBy: $iGroupBy
      iEndDate: $iEndDate
    ) {
      nodes {
        value
        intermediateValues
        groupingIndex
      }
    }
  }
`
