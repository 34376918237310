import { useMemo } from 'react'

import { toPercentString, toUsdString } from 'pared/utils/number'

import { useVariables } from '../../../variables'
import type { IApiDataType } from '../../types'
import useFetchGroupMetricValue from './useFetchGroupMetricValue'

const useBbbCorpLabor = (): IApiDataType => {
  const { variables } = useVariables()
  const { data, loading } = useFetchGroupMetricValue([
    'total_labor',
    'total_labor_budget',
    'labor_cost_percentage',
    'labor_budget_percent',
  ])

  return {
    data: useMemo(() => {
      if (!data) return null

      const currentPeriod = variables.date?.getInfo(0)
      const labor = data.total_labor ?? 0
      const laborBudget = data.total_labor_budget ?? 0
      const laborPct = data.labor_cost_percentage ?? 0
      const laborBudgetPct = data.labor_budget_percent ?? 0
      const priorLaborPct = data.prior_labor_cost_percentage ?? 0
      const yoyLaborPct = data.yoy_labor_cost_percentage ?? 0
      const laborVarPct = laborPct - laborBudgetPct

      // ADD TEXT HERE START

      const laborVarPctText =
        laborVarPct >= 0
          ? `Higher than the ${toPercentString(
              laborBudgetPct,
            )} budget by ${toPercentString(laborVarPct)}`
          : `Lower than the ${toPercentString(
              laborBudgetPct,
            )} budget by ${toPercentString(-laborVarPct)}`

      const laborVarPctPeriodChgText =
        priorLaborPct === 0
          ? `No Labor data from Prior Period for comparison`
          : laborPct >= priorLaborPct
          ? `Increasing ${toPercentString(
              laborPct - priorLaborPct,
            )} from Prior Period of ${toPercentString(priorLaborPct)}`
          : `Decreasing ${toPercentString(
              priorLaborPct - laborPct,
            )} from Prior Period of ${toPercentString(priorLaborPct)}`

      const laborVarPctYoyChgText =
        yoyLaborPct === 0
          ? `No Labor data from Prior Year for comparison`
          : laborPct >= yoyLaborPct
          ? `Increasing ${toPercentString(
              laborPct - yoyLaborPct,
            )} from Prior Year of ${toPercentString(yoyLaborPct)}`
          : `Decreasing ${toPercentString(
              yoyLaborPct - laborPct,
            )} from Prior Year of ${toPercentString(yoyLaborPct)}`

      // ADD TEXT HERE END

      return {
        title: `Labor`,
        total: laborVarPct,
        summary: `Labor Cost in ${
          currentPeriod?.displayName
        } was ${toPercentString(laborPct)}`,
        detail: `
          <ul>
            <li>${laborVarPctText} or ${toUsdString(
          Math.abs(labor - laborBudget),
        )}</li>
            <li>${laborVarPctPeriodChgText}</li>
            <li>${laborVarPctYoyChgText}</li>
          </ul>`,
        hasDetails: true,
      }
    }, [data, variables]),
    loading,
  }
}

export default useBbbCorpLabor
