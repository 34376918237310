import DeleteIcon from '@mui/icons-material/Delete'
import { useState } from 'react'
import styled from 'styled-components'

import DeletePlanModal, {
  IPropsType as IDeletePlanModalType,
} from './deletePlanModal'

export interface IPropsType extends Omit<IDeletePlanModalType, 'onClose'> {}

const StyledDeleteIcon = styled(DeleteIcon)`
  cursor: pointer;
`

const DeleteDevelopmentPlan = ({ ...props }: IPropsType) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <StyledDeleteIcon onClick={() => setOpen(true)} />
      {open && <DeletePlanModal {...props} onClose={() => setOpen(false)} />}
    </>
  )
}

export default DeleteDevelopmentPlan
