import { gql } from '@apollo/client'

export interface IDailyInventoryAnalysisNodeType {
  locationId: number
  date: string
  aggregatableData: {
    item: string
    ending_qty: number
    counted: boolean
  }[]
}

export interface IDailyInventoryAnalysisType {
  listLocationDailyInventoryAnalysis: {
    nodes: IDailyInventoryAnalysisNodeType[]
  }
}

export interface IDailyInventoryAnalysisVariablesType {
  iStartDate: string
  iEndDate: string
  iFilter: {
    location_ids: number[]
  }
}

export const LIST_LOCATION_DAILY_INVENTORY_ANALYSIS = gql`
  query ListLocationDailyInventoryAnalysis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationDailyInventoryAnalysis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        date
        aggregatableData
      }
    }
  }
`
