import _ from 'lodash'
import { useEffect, useState } from 'react'

import { INavParams } from 'pared/Routes/navParams'
import { feature, page } from 'pared/analytics/user'
import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import getDateRanges, { IDateRange } from 'pared/data/getDateRanges'
import useGetDirectors from 'pared/hooks/useGetDirectors'
import { getBrand } from 'pared/utils/brand'
import { scrollToTop } from 'pared/utils/web'

import Main from './Main'
import useDisplayData from './hooks/useDisplayData'

interface IProps {
  navParams: INavParams
}

function CorporateSalemanship({ navParams }: IProps) {
  const dateRange = navParams.dateRange || ''

  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange | null>(
    null,
  )

  const {
    director: selectedDirector,
    directors: allDirectors,
    directorChange: onDirectorChange,
    locationGroupTypes,
  } = useGetDirectors()

  const brand = getBrand()

  const brandLocationGroupId = BRAND_LOCATION_GROUP_ID[brand]
  let selectedDirectorLocationGroupId =
    _.get(selectedDirector, 'locationGroupId') || -1
  selectedDirectorLocationGroupId =
    selectedDirectorLocationGroupId < 0
      ? brandLocationGroupId
      : selectedDirectorLocationGroupId

  const displayData = useDisplayData(
    selectedDirectorLocationGroupId,
    selectedDateRange?.startDateStr || '',
    selectedDateRange?.endDateStr || '',
  )

  let locationGroupIds: number[] | null = []
  const selectedDirctorEmployeeId = _.get(selectedDirector, 'employeeId', -1)
  if (selectedDirctorEmployeeId === -2) {
    // breakdownByDirectors
    locationGroupIds = _.map(allDirectors, (doData) => {
      return doData.locationGroupId
    })
  } else if (selectedDirctorEmployeeId < -2) {
    locationGroupIds = null
  } else {
    locationGroupIds = [selectedDirectorLocationGroupId]
  }

  useEffect(() => {
    async function fetchData() {
      const newAllDateRangeData = await getDateRanges()

      let newSelectedDateRange: IDateRange | null = null
      if (dateRange) {
        newSelectedDateRange = newAllDateRangeData.dateRangeMap[dateRange]
      }

      if (!newSelectedDateRange) {
        newSelectedDateRange = newAllDateRangeData.defaultPeriod
      }

      if (newSelectedDateRange) {
        setSelectedDateRange(newSelectedDateRange)
      }
    }

    fetchData()
  }, [selectedDirector, dateRange, brand])

  useEffect(() => {
    if (navParams.pageUrl) {
      scrollToTop()
      page.visit(navParams.pageUrl)
      feature.used('Corporate Salesmanship')
    }
  }, [navParams.pageUrl])

  return (
    <>
      <Main
        navParams={navParams}
        allDirectors={allDirectors || []}
        selectedDirector={selectedDirector}
        onDirectorChange={onDirectorChange}
        selectedDateRange={selectedDateRange}
        locationGroupIds={locationGroupIds}
        locationGroupTypes={locationGroupTypes}
        selectedLocationGroupId={selectedDirectorLocationGroupId}
        displayData={displayData}
      />
    </>
  )
}

export default CorporateSalemanship
