import { useMemo } from 'react'

import { getBrand } from 'pared/utils/brand'

export default () => {
  const brand = getBrand()

  return useMemo(() => {
    switch (brand) {
      case 'lfr':
        return { breakdownByBrand: true }

      default:
        return { breakdownByBrand: false }
    }
  }, [brand])
}
