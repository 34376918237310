const formatPrice = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const getPrice = (value: number) => formatPrice.format(value / 100)

export const getTotalPrice = (value: number) =>
  formatPrice.format(Math.round(value / 100)).replace(/\..+/, '')

export const getPriceOrDash = (value: number | string) => {
  const valueNum = typeof value === 'number' ? value : parseFloat(value)
  if (isNaN(valueNum)) {
    return '-'
  }
  return formatPrice.format(valueNum)
}
