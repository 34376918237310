import { useMemo } from 'react'

import { IValueType } from 'pared/components/basicUi/Menu/MenuItem'
import { getBrandSettings } from 'pared/customer'

import useConfig from '../hooks/useConfig'

export const SECTION = 'SECTION'
export const GROUP = 'GROUP'

export interface IItem {
  parentId: string
  text: string
  id?: string
  type?: typeof SECTION | typeof GROUP
}

export default function useQuestionMenuDataSource() {
  const chatBoxConfig = useConfig()?.chatBox
  const { director } = getBrandSettings().labels.business
  return useMemo(() => {
    const plv = chatBoxConfig?.plvDisplayString || 'PLV'

    const items: IItem[] = [
      // Section: Sales
      {
        id: 'section-Sales',
        parentId: 'root',
        text: 'Sales',
        type: SECTION,
      },

      // Same Store Sales
      {
        id: 'group-sss',
        parentId: 'root',
        text: 'Same Store Sales',
        type: GROUP,
      },
      {
        parentId: 'group-sss',
        text: 'What is SSS this year?',
      },
      {
        parentId: 'group-sss',
        text: `Which ${director} has the best SSS?`,
      },
      {
        parentId: 'group-sss',
        text: 'Which stores have the best SSS?',
      },
      {
        parentId: 'group-sss',
        text: 'What is my SSS?',
      },

      // Traffic
      {
        id: 'group-traffic',
        parentId: 'root',
        text: 'Traffic',
        type: GROUP,
      },
      {
        parentId: 'group-traffic',
        text: 'What is Comparable Traffic?',
      },
      {
        parentId: 'group-traffic',
        text: `Which ${director} has the best Comparable Traffic?`,
      },
      {
        parentId: 'group-traffic',
        text: 'Which stores have the best Comparable Traffic?',
      },
      {
        parentId: 'group-traffic',
        text: 'What is my Comparable Traffic?',
      },

      // Speed of Service
      ...(chatBoxConfig.hideSos
        ? []
        : ([
            {
              id: 'group-sos',
              parentId: 'root',
              text: 'Speed of Service',
              type: GROUP,
            },
            {
              parentId: 'group-sos',
              text: 'What is SoS this year?',
            },
            {
              parentId: 'group-sos',
              text: `Which ${director} has the fastest SoS?`,
            },
            {
              parentId: 'group-sos',
              text: 'Which Stores have the fastest SoS?',
            },
            {
              parentId: 'group-sos',
              text: 'What is my SoS?',
            },
          ] as IItem[])),

      // Delivery
      {
        id: 'group-delivery',
        parentId: 'root',
        text: 'Delivery',
        type: GROUP,
      },
      {
        parentId: 'group-delivery',
        text: 'What is Delivery Sales this period?',
      },
      {
        parentId: 'group-delivery',
        text: `Which ${director} has the best Delivery Sales?`,
      },
      {
        parentId: 'group-delivery',
        text: 'Which Stores have the best Delivery Sales?',
      },
      {
        parentId: 'group-delivery',
        text: 'What are my Delivery Sales?',
      },
      {
        parentId: 'group-delivery',
        text: 'How do we improve Delivery?',
      },
      {
        parentId: 'group-delivery',
        text: 'How do I improve my Delivery?',
      },

      // Salesmanship
      {
        id: 'group-salesmanship',
        parentId: 'root',
        text: 'Salesmanship',
        type: GROUP,
      },
      {
        parentId: 'group-salesmanship',
        text: 'What are Check Averages?',
      },
      {
        parentId: 'group-salesmanship',
        text: `Which ${director} has the best Check Average?`,
      },
      {
        parentId: 'group-salesmanship',
        text: 'Which store has the best Check Average?',
      },
      {
        parentId: 'group-salesmanship',
        text: 'What is my Check Average?',
      },
      {
        parentId: 'group-salesmanship',
        text: 'How do we improve Check Average?',
      },
      {
        parentId: 'group-salesmanship',
        text: 'How do I improve my Check Average?',
      },

      // Daypart / Revenue Centers
      {
        id: 'group-revenueCenters',
        parentId: 'root',
        text: 'Daypart / Revenue Centers',
        type: GROUP,
      },
      {
        parentId: 'group-revenueCenters',
        text: 'What is dinner sales?',
      },
      ...(chatBoxConfig.hideDriveThru
        ? []
        : [
            {
              parentId: 'group-revenueCenters',
              text: `Which ${director} has the best drive through sales?`,
            },
          ]),
      {
        parentId: 'group-revenueCenters',
        text: 'Which stores have the best lunch sales?',
      },
      {
        parentId: 'group-revenueCenters',
        text: 'What is my delivery Sales %?',
      },

      // Sales Target
      {
        id: 'group-salesTarget',
        parentId: 'root',
        text: 'Sales Target',
        type: GROUP,
      },
      {
        parentId: 'group-salesTarget',
        text: 'How are we performing against Sales Targets?',
      },
      {
        parentId: 'group-salesTarget',
        text: 'Am I beating my Sales Targets?',
      },
      {
        parentId: 'group-salesTarget',
        text: 'How do I improve Check Avg?',
      },

      // Loss Prevention / Discounts
      {
        id: 'group-lossPrevention',
        parentId: 'root',
        text: 'Loss Prevention / Discounts',
        type: GROUP,
      },
      {
        parentId: 'group-lossPrevention',
        text: 'What % of Sales do we discount?',
      },
      {
        parentId: 'group-lossPrevention',
        text: `Which ${director} is Discounting the most?`,
      },
      {
        parentId: 'group-lossPrevention',
        text: 'Which stores are Discounting too much?',
      },
      {
        parentId: 'group-lossPrevention',
        text: 'How do I improve Loss Prevention?',
      },

      // Section: Labor
      {
        id: 'section-Labor',
        parentId: 'root',
        text: 'Labor',
        type: SECTION,
      },

      // Hourly Labor
      {
        id: 'group-hourlyLabor',
        parentId: 'root',
        text: 'Hourly Labor',
        type: GROUP,
      },
      {
        parentId: 'group-hourlyLabor',
        text: 'What is Hourly Labor this year?',
      },
      {
        parentId: 'group-hourlyLabor',
        text: `Which ${director} is beating their Labor Budget?`,
      },
      {
        parentId: 'group-hourlyLabor',
        text: 'Which Stores are beating Labor Budget?',
      },
      {
        parentId: 'group-hourlyLabor',
        text: 'How do we improve Labor?',
      },
      {
        parentId: 'group-hourlyLabor',
        text: 'How do I improve my Labor?',
      },

      // Turnover
      {
        id: 'group-turnover',
        parentId: 'root',
        text: 'Turnover',
        type: GROUP,
      },
      {
        parentId: 'group-turnover',
        text: 'What is Hourly Turnover in the past month?',
      },
      {
        parentId: 'group-turnover',
        text: 'Which store has the best Hourly Turnover?',
      },
      {
        parentId: 'group-turnover',
        text: 'What is my Turnover rate?',
      },

      // Section: Food
      {
        id: 'section-Food',
        parentId: 'root',
        text: 'Food',
        type: SECTION,
      },

      // Food Cost
      {
        id: 'group-foodCost',
        parentId: 'root',
        text: 'Food Cost',
        type: GROUP,
      },
      {
        parentId: 'group-foodCost',
        text: `What is ${plv} this year?`,
      },
      {
        parentId: 'group-foodCost',
        text: `What is my ${plv}?`,
      },
      {
        parentId: 'group-foodCost',
        text: `How do we improve ${plv}?`,
      },
      {
        parentId: 'group-foodCost',
        text: `How do I improve my ${plv}?`,
      },

      // Product Mix
      ...(chatBoxConfig.hidePmix
        ? []
        : ([
            {
              id: 'group-productMix',
              parentId: 'root',
              text: 'Product Mix',
              type: GROUP,
            },
            {
              parentId: 'group-productMix',
              text: 'How many fries did we sell?',
            },
            {
              parentId: 'group-productMix',
              text: `Which ${director} is selling the most cookies?`,
            },
            {
              parentId: 'group-productMix',
              text: 'Which Stores are selling the most fried chicken?',
            },
            {
              parentId: 'group-productMix',
              text: 'How many Coke did I sell?',
            },
          ] as IItem[])),

      // Purchasing / Waste
      {
        id: 'group-purchasingWaste',
        parentId: 'root',
        text: 'Purchasing / Waste',
        type: GROUP,
      },
      ...(chatBoxConfig.hideBuns
        ? []
        : [
            {
              parentId: 'group-purchasingWaste',
              text: `Which ${director} is buying the most buns?`,
            },
          ]),
      ...(chatBoxConfig.showChicken
        ? [
            {
              parentId: 'group-purchasingWaste',
              text: `Which ${director} is purchasing the most chicken?`,
            },
          ]
        : []),
      {
        parentId: 'group-purchasingWaste',
        text: 'Which Stores are wasting the most chicken?',
      },
      {
        parentId: 'group-purchasingWaste',
        text: 'How much cheese are we wasting?',
      },

      ...(chatBoxConfig.hideGuest
        ? []
        : ([
            // Section: Guest
            {
              id: 'section-guest',
              parentId: 'root',
              text: 'Guest',
              type: SECTION,
            },

            // ACR
            {
              id: 'group-acr',
              parentId: 'root',
              text: 'ACR',
              type: GROUP,
            },
            {
              parentId: 'group-acr',
              text: 'What is ACR this year?',
            },
            {
              parentId: 'group-acr',
              text: `Which ${director} has the best ACR?`,
            },
            {
              parentId: 'group-acr',
              text: 'Which Store has the best ACR?',
            },
            {
              parentId: 'group-acr',
              text: 'What is my ACR?',
            },
            {
              parentId: 'group-acr',
              text: 'How do we improve ACR?',
            },
            {
              parentId: 'group-acr',
              text: 'How do I improve my ACR?',
            },

            // FSS
            {
              id: 'group-fss',
              parentId: 'root',
              text: 'FSS',
              type: GROUP,
            },
            {
              parentId: 'group-fss',
              text: 'What is FSS this period?',
            },
            {
              parentId: 'group-fss',
              text: `Which ${director} has the best FSS?`,
            },
            {
              parentId: 'group-fss',
              text: 'Which Stores have the best FSS?',
            },
            {
              parentId: 'group-fss',
              text: 'What is my FSS?',
            },
          ] as IItem[])),
    ]

    const questionMenuDataSource: IValueType[] = [
      {
        id: 'root',
        parentId: null,
        value: 'root',
        text: 'root',
      },
    ]

    items.forEach((item) => {
      questionMenuDataSource.push({
        id: item.id || `${item.parentId}-${item.text}`,
        parentId: item.parentId,
        value: item.text,
        text: item.text,
        isDisabled: item.type === 'SECTION' ? true : false,
        style:
          item.type === 'SECTION'
            ? { fontWeight: 700, fontSize: '20px' }
            : item.type === 'GROUP'
            ? { paddingLeft: '60px', fontWeight: 500, fontSize: '18px' }
            : undefined,
      })
    })

    return questionMenuDataSource
  }, [chatBoxConfig])
}
