import styled from 'styled-components'

import ProgressBar from './ProgressBar'

interface IPropsType {
  value: string | number
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 250px;
  height: 40px;
`

const TrackerCompletionRate = ({ value }: IPropsType) => {
  if (typeof value === 'number') return <ProgressBar value={value} />
  return <Container>{value}</Container>
}

export default TrackerCompletionRate
