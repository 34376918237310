import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import {
  GET_LOCATION_TOP_MISSING_ITEMS,
  ILocationTopMissingItemsType,
  ILocationTopMissingItemsVariablesType,
} from '../gqls'

export interface IDataSourceType {
  itemName: string
  totalCount: number
}

interface IProps {
  startDate: string
  endDate: string
  locationId: number
}

export default ({
  startDate,
  endDate,
  locationId,
}: IProps): {
  dataSource: IDataSourceType[]
  loading: boolean
} => {
  const { data, loading } = useQuery<
    ILocationTopMissingItemsType,
    ILocationTopMissingItemsVariablesType
  >(GET_LOCATION_TOP_MISSING_ITEMS, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_ids: [locationId],
      },
    },
  })

  const dataSource = useMemo(() => {
    if (!data?.getLocationTopMissingItems.nodes) return []

    return data.getLocationTopMissingItems.nodes
  }, [data, loading])

  return {
    dataSource,
    loading,
  }
}
