import { useQuery } from '@apollo/client'

import { INullableType } from 'pared/types'

import {
  ILaborSummaryType,
  ILaborSummaryVariablesType,
  getSalesPerLaborHour,
} from '../gqls/labor'

interface ILaborActionType {
  opportunityHour: string
  overstaffedHours: string
  noOverstaffed: boolean
  totalAnnualOpportunityCost: number
}

interface IReturnType {
  loading: boolean
  action: ILaborActionType
}

const convertHourToString = (hour: number) => {
  if (hour === 0) {
    return '12am'
  }

  if (hour === 12) {
    return '12pm'
  }

  if (hour < 12) {
    return `${hour}am`
  }

  if (hour > 12) {
    return `${hour - 12}pm`
  }

  return ''
}

export default (
  startDate: string,
  endDate: string,
  locationId: number,
): IReturnType => {
  const action: ILaborActionType = {
    opportunityHour: '0',
    overstaffedHours: '0',
    noOverstaffed: false,
    totalAnnualOpportunityCost: 0,
  }
  const variables: INullableType<ILaborSummaryVariablesType> = {
    iLocationId: locationId,
    iStartDate: startDate,
    iEndDate: endDate,
  }

  const { data, loading } = useQuery<ILaborSummaryType>(getSalesPerLaborHour, {
    variables,
  })

  const dataSource = data?.getSalesPerLaborHour?.nodes || []

  const topOpportunity = [...dataSource].sort((a, b) => {
    if (a.differenceLaborHours === null || b.differenceLaborHours === null) {
      return -1
    }

    return b.differenceLaborHours - a.differenceLaborHours
  })

  if (topOpportunity.length > 0) {
    action.opportunityHour = convertHourToString(topOpportunity[0].hour)
    action.overstaffedHours =
      topOpportunity[0].differenceLaborHours?.toFixed(1) ?? '-'
    action.noOverstaffed = topOpportunity[0].differenceLaborHours < 0
    if (topOpportunity[0].totalAnnualOpportunityCost > 0) {
      action.totalAnnualOpportunityCost =
        topOpportunity[0].totalAnnualOpportunityCost / 100
    }
  }

  return {
    loading,
    action,
  }
}
