import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { useMemo } from 'react'

import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import useLocationInfo from 'pared/components/LocationInfo/hooks/useLocationInfo'
import { DATE_FORMAT } from 'pared/data/getDateRanges'
import { toUsdStr } from 'pared/utils/number'

import { IApiDataType } from '../../types'

interface ILaborSummaryNodeType {
  hour: number
  sales: number
  systemwideSales: number
  differenceSales: number
  laborHours: number
  systemwideLaborHours: number
  differenceLaborHours: number
  annualOpportunityCost: number
  salesPerLaborHour: number
  systemwideSalesPerLaborHour: number
  differenceSalesPerLaborHour: number
  totalSales: number
  totalSystemwideSales: number
  totalDifferenceSales: number
  totalLaborHours: number
  totalSystemwideLaborHours: number
  totalDifferenceLaborHours: number
  totalAnnualOpportunityCost: number
  totalSalesPerLaborHour: number
  totalSystemwideSalesPerLaborHour: number
  totalDifferenceSalesPerLaborHour: number
}

interface ILaborSummaryType {
  getSalesPerLaborHour: {
    nodes: ILaborSummaryNodeType[]
  }
}

const query = gql`
  query GetSalesPerLaborHour(
    $iLocationId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    getSalesPerLaborHour(
      iLocationId: $iLocationId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        hour
        sales
        systemwideSales
        differenceSales
        laborHours
        systemwideLaborHours
        differenceLaborHours
        annualOpportunityCost
        salesPerLaborHour
        systemwideSalesPerLaborHour
        differenceSalesPerLaborHour
        totalSales
        totalSystemwideSales
        totalDifferenceSales
        totalLaborHours
        totalSystemwideLaborHours
        totalDifferenceLaborHours
        totalAnnualOpportunityCost
        totalSalesPerLaborHour
        totalSystemwideSalesPerLaborHour
        totalDifferenceSalesPerLaborHour
      }
    }
  }
`

const convertHourToString = (hour: number) => {
  if (hour === 0) {
    return '12am'
  }

  if (hour === 12) {
    return '12pm'
  }

  if (hour < 12) {
    return `${hour}am`
  }

  if (hour > 12) {
    return `${hour - 12}pm`
  }

  return ''
}

const useLabor = (): IApiDataType => {
  const startDate = moment().subtract(30, 'days').format(DATE_FORMAT)
  const endDate = moment().subtract(1, 'day').format(DATE_FORMAT)
  const { groupFilter } = useGroupFilter()
  const locationId = groupFilter?.ids[0] || 0
  const locationName = useLocationInfo(locationId)?.name || 'Unknown'

  const { data, loading } = useQuery<ILaborSummaryType>(query, {
    variables: {
      iLocationId: locationId,
      iStartDate: startDate,
      iEndDate: endDate,
    },
    skip: !groupFilter,
  })

  return {
    data: useMemo(() => {
      if (!data) return null

      const laborData = data.getSalesPerLaborHour?.nodes
      const topOpportunity = [...laborData].sort((a, b) => {
        if (
          a.differenceLaborHours === null ||
          b.differenceLaborHours === null
        ) {
          return -1
        }

        return b.differenceLaborHours - a.differenceLaborHours
      })
      const totalAnnualOpportunityCost =
        topOpportunity[0].totalAnnualOpportunityCost > 0
          ? topOpportunity[0].totalAnnualOpportunityCost / 100
          : 0

      return {
        title: 'Labor',
        total: totalAnnualOpportunityCost,
        detail:
          topOpportunity[0].differenceLaborHours < 0
            ? `${locationName} has lower staffing than company averages.`
            : `${locationName}'s biggest opportunities are at ${convertHourToString(
                topOpportunity[0].hour,
              )} (${
                topOpportunity[0].differenceLaborHours?.toFixed(1) ?? '-'
              } hours overstaffed).  Consider changing your scheduling during these hours. ${
                totalAnnualOpportunityCost
                  ? `Annual opportunity cost for all hours: ${toUsdStr(
                      totalAnnualOpportunityCost,
                    )}`
                  : ''
              }`,
      }
    }, [locationName, data]),
    loading,
  }
}

export default useLabor
