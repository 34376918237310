import paredAnalytics from 'pared/analytics/utils/ParedAnalytics'

export const track = {
  async dayTrendsDayPartChanged(dayPart: string) {
    await paredAnalytics.track('Delivery - Day Trends Day Part', { dayPart })
  },

  async dayTrendsCategoryChanged(category: string) {
    await paredAnalytics.track('Delivery - Day Trends Day Part', { category })
  },

  async staffCategoryChanged(dayPart: string) {
    await paredAnalytics.track('Delivery - Staff Category', { dayPart })
  },
}
