import { IDataType } from '../types'
import useListGeoInfo from './useListGeoInfo'

export type IApiKeyType = keyof typeof api

const api = {
  listGeoInfo: useListGeoInfo,
}

const useApi = (
  apiName: IApiKeyType,
): { data: IDataType[] | null; loading: boolean } => api[apiName]()

export default useApi
