import { useMemo } from 'react'

import { getBrandSettings } from 'pared/customer'
import useBrands from 'pared/layouts/hooks/useBrands'
import { getUser } from 'pared/utils/user'

import { routes } from '../configs'
import { IRouteType } from '../types'
import useAccessiblePages from './useAccessiblePages'

const useUnAccessibleLinks = () => {
  const { brand } = useBrands()
  const brandSettings = getBrandSettings()
  const accessiblePages = useAccessiblePages()
  const user = getUser()

  return useMemo(() => {
    const unAccessibleLinks = (
      routes: IRouteType[],
      isCorporateAndDisabled: boolean = false,
    ): string[] =>
      routes?.reduce((result, r) => {
        if ('children' in r) {
          return [
            ...result,
            ...unAccessibleLinks(
              r.children,
              r.title === brandSettings.labels.business.corporate &&
                user.disableCorporatePages,
            ),
          ]
        } else {
          if (
            'link' in r &&
            (!Object.keys(accessiblePages).includes(r.link) ||
              isCorporateAndDisabled)
          ) {
            return [...result, r.link]
          }
          return result
        }
      }, [] as string[]) || []

    return unAccessibleLinks(routes[brand])
  }, [brand, accessiblePages, brandSettings, user])
}

export default useUnAccessibleLinks
