import { useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'

import { BRAND_LOCATION_GROUP_ID } from 'pared/constants/brands'
import { INullableType } from 'pared/types'
import { getBrand } from 'pared/utils/brand'

import {
  GET_STORE_LIST,
  IGetStoreListByBrandType,
  IGetStoreListByBrandVariableTypes,
} from '../../gql'

export const ALL = 'All'

interface IlocationType {
  key: number
  title: string
}

interface IReturnType {
  selectedLocation: number
  locationChanged: (value: number) => void
  locations: IlocationType[]
}

const useLocationSelect = (): IReturnType => {
  const brand = getBrand()

  const { data: storeList, loading: storeListLoading } = useQuery<
    IGetStoreListByBrandType,
    INullableType<IGetStoreListByBrandVariableTypes>
  >(GET_STORE_LIST, {
    variables: {
      iFilter: {
        location_group_ids: [BRAND_LOCATION_GROUP_ID[brand]],
      },
    },
  })

  const [selectedLocation, setSelectedLocation] = useState<number>(-1)

  const locations = useMemo(() => {
    const rawLocations = storeList?.listLocationDetails?.nodes.map(
      (location) => {
        return {
          key: location.id,
          title: `${location.code} - ${location.name}`,
        }
      },
    )

    return [{ key: -1, title: ALL }, ...(rawLocations || [])]
  }, [storeList])

  return {
    selectedLocation,
    locationChanged: setSelectedLocation,
    locations,
  }
}

export default useLocationSelect
