import React from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

interface IDataSource {
  rank?: number
  value: number
  employeeId: number | string
  employeeName: string
  displayString: string
  isStoreAvg: boolean
}

interface IPropsType {
  title: string
  dataSource: IDataSource[] | null
}

const RankTable = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  font-family: Lexend-Regular;

  thead tr {
    border-bottom: 1px solid ${COLORS.Chalkboard};
  }

  tbody tr:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }

  tfoot tr:first-child {
    border-top: 1px solid ${COLORS.Chalkboard};
  }

  thead th {
    font-weight: 700;
    line-height: 14px;
    padding: 10px 0;
    min-width: 50px;
    white-space: nowrap;
  }

  tbody td {
    font-style: normal;
    font-weight: 400;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  tfoot td {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding: 18px 0 0 0;
  }
`

const RankTableTh = styled.th<{ textAlign?: string }>`
  font-family: Lexend-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankTableTd = styled.td<{
  bold?: boolean
  textAlign?: string
  isAvg?: boolean
}>`
  color: ${(props) => (props.isAvg ? 'white' : 'black')};
  font-family: ${(props) =>
    props.bold ? 'Lexend-SemiBold' : 'Lexend-Regular'};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: ${(props) => (props.textAlign === 'left' ? 'left' : 'center')};
`

const RankNumberTd = styled(RankTableTd)`
  padding-left: 12px;
`

const AvgTr = styled.tr`
  background-color: ${COLORS.Sky} !important;
`

const Table = ({ dataSource, title }: IPropsType) => {
  return (
    <RankTable>
      <thead>
        <tr>
          <RankTableTh textAlign={'left'}>Rank</RankTableTh>
          <RankTableTh textAlign={'left'}>Name</RankTableTh>
          <RankTableTh textAlign={'center'}>{title}</RankTableTh>
        </tr>
      </thead>
      <tbody>
        {dataSource?.map((data) => {
          return data.isStoreAvg ? (
            <AvgTr key={[data.employeeId, data.employeeName].join('-')}>
              <RankNumberTd bold textAlign={'left'}></RankNumberTd>
              <RankTableTd textAlign={'left'} isAvg>
                {data.employeeName}
              </RankTableTd>
              <RankTableTd textAlign={'center'} isAvg>
                {data.displayString}
              </RankTableTd>
            </AvgTr>
          ) : (
            <tr key={[data.employeeId, data.employeeName].join('-')}>
              <RankNumberTd bold textAlign={'left'}>
                {data.rank}
              </RankNumberTd>
              <RankTableTd textAlign={'left'}>{data.employeeName}</RankTableTd>
              <RankTableTd textAlign={'center'}>
                {data.displayString}
              </RankTableTd>
            </tr>
          )
        })}
      </tbody>
    </RankTable>
  )
}

export default React.memo(Table)
