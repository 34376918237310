import useLfrListConsolidatedGfpReport, {
  lfrListConsolidatedGfpReportConfigs,
} from './useLfrListConsolidatedGfpReport'
import useLfrListLocationGfpReport, {
  lfrListLocationGfpReportConfigs,
} from './useLfrListLocationGfpReport'

export const lfrListRestaurantGfpReportConfigs = {
  ...lfrListLocationGfpReportConfigs,
  ...lfrListConsolidatedGfpReportConfigs,
}

const useLfrListRestaurantGfpReport = () => {
  const listLfrLocationGfpReport = useLfrListLocationGfpReport()
  const listLfrConsolidatedGfpReport = useLfrListConsolidatedGfpReport()

  return (listLfrLocationGfpReport?.data || []).length === 0
    ? listLfrConsolidatedGfpReport
    : listLfrLocationGfpReport
}

export default useLfrListRestaurantGfpReport
