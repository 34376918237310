import { useMemo, useState } from 'react'

export interface IDataType {
  interval?: {
    options: any
    value: any
  }
}

const useInterval = (): IDataType => {
  const intervalHook = useState([['daypart']])

  return {
    interval: useMemo(() => {
      const options = [
        {
          id: 'daypart',
          parentId: 'root',
          displayName: 'Daypart',
        },
        {
          id: '15min',
          parentId: 'root',
          displayName: '15 Minute',
        },
        {
          id: '30min',
          parentId: 'root',
          displayName: '30 Minute',
        },
        {
          id: '1hr',
          parentId: 'root',
          displayName: '1 Hour',
        },
      ]

      return {
        value: intervalHook[0],
        options: options,
        onChange: intervalHook[1],
      }
    }, [intervalHook]),
  }
}

export default useInterval
