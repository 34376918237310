import { MenuItem, Select } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { INavParams } from 'pared/Routes/navParams'
import Print from 'pared/components/basicUi/Print'
import Spin from 'pared/components/basicUi/spin'
import Table from 'pared/components/basicUi/table'
import LocationSelector from 'pared/components/nav/LocationSelector'
import COLORS from 'pared/constants/colors'
import { MOBILE_WIDTH } from 'pared/constants/styles'
import ItemSelector from 'pared/pages/CorporatePurchases/CorporatePurchasesTable/ItemSelector'

import getOptions from './hooks/getOptions'
import useColumns from './hooks/useColumns'
import useDataSource from './hooks/useDataSource'

interface IPropsType {
  navParams: INavParams
}

const Container = styled.div`
  display: flex;
  width: 1200px;
  padding: 0;
  @media ${MOBILE_WIDTH} {
    padding: 30px 0;
  }
`

const Header = styled.div`
  display: flex;
  width: 1200px;
  justify-content: space-between;
  padding-bottom: 30px;

  @media ${MOBILE_WIDTH} {
    margin: 0px;
    flex-direction: column-reverse;
    width: 100%;
    padding-bottom: 15px;
  }

  h1 {
    margin: 0px;
    font-family: Lexend-SemiBold;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    color: ${COLORS.Chalkboard};
    line-height: 52px;

    @media ${MOBILE_WIDTH} {
      padding: 30px 25px 0px;
    }
  }
`

const Selectors = styled.div`
  @media ${MOBILE_WIDTH} {
    padding: 10px 25px;
    background-color: rgb(196, 196, 196);
  }

  & > * {
    &:not(:last-child) {
      margin-right: 7px;

      @media ${MOBILE_WIDTH} {
        margin-right: 0px;
      }
    }

    @media ${MOBILE_WIDTH} {
      width: 100% !important;
    }
  }
`

const SubHeader = styled.div`
  padding: 20px 0;
  width: 670px;
  align-items: center;

  @media ${MOBILE_WIDTH} {
    display: block;
    width: 100%;
    margin: 60px 0px 0px;
  }

  h1 {
    margin: 0px;
    padding: 20px;
    font-family: Lexend-SemiBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
`

const FilterContainer = styled.div`
  width: 1200px;
  padding: 25px 0;

  span {
    font-family: Lexend-SemiBold;
    font-size: 14px;
    font-weight: 700;
  }

  @media ${MOBILE_WIDTH} {
    text-align: left;
    width: 100%;
    padding: 5px 0 25px 0;

    span {
      display: block;
      padding: 0;
    }
  }
`

const MenuItemSelect = styled(Select)`
  width: ${(props) => props.width || '300px'};
  height: 40px;
  background-color: white;
  margin: 0 30px 0 15px;

  @media ${MOBILE_WIDTH} {
    width: 100%;
    margin: 10px 0 20px 0;
  }
`

const ItemSelectorContainer = styled.div`
  width: 1300px;
  padding: 25px 0;
  display: flex;
  align-items: center;
  gap: 20px;
  span {
    font-family: Lexend-SemiBold;
    font-size: 14px;
    font-weight: 700;
  }

  @media ${MOBILE_WIDTH} {
    flex-direction: column;
    text-align: left;
    width: 100%;
    padding: 5px 0 25px 0;

    span {
      display: block;
      padding: 0;
    }
  }
`

const StyledItemSelector = styled(ItemSelector)`
  background-color: white;
  margin: 0 30px 0 15px;

  @media ${MOBILE_WIDTH} {
    width: 100%;
    margin: 10px 0 20px 0;
  }
`

const rowStyle = `
  td:first-child {
    font-family: Lexend-SemiBold;
    font-weight: 600px;
  }

  &:nth-child(even) {
    background-color: ${COLORS.Porcelain};
  }
`

const FlexContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
`

const PrintTableHeader = styled.div`
  margin: 30px 0px 10px;
  font-family: Lexend-Regular;
  font-size: 18px;
  font-weight: normal;
`

const PrintContainer = styled.div`
  max-width: 650px;
`

const nextDayOptions = [
  '2 Days',
  '3 Days',
  '4 Days',
  '5 Days',
  '6 Days',
  '7 Days',
]

const OrderingAnalysis = ({ navParams }: IPropsType) => {
  const storeId = parseInt(navParams.storeId || '0', 10) || 0

  const [nextDays, setNextDays] = useState(0)
  const [vendorName, setVendorName] = useState('All')

  const { dataSource, loading } = useDataSource(storeId, nextDays)
  const columns = useColumns(dataSource, nextDays)

  const uniqueVendorNames = dataSource
    .map((item) => item.vendor)
    .filter((value, index, self) => self.indexOf(value) === index)

  const { purchaseOptions, defaultPurchaseOption } = getOptions({
    reportResult: dataSource,
    selectedVendor: vendorName,
  })

  const [selectedItem, setSelectedItem] = useState<{
    name: string
    type: string
  } | null>(defaultPurchaseOption)

  const handleItemChange = (
    event: any,
    value: { name: string; type: string },
  ) => {
    if (value) {
      setSelectedItem(value)
    }
  }

  const filteredData = useMemo(() => {
    let data = dataSource
    if (vendorName !== 'All')
      data = dataSource?.filter((d) => d.vendor === vendorName)

    if (selectedItem?.type && selectedItem?.type != 'All Items')
      return data?.filter((d) =>
        selectedItem?.type === 'Category'
          ? d.itemType === selectedItem.name
          : d.item === selectedItem?.name,
      )

    return data
  }, [dataSource, vendorName, selectedItem])

  return (
    <>
      <Header>
        <div>
          <h1>Ordering Analysis</h1>
        </div>

        <Selectors>
          <LocationSelector navParams={navParams} />
        </Selectors>
      </Header>

      <Container>
        <FilterContainer>
          <span>Vender:</span>
          <MenuItemSelect
            variant="outlined"
            width="250px"
            value={vendorName}
            onChange={(e: any) => {
              setVendorName(e.target.value as string)
              setSelectedItem({ name: 'All Items', type: 'All Items' })
            }}
          >
            <MenuItem key="all" value="All">
              All
            </MenuItem>
            {uniqueVendorNames.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </MenuItemSelect>
        </FilterContainer>

        <ItemSelectorContainer>
          <span>Category or Item:</span>
          <StyledItemSelector
            allItems={purchaseOptions}
            selectedItem={selectedItem}
            onItemChange={handleItemChange}
            isEnabled
            isGrouped
            isLoading={loading}
          />
        </ItemSelectorContainer>

        <FilterContainer>
          <span>Order Enough For Next:</span>
          <MenuItemSelect
            variant="outlined"
            width="125px"
            value={nextDays}
            onChange={(e: any) => {
              setNextDays(e.target.value as number)
              setVendorName('All')
              setSelectedItem({ name: 'All Items', type: 'All Items' })
            }}
          >
            {nextDayOptions.map((option, index) => (
              <MenuItem key={option} value={index}>
                {option}
              </MenuItem>
            ))}
          </MenuItemSelect>
        </FilterContainer>
      </Container>

      <SubHeader>
        <FlexContainer>
          <h1>ORDERING GUIDE</h1>
          <Print>
            <PrintTableHeader>Ordering Guide</PrintTableHeader>
            <PrintContainer>
              <Table
                key={'ordering-guide'}
                dataSource={filteredData}
                columns={columns}
                rowStyle={rowStyle}
              />
            </PrintContainer>
          </Print>
        </FlexContainer>
        <Spin spinning={loading}>
          <Table
            key={'ordering-guide'}
            dataSource={filteredData}
            columns={columns}
            rowStyle={rowStyle}
          />
        </Spin>
      </SubHeader>
    </>
  )
}

export default React.memo(OrderingAnalysis)
