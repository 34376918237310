import { gql, useQuery } from '@apollo/client'
import * as _ from 'lodash'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query ListAcePopDailyFlashAverageKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationId
        metricData
        metricSummaryData
      }
    }
  }
`

export const acePopDailyFlashAverageConfigs = {
  name: 'string',
  netSales: 'price',
  cashDeposits: 'price',
  cashOs: 'price',
  overringAmount: 'price',
  deletesAmount: 'price',
} as const

const KPIS = [
  'net_sales',
  'cash_deposits',
  'cash_os',
  'overring_amount',
  'deletes_amount',
]

function getAverage(data?: number[]) {
  if (!data) return null

  const nonNullData = data.filter((item) => !_.isNil(item))
  return (
    nonNullData.reduce((result, item) => (result = result + item), 0) /
    nonNullData.length
  )
}

const DEFAULT_COL = {
  name: ' ',
  netSales: null,
  cashDeposits: null,
  cashOs: null,
  overringAmount: null,
  deletesAmount: null,
}

const useAcePopDailyFlashAverage = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: groupFilter?.ids,
        metrics: KPIS,
      },
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const customizedData: any = data?.listLocationMetricValues?.nodes

      if (!customizedData) return null

      const [rawSummary] = customizedData.filter(
        (locationData: any) => locationData.metricSummaryData != null,
      )

      const totalNetSales = rawSummary?.metricSummaryData.net_sales.value * 100

      const avgNetSales = getAverage(
        customizedData.map(
          (data: any) => data.metricData.net_sales.value * 100,
        ),
      )
      const avgCashDeposits = getAverage(
        customizedData.map(
          (data: any) => data.metricData.cash_deposits.value * 100,
        ),
      )
      const avgCashOs = getAverage(
        customizedData.map((data: any) => data.metricData.cash_os.value * 100),
      )
      const avgOverringAmount = getAverage(
        customizedData.map(
          (data: any) => data.metricData.overring_amount.value * 100,
        ),
      )
      const avgDeletesAmount = getAverage(
        customizedData.map(
          (data: any) => data.metricData.deletes_amount.value * 100,
        ),
      )

      return {
        source: [
          {
            ...DEFAULT_COL,
            name: 'Average/Location',
            netSales: avgNetSales,
            cashDeposits: avgCashDeposits,
            cashOs: avgCashOs,
            overringAmount: avgOverringAmount,
            deletesAmount: avgDeletesAmount,
          },
          {
            ...DEFAULT_COL,
            name: 'Weekly/Location',
            netSales: avgNetSales ? avgNetSales * 7 : avgNetSales,
          },
          {
            ...DEFAULT_COL,
            name: 'Montly/Location',
            netSales: avgNetSales ? avgNetSales * 28 : avgNetSales,
          },
          {
            ...DEFAULT_COL,
            name: 'Yearly/Location',
            netSales: avgNetSales ? avgNetSales * 365 : avgNetSales,
          },
          {
            ...DEFAULT_COL,
            name: 'Total Yearly',
            netSales: totalNetSales * 365,
          },
        ],
      }
    }, [groupFilter, data]),
    loading,
  }
}

export default useAcePopDailyFlashAverage
