import React, { useContext, useMemo, useState } from 'react'

import { IDataType } from './types'

type IGroupFilterContextType = [
  null | IDataType,
  (value: IGroupFilterContextType[0]) => void,
]

interface IPropsType {
  children: React.ReactNode
}

export const GroupFilterContext = React.createContext<IGroupFilterContextType>([
  null,
  () => {
    throw new Error('not found provider')
  },
])

export const useGroupFilter = () => {
  const [selectedGroup] = useContext(GroupFilterContext)
  const hasGroupBy = useMemo(
    () =>
      Boolean(selectedGroup?.list?.[0] && 'groupBy' in selectedGroup.list[0]),
    [selectedGroup],
  )

  return { groupFilter: selectedGroup, hasGroupBy }
}

const GroupFilterProvider = ({ children }: IPropsType) => {
  const result = useState<IGroupFilterContextType[0]>(null)

  return (
    <GroupFilterContext.Provider value={result}>
      {children}
    </GroupFilterContext.Provider>
  )
}

export default GroupFilterProvider
