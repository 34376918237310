import { LargeScreen, SmallScreen } from 'pared/components/responsive'

import WebLargeMain from './WebLargeMain'
import WebSmallMain from './WebSmallMain'
import { IDetailGuestOpentableKpi } from './index'
import { IOrderBy } from './index'

interface IProps {
  itemizedKpis: IDetailGuestOpentableKpi[]
  isTableExpanded: boolean
  onToggleExpansion: () => void
  orderBy: IOrderBy
  setOrderBy: any
  isBreakdownByDirectors: boolean
  breakdownType: string | null
}

function Main({
  itemizedKpis,
  isTableExpanded,
  onToggleExpansion,
  orderBy,
  setOrderBy,
  isBreakdownByDirectors,
  breakdownType,
}: IProps) {
  return (
    <>
      <LargeScreen>
        <WebLargeMain
          itemizedKpis={itemizedKpis}
          isTableExpanded={isTableExpanded}
          onToggleExpansion={onToggleExpansion}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          isBreakdownByDirectors={isBreakdownByDirectors}
          breakdownType={breakdownType}
        />
      </LargeScreen>
      <SmallScreen>
        <WebSmallMain
          itemizedKpis={itemizedKpis}
          isTableExpanded={isTableExpanded}
          onToggleExpansion={onToggleExpansion}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          isBreakdownByDirectors={isBreakdownByDirectors}
          breakdownType={breakdownType}
        />
      </SmallScreen>
    </>
  )
}

export default Main
