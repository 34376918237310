import { useEffect } from 'react'

import navigator from 'pared/Routes/navigator'
import getLocations from 'pared/data/getLocations'
import useNavParams from 'pared/layouts/hooks/useNavParams'
import { getPathnameWithoutBrand } from 'pared/utils/brand'

const useDefaultLocation = () => {
  const navParams = useNavParams()
  useEffect(() => {
    async function setDefaultLocation() {
      const locationData = await getLocations()
      const locations = locationData?.locations ?? []
      const locationMap = locationData?.locationMap ?? {}
      const locationIds = locations.map(({ locationId }) => locationId)
      let newSearchParams: { storeId: string | number } = {
        storeId:
          navParams.storeId ?? navParams.userDefaultStoreId ?? locationIds[0],
      }
      if (!locationMap[newSearchParams.storeId]) {
        newSearchParams.storeId = locationIds[0]
      }

      if (navParams.pagePathname) {
        const pathnameWithoutBrand = getPathnameWithoutBrand(
          navParams.pagePathname,
        )

        navigator.go(pathnameWithoutBrand, newSearchParams)
      }
    }

    setDefaultLocation()
  }, [])
}

export default useDefaultLocation
