import stringSimilarity from 'string-similarity'

import { IReportResult } from 'pared/components/CustomizedReport'

const getOptions = ({
  reportResult,
  selectedVendor = null,
  presetCategoryOrItemFilter = '',
  config,
}: {
  reportResult: IReportResult | undefined
  selectedVendor?: string | null
  presetCategoryOrItemFilter?: string
  config?: any
}) => {
  let purchaseVendorOptions = new Set<string>()
  let purchaseItemOptions = new Set<string>()
  let purchaseCategoryOptions = new Set<string>()

  if (reportResult?.tableData) {
    for (const location of reportResult.tableData) {
      for (const item in location.itemDetails) {
        const { vendor, type } = location.itemDetails[item]
        purchaseVendorOptions.add(vendor)

        if (selectedVendor) {
          if (vendor === selectedVendor) {
            if (type === 'item') {
              purchaseItemOptions.add(item)
            }

            if (type === 'category') {
              purchaseCategoryOptions.add(item)
            }
          }
        } else {
          if (type === 'item') {
            purchaseItemOptions.add(item)
          }

          if (type === 'category') {
            purchaseCategoryOptions.add(item)
          }
        }
      }
    }

    const purchaseOptions = [
      ...(config?.hasSummaryCategories
        ? [
            {
              name: 'All Items',
              type: 'Summary',
            },
            {
              name: 'Weekly Items',
              type: 'Summary',
            },
            {
              name: 'Period Items',
              type: 'Summary',
            },
          ]
        : [
            {
              name: 'All Items',
              type: 'All Items',
            },
          ]),
      ...Array.from(purchaseCategoryOptions)
        .sort()
        .map((name) => ({
          name,
          type: 'Category',
        })),
      ...Array.from(purchaseItemOptions)
        .sort()
        .map((name) => ({
          name,
          type: 'Item',
        })),
    ]

    let defaultPurchaseOption =
      purchaseOptions?.length > 0 ? purchaseOptions[0] : null

    let bestMatchOptionName = ''
    if (presetCategoryOrItemFilter && purchaseOptions?.length > 0) {
      const bestMatchResults = stringSimilarity.findBestMatch(
        presetCategoryOrItemFilter,
        purchaseOptions.map((option) => {
          return option.name
        }),
      )
      bestMatchOptionName = bestMatchResults?.bestMatch?.target
    }

    const purchaseOptionCount = purchaseOptions?.length || 0
    for (let io = 0; io < purchaseOptionCount; ++io) {
      const option = purchaseOptions[io]
      if (bestMatchOptionName) {
        if (option.name === bestMatchOptionName) {
          defaultPurchaseOption = option
          break
        }
      } else if (option.name === 'Food' && option.type === 'Category') {
        defaultPurchaseOption = option
        break
      }
    }

    return {
      purchaseOptions,
      defaultPurchaseOption,
      purchaseVendorOptions: Array.from(purchaseVendorOptions)
        .sort()
        .map((vendor) => ({
          name: vendor,
          type: 'Vendor',
        })),
    }
  }

  return {
    purchaseVendorOptions: [],
    purchaseOptions: [],
    defaultPurchaseOption: null,
  }
}

export default getOptions
