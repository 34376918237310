import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { toHourStr, toPercentString, toUsdString } from 'pared/utils/number'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query ListAcePopDailyFlashKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        metricData
        metricSummaryData
      }
    }

    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        metricData
        metricSummaryData
      }
    }

    listEmployeeMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        metricLocationId
        employeeName
        metricData
      }
    }
  }
`

export const acePopDailyFlashConfigs = {
  netSales: 'colored-text',
  yoyNetSalesPercentage: 'colored-text',
  cashDeposits: 'colored-text',
  cashOs: 'colored-text',
  overringAmount: 'colored-text',
  deletesAmount: 'colored-text',
  deletesAmountPercentage: 'colored-text',
  maxDeleteBy: 'string',
  maxDeleteAmount: 'price',
} as const

const KPIS = [
  'net_sales',
  'yoy_net_sales_percentage',
  'cash_deposits',
  'cash_os',
  'overring_amount',
  'deletes_amount',
  'deletes_amount_percentage',
]

function getMaxMinValue(data?: number[], amount?: number) {
  if (!data) return null

  const orderedData = Array.from(data)
  for (let i = 1; i < orderedData.length; i++) {
    for (let j = 0; j < orderedData.length - i; j++) {
      if (orderedData[j] > orderedData[j + 1]) {
        ;[orderedData[j], orderedData[j + 1]] = [
          orderedData[j + 1],
          orderedData[j],
        ]
      }
    }
  }

  if (!amount) {
    return {
      max: [orderedData[orderedData.length - 1]],
      min: [orderedData[0]],
    }
  }

  return {
    max: orderedData.slice(-amount),
    min: orderedData.slice(0, amount),
  }
}

const useAcePopDailyFlash = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: hasGroupBy
          ? groupFilter?.ids
          : groupFilter?.list?.map((g) => g.id),
        metrics: KPIS,
      },
      hasGroupBy,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const rawEmployeeData: any = data?.listEmployeeMetricValues?.nodes
      const customizedData: any =
        data?.[
          hasGroupBy
            ? 'listLocationMetricValues'
            : 'listLocationGroupMetricValues'
        ]?.nodes

      if (!customizedData) return null

      const orderedNetSales = getMaxMinValue(
        customizedData.map((data: any) => data.metricData.net_sales.value),
        2,
      )
      const orderedCashOs = getMaxMinValue(
        customizedData.map((data: any) => data.metricData.cash_os.value),
      )
      const orderedOverringAmount = getMaxMinValue(
        customizedData.map(
          (data: any) => data.metricData.overring_amount.value,
        ),
      )
      const orderedDeletesAmount = getMaxMinValue(
        customizedData.map((data: any) => data.metricData.deletes_amount.value),
      )

      const source = customizedData.map((locationData: any) => {
        const groupInfo = groupFilter?.list?.find(
          (l) =>
            l.id ===
            (hasGroupBy
              ? locationData.locationId
              : locationData.locationGroupId),
        )

        const kpisData: {
          [key: string]: {
            text: string
            textColor: string
            type?: string
            value?: number
          }
        } = {}

        for (const key in locationData.metricData) {
          if (locationData.metricData.hasOwnProperty(key)) {
            const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
            let status = ''
            switch (key) {
              case 'net_sales':
                if (!orderedNetSales) break

                status = 'black'
                if (
                  orderedNetSales.max.includes(
                    locationData.metricData[key].value,
                  )
                ) {
                  status = 'green'
                } else if (
                  orderedNetSales.min.includes(
                    locationData.metricData[key].value,
                  )
                ) {
                  status = 'danger'
                }

                kpisData[newKey] = {
                  text: toUsdString(locationData.metricData[key].value || '-'),
                  textColor: status,
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'yoy_net_sales_percentage':
                kpisData[newKey] = {
                  text: toPercentString(
                    locationData.metricData[key].value * 100 || '-',
                  ),
                  textColor:
                    locationData.metricData[key].value >= 0
                      ? 'green'
                      : 'danger',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'cash_deposits':
                kpisData[newKey] = {
                  text: toUsdString(locationData.metricData[key].value || '-'),
                  textColor: 'black',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'cash_os':
                if (!orderedCashOs) break

                status = 'black'
                if (
                  orderedCashOs.max.includes(locationData.metricData[key].value)
                ) {
                  status = 'danger'
                } else if (!locationData.metricData[key].value) {
                  status = 'warning'
                }
                kpisData[newKey] = {
                  text: toUsdString(locationData.metricData[key].value || '-'),
                  textColor: status,
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'overring_amount':
                kpisData[newKey] = {
                  text: toUsdString(locationData.metricData[key].value || '-'),
                  textColor: orderedOverringAmount?.max.includes(
                    locationData.metricData[key].value,
                  )
                    ? 'danger'
                    : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'deletes_amount':
                kpisData[newKey] = {
                  text: toUsdString(locationData.metricData[key].value || '-'),
                  textColor: orderedDeletesAmount?.max.includes(
                    locationData.metricData[key].value,
                  )
                    ? 'danger'
                    : 'black',
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              case 'deletes_amount_percentage':
                status = 'warning'
                if (locationData.metricData[key].value >= 0.1) {
                  status = 'danger'
                } else if (locationData.metricData[key].value < 0.05) {
                  status = 'green'
                }

                kpisData[newKey] = {
                  text: toPercentString(
                    locationData.metricData[key].value * 100 || '-',
                  ),
                  textColor: status,
                  type: 'number',
                  value: locationData.metricData[key].value,
                }
                break

              default:
                break
            }
          }
        }

        const maxEmployeeData = !hasGroupBy
          ? { employee: '', value: null }
          : rawEmployeeData?.reduce(
              (result: any, data: any) => {
                if (data.metricLocationId === locationData.locationId) {
                  if (
                    !result.value ||
                    result.value <= data.metricData?.deletes_amount.value
                  ) {
                    return {
                      employee: data.employeeName,
                      value: data.metricData?.deletes_amount.value,
                    }
                  }
                }

                return result
              },
              { employee: '', value: null },
            )

        return {
          ...kpisData,
          groupInfo,
          maxDeleteBy: maxEmployeeData?.employee,
          maxDeleteAmount: maxEmployeeData?.value
            ? maxEmployeeData.value * 100
            : null,
        }
      })

      const [rawSummary] = customizedData.filter(
        (locationData: any) => locationData.metricSummaryData != null,
      )
      const summary: {
        [key: string]: {
          text: string
          textColor: string
          type?: string
          value?: number
        }
      } = {}

      const percentSummary: {
        [key: string]: {
          text: string
          textColor: string
          type?: string
          value?: number
        }
      } = {}

      const totalNetSales = rawSummary?.metricSummaryData.net_sales.value

      for (const key in rawSummary?.metricSummaryData) {
        if (rawSummary.metricSummaryData.hasOwnProperty(key)) {
          const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
          switch (rawSummary.metricSummaryData[key].unit) {
            case 'DOLLAR':
              summary[newKey] = {
                text: toUsdString(
                  rawSummary.metricSummaryData[key].value || '-',
                ),
                textColor: 'black',
              }
              break
            case 'PERCENTAGE':
              summary[newKey] = {
                text: toPercentString(
                  rawSummary.metricSummaryData[key].value * 100 || '-',
                ),
                textColor: 'black',
              }
              break

            default:
              summary[newKey] = {
                text: toHourStr(rawSummary.metricSummaryData[key].value),
                textColor: 'black',
              }
              break
          }
        }
      }

      for (const key in rawSummary?.metricSummaryData) {
        if (rawSummary.metricSummaryData.hasOwnProperty(key)) {
          const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
          switch (rawSummary.metricSummaryData[key].unit) {
            case 'DOLLAR':
              percentSummary[newKey] = {
                text: `${toPercentString(
                  (rawSummary.metricSummaryData[key].value / totalNetSales) *
                    100,
                )}`,
                textColor: 'black',
              }
              break
            case 'PERCENTAGE':
              percentSummary[newKey] = {
                text: '-',
                textColor: 'black',
              }
              break

            default:
              percentSummary[newKey] = {
                text: '-',
                textColor: 'black',
              }
              break
          }
        }
      }

      return [
        {
          id: 'all-store',
          parentId: 'root',
          source,
          summary: {
            ...summary,
            maxDeleteBy: null,
            maxDeleteAmount: null,
            groupInfo: { name: 'Total' },
          },
        },
        {
          id: 'percent-total',
          parentId: 'root',
          source: [],
          summary: {
            ...percentSummary,
            maxDeleteBy: null,
            maxDeleteAmount: null,
            groupInfo: { name: '% of Net Sales' },
          },
        },
      ]
    }, [groupFilter, data]),
    loading,
  }
}

export default useAcePopDailyFlash
