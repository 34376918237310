import { useQuery } from '@apollo/client'
import { Moment } from 'moment'
import { useMemo } from 'react'

import { DATE_FORMAT } from 'pared/data/getDateRanges'
import useGetDirectors from 'pared/hooks/useGetDirectors'

import {
  IAccountingDataType,
  IAccountingDataVariablesType,
  listLocationGroupAccountingData,
} from '../gql'

const csvHeader = [
  'Store #',
  'Deposit Amount #1',
  'Deposit Amount #2',
  'Deposit Amount #3',
  'Deposit Amount #4',
  'Deposit Amount #5',
  'Deposit Amount #6',
  'Deposit Amount #7',
  'Deposit Amount #8',
  'Deposit Amount #9',
  'Deposit Amount #10',
  'Discover Total',
  'AMEX Total',
  'Visa/MC Total',
  'Door Dash Amount',
  'Grub Hub Amount',
  'Post Mates Amount',
  'Uber Eats Amount',
  'Net Food Sales Amount',
  'Net Non Food Sales Amount',
  'Sales Tax Amount',
  'Paid In Amount',
  'Paid Outs Amount',
  'Gift Card Sold Amount',
  'Gift Card Reloads Amount',
  'Gift Redeemed Amount',
  'Delivery Tips$ Amount',
  'Donation RoundUp Amount',
  'Donation VFW $ Amount',
  'School Voucher Amount',
  'Over/Short Amount',
]

const useDataSource = (startDate: Moment, endDate: Moment) => {
  const { locationGroupIds } = useGetDirectors()

  const { data, loading } = useQuery<
    IAccountingDataType,
    IAccountingDataVariablesType
  >(listLocationGroupAccountingData, {
    variables: {
      iStartDate: startDate.format(DATE_FORMAT),
      iEndDate: endDate.format(DATE_FORMAT),
      iFilter: {
        location_group_ids: locationGroupIds,
      },
    },
  })

  const csvData = useMemo(() => {
    if (!data?.listLocationGroupAccountingData) return []

    const accountingData = data?.listLocationGroupAccountingData.nodes?.map(
      (row) => {
        const deposits = Array.from(
          { length: 10 },
          (_, index) => row.deposit[index] / 100 || 0,
        )
        const creditCards = row.creditCard.reduce(
          (obj, c) => ({ ...obj, [c.paymentMethod]: c.totalAmount / 100 }),
          {} as { [key: string]: number },
        )

        return [
          row.code,
          ...deposits,
          creditCards['Discover'],
          creditCards['AMEX'],
          creditCards['MC'] + creditCards['Visa'],
          row.doorDashAmount / 100,
          row.grubHubAmount / 100,
          row.postMatesAmount / 100,
          row.uberEatsAmount / 100,
          row.netFoodSalesAmount / 100,
          row.netNonFoodSalesAmount / 100,
          row.salesTaxAmount / 100,
          row.paidInAmount / 100,
          row.paidOutsAmount / 100,
          row.giftCardSoldAmount / 100,
          row.giftCardReloadsAmount / 100,
          row.giftRedeemedAmount / 100,
          row.deliveryTipsAmount / 100,
          row.donationRoundUpAmount / 100,
          row.donationVfwAmount / 100,
          row.schoolVoucherAmount / 100,
          row.overShortAmount / 100,
        ]
      },
    )

    return [
      [
        'Business Date',
        '',
        startDate.format('MM/DD/YYYY'),
        endDate.format('MM/DD/YYYY'),
      ],
      csvHeader,
      ...accountingData,
    ]
  }, [data])

  return {
    csvData,
    loading,
  }
}

export default useDataSource
