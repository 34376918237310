import { gql, useQuery } from '@apollo/client'
import { useMemo, useState } from 'react'

interface IQueryDataType {
  listItems: {
    nodes: {
      itemId: number
      itemType: string
      itemDisplayName: string
      displayParentCategoryId: number
      displayParentCategoryName: string
    }[]
  }
}

export interface IDataType {
  items?: {
    options: any
    value: any
  }
}

const QUERY_NAME = 'listItems'
const query = gql`
  query {
    ${QUERY_NAME} {
      nodes {
        itemId
        itemType
        itemDisplayName
        displayParentCategoryId
        displayParentCategoryName
      }
    }
  }
`

const useItemOrCategory = (): IDataType => {
  const { data } = useQuery<IQueryDataType>(query)
  // Hard coded for MWB Breakfast
  const itemHook = useState([['302', 'category-302']])

  return {
    items: useMemo(() => {
      const fetchedData = data?.[QUERY_NAME]?.nodes

      const subcategories = {}
      const categories = {}
      fetchedData?.forEach(({ itemType, itemId, itemDisplayName }) => {
        if (itemType === 'CATEGORY') {
          subcategories[itemId] = itemDisplayName
        }
      })

      fetchedData?.forEach(
        ({ displayParentCategoryId, displayParentCategoryName }) => {
          if (!subcategories[displayParentCategoryId]) {
            categories[displayParentCategoryId] = displayParentCategoryName
          }
        },
      )

      const categoriesOptions = Object.keys(categories).map((category) => ({
        id: category,
        parentId: 'root',
        displayName: categories[category],
      }))

      const allCategoriesOptions = categoriesOptions.map(
        ({ id, displayName }) => ({
          id: `category-${id}`,
          parentId: id,
          displayName,
        }),
      )

      const subcategoriesOptions =
        fetchedData
          ?.filter(({ itemType }) => itemType === 'CATEGORY')
          .map(({ displayParentCategoryId, itemId, itemDisplayName }) => ({
            id: itemId,
            parentId: displayParentCategoryId,
            displayName: itemDisplayName,
          })) ?? []

      const allSubcategoriesOptions = subcategoriesOptions.map(
        ({ id, displayName }) => ({
          id: `subcategory-${id}`,
          parentId: id,
          displayName,
        }),
      )

      const options =
        fetchedData
          ?.filter(({ itemType }) => itemType === 'ITEM')
          .map(({ itemId, itemDisplayName, displayParentCategoryId }) => ({
            id: itemId,
            parentId: displayParentCategoryId,
            displayName: itemDisplayName,
          })) ?? []

      const allOptions = [
        ...categoriesOptions,
        ...allCategoriesOptions,
        ...subcategoriesOptions,
        ...allSubcategoriesOptions,
        ...options,
      ]

      return {
        value: itemHook[0],
        options: allOptions,
        onChange: itemHook[1],
      }
    }, [data, itemHook]),
  }
}

export default useItemOrCategory
