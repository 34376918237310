import React from 'react'

import Main from './Main'
import { IYAxisData } from './web/drawMixedChart'

export interface IOptions {
  width?: number
  height?: number
  title?: string
  xAxisLabel?: string
  xTickCallback?: (value: any, index: number, values: any[]) => string
  xShowGridLines?: boolean
  yLeftAxisLabel?: string
  yLeftTickCallback?: (value: any, index: number, values: any[]) => string
  yLeftShowGridLines?: boolean
  yRightAxisLabel?: string
  yRightTickCallback?: (value: any, index: number, values: any[]) => string
  yRightShowGridLines?: boolean
  tooltipTitleCallback?: (tooltipItemContext: any[]) => string
  tooltipLabelCallback?: (tooltipItemContext: any) => string
}

interface IProps {
  xAxisData: any[]
  yAxisDataArr: IYAxisData[]
  options?: IOptions
}

function MixedChart({ xAxisData, yAxisDataArr, options = {} }: IProps) {
  return (
    <Main xAxisData={xAxisData} yAxisDataArr={yAxisDataArr} options={options} />
  )
}

export default MixedChart
