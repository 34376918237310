import { gql } from '@apollo/client'

interface IDirectorInfo {
  id: number
  given_name: string
  family_name: string
  preferred_name: string
}

interface ILocationInfo {
  id: string
  code: string
  name: string
  director: IDirectorInfo
}

export interface ICorporateSalesmanshipLocations extends ILocationInfo {
  rate: number
  expectRate: number
  checkCount: number
  opportunityCost: number
  annualOpportunityCost: number
}

export interface ISalesSummaryNodeType {
  area: string
  locations: ICorporateSalesmanshipLocations[]
  annualOpportunityCost: number
  opportunityCost: number
  totalNetSales: number
  annualTotalNetSales: number
}

export interface ISalesSummaryType {
  corporateSalesmanshipSummaryV2: {
    nodes: ISalesSummaryNodeType[]
  }
}

export interface ISalesSummaryVariablesType {
  iLocationGroupId: number
  iBrandLocationGroupId: number
  iStartDate?: string
  iEndDate?: string
}

export const getCorporateSalesmanshipSummary = gql`
  query GetCorporateSalesmanshipSummary(
    $iLocationGroupId: Int!
    $iBrandLocationGroupId: Int!
    $iStartDate: Date!
    $iEndDate: Date!
  ) {
    corporateSalesmanshipSummaryV2(
      iLocationGroupId: $iLocationGroupId
      iBrandLocationGroupId: $iBrandLocationGroupId
      iStartDate: $iStartDate
      iEndDate: $iEndDate
    ) {
      nodes {
        area
        locations
        annualOpportunityCost
        opportunityCost
        totalNetSales
        annualTotalNetSales
      }
    }
  }
`
