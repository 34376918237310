import _ from 'lodash'
import React from 'react'

import LeaderboardTable from '../LeaderboardTable'
import useUpsizeLeaderboardData from './hooks/useUpsizeLeaderboardData'

interface IProps {
  leaderboardTitle: string
  dataColumnHeader: string
  upsizeType: string
  locationId: number | string
  startDate: string
  endDate: string
  dayPart: string
  storeName: string
  isExpanded: boolean
}

function SalesLeaderboard({
  leaderboardTitle,
  dataColumnHeader,
  upsizeType,
  locationId,
  startDate,
  endDate,
  dayPart,
  storeName,
  isExpanded,
}: IProps) {
  const { leaderboardData } = useUpsizeLeaderboardData(
    upsizeType,
    locationId,
    startDate,
    endDate,
    dayPart,
  )

  return (
    <LeaderboardTable
      leaderboardTitle={leaderboardTitle}
      dataColumnHeader={dataColumnHeader}
      isExpanded={isExpanded}
      storeName={storeName}
      leaderboardData={leaderboardData}
    />
  )
}

export default React.memo(SalesLeaderboard)
