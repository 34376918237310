import { gql } from '@apollo/client'

export interface IGetStoreListByBrandVariableTypes {
  iFilter: { location_group_ids: number[] }
}

export interface IStoreListNodeType {
  id: number
  code: string
  name: string
  brandId: number
  directorId: number
  directorEmployeeInfo: {
    id: number
    givenName: string
    familyName: string
    preferredName: string
  }
  gmEmployeeInfo: {
    id: number
    givenName: string
    familyName: string
    preferredName: string
  }
}

export interface IGetStoreListByBrandType {
  listLocationDetails: {
    nodes: IStoreListNodeType[]
  }
}

export interface IListLocationGroupLossPreventionKpisNodeType {
  locationGroupId: number
  cashOverOrShort: number
  oloOverOrShort: number
  inaccurateCashierShiftPercent: number
  compsTotalCount: number
  compsTotalAmount: number
  compsPercent: number
  lossPreventionItems: {
    type: string
    amount: number
    count: number
    checkPercent: number
  }[]
}

export interface IListLocationGroupLossPreventionKpisType {
  listLocationGroupLossPreventionKpis: {
    nodes: IListLocationGroupLossPreventionKpisNodeType[]
  }
}

export const LIST_LOCATION_GROUP_LOSS_PREVENTION_KPIS = gql`
  query ListLocationGroupLossPreventionKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    listLocationGroupLossPreventionKpis(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) {
      nodes {
        locationGroupId
        cashOverOrShort
        oloOverOrShort
        inaccurateCashierShiftPercent
        compsTotalCount
        compsTotalAmount
        compsPercent
        lossPreventionItems
      }
    }
  }
`

export const GET_STORE_LIST = gql`
  query GetStoreListByBrand($iFilter: JSON!) {
    listLocationDetails(iFilter: $iFilter) {
      nodes {
        id
        code
        name
        brandId
        directorId
        directorEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
        gmEmployeeInfo {
          id
          givenName
          familyName
          preferredName
        }
      }
    }
  }
`
