import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import axios from 'axios'
import moment from 'moment'
import { useState } from 'react'
import styled from 'styled-components'

import COLORS from 'pared/constants/colors'

import useGetPeriod from './hooks/useGetPeriod'

export interface IPropsType {
  type: 'upload-reports'
  uploadRoute: string
  reportTypes: string[]
  enableTimeSelector?: boolean
}

const Alert = styled.div<{
  error: boolean
}>`
  margin-top: 20px;
  font-size: 16px;
  color: ${({ error }) => (error ? COLORS.ExpoRed1 : 'black')};
`

const StypedTextField = styled(TextField)`
  margin-top: 25px;
`

const UploadReports = ({
  uploadRoute,
  reportTypes,
  enableTimeSelector,
}: IPropsType) => {
  const today = moment.utc().clone()
  const { data, loading } = useGetPeriod()
  const [file, setFile] = useState(null)
  const [time, setTime] = useState<string>(
    enableTimeSelector ? '' : today.format('YYYY-MM-DD'),
  )
  const [type, setType] = useState<string>('')
  const [uploading, setUploading] = useState(false)
  const [uploadStatus, setUploadStatus] = useState<string | null>(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const handleFileChange = (event: any) => {
    setFile(event.target.files[0])
  }

  const handleUpload = async () => {
    if (!file || !time || !type) return

    try {
      setUploading(true)
      setUploadStatus(null)

      // Send a pre-check request to the backend to see if the file exists
      const response = await axios.head(
        `${
          process.env.REACT_APP_BE_BASE_URL
        }/upload/gfp_reports?time=${encodeURIComponent(
          time,
        )}&type=${encodeURIComponent(type)}`,
        {
          validateStatus: function (status) {
            return status >= 200 && status < 500
          },
        },
      )

      if (response.status === 200) {
        setConfirmDialogOpen(true)
        setUploading(false)
        return
      }

      const formData = new FormData()
      formData.append('file', file)
      formData.append('time', time)
      formData.append('type', type)

      await axios.post(
        `${process.env.REACT_APP_BE_BASE_URL}/${uploadRoute}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      setUploading(false)
      setUploadStatus('Upload successfully.')
    } catch (error) {
      setUploading(false)
      setUploadStatus('Error uploading file: ' + error)
      console.error('Error uploading file:', error)
    }
  }

  const handleConfirmOverwrite = async () => {
    setConfirmDialogOpen(false)

    try {
      setUploading(true)
      setUploadStatus(null)

      if (!file || !time || !type) return
      // If the user confirms overwriting, proceed with uploading without pre-checking
      const formData = new FormData()
      formData.append('file', file)
      formData.append('time', time)
      formData.append('type', type)

      await axios.post(
        `${process.env.REACT_APP_BE_BASE_URL}/${uploadRoute}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      setUploading(false)
      setUploadStatus('Upload successfully.')
    } catch (error) {
      setUploading(false)
      setUploadStatus('Error uploading file: ' + error)
    }
  }

  if (loading) return null

  return (
    <Container maxWidth="sm">
      <Box component="form">
        <FormControl fullWidth margin="normal">
          <InputLabel id="type">Select Report Type</InputLabel>
          <Select
            labelId="type"
            value={type}
            onChange={({ target: { value } }) => setType(value as string)}
            label="type"
          >
            {reportTypes.map((type: string) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {enableTimeSelector ? (
          <FormControl fullWidth margin="normal">
            <InputLabel id="period">Select Period</InputLabel>
            <Select
              labelId="period"
              value={time}
              onChange={({ target: { value } }) => setTime(value as string)}
              label="period"
            >
              {data?.map((period: string) => (
                <MenuItem key={period} value={period}>
                  {period}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        <StypedTextField
          type="file"
          fullWidth
          onChange={handleFileChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={!file || !time || uploading}
            startIcon={uploading ? <CircularProgress size={24} /> : null}
          >
            {uploading ? 'Uploading...' : 'Upload'}
          </Button>
          {uploadStatus && (
            <Alert error={uploadStatus !== 'Upload successfully.'}>
              {uploadStatus}
            </Alert>
          )}
        </Box>
      </Box>
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Confirm Overwrite</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`The ${type} report of ${
              enableTimeSelector ? 'this period' : 'today'
            } already exists. Do you want to overwrite it?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmOverwrite} color="primary">
            Overwrite
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default UploadReports
