import { getBusinessLabels } from 'pared/customer/businessLabels'
import { getUser } from 'pared/utils/user'

const corporateLevelModules = {
  expoAi: {
    title: 'Expo AI',
    url: '/:brand/expo_ai',
  },
  overview: {
    title: 'Systemwide Overview',
    url: '/:brand/systemwide_overview',
  },
  ranking: { title: 'Rankings', url: '/:brand/rankings' },
  flash: { title: 'Flash', url: '/:brand/flash' },
  dailyFlash: { title: 'Daily Flash', url: '/:brand/daily_flash' },
  yields: { title: 'Yields', url: '/:brand/corporate_yields' },
  product: { title: 'Product', url: '/:brand/corporate_product' },
  team: { title: 'Team', url: '/:brand/corporate_team' },
  salesmanship: {
    title: 'Salesmanship',
    url: '/:brand/corporate_salesmanship',
  },
  pmix: {
    title: 'Product',
    url: '/:brand/corporate_pmix',
  },
  lossPrevention: {
    title: 'Loss Prevention',
    url: '/:brand/corporate_loss_prevention',
  },
  SalesExaminer: {
    title: 'Sales Examiner',
    url: '/:brand/corporate_sales_examiner',
  },
  inventory: {
    title: 'Inventory',
    url: '/:brand/corporate_inventory',
  },
  purchases: {
    title: 'Purchases',
    url: '/:brand/corporate_purchases',
  },
  expandedPL: {
    title: 'Expanded P&L',
    url: '/:brand/corporate_expanded_pl',
  },
  guest: { title: 'Guest', url: '/:brand/corporate_guest' },
  opsExcellence: { title: 'Ops Excellence', url: '/:brand/corporate_guest' },
  delivery: { title: 'Delivery', url: '/:brand/corporate_delivery' },
  custom: { title: 'Custom', url: '/:brand/corporate_custom' },
  calls: { title: 'Calls', url: '/:brand/corporate_calls' },
  employeeDevelopment: {
    title: 'Development',
    url: '/:brand/corporate_development',
    notification: 'corporateDevelopment',
  },
  marketAnalysis: {
    title: 'Marketing',
    url: '/:brand/corporate_market_analysis',
  },
  plaid: { title: 'Plaid', url: '/:brand/plaid' },
  creditCardReconciliation: {
    title: 'Credit Cards',
    url: '/:brand/corporate_credit_card_reconciliation',
    accessPage: 'credit-card-reconciliation-c7a214a1',
  },
  kpis: { title: 'KPIs', url: '/:brand/kpis' },
}

const storeLevelModules = {
  performance: {
    title: 'Store Performance',
    url: '/:brand/store_detail',
  },
  scorecard: { title: 'Home', url: '/:brand/scorecard' },
  team: { title: 'Team', url: '/:brand/team' },
  salesmanship: { title: 'Salesmanship', url: '/:brand/salesmanship' },
  pmix: { title: 'Product', url: '/:brand/pmix' },
  product: { title: 'Product', url: '/:brand/product' },
  buns: { title: 'Buns', url: '/:brand/buns' },
  lossPrevention: {
    title: 'Loss Prevention',
    url: '/:brand/loss_prevention',
  },
  guest: { title: 'Guest', url: '/:brand/guest' },
  delivery: { title: 'Delivery', url: '/:brand/delivery' },
  inventory: {
    title: 'Inventory',
    url: '/:brand/inventory',
  },
  purchases: {
    title: 'Purchases',
    url: '/:brand/purchases',
  },
  calls: { title: 'Calls', url: '/:brand/calls' },
  employeeDevelopment: {
    title: 'Development',
    url: '/:brand/development',
  },
  orderingAnalysis: {
    title: 'Ordering Analysis',
    url: '/:brand/ordering_analysis',
  },
  intradayFlash: {
    title: 'Today',
    url: '/:brand/today',
  },
}

interface IModuleObjects {
  title: string
  url: string
  notification?: string
  accessPage?: string // for page permission record in user_role table
}

export interface IModules {
  corporate: IModuleObjects[]
  stores: IModuleObjects[]
  customs?: IModuleObjects[]
}

export function getModules(brand: string): IModules {
  const user = getUser()
  const businessLabels = getBusinessLabels(brand)
  storeLevelModules.performance.title = `${businessLabels.store} Performance`
  corporateLevelModules.custom.title =
    businessLabels.corporateCustomNavTitle || 'Custom'
  switch (brand) {
    case 'rmg': // RMG
      if (user.isDrgSalesManager) {
        return {
          corporate: [
            corporateLevelModules.overview,
            corporateLevelModules.SalesExaminer,
          ],
          stores: [],
        }
      }

      return {
        corporate: [
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.SalesExaminer,
          corporateLevelModules.product,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.expandedPL,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }
    case 'sullivans': // Sullivan's
    case 'eddiemerlots': // Eddie Merlot's
    case 'sullivans_nso': // Sullivan's NSO
    case 'eddiemerlots_nso':
      if (user.isDrgSalesManager) {
        return {
          corporate: [
            corporateLevelModules.overview,
            corporateLevelModules.SalesExaminer,
          ],
          stores: [],
        }
      }

      return {
        corporate: [
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.SalesExaminer,
          corporateLevelModules.product,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.expandedPL,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
          corporateLevelModules.calls,
          corporateLevelModules.marketAnalysis,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.product,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.guest,
          storeLevelModules.delivery,
          storeLevelModules.calls,
        ],
      }
    case 'rmg_nso': // RMG NSO
      return {
        corporate: [
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.SalesExaminer,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.expandedPL,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }
    case 'papajohns': // Papa Johns
    case 'qsr_shack': // QSR Demo
      return {
        corporate: [
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.SalesExaminer,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }
    case 'irmg_cc':
      return {
        corporate: [corporateLevelModules.plaid],
        stores: [],
      }
    case 'irmg_bk':
      return {
        corporate: [
          corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.buns,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }
    case 'sandbox_bk':
      return {
        corporate: [
          corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.buns,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }
    case 'viking_bk':
      return {
        corporate: [
          corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.buns,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
        customs: [corporateLevelModules.custom],
      }
    case 'jsc_bk':
    case 'jsc_bk_nso':
      return {
        corporate: [
          corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          corporateLevelModules.team,
          corporateLevelModules.employeeDevelopment,
          corporateLevelModules.salesmanship,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.employeeDevelopment,
          storeLevelModules.salesmanship,
          storeLevelModules.buns,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
        customs: [corporateLevelModules.creditCardReconciliation],
      }
    case 'timoney_bk':
      return {
        corporate: [
          // using renderer instead with 'useOriginal'
          // corporateLevelModules.expoAi,
          // corporateLevelModules.overview,
          // corporateLevelModules.custom,
          // corporateLevelModules.ranking,
          // corporateLevelModules.flash,
          // corporateLevelModules.team,
          // corporateLevelModules.salesmanship,
          // corporateLevelModules.pmix,
          // corporateLevelModules.lossPrevention,
          // corporateLevelModules.inventory,
          // corporateLevelModules.guest,
          // corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.pmix,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }
    case 'supreme_bk':
      return {
        corporate: [
          corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }
    case 'mbn_bk':
      return {
        corporate: [
          corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.pmix,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.pmix,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }
    case 'demo_bk':
      return {
        corporate: [
          corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.buns,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }
    case 'demo_pop':
      return {
        corporate: [
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }
    case 'irmg_pop':
      return {
        corporate: [
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
        ],
        stores: [
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
        ],
      }
    case 'supreme_pop':
      return {
        corporate: [
          corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
        ],
      }
    case 'ghai_pop':
      return {
        corporate: [
          corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          corporateLevelModules.salesmanship,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.salesmanship,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
        ],
      }
    case 'wingitnorth':
      return {
        corporate: [
          corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          {
            ...corporateLevelModules.flash,
            title: 'Weekly Flash',
          },
          corporateLevelModules.dailyFlash,
          corporateLevelModules.salesmanship,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.guest,
          corporateLevelModules.purchases,
          corporateLevelModules.delivery,
          corporateLevelModules.calls,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.salesmanship,
          storeLevelModules.lossPrevention,
          storeLevelModules.guest,
          storeLevelModules.purchases,
          storeLevelModules.delivery,
          storeLevelModules.calls,
        ],
      }
    case 'wingstop':
      return {
        corporate: [
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          corporateLevelModules.yields,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }
    case 'cava':
      return {
        corporate: [
          {
            ...corporateLevelModules.overview,
            title: 'Overview',
          },
          corporateLevelModules.ranking,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.guest,
          corporateLevelModules.purchases,
        ],
        stores: [
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.guest,
          {
            ...storeLevelModules.scorecard,
            title: 'KPIs',
          },
          storeLevelModules.purchases,
        ],
      }

    case 'jitb':
      return {
        corporate: [
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }

    case 'mwb':
      return {
        corporate: [
          corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          // corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
          // corporateLevelModules.product,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          // storeLevelModules.team,
          storeLevelModules.salesmanship,
          // storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }
    case 'burgerworks':
      return {
        corporate: [
          corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          // corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
          // corporateLevelModules.product,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          // storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }
    // Local Favorite
    case 'lfr':
      return {
        corporate: [corporateLevelModules.overview],
        stores: [],
      }

    case 'lfr_ef':
    case 'lfr_tr':
    case 'lfr_sn':
    case 'lfr_lv':
    case 'lfr_vb':
    case 'lfr_tu':
    case 'lfr_ws':
    case 'lfr_jn':
    case 'lfr_mm':
    case 'lfr_ol':
    case 'lfr_dt':
    case 'lfr_uv':
    case 'lfr_bth':
      return {
        corporate: [
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.SalesExaminer,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }
    case 'primanti':
      return {
        corporate: [
          corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.salesmanship,
          corporateLevelModules.guest,
        ],
        stores: [
          storeLevelModules.performance,
          storeLevelModules.salesmanship,
          storeLevelModules.guest,
        ],
      }
    case 'bibibop':
      return {
        corporate: [
          corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.flash,
          corporateLevelModules.kpis,
          corporateLevelModules.ranking,
          corporateLevelModules.team,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.intradayFlash,
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }

    case 'fw_wingstop':
      return {
        corporate: [
          // corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.opsExcellence,
          corporateLevelModules.delivery,
        ],
        stores: [
          // using renderer instead with 'useOriginal'
          // storeLevelModules.performance,
          // storeLevelModules.guest,
          // storeLevelModules.delivery,
        ],
      }

    case 'ace_pop':
      return {
        corporate: [
          corporateLevelModules.expoAi,
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.flash,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.lossPrevention,
          corporateLevelModules.inventory,
          corporateLevelModules.guest,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.lossPrevention,
          storeLevelModules.inventory,
          storeLevelModules.guest,
        ],
      }

    default:
      return {
        corporate: [
          corporateLevelModules.overview,
          corporateLevelModules.ranking,
          corporateLevelModules.product,
          corporateLevelModules.team,
          corporateLevelModules.salesmanship,
          corporateLevelModules.guest,
          corporateLevelModules.delivery,
        ],
        stores: [
          storeLevelModules.scorecard,
          storeLevelModules.performance,
          storeLevelModules.team,
          storeLevelModules.salesmanship,
          storeLevelModules.guest,
          storeLevelModules.delivery,
        ],
      }
  }
}
